import * as React from 'react';
import {
  AUTH_LEVEL,
  loginUser,
  useApp,
  useAuth,
  useUnit,
} from '@danfoss/etui-sm';
import { objToB64Uri } from 'utils/b64-object-serialisation';
import { addSiteToRecents } from 'utils';
import {
  fetchMetadataFile,
  STORAGE_METADATA_KEY,
  STORAGE_SESSION_TOKEN,
} from '@danfoss/etui-sm-xml';
import { targetAccessLevel } from '../../../config';
import { LoginFormData } from '../LoginPage.types';

export function useLogin() {
  const [state, dispatch] = useApp();
  const { user, dispatch: authDispatch } = useAuth();
  const { dispatch: unitDispatch } = useUnit();

  React.useEffect(
    () => () => {
      user &&
        addSiteToRecents({
          protocol: { label: state.protocol, value: state.protocol },
          ipAddress: state.xmlBackendIp,
          user: user.user,
        });
    },
    [user],
  );

  const login = async ({ user: userName, password, ip }: LoginFormData) => {
    sessionStorage.removeItem(STORAGE_SESSION_TOKEN);
    sessionStorage.removeItem(STORAGE_METADATA_KEY);

    const url = `${state.protocol}//${ip.value}/xml.cgi`;

    unitDispatch({
      type: 'LOAD_UNITS_REQUEST',
    });

    await fetchMetadataFile(url);

    await loginUser(
      authDispatch,
      userName,
      password,
      url,
      targetAccessLevel ? AUTH_LEVEL[targetAccessLevel] : AUTH_LEVEL.SUPERVISOR,
    );

    if (ip) {
      dispatch({
        type: 'SET_URL_LOGIN_INFO',
        payload: objToB64Uri({ user, IP: ip.value }),
      });
    }
  };

  return login;
}

import * as React from 'react';
import { useResponsive } from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import { getSoftwareVersion } from 'utils/get-software-version';
import { isIE } from 'react-device-detect';
import { LoginLayout } from 'components/LoginLayout';
import * as S from './styles';
import { BrowserSupportInfo } from './components/BrowserSupportInfo';
import { LoginForm } from './components';

export function EmbeddedLoginPage() {
  const theme = useTheme();
  const [isAuthenticating, setIsAuthenticating] = React.useState(false);
  const { screenIsAtMost } = useResponsive({
    md: parseInt(theme.breakpoints[1], 10),
  });

  const isMdView = screenIsAtMost('md');

  const prefilledData = React.useMemo(() => {
    const { host } = window.location;
    return { ipAddress: host };
  }, []);

  const softwareVersion = getSoftwareVersion();

  return (
    <LoginLayout>
      {isIE ? (
        <BrowserSupportInfo />
      ) : (
        <>
          <S.EmbeddedFormContainer isMdView={isMdView}>
            <LoginForm
              onSetIpError={() => {}}
              prefilledData={prefilledData}
              isAuthenticating={isAuthenticating}
              setIsAuthenticating={setIsAuthenticating}
            />
          </S.EmbeddedFormContainer>
          <S.SoftwareVersion testId="appVersion-embed">
            {softwareVersion}
          </S.SoftwareVersion>
        </>
      )}
    </LoginLayout>
  );
}

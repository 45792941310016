import { Unit, UnitNetwork } from '@danfoss/etui-sm-xml';
import { siteInfo } from '../constants';

export const getUnitDetails = (
  units: UnitNetwork[],
  network: UnitNetwork,
): siteInfo => {
  const internalUnit: UnitNetwork = units?.filter(unit => {
    return unit?.addr === network?.addr;
  })[0];
  return getUnitDetailsObj(internalUnit);
};

export const getUnitDetailsObj = (internalUnit: UnitNetwork): siteInfo => {
  return {
    ip: internalUnit?.ip,
    port: internalUnit?.port,
    name: internalUnit?.name,
  };
};

export const checkIfExternalIp = (
  unit: Partial<Unit>,
  unitInternetValue: string,
  ip: string,
): string => {
  return +unit?.external_internet === 0 || ip === '0' ? '-' : unitInternetValue;
};

import { H3, P } from '@danfoss/etui-system-elements';
import { Theme } from '@danfoss/etui-themes';
import { TFunction } from 'i18next';

type HelpTextItem = { type: HelpTextType; text: string };
enum HelpTextType {
  HEADING = 'heading',
  TITLE = 'title',
  POINT = 'point',
}

const helpTextList: HelpTextItem[] = [
  { type: HelpTextType.HEADING, text: 't3622' },
  { type: HelpTextType.POINT, text: 't3623' },
  { type: HelpTextType.POINT, text: 't3624' },
  { type: HelpTextType.HEADING, text: 't3586' },
  { type: HelpTextType.TITLE, text: 't3587' },
  { type: HelpTextType.POINT, text: 't3588' },
  { type: HelpTextType.POINT, text: 't3589' },
  { type: HelpTextType.POINT, text: 't3590' },
  { type: HelpTextType.TITLE, text: 't3591' },
  { type: HelpTextType.POINT, text: 't3592' },
  { type: HelpTextType.POINT, text: 't3593' },
  { type: HelpTextType.POINT, text: 't3594' },
  { type: HelpTextType.POINT, text: 't3595' },
  { type: HelpTextType.POINT, text: 't3596' },
  { type: HelpTextType.POINT, text: 't3597' },
  { type: HelpTextType.POINT, text: 't3598' },
  { type: HelpTextType.POINT, text: 't3599' },
  { type: HelpTextType.POINT, text: 't3600' },
  { type: HelpTextType.TITLE, text: 't3601' },
  { type: HelpTextType.POINT, text: 't3602' },
  { type: HelpTextType.POINT, text: 't3603' },
  { type: HelpTextType.POINT, text: 't3604' },
  { type: HelpTextType.POINT, text: 't3605' },
  { type: HelpTextType.TITLE, text: 't3606' },
  { type: HelpTextType.POINT, text: 't3607' },
  { type: HelpTextType.POINT, text: 't3608' },
  { type: HelpTextType.TITLE, text: 't3609' },
  { type: HelpTextType.POINT, text: 't3610' },
  { type: HelpTextType.POINT, text: 't3611' },
  { type: HelpTextType.TITLE, text: 't3612' },
  { type: HelpTextType.POINT, text: 't3613' },
  { type: HelpTextType.POINT, text: 't3614' },
  { type: HelpTextType.TITLE, text: 't3615' },
  { type: HelpTextType.POINT, text: 't3616' },
  { type: HelpTextType.POINT, text: 't3617' },
  { type: HelpTextType.TITLE, text: 't3618' },
  { type: HelpTextType.POINT, text: 't3619' },
  { type: HelpTextType.POINT, text: 't3620' },
  { type: HelpTextType.POINT, text: 't3621' },
];

export const createHelpTextList = (theme: Theme, t: TFunction): JSX.Element => {
  const elements = {
    heading: (text: string, isFirst: boolean) => (
      <H3 testId="helpText-header" mt={isFirst && 0}>
        {t(text)}
      </H3>
    ),
    title: (text: string) => (
      <P
        fontWeight={theme.typography.fontWeightBold}
        m={`${theme.spacing.sm}px 0`}
      >
        {t(text)}
      </P>
    ),
    point: (text: string) => <P>- {t(text)}</P>,
  };

  return (
    <>
      {helpTextList.map(({ type, text }, index) =>
        elements[type](text, !index),
      )}
    </>
  );
};

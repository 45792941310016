import { STORAGE_XML_BACKEND_PROTOCOL_KEY } from '../../../constants/session-storage-constants.js';
import '../../../constants/software-versions.js';

/**
 * Gets xml protocol from local storage if exists
 * Fallbacks to get it from window.location.protocol
 *
 * @returns {AppState['protocol']}
 */
function getXmlBackendProtocol() {
  const xmlBackendProtocol = sessionStorage.getItem(STORAGE_XML_BACKEND_PROTOCOL_KEY);
  let protocol = xmlBackendProtocol || window.location.protocol;
  protocol = protocol.includes('http') ? protocol : 'http:';
  return protocol;
}

/**
 * Gets the requested protocol in the payload and returns either 'http' or 'https', all other protocols default to 'http'
 * Electron will use 'file:' as a protocol which is not supported, so that is defaulted to 'http'
 * @param action {AppState['AppAction']}
 * @returns {AppState['protocol']}
 */
function getPayloadProtocol(action) {
  const protocol = action.payload === 'http:' || action.payload === 'https:' ? action.payload : 'http:';
  return protocol;
}

export { getPayloadProtocol, getXmlBackendProtocol };

export { default as i18n } from 'i18next';
export { initReactI18next } from 'react-i18next';
export { default as languageDetector } from 'i18next-browser-languagedetector';

var lang = {
	t0: "Actieve alarmen",
	t1: "Verwijd. alarmen",
	t2: "Host",
	t3: "ID",
	t4: "Naam apparaat",
	t5: "Wijzig notities",
	t6: "Geen autorisatie om alarmen aan te nemen",
	t7: "Alarmtype",
	t8: "Opgetr.",
	t9: "Bevestigen",
	t10: "Actieve alarmen",
	t11: "Verwijd.",
	t12: "Berekeningen",
	t13: "Opgeheven alarm(en)",
	t14: "Mute/aannemen",
	t15: "Aannemen alle niet aangenomen alarmen",
	t16: "Autorisatie mislukt!",
	t17: "Fout",
	t18: "Handmatige bediening",
	t19: "Visueel",
	t20: "Samenvatting data",
	t21: "Technische weergave",
	t22: "Grafiek (S4)",
	t23: "Node",
	t24: "Mod",
	t25: "Grafiek (Po &amp; Pc)",
	t26: "(geen alarmen gedetecteerd)",
	t27: "App. alarmen",
	t28: "Toon vorige regelaar",
	t29: "Toon volgende regelaar",
	t30: "Fout in lezen alarmen.",
	t31: "Huidige alarmen",
	t32: "Huidig alarm",
	t33: "Geen alarmen gedetecteerd.",
	t34: "Detail",
	t35: "Punt",
	t36: "Registratie",
	t37: "Schema's",
	t38: "Starttijd",
	t39: "Stoptijd",
	t40: "Dagn",
	t41: "Vakanties",
	t42: "Status",
	t43: "Selecteer datapunten",
	t44: "OK",
	t45: "Sluiten",
	t46: "Start",
	t47: "Einde",
	t48: "Startdatum",
	t49: "Interval",
	t50: "Geen datapunten geselecteerd",
	t51: "Nu",
	t52: "sec",
	t53: "min",
	t54: "uur",
	t55: "dag",
	t56: "Adrestype",
	t57: "Adres",
	t58: "Locatie:",
	t59: "Geef nieuwe waarde",
	t60: "Ververs",
	t61: "Selecteer datapunten",
	t62: "Selecteer nieuwe waarde",
	t63: "Uit",
	t64: "aan",
	t65: "Ophalen datapunten...",
	t66: "Fout in ophalen data...",
	t67: "Geen registratie geconfigueerd...",
	t68: "Fout in ophalen datapunten...",
	t69: "Ophalen registraties voor",
	t70: "Fout in ophalen registraties...",
	t71: "Gebruikersnaam",
	t72: "Wachtwoord",
	t73: "Host IP/URL adres:",
	t74: "OK",
	t75: "Sluiten",
	t76: "Naam",
	t77: "Waard",
	t78: "Fout in ophalen gebruikerslijst",
	t79: "Geef nieuw adres",
	t80: "Ophalen gebruikerslijst mislukt. Controleer Host IP/URL adres aub.",
	t81: "Aut. code",
	t82: "Acct code",
	t83: "Selecteer nieuwe tijd en datum",
	t84: "Selecteer nieuwe duur",
	t85: "Bewerking mislukt",
	t86: "Geef autorisatie informatie",
	t87: "HVAC",
	t88: "Start!",
	t89: "Ontdooiregeling",
	t90: "Start handontdooiing",
	t91: "Lichtregeling",
	t92: "Handm. licht aan",
	t93: "Handm. licht uit",
	t94: "Schoonmaakregeling",
	t95: "Handm. schoonmaak aan",
	t96: "Handm. schoonmaak uit",
	t97: "Hoofdschak.",
	t98: "Handm. hoofdschakelaar aan",
	t99: "Handm. hoofdschakelaar uit",
	t100: "Uitschakelen regeling",
	t101: "Schakel regelaar uit",
	t102: "Schakel regelaar aan",
	t103: "Dag/nachtregeling",
	t104: "Nachtverschuiving aan",
	t105: "Nachtverschuiving uit",
	t106: "Bericht verstuurd...",
	t107: "Handmatige bediening",
	t108: "Sluit",
	t109: "Verlichting",
	t110: "Verm/Overi",
	t111: "Overzicht",
	t112: "Selecteer AK-CS unit",
	t113: "Laden...",
	t114: "van",
	t115: "Alle AK-SC'en",
	t116: "Vorig",
	t117: "Pagina's",
	t118: "Volg.",
	t119: "Parameter",
	t120: "Metingen",
	t121: "Ophalen data...",
	t122: "Fout in lezen sectie",
	t123: "Temperatuur",
	t124: "Vertraging na ontdooiing",
	t125: "Alarm als onder",
	t126: "Alarm als boven",
	t127: "Bewaking",
	t128: "Offline",
	t129: "Actief",
	t130: "Host",
	t131: "Adres",
	t132: "Omschrijving",
	t133: "Huid. waarde",
	t134: "Huidige status",
	t135: "Regelaars",
	t136: "Unit",
	t137: "Actief alarm aanwezig",
	t138: "Actief alarm aanwezig!",
	t139: "Digitale ingangen",
	t140: "Relaisuitgang",
	t141: "Voeleringang.",
	t142: "Analoge uitg.",
	t143: "Regelaars",
	t144: "Overige",
	t145: "Visualisatiepunt",
	t146: "Visueel overzicht",
	t147: "Uitloggen uit systeem",
	t148: "Inloggen",
	t149: "Uitloggen",
	t150: "Koeling",
	t151: "Overzicht apparaten",
	t152: "Alarmen",
	t153: "Historie",
	t154: "CS-overzicht",
	t155: "Visueel overzicht",
	t156: "Configuratie",
	t157: "Mimic",
	t158: "Installatieoverzicht",
	t159: "Home",
	t160: "Alarm",
	t161: "Setpoint",
	t162: "App. detail",
	t163: "Instelling",
	t164: "Selecteer datum",
	t165: "Informatie",
	t166: "Logboek",
	t167: "Berekeningen",
	t168: "Bestandsbeheer",
	t169: "Hulp handleiding",
	t170: "Info",
	t171: "Vertalen",
	t172: "Opslaan naar server",
	t173: "Aanpassen taalbestanden?",
	t174: "Deze actie zal de taalbestanden op de unit wijzigen. Verder gaan?",
	t175: "Exporteren registratie",
	t176: "Selecteer nieuwe waarde",
	t177: "minimum",
	t178: "maximum",
	t179: "Geen actief alarm!",
	t180: "Niet te wijzigen!",
	t181: "Wachten aub...",
	t182: "Commando verstuurd!",
	t183: "Actie mislukt. Probeer opnieuw aub!",
	t184: "Actie gelukt!",
	t185: "Geen geautoriseerde actie!",
	t186: "Weet u zeker dat u deze actie wilt uitvoeren?",
	t187: "VERD",
	t188: "COMP-COND",
	t189: "Bewaken ingangen",
	t190: "OVERIG",
	t191: "Bevestigde alarmen",
	t192: "Opheffen aangenomen alarmen",
	t193: "Opheffen alle aangenomen alarmen",
	t194: "U bent niet geautoriseerd om alarmen aan te nemen",
	t195: "Niet geautoriseerd",
	t196: "Wilt u dit alarm aannemen?",
	t197: "Bevestig aanemen alarm",
	t198: "Eerst inloggen aub",
	t199: "Selecteer een alarm om op te heffen",
	t200: "Bevestig opheffen alarmen",
	t201: "Wilt u dit alarm opheffen?",
	t202: "Niet geautoriseerd om alarm op te heffen",
	t203: "Weet u zeker dat u alle alarmen wilt opheffen?",
	t204: "Geselecteerde alarmen kunnen niet opgeheven worden",
	t205: "Alarminstelling",
	t206: "Moet geautoriseerd zijn als Supervisor om deze actie uit te voeren",
	t207: "Geen data retour van unit",
	t208: "Selecteer een unit",
	t209: "Selecteer een unit uit de lijst aub",
	t210: "Aantal alarmen",
	t211: "Alarmstatus",
	t212: "Algemeen",
	t213: "Opheffen geselecteerd alarm",
	t214: "Bevestig aub",
	t215: "Verbinding met unit mislukt. Controleer netwerkverbinding aub.",
	t216: "Ontdooialarm",
	t217: "Digitaal alarm",
	t218: "Status digitale ingang",
	t219: "Ontdooi ingang",
	t220: "Schoonmaak ingang",
	t221: "Digitale ingang",
	t222: "Opslaan taalbestanden",
	t223: "Units",
	t224: "Geen apparaat geconfigureerd in systeem. Website uitgeschakeld.",
	t225: "Waarschuwing:",
	t226: "Selecteer tijd",
	t227: "Communicatie",
	t228: "Download rapportbestand?",
	t229: "Wachten aub tijdens genereren rapportbestand...",
	t230: "Download rapport",
	t231: "Alarmstatus/Service",
	t232: "Wachten aub ophalen data...",
	t233: "Ingelogd",
	t234: "Gebruiker uitgelogd",
	t235: "Gebruiker ingelogd",
	t236: "Welkom!",
	t237: "Ongeldige licentie",
	t238: "Energiemeter regelaars",
	t239: "Lichtpanelen",
	t240: "Frequentieregelaar",
	t241: "Compressormodule",
	t242: "Apparaat regelaarpunten",
	t243: "Berekende punten",
	t244: "Cutler-Hammer panelen",
	t245: "Carriers",
	t246: "Uitgebreide weergave",
	t247: "Dubbel-klik hier om in/uit te zoomen",
	t248: "Klik hier voor in/uitzoomen",
	t249: "Aangenomen alarm(en)",
	t250: "Simulator",
	t251: "Koeltaak",
	t252: "HVAC taak",
	t253: "Lichttaak",
	t254: "Overige taken",
	t255: "Netwerk verbonden",
	t256: "FreqR",
	t257: "Ref.",
	t258: "Grafiek (Freq.reg. Hz &amp; Amp)",
	t259: "Stopdatum",
	t260: "Stop",
	t261: "De stoptijd is op of voor de starttijd. Corrigeer aub de datum/tijd.",
	t262: "De creatietijd van datapunt(en) registratie",
	t263: "zijn later dan de gespecificeerde starttijd",
	t264: "Het aantal opgehaalde metingen voor ieder registratiepunt heeft de 100.000 bereikt. Het zou erg lang duren om op te halen. U kunt dit aantal verlagen door de interval te verlagen OF de tijdperiode te wijzigen.",
	t265: "Registratie database van unit is bezig. Wacht aub 2 minuten en probeer opnieuw...",
	t266: "Bewaar",
	t267: "Registraties",
	t268: "Eerste",
	t269: "Laatste",
	t270: "Print",
	t271: "Zoom uit",
	t272: "Zoom in",
	t273: "Toon/verberg legenda",
	t274: "Exporteer registraties naar bestand",
	t275: "Toon/verberg raster",
	t276: "Enkele pagina weergave",
	t277: "Multi pagina weergave",
	t278: "Laden registratiegroep van bestand",
	t279: "Laden registratie van bestand",
	t280: "Opslaan registratiegroep naar bestand",
	t281: "Multi pagina aantallen",
	t282: "Pagina",
	t283: "Selecteer een pagina voor weergave",
	t284: "Registratiebestand moet een Excel CSV formaat zijn!",
	t285: "Geen data retour gekregen. Controleer aub de registratie puntselectie en de geselecteerde datum/tijd.",
	t286: "Snapshot registraties",
	t287: "Historie volledige weergave registraties",
	t288: "Historie bestand weergave registratiebestand",
	t289: "Kies...",
	t290: "Deze actie exporteert de huidige registratieweergave naar een .csv bestand.",
	t291: "Kies een doelbestand aub.",
	t292: "Bestand",
	t293: "Laden registratiegroep...",
	t294: "Laden registratie..",
	t295: "Opslaan registratiegroep",
	t296: "Toon",
	t297: "Zoom",
	t298: "Ja",
	t299: "Nee",
	t300: "Haal configuratie op uit regelaar",
	t301: "Niet opnieuw vragen",
	t302: "Ophalen configuratie regelaar",
	t303: "Data opgehaald",
	t304: "Alarmniveau",
	t305: "Alarmactie",
	t306: "Bevestigd",
	t307: "Bewaar database op schijf",
	t308: "Bestandsnaam",
	t309: "Beschrijving database",
	t310: "Pad database",
	t311: "Versie",
	t312: "Tijd",
	t313: "Exporteer alarmen",
	t314: "Database bewaard in",
	t315: "Bewaren database mislukt, controleer pad en bestandsnaam!",
	t316: "Bestandsnaam onjuist, controleer de naam van de database",
	t317: "Exporteren alarmen succesvol. Kies een bestand voor de geëxporteerde alarmen",
	t318: "Kies de te exporteren alarmen",
	t319: "Ophalen alarminformatie uit de units",
	t320: "Beschrijving bestand",
	t321: "Omzetten .hst bestand naar .csv bestandsformaat",
	t322: "Stap 1: Kies het .hst bestand",
	t323: "Stap 2: Kies de datapunten en het interval",
	t324: "Stap 3: Kies het .csv doelbestand",
	t325: "Bewaar als...",
	t326: "Niet meer dan 6000 datapunten toegestaan bij deze functie",
	t327: "Datapunten geselecteerd",
	t328: "Niet meer dan 65.536 metingen per datapunt toegestaan, wijzig het interval en de tijdsperiode",
	t329: "Ophalen",
	t330: "Ophalen geregistreerde data uit de unit",
	t331: "Kies opgehaalde datapunten",
	t332: "Geen waarde",
	t333: "Ontbreekt",
	t334: "Gestopt",
	t335: "Maak een keuze aub",
	t336: "Kies de datapunten voor grafiek",
	t337: "Bewaar als .hst file",
	t338: "Downloaden data voltooid, kies een van volgende opties:",
	t339: "Kies maximaal 8 punten voor de grafiek",
	t340: "Bewaar de opgehaalde registraties in .hst bestand",
	t341: "Printoverzicht",
	t342: "Kies bewaren of printen van het overzichtsrapport",
	t343: "Kies het bestandsfomaat",
	t344: "Genereren van overzichtsbestand, moment aub...",
	t345: "Het gekozen databasebestand bestaat al, wilt u dit overschrijven?",
	t346: "Dashboard",
	t347: "Mimic overzicht",
	t348: "Help",
	t349: "Systeem",
	t350: "Kaart",
	t351: "Vertaalfunctie",
	t352: "Handm.",
	t353: "Opvragen alarms...",
	t354: "Aangepast",
	t355: "Model",
	t356: "Regeling",
	t357: "Olie",
	t358: "Temp",
	t359: "Maximaliseer",
	t360: "Herstel",
	t361: "Terug...",
	t362: "Comm mislukt!",
	t363: "Comm status OK",
	t364: "Comm bericht verzonden",
	t365: "Laden component",
	t366: "Laden configuratie component",
	t367: "Reg.temp.",
	t368: "Deze instelling is van kracht na het verversen van de registraties, wilt u verdergaan?",
	t369: "Laden, even geduld",
	t370: "datapunten",
	t371: "Geen unitnaam",
	t372: "Voorkeuren",
	t373: "Simulatorversie",
	t374: "Energie",
	t375: "Ga naar overzichtscherm",
	t376: "Systeemalarm of geen gerelateerd apparaat gevonden",
	t377: "Gelijktijdige schema's",
	t378: "Taalbestand ontbreekt.",
	t379: "Versie niet ondersteund",
	t380: "Geef een IP adres van een unit (vb 192.168.1.1)",
	t381: "Geef het IP adres van unit of URL link.",
	t382: "Details verbergen",
	t383: "Details tonen",
	t384: "Buitentemp.",
	t385: "Retour",
	t386: "Aanvoer",
	t387: "Vochtigheid",
	t388: "Dauwpunt",
	t389: "Vent.",
	t390: "Zone",
	t391: "Uitschak.",
	t392: "Splitsen",
	t393: "Zuigtemp.",
	t394: "Huidige capaciteit",
	t395: "Zuigdruk",
	t396: "Pers",
	t397: "Compressoren",
	t398: "Condensor",
	t399: "Condensor setpoint",
	t400: "Koudemiddel",
	t401: "Zuiggas setpoint",
	t402: "Zuig",
	t403: "Ontd.",
	t404: "Danfoss registratieformaat",
	t405: "Tekstformaat",
	t406: "Deze opdracht vereist het herstarten van de unit, wilt u nu de unit herstarten?",
	t407: "Herstarten gelukt, ververs de website",
	t408: "Fabrieks applicatiemode",
	t409: "Berekenen...",
	t410: "minuten",
	t411: "Nachtverlaging",
	t412: "Meubelverl.",
	t413: "Gelijktijdig schema",
	t414: "(Resolutie 10 minuten)",
	t415: "Toon tekst",
	t416: "Exporteren als CSV",
	t417: "Bewaar PNG",
	t418: "Toon grafiek",
	t419: "Toon tekst",
	t420: "Schema",
	t421: "Duur",
	t422: "Stoptemp.",
	t423: "GEEN",
	t424: "Regeltemp.",
	t425: "Loopt vanaf",
	t426: "Laden database van",
	t427: "Statistieken",
	t428: "Kies bestand",
	t429: "Kies het registratiebestand om naar .CSV formaat te converteren",
	t430: "Voltooien",
	t431: "Samenv.",
	t432: "Bestanden registraties (*.hst)",
	t433: "Registratie conversie wizard",
	t434: "Aanmaak bestand voltooid",
	t435: "Kies de datapunt voor conversie naar CSV formaat",
	t436: "Selecteer tijdinterval",
	t437: "Kies de periode en druk de opdrachtknop...",
	t438: "Bewerken gegevens",
	t439: "Prepareren registratiedata, moment aub",
	t440: "Kies CSV bestandsnaam en sla op naar bestand...",
	t441: "Kan het registratiedatapunt niet vinden",
	t442: "Fout - Geen registraties retour",
	t443: "Een communicatiefout opgetreden op het host network. Gevonden",
	t444: "Let op!",
	t445: "Stilte",
	t446: "Database niet bewaard, wilt u echt het scherm sluiten?",
	t447: "Toon tabel",
	t448: "Toon grafiek",
	t449: "Toon tabel",
	t450: "Handontdooiing AAN",
	t451: "Handontdooiing UIT",
	t452: "Autom. ontdooiing",
	t453: "Instellen ontdooiregeling",
	t454: "Cap.klep aan",
	t455: "Cap.klep. uit",
	t456: "Vloeistof",
	t457: "Geen schema's geconfigureerd",
	t458: "StoreView",
	t459: "Communicatie verloren met een unit op het host netwerk!",
	t460: "Snelinstelling",
	t461: "Straat",
	t462: "Stad",
	t463: "Telef",
	t464: "Regio",
	t466: "Index",
	t469: "Adresboek succesvol geexporteerd",
	t470: "Verwijder item",
	t471: "Gauge",
	t472: "Let op: hoofdlettergevoelig",
	t473: "Online",
	t474: "RO board",
	t475: "SI board",
	t476: "OI board",
	t477: "Berekening",
	t478: "Huidige tijd",
	t479: "Rack",
	t480: "Geen compressor",
	t481: "Circuit",
	t482: "Koelobj.",
	t483: "Onbekend",
	t484: "Fouttype:",
	t485: "Toev",
	t486: "Kopie",
	t487: "Verwijder",
	t488: "Verdamperregelaars",
	t489: "Meubeltype",
	t490: "LON",
	t491: "Geef aantal voor kopie",
	t492: "geen naam",
	t493: "Configureren...",
	t494: "Configuratiefout",
	t495: "Controleer configuratie aub.",
	t496: "De configuratie is succesvol afgesloten. Druk OK om deze wizard af te sluiten.",
	t497: "Configuratiestatus",
	t498: "Definitieve configuratie",
	t500: "Code #",
	t501: "Netwerkconfiguratie",
	t502: "Systeeminstellingen",
	t503: "Licenties",
	t504: "Gebuikers",
	t505: "Niet geautoriseerd",
	t506: "Controleer aub...",
	t507: "Weet u zeker dat u dit scherm wilt verlaten? Uw instellingen kunnen verloren gaan.",
	t508: "Scannen netwerk...",
	t509: "Scannen",
	t510: "Controle",
	t511: "AK-I/O",
	t512: "Scannen",
	t513: "Inclusief offline programmeren",
	t514: "Comm. bus",
	t515: "Online regelaars",
	t516: "Offline programmeren",
	t517: "Dubbel adres",
	t518: "Compressor-condensorregelaars",
	t519: "Zuiggroepen",
	t520: "N/A",
	t521: "Beschikbare verdamperregelaars",
	t522: "Multi-circuit creatie toestaan (Alleen voor I/O)",
	t523: "Invoegen apparaten",
	t524: "Invoegen circuit",
	t525: "Multi-verdamper circuit.",
	t526: "Laden tabellen...",
	t527: "Opvragen alarmdata voor",
	t528: "Registratiegroep bestanden",
	t529: "Registratiedata bestanden",
	t530: "Alle bestanden",
	t531: "Toon symbolen",
	t532: "Toon kromming",
	t533: "Onvoldoende data is opgehaald.",
	t534: "Haal de laatste registratiedata op.",
	t535: "Status/Instellingen",
	t536: "Nu downloaden",
	t537: "registratie samples...",
	t538: "Ophalen",
	t539: "Datapunten...",
	t540: "Interval",
	t541: "Min",
	t542: "Max",
	t543: "Gemid.",
	t544: "Ga naar hoofd alarmscherm.",
	t545: "Stop auto-inloggen",
	t546: "Verbinden met",
	t547: "Sluiten auto-inlog",
	t548: "Geef aub uitlog wachtwoord",
	t549: "Web 1",
	t550: "Web 2",
	t551: "Automatisch inloggen",
	t552: "Uitloggen na inactieve periode",
	t553: "Thuisscherm",
	t554: "Toon configuratiemenu",
	t555: "Toon overige items op dashboard",
	t556: "Gebruik volledig scherm",
	t557: "Alarmgeluid aan",
	t558: "Programma sluiten/minimaliseren toestaan",
	t559: "Fout verbinden met unit #",
	t560: "Verbonden met netwerk.",
	t561: "Auto-inlog uitgeschakeld",
	t562: "Opnieuw verbinden met",
	t563: "Uitgelogd uit",
	t564: "Verbonden",
	t565: "Tijd format",
	t566: "Datumweerg.",
	t567: "Druk",
	t568: "Lichtnivo",
	t569: "Ond.koel.",
	t570: "Onderkoel.",
	t571: "Dagelijks",
	t572: "Weeklijks",
	t573: "HST bestand",
	t574: "CSV bestand",
	t575: "Zondag",
	t576: "Maandag",
	t577: "Dinsdag",
	t578: "Woensdag",
	t579: "Donderdag",
	t580: "Vrijdag",
	t581: "Zaterdag",
	t582: "Ophalen registraties",
	t583: "Autom. ophalen aan",
	t584: "Wanneer ophalen",
	t585: "Lokatie opslaan bestand",
	t586: "Selecteer bestandsformat",
	t587: "Selecteer map voor opslaan registratiebestanden...",
	t588: "Activeer automatisch ophalen om dagelijkse of wekelijkse registraties te zien van de geconfigureerde punten.",
	t589: "Om HST historiebestanden te zien, ga naar het hoofd historiescherm en selecteer importeer historie. Om CSV bestanden te zien, gebruik ieder spreadsheet programma.",
	t590: "Als het huidig verbonden IP adres in het adresboek wordt gevonden, worden de geselecteerde historiepunten opgeslagen.",
	t591: "Selecteer datapunten",
	t592: "Alles geselecteerd",
	t593: "Geselecteerde punten",
	t594: "Zoek",
	t595: "Autom. verbinden met",
	t596: "Controleer aub",
	t597: "Automatisch ophalen is geactiveerd. Weet u zeker dat u dit programma wilt sluiten?",
	t598: "Weet u zeker dat u dit programma wilt sluiten?",
	t599: "Actie",
	t600: "filter",
	t601: "Alle acties",
	t602: "Meer informatie...",
	t603: "Wijzigbaar",
	t604: "Actie",
	t605: "Handeling gestopt",
	t606: "Downloaden database afgerond",
	t607: "Database van unit ge-update",
	t608: "Bestand(en) sucesvol naar unit verzonden",
	t609: "Downloaden afgerond, dit bestand wordt nu in de unit verwerkt",
	t610: "Fout opgetreden tijdens uploaden bestand naar PC, controleer of het bestand schrijfbaar en toegangkelijk is",
	t611: "Uploaden bestand",
	t612: "Downloaden bestand",
	t613: "Uploaden geselecteerd(e) bestand(en)",
	t614: "Downloaden geselecteerd(e) bestand(en)",
	t615: "Verwijderen bestand",
	t616: "Verwijderen geselecteerd(e) bestand(en)",
	t617: "Fout FTP verbinding",
	t618: "Download geselecteerd(e) bestand(en) naar de unit",
	t619: "Fout opgetreden in FTP verbinding. Controleer de FTP autorisatie en wachtwoord/gebruikersnaam.",
	t620: "FTP verbonden",
	t621: "Bytes",
	t622: "Bestanden op de unit worden door deze actie overschreven, wilt u doorgaan?",
	t623: "Bestanden op de PC worden door deze actie overschreven, wilt u doorgaan?",
	t624: "Bestanden zijn ge-download naar de unit.",
	t625: "Alleen koeling",
	t626: "Buurtwinkel",
	t627: "Volledige functionaliteit",
	t628: "Alleen HVAC",
	t629: "Bestandsnaam is ongeldig; zorg ervoor dat de naam ten minste 8 karakters heeft.",
	t630: "Nu applicatiecode overschrijven?",
	t631: "Later",
	t632: "uploaded",
	t633: "kies Ja voor het nu overschrijven van de applicatiecode of kies Later",
	t634: "Selecteer aub de simulator root directory",
	t635: "Selecteer aub de windows directory",
	t636: "Selecteer aub de doel directory",
	t637: "Selecteer database directory",
	t638: "FTP verbroken",
	t639: "Time out FTP verbinding, FTP verbroken",
	t640: "Upload geselecteerd(e) bestand(en) naar PC",
	t641: "Verwijder geselecteerd(e) bestand(en)",
	t642: "FTP server poort",
	t643: "Webserver poort",
	t644: "Lokaal pad",
	t645: "Omhoog",
	t646: "Grootte",
	t647: "Datum",
	t648: "Verbinden",
	t649: "Verbreken",
	t650: "Remote pad",
	t651: "Vrij ruimte beschikbaar:",
	t652: "Geselecteerde bestand(en)",
	t653: "Bestanden",
	t654: "Totale grootte:",
	t655: "Klik om map te wijzigen...",
	t656: "Wijzig...",
	t657: "Wilt u de geselecteerde bestanden verwijderen?",
	t658: "Verbinding gesloten, sluiten",
	t659: "De geselecteerde bestanden (bestand), namen (naam), zijn (is) illegaal en de download wordt genegeerd.",
	t660: "Sommige van de geselecteerde bestandsnamen zijn illegaal en genegeerd.",
	t661: "Bestand(en) succesvol naar PC verzonden",
	t662: "Niet voldoende vrije ruimte op de unit.",
	t663: "Bestand(en) versturen",
	t664: "Aangepast",
	t665: "Kopieer bestanden van flash disk naar RAM disk",
	t666: "Selecteer een bestand kopieer actie:",
	t667: "Kopieer alle geconfigureerde bestanden van flash disk",
	t668: "Kopieer alle EDF bestanden van flash disk",
	t669: "De edf/ed3 bestanden worden opgeslagen op de flash disk, maar worden niet geladen in het Bestand Management scherm. Gebruik de onderstaande opties om de opgeslagen EDF bestanden in uw unit naar het Bestand Management scherm te kopieren.",
	t670: "Deze actie kopieert alle in de unit geconfigureerde EDF bestanden van een flash disk naar RAM disk.",
	t671: "Deze actie kopieert alle EDF bestanden in de unit van flash disk naar RAM disk",
	t672: "Geen geldige EDF bestanden bestaan",
	t673: "Kopieren EDF bestanden afgerond",
	t674: "bestanden gekopieerd",
	t675: "Bevestig database download",
	t676: "Unit adres",
	t677: "IP-adres/Hostnaam",
	t678: "Bestandsnaam database",
	t679: "Kies",
	t680: "Update compleet, resetten en upgraden unit. Dit kan enige minuten duren.",
	t681: "Welkom tot upgrade manager - Stap 1 van 2",
	t682: "Welkom tot upgrade manager - Stap 2 van 2",
	t683: "Verder",
	t684: "Pre-updrade controle",
	t685: "Lokaliseren firmware",
	t686: "Backup maken database?",
	t687: "Backup maken database",
	t688: "Overbrengen software",
	t689: "Overschrijven software",
	t690: "Selecteer software voor upgrade",
	t691: "Geen fabrieks fail-safe toepassingen gedetecteerd.",
	t692: "U kunt verder gaan, maar door fouten tijdens het updateprocess kan het systeem niet meer goed werken.",
	t693: "Het wordt ten strengste aanbevolen om niet verder te gaan.",
	t694: "Wilt u verder gaan?",
	t695: "Het op afstand upgraden van een systeem brengt een zeker risico met zich mee, dat kan resulteren in een systeemfout.",
	t696: "Disclaimer",
	t697: "Uploaden database afgerond",
	t698: "Backup maken van database afgerond",
	t699: "Downloaden applicatie afgerond",
	t700: "Overschrijven applicatie afgerond",
	t701: "Software upgrade succesvol",
	t702: "Wilt u de unit resetten naar fabrieksinstelling?",
	t703: "Controleren hardware",
	t704: "Controleren apparaat status",
	t705: "Fabrieks fail safe",
	t706: "Huidige firmware versie",
	t707: "Start upgrade",
	t708: "Selecteer een bestaand database bestand",
	t709: "Selecteer een bestaand firmware bestand",
	t710: "Terug",
	t711: "Lokaliseren firmware",
	t712: "Selecteer .csi bestand",
	t713: "Selecteer .mai bestand",
	t714: "Remote downloaden wordt niet ondersteund door dit CPU board.",
	t715: "Downloaden applicatie mislukt",
	t716: "Uploaden databse mislukt! Probeer opnieuw aub.",
	t717: "Overschrijven applicatie afgerond",
	t718: "Overschrijven applicatie mislukt! Probeer opnieuw aub.",
	t719: "Overschrijven applicatie mislukt",
	t720: "Geen fabrieks fail-safe toepassingen gedetecteerd.",
	t721: "Klaar",
	t722: "De geselecteerde applicatie kan een andere versie zijn dan de huidige firmware van de unit. Doorgaan met deze actie kan resulteren in een systeemfout.",
	t723: "De geselecteerde bestanden (bestand), namen (naam), zijn (is) illegaal en de download wordt genegeerd.",
	t724: "Sommige van de geselecteerde bestandsnamen zijn illegaal en genegeerd.",
	t725: "Unit's berg pin is missing.",
	t726: "Batterij van unit bijna leeg",
	t727: "Unit's host network failed.",
	t728: "Unit is in load shed status",
	t729: "Selecteer bestemming bestand",
	t730: "Selecteer directory bestemming",
	t731: "Uploaden database afgerond",
	t732: "Status OK",
	t733: "Status fout",
	t734: "Status onbekend",
	t735: "Deze actie kan de geselecteerde bestanden in de doel simulator overschrijven, wilt u doorgaan?",
	t736: "Downloaden database afgerond, herstart nu het systeem",
	t737: "Downloaden database mislukt, probeer later opnieuw",
	t738: "De unit zal automatisch resetten na deze actie. Wilt u verder gaan?",
	t739: "Selecteer database",
	t740: "Downloaden databasebestand?",
	t741: "Uploaden databasebestand?",
	t742: "Laden database naar de unit, wachten aub",
	t743: "Database succesvol geladen, unit wordt nu herstart",
	t744: "Updaten database afgerond, reset unit succesvol",
	t745: "Updaten database mislukt! Probeer opnieuw aub.",
	t746: "Fout in updaten database",
	t747: "Pre-download controle",
	t748: "Lokaliseer database",
	t749: "Dowloaden database",
	t750: "Huidige database informatie",
	t751: "Selecteer database voor download",
	t752: "Opslaan database naar PC (upload)",
	t753: "Laad database naar de unit (download)",
	t754: "Supervisor autorisatie benodigd",
	t755: "Start upload",
	t756: "Selecteer aan database actie:",
	t757: "Start download",
	t758: "Huidige firmware versie",
	t760: "Download database succesvol!",
	t761: "Welkom tot de database manager - Stap 1 van 2",
	t762: "Welkom tot de database manager - Stap 2 van 2",
	t763: "Verbinden met unit mislukt. Controleer aub Host IP/URL adres",
	t764: "Het op afstand upgraden van de database van een systeem brengt een risico met zich mee wat kan resulteren in een systeemfout.",
	t765: "Downloaden database afgerond",
	t766: "Ethernet scan",
	t767: "Geef eerste IP adres en druk op start",
	t768: "Scan",
	t769: "Scannen",
	t770: "Ophalen registraties",
	t771: "Analoge grafiek kleuren",
	t772: "Digitale grafiek kleuren",
	t773: "Uitschakel schema's",
	t774: "Ontdooi-overzicht",
	t775: "Nachtverlaging schema's",
	t776: "Meubelverlichting schema's",
	t777: "Laden status",
	t778: "Alarm ophalen",
	t779: "Selecteer map voor opslaan alarmbestanden...",
	t780: "Maandlijks",
	t781: "Activeer automatisch alarmen ophalen om periodiek alarminformatie op te halen en op te slaan naar een bestand.",
	t782: "CSV scheidingsteken",
	t783: "Geen alarmen",
	t784: "Ophalen alarmtelling voor unit:",
	t785: "Ophalen alarmdetails van unit:",
	t786: "Automatisch alarm ophalen geactiveerd. Weet u zeker dat u dit programma wilt sluiten?",
	t787: "Uurlijks",
	t788: "This will update the unit's database and set the location to the center of the current view. Proceed?",
	t789: "samples processed",
	t790: "Configureer een zuiggroep aub.",
	t791: "Om de Netwerkwizard vaker te gebruiken moet de koeling database gereset worden.",
	t792: "Do this via the Configuration-&gt;Control page and set the number of Racks/Packs to zero.",
	t793: "Let op: aanwezige schema's en berekeningen gebaseerd op een eerdere koelconfiguratie zullen opnieuw geconfigureerd moeten worden.",
	t794: "Kopieer proces afgerond.",
	t795: "Koel rapport",
	t796: "Final HVAC Layout",
	t797: "De HVAC layout instelling is succesvol afgerond. Druk op OK om de wizard af te sluiten.",
	t798: "Configureren HVAC layout...",
	t799: "Om de HVAC wizard nog een keer te gebruiken moet de HVAC database layout worden gereset.",
	t800: "Do this via the Configuration-&gt;Control page and set the number of HVAC units to zero.",
	t801: "Let op: aanwezige schema's en berekeningen gebaseerd op een eerdere HVAC configuratie zullen opnieuw geconfigureerd moeten worden.",
	t802: "Step mode",
	t803: "Stap.",
	t804: "Nivo",
	t805: "Show Multiple DataTip",
	t806: "Aantal stappen",
	t807: "Bestand kon niet opgeslagen worden.",
	t808: "Incorrect model",
	t809: "Laden succesvol.",
	t810: "Open",
	t811: "Deze functie is alleen beschikbaar in de desktop versie van StoreView.",
	t812: "Verder",
	t813: "Verlaat wizard",
	t814: "Succesvol. Wilt u verder gaan of wilt u de wizard verlaten?",
	t815: "Error while fetching alarm logger data.",
	t817: "Apparaat naam:",
	t819: "Bezig...",
	t820: "Alarm data niet aanwezig",
	t821: "Aantal alarmen",
	t822: "Mimic opties",
	t823: "Toon alleen tekst",
	t824: "Toon alleen icoon",
	t825: "Toon tekst en icoon",
	t826: "1 week",
	t827: "2 weken",
	t828: "3 weken",
	t829: "4 weken",
	t830: "Grafische instellingen",
	t831: "Unable to display alarm diagnostics, please check auto alarm collection under preferences",
	t832: "Reordering not allowed with IO racks or circuits included in configuration.",
	t833: "This Host IP/URL Address is already present in address book. Do you like to update that entry?",
	t834: "Bladeren",
	t835: "Selecteer adresboek map",
	t836: "Locatie",
	t837: "Selected location is not having any address book contents.Would you like to export contents of the existing address book to the selected location?",
	t838: "Alarmactie codes",
	t839: "Address book file is not found in the selected path. Address book is loaded from local...",
	t840: "Selecteer een locatie voor opslaan registraties.",
	t841: "Selecteer een geldige locatie voor opslaan registraties.",
	t842: "Alarmhistorie ophalen is bezig…",
	t843: "Ophalen registraties is bezig….",
	t844: "Werk op achtergrond",
	t845: "Alarmhostorie voortgang",
	t846: "Host IP/URL adres mag niet leeg zijn",
	t847: "Adresveld mag niet leeg zijn",
	t849: "You don't have latest version of the file, would you like to load data from the latest version?",
	t850: "Version conflict",
	t851: "Registratie voortgang",
	t852: "User Name and Password fields should not be blank.",
	t853: "IP Address field should not be blank.",
	t854: "Host IP/URL Address field should not be blank.",
	t855: "User name should not be blank.",
	t856: "Password should not be blank.",
	t857: "The SM800 you have connected to with",
	t858: "has not had the Initial Startup Wizard completed. Please complete this task and reconnect to the SM800",
	t859: "Set to now",
	t860: "Invalid input",
	t861: "Text is too long",
	t862: "Offset",
	t863: "Unable to communicate with unit(s) on host network",
	t864: "Please verify addresses are different. Configuration is updated when the save button is pressed.",
	t865: "Please select a valid History File (.hst)",
	t866: "Please select a valid History Group File (.grp)",
	t867: "Kleur",
	t868: "Percentage",
	t869: "Spanning",
	t870: "Huidige",
	t871: "Macht",
	t872: "Frequentie",
	t873: "Concentratie",
	t874: "Stroom",
	t875: "Snelheid",
	t876: "Zuurgraad",
	t877: "Selecteer grafieken onder lade",
	t878: "PNG opslaan",
	t879: "Tekenen",
	t880: "Systeemstatus...",
	t881: "Systeem- en eenheidsstatus",
	t882: "Gataway Gataway",
	t883: "Licenties voor functies",
	t884: "Softwareversie",
	t885: "Versie van browsermodule",
	t886: "Danux-versie",
	t887: "Mac-adres",
	t888: "Ondersteuning & updates",
	t889: "Eenheidsnaam",
	t890: "Sluit",
	t891: "Verloren verbinding",
	t892: "Er is geen {{type}} apparatuur gevonden.",
	t893: "De momenteel gebruikte softwareversie wordt niet ondersteund",
	t894: "Snelle koppelingen",
	t895: "Apparatuur",
	t896: "Graphing",
	t897: "Utilities",
	t898: "Terugdraaien van software",
	t899: "Diagnostics",
	t900: "Er is een fout opgetreden",
	t901: "Geen {{alarmen}} gevonden gegevens",
	t902: "Instellingen",
	t903: "Toepassingsnaam",
	t904: "Toepassingsversie",
	t905: "Bericht",
	t906: "Gebeurtenistype",
	t907: "Tijdstempel",
	t908: "Terugdraaienversie",
	t909: "Huidige versie",
	t910: "Software",
	t911: "Terugdraaien...",
	t912: "Selecteer apparatuur uit de lijst",
	t913: "Agendaweergave",
	t914: "Er is een fout opgetreden tijdens het laden van apparaten(en), probeer het opnieuw.",
	t915: "Er is een fout opgetreden tijdens het laden van schema's, probeer het opnieuw.",
	t916: "Geen apparatuur gevonden",
	t917: "Schema's exporteren",
	t918: "Kies CSV- of PDF-exporttype",
	t919: "Exporteren als CSV",
	t920: "PDF voorbereiden",
	t921: "Exporteren als PDF",
	t922: "Geef een IP in dit formaat xx.xx.xx.xx[:xxxx]",
	t923: "Gebruikersnaam",
	t924: "Login",
	t925: "Terugdraaien",
	t926: "Terugdraaien SM800A-software",
	t927: "Met deze actie wordt alle software teruggedraaid naar de vermelde versies.",
	t928: "Party Device Editor",
	t929: "tekst die de functie uitlegt",
	t930: "CDF bijwerken",
	t931: "Kaartapparaat",
	t932: "Apparaatbestand",
	t933: "1-gebaseerd registernummer",
	t934: "Opslagtype",
	t935: "Min",
	t936: "Max",
	t937: "Alleen lezen",
	t938: "Log",
	t939: "Berichttype",
	t940: "Conversie-trefwoord",
	t941: "Parameters",
	t942: "Addr.",
	t943: "Prioriteit",
	t944: "Beetje",
	t945: "Ontkend",
	t946: "Nieuw bestand",
	t947: "Bestand openen",
	t948: "Bestand genereren",
	t949: "Groep",
	t950: "Productversie",
	t951: "Fout opgetreden",
	t952: "Niet gevonden",
	t953: "Dit apparaat is niet gevonden",
	t954: "Buiten temp.",
	t955: "Geen {{equipment}} gevonden",
	t956: "Gevallen",
	t957: "Alarm met succes dempen/handelen",
	t958: "Dempen/handelen is mislukt voor dit alarm",
	t959: "Er is een fout opgetreden tijdens het laden van eenheden(en), probeer het opnieuw.",
	t960: "Geen apparatuur",
	t961: "Geen {{type}} apparatuur gevonden",
	t962: "De systeembeheerder configureren",
	t963: "Apparaat",
	t964: "Ongedaan maken",
	t965: "U hebt de waarde van het veld gewijzigd, wilt u de wijzigingen opslaan?",
	t966: "Wis geselecteerde geschiedenispunt(en)",
	t967: "Shr {{number}}",
	t968: "Hr pompsnelheid",
	t969: "Sd",
	t970: "V3hr",
	t971: "HR-referentie",
	t972: "Stw {{number}}",
	t973: "Tw-referentie",
	t974: "Tw pompsnelheid",
	t975: "V3tw",
	t976: "Trec",
	t977: "Prec",
	t978: "Vrec OD",
	t979: "Prec-referentie:",
	t980: "Trec-referentie",
	t981: "Pc",
	t982: "ss",
	t983: "Vhp OD",
	t984: "Pgc-referentie",
	t985: "Uitwerper OD",
	t986: "Pgc",
	t987: "Shp",
	t988: "Sgc",
	t989: "Sc3",
	t990: "Sgc ctrl.",
	t991: "Cond. capaciteit",
	t992: "Po",
	t993: "ss",
	t994: "Sd",
	t995: "pc",
	t996: "Hardloopcapaciteit:",
	t997: "Browsercertificaat",
	t998: "Uitloggen",
	t1000: "Alleen sorteren op {{count}}",
	t1001: "{{count}} van {{total}} alarmen geladen",
	t1002: "Bevestiging geslaagd voor alle alarmen",
	t1003: "Bevestiging mislukt voor alle alarmen",
	t1004: "Slechts {{count}} van {{total}} alarmen bevestigd",
	t1005: "Export mislukt voor alle alarmen",
	t1006: "Exporteren gelukt",
	t1007: "Export mislukt, er is minimaal één alarmtype vereist",
	t2000: "-",
	t2001: "Reactie is te groot voor de XML-transmissiebuffer die 32K bytes is",
	t2002: "Geen attributen geassocieerd met cmd-element of commando heeft geen \"cmd\"-element of kan niet naar een variabele schrijven of kan geen variabele lezen of kan geen punt lezen of kan niet schrijven aan/uit-ingang of relaisuitgang bedrijfsmodus",
	t2003: "Slechte opdrachtinvoer",
	t2004: "Fout in CGI-postparameters",
	t2005: "Fout bij het converteren van het XML-antwoord van de interne weergave naar de XML-tekenreeks voor het antwoord",
	t2006: "Fout bij decoderen van URL-tekenreeks",
	t2007: "Geheugenoverloop",
	t2008: "Compressie van responsgegevens mislukt",
	t2009: "Fout bij parseren van verzoek",
	t2010: "Fout bij gegevenstoegang - een aantal oorzaken die niet verder zijn opgelost",
	t2011: "Ongedefinieerde opdracht actie attribuut waarde Attribuut waarde is ongeldig",
	t2012: "Fout in alarmreferentienummer of alarm niet geconfigureerd",
	t2013: "De ingevoerde cgi-parameterreeks is te lang.",
	t2014: "Apparaatadres is niet gevonden",
	t2015: "Machtiging om te schrijven is mislukt",
	t2016: "Geen geschiedenis geconfigureerd",
	t2017: "Geen geschiedenisgegevens geretourneerd, kan een tijdfout zijn",
	t2018: "Bestandsfouten maken",
	t2019: "Bestandsfouten lezen",
	t2020: "Maximale grootte van apparatenlijst overschreden",
	t2021: "niet gebruikt",
	t2022: "Ongeldige geschiedenisindex",
	t2023: "Kan bordpuntindex van nodetype,node,mod en point niet vinden.",
	t2024: "Kan geschiedenisindex niet vinden",
	t2025: "Geschiedenisquery is niet geïnitialiseerd voordat u een geschiedenisquery probeerde te starten.",
	t2026: "Er is een hq_start_query geprobeerd terwijl een geschiedenisquery actief is",
	t2027: "Geen opdrachtkenmerken gevonden wanneer sommige worden verwacht",
	t2028: "Een begin van een tijdperk is voorzien zonder einde of omgekeerd",
	t2029: "Kon de tijd- en datumnotatie niet converteren naar epoch-seconden",
	t2030: "Opdracht vereist een query_id maar geen verstrekt",
	t2031: "Fout bij opstellen XML-antwoord",
	t2032: "De opgegeven query_id komt niet overeen met een actieve zoekopdracht",
	t2033: "Er is geprobeerd een hq_get_data-opdracht uit te voeren, maar er zijn nog geen gegevens beschikbaar",
	t2034: "Er is een samplefrequentie opgegeven die niet is toegestaan",
	t2035: "Het middelingsinterval is kleiner dan of gelijk aan de samplefrequentie waarmee gegevens zijn vastgelegd",
	t2036: "De stop-epoch-tijd is korter dan de start-epoch-tijd of de stop-epoch-tijd minus de start-epoch-tijd is korter dan het middelingsinterval",
	t2037: "De opgegeven device_id komt niet overeen met de ID die momenteel bekend is bij de ak255. Merk op dat alleen apparaten die zijn geconfigureerd bekend zijn bij de ak255",
	t2038: "Geen ID-kenmerken",
	t2039: "Ongeldige ID-kenmerken",
	t2040: "Geen schemanummer",
	t2041: "Ongeldig schemanummer",
	t2042: "Geen schemadetails",
	t2043: "On_time ontbreekt",
	t2044: "Ontbrekend uur",
	t2045: "Ongeldig uur",
	t2046: "ontbrekende minuut",
	t2047: "Ongeldige minuut",
	t2048: "Vrije tijd ontbreekt",
	t2049: "Ontbrekende weekdagen",
	t2050: "Ongeldige weekdagen",
	t2051: "ontbrekende feestdagen",
	t2052: "Ongeldige feestdagen",
	t2053: "Ontbrekende of ongeldige holiday_start",
	t2054: "Ontbrekende of ongeldige vakantie_end",
	t2055: "Ontbrekende of ongeldige holiday_open",
	t2056: "Ontbrekende of ongeldige holiday_close",
	t2057: "Vakantie niet geconfigureerd",
	t2058: "Ontbrekende beschrijving",
	t2059: "Kan alarm niet wissen",
	t2060: "Ontbrekende of ongeldige store_open",
	t2061: "Ontbrekende of ongeldige store_closed",
	t2062: "Alarm niet geconfigureerd",
	t2063: "Knooppunt offline",
	t2064: "Ontbrekende index",
	t2065: "Ongeldige index",
	t2066: "Relais kan niet op tijd worden gezet",
	t2067: "De naam die wordt gewijzigd, bevat te veel tekens. Als het wijzigen van de winkelnaam, of eenheidsnaam, < = 16 tekens moet zijn, als het wijzigen van winkel-ID1 of winkel-ID2 < = 8 tekens moet zijn.",
	t2068: "Ontbrekende elementen die zijn gekoppeld aan het commando-element.",
	t2069: "Tag_ID niet gedefinieerd",
	t2070: "Ontbrekend bladknooppunt voor gegevens",
	t2071: "Geen wijziging in gegevens",
	t2072: "Kan database niet updaten",
	t2073: "Niet beschrijfbaar",
	t2074: "Storing meterkast",
	t2075: "Geen meter",
	t2076: "Ongeldig schuurniveau",
	t2077: "Ongeldig type",
	t2078: "Systeem bezet",
	t2079: "Activa niet geconfigureerd voor loadshed",
	t2080: "Ongeldige vermogensclassificatie voor activa",
	t2081: "Ongeldige opstartvertraging voor activa",
	t2082: "Configuratie bezet",
	t2083: "I/O-scannen bezig",
	t2084: "Te veel ontdooiingen bezig",
	t2085: "Proberen een slechte databaseversie in unit te laden",
	t2086: "Laden van database mislukt",
	t2087: "Geen beschikbaar webgeheugen beschikbaar",
	t2088: "Vraagrespons niet geconfigureerd (alleen het AK355-systeem)",
	t2089: "Ontbrekende of onjuiste bestandsgrootte.",
	t2090: "Ontbrekend gecodeerd bestand (b64-elementen)",
	t2091: "Opslaan van database op RAM-schijf is mislukt.",
	t2092: "Bestandsgrootte te groot.",
	t2093: "Ontbrekende bestandsnaam.",
	t2094: "Ontbrekende bestands-ID.",
	t2095: "Ontbrekende bestandslaadindex (Opmerking: dit is de volgorde-index voor load_file load_database).",
	t2096: "Bestands-ID komt niet overeen of ontbreekt.",
	t2097: "Bewerking voor het laden van bestanden is al bezig.",
	t2098: "Gedecodeerd bestand komt niet overeen met de oorspronkelijke bestandsgrootte.",
	t2099: "De bewerking voor het laden van bestanden is geannuleerd of niet gestart.",
	t2100: "Ongeldig gebruikersverzoek (Oorspronkelijke aanvrager komt niet overeen met IP-adres)",
	t2101: "Ongeldige laadvolgorde voor bestanden.",
	t2102: "Ongeldige map",
	t2103: "Ongeldige extensie",
	t2104: "Ongeldige offset",
	t2105: "Fout bij het coderen van bestand",
	t2106: "Fout bij decoderen bestand",
	t2107: "Kan bestand niet openen voor lezen/schrijven",
	t2108: "Kan niet naar bestand schrijven",
	t2109: "Bestand is beveiligd tegen schrijven in Flash-bestandssysteem",
	t2110: "Kan bestand niet verwijderen.",
	t2111: "Kan map niet verwijderen.",
	t2112: "Ontbrekende apparaten",
	t2113: "Niet alle apparaten van de URL zijn gevonden",
	t2114: "Ontbrekende geschiedenisparameters",
	t2115: "Niet alle geschiedenisparameters van de URL zijn gevonden",
	t2200: "De momenteel gebruikte softwareversie ( {{currentVersion}} ) wordt niet aanbevolen",
	t2201: "De momenteel gebruikte softwareversie ( {{currentVersion}} ) wordt niet aanbevolen ( > {{recommendedVersion}} aanbevolen)",
	t2202: "Fout in softwareversie",
	t2203: "Grafische Editor",
	t2204: "Bewerken",
	t2205: "Tekenen",
	t2206: "Nieuw uitzicht",
	t2207: "Gemaakt",
	t2208: "Laten vallen",
	t2209: "Nieuwe afbeelding",
	t2210: "Een nieuwe grafische weergave maken",
	t2211: "Naam",
	t2212: "Hernoemen",
	t2214: "Exporteren",
	t2213: "Duplicaat",
	t2215: "Verwijderen",
	t2216: "Weet u zeker dat u de weergave \" {{name}} \" wilt verwijderen?",
	t2217: "Alle weergaven exporteren",
	t2218: "Meer...",
	t2219: "Regel",
	t2220: "reglement",
	t2221: "Regel naam",
	t2222: "Paneel bewerken",
	t2223: "Conditie",
	t2224: "verloop",
	t2225: "Voorwaarden omkeren",
	t2226: "toewijzingen",
	t2227: "Kaartvormen door",
	t2228: "Vorm-ID",
	t2229: "Staten",
	t2230: "Toewijzing toevoegen",
	t2231: "Regel toevoegen",
	t2232: "Operator",
	t2233: "Voorwaarde toevoegen",
	t2234: "Begin met het toevoegen van regels",
	t2235: "Weet u zeker dat u de regel \" {{name}} \" wilt verwijderen?",
	t2236: "Er wordt een kopie van de regel gemaakt",
	t2237: "Siteweergaven",
	t2238: "U moet siteweergaven maken om ze te kunnen bekijken",
	t2239: "Ga naar grafische editor",
	t2240: "Ga naar details",
	t2241: "Selecteer parameter",
	t2242: "Geen parameters gevonden",
	t2243: "Opties",
	t2244: "Aan/Uit-ingang",
	t2245: "Relaisuitgang",
	t2246: "Sensoringang",
	t2247: "Variabele uitvoer",
	t2248: "Algemeen apparaat",
	t2249: "Leeg knooppunt",
	t2250: "Geschiedenis loggen wordt niet ondersteund...",
	t2251: "Opnieuw",
	t2252: "Kan geschiedenis niet ophalen",
	t2253: "Het laden van de geschiedenis kan even duren en ondersteunt niet meerdere zoekopdrachten tegelijk. Probeer dit verzoek opnieuw.",
	t2254: "Parameternaam",
	t2255: "Regelaarnaam",
	t2256: "Apparaat type",
	t2257: "Niet alle geschiedenisparameters konden worden geladen ( {{n}} mislukt)",
	t2258: "Verkeerde gebruikersnaam of wachtwoord",
	t2259: "Geen communicatie met controller",
	t2260: "Software-update",
	t2261: "Systeem",
	t2262: "Terugdraaien van software",
	t2263: "Installeren",
	t2264: "De software rollback vervangt een nieuwere versie",
	t2265: "Software terugzetten naar de back-upversie {{version}}",
	t2266: "Database",
	t2267: "Een update is beschikbaar",
	t2268: "Database exporteren",
	t2269: "Downloaden",
	t2270: "Database downloaden",
	t2271: "Kan database niet exporteren",
	t2272: "Databasebestand",
	t2999: "Communicatie fout",
	t2998: "Onbekende fout",
	t3000: "Details",
	t3001: "Eerdere XML-code hier",
	t3002: "Toewijzen",
	t3003: "Status",
	t3004: "Softwarebeheer",
	t3005: "Lokaal opgeslagen bestand",
	t3006: "Online via internet",
	t3007: "Zorg ervoor dat de stroom niet wordt onderbroken tijdens het updateproces. Het wordt aanbevolen om een back-up van de systeemdatabase te maken voordat u uw systeem bijwerkt. Na het voltooien van de software-update(s) wordt aanbevolen om de operationele status van het systeem te inspecteren om de juiste besturing en werking te bevestigen.",
	t3008: "Geen bestanden geselecteerd",
	t3009: "Selecteer softwarebestand",
	t3010: "Bestanden uploaden",
	t3011: "Beveiligingscontrole",
	t3012: "Bestand uitpakken",
	t3013: "Bestand controleren",
	t3014: "Bestand uploaden mislukt!",
	t3015: "Beveiligingscontrole mislukt!",
	t3016: "Uitpakken mislukt!",
	t3017: "Het controleren van het bestand is mislukt!",
	t3018: "Bestand downloaden: {{fileName}}",
	t3019: "Bestand succesvol gedownload!",
	t3020: "Download mislukt!",
	t3021: "Upload bestanden",
	t3022: "Bestanden downloaden",
	t3023: "OS-update",
	t3024: "EDF-update",
	t3025: "Bestanden downloaden",
	t3026: "De waarde van de geselecteerde parameter was helaas niet beschikbaar",
	t3027: "Importeren",
	t3028: "Er is minimaal één bestand vereist",
	t3029: "Bestand is niet geldig",
	t3030: "Grafische weergaven beheren",
	t3031: "Uploaden",
	t3032: "migreren",
	t3033: "Grafische bestand(en) aan het lezen, even geduld aub...",
	t3034: "Grafisch bestand importeren, even geduld a.u.b. ...",
	t3035: "Kies afbeelding",
	t3036: "Rapporten",
	t3037: "Database importeren",
	t3038: "Selecteer databasebestand",
	t3039: "Zorg ervoor dat de stroom niet wordt onderbroken tijdens het terugdraaien. Het wordt aanbevolen om een back-up van de systeemdatabase te maken voordat u uw systeem terugdraait. Na het voltooien van het terugdraaien van de software wordt aanbevolen om de operationele status van het systeem te inspecteren om de juiste besturing en werking te bevestigen.",
	t3040: "De systeembeheerder wordt opnieuw opgestart na de update",
	t3041: "Uitbreiden",
	t3042: "Foutopsporingsberichten weergeven",
	t3043: "Maximaal toegestane tekens -",
	t3044: "Upload a.u.b. updatebestand",
	t3045: "Huidige SW-versie",
	t3046: "Huidige Danux-versie",
	t3047: "Laatste SW-versie",
	t3048: "Laatste Danux-versie",
	t3049: "Een back-up maken, even geduld a.u.b.",
	t3050: "Bestand aan het installeren, even geduld a.u.b.",
	t3051: "Update installeren",
	t3052: "Apparaat wordt opnieuw opgestart",
	t3053: "Systeembeheer opnieuw starten",
	t3054: "Browser wordt nu vernieuwd",
	t3055: "Software succesvol geïnstalleerd",
	t3056: "Update mislukt, de volgende fout is opgetreden:",
	t3057: "Maak een back-up van de huidige softwareversie",
	t3058: "Maak een back-up van de huidige EDF-bestanden",
	t3059: "Grafische weergave is succesvol opgeslagen",
	t3060: "Achtergrondafbeelding toepassen",
	t3061: "Wissen",
	t3062: "Opnieuw doen",
	t3063: "Winkel",
	t3064: "Circuit nacht tegenslagen",
	t3065: "Zuig nacht tegenslagen",
	t3066: "Gedownloade versie {{fileName}}",
	t3067: "Om de geselecteerde units te updaten, druk op 'Install'",
	t3068: "{{updateType}} versie",
	t3069: "Installeren",
	t3070: "Rollback-versie installeren",
	t3071: "Geen van de beschikbare eenheden bevat een rollback-versie",
	t3072: "Nieuwe versie online beschikbaar",
	t3073: "Upload bestand voor geselecteerde eenheden",
	t3074: "Upload bestand voor geselecteerde unit",
	t3075: "Geen rollback-versies beschikbaar voor unit",
	t3076: "Download voltooid",
	t3077: "Huidige {{updateType}} versie",
	t3078: "Laatste {{updateType}} versie",
	t3079: "De update is bezig, weet u zeker dat u het venster wilt sluiten?",
	t3080: "Danux is niet up-to-date! Werk Danux bij en werk de software opnieuw bij",
	t3081: "Applicaties kunnen niet worden gedowngraded naar deze versie",
	t3082: "Aanmeldingsinformatie",
	t3083: "Release-opmerkingen",
	t3084: "Voorwaarden & Privacy",
	t3085: "Licenties",
	t3086: "Ga naar configuratie om schema's in te stellen",
	t3087: "Helaas is er een fout opgetreden bij het voorbereiden van de migratie. Zorg ervoor dat alle units bereikbaar zijn op het netwerk en correct zijn geconfigureerd. \n\n De volgende fout is opgetreden:\n {{error}}",
	t3088: "Een bestaande afbeelding importeren of migreren",
	t3089: "Weergave",
	t3090: "Naam bedrijfsmiddel",
	t3091: "Instorten",
	t3092: "Importeren voltooid",
	t3093: "ontlading veiligheid",
	t3094: "Zuig veiligheid",
	t3095: "Ontlading Cutin",
	t3096: "Afvoeruitsparing",
	t3097: "Zuiguitsparing",
	t3098: "Zuig Cutin",
	t3099: "Capaciteit",
	t3100: "De migratiebestanden (flp, dpj of json) konden niet worden gevonden",
	t3101: "Time-out bij het parseren van bestand",
	t3102: "Apparaatbestanden en adressen van derden",
	t3103: "Net",
	t3104: "Slot",
	t3105: "XML",
	t3106: "Selecteer parameter om weer te geven:",
	t3107: "Apparaatparameter:",
	t3108: "Geen eenheden",
	t3109: "Er zijn geen eenheid(en) gevonden of geladen",
	t3110: "HACCP",
	t3111: "BEDRIJFSMIDDEL",
	t3112: "Geen haccp-gegevens beschikbaar",
	t3113: "Eenheidsinformatie",
	t3114: "Updates beschikbaar",
	t3115: "Ga naar Software",
	t3116: "Niet verbonden",
	t3117: "Het huidige toegangsbereik voor deze applicatie is ' {{targetLevel}} ', de opgegeven gebruiker bevindt zich boven dit bereik in termen van toegangsrechten",
	t3118: "PDF exporteren",
	t3119: "PDF-export mislukt",
	t3120: "Bij grote datasets reageert de interface mogelijk niet meer en kan het even duren voordat deze is voltooid",
	t3121: "Selecteer schema's uit de lijst",
	t3122: "Ga naar configuratie om een schema in te stellen",
	t3123: "Succes",
	t3124: "Waarschuwing",
	t3125: "Site Informatie",
	t3126: "Selecteer tijd en datapunten uit de lijst",
	t3127: "Verbinden",
	t3128: "Er kon geen verbinding worden gemaakt met deze site, neem contact op met de ondersteuning",
	t3129: "Op de gekozen datum waren geen HACCP-gegevens beschikbaar. Zorg ervoor dat de geschiedenis en tijd correct zijn geconfigureerd in het configuratiemenu",
	t3130: "Diagnostisch rapport",
	t3131: "Selecteer eenheid(en) voor export van diagnoserapport",
	t3132: "Niet beschikbaar op dit gebruikerstoegangsniveau",
	t3133: "Diagnostisch logboek",
	t3134: "kan rapport niet downloaden",
	t3135: "Lettertype",
	t3136: "lettertypegrootte",
	t3137: "Geen weergaven om te migreren",
	t3138: "Desktopversie downloaden",
	t3139: "Vanwege beveiligingsbeperkingen in moderne browsers (",
	t3140: "RFC1918",
	t3141: ") is het helaas niet mogelijk om via onversleutelde HTTP verbinding te maken met apparaten binnen een privé-netwerkruimte. Configureer uw systeembeheerder om HTTPS te gebruiken of gebruik de desktopversie",
	t3142: "Geen gegevens beschikbaar voor de geselecteerde periode",
	t3143: "Configureer uw systeembeheerder om HTTPS te gebruiken of gebruik de desktopversie.",
	t3144: "We konden niet communiceren met de systeembeheerder. Dit betekent ofwel dat uw apparaat geen verbinding kan maken met het IP-adres of de hostnaam van de systeembeheerder, of dat u het SSL-certificaat dat op het apparaat wordt gebruikt, mogelijk handmatig moet vertrouwen.",
	t3145: "Klik op de volgende link om een nieuw tabblad te openen om het certificaat te vertrouwen en keer dan terug naar dit scherm.",
	t3146: "Handtekening",
	t3147: "DI - digitale ingangen",
	t3148: "AI - analoge ingangen",
	t3149: "Ga naar HACCP-configuratie",
	t3150: "Geen gegevens",
	t3151: "De back-up wordt opgeslagen in de System Manager voor de Rollback-functie",
	t3152: "Selecteer wat u wilt weergeven in siteweergave(n)",
	t3153: "Activa-eenheid",
	t3154: "Grafische bestanden",
	t3155: "Helaas is er een fout opgetreden bij het ophalen van de edf-bestanden en versies van de systeembeheerder...",
	t3156: "Om te selecteren welke bestanden u wilt installeren, drukt u op de knop 'Bestanden kiezen'",
	t3157: "Kies documenten",
	t3158: "Back-up verwijderen",
	t3159: "Staging-bestanden",
	t3160: "De back-up wordt opgeslagen in de System Manager voor de Rollback-functie",
	t3161: "Geïnstalleerde maat",
	t3162: "Nieuwe maat",
	t3163: "Geïnstalleerde datum",
	t3164: "Nieuwe datum",
	t3165: "Sensoren",
	t3166: "relais",
	t3167: "Ingangen",
	t3168: "outs",
	t3169: "meter",
	t3170: "Algemeen",
	t3171: "Wachtwoord verlopen, voer een nieuw wachtwoord in",
	t3172: "Nieuw paswoord",
	t3173: "Bevestig wachtwoord",
	t3174: "Wachtwoord wijzigen",
	t3175: "Wachtwoord moet minimaal 8 tekens bevatten 1 hoofdletter, 1 kleine letter, 1 cijfer, 1 speciaal teken)",
	t3176: "Wachtwoorden komen niet overeen",
	t3177: "Wachtwoord mag niet hetzelfde zijn als het oude wachtwoord",
	t3178: "Wachtwoordwijziging succesvol",
	t3179: "Wachtwoord kan niet worden gewijzigd. Fout van apparaat",
	t3180: "Wachtwoord reset",
	t3181: "gegenereerd",
	t3182: "Site naam",
	t3183: "Evenement-ID",
	t3184: "Tijd",
	t3185: "Rol",
	t3186: "Evenement-informatie",
	t3187: "Geselecteerde alarmen bevestigen",
	t3188: "Bevestig alle geselecteerde alarmen",
	t3189: "Database importeren naar",
	t3190: "Database importeren",
	t3191: "Bestandstype moet .zip . zijn",
	t3192: "Selecteer bestand",
	t3193: "Openingstijden",
	t3194: "Vakantie",
	t3195: "Aan/Uit-schema's",
	t3196: "Bezit",
	t3197: "U heeft niet-opgeslagen wijzigingen. Weet je zeker dat je wilt vertrekken?",
	t3198: "Helaas is de migratie mislukt. Het systeem is momenteel bezet. Probeer het later opnieuw",
	t3199: "Upload afbeelding aanpassen aan huidige afmetingen",
	t3200: "Achtergrondafbeelding vervangen",
	t3201: "Vervang achtergrondafbeeldingen door Scalable Vector Graphics.",
	t3202: "Alleen SVG-bestanden met dezelfde naam als de bestaande bestanden worden vervangen.",
	t3203: "Selecteer bestand(en) om te vervangen",
	t3204: "Afbeeldingsbestanden",
	t3205: "kan niet worden verwerkt. Bestand heeft een ongeldige indeling.",
	t3206: "{{amount}} bestand(en) succesvol vervangen",
	t3207: "{{amount}} bestand(en) kunnen niet worden vervangen",
	t3208: "Het uitzicht heeft geen achtergrond",
	t3209: "U kunt een nieuwe of een bestaande achtergrondafbeelding uploaden",
	t3210: "Als u een achtergrondafbeelding vervangt, kunnen de parameterposities behouden blijven als:",
	t3211: "Een png- of jpg-bestand heeft dezelfde resolutie als de huidige achtergrond",
	t3212: "Een svg-bestand heeft dezelfde beeldverhouding (maar niet noodzakelijk dezelfde resolutie)",
	t3213: "Functie is uitgeschakeld",
	t3214: "Toont momenteel de laatst ontvangen waarde, wordt bijgewerkt...",
	t3215: "Upload een grafisch bestand gemaakt in",
	t3216: "(optioneel)",
	t3217: "Achtergrond afbeelding",
	t3218: "Bladeren",
	t3219: "Meer...",
	t3220: "Voeg een achtergrondafbeelding toe (optioneel)",
	t3221: "grafisch bestand",
	t3222: "Time-out in communicatie",
	t3223: "Geen koelschema's geconfigureerd",
	t3224: "Inhoudsopgave",
	t3225: "Type",
	t3226: "Vakantie",
	t3227: "dagen",
	t3228: "Op",
	t3229: "Uit",
	t3230: "ID kaart",
	t3231: "Open",
	t3232: "Begin",
	t3233: "Einde",
	t3234: "Beschrijving",
	t3235: "Dag",
	t3236: "Dichtbij",
	t3237: "EDF-bestand(en) succesvol geüpload. Systeembeheerder opnieuw starten",
	t3238: "Edf-bestand(en) uploaden voltooid, systeembeheerder reset succesvol",
	t3239: "Fout bij het ophalen van edf-bestand(en) uit systeembeheer",
	t3240: "Uploaden van edf-bestand(en) mislukt. Probeer het na een tijdje",
	t3241: "Geen koelschema's geconfigureerd",
	t3242: "Bestand uploaden naar systeembeheerder",
	t3243: "Specificeer het apparaattype - beïnvloedt de vereiste parametertags om het te bedienen",
	t3244: "Specificeer hoe de waarden van het apparaat worden benaderd en welke functie ze vervullen",
	t3245: "Maak een alarm op basis van een beetje een parameter",
	t3246: "Etiketten",
	t3247: "Maak een lijst met labels die aan een parameter kunnen worden toegewezen",
	t3248: "Groepen",
	t3249: "Specificeer parametergroepen",
	t3250: "Registratienummer",
	t3251: "Data type",
	t3252: "Lijst met labels",
	t3253: "Minimum",
	t3254: "Standaard",
	t3255: "Maximaal",
	t3256: "Registratietype",
	t3257: "Het registertype wordt vertaald naar corresponderende functiecodes voor lezen en schrijven",
	t3258: "Wisselmodus",
	t3259: "Voor een waarde met bytes ABCD die naar het apparaat is verzonden, verwisselt deze optie bytes (BADC), woorden (CDAB) of beide (DCBA).",
	t3260: "Alleen lezen",
	t3261: "Loggen",
	t3262: "Schakelt geschiedenisregistratie in voor deze parameter",
	t3263: "Tekst overnemen van parameter naar alarm",
	t3264: "Positie van de alarmvlag geteld vanaf het minst significante deel van de parameter",
	t3265: "Alarm activeren bij lage waarde",
	t3266: "Label",
	t3267: "Verbergen",
	t3268: "{{field}} is een verplicht veld.",
	t3269: "Maximum moet groter of gelijk zijn aan minimum",
	t3270: "Standaardwaarde moet in [Minimum, Maximum] zijn",
	t3271: "Het gegevenstype moet {{reg}} zijn voor het geselecteerde registratietype {{mt}}",
	t3272: "Naam mag geen scheidingstekens bevatten",
	t3273: "Naam mag niet langer zijn dan 20 tekens",
	t3274: "Lijst met labels mag niet leeg zijn",
	t3275: "De tag(s) {{taglist}} moeten precies één keer worden toegewezen.",
	t3276: "De groep(en) {{grouplist}} bevatten meer dan het maximum van 50 parameters.",
	t3277: "Formulier bevat fouten, controleer de fouten en probeer het opnieuw.",
	t3278: "Indienen",
	t3279: "Om de geüploade EDF te activeren, moet u de unit resetten. Wilt u het apparaat nu resetten?",
	t3280: "Nu resetten",
	t3281: "Later resetten",
	t3282: "{{file}} bestaat al. Wil je het overschrijven?",
	t3283: "Uploaden {{what}}",
	t3284: "Labels en waarden moeten uniek zijn.",
	t3285: "Multi",
	t3286: "Apparaat verwerkt meerdere koffers of pakketten",
	t3287: "Tags",
	t3288: "Internet Explorer is aan het einde van de levensduur en wordt niet ondersteund door StoreviewWeb",
	t3289: "Schakel over naar een ondersteunde browser, Edge, Chrome of Firefox",
	t3290: "Meer informatie",
	t3291: "Dichtbij",
	t3292: "Kan gegevens niet lezen, probeer het opnieuw",
	t3293: "Back-up EDF's bezig",
	t3294: "Er zijn geen weergaven om te migreren",
	t3295: "Het systeem is geconfigureerd met een openbaar IP-adres in het privé-IP-veld",
	t3296: "Het systeem is geconfigureerd met een privé-IP in het openbare IP-veld, het systeem is mogelijk niet op afstand toegankelijk of sommige functies werken mogelijk niet correct",
	t3297: "U bent verbonden via een IP of hostnaam die de systeembeheerder niet kent, waarschijnlijk via een proxy-service, sommige functies werken mogelijk niet correct",
	t3298: "Apparaat is offline, laat de laatst bekende waarde zien",
	t3299: "Apparaatintegratie",
	t3300: "De tag(s) {{taglist}} zijn gedupliceerd.",
	t3301: "Pagina niet gevonden",
	t3302: "Het spijt ons, de door u opgevraagde pagina kon niet worden gevonden",
	t3303: "Foutcode: 404",
	t3304: "Ga naar het dashboard",
	t3305: "Alleen bekijken",
	t3306: "Intern IP-adres",
	t3307: "Extern IP-adres",
	t3308: "Domeinnaam",
	t3309: "Geen waarschuwingen",
	t3310: "Meerdere waarschuwingen",
	t3311: "Het lijkt erop dat het systeem is geconfigureerd met meerdere units, maar we hebben geen contact kunnen maken met een of meer van de slave-units. Zorg ervoor dat de instellingen correct zijn. Houd er rekening mee dat een installatie met meerdere eenheden niet meerdere domeinnamen ondersteunt en dat elke eenheid moet worden geconfigureerd met een afzonderlijke poort wanneer hetzelfde domein wordt gebruikt",
	t3312: "Alleen bekijken",
	t3313: "Dit element heeft geen associatie met een parameter en kan veilig worden verwijderd",
	t3314: "Parameter verwijderen",
	t3315: "Schema",
	t3316: "U wordt nu uitgelogd en moet inloggen met het nieuwe wachtwoord",
	t759: "Database gedownload, unit opnieuw ingesteld en geüpgraded. Dit kan enkele minuten duren.",
	t816: "Alarmtype:",
	t818: "Graaf :",
	t848: "DB-bestand is niet gemaakt. U hebt beperkte toegang tot de geïnstalleerde map. Neem contact op met de systeembeheerder.",
	t3317: "Geen schema's om te exporteren",
	t3318: "In afwachting van reactie van het systeem",
	t3319: "Geen netwerkactiviteit",
	t3320: "Winkel naam",
	t3321: "Nieuwe versie {{latestVersion}} online beschikbaar",
	t3322: "Externe poort",
	t3323: "Interne poort",
	t3324: "We kunnen niet communiceren met de systeembeheerder",
	t3325: "misschien moet het certificaat worden vertrouwd?",
	t3326: "Bestand van dit formaat kan niet worden verwijderd",
	t3327: "Gebruik",
	t3328: "Op tijd",
	t3329: "Vrije tijd",
	t3330: "Doordeweekse dagen",
	t3331: "Koelfase",
	t3332: "Warmteterugwinning",
	t3333: "Koeling",
	t3334: "Verwarming",
	t3335: "Hulpverwarming",
	t3336: "Ontvochtiging",
	t3337: "Internet",
	t3338: "Berichten",
	t3339: "Contacten",
	t3340: "Comm",
	t3341: "Geen items gevonden",
	t3342: "Type om te zoeken",
	t3408: "Alles inschakelen",
	t3409: "Alles uitschakelen",
	t3410: "Normaal",
	t3411: "Streng",
	t3412: "Kritisch",
	t3413: "Alle alarmen inschakelen",
	t3414: "Zoeken naar alarmen",
	t3415: "Ingeschakeld",
	t3417: "Fout bij het ophalen van alarmen",
	t3418: "Fout bij het ophalen van apparaten om te kopiëren",
	t3419: "Fout bij het controleren van de kopieerstatus",
	t3420: "Automatisch pollen wordt hervat na een time-out van maximaal 2 uur, of als de wizard wordt geannuleerd of voltooid",
	t3421: "Kopie naar controller(s)",
	t3422: "Kopiëren naar het/de geselecteerde apparaat(en)",
	t3423: "De kopieerfunctie kopieert eerst de instellingen naar de System Manager-database en downloadt vervolgens de instellingen naar de controllerapparaten. Het kan enige tijd duren om de actie te voltooien.",
	t3424: "Geen apparaten geselecteerd om te kopiëren",
	t3425: "Dubbelklik om te kopiëren",
	t3426: "Fout bij het ophalen van apparaten",
	t3427: "Apparaten voor kopiëren",
	t3428: "Selecteer het apparaat/de apparaten voor kopiëren",
	t3429: "Hier worden alleen controllers van hetzelfde type weergegeven",
	t3430: "Kopieerproces is geslaagd",
	t3431: "Ongeldig bestand. Verwacht bestandsmodel:",
	t3432: "Fout bij het laden van het instellingenbestand",
	t3435: "Weet u zeker dat u het geselecteerde apparaat naar deze groep {{toPackGroup}} wilt verplaatsen?",
	t3343: "Ontdekking",
	t3344: "Tabel vernieuwen",
	t3345: "Netwerken scannen, even geduld...",
	t3346: "De netwerktabel is vernieuwd",
	t3347: "Fout bij het vernieuwen van netwerken",
	t3348: "Fout bij het opnieuw scannen van netwerken",
	t3349: "Fout bij het ophalen van netwerken",
	t3350: "Netwerkscan voltooid",
	t3351: "Geen apparaten beschikbaar",
	t3352: "Netwerk scannen",
	t3353: "Ongecfg",
	t3354: "Elke herschikking van apparaten in de wizard kan van invloed zijn op de bewakingsservices van Alsense.",
	t3355: "Netwerk",
	t3356: "Selecteer veldbustype",
	t3357: "Als Modbus/RS485 is ingeschakeld, kunt u SLV selecteren voor Danfoss variabele snelheidscompressor. Wanneer u SNMP selecteert als een lokale bus, moet u een geldig subnetadresbereik invoeren via Danfoss SNMP-bedieningen",
	t3358: "Compressoren/pakketten",
	t3359: "Beheer compressoren/packs controllers",
	t3360: "Alleen Pack-apparaten kunnen hier worden beheerd - geen Rack I/O-systeem. Compatibele Multi-Suction Pack Controllers die zijn gescand, worden in eerste instantie weergegeven als 0-zuiggroepen. De AK-ST 500 (Service Tool) is vereist om de Pack-controller te configureren.",
	t3361: "Te kopiëren bedrag",
	t3362: "Als u de eerder geconfigureerde zuiggroep verkleint, worden ook alle bijbehorende verdampingsregelingen voor die zuiggroep verwijderd.",
	t3363: "Het aantal zuiggroepen kan niet nul zijn, het minimale aantal zuiggroepen moet minimaal één zijn.",
	t3364: "Fout bij het ophalen van compressoren/pakketten",
	t3365: "Geen compressoren/pakketten beschikbaar",
	t3366: "Online apparaten",
	t3367: "Offline apparaten",
	t3369: "Pakket toevoegen",
	t3370: "Als u Modbus/RS485 selecteert, ziet u extra opties voor SLV/ECFan. Deze worden gebruikt voor koeling en kunnen worden geselecteerd op Nee.",
	t3371: "Circuit toevoegen",
	t3372: "Apparaten toevoegen, kopiëren of verwijderen. Let op: niet alle apparaattypen staan bewerkingen van alle velden toe en de adressen van I/O-punten worden in de configuratie ingesteld.",
	t3373: "Beheer casecontrollers",
	t3374: "Een apparaat toevoegen",
	t3375: "Beheer compressor-/pakketcontrollers",
	t3376: "Fout in gegevenstoegang",
	t3377: "HVAC-indeling is succesvol toegepast",
	t3378: "Indeling HVAC",
	t3379: "Weet u zeker dat u deze pagina wilt verlaten?",
	t3380: "Uw instellingen zijn mogelijk verloren gegaan",
	t3381: "Configureer een apparaat",
	t3382: "Snelle installatie verlaten",
	t3383: "Weet u zeker dat u Snelle installatie wilt afsluiten?",
	t3384: "Poorten (AK-PI200)",
	t3385: "Selecteer de gevonden gateways",
	t3386: "IP adres",
	t3387: "Broncontroller",
	t3388: "Broninstellingen",
	t3389: "Bron alarmen",
	t3390: "Bron grafiek",
	t3392: "Grafiek",
	t3393: "Apparaten",
	t3394: "Auto polling wordt opgeschort tijdens de kopieerfase. De hoofdschakelaar van bron- en doelapparaat(en) wordt UIT gezet en keert terug naar de oorspronkelijke staat zodra het kopiëren is voltooid. Controleer de hoofdschakelaar nadat de kopieerwizard is voltooid. Als een van de geselecteerde apparaten in een actief alarm staat, worden wijzigingen in de alarmactie voor dat specifieke punt niet gewijzigd. De oorspronkelijke instelling blijft behouden.",
	t3395: "Selecteer een bronapparaat waarvan u de instellingen wilt kopiëren",
	t3396: "Op tijd",
	t3397: "De kopieerfunctie kopieert beschikbare grafiekpunten totdat de capaciteit van de eenheid is bereikt. Ga naar Configuratie om het aantal gedefinieerde punten te zien",
	t3398: "Bronapparaatgrafiek",
	t3399: "Grafiekpunten configureren voor de broncontroller",
	t3400: "Schakel HACCP in om de punten in een HACCP-temperatuurrapport te loggen",
	t3401: "Zoeken naar punten",
	t3402: "Geen gegevens beschikbaar",
	t3403: "Fout bij het ophalen van grafiekpunten",
	t3404: "Uitgeschakeld",
	t3405: "Bronapparaat alarmen",
	t3406: "Alarmdetails configureren in de broncontroller",
	t3407: "Alarmnaam",
	t3436: "Het apparaat wordt verwijderd uit de toegewezen groep [ {{fromPackGroup}} ]",
	t3437: "Indeling Koeling",
	t3438: "De koelinrichting is succesvol toegepast",
	t3439: "Opnieuw toewijzen",
	t3440: "Wijs case-controles toe aan pack-controles",
	t3441: "Wijs Case-bedieningen (van beschikbare cases) toe aan Compressor-/Pack-bedieningen en vorm zuiggroepen",
	t3442: "Apparaatparameters",
	t3443: "Selecteer parameters voor kopiëren",
	t3444: "Wanneer u op een live (online) apparaat werkt, kan er een vertraging in de instellingen optreden vanwege de communicatie met het apparaat.",
	t3445: "Selecteer alarmen om te kopiëren",
	t3446: "Apparaatgrafiek",
	t3447: "Selecteer grafiekpunten voor kopiëren",
	t3448: "Huidige apparaatinstellingen opslaan in een bestand",
	t3449: "U kunt de huidige apparaatinstellingen opslaan in een bestand dat kan worden gebruikt om apparaten op een later tijdstip te configureren. Als u de wizard wilt afsluiten nadat het bestand is opgeslagen, gebruikt u het pictogram Afsluiten. Als u met aangesloten apparaten werkt, zorgt afsluiten ervoor dat netwerkpolling en de hoofdschakelaar van het apparaat terugkeren naar de juiste status.",
	t3450: "Instellingsbestand(en) succesvol opgeslagen",
	t3451: "Wilt u doorgaan of de Snelle installatie afsluiten?",
	t3452: "Bestand(en) opslaan",
	t3453: "Fout bij het ophalen van instellingen",
	t3454: "Bronapparaatinstellingen",
	t3455: "Apparaatparameters configureren in de broncontroller",
	t3456: "Geen van de wachtwoordvelden mag leeg zijn",
	t3457: "Recente",
	t3458: "Adresboek",
	t3459: "Laatst bezocht",
	t3460: "Maak regelmatig een back-up van het adresboek.",
	t3461: "Adresboek is succesvol geïmporteerd",
	t3462: "Importeer adresboek",
	t3463: "Exporteer adresboek",
	t3464: "Site toevoegen",
	t3465: "Site bewerken",
	t3466: "Locatie verwijderen.",
	t3467: "U moet een wachtwoord voor Adresboek aanmaken voordat u Adresboek gaat gebruiken",
	t3468: "Site is succesvol toegevoegd aan uw Adresboek",
	t3469: "Al in Adresboek",
	t3470: "Toevoegen aan adresboek",
	t3471: "Inloggen op site",
	t3472: "Site-info is succesvol bijgewerkt",
	t3474: "Kennisgeving",
	t3475: "Site is succesvol verwijderd uit uw adresboek",
	t3476: "Site is succesvol bijgewerkt",
	t3477: "Ongeldig adresboekformaat",
	t3478: "Acties",
	t3479: "Niet beschikbaar wanneer offline",
	t3480: "Het laden van de S55-database is mislukt",
	t3481: "S55-database laden",
	t3482: "Protocol",
	t3483: "Gebruiker heeft geen toestemming om software-informatie te zien",
	t3484: "Het wachtwoord mag alleen alfanumeriek of numeriek zijn",
	t3485: "U moet een wachtwoord voor het Adresboek aanmaken om toegang te krijgen tot het Adresboek",
	t3486: "Adresboekwachtwoord is niet correct",
	t3487: "Site is al aanwezig in het adresboek",
	t3488: "Maak een Adresboek-wachtwoord om Adresboek te gebruiken. Wachtwoord mag alleen alfanumeriek of numeriek zijn",
	t3489: "Voer uw Adresboek-wachtwoord in",
	t3490: "Geen online apparaten beschikbaar om te kopiëren",
	t3491: "Maak een wachtwoord voor het adresboek",
	t3492: "Wachtwoord adresboek vernieuwen",
	t3493: "Sitewachtwoord mag niet leeg zijn",
	t3494: "Typ een wachtwoord in voor het adresboek dat wordt geïmporteerd",
	t3495: "Adresboekwachtwoord is succesvol aangemaakt",
	t3496: "Het wachtwoord van het adresboek is succesvol bijgewerkt",
	t3497: "Het wachtwoord van het te importeren adresboek is niet correct",
	t3498: "Voer het wachtwoord van de site in",
	t3499: "Adresboek wissen",
	t3500: "Zoeksites",
	t3501: "Selecteer het adresboekbestand dat u wilt importeren",
	t3502: "Selecteer sleutelbestand om adresboek te decoderen",
	t3503: "Druk op exporteren om het gecodeerde adresboek te downloaden",
	t3505: "Met gecodeerde",
	t3506: "ongecodeerde wachtwoorden",
	t3507: "Ongeldige geheime sleutel",
	t3508: "Import mislukt. Zorg ervoor dat u de juiste sleutel hebt geïmporteerd",
	t3509: "Geen beschikbare versies",
	t3510: "Open Alsense Services (abonnement vereist)",
	t3511: "Lees de EULA-voorwaarden zorgvuldig door. Als u de voorwaarden niet kunt accepteren, mag u de applicatie niet gebruiken.",
	t3512: "Adresboek en uw Adresboek-wachtwoord zijn succesvol gewist",
	t3513: "(vereist)",
	t3515: "Adresboek is leeg",
	t3516: "Adresboekwachtwoord mag niet leeg zijn",
	t3519: "Als u uw adresboek wist, verwijdert u ook uw wachtwoord voor het adresboek.",
	t3520: "Niet binnen bereik. Moet tussen 0 en {{maxAddr}} zijn",
	t3521: "Klik op 'Exporteren' om alle grafische weergaven te downloaden die zijn opgeslagen in de System Manager",
	t3522: "Druk op 'Downloaden' om een systeemrapport te downloaden",
	t3523: "Alleen de masterunit is beschikbaar via een directe wifi-verbinding",
	t3524: "Er zijn geen lokale grafische weergaven. Voeg nieuwe toe of importeer bestaande weergaven",
	t3525: "Er zijn geen remote graphic views. Probeer te zoeken met een andere naam",
	t3526: "Er zijn geen externe grafische weergaven. Voeg nieuwe toe, importeer of migreer bestaande weergaven",
	t3527: "Lokale weergaven ( {{totalFiles}} van 5)",
	t3528: "{{totalFiles}} lokale afbeeldingen uit maximaal 5",
	t3529: "Afstandsweergaven",
	t3530: "Het bestand is geen bitmap.",
	t3531: "Bitmaplengte is onjuist.",
	t3532: "Bitmap-headerformaat is onjuist.",
	t3533: "Bitmap-formaat is onjuist.",
	t3534: "Fout in paletgrootte.",
	t3535: "Incompatibel .bmp-bestand",
	t3536: "Fout bij het verwijderen van lokaal grafisch bestand",
	t3537: "Fout bij het dupliceren van een lokaal grafisch bestand",
	t3538: "Fout bij het exporteren van lokaal grafisch bestand",
	t3539: "Verwijder afbeelding",
	t3540: "Een lokaal grafisch bestand verwijderen",
	t3541: "Het verwijderen van het lokale grafische bestand is bezig",
	t3542: "Lokaal grafisch bestand succesvol verwijderd. Systeembeheer opnieuw opstarten",
	t3543: "Verwijderen van lokaal grafisch bestand voltooid. Systeembeheer is succesvol opnieuw opgestart.",
	t3544: "Duplicaat Grafisch",
	t3545: "Een lokaal grafisch bestand dupliceren",
	t3546: "Lokale grafische bestandsduplicatie is bezig",
	t3547: "Lokaal grafisch bestand succesvol gedupliceerd. Systeembeheer opnieuw opstarten",
	t3548: "Lokale grafische bestandsduplicaat voltooid. Systeembeheer succesvol opnieuw opgestart",
	t3549: "Grafisch exporteren",
	t3550: "Exporteer een lokaal grafisch bestand",
	t3551: "Export van lokaal grafisch bestand is bezig",
	t3552: "Lokaal grafisch bestand succesvol geëxporteerd",
	t3553: "De systeembeheerder zal bij deze actie opnieuw opstarten",
	t3554: "Weet u zeker dat u wilt verwijderen?",
	t3555: "Weet u zeker dat u wilt dupliceren?",
	t3556: "Klik op 'Exporteren' om alle lokale grafische weergaven te downloaden die zijn opgeslagen in de System Manager",
	t3557: "Export van lokale grafische bestanden is bezig",
	t3558: "Lokale grafische bestanden succesvol geëxporteerd",
	t3559: "Productcode",
	t3560: "Krijg meer informatie over dit product",
	t3561: "Ventilator eco",
	t3562: "Klep openen",
	t3563: "Oververhitting",
	t3564: "Verdamper druk",
	t3565: "Verdamper temperatuur",
	t3566: "Uitsparing",
	t3567: "Snijden",
	t3568: "Kastdeur",
	t3569: "Zuigtemperatuur",
	t3570: "Railwarmte",
	t3571: "Maximaal toegestane bestandslimiet bereikt. Deselecteer bestand(en) om te importeren.",
	t3572: "Verwijder bestaande bestanden in systeembeheer om bestanden te importeren",
	t3573: "Lokaal grafisch bestand succesvol geïmporteerd. Systeembeheer opnieuw opstarten",
	t3574: "Import van lokale grafische bestanden voltooid. System Manager succesvol opnieuw opgestart.",
	t3575: "Maximaal toegestane bestandslimiet bereikt. Bestand(en) verwijderen om te importeren",
	t3576: "Maximaal toegestane grafische bestanden zijn 5",
	t3577: "Lokaal grafisch bestand succesvol geüpload. Systeembeheer opnieuw opstarten",
	t3578: "Uploaden van lokaal grafisch bestand voltooid. System Manager is succesvol opnieuw opgestart.",
	t3579: "Ongeldig bestandsformaat. Upload {{supportedFileFormats}} bestand",
	t3580: "De bestandsafmetingen moeten {{maxWidthAllowed}} x {{maxHeightAllowed}} pixels zijn voor lokale schermen",
	t3581: "Op afstand bekijken",
	t3582: "Lokale weergave",
	t3583: "Storeview Web-homepage",
	t3584: "EULA-voorwaarden",
	t3585: "Beveiliging",
	t3586: "Beveiligingshandhavingsniveaus",
	t3587: "Achterwaarts compatibel (laagste beveiligingsniveau - ervan uitgaande dat header-authenticatie is uitgeschakeld)",
	t3588: "SvW/XML-verzoeken bevatten gebruikersnaam en wachtwoord.",
	t3589: "Geen opschoning van verzoeken, dezelfde beveiliging als eerdere releases",
	t3590: "Geen wijzigingen nodig op SvW of bestaande XML-verbindingen",
	t3591: "Permissief (kan worden gebruikt als overgang naar strikt niveau)",
	t3592: "XML-verzoeken kunnen gebruikersnaam en wachtwoord bevatten, en/of een sessietoken. Het sessietoken wordt ontvangen in de respons van het inlogverzoek.",
	t3593: "Als het sessietoken in de AKSM-auth-header wordt verstrekt, worden gebruikersnamen en wachtwoorden genegeerd",
	t3594: "XML-aanvraag wordt gesaneerd, er wordt een waarschuwing in het antwoord op de aanvraag geretourneerd als er problemen worden ontdekt",
	t3595: "Houd rekening met deze waarschuwingen ter voorbereiding op de overstap naar de strikte modus",
	t3596: "Als het sessietoken niet wordt gebruikt, kan sessieverval niet worden toegepast",
	t3597: "Al ondersteund in externe gebruikersinterface (SvW)",
	t3598: "Mogelijke / vereiste wijzigingen vanuit het perspectief van XML van derden",
	t3599: "Geen gebruik van sessietokens - geen wijzigingen nodig",
	t3600: "Gebruik van sessietokens - verwijder gebruikersnaam/wachtwoord uit de hoofdtekst en let op waarschuwingen ter voorbereiding op Strict",
	t3601: "Strikt (hoogste beveiligingsniveau)",
	t3602: "XML-verzoeken kunnen geen gebruikersnamen en wachtwoorden bevatten, maar moeten het sessietoken in de AKSM-auth-header bevatten. Als er een probleem in het verzoek wordt ontdekt, wordt het afgewezen",
	t3603: "Al ondersteund in (SvW)",
	t3604: "Vereiste wijzigingen vanuit het perspectief van derden",
	t3605: "gebruikersnaam en wachtwoord moeten worden verwijderd uit de XML-aanvraagpayload en het sessietoken moet worden geplaatst in een CORS-header met de naam AKSM-auth",
	t3606: "Inloggen mislukt Terugtrekken",
	t3607: "Hoe lang (seconden) een externe gebruiker wacht om opnieuw te kunnen proberen in te loggen na een mislukte poging. De wachttijd neemt cumulatief toe met elke mislukte poging. Na 10 pogingen wordt het IP-adres van waaruit de gebruiker probeerde in te loggen, 24 uur lang geblokkeerd. Als dit is ingesteld op 0, wordt Login failure back-off uitgeschakeld",
	t3608: "Standaard 30s (max 600s / 0 = uitgeschakeld)",
	t3609: "Gratis pogingen",
	t3610: "Hoeveel inlogpogingen zijn toegestaan voordat back-off wordt toegepast?",
	t3611: "Standaard 2 (max 5)",
	t3612: "Blok",
	t3613: "Als dit is ingeschakeld, worden Gebruiker + IP geblokkeerd tijdens back-off, anders wordt de Gebruiker geblokkeerd",
	t3614: "Standaard 'Gebruiker' (Gebruiker + IP / Gebruiker)",
	t3615: "Sessies per gebruiker",
	t3616: "Een sessie is wanneer een gebruiker succesvol is ingelogd op de unit en zich binnen de externe interface (SvW) bevindt",
	t3617: "Aantal actieve sessies dat een gebruiker kan hebben. Als dit op 0 staat, kan een gebruiker het maximale aantal sessies hebben (30)",
	t3618: "Standaard 0",
	t3619: "Sessie vervalt",
	t3620: "Hoeveel seconden nadat een inactieve sessie verloopt. Als een sessie verloopt, is een nieuwe login nodig",
	t3621: "Standaard 900s (max 3.600)",
	t3622: "Header-authenticatie",
	t3623: "Bij het inschakelen van HTTPS en headerverificatie moeten alle XML-aanvragen van derden gebruikersnaam-/wachtwoordreferenties in de header doorgeven (basisverificatieschema)",
	t3624: "Het inschakelen van 'Use Header Authentication' vereist dat het systeem opnieuw wordt geïnitialiseerd. Gebruik de knop 'Press to Initialize' om deze actie uit te voeren",
	t3625: "Klein",
	t3626: "Groot",
	t3627: "Grafisch opslaan",
	t3628: "Een lokaal grafisch bestand opslaan",
	t3629: "Lokaal grafisch bestand succesvol verwijderd. Lokaal grafisch bestand laden is bezig",
	t3630: "Lokaal grafisch bestand is succesvol geladen. Systeembeheer opnieuw opstarten",
	t3631: "Opslaan van lokaal grafisch bestand voltooid. Systeembeheer is succesvol opnieuw opgestart.",
	t3632: "Weet u zeker dat u wilt opslaan?",
	t3633: "Parsen mislukt",
	t3634: "Kon viz-bestand niet parseren",
	t3635: "Kon vz2-bestand niet parseren",
	t3636: "Het openen van het Viz-bestand is mislukt:",
	t3637: "De migratiebestanden (vz2, dpj of json) konden niet worden gevonden",
	t3638: "De migratiebestanden (viz) konden niet worden gevonden",
	t3639: "De System Manager zal opnieuw opstarten bij deze actie. De System Manager staat in permissieve of strikte modus, dus het sessietoken zal verlopen bij het opnieuw opstarten en u zult worden uitgelogd van het systeem",
	t3643: "Uitlaatdruk",
	t3644: "Totaal kW",
	t3645: "Totaal KVAR",
	t3646: "Totaal Kvarh+",
	t3647: "Vraag",
	t3648: "Vermogensfactor",
	t3649: "Letterkleur",
	t3650: "Achtergrondkleur",
	t3651: "Adresboek is succesvol geëxporteerd",
	t3652: "Controlemodus",
	t3653: "Sc3 Buitentemperatuur",
	t3654: "Ontladingstemperatuur",
	t3655: "Condensatorcapaciteit",
	t3656: "Geen gegevens om te exporteren",
	t3657: "Het wachtwoord van de gebruiker op {{unit}} is verlopen",
	t3658: "Kan niet inloggen op {{unit}} met de opgegeven inloggegevens.",
	t3659: "Werk de firmwareversie bij of wijzig het beveiligingsniveau voor {{unit}} om ondersteuning voor sessietokens te krijgen.",
	t3661: "V3gc",
	t3662: "Sectie B",
	t3663: "Sectie C",
	t3664: "Adaptieve oververhittingsregeling",
	t3665: "Reden voor controlestatus",
	t3666: "Gebruiker kan niet worden aangemeld. Het adres van de eenheid is niet ingesteld in de proxyconfiguratie",
	t3667: "Communicatieprotocol is niet gespecificeerd in proxyconfiguratie. Dit kan leiden tot fouten",
	t3668: "IT-beveiligingsinformatie",
	t3669: "In overeenstemming met de beveiligingsnormen staat de systeembeheerder nu standaard op strikte sessiecontrole. Dit betekent dat externe verbindingen/XML-gebaseerde externe services niet meer functioneren, tenzij er sessietokens worden toegepast.",
	t3670: "Danfoss adviseert om door te gaan in de strikte modus, maar de modus voor achterwaartse compatibiliteit is nog steeds beschikbaar.",
	t3671: "Let op, dit kan worden gewijzigd in het menu Configuratiebeveiliging en heeft geen invloed op de lokale besturing en sitewerking van System Manager.",
	t3672: "Zie meer informatie over sessiebeheer",
	t3673: "Achterwaartse modus",
	t3674: "Strikte modus",
	t3675: "Wanneer u op de modus voor achterwaartse compatibiliteit klikt, worden alle toepasselijke systeembeheerders met bijgewerkte firmware ingesteld op de modus voor achterwaartse compatibiliteit, opnieuw opgestart en wordt de toepassing afgemeld.",
	t3676: "Maximaal aantal toegestane zuiggroepen is 12",
	t3677: "Wanneer de geselecteerde eenheden succesvol zijn bijgewerkt van 3.3.0 naar 4.X of hoger, wordt de gebruiker automatisch afgemeld wanneer hij in de laatste stap op de knop Voltooien klikt.",
	t3678: "Multicase voor AK2-controllers (Pack/Case) worden niet ondersteund",
	t3679: "In overeenstemming met de beveiligingsnormen die zijn geïntroduceerd met release 4.0, zal de System Manager standaard overschakelen naar Strict session control, wat betekent dat elke externe verbinding / XML-gebaseerde externe service niet meer zal functioneren tenzij sessietokens worden toegepast. Daarnaast zal de communicatie automatisch worden aangepast naar HTTPS door te upgraden naar rel. 4.0. Danfoss raadt aan om door te gaan in de Strict-modus, maar de modus voor achterwaartse compatibiliteit is beschikbaar, die het systeem terugzet naar de tot nu toe toegepaste instellingen. Let op: als u deze update op afstand uitvoert op een HTTP-eenheid, zorg er dan voor dat uw IT-installatie is voorbereid op HTTPS (poort 443) of dat er personeel ter plaatse beschikbaar is.",
	t3680: "Meer informatie over de nieuwe beveiligingsimplementatie vindt u hier",
	t3681: "Toegang tot de http-versie van deze site kan geblokkeerd zijn vanwege uw browserbeperkingen. Bezoek onze FAQ-pagina voor instructies over het oplossen van dit probleem of download de desktopversie van StoreView Web.",
	t3682: "Veelgestelde vragen over systeembeheerders",
	t3683: "u17 Ther. lucht",
	t3684: "u91 Uitschakeltemp.",
	t3685: "u00 Ctrl.-status",
	t3686: "u12 S3 luchttemp.",
	t3687: "u16 S4 luchttemp.",
	t3688: "u23 EEV OD %",
	t3689: "u21 Oververhitting",
	t3690: "u22 OververhittingRef",
	t3691: "u26 EvapTemp Te",
	t3692: "S2 Gasuitlaattemp.",
	t3693: "Stw8",
	t3694: "SD-MT",
	t3695: "Tw-status",
	t3696: "Tw inschakelen",
	t3697: "Twee pompen draaien",
	t3698: "Stw3",
	t3699: "Stw4",
	t3700: "Stw2",
	t3701: "Ontvangst controle status",
	t3702: "Zuigoffset",
	t3703: "Vrec-besturingsmodus",
	t3704: "Vrec-status",
	t3705: "Ctrl-status reden",
	t3706: "Hp-regelstatus",
	t3707: "Maximale PGC",
	t3708: "Pgc max. limiet P-ban",
	t3709: "Pgc min.",
	t3710: "Damp-ejector capaciteit",
	t3711: "Capaciteit vloeistofuitwerper",
	t3712: "Referentieluchttemp.",
	t3713: "Toevoerluchttemp.",
	t3714: "Retourlucht",
	t3715: "Dauwpunt van de zone",
	t3716: "Zonevochtigheid",
	t3717: "Buitentemperatuur",
	t3718: "Zone CO2-niveau",
	t3719: "Zonetemperatuur",
	t3720: "Cond. referentie",
	t3721: "Cond. controle status",
	t3722: "Cond. controle toestand",
	t3723: "Geleidelijke ctrl.",
	t3724: "Cond. verzochte cap.",
	t3725: "V3gc-status",
	t3726: "V3gc-OD",
	t3727: "Geleidbaarheidstemp.",
	t3728: "HR-status",
	t3729: "Hr inschakelen",
	t3730: "HR Consumentenverzoek",
	t3731: "Shr3",
	t3732: "Shr4",
	t3733: "Shr2",
	t3734: "Shr8",
	t3735: "Zuig ref. To-MT",
	t3736: "Controlestatus MT",
	t3737: "Werkelijke zone MT",
	t3738: "Om te kiezen voor offset MT",
	t3739: "Rijdende capaciteit MT",
	t3740: "Gevraagde cap. MT",
	t3741: "Tijd tot volgende stap MT",
	t3742: "Po-MT",
	t3744: "Zuigtemp. To-MT",
	t3745: "Totale actieve energie",
	t3746: "Piekvraag",
	t3747: "Totale vermogensfactor",
	t3748: "Fase A volt",
	t3749: "Fase B volt",
	t3750: "Fase C volt",
	t3751: "Fase A-versterkers",
	t3752: "Fase B-versterkers",
	t3753: "Fase C-versterkers",
	t3754: "Totaal actief vermogen",
	t3755: "Zuigtemp. To-LT",
	t3756: "Zuigtemp. To-IT",
	t3757: "Zuigreferentie LT",
	t3758: "Controlestatus LT",
	t3759: "Werkelijke zone LT",
	t3760: "Om te kiezen voor offset LT",
	t3761: "Rijdende capaciteit LT",
	t3762: "Gevraagde cap. LT",
	t3763: "Tijd tot volgende stap LT",
	t3764: "Po-LT",
	t3765: "SD-LT-formaat",
	t3766: "Zuigreferentie IT",
	t3767: "Controlestatus IT",
	t3768: "Werkelijke zone IT",
	t3769: "Lopende capaciteit IT",
	t3770: "Gevraagde capaciteit IT",
	t3771: "Tijd voor de volgende stap IT",
	t3772: "Tn Po-IT",
	t2773: "SD-IT",
	t2774: "IT-Comp. start",
	t3774: "Niet binnen bereik. Moet tussen {{minAddr}} en {{maxAddr}} liggen.",
	t3775: "Bediening voor dit apparaat is uitgeschakeld",
	t3776: "Wanneer u deze actie uitvoert, wordt de applicatie uitgelogd. Dit kan enkele ogenblikken duren.",
	t3777: "De migratiebestanden (flp of json) konden niet worden gevonden",
	t3778: "Peiling",
	t3779: "Het stemmen is momenteel opgeschort.",
	t3780: "Wilt u de stemming hervatten?",
	t3781: "Energiemeter",
	t3782: "Meerdere knooppunten geselecteerd",
	t3783: "Zoom naar knooppunt",
	t3784: "Meeteenheden",
	t3785: "Bestand(en) succesvol geëxporteerd",
	t3786: "Adres kan niet negatief zijn",
	t3787: "Zoom resetten",
	t3788: "Fout bij het ophalen van stagingpatroonstappen",
	t3789: "Fout bij het opslaan van stagingpatroonstappen.",
	t3790: "Stappen van het stagingpatroon succesvol opgeslagen.",
	t3791: "Stralingspatroon",
	t3792: "Druk op de ontladen pictogrammen om de ontladers die aan de compressoren zijn gekoppeld in of uit te schakelen",
	t3793: "Ontbrekende gegevens",
	t3794: "De volgende parameters hebben geen datapunten opgeleverd.",
	t3795: "HTTPS/SSL-vertrouwen was succesvol.",
	t3796: "U kunt nu inloggen op de StoreView-webbrowser.",
	t3797: "Doorverwijzen naar StoreView-webbrowser over {{timeLeft}} seconden...",
	t3798: "Stemmen is nu opgeschort!",
	t3799: "Gewiste alarmen (volledige systeemlogboeken)",
	t3800: "Het maximaal toegestane aantal HVAC-systemen is 45.",
	t3801: "Het is niet gelukt om het wijzigingslogboek op te halen",
	t3802: "Verbinding maken met opslag...",
	t3803: "Time-out bij SCADA-opslagverbinding",
	t3804: "Verbinding met SCADA-opslag mislukt",
	t3805: "Ongeldige datum",
	t3806: "Selecteer eenheid(en) voor het downloaden van het rapport"
};
var nl = {
	lang: lang
};

var lang$1 = {
	t0: "Active Alarms",
	t1: "Cleared Alarms",
	t2: "Host",
	t3: "ID",
	t4: "Device Name",
	t5: "Edit Notes",
	t6: "Not Authorized to Acknowledge Alarm",
	t7: "Alarm Type",
	t8: "Occurred",
	t9: "Acknowledge",
	t10: "Active Alarm(s)",
	t11: "Cleared",
	t12: "Calculations",
	t13: "Cleared Alarm(s)",
	t14: "Mute/Ack",
	t15: "Acknowledge all unacknowledged alarms",
	t16: "Authorization failed!",
	t17: "Error",
	t18: "Manual Control",
	t19: "Visual",
	t20: "Summary Data",
	t21: "Technical View",
	t22: "Graph (S4)",
	t23: "Node",
	t24: "Mod",
	t25: "Graph (Po & Pc)",
	t26: "(no alarms detected)",
	t27: "Device Alarms",
	t28: "View previous device",
	t29: "View next device",
	t30: "Error in reading alarms.",
	t31: "Current Alarms",
	t32: "Current Alarm",
	t33: "No alarms detected.",
	t34: "Type",
	t35: "Point",
	t36: "History Log",
	t37: "Schedules",
	t38: "Start time",
	t39: "Stop Time",
	t40: "Days",
	t41: "Holidays",
	t42: "Status",
	t43: "Select Datapoints",
	t44: "OK",
	t45: "Cancel",
	t46: "Start",
	t47: "End",
	t48: "Start date",
	t49: "Sample rate",
	t50: "No datapoints selected",
	t51: "Now",
	t52: "sec",
	t53: "min",
	t54: "hr",
	t55: "Day",
	t56: "Address Type",
	t57: "Address",
	t58: "Location:",
	t59: "Input New Value",
	t60: "Refresh",
	t61: "Select Datapoints",
	t62: "Select the New Value",
	t63: "Off",
	t64: "On",
	t65: "Retrieving datapoints...",
	t66: "Error in retrieving data...",
	t67: "No history configured...",
	t68: "Error in retrieving datapoints...",
	t69: "Retrieving history samples for",
	t70: "Error in retrieving history samples...",
	t71: "User Name",
	t72: "Password",
	t73: "Host IP/URL Address:",
	t74: "OK",
	t75: "Cancel",
	t76: "Name",
	t77: "Value",
	t78: "Error in retrieving user list",
	t79: "Input New Address",
	t80: "Get User List Failed, Check Host IP/URL Address Please",
	t81: "Auth Code",
	t82: "Acct Code",
	t83: "Select New Time and Date",
	t84: "Select New Duration",
	t85: "Operation Failed",
	t86: "Enter Authorization Information",
	t87: "HVAC",
	t88: "Go!",
	t89: "Defrost Control",
	t90: "Start Manual Defrost",
	t91: "Case Lighting Control",
	t92: "Manual Case Lights On",
	t93: "Manual Case Lights Off",
	t94: "Case Cleaning Control",
	t95: "Manual Case Cleaning On",
	t96: "Manual Case Cleaning Off",
	t97: "Main Switch",
	t98: "Manual Main Switch On",
	t99: "Manual Main Switch Off",
	t100: "Shutdown Control",
	t101: "Shut Device Down",
	t102: "Turn Device On",
	t103: "Night Setback Control",
	t104: "Night Setback On",
	t105: "Night Setback Off",
	t106: "Message sent...",
	t107: "Manual Control",
	t108: "Close",
	t109: "Lighting",
	t110: "Power/Misc",
	t111: "Overview",
	t112: "Select AK255 unit to display.",
	t113: "Loading...",
	t114: "of",
	t115: "All AK255s",
	t116: "Previous",
	t117: "Pages",
	t118: "Next",
	t119: "Parameter",
	t120: "Measurements",
	t121: "Retrieving data...",
	t122: "Error in reading section",
	t123: "Temperature",
	t124: "Delay after defrost",
	t125: "Alarm if below",
	t126: "Alarm if above",
	t127: "Monitoring",
	t128: "Offline",
	t129: "Active",
	t130: "Host",
	t131: "Address",
	t132: "Description",
	t133: "Current Value",
	t134: "Current Status",
	t135: "Controllers",
	t136: "Unit",
	t137: "Active Alarm Exists",
	t138: "Active Alarms Exist!",
	t139: "On-Off Inputs",
	t140: "Relay Outputs",
	t141: "Sensor Inputs",
	t142: "Variable Outputs",
	t143: "Generic Controllers",
	t144: "Others",
	t145: "Visualization Point",
	t146: "Store Overview",
	t147: "Log Out from The System",
	t148: "Log On",
	t149: "Log Off",
	t150: "Refrigeration",
	t151: "Device Overview",
	t152: "Alarms",
	t153: "History",
	t154: "CS-Overview",
	t155: "Store Overview",
	t156: "Configuration",
	t157: "Site View",
	t158: "Plant View",
	t159: "Home",
	t160: "Alarm",
	t161: "Setpoint",
	t162: "Device Detail",
	t163: "Setting",
	t164: "Select Date",
	t165: "Information",
	t166: "Audit Trail",
	t167: "Calculations",
	t168: "File Management",
	t169: "Help Manual",
	t170: "Info",
	t171: "Translator",
	t172: "Save to Server",
	t173: "Update Language Files?",
	t174: "This operation will update the language files on unit. Continue?",
	t175: "Export graph",
	t176: "Select New Value",
	t177: "minimum",
	t178: "maximum",
	t179: "No Active Alarm!",
	t180: "Not Changeable!",
	t181: "Please Wait...",
	t182: "Command Sent!",
	t183: "Operation failed, please try again later!",
	t184: "Operation succeeded!",
	t185: "Not Authorized Operation!",
	t186: "Are you sure to continue this operation?",
	t187: "EVAP",
	t188: "PACK",
	t189: "Monitoring Inputs",
	t190: "MISC",
	t191: "Acknowledged Alarms",
	t192: "Clear Acknowledged Alarms",
	t193: "Clear All Acknowledged Alarms",
	t194: "You are not authorized to acknowledge alarms",
	t195: "Not Authorized",
	t196: "Do you want to acknowledge this alarm?",
	t197: "Confirm to Acknowledge Alarm",
	t198: "Please Logon First",
	t199: "Please select an alarm to clear",
	t200: "Confirm to Clear Alarms",
	t201: "Are you sure to clear this alarm?",
	t202: "Not Authorized to Clear Alarm",
	t203: "Are you sure to clear all alarms?",
	t204: "Selected alarms cannot be cleared",
	t205: "Alarm Setting",
	t206: "Must be Authorized as Supervisor to Perform this Operation",
	t207: "No data returned from unit",
	t208: "Select A Unit",
	t209: "Please select a unit from the list",
	t210: "Number of Alarms",
	t211: "Alarm Status",
	t212: "Miscellaneous",
	t213: "Clear Selected Alarm",
	t214: "Please Confirm",
	t215: "Connection to Unit Failed, Please Check the Network Connection!",
	t216: "Defrost Alarm",
	t217: "Digital Alarm",
	t218: "Digital Input Status",
	t219: "Defrost Input",
	t220: "Cleaning Input",
	t221: "Digital Input",
	t222: "Saving Language Files",
	t223: "Units",
	t224: "No Device Configured in System, Website Disabled.",
	t225: "Warning:",
	t226: "Select Time",
	t227: "Communications",
	t228: "Download Report File?",
	t229: "Please wait while generating report file...",
	t230: "Download Report",
	t231: "Alarm Status/Service",
	t232: "Please Wait While Retrieving Data...",
	t233: "Logged On",
	t234: "User Logged Off",
	t235: "User Logged On",
	t236: "Welcome!",
	t237: "Invalid license",
	t238: "Energy Meter Controllers",
	t239: "Lighting Panels",
	t240: "VLT Inverter",
	t241: "Compressor Module",
	t242: "Device Controller Points",
	t243: "Calculated Points",
	t244: "Cutler-Hammer Panels",
	t245: "Carriers",
	t246: "Advanced View",
	t247: "Double Click Here to Zoom In/Out",
	t248: "Click Here to Zoom In/Out",
	t249: "Acknowledged Alarm(s)",
	t250: "Simulator",
	t251: "Refrig Task",
	t252: "HVAC Task",
	t253: "Lighting Task",
	t254: "MISC Task",
	t255: "Network Connected",
	t256: "Drive",
	t257: "Reference",
	t258: "Graph (Drive Mtr Hz & Amp)",
	t259: "Stop Date",
	t260: "Stop",
	t261: "The stop time is on or before start time, please reselect the date/time",
	t262: "The history creation time of datapoint(s)",
	t263: "are later than the start time specified",
	t264: "The returned number of samples from each history point has reached 100,000, it would take a long time to get the response. You may reduce this number by decreasing the sample rate OR change the date/time.",
	t265: "Unit history query task is busy, please wait for about 2 minutes and try again...",
	t266: "Save",
	t267: "History Control Panel",
	t268: "First",
	t269: "Last",
	t270: "Print",
	t271: "Zoom Out",
	t272: "Zoom In",
	t273: "Show/Hide Legend",
	t274: "Export graph data file",
	t275: "Show/Hide Grid",
	t276: "Single Page View",
	t277: "Multi Page View",
	t278: "Load History Group from File",
	t279: "Load History from File",
	t280: "Save History Group to File",
	t281: "Multi Page View Count",
	t282: "Page",
	t283: "Select a page to display",
	t284: "History file must be in .hst format!",
	t285: "No data returned, please check the history point selection and date/time selected",
	t286: "History Snapshot",
	t287: "History Full View",
	t288: "History File View",
	t289: "Choose...",
	t290: "This operation will export the current history view to a .hst/.csv file.",
	t291: "Please choose a target file.",
	t292: "File",
	t293: "Load History Group...",
	t294: "Load History...",
	t295: "Save History Group...",
	t296: "View",
	t297: "Zoom",
	t298: "Yes",
	t299: "No",
	t300: "Retrieve configuration from controller?",
	t301: "Don't ask again",
	t302: "Retrieve Controller Configuration",
	t303: "Data Retrieved",
	t304: "Alarm Level",
	t305: "Alarm Action",
	t306: "Acknowledged",
	t307: "Save Database to Disk",
	t308: "File Name",
	t309: "Database Description",
	t310: "Database Path",
	t311: "Version",
	t312: "Time",
	t313: "Export Alarms",
	t314: "Database Saved to",
	t315: "Database saving failed, please check if directory and filename are valid!",
	t316: "File name is not correct, please check the database file name",
	t317: "Alarms export succeeded. Please choose a file to save the exported alarms",
	t318: "Please Select Alarms to Export",
	t319: "Retrieving Alarm Information from Unit(s)",
	t320: "File Description",
	t321: "Convert .hst file to .csv file format",
	t322: "Step 1: Select the .hst File",
	t323: "Step 2: Select the Datapoints and Time Range",
	t324: "Step 3: Select the Target .csv File",
	t325: "Save as...",
	t326: "No more than 6000 datapoints allowed in this operation",
	t327: "Datapoints Selected",
	t328: "It is not allowed to export more than 65,536 samples per data point, please change the sample rate or time range.",
	t329: "Collect",
	t330: "Collect history data from unit",
	t331: "Select collected datapoints",
	t332: "No Value",
	t333: "Missing",
	t334: "Suspended",
	t335: "Please Make A Selection",
	t336: "Select Data Points to Draw",
	t337: "Save to .hst File",
	t338: "Data downloading finished, please select one of the following options:",
	t339: "Select at most 8 points to draw",
	t340: "Save collected history data to .hst file",
	t341: "Overview Print",
	t342: "Please Select Save or Print Overview Report",
	t343: "Choose A File Format",
	t344: "Generating Overview File, Please Wait...",
	t345: "The selected database file exists, do you want to overwrite it?",
	t346: "Dashboard",
	t347: "Graphic View",
	t348: "Help",
	t349: "System View",
	t350: "Map",
	t351: "Translation Tool",
	t352: "Manual",
	t353: "Request alarms...",
	t354: "Updated",
	t355: "Model",
	t356: "Control",
	t357: "Oil",
	t358: "Temp",
	t359: "Maximize",
	t360: "Restore",
	t361: "Go back...",
	t362: "Comm failed!",
	t363: "Comm status ok.",
	t364: "Comm message sent.",
	t365: "Loading Home Component",
	t366: "Loading Configuration Component",
	t367: "Ctrl Temp",
	t368: "This setting will be effective only when the history is redrawn, do you want to continue?",
	t369: "Loading, please wait",
	t370: "datapoints",
	t371: "No unit name",
	t372: "Preferences",
	t373: "Simulator Version",
	t374: "Energy",
	t375: "Go to overview window",
	t376: "System alarm or no related device found",
	t377: "Concurrent schedules",
	t378: "Missing language file.",
	t379: "Version not supported",
	t380: "Input a unit's IP address here (eg. 192.168.1.1)",
	t381: "Please enter a unit's IP address or URL.",
	t382: "Hide Detail",
	t383: "Show Detail",
	t384: "Outside Temp",
	t385: "Return",
	t386: "Supply",
	t387: "Humidity",
	t388: "Dewpoint",
	t389: "Fan",
	t390: "Zone",
	t391: "Shutdown",
	t392: "Split",
	t393: "Suction Temp",
	t394: "Current Capacity",
	t395: "Suction Pressure",
	t396: "Discharge",
	t397: "Compressors",
	t398: "Condenser",
	t399: "Condenser Target",
	t400: "Refrigerant",
	t401: "Suction Target",
	t402: "Suction",
	t403: "Defrost",
	t404: "Danfoss History Format",
	t405: "Text Format",
	t406: "This operation needs a unit reset, do you want to reset the unit now?",
	t407: "Unit reset succeeded, please reload the web site.",
	t408: "Factory Application Mode",
	t409: "Calculating...",
	t410: "minutes",
	t411: "Night Setback",
	t412: "Case Lights",
	t413: "Schedule Concurrency",
	t414: "(10 minute resolution)",
	t415: "View Text",
	t416: "Export as CSV",
	t417: "Export as PNG",
	t418: "View Graph",
	t419: "View Text",
	t420: "Schedule",
	t421: "Duration",
	t422: "Term Temp",
	t423: "NONE",
	t424: "Control Temp",
	t425: "Running from",
	t426: "Database loaded from",
	t427: "Statistics",
	t428: "Select File",
	t429: "Choose the history file you wish to convert to CSV format...",
	t430: "Finish",
	t431: "Summary",
	t432: "History Data Files (*.hst)",
	t433: "History Conversion Wizard",
	t434: "File creation complete.",
	t435: "Choose the datapoints you wish to convert to CSV format...",
	t436: "Select Time Range",
	t437: "Select the time range and press the process button...",
	t438: "Process Data",
	t439: "Preparing history data, please wait",
	t440: "Select CSV filename and save data to file...",
	t441: "Cannot find history data point",
	t442: "Error - No history data returned",
	t443: "There was an error communicating with units over the host network. Found",
	t444: "Attention",
	t445: "Silence",
	t446: "Database not saved, Do you really want to close the window?",
	t447: "Table View",
	t448: "Show Graphic",
	t449: "Show Table",
	t450: "Manual Defrost On",
	t451: "Manual Defrost Off",
	t452: "Auto Defrost",
	t453: "Set Defrost Control",
	t454: "Unloader on",
	t455: "Unloader off",
	t456: "Liquid",
	t457: "No schedules configured.",
	t458: "StoreView",
	t459: "Lost communication with a unit on the host network!",
	t460: "Quick Setup",
	t461: "Street",
	t462: "City",
	t463: "Phone",
	t464: "Region",
	t466: "Index",
	t469: "Address Book Exported Successfully",
	t470: "Delete item",
	t471: "Gauge",
	t472: "Note: input is case sensitive",
	t473: "Online",
	t474: "RO Board",
	t475: "SI Board",
	t476: "OI Board",
	t477: "Calculation",
	t478: "Set to Current Time",
	t479: "Rack",
	t480: "No Compressor",
	t481: "Circuit",
	t482: "Case",
	t483: "Unknown",
	t484: "err type:",
	t485: "Add",
	t486: "Copy",
	t487: "Remove",
	t488: "Case Control",
	t489: "Case Type",
	t490: "LON",
	t491: "Enter amount to copy",
	t492: "no name",
	t493: "Configuring refrigeration layout...",
	t494: "Configuration error:",
	t495: "Please verify configuration.",
	t496: "The refrigeration layout setup has completed successfully. Press the ok button to exit this wizard.",
	t497: "Configuration Status",
	t498: "Final Refrigeration Layout",
	t500: "Code #",
	t501: "Layout",
	t502: "System Setup",
	t503: "Licensing",
	t504: "Users",
	t505: "Not Authorized",
	t506: "Please verify...",
	t507: "Are you sure you want to leave this screen? Your settings may be lost.",
	t508: "Scanning controller network...",
	t509: "Scanning",
	t510: "Verifying",
	t511: "AK-I/O",
	t512: "Rescan",
	t513: "Include offline programming",
	t514: "Device Bus",
	t515: "Online devices",
	t516: "Offline programming",
	t517: "Duplicate address",
	t518: "Compressor Controls",
	t519: "Suction Groups",
	t520: "N/A",
	t521: "Available Cases",
	t522: "Allow multi-case circuit creation",
	t523: "Insert devices",
	t524: "Insert circuit",
	t525: "Multi-case circuit.",
	t526: "Loading tables...",
	t527: "Requesting alarm data for",
	t528: "History Group Files",
	t529: "History Data Files",
	t530: "All Files",
	t531: "Show Symbols",
	t532: "Show Curves",
	t533: "Not enough history has been collected.",
	t534: "Retrieve the latest history data.",
	t535: "Status/Settings",
	t536: "Now downloading",
	t537: "history samples...",
	t538: "Retrieving",
	t539: "Datapoints...",
	t540: "Interval",
	t541: "Min",
	t542: "Max",
	t543: "Average",
	t544: "Go to main alarm window.",
	t545: "Stop auto-logon",
	t546: "Connecting to",
	t547: "Cancel auto-logon",
	t548: "Please enter log-off password",
	t549: "Web 1",
	t550: "Web 2",
	t551: "Log on automatically",
	t552: "Log off after inactivity period",
	t553: "Home screen",
	t554: "Show configuration menu",
	t555: "Show misc items on Dashboard",
	t556: "Use full screen",
	t557: "Enable alarm sound",
	t558: "Allow program close/minimize",
	t559: "Error accessing unit #",
	t560: "Connected to network.",
	t561: "Auto-logon cancelled",
	t562: "Retry connect to",
	t563: "Logged out from",
	t564: "Connected",
	t565: "Time Format",
	t566: "Date Format",
	t567: "Pressure",
	t568: "Light Level",
	t569: "Subcool",
	t570: "Subcooler",
	t571: "Daily",
	t572: "Weekly",
	t573: "HST File",
	t574: "CSV File",
	t575: "Sunday",
	t576: "Monday",
	t577: "Tuesday",
	t578: "Wednesday",
	t579: "Thursday",
	t580: "Friday",
	t581: "Saturday",
	t582: "History Log Collection",
	t583: "Enable auto-collect",
	t584: "When to collect",
	t585: "File save location",
	t586: "Select file format",
	t587: "Select folder to store history files...",
	t588: "Enable automatic history collection to retrieve and save daily or weekly history logs of the store's configured history points.",
	t589: "To view HST history files go to the main history screen and select import history. To view CSV files use any spreadsheet application.",
	t590: "If the current connection's IP address is found in the address book the selected history points will be saved.",
	t591: "Select data-points",
	t592: "All selected",
	t593: "Points selected",
	t594: "Search",
	t595: "Auto-connecting to",
	t596: "Please verify",
	t597: "Automatic history collection is enabled. Are you sure you want to close this program?",
	t598: "Are you sure you want to close this program?",
	t599: "Action",
	t600: "Filter",
	t601: "All actions",
	t602: "More information...",
	t603: "Editable",
	t604: "Operation",
	t605: "Operation Cancelled",
	t606: "Database Downloading Finished",
	t607: "Units' Database Updated",
	t608: "File(s) Transferred to the Unit Successfully",
	t609: "Downloading finished, file is under processing in the unit",
	t610: "Error happened in uploading file to PC, please check if the file is writable and accessible",
	t611: "Uploading File",
	t612: "Downloading File",
	t613: "Uploading selected file(s)",
	t614: "Downloading selected file(s)",
	t615: "Deleting File",
	t616: "Deleting selected file(s)",
	t617: "FTP Connection Error",
	t618: "Download Selected File(s) to the Unit",
	t619: "Error happened in FTP connection. Please check FTP connection and password/user name.",
	t620: "FTP connected",
	t621: "Bytes",
	t622: "Files on the unit would be overwritten by this operation, Do you want to continue?",
	t623: "Files on the PC would be overwritten by this operation, Do you want to continue?",
	t624: "Files have been downloaded to the unit.",
	t625: "Refrigeration ONLY",
	t626: "Convenience Store",
	t627: "Full Functionality",
	t628: "HVAC ONLY",
	t629: "File Name is illegal; Please make sure the file name has less than 8 characters.",
	t630: "Overwrite the Application Code Now?",
	t631: "Later",
	t632: "uploaded",
	t633: "you may choose YES to overwrite application now or choose Later",
	t634: "Please Select Simulator Root Directory",
	t635: "Please Select the Windows Directory",
	t636: "Please Select the Target Directory",
	t637: "Select Database Directory",
	t638: "FTP disconnected",
	t639: "Connection Timeout, FTP Disconnected",
	t640: "Upload Selected File(s) to PC",
	t641: "Delete Selected File(s)",
	t642: "FTP Server Port",
	t643: "Web Server Port",
	t644: "Local Path",
	t645: "Up",
	t646: "Size",
	t647: "Date",
	t648: "Connect",
	t649: "Disconnect",
	t650: "Remote Path",
	t651: "Free Space Available:",
	t652: "Selected File(s)",
	t653: "Files",
	t654: "Total Size:",
	t655: "Click to change folder...",
	t656: "Change...",
	t657: "Do you want to delete the selected file(s)?",
	t658: "Connection Closed, Exit",
	t659: "The selected file(files) name(names) is(are) illegal and the download is ignored.",
	t660: "Some of the selected files' names are illegal and ignored.",
	t661: "File(s) Transferred to PC Successfully",
	t662: "Not Enough Free Disk Space on the Unit.",
	t663: "Transfer file(s)",
	t664: "Modified",
	t665: "Copy Files from Flash Disk to RAM Disk",
	t666: "Please Select A File Copy Operation :",
	t667: "Copy All the Configured EDF Files from Flash Disk",
	t668: "Copy All the EDF Files from Flash Disk",
	t669: "The edf/ed3 files are stored on the flash disk of your unit but not loaded into the File Management screen.  Use the options below to copy the stored EDF files in your unit to the File Management screen",
	t670: "This Operation Copies all the EDF files that has been configured in the unit from flash disk to RAM disk",
	t671: "This Operation Copies all the EDF files in the unit from flash disk to RAM disk",
	t672: "No Valid EDF File Exists",
	t673: "EDF File Copy Finished",
	t674: "files copied",
	t675: "Confirm database download",
	t676: "Unit Address",
	t677: "IP Address/Hostname",
	t678: "Database File Name",
	t679: "Select",
	t680: "Update complete, resetting and upgrading unit. This can take several minutes.",
	t681: "Welcome to Upgrade Manager - Step 1 of 2",
	t682: "Welcome to Upgrade Manager - Step 2 of 2",
	t683: "Proceed",
	t684: "Pre-Upgrade Check",
	t685: "Locate Firmware",
	t686: "Backup Database?",
	t687: "Backing up Database",
	t688: "Transferring Software",
	t689: "Overwriting Software",
	t690: "Select Software to Upgrade",
	t691: "No factory fail safe application detected.",
	t692: "You may continue to proceed but any errors during the update process may render the system inoperable.",
	t693: "It is strongly recommended not to proceed.",
	t694: "Do you want to continue?",
	t695: "Remotely upgrading a system does carry a risk of failure, which may result in system error.",
	t696: "Disclaimer",
	t697: "Database Uploading Finished",
	t698: "Backing up Database Finished",
	t699: "Application Downloading Finished",
	t700: "Application Overwriting Finished",
	t701: "Software Upgrade Succeeded",
	t702: "Do you want to reset the unit to its factory application?",
	t703: "Checking Hardware",
	t704: "Checking Device Status",
	t705: "Factory Fail Safe",
	t706: "Current Firmware Version",
	t707: "Start Upgrade",
	t708: "Select an existing database file",
	t709: "Select an existing firmware file",
	t710: "Back",
	t711: "Locate Firmware",
	t712: "Select .csi file",
	t713: "Select .mai File",
	t714: "Remote downloading is not supported by this CPU board.",
	t715: "Application Downloading Failed",
	t716: "Database Upload Failed, Please Try Again",
	t717: "Application Overwriting Finished",
	t718: "Application Overwriting Failed! Please Try Again.",
	t719: "Application Overwriting Failed",
	t720: "No factory fail safe application detected.",
	t721: "Done",
	t722: "The selected application may have a different version type than the current unit's firmware, continue the operation may carry a risk of failure and system error.",
	t723: "The selected file(files) name(names) is(are) illegal and the download is ignored.",
	t724: "Some of the selected files' names are illegal and ignored.",
	t725: "Unit's berg pin is missing.",
	t726: "Unit's battery is low.",
	t727: "Unit's host network failed.",
	t728: "Unit is in load shed status",
	t729: "Select destination file",
	t730: "Select destination directory",
	t731: "Database Uploading Finished",
	t732: "Status OK",
	t733: "Status Error",
	t734: "Status Unknown",
	t735: "This operation may overwrite the selected files in the target simulator, do you want to continue?",
	t736: "Downloading database finished, you need to reset the system",
	t737: "Downloading database failed, you need to try again later",
	t738: "The unit will be reset automatically after this operation, Do you want to continue?",
	t739: "Select Database",
	t740: "Download Database File?",
	t741: "Upload Database File?",
	t742: "Downloading database to the unit, please wait",
	t743: "Database Downloaded Successfully, Now Resetting the Unit",
	t744: "Database update Finished, Unit Reset Successfully",
	t745: "Updating Database Failed! Please Try Again.",
	t746: "Error in updating database",
	t747: "Pre-Download Check",
	t748: "Locate Database",
	t749: "Downloading Database",
	t750: "Current Database Information",
	t751: "Select Database to Download",
	t752: "Save Database to PC (Upload)",
	t753: "Load Database to the Unit (Download)",
	t754: "Needs Supervisor Authorization",
	t755: "Start Upload",
	t756: "Please Select A Database Operation:",
	t757: "Start Download",
	t758: "Current Firmware Version",
	t760: "Database download succeeded!",
	t761: "Welcome to Database Manager - Step 1 of 2",
	t762: "Welcome to Database Manager - Step 2 of 2",
	t763: "Connection to Unit Failed, Please Check Host IP/URL Address",
	t764: "Remotely upgrading a system's database does carry a risk of failure, which may result in system error.",
	t765: "Database downloading finished",
	t766: "Ethernet Scan",
	t767: "Enter first IP address then press start",
	t768: "Scan",
	t769: "Scanning",
	t770: "History Log Collection",
	t771: "Analog graph colors",
	t772: "Digital graph colors",
	t773: "Shutdown Schedules",
	t774: "Defrost Summary",
	t775: "Night setback schedules",
	t776: "Case Lighting Schedules",
	t777: "Loading Status",
	t778: "Alarm Log Collection",
	t779: "Select folder to store alarm files...",
	t780: "Monthly",
	t781: "Enable automatic alarm collection to retrieve and save alarm information to a file on a periodic basis.",
	t782: "CSV Separator",
	t783: "No Alarms",
	t784: "Retrieving alarm counts for unit:",
	t785: "Retrieving alarm details from unit:",
	t786: "Automatic alarm collection is enabled. Are you sure you want to close this program?",
	t787: "Hourly",
	t788: "This will update the unit's database and set the location to the center of the current view. Proceed?",
	t789: "samples processed",
	t790: "Please configure a suction group.",
	t791: "To re-use the Layout Wizard more than once the refrigeration database layout needs to be reset.",
	t792: "Do this via the Configuration->Control page and set the number of Racks/Packs to zero.",
	t793: "Note: any schedules, calculations or imports based on prior refrigeration configuration will need to be reconfigured.",
	t794: "Copy process finished.",
	t795: "Refrigeration Report",
	t796: "Final HVAC Layout",
	t797: "The HVAC layout setup has completed successfully. Press the ok button to exit this wizard.",
	t798: "Configuring HVAC layout...",
	t799: "To re-use the HVAC Layout Wizard more than once the HVAC database layout needs to be reset.",
	t800: "Do this via the Configuration->Control page and set the number of HVAC units to zero.",
	t801: "Note: any schedules, calculations or imports based on prior HVAC configuration will need to be reconfigured.",
	t802: "Staging pattern (requires unit reset to activate)",
	t803: "Steps",
	t804: "Level",
	t805: "Show Multiple DataTip",
	t806: "Number Of Steps",
	t807: "Could not save file.",
	t808: "Incorrect model.",
	t809: "Load successful.",
	t810: "Open",
	t811: "This feature is only available in the desktop version of StoreView.",
	t812: "Continue",
	t813: "Leave Wizard",
	t814: "Operation succeeded. Would you like to continue or you want to leave the wizard.",
	t815: "Error while fetching alarm logger data.",
	t817: "Device Name :",
	t819: "In Progress...",
	t820: "Alarm data is not present.",
	t821: "Alarm Count",
	t822: "Mimic Display Options",
	t823: "Display only text",
	t824: "Display only icon",
	t825: "Display text and icon",
	t826: "1 Week",
	t827: "2 Weeks",
	t828: "3 Weeks",
	t829: "4 Weeks",
	t830: "Graphic Settings",
	t831: "Unable to display alarm diagnostics, please check auto alarm collection under preferences",
	t832: "Reordering not allowed with IO racks or circuits included in configuration.",
	t833: "This Host IP/URL Address is already present in address book. Do you like to update that entry?",
	t834: "Browse",
	t835: "Select Address Book Folder",
	t836: "Location",
	t837: "Selected location is not having any address book contents.Would you like to export contents of the existing address book to the selected location?",
	t838: "Alarm Action Codes",
	t839: "Address book file is not found in the selected path. Address book is loaded from local...",
	t840: "Please select location to save logs.",
	t841: "Please select a valid location to save logs.",
	t842: "Alarm log collection is in progress...",
	t843: "History log collection is in progress...",
	t844: "Run In Background",
	t845: "Alarm log progress",
	t846: "Host IP/URL Address should not be blank",
	t847: "Address field should not be blank",
	t849: "You don't have latest version of the file, would you like to load data from the latest version?",
	t850: "Version conflict",
	t851: "History Log Progress",
	t852: "User Name and Password fields should not be blank.",
	t853: "IP Address field should not be blank.",
	t854: "Host IP/URL Address field should not be blank.",
	t855: "User name should not be blank.",
	t856: "Password should not be blank.",
	t857: "The SM800 you have connected to with",
	t858: "has not had the Initial Startup Wizard completed. Please complete this task and reconnect to the SM800",
	t859: "Set to now",
	t860: "Invalid input",
	t861: "Text is too long",
	t862: "Offset",
	t863: "Unable to communicate with unit(s) on host network",
	t864: "Please verify addresses are different. Configuration is updated when the save button is pressed.",
	t865: "Please select a valid History File (.hst)",
	t866: "Please select a valid History Group File (.grp)",
	t867: "Color",
	t868: "Percentage",
	t869: "Voltage",
	t870: "Current",
	t871: "Power",
	t872: "Frequency",
	t873: "Concentration",
	t874: "Flow",
	t875: "Speed",
	t876: "Acidity",
	t877: "Please select charts at the bottom Drawer",
	t878: "Save PNG",
	t879: "Draw",
	t880: "System Status...",
	t881: "System and unit status",
	t882: "Gataway",
	t883: "Features licenses",
	t884: "Software version",
	t885: "Browser module version",
	t886: "Danux version",
	t887: "Mac Address",
	t888: "Support & Updates",
	t889: "Unit name",
	t890: "Reconnect",
	t891: "Not reachable. Check the IP configuration.",
	t892: "No {{type}} equipment found.",
	t893: "The currently used software version is not supported",
	t894: "Quick links",
	t895: "Equipment",
	t896: "Graphing",
	t897: "Utilities",
	t898: "Software rollback",
	t899: "Diagnostics",
	t900: "An error occurred",
	t901: "No {{alarms}} data found",
	t902: "Settings",
	t903: "Application name",
	t904: "Application version",
	t905: "Message",
	t906: "Event type",
	t907: "Timestamp",
	t908: "Rollback version",
	t909: "Current version",
	t910: "Software",
	t911: "Rollback...",
	t912: "Please select equipment from the list",
	t913: "Calendar View",
	t914: "An error occurred while loading devices(s), please try again.",
	t915: "An error occurred while loading schedules, please try again.",
	t916: "No equipment found",
	t917: "Schedules Export",
	t918: "Please choose CSV or PDF export type",
	t919: "Export as CSV",
	t920: "Preparing PDF",
	t921: "Export as PDF",
	t922: "Please provide an IP in this format xx.xx.xx.xx[:xxxx]",
	t923: "Username",
	t924: "Login",
	t925: "Rollback",
	t926: "Rollback SM800A software",
	t927: "This action will rollback all software to the listed versions.",
	t928: "Party Device Editor",
	t929: "some text which explains the feature",
	t930: "Update CDF",
	t931: "Map Device",
	t932: "Device file",
	t933: "1-based register number",
	t934: "Storage type",
	t935: "Min",
	t936: "Max",
	t937: "Read only",
	t938: "Log",
	t939: "Message Type",
	t940: "Conversion Keyword",
	t941: "Parameters",
	t942: "Addr.",
	t943: "Priority",
	t944: "Bit",
	t945: "Negated",
	t946: "New file",
	t947: "Open file",
	t948: "Generate file",
	t949: "Group",
	t950: "Product version",
	t951: "Error occurred",
	t952: "Not found",
	t953: "This device was not found",
	t954: "Outside temp.",
	t955: "No {{equipment}} found",
	t956: "Cases",
	t957: "Alarm successfully mute/act",
	t958: "Mute/act failed for this alarm",
	t959: "An error occurred while loading unit(s), please try again.",
	t960: "No equipment",
	t961: "No {{type}} equipment found",
	t962: "Configure the system manager",
	t963: "Device",
	t964: "Undo",
	t965: "You changed the value of field, do you want save the changes?",
	t966: "Clear selected history point(s)",
	t967: "Shr {{number}}",
	t968: "Hr pump speed",
	t969: "Sd",
	t970: "V3hr",
	t971: "Hr reference",
	t972: "Stw {{number}}",
	t973: "Tw reference",
	t974: "Tw pump speed",
	t975: "V3tw",
	t976: "Trec",
	t977: "Prec",
	t978: "Vrec OD",
	t979: "Prec reference",
	t980: "Trec reference",
	t981: "Pc",
	t982: "Ss",
	t983: "Vhp OD",
	t984: "Pgc reference",
	t985: "Ejector OD",
	t986: "Pgc",
	t987: "Shp",
	t988: "Sgc",
	t989: "Sc3",
	t990: "Sgc ctrl.",
	t991: "Cond. capacity",
	t992: "Po",
	t993: "Ss",
	t994: "Sd",
	t995: "Pc",
	t996: "Running capacity",
	t997: "Browser certificate",
	t998: "Log out",
	t1000: "Sorting only on {{count}}",
	t1001: "Loaded {{count}} of {{total}} alarms",
	t1002: "Acknowledge succeeded for all alarms",
	t1003: "Acknowledge failed for all alarms",
	t1004: "Acknowledged only {{count}} of {{total}} alarms",
	t1005: "Export failed for all alarms",
	t1006: "Export succeeded",
	t1007: "Export failed, at least one alarm type is required",
	t2000: "-",
	t2001: "Response is too large for the XML transmission buffer which is 32K bytes",
	t2002: "No attributes associated with cmd element or command has no \"cmd\" element or could not write to a variable or could not read a variable or could not read a point or could not write on/off input or relay output operation mode",
	t2003: "Bad command input",
	t2004: "Error in CGI post parameters",
	t2005: "Error converting the XML response from its internal representation to the response XML string",
	t2006: "Error decoding URL string",
	t2007: "Memory Overflow",
	t2008: "Response data compression failed",
	t2009: "Error parsing request",
	t2010: "Data access error - a number of causes that are not further resolved",
	t2011: "Undefined command action attribute value Attribute value is invalid",
	t2012: "Error in Alarm Reference Number or Alarm not configured",
	t2013: "The input cgi parameter string is too long.",
	t2014: "Device address is not found",
	t2015: "Failed in authorization to write",
	t2016: "No history configured",
	t2017: "No history data returned, can be time error",
	t2018: "Create file errors",
	t2019: "Read file errors",
	t2020: "Exceeded maximum size of device list",
	t2021: "not used",
	t2022: "Invalid History Index",
	t2023: "Could not find board point index from nodetype,node,mod, and point.",
	t2024: "Could not find History Index",
	t2025: "History query has not been initialized before attempting to start a history query.",
	t2026: "An hq_start_query has been attempted while a history query is active",
	t2027: "No command attributes found when some are expected",
	t2028: "A beginning of epoch has been provided without an end or vise-versa",
	t2029: "Could not convert from time and date format to epoch seconds",
	t2030: "Command requires a query_id but none provided",
	t2031: "Error building XML response",
	t2032: "The provided query_id does not match an active query",
	t2033: "An hq_get_data command has been attempted but data is not yet available",
	t2034: "A sample rate has been provided that is not one of those allowed",
	t2035: "The averaging interval is less than or equal to the sample rate at which data has been recorded",
	t2036: "The stop epoch time is less than the start epoch time or the stop epoch time minus the start epoch time is less than the averaging interval",
	t2037: "The device_id provided does not match any currently known to the ak255. Note that only devices that have been configured are known to the ak255",
	t2038: "No Id attributes",
	t2039: "Invalid Id attributes",
	t2040: "No schedule number",
	t2041: "Invalid schedule number",
	t2042: "No schedule details",
	t2043: "Missing on_time",
	t2044: "Missing hour",
	t2045: "Invalid hour",
	t2046: "Missing minute",
	t2047: "Invalid minute",
	t2048: "Missing off_time",
	t2049: "Missing weekdays",
	t2050: "Invalid weekdays",
	t2051: "Missing holidays",
	t2052: "Invalid holidays",
	t2053: "Missing or invalid holiday_start",
	t2054: "Missing or invalid holiday_end",
	t2055: "Missing or invalid holiday_open",
	t2056: "Missing or invalid holiday_close",
	t2057: "Holiday not configured",
	t2058: "Missing description",
	t2059: "Cannot clear alarm",
	t2060: "Missing or invalid store_open",
	t2061: "Missing or invalid store_closed",
	t2062: "Alarm Not Configured",
	t2063: "Node Offline",
	t2064: "Missing Index",
	t2065: "Invalid Index",
	t2066: "Relay Cannot be Timed On",
	t2067: "The name being changed has too many characters. If changing the store_name, or unit_name, must be <= 16 characters, if changing the storeId1, or storeId2, must be <= 8 characters.",
	t2068: "Missing elements associated with the command element.",
	t2069: "Tag_ID not defined",
	t2070: "Missing Leaf Node for Data",
	t2071: "No Change in Data",
	t2072: "Failed to update database",
	t2073: "Not writable",
	t2074: "Shed meter error",
	t2075: "No meters",
	t2076: "Invalid shed level",
	t2077: "Invalid type",
	t2078: "System busy",
	t2079: "Assets not configured for load shed",
	t2080: "Invalid Power Rating for Asset",
	t2081: "Invalid Start-up Delay for Asset",
	t2082: "Config Busy",
	t2083: "I/O Scanning in Progress",
	t2084: "Too Many Defrosts in Progress",
	t2085: "Trying to load a bad database version into unit",
	t2086: "Database load failed",
	t2087: "No available web memory available",
	t2088: "Demand response not configured (Only the AK355 system)",
	t2089: "Missing or incorrect file size.",
	t2090: "The requested file could not be found in the system",
	t2091: "Failed saving database to RAM disk.",
	t2092: "File size too large.",
	t2093: "Missing file name.",
	t2094: "Missing file id.",
	t2095: "Missing file load index (Note: this is the sequence index for load_file load_database).",
	t2096: "File id does not match or missing.",
	t2097: "File load operation already in progress.",
	t2098: "Decoded file does not match original file size.",
	t2099: "File load operation was canceled or not started.",
	t2100: "Invalid user request (Original requester does not match IP address)",
	t2101: "Invalid file load sequence.",
	t2102: "Invalid directory",
	t2103: "Invalid extension",
	t2104: "Invalid offset",
	t2105: "Error encoding file",
	t2106: "Error decoding file",
	t2107: "Failed to open file for read/write",
	t2108: "Failed to write to file",
	t2109: "File is write protected in Flash file system",
	t2110: "Failed to delete file.",
	t2111: "Cannot delete folder.",
	t2112: "Missing devices",
	t2113: "Not all devices from URL were found",
	t2114: "Missing history params",
	t2115: "Not all history params from the URL were found",
	t2200: "The currently used software version ({{currentVersion}}) is not recommended",
	t2201: "The currently used software version ({{currentVersion}}) is not recommended (>{{recommendedVersion}} recommended)",
	t2202: "Software version error",
	t2203: "Graphic Editor",
	t2204: "Edit",
	t2205: "Draw",
	t2206: "New view",
	t2207: "Created",
	t2208: "Drop",
	t2209: "New Graphic",
	t2210: "Create a new graphic view",
	t2211: "Name",
	t2212: "Rename",
	t2214: "Export",
	t2213: "Duplicate",
	t2215: "Delete",
	t2216: "Are you sure you want to delete \"{{name}}\" view?",
	t2217: "Export all views",
	t2218: "More...",
	t2219: "Rule",
	t2220: "rules",
	t2221: "Rule name",
	t2222: "Edit panel",
	t2223: "Conditions",
	t2224: "Gradient",
	t2225: "Invert conditions",
	t2226: "Mappings",
	t2227: "Map shapes by",
	t2228: "Shape ID",
	t2229: "States",
	t2230: "Add mapping",
	t2231: "Add rule",
	t2232: "Operator",
	t2233: "Add condition",
	t2234: "Start by adding rules",
	t2235: "Are you sure you want to delete \"{{name}}\" rule?",
	t2236: "A copy of rule is made",
	t2237: "Site views",
	t2238: "You have to create site views to view them",
	t2239: "Go to graphic editor",
	t2240: "Go to details",
	t2241: "Select parameter",
	t2242: "No parameters found",
	t2243: "Options",
	t2244: "On/Off Input",
	t2245: "Relay Output",
	t2246: "Sensor Input",
	t2247: "Variable Output",
	t2248: "Generic Device",
	t2249: "Empty Node",
	t2250: "History logging is not supported...",
	t2251: "Retry",
	t2252: "Could not fetch history",
	t2253: "Loading history can take a while and does not support multiple queries at the same time, please retry this request.",
	t2254: "Parameter name",
	t2255: "Device name",
	t2256: "Device type",
	t2257: "Not all history parameters could be loaded ({{n}} failed)",
	t2258: "Wrong user name or password",
	t2259: "No communication to the system-manager",
	t2260: "Software update",
	t2261: "System",
	t2262: "Software rollback",
	t2263: "Install",
	t2264: "The software rollback will replace a newer version",
	t2265: "Rollback software to the backup version {{version}}",
	t2266: "Database",
	t2267: "An update is available",
	t2268: "Export database",
	t2269: "Download",
	t2270: "Downloading database",
	t2271: "Can't export database",
	t2272: "Database file",
	t2999: "Communication error",
	t2998: "Unknown error",
	t3000: "Details",
	t3001: "Past XML code here",
	t3002: "Assign to",
	t3003: "State",
	t3004: "Software management",
	t3005: "Local stored file",
	t3006: "Online via internet",
	t3007: "Ensure power is not interrupted during the update process. It is recommended to make a backup of the system database before updating your system. After completing software update(s) it is recommended to inspect system operational status to confirm correct control and operation.",
	t3008: "No files selected",
	t3009: "Select software file",
	t3010: "Uploading files",
	t3011: "Security checking",
	t3012: "Extracting file",
	t3013: "Checking file",
	t3014: "File upload failed!",
	t3015: "Security check failed!",
	t3016: "Extracting failed!",
	t3017: "Checking file failed!",
	t3018: "Downloading file: {{fileName}}",
	t3019: "File successfully downloaded!",
	t3020: "Download failed!",
	t3021: "Upload files",
	t3022: "Download files",
	t3023: "OS Update",
	t3024: "Edf update",
	t3025: "Download files",
	t3026: "The value of the selected parameter was unfortunately not available",
	t3027: "Import",
	t3028: "Minimum one file is required",
	t3029: "File is not valid",
	t3030: "Manage graphic views",
	t3031: "Upload",
	t3032: "Migrate",
	t3033: "Reading graphic file(s), please wait ...",
	t3034: "Importing graphic file, please wait ...",
	t3035: "Choose graphic",
	t3036: "Reports",
	t3037: "Import database",
	t3038: "Select database file",
	t3039: "Ensure power is not interrupted during the rollback process. It is recommended to make a backup of the system database before rollback of your system. After completing software rollback it is recommended to inspect system operational status to confirm correct control and operation.",
	t3040: "The System Manager will restart after the update",
	t3041: "Expand",
	t3042: "Show debug messages",
	t3043: "Max. allowed characters - ",
	t3044: "Please upload update file",
	t3045: "Current SW version",
	t3046: "Current Danux version",
	t3047: "Latest SW version",
	t3048: "Latest Danux version",
	t3049: "Backing up please wait",
	t3050: "Installing file please wait",
	t3051: "Installing update",
	t3052: "Device is restarting",
	t3053: "Restarting system manager",
	t3054: "Browser will refresh now",
	t3055: "Software installed successfully",
	t3056: "Update failed, the following error occurred:",
	t3057: "Backup the current software version",
	t3058: "Backup the current EDF files",
	t3059: "Graphic view is saved successfully",
	t3060: "Apply background image",
	t3061: "Clear",
	t3062: "Redo",
	t3063: "Store",
	t3064: "Circuit night setbacks",
	t3065: "Suction night setbacks",
	t3066: "Downloaded version {{fileName}}",
	t3067: "To update the selected units, press 'Install'",
	t3068: "Rollback {{updateType}} version",
	t3069: "Installing",
	t3070: "Install rollback version",
	t3071: "None of the available units contains any rollback version",
	t3072: "New version available online",
	t3073: "Upload file for selected units",
	t3074: "Upload file for selected unit",
	t3075: "No rollback versions available for unit",
	t3076: "Download finished",
	t3077: "Current {{updateType}} version",
	t3078: "Latest {{updateType}} version",
	t3079: "Update is in progress, are you sure you want to close the window?",
	t3080: "Danux is not up to date! Please Update Danux then update the software again",
	t3081: "Applications cannot be downgraded to this version",
	t3082: "Application Information",
	t3083: "Release Notes",
	t3084: "Terms & Privacy",
	t3085: "Licenses",
	t3086: "Please go to configuration to set up schedules",
	t3087: "Unfortunately an error happened preparing the migration. Please ensure that all units are reachable on the network and properly configured.\n\nThe following error occurred:\n{{error}}",
	t3088: "Import or migrate an existing graphic",
	t3089: "Display",
	t3090: "Asset Name",
	t3091: "Collapse",
	t3092: "Import complete",
	t3093: "Discharge safety",
	t3094: "Suction safety",
	t3095: "Discharge Cutin",
	t3096: "Discharge Cutout",
	t3097: "Suction Cutout",
	t3098: "Suction Cutin",
	t3099: "Capacity",
	t3100: "The migration files (flp, dpj or json) could not be found",
	t3101: "Timeout parsing file",
	t3102: "Third party device files and addresses",
	t3103: "Grid",
	t3104: "Lock",
	t3105: "Xml",
	t3106: "Select parameter to display",
	t3107: "Device parameter",
	t3108: "No units",
	t3109: "There is no unit(s) found or loaded",
	t3110: "HACCP",
	t3111: "ASSET",
	t3112: "No haccp data available",
	t3113: "Unit Information",
	t3114: "Updates available",
	t3115: "Go to Software",
	t3116: "Not connected",
	t3117: "The current access scope for this application is '{{targetLevel}}', the provided user is above this scope in terms of access rights",
	t3118: "Unfortunately, an error occurred when generating the PDF, please try again or contact Product Support if the issue persists.",
	t3119: "PDF export failed",
	t3120: "On large datasets the interface might be unresponsive and take a while to complete",
	t3121: "Please select schedules from the list",
	t3122: "Please go to configuration to set up a schedule",
	t3123: "Success",
	t3124: "Warning",
	t3125: "Site Information",
	t3126: "Please select time and data points from the list",
	t3127: "Connecting",
	t3128: "Connection could not be established to this site, please contact support",
	t3129: "No HACCP data was available on the chosen date. Please ensure history and time is configured correctly in the configuration menu",
	t3130: "Diagnostic report",
	t3131: "Select unit(s) for diagnostic report export",
	t3132: "Not available at this user access level",
	t3133: "Diagnostics Log",
	t3134: "failed to download report",
	t3135: "Font",
	t3136: "Fontsize",
	t3137: "No views to migrate",
	t3138: "Download Desktop version",
	t3139: "Due to security restrictions in modern browsers (",
	t3140: "RFC1918",
	t3141: ") it is unfortunately not possible to connect to devices within a private-network space using unencrypted HTTP.",
	t3142: "No data available for the selected period",
	t3143: "Configure your system-manager to use HTTPS or use the desktop version.",
	t3144: "We could not communicate with the System Manager. This either means that your device cannot connect to the IP or Hostname of the System Manager, or that you may need to manually trust the SSL certificate used on the device.",
	t3145: "Click the following link to open a new tab in order to trust the certificate and then return to this screen.",
	t3146: "Signature",
	t3147: "DI - digital inputs",
	t3148: "AI - Analog Input",
	t3149: "Go to HACCP configuration",
	t3150: "No data",
	t3151: "The backup is saved in the System Manager for the Rollback feature",
	t3152: " Select what to display in Site view(s)",
	t3153: "Unit of measurement",
	t3154: "Graphic Files",
	t3155: "Unfortunatly an error happened fetching the edf files and versions from the system manager...",
	t3156: "To select which files to install, press the 'Choose files' button",
	t3157: "Choose files",
	t3158: "Removing backup",
	t3159: "Staging files",
	t3160: "The backup is saved in the System Manager for the Rollback feature",
	t3161: "Installed size",
	t3162: "New size",
	t3163: "Installed date",
	t3164: "New date",
	t3165: "Sensors",
	t3166: "Relays",
	t3167: "Inputs",
	t3168: "Outs",
	t3169: "Meters",
	t3170: "Generic",
	t3171: "Password expired, please enter new password",
	t3172: "New password",
	t3173: "Confirm password",
	t3174: "Change password",
	t3175: "Password must contain min 8 characters 1 upper case, 1 lower case, 1 number, 1 special character)",
	t3176: "Passwords do not match",
	t3177: "Password cannot be same as old password",
	t3178: "Password change successful",
	t3179: "Password cannot be changed. Error from device",
	t3180: "Password Reset",
	t3181: "Generated",
	t3182: "Site name",
	t3183: "Event-Id",
	t3184: "Time",
	t3185: "Role",
	t3186: "Event-Information",
	t3187: "Acknowledge selected alarms",
	t3188: "Acknowledge all selected alarms",
	t3189: "Import database to",
	t3190: "Importing database",
	t3191: "File type should be .zip",
	t3192: "Select file",
	t3193: "Opening hours",
	t3194: "Holidays",
	t3195: "On/Off Schedules",
	t3196: "Asset",
	t3197: "You have unsaved changes. Are you sure you want to leave?",
	t3198: "Unfortunately the migration failed. The system is currently busy. Please try again later",
	t3199: "Upload image adjusting to current dimensions",
	t3200: "Replace background image",
	t3201: "Replace background images with Scalable Vector Graphics.",
	t3202: "SvW will try to replace the background images for all graphics based on the name of the uploaded file. Only SVG files are supported in this mode and the filenames of each individual file should correspond to the name of an already existing graphic.",
	t3203: "Select file(s) to replace",
	t3204: "Image files",
	t3205: "cannot be processed. File has invalid format.",
	t3206: "{{amount}} file(s) replaced successfully",
	t3207: "{{amount}} file(s) failed to be replaced",
	t3208: "The view has no background",
	t3209: "You can upload a new or replace an existing background image",
	t3210: "If you are replacing a background image the parameter positions can be kept if:",
	t3211: "A png or jpg file has the same resolution as the current background",
	t3212: "A svg file has the same aspect-ratio (but not necessarily the same resolution)",
	t3213: "Feature is disabled",
	t3214: "Currently showing last received value, updating...",
	t3215: "Upload a graphic file created in",
	t3216: "(optional)",
	t3217: "Background image",
	t3218: "Browse",
	t3219: "More...",
	t3220: "Add a background image (optional)",
	t3221: "Graphic file",
	t3222: "Timeout in communication",
	t3223: "No refrigeration schedules configured",
	t3224: "Index",
	t3225: "[%key_id:149288868%]",
	t3226: "Holidays",
	t3227: "Days",
	t3228: "On",
	t3229: "Off",
	t3230: "ID",
	t3231: "Open",
	t3232: "Start",
	t3233: "End",
	t3234: "Description",
	t3235: "Day",
	t3236: "Close",
	t3237: "Edf file(s) uploaded successfully. Restarting system manager",
	t3238: "Edf file(s) upload finished, system manager reset successfully",
	t3239: "Error while fetching edf file(s) from system manager",
	t3240: "Edf file(s) upload failed. Try after sometime",
	t3241: "No refrigeration schedules configured",
	t3242: "Upload file to system manager",
	t3243: "Specify the device type - affects the required parameter tags to operate it",
	t3244: "Specify how values of the device are accessed and what function they fulfill",
	t3245: "Create an alarm based on a bit of a parameter",
	t3246: "Labels",
	t3247: "Create a list of labels that can be assigned to a parameter",
	t3248: "Groups",
	t3249: "Specify parameter groups",
	t3250: "Register Number",
	t3251: "Data Type",
	t3252: "List of Labels",
	t3253: "Minimum",
	t3254: "Default",
	t3255: "Maximum",
	t3256: "Register Type",
	t3257: "The Register type is translated to corresponding function codes for reading and writing",
	t3258: "Swap Mode",
	t3259: "For a value with bytes ABCD sent to the device, this option swaps bytes (BADC), words (CDAB) or both (DCBA).",
	t3260: "Read Only",
	t3261: "Logging",
	t3262: "Enables history logging for this parameter",
	t3263: "Take over text from parameter to alarm",
	t3264: "Position of the alarm flag counting from them least significant bit of the parameter",
	t3265: "Trigger alarm on low value",
	t3266: "Label",
	t3267: "Hide",
	t3268: "{{field}} is a required field.",
	t3269: "Maximum must be greater or equal minimum",
	t3270: "Default value must be in [Minimum, Maximum]",
	t3271: "Data Type must be {{reg}} for selected Register Type {{mt}}",
	t3272: "Name must not contain delimiter signs",
	t3273: "Name must not exceed 20 characters",
	t3274: "List of Labels must not be empty",
	t3275: "The tag(s) {{taglist}} must be assigned exactly once.",
	t3276: "The group(s) {{grouplist}} contain more than the maximum of 50 parameters.",
	t3277: "Form contains errors, please review errors and try again.",
	t3278: "Submit",
	t3279: "To activate the uploaded EDF you have to reset the unit. Do you want to reset the unit now?",
	t3280: "Reset now",
	t3281: "Reset later",
	t3282: "{{file}} already exists. Do you want to overwrite it?",
	t3283: "Upload {{what}}",
	t3284: "Labels and values must be unique.",
	t3285: "Multi",
	t3286: "Device handles multiple cases or packs",
	t3287: "Tags",
	t3288: "Internet Explorer is end-of-life and is not supported by StoreviewWeb",
	t3289: "Please switch to a supported browser, either Edge, Chrome or Firefox",
	t3290: "More Information",
	t3291: "Close",
	t3292: "Failure reading data, please try again",
	t3293: "Backup EDFs in progress",
	t3294: "There are no views to migrate",
	t3295: "The system is configured with a public IP in the private IP field",
	t3296: "The system is configured with a private IP in the public IP field, system may not be accessible remotely or some functions may not work correctly",
	t3297: "You are connected through an IP or hostname which is not know to the System Manager, likely through a proxy-service, some functions may not work correctly",
	t3298: "Device is offline, showing last known value",
	t3299: "Device Integration",
	t3300: "The tag(s) {{taglist}} are duplicated.",
	t3301: "Page not found",
	t3302: "We are sorry, the page you requested could not be found",
	t3303: "Error code: 404",
	t3304: "Go to dashboard",
	t3305: "Connected IP/Domain",
	t3306: "Internal IP Address",
	t3307: "External IP Address",
	t3308: "Domain Name",
	t3309: "No Warnings",
	t3310: "Multiple warnings",
	t3311: "It looks like the system is configured with multiple-units but we were unable to contact one or more of the slave-units, please ensure that the settings are correct. Please note that a multi-unit setup does not support multiple domain names and that each unit needs to be configured with a distinct port when using the same domain",
	t3312: "View only",
	t3313: "This element has no association to any parameter and can be safely removed",
	t3314: "Remove parameter",
	t3315: "Schedule",
	t3316: "You will now be logged out and will need to log in with the new password",
	t759: "Database downloaded, resetting and upgrading unit. This can take several minutes.",
	t816: "Alarm Type :",
	t818: "Count :",
	t848: "DB file is not created. You have limited access to the installed directory. Please contact system administrator.",
	t3317: "No schedules to export",
	t3318: "Pending response from the system",
	t3319: "No network activity",
	t3320: "Store Name",
	t3321: "New version {{latestVersion}} available online",
	t3322: "External Port",
	t3323: "Internal Port",
	t3324: "We cannot communicate with the system-manager on",
	t3325: "maybe the certificate needs to be trusted?",
	t3326: "File of this format cannot be removed",
	t3327: "Usage",
	t3328: "Turn On Time",
	t3329: "Off Time",
	t3330: "Weekdays",
	t3331: "Cooling Stage",
	t3332: "Heat Reclaim",
	t3333: "Cooling",
	t3334: "Heating",
	t3335: "Aux Heat",
	t3336: "Dehumidification",
	t3337: "Internet",
	t3338: "Messages",
	t3339: "Contacts",
	t3340: "Comm",
	t3341: "No items found",
	t3342: "Type to search",
	t3408: "Enable All",
	t3409: "Disable All",
	t3410: "Normal",
	t3411: "Severe",
	t3412: "Critical",
	t3413: "Enable all alarms",
	t3414: "Search for alarms",
	t3415: "Enabled",
	t3417: "Error while fetching alarms",
	t3418: "Error while fetching devices to copy",
	t3419: "Error while checking copy status",
	t3420: "Auto polling will resume after max 2 hours timeout, or if the wizard is canceled or finished",
	t3421: "Copy to controller(s)",
	t3422: "Copying to the selected device(s)",
	t3423: "The copy function will first copy the settings to the System Manager database, then download settings to the controller devices. It can take some time to complete the action.",
	t3424: "No devices selected to copy",
	t3425: "Double-click to copy",
	t3426: "Error while fetching devices",
	t3427: "Devices for copy",
	t3428: "Select the device(s) for copy",
	t3429: "Only same type controllers will be shown here",
	t3430: "Copy process succeeded",
	t3431: "Invalid file. Expected file model:",
	t3432: "Error while loading settings file",
	t3435: "Are you sure to move the selected device to this group {{toPackGroup}} ?",
	t3343: "Discovery",
	t3344: "Refresh table",
	t3345: "Scanning networks, please wait...",
	t3346: "The network table is refreshed",
	t3347: "Error while refreshing networks",
	t3348: "Error while rescanning networks",
	t3349: "Error while fetching networks",
	t3350: "Network scan completed",
	t3351: "No devices available",
	t3352: "Scan network",
	t3353: "Uncfg",
	t3354: "Any rearrangement of devices in wizard may affect Alsense monitoring services.",
	t3355: "Network",
	t3356: "Select fieldbus type",
	t3357: "If Modbus/RS485 is enabled you may select SLV for Danfoss variable speed compressor. When selecting SNMP as a local bus, you will be required to enter a valid subnet address range by Danfoss SNMP controls",
	t3358: "Compressors/Packs",
	t3359: "Manage compressors/packs controllers",
	t3360: "Only Pack devices can be managed here - not Rack I/O system. Compatible Multi-Suction Pack Controllers that have been scanned will initially appear as 0 suction groups. The AK-ST 500 (Service Tool) is required to configure Pack controller.",
	t3361: "Amount to copy",
	t3362: "Reducing previously configured suction group will also remove any associated evap controls for that suction group.",
	t3363: "No. of suction group cannot be zero, minimum no. of suction group should be atleast one.",
	t3364: "Error while fetching compressors/packs",
	t3365: "No compressors/packs available",
	t3366: "On-line Devices",
	t3367: "Off-line Devices",
	t3369: "Add Pack",
	t3370: "If selecting Modbus/RS485 you will see additional options for SLV/ECFan, these are used for Refrigeration and can be selected to No.",
	t3371: "Add Circuit",
	t3372: "Add, copy or remove devices. Note that not all devices types allow editings of all fields and the addresses of I/O points are set in configuration.",
	t3373: "Manage case controllers",
	t3374: "Add a device",
	t3375: "Manage compressor/pack controllers",
	t3376: "Error in Data Access",
	t3377: "HVAC Layout has been successfully applied",
	t3378: "Layout HVAC",
	t3379: "Are you sure you want to leave this page?",
	t3380: "Your settings may be lost",
	t3381: "Please configure a device",
	t3382: "Exit Quick Setup",
	t3383: "Are you sure you want to exit Quick setup?",
	t3384: "Gateways (AK-PI200)",
	t3385: "Select the found gateways",
	t3386: "IP Address",
	t3387: "Source controller",
	t3388: "Source settings",
	t3389: "Source alarms",
	t3390: "Source graph",
	t3392: "Graph",
	t3393: "Devices",
	t3394: "Auto polling is suspended during the copy phase. The main switch of source and target device(s) will be switched OFF then returned to original state, once copy is complete. Be sure to check main switch after copy wizard is complete. If any of the selected devices are in active alarm, any modification to alarm action for that particular point will not be modified.Original setting will remain.",
	t3395: "Select a source device to copy settings from",
	t3396: "On Time",
	t3397: "The copy function will copy available graph points until the unit capacity is reached. Goto Configuration to see the number of points defined",
	t3398: "Source device graphing",
	t3399: "Configure graph points for the source controller",
	t3400: "Enable HACCP to log the points in a HACCP temperature report",
	t3401: "Search for points",
	t3402: "No data available",
	t3403: "Error while fetching graph points",
	t3404: "Disabled",
	t3405: "Source device alarms",
	t3406: "Configure alarm details in the source controller",
	t3407: "Alarm Name",
	t3436: "The device will be removed from the assigned group [{{fromPackGroup}}]",
	t3437: "Layout Refrigeration",
	t3438: "The refrigeration layout has been successfully applied",
	t3439: "Reassign",
	t3440: "Assign case controls to pack controls",
	t3441: "Assign Case controls (from available cases) to Compressor/Pack controls. forming suction groups",
	t3442: "Device parameters",
	t3443: "Select parameters for copy",
	t3444: "When working on a live (online) device, there may be a delay in the settings, due to communication to the device.",
	t3445: "Select alarms for copy",
	t3446: "Device graph",
	t3447: "Select graph points for copy",
	t3448: "Save current device settings to a file",
	t3449: "You may save the current device settings to a file that can be used to configure devices at a later date. If you wish to exit the wizard after the file save, use the exit icon. If working with connected devices, exiting will ensure network polling and device main switch will return to correct status.",
	t3450: "Setting file(s) saved successfully",
	t3451: "Would you like to continue or exit the Quick Setup?",
	t3452: "Saving file(s)",
	t3453: "Error while fetching settings",
	t3454: "Source device settings",
	t3455: "Configure device parameters in the source controller",
	t3456: "None of password fields should be empty",
	t3457: "Recents",
	t3458: "Address Book",
	t3459: "Last accessed",
	t3460: "Please take a backup of the Address Book on a regular basis.",
	t3461: "Address Book was successfully imported",
	t3462: "Import Address Book",
	t3463: "Export Address Book",
	t3464: "Add site",
	t3465: "Edit site",
	t3466: "Delete site",
	t3467: "You need to create an Address Book password before your start using Address Book",
	t3468: "Site has been successfully added to your Address Book",
	t3469: "Already in Address Book",
	t3470: "Add to Address Book",
	t3471: "Login to site",
	t3472: "Site info has been successfully updated",
	t3474: "Notification",
	t3475: "Site has been successfully removed from your Address Book",
	t3476: "Site has been successfully updated",
	t3477: "Invalid Address Book format",
	t3478: "Actions",
	t3479: "Not available when offline",
	t3480: "Failed to load S55 database",
	t3481: "Loading S55 database",
	t3482: "Protocol",
	t3483: "User does not have permission to see software information",
	t3484: "Password must be alphanumeric or numeric only",
	t3485: "You need to create an Address Book password to access Address Book",
	t3486: "Address Book password is not correct",
	t3487: "Site is already present in the Address Book",
	t3488: "Create an Address Book password to use Address Book. Password must be alphanumeric or numeric only",
	t3489: "Enter your Address Book password",
	t3490: "No online devices available to copy",
	t3491: "Create Address Book password",
	t3492: "Refresh Address Book password",
	t3493: "Site password can't be empty",
	t3494: "Type in a password of the Address Book being imported",
	t3495: "Address Book password was successfully created",
	t3496: "Address Book password was successfully updated",
	t3497: "The password of Address Book being imported is not correct",
	t3498: "Please enter site password",
	t3499: "Clear Address Book",
	t3500: "Search sites",
	t3501: "Select Address Book file to import",
	t3502: "Select key file to decrypt Address Book",
	t3503: "Press export to download the encrypted address book",
	t3505: "With encrypted",
	t3506: "unencryped passwords",
	t3507: "Invalid secret key",
	t3508: "Import failure. Make sure you have imported proper key",
	t3509: "No available versions",
	t3510: "Open Alsense Services (requires subscription)",
	t3511: "Please review the EULA terms carefully. If you cannot accept the terms, you are not allowed to use the application.",
	t3512: "Address Book and your Address Book password were successfully cleared",
	t3513: "(required)",
	t3515: "Address Book is empty",
	t3516: "Address Book password can't be empty",
	t3519: "Clearing Address Book you will also remove your Address Book password.",
	t3520: "Not in range. Should be between 0 to {{maxAddr}}",
	t3521: "Press 'Export' to download all graphic views stored in the System Manager",
	t3522: "Press 'Download' to download a System report",
	t3523: "Only the master unit is available on a direct WiFi connection",
	t3524: "There are no local graphic views. Add new or import existing views",
	t3525: "There are no remote graphic views. Try searching with different name",
	t3526: "There are no remote graphic views. Add new, import or migrate existing views",
	t3527: "Local Views ({{totalFiles}} of 5)",
	t3528: "{{totalFiles}} local graphics out of a maximum of 5",
	t3529: "Remote Views",
	t3530: "File is not a bitmap.",
	t3531: "Bitmap length wrong.",
	t3532: "Bitmap header size wrong.",
	t3533: "Bitmap size wrong.",
	t3534: "Error in palette size.",
	t3535: "Incompatible .bmp file",
	t3536: "Error while deleting local graphic file",
	t3537: "Error while duplicating local graphic file",
	t3538: "Error while exporting local graphic file",
	t3539: "Delete Graphic",
	t3540: "Delete a local graphic file",
	t3541: "Local graphic file delete is in progress",
	t3542: "Local graphic file deleted successfully. Restarting System Manager",
	t3543: "Local graphic file delete finished. System Manager restarted successfully",
	t3544: "Duplicate Graphic",
	t3545: "Duplicate a local graphic file",
	t3546: "Local graphic file duplicate is in progress",
	t3547: "Local graphic file duplicated successfully. Restarting System Manager",
	t3548: "Local graphic file duplicate finished. System Manager restarted successfully",
	t3549: "Export Graphic",
	t3550: "Export a local graphic file",
	t3551: "Local graphic file export is in progress",
	t3552: "Local graphic file exported successfully",
	t3553: "The System Manager will restart on this action",
	t3554: "Are you sure you want to delete",
	t3555: "Are you sure you want to duplicate",
	t3556: "Press 'Export' to download all local graphic views stored in the System Manager",
	t3557: "Local graphic files export is in progress",
	t3558: "Local graphic files exported successfully",
	t3559: "Product code",
	t3560: "Get more information about this product",
	t3561: "Fan eco",
	t3562: "Valve opening",
	t3563: "Superheat",
	t3564: "Evaporator pressure",
	t3565: "Evaporator temperature",
	t3566: "Cutout",
	t3567: "Cutin",
	t3568: "Case door",
	t3569: "Suction temperature",
	t3570: "Rail heat",
	t3571: "Maximum allowed file limit reached. Unselect file(s) to import",
	t3572: "Please delete existing file(s) in system manager to import files",
	t3573: "Local graphic file imported successfully. Restarting System Manager",
	t3574: "Local graphic file(s) import completed. System Manager restarted successfully",
	t3575: "Maximum allowed file limit reached. Delete file(s) to import",
	t3576: "Maximum graphic files allowed is 5",
	t3577: "Local graphic file uploaded successfully. Restarting System Manager",
	t3578: "Local graphic file upload finished. System Manager restarted successfully",
	t3579: "Invalid file format. upload {{supportedFileFormats}} file",
	t3580: "File dimension must be {{maxWidthAllowed}}x{{maxHeightAllowed}} pixels for local screens",
	t3581: "Remote View",
	t3582: "Local View",
	t3583: "Storeview Web homepage",
	t3584: "EULA terms",
	t3585: "Security",
	t3586: "Security Enforcing Levels",
	t3587: "Backward Compatible (lowest security level - assuming use header auth is disabled)",
	t3588: "SvW / XML requests contain username and password.",
	t3589: "No sanitizing of requests, same security as prior releases",
	t3590: "No changes needed on SvW or existing XML connections",
	t3591: "Permissive (can be used as a transition to strict level)",
	t3592: "XML requests can contain username and password, and or a session token. The session token is received in the response of the login request.",
	t3593: "If the session token is provided in the AKSM-auth header, usernames and passwords are ignored",
	t3594: "XML request gets sanitized, a warning is returned in the response of request if any issues discovered",
	t3595: "Take note of these warnings in preparation for switching to strict mode",
	t3596: "If the session token is not used, session expiry cannot be applied",
	t3597: "Already supported in remote UI (SvW)",
	t3598: "Possible / Required changes from 3rd party XML perspective",
	t3599: "No session token usage - no changes needed",
	t3600: "Session token usage - remove username / password from body and observe warnings in preparation for Strict",
	t3601: "Strict (highest security level)",
	t3602: "XML requests can not contain usernames and passwords, but must provide the session token in the AKSM-auth header. If an issue is discovered in the request, it gets rejected",
	t3603: "Already supported in (SvW)",
	t3604: "Required changes from 3rd party perspective",
	t3605: "username and password must be removed from the XML request payload and the session token must be placed in a CORS header called AKSM-auth",
	t3606: "Login Failure Back-off",
	t3607: "How long (seconds) a remote user waits to be able to try again to login in after a failed attempt. Waiting time increases cumulatively with each failed attempt. After 10 attempts the IP from which the user tried to login is blocked for 24 hours. If set to 0 Login failure back-off is disabled",
	t3608: "Default 30s (max 600s / 0 = disabled)",
	t3609: "Free Attempts",
	t3610: "How many login attempts are allowed before back-off is applied",
	t3611: "Default 2 (max 5)",
	t3612: "Block",
	t3613: "If enabled User + IP are blocked during back-off, otherwise the User is blocked",
	t3614: "Default 'User' (User + IP / User)",
	t3615: "Sessions per user",
	t3616: "A session is where a user has successfully logged into the unit and is within the remote interface (SvW)",
	t3617: "Number of active Sessions a user can have. If set to 0, a user can have the max amount of Sessions (30)",
	t3618: "Default 0",
	t3619: "Session Expiry",
	t3620: "How many Seconds after an idle Session expires. If a Session expires a new login is needed",
	t3621: "Default 900s (max 3,600)",
	t3622: "Header Authentication",
	t3623: "When enabling HTTPS and Header Authentication all 3rd party XML requests must pass username / password credentials in the header (basic auth scheme)",
	t3624: "Enabling the 'Use Header Authentication' requires the system to be re-initialized. Use the 'Press to Initialize' button to perform this action",
	t3625: "Small",
	t3626: "Large",
	t3627: "Save Graphic",
	t3628: "Save a local graphic file",
	t3629: "Local graphic file deleted successfully. Local graphic file load is in progress",
	t3630: "Local graphic file is loaded successfully. Restarting System Manager",
	t3631: "Local graphic file save finished. System Manager restarted successfully",
	t3632: "Are you sure you want to save?",
	t3633: "Parsing Failed",
	t3634: "Could not parse viz file",
	t3635: "Could not parse vz2 file",
	t3636: "Opening of Viz File Failed:",
	t3637: "The migration files (vz2, dpj or json) could not be found",
	t3638: "The migration files (viz) could not be found",
	t3639: "The System Manager will restart on this action. The System Manager is in either permissive or strict mode so the session token will expire on restart and you will be logged out from the system",
	t3643: "Discharge pressure",
	t3644: "Total kW",
	t3645: "Total KVAR",
	t3646: "Total Kvarh+",
	t3647: "Demand",
	t3648: "Power factor",
	t3649: "Font color",
	t3650: "Background color",
	t3651: "Address Book was successfully exported",
	t3652: "Control mode",
	t3653: "Sc3 Outside temperature",
	t3654: "Discharge temperature",
	t3655: "Condenser running capacity",
	t3656: "No data to be exported",
	t3657: "The user's password on {{unit}} is expired",
	t3658: "Cannot login to {{unit}} with provided credentials.",
	t3659: "Update firmware version or change security enforcing level for {{unit}} to get session token support.",
	t3661: "V3gc",
	t3662: "Section B",
	t3663: "Section C",
	t3664: "Adaptive superheat control",
	t3665: "Control status reason",
	t3666: "User cannot be logged in. Unit address is not set in proxy configuration",
	t3667: "Communication protocol is not specified in proxy configuration. It may lead to errors",
	t3668: "IT Security Information",
	t3669: "In compliance with security standards the System Manager now defaults to Strict session control, meaning any remote connection / XML based remote service will no longer function unless session tokens are applied.",
	t3670: "Danfoss recommends continuing in Strict mode, but backward compatiblity mode is still available.",
	t3671: "Note, this can be changed in Configuration Security menu, and have no impact on System Manager local control and site operation.",
	t3672: "See more information on session control",
	t3673: "Backward mode",
	t3674: "Strict mode",
	t3675: "On clicking backward compatible mode, all the applicable System Manager(s) with updated firmware will be set to the backward compatible mode, restarted and the application will be logged out",
	t3676: "Max number of Suction groups allowed is 12",
	t3677: "On successfully updating the selected units from 3.3.0 to 4.X or above, the user will be logged out automatically on click of Finish button in the final step.",
	t3678: "Multi case for AK2 controllers (Pack/Case) are not supported",
	t3679: "In compliance with security standards introduced with release 4.0, the System Manager will default to Strict session control, meaning any remote connection / XML based remote service will no longer function unless session tokens are applied. Besides that, the communication will automatically be adjusted to HTTPS by upgrading to rel. 4.0. Danfoss recommends continuing in Strict mode, but backward compatibility mode is available, which will set the system back to the settings applied until now. Please note if you are making this update remotely on an HTTP unit, please make sure your IT setup is prepared for HTTPS (port 443) or have personnel available on site.",
	t3680: "Find more information about the new security implementation here",
	t3681: "Access to http version of this site may be blocked due to your browser restrictions. Visit our FAQ page to get instructions on resolving this issue or download the desktop version of StoreView Web.",
	t3682: "System Manager FAQ",
	t3683: "u17 Ther. air",
	t3684: "u91 Cutout temp.",
	t3685: "u00 Ctrl. state",
	t3686: "u12 S3 air temp.",
	t3687: "u16 S4 air temp.",
	t3688: "u23 EEV OD %",
	t3689: "u21 Superheat",
	t3690: "u22 SuperheatRef",
	t3691: "u26 EvapTemp Te",
	t3692: "S2 Gas outlet temp.",
	t3693: "Stw8",
	t3694: "Sd-MT",
	t3695: "Tw status",
	t3696: "Tw enable",
	t3697: "Tw pump running",
	t3698: "Stw3",
	t3699: "Stw4",
	t3700: "Stw2",
	t3701: "Rcv. control status",
	t3702: "Suction offset",
	t3703: "Vrec control mode",
	t3704: "Vrec status",
	t3705: "Ctrl status reason",
	t3706: "Hp control status",
	t3707: "Pgc max.",
	t3708: "Pgc max. limit P-ban",
	t3709: "Pgc min.",
	t3710: "Vapor ejector capaci",
	t3711: "Liquid ejector capac",
	t3712: "Reference air temp.",
	t3713: "Supply air temp.",
	t3714: "Return air",
	t3715: "Zone dew point",
	t3716: "Zone humidity",
	t3717: "Outdoor temp.",
	t3718: "Zone CO2 level",
	t3719: "Zone temp.",
	t3720: "Cond. reference",
	t3721: "Cond. control status",
	t3722: "Cond. control state",
	t3723: "Cond. ctrl.",
	t3724: "Cond. requested cap.",
	t3725: "V3gc state",
	t3726: "V3gc OD",
	t3727: "Cond. temp.",
	t3728: "Hr status",
	t3729: "Hr enable",
	t3730: "HR Consumer request",
	t3731: "Shr3",
	t3732: "Shr4",
	t3733: "Shr2",
	t3734: "Shr8",
	t3735: "Suction ref. To-MT",
	t3736: "Control status MT",
	t3737: "Actual zone MT",
	t3738: "To opt. offset MT",
	t3739: "Running capacity MT",
	t3740: "Requested cap. MT",
	t3741: "Time to next step MT",
	t3742: "Po-MT",
	t3744: "Suction temp. To-MT",
	t3745: "Total active energy",
	t3746: "Peak demand",
	t3747: "Total power factor",
	t3748: "Phase A volts",
	t3749: "Phase B volts",
	t3750: "Phase C volts",
	t3751: "Phase A amps",
	t3752: "Phase B amps",
	t3753: "Phase C amps",
	t3754: "Total active power",
	t3755: "Suction temp. To-LT",
	t3756: "Suction temp. To-IT",
	t3757: "Suction reference LT",
	t3758: "Control status LT",
	t3759: "Actual zone LT",
	t3760: "To opt. offset LT",
	t3761: "Running capacity LT",
	t3762: "Requested cap. LT",
	t3763: "Time to next step LT",
	t3764: "Po-LT",
	t3765: "Sd-LT",
	t3766: "Suction reference IT",
	t3767: "Control status IT",
	t3768: "Actual zone IT",
	t3769: "Running capacity IT",
	t3770: "Requested capacity IT",
	t3771: "Time to next step IT",
	t3772: "Tn Po-IT",
	t2773: "Sd-IT",
	t2774: "IT Comp. start",
	t3774: "Not in range. Should be between {{minAddr}} to {{maxAddr}}",
	t3775: "Operation disabled for this device",
	t3776: "Upon this action the application will log out, which may take a few moments.",
	t3777: "The migration files (flp or json) could not be found",
	t3778: "Polling",
	t3779: "Polling is currently suspended.",
	t3780: "Do you wish to resume polling?",
	t3781: "Energy Meter",
	t3782: "Multiple nodes selected",
	t3783: "Zoom to node",
	t3784: "Measurement units",
	t3785: "File(s) exported successfully",
	t3786: "Address can't be negative",
	t3787: "Reset zoom",
	t3788: "Error while fetching staging pattern steps",
	t3789: "Error while saving staging pattern steps",
	t3790: "Staging pattern steps saved successfully",
	t3791: "Staging Pattern",
	t3792: "Press the unloaded icons to enable/disable the unloaded associated to the compressors",
	t3793: "Missing data",
	t3794: "Following parameters did not return any data points",
	t3795: "HTTPS/SSL trust was successful.",
	t3796: "You can now log in to StoreView Web Browser.",
	t3797: "Redirecting to StoreView Web Browser in {{timeLeft}} seconds...",
	t3798: "Polling currently suspended!",
	t3799: "Cleared Alarms (full system logs)",
	t3800: "Max no.of HVAC allowed is 45.",
	t3801: "Failed to get change log",
	t3802: "Connecting to storage...",
	t3803: "SCADA storage connection timeout",
	t3804: "Failed to connect to SCADA storage",
	t3805: "Invalid date",
	t3806: "Select unit(s) for report download"
};
var en = {
	lang: lang$1
};

var lang$2 = {
	t0: "Alarmes Actives",
	t1: "Alarmes Annulees",
	t2: "Nom Hote",
	t3: "ID",
	t4: "Nom Régulateur",
	t5: "Editer Notes",
	t6: "Non autorise pour Reconnaitre une alarme",
	t7: "Type Alarme",
	t8: "Apparue",
	t9: "Reconnaitre",
	t10: "Alarme(s) Active(s)",
	t11: "Annulee",
	t12: "Calculs",
	t13: "Alarme(s) Annulee(s)",
	t14: "Recon/Annul",
	t15: "Reconnaître toutes les alarmes non-reconnues",
	t16: "Autorisation echouee !",
	t17: "Erreur",
	t18: "Manu Regul",
	t19: "Visuel",
	t20: "Donnees",
	t21: "Vue Technique",
	t22: "Graphique (S4)",
	t23: "Module",
	t24: "Mod.",
	t25: "Graphique (Po &amp; Pc)",
	t26: "(Pas d’alarmes detectees)",
	t27: "Alrm Appareil",
	t28: "Voir Regulateur Precedent",
	t29: "Voir Regulateur Suivant",
	t30: "Erreur lecture alarmes",
	t31: "Alarmes Actuelles",
	t32: "Alarme Actuelle",
	t33: "Pas d’alarmes detectees",
	t34: "Detail",
	t35: "Point",
	t36: "Courbes T°",
	t37: "Programmes",
	t38: "Heure Début",
	t39: "Heure Fin",
	t40: "Jours",
	t41: "J.Feries",
	t42: "Etat",
	t43: "Choisir donnees",
	t44: "OK",
	t45: "Annuler",
	t46: "Marche",
	t47: "Fin",
	t48: "Date Debut",
	t49: "Taux échantillon.",
	t50: "Pas de point selectionnees",
	t51: "Etat",
	t52: "sec",
	t53: "min",
	t54: "hr",
	t55: "jour",
	t56: "Type Adresse",
	t57: "Adresse",
	t58: "Emplacement :",
	t59: "Entrer Nouvelle Valeur",
	t60: "Rafr.",
	t61: "Choisir donnees",
	t62: "Choisir la nouvelle valeur",
	t63: "OFF",
	t64: "ON",
	t65: "Recuperation des Donnees ...",
	t66: "Erreur Recuperation des Donnees ...",
	t67: "Historique non configure ...",
	t68: "Erreur Recuperation des Donnees ...",
	t69: "Recuperation de l’Historique de",
	t70: "Erreur Recuperation de l’Historique ...",
	t71: "Identifiant",
	t72: "Mot de passe",
	t73: "Adresse IP/URL Hote:",
	t74: "OK",
	t75: "Annuler",
	t76: "Nom",
	t77: "Valeur",
	t78: "Erreur Recuperation Liste Utilisataur",
	t79: "Entrer Nouvelle Adresse",
	t80: "Erreur liste utilisateur, verifier adresse IP",
	t81: "Code Aut.",
	t82: "Compte",
	t83: "Choisir Nouvelle Heure et Date",
	t84: "Choisir Nouvelle Duree",
	t85: "Operation defaillante",
	t86: "Entrez Information d’Autorisation",
	t87: "HVAC",
	t88: "Executer !",
	t89: "Degi Regul",
	t90: "Demarrage Degivrage Manuel",
	t91: "Ctrl Eclairage Poste",
	t92: "Eclairage Manuel On",
	t93: "Eclairage Manuel Off",
	t94: "Controle Nettoyage Postes",
	t95: "Nettoyage Manuel On",
	t96: "Nettoyage Manuel Off",
	t97: "Mar / Arr",
	t98: "Interrupteur General Manuel On",
	t99: "Interrupteur General Manuel Off",
	t100: "Controle Arret",
	t101: "Arret Poste",
	t102: "Demarrage Poste",
	t103: "Controle Regulation de Nuit",
	t104: "Regulation de Nuit On",
	t105: "Regulation de Nuit Off",
	t106: "Message Envoye ...",
	t107: "Manu Regul",
	t108: "Fermer",
	t109: "Eclairage",
	t110: "Puiss./Div",
	t111: "Supervision",
	t112: "Choisir AK-SC a afficher",
	t113: "Chargement ...",
	t114: "de",
	t115: "Tous les AK-SC",
	t116: "Précédent",
	t117: "Pages",
	t118: "Suivant",
	t119: "Parametre",
	t120: "Mesures",
	t121: "Recuperation de Donnees ...",
	t122: "Erreur Recuperation de Donnees",
	t123: "Température",
	t124: "Delais Apres Degivrage",
	t125: "Alarme si &lt; à",
	t126: "Alarme si &gt; à",
	t127: "Contrôle T",
	t128: "Def.Com",
	t129: "Actif",
	t130: "Nom Hote",
	t131: "Adresse",
	t132: "Description",
	t133: "Val. courant",
	t134: "Statut Actuel",
	t135: "Régulateurs",
	t136: "Unité",
	t137: "Alarme Active Présente",
	t138: "Alarmes Actives Presentes",
	t139: "Entrees Digitales",
	t140: "Sorties TOR",
	t141: "Entrées Sonde",
	t142: "Sorties Analog.",
	t143: "Regulateurs",
	t144: "Autres",
	t145: "Visualisation du point",
	t146: "Vue d'Ensemble Magasin",
	t147: "Autorisatoin Systeme OFF",
	t148: "Autorisation ON",
	t149: "Autorisation OFF",
	t150: "Réfrigération",
	t151: "Vue d'Ensemble Regulateur",
	t152: "Alarmes",
	t153: "Courbes",
	t154: "Vue d'Ensemble AK-SC",
	t155: "Vue d'Ensemble Magasin",
	t156: "Configuration",
	t157: "Vue Site",
	t158: "Vue du Materiel",
	t159: "Menu principal",
	t160: "Alarme",
	t161: "Consigne",
	t162: "Detail Appareil",
	t163: "Reglage",
	t164: "Choisir Date",
	t165: "Information",
	t166: "Tracage",
	t167: "Calculs",
	t168: "Gestion Fichier",
	t169: "Manuel Aide",
	t170: "Info",
	t171: "Traducteur",
	t172: "Sauvegarder vers Serveur",
	t173: "Mise a Jour Fichier Langue ?",
	t174: "Cette operation va mettre a jour le fichier Langue sur le serveur.",
	t175: "Exporter Historique",
	t176: "Choisir Nouvelle Valeur",
	t177: "minimum",
	t178: "maximum",
	t179: "Pas d'Alarme Active !",
	t180: "Pas Modifiable !",
	t181: "Patientez ...",
	t182: "Commande Envoyee !",
	t183: "Operation Echouee, reessayer ulterieurement !",
	t184: "Operation Reussie !",
	t185: "Operation Non Autorisee",
	t186: "Etes-vous sur ce vouloir continuer l'operation ?",
	t187: "EVAP",
	t188: "CENT.",
	t189: "Entrees Surveillance",
	t190: "Divers",
	t191: "Alarmes Reconnues",
	t192: "Annuler les alarmes reconnues",
	t193: "Annuler toutes les alarmes reconnues",
	t194: "Vous n'etes pas autorise a reconnaître les alarmes",
	t195: "Non autorise",
	t196: "Voulez-vous reconnaître cette alarme ?",
	t197: "Confirmer Recon Alarme",
	t198: "Merci de vous enregistrer avant.",
	t199: "Selectionnez une alarme a annuler",
	t200: "Confirmer Annul Alarme",
	t201: "Etes-vous sur de vouloir annuler cette alarme ?",
	t202: "Non autorise a annuler l'alarme",
	t203: "Etes-vous sur de vouloir annuler toutes les alarmes ?",
	t204: "L'alarme selectionnee n'est pas annulable",
	t205: "Reglage Alarme",
	t206: "Niveau superviseur requis pour cette operation",
	t207: "Pas de donnees envoyees par l'unite",
	t208: "Choisir une unite",
	t209: "Merci de selectionner une unite dans la liste",
	t210: "Nb d'alarmes",
	t211: "Etat Alarme",
	t212: "Divers",
	t213: "Annuler l'alarme selectionnee",
	t214: "Confirmez",
	t215: "Connexion échouée, verifiez les connexions reseau !",
	t216: "Alarme entree degivrage",
	t217: "Alarme entree digitale",
	t218: "Etat entree digitale",
	t219: "Entree Degivrage",
	t220: "Entree Nettoyage",
	t221: "Entree DI",
	t222: "Sauvegarde fichier langue",
	t223: "Unités",
	t224: "AK-SC non configure, acces Web suspendu",
	t225: "Attention :",
	t226: "Choisir Periode",
	t227: "Communication",
	t228: "Telecharger le rapport ?",
	t229: "Merci d'attendre la fin de la creation du rapport",
	t230: "Telecharger rapport",
	t231: "Etat Alarme / Service",
	t232: "Patienter, recuperation des donnees ...",
	t233: "Autorise",
	t234: "Autorisation OFF",
	t235: "Autorisation ON",
	t236: "Bienvenue!",
	t237: "Licence Invalide",
	t238: "Compteur Energie Regulateurs",
	t239: "Eclairage",
	t240: "Variateur de Frequence",
	t241: "Compresseur Module",
	t242: "Points des Regulateurs",
	t243: "Points Calculs",
	t244: "Panneaux Cutler-Hammer",
	t245: "Carriers",
	t246: "Vue Avancee",
	t247: "Double cliquer ici pour zoomer avant ou arriere",
	t248: "Cliquer ici pour zoomer avant ou arriere",
	t249: "Alarmes Reconnue(s)",
	t250: "Simulateur",
	t251: "Tache Refrigeration",
	t252: "Tache HVAC",
	t253: "Tache Eclairage",
	t254: "Tache Divers",
	t255: "Reseau connecte",
	t256: "VEV",
	t257: "Référence",
	t258: "Courbes (Variateur Hz &amp; A)",
	t259: "Date Fin",
	t260: "Stop",
	t261: "La date de fin est plus ancienne que la date de debut, merci de la modifier",
	t262: "La date de creation des enregistrements",
	t263: "sont plus anciens que la date de debut specifie",
	t264: "Le nombre d'echantillon pour chaque point a atteint 100 000, cela prendrait trop de temps pour obtenir les donnees. Vous devriez reduire ce nombre en diminuant le taux d'echantillonage ou changer le jour et la date.",
	t265: "L'unite est occupee a proceder a une autre tache, merci de patienter 2 minutes et essayer a nouveau...",
	t266: "Sauver",
	t267: "Panneau de Controle",
	t268: "Premier",
	t269: "Dernier",
	t270: "Imprimer",
	t271: "Zoom Arriere",
	t272: "Zoom Avant",
	t273: "Afficher/Masquer Legende",
	t274: "Exporter Historique vers Fichier",
	t275: "Afficher/Masquer Grille",
	t276: "Vue Page Unique",
	t277: "Vue Plusieurs Pages",
	t278: "Charger Groupe Historique Depuis Fichier",
	t279: "Charger Historique Depuis Fichier",
	t280: "Sauvegarder Groupe Historique Vers Fichier",
	t281: "Vue Multi Page Compteur",
	t282: "Page",
	t283: "Choisir Page a Afficher",
	t284: "Le fichier Historique doit etre au format CSV !",
	t285: "Pas de donnees retournees, merci de verifier les points historiques selectionnes et la date selectionnee.",
	t286: "Capture d'ecran Historique",
	t287: "Vue Complete Historique",
	t288: "Vue Fichier Historique",
	t289: "Choisir ...",
	t290: "Cette operation exportera l'historique actuel vers un fichier csv.",
	t291: "Merci de choisir un fichier cible.",
	t292: "Fichier",
	t293: "Chargement Groupe Historique ...",
	t294: "Chargement Historique",
	t295: "Sauvegarder Groupe Historique ...",
	t296: "Vue",
	t297: "Zoom",
	t298: "Oui",
	t299: "Non",
	t300: "Recuperer la configuration du régulateur ?",
	t301: "Ne plus demander",
	t302: "Recupere Configuration Regulateur",
	t303: "Donnees Recuperees",
	t304: "Niveau Alarme",
	t305: "Action Alarme",
	t306: "Reconnue",
	t307: "Sauvegarder la database",
	t308: "Nom Fichier",
	t309: "Description Database",
	t310: "Chemin database",
	t311: "Version",
	t312: "Horloge",
	t313: "Exporter Alarmes",
	t314: "Database Sauvee Vers",
	t315: "Sauvegarde database echoue, merci de verifier si le nom et le repetoire sont valides !",
	t316: "Nom de fichier incorrect, merci de verifier le nom du fichier database",
	t317: "Export alarmes reussi. Merci de choisir un fichier pour sauvegarder les alarmes exportees",
	t318: "Merci de selectionner les alarmes a exporter",
	t319: "Recuperation des informations d'alarmes de l'unite",
	t320: "Description Fichier",
	t321: "Convertir fichier .hst vers fichier .csv",
	t322: "Etape 1: selectionner le fichier .hst",
	t323: "Etape 2: selectionner les points et la periode",
	t324: "Etape 3: selectionner le fichier cible .csv",
	t325: "Sauvegarder sous ...",
	t326: "Pas plus de 250 points autorises pour cette operation",
	t327: "Points selectionnes",
	t328: "Il n'est pas autorise d'exporter plus de 65538 echantillons par point, merci de changer le taux d'echantillonnage",
	t329: "Collecte",
	t330: "Collecte donnees historique de l'unite",
	t331: "Choisir points collectes",
	t332: "Pas de valeur",
	t333: "Manquant",
	t334: "Suspendu",
	t335: "Merci de faire une selection",
	t336: "Choisir les points a tracer",
	t337: "Sauvegarder vers fichier .hst",
	t338: "Telechargement termine, merci de selectionner une des options suivantes:",
	t339: "Choisir au plus 8 points a tracer",
	t340: "Sauvegarder les donnees historiques collectees vers un fichier .hst",
	t341: "Imprimer vue",
	t342: "Merci de selectionner sauvegarder ou imprimer rapport de synthese",
	t343: "Choisir un format de fichier",
	t344: "Generation du fichier de synthese, merci de patienter ...",
	t345: "Le fichier selectionne existe, voulez-vous l'ecraser ?",
	t346: "Tableau de bord",
	t347: "Vue Graphique",
	t348: "Aide",
	t349: "Vue Systeme",
	t350: "Plan",
	t351: "Outil Traduction",
	t352: "Manuel",
	t353: "Requete Alarmes...",
	t354: "Mise a jour",
	t355: "Modele",
	t356: "Regul",
	t357: "Huile",
	t358: "Temperature",
	t359: "Maximize",
	t360: "Restaure",
	t361: "Retour arriere ...",
	t362: "Communication echouee !",
	t363: "Etat communication OK.",
	t364: "Message communication envoye.",
	t365: "Chargement Composant Menu Principal",
	t366: "Chargement Composant Configuration",
	t367: "Régul.Temp",
	t368: "Ce reglage sera effectif seulement quand l'historique sera redessine, voulez-vous continuer ?",
	t369: "Chargement,patienter",
	t370: "donnees",
	t371: "Unite non nommee",
	t372: "Preferences",
	t373: "Version Simulateur",
	t374: "Energie",
	t375: "Aller vers vue d'ensemble",
	t376: "Alarme systeme ou aucun appareil associe trouve",
	t377: "Plannings simultanes",
	t378: "Fichier langue manquant.",
	t379: "Version non supportee",
	t380: "Entrer l'adresse IP de l'unite ici (ex 192.168.1.1)",
	t381: "Merci d'entrer l'adresse IP ou URL d'une unite.",
	t382: "Cacher Detail",
	t383: "Afficher Detail",
	t384: "Temp. ext.",
	t385: "Retour",
	t386: "Ent.",
	t387: "Humidite",
	t388: "Pt Rosée",
	t389: "Ventilateur",
	t390: "Zone",
	t391: "Stop AKV",
	t392: "Div.",
	t393: "Temp. Asp.",
	t394: "Capacite Actuelle",
	t395: "Pression Aspiration",
	t396: "Refoulement",
	t397: "Compresseurs",
	t398: "Condenseur",
	t399: "Condenseur Consigne",
	t400: "Refrigerant",
	t401: "Consigne Aspiration",
	t402: "Centrale",
	t403: "Dégi.",
	t404: "Format Historique Danfoss",
	t405: "Format Texte",
	t406: "Cette operation necessite de redemarre l'unite, voulez-vous redemarrer maintenant ?",
	t407: "Unite redemarree avec succes, merci de recharger le site web.",
	t408: "Mode Application Usine",
	t409: "Calcul ...",
	t410: "minutes",
	t411: "Regul Nuit",
	t412: "Eclairage Poste",
	t413: "Planings Cumules",
	t414: "(Resolution 10 min)",
	t415: "Voir Texte",
	t416: "Exportation en tant que CSV",
	t417: "Sauve PNG",
	t418: "Voir Graphe",
	t419: "Voir Texte",
	t420: "Progr.",
	t421: "Durée",
	t422: "Temp Term",
	t423: "AUCUN",
	t424: "Regul Temp",
	t425: "Demarre depuis",
	t426: "Database Charge de",
	t427: "Statistiques",
	t428: "ChoisirFichier",
	t429: "Choisir les historiques a convertir au format csv ...",
	t430: "Fini",
	t431: "Resume",
	t432: "Fichier historique (-.hst)",
	t433: "Assistant Conversion Historique",
	t434: "Creation fichier complete.",
	t435: "Choisir les donnees a convertir au format csv ...",
	t436: "Choisir Echelle Temps",
	t437: "Choisir l'echelle temps et presser le bouton proceder...",
	t438: "Procede Donnee",
	t439: "Preparation des donnees, patienter",
	t440: "Choisir nom de fichier CSV et sauvegarder les donnees dans fichier ....",
	t441: "Pas de point historique",
	t442: "Erreur - Pas d'historique retourne",
	t443: "Il y a eu une erreur de communication avec les unites au travers du reseau hote. Trouve",
	t444: "Attention",
	t445: "Silence",
	t446: "Database non sauvee, voulez vous vraiment fermer la fenetre ?",
	t447: "Vue Tableau",
	t448: "Afficher Graphique",
	t449: "Afficher Tableau",
	t450: "Degivrage Manuel On",
	t451: "Degivrage Manuel Off",
	t452: "Degivrage auto",
	t453: "Regler Controle Degivrage",
	t454: "Reduction de puissance on",
	t455: "Reduction de puissance off",
	t456: "Liquide",
	t457: "Plannings non configures.",
	t458: "Vue magasin",
	t459: "Communication perdue avec une unite sur le reseau hote !",
	t460: "Reglages Rapide",
	t461: "Rue",
	t462: "Ville",
	t463: "Téléphone",
	t464: "Region",
	t466: "Index",
	t469: "Repertoire Adresse Exporte Avec Succes",
	t470: "Effacer un site",
	t471: "Jauge",
	t472: "Note: mot de passe sensible a la casse",
	t473: "Enligne",
	t474: "RO Board",
	t475: "SI Board",
	t476: "Carte OI",
	t477: "Calcul",
	t478: "Reglee a l'heure actuelle",
	t479: "Centrale",
	t480: "Pas de compresseur",
	t481: "Circuit",
	t482: "Poste",
	t483: "Inconnu",
	t484: "type err:",
	t485: "Ajouter",
	t486: "Copie",
	t487: "Supprimer",
	t488: "Controle Poste",
	t489: "Type MFV",
	t490: "LON",
	t491: "Entrer quantite a copier",
	t492: "Pas de nom",
	t493: "Configuration plan refrigeration …",
	t494: "Erreur Configuration:",
	t495: "Verifiez la configuration.",
	t496: "La configuration de la mise en page refrigeration a reussi. Appuyez sur le bouton OK pour quitter cet assistant.",
	t497: "Etat Config",
	t498: "Plan refrigeration Final",
	t500: "Code #",
	t501: "Arriere Plan",
	t502: "Reglage Systeme",
	t503: "License",
	t504: "Utilisateurs",
	t505: "Non autorise",
	t506: "Merci de verifier…",
	t507: "Etes vous sur de vouloir quitter cet ecran? Les reglages seront perdus.",
	t508: "scanne reseau regulateurs",
	t509: "Scannant",
	t510: "Verification",
	t511: "AK-I/O",
	t512: "Re-scanne",
	t513: "Inclure Programme Hors ligne",
	t514: "Bus Appareil",
	t515: "Appareil en ligne",
	t516: "Programmation hors ligne",
	t517: "Dupliquer Adresse",
	t518: "Compresseur Controle",
	t519: "Groupes Asp.",
	t520: "N/A",
	t521: "Postes Disponibles",
	t522: "Autorise Regul Multi-Circuit",
	t523: "Inserez Appareils",
	t524: "Inserez Circuit",
	t525: "Circuit Multi meuble",
	t526: "Chargement Tables…",
	t527: "Demande des donnees d'alarme pour",
	t528: "Historique Groupes",
	t529: "Fichier historique",
	t530: "Tous Fichiers",
	t531: "Montrer Symboles",
	t532: "Afficher Courbes",
	t533: "Pas assez d'historique collecte",
	t534: "Recuperer les dernieres donnees de l'historique.",
	t535: "Etat/Reglage",
	t536: "Telechargement",
	t537: "Exemple Historique…",
	t538: "Recuperation",
	t539: "Donnees…",
	t540: "Intervalle",
	t541: "Min",
	t542: "Max",
	t543: "Moyenne",
	t544: "Aller a fenetre princ. alarme",
	t545: "Arret connexion Auto",
	t546: "Connexion à",
	t547: "Annuler Auto-logon",
	t548: "Merci d'entre le mot de passe de deconnexion.",
	t549: "Web 1",
	t550: "Web 2",
	t551: "Connection Automatique",
	t552: "Deconnexion apres une periode d'inactivite",
	t553: "Ecran d'acceuil",
	t554: "Montrer Menu Configuration",
	t555: "Voir les articles divers sur tableau de bord",
	t556: "Utiliser plein ecran",
	t557: "Active alarme sonore",
	t558: "Autorise Fermeture/Reduction Prog",
	t559: "Erreur en accedant unite #",
	t560: "Connecté au reseau",
	t561: "Auto-Sortie Logon",
	t562: "Reessayer de connecter Essais reconnection",
	t563: "Deconnecte de",
	t564: "Connecté",
	t565: "Format Heure",
	t566: "Format Date",
	t567: "Pression",
	t568: "Luminosite",
	t569: "Sous Refr.",
	t570: "Sous Refr.",
	t571: "Journalier",
	t572: "hebdomadaire",
	t573: "Fichier HST",
	t574: "Fichier CSV",
	t575: "Dimanche",
	t576: "Lundi",
	t577: "Mardi",
	t578: "Mercredi",
	t579: "Jeudi",
	t580: "Vendredi",
	t581: "Samedi",
	t582: "Collecte Historiques",
	t583: "Active auto-collecte",
	t584: "Quand collecter",
	t585: "Sauve emplacement fichier",
	t586: "Choisir",
	t587: "Choisir le repertoire de stockage du fichier des historiques...",
	t588: "Activer collecte automatique des historiques pour recuperer et sauver chaque jour ou semaine les donnes des sites configures.",
	t589: "Pour visualiser les fichiers historiques HST ouvrez le menu historique et selectionner Import historique. Pour visualiser les fichiers CSV utiliser un tableur.",
	t590: "Si l'adresse IP de la connexion en cours se trouve dans le carnet d'adresses les points historiquesseront sauves.",
	t591: "Choisir points",
	t592: "Tous select",
	t593: "Points selectionnes",
	t594: "Recherche",
	t595: "Auto-connexion a",
	t596: "Merci de verifier",
	t597: "La collecte automatique des historiques est active. Etes-vous sur de vouloir fermer ce programme?",
	t598: "Etes-vous sur de vouloir fermer ce programme?",
	t599: "Action",
	t600: "Filtre",
	t601: "Ttes Actions",
	t602: "Plus d'information...",
	t603: "Modifiable",
	t604: "Operation",
	t605: "Opration annulee",
	t606: "Telechargement Database Fini",
	t607: "La base de donnees des unites a ete mise a jour",
	t608: "Fichier(s) transfere(s) a l'unite avec succes",
	t609: "Telechargement termine, le fichier est en cours de traitement dans l'unite",
	t610: "Erreur en telechargeant le fichier vers le PC, verifier si le fichier est accessible et modifiable",
	t611: "Telechargement fichier",
	t612: "Telechargement fichier",
	t613: "Telechargement des fichiers selectiones",
	t614: "Telechargement des fichiers selectiones",
	t615: "Effacement fichier",
	t616: "Effacement des fichiers selectionnes",
	t617: "Erreur de connexion FTP",
	t618: "Telechager le(s) fichier(s) selectione(s) vers l'AK-CS",
	t619: "Erreur survenu lors de la connexion FTP. Verifier les autorisations FTP et l'adresse IP",
	t620: "FTP: Connecte",
	t621: "Bytes",
	t622: "Les fichiers de l'Unite seront ecrases par l'operation, Voulez vous continuer?",
	t623: "Les fichiers du PC seront ecrases par l'operation, Voulez vous continuer?",
	t624: "Les fichiers sont telecharges dans l'unite.",
	t625: "Refrigeration seul",
	t626: "Magasin Proximite",
	t627: "Fonctionalitee Complete",
	t628: "HVAC Seul",
	t629: "Nom de fichier illegal; Verifier que le nom du fichier comporte maximum 8 caracteres.",
	t630: "Remplacer le logiciel maintenant?",
	t631: "Plus tard",
	t632: "Telecharge",
	t633: "vous devez choisir OUI pour remplacer le logiciel maintenant ou choisir APRES",
	t634: "Merci de selectionner le repertoire racine",
	t635: "Merci de selectionner le repertoire windows",
	t636: "Merci de selectionner le repertoire cible",
	t637: "Choisir le repertoire de la database",
	t638: "FTP: Deconnecte",
	t639: "Delai de connexion FTP depassee, FTP deconnecte",
	t640: "Telecharge le(s) fichier(s) selectionne(s) vers le PC",
	t641: "Effacer le(s) fichier(s) selectionne(s)",
	t642: "Port Server FTP",
	t643: "Port Web Server",
	t644: "Chemin local",
	t645: "Haut",
	t646: "Taille",
	t647: "Date",
	t648: "Connecter",
	t649: "Deconnecter",
	t650: "Chemin distant",
	t651: "Espace libre disponible:",
	t652: "Fichier(s) selectionnee",
	t653: "Fichiers",
	t654: "Taille totale:",
	t655: "Cliquer pour changer de repertoire",
	t656: "Change...",
	t657: "Voulez-vous effacer le(s) fichier(s) selectionne(s)?",
	t658: "Connexion interrompue, sortie",
	t659: "Le(s) nom(s) de fichiers est(sont) illegal(ux) et le telechargement ignore.",
	t660: "Certains noms des fichiers selectionnes sont illegaux et ignores.",
	t661: "Fichier(s) transfere(s) au PC avec succes",
	t662: "Pas assez d'espace libre disponible sur l'unite.",
	t663: "Transfert Fichiers",
	t664: "Modifie",
	t665: "Copier les Fichiers de Flash Disk vers Ram Disk",
	t666: "Selectionnez l'operation de copie desiree:",
	t667: "Copier Tous les Fichiers EDF configures de Flash Disk",
	t668: "Copier Tous les Fichiers EDF de Flash Disk",
	t669: "Les fichiers edf/ed3 sont stockés sur le disque flash de votre appareil, mais pas chargés sur l'écran de gestion des fichiers. Utilisez les options ci-dessous pour copier les fichiers EDF stockés dans votre appareil vers l'écran de gestion des fichiers",
	t670: "This Operation Copies all the EDF files that has been configured in the unit from flash disk to RAM disk",
	t671: "Cette operation copie tous les fichiers EDF qui a ete configure dans l'unite memoire flash a la memoire RAM",
	t672: "Fichier EDF valide inexistant",
	t673: "Copie Fichier EDF Termine",
	t674: "fichiers copies",
	t675: "Confirmer chargement database",
	t676: "Adresse Unite",
	t677: "Adresse IP/Nom d'hôte",
	t678: "Nom Fichier Database",
	t679: "Choisir",
	t680: "Mettre a jour complete: CMA: reinitialisation et maj de l'unite. Cela peut prendre plusieurs minutes.",
	t681: "Bienvenue sur Gestion Mise a niveau - etape 1 de 2",
	t682: "Bienvenue sur Gestion Mise a niveau - etape 2 de 2",
	t683: "En cours",
	t684: "Verification pre MaJ",
	t685: "Firmware locale",
	t686: "Sauvegarde Database?",
	t687: "Sauvegarde Database",
	t688: "Transfert logiciel",
	t689: "Remplacer Software",
	t690: "Choisir logiciel de mise a niveau",
	t691: "Pas d'application FAI detectee.",
	t692: "Vous pouvez continuer le processus mais des erreurs durant le processus de mise a jour peut rendre le systeme inutilisable",
	t693: "Il est fortement conseille de NE PAS continuer.",
	t694: "Voulez-vous continuer?",
	t695: "La mise a jour d'un system a distance peut echouer, ce qui implique un risque d'erreur systeme.",
	t696: "Desistement",
	t697: "Chargement Database Termine",
	t698: "Sauvegarde Database Termine",
	t699: "Chargement Application Termine",
	t700: "Reecriture Application Termine",
	t701: "Mise a jour logiciel reussi",
	t702: "Voulez-vous redemarrer l'unite avec les reglages usine?",
	t703: "Verification Materiel",
	t704: "Verification Etat Regulateur",
	t705: "MAJ Securise Usine",
	t706: "Version Firmware Actuelle",
	t707: "Demarrage mise a jour",
	t708: "Choisir un fichier de base de donnees existante",
	t709: "Choisir un fichier firmware existant",
	t710: "Arriere",
	t711: "Firmware locale",
	t712: "Choisir le fichier .csi",
	t713: "Choisir fichier .mai",
	t714: "Telechargement a distance non supporte par l'unite.",
	t715: "Chargement Application Echoue",
	t716: "Echec du telechargement de la database, reessayer",
	t717: "Reecriture Application Termine",
	t718: "Reecriture Application Echoue! Reessayer.",
	t719: "Reecriture Application Echoue",
	t720: "Pas d'application FAI detectee.",
	t721: "Fait",
	t722: "L'application selectionnee peut avoir un type de version differente que le firmware de l'actuelle unite: CMA: poursuivre l'exploitation peut comporter un risque d'echec et d'erreur du systeme.",
	t723: "Le(s) nom(s) de fichiers est(sont) illegal(ux) et le telechargement ignore.",
	t724: "Certains noms des fichiers selectionnes sont illegaux et ignores.",
	t725: "Cavalier de l'unite est absent.",
	t726: "Batterie faible",
	t727: "Reseau de l'unite eleve  a echoue.",
	t728: "L'unite est en etat ​​de delestage",
	t729: "Choisir le fichier de destination",
	t730: "Choisir le repertoire de destination",
	t731: "Chargement Database Termine",
	t732: "Etat Ok",
	t733: "Erreur Etat",
	t734: "Etat Inconnu",
	t735: "Cette operation pourrait ecraser les fichiers selectionnes dans le simulateur cible, voulez vous continuer?",
	t736: "Telechargement de la database termine, vous devez redemarrer l'AK-CS",
	t737: "Echec du telechargement, reesayer plus tard",
	t738: "L'AK-CS redemarrera automatiquement apres l'operation, voulez-vous continuer?",
	t739: "Choisir database",
	t740: "Telecharger la database?",
	t741: "Charger la database?",
	t742: "Telechargement de la database vers l'AK-CS, patienter",
	t743: "Telechargement Database fini avec succes, maintenant redemarrer l'AK-CS",
	t744: "Mise a jour de la database finie avec succes, unite redemarree avec succes",
	t745: "Echec de la mise a jour! Reessayer.",
	t746: "Erreur en mettant a jour la database",
	t747: "Verif. Pre Chargement",
	t748: "Database Locale",
	t749: "Telechargement Database",
	t750: "Information Database Actuelle",
	t751: "Choisir la base de donnee a telecharger",
	t752: "Sauvegarde de la database vers le PC",
	t753: "Chargement de la database vers l'AK-CS",
	t754: "Besoin du niveau Autorisation Superviseur",
	t755: "Demarrage chargement",
	t756: "Merci de selection l'operation de la database",
	t757: "Demarrage telechargement",
	t758: "Version Firmware Actuelle",
	t760: "Telechargement Database fini avec succes!",
	t761: "Bienvenue sur Gestion Database - etape 1 de 2",
	t762: "Bienvenue sur Gestion Database - etape 2 de 2",
	t763: "Echec d'obtention de la liste des utilisateurs, verifier l'adresse IP",
	t764: "La mise a jour d'une database a distance peut echouer, ce qui implique un risque d'erreur systeme.",
	t765: "Telechargement Database Fini",
	t766: "Ethernet Scan",
	t767: "Entrer premier adresse IP puis demarrer",
	t768: "Scan",
	t769: "Scannant",
	t770: "Collecte Historiques",
	t771: "Couleurs graphes analogique",
	t772: "Couleurs Graphe Digital",
	t773: "Plannings Arret",
	t774: "Resume Degivrage",
	t775: "Programmes Reg Nocturne",
	t776: "Plannings Eclairage Vitrine",
	t777: "Etat Chargement",
	t778: "Collecte Enreg Alarme",
	t779: "Choisir le repertoire de stockage du fichier des alarmes...",
	t780: "Mensuelle",
	t781: "Autoriser la collecte et la sauvegarde automatique et periodique des alarmes dans un fichier.",
	t782: "Separateur CSV",
	t783: "Pas d'alarme",
	t784: "Recuperation comptage alarmes pour unite:",
	t785: "Recuperation detail alarmes pour unite:",
	t786: "La collecte automatique des alarmes est active. Etes-vous sur de vouloir fermer ce programme?",
	t787: "Horaire",
	t788: "This will update the unit's database and set the location to the center of the current view. Proceed?",
	t789: "échantillons traités",
	t790: "Configurer un Groupe Aspiration svp",
	t791: "To re-use the Layout Wizard more than once the refrigeration database layout needs to be reset.",
	t792: "Do this via the Configuration-&gt;Control page and set the number of Racks/Packs to zero.",
	t793: "Note: any schedules, calculations or imports based on prior refrigeration configuration will need to be reconfigured.",
	t794: "Procédure copie terminée.",
	t795: "Rapport Réfrigération",
	t796: "Vue Finale HVAC",
	t797: "The HVAC layout setup has completed successfully. Press the ok button to exit this wizard.",
	t798: "Configuration HVAC...",
	t799: "To re-use the HVAC Layout Wizard more than once the HVAC database layout needs to be reset.",
	t800: "Do this via the Configuration-&gt;Control page and set the number of HVAC units to zero.",
	t801: "Note: any schedules, calculations or imports based on prior HVAC configuration will need to be reconfigured.",
	t802: "Modele etagemt",
	t803: "Etage",
	t804: "Niv.",
	t805: "Show Multiple DataTip",
	t806: "Nb Sorties Pas a Pas",
	t807: "Ne peut pas sauver le fichier.",
	t808: "Modèle incorrecte.",
	t809: "Charger avec succès.",
	t810: "Ouvert",
	t811: "This feature is only available in the desktop version of StoreView.",
	t812: "Continuer",
	t813: "Quitter Assistant",
	t814: "Operation succeeded. Would you like to continue or you want to leave the wizard.",
	t815: "Erreur en récupérant données alarme enregistreur.",
	t817: "Nom Appareil:",
	t819: "En Cours...",
	t820: "Alarm data is not present.",
	t821: "Comptage Alarme",
	t822: "Options Affichage Mimic",
	t823: "Afficher texte seulement",
	t824: "Afficher seulement icone",
	t825: "Afficher texte et icone",
	t826: "1 semaine",
	t827: "2 semaines",
	t828: "3 semaines",
	t829: "4 semaines",
	t830: "Reglage graphique",
	t831: "Impossible d'afficher le diagnostic d'alarme, s'il vous plaît vérifier la collecte d'alarme automatique dans les préférences",
	t832: "Réorganisation non autorisé avec racks IO ou circuits inclus dans la configuration.",
	t833: "Cette adresse IP/URL est déjà présent. Voulez vous mettre à jour cet entrée?",
	t834: "Parcourir",
	t835: "Selectionner le dossier répertoire",
	t836: "Emplacement",
	t837: "L'emplacement sélectionné ne contient pas de répertoire. Voulez vous exporter le contenu du répertoire existant vers cet emplacement?",
	t838: "Code Action Alarme",
	t839: "Le fichier répertoire n'a pâs été trouvé dans le dossier selectionné. Repertoire chargé localement…",
	t840: "Merci de sélectionnez un emplacement pour la sauvegarde.",
	t841: "Merci de sélectionnez un emplacement valide pour la sauvegarde.",
	t842: "Collecte alarme en cours…",
	t843: "Collecte historique en cours…",
	t844: "Marche en arriere-plan",
	t845: "Progression collecte alarme",
	t846: "Adresse IP/URL ne peut etre vide",
	t847: "Champs adresse ne peut pas etre vide",
	t849: "Vous n'avez pas la dernière version du fichier, voulez vous télécharger les données de la dernière version?",
	t850: "Conflit de version",
	t851: "Progression collecte historique",
	t852: "User Name and Password fields should not be blank.",
	t853: "IP Address field should not be blank.",
	t854: "Host IP/URL Address field should not be blank.",
	t855: "User name should not be blank.",
	t856: "Password should not be blank.",
	t857: "The SM800 you have connected to with",
	t858: "has not had the Initial Startup Wizard completed. Please complete this task and reconnect to the SM800",
	t859: "Set to now",
	t860: "Invalid input",
	t861: "Text is too long",
	t862: "Offset",
	t863: "Unable to communicate with unit(s) on host network",
	t864: "Please verify addresses are different. Configuration is updated when the save button is pressed.",
	t865: "Please select a valid History File (.hst)",
	t866: "Please select a valid History Group File (.grp)",
	t867: "Couleur",
	t868: "Pourcentage",
	t869: "Tension",
	t870: "Actuelle",
	t871: "Pouvoir",
	t872: "Fréquence",
	t873: "Concentration",
	t874: "Flux",
	t875: "Vitesse",
	t876: "Acidité",
	t877: "S’il vous plaît sélectionner des graphiques au tiroir inférieur",
	t878: "Enregistrer PNG",
	t879: "Dessiner",
	t880: "État du système...",
	t881: "État du système et de l’unité",
	t882: "Gataway Gataway Gataway Gataway",
	t883: "Licences de fonctionnalités",
	t884: "Version logicielle",
	t885: "Version du module de navigateur",
	t886: "Version Danux",
	t887: "Adresse Mac",
	t888: "Support & Mises à jour",
	t889: "Nom de l’unité",
	t890: "Reconnecter",
	t891: "Connexion perdue",
	t892: "Aucun équipement {{type}} trouvé.",
	t893: "La version logicielle actuellement utilisée n’est pas prise en charge",
	t894: "Liens rapides",
	t895: "Équipement",
	t896: "Graphique",
	t897: "Utilitaires",
	t898: "Restauration logicielle",
	t899: "Diagnostics",
	t900: "Une erreur s’est produite",
	t901: "Pas de données {{alarmes}} trouvées",
	t902: "Paramètres",
	t903: "Nom de l’application",
	t904: "Version d’application",
	t905: "Message",
	t906: "Type d’événement",
	t907: "Timestamp",
	t908: "Version rollback",
	t909: "Version actuelle",
	t910: "Logiciel",
	t911: "Restauration...",
	t912: "S’il vous plaît sélectionner l’équipement de la liste",
	t913: "Vue du calendrier",
	t914: "Une erreur s’est produite lors du chargement des périphériques( s), s’il vous plaît essayer à nouveau.",
	t915: "Une erreur s’est produite pendant le chargement des horaires, s’il vous plaît essayer à nouveau.",
	t916: "Aucun équipement trouvé",
	t917: "Calendriers Exportation",
	t918: "Veuillez choisir le type d’exportation CSV ou PDF",
	t919: "Exportation en tant que CSV",
	t920: "Préparation PDF",
	t921: "Exportation en PDF",
	t922: "S’il vous plaît fournir une ADRESSE IP dans ce format xx.xx.xx.xx[:xxxx]",
	t923: "nom d'utilisateur",
	t924: "connectez-vous",
	t925: "Restauration",
	t926: "Logiciel Rollback SM800A",
	t927: "Cette action va rollback tous les logiciels vers les versions répertoriées.",
	t928: "Éditeur d’appareils de fête",
	t929: "un texte qui explique la fonctionnalité",
	t930: "Mise à jour CDF",
	t931: "Dispositif de carte",
	t932: "Fichier d’appareil",
	t933: "Numéro de registre basé sur 1",
	t934: "Type de stockage",
	t935: "Min",
	t936: "Max",
	t937: "Lire uniquement",
	t938: "rapport",
	t939: "Message Type",
	t940: "Mot clé de conversion",
	t941: "Paramètres",
	t942: "Addr.",
	t943: "Priorité",
	t944: "Peu",
	t945: "Nié",
	t946: "Nouveau fichier",
	t947: "Fichier ouvert",
	t948: "Générer du fichier",
	t949: "Groupe",
	t950: "Version produit",
	t951: "Erreur s’est produite",
	t952: "Ina pas trouvé",
	t953: "Cet appareil n’a pas été trouvé",
	t954: "Temp extérieur.",
	t955: "Aucun {{équipement}} trouvé",
	t956: "Cas",
	t957: "Alarme avec succès mute/act",
	t958: "Mute/act a échoué pour cette alarme",
	t959: "Une erreur s’est produite lors du chargement de l’unité(s), s’il vous plaît essayer à nouveau.",
	t960: "Pas d’équipement",
	t961: "Aucun équipement {{type}} trouvé",
	t962: "Configurer le gestionnaire de système",
	t963: "Appareil",
	t964: "Annuler",
	t965: "Vous avez changé la valeur du champ, voulez-vous enregistrer les changements?",
	t966: "Effacer le(s) point(s) d'historique sélectionné(s)",
	t967: "Shr {{number}}",
	t968: "Vitesse de la pompe Hr",
	t969: "Sd",
	t970: "V3h",
	t971: "Référence rh",
	t972: "Stw {{number}}",
	t973: "Deux références",
	t974: "Deux vitesses de pompe",
	t975: "V3tw",
	t976: "Trec",
	t977: "Préc",
	t978: "Vrec OD",
	t979: "Préc référence",
	t980: "Référence Trec",
	t981: "Pc",
	t982: "SS",
	t983: "Vhp OD",
	t984: "Référence PGC",
	t985: "Diamètre extérieur de l'éjecteur",
	t986: "Pgc",
	t987: "Shp",
	t988: "SGC",
	t989: "Sc3",
	t990: "Sgc ctrl.",
	t991: "Cond. capacité",
	t992: "Pô",
	t993: "SS",
	t994: "Sd",
	t995: "ordinateur",
	t996: "Capacité de fonctionnement",
	t997: "Certificat de navigateur",
	t998: "Log Off",
	t1000: "Tri uniquement sur {{count}}",
	t1001: "{{count}} {{total}}",
	t1002: "Acquittement réussi pour toutes les alarmes",
	t1003: "Echec de l'acquittement pour toutes les alarmes",
	t1004: "Accusé de réception de seulement {{count}} {{total}}",
	t1005: "L'exportation a échoué pour toutes les alarmes",
	t1006: "Exportation réussie",
	t1007: "Échec de l'exportation, au moins un type d'alarme est requis",
	t2000: "-",
	t2001: "La réponse est trop volumineuse pour le tampon de transmission XML qui est de 32 Ko",
	t2002: "Aucun attribut associé à l'élément cmd ou la commande n'a pas d'élément \"cmd\" ou n'a pas pu écrire dans une variable ou n'a pas pu lire une variable ou n'a pas pu lire un point ou n'a pas pu écrire l'entrée marche/arrêt ou le mode de fonctionnement de la sortie relais",
	t2003: "Mauvaise entrée de commande",
	t2004: "Erreur dans les paramètres de publication CGI",
	t2005: "Erreur lors de la conversion de la réponse XML de sa représentation interne en chaîne XML de réponse",
	t2006: "Erreur lors du décodage de la chaîne d'URL",
	t2007: "Débordement de mémoire",
	t2008: "Échec de la compression des données de réponse",
	t2009: "Erreur lors de l'analyse de la demande",
	t2010: "Erreur d'accès aux données - un certain nombre de causes qui ne sont pas résolues davantage",
	t2011: "Valeur d'attribut d'action de commande non définie La valeur d'attribut n'est pas valide",
	t2012: "Erreur dans le numéro de référence de l'alarme ou alarme non configurée",
	t2013: "La chaîne du paramètre cgi d'entrée est trop longue.",
	t2014: "L'adresse de l'appareil est introuvable",
	t2015: "Échec de l'autorisation d'écriture",
	t2016: "Aucun historique configuré",
	t2017: "Aucune donnée d'historique renvoyée, peut être une erreur de temps",
	t2018: "Créer des erreurs de fichier",
	t2019: "Lire les erreurs de fichier",
	t2020: "La taille maximale de la liste d'appareils a été dépassée",
	t2021: "non utilisé",
	t2022: "Index d'historique invalide",
	t2023: "Impossible de trouver l'index de point de la carte à partir du type de nœud, du nœud, du mod et du point.",
	t2024: "Impossible de trouver l'index de l'historique",
	t2025: "La requête d'historique n'a pas été initialisée avant de tenter de démarrer une requête d'historique.",
	t2026: "Une hq_start_query a été tentée alors qu'une requête d'historique était active",
	t2027: "Aucun attribut de commande trouvé alors que certains sont attendus",
	t2028: "Un début d'époque a été fourni sans fin ou vice-versa",
	t2029: "Impossible de convertir le format d'heure et de date en secondes d'époque",
	t2030: "La commande nécessite un query_id mais aucun n'est fourni",
	t2031: "Erreur lors de la création de la réponse XML",
	t2032: "Le query_id fourni ne correspond pas à une requête active",
	t2033: "Une commande hq_get_data a été tentée mais les données ne sont pas encore disponibles",
	t2034: "Un taux d'échantillonnage a été fourni qui n'est pas l'un de ceux autorisés",
	t2035: "L'intervalle de calcul de la moyenne est inférieur ou égal à la fréquence d'échantillonnage à laquelle les données ont été enregistrées",
	t2036: "Le temps d'époque d'arrêt est inférieur au temps d'époque de début ou le temps d'époque d'arrêt moins le temps d'époque de début est inférieur à l'intervalle de calcul de la moyenne",
	t2037: "Le device_id fourni ne correspond à aucun actuellement connu de l'ak255. Notez que seuls les appareils qui ont été configurés sont connus de l'ak255",
	t2038: "Aucun attribut d'identifiant",
	t2039: "Attributs d'ID non valides",
	t2040: "Pas de numéro d'horaire",
	t2041: "Numéro d'horaire invalide",
	t2042: "Aucun détail d'horaire",
	t2043: "Manquant on_time",
	t2044: "Heure manquante",
	t2045: "Heure invalide",
	t2046: "Minute manquante",
	t2047: "Minute invalide",
	t2048: "Temps de repos manquant",
	t2049: "Jours de semaine manquants",
	t2050: "Jours de semaine invalides",
	t2051: "Vacances manquantes",
	t2052: "Jours fériés invalides",
	t2053: "holiday_start manquant ou invalide",
	t2054: "holiday_end manquant ou invalide",
	t2055: "Holiday_open manquant ou invalide",
	t2056: "vacances manquantes ou non valides_close",
	t2057: "Vacances non configurées",
	t2058: "Description manquante",
	t2059: "Impossible d'effacer l'alarme",
	t2060: "store_open manquant ou invalide",
	t2061: "store_closed manquant ou invalide",
	t2062: "Alarme non configurée",
	t2063: "Nœud hors ligne",
	t2064: "Index manquant",
	t2065: "Index invalide",
	t2066: "Le relais ne peut pas être chronométré",
	t2067: "Le nom en cours de modification contient trop de caractères. Si le changement de store_name, ou unit_name, doit être < = 16 caractères, si le changement de storeId1, ou storeId2, doit être < = 8 caractères.",
	t2068: "Éléments manquants associés à l'élément de commande.",
	t2069: "Tag_ID non défini",
	t2070: "Nœud feuille manquant pour les données",
	t2071: "Aucun changement dans les données",
	t2072: "Échec de la mise à jour de la base de données",
	t2073: "Non inscriptible",
	t2074: "Erreur de compteur de remise",
	t2075: "Pas de compteurs",
	t2076: "Niveau de remise invalide",
	t2077: "Type invalide",
	t2078: "Système occupé",
	t2079: "Actifs non configurés pour le délestage",
	t2080: "Puissance nominale non valide pour l'actif",
	t2081: "Délai de démarrage non valide pour l'actif",
	t2082: "Configuration occupée",
	t2083: "I/O Scanning en cours",
	t2084: "Trop de dégivrages en cours",
	t2085: "Essayer de charger une mauvaise version de la base de données dans l'unité",
	t2086: "Le chargement de la base de données a échoué",
	t2087: "Aucune mémoire Web disponible disponible",
	t2088: "Réponse à la demande non configurée (Seulement le système AK355)",
	t2089: "Taille de fichier manquante ou incorrecte.",
	t2090: "Fichier encodé manquant (éléments b64)",
	t2091: "Échec de l'enregistrement de la base de données sur le disque RAM.",
	t2092: "Taille du fichier trop grande.",
	t2093: "Nom de fichier manquant.",
	t2094: "ID de fichier manquant.",
	t2095: "Index de chargement de fichier manquant (Remarque : il s'agit de l'index de séquence pour load_file load_database).",
	t2096: "L'ID de fichier ne correspond pas ou est manquant.",
	t2097: "Opération de chargement de fichier déjà en cours.",
	t2098: "Le fichier décodé ne correspond pas à la taille du fichier d'origine.",
	t2099: "L'opération de chargement de fichier a été annulée ou n'a pas démarré.",
	t2100: "Demande d'utilisateur non valide (le demandeur d'origine ne correspond pas à l'adresse IP)",
	t2101: "Séquence de chargement de fichier non valide.",
	t2102: "Répertoire invalide",
	t2103: "Extension invalide",
	t2104: "Décalage invalide",
	t2105: "Erreur d'encodage du fichier",
	t2106: "Erreur de décodage du fichier",
	t2107: "Impossible d'ouvrir le fichier en lecture/écriture",
	t2108: "Impossible d'écrire dans le fichier",
	t2109: "Le fichier est protégé en écriture dans le système de fichiers Flash",
	t2110: "Échec de la suppression du fichier.",
	t2111: "Impossible de supprimer le dossier.",
	t2112: "Appareils manquants",
	t2113: "Tous les appareils de l'URL n'ont pas été trouvés",
	t2114: "Paramètres d'historique manquants",
	t2115: "Tous les paramètres d'historique de l'URL n'ont pas été trouvés",
	t2200: "La version du logiciel actuellement utilisée ( {{currentVersion}} ) n'est pas recommandée",
	t2201: "La version du logiciel actuellement utilisée ( {{currentVersion}} ) n'est pas recommandée ( > {{recommendedVersion}} recommandé)",
	t2202: "Erreur de version du logiciel",
	t2203: "Éditeur graphique",
	t2204: "Modifier",
	t2205: "Dessiner",
	t2206: "Nouvelle vue",
	t2207: "Créé",
	t2208: "Laissez tomber",
	t2209: "Nouveau graphique",
	t2210: "Créer une nouvelle vue graphique",
	t2211: "Nom",
	t2212: "Renommer",
	t2214: "Exportation",
	t2213: "Dupliquer",
	t2215: "Supprimer",
	t2216: "Voulez-vous vraiment supprimer la vue \" {{name}} \" ?",
	t2217: "Exporter toutes les vues",
	t2218: "Suite...",
	t2219: "Régner",
	t2220: "des règles",
	t2221: "Nom de la règle",
	t2222: "Panneau d'édition",
	t2223: "Conditions",
	t2224: "Pente",
	t2225: "Inverser les conditions",
	t2226: "Mappages",
	t2227: "Mapper les formes par",
	t2228: "ID de forme",
	t2229: "États",
	t2230: "Ajouter un mappage",
	t2231: "Ajouter une règle",
	t2232: "Opérateur",
	t2233: "Ajouter une condition",
	t2234: "Commencez par ajouter des règles",
	t2235: "Voulez-vous vraiment supprimer la règle \" {{name}} \" ?",
	t2236: "Une copie de la règle est faite",
	t2237: "Vues du site",
	t2238: "Vous devez créer des vues de site pour les afficher",
	t2239: "Aller à l'éditeur graphique",
	t2240: "Aller aux détails",
	t2241: "Sélectionnez le paramètre",
	t2242: "Aucun paramètre trouvé",
	t2243: "Choix",
	t2244: "Entrée Marche/Arrêt",
	t2245: "Sortie relais",
	t2246: "Entrée du capteur",
	t2247: "Sortie variable",
	t2248: "Dispositif générique",
	t2249: "Nœud vide",
	t2250: "La journalisation de l'historique n'est pas prise en charge...",
	t2251: "Réessayer",
	t2252: "Impossible de récupérer l'historique",
	t2253: "Le chargement de l'historique peut prendre un certain temps et ne prend pas en charge plusieurs requêtes en même temps, veuillez réessayer cette requête.",
	t2254: "Le nom du paramètre",
	t2255: "Nom régulateur",
	t2256: "Type de dispositif",
	t2257: "Tous les paramètres d'historique n'ont pas pu être chargés (échec de {{n}} )",
	t2258: "Nom d'utilisateur ou mot de passe erroné",
	t2259: "Aucune communication avec le system-manager",
	t2260: "Mise à jour logicielle",
	t2261: "Système",
	t2262: "Restauration logicielle",
	t2263: "Installer",
	t2264: "La restauration du logiciel remplacera une version plus récente",
	t2265: "Logiciel de restauration vers la version de sauvegarde {{version}}",
	t2266: "Base de données",
	t2267: "Une mise à jour est disponible",
	t2268: "Exporter la base de données",
	t2269: "Télécharger",
	t2270: "Téléchargement de la base de données",
	t2271: "Impossible d'exporter la base de données",
	t2272: "Fichier de base de données",
	t2999: "Erreur de communication",
	t2998: "Erreur inconnue",
	t3000: "Détails",
	t3001: "Code XML passé ici",
	t3002: "Affecter à",
	t3003: "État",
	t3004: "Gestion des logiciels",
	t3005: "Fichier stocké localement",
	t3006: "En ligne via internet",
	t3007: "Assurez-vous que l'alimentation n'est pas interrompue pendant le processus de mise à jour. Il est recommandé de faire une sauvegarde de la base de données du système avant de mettre à jour votre système. Après avoir terminé la ou les mises à jour logicielles, il est recommandé d'inspecter l'état de fonctionnement du système pour confirmer le contrôle et le fonctionnement corrects.",
	t3008: "Aucun fichier sélectionné",
	t3009: "Sélectionnez le fichier du logiciel",
	t3010: "Télécharger des fichiers",
	t3011: "Contrôle de sécurité",
	t3012: "Fichier d'extraction",
	t3013: "Vérification du fichier",
	t3014: "Le téléchargement du fichier a échoué !",
	t3015: "Le contrôle de sécurité a échoué !",
	t3016: "Échec de l'extraction !",
	t3017: "La vérification du fichier a échoué !",
	t3018: "Téléchargement du fichier : {{fileName}}",
	t3019: "Fichier téléchargé avec succès !",
	t3020: "Échec du téléchargement!",
	t3021: "Télécharger des fichiers",
	t3022: "Telecharger des fichiers",
	t3023: "Mise à jour du système d'exploitation",
	t3024: "Mise à jour EDF",
	t3025: "Telecharger des fichiers",
	t3026: "La valeur du paramètre sélectionné n'était malheureusement pas disponible",
	t3027: "Importer",
	t3028: "Un fichier minimum est requis",
	t3029: "Le fichier n'est pas valide",
	t3030: "Gérer les vues graphiques",
	t3031: "Télécharger",
	t3032: "Migrer",
	t3033: "Lecture des fichiers graphiques, veuillez patienter...",
	t3034: "Importation du fichier graphique, veuillez patienter...",
	t3035: "Choisissez le graphique",
	t3036: "Rapports",
	t3037: "Importer la base de données",
	t3038: "Sélectionnez le fichier de base de données",
	t3039: "Assurez-vous que l'alimentation n'est pas interrompue pendant le processus de restauration. Il est recommandé de faire une sauvegarde de la base de données système avant de restaurer votre système. Après avoir terminé la restauration du logiciel, il est recommandé d'inspecter l'état de fonctionnement du système pour confirmer le contrôle et le fonctionnement corrects.",
	t3040: "Le gestionnaire de système redémarrera après la mise à jour",
	t3041: "Développer",
	t3042: "Afficher les messages de débogage",
	t3043: "Max. caractères autorisés -",
	t3044: "Veuillez télécharger le fichier de mise à jour",
	t3045: "Version logicielle actuelle",
	t3046: "Version actuelle de Danux",
	t3047: "Dernière version logicielle",
	t3048: "Dernière version de Danux",
	t3049: "Sauvegarde en cours, veuillez patienter",
	t3050: "Installation du fichier, veuillez patienter",
	t3051: "Installation de mise à jour",
	t3052: "L'appareil redémarre",
	t3053: "Redémarrage du gestionnaire de système",
	t3054: "Le navigateur va maintenant s'actualiser",
	t3055: "Logiciel installé avec succès",
	t3056: "La mise à jour a échoué, l'erreur suivante s'est produite :",
	t3057: "Sauvegardez la version actuelle du logiciel",
	t3058: "Sauvegardez les fichiers EDF actuels",
	t3059: "La vue graphique est enregistrée avec succès",
	t3060: "Appliquer l'image d'arrière-plan",
	t3061: "Effacer",
	t3062: "Refaire",
	t3063: "Boutique",
	t3064: "Déboires nocturnes du circuit",
	t3065: "Reculs de nuit d'aspiration",
	t3066: "Version téléchargée {{fileName}}",
	t3067: "Pour mettre à jour les unités sélectionnées, appuyez sur \"Installer\"",
	t3068: "Restauration de la version {{updateType}}",
	t3069: "Installation",
	t3070: "Installer la version de restauration",
	t3071: "Aucune des unités disponibles ne contient de version de restauration",
	t3072: "Nouvelle version disponible en ligne",
	t3073: "Télécharger le fichier pour les unités sélectionnées",
	t3074: "Télécharger le fichier pour l'unité sélectionnée",
	t3075: "Aucune version de restauration disponible pour l'unité",
	t3076: "Téléchargement terminé",
	t3077: "Version {{updateType}} actuelle",
	t3078: "Dernière version {{updateType}}",
	t3079: "La mise à jour est en cours, êtes-vous sûr de vouloir fermer la fenêtre ?",
	t3080: "Danux n'est pas à jour ! Veuillez mettre à jour Danux puis mettre à jour le logiciel à nouveau",
	t3081: "Les applications ne peuvent pas être rétrogradées vers cette version",
	t3082: "Informations sur la candidature",
	t3083: "Notes de version",
	t3084: "Conditions et confidentialité",
	t3085: "Licences",
	t3086: "Veuillez accéder à la configuration pour configurer les horaires",
	t3087: "Malheureusement, une erreur s'est produite lors de la préparation de la migration. Veuillez vous assurer que toutes les unités sont accessibles sur le réseau et correctement configurées. \n\n L'erreur suivante s'est produite :\n {{error}}",
	t3088: "Importer ou migrer un graphique existant",
	t3089: "Afficher",
	t3090: "Nom de poste",
	t3091: "S'effondrer",
	t3092: "Importation terminée",
	t3093: "Sécurité de décharge",
	t3094: "Sécurité d'aspiration",
	t3095: "Décharge Cutin",
	t3096: "Découpe de décharge",
	t3097: "Découpe d'aspiration",
	t3098: "Coupe d'aspiration",
	t3099: "Capacité",
	t3100: "Les fichiers de migration (flp, dpj ou json) sont introuvables",
	t3101: "Fichier d'analyse du délai d'attente",
	t3102: "Fichiers et adresses d'appareils tiers",
	t3103: "Réseau",
	t3104: "Fermer à clé",
	t3105: "XML",
	t3106: "Sélectionnez le paramètre à afficher",
	t3107: "Paramètre d'appareil",
	t3108: "Aucune unité",
	t3109: "Il n'y a pas d'unité(s) trouvée(s) ou chargée(s)",
	t3110: "HACCP",
	t3111: "ACTIF",
	t3112: "Aucune donnée haccp disponible",
	t3113: "Informations sur l'unité",
	t3114: "Mises à jour disponibles",
	t3115: "Aller au logiciel",
	t3116: "Pas connecté",
	t3117: "La portée d'accès actuelle pour cette application est ' {{targetLevel}} ', l'utilisateur fourni est au-dessus de cette portée en termes de droits d'accès",
	t3118: "Exportation PDF",
	t3119: "L'exportation PDF a échoué",
	t3120: "Sur de grands ensembles de données, l'interface peut ne pas répondre et prendre un certain temps pour se terminer",
	t3121: "Veuillez sélectionner les horaires dans la liste",
	t3122: "Veuillez accéder à la configuration pour définir un horaire",
	t3123: "Succès",
	t3124: "Attention",
	t3125: "Informations sur le site",
	t3126: "Veuillez sélectionner l'heure et les points de données dans la liste",
	t3127: "De liaison",
	t3128: "La connexion n'a pas pu être établie avec ce site, veuillez contacter le support",
	t3129: "Aucune donnée HACCP n'était disponible à la date choisie. Veuillez vous assurer que l'historique et l'heure sont correctement configurés dans le menu de configuration",
	t3130: "Rapport diagnostique",
	t3131: "Sélectionner les unités pour l'exportation du rapport de diagnostic",
	t3132: "Non disponible à ce niveau d'accès utilisateur",
	t3133: "Journal de diagnostic",
	t3134: "échec du téléchargement du rapport",
	t3135: "Police de caractère",
	t3136: "taille de police",
	t3137: "Aucune vue à migrer",
	t3138: "Télécharger la version de bureau",
	t3139: "En raison des restrictions de sécurité des navigateurs modernes (",
	t3140: "RFC1918",
	t3141: "), il n'est malheureusement pas possible de se connecter à des appareils au sein d'un espace de réseau privé en utilisant HTTP non crypté. Configurez votre gestionnaire de système pour utiliser HTTPS ou utilisez la version de bureau",
	t3142: "Aucune donnée disponible pour la période sélectionnée",
	t3143: "Configurez votre gestionnaire de système pour utiliser HTTPS ou utilisez la version de bureau.",
	t3144: "Nous n'avons pas pu communiquer avec le gestionnaire du système. Cela signifie soit que votre appareil ne peut pas se connecter à l'adresse IP ou au nom d'hôte du gestionnaire de système, soit que vous devrez peut-être approuver manuellement le certificat SSL utilisé sur l'appareil.",
	t3145: "Cliquez sur le lien suivant pour ouvrir un nouvel onglet afin de faire confiance au certificat, puis revenez à cet écran.",
	t3146: "Signature",
	t3147: "DI - entrées numériques",
	t3148: "AI - entrées analogiques",
	t3149: "Aller à la configuration HACCP",
	t3150: "Pas de données",
	t3151: "La sauvegarde est enregistrée dans le gestionnaire de système pour la fonction de restauration",
	t3152: "Sélectionnez les éléments à afficher dans la ou les vues du site",
	t3153: "Unité d'actif",
	t3154: "Fichiers graphiques",
	t3155: "Malheureusement, une erreur s'est produite lors de la récupération des fichiers edf et des versions du gestionnaire de système...",
	t3156: "Pour sélectionner les fichiers à installer, appuyez sur le bouton \"Choisir les fichiers\"",
	t3157: "Choisir les fichiers",
	t3158: "Suppression de la sauvegarde",
	t3159: "Fichiers intermédiaires",
	t3160: "La sauvegarde est enregistrée dans le gestionnaire de système pour la fonction de restauration",
	t3161: "Taille installée",
	t3162: "Nouvelle taille",
	t3163: "Date d'installation",
	t3164: "Nouvelle date",
	t3165: "Capteurs",
	t3166: "Relais",
	t3167: "Contributions",
	t3168: "Sorties",
	t3169: "Mètres",
	t3170: "Générique",
	t3171: "Mot de passe expiré, veuillez entrer un nouveau mot de passe",
	t3172: "Nouveau mot de passe",
	t3173: "Confirmez le mot de passe",
	t3174: "Changer le mot de passe",
	t3175: "Le mot de passe doit contenir au moins 8 caractères (1 majuscule, 1 minuscule, 1 chiffre, 1 caractère spécial)",
	t3176: "Les mots de passe ne correspondent pas",
	t3177: "Le mot de passe ne peut pas être le même que l'ancien mot de passe",
	t3178: "Changement de mot de passe réussi",
	t3179: "Le mot de passe ne peut pas être modifié. Erreur de l'appareil",
	t3180: "Réinitialisation du mot de passe",
	t3181: "Généré",
	t3182: "Nom du site",
	t3183: "ID d'événement",
	t3184: "Temps",
	t3185: "Rôle",
	t3186: "Informations sur l'événement",
	t3187: "Acquitter les alarmes sélectionnées",
	t3188: "Acquitter toutes les alarmes sélectionnées",
	t3189: "Importer la base de données vers",
	t3190: "Importation de la base de données",
	t3191: "Le type de fichier doit être .zip",
	t3192: "Choisir le dossier",
	t3193: "Horaires d'ouvertures",
	t3194: "Vacances",
	t3195: "Horaires marche/arrêt",
	t3196: "Actif",
	t3197: "Vous avez des changements non enregistrés. Êtes-vous sûr de vouloir quitter?",
	t3198: "Malheureusement, la migration a échoué. Le système est actuellement occupé. Veuillez réessayer plus tard",
	t3199: "Téléchargez l'image en l'ajustant aux dimensions actuelles",
	t3200: "Remplacer l'image de fond",
	t3201: "Remplacez les images d'arrière-plan par des graphiques vectoriels évolutifs.",
	t3202: "Seuls les fichiers SVG portant le même nom que les fichiers existants sont remplacés.",
	t3203: "Sélectionnez le(s) fichier(s) à remplacer",
	t3204: "Fichiers images",
	t3205: "ne peut pas être traité. Le fichier a un format invalide.",
	t3206: "{{amount}} fichier(s) remplacé(s) avec succès",
	t3207: "Échec du remplacement de {{amount}} fichier(s)",
	t3208: "La vue n'a pas d'arrière-plan",
	t3209: "Vous pouvez télécharger une nouvelle image d'arrière-plan ou remplacer une image d'arrière-plan existante",
	t3210: "Si vous remplacez une image d'arrière-plan, les positions des paramètres peuvent être conservées si :",
	t3211: "Un fichier png ou jpg a la même résolution que l'arrière-plan actuel",
	t3212: "Un fichier svg a le même format d'image (mais pas nécessairement la même résolution)",
	t3213: "La fonctionnalité est désactivée",
	t3214: "Affichage actuel de la dernière valeur reçue, mise à jour...",
	t3215: "Télécharger un fichier graphique créé dans",
	t3216: "(optionnel)",
	t3217: "Image de fond",
	t3218: "Parcourir",
	t3219: "Suite...",
	t3220: "Ajouter une image de fond (facultatif)",
	t3221: "Fichier graphique",
	t3222: "Délai d'attente dans la communication",
	t3223: "Aucun programme de réfrigération configuré",
	t3224: "Indice",
	t3225: "Taper",
	t3226: "Vacances",
	t3227: "Journées",
	t3228: "Sur",
	t3229: "À l'arrêt",
	t3230: "IDENTIFIANT",
	t3231: "Ouvert",
	t3232: "Commencer",
	t3233: "Fin",
	t3234: "La description",
	t3235: "Jour",
	t3236: "proche",
	t3237: "Le ou les fichiers EDF ont été téléchargés avec succès. Redémarrage du gestionnaire de système",
	t3238: "Téléchargement du ou des fichiers Edf terminé, le gestionnaire de système a été réinitialisé avec succès",
	t3239: "Erreur lors de la récupération du ou des fichiers edf à partir du gestionnaire de système",
	t3240: "Échec du téléchargement du ou des fichiers EDF. Essayez après quelque temps",
	t3241: "Aucun programme de réfrigération configuré",
	t3242: "Télécharger le fichier sur le gestionnaire de système",
	t3243: "Spécifiez le type d'appareil - affecte les balises de paramètres requises pour le faire fonctionner",
	t3244: "Spécifiez comment les valeurs de l'appareil sont accessibles et quelle fonction elles remplissent",
	t3245: "Créer une alarme basée sur un petit paramètre",
	t3246: "Étiquettes",
	t3247: "Créer une liste d'étiquettes pouvant être attribuées à un paramètre",
	t3248: "Groupes",
	t3249: "Spécifier les groupes de paramètres",
	t3250: "Numéro d'enregistrement",
	t3251: "Type de données",
	t3252: "Liste des étiquettes",
	t3253: "Le minimum",
	t3254: "Défaut",
	t3255: "Maximum",
	t3256: "Type de registre",
	t3257: "Le type de registre est traduit en codes de fonction correspondants pour la lecture et l'écriture",
	t3258: "Mode d'échange",
	t3259: "Pour une valeur avec octets ABCD envoyée à l'équipement, cette option permute les octets (BADC), les mots (CDAB) ou les deux (DCBA).",
	t3260: "Lecture seulement",
	t3261: "Enregistrement",
	t3262: "Active la journalisation de l'historique pour ce paramètre",
	t3263: "Reprendre le texte du paramètre à l'alarme",
	t3264: "Position du drapeau d'alarme à partir du bit le moins significatif du paramètre",
	t3265: "Déclencher l'alarme sur valeur basse",
	t3266: "Étiquette",
	t3267: "Cacher",
	t3268: "{{field}} est un champ obligatoire.",
	t3269: "Le maximum doit être supérieur ou égal au minimum",
	t3270: "La valeur par défaut doit être dans [Minimum, Maximum]",
	t3271: "Le type de données doit être {{reg}} pour le type de registre sélectionné {{mt}}",
	t3272: "Le nom ne doit pas contenir de signes de délimitation",
	t3273: "Le nom ne doit pas dépasser 20 caractères",
	t3274: "La liste des libellés ne doit pas être vide",
	t3275: "La ou les balises {{taglist}} doivent être attribuées une seule fois.",
	t3276: "Le ou les groupes {{grouplist}} contiennent plus que le maximum de 50 paramètres.",
	t3277: "Le formulaire contient des erreurs, veuillez vérifier les erreurs et réessayer.",
	t3278: "Soumettre",
	t3279: "Pour activer l'EDF téléchargé, vous devez réinitialiser l'unité. Voulez-vous réinitialiser l'unité maintenant ?",
	t3280: "Réinitialiser maintenant",
	t3281: "Réinitialiser plus tard",
	t3282: "{{file}} existe déjà. Voulez-vous l'écraser ?",
	t3283: "Télécharger {{what}}",
	t3284: "Les étiquettes et les valeurs doivent être uniques.",
	t3285: "Multi",
	t3286: "L'appareil gère plusieurs caisses ou packs",
	t3287: "Mots clés",
	t3288: "Internet Explorer est en fin de vie et n'est pas pris en charge par StoreviewWeb",
	t3289: "Veuillez passer à un navigateur pris en charge, soit Edge, Chrome ou Firefox",
	t3290: "Plus d'information",
	t3291: "proche",
	t3292: "Échec de la lecture des données, veuillez réessayer",
	t3293: "EDF de secours en cours",
	t3294: "Il n'y a aucune vue à migrer",
	t3295: "Le système est configuré avec une adresse IP publique dans le champ IP privée",
	t3296: "Le système est configuré avec une adresse IP privée dans le champ IP public, le système peut ne pas être accessible à distance ou certaines fonctions peuvent ne pas fonctionner correctement",
	t3297: "Vous êtes connecté via une adresse IP ou un nom d'hôte qui n'est pas connu du gestionnaire du système, probablement via un service proxy, certaines fonctions peuvent ne pas fonctionner correctement",
	t3298: "L'appareil est hors ligne et affiche la dernière valeur connue",
	t3299: "Intégration de l'appareil",
	t3300: "La ou les balises {{taglist}} sont dupliquées.",
	t3301: "Page non trouvée",
	t3302: "Nous sommes désolés, la page que vous avez demandée est introuvable",
	t3303: "Code d'erreur : 404",
	t3304: "Aller au tableau de bord",
	t3305: "Afficher uniquement",
	t3306: "Adresse IP interne",
	t3307: "Adresse IP externe",
	t3308: "Nom de domaine",
	t3309: "Aucun avertissement",
	t3310: "Avertissements multiples",
	t3311: "Il semble que le système soit configuré avec plusieurs unités, mais nous n'avons pas pu contacter une ou plusieurs des unités esclaves, veuillez vous assurer que les paramètres sont corrects. Veuillez noter qu'une configuration multi-unités ne prend pas en charge plusieurs noms de domaine et que chaque unité doit être configurée avec un port distinct lors de l'utilisation du même domaine",
	t3312: "Afficher uniquement",
	t3313: "Cet élément n'est associé à aucun paramètre et peut être supprimé en toute sécurité",
	t3314: "Supprimer le paramètre",
	t3315: "Programme",
	t3316: "Vous allez maintenant être déconnecté et devrez vous connecter avec le nouveau mot de passe",
	t759: "Base de données téléchargée, réinitialisation et mise à niveau de l'unité. Cela peut prendre plusieurs minutes.",
	t816: "Type d'alarme :",
	t818: "Compter :",
	t848: "Le fichier DB n'est pas créé. Vous avez un accès limité au répertoire installé. Veuillez contacter l'administrateur système.",
	t3317: "Aucun programme à exporter",
	t3318: "En attente de réponse du système",
	t3319: "Aucune activité réseau",
	t3320: "Nom du magasin",
	t3321: "Nouvelle version {{latestVersion}} disponible en ligne",
	t3322: "Port externe",
	t3323: "Port interne",
	t3324: "Nous ne pouvons pas communiquer avec le gestionnaire du système sur",
	t3325: "peut-être que le certificat doit être approuvé ?",
	t3326: "Le fichier de ce format ne peut pas être supprimé",
	t3327: "Usage",
	t3328: "À temps",
	t3329: "Délai dépassé",
	t3330: "Jours de la semaine",
	t3331: "Étape de refroidissement",
	t3332: "Récupération de chaleur",
	t3333: "Refroidissement",
	t3334: "Chauffage",
	t3335: "Chauffage auxiliaire",
	t3336: "Déshumidification",
	t3337: "Internet",
	t3338: "Messages",
	t3339: "Contacts",
	t3340: "Communication",
	t3341: "Aucun élément trouvé",
	t3342: "Tapez pour rechercher",
	t3408: "Activer tout",
	t3409: "Désactiver tout",
	t3410: "Normale",
	t3411: "Grave",
	t3412: "Critique",
	t3413: "Activer toutes les alarmes",
	t3414: "Rechercher des alarmes",
	t3415: "Activé",
	t3417: "Erreur lors de la récupération des alarmes",
	t3418: "Erreur lors de la récupération des périphériques à copier",
	t3419: "Erreur lors de la vérification de l'état de la copie",
	t3420: "L'interrogation automatique reprendra après un délai d'attente maximal de 2 heures, ou si l'assistant est annulé ou terminé",
	t3421: "Copie au(x) responsable(s) du traitement",
	t3422: "Copie sur le(s) périphérique(s) sélectionné(s)",
	t3423: "La fonction de copie copie d'abord les paramètres dans la base de données du gestionnaire système, puis les télécharge sur les périphériques du contrôleur. L'exécution de l'action peut prendre un certain temps.",
	t3424: "Aucun appareil sélectionné pour la copie",
	t3425: "Double-cliquez pour copier",
	t3426: "Erreur lors de la récupération des appareils",
	t3427: "Appareils pour la copie",
	t3428: "Sélectionnez le(s) périphérique(s) à copier",
	t3429: "Seuls les contrôleurs du même type seront affichés ici",
	t3430: "Le processus de copie a réussi",
	t3431: "Fichier non valide. Modèle de fichier attendu :",
	t3432: "Erreur lors du chargement du fichier de paramètres",
	t3435: "Êtes-vous sûr de déplacer l'appareil sélectionné vers ce groupe {{toPackGroup}} ?",
	t3343: "Découverte",
	t3344: "Actualiser le tableau",
	t3345: "Analyse des réseaux, veuillez patienter...",
	t3346: "La table réseau est actualisée",
	t3347: "Erreur lors de l'actualisation des réseaux",
	t3348: "Erreur lors de la nouvelle analyse des réseaux",
	t3349: "Erreur lors de la récupération des réseaux",
	t3350: "Analyse du réseau terminée",
	t3351: "Aucun appareil disponible",
	t3352: "Scan du réseau",
	t3353: "Non cfg",
	t3354: "Toute réorganisation des appareils dans l'assistant peut affecter les services de surveillance Alsense.",
	t3355: "Réseau",
	t3356: "Sélectionnez le type de bus de terrain",
	t3357: "Si Modbus/RS485 est activé, vous pouvez sélectionner SLV pour le compresseur à vitesse variable Danfoss. Lorsque vous sélectionnez SNMP comme bus local, vous devrez saisir une plage d'adresses de sous-réseau valide par les commandes SNMP de Danfoss",
	t3358: "Compresseurs/Packs",
	t3359: "Gérer les contrôleurs de compresseurs/packs",
	t3360: "Seuls les périphériques Pack peuvent être gérés ici - pas le système d'E/S en rack. Les contrôleurs Pack multi-aspiration compatibles qui ont été scannés apparaîtront initialement comme 0 groupe d'aspiration. L'AK-ST 500 (outil de service) est requis pour configurer le contrôleur Pack.",
	t3361: "Montant à copier",
	t3362: "La réduction du groupe d’aspiration précédemment configuré supprimera également toutes les commandes d’évaporation associées à ce groupe d’aspiration.",
	t3363: "Le nombre de groupes d'aspiration ne peut pas être nul, le nombre minimum de groupes d'aspiration doit être d'au moins un.",
	t3364: "Erreur lors de la récupération des compresseurs/packs",
	t3365: "Aucun compresseur/pack disponible",
	t3366: "Appareils en ligne",
	t3367: "Appareils hors ligne",
	t3369: "Ajouter un pack",
	t3370: "Si vous sélectionnez Modbus/RS485, vous verrez des options supplémentaires pour SLV/ECFan, celles-ci sont utilisées pour la réfrigération et peuvent être sélectionnées sur Non.",
	t3371: "Ajouter un circuit",
	t3372: "Ajoutez, copiez ou supprimez des périphériques. Notez que tous les types de périphériques ne permettent pas de modifier tous les champs et que les adresses des points d'E/S sont définies dans la configuration.",
	t3373: "Gérer les contrôleurs de cas",
	t3374: "Ajouter un appareil",
	t3375: "Gérer les contrôleurs de compresseur/pack",
	t3376: "Erreur dans l'accès aux données",
	t3377: "La disposition CVC a été appliquée avec succès",
	t3378: "Aménagement CVC",
	t3379: "Etes-vous sûr de vouloir quitter cette page ?",
	t3380: "Vos paramètres peuvent être perdus",
	t3381: "Veuillez configurer un appareil",
	t3382: "Quitter la configuration rapide",
	t3383: "Êtes-vous sûr de vouloir quitter la configuration rapide ?",
	t3384: "Passerelles (AK-PI200)",
	t3385: "Sélectionnez les passerelles trouvées",
	t3386: "Adresse IP",
	t3387: "Contrôleur de source",
	t3388: "Paramètres de la source",
	t3389: "Alarmes de source",
	t3390: "Graphique source",
	t3392: "Graphique",
	t3393: "Appareils",
	t3394: "L'interrogation automatique est suspendue pendant la phase de copie. L'interrupteur principal des périphériques source et cible sera désactivé puis reviendra à son état d'origine une fois la copie terminée. Assurez-vous de vérifier l'interrupteur principal une fois l'assistant de copie terminé. Si l'un des périphériques sélectionnés est en état d'alarme active, toute modification de l'action d'alarme pour ce point particulier ne sera pas modifiée. Le paramètre d'origine sera conservé.",
	t3395: "Sélectionnez un périphérique source à partir duquel copier les paramètres",
	t3396: "À l'heure",
	t3397: "La fonction de copie copiera les points de graphique disponibles jusqu'à ce que la capacité de l'unité soit atteinte. Accédez à la configuration pour voir le nombre de points définis",
	t3398: "Graphique du périphérique source",
	t3399: "Configurer les points du graphique pour le contrôleur source",
	t3400: "Activer HACCP pour enregistrer les points dans un rapport de température HACCP",
	t3401: "Recherche de points",
	t3402: "Aucune donnée disponible",
	t3403: "Erreur lors de la récupération des points du graphique",
	t3404: "Désactivé",
	t3405: "Alarmes de l'appareil source",
	t3406: "Configurer les détails de l’alarme dans le contrôleur source",
	t3407: "Nom de l'alarme",
	t3436: "L'appareil sera supprimé du groupe attribué [ {{fromPackGroup}} ]",
	t3437: "Disposition de la réfrigération",
	t3438: "La disposition de la réfrigération a été appliquée avec succès",
	t3439: "Réaffecter",
	t3440: "Affecter des contrôles de cas aux contrôles de pack",
	t3441: "Affectez les contrôles de cas (à partir des cas disponibles) aux contrôles de compresseur/pack. formant des groupes d'aspiration",
	t3442: "Paramètres de l'appareil",
	t3443: "Sélectionner les paramètres pour la copie",
	t3444: "Lorsque vous travaillez sur un appareil en direct (en ligne), il peut y avoir un retard dans les paramètres, en raison de la communication avec l'appareil.",
	t3445: "Sélectionner les alarmes à copier",
	t3446: "Graphique de l'appareil",
	t3447: "Sélectionner les points du graphique à copier",
	t3448: "Enregistrer les paramètres actuels de l'appareil dans un fichier",
	t3449: "Vous pouvez enregistrer les paramètres actuels de l'appareil dans un fichier qui pourra être utilisé pour configurer les appareils ultérieurement. Si vous souhaitez quitter l'assistant après l'enregistrement du fichier, utilisez l'icône de sortie. Si vous travaillez avec des appareils connectés, la sortie garantit que l'interrogation du réseau et le commutateur principal de l'appareil reviendront à l'état correct.",
	t3450: "Les fichiers de paramètres ont été enregistrés avec succès",
	t3451: "Souhaitez-vous continuer ou quitter la configuration rapide ?",
	t3452: "Sauvegarde du/des fichier(s)",
	t3453: "Erreur lors de la récupération des paramètres",
	t3454: "Paramètres du périphérique source",
	t3455: "Configurer les paramètres de l'appareil dans le contrôleur source",
	t3456: "Aucun des champs de mot de passe ne doit être vide",
	t3457: "Récents",
	t3458: "Liste des sites",
	t3459: "Dernier accès",
	t3460: "Veuillez effectuer régulièrement une sauvegarde du carnet d'adresses.",
	t3461: "Le carnet d'adresses a été importé avec succès",
	t3462: "Importer repertoire adresse",
	t3463: "Exporter repertoire adresse",
	t3464: "Ajouter un site",
	t3465: "Modifier le site",
	t3466: "Supprimer l'emplacement",
	t3467: "Vous devez créer un mot de passe pour le carnet d'adresses avant de commencer à utiliser le carnet d'adresses.",
	t3468: "Le site a été ajouté avec succès à votre carnet d'adresses",
	t3469: "Déjà dans le carnet d'adresses",
	t3470: "Ajouter au carnet d'adresses",
	t3471: "Se connecter au site",
	t3472: "Les informations du site ont été mises à jour avec succès",
	t3474: "Notification",
	t3475: "Le site a été supprimé avec succès de votre carnet d'adresses",
	t3476: "Le site a été mis à jour avec succès",
	t3477: "Format de carnet d'adresses non valide",
	t3478: "Actions",
	t3479: "Non disponible hors ligne",
	t3480: "Échec du chargement de la base de données S55",
	t3481: "Chargement de la base de données S55",
	t3482: "Protocole",
	t3483: "L'utilisateur n'a pas l'autorisation de voir les informations du logiciel",
	t3484: "Le mot de passe doit être alphanumérique ou numérique uniquement",
	t3485: "Vous devez créer un mot de passe de carnet d'adresses pour accéder au carnet d'adresses",
	t3486: "Le mot de passe du carnet d'adresses n'est pas correct",
	t3487: "Le site est déjà présent dans le carnet d'adresses",
	t3488: "Créez un mot de passe pour le carnet d'adresses afin de l'utiliser. Le mot de passe doit être alphanumérique ou numérique uniquement",
	t3489: "Entrez le mot de passe de votre carnet d'adresses",
	t3490: "Aucun appareil en ligne disponible pour copier",
	t3491: "Créer un mot de passe pour le carnet d'adresses",
	t3492: "Actualiser le mot de passe du carnet d'adresses",
	t3493: "Le mot de passe du site ne peut pas être vide",
	t3494: "Saisissez un mot de passe du carnet d'adresses en cours d'importation",
	t3495: "Le mot de passe du carnet d'adresses a été créé avec succès",
	t3496: "Le mot de passe du carnet d'adresses a été mis à jour avec succès",
	t3497: "Le mot de passe du carnet d'adresses en cours d'importation n'est pas correct",
	t3498: "Veuillez saisir le mot de passe du site",
	t3499: "Effacer le carnet d'adresses",
	t3500: "Rechercher sur les sites",
	t3501: "Sélectionnez le fichier du carnet d'adresses à importer",
	t3502: "Sélectionnez le fichier clé pour décrypter le carnet d'adresses",
	t3503: "Appuyez sur Exporter pour télécharger le carnet d'adresses crypté",
	t3505: "Avec crypté",
	t3506: "mots de passe non cryptés",
	t3507: "Clé secrète invalide",
	t3508: "Échec de l'importation. Assurez-vous d'avoir importé la clé appropriée",
	t3509: "Aucune version disponible",
	t3510: "Open Alsense Services (nécessite un abonnement)",
	t3511: "Veuillez lire attentivement les conditions du CLUF. Si vous ne pouvez pas accepter les conditions, vous n'êtes pas autorisé à utiliser l'application.",
	t3512: "Le carnet d'adresses et le mot de passe de votre carnet d'adresses ont été effacés avec succès",
	t3513: "(requis)",
	t3515: "Le carnet d'adresses est vide",
	t3516: "Le mot de passe du carnet d'adresses ne peut pas être vide",
	t3519: "En effaçant le carnet d'adresses, vous supprimerez également le mot de passe de votre carnet d'adresses.",
	t3520: "Hors de portée. Doit être compris entre 0 et {{maxAddr}}",
	t3521: "Appuyez sur « Exporter » pour télécharger toutes les vues graphiques stockées dans le gestionnaire système",
	t3522: "Appuyez sur « Télécharger » pour télécharger un rapport système",
	t3523: "Seule l'unité principale est disponible sur une connexion WiFi directe",
	t3524: "Il n'y a pas de vues graphiques locales. Ajoutez de nouvelles vues ou importez des vues existantes",
	t3525: "Il n'y a pas de vues graphiques à distance. Essayez de rechercher avec un nom différent",
	t3526: "Il n'y a pas de vues graphiques distantes. Ajoutez de nouvelles vues, importez ou migrez des vues existantes",
	t3527: "Vues locales ( {{totalFiles}} sur 5)",
	t3528: "{{totalFiles}} graphiques locaux sur un maximum de 5",
	t3529: "Vues à distance",
	t3530: "Le fichier n'est pas une image bitmap.",
	t3531: "Longueur du bitmap incorrecte.",
	t3532: "La taille de l'en-tête du bitmap est incorrecte.",
	t3533: "Taille du bitmap incorrecte.",
	t3534: "Erreur dans la taille de la palette.",
	t3535: "Fichier .bmp incompatible",
	t3536: "Erreur lors de la suppression du fichier graphique local",
	t3537: "Erreur lors de la duplication du fichier graphique local",
	t3538: "Erreur lors de l'exportation du fichier graphique local",
	t3539: "Supprimer le graphique",
	t3540: "Supprimer un fichier graphique local",
	t3541: "La suppression du fichier graphique local est en cours",
	t3542: "Fichier graphique local supprimé avec succès. Redémarrage du Gestionnaire système",
	t3543: "Suppression du fichier graphique local terminée. Le gestionnaire système a redémarré avec succès",
	t3544: "Dupliquer un graphique",
	t3545: "Dupliquer un fichier graphique local",
	t3546: "La duplication du fichier graphique local est en cours",
	t3547: "Fichier graphique local dupliqué avec succès. Redémarrage du Gestionnaire système",
	t3548: "Duplication du fichier graphique local terminée. Le gestionnaire système a redémarré avec succès",
	t3549: "Exporter le graphique",
	t3550: "Exporter un fichier graphique local",
	t3551: "L'exportation du fichier graphique local est en cours",
	t3552: "Fichier graphique local exporté avec succès",
	t3553: "Le gestionnaire système redémarrera sur cette action",
	t3554: "Etes-vous sûr de vouloir supprimer",
	t3555: "Etes-vous sûr de vouloir dupliquer",
	t3556: "Appuyez sur « Exporter » pour télécharger toutes les vues graphiques locales stockées dans le gestionnaire système",
	t3557: "L'exportation des fichiers graphiques locaux est en cours",
	t3558: "Fichiers graphiques locaux exportés avec succès",
	t3559: "Code produit",
	t3560: "Obtenez plus d'informations sur ce produit",
	t3561: "Ventilateur éco",
	t3562: "Ouverture de la vanne",
	t3563: "Surchauffer",
	t3564: "Pression de l'évaporateur",
	t3565: "Température de l'évaporateur",
	t3566: "Découper",
	t3567: "Cutine",
	t3568: "Porte du boîtier",
	t3569: "Température d'aspiration",
	t3570: "Chauffage ferroviaire",
	t3571: "Limite maximale de fichiers autorisée atteinte. Désélectionnez le(s) fichier(s) à importer",
	t3572: "Veuillez supprimer les fichiers existants dans le gestionnaire de système pour importer des fichiers",
	t3573: "Fichier graphique local importé avec succès. Redémarrage du Gestionnaire système",
	t3574: "Importation des fichiers graphiques locaux terminée. Le gestionnaire de système a redémarré avec succès",
	t3575: "La limite maximale autorisée pour le fichier a été atteinte. Supprimer le(s) fichier(s) à importer",
	t3576: "Le nombre maximal de fichiers graphiques autorisés est de 5",
	t3577: "Fichier graphique local téléchargé avec succès. Redémarrage du Gestionnaire système",
	t3578: "Téléchargement du fichier graphique local terminé. Le gestionnaire système a redémarré avec succès",
	t3579: "Format de fichier non valide. Télécharger le fichier {{supportedFileFormats}}",
	t3580: "La dimension du fichier doit être {{maxWidthAllowed}} x {{maxHeightAllowed}} pixels pour les écrans locaux",
	t3581: "Vue à distance",
	t3582: "Vue locale",
	t3583: "Page d'accueil de Storeview Web",
	t3584: "Conditions du CLUF",
	t3585: "Sécurité",
	t3586: "Niveaux de renforcement de la sécurité",
	t3587: "Rétrocompatible (niveau de sécurité le plus bas - en supposant que l'authentification par en-tête soit désactivée)",
	t3588: "Les requêtes SvW / XML contiennent un nom d'utilisateur et un mot de passe.",
	t3589: "Aucune désinfection des demandes, même sécurité que les versions précédentes",
	t3590: "Aucune modification nécessaire sur les connexions SvW ou XML existantes",
	t3591: "Permissif (peut être utilisé comme transition vers un niveau strict)",
	t3592: "Les requêtes XML peuvent contenir un nom d'utilisateur et un mot de passe, ainsi qu'un jeton de session. Le jeton de session est reçu dans la réponse à la demande de connexion.",
	t3593: "Si le jeton de session est fourni dans l'en-tête AKSM-auth, les noms d'utilisateur et les mots de passe sont ignorés",
	t3594: "La requête XML est nettoyée, un avertissement est renvoyé dans la réponse de la requête si des problèmes sont détectés",
	t3595: "Prenez note de ces avertissements en prévision du passage en mode strict",
	t3596: "Si le jeton de session n'est pas utilisé, l'expiration de la session ne peut pas être appliquée",
	t3597: "Déjà pris en charge dans l'interface utilisateur distante (SvW)",
	t3598: "Modifications possibles/requises du point de vue XML tiers",
	t3599: "Aucune utilisation de jeton de session - aucune modification nécessaire",
	t3600: "Utilisation du jeton de session - supprimez le nom d'utilisateur/mot de passe du corps et observez les avertissements en prévision de Strict",
	t3601: "Strict (niveau de sécurité le plus élevé)",
	t3602: "Les requêtes XML ne peuvent pas contenir de noms d'utilisateur et de mots de passe, mais doivent fournir le jeton de session dans l'en-tête AKSM-auth. Si un problème est détecté dans la requête, elle est rejetée",
	t3603: "Déjà pris en charge dans (SvW)",
	t3604: "Modifications requises du point de vue d'un tiers",
	t3605: "le nom d'utilisateur et le mot de passe doivent être supprimés de la charge utile de la requête XML et le jeton de session doit être placé dans un en-tête CORS appelé AKSM-auth",
	t3606: "Échec de la connexion",
	t3607: "Durée (en secondes) pendant laquelle un utilisateur distant attend avant de pouvoir réessayer de se connecter après une tentative infructueuse. Le temps d'attente augmente de manière cumulative à chaque tentative infructueuse. Après 10 tentatives, l'adresse IP à partir de laquelle l'utilisateur a tenté de se connecter est bloquée pendant 24 heures. Si la valeur est définie sur 0, la fonction de retour en arrière en cas d'échec de connexion est désactivée.",
	t3608: "Par défaut 30 s (max 600 s / 0 = désactivé)",
	t3609: "Essais gratuits",
	t3610: "Combien de tentatives de connexion sont autorisées avant que le délai de retour à la normale ne soit appliqué",
	t3611: "Par défaut 2 (max 5)",
	t3612: "Bloc",
	t3613: "Si l'utilisateur et l'IP sont activés, ils sont bloqués pendant le back-off, sinon l'utilisateur est bloqué",
	t3614: "« Utilisateur » par défaut (Utilisateur + IP / Utilisateur)",
	t3615: "Sessions par utilisateur",
	t3616: "Une session est une session au cours de laquelle un utilisateur s'est connecté avec succès à l'unité et se trouve dans l'interface distante (SvW)",
	t3617: "Nombre de sessions actives qu'un utilisateur peut avoir. Si ce paramètre est défini sur 0, un utilisateur peut avoir le nombre maximal de sessions (30)",
	t3618: "Par défaut 0",
	t3619: "Expiration de la session",
	t3620: "Combien de secondes s'écoulent après l'expiration d'une session inactive. Si une session expire, une nouvelle connexion est nécessaire",
	t3621: "Par défaut 900s (max 3,600)",
	t3622: "Authentification d'en-tête",
	t3623: "Lors de l'activation de HTTPS et de l'authentification par en-tête, toutes les requêtes XML tierces doivent transmettre les informations d'identification du nom d'utilisateur/mot de passe dans l'en-tête (schéma d'authentification de base)",
	t3624: "L'activation de l'option « Utiliser l'authentification par en-tête » nécessite la réinitialisation du système. Utilisez le bouton « Appuyer pour initialiser » pour effectuer cette action",
	t3625: "Petit",
	t3626: "Grand",
	t3627: "Enregistrer le graphique",
	t3628: "Enregistrer un fichier graphique local",
	t3629: "Le fichier graphique local a été supprimé avec succès. Le chargement du fichier graphique local est en cours",
	t3630: "Le fichier graphique local a été chargé avec succès. Redémarrage du Gestionnaire système",
	t3631: "L'enregistrement du fichier graphique local est terminé. Le gestionnaire système a redémarré avec succès",
	t3632: "Etes-vous sûr de vouloir sauvegarder ?",
	t3633: "L'analyse a échoué",
	t3634: "Impossible d'analyser le fichier viz",
	t3635: "Impossible d'analyser le fichier vz2",
	t3636: "Échec de l'ouverture du fichier Viz :",
	t3637: "Les fichiers de migration (vz2, dpj ou json) n'ont pas pu être trouvés",
	t3638: "Les fichiers de migration (viz) n'ont pas pu être trouvés",
	t3639: "Le gestionnaire système redémarrera après cette action. Le gestionnaire système est en mode permissif ou strict, le jeton de session expirera donc au redémarrage et vous serez déconnecté du système",
	t3643: "Pression de refoulement",
	t3644: "Puissance totale en kW",
	t3645: "KVAR total",
	t3646: "Total Kvarh+",
	t3647: "Demande",
	t3648: "Facteur de puissance",
	t3649: "Couleur de police",
	t3650: "Couleur d'arrière-plan",
	t3651: "Le carnet d'adresses a été exporté avec succès",
	t3652: "Mode de contrôle",
	t3653: "Sc3 Température extérieure",
	t3654: "Température de refoulement",
	t3655: "Capacité de fonctionnement du condenseur",
	t3656: "Aucune donnée à exporter",
	t3657: "Le mot de passe de l'utilisateur sur {{unit}} a expiré",
	t3658: "Impossible de se connecter à {{unit}} avec les informations d'identification fournies.",
	t3659: "Mettez à jour la version du micrologiciel ou modifiez le niveau de sécurité pour {{unit}} pour obtenir la prise en charge du jeton de session.",
	t3661: "V3gc",
	t3662: "Section B",
	t3663: "Section C",
	t3664: "Contrôle adaptatif de la surchauffe",
	t3665: "Raison du statut de contrôle",
	t3666: "Impossible de connecter l'utilisateur. L'adresse de l'unité n'est pas définie dans la configuration du proxy",
	t3667: "Le protocole de communication n'est pas spécifié dans la configuration du proxy. Cela peut entraîner des erreurs",
	t3668: "Informations sur la sécurité informatique",
	t3669: "Conformément aux normes de sécurité, le gestionnaire de système utilise désormais par défaut un contrôle de session strict, ce qui signifie que toute connexion à distance / service distant basé sur XML ne fonctionnera plus à moins que des jetons de session ne soient appliqués.",
	t3670: "Danfoss recommande de continuer en mode strict, mais le mode de compatibilité descendante est toujours disponible.",
	t3671: "Remarque : cela peut être modifié dans le menu Configuration et sécurité et n'a aucun impact sur le contrôle local du gestionnaire de système et sur le fonctionnement du site.",
	t3672: "Voir plus d'informations sur le contrôle de session",
	t3673: "Mode arrière",
	t3674: "Mode strict",
	t3675: "En cliquant sur le mode rétrocompatible, tous les gestionnaires de système concernés avec le micrologiciel mis à jour seront configurés sur le mode rétrocompatible, redémarrés et l'application sera déconnectée",
	t3676: "Le nombre maximum de groupes d'aspiration autorisés est de 12",
	t3677: "Une fois la mise à jour des unités sélectionnées de 3.3.0 à 4.X ou supérieure effectuée avec succès, l'utilisateur sera automatiquement déconnecté en cliquant sur le bouton Terminer à l'étape finale.",
	t3678: "Les boîtiers multiples pour contrôleurs AK2 (Pack/Case) ne sont pas pris en charge",
	t3679: "Conformément aux normes de sécurité introduites avec la version 4.0, le gestionnaire de système utilisera par défaut le contrôle de session strict, ce qui signifie que toute connexion à distance / service distant basé sur XML ne fonctionnera plus à moins que des jetons de session ne soient appliqués. En outre, la communication sera automatiquement ajustée sur HTTPS lors de la mise à niveau vers la version 4.0. Danfoss recommande de continuer en mode strict, mais le mode de compatibilité descendante est disponible, ce qui rétablira les paramètres appliqués jusqu'à présent. Veuillez noter que si vous effectuez cette mise à jour à distance sur une unité HTTP, assurez-vous que votre configuration informatique est préparée pour HTTPS (port 443) ou que du personnel est disponible sur place.",
	t3680: "Retrouvez plus d'informations sur la nouvelle mise en œuvre de sécurité ici",
	t3681: "L'accès à la version http de ce site peut être bloqué en raison des restrictions de votre navigateur. Visitez notre page FAQ pour obtenir des instructions sur la résolution de ce problème ou téléchargez la version de bureau de StoreView Web.",
	t3682: "FAQ sur le gestionnaire de système",
	t3683: "u17 Ther. air",
	t3684: "u91 Température de coupure",
	t3685: "u00 Ctrl. état",
	t3686: "u12 S3 température de l'air",
	t3687: "u16 S4 température de l'air",
	t3688: "u23 EEV OD %",
	t3689: "Super chaleur u21",
	t3690: "Superheat u22Réf.",
	t3691: "u26 Température d'évaporation Te",
	t3692: "S2 Température de sortie du gaz",
	t3693: "Stw8",
	t3694: "SD-MT",
	t3695: "Statut Tw",
	t3696: "Tw activer",
	t3697: "Deux pompes en marche",
	t3698: "Stw3",
	t3699: "Stw4",
	t3700: "Stw2",
	t3701: "Statut de contrôle Rcv.",
	t3702: "Décalage d'aspiration",
	t3703: "Mode de contrôle Vrec",
	t3704: "Statut Vrec",
	t3705: "Raison du statut Ctrl",
	t3706: "État du contrôle HP",
	t3707: "Pgc max.",
	t3708: "Limite max. Pgc P-ban",
	t3709: "Pgc minimum",
	t3710: "Capacité de l'éjecteur de vapeur",
	t3711: "Capacité d'éjection de liquide",
	t3712: "Température de l'air de référence",
	t3713: "Température de l'air soufflé",
	t3714: "Retour d'air",
	t3715: "Point de rosée de la zone",
	t3716: "Humidité de la zone",
	t3717: "Température extérieure",
	t3718: "Niveau de CO2 de la zone",
	t3719: "Température de zone",
	t3720: "Référence cond.",
	t3721: "État du contrôle de la condition",
	t3722: "État de contrôle cond.",
	t3723: "Contrôle de la continuité",
	t3724: "Cond. demandé cap.",
	t3725: "État du V3gc",
	t3726: "OD V3gc",
	t3727: "Temp. cond.",
	t3728: "Statut RH",
	t3729: "Activation des RH",
	t3730: "Demande du consommateur RH",
	t3731: "Shr3",
	t3732: "Shr4",
	t3733: "Shr2",
	t3734: "Shr8",
	t3735: "Aspiration réf. To-MT",
	t3736: "État de contrôle MT",
	t3737: "Zone actuelle MT",
	t3738: "Pour opter pour le décalage MT",
	t3739: "Capacité de course MT",
	t3740: "Capacité demandée MT",
	t3741: "Il est temps de passer à l'étape suivante MT",
	t3742: "Po-MT",
	t3744: "Température d'aspiration To-MT",
	t3745: "Énergie active totale",
	t3746: "Demande de pointe",
	t3747: "Facteur de puissance total",
	t3748: "Phase A volts",
	t3749: "Phase B volts",
	t3750: "Phase C volts",
	t3751: "Amplis de phase A",
	t3752: "Amplis de phase B",
	t3753: "Amplis Phase C",
	t3754: "Puissance active totale",
	t3755: "Température d'aspiration To-LT",
	t3756: "Température d'aspiration To-IT",
	t3757: "Référence d'aspiration LT",
	t3758: "État de contrôle LT",
	t3759: "Zone actuelle LT",
	t3760: "Pour opter pour le décalage LT",
	t3761: "Capacité de fonctionnement LT",
	t3762: "Cap. demandé LT",
	t3763: "Il est temps de passer à l'étape suivante LT",
	t3764: "Po-LT",
	t3765: "SD-LT",
	t3766: "Référence d'aspiration IT",
	t3767: "Statut de contrôle informatique",
	t3768: "Zone actuelle IT",
	t3769: "Capacité de fonctionnement informatique",
	t3770: "Capacité informatique demandée",
	t3771: "Il est temps de passer à l'étape suivante de l'informatique",
	t3772: "Tn Po-IT",
	t2773: "SD-IT",
	t2774: "Début de la Comp. IT",
	t3774: "Hors de portée. Doit être compris entre {{minAddr}} et {{maxAddr}}",
	t3775: "Fonctionnement désactivé pour cet appareil",
	t3776: "Après cette action, l'application se déconnectera, ce qui peut prendre quelques instants.",
	t3777: "Les fichiers de migration (flp ou json) n'ont pas pu être trouvés",
	t3778: "Vote",
	t3779: "Le scrutin est actuellement suspendu.",
	t3780: "Souhaitez-vous reprendre le sondage ?",
	t3781: "Compteur d'énergie",
	t3782: "Plusieurs nœuds sélectionnés",
	t3783: "Zoom sur le nœud",
	t3784: "Unités de mesure",
	t3785: "Fichier(s) exporté(s) avec succès",
	t3786: "L'adresse ne peut pas être négative",
	t3787: "Réinitialiser le zoom",
	t3788: "Erreur lors de la récupération des étapes du modèle de préparation",
	t3789: "Erreur lors de l'enregistrement des étapes du modèle de préparation",
	t3790: "Les étapes du modèle de mise en scène ont été enregistrées avec succès",
	t3791: "Modèle de mise en scène",
	t3792: "Appuyez sur les icônes déchargées pour activer/désactiver les déchargeurs associés aux compresseurs",
	t3793: "Données manquantes",
	t3794: "Les paramètres suivants n'ont renvoyé aucun point de données",
	t3795: "La confiance HTTPS/SSL a réussi.",
	t3796: "Vous pouvez maintenant vous connecter au navigateur Web StoreView.",
	t3797: "Redirection vers le navigateur Web StoreView dans {{timeLeft}} secondes...",
	t3798: "Sondage actuellement suspendu !",
	t3799: "Alarmes effacées (journaux système complets)",
	t3800: "Le nombre maximum de systèmes CVC autorisés est de 45.",
	t3801: "Impossible d'obtenir le journal des modifications",
	t3802: "Connexion au stockage...",
	t3803: "Délai d'expiration de la connexion au stockage SCADA",
	t3804: "Échec de la connexion au stockage SCADA",
	t3805: "Date non valide",
	t3806: "Sélectionnez l'unité(s) pour le téléchargement du rapport"
};
var fr = {
	lang: lang$2
};

var lang$3 = {
	t0: "Aktive Alarme",
	t1: "Inaktive Alarme",
	t2: "Host",
	t3: "ID",
	t4: "Regler Name",
	t5: "editiere Gerät",
	t6: "nicht authorisiert Alarme anzunehmen",
	t7: "Alarm Typ",
	t8: "Ereignet",
	t9: "Quittierung",
	t10: "Aktive Alarme",
	t11: "Gelöscht",
	t12: "Kalkulationen",
	t13: "gelöschte Alarme",
	t14: "stumm/akzept.",
	t15: "Annahme aller unakzeptierten Alarme",
	t16: "Authorisation fehlgeschlagen",
	t17: "Fehler",
	t18: "Manuelle Kontrolle",
	t19: "Visualisieren",
	t20: "Zusammenfassung Daten",
	t21: "technische Ansicht",
	t22: "Kurve (S4)",
	t23: "Gerä",
	t24: "Module",
	t25: "Kurve (P0 &amp; Pc)",
	t26: "(keine Alarme entdeckt)",
	t27: "Gerätealarm",
	t28: "siehe vorheriges Gerät",
	t29: "siehe nächstes Gerät",
	t30: "Fehler beim lesen der Alarme",
	t31: "aktuelle Alarme",
	t32: "aktueller Alarm",
	t33: "keine Alarm entdeckt.",
	t34: "Detailansicht",
	t35: "Kanal",
	t36: "Log",
	t37: "Zeitpläne",
	t38: "Startzeit",
	t39: "Stop-Zeit",
	t40: "Tage",
	t41: "Feiertage",
	t42: "Status",
	t43: "Wähle Datenpunkt",
	t44: "OK",
	t45: "Beenden",
	t46: "Start",
	t47: "Ende",
	t48: "Startdatum",
	t49: "Abtastrate",
	t50: "Keine Auswahl getroffen",
	t51: "PC",
	t52: "sek",
	t53: "Min",
	t54: "h",
	t55: "Tag",
	t56: "Adress-Typ",
	t57: "Adresse",
	t58: "Auswahl: ",
	t59: "Eingabe neue Einheit",
	t60: "Erneuer",
	t61: "Wähle Datenpunkt",
	t62: "Auswahl der neuen Einheit",
	t63: "Aus",
	t64: "Ein",
	t65: "Empfangen von Datenpunkten",
	t66: "Fehler beim Empfangen der Daten",
	t67: "keine Historie konfiguriert",
	t68: "Fehler beim Empfangen der Datenpunkte",
	t69: "Empfangen der historischen Beispiele für ",
	t70: "Fehler beim Empfangen der historischen Beispiele für ",
	t71: "Nutzer Name",
	t72: "Passwort",
	t73: "Host IP/URL Adresse",
	t74: "OK",
	t75: "Beenden",
	t76: "Name",
	t77: "Wert",
	t78: "Fehler beim Empfangen der Benutzer-Liste",
	t79: "Eingabe neue Adresse",
	t80: "Benutezr-Liste fehlgeschlagen. Bitte überprüfen IP/URL Adresse",
	t81: "Auth.Kode",
	t82: "Code akzeptiert",
	t83: "Wähle neue Zeit und Datum",
	t84: "Wähle neue Duration",
	t85: "Betriebsfehler",
	t86: "Eingabe Authorisation",
	t87: "HLK",
	t88: "Start!!",
	t89: "Abtau-Regelung",
	t90: "manuelle Abtauung starten",
	t91: "Möbel-Licht Regelung",
	t92: "Möbel-Licht HAND EIN",
	t93: "Möbel-Licht HAND AUS",
	t94: "Möbelreinigung - Regelung",
	t95: "Möbelreinigung - Regelung HAND EIN",
	t96: "Möbelreinigung - Regelung HAND AUS",
	t97: "Hauptschalt",
	t98: "Haupt-Schalter HAND EIN",
	t99: "Haupt-Schalter HAND AUS",
	t100: "Abschalt-Regelung",
	t101: "Gerät wird abgeschaltet",
	t102: "Gerät wird eingeschaltet",
	t103: "Nachtanhebung - Regelung",
	t104: "Nachtanhebung EIN",
	t105: "Nachtanhebung AUS",
	t106: "Meldung gesendet",
	t107: "Manuelle Kontrolle",
	t108: "Beende",
	t109: "Beleuchtung",
	t110: "Lstg/Diver",
	t111: "Übersicht",
	t112: "Wähle AK255 Gerät um anzuzeigen",
	t113: "lädt...",
	t114: "von",
	t115: "Alle 255-er",
	t116: "Früheres",
	t117: "Seiten",
	t118: "Nächste",
	t119: "Parameter",
	t120: "Messungen",
	t121: "Empfange Daten.......",
	t122: "Fehler beim Lesen dieser Sektion",
	t123: "Temperatur",
	t124: "Verzögerung nach Abtauung",
	t125: "Alarm wenn &lt;",
	t126: "Alarm wenn &gt;",
	t127: "Überwachung",
	t128: "Offline",
	t129: "Aktiv",
	t130: "Host",
	t131: "Adresse",
	t132: "Beschreibung",
	t133: "AktuellerWert",
	t134: "aktueller Status",
	t135: "Regler",
	t136: "Einh",
	t137: "aktiver Alarm existiert",
	t138: "aktive Alarme existieren!",
	t139: "digitale Eingänge",
	t140: "Relaisausgäng",
	t141: "Fühler-Eing.",
	t142: "Variab. Ausgänge",
	t143: "Übergeordneter - Regler",
	t144: "Andere",
	t145: "Punkt-Visualisierung",
	t146: "Markt Übersicht",
	t147: "Abmelden aus dem System",
	t148: "Anmelden",
	t149: "Abmelden",
	t150: "Kälte",
	t151: "Regler Übersicht",
	t152: "Alarme",
	t153: "Historie",
	t154: "CS-Übersicht",
	t155: "Markt Übersicht",
	t156: "Konfiguration",
	t157: "Übersicht",
	t158: "Anlagenansicht",
	t159: "Zurück",
	t160: "Alarm",
	t161: "Sollwert",
	t162: "Gerät Detail",
	t163: "Einstellungen",
	t164: "Wähle Datum",
	t165: "Informationen",
	t166: "Protokoll",
	t167: "Kalkulationen",
	t168: "Dateiverwaltung",
	t169: "Hilfe Menü",
	t170: "Info",
	t171: "Übersetzer",
	t172: "Sichern zum Server",
	t173: "Erneuern der Sprachen-Datei?",
	t174: "Jetzt wird die Sprachen-Datei auf dem Gerät erneuert! Forfahren?",
	t175: "Export Historie",
	t176: "Auswahl neue Einheit",
	t177: "minimum",
	t178: "maximum",
	t179: "Keine aktiven Alarme!",
	t180: "Nicht änderbar !",
	t181: "Bitte Warten....",
	t182: "Befehl gesendet!",
	t183: "Operation fehlgeschlagen, bitte später noch einmal versuchen",
	t184: "Operation erfolgreich!",
	t185: "Keine authorisierte Operation !",
	t186: "Wollen Sie wirklich weitermachen??",
	t187: "Verdampfer",
	t188: "Verbund",
	t189: "Aufzeichnung Eingänge",
	t190: "Verschiedenes",
	t191: "Quittierte Alarme",
	t192: "gelöschte angenommene Alarme",
	t193: "Lösche alle angenommene Alarme",
	t194: "Sie sind nicht authorisiert Alarme anzunehmen",
	t195: "Nicht authorisiert",
	t196: "Wollen Sie diesen Alarm annehmen?",
	t197: "Bestätige zum Annehmen von Alarmen",
	t198: "Bitte erst anmelden",
	t199: "Bitte einen zu löschenden Alarm auswählen",
	t200: "Bestätige zum Löschen von Alarmen",
	t201: "Wollen Sie diesen Alarm wirklich löschen?",
	t202: "Nicht authorisiert - löschen nicht möglich",
	t203: "Wollen Sie alle Alarme wirklich löschen?",
	t204: "gewählte Alarme können nicht gelöscht werden",
	t205: "Alarm Einstellungen",
	t206: "Sie müssen als Supervisor angemeldet sein, um diese Operation durchzuführen",
	t207: "Keine Daten kommen von der Einheit",
	t208: "Wähle eine Einheit",
	t209: "Bitte eine Einheit von der Liste wählen",
	t210: "Anazhl von Alarmen",
	t211: "Alarmstatus",
	t212: "Diverse",
	t213: "lösche gewählte Alarme",
	t214: "Bitte bestätigen",
	t215: "Verbindung zum Gerät fehlgeschlagen - bitte Netzverbindung prüfen!",
	t216: "Abtau-Alarm",
	t217: "digitaler Alarm",
	t218: "Status digitaler Eingang",
	t219: "Abtau-Eingang",
	t220: "Reinigungs-Eingang",
	t221: "digitaler Eingang",
	t222: "Sicherung der Sprachen-Datei",
	t223: "Einht",
	t224: "Kein Gerät konfiguriert im System, Webseite abgeschaltet",
	t225: "Warnung !!",
	t226: "Wähle Zeit",
	t227: "Kommunikation",
	t228: "Laden der Report-Datei",
	t229: "Bitte warten während die Report-Datei generiert wird",
	t230: "Report erstellen",
	t231: "Alarm Status/Service",
	t232: "Bitte warten - Datenempfang",
	t233: "angemeldet",
	t234: "Nutzer abgemeldet",
	t235: "Nutzer angemeldet",
	t236: "Willkommen!",
	t237: "ungültige Lizens",
	t238: "kW/h-Messungen",
	t239: "Licht-Panele",
	t240: "VLT - Frequenzumrichter",
	t241: "Verdichter Modul",
	t242: "Geräte - Punkte",
	t243: "berechnete Punkte",
	t244: "Cutler-Hammer Panele",
	t245: "Carriers",
	t246: "erweiterte Ansicht",
	t247: "doppelklicken zum Rein/Raus-Zoomen",
	t248: "hier klicken zum Rein/Raus-Zoomen",
	t249: "angenommene Alarme",
	t250: "Simulator",
	t251: "Kälte",
	t252: "Hzg./Lüft./Klima",
	t253: "Beleuchtung",
	t254: "Verschiedenes",
	t255: "Netzwerk verbunden",
	t256: "VSD",
	t257: "Referenz",
	t258: "Graph (AKD,kWh, Hz, &amp; Amp.)",
	t259: "Enddatum",
	t260: "Stopp",
	t261: "Endzeit ist aelter als Startzeit. bitte Datum neu auswaehlen",
	t262: "Die Ursprungszeit der Datenpunkte",
	t263: "ist jünger als die ausgesuchte Zeit",
	t264: "Die Anzahl der Datenpunkte hat 100.000 erreicht. Die Berechnung wird einige Zeit in Anspruch nehmen",
	t265: "Der Prozessor is beschäftigt. Bitte warten Sie 2 Minuten und versuchen es erneut",
	t266: "Speichern",
	t267: "Steuereinheit",
	t268: "Erste",
	t269: "Letzte",
	t270: "Druck",
	t271: "Verkleinern",
	t272: "Vergrössern",
	t273: "Ein/Ausblenden der Legende",
	t274: "Exportieren der Daten in eine Datei",
	t275: "Ein/Ausblenden des Gitters",
	t276: "Einseitenansicht",
	t277: "Mehrseitenansicht",
	t278: "Lade Log-Gruppe von Datei",
	t279: "Lade Log von Datei",
	t280: "Speicher Log-Gruppe in Datei",
	t281: "Anzahl Mehrseitenübersicht",
	t282: "Seite",
	t283: "Seite aussuchen zum Anzeigen",
	t284: "Die Logdaten müssen im CSV-format vorliegen",
	t285: "Keine Daten vorhanden. Bitte selektierte Datum/Uhrzeit und Datenpunkt überprüfen",
	t286: "Schnappschuß Log",
	t287: "Vollbild Log",
	t288: "Dateiansicht Log",
	t289: "Wähle...",
	t290: "Exportienen der gewählten Historie in eine CSV Datei",
	t291: "Bitte Zieldatei aussuchen",
	t292: "Datei",
	t293: "Öffne Log-Gruppe",
	t294: "Öffne Log",
	t295: "Speicher Log-Gruppe",
	t296: "Anzeigen",
	t297: "Zoom",
	t298: "Ja",
	t299: "Nein",
	t300: "Konfiguration von Regler empfangen?",
	t301: "Nicht erneut fragen",
	t302: "Einstellungen des Reglers empfangen",
	t303: "Daten empfangen",
	t304: "Alarmstufe",
	t305: "Alarmaktion",
	t306: "Quittiert",
	t307: "Datenbank auf Disk speichern",
	t308: "Dateiname",
	t309: "Datenbankbeschreibung",
	t310: "Datenbankpfad",
	t311: "Version",
	t312: "Zeit",
	t313: "Alarme exportieren",
	t314: "Datenbank gespeichet auf",
	t315: "Speichern der Datenbank fehlgeschlagen. Bitte Verzeichnis und Dateiname pruefen!",
	t316: "Dateiname ist nicht korrekt. Bitte Dateinamen pruefen.",
	t317: "Alarme erfogreich exportiert. Bitte Datei zum Speichern wählen",
	t318: "Bitte Alarme zum Export selektieren",
	t319: "Empfange Alarminformationen ",
	t320: "Dateibeschreibung",
	t321: "Konvertiere HST Datei zu CSV Format",
	t322: "Schritt 1: Sellektiere HST Datei",
	t323: "Schritt 2: Selektiere Datenpunkte und Zeitraum",
	t324: "Schritt 3: Selektiere Ziel CSV Datei",
	t325: "Speichere als ...",
	t326: "Max. 250 Datenpunkte erlaubt",
	t327: "Datenpunkt selektiert",
	t328: "Es ist nicht erlaubt mehr als 65536 Ereignisse pro Datenpunkt zu exportieren.Bitte Zeitraum oder Taktrate øndern.",
	t329: "Aufzeichnen",
	t330: "Zeichne Daten des Reglers auf",
	t331: "Selektiere aufgezeichnete Datenpunkte",
	t332: "Kein Wert",
	t333: "fehlend",
	t334: "aufgehoben",
	t335: "Bitte machen Sie eineAuswahl",
	t336: "Datenpunkte anzeigen",
	t337: "Datenpunkte als *.hst speichern",
	t338: "Datendownload beendet. Bitte selektieren Sie eine der folgenden Optionen:",
	t339: "Wählen Sie max. 8 Datenpunkte",
	t340: "Speicher aufgezeichnete Daten in *.hst",
	t341: "Druckübersicht",
	t342: "Bitte wählen Sie speichern oder Drucke Übersichtsbericht",
	t343: "Wählen Sie ein Dateiformat",
	t344: "Generiere Übersichtsdatei, bitte warten ...",
	t345: "Selektierte Datenbank existiert bereits, wollen Sie sie überschreiben ?",
	t346: "Übersicht",
	t347: "Grafiken",
	t348: "Hilfe",
	t349: "System",
	t350: "Karte",
	t351: "Übersetzungswerkzeug",
	t352: "Manuell",
	t353: "Alarme anfordern...",
	t354: "aktualisiert",
	t355: "Model",
	t356: "Regelung",
	t357: "ÖL",
	t358: "Temp",
	t359: "maximieren",
	t360: "Wiederherstellen",
	t361: "zurück...",
	t362: "Kommunikationsfehler!",
	t363: "Kommunikationsstatus OK",
	t364: "Kommunikationsnachricht gesendet.",
	t365: "lädt Hauptkomponente",
	t366: "lädt Konfigurationskomponente",
	t367: "Solltemp.",
	t368: "Änderung wirkz sich nur bei Neuzeichnen aus. Weiter ?",
	t369: "lädt, bitte warten...",
	t370: "Datenpunkt",
	t371: "Kein Name",
	t372: "Nutzereinst.",
	t373: "Simulator-Version",
	t374: "Energie",
	t375: "zurück zur Übersicht",
	t376: "System alarm oder kein Gerät verbunden",
	t377: "konkurrierende Zeitpläne",
	t378: "Sprachdatei fehlt",
	t379: "Version wird nicht unterstützt",
	t380: "Bitte Geräte IP eingeben (z.B. 192.168.1.1)",
	t381: "Bitte geben Sie eine IP/URL für eine Eiheit ein.",
	t382: "Details schließen",
	t383: "Details öffnen",
	t384: "Außentemp.",
	t385: "Zurück",
	t386: "LEintr",
	t387: "Feuchte",
	t388: "Taupunkt",
	t389: "Lüfter",
	t390: "Zone",
	t391: "Abschaltung",
	t392: "Auft.",
	t393: "Sauggastemp.",
	t394: "aktuelle Leistung",
	t395: "Saugdruck Druck",
	t396: "Hochdruck",
	t397: "Verdichter",
	t398: "Verflüssig.",
	t399: "Verflüssiger Sollwert",
	t400: "Kältemittel",
	t401: "Saugdruck Sollwert",
	t402: "Saugdr.",
	t403: "Abtauen",
	t404: "Danfoss Log-Format",
	t405: "Textformat",
	t406: "Diese Aktion erfordert einen Neustart, wollen Sie die Einheit neu starten?",
	t407: "Einheit wird neugestartet, bitte Laden Sie die Seite neu.",
	t408: "Werks-Applikation",
	t409: "berechnet...",
	t410: "Minuten",
	t411: "Nachtanhebung",
	t412: "Möbelbeleuchtung",
	t413: "Parallele Zeitpläne",
	t414: "(10min. Raster)",
	t415: "zeige Text",
	t416: "Export als CSV",
	t417: "Speichern als *.png",
	t418: "zeige Grafik",
	t419: "zeige Text",
	t420: "Zeitpläne",
	t421: "Dauer",
	t422: "Term Temp",
	t423: "KEINE",
	t424: "Sollwert",
	t425: "Läuft von",
	t426: "Datenbank laden",
	t427: "Statistik",
	t428: "Wähle Datei",
	t429: "Wählen Sie eine Logdatei die Sie in .csv konvertiern möchten",
	t430: "Fertig",
	t431: "Allgem.",
	t432: "Log-Datei (*.hst)",
	t433: "Log Umwandlungshilfe",
	t434: "Datei erstellt.",
	t435: "Wählen Sie einen Datenpunkt den Sie in .csv konvertiern möchten",
	t436: "Wähle Zeitbereich",
	t437: "Zeitraum selektieren und Prozess button druecken",
	t438: "Prozessdaten",
	t439: "Bereite Historie vor. Bitte warten !",
	t440: "Wähle CSV-Dateiname und speicher",
	t441: "Log-Datenpunkt kann nicht gefunden werden.",
	t442: "Fehler - Keine Logdaten zurück",
	t443: "Fehler bei der Kommun. mit Geräten (Master/Save) gefunden",
	t444: "Achtung!!!",
	t445: "Ruhe",
	t446: "Datenbank nicht gespeichert, wollen Sie das Fenster wirklich schließen?",
	t447: "tabellarische Ansicht",
	t448: "Grafik anzeigen",
	t449: "Tabelle anzeigen",
	t450: "Manuelle Abtauung EIN",
	t451: "Manuelle Abtauung AUS",
	t452: "Auto Abtauung",
	t453: "Setzte Abtausteuerung",
	t454: "Anlaufentlastung EIN",
	t455: "Anlaufentlastung AUS",
	t456: "Flüssigkeit",
	t457: "Keine Zeitpläne konfiguriert",
	t458: "Filialansicht",
	t459: "Verbindung mit Gerät (Master / Slave) ausgefallen !",
	t460: "Schnell Setup",
	t461: "Straße",
	t462: "Stadt",
	t463: "Telef",
	t464: "Region",
	t466: "Index",
	t469: "Adressbuch erfolgreich exportiert",
	t470: "Lösche Eintrag",
	t471: "Messgerät",
	t472: "Achtung: Gross/Kleinschreibung beachten",
	t473: "Online",
	t474: "Relais/Ausgang",
	t475: "Sensoren",
	t476: "Aus/Eingänge",
	t477: "Kalkulation",
	t478: "auf akt. Zeit setzen",
	t479: "Verbund",
	t480: "kein Verdichter",
	t481: "Kühlpos.",
	t482: "Möbel",
	t483: "Unbekan.",
	t484: "Fehlertyp:",
	t485: "Zuf.",
	t486: "Kopie",
	t487: "Entfernen",
	t488: "Möbelregler",
	t489: "Möbelart",
	t490: "LON",
	t491: "zu kopierende Anzahl eingeben",
	t492: "kein Name",
	t493: "Konfiguriere Kältelayout",
	t494: "Konfigurationsfehler:",
	t495: "Bitte Konfiguration vergleichen.",
	t496: "Kältelayout erfolgreich erstellt. OK zum Verlassen des Wizard drücken.",
	t497: "Konfigurationsstatus",
	t498: "Endgültiges Kältelayout",
	t500: "Code #",
	t501: "Layout",
	t502: "Systemsetup",
	t503: "Lizenzierung",
	t504: "Benutzer",
	t505: "Nicht authorisiert",
	t506: "Bitte vergleiche...",
	t507: "Beim Verlassen des Bildschirms gehen die Änderungen verloren.",
	t508: "Scanne Reglernetzwerk",
	t509: "Scanne",
	t510: "Vergleiche",
	t511: "AK-I/O",
	t512: "Neuscan",
	t513: "beinhaltet offline Programmierung",
	t514: "Geräte Bus",
	t515: "Online Geräte",
	t516: "Offline Programmierung",
	t517: "Doppelte Adressen",
	t518: "Verdichter Regelung",
	t519: "Verbunde",
	t520: "N/A",
	t521: "verfügbare Möbel",
	t522: "Erlaube erstellen von Multimöbeln",
	t523: "Gerät einfügen",
	t524: "Regler einfügen",
	t525: "Multi Möbel Regler",
	t526: "Lade Tabellen...",
	t527: "Anforderung von Alarmdaten für",
	t528: "Gruppen dateien Historie",
	t529: "Daten Dateien Historie",
	t530: "Alle Dateien",
	t531: "Zeige Symbole",
	t532: "Zeige Kurven",
	t533: "Nicht genügend daten gesammelt",
	t534: "Empfange neueste Daten.",
	t535: "Status/Sollwerte",
	t536: "Download gestartet",
	t537: "Datenpunkte",
	t538: "Empfange",
	t539: "Datenpunkte...",
	t540: "Intervall",
	t541: "Min",
	t542: "Max",
	t543: "Mittelw",
	t544: "Gehe zum Hauptalarm Fenster",
	t545: "Stop Autoanmelden",
	t546: "Verbinde mit",
	t547: "Auto-Anmelden abbrechen",
	t548: "Eingabetaste zum Abmelden",
	t549: "WEB 1",
	t550: "WEB 2",
	t551: "Automatisch anmelden",
	t552: "Ausloggen nach inaktiver Zeit",
	t553: "Home screen",
	t554: "Zeige Konfigurationsmenü",
	t555: "Zeige 'Verschiedenes' in der Übersicht",
	t556: "Vollbildschirm",
	t557: "Freigabe Alarmton",
	t558: "Erlaube Programm zu schiessen/minimieren",
	t559: "Fehler bei Verbind. mit Gerät #",
	t560: "Verbunden mit Netzwerk",
	t561: "Auto Logon abgebrochen",
	t562: "wiederhole Verbindungsversuch",
	t563: "abgemeldet von",
	t564: "Verbunden",
	t565: "Zeitformat",
	t566: "Datumsformat",
	t567: "Druck",
	t568: "Lichtstärke",
	t569: "Unterkühl",
	t570: "Unterkühl",
	t571: "Täglich",
	t572: "Wöchentl.",
	t573: "HST Datei",
	t574: "CSV Datei",
	t575: "Sonntag",
	t576: "Montag",
	t577: "Dienstag",
	t578: "Mittwoch",
	t579: "Donnerstag",
	t580: "Freitag",
	t581: "Samstag",
	t582: "Logs aufnehmen",
	t583: "Enable auto-collect",
	t584: "When to collect",
	t585: "File save location",
	t586: "Select file format",
	t587: "Select folder to store history files...",
	t588: "Aktivieren Sie das Loggen der Daten um tägliche oder wöchentliche Logs der Filiale empfangen und speichern zu können.",
	t589: "To view HST history files go to the main history screen and select import history. To view CSV files use any spreadsheet application.",
	t590: "Wenn die IP Adresse der aktuellen Filiale im Adressbuch existiert, werden die selektierten Logs gepeichert.",
	t591: "Select data-points",
	t592: "Alles selektiert",
	t593: "Points selected",
	t594: "Suchen",
	t595: "Autoverbindung mit",
	t596: "Please verify",
	t597: "Das automatische Sammeln der historischen Daten ist aktiv. Wollen Sie das Programm wirklich beenden ?",
	t598: "Wollen Sie das Programm wirklich beenden ?",
	t599: "Aktion",
	t600: "Filter",
	t601: "Alle Aktionen",
	t602: "More information...",
	t603: "Editierbar",
	t604: "Betrieb",
	t605: "Operation abgebrochen",
	t606: "Datenbankdownload vollständig !",
	t607: "Upate der Gerätedatenbank erfolgreich",
	t608: "Dateienübertragung zum Gerät erfolgreich",
	t609: "Download beendet, Datei wird im Gerät verarbeitet",
	t610: "Fehler während des ladens der Datei; Bitte Datei überprüfen",
	t611: "Uploading Datei",
	t612: "Downloading Datei",
	t613: "Uploading gewählter Datei",
	t614: "Downloading gewählter Datei",
	t615: "Löschen der Datei",
	t616: "Löschen gewählter Datei",
	t617: "FTP Verbindungsfehlr",
	t618: "Download gewählter Datei zum Systemmanger",
	t619: "Fehler bei der FTP-Verbindung. Prüfe IP-Adresse und Authorisation",
	t620: "FTP verbunden",
	t621: "Bytes",
	t622: "Dateien im Systemmanager werden überschrieben. Fortfahren ?",
	t623: "Dateien auf dem PC werden überschrieben. Fortfahren ?",
	t624: "Dateien sind zum Systemmanger kopiert worden",
	t625: "Nur Kälte",
	t626: "Convenience Filiale",
	t627: "Volle Funktion",
	t628: "Nur HVAC",
	t629: "Nicht erlaubter Dateiname. Der Dateiname muss kleiner 8 Buchstaben sein.",
	t630: "Überschreibe den Applikations-Code jetzt?",
	t631: "später",
	t632: "geladen",
	t633: "Sie müssen JA wählen um die Applikation zu überschreiben - oder SPÄTER",
	t634: "Bitte Simulator Hauptverzeichnis selektieren",
	t635: "Bitte selektiere Windows Verz.",
	t636: "Bitte selektiere Zielverz.",
	t637: "Datenbankverzeichnis selektieren",
	t638: "FTP getrennt",
	t639: "Zeitüberschreitung für FTP-Verbindung, FTP getrennt",
	t640: "Uplaod gewählter Datei zum PC",
	t641: "Lösche gewählte Datei",
	t642: "FTP Server Port",
	t643: "Web Server Port",
	t644: "lokaler Pfad",
	t645: "Hoch",
	t646: "Grösse",
	t647: "Datum",
	t648: "verbinden",
	t649: "Getrennt",
	t650: "Geräte-Pfad",
	t651: "Verfügbarer Speicherplatz:",
	t652: "Insgesamt ausgewählt Dateigröße",
	t653: "Dateien",
	t654: "Gesamtgröße:",
	t655: "Zum Ändern des Ordnes Klicken...",
	t656: "Ändere...",
	t657: "Diese ausgewählte Datei soll gelöscht werden?",
	t658: "Verbindung getrennt, EXIT",
	t659: "Selektierte(r) Dateiname(n) illegal. Der Download wird ignoriert.",
	t660: "Einige selektierte Dateinamen are illegal und werden ignoriert.",
	t661: "Dateienübertragung zum PC erfolgreich",
	t662: "Nicht genügend freier Speicher im Systemmanager.",
	t663: "Transfer file(s)",
	t664: "Modified",
	t665: "Kopiert Dateien von Flash zur Ramdisk",
	t666: "Bitte Datei Kopiervorgang selektieren:",
	t667: "Kopiere alle konfigurierten EDF Dateien vom Flashdrive",
	t668: "Alle Dateien von Flashdisk kopieren",
	t669: "Die edf/ed3 Dateien sind im Flash Speicher gesichert und somit nicht sichtbar im Dateibereich des SM.  Nutzen Sie die unten stehende Option, um die Dateien in den sichtbaren Bereich zu kopieren.",
	t670: "Dieser Vorgang kopiert alle im Gerät konfigurierten EDF Dateien vom Flash ins RAM",
	t671: "Dieser Vorgang kopiert alle EDF Dateien vom Flash ins RAM",
	t672: "No Valid EDF File Exists",
	t673: "EDF File Copy Finished",
	t674: "files copied",
	t675: "Bestät. Datenbank download",
	t676: "Geräteadresse",
	t677: "IP-Adresse/Hostname",
	t678: "Dateiname Datenbasis",
	t679: "Wähle",
	t680: "Update vollst. Systemmanger wird resettet und upgegraded. Vorgang kann einige Minuten dauern. ",
	t681: "Willkommen zum Upgrade manager - Schritt 1 von 2",
	t682: "Willkommen zum Upgrade manager - Schritt 2 von 2",
	t683: "Weiter",
	t684: "Vor Upgrade check",
	t685: "Lokalisiere Firmware",
	t686: "Backup Datenbank ?",
	t687: "Datenbankbackup läuft",
	t688: "Übertrage Software",
	t689: "Überschreibe Software",
	t690: "Selektiere Software zum Upgrade",
	t691: "Keine Werkseinstellungsoperation erkannt",
	t692: "Sie können fortfahren. Das System wird aber ggfs. nicht mehr funktionieren",
	t693: "Es wird empfohlen nicht fortzusetzen.",
	t694: "Wollen Sie weitermachen ?",
	t695: "Das Fernupgrade beinhaltet Risiken, die in einem Systemfehler enden können",
	t696: "Haftungsausschluss",
	t697: "Datenbank erfolgreich geladen",
	t698: "Backup erfolgreich",
	t699: "Applikation geladen",
	t700: "Applikation überschrieben",
	t701: "Software upgrade erfolgreich",
	t702: "Systemmanger in Werkseinstellung resetten ?",
	t703: "Untersuche Hardware",
	t704: "Untersuche device Status",
	t705: "Werkseinstellung gesichert",
	t706: "Aktuelle Software Version",
	t707: "Starte Updrade",
	t708: "Selektiere existierende Datenbank Datei",
	t709: "Selektiere existierende Firmware Datei",
	t710: "Zurück",
	t711: "Lokalisiere Firmware",
	t712: "Wähle .csi-Datei",
	t713: "Selektiere .MAI Datei",
	t714: "Remote downloading is not supported by this CPU board.",
	t715: "Anwendungsdownload fehlgeschlagen",
	t716: "Laden der Datenbasis fehlgeschlagen, bitte später noch einmal versuchen",
	t717: "Applikation überschrieben",
	t718: "Überschreiben der Anwendung fehlgeschlagen! Bitte erneut versuchen.",
	t719: "Überschreiben der Anwendung fehlgeschlagen",
	t720: "Keine Werkseinstellungsoperation erkannt",
	t721: "Fertig",
	t722: "Die selektierte Applikation ist unterschiedlich zur Firmware im Gerät. Laden kann zu einem Systemfehler führen.",
	t723: "Selektierte(r) Dateiname(n) illegal. Der Download wird ignoriert.",
	t724: "Einige selektierte Dateinamen are illegal und werden ignoriert.",
	t725: "Geräte BERG Jumper nicht Fehler",
	t726: "Gerätebatterie ist schwach",
	t727: "Geräte Hostnetzwerk ausgefallen",
	t728: "Gerät ist im Lastabwurf status",
	t729: "Selektieren Zieldatei",
	t730: "Selektiere Zielverzeichnis",
	t731: "Datenbank erfolgreich geladen",
	t732: "Status OK",
	t733: "Status Fehler",
	t734: "Status unbekannt",
	t735: "Vorgang überschreibt ggfs. Dateien im Zielsimulator. Weiter ?",
	t736: "Erneuern der Datenbasis beendet, bitte das Gerät jetzt neustarten",
	t737: "Erneuern der Datenbasis fehlgeschlagen, bitte später noch einmal versuchen",
	t738: "Gerät wird automatisch neustarten; wollen Sie fortfahren?",
	t739: "Wähle Datenbasis",
	t740: "Download Datenbasis-Datei?",
	t741: "Upload Datenbasis-Datei?",
	t742: "Laden der Datenbasis zum Systemmanger, bitte warten",
	t743: "Laden der Datenbasis war erfolgreich, jetzt Neustarten des Gerätes",
	t744: "Erneuern der Datenbasis beendet, Gerät erfolgreich neugestartet",
	t745: "Erneuern der Datenbasis fehlgeschlagen, bitte noch einmal versuchen",
	t746: "Fehler beim Erneuern der Datenbasis",
	t747: "Pre Download Check",
	t748: "Lokalisiere Datenbank",
	t749: "Datenbank download",
	t750: "Aktuelle Datenbank Information",
	t751: "Datendank zum Download selektieren",
	t752: "Sichere Datenbasis zum PC ( Upload )",
	t753: "Lade Datenbasis zum Systemmanger ( Download )",
	t754: "Supervisor Authorisation benötigt",
	t755: "Starte Upload",
	t756: "Bitte Datenbankoperation wählen:",
	t757: "Starte Download",
	t758: "Aktuelle Software Version",
	t760: "Datenbankdownload vollständig !",
	t761: "Wilkommen zum Datenbankmanager - Schritt 1 von 2",
	t762: "Wilkommen zum Datenbankmanager - Schritt 2 von 2",
	t763: "Erhalte eine fehlerhafte User-Liste, prüfe IP/URL Adresse",
	t764: "Ein fernupgrade der Systemdantenbank beinhaltet Risiken, welche in einem Systemfehler enden können.",
	t765: "Database downloading finished",
	t766: "Ethernet Scan",
	t767: "Vor dem Start die IP Adresse eingeben",
	t768: "Scan",
	t769: "Scanne",
	t770: "Logs aufnehmen",
	t771: "Farben für analoge Werte",
	t772: "Farben für digitale Werte",
	t773: "Ausschalt Zeitpläne",
	t774: "Abtauergebnis",
	t775: "Nachtanhebungszeitplan",
	t776: "Zeitplan Möbelbeleuchtung",
	t777: "Ladestatus",
	t778: "Alarme sammeln",
	t779: "Selektiere Ordner zum Speichern der Alarm Dateien ...",
	t780: "Monatlich",
	t781: "Aktivieren des automatischen und periodischen Alarmsammelns und Speichern in eine Datei. ",
	t782: "CSV Separator",
	t783: "Kein Alarme",
	t784: "Abrufen der Alarmanzahl for Gerät:",
	t785: "Abrufen der Alarmdetails von Gerät:",
	t786: "Automatisches Alarm sammeln ist aktiv. Das Programm wirklich schliessen ?",
	t787: "Stündl.",
	t788: "Die Datenbank wird aktualisiert. Fortfahren ?",
	t789: "Abtastung abgeschlossen",
	t790: "Bitte eine Saugdruckgruppe konfigurieren.",
	t791: "Zur erneuten Nutzung des Layout Wizard die Anzahl der Verbundanlagen null setzen (Datenbank wird gelöscht).",
	t792: "Bitte über Konfiguration-&gt; Regelung die Anzahl der Verbundanlagen auf null setzen.",
	t793: "Hinweis: Alle Zeitpläne, Kalkulationen oder Importe vorheriger Kälte Konfigurationen müssen neu konfiguriert werden.",
	t794: "Kopiervorgang beendet.",
	t795: "Zustandsbericht Kälte",
	t796: "Endgültiges HLK Layout",
	t797: "HLK layout erfojgreich fertiggestellt. Drücken Sie Ok zu Beenden des Assistenten.",
	t798: "Konfiguration HLK Layout...",
	t799: "Um den HLK Assistenten mehrfach zu nutzen muß die HLK Datenbank resettet werden.",
	t800: "Bitte über Konfiguration-&gt; Regelung die Anzahl der HLK Regler auf null setzen.",
	t801: "Hinweis: Alle Zeitpläne, Berechnungen oder Importe auf der Grundlage der vorigen HVAC-Konfiguration müssen neu konfiguriert werden.",
	t802: "Stufenraster",
	t803: "Stufe",
	t804: "Level",
	t805: "Mehrfachtip anzeigen",
	t806: "Stufenzahl",
	t807: "Datei konnte nicht gespeichert werden.",
	t808: "Falsches Modell.",
	t809: "Erfolgreich geladen.",
	t810: "Offen",
	t811: "Diese Funktion ist nur in der Desktop Version von Storeview verfügbar.",
	t812: "Fortfahren",
	t813: "Wizard verlassen",
	t814: "Vorgang war erfolgreich. Möchten Sie fortfahren oder Sie den Assistenten verlassen ?",
	t815: "Fehler beim Abrufen von Alarmloggerdaten.",
	t817: "Gerätename :",
	t819: "Im Gange...",
	t820: "Alarmdaten nicht vorhaden",
	t821: "Anzahl Alarme",
	t822: "Mimic Anzeigeoptionen",
	t823: "Nur text anzeigen",
	t824: "Nur Icon anzeigen",
	t825: "Text und Icon anzeigen",
	t826: "1 Woche",
	t827: "2 Wochen",
	t828: "3 Wochen",
	t829: "4 Wochen",
	t830: "Grafik Einstellungen",
	t831: "Alarmdiagnose kann nicht angezeigt werden. Bitte prüfen Sie das automatische Sammeln der Alarm unter Einstellungen.",
	t832: "Umordnen der Konfiguration mit I/O's ist nicht erlaubt ",
	t833: "Die Host/IP Adresse existiert bereits im Adressbuch. Möchten Sie den Eintrag updaten ?",
	t834: "Blättern",
	t835: "Adressbuch Ordner wählen",
	t836: "Ort",
	t837: "Der selektierte Pfad beinhaltet keine Adressbuch Inhalte. Mächten Sie den Inhalt des existierenden Adressbuch zum selektierten Pfad exportieren ?",
	t838: "Alarm Aktionscodes",
	t839: "Addressbuch im Pfad nicht gefunden. Adressbuch wird von hier geladen...",
	t840: "Bitte Pfad zum Speichern der Logs wählen.",
	t841: "Bitte einen gültigen Pfad zum Speichern der Logs wählen.",
	t842: "Sammeln der Alarme ist im Gange...",
	t843: "Datensammeln ist im Gange ...",
	t844: "Im Hintergrund arbeiten",
	t845: "Alarm log Fortschritt",
	t846: "Host IP/URL darf nicht leer sein",
	t847: "Adresse darf nicht leer sein",
	t849: "Sie haben nicht die neueste Dateiversion, möchten Sie die Daten von der neuesten Version laden ?",
	t850: "Versionskonflikt",
	t851: "Daten Log Fortschritt",
	t852: "User Name and Password fields should not be blank.",
	t853: "IP Address field should not be blank.",
	t854: "Host IP/URL Address field should not be blank.",
	t855: "User name should not be blank.",
	t856: "Password should not be blank.",
	t857: "The SM800 you have connected to with",
	t858: "has not had the Initial Startup Wizard completed. Please complete this task and reconnect to the SM800",
	t859: "Set to now",
	t860: "Invalid input",
	t861: "Text is too long",
	t862: "Offset",
	t863: "Unable to communicate with unit(s) on host network",
	t864: "Please verify addresses are different. Configuration is updated when the save button is pressed.",
	t865: "Please select a valid History File (.hst)",
	t866: "Please select a valid History Group File (.grp)",
	t867: "Farbe",
	t868: "Prozentsatz",
	t869: "Spannung",
	t870: "Aktuellen",
	t871: "Macht",
	t872: "Frequenz",
	t873: "Konzentration",
	t874: "Fluss",
	t875: "Geschwindigkeit",
	t876: "Säure",
	t877: "Bitte wählen Sie Diagramme an der unteren Schublade",
	t878: "PNG speichern",
	t879: "Zeichnen",
	t880: "Systemstatus...",
	t881: "System- und Einheitenstatus",
	t882: "Gataway",
	t883: "Features-Lizenzen",
	t884: "Software-Version",
	t885: "Browsermodulversion",
	t886: "Danux-Version",
	t887: "Mac-Adresse",
	t888: "Support & Updates",
	t889: "Einheitenname",
	t890: "Verbinden",
	t891: "Verlorene Verbindung",
	t892: "Es wurden keine Geräte für den Typ gefunden.",
	t893: "Die aktuell verwendete Softwareversion wird nicht unterstützt",
	t894: "Schnelle Links",
	t895: "Detailansicht",
	t896: "Grafik",
	t897: "Dienstprogramme",
	t898: "Software-Rollback",
	t899: "Diagnose",
	t900: "Ein Fehler ist aufgetreten",
	t901: "Es wurden keine Daten gefunden, die keine Alarmdaten enthalten.",
	t902: "Einstellungen",
	t903: "Anwendungsname",
	t904: "Anwendungsversion",
	t905: "Nachricht",
	t906: "Ereignistyp",
	t907: "Timestamp",
	t908: "Rollback-Version",
	t909: "Aktuelle Version",
	t910: "Software",
	t911: "Rollback...",
	t912: "Bitte wählen Sie Geräte aus der Liste aus",
	t913: "Kalenderansicht",
	t914: "Beim Laden von Geräten ist ein Fehler aufgetreten, versuchen Sie es erneut.",
	t915: "Beim Laden von Zeitplänen ist ein Fehler aufgetreten, versuchen Sie es erneut.",
	t916: "Keine Ausrüstung gefunden",
	t917: "Termine Export",
	t918: "Bitte wählen Sie CSV- oder PDF-Exporttyp",
	t919: "Export als CSV",
	t920: "Vorbereiten von PDF",
	t921: "Export als PDF",
	t922: "Bitte geben Sie eine IP-Adresse in diesem Format xx.xx.xx.xx[:xxxx] an.",
	t923: "Nutzername",
	t924: "einloggen",
	t925: "Rollback",
	t926: "Rollback SM800A Software",
	t927: "Mit dieser Aktion wird die gesamte Software auf die aufgeführten Versionen zurückgesetzt.",
	t928: "Party-Geräte-Editor",
	t929: "Text, der die Funktion erklärt",
	t930: "AKTUALISIEREN CDF",
	t931: "Kartengerät",
	t932: "Gerätedatei",
	t933: "1-basierte Registernummer",
	t934: "Speichertyp",
	t935: "Min",
	t936: "Max",
	t937: "Nur lesen",
	t938: "Protokoll",
	t939: "Nachrichtentyp",
	t940: "Conversion-Schlüsselwort",
	t941: "Parameter",
	t942: "Addr.",
	t943: "Priorität",
	t944: "Bit",
	t945: "Negiert",
	t946: "Neue Datei",
	t947: "Datei öffnen",
	t948: "Datei generieren",
	t949: "Gruppe",
	t950: "Produktversion",
	t951: "Fehler aufgetreten",
	t952: "Nicht gefunden",
	t953: "Dieses Gerät wurde nicht gefunden.",
	t954: "Außentemperatur.",
	t955: "Es wurden keine Geräte gefunden",
	t956: "Fällen",
	t957: "Alarm erfolgreich stummschalten/handeln",
	t958: "Stummschaltung/Akt für diesen Alarm fehlgeschlagen",
	t959: "Beim Laden von Einheiten ist ein Fehler aufgetreten, versuchen Sie es erneut.",
	t960: "Keine Ausrüstung",
	t961: "Es wurden keine Geräte für den Typ gefunden.",
	t962: "Konfigurieren des Systemmanagers",
	t963: "Gerät",
	t964: "Rückgängig",
	t965: "Sie haben den Wert des Felds geändert, möchten Sie die Änderungen speichern?",
	t966: "Ausgewählte Verlaufspunkte löschen",
	t967: "Schr {{number}}",
	t968: "Std. Pumpendrehzahl",
	t969: "Sd",
	t970: "V3hr",
	t971: "HR-Referenz",
	t972: "Stw {{number}}",
	t973: "Zwei Referenzen",
	t974: "Zwei Pumpendrehzahlen",
	t975: "V3tw",
	t976: "Trec",
	t977: "Präz",
	t978: "Vrec OD",
	t979: "Prec-Referenz",
	t980: "Trec-Referenz",
	t981: "Pc",
	t982: "SS",
	t983: "Vhp OD",
	t984: "PGC-Referenz",
	t985: "Auswerfer OD",
	t986: "Pgc",
	t987: "Shp",
	t988: "Sgc",
	t989: "Sc3",
	t990: "SGC-Steuerung",
	t991: "Kond. Kapazität",
	t992: "Po",
	t993: "SS",
	t994: "Sd",
	t995: "Stk",
	t996: "Laufleistung",
	t997: "Browserzertifikat",
	t998: "Ausloggen",
	t1000: "Sortierung nur auf {{count}}",
	t1001: "{{count}} von {{total}} Alarmen geladen",
	t1002: "Bestätigung für alle Alarme erfolgreich",
	t1003: "Bestätigung für alle Alarme fehlgeschlagen",
	t1004: "Nur {{count}} von {{total}} Alarmen bestätigt",
	t1005: "Export für alle Alarme fehlgeschlagen",
	t1006: "Export erfolgreich",
	t1007: "Export fehlgeschlagen, mindestens ein Alarmtyp ist erforderlich",
	t2000: "-",
	t2001: "Die Antwort ist zu groß für den XML-Übertragungspuffer, der 32 KB groß ist",
	t2002: "Kein mit cmd-Element verbundenes Attribut oder Befehl hat kein \"cmd\"-Element oder konnte nicht in eine Variable schreiben oder konnte eine Variable nicht lesen oder konnte einen Punkt nicht lesen oder konnte nicht Ein/Aus-Eingang schreiben oder Ausgangsbetriebsmodus weiterleiten",
	t2003: "Schlechte Befehlseingabe",
	t2004: "Fehler in den CGI-Post-Parametern",
	t2005: "Fehler beim Konvertieren der XML-Antwort von ihrer internen Darstellung in die XML-Antwortzeichenfolge",
	t2006: "Fehler beim Decodieren der URL-Zeichenfolge",
	t2007: "Speicherüberlauf",
	t2008: "Komprimierung der Antwortdaten fehlgeschlagen",
	t2009: "Fehler beim Analysieren der Anfrage",
	t2010: "Datenzugriffsfehler – eine Reihe von Ursachen, die nicht weiter behoben werden",
	t2011: "Undefinierter Wert des Befehlsaktionsattributs Der Attributwert ist ungültig",
	t2012: "Fehler in Alarmreferenznummer oder Alarm nicht konfiguriert",
	t2013: "Die Eingabe-CGI-Parameterzeichenfolge ist zu lang.",
	t2014: "Geräteadresse nicht gefunden",
	t2015: "Autorisierung zum Schreiben fehlgeschlagen",
	t2016: "Kein Verlauf konfiguriert",
	t2017: "Keine Verlaufsdaten zurückgegeben, möglicherweise Zeitfehler",
	t2018: "Dateifehler erstellen",
	t2019: "Dateifehler lesen",
	t2020: "Maximale Größe der Geräteliste überschritten",
	t2021: "nicht benutzt",
	t2022: "Ungültiger Verlaufsindex",
	t2023: "Platinenpunktindex konnte nicht von nodetype,node,mod und point gefunden werden.",
	t2024: "Verlaufsindex konnte nicht gefunden werden",
	t2025: "Die Verlaufsabfrage wurde nicht initialisiert, bevor versucht wurde, eine Verlaufsabfrage zu starten.",
	t2026: "Eine hq_start_query wurde versucht, während eine Verlaufsabfrage aktiv ist",
	t2027: "Keine Befehlsattribute gefunden, obwohl einige erwartet werden",
	t2028: "Ein Beginn der Epoche ist ohne Ende vorgesehen oder umgekehrt",
	t2029: "Das Zeit- und Datumsformat konnte nicht in Epochensekunden konvertiert werden",
	t2030: "Der Befehl erfordert eine query_id, es wird jedoch keine bereitgestellt",
	t2031: "Fehler beim Erstellen der XML-Antwort",
	t2032: "Die angegebene Abfrage-ID stimmt mit keiner aktiven Abfrage überein",
	t2033: "Es wurde versucht, einen hq_get_data-Befehl auszuführen, aber es sind noch keine Daten verfügbar",
	t2034: "Es wurde eine Abtastrate angegeben, die nicht zulässig ist",
	t2035: "Das Mittelungsintervall ist kleiner oder gleich der Abtastrate, mit der Daten aufgezeichnet wurden",
	t2036: "Die Stopp-Epochenzeit ist kleiner als die Start-Epochenzeit oder die Stopp-Epochenzeit minus der Start-Epochenzeit ist kleiner als das Mittelungsintervall",
	t2037: "Die bereitgestellte device_id stimmt mit keiner derzeit bekannten ak255 überein. Beachten Sie, dass dem ak255 nur konfigurierte Geräte bekannt sind",
	t2038: "Keine ID-Attribute",
	t2039: "Ungültige ID-Attribute",
	t2040: "Keine Fahrplannummer",
	t2041: "Ungültige Zeitplannummer",
	t2042: "Keine Zeitplandetails",
	t2043: "Einschaltzeit fehlt",
	t2044: "Stunde fehlt",
	t2045: "Ungültige Stunde",
	t2046: "Minute fehlt",
	t2047: "Ungültige Minute",
	t2048: "Off_time fehlt",
	t2049: "Fehlende Wochentage",
	t2050: "Ungültige Wochentage",
	t2051: "Fehlende Feiertage",
	t2052: "Ungültige Feiertage",
	t2053: "Holiday_start fehlt oder ist ungültig",
	t2054: "Holiday_end fehlt oder ist ungültig",
	t2055: "Fehlendes oder ungültiges holiday_open",
	t2056: "Holiday_close fehlt oder ist ungültig",
	t2057: "Urlaub nicht konfiguriert",
	t2058: "Fehlende Beschreibung",
	t2059: "Alarm kann nicht gelöscht werden",
	t2060: "Fehlende oder ungültige store_open",
	t2061: "Fehlende oder ungültige store_closed",
	t2062: "Alarm nicht konfiguriert",
	t2063: "Knoten offline",
	t2064: "Fehlender Index",
	t2065: "Ungültiger Index",
	t2066: "Relais kann nicht zeitgesteuert eingeschaltet werden",
	t2067: "Der zu ändernde Name hat zu viele Zeichen. Beim Ändern von store_name oder unit_name müssen < = 16 Zeichen sein, beim Ändern von storeId1 oder storeId2 müssen < = 8 Zeichen sein.",
	t2068: "Fehlende Elemente, die dem Befehlselement zugeordnet sind.",
	t2069: "Tag_ID nicht definiert",
	t2070: "Fehlender Blattknoten für Daten",
	t2071: "Keine Änderung der Daten",
	t2072: "Datenbank konnte nicht aktualisiert werden",
	t2073: "Nicht beschreibbar",
	t2074: "Zählerfehler",
	t2075: "Keine Meter",
	t2076: "Ungültige Schuppenebene",
	t2077: "Ungültiger Typ",
	t2078: "System beschäftigt",
	t2079: "Assets sind nicht für Lastabwurf konfiguriert",
	t2080: "Ungültige Nennleistung für Anlage",
	t2081: "Ungültige Startverzögerung für Asset",
	t2082: "Konfiguration beschäftigt",
	t2083: "E/A-Abfrage läuft",
	t2084: "Zu viele Abtauungen im Gange",
	t2085: "Versuch, eine fehlerhafte Datenbankversion in das Gerät zu laden",
	t2086: "Das Laden der Datenbank ist fehlgeschlagen",
	t2087: "Kein verfügbarer Webspeicher verfügbar",
	t2088: "Demand Response nicht konfiguriert (nur das AK355-System)",
	t2089: "Fehlende oder falsche Dateigröße.",
	t2090: "Die angeforderte Datei konnte im System nicht gefunden werden",
	t2091: "Speichern der Datenbank auf RAM-Disk fehlgeschlagen.",
	t2092: "Dateigröße zu groß.",
	t2093: "Fehlender Dateiname.",
	t2094: "Fehlende Datei-ID.",
	t2095: "Fehlender Dateiladeindex (Hinweis: Dies ist der Sequenzindex für load_file load_database).",
	t2096: "Datei-ID stimmt nicht überein oder fehlt.",
	t2097: "Dateiladevorgang läuft bereits.",
	t2098: "Die decodierte Datei stimmt nicht mit der ursprünglichen Dateigröße überein.",
	t2099: "Der Dateiladevorgang wurde abgebrochen oder nicht gestartet.",
	t2100: "Ungültige Benutzeranfrage (ursprünglicher Anfragender stimmt nicht mit IP-Adresse überein)",
	t2101: "Ungültige Dateiladesequenz.",
	t2102: "Ungültiges Verzeichnis",
	t2103: "Ungültige Erweiterung",
	t2104: "Ungültiger Versatz",
	t2105: "Fehler beim Codieren der Datei",
	t2106: "Fehler beim Decodieren der Datei",
	t2107: "Fehler beim Öffnen der Datei zum Lesen/Schreiben",
	t2108: "Fehler beim Schreiben in die Datei",
	t2109: "Die Datei ist im Flash-Dateisystem schreibgeschützt",
	t2110: "Datei konnte nicht gelöscht werden.",
	t2111: "Ordner kann nicht gelöscht werden.",
	t2112: "Fehlende Geräte",
	t2113: "Es wurden nicht alle Geräte von URL gefunden",
	t2114: "Fehlende Verlaufsparameter",
	t2115: "Es wurden nicht alle Verlaufsparameter der URL gefunden",
	t2200: "Die derzeit verwendete Softwareversion ( {{currentVersion}} ) wird nicht empfohlen",
	t2201: "Die aktuell verwendete Softwareversion ( {{currentVersion}} ) wird nicht empfohlen ( > {{recommendedVersion}} empfohlen)",
	t2202: "Softwareversionsfehler",
	t2203: "Grafikeditor",
	t2204: "Bearbeiten",
	t2205: "Zeichnen",
	t2206: "Neue Ansicht",
	t2207: "Erstellt",
	t2208: "Tropfen",
	t2209: "Neue Grafik",
	t2210: "Erstellen Sie eine neue Grafikansicht",
	t2211: "Name",
	t2212: "Umbenennen",
	t2214: "Export",
	t2213: "Duplikat",
	t2215: "Löschen",
	t2216: "Möchten Sie die Ansicht „ {{name}} “ wirklich löschen?",
	t2217: "Alle Ansichten exportieren",
	t2218: "Mehr...",
	t2219: "Regel",
	t2220: "Regeln",
	t2221: "Regelname",
	t2222: "Bedienfeld bearbeiten",
	t2223: "Bedingungen",
	t2224: "Gradient",
	t2225: "Bedingungen umkehren",
	t2226: "Zuordnungen",
	t2227: "Kartenformen nach",
	t2228: "Form-ID",
	t2229: "Zustände",
	t2230: "Zuordnung hinzufügen",
	t2231: "Regel hinzufügen",
	t2232: "Operator",
	t2233: "Bedingung hinzufügen",
	t2234: "Beginnen Sie mit dem Hinzufügen von Regeln",
	t2235: "Möchten Sie die Regel \" {{name}} \" wirklich löschen?",
	t2236: "Eine Kopie der Regel wird erstellt",
	t2237: "Grafik",
	t2238: "Sie müssen Site-Ansichten erstellen, um sie anzuzeigen",
	t2239: "Gehen Sie zum Grafikeditor",
	t2240: "Gehen Sie zu den Details",
	t2241: "Parameter auswählen",
	t2242: "Keine Parameter gefunden",
	t2243: "Optionen",
	t2244: "Ein/Aus-Eingang",
	t2245: "Relaisausgang",
	t2246: "Sensoreingang",
	t2247: "Variable Ausgabe",
	t2248: "Generisches Gerät",
	t2249: "Leerer Knoten",
	t2250: "Verlaufsprotokollierung wird nicht unterstützt...",
	t2251: "Erneuter Versuch",
	t2252: "Verlauf konnte nicht abgerufen werden",
	t2253: "Das Laden des Verlaufs kann eine Weile dauern und unterstützt nicht mehrere Abfragen gleichzeitig. Bitte wiederholen Sie diese Anfrage.",
	t2254: "Parametername",
	t2255: "Gerätename",
	t2256: "Gerätetyp",
	t2257: "Nicht alle Verlaufsparameter konnten geladen werden ( {{n}} fehlgeschlagen)",
	t2258: "Falscher Benutzername oder falsches Passwort",
	t2259: "Keine Kommunikation mit der systemmanager",
	t2260: "Software-Update",
	t2261: "System",
	t2262: "Software-Rollback",
	t2263: "Installieren",
	t2264: "Das Software-Rollback ersetzt eine neuere Version",
	t2265: "Rollback-Software auf die Backup-Version {{version}}",
	t2266: "Datenbank",
	t2267: "Eine Aktualisierung ist verfügbar",
	t2268: "Datenbank exportieren",
	t2269: "Herunterladen",
	t2270: "Datenbank herunterladen",
	t2271: "Datenbank kann nicht exportiert werden",
	t2272: "Datenbankdatei",
	t2999: "Kommunikationsfehler",
	t2998: "Unbekannter Fehler",
	t3000: "Details",
	t3001: "Früherer XML-Code hier",
	t3002: "Zuweisen",
	t3003: "Bundesland",
	t3004: "Softwareverwaltung",
	t3005: "Lokal gespeicherte Datei",
	t3006: "Online über Internet",
	t3007: "Stellen Sie sicher, dass die Stromversorgung während des Aktualisierungsvorgangs nicht unterbrochen wird. Es wird empfohlen, eine Sicherungskopie der Systemdatenbank zu erstellen, bevor Sie Ihr System aktualisieren. Nach Abschluss der Softwareaktualisierung(en) wird empfohlen, den Betriebsstatus des Systems zu überprüfen, um die korrekte Steuerung und den korrekten Betrieb zu bestätigen.",
	t3008: "Keine Dateien ausgewählt",
	t3009: "Softwaredatei auswählen",
	t3010: "Hochladen von Dateien",
	t3011: "Sicherheitsüberprüfung",
	t3012: "Datei extrahieren",
	t3013: "Datei prüfen",
	t3014: "Datei-Upload fehlgeschlagen!",
	t3015: "Sicherheitsüberprüfung fehlgeschlagen!",
	t3016: "Extrahieren fehlgeschlagen!",
	t3017: "Überprüfen der Datei fehlgeschlagen!",
	t3018: "Datei wird heruntergeladen: {{fileName}}",
	t3019: "Datei erfolgreich heruntergeladen!",
	t3020: "Herunterladen fehlgeschlagen!",
	t3021: "Daten hochladen",
	t3022: "Dateien herunterladen",
	t3023: "Betriebssystem-Update",
	t3024: "Edf-Update",
	t3025: "Dateien herunterladen",
	t3026: "Der Wert des ausgewählten Parameters war leider nicht verfügbar",
	t3027: "Importieren",
	t3028: "Es ist mindestens eine Datei erforderlich",
	t3029: "Datei ist nicht gültig",
	t3030: "Grafikansichten verwalten",
	t3031: "Hochladen",
	t3032: "Umwandeln/Migrieren",
	t3033: "Grafikdatei(en) werden gelesen, bitte warten ...",
	t3034: "Grafikdatei wird importiert, bitte warten ...",
	t3035: "Wählen Sie Grafik",
	t3036: "Berichte",
	t3037: "Datenbank importieren",
	t3038: "Datenbankdatei auswählen",
	t3039: "Stellen Sie sicher, dass die Stromversorgung während des Rollback-Vorgangs nicht unterbrochen wird. Es wird empfohlen, vor dem Rollback Ihres Systems ein Backup der Systemdatenbank zu erstellen. Nach Abschluss des Software-Rollbacks wird empfohlen, den Betriebsstatus des Systems zu überprüfen, um die korrekte Steuerung und den korrekten Betrieb zu bestätigen.",
	t3040: "Der System Manager wird nach dem Update neu gestartet",
	t3041: "Erweitern",
	t3042: "Debug-Meldungen anzeigen",
	t3043: "max. erlaubte Zeichen -",
	t3044: "Bitte Update-Datei hochladen",
	t3045: "Aktuelle SW-Version",
	t3046: "Aktuelle Danux-Version",
	t3047: "Neueste SW-Version",
	t3048: "Neueste Danux-Version",
	t3049: "Sichern, bitte warten",
	t3050: "Datei wird installiert, bitte warten",
	t3051: "Update installieren",
	t3052: "Das Gerät wird neu gestartet",
	t3053: "Neustart des Systemmanagers",
	t3054: "Der Browser wird jetzt aktualisiert",
	t3055: "Software erfolgreich installiert",
	t3056: "Update fehlgeschlagen, folgender Fehler ist aufgetreten:",
	t3057: "Sichern Sie die aktuelle Softwareversion",
	t3058: "Sichern Sie die aktuellen EDF-Dateien",
	t3059: "Grafikansicht wurde erfolgreich gespeichert",
	t3060: "Hintergrundbild anwenden",
	t3061: "Quittieren",
	t3062: "Wiederholen",
	t3063: "Allgemein",
	t3064: "Kühlpos. Night Rückschläge",
	t3065: "Saugnacht Rückschläge",
	t3066: "Heruntergeladene Version {{fileName}}",
	t3067: "Um die ausgewählten Einheiten zu aktualisieren, klicken Sie auf „Installieren“.",
	t3068: "{{updateType}} Version zurücksetzen",
	t3069: "Installieren",
	t3070: "Installieren Sie die Rollback-Version",
	t3071: "Keine der verfügbaren Einheiten enthält eine Rollback-Version",
	t3072: "Neue Version online verfügbar",
	t3073: "Datei für ausgewählte Einheiten hochladen",
	t3074: "Datei für ausgewählte Einheit hochladen",
	t3075: "Keine Rollback-Versionen für Einheit verfügbar",
	t3076: "Download beendet",
	t3077: "Aktuelle {{updateType}} Version",
	t3078: "Neueste {{updateType}} Version",
	t3079: "Die Aktualisierung wird durchgeführt. Möchten Sie das Fenster wirklich schließen?",
	t3080: "Danux ist nicht aktuell! Bitte aktualisieren Sie Danux und aktualisieren Sie dann die Software erneut",
	t3081: "Anwendungen können nicht auf diese Version heruntergestuft werden",
	t3082: "Anwendungsinformationen",
	t3083: "Versionshinweise",
	t3084: "Bedingungen & Datenschutz",
	t3085: "Lizenzen",
	t3086: "Bitte gehen Sie zur Konfiguration, um Zeitpläne einzurichten",
	t3087: "Leider ist bei der Vorbereitung der Migration ein Fehler aufgetreten. Bitte stellen Sie sicher, dass alle Einheiten im Netzwerk erreichbar und richtig konfiguriert sind. \n\n Der folgende Fehler ist aufgetreten:\n {{error}}",
	t3088: "Importieren oder migrieren Sie eine vorhandene Grafik",
	t3089: "Anzeige",
	t3090: "Name der Anlage",
	t3091: "Zusammenbruch",
	t3092: "Import abgeschlossen",
	t3093: "Entladesicherheit",
	t3094: "Saugsicherheit",
	t3095: "Cutin entladen",
	t3096: "Entladungsausschnitt",
	t3097: "Saugausschnitt",
	t3098: "Saugschnitt",
	t3099: "Kapazität",
	t3100: "Die Migrationsdateien (flp, dpj oder json) konnten nicht gefunden werden",
	t3101: "Timeout-Parsing-Datei",
	t3102: "Gerätedateien und -adressen von Drittanbietern",
	t3103: "Netz",
	t3104: "Sperren",
	t3105: "XML",
	t3106: "Wählen Sie den anzuzeigenden Parameter aus",
	t3107: "Geräteparameter",
	t3108: "Keine Einheiten",
	t3109: "Es wurden keine Einheiten gefunden oder geladen",
	t3110: "HACCP",
	t3111: "ANLAGEGUT",
	t3112: "Keine HACCP-Daten verfügbar",
	t3113: "Geräteinformationen",
	t3114: "Updates verfügbar",
	t3115: "Gehen Sie zu Software",
	t3116: "Nicht verbunden",
	t3117: "Der aktuelle Zugriffsbereich für diese Anwendung ist ' {{targetLevel}} ', der bereitgestellte Benutzer liegt in Bezug auf die Zugriffsrechte über diesem Bereich",
	t3118: "Leider ist beim Generieren der PDF-Datei ein Fehler aufgetreten, bitte versuchen Sie es erneut oder wenden Sie sich an den Produktsupport, wenn das Problem weiterhin besteht.",
	t3119: "PDF-Export fehlgeschlagen",
	t3120: "Bei großen Datensätzen reagiert die Benutzeroberfläche möglicherweise nicht und es kann eine Weile dauern, bis sie abgeschlossen ist",
	t3121: "Bitte wählen Sie Termine aus der Liste aus",
	t3122: "Bitte gehen Sie zur Konfiguration, um einen Zeitplan einzurichten",
	t3123: "Erfolgreich",
	t3124: "Warnung",
	t3125: "Anlageninformationen",
	t3126: "Bitte wählen Sie Zeit und Datenpunkte aus der Liste aus",
	t3127: "Verbinden",
	t3128: "Verbindung zu dieser Seite konnte nicht hergestellt werden, bitte kontaktieren Sie den Support",
	t3129: "Zum gewählten Datum waren keine HACCP-Daten verfügbar. Bitte stellen Sie sicher, dass Verlauf und Uhrzeit im Konfigurationsmenü korrekt konfiguriert sind",
	t3130: "Diagnosebericht",
	t3131: "Einheit(en) für den Export des Diagnoseberichts auswählen",
	t3132: "Auf dieser Benutzerzugriffsebene nicht verfügbar",
	t3133: "Diagnoseprotokoll",
	t3134: "Bericht konnte nicht heruntergeladen werden",
	t3135: "Schriftart",
	t3136: "Schriftgröße",
	t3137: "Keine zu migrierenden Ansichten",
	t3138: "Laden Sie die Desktop-Version herunter",
	t3139: "Aufgrund von Sicherheitseinschränkungen in modernen Browsern (",
	t3140: "RFC1918",
	t3141: ") ist es leider nicht möglich, sich mit unverschlüsseltem HTTP mit Geräten innerhalb eines privaten Netzwerkbereichs zu verbinden.",
	t3142: "Keine Daten für den ausgewählten Zeitraum verfügbar",
	t3143: "Konfigurieren Sie Ihren Systemmanager für die Verwendung von HTTPS oder verwenden Sie die Desktop-Version.",
	t3144: "Wir konnten nicht mit dem Systemmanager kommunizieren. Dies bedeutet entweder, dass Ihr Gerät keine Verbindung zur IP oder zum Hostnamen des System Managers herstellen kann, oder dass Sie dem auf dem Gerät verwendeten SSL-Zertifikat möglicherweise manuell vertrauen müssen.",
	t3145: "Klicken Sie auf den folgenden Link, um eine neue Registerkarte zu öffnen, um dem Zertifikat zu vertrauen, und kehren Sie dann zu diesem Bildschirm zurück.",
	t3146: "Unterschrift",
	t3147: "DI - digitale Eingänge",
	t3148: "AI - Analogeingang",
	t3149: "Gehen Sie zur HACCP-Konfiguration",
	t3150: "Keine Daten",
	t3151: "Die Sicherung wird im System Manager für die Rollback-Funktion gespeichert",
	t3152: "Auswählen, was in Site-Ansicht(en) angezeigt werden soll",
	t3153: "Vermögenseinheit",
	t3154: "Grafikdateien",
	t3155: "Leider ist beim Abrufen der edf-Dateien und -Versionen aus dem Systemmanager ein Fehler aufgetreten...",
	t3156: "Um auszuwählen, welche Dateien installiert werden sollen, klicken Sie auf die Schaltfläche „Dateien auswählen“.",
	t3157: "Wähle Dateien",
	t3158: "Sicherung entfernen",
	t3159: "Staging-Dateien",
	t3160: "Die Sicherung wird im System Manager für die Rollback-Funktion gespeichert",
	t3161: "Installierte Größe",
	t3162: "Neue Größe",
	t3163: "Einbaudatum",
	t3164: "Neues Datum",
	t3165: "Sensoren",
	t3166: "Relais",
	t3167: "Eingänge",
	t3168: "Aus",
	t3169: "Meter",
	t3170: "Geräte",
	t3171: "Passwort abgelaufen, bitte neues Passwort eingeben",
	t3172: "Neues Kennwort",
	t3173: "Bestätige das Passwort",
	t3174: "Ändere das Passwort",
	t3175: "Das Passwort muss mindestens 8 Zeichen enthalten (1 Großbuchstabe, 1 Kleinbuchstabe, 1 Zahl, 1 Sonderzeichen)",
	t3176: "Passwörter stimmen nicht überein",
	t3177: "Das Passwort darf nicht mit dem alten Passwort identisch sein",
	t3178: "Passwortänderung erfolgreich",
	t3179: "Passwort kann nicht geändert werden. Fehler vom Gerät",
	t3180: "Passwort zurücksetzen",
	t3181: "Generiert",
	t3182: "Site-Name",
	t3183: "Ereignis-ID",
	t3184: "Zeit",
	t3185: "Rolle",
	t3186: "Informationen zur Veranstaltung",
	t3187: "Ausgewählte Alarme bestätigen",
	t3188: "Alle ausgewählten Alarme bestätigen",
	t3189: "Datenbank importieren nach",
	t3190: "Datenbank importieren",
	t3191: "Dateityp sollte .zip sein",
	t3192: "Datei auswählen",
	t3193: "Öffnungszeiten",
	t3194: "Feiertage",
	t3195: "Ein/Aus Zeitpläne",
	t3196: "Anlage",
	t3197: "Du hast nicht gespeicherte Änderungen. Bist du sicher dass du gehen willst?",
	t3198: "Leider ist die Migration fehlgeschlagen. Das System ist derzeit ausgelastet. Bitte versuchen Sie es später erneut",
	t3199: "Laden Sie ein Bild hoch, das an die aktuellen Abmessungen angepasst ist",
	t3200: "Hintergrundbild ersetzen",
	t3201: "Ersetzen Sie Hintergrundbilder durch skalierbare Vektorgrafiken.",
	t3202: "Nur SVG-Dateien mit demselben Namen wie die vorhandenen Dateien werden ersetzt.",
	t3203: "Wählen Sie die zu ersetzende(n) Datei(en) aus",
	t3204: "Bilddateien",
	t3205: "können nicht bearbeitet werden. Die Datei hat ein ungültiges Format.",
	t3206: "{{amount}} Datei(en) erfolgreich ersetzt",
	t3207: "{{amount}} Datei(en) konnten nicht ersetzt werden",
	t3208: "Die Ansicht hat keinen Hintergrund",
	t3209: "Sie können ein neues Hintergrundbild hochladen oder ein vorhandenes ersetzen",
	t3210: "Wenn Sie ein Hintergrundbild ersetzen, können die Parameterpositionen beibehalten werden, wenn:",
	t3211: "Eine PNG- oder JPG-Datei hat dieselbe Auflösung wie der aktuelle Hintergrund",
	t3212: "Eine SVG-Datei hat das gleiche Seitenverhältnis (aber nicht unbedingt die gleiche Auflösung)",
	t3213: "Funktion ist deaktiviert",
	t3214: "Zeigt derzeit den zuletzt empfangenen Wert an, wird aktualisiert ...",
	t3215: "Hochladen einer Grafikdatei, die in",
	t3216: "(optional)",
	t3217: "Hintergrundbild",
	t3218: "Durchsuche",
	t3219: "Mehr...",
	t3220: "Fügen Sie ein Hintergrundbild hinzu (optional)",
	t3221: "Grafikdatei",
	t3222: "Timeout in der Kommunikation",
	t3223: "Keine Kühlpläne konfiguriert",
	t3224: "Index",
	t3225: "Typ",
	t3226: "Ferien",
	t3227: "Tage",
	t3228: "Ein",
	t3229: "Aus",
	t3230: "ID",
	t3231: "Offen",
	t3232: "Start",
	t3233: "Ende",
	t3234: "Beschreibung",
	t3235: "Tag",
	t3236: "Beende",
	t3237: "EDF-Datei(en) erfolgreich hochgeladen. Neustart des Systemmanagers",
	t3238: "Hochladen der EDF-Datei(en) abgeschlossen, Zurücksetzen des Systemmanagers erfolgreich",
	t3239: "Fehler beim Abrufen der edf-Datei(en) vom Systemmanager",
	t3240: "Hochladen der EDF-Datei(en) fehlgeschlagen. Versuchen Sie es nach einiger Zeit",
	t3241: "Keine Kühlpläne konfiguriert",
	t3242: "Datei in den Systemmanager hochladen",
	t3243: "Geben Sie den Gerätetyp an - wirkt sich auf die erforderlichen Parameter-Tags aus, um es zu betreiben",
	t3244: "Legen Sie fest, wie auf Werte des Geräts zugegriffen wird und welche Funktion sie erfüllen",
	t3245: "Erstellen Sie einen Alarm basierend auf einem kleinen Parameter",
	t3246: "Etiketten",
	t3247: "Erstellen Sie eine Liste mit Bezeichnungen, die einem Parameter zugewiesen werden können",
	t3248: "Gruppen",
	t3249: "Parametergruppen angeben",
	t3250: "Registriernummer",
	t3251: "Datentyp",
	t3252: "Liste der Etiketten",
	t3253: "Minimum",
	t3254: "Standard",
	t3255: "Maximum",
	t3256: "Registertyp",
	t3257: "Der Registertyp wird in entsprechende Funktionscodes zum Lesen und Schreiben übersetzt",
	t3258: "Swap-Modus",
	t3259: "Bei einem an das Gerät gesendeten Wert mit Bytes ABCD tauscht diese Option Bytes (BADC), Wörter (CDAB) oder beides (DCBA).",
	t3260: "Nur lesen",
	t3261: "Protokollierung",
	t3262: "Aktiviert die Verlaufsprotokollierung für diesen Parameter",
	t3263: "Text von Parameter in Alarm übernehmen",
	t3264: "Position des Alarmflags, gezählt ab dem niederwertigsten Bit des Parameters",
	t3265: "Alarm bei niedrigem Wert auslösen",
	t3266: "Etikett",
	t3267: "Ausblenden",
	t3268: "{{field}} ist ein Pflichtfeld.",
	t3269: "Das Maximum muss größer oder gleich dem Minimum sein",
	t3270: "Der Standardwert muss in [Minimum, Maximum] liegen",
	t3271: "Der Datentyp muss {{reg}} für den ausgewählten Registertyp {{mt}} sein",
	t3272: "Name darf keine Trennzeichen enthalten",
	t3273: "Der Name darf nicht länger als 20 Zeichen sein",
	t3274: "Liste der Labels darf nicht leer sein",
	t3275: "Die Tag(s) {{taglist}} müssen genau einmal vergeben werden.",
	t3276: "Die Gruppe(n) {{grouplist}} enthalten mehr als das Maximum von 50 Parametern.",
	t3277: "Das Formular enthält Fehler. Bitte überprüfen Sie die Fehler und versuchen Sie es erneut.",
	t3278: "Absenden",
	t3279: "Um das hochgeladene EDF zu aktivieren, müssen Sie das Gerät zurücksetzen. Möchten Sie das Gerät jetzt zurücksetzen?",
	t3280: "Jetzt zurücksetzen",
	t3281: "Später zurücksetzen",
	t3282: "{{file}} existiert bereits. Willst du es überschreiben?",
	t3283: "Upload {{what}}",
	t3284: "Labels und Werte müssen eindeutig sein.",
	t3285: "Multi",
	t3286: "Gerät verarbeitet mehrere Kühlmöbel oder Packungen",
	t3287: "Tags",
	t3288: "Internet Explorer ist nicht mehr verfügbar und wird von StoreviewWeb nicht unterstützt",
	t3289: "Bitte wechseln Sie zu einem unterstützten Browser, entweder Edge, Chrome oder Firefox",
	t3290: "Mehr Informationen",
	t3291: "Schließen",
	t3292: "Fehler beim Lesen der Daten, bitte versuchen Sie es erneut",
	t3293: "Laufende Backup-EDFs",
	t3294: "Es sind keine Ansichten zum Migrieren vorhanden",
	t3295: "Das System wird mit einer öffentlichen IP im privaten IP-Feld konfiguriert",
	t3296: "Das System ist mit einer privaten IP im öffentlichen IP-Feld konfiguriert, das System ist möglicherweise nicht aus der Ferne zugänglich oder einige Funktionen funktionieren möglicherweise nicht richtig",
	t3297: "Sie sind über eine IP oder einen Hostnamen verbunden, der dem System Manager nicht bekannt ist, wahrscheinlich über einen Proxy-Dienst, einige Funktionen funktionieren möglicherweise nicht richtig",
	t3298: "Das Gerät ist offline und zeigt den letzten bekannten Wert an",
	t3299: "Integration von Geräten",
	t3300: "Die Tags {{taglist}} werden dupliziert.",
	t3301: "Nicht gefunden",
	t3302: "Ausgewählter Bereich wurde nicht gefunden",
	t3303: "Fehlercode: 404",
	t3304: "Zum Dashboard",
	t3305: "Nur anschauen",
	t3306: "Interne IP-Adresse",
	t3307: "Externe IP-Adresse",
	t3308: "Domänenname",
	t3309: "Keine Warnungen",
	t3310: "Mehrere Warnungen",
	t3311: "Es sieht so aus, als ob das System mit mehreren Einheiten konfiguriert ist, aber wir konnten eine oder mehrere der Slave-Einheiten nicht kontaktieren. Bitte stellen Sie sicher, dass die Einstellungen korrekt sind. Bitte beachten Sie, dass eine Einrichtung mit mehreren Einheiten nicht mehrere Domänennamen unterstützt und dass jede Einheit mit einem eigenen Port konfiguriert werden muss, wenn dieselbe Domäne verwendet wird",
	t3312: "Nur anschauen",
	t3313: "Dieses Element ist keinem Parameter zugeordnet und kann sicher entfernt werden",
	t3314: "Parameter entfernen",
	t3315: "Zeitlicher Ablauf",
	t3316: "Sie werden nun abgemeldet und müssen sich mit dem neuen Passwort anmelden",
	t759: "Datenbank heruntergeladen, Gerät zurücksetzen und aktualisieren. Dies kann einige Minuten dauern.",
	t816: "Alarmtyp:",
	t818: "Anzahl :",
	t848: "DB-Datei wird nicht erstellt. Sie haben eingeschränkten Zugriff auf das installierte Verzeichnis. Bitte wenden Sie sich an den Systemadministrator.",
	t3317: "Keine Zeitpläne zum Exportieren",
	t3318: "Ausstehende Antwort vom System",
	t3319: "Keine Netzwerkaktivität",
	t3320: "Geschäftsname",
	t3321: "Neue Version {{latestVersion}} online verfügbar",
	t3322: "Externer Port",
	t3323: "Interner Port",
	t3324: "Wir können nicht mit dem Systemmanager kommunizieren",
	t3325: "Vielleicht muss dem Zertifikat vertraut werden?",
	t3326: "Datei dieses Formats kann nicht entfernt werden",
	t3327: "Verwendung",
	t3328: "Einschaltzeit",
	t3329: "Aus-Zeit",
	t3330: "Wochentage",
	t3331: "Kühlstufe",
	t3332: "Wärmerückgewinnung",
	t3333: "Kühlung",
	t3334: "Heizen",
	t3335: "Zusatzheizung",
	t3336: "Entfeuchtung",
	t3337: "Internet",
	t3338: "Nachrichten",
	t3339: "Kontakte",
	t3340: "Komm",
	t3341: "Keine Elemente gefunden",
	t3342: "Tippe um zu suchen",
	t3408: "Alle aktivieren",
	t3409: "Alle deaktivieren",
	t3410: "Normal",
	t3411: "Schwer",
	t3412: "Kritisch",
	t3413: "Alle Alarme aktivieren",
	t3414: "Suche nach Alarmen",
	t3415: "Ermöglicht",
	t3417: "Fehler beim Abrufen der Alarme",
	t3418: "Fehler beim Abrufen der zu kopierenden Geräte",
	t3419: "Fehler beim Prüfen des Kopierstatus",
	t3420: "Die automatische Abfrage wird nach maximal 2 Stunden Timeout fortgesetzt oder wenn der Assistent abgebrochen oder beendet wird",
	t3421: "Auf Controller kopieren",
	t3422: "Auf das/die ausgewählte(n) Gerät(e) kopieren",
	t3423: "Die Kopierfunktion kopiert zuerst die Einstellungen in die System Manager-Datenbank und lädt sie dann auf die Controller-Geräte herunter. Es kann einige Zeit dauern, bis der Vorgang abgeschlossen ist.",
	t3424: "Keine Geräte zum Kopieren ausgewählt",
	t3425: "Doppelklicken zum Kopieren",
	t3426: "Fehler beim Abrufen der Geräte",
	t3427: "Geräte zum Kopieren",
	t3428: "Wählen Sie das/die zu kopierende(n) Gerät(e) aus",
	t3429: "Hier werden nur Controller desselben Typs angezeigt",
	t3430: "Kopiervorgang erfolgreich",
	t3431: "Ungültige Datei. Erwartetes Dateimodell:",
	t3432: "Fehler beim Laden der Einstellungsdatei",
	t3435: "Möchten Sie das ausgewählte Gerät wirklich in diese Gruppe {{toPackGroup}} verschieben?",
	t3343: "Entdeckung",
	t3344: "Tabelle aktualisieren",
	t3345: "Netzwerke werden gescannt, bitte warten …",
	t3346: "Die Netzwerktabelle wird aktualisiert",
	t3347: "Fehler beim Aktualisieren der Netzwerke",
	t3348: "Fehler beim erneuten Scannen von Netzwerken",
	t3349: "Fehler beim Abrufen der Netzwerke",
	t3350: "Netzwerkscan abgeschlossen",
	t3351: "Keine Geräte verfügbar",
	t3352: "Netzwerk scannen",
	t3353: "Uncfg",
	t3354: "Jede Neuanordnung der Geräte im Assistenten kann sich auf die Alsense-Überwachungsdienste auswirken.",
	t3355: "Netzwerk",
	t3356: "Feldbustyp auswählen",
	t3357: "Wenn Modbus/RS485 aktiviert ist, können Sie SLV für den Kompressor mit variabler Drehzahl von Danfoss auswählen. Wenn Sie SNMP als lokalen Bus auswählen, müssen Sie von den Danfoss SNMP-Steuerungen einen gültigen Subnetzadressbereich eingeben.",
	t3358: "Kompressoren/Packs",
	t3359: "Kompressoren/Aggregate-Steuerungen verwalten",
	t3360: "Hier können nur Pack-Geräte verwaltet werden, kein Rack-E/A-System. Kompatible Multi-Suction Pack Controller, die gescannt wurden, werden zunächst als 0 Sauggruppen angezeigt. Zum Konfigurieren des Pack Controllers ist das AK-ST 500 (Service Tool) erforderlich.",
	t3361: "Zu kopierender Betrag",
	t3362: "Durch die Reduzierung der zuvor konfigurierten Sauggruppe werden auch alle zugehörigen Verdunstungssteuerungen für diese Sauggruppe entfernt.",
	t3363: "Die Anzahl der Sauggruppen darf nicht Null sein, die Mindestanzahl der Sauggruppen sollte mindestens Eins betragen.",
	t3364: "Fehler beim Abrufen der Kompressoren/Pakete",
	t3365: "Keine Kompressoren/Packs verfügbar",
	t3366: "Online-Geräte",
	t3367: "Offline-Geräte",
	t3369: "Paket hinzufügen",
	t3370: "Wenn Sie Modbus/RS485 auswählen, werden Ihnen zusätzliche Optionen für SLV/ECFan angezeigt. Diese werden zur Kühlung verwendet und können auf „Nein“ gesetzt werden.",
	t3371: "Schaltkreis hinzufügen",
	t3372: "Geräte hinzufügen, kopieren oder entfernen. Beachten Sie, dass nicht bei allen Gerätetypen die Bearbeitung aller Felder möglich ist und die Adressen der E/A-Punkte in der Konfiguration festgelegt werden.",
	t3373: "Fallcontroller verwalten",
	t3374: "Gerät hinzufügen",
	t3375: "Verwalten von Kompressor-/Pack-Controllern",
	t3376: "Fehler beim Datenzugriff",
	t3377: "HVAC Layout wurde erfolgreich angewendet",
	t3378: "Layout HLK",
	t3379: "Möchten Sie diese Seite wirklich verlassen?",
	t3380: "Ihre Einstellungen gehen möglicherweise verloren",
	t3381: "Bitte konfigurieren Sie ein Gerät",
	t3382: "Beenden der Schnelleinrichtung",
	t3383: "Möchten Sie die Schnelleinrichtung wirklich beenden?",
	t3384: "Gateways (AK-PI200)",
	t3385: "Wählen Sie die gefundenen Gateways aus",
	t3386: "IP Adresse",
	t3387: "Quellcontroller",
	t3388: "Quelleneinstellungen",
	t3389: "Quellenalarme",
	t3390: "Quelldiagramm",
	t3392: "Grafik",
	t3393: "Geräte",
	t3394: "Die automatische Abfrage wird während der Kopierphase ausgesetzt. Der Hauptschalter der Quell- und Zielgeräte wird ausgeschaltet und nach Abschluss des Kopiervorgangs in den ursprünglichen Zustand zurückversetzt. Überprüfen Sie unbedingt den Hauptschalter, nachdem der Kopierassistent abgeschlossen ist. Wenn eines der ausgewählten Geräte einen aktiven Alarm hat, werden Änderungen an der Alarmaktion für diesen bestimmten Punkt nicht geändert. Die ursprünglichen Einstellungen bleiben erhalten.",
	t3395: "Wählen Sie ein Quellgerät aus, von dem die Einstellungen kopiert werden sollen",
	t3396: "Rechtzeitig",
	t3397: "Die Kopierfunktion kopiert verfügbare Diagrammpunkte, bis die Gerätekapazität erreicht ist. Gehen Sie zur Konfiguration, um die Anzahl der definierten Punkte anzuzeigen.",
	t3398: "Quellgeräte-Grafiken",
	t3399: "Konfigurieren Sie Diagrammpunkte für den Quellcontroller",
	t3400: "Aktivieren Sie HACCP, um die Punkte in einem HACCP-Temperaturbericht zu protokollieren",
	t3401: "Suche nach Punkten",
	t3402: "Keine Daten verfügbar",
	t3403: "Fehler beim Abrufen der Diagrammpunkte",
	t3404: "Deaktiviert",
	t3405: "Quellgerätealarme",
	t3406: "Konfigurieren Sie Alarmdetails im Quellcontroller",
	t3407: "Alarmname",
	t3436: "Das Gerät wird aus der zugewiesenen Gruppe entfernt [ {{fromPackGroup}} ]",
	t3437: "Layout Kühlung",
	t3438: "Das Kühlkonzept wurde erfolgreich umgesetzt",
	t3439: "Neu zuweisen",
	t3440: "Zuweisen von Fallkontrollen zu Packungskontrollen",
	t3441: "Weisen Sie den Kompressor-/Pack-Bedienelementen Case-Bedienelemente (aus verfügbaren Cases) zu. Bilden von Sauggruppen",
	t3442: "Geräteparameter",
	t3443: "Parameter zum Kopieren auswählen",
	t3444: "Wenn Sie an einem Live-Gerät (Online-Gerät) arbeiten, kann es aufgrund der Kommunikation mit dem Gerät zu einer Verzögerung bei den Einstellungen kommen.",
	t3445: "Alarme zum Kopieren auswählen",
	t3446: "Gerätediagramm",
	t3447: "Diagrammpunkte zum Kopieren auswählen",
	t3448: "Aktuelle Geräteeinstellungen in einer Datei speichern",
	t3449: "Sie können die aktuellen Geräteeinstellungen in einer Datei speichern, die Sie später zum Konfigurieren von Geräten verwenden können. Wenn Sie den Assistenten nach dem Speichern der Datei beenden möchten, verwenden Sie das Symbol „Beenden“. Wenn Sie mit angeschlossenen Geräten arbeiten, stellt das Beenden sicher, dass die Netzwerkabfrage und der Hauptschalter des Geräts in den richtigen Status zurückkehren.",
	t3450: "Einstellungsdatei(en) erfolgreich gespeichert",
	t3451: "Möchten Sie die Schnelleinrichtung fortsetzen oder beenden?",
	t3452: "Datei(en) speichern",
	t3453: "Fehler beim Abrufen der Einstellungen",
	t3454: "Quellgeräteeinstellungen",
	t3455: "Konfigurieren Sie Geräteparameter im Quellcontroller",
	t3456: "Keines der Passwortfelder darf leer sein",
	t3457: "Aktuelles",
	t3458: "Adressbuch",
	t3459: "Zuletzt aufgerufen",
	t3460: "Bitte erstellen Sie regelmäßig eine Sicherungskopie des Adressbuchs.",
	t3461: "Das Adressbuch wurde erfolgreich importiert",
	t3462: "importiere Adressbuch",
	t3463: "exportiere Adressbuch",
	t3464: "Standort hinzufügen",
	t3465: "Site bearbeiten",
	t3466: "Ort löschen",
	t3467: "Sie müssen ein Adressbuch-Passwort erstellen, bevor Sie das Adressbuch verwenden können.",
	t3468: "Die Site wurde erfolgreich zu Ihrem Adressbuch hinzugefügt.",
	t3469: "Bereits im Adressbuch",
	t3470: "Zum Adressbuch hinzufügen",
	t3471: "Melden Sie sich bei der Site an",
	t3472: "Die Site-Informationen wurden erfolgreich aktualisiert.",
	t3474: "Benachrichtigung",
	t3475: "Die Site wurde erfolgreich aus Ihrem Adressbuch entfernt.",
	t3476: "Die Site wurde erfolgreich aktualisiert.",
	t3477: "Ungültiges Adressbuchformat",
	t3478: "Aktionen",
	t3479: "Offline nicht verfügbar",
	t3480: "S55-Datenbank konnte nicht geladen werden",
	t3481: "S55-Datenbank wird geladen",
	t3482: "Protokoll",
	t3483: "Der Benutzer hat keine Berechtigung, Softwareinformationen anzuzeigen.",
	t3484: "Das Passwort darf nur alphanumerisch oder numerisch sein",
	t3485: "Sie müssen ein Adressbuch-Passwort erstellen, um auf das Adressbuch zugreifen zu können",
	t3486: "Das Adressbuchkennwort ist nicht korrekt.",
	t3487: "Die Site ist bereits im Adressbuch vorhanden",
	t3488: "Erstellen Sie ein Adressbuchkennwort, um das Adressbuch zu verwenden. Das Kennwort darf nur alphanumerisch oder numerisch sein",
	t3489: "Geben Sie Ihr Adressbuch-Passwort ein",
	t3490: "Keine Online-Geräte zum Kopieren verfügbar",
	t3491: "Adressbuchkennwort erstellen",
	t3492: "Adressbuchkennwort aktualisieren",
	t3493: "Das Site-Passwort darf nicht leer sein",
	t3494: "Geben Sie ein Passwort für das zu importierende Adressbuch ein.",
	t3495: "Das Adressbuchkennwort wurde erfolgreich erstellt",
	t3496: "Das Adressbuchkennwort wurde erfolgreich aktualisiert",
	t3497: "Das Kennwort des zu importierenden Adressbuchs ist nicht korrekt.",
	t3498: "Bitte geben Sie das Site-Passwort ein",
	t3499: "Adressbuch löschen",
	t3500: "Suchseiten",
	t3501: "Wählen Sie die zu importierende Adressbuchdatei aus",
	t3502: "Wählen Sie die Schlüsseldatei zum Entschlüsseln des Adressbuchs aus",
	t3503: "Klicken Sie auf „Exportieren“, um das verschlüsselte Adressbuch herunterzuladen",
	t3505: "Mit verschlüsselter",
	t3506: "unverschlüsselte Passwörter",
	t3507: "Ungültiger geheimer Schlüssel",
	t3508: "Importfehler. Stellen Sie sicher, dass Sie den richtigen Schlüssel importiert haben.",
	t3509: "Keine verfügbaren Versionen",
	t3510: "Öffnen Sie Alsense Services (erfordert Abonnement)",
	t3511: "Bitte lesen Sie die EULA-Bedingungen sorgfältig durch. Wenn Sie die Bedingungen nicht akzeptieren, dürfen Sie die Anwendung nicht verwenden.",
	t3512: "Adressbuch und Ihr Adressbuch-Passwort wurden erfolgreich gelöscht",
	t3513: "(erforderlich)",
	t3515: "Das Adressbuch ist leer",
	t3516: "Das Adressbuchkennwort darf nicht leer sein",
	t3519: "Durch das Löschen des Adressbuchs wird auch Ihr Adressbuchkennwort entfernt.",
	t3520: "Nicht im Bereich. Sollte zwischen 0 und {{maxAddr}} liegen.",
	t3521: "Klicken Sie auf „Exportieren“, um alle im System Manager gespeicherten Grafikansichten herunterzuladen",
	t3522: "Klicken Sie auf „Herunterladen“, um einen Systembericht herunterzuladen",
	t3523: "Über eine direkte WLAN-Verbindung ist nur die Master-Einheit verfügbar",
	t3524: "Es gibt keine lokalen Grafikansichten. Neue Ansichten hinzufügen oder vorhandene importieren",
	t3525: "Es sind keine Remote-Grafikansichten vorhanden. Versuchen Sie die Suche mit einem anderen Namen",
	t3526: "Es gibt keine Remote-Grafikansichten. Neue Ansichten hinzufügen, importieren oder vorhandene Ansichten migrieren",
	t3527: "Lokale Ansichten ( {{totalFiles}} von 5)",
	t3528: "{{totalFiles}} lokale Grafiken von maximal 5",
	t3529: "Remote-Ansichten",
	t3530: "Die Datei ist kein Bitmap.",
	t3531: "Falsche Bitmaplänge.",
	t3532: "Falsche Bitmap-Headergröße.",
	t3533: "Falsche Bitmap-Größe.",
	t3534: "Fehler bei der Palettengröße.",
	t3535: "Inkompatible BMP-Datei",
	t3536: "Fehler beim Löschen der lokalen Grafikdatei",
	t3537: "Fehler beim Duplizieren der lokalen Grafikdatei",
	t3538: "Fehler beim Exportieren der lokalen Grafikdatei",
	t3539: "Grafik löschen",
	t3540: "Löschen einer lokalen Grafikdatei",
	t3541: "Das Löschen der lokalen Grafikdatei wird durchgeführt",
	t3542: "Lokale Grafikdatei erfolgreich gelöscht. System Manager wird neu gestartet.",
	t3543: "Löschen der lokalen Grafikdatei abgeschlossen. System Manager erfolgreich neu gestartet",
	t3544: "Grafik duplizieren",
	t3545: "Duplizieren einer lokalen Grafikdatei",
	t3546: "Die lokale Grafikdatei wird dupliziert.",
	t3547: "Lokale Grafikdatei erfolgreich dupliziert. System Manager wird neu gestartet",
	t3548: "Lokale Grafikdatei dupliziert. System Manager erfolgreich neu gestartet",
	t3549: "Grafik exportieren",
	t3550: "Exportieren einer lokalen Grafikdatei",
	t3551: "Der lokale Grafikdateiexport wird ausgeführt",
	t3552: "Lokale Grafikdatei erfolgreich exportiert",
	t3553: "Der Systemmanager wird bei dieser Aktion neu gestartet",
	t3554: "Möchten Sie wirklich löschen",
	t3555: "Möchten Sie wirklich duplizieren",
	t3556: "Klicken Sie auf „Exportieren“, um alle im System Manager gespeicherten lokalen Grafikansichten herunterzuladen",
	t3557: "Der Export lokaler Grafikdateien wird ausgeführt",
	t3558: "Lokale Grafikdateien erfolgreich exportiert",
	t3559: "Produktcode",
	t3560: "Erfahren Sie mehr über dieses Produkt",
	t3561: "Lüfter Öko",
	t3562: "Ventilöffnung",
	t3563: "Überhitzung",
	t3564: "Verdampferdruck",
	t3565: "Verdampfertemperatur",
	t3566: "Ausgeschnitten",
	t3567: "Schnitt",
	t3568: "Gehäusetür",
	t3569: "Saugtemperatur",
	t3570: "Schienenwärme",
	t3571: "Maximal zulässiges Dateilimit erreicht. Auswahl der zu importierenden Dateien aufheben",
	t3572: "Bitte löschen Sie vorhandene Dateien im Systemmanager, um Dateien zu importieren",
	t3573: "Lokale Grafikdatei erfolgreich importiert. System Manager wird neu gestartet.",
	t3574: "Import der lokalen Grafikdatei(en) abgeschlossen. System Manager erfolgreich neu gestartet",
	t3575: "Maximal zulässiges Dateilimit erreicht. Zu importierende Datei(en) löschen",
	t3576: "Maximal zulässige Grafikdateien sind 5",
	t3577: "Lokale Grafikdatei erfolgreich hochgeladen. System Manager wird neu gestartet.",
	t3578: "Lokaler Grafikdatei-Upload abgeschlossen. System Manager erfolgreich neu gestartet",
	t3579: "Ungültiges Dateiformat. {{supportedFileFormats}} -Datei hochladen.",
	t3580: "Die Dateigröße muss für lokale Bildschirme {{maxWidthAllowed}} x {{maxHeightAllowed}} Pixel betragen.",
	t3581: "Fernansicht",
	t3582: "Lokale Ansicht",
	t3583: "Storeview Web-Startseite",
	t3584: "EULA-Bedingungen",
	t3585: "Sicherheit",
	t3586: "Sicherheitserzwingungsebenen",
	t3587: "Abwärtskompatibel (niedrigste Sicherheitsstufe – vorausgesetzt, die Verwendung der Header-Authentifizierung ist deaktiviert)",
	t3588: "SvW/XML-Anfragen enthalten Benutzernamen und Passwort.",
	t3589: "Keine Bereinigung von Anfragen, gleiche Sicherheit wie in früheren Versionen",
	t3590: "Keine Änderungen an SvW oder vorhandenen XML-Verbindungen erforderlich",
	t3591: "Permissiv (kann als Übergang zur strengen Ebene verwendet werden)",
	t3592: "XML-Anfragen können Benutzernamen und Passwort und/oder ein Sitzungstoken enthalten. Das Sitzungstoken wird in der Antwort der Anmeldeanfrage empfangen.",
	t3593: "Wenn das Sitzungstoken im AKSM-Auth-Header angegeben ist, werden Benutzernamen und Passwörter ignoriert",
	t3594: "Die XML-Anfrage wird bereinigt. Bei erkannten Problemen wird in der Antwort auf die Anfrage eine Warnung zurückgegeben.",
	t3595: "Beachten Sie diese Warnungen bei der Vorbereitung auf den Wechsel in den strikten Modus",
	t3596: "Wenn das Sitzungstoken nicht verwendet wird, kann das Ablaufdatum der Sitzung nicht angewendet werden",
	t3597: "Wird bereits in der Remote-Benutzeroberfläche (SvW) unterstützt",
	t3598: "Mögliche/Erforderliche Änderungen aus der Sicht von 3rd Party XML",
	t3599: "Keine Verwendung von Sitzungstoken – keine Änderungen erforderlich",
	t3600: "Verwendung von Sitzungstoken - Entfernen Sie Benutzernamen und Passwort aus dem Textkörper und beachten Sie die Warnungen zur Vorbereitung auf Strict",
	t3601: "Streng (höchste Sicherheitsstufe)",
	t3602: "XML-Anfragen dürfen keine Benutzernamen und Passwörter enthalten, müssen aber das Sitzungstoken im AKSM-Auth-Header angeben. Wenn in der Anfrage ein Problem festgestellt wird, wird sie abgelehnt.",
	t3603: "Bereits unterstützt in (SvW)",
	t3604: "Erforderliche Änderungen aus Sicht von Drittanbietern",
	t3605: "Benutzername und Passwort müssen aus der XML-Anforderungsnutzlast entfernt werden und das Sitzungstoken muss in einem CORS-Header namens AKSM-auth platziert werden.",
	t3606: "Backoff bei fehlgeschlagener Anmeldung",
	t3607: "Wie lange (Sekunden) ein Remote-Benutzer wartet, bis er nach einem fehlgeschlagenen Versuch erneut versuchen kann, sich anzumelden. Die Wartezeit erhöht sich kumulativ mit jedem fehlgeschlagenen Versuch. Nach 10 Versuchen wird die IP, von der aus der Benutzer versucht hat, sich anzumelden, für 24 Stunden gesperrt. Wenn der Wert auf 0 gesetzt ist, ist die Zurücksetzung bei fehlgeschlagenen Anmeldungen deaktiviert",
	t3608: "Standardmäßig 30 s (max. 600 s / 0 = deaktiviert)",
	t3609: "Freie Versuche",
	t3610: "Wie viele Anmeldeversuche sind zulässig, bevor ein Backoff angewendet wird?",
	t3611: "Standardmäßig 2 (max. 5)",
	t3612: "Block",
	t3613: "Wenn aktiviert, werden Benutzer + IP während des Backoffs blockiert, andernfalls wird der Benutzer blockiert",
	t3614: "Standardmäßig „Benutzer“ (Benutzer + IP / Benutzer)",
	t3615: "Sitzungen pro Benutzer",
	t3616: "Eine Sitzung liegt vor, wenn sich ein Benutzer erfolgreich bei der Einheit angemeldet hat und sich innerhalb der Remote-Schnittstelle befindet (SvW).",
	t3617: "Anzahl der aktiven Sitzungen, die ein Benutzer haben kann. Wenn der Wert auf 0 gesetzt ist, kann ein Benutzer die maximale Anzahl an Sitzungen haben (30).",
	t3618: "Standardmäßig 0",
	t3619: "Ablauf der Sitzung",
	t3620: "Wie viele Sekunden nach Ablauf einer inaktiven Sitzung. Wenn eine Sitzung abläuft, ist eine erneute Anmeldung erforderlich",
	t3621: "Standardmäßig 900 s (max. 3.600)",
	t3622: "Header-Authentifizierung",
	t3623: "Wenn HTTPS und Header-Authentifizierung aktiviert sind, müssen alle XML-Anfragen von Drittanbietern Benutzernamen/Passwort-Anmeldeinformationen im Header übergeben (einfaches Authentifizierungsschema).",
	t3624: "Das Aktivieren der Funktion „Header-Authentifizierung verwenden“ erfordert eine Neuinitialisierung des Systems. Verwenden Sie dazu die Schaltfläche „Zum Initialisieren drücken“.",
	t3625: "Klein",
	t3626: "Groß",
	t3627: "Grafik speichern",
	t3628: "Speichern einer lokalen Grafikdatei",
	t3629: "Lokale Grafikdatei erfolgreich gelöscht. Lokale Grafikdatei wird geladen",
	t3630: "Lokale Grafikdatei wurde erfolgreich geladen. System Manager wird neu gestartet",
	t3631: "Lokales Speichern der Grafikdatei abgeschlossen. System Manager erfolgreich neu gestartet",
	t3632: "Möchten Sie wirklich speichern?",
	t3633: "Analyse fehlgeschlagen",
	t3634: "Visualisierungsdatei konnte nicht analysiert werden",
	t3635: "Die vz2-Datei konnte nicht analysiert werden",
	t3636: "Öffnen der Viz-Datei fehlgeschlagen:",
	t3637: "Die Migrationsdateien (vz2, dpj oder json) konnten nicht gefunden werden",
	t3638: "Die Migrationsdateien (viz) konnten nicht gefunden werden",
	t3639: "Der Systemmanager wird bei dieser Aktion neu gestartet. Der Systemmanager befindet sich entweder im permissiven oder strikten Modus, sodass das Sitzungstoken beim Neustart abläuft und Sie vom System abgemeldet werden.",
	t3643: "Abgabedruck",
	t3644: "Gesamt-kW",
	t3645: "Gesamt-KVAR",
	t3646: "Gesamt Kvarh+",
	t3647: "Nachfrage",
	t3648: "Leistungsfaktor",
	t3649: "Schriftfarbe",
	t3650: "Hintergrundfarbe",
	t3651: "Adressbuch wurde erfolgreich exportiert",
	t3652: "Steuerungsmodus",
	t3653: "Sc3 Außentemperatur",
	t3654: "Entladetemperatur",
	t3655: "Kondensator-Laufleistung",
	t3656: "Keine zu exportierenden Daten",
	t3657: "Das Passwort des Benutzers für {{unit}} ist abgelaufen",
	t3658: "Mit den angegebenen Anmeldeinformationen ist eine Anmeldung bei {{unit}} nicht möglich.",
	t3659: "Aktualisieren Sie die Firmware-Version oder ändern Sie die Sicherheitsstufe für {{unit}} um Unterstützung für Sitzungstoken zu erhalten.",
	t3661: "V3gc",
	t3662: "Abschnitt B",
	t3663: "Abschnitt C",
	t3664: "Adaptive Überhitzungsregelung",
	t3665: "Kontrollstatusgrund",
	t3666: "Der Benutzer kann nicht angemeldet werden. Die Geräteadresse ist in der Proxy-Konfiguration nicht festgelegt.",
	t3667: "Das Kommunikationsprotokoll ist in der Proxy-Konfiguration nicht angegeben. Dies kann zu Fehlern führen",
	t3668: "Informationen zur IT-Sicherheit",
	t3669: "Zur Einhaltung von Sicherheitsstandards ist der System Manager jetzt standardmäßig auf eine strenge Sitzungskontrolle eingestellt. Dies bedeutet, dass Remoteverbindungen und XML-basierte Remotedienste nur noch funktionieren, wenn Sitzungstoken angewendet werden.",
	t3670: "Danfoss empfiehlt, im strengen Modus fortzufahren, der Abwärtskompatibilitätsmodus ist jedoch weiterhin verfügbar.",
	t3671: "Beachten Sie, dass dies im Menü „Konfigurationssicherheit“ geändert werden kann und keine Auswirkungen auf die lokale Steuerung des System Managers und den Site-Betrieb hat.",
	t3672: "Weitere Informationen zur Sitzungssteuerung",
	t3673: "Rückwärtsmodus",
	t3674: "Strikter Modus",
	t3675: "Wenn Sie auf den abwärtskompatiblen Modus klicken, werden alle anwendbaren Systemmanager mit aktualisierter Firmware in den abwärtskompatiblen Modus versetzt, neu gestartet und die Anwendung wird abgemeldet.",
	t3676: "Die maximale Anzahl zulässiger Sauggruppen beträgt 12",
	t3677: "Nach erfolgreicher Aktualisierung der ausgewählten Einheiten von 3.3.0 auf 4.X oder höher wird der Benutzer im letzten Schritt automatisch abgemeldet, wenn er auf die Schaltfläche „Fertig stellen“ klickt.",
	t3678: "Multicases für AK2 Controller (Pack/Case) werden nicht unterstützt",
	t3679: "In Übereinstimmung mit den mit Version 4.0 eingeführten Sicherheitsstandards wird der System Manager standardmäßig auf strikte Sitzungskontrolle eingestellt, was bedeutet, dass jede Remote-Verbindung/jeder XML-basierte Remote-Dienst nicht mehr funktioniert, wenn keine Sitzungstoken angewendet werden. Außerdem wird die Kommunikation durch das Upgrade auf Version 4.0 automatisch auf HTTPS umgestellt. Danfoss empfiehlt, im strikten Modus fortzufahren, es ist jedoch ein Abwärtskompatibilitätsmodus verfügbar, der das System auf die bis jetzt verwendeten Einstellungen zurücksetzt. Bitte beachten Sie: Wenn Sie dieses Update remote auf einer HTTP-Einheit durchführen, stellen Sie bitte sicher, dass Ihr IT-Setup für HTTPS (Port 443) vorbereitet ist oder dass Personal vor Ort verfügbar ist.",
	t3680: "Weitere Informationen zur neuen Sicherheitsimplementierung finden Sie hier",
	t3681: "Der Zugriff auf die HTTP-Version dieser Site kann aufgrund von Browsereinschränkungen blockiert sein. Besuchen Sie unsere FAQ-Seite, um Anweisungen zur Lösung dieses Problems zu erhalten, oder laden Sie die Desktopversion von StoreView Web herunter.",
	t3682: "Häufig gestellte Fragen zum Systemmanager",
	t3683: "u17 Ther. Luft",
	t3684: "u91 Abschalttemp.",
	t3685: "u00 Steuerzustand",
	t3686: "u12 S3 Lufttemp.",
	t3687: "u16 S4 Lufttemp.",
	t3688: "u23 EEV AD %",
	t3689: "u21 Überhitzung",
	t3690: "u22 Überhitzungsreferenz",
	t3691: "u26 EvapTemp Te",
	t3692: "S2 Gasaustrittstemp.",
	t3693: "Stw8",
	t3694: "Sd-MT",
	t3695: "Tw-Status",
	t3696: "Tw aktivieren",
	t3697: "Zweipumpen laufen",
	t3698: "Stw3",
	t3699: "Stw4",
	t3700: "Stw2",
	t3701: "Empfangskontrollstatus",
	t3702: "Saugversatz",
	t3703: "Vrec-Steuermodus",
	t3704: "Vrec-Status",
	t3705: "Strg-Statusgrund",
	t3706: "HP-Kontrollstatus",
	t3707: "Pgc max.",
	t3708: "PGC-Maximalgrenze P-Verbot",
	t3709: "PGC mindestens",
	t3710: "Dampfausstoßerkapazität",
	t3711: "Flüssigkeitsauswerferkapazität",
	t3712: "Referenzlufttemp.",
	t3713: "Zulufttemp.",
	t3714: "Rückluft",
	t3715: "Zonentaupunkt",
	t3716: "Zonenfeuchtigkeit",
	t3717: "Außentemperatur",
	t3718: "CO2-Gehalt der Zone",
	t3719: "Zonentemp.",
	t3720: "Bed.-Nr.",
	t3721: "Zustand der Leitf.-Kontrolle",
	t3722: "Zustand der Leitf.-Kontrolle",
	t3723: "Bed.-Strg.",
	t3724: "Bedingung: gewünschte Obergrenze.",
	t3725: "V3gc-Zustand",
	t3726: "V3gc OD",
	t3727: "Leit.temp.",
	t3728: "Personalstatus",
	t3729: "HR aktivieren",
	t3730: "HR-Consumer-Anfrage",
	t3731: "Shr3",
	t3732: "Shr4",
	t3733: "Shr2",
	t3734: "Shr8",
	t3735: "Saugseite siehe To-MT",
	t3736: "Kontrollstatus MT",
	t3737: "Tatsächliche Zone MT",
	t3738: "Zum opt. Offset MT",
	t3739: "Laufleistung MT",
	t3740: "Angeforderte Kapazität MT",
	t3741: "Zeit bis zum nächsten Schritt MT",
	t3742: "Po-MT",
	t3744: "Saugtemp. To-MT",
	t3745: "Gesamtwirkenergie",
	t3746: "Spitzenbedarf",
	t3747: "Gesamtleistungsfaktor",
	t3748: "Phase A Volt",
	t3749: "Phase B Volt",
	t3750: "Phase C Volt",
	t3751: "Phase A Verstärker",
	t3752: "Phase B Verstärker",
	t3753: "Phase C Verstärker",
	t3754: "Gesamtwirkleistung",
	t3755: "Saugtemp. To-LT",
	t3756: "Saugtemperatur To-IT",
	t3757: "Saugreferenz LT",
	t3758: "Kontrollstatus LT",
	t3759: "Tatsächliche Zone LT",
	t3760: "Zum opt. Offset LT",
	t3761: "Laufleistung LT",
	t3762: "Angeforderte Kapazität LT",
	t3763: "Zeit bis zum nächsten Schritt LT",
	t3764: "Po-LT",
	t3765: "Sd-LT",
	t3766: "Saugreferenz IT",
	t3767: "Kontrollstatus IT",
	t3768: "Tatsächliche Zone IT",
	t3769: "Laufleistung IT",
	t3770: "Angeforderte Kapazität IT",
	t3771: "Zeit für den nächsten IT-Schritt",
	t3772: "Tn Po-IT",
	t2773: "Sd-IT",
	t2774: "IT-Firmenstart",
	t3774: "Nicht im Bereich. Sollte zwischen {{minAddr}} und {{maxAddr}} liegen.",
	t3775: "Vorgang für dieses Gerät deaktiviert",
	t3776: "Nach dieser Aktion wird die Anwendung abgemeldet. Dies kann einige Augenblicke dauern.",
	t3777: "Die Migrationsdateien (flp oder json) konnten nicht gefunden werden",
	t3778: "Umfrage",
	t3779: "Die Stimmabgabe ist derzeit ausgesetzt.",
	t3780: "Möchten Sie die Umfrage fortsetzen?",
	t3781: "Energiezähler",
	t3782: "Mehrere Knoten ausgewählt",
	t3783: "Auf Knoten zoomen",
	t3784: "Maßeinheiten",
	t3785: "Datei(en) erfolgreich exportiert",
	t3786: "Die Adresse darf nicht negativ sein.",
	t3787: "Zoom zurücksetzen",
	t3788: "Fehler beim Abrufen der Staging-Musterschritte",
	t3789: "Fehler beim Speichern der Staging-Musterschritte",
	t3790: "Schritte des Staging-Musters erfolgreich gespeichert",
	t3791: "Staging-Muster",
	t3792: "Drücken Sie die entladenen Symbole, um die den Kompressoren zugeordneten Entlader zu aktivieren,/deaktivieren",
	t3793: "Fehlende Daten",
	t3794: "Folgende Parameter haben keine Datenpunkte zurückgegeben",
	t3795: "HTTPS/SSL-Vertrauen war erfolgreich.",
	t3796: "Sie können sich jetzt beim StoreView-Webbrowser anmelden.",
	t3797: "Umleitung zum StoreView-Webbrowser in {{timeLeft}} Sekunden …",
	t3798: "Umfrage derzeit ausgesetzt!",
	t3799: "Gelöschte Alarme (vollständige Systemprotokolle)",
	t3800: "Die maximal zulässige Anzahl an HVAC-Geräten beträgt 45.",
	t3801: "Änderungsprotokoll konnte nicht abgerufen werden.",
	t3802: "Verbindung zum Speicher wird hergestellt ...",
	t3803: "Zeitüberschreitung bei der SCADA-Speicherverbindung",
	t3804: "Verbindung zum SCADA-Speicher fehlgeschlagen",
	t3805: "Ungültiges Datum",
	t3806: "Wählen Sie die Einheit(en) zum Herunterladen des Berichts aus."
};
var de = {
	lang: lang$3
};

var lang$4 = {
	t0: "Aktív alarmok",
	t1: "Törölt Alarmok",
	t2: "host",
	t3: "ID",
	t4: "Eszköz név",
	t5: "Megjegyzések szerkesztése",
	t6: "Nem jogosult Alarmot nyugtázni",
	t7: "Alarm Tipus",
	t8: "Megtörtént",
	t9: "Nyugtázás",
	t10: "Aktív alarm(ok)",
	t11: "Törölt",
	t12: "Számítások",
	t13: "Törölt Alarm(ok)",
	t14: "Némít / Nyugta",
	t15: "Minden nem nyugtázott alarm nyugtázása",
	t16: "Meghatalmazási hiba",
	t17: "Hiba",
	t18: "Kézi vezérlés",
	t19: "Látható",
	t20: "Összegzett Adat",
	t21: "Műszaki nézet",
	t22: "Grafikon (S4)",
	t23: "Csomópont",
	t24: "Mod",
	t25: "Grafikon (Po és PC)",
	t26: "(nincs riasztás érzékelés)",
	t27: "Készülék Alarmok",
	t28: "Előző eszköz megtekintése",
	t29: "Következő eszköz megtekintése",
	t30: "Hiba az alarmok olvasásában.",
	t31: "Aktuállis Alarmok",
	t32: "Aktuállis Alarm",
	t33: "Nincs észlet riasztás",
	t34: "Részlet",
	t35: "Pont",
	t36: "Történelem napló",
	t37: "Menetrendek",
	t38: "Start time",
	t39: "Állás iső",
	t40: "Napok",
	t41: "Ünnepnapok",
	t42: "Állapot",
	t43: "Válasszon adatpontokat",
	t44: "rendben",
	t45: "Mégsem",
	t46: "Indítás",
	t47: "vége",
	t48: "Start date",
	t49: "Sample rate",
	t50: "Nincs adatpont kiválasztva",
	t51: "jelenleg",
	t52: "sec",
	t53: "min",
	t54: "hr",
	t55: "nap",
	t56: "Cím Tipus",
	t57: "Cím",
	t58: "Tartózkodási hely:",
	t59: "Új érték bemenet",
	t60: "Frissít",
	t61: "Válasszon adatpontokat",
	t62: "Válasszon az új értéket",
	t63: "Ki",
	t64: "be",
	t65: "Adatpontok lekérése…",
	t66: "Hiba az adatok lekérésében ...",
	t67: "Nincsenek előzmények beállítva ...",
	t68: "Hiba adapontok lekérésében ...",
	t69: "Történeti minták lekérése",
	t70: "Hiba a történelemi minták lekérésében ...",
	t71: "Felhasználó Név",
	t72: "Jelszó",
	t73: "Host IP / URL-cím:",
	t74: "rendben",
	t75: "Mégsem",
	t76: "Név",
	t77: "Érték",
	t78: "Hiba a felhasználói lista lekérésében",
	t79: "Új cím bemenet",
	t80: "Felhasználó lista lehívás Sikertelen: CMA: Kérjük ellenőrizze a Host IP / URL-címet",
	t81: "Hitelesítési kód",
	t82: "Leírási kód",
	t83: "Válasszon új Időt és dátumot",
	t84: "Válasszon új Időtartamot",
	t85: "A művelet nem sikerült",
	t86: "Adja meg a jogosultsági információkat",
	t87: "HVAC",
	t88: "Tovább!",
	t89: "Leolvasztás",
	t90: "Kézi leolvasztás Indítás",
	t91: "Hűtőbútor világítás vezérlés",
	t92: "Kézi bútor világítás be",
	t93: "Kézi bútor világítás ki",
	t94: "Hűtőpult tisztítás vezérlés",
	t95: "Kézi bútor tisztítás be",
	t96: "Kézi bútor tisztítás ki",
	t97: "Főkapcsoló",
	t98: "Kézi főkapcsoló Be",
	t99: "Kézi Főkapcsoló ki",
	t100: "Leállítás vezérlés",
	t101: "Készülék lekapcs",
	t102: "Kapcsolja BE az eszközöket",
	t103: "Éjszakai visszaállítás szabályozás",
	t104: "Éjszakai visszaállítás Be",
	t105: "Éjszakai visszaállítás Ki",
	t106: "Üzenet elküldve ...",
	t107: "Kézi vezérlés",
	t108: "Zárás",
	t109: "Világítás",
	t110: "Teljesítmény / Egyéb",
	t111: "áttekintés",
	t112: "Válasszan egy AK255 egységet megjelenítéshez.",
	t113: "Betöltés…",
	t114: "-ból",
	t115: "Minden AK255",
	t116: "előző",
	t117: "Oldalak",
	t118: "Következő",
	t119: "Paraméter",
	t120: "Mérések",
	t121: "Adatok lekérése…",
	t122: "Hiba az olvasás részben",
	t123: "Hőmérséklet",
	t124: "Késleltetés leolvasztás után",
	t125: "Alarm if below",
	t126: "Alarm if above",
	t127: "Felügyelet",
	t128: "Offline",
	t129: "Aktív",
	t130: "host",
	t131: "Cím",
	t132: "Leírás",
	t133: "Aktuális Érték",
	t134: "Aktuális állapot",
	t135: "Szabályozók",
	t136: "Egység",
	t137: "Aktív Alarm Van",
	t138: "Aktív Alarmok Vannak!",
	t139: "Be-Ki bemenetek",
	t140: "Relé kimenetek",
	t141: "Érzékelő Bemenetek",
	t142: "Változó Kimenetek",
	t143: "Általános vezérlők",
	t144: "Mások",
	t145: "Megjelenítési pont",
	t146: "Üzlet áttekintés",
	t147: "Kijelentkezés a rendszerből",
	t148: "Bejelentkezés",
	t149: "Kijelentkezés",
	t150: "Hűtés",
	t151: "A készülék áttekintése",
	t152: "Alarmok",
	t153: "történelem",
	t154: "CS-Áttekintés",
	t155: "Üzlet áttekintés",
	t156: "Konfiguráció",
	t157: "Helyszín megtekintés",
	t158: "Rendszer megtekintés",
	t159: "otthon",
	t160: "Alarm",
	t161: "Alapjel",
	t162: "Készülék Részlet",
	t163: "beállítás",
	t164: "Válasszon Dátumot",
	t165: "Információ",
	t166: "napló",
	t167: "Számítások",
	t168: "Fájlkezelés",
	t169: "Súgó Kézikönyv",
	t170: "Infó",
	t171: "Fordító",
	t172: "Mentés Szerverre",
	t173: "Nyelvi fájlok frissítése?",
	t174: "Ez a művelet frissíti a nyelvi fájlokat az egységben. Folytatja ?",
	t175: "Történet exportálás",
	t176: "Válasszon új értéket",
	t177: "minimum",
	t178: "maximum",
	t179: "Nincs aktív riasztás!",
	t180: "Nem változtatható!",
	t181: "Várjon…",
	t182: "Parancs elküldve",
	t183: "Operation failed, please try again later!",
	t184: "A művelet sikeres!",
	t185: "Nem engedélyezett működés!",
	t186: "Biztosan folytatni akarja ezt a műveletet?",
	t187: "ELPÁ",
	t188: "CsopAggr",
	t189: "Felügyelt bemenetek",
	t190: "Egyéb",
	t191: "Nyugtázott Alarmok",
	t192: "Nyugtázott riasztások törlése",
	t193: "Összes nyugtázott riasztások törlése",
	t194: "Ön nem jogosult nyugtázásra",
	t195: "Nem engedélyezett",
	t196: "Szeretné nyugtázni ezt az alarmot?",
	t197: "Erősítse meg  az ismert Alarmokat",
	t198: "Kérjük először Jelentkezzen Be",
	t199: "Kérjük válassza ki a törlendő alarmokat",
	t200: "Erősítse meg a nyugtázott alarmokat",
	t201: "Biztosan törölni akarja ezt az alarmot?",
	t202: "Nem jogosult Alarmot törölni",
	t203: "Biztosan törölni akarja az összes alarmot?",
	t204: "A kiválasztott alarmok nem törölhetők",
	t205: "Alarm Beállítás",
	t206: "Felügyelői jogosítvány kell a művelet végrehajtásához",
	t207: "Nincs visszatérő adat az egységből",
	t208: "Válasszon egységet",
	t209: "Kérjük válasszon ki egy egységet a listából",
	t210: "Alarmok száma",
	t211: "Alarm Állapot",
	t212: "Egyéb",
	t213: "Kiválasztott Alarm Törlése",
	t214: "Kérjük megerősíteni",
	t215: "Connection to Unit Failed, Please Check the Network Connection!",
	t216: "Leolvasztás Alarm",
	t217: "Digitális riasztó",
	t218: "Digitális bemenet állapotát",
	t219: "Leolvasztás bemenet",
	t220: "Tisztítás bemenet",
	t221: "Digitális bemenet",
	t222: "Nyelvi Fájlok Mentése",
	t223: "Egységek",
	t224: "Nincs konfigurált készülék a rendszerben: CMA: Honlap letiltva.",
	t225: "Figyelmeztetés:",
	t226: "Válasszon időt",
	t227: "Kommunikáció",
	t228: "Letölti a Jelentés Fájlt?",
	t229: "Please wait while generating report file...",
	t230: "Letöltés Jelentés",
	t231: "Alarm Állapot / Szerviz",
	t232: "Please Wait While Retrieving Data...",
	t233: "Bejelentkezve",
	t234: "Felhasználó kijelentkezett",
	t235: "Felhasználó bejelentkezett",
	t236: "Üdvözöljük!",
	t237: "Invalid license",
	t238: "Energia Mérő vezérlők",
	t239: "Világítás panelek",
	t240: "VLT Inverter",
	t241: "Kompresszor modul ",
	t242: "Készülék szabályozó pont",
	t243: "Számított Pontok",
	t244: "Cutler-Hammer Panelek",
	t245: "Fuvarozók",
	t246: "Speciális nézet",
	t247: "Dupla Kattintás ide a nagyításhoz / kicsinyítéshez",
	t248: "Kattintson ide a nagyításhoz / kicsinyítéshez",
	t249: "Nyugtázott Alarm(ok)",
	t250: "Szimulátor",
	t251: "Hűtés feladat",
	t252: "HVAC feladat",
	t253: "Világítás feladat",
	t254: "Egyéb feladat",
	t255: "Hálózatra kapcsolt",
	t256: "Meghajtó",
	t257: "Hivatkozás",
	t258: "Grafikon (Hajtás MTR Hz és Amp)",
	t259: "Leállítási dátum",
	t260: "Állj",
	t261: "The stop time is on or before start time, please reselect the date/time",
	t262: "Az adapont történelem létrehozás",
	t263: "are later than the start time specified",
	t264: "Az egyes történelmi pontokból visszaadott minták száma elérte a 100 : CMA : 000-et : CMA : A lekérdezés hosszú időt venne igénybe. Csökkentse ezt a számot mintavételi sebesség csökkentésével vagy változtassa meg a dátumot/ időt .",
	t265: "Unit history query task is busy, please wait for about 2 minutes and try again...",
	t266: "Mentés",
	t267: "Történelem Vezérlő Panel",
	t268: "Első",
	t269: "Utolsó",
	t270: "nyomtatás",
	t271: "Kicsinyítés",
	t272: "Nagyítás",
	t273: "Leírás megjelenítés/elrejtés",
	t274: "Történeti adatok exportálása fájlba",
	t275: "Rács megjelenítés/elrejtés",
	t276: "Egy oldal megtekintése",
	t277: "Több-oldalas megtekintés",
	t278: "Történelem csoport betöltése fájlból",
	t279: "Történelem betöltése fájlból",
	t280: "Történelem Csoport mentése fájlba",
	t281: "Több-oldalas megtekintés száml",
	t282: "oldal",
	t283: "Válasszon ki egy oldalt megjelenítésre",
	t284: "Történeti adat fájl .hst formátumú lehet!",
	t285: "No data returned, please check the history point selection and date/time selected",
	t286: "Történelm pillanatkép",
	t287: "Történet teljes megtekintése",
	t288: "Történet teljes megtekintése",
	t289: "Választás…",
	t290: "Ez a művelet exportálja a jelenlegi történelem nézetet .hst / .csv fájlba.",
	t291: "Válassza ki a cél fájlt.",
	t292: "Fájl",
	t293: "Történelem csoport betöltése …",
	t294: "Történelem betöltése …",
	t295: "Történelem Csoport mentése…",
	t296: "Nézet",
	t297: "Nagyítás",
	t298: "Igen",
	t299: "Nincs",
	t300: "Szabályozó konfiguráció lekérés?",
	t301: "Ne kérdezze meg újra",
	t302: "Szabályozó konfiguráció lekérés",
	t303: "Beolvasott adatok",
	t304: "Alarm Szint",
	t305: "Alarm akció",
	t306: "Nyugtázva",
	t307: "Adatbázis mentés lemezre",
	t308: "Fájl Név",
	t309: "Adatbázis Leírás",
	t310: "Adatbázis elérési út",
	t311: "Verzió",
	t312: "Idő",
	t313: "Alarmok exportálása",
	t314: "Adatbázis mentve a",
	t315: "Database saving failed, please check if directory and filename are valid!",
	t316: "Fájlnév nem helyes: CMA: Ellenőrizze az adatbázis fájl nevét",
	t317: "Alarms export succeeded. Please choose a file to save the exported alarms",
	t318: "Kérjük válassza ki az Exportálandó Alarmokat",
	t319: "Alarm információ lekérés az egység(ek)től",
	t320: "fájl Leírás",
	t321: ".hst fájl konvertálás .csv formátumra",
	t322: "1. lépés: Válassza ki a. Hst Fájlt",
	t323: "2. lépés: Válassza ki a adatpont és idő tartományt",
	t324: "3. lépés: válassza ki a cél. Csv fájlt",
	t325: "Mentés másként ...",
	t326: "6000 adatpontnál több nem megengedett ennél műveletnél",
	t327: "Adatpontok Kiválasztva",
	t328: "It is not allowed to export more than 65,536 samples per data point, please change the sample rate or time range.",
	t329: "Összegyűjtés",
	t330: "Gyűjtsd össze az egység előzmény adatait",
	t331: "Válasszon begyűjtött adatpontot",
	t332: "Nincs érték",
	t333: "Hiányzik",
	t334: "Felfüggesztett",
	t335: "Please Make A Selection",
	t336: "Válasszon adat pontokat rajzoláshoz",
	t337: "Mentés .Hst Fájlba",
	t338: "Adatletöltés kész: CMA: Kérem válasszon az alábbi lehetőségek közül:",
	t339: "Válasszon legfeljebb 8 pontot rajzoláshoz",
	t340: "Mentse a begyűjtött történelem adatokat. HST fájlba",
	t341: "Áttekintés Nyomtatás",
	t342: "Kérjük válassza ki az összefoglaló jelentés mentést vagy nyomtatást",
	t343: "Válassza  az A fájl formátumot",
	t344: "Generating Overview File, Please Wait...",
	t345: "A kiválasztott adatbázis fájl létezik : CMA : akarja felülírni?",
	t346: "Műszerfal",
	t347: "Grafikus nézet",
	t348: "Segítség",
	t349: "Rendszer nézet",
	t350: "Beilleszt",
	t351: "Fordítás Segítő",
	t352: "Kézi",
	t353: "Alarm igény ...",
	t354: "Frissítve",
	t355: "Modell",
	t356: "Ellenőrzés",
	t357: "Olaj",
	t358: "Hőm",
	t359: "Maximalizálása",
	t360: "Visszaállít",
	t361: "Vissza ...",
	t362: "Hibás komm",
	t363: "Komm állapot rendben",
	t364: "Komm üzenet elküldve",
	t365: "Honlap komponensek betöltése",
	t366: "Konfigurációs komponensek betöltése",
	t367: "Vez Hőm",
	t368: "This setting will be effective only when the history is redrawn, do you want to continue?",
	t369: "Loading, please wait",
	t370: "adatpontok",
	t371: "Nincs egység név",
	t372: "Beállítások",
	t373: "Szimulátor Verzió",
	t374: "Energia",
	t375: "Ugrás az áttekintéshez ablakhoz",
	t376: "Rendszer riasztás vagy kapcsolódó eszköz nem található",
	t377: "Egyidejű menetrendek",
	t378: "Hiányzó nyelvi fájl.",
	t379: "Verzió nem támogatott",
	t380: "Írja be egy készülék IP-címét (pl. 192.168.1.1)",
	t381: "Kérjük adja meg egy egység IP címét vagy az URL-t",
	t382: "Részletek elrejtése",
	t383: "Részlet mutatása",
	t384: "Külső Hőm",
	t385: "Visszatérő",
	t386: "Betáp",
	t387: "Nedvesség",
	t388: "harmatpont",
	t389: "Ventilátor",
	t390: "Zóna",
	t391: "Leállítás",
	t392: "Megosztás",
	t393: "Szívás Temp",
	t394: "Aktuális Teljesítmény",
	t395: "Szívó Nyomás",
	t396: "Kibocsátás",
	t397: "Kompresszorok",
	t398: "Kondenzátor",
	t399: "Kondenzátor Cél",
	t400: "Hűtőközeg",
	t401: "Szívás cél",
	t402: "Szívó",
	t403: "Leolvasztás",
	t404: "Danfoss Rögzítési Forma",
	t405: "Szöveg Formátum",
	t406: "Ez a művelethez az egységet reszetelni kell : CMA : akarja a készüléket reszetelni most?",
	t407: "Unit reset succeeded, please reload the web site.",
	t408: "Gyári Alkalmazás Mód",
	t409: "Számítás…",
	t410: "percek",
	t411: "Éjszakai visszaállítás",
	t412: "Hűtőbútor világítás",
	t413: "Menetrend Konkurencia",
	t414: "(10 perces felbontás)",
	t415: "Szöveg megtekintése",
	t416: "Exportálás CSV-ként",
	t417: "png mentése",
	t418: "Diagram megtekintése",
	t419: "Szöveg megtekintése",
	t420: "Menetrend",
	t421: "Tartam",
	t422: "Term Hőm",
	t423: "Semmi",
	t424: "Hőm Kontrol",
	t425: "Üzemelés innen",
	t426: "Adatbázis betöltött",
	t427: "Statisztika",
	t428: "Válassza Fájlt",
	t429: "Choose the history file you wish to convert to CSV format...",
	t430: "Befejezés",
	t431: "Összegzés",
	t432: "Történeti adat fájlok (*.hst)",
	t433: "Történelem konvertáló varázsló",
	t434: "Fájl létrehozása teljes.",
	t435: "Válassza ki a kívánt adatpontokat konvertálja CSV formátumba ...",
	t436: "Válasszon idő Tartományt",
	t437: "Select the time range and press the process button...",
	t438: "Adat feldolgozás",
	t439: "Preparing history data, please wait",
	t440: "Select CSV filename and save data to file...",
	t441: "Nem található történelem adatpont",
	t442: "Hiba - Nincs lehívható történelmi adat",
	t443: "Hiba volt a host hálozat egységekkel való kommunikációban. Megtalálva",
	t444: "Figyelem",
	t445: "Csend",
	t446: "Az adatbázist nem mentette: CMA: Valóban szeretné zárni az ablakot?",
	t447: "Táblázat nézet",
	t448: "Grafikák mutatása",
	t449: "Tábla megjelenítés",
	t450: "Kézi leolvasztás Be",
	t451: "Kézi leolvasztás Ki",
	t452: "Auto leolvasztás",
	t453: "Leolvasztás szabályozás beállítása",
	t454: "Tehermentesítő be",
	t455: "Tehermentesítő ki",
	t456: "Folyadék",
	t457: "Nem menetrend konfigurálva.",
	t458: "Üzletlátvány",
	t459: "Megszakadt a kommunikáció egy egységgel a host hálózatban!",
	t460: "Gyors beállítás",
	t461: "Utca",
	t462: "Város",
	t463: "Telefon",
	t464: "Régió",
	t466: "Index",
	t469: "Címlista sikeresen exportálva",
	t470: "Elem törlése",
	t471: "Műszer",
	t472: "Megjegyzés: a bemenet érzékeny a kis- és nagybetűkre",
	t473: "online",
	t474: "RO Panel",
	t475: "SI Modul",
	t476: "OI Panel",
	t477: "Számítás",
	t478: "Aktuális idő beállítás",
	t479: "csop.aggr.",
	t480: "Nincs kompresszor",
	t481: "Áramlásikör",
	t482: "Hűtőpult",
	t483: "Ismeretlen",
	t484: "hiba tipus:",
	t485: "Hozzáadás",
	t486: "Másolás",
	t487: "Eltávolít",
	t488: "Hűtőpult vezérlés",
	t489: "Hűtőpult típus",
	t490: "LON",
	t491: "Adja meg az összeget másoláshoz",
	t492: "Nincs név",
	t493: "Hűtőrendszer elrendezés konfigurálása ...",
	t494: "Konfigurációs hiba:",
	t495: "Please verify configuration.",
	t496: "The refrigeration layout setup has completed successfully. Press the ok button to exit this wizard.",
	t497: "Konfigurációs Állapot",
	t498: "Végső hűtés elrendezés",
	t500: "Kód #",
	t501: "Elrendezés",
	t502: "Rendszer beállítás",
	t503: "Engedélyezés",
	t504: "Felhasználók",
	t505: "Nem engedélyezett",
	t506: "Please verify...",
	t507: "Are you sure you want to leave this screen? Your settings may be lost.",
	t508: "Vezérlő hálózat szkennelés...",
	t509: "Szkennelés",
	t510: "Ellenőrzés",
	t511: "AK-Be/Ki",
	t512: "Újszkenn",
	t513: "Tartalmazza az offline programozást",
	t514: "Készülék Busz",
	t515: "Online eszközök",
	t516: "Offline programozás",
	t517: "azonos cím",
	t518: "Kompresszor vezérlés",
	t519: "Szívó Csoportok",
	t520: "N / A",
	t521: "A rendelkezésre álló ügyek",
	t522: "Engedélyezze  több pontos áramkör létrehozását",
	t523: "Készülék beillesztés",
	t524: "Áramkör beillesztés",
	t525: "Több-bútoros kör",
	t526: "Táblák betöltése ...",
	t527: "Riasztás adatok kérése",
	t528: "Történelem csoport fájlok",
	t529: "Történeti adat fájlok",
	t530: "Minden Fájl",
	t531: "Szimbólumok mutatása",
	t532: "Görbék mutatása",
	t533: "Nincs elég előzmény begyűjtve.",
	t534: "A legutólsó történeti adatok lekérése",
	t535: "Állapotok / Beállítások",
	t536: "Most letöltés",
	t537: "történelem minták ...",
	t538: "Lekérés",
	t539: "Adatpontok…",
	t540: "Intervallum",
	t541: "Min",
	t542: "Max",
	t543: "Átlag",
	t544: "Tovább a riasztási ablakhoz.",
	t545: "Auto-bejelentkezés leállítás",
	t546: "Csatlakozás -hoz",
	t547: "Mégsem automatikus bejelentkezés",
	t548: "Kérjük adja meg a kilépési jelszót",
	t549: "Web 1",
	t550: "Web 2",
	t551: "Automatikus bejelentkezés",
	t552: "kijelentkezés inaktív periódus után",
	t553: "Kezdőképernyő",
	t554: "Konfigurációs menü mutatás",
	t555: "Egyéb tételek a falitáblán mutatása",
	t556: "Használjon teljes képernyőt",
	t557: "Alarm hangjelzés Engedélyezése ",
	t558: "Engedély a program bezárásához / minimalizálásához",
	t559: "Hiba elérése egység #",
	t560: "Csatlakozva a hálózathoz.",
	t561: "Auto-bejelentkezés törlése",
	t562: "Újra csatlakozás ide",
	t563: "Kijelentkezve ebből",
	t564: "Csatlakozva",
	t565: "Idő formátum",
	t566: "Dátum formátum",
	t567: "Nyomás",
	t568: "Világítás szint",
	t569: "Utóhűtés",
	t570: "Utóhűtő",
	t571: "Napi",
	t572: "Heti",
	t573: "HST File",
	t574: "CSV File",
	t575: "Vasárnap",
	t576: "Hétfő",
	t577: "Kedd",
	t578: "Szerda",
	t579: "Csütörtök",
	t580: "Péntek",
	t581: "Szombat",
	t582: "History Log Collection",
	t583: "Enable auto-collect",
	t584: "When to collect",
	t585: "File save location",
	t586: "Select file format",
	t587: "Select folder to store history files...",
	t588: "Enable automatic history collection to retrieve and save daily or weekly history logs of the store's configured history points.",
	t589: "To view HST history files go to the main history screen and select import history. To view CSV files use any spreadsheet application.",
	t590: "If the current connection's IP address is found in the address book the selected history points will be saved.",
	t591: "Select data-points",
	t592: "All selected",
	t593: "Points selected",
	t594: "Keresés",
	t595: "Auto-connecting to",
	t596: "Please verify",
	t597: "Automatic history collection is enabled. Are you sure you want to close this program?",
	t598: "Are you sure you want to close this program?",
	t599: "Tevékenység",
	t600: "Szűrő",
	t601: "All actions",
	t602: "More information...",
	t603: "Editable",
	t604: "Működés",
	t605: "Törölt művelet",
	t606: "Adatbázis Letöltés Kész",
	t607: "Egység Adatbázis Frissítve",
	t608: "Fájl (ok) egységre küldése sikeres",
	t609: "Letöltése elkészült: CMA: fájl feldolgozás alatt a készülékben",
	t610: "Error happened in uploading file to PC, please check if the file is writable and accessible",
	t611: "Fajl Feltöltés",
	t612: "Fájl letöltés",
	t613: "Kiválasztott fájl(ok) feltöltése",
	t614: "Kiválasztott fájl (ok) Letöltése",
	t615: "Fájl törlése",
	t616: "Törlésre kiválasztott fájl (ok)",
	t617: "FTP Csatlakozási hiba",
	t618: "Kijelölt fájl (ok) letöltése az egységre",
	t619: "Error happened in FTP connection. Please check FTP connection and password/user name.",
	t620: "FTP csatlakozva",
	t621: "Bájtok",
	t622: "Ez a művelet felülírja a fájlokat a készülékben: CMA: Szeretné folytatni?",
	t623: "Ez a művelet felülírja a fájlokat a PC-n: CMA: Szeretné folytatni?",
	t624: "Fájlok már letöltve a készülékbe.",
	t625: "Csak hűtés",
	t626: "Kisbolt",
	t627: "teljes funkcionalitás",
	t628: "HVAC CSAK",
	t629: "File Name is illegal; Please make sure the file name has less than 8 characters.",
	t630: "Felülírja az alkalmazási kódot most?",
	t631: "Később",
	t632: "feltöltve",
	t633: "Ha a IGEN-t választja felülírhatja az alkalmazást most vagy válassza a KÉSŐBB-et",
	t634: "Kérjük válassza ki a Simulator gyökér könyvtárat",
	t635: "Kérem válassza ki a Windows könyvtárat",
	t636: "Please Select the Target Directory",
	t637: "Válasszon adatbázis könyvtárat",
	t638: "FTP lecsatlakozva",
	t639: "Kapcsolat megszűnt: CMA: FTP szétkapcsolt",
	t640: "Kiválasztott Fájl(ok) Feltöltése PC-re",
	t641: "Kijelölt fájl (ok) törlése",
	t642: "FTP Szerver Port",
	t643: "Web Szerver Port",
	t644: "Helyi elérési út",
	t645: "Fel",
	t646: "Méret",
	t647: "Dátum",
	t648: "Csatlakozás",
	t649: "Szétkapcsol",
	t650: "Távoli elérési út",
	t651: "Elérhető Szabad hely:",
	t652: "Kiválasztott Fájl(ok)",
	t653: "Fájlok",
	t654: "Teljes méret",
	t655: "Click to change folder...",
	t656: "Cserél…",
	t657: "Szeretné törölni a kiválasztott fájl(oka)t?",
	t658: "Kapcsolat bezárva: CMA: Kilépés",
	t659: "The selected file(files) name(names) is(are) illegal and the download is ignored.",
	t660: "Néhány kiválasztott fájl neve illegális vagy törölt.",
	t661: "Fájl (ok) PC-re küldése sikeres",
	t662: "Nincs elég szabad lemezterület a készüléken.",
	t663: "Transfer file(s)",
	t664: "Modified",
	t665: "Másold a fájlokat Flash Disk-ről RAM lemezre",
	t666: "Kérjük válassz ki  a fájl másolás műveletet:",
	t667: "Copy All the Configured EDF Files from Flash Disk",
	t668: "Másold át az összes EDF fájlt Flash Disk-ről",
	t669: "The edf/ed3 files are stored on the flash disk of your unit but not loaded into the File Management screen.  Use the options below to copy the stored EDF files in your unit to the File Management screen",
	t670: "Ez a művelet bemásolja a készülékben konfigurált összes EFA fájlt a flash diszkről a RAM diszkre",
	t671: "Ez a művelet bemásolja a készülékben lévő összes EFA fájlt a flash diszkről a RAM diszkre",
	t672: "No Valid EDF File Exists",
	t673: "EDF File Copy Finished",
	t674: "files copied",
	t675: "Confirm database download",
	t676: "Egység Cím",
	t677: "IP-cím/Szállónév",
	t678: "Adatbázis Fájl Név",
	t679: "Kiválasztás",
	t680: "Frissítés befejezve, egység frissítés és reszet. Ez több percet is igénybe vehet.",
	t681: "Üdvözöljük a frissítés Manager-ben - 1/2 lépés",
	t682: "Üdvözöljük a frissítés Manager-ben - 2/2 lépés",
	t683: "Feldolgoz",
	t684: "Frissítés előtti ellenőrzés",
	t685: "Firmware keresés",
	t686: "Biztonsági mentés adatbázis?",
	t687: "Biztonsági mentés adatbázisba",
	t688: "Szoftver Transzfer",
	t689: "Szoftver felülírás",
	t690: "Válasszon szoftvert frissítéshez",
	t691: "Nem észlelt gyári biztonsági alkalmazást.",
	t692: "You may continue to proceed but any errors during the update process may render the system inoperable.",
	t693: "It is strongly recommended not to proceed.",
	t694: "Szeretné folytatni?",
	t695: "Remotely upgrading a system does carry a risk of failure, which may result in system error.",
	t696: "Lemondás",
	t697: "Adatbázis Feltöltés Befejezve",
	t698: "Biztonsági mentés adatbázis Kész",
	t699: "Alkalmazások letöltései befejezve",
	t700: "Alkalmazás felülírása Kész",
	t701: "Sikeres Szoftver Frissítés",
	t702: "Szeretné visszaállítani a készülék gyári beállítását?",
	t703: "Hardver Ellenőrzés",
	t704: "Eszköz állapot ellenőrzése",
	t705: "Gyári Hibabiztosítás",
	t706: "Aktuális Firmver Verzió",
	t707: "Frissítés indítása",
	t708: "Válasszon ki egy meglévő adatbázis fájlt",
	t709: "Válasszon ki egy meglévő firmware fájlt",
	t710: "Vissza",
	t711: "Firmware keresés",
	t712: "Válassza ki a. Csi fájlt",
	t713: "Válassza ki a. Mai Fájl",
	t714: "Remote downloading is not supported by this CPU board.",
	t715: "Alkalmazások letöltései sikertelenek",
	t716: "Adatbázis Feltöltés sikertelen, Próbálja újra",
	t717: "Alkalmazás felülírása Kész",
	t718: "Application Overwriting Failed! Please Try Again.",
	t719: "Alkalmazás felülírása sikertelen",
	t720: "Nem észlelt gyári biztonsági alkalmazást.",
	t721: "Elvégezve",
	t722: "The selected application may have a different version type than the current unit's firmware, continue the operation may carry a risk of failure and system error.",
	t723: "The selected file(files) name(names) is(are) illegal and the download is ignored.",
	t724: "Néhány kiválasztott fájl neve illegális vagy törölt.",
	t725: "Egység berg pin hiányzik.",
	t726: "Egység elem fesz alacsony",
	t727: "Egység host hálózat hiányzik.",
	t728: "Készülék Terheléscsökkentés állapotban",
	t729: "Válasszon cél fájlt",
	t730: "Válasszon cél könyvtárat",
	t731: "Adatbázis Feltöltés Befejezve",
	t732: "Állapot OK",
	t733: "Állapot hiba",
	t734: "Állapot nem ismert",
	t735: "Ez a művelet felülírja a kijelölt fájlokat a cél szimulátorban : CMA : akarsz folytatni?",
	t736: "Adatbázis Letöltés kész: CMA: újra kell indítani a rendszert",
	t737: "Adatbázis Letöltés  sikertelen: CMA: meg kell próbálni később",
	t738: "A készülék automatikusan nullázódik ezen műveletet után: CMA: Szeretné folytatni?",
	t739: "Válasszon adatbázist",
	t740: "Letölti az adatbázis fájlt?",
	t741: "Adatbázis Fájl Feltöltés ?",
	t742: "Downloading database to the unit, please wait",
	t743: "Adatbázis letöltés sikeres: CMA: Most a készülék újraindítása",
	t744: "Adatbázis frissítése Kész: CMA: Egység visszaállítása sikeres",
	t745: "Sikertelen Adatbázis Fissítés! Próbálja Újra.",
	t746: "Hiba adatbázis frissítésben",
	t747: "Letöltés elő-ellenőrzés",
	t748: "Adatbázis keresés",
	t749: "Adatbázis Letöltés ",
	t750: "Aktuális Adatbázis Információ",
	t751: "Válasszon Adatbázist letöltéshez",
	t752: "Adatbázis mentése PC-re (Feltöltés)",
	t753: "Adatbázis betöltés az egségbe (letöltés)",
	t754: "Felügyelői jogosultság szükséges",
	t755: "Feltöltés indítása",
	t756: "Kérjük válasszon egy adatbázis műveletet:",
	t757: "Letöltés indítása",
	t758: "Aktuális Firmver Verzió",
	t760: "Adatbázis letöltés sikerült!",
	t761: "Üdvözöljük az Adatbázis Manager-ben - 1/2 lépés",
	t762: "Üdvözöljük az Adatbázis Manager-ben - 2/2 lépés",
	t763: "Connection to Unit Failed, Please Check Host IP/URL Address",
	t764: "Remotely upgrading a system's database does carry a risk of failure, which may result in system error.",
	t765: "Database downloading finished",
	t766: "Ethernet Scan",
	t767: "Enter first IP address then press start",
	t768: "Scan",
	t769: "Szkennelés",
	t770: "History Log Collection",
	t771: "Analog graph colors",
	t772: "Digital graph colors",
	t773: "Shutdown Schedules",
	t774: "Defrost Summary",
	t775: "Éjszakai visszaállítás menetrendek",
	t776: "Case Lighting Schedules",
	t777: "Loading Status",
	t778: "Alarm Log Collection",
	t779: "Select folder to store alarm files...",
	t780: "Havi",
	t781: "Enable automatic alarm collection to retrieve and save alarm information to a file on a periodic basis.",
	t782: "CSV Separator",
	t783: "Nincsenek larmok",
	t784: "Retrieving alarm counts for unit:",
	t785: "Retrieving alarm details from unit:",
	t786: "Automatic alarm collection is enabled. Are you sure you want to close this program?",
	t787: "Óránként",
	t788: "This will update the unit's database and set the location to the center of the current view. Proceed?",
	t789: "samples processed",
	t790: "Please configure a suction group.",
	t791: "To re-use the Layout Wizard more than once the refrigeration database layout needs to be reset.",
	t792: "Do this via the Configuration-&gt;Control page and set the number of Racks/Packs to zero.",
	t793: "Note: any schedules, calculations or imports based on prior refrigeration configuration will need to be reconfigured.",
	t794: "Copy process finished.",
	t795: "Refrigeration Report",
	t796: "Final HVAC Layout",
	t797: "The HVAC layout setup has completed successfully. Press the ok button to exit this wizard.",
	t798: "Configuring HVAC layout...",
	t799: "To re-use the HVAC Layout Wizard more than once the HVAC database layout needs to be reset.",
	t800: "Do this via the Configuration-&gt;Control page and set the number of HVAC units to zero.",
	t801: "Note: any schedules, calculations or imports based on prior HVAC configuration will need to be reconfigured.",
	t802: "Szakaszolási minta",
	t803: "Lépések",
	t804: "Szint",
	t805: "Show Multiple DataTip",
	t806: "Lépések száma",
	t807: "Could not save file.",
	t808: "Incorrect model.",
	t809: "Load successful.",
	t810: "Megnyitás",
	t811: "This feature is only available in the desktop version of StoreView.",
	t812: "Folytatás",
	t813: "Leave Wizard",
	t814: "Operation succeeded. Would you like to continue or you want to leave the wizard.",
	t815: "Error while fetching alarm logger data.",
	t817: "Device Name : ",
	t819: "In Progress...",
	t820: "Alarm data is not present.",
	t821: "Alarm szám ",
	t822: "Mimic Display Options",
	t823: "Display only text",
	t824: "Display only icon",
	t825: "Display text and icon",
	t826: "1 Hét",
	t827: "2 Weeks",
	t828: "3 Weeks",
	t829: "4 Weeks",
	t830: "Graphic Settings",
	t831: "Unable to display alarm diagnostics, please check auto alarm collection under preferences",
	t832: "Reordering not allowed with IO racks or circuits included in configuration.",
	t833: "This Host IP/URL Address is already present in address book. Do you like to update that entry?",
	t834: "Browse",
	t835: "Select Address Book Folder",
	t836: "Location",
	t837: "Selected location is not having any address book contents.Would you like to export contents of the existing address book to the selected location?",
	t838: "Alarm Action Codes",
	t839: "Address book file is not found in the selected path. Address book is loaded from local...",
	t840: "Please select location to save logs.",
	t841: "Please select a valid location to save logs.",
	t842: "Alarm log collection is in progress...",
	t843: "History log collection is in progress...",
	t844: "Run In Background",
	t845: "Alarm log progress",
	t846: "Host IP/URL Address should not be blank",
	t847: "Address field should not be blank",
	t849: "You don't have latest version of the file, would you like to load data from the latest version?",
	t850: "Version conflict",
	t851: "History Log Progress",
	t852: "User Name and Password fields should not be blank.",
	t853: "IP Address field should not be blank.",
	t854: "Host IP/URL Address field should not be blank.",
	t855: "User name should not be blank.",
	t856: "Password should not be blank.",
	t857: "The SM800 you have connected to with",
	t858: "has not had the Initial Startup Wizard completed. Please complete this task and reconnect to the SM800",
	t859: "Set to now",
	t860: "Invalid input",
	t861: "Text is too long",
	t862: "Offset",
	t863: "Unable to communicate with unit(s) on host network",
	t864: "Please verify addresses are different. Configuration is updated when the save button is pressed.",
	t865: "Please select a valid History File (.hst)",
	t866: "Please select a valid History Group File (.grp)",
	t867: "Szín",
	t868: "Százalékos",
	t869: "Feszültség",
	t870: "Jelenlegi",
	t871: "Hatalom",
	t872: "Frekvencia",
	t873: "Koncentráció",
	t874: "Áramlás",
	t875: "Sebesség",
	t876: "Savasság",
	t877: "Jelöljön ki diagramokat az alsó fiókban",
	t878: "PNG mentése",
	t879: "Felhívni",
	t880: "Rendszer állapota...",
	t881: "Rendszer és egység állapota",
	t882: "Gataway között",
	t883: "Jellemzőlicencek",
	t884: "Szoftververzió",
	t885: "Böngészőmodul verziója",
	t886: "Danux verzió",
	t887: "Mac-cím",
	t888: "Támogatás és frissítések",
	t889: "Egység neve",
	t890: "Újra",
	t891: "Megszakadt a kapcsolat",
	t892: "Nem található {{type}} berendezés.",
	t893: "A jelenleg használt szoftververzió nem támogatott",
	t894: "Gyorshivatkozások",
	t895: "Berendezések",
	t896: "Grafikonrajzoló",
	t897: "Segédprogramok",
	t898: "Szoftvervisszaállítás",
	t899: "Diagnosztika",
	t900: "Hiba történt",
	t901: "Nem található {{alarms}} adat",
	t902: "Beállítások",
	t903: "Alkalmazás neve",
	t904: "Alkalmazás verziója",
	t905: "Üzenetet",
	t906: "Esemény típusa",
	t907: "Időbélyeg",
	t908: "Visszaállítási verzió",
	t909: "Jelenlegi verzió",
	t910: "Szoftver",
	t911: "Visszagurítás...",
	t912: "Kérjük, válassza ki a berendezést a listából",
	t913: "Naptár nézet",
	t914: "Hiba történt az eszközök betöltése közben, próbálkozzon újra.",
	t915: "Hiba történt az ütemezések betöltése közben, próbálkozzon újra.",
	t916: "Nem található berendezés",
	t917: "Ütemezések exportálása",
	t918: "Válassza a CSV vagy PDF exportálási típust",
	t919: "Exportálás CSV-ként",
	t920: "PDF előkészítése",
	t921: "Exportálás PDF formátumban",
	t922: "Kérjük, adja meg az IP ebben a formátumban xx.xx.xx.xx[:xxxx]",
	t923: "Felhasználónév",
	t924: "Logika",
	t925: "Visszagurítás",
	t926: "Visszagörgetés SM800A szoftver",
	t927: "Ez a művelet visszaállítja az összes szoftvert a felsorolt verziókra.",
	t928: "Fél eszközszerkesztő",
	t929: "néhány szöveget, amely elmagyarázza a funkció",
	t930: "CdF frissítése",
	t931: "Leképező eszköz",
	t932: "Eszközfájl",
	t933: "1-alapú regiszterszám",
	t934: "Tároló típusa",
	t935: "Min",
	t936: "Max",
	t937: "Írásvédett",
	t938: "Napló",
	t939: "Üzenet típusa",
	t940: "Konverziós kulcsszó",
	t941: "Paraméterek",
	t942: "Addr, ez nem az én hibám.",
	t943: "Prioritás",
	t944: "Kicsit",
	t945: "Áthidalta",
	t946: "Új fájl",
	t947: "Fájl megnyitása",
	t948: "Fájl létrehozása",
	t949: "Csoport",
	t950: "Termék verziója",
	t951: "Hiba történt",
	t952: "Nem található",
	t953: "Ez az eszköz nem található",
	t954: "Külső hőmérséklet.",
	t955: "Nem található {{equipment}}",
	t956: "Esetekben",
	t957: "A riasztás sikeresen elnémul/hat",
	t958: "A riasztás elnémítása/felvonása nem sikerült",
	t959: "Hiba történt az egység(ek) betöltése közben, próbálkozzon újra.",
	t960: "Nincs berendezés",
	t961: "Nem található {{type}} berendezés",
	t962: "A rendszerkezelő konfigurálása",
	t963: "Eszköz",
	t964: "Visszavonás",
	t965: "Módosította a mező értékét, menti a módosításokat?",
	t966: "Kijelölt előzménypont(ok) törlése",
	t967: "Shr {{number}}",
	t968: "Hr szivattyú fordulatszám",
	t969: "Sd",
	t970: "V3hr",
	t971: "Hr referencia",
	t972: "Stw {{number}}",
	t973: "Tw hivatkozás",
	t974: "Tw szivattyú fordulatszám",
	t975: "V3tw",
	t976: "Trec",
	t977: "Prec",
	t978: "Vrec OD",
	t979: "Prec referencia",
	t980: "Trec hivatkozás",
	t981: "Pc",
	t982: "Ss",
	t983: "Vhp OD",
	t984: "Pgc hivatkozás",
	t985: "Kidobó OD",
	t986: "Pgc",
	t987: "Shp",
	t988: "Sgc",
	t989: "Sc3",
	t990: "Sgc ctrl.",
	t991: "Kond. kapacitás",
	t992: "Po",
	t993: "Ss",
	t994: "Sd",
	t995: "Pc",
	t996: "Futóképesség",
	t997: "Böngésző tanúsítvány",
	t998: "Kijelentkezés",
	t1000: "Rendezés csak ekkor: {{count}}",
	t1001: "{{count}} / {{total}} riasztás betöltve",
	t1002: "Az összes riasztás nyugtázása sikerült",
	t1003: "Az összes riasztás nyugtázása sikertelen",
	t1004: "Csak {{count}} / {{total}} riasztás nyugtázva",
	t1005: "Az exportálás nem sikerült az összes riasztásnál",
	t1006: "Az exportálás sikerült",
	t1007: "Az exportálás sikertelen, legalább egy riasztástípus szükséges",
	t2000: "-",
	t2001: "A válasz túl nagy az XML átviteli pufferhez, amely 32 000 bájt",
	t2002: "A cmd elemhez vagy parancshoz társított attribútumok nem tartalmaznak \"cmd\" elemet, vagy nem tudnak változóba írni, nem tudnak változót olvasni, vagy nem tudnak pontot olvasni, vagy nem tudnak ki/be írni a bemeneti vagy relé kimeneti üzemmódot",
	t2003: "Rossz parancsbevitel",
	t2004: "Hiba a CGI bejegyzés paramétereiben",
	t2005: "Hiba az XML-válasz belső reprezentációjáról a válasz XML-karakterláncává konvertálásakor",
	t2006: "Hiba az URL-karakterlánc dekódolása során",
	t2007: "Memória túlcsordulás",
	t2008: "A válaszadatok tömörítése nem sikerült",
	t2009: "Hiba a kérés elemzése során",
	t2010: "Adathozzáférési hiba – számos olyan ok, amelyet nem sikerült megoldani",
	t2011: "Meghatározatlan parancs művelet attribútum értéke Az attribútum értéke érvénytelen",
	t2012: "Hiba a riasztás hivatkozási számában vagy a riasztás nincs konfigurálva",
	t2013: "A bemeneti cgi paraméter karakterlánc túl hosszú.",
	t2014: "Az eszköz címe nem található",
	t2015: "Nem sikerült az írási jogosultság megadása",
	t2016: "Nincs konfigurálva előzmény",
	t2017: "Nem küldtek vissza előzményadatokat, időhiba lehet",
	t2018: "Fájlhibák létrehozása",
	t2019: "Olvasási fájl hibák",
	t2020: "Túllépte az eszközlista maximális méretét",
	t2021: "nem használt",
	t2022: "Érvénytelen előzményindex",
	t2023: "Nem található a tábla pontindex a csomóponttípusból, csomópontból, modból és pontból.",
	t2024: "Az előzmények indexe nem található",
	t2025: "Az előzménylekérdezés inicializálása nem történt meg az előzménylekérdezés elindítása előtt.",
	t2026: "Egy hq_start_query lekérdezést kíséreltek meg, miközben egy előzménylekérdezés aktív",
	t2027: "Nem található parancsattribútum, amikor néhány elvárt",
	t2028: "A korszak kezdete vég nélkül biztosított, vagy fordítva",
	t2029: "Nem sikerült idő- és dátumformátumról korszakmásodpercekre konvertálni",
	t2030: "A parancs megköveteli a query_id értéket, de egyik sem szerepel",
	t2031: "Hiba az XML válasz létrehozásakor",
	t2032: "A megadott query_id nem egyezik egy aktív lekérdezéssel",
	t2033: "Megkísérelték a hq_get_data parancsot, de még nem állnak rendelkezésre adatok",
	t2034: "Olyan mintavételi arányt adtak meg, amely nem tartozik az engedélyezettek közé",
	t2035: "Az átlagolási intervallum kisebb vagy egyenlő, mint az adatok rögzítésének mintavételi gyakorisága",
	t2036: "A leállítási periódus ideje kisebb, mint a kezdeti időszak, vagy a leállítási időszak mínusz a kezdési időszak kisebb, mint az átlagolási intervallum",
	t2037: "A megadott device_id nem egyezik az ak255 által jelenleg ismert egyikkel sem. Vegye figyelembe, hogy az ak255 csak a konfigurált eszközöket ismeri",
	t2038: "Nincsenek azonosító attribútumok",
	t2039: "Érvénytelen azonosító attribútumok",
	t2040: "Nincs menetrendi szám",
	t2041: "Érvénytelen ütemezési szám",
	t2042: "Nincsenek részletek a menetrendről",
	t2043: "Hiányzó on_time",
	t2044: "Hiányzó óra",
	t2045: "Érvénytelen óra",
	t2046: "Hiányzó perc",
	t2047: "Érvénytelen perc",
	t2048: "Hiányzó off_time",
	t2049: "Hiányzó hétköznapok",
	t2050: "Érvénytelen hétköznapok",
	t2051: "Hiányzó ünnepek",
	t2052: "Érvénytelen ünnepnapok",
	t2053: "Hiányzó vagy érvénytelen holiday_start",
	t2054: "Hiányzó vagy érvénytelen holiday_end",
	t2055: "Hiányzó vagy érvénytelen holiday_open",
	t2056: "Hiányzó vagy érvénytelen holiday_close",
	t2057: "A szabadság nincs konfigurálva",
	t2058: "Hiányzó leírás",
	t2059: "A riasztást nem lehet törölni",
	t2060: "Hiányzó vagy érvénytelen store_open",
	t2061: "Hiányzó vagy érvénytelen store_closed",
	t2062: "Riasztás nincs konfigurálva",
	t2063: "Node Offline",
	t2064: "Hiányzó index",
	t2065: "Érvénytelen index",
	t2066: "A relé nem kapcsolható be",
	t2067: "A módosítandó név túl sok karaktert tartalmaz. Ha módosítja az üzlet_neve vagy az egység_neve, akkor < = 16 karakterből kell állnia, ha a storeId1 vagy storeId2 módosítja, akkor < = 8 karakterből kell állnia.",
	t2068: "Hiányzó elemek a parancselemhez társítva.",
	t2069: "A címke_azonosítója nincs megadva",
	t2070: "Hiányzó levélcsomópont az adatokhoz",
	t2071: "Nincs változás az adatokban",
	t2072: "Nem sikerült frissíteni az adatbázist",
	t2073: "Nem írható",
	t2074: "A fészermérő hibája",
	t2075: "Nincsenek méterek",
	t2076: "Érvénytelen fészerszint",
	t2077: "Érvénytelen típus",
	t2078: "A rendszer foglalt",
	t2079: "Az eszközök nincsenek konfigurálva raktérhez",
	t2080: "Az eszköz teljesítménybesorolása érvénytelen",
	t2081: "Érvénytelen indítási késleltetés az eszközhöz",
	t2082: "Konfig Foglalt",
	t2083: "I/O szkennelés folyamatban",
	t2084: "Túl sok leolvasztás van folyamatban",
	t2085: "Rossz adatbázisverziót próbál betölteni az egységbe",
	t2086: "Az adatbázis betöltése nem sikerült",
	t2087: "Nincs elérhető webmemória",
	t2088: "A keresletreakció nincs konfigurálva (csak az AK355 rendszer)",
	t2089: "Hiányzó vagy helytelen fájlméret.",
	t2090: "Hiányzó kódolt fájl (b64 elemek)",
	t2091: "Nem sikerült az adatbázist RAM lemezre menteni.",
	t2092: "A fájl mérete túl nagy.",
	t2093: "Hiányzó fájlnév.",
	t2094: "Hiányzó fájlazonosító.",
	t2095: "Hiányzik a fájlbetöltési index (Megjegyzés: ez a load_file load_database sorozatindexe).",
	t2096: "A fájlazonosító nem egyezik vagy hiányzik.",
	t2097: "A fájl betöltése már folyamatban van.",
	t2098: "A dekódolt fájl nem egyezik az eredeti fájl méretével.",
	t2099: "A fájlbetöltési művelet megszakadt, vagy nem indult el.",
	t2100: "Érvénytelen felhasználói kérés (az eredeti kérelmező nem egyezik az IP-címmel)",
	t2101: "Érvénytelen fájlbetöltési sorrend.",
	t2102: "Érvénytelen könyvtár",
	t2103: "Érvénytelen kiterjesztés",
	t2104: "Érvénytelen eltolás",
	t2105: "Hiba a fájl kódolásakor",
	t2106: "Hiba a fájl dekódolása közben",
	t2107: "Nem sikerült megnyitni a fájlt olvasásra/írásra",
	t2108: "Nem sikerült a fájlba írni",
	t2109: "A fájl írásvédett Flash fájlrendszerben",
	t2110: "Nem sikerült törölni a fájlt.",
	t2111: "A mappa nem törölhető.",
	t2112: "Hiányzó eszközök",
	t2113: "Nem minden eszköz található a(z) URL címről",
	t2114: "Hiányzó történelem paraméterek",
	t2115: "Nem minden előzményparaméter található az URL-ből",
	t2200: "A jelenleg használt szoftververzió ( {{currentVersion}} ) nem ajánlott",
	t2201: "A jelenleg használt szoftververzió ( {{currentVersion}} ) nem ajánlott ( > {{recommendedVersion}} ajánlott)",
	t2202: "Szoftver verzió hiba",
	t2203: "Grafikus szerkesztő",
	t2204: "Szerkesztés",
	t2205: "Felhívni",
	t2206: "Új nézet",
	t2207: "Létrehozva",
	t2208: "Csepp",
	t2209: "Új grafika",
	t2210: "Hozzon létre egy új grafikus nézetet",
	t2211: "Név",
	t2212: "Átnevezés",
	t2214: "Export",
	t2213: "Másolat",
	t2215: "Töröl",
	t2216: "Biztosan törli a(z) \" {{name}} \" nézetet?",
	t2217: "Az összes nézet exportálása",
	t2218: "Több...",
	t2219: "Szabály",
	t2220: "szabályokat",
	t2221: "Szabály neve",
	t2222: "Szerkesztési panel",
	t2223: "Körülmények",
	t2224: "Gradiens",
	t2225: "Fordítsa meg a feltételeket",
	t2226: "Leképezések",
	t2227: "Alakzatok térképezése",
	t2228: "Alakzatazonosító",
	t2229: "Államok",
	t2230: "Leképezés hozzáadása",
	t2231: "Szabály hozzáadása",
	t2232: "Operátor",
	t2233: "Feltétel hozzáadása",
	t2234: "Kezdje szabályok hozzáadásával",
	t2235: "Biztosan törölni szeretné a(z) „ {{name}} ” szabályt?",
	t2236: "A szabályról másolat készül",
	t2237: "Webhelynézetek",
	t2238: "Megtekintésükhöz webhelynézeteket kell létrehoznia",
	t2239: "Menjen a grafikus szerkesztőhöz",
	t2240: "Tovább a részletekhez",
	t2241: "Válassza ki a paramétert",
	t2242: "Nem található paraméter",
	t2243: "Opciók",
	t2244: "Be/Ki bemenet",
	t2245: "Relékimenet",
	t2246: "Érzékelő bemenet",
	t2247: "Változó kimenet",
	t2248: "Általános készülék",
	t2249: "Üres csomópont",
	t2250: "Az előzmények naplózása nem támogatott...",
	t2251: "Próbálja újra",
	t2252: "Nem sikerült lekérni az előzményeket",
	t2253: "Az előzmények betöltése eltarthat egy ideig, és nem támogatja egyszerre több lekérdezést. Kérjük, próbálja újra ezt a kérést.",
	t2254: "Paraméter neve",
	t2255: "Eszköz neve",
	t2256: "Készüléktípus",
	t2257: "Nem minden előzményparamétert lehetett betölteni ( {{n}} sikertelen)",
	t2258: "Rossz felhasználónév vagy jelszó",
	t2259: "Nincs kommunikáció a vezérlővel",
	t2260: "Szoftver frissítés",
	t2261: "Rendszer",
	t2262: "Szoftvervisszaállítás",
	t2263: "Telepítés",
	t2264: "A szoftver visszaállítása egy újabb verziót vált fel",
	t2265: "A szoftver visszaállítása a(z {{version}} biztonsági verzióra",
	t2266: "Adatbázis",
	t2267: "Frissítés elérhető",
	t2268: "Adatbázis exportálása",
	t2269: "Letöltés",
	t2270: "Adatbázis letöltése",
	t2271: "Nem lehet exportálni az adatbázist",
	t2272: "Adatbázis fájl",
	t2999: "Kommunikációs hiba",
	t2998: "Ismeretlen hiba",
	t3000: "Részletek",
	t3001: "Korábbi XML kód itt",
	t3002: "Hozzárendelni",
	t3003: "Állapot",
	t3004: "Szoftverkezelés",
	t3005: "Helyben tárolt fájl",
	t3006: "Online az interneten keresztül",
	t3007: "Győződjön meg arról, hogy az áramellátás ne szakadjon meg a frissítési folyamat során. A rendszer frissítése előtt ajánlatos biztonsági másolatot készíteni a rendszeradatbázisról. A szoftverfrissítés(ek) befejezése után ajánlatos ellenőrizni a rendszer működési állapotát a megfelelő vezérlés és működés megerősítése érdekében.",
	t3008: "Nincs kiválasztva fájl",
	t3009: "Válassza ki a szoftverfájlt",
	t3010: "Fájlok feltöltése",
	t3011: "Biztonsági ellenőrzés",
	t3012: "Fájl kicsomagolása",
	t3013: "Fájl ellenőrzése",
	t3014: "A fájl feltöltése nem sikerült!",
	t3015: "A biztonsági ellenőrzés sikertelen!",
	t3016: "A kibontás nem sikerült!",
	t3017: "A fájl ellenőrzése nem sikerült!",
	t3018: "Fájl letöltése: {{fileName}}",
	t3019: "A fájl sikeresen letöltve!",
	t3020: "Sikertelen letöltés!",
	t3021: "Fájlok feltöltése",
	t3022: "Fájlok letöltése",
	t3023: "OS frissítés",
	t3024: "Edf frissítés",
	t3025: "Fájlok letöltése",
	t3026: "A kiválasztott paraméter értéke sajnos nem volt elérhető",
	t3027: "Importálás",
	t3028: "Legalább egy fájl szükséges",
	t3029: "A fájl nem érvényes",
	t3030: "Grafikus nézetek kezelése",
	t3031: "Feltöltés",
	t3032: "Vándorol",
	t3033: "Grafikus fájl(ok) olvasása, kérjük, várjon...",
	t3034: "Grafikus fájl importálása, kérjük, várjon...",
	t3035: "Válasszon grafikát",
	t3036: "Jelentések",
	t3037: "Adatbázis importálása",
	t3038: "Válassza ki az adatbázisfájlt",
	t3039: "Győződjön meg arról, hogy az áramellátás ne szakad meg a visszaállítási folyamat során. Javasoljuk, hogy a rendszer visszaállítása előtt készítsen biztonsági másolatot a rendszer adatbázisáról. A szoftver visszagörgetése után ajánlatos ellenőrizni a rendszer működési állapotát a helyes vezérlés és működés megerősítése érdekében.",
	t3040: "A rendszerkezelő a frissítés után újraindul",
	t3041: "Kiterjed",
	t3042: "Hibakeresési üzenetek megjelenítése",
	t3043: "Max. engedélyezett karakterek -",
	t3044: "Kérjük, töltsön fel frissítő fájlt",
	t3045: "Jelenlegi SW verzió",
	t3046: "Jelenlegi Danux verzió",
	t3047: "Legújabb SW verzió",
	t3048: "Danux legújabb verziója",
	t3049: "Biztonsági mentés folyamatban, kérjük, várjon",
	t3050: "A fájl telepítése, kérjük, várjon",
	t3051: "Frissítés telepítése",
	t3052: "A készülék újraindul",
	t3053: "Rendszerkezelő újraindítása",
	t3054: "A böngésző most frissül",
	t3055: "Szoftver sikeresen telepítve",
	t3056: "A frissítés nem sikerült, a következő hiba történt:",
	t3057: "Készítsen biztonsági másolatot az aktuális szoftververzióról",
	t3058: "Készítsen biztonsági másolatot az aktuális EDF fájlokról",
	t3059: "A grafikus nézet mentése sikeresen megtörtént",
	t3060: "Alkalmazzon háttérképet",
	t3061: "Egyértelmű",
	t3062: "Újra",
	t3063: "Bolt",
	t3064: "Kör éjszakai kudarcok",
	t3065: "Szívóéjszakai kudarcok",
	t3066: "Letöltött verzió {{fileName}}",
	t3067: "A kiválasztott egységek frissítéséhez nyomja meg a \"Telepítés\" gombot",
	t3068: "{{updateType}} verzió visszaállítása",
	t3069: "Telepítés",
	t3070: "Visszaállítási verzió telepítése",
	t3071: "Az elérhető egységek egyike sem tartalmaz visszaállítási verziót",
	t3072: "Az új verzió online elérhető",
	t3073: "Fájl feltöltése a kiválasztott egységekhez",
	t3074: "Fájl feltöltése a kiválasztott egységhez",
	t3075: "Az egységhez nem érhető el visszaállítási verzió",
	t3076: "Letöltés kész",
	t3077: "Jelenlegi {{updateType}} verzió",
	t3078: "A legújabb {{updateType}} verzió",
	t3079: "A frissítés folyamatban van. Biztosan bezárja az ablakot?",
	t3080: "A Danux nem naprakész! Kérjük, frissítse a Danuxot, majd frissítse újra a szoftvert",
	t3081: "Az alkalmazások nem frissíthetők vissza erre a verzióra",
	t3082: "Alkalmazás információ",
	t3083: "Kiadási megjegyzések",
	t3084: "Feltételek és adatvédelem",
	t3085: "Engedélyek",
	t3086: "Az ütemezések beállításához lépjen a konfigurációhoz",
	t3087: "Sajnos hiba történt az áttelepítés előkészítésekor. Győződjön meg arról, hogy minden egység elérhető a hálózaton, és megfelelően konfigurálva van. \n\n A következő hiba történt:\n {{error}}",
	t3088: "Meglévő grafika importálása vagy áttelepítése",
	t3089: "Kijelző",
	t3090: "Eszköz neve",
	t3091: "Összeomlás",
	t3092: "Az importálás befejeződött",
	t3093: "Kisülési biztonság",
	t3094: "Szívásbiztonság",
	t3095: "Kisütés Cutin",
	t3096: "Kisülési kivágás",
	t3097: "Szíváskivágás",
	t3098: "Szívás Cutin",
	t3099: "Kapacitás",
	t3100: "A migrációs fájlok (flp, dpj vagy json) nem találhatók",
	t3101: "Időtúllépés a fájl elemzése során",
	t3102: "Harmadik féltől származó eszközfájlok és -címek",
	t3103: "Rács",
	t3104: "Zár",
	t3105: "Xml",
	t3106: "Válassza ki a megjelenítendő paramétert",
	t3107: "Eszköz paraméter",
	t3108: "Nincsenek egységek",
	t3109: "Nem található(k) vagy nincs betöltött egység(ek).",
	t3110: "HACCP",
	t3111: "ESZKÖZ",
	t3112: "Nem állnak rendelkezésre haccp adatok",
	t3113: "Egységinformáció",
	t3114: "Frissítések elérhetők",
	t3115: "Lépjen a Szoftver elemre",
	t3116: "Nem kapcsolódik",
	t3117: "Ennek az alkalmazásnak a jelenlegi hozzáférési hatóköre \" {{targetLevel}} \", a megadott felhasználó hozzáférési jogait tekintve e hatókör felett van",
	t3118: "PDF exportálása",
	t3119: "A PDF exportálása sikertelen",
	t3120: "Nagy adatkészletek esetén előfordulhat, hogy az interfész nem reagál, és eltarthat egy ideig, amíg befejeződik",
	t3121: "Kérjük, válasszon menetrendeket a listából",
	t3122: "Az ütemezés beállításához lépjen a konfigurációhoz",
	t3123: "Siker",
	t3124: "Figyelem",
	t3125: "Webhely információ",
	t3126: "Kérjük, válasszon időpontot és adatpontokat a listából",
	t3127: "Csatlakozás",
	t3128: "Nem sikerült kapcsolatot létesíteni ezzel az oldallal, kérjük, forduljon az ügyfélszolgálathoz",
	t3129: "A kiválasztott napon nem állt rendelkezésre HACCP adat. Győződjön meg arról, hogy az előzmények és az idő megfelelően van beállítva a konfigurációs menüben",
	t3130: "Diagnosztikai jelentés",
	t3131: "Válasszon mértékegység(eke)t a diagnosztikai jelentés exportálásához",
	t3132: "Nem érhető el ezen a felhasználói hozzáférési szinten",
	t3133: "Diagnosztikai napló",
	t3134: "nem sikerült letölteni a jelentést",
	t3135: "Betűtípus",
	t3136: "betűméret",
	t3137: "Nincsenek áttelepíthető nézetek",
	t3138: "Asztali verzió letöltése",
	t3139: "A modern böngészők biztonsági korlátozásai miatt (",
	t3140: "RFC1918",
	t3141: ") sajnos nem lehet titkosítatlan HTTP használatával csatlakozni magánhálózati területen belüli eszközökhöz. Konfigurálja a rendszerkezelőt a HTTPS vagy az asztali verzió használatára",
	t3142: "A kiválasztott időszakra vonatkozóan nem állnak rendelkezésre adatok",
	t3143: "Konfigurálja a rendszerkezelőt a HTTPS vagy az asztali verzió használatára.",
	t3144: "Nem tudtunk kommunikálni a rendszerkezelővel. Ez vagy azt jelenti, hogy az eszköz nem tud csatlakozni a rendszerkezelő IP-címéhez vagy gazdagépnevéhez, vagy esetleg manuálisan kell megbíznia az eszközön használt SSL-tanúsítványban.",
	t3145: "Kattintson a következő hivatkozásra egy új lap megnyitásához, hogy megbízható legyen a tanúsítvány, majd térjen vissza erre a képernyőre.",
	t3146: "Aláírás",
	t3147: "DI - digitális bemenetek",
	t3148: "AI - analóg bemenetek",
	t3149: "Lépjen a HACCP konfigurációhoz",
	t3150: "Nincs adat",
	t3151: "A biztonsági másolatot a rendszer a Rendszerkezelőben menti a visszaállítási funkcióhoz",
	t3152: "Válassza ki, hogy mi jelenjen meg a webhelynézet(ek)ben",
	t3153: "Eszközegység",
	t3154: "Grafikus fájlok",
	t3155: "Sajnos hiba történt az edf fájlok és verziók lekérésekor a rendszerkezelőből...",
	t3156: "A telepítendő fájlok kiválasztásához nyomja meg a „Fájlok kiválasztása” gombot",
	t3157: "Válasszon fájlokat",
	t3158: "Biztonsági másolat eltávolítása",
	t3159: "Fájlok elhelyezése",
	t3160: "A biztonsági másolatot a rendszer a Rendszerkezelőben menti a visszaállítási funkcióhoz",
	t3161: "Telepített méret",
	t3162: "Új méret",
	t3163: "Telepítés dátuma",
	t3164: "Új dátum",
	t3165: "Érzékelők",
	t3166: "Relék",
	t3167: "Bemenetek",
	t3168: "Outs",
	t3169: "Mérők",
	t3170: "Generikus",
	t3171: "A jelszó lejárt, adjon meg új jelszót",
	t3172: "Új jelszó",
	t3173: "Jelszó megerősítése",
	t3174: "Jelszó módosítása",
	t3175: "A jelszónak legalább 8 karakterből, 1 nagybetűből, 1 kisbetűből, 1 számból, 1 speciális karakterből kell állnia)",
	t3176: "A jelszavak nem egyeznek",
	t3177: "A jelszó nem egyezhet meg a régi jelszóval",
	t3178: "Sikeres jelszómódosítás",
	t3179: "A jelszó nem módosítható. Hiba az eszközből",
	t3180: "Jelszó visszaállítása",
	t3181: "Generált",
	t3182: "Webhely neve",
	t3183: "Eseményazonosító",
	t3184: "Idő",
	t3185: "Szerep",
	t3186: "Esemény-információ",
	t3187: "A kiválasztott riasztások nyugtázása",
	t3188: "Nyugtázza az összes kiválasztott riasztást",
	t3189: "Adatbázis importálása ide",
	t3190: "Adatbázis importálása",
	t3191: "A fájl típusa legyen .zip",
	t3192: "Válaszd ki a fájlt",
	t3193: "Nyitvatartási idő",
	t3194: "Ünnepek",
	t3195: "Be/Ki ütemezések",
	t3196: "Eszköz",
	t3197: "Nem mentett módosításai vannak. Biztos, hogy el akarsz menni?",
	t3198: "Sajnos a migráció nem sikerült. A rendszer jelenleg foglalt. Kérlek, próbáld újra később",
	t3199: "Töltsön fel képet az aktuális méretekhez igazítva",
	t3200: "Cserélje le a háttérképet",
	t3201: "Cserélje le a háttérképeket Scalable Vector Graphicsra.",
	t3202: "Csak a meglévő fájlokkal azonos nevű SVG-fájlok kerülnek lecserélésre.",
	t3203: "Válassza ki a cserélni kívánt fájl(oka)t",
	t3204: "Képfájlok",
	t3205: "nem lehet feldolgozni. A fájl formátuma érvénytelen.",
	t3206: "{{amount}} fájl sikeresen kicserélve",
	t3207: "{{amount}} fájl cseréje nem sikerült",
	t3208: "A nézetnek nincs háttere",
	t3209: "Feltölthet új háttérképet, vagy lecserélhet egy meglévő háttérképet",
	t3210: "Ha háttérképet cserél, a paraméterek pozíciói megtarthatók, ha:",
	t3211: "A png vagy jpg fájl felbontása megegyezik az aktuális háttérrel",
	t3212: "Egy svg-fájlnak ugyanaz a képaránya (de nem feltétlenül ugyanaz a felbontás)",
	t3213: "A funkció le van tiltva",
	t3214: "Jelenleg az utoljára kapott érték látható, frissítés...",
	t3215: "Töltsön fel egy ben létrehozott grafikus fájlt",
	t3216: "(választható)",
	t3217: "Háttérkép",
	t3218: "Tallózás",
	t3219: "Több...",
	t3220: "Háttérkép hozzáadása (nem kötelező)",
	t3221: "Grafikus fájl",
	t3222: "Időtúllépés a kommunikációban",
	t3223: "Nincs beállítva hűtési ütemezés",
	t3224: "Index",
	t3225: "típus",
	t3226: "Ünnepek",
	t3227: "Napok",
	t3228: "Tovább",
	t3229: "Ki",
	t3230: "ID",
	t3231: "Nyisd ki",
	t3232: "Rajt",
	t3233: "Vége",
	t3234: "Leírás",
	t3235: "Nap",
	t3236: "Bezárás",
	t3237: "EDF fájl(ok) sikeresen feltöltve. Rendszerkezelő újraindítása",
	t3238: "EDF fájl(ok) feltöltése befejeződött, a rendszerkezelő visszaállítása sikeresen megtörtént",
	t3239: "Hiba történt az edf fájl(ok) rendszerkezelőből való lekérésekor",
	t3240: "Az EDF fájl(ok) feltöltése sikertelen. Próbáld meg egy idő után",
	t3241: "Nincs beállítva hűtési ütemezés",
	t3242: "Fájl feltöltése a rendszerkezelőbe",
	t3243: "Adja meg az eszköz típusát – befolyásolja a működéséhez szükséges paramétercímkéket",
	t3244: "Adja meg, hogy az eszköz értékei hogyan érhetők el, és milyen funkciót töltenek be",
	t3245: "Hozzon létre egy riasztást egy kis paraméter alapján",
	t3246: "Címkék",
	t3247: "Hozzon létre egy listát a paraméterekhez rendelhető címkékről",
	t3248: "Csoportok",
	t3249: "Paramétercsoportok megadása",
	t3250: "Regisztrációs szám",
	t3251: "Adattípus",
	t3252: "Címkék listája",
	t3253: "Minimális",
	t3254: "Alapértelmezett",
	t3255: "Maximális",
	t3256: "Regisztrálás típusa",
	t3257: "A regiszter típust a rendszer lefordítja a megfelelő funkciókódokra az olvasáshoz és az íráshoz",
	t3258: "Swap mód",
	t3259: "Az eszköznek küldött ABCD bájtokat tartalmazó érték esetén ez a beállítás a bájtokat (BADC), a szavakat (CDAB) vagy mindkettőt (DCBA) cseréli.",
	t3260: "Csak olvasható",
	t3261: "Fakitermelés",
	t3262: "Engedélyezi az előzmények naplózását ehhez a paraméterhez",
	t3263: "Szöveg átvétele paraméterről riasztásra",
	t3264: "A riasztási zászló helyzete a paraméter legkevésbé jelentős bitjétől számítva",
	t3265: "Kioldó riasztás alacsony értéknél",
	t3266: "Címke",
	t3267: "Elrejt",
	t3268: "A {{field}} kötelező mező.",
	t3269: "A maximumnak nagyobbnak vagy egyenlőnek kell lennie a minimummal",
	t3270: "Az alapértelmezett értéknek [Minimum, Maximum] értékűnek kell lennie",
	t3271: "Az adattípusnak {{reg}} -nak kell lennie a kiválasztott regisztertípushoz {{mt}}",
	t3272: "A név nem tartalmazhat határoló jeleket",
	t3273: "A név nem haladhatja meg a 20 karaktert",
	t3274: "A címkék listája nem lehet üres",
	t3275: "A {{taglist}} címké(ke)t pontosan egyszer kell hozzárendelni.",
	t3276: "A(z) {{grouplist}} csoport(ok) több mint 50 paramétert tartalmaznak.",
	t3277: "Az űrlap hibákat tartalmaz. Kérjük, tekintse át a hibákat, és próbálja újra.",
	t3278: "Beküldés",
	t3279: "A feltöltött EDF aktiválásához alaphelyzetbe kell állítania az egységet. Szeretné most alaphelyzetbe állítani az egységet?",
	t3280: "Állítsa vissza most",
	t3281: "Állítsa vissza később",
	t3282: "{{file}} már létezik. Felül akarja írni?",
	t3283: "Feltöltés {{what}}",
	t3284: "A címkéknek és az értékeknek egyedinek kell lenniük.",
	t3285: "Több",
	t3286: "Az eszköz több tokot vagy csomagot kezel",
	t3287: "Címkék",
	t3288: "Az Internet Explorer élettartama lejárt, és a StoreviewWeb nem támogatja",
	t3289: "Kérjük, váltson támogatott böngészőre, legyen az Edge, Chrome vagy Firefox",
	t3290: "Több információ",
	t3291: "Bezárás",
	t3292: "Hiba az adatok beolvasása során, próbálkozzon újra",
	t3293: "Az EDF-ek biztonsági mentése folyamatban van",
	t3294: "Nincsenek migrálható nézetek",
	t3295: "A rendszer nyilvános IP-címmel van konfigurálva a privát IP mezőben",
	t3296: "A rendszer privát IP-címmel van konfigurálva a nyilvános IP mezőben, előfordulhat, hogy a rendszer nem érhető el távolról, vagy egyes funkciók nem működnek megfelelően",
	t3297: "Olyan IP-címen vagy gazdagépnéven keresztül csatlakozik, amelyet nem ismer a rendszerkezelő, valószínűleg proxyszolgáltatáson keresztül, előfordulhat, hogy egyes funkciók nem működnek megfelelően",
	t3298: "Az eszköz offline állapotban van, az utolsó ismert értéket mutatja",
	t3299: "Eszköz integráció",
	t3300: "A(z) {{taglist}} címke(ek) duplikált(ok).",
	t3301: "Az oldal nem található",
	t3302: "Sajnáljuk, a keresett oldal nem található",
	t3303: "Hibakód: 404",
	t3304: "Ugrás az irányítópultra",
	t3305: "Csak megtekintésre",
	t3306: "Belső IP-cím",
	t3307: "Külső IP-cím",
	t3308: "Domain név",
	t3309: "Nincsenek figyelmeztetések",
	t3310: "Több figyelmeztetés",
	t3311: "Úgy tűnik, hogy a rendszer több egységgel van konfigurálva, de nem tudtunk kapcsolatba lépni egy vagy több slave-egységgel. Kérjük, ellenőrizze, hogy a beállítások helyesek-e. Felhívjuk figyelmét, hogy a több egységből álló beállítás nem támogatja a több tartománynevet, és minden egységhez külön portot kell konfigurálni, ha ugyanazt a tartományt használja.",
	t3312: "Csak megtekintésre",
	t3313: "Ez az elem nem kapcsolódik semmilyen paraméterhez, és biztonságosan eltávolítható",
	t3314: "Paraméter eltávolítása",
	t3315: "Menetrend",
	t3316: "Ekkor ki lesz jelentkezve, és be kell jelentkeznie az új jelszóval",
	t759: "Adatbázis letöltve, az egység visszaállítása és frissítése. Ez több percig is eltarthat.",
	t816: "Riasztás típusa:",
	t818: "Számol :",
	t848: "A DB fájl nem jön létre. Korlátozott hozzáférése van a telepített könyvtárhoz. Kérjük, lépjen kapcsolatba a rendszergazdával.",
	t3317: "Nincsenek exportálási ütemezések",
	t3318: "Válaszra vár a rendszertől",
	t3319: "Nincs hálózati tevékenység",
	t3320: "Az üzlet neve",
	t3321: "Az új verzió {{latestVersion}} elérhető online",
	t3322: "Külső port",
	t3323: "Belső port",
	t3324: "Nem tudunk kommunikálni a bekapcsolt rendszermenedzserrel",
	t3325: "lehet, hogy a tanúsítványban megbízni kell?",
	t3326: "Az ilyen formátumú fájl nem távolítható el",
	t3327: "Használat",
	t3328: "Időben",
	t3329: "Szabadidő",
	t3330: "Hétköznapok",
	t3331: "Hűtési szakasz",
	t3332: "Hővisszanyerés",
	t3333: "Hűtés",
	t3334: "Fűtés",
	t3335: "Aux Heat",
	t3336: "Párátlanítás",
	t3337: "Internet",
	t3338: "Üzenetek",
	t3339: "Kapcsolatok",
	t3340: "Comm",
	t3341: "Nincs találat",
	t3342: "Írja be a kereséshez",
	t3408: "Összes engedélyezése",
	t3409: "Összes letiltása",
	t3410: "Normál",
	t3411: "Szigorú",
	t3412: "Kritikai",
	t3413: "Engedélyezze az összes riasztást",
	t3414: "Riasztások keresése",
	t3415: "Engedélyezve",
	t3417: "Hiba történt a riasztások lekérésekor",
	t3418: "Hiba történt a másolandó eszközök lekérése közben",
	t3419: "Hiba a másolás állapotának ellenőrzése közben",
	t3420: "Az automatikus lekérdezés legfeljebb 2 órás időtúllépés után folytatódik, vagy ha a varázslót megszakítják vagy befejezik",
	t3421: "Másolás vezérlő(k)re",
	t3422: "Másolás a kiválasztott eszköz(ek)re",
	t3423: "A másolás funkció először átmásolja a beállításokat a System Manager adatbázisba, majd letölti a beállításokat a vezérlő eszközökre. A művelet végrehajtása eltarthat egy ideig.",
	t3424: "Nincsenek másolandó eszközök",
	t3425: "Kattintson duplán a másoláshoz",
	t3426: "Hiba az eszközök lekérése közben",
	t3427: "Eszközök másoláshoz",
	t3428: "Válassza ki a másolni kívánt eszköz(eke)t",
	t3429: "Itt csak az azonos típusú vezérlők jelennek meg",
	t3430: "A másolási folyamat sikerült",
	t3431: "Érvénytelen fájl. Várható fájlmodell:",
	t3432: "Hiba a beállítási fájl betöltésekor",
	t3435: "Biztosan áthelyezi a kiválasztott eszközt ebbe a csoportba {{toPackGroup}} ?",
	t3343: "Felfedezés",
	t3344: "Táblázat frissítése",
	t3345: "Hálózatok keresése, kérjük, várjon...",
	t3346: "A hálózati tábla frissül",
	t3347: "Hiba a hálózatok frissítése közben",
	t3348: "Hiba a hálózatok újrakeresése közben",
	t3349: "Hiba a hálózatok lekérése közben",
	t3350: "A hálózati szkennelés befejeződött",
	t3351: "Nincsenek elérhető eszközök",
	t3352: "Pásztázza a hálózatot",
	t3353: "Uncfg",
	t3354: "Az eszközök bármilyen átrendezése a varázslóban hatással lehet az Alsense megfigyelő szolgáltatásaira.",
	t3355: "Hálózat",
	t3356: "Válassza ki a terepi busz típusát",
	t3357: "Ha a Modbus/RS485 engedélyezve van, kiválaszthatja az SLV-t a Danfoss változó sebességű kompresszorhoz. Ha az SNMP-t helyi buszként választja, meg kell adnia egy érvényes alhálózati címtartományt a Danfoss SNMP vezérlőivel.",
	t3358: "Kompresszorok/csomagok",
	t3359: "Kompresszorok/csomagvezérlők kezelése",
	t3360: "Itt csak Pack eszközök kezelhetők, Rack I/O rendszer nem. A beolvasott kompatibilis Multi-Suction Pack vezérlők kezdetben 0 szívócsoportként jelennek meg. Az AK-ST 500 (Service Tool) szükséges a Pack vezérlő konfigurálásához.",
	t3361: "Másolandó mennyiség",
	t3362: "A korábban konfigurált szívócsoport csökkentése az adott szívócsoporthoz tartozó összes párologtatási vezérlőt is eltávolít.",
	t3363: "A szívócsoport száma nem lehet nulla, a minimális sz. A szívócsoportnak legalább egynek kell lennie.",
	t3364: "Hiba történt a kompresszorok/csomagok lekérésekor",
	t3365: "Nincsenek kompresszorok/csomagok",
	t3366: "On-line eszközök",
	t3367: "Off-line eszközök",
	t3369: "Csomag hozzáadása",
	t3370: "Ha a Modbus/RS485 lehetőséget választja, további opciók jelennek meg az SLV/ECFan számára, ezek a hűtéshez használatosak, és a Nem értékre választhatók.",
	t3371: "Áramkör hozzáadása",
	t3372: "Eszközök hozzáadása, másolása vagy eltávolítása. Vegye figyelembe, hogy nem minden eszköztípus teszi lehetővé az összes mező szerkesztését, és az I/O pontok címei a konfigurációban vannak beállítva.",
	t3373: "Ügyvezérlők kezelése",
	t3374: "Adjon hozzá egy eszközt",
	t3375: "Kompresszor/csomagvezérlők kezelése",
	t3376: "Hiba az adatelérésben",
	t3377: "A HVAC elrendezés sikeresen alkalmazva",
	t3378: "Elrendezés HVAC",
	t3379: "Biztos, hogy elhagyja ezt az oldalt?",
	t3380: "A beállítások elveszhetnek",
	t3381: "Kérjük, konfiguráljon egy eszközt",
	t3382: "Lépjen ki a Gyorsbeállításból",
	t3383: "Biztosan ki akar lépni a Gyorsbeállításból?",
	t3384: "Átjárók (AK-PI200)",
	t3385: "Válassza ki a talált átjárókat",
	t3386: "IP Cím",
	t3387: "Forrásvezérlő",
	t3388: "Forrás beállításai",
	t3389: "Forrás riasztások",
	t3390: "Forrás grafikonja",
	t3392: "Grafikon",
	t3393: "Készülékek",
	t3394: "Az automatikus lehívás felfüggesztésre kerül a másolási fázis alatt. A forrás és a céleszköz(ek) főkapcsolója KI lesz kapcsolva, majd visszaáll az eredeti állapotba, amint a másolás befejeződött. A másolási varázsló befejezése után feltétlenül ellenőrizze a főkapcsolót. Ha a kiválasztott eszközök bármelyike aktív riasztásban van, az adott pontra vonatkozó riasztási műveletek módosításai nem módosulnak. Az eredeti beállítás megmarad.",
	t3395: "Válassza ki a forráseszközt a beállítások másolásához",
	t3396: "Időben",
	t3397: "A másolás funkció addig másolja a rendelkezésre álló grafikonpontokat, amíg el nem éri az egységkapacitást. Lépjen a Konfiguráció elemre a meghatározott pontok számának megtekintéséhez",
	t3398: "Forráseszköz-grafikon",
	t3399: "Grafikonpontok konfigurálása a forrásvezérlőhöz",
	t3400: "A HACCP engedélyezése a pontok naplózásához a HACCP hőmérsékleti jelentésben",
	t3401: "Pontok keresése",
	t3402: "Nincs adat",
	t3403: "Hiba a grafikonpontok lekérésekor",
	t3404: "Letiltva",
	t3405: "Forráseszköz riasztások",
	t3406: "Konfigurálja a riasztás részleteit a forrásvezérlőben",
	t3407: "Riasztás neve",
	t3436: "Az eszköz eltávolításra kerül a hozzárendelt csoportból [ {{fromPackGroup}} ]",
	t3437: "Elrendezés Hűtés",
	t3438: "A hűtési elrendezés sikeresen alkalmazásra került",
	t3439: "Újra hozzárendelés",
	t3440: "Kisbetű-vezérlők hozzárendelése a csomagvezérlőkhöz",
	t3441: "Rendelje hozzá a Case vezérlőket (a rendelkezésre álló esetekből) a Compressor/Pack vezérlőkhöz. szívócsoportok kialakítása",
	t3442: "A készülék paraméterei",
	t3443: "Válassza ki a másolás paramétereit",
	t3444: "Élő (online) eszközön végzett munka során előfordulhat, hogy a beállítások késedelmet szenvednek az eszközzel való kommunikáció miatt.",
	t3445: "Válassza ki a másoláshoz szükséges riasztásokat",
	t3446: "Eszköz grafikonja",
	t3447: "Válassza ki a grafikonpontokat a másoláshoz",
	t3448: "Az aktuális eszközbeállítások mentése fájlba",
	t3449: "Az aktuális eszközbeállításokat elmentheti egy fájlba, amelyet később az eszközök konfigurálására használhat. Ha a fájl mentése után ki szeretne lépni a varázslóból, használja a kilépés ikont. Ha csatlakoztatott eszközökkel dolgozik, a kilépés biztosítja a hálózati lekérdezést, és az eszköz főkapcsolója visszatér a megfelelő állapotba.",
	t3450: "A beállításfájl(ok) sikeresen elmentve",
	t3451: "Folytatja vagy kilép a Gyorsbeállításból?",
	t3452: "Fájl(ok) mentése",
	t3453: "Hiba a beállítások lekérése közben",
	t3454: "Forráseszköz beállításai",
	t3455: "Konfigurálja az eszközparamétereket a forrásvezérlőben",
	t3456: "A jelszómezők egyike sem lehet üres",
	t3457: "Legutóbbiak",
	t3458: "Címlista",
	t3459: "Utolsó hozzáférés",
	t3460: "Kérjük, rendszeresen készítsen biztonsági másolatot a Címjegyzékről.",
	t3461: "A címjegyzék sikeresen importálva",
	t3462: "Címjegyzék importálása",
	t3463: "Cím lista exportálás",
	t3464: "Webhely hozzáadása lehetőségre",
	t3465: "Webhely szerkesztése",
	t3466: "Webhely törlése",
	t3467: "A Címjegyzék használatának megkezdése előtt létre kell hoznia egy Címjegyzék jelszót",
	t3468: "A webhely sikeresen hozzáadva a címjegyzékhez",
	t3469: "Már a címjegyzékben",
	t3470: "Hozzáadás a címjegyzékhez",
	t3471: "Bejelentkezés az oldalra",
	t3472: "A webhely információi sikeresen frissítve",
	t3474: "Bejelentés",
	t3475: "A webhely sikeresen eltávolítva a címjegyzékből",
	t3476: "A webhely sikeresen frissítve",
	t3477: "Érvénytelen címjegyzék formátum",
	t3478: "Akciók",
	t3479: "Offline állapotban nem érhető el",
	t3480: "Nem sikerült betölteni az S55 adatbázist",
	t3481: "S55 adatbázis betöltése",
	t3482: "Jegyzőkönyv",
	t3483: "A felhasználónak nincs engedélye a szoftverinformációk megtekintéséhez",
	t3484: "A jelszó csak alfanumerikus vagy numerikus lehet",
	t3485: "A Címjegyzék eléréséhez létre kell hoznia egy Címjegyzék jelszót",
	t3486: "A Címjegyzék jelszó nem megfelelő",
	t3487: "A webhely már szerepel a címjegyzékben",
	t3488: "Hozzon létre egy Címjegyzék jelszót a Címjegyzék használatához. A jelszó csak alfanumerikus vagy numerikus lehet",
	t3489: "Adja meg a Címjegyzék jelszavát",
	t3490: "Nincsenek másolható online eszközök",
	t3491: "Address Book jelszó létrehozása",
	t3492: "Frissítse a Címjegyzék jelszavát",
	t3493: "A webhely jelszava nem lehet üres",
	t3494: "Írja be az importált címjegyzék jelszavát",
	t3495: "A Címjegyzék jelszava sikeresen létrehozva",
	t3496: "A Címjegyzék jelszava sikeresen frissítve",
	t3497: "Az importált címjegyzék jelszava helytelen",
	t3498: "Kérjük, adja meg a webhely jelszavát",
	t3499: "Címjegyzék törlése",
	t3500: "Webhelyek keresése",
	t3501: "Válassza ki az importálandó Címjegyzék fájlt",
	t3502: "Válassza ki a kulcsfájlt a címjegyzék visszafejtéséhez",
	t3503: "Nyomja meg az exportálás gombot a titkosított címjegyzék letöltéséhez",
	t3505: "Titkosítva",
	t3506: "titkosítatlan jelszavak",
	t3507: "Érvénytelen titkos kulcs",
	t3508: "Importálási hiba. Győződjön meg arról, hogy a megfelelő kulcsot importálta",
	t3509: "Nincs elérhető verzió",
	t3510: "Nyissa meg az Alsense Services szolgáltatást (előfizetés szükséges)",
	t3511: "Kérjük, figyelmesen olvassa el az EULA feltételeit. Ha nem tudja elfogadni a feltételeket, nem használhatja az alkalmazást.",
	t3512: "A Címjegyzék és a Címjegyzék jelszava sikeresen törölve",
	t3513: "(kívánt)",
	t3515: "A Címjegyzék üres",
	t3516: "A Címjegyzék jelszava nem lehet üres",
	t3519: "A Címjegyzék törlésével eltávolítja a Címjegyzék jelszavát is.",
	t3520: "Nincs hatótávolságon belül. 0 és {{maxAddr}} között kell lennie",
	t3521: "Nyomja meg az „Exportálás” gombot a Rendszerkezelőben tárolt összes grafikai nézet letöltéséhez",
	t3522: "Nyomja meg a „Letöltés” gombot a rendszerjelentés letöltéséhez",
	t3523: "Csak a fő egység érhető el közvetlen WiFi kapcsolaton",
	t3524: "Nincsenek helyi grafikus nézetek. Új nézetek hozzáadása vagy meglévő nézetek importálása",
	t3525: "Nincsenek távoli grafikus nézetek. Próbáljon más néven keresni",
	t3526: "Nincsenek távoli grafikus nézetek. Új nézetek hozzáadása, meglévő nézetek importálása vagy áttelepítése",
	t3527: "Helyi nézetek (5 {{totalFiles}} )",
	t3528: "{{totalFiles}} helyi grafika a maximum 5-ből",
	t3529: "Távoli nézetek",
	t3530: "A fájl nem bittérkép.",
	t3531: "Hibás a bittérkép hossza.",
	t3532: "A bitkép fejléc mérete hibás.",
	t3533: "Hibás a bittérkép mérete.",
	t3534: "Hiba a paletta méretében.",
	t3535: "Nem kompatibilis .bmp fájl",
	t3536: "Hiba történt a helyi grafikus fájl törlésekor",
	t3537: "Hiba a helyi grafikus fájl sokszorosítása közben",
	t3538: "Hiba a helyi grafikus fájl exportálása közben",
	t3539: "Grafika törlése",
	t3540: "Töröljön egy helyi grafikus fájlt",
	t3541: "A helyi grafikus fájl törlése folyamatban van",
	t3542: "A helyi grafikus fájl sikeresen törölve. A System Manager újraindítása",
	t3543: "A helyi grafikus fájl törlése befejeződött. A Rendszerkezelő sikeresen újraindult",
	t3544: "Duplikált grafika",
	t3545: "Helyi grafikus fájl megkettőzése",
	t3546: "A helyi grafikus fájl duplikálása folyamatban van",
	t3547: "A helyi grafikus fájl sikeresen megkettőzve. A System Manager újraindítása",
	t3548: "A helyi grafikus fájl másolata elkészült. A Rendszerkezelő sikeresen újraindult",
	t3549: "Grafika exportálása",
	t3550: "Exportáljon egy helyi grafikus fájlt",
	t3551: "A helyi grafikus fájl exportálása folyamatban van",
	t3552: "A helyi grafikus fájl sikeresen exportálva",
	t3553: "A rendszerkezelő újraindul ezzel a művelettel",
	t3554: "Biztos, hogy törölni szeretnéd?",
	t3555: "Biztosan másolni akar?",
	t3556: "Nyomja meg az „Exportálás” gombot a Rendszerkezelőben tárolt összes helyi grafikus nézet letöltéséhez",
	t3557: "A helyi grafikus fájlok exportálása folyamatban van",
	t3558: "A helyi grafikus fájlok sikeresen exportálva",
	t3559: "Termék kódja",
	t3560: "További információ erről a termékről",
	t3561: "Fan eco",
	t3562: "Szelep nyitás",
	t3563: "Túlhevítés",
	t3564: "Párologtató nyomás",
	t3565: "Az elpárologtató hőmérséklete",
	t3566: "Kivágott",
	t3567: "Cutin",
	t3568: "Tok ajtó",
	t3569: "Szívási hőmérséklet",
	t3570: "Sínhő",
	t3571: "Elérte a megengedett maximális fájlkorlátot. Törölje az importálandó fájl(ok) kijelölését",
	t3572: "A fájlok importálásához törölje a meglévő fájl(oka)t a rendszerkezelőben",
	t3573: "A helyi grafikus fájl sikeresen importálva. A System Manager újraindítása",
	t3574: "A helyi grafikus fájl(ok) importálása befejeződött. A Rendszerkezelő sikeresen újraindult",
	t3575: "Elérte a megengedett maximális fájlkorlátot. Törölje az importálandó fájl(oka)t",
	t3576: "Maximum 5 grafikus fájl megengedett",
	t3577: "A helyi grafikus fájl sikeresen feltöltve. A System Manager újraindítása",
	t3578: "A helyi grafikus fájl feltöltése befejeződött. A Rendszerkezelő sikeresen újraindult",
	t3579: "Érvénytelen fájlformátum. {{supportedFileFormats}} fájl feltöltése",
	t3580: "A fájl méretének {{maxWidthAllowed}} x {{maxHeightAllowed}} képpontnak kell lennie helyi képernyők esetén",
	t3581: "Távoli nézet",
	t3582: "Helyi nézet",
	t3583: "Storeview Web kezdőlap",
	t3584: "EULA feltételek",
	t3585: "Biztonság",
	t3586: "Biztonsági betartatási szintek",
	t3587: "Visszafelé kompatibilis (a legalacsonyabb biztonsági szint – feltételezve, hogy a fejléc hitelesítése le van tiltva)",
	t3588: "Az SvW / XML kérések felhasználónevet és jelszót tartalmaznak.",
	t3589: "Nincs kérések fertőtlenítése, ugyanaz a biztonság, mint a korábbi kiadásoknál",
	t3590: "Nincs szükség változtatásra az SvW vagy a meglévő XML kapcsolatokon",
	t3591: "Megengedő (átmenetként használható a szigorú szintre)",
	t3592: "Az XML kérések tartalmazhatnak felhasználónevet és jelszót, és/vagy egy munkamenet-token. A munkamenet tokent a bejelentkezési kérelem válaszában kapja meg.",
	t3593: "Ha a munkamenet jogkivonat szerepel az AKSM-auth fejlécben, a rendszer figyelmen kívül hagyja a felhasználóneveket és jelszavakat",
	t3594: "Az XML-kérelem megtisztul, és a kérés válaszában figyelmeztetést ad vissza, ha bármilyen hibát észlel",
	t3595: "Vegye figyelembe ezeket a figyelmeztetéseket a szigorú üzemmódra való átállás előkészítése során",
	t3596: "Ha a munkamenet tokent nem használja, a munkamenet lejárata nem alkalmazható",
	t3597: "Távoli felhasználói felületen már támogatott (SvW)",
	t3598: "Lehetséges/szükséges változtatások harmadik fél XML-szempontjából",
	t3599: "Nincs munkamenet-token használat – nincs szükség változtatásra",
	t3600: "Session token használata - távolítsa el a felhasználónevet / jelszót a törzsből, és figyelje meg a figyelmeztetéseket a Strict előkészítése során",
	t3601: "Szigorú (legmagasabb biztonsági szint)",
	t3602: "Az XML-kérelmek nem tartalmazhatnak felhasználóneveket és jelszavakat, de meg kell adniuk a munkamenet tokent az AKSM-auth fejlécben. Ha hibát fedeznek fel a kérésben, a rendszer elutasítja",
	t3603: "Már támogatott (SvW)",
	t3604: "Szükséges változtatások harmadik fél szemszögéből",
	t3605: "A felhasználónevet és a jelszót el kell távolítani az XML kérés hasznos adattartalmából, és a munkamenet tokent egy AKSM-auth nevű CORS fejlécbe kell helyezni.",
	t3606: "Bejelentkezési hiba Visszalépés",
	t3607: "Mennyi ideig (másodpercekben) vár egy távoli felhasználó, hogy újra megpróbálhasson bejelentkezni egy sikertelen kísérlet után. A várakozási idő halmozottan növekszik minden sikertelen próbálkozással. 10 próbálkozás után az IP, amelyről a felhasználó megpróbált bejelentkezni, 24 órára letiltásra kerül. Ha 0-ra van állítva Bejelentkezési hiba, a visszalépés le van tiltva",
	t3608: "Alapértelmezett 30 s (max. 600 s / 0 = letiltva)",
	t3609: "Ingyenes kísérletek",
	t3610: "Hány bejelentkezési kísérlet engedélyezett a visszalépés alkalmazása előtt",
	t3611: "Alapértelmezett 2 (max. 5)",
	t3612: "Tömb",
	t3613: "Ha engedélyezve van, a Felhasználó + IP blokkolva van a visszalépés során, ellenkező esetben a felhasználó blokkolva van",
	t3614: "Alapértelmezett „Felhasználó” (Felhasználó + IP / Felhasználó)",
	t3615: "Munkamenetek felhasználónként",
	t3616: "A munkamenet az, amikor a felhasználó sikeresen bejelentkezett az egységbe, és a távoli felületen (SvW) belül van.",
	t3617: "Egy felhasználó aktív munkameneteinek száma. Ha 0-ra van állítva, akkor a felhasználó a munkamenetek maximális számát (30) érheti el.",
	t3618: "Alapértelmezett 0",
	t3619: "Munkamenet lejárta",
	t3620: "Hány másodperccel az üresjárati munkamenet lejárta után. Ha egy munkamenet lejár, új bejelentkezés szükséges",
	t3621: "Alapértelmezett 900 s (max. 3600)",
	t3622: "Fejléc hitelesítés",
	t3623: "A HTTPS és a fejléc-hitelesítés engedélyezésekor minden harmadik féltől származó XML-kérelemnek át kell adnia a fejlécben szereplő felhasználónév/jelszó hitelesítési adatokat (alap hitelesítési séma)",
	t3624: "A „Fejléc-hitelesítés használata” engedélyezéséhez a rendszert újra kell inicializálni. A művelet végrehajtásához használja a \"Nyomja meg az inicializáláshoz\" gombot",
	t3625: "Kicsi",
	t3626: "Nagy",
	t3627: "Grafika mentése",
	t3628: "Mentsen el egy helyi grafikus fájlt",
	t3629: "A helyi grafikus fájl sikeresen törölve. A helyi grafikus fájl betöltése folyamatban van",
	t3630: "A helyi grafikus fájl sikeresen betöltődött. A System Manager újraindítása",
	t3631: "A helyi grafikus fájl mentése befejeződött. A Rendszerkezelő sikeresen újraindult",
	t3632: "Biztosan menteni akar?",
	t3633: "Az elemzés nem sikerült",
	t3634: "Nem sikerült elemezni a viz fájlt",
	t3635: "Nem sikerült elemezni a vz2 fájlt",
	t3636: "A Viz fájl megnyitása nem sikerült:",
	t3637: "A migrációs fájlok (vz2, dpj vagy json) nem találhatók",
	t3638: "A migrációs fájlok (pl.) nem találhatók",
	t3639: "A rendszerkezelő újraindul ezzel a művelettel. A rendszerkezelő megengedő vagy szigorú módban van, így a munkamenet-token újraindításkor lejár, és Ön ki lesz jelentkezve a rendszerből",
	t3643: "Kisülési nyomás",
	t3644: "Teljes kW",
	t3645: "Összes KVAR",
	t3646: "Total Kvarh+",
	t3647: "Kereslet",
	t3648: "Teljesítménytényező",
	t3649: "Betűszín",
	t3650: "Háttérszín",
	t3651: "A címjegyzék sikeresen exportálva",
	t3652: "Vezérlési mód",
	t3653: "Sc3 Külső hőmérséklet",
	t3654: "Kibocsátási hőmérséklet",
	t3655: "Kondenzátor üzemi kapacitása",
	t3656: "Nincs exportálandó adat",
	t3657: "A(z {{unit}} felhasználó jelszava lejárt",
	t3658: "Nem lehet bejelentkezni a(z {{unit}} fiókba a megadott hitelesítő adatokkal.",
	t3659: "Frissítse a firmware-verziót, vagy módosítsa a(z {{unit}} biztonsági kényszerítési szintjét a munkamenet-token támogatásához.",
	t3661: "V3gc",
	t3662: "B szakasz",
	t3663: "C szakasz",
	t3664: "Adaptív túlhevítés szabályozás",
	t3665: "Állapot ellenőrzésének oka",
	t3666: "A felhasználó nem tud bejelentkezni. Az egység címe nincs beállítva a proxy konfigurációjában",
	t3667: "A kommunikációs protokoll nincs megadva a proxy konfigurációjában. Ez hibákhoz vezethet",
	t3668: "IT biztonsági információk",
	t3669: "A biztonsági szabványoknak megfelelően a Rendszerkezelő alapértelmezés szerint a szigorú munkamenet-vezérlést használja, ami azt jelenti, hogy a távoli kapcsolat / XML alapú távoli szolgáltatás többé nem fog működni, hacsak nem alkalmaznak munkamenetjogkivonatokat.",
	t3670: "A Danfoss a szigorú módban való folytatást javasolja, de a visszafelé kompatibilitási mód továbbra is elérhető.",
	t3671: "Megjegyzés: ez a Configuration Security menüben módosítható, és nincs hatással a System Manager helyi vezérlésére és a hely működésére.",
	t3672: "További információ a munkamenet-vezérlésről",
	t3673: "Visszafelé mód",
	t3674: "Szigorú mód",
	t3675: "A visszafelé kompatibilis módra kattintva az összes frissített firmware-rel rendelkező rendszerkezelő visszafelé kompatibilis módba kerül, újraindul, és az alkalmazás kijelentkezett.",
	t3676: "A szívócsoportok megengedett maximális száma 12",
	t3677: "A kiválasztott egységek 3.3.0-ról 4.X-re vagy újabb verzióra történő sikeres frissítése esetén a felhasználó automatikusan kijelentkezett az utolsó lépésben a Befejezés gombra kattintva.",
	t3678: "Az AK2 vezérlők több háza (Pack/Case) nem támogatott",
	t3679: "A 4.0-s kiadással bevezetett biztonsági szabványoknak megfelelően a Rendszerkezelő alapértelmezés szerint a szigorú munkamenet-vezérlést használja, ami azt jelenti, hogy a távoli kapcsolat / XML alapú távoli szolgáltatás a továbbiakban nem fog működni, hacsak nem alkalmaznak munkamenetjogkivonatokat. Emellett a kommunikáció automatikusan HTTPS-re lesz igazítva a rel-re való frissítéssel. 4.0. A Danfoss a szigorú módban való folytatást javasolja, de elérhető visszafelé kompatibilitási mód, amely visszaállítja a rendszert az eddig alkalmazott beállításokra. Kérjük, vegye figyelembe, hogy ha ezt a frissítést távolról, HTTP-egységen végzi, győződjön meg arról, hogy az IT-beállítása felkészült a HTTPS-re (443-as port), vagy hogy a személyzet rendelkezésre álljon a helyszínen.",
	t3680: "Az új biztonsági megvalósításról itt talál további információt",
	t3681: "A webhely http verziójához való hozzáférés a böngésző korlátozásai miatt blokkolva lehet. Látogasson el GYIK oldalunkra a probléma megoldására vonatkozó utasításokért, vagy töltse le a StoreView Web asztali verzióját.",
	t3682: "Rendszerkezelő GYIK",
	t3683: "u17 Ott. levegő",
	t3684: "u91 Kikapcsolási hőm.",
	t3685: "u00 Ctrl. állami",
	t3686: "u12 S3 léghőm.",
	t3687: "u16 S4 léghőm.",
	t3688: "u23 EEV OD %",
	t3689: "u21 Superheat",
	t3690: "u22 SuperheatRef",
	t3691: "u26 EvapTemp Te",
	t3692: "S2 Gáz kimeneti hőm.",
	t3693: "Stw8",
	t3694: "Sd-MT",
	t3695: "Tw állapot",
	t3696: "Tw engedélyezése",
	t3697: "Tw szivattyú működik",
	t3698: "Stw3",
	t3699: "Stw4",
	t3700: "Stw2",
	t3701: "Rcv. ellenőrzési állapot",
	t3702: "Szívás eltolás",
	t3703: "Vrec vezérlési mód",
	t3704: "Vrec állapot",
	t3705: "Ctrl állapot oka",
	t3706: "Hp ellenőrzési állapot",
	t3707: "Pgc max.",
	t3708: "Pgc max. limit P-ban",
	t3709: "Pgc min.",
	t3710: "Gőzkidobó kapacitás",
	t3711: "Folyadékkidobó kapacitás",
	t3712: "Referencia levegő hőmérséklet.",
	t3713: "Befúvott levegő hőm.",
	t3714: "Visszatérő levegő",
	t3715: "Zóna harmatpont",
	t3716: "Zóna páratartalom",
	t3717: "Külső hőm.",
	t3718: "Zóna CO2 szint",
	t3719: "Zóna hőm.",
	t3720: "Kond. referencia",
	t3721: "Kond. ellenőrzési állapot",
	t3722: "Kond. vezérlő állapot",
	t3723: "Kond. ctrl.",
	t3724: "Kond. kért sapkát.",
	t3725: "V3gc állapot",
	t3726: "V3gc OD",
	t3727: "Kond. hőm.",
	t3728: "Hr állapot",
	t3729: "Hr engedélyezése",
	t3730: "HR Fogyasztói kérés",
	t3731: "Shr3",
	t3732: "Shr4",
	t3733: "Shr2",
	t3734: "Shr8",
	t3735: "Szívási ref. To-MT",
	t3736: "Ellenőrzési állapot MT",
	t3737: "Tényleges MT zóna",
	t3738: "A választáshoz offset MT",
	t3739: "Futóképesség MT",
	t3740: "Kért sapkát. MT",
	t3741: "Ideje a következő lépéshez MT",
	t3742: "Po-MT",
	t3744: "Szívási hőm. To-MT",
	t3745: "Teljes aktív energia",
	t3746: "Csúcskereslet",
	t3747: "Teljes teljesítménytényező",
	t3748: "A fázis volt",
	t3749: "B fázis volt",
	t3750: "C fázis volt",
	t3751: "A fázisú erősítők",
	t3752: "B fázisú erősítők",
	t3753: "C fázisú erősítők",
	t3754: "Teljes aktív teljesítmény",
	t3755: "Szívási hőm. To-LT",
	t3756: "Szívási hőm. To-IT",
	t3757: "Szívási referencia LT",
	t3758: "Ellenőrzési állapot LT",
	t3759: "Tényleges LT zóna",
	t3760: "A választáshoz offset LT",
	t3761: "Futóképesség LT",
	t3762: "Kért sapkát. LT",
	t3763: "Ideje a következő lépéshez LT",
	t3764: "Po-LT",
	t3765: "Sd-LT",
	t3766: "Szívási referencia IT",
	t3767: "Állapot ellenőrzése IT",
	t3768: "Tényleges zóna IT",
	t3769: "Futóképesség IT",
	t3770: "Igényelt kapacitás IT",
	t3771: "Ideje a következő IT lépéshez",
	t3772: "Tn Po-IT",
	t2773: "Sd-IT",
	t2774: "IT Comp. indul",
	t3774: "Nincs hatótávolságon belül. {{minAddr}} és {{maxAddr}} között kell lennie",
	t3775: "A művelet le van tiltva ezen az eszközön",
	t3776: "Erre a műveletre az alkalmazás kijelentkezik, ami eltarthat néhány pillanatig.",
	t3777: "A migrációs fájlok (flp vagy json) nem találhatók",
	t3778: "Szavazás",
	t3779: "A szavazás jelenleg szünetel.",
	t3780: "Szeretné folytatni a szavazást?",
	t3781: "Energiamérő",
	t3782: "Több csomópont van kiválasztva",
	t3783: "Nagyítás a csomóponthoz",
	t3784: "Mértékegységek",
	t3785: "A fájl(ok) exportálása sikeres volt",
	t3786: "A cím nem lehet negatív",
	t3787: "Zoom visszaállítása",
	t3788: "Hiba történt az ütemezési minta lépéseinek lekérése közben",
	t3789: "Hiba történt az átmeneti minta lépéseinek mentésekor",
	t3790: "Az átmeneti minta lépései sikeresen mentve",
	t3791: "Színpadi minta",
	t3792: "Nyomja meg a tehermentesített ikonokat a kompresszorokhoz társított ürítők engedélyezéséhez/letiltásához",
	t3793: "Hiányzó adatok",
	t3794: "A következő paraméterek nem adtak vissza semmilyen adatpontot",
	t3795: "A HTTPS/SSL megbízhatóság sikeres volt.",
	t3796: "Most már bejelentkezhet a StoreView webböngészőbe.",
	t3797: "Átirányítás a StoreView webböngészőbe {{timeLeft}} másodperc múlva...",
	t3798: "A szavazás jelenleg felfüggesztve!",
	t3799: "Törölt riasztások (teljes rendszernaplók)",
	t3800: "A HVAC megengedett maximális száma 45.",
	t3801: "Nem sikerült lekérni a változásnaplót",
	t3802: "Csatlakozás a tárhelyhez...",
	t3803: "SCADA tárolókapcsolati időtúllépés",
	t3804: "Nem sikerült csatlakozni a SCADA tárolóhoz",
	t3805: "Érvénytelen adatok",
	t3806: "Válassza ki a jelentés letöltéséhez szükséges mértékegység(eke)t"
};
var hu = {
	lang: lang$4
};

var lang$5 = {
	t0: "Allarmi attivi",
	t1: "All.elimin.",
	t2: "Host",
	t3: "ID",
	t4: "Nome Strumento",
	t5: "Edita Note",
	t6: "Non Aurorizzato all'accettazione degli allarmi",
	t7: "Tipo Allarme",
	t8: "Occorso",
	t9: "Riconosci",
	t10: "Allarme(i) Attivi",
	t11: "Cancell.",
	t12: "Calcoli",
	t13: "Allarme(i)  Cancellato",
	t14: "Silenzia/accetta",
	t15: "Accettazione di tutti gli allarmi ancora da accettare",
	t16: "Autorizzazione fallita",
	t17: "Err.",
	t18: "Controllo Manuale",
	t19: "Visivo",
	t20: "Riepilogo Dati",
	t21: "Vista tecnica",
	t22: "Grafico (S4)",
	t23: "Nodo",
	t24: "Mod",
	t25: "Grafico (Po &amp; Pc)",
	t26: "(nessun allarme trovato)",
	t27: "All. strum.",
	t28: "Vista strumento precedente",
	t29: "Vista strumento successivo",
	t30: "Errore nella lettura allarmi",
	t31: "Allarmi Attuali",
	t32: "Allarme Attuale",
	t33: "Nessun allarme trovato",
	t34: "Dettaglio",
	t35: "Punto",
	t36: "Storico Log",
	t37: "Programmi",
	t38: "Orario d'inizio",
	t39: "Orario fine",
	t40: "Giorni",
	t41: "Festiv.",
	t42: "Stato",
	t43: "Selezione punti dati",
	t44: "OK",
	t45: "Cancella",
	t46: "Avvio",
	t47: "Fine",
	t48: "Data inizio",
	t49: "Frequenza di campionamento",
	t50: "Nessun punto dato selezionato",
	t51: "Ora",
	t52: "Sec",
	t53: "min",
	t54: "ore",
	t55: "Giorno",
	t56: "Tipo Indirizzo",
	t57: "Ind.",
	t58: "Posizione:",
	t59: "Input Nuovo Valore",
	t60: "Aggior.",
	t61: "Selezione punti dati",
	t62: "Seleziona Nuovo Valore",
	t63: "Fuori",
	t64: "on",
	t65: "Recupera punti dati ",
	t66: "Errore nel recupero dati.....",
	t67: "Storico non configurato...",
	t68: "Errore nel recupero punti dati.....",
	t69: "Recupero storico campioni per ",
	t70: "Errore nel recupero storico campioni....",
	t71: "Nome utente",
	t72: "Password",
	t73: "Host IP/Indirizzo URL",
	t74: "OK",
	t75: "Cancella",
	t76: "Nome",
	t77: "Val.",
	t78: "Errore nel recupero lista utente",
	t79: "Input Nuovo Indirizzo",
	t80: "Lista Utenti Fallita, Verificare Host Indirizzo IP/URL",
	t81: "Codice aut",
	t82: "Codice Acct",
	t83: "Seleziona Nuovo Orario e Data ",
	t84: "Seleziona Nuova Durata",
	t85: "Operazione Fallita",
	t86: "Imposta Informazione Autorizzazione",
	t87: "HVAC",
	t88: "Procedere",
	t89: "gestione Sbrinamneto",
	t90: "Avvio Sbrinamento Manuale",
	t91: "Gestione Luci Banco",
	t92: "Luci Manuali Banco On",
	t93: "Luci Manuali Banco Off",
	t94: "Gestione Pulizia Banco",
	t95: "Gestione Manuale Pulizia Banco On",
	t96: "Gestione Manuale Pulizia Banco Off",
	t97: "Interruttore principale",
	t98: "Main Switch Manuale On",
	t99: "Main Switch Manuale Off",
	t100: "Gestione Chiusura",
	t101: "Spegnimento dispositivo",
	t102: "Gira il dispositivo su On",
	t103: "Gestione impostazioni notte",
	t104: "Impostazione Notte On",
	t105: "Impostazione Notte Off",
	t106: "Messaggio inviato...",
	t107: "Controllo Manuale",
	t108: "Chiuso",
	t109: "Luci",
	t110: "Potenza/Misc",
	t111: "Descr. gener.",
	t112: "Imposta AK255 come visualizzazione",
	t113: "Carico....",
	t114: "di",
	t115: "Tutti gli AK255",
	t116: "Preced.",
	t117: "Pagine",
	t118: "Succ.",
	t119: "Parametro",
	t120: "Misure",
	t121: "Recupero Dati...",
	t122: "Errore in sezione lettura ",
	t123: "Temperatura",
	t124: "Ritardo dopo lo sbrinamento",
	t125: "All. se inf.",
	t126: "All. se sup.",
	t127: "Monitoragg.",
	t128: "Offline",
	t129: "Attivo",
	t130: "Host",
	t131: "Ind.",
	t132: "Descrizione",
	t133: "Valv.corrente",
	t134: "Stato Attuale",
	t135: "Regolatori",
	t136: "Unità",
	t137: "Presenza Allarme Attivo ",
	t138: "Presenza Allarmi Attivi ! ",
	t139: "Ingresso Digitale",
	t140: "Uscite relè",
	t141: "Ingr. sensore",
	t142: "Uscite variabili",
	t143: "Controllore Generico",
	t144: "Altri",
	t145: "Punto Visualizzazione",
	t146: "Panoramica Impianto",
	t147: "Log Out dal Sistema",
	t148: "Log On",
	t149: "Disconnetti",
	t150: "Refrigerazione",
	t151: "Panoramica Strumento",
	t152: "Allarmi",
	t153: "Storico",
	t154: "Panoramica CS",
	t155: "Panoramica Impianto",
	t156: "Configur.",
	t157: "Vis. sito",
	t158: "Vista Impianto",
	t159: "Home",
	t160: "Allar.",
	t161: "Setpoint",
	t162: "Dett. strum.",
	t163: "Impostazione",
	t164: "imposta Data",
	t165: "Informazioni",
	t166: "Audit Trail",
	t167: "Calcoli",
	t168: "Gestione file",
	t169: "Manuale D'Aiuto",
	t170: "Info",
	t171: "Traduttore",
	t172: "Salva su Server",
	t173: "Aggiorna File Lingua",
	t174: "Questa operazione aggiornerà i file delle lingue sull'unità. Continuare ?",
	t175: "Esporta Storico",
	t176: "Imposta Valore Successivo",
	t177: "Minimo",
	t178: "Massimo",
	t179: "Nessun Allarme Attivo !",
	t180: "Non Modificabile !",
	t181: "Attendere prego....",
	t182: "Comando Inviato!",
	t183: "Operazione fallita, riprovare più tardi!",
	t184: "Operazione Eseguita!",
	t185: "Operazione non Autorizzata!",
	t186: "Siete sicuri di voler continuare con questa operazione?",
	t187: "EVAP",
	t188: "CENTRALE",
	t189: "Ingressi Monitoraggio",
	t190: "VARIE",
	t191: "Riconosci allarmi",
	t192: "Elimina Allarmi Accettati",
	t193: "Elimina Tutti gli Allarmi Accettati",
	t194: "Non siete autorizzati ad accettare gli allarmi",
	t195: "Non Autorizzato",
	t196: "Volete Accettare questo Allarme ?",
	t197: "Conferma dell'accettazione Allarme",
	t198: "Prego prima effettuare Logon",
	t199: "Selezionare un allarme da eliminare",
	t200: "Conferma per Eliminazione Allarmi",
	t201: "Siete sicuri di voler eliminare questo allarme?",
	t202: "Non autorizzato ad eliminare gli allarmi",
	t203: "Siete sicuri di voler eliminare tutti gli allarmi?",
	t204: "Gli allarmi selezionati non possono essere eliminati",
	t205: "Impostazione Allarme",
	t206: "Per Eseguire questa Operazione è necessario accedere come Supervisore",
	t207: "Nessun dato è ritornato dall'unità",
	t208: "Selezionare un'unità",
	t209: "Selezionare un'unità dalla lista",
	t210: "Numero di Allarmi",
	t211: "Stato allarme",
	t212: "Varie",
	t213: "Elimina Allarme Selezionato",
	t214: "Prego Confermare",
	t215: "Collegamento all'unità fallito, verificare la connesione di rete!",
	t216: "Allarme Sbrinamento",
	t217: "Allarme Digitale",
	t218: "Stato Ingresso Digitale",
	t219: "Ingresso Sbrinamento",
	t220: "Ingresso Pulizia ",
	t221: "Ingresso Digitale",
	t222: "Salva file linguaggio",
	t223: "Unità",
	t224: "Nessuno Strumento configurato nel sistema, Website disabilitato",
	t225: "Avviso:",
	t226: "Impostazione Orario",
	t227: "Comunicazioni",
	t228: "Download File di Report",
	t229: "Prego attendere l'elaborazione del file di report...",
	t230: "Download Report",
	t231: "Stato Allarme/Service",
	t232: "Prego attendere recupero dati...",
	t233: "Connesso",
	t234: "Utente Logged Off",
	t235: "Utente Logged On",
	t236: "Benvenuti !",
	t237: "Licenza non valida",
	t238: "Misuratore Energia",
	t239: "Pannelli Luci",
	t240: "VLT Inverter",
	t241: "Modulo Compressore",
	t242: "Punti Strumento",
	t243: "Punti Calcolati",
	t244: "Pannelli Cutler-Hammer",
	t245: "Vettori",
	t246: "Visualizzazione avanzata",
	t247: "Doppio Click per ingrandire",
	t248: "Clicca qui per ingrandire",
	t249: "Allarme(i) Accettati",
	t250: "Simulatore",
	t251: "Funzione Refrigerazione",
	t252: "Funzione HVAC",
	t253: "Funzione Illuminazione",
	t254: "Funzione Varie",
	t255: "Rete Collegata",
	t256: "Guida",
	t257: "Riferime.",
	t258: "Grafico (Drive Mtr Hz &amp; Amp)",
	t259: "Data Fermata",
	t260: "Stop",
	t261: "L'orario di fermata è attivo o antecedente all'orario di partenza, prego reimpostare orario/data",
	t262: "Orario di creazione log del punto(i)",
	t263: "sono successive all'orario di partenza specificato",
	t264: "Il numero restituito di campioni provenienti da ogni punto di log ha raggiunto 100.000, ci potrebbe volere molto tempo per ottenere la risposta. Si può ridurre questo numero, diminuendo la frequenza di campionamento o l'intervallo.",
	t265: "Processo di creazione coda è impegnato, prego attendere circa 2 minuti e riprovare...",
	t266: "Salva",
	t267: "Pannello Controllo",
	t268: "Primo",
	t269: "Ultimo",
	t270: "Stampa",
	t271: "Zoom +",
	t272: "Zoom -",
	t273: "Mostra/Nascondi Legenda",
	t274: "Esporta Storico Dati a File ",
	t275: "Mostra/Nascondi Griglia",
	t276: "Visualizzazione Pagina Singola",
	t277: "Visualizzazione Multi Pagina",
	t278: "Carica Gruppo Log da File ",
	t279: "Carica Log da File",
	t280: "salva Gruppo Storico a File ",
	t281: "Conteggio Visualizzazione Multi Pagina",
	t282: "Pagina",
	t283: "Selezionare Pagina da visualizzare",
	t284: "File Storico deve essere in  formato Excel .hst!",
	t285: "Nessun dato restituito, prego verificare selezione punto storico e selezione orario/data",
	t286: "Istantanea Storico",
	t287: "Visualizzazione completa Storico",
	t288: "Visualizzazione File Storico",
	t289: "Scegliere...",
	t290: "Con questa operazione si esporterà l'attuale visualizzazione storico a un file .csv ",
	t291: "prego selezione un file di destinazione",
	t292: "File",
	t293: "Carica Gruppo Log... ",
	t294: "Carica Log...",
	t295: "Salva Gruppo Storico...",
	t296: "Vis.",
	t297: "Zoom",
	t298: "Si",
	t299: "No",
	t300: "Recuperare configurazione dal controllore?",
	t301: "Non richiedere",
	t302: "Recupera Configurazione Controllore ",
	t303: "Dati recuperati",
	t304: "Livello Allarme",
	t305: "Az. allar.",
	t306: "Riconosciuto",
	t307: "Salva database nel disco",
	t308: "Nome File",
	t309: "Descrizione Database",
	t310: "Path database",
	t311: "Versione",
	t312: "Tempo",
	t313: "Esporta allarmi",
	t314: "Database salvato in",
	t315: "Salvataggio database fallito, verificare se la directory e il nome file sono validi",
	t316: "Il nome file non è corretto, verificare nome file database",
	t317: "Allarmi esportati correttamente. Selezionare un file per salvare gli allarmi esportati",
	t318: "Selezionare allarmi da esportare",
	t319: "Recupero informazioni allarme(i) da unità",
	t320: "File descrizione",
	t321: "Converti file .hst in formato .csv",
	t322: "Passo 1: Seleziona file .hst",
	t323: "Passo 2: Seleziona i punti e l'intervallo di tempo",
	t324: "Passo 3: Seleziona il file .csv di destinazione",
	t325: "Salva come...",
	t326: "Massimo numero di 250 punti permessi in questa operazione",
	t327: "Punti selezionati",
	t328: "Non è possibile esportare più di 65.536 campioni per punto, prego cambiare campionamento o intervallo di tempo",
	t329: "Raccogli",
	t330: "Raccolta dati di log dall'unità",
	t331: "Seleziona punti raccolti",
	t332: "Nessun valore",
	t333: "Mancante",
	t334: "Sospeso",
	t335: "Prego fare una selezione",
	t336: "Selezionare i punti da visualizzare",
	t337: "Salva nel file .hst",
	t338: "Scarico dei dati terminato, prego selezionare una delle opzioni seguenti:",
	t339: "Selezionare al massimo 8 punti da visualizzare",
	t340: "Salva i log raccolti nel file .hst",
	t341: "Stampa panoramica",
	t342: "Prego selezionare salva o stampa panoramica",
	t343: "Scegliere un formato file",
	t344: "Generazione file overview, attendere prego ...",
	t345: "Il file database selezionato esiste già, vuoi riscriverlo ?",
	t346: "Cruscotto",
	t347: "Vista grafica",
	t348: "Help",
	t349: "Vis. sist.",
	t350: "Mappa",
	t351: "Strumento di traduz.",
	t352: "Manuale",
	t353: "Allarme richiesto...",
	t354: "Aggiornato",
	t355: "Mod.",
	t356: "Regol.",
	t357: "Olio",
	t358: "Temp",
	t359: "Massimizza",
	t360: "Ripristina",
	t361: "Torna indietro...",
	t362: "Comunicazione fallita!",
	t363: "Stato Comun. OK",
	t364: "Messaggio comun. inviato.",
	t365: "Caricando componenti di base",
	t366: "Caricando configurazione componenti",
	t367: "Ctrl Temp",
	t368: "Questa impostazione avrà effetto solo quando i log saranno ridisegnati, vuoi continuare ?",
	t369: "Caricamento, prego attendere",
	t370: "Punti log",
	t371: "Nome unità mancante",
	t372: "Preferenze",
	t373: "Versione simulatore",
	t374: "Energia",
	t375: "Vai alla finestra panoramica",
	t376: "Allarme sistema o nessun dispositivo correlato trovato",
	t377: "Programmi simultanei",
	t378: "Manca file linguaggio",
	t379: "Versione non supportata",
	t380: "Inserire IP unità qui (es. 192.168.1.1)",
	t381: "Prego inserire Ip o URL dell'unità.",
	t382: "Dettagli nascosti",
	t383: "Mostra dettagli",
	t384: "Temp. est.",
	t385: "Ritorno",
	t386: "Alim.",
	t387: "Umidità",
	t388: "Rugiada",
	t389: "vent.",
	t390: "Zona",
	t391: "Spegni",
	t392: "Div.",
	t393: "Temperatura di aspirazione",
	t394: "Capacità attuale",
	t395: "Pressione aspiraz.",
	t396: "Scarico",
	t397: "Compressori",
	t398: "Condens.",
	t399: "Impostaz. Condensatore",
	t400: "Refriger.",
	t401: "Aspiraz. impostata",
	t402: "Aspiraz",
	t403: "Sbrinam",
	t404: "Log formato Danfoss",
	t405: "Formato testo",
	t406: "Questa operazione necessità di resettare l'unità, vuoi resettare l'unità adesso?",
	t407: "Unità resettata con successo, prego ricarica il sito web",
	t408: "Modo applicazione fabbrica",
	t409: "Calcolando ...",
	t410: "minuti",
	t411: "Modo notturno",
	t412: "Luci banco",
	t413: "Programmi simultanei",
	t414: "(risoluzione 10 min)",
	t415: "Vista testo",
	t416: "Esporta come CSV",
	t417: "Salva PNG",
	t418: "Vista Grafica",
	t419: "Vista testo",
	t420: "Program.",
	t421: "Durata",
	t422: "Durata del termine",
	t423: "Nessuno",
	t424: "Controllo temper.",
	t425: "Lancia da",
	t426: "Database caricato da ",
	t427: "Statistiche",
	t428: "Seleziona file",
	t429: "Scegli il file di log che vuoi convertire in formato CSV ...",
	t430: "Finito",
	t431: "Sommar.",
	t432: "File dati Log (*.hst)",
	t433: "Wizard conversione log",
	t434: "Creazione file completa",
	t435: "Scegli i punti log che vuoi convertire in formato CSV ...",
	t436: "Selezionare range periodo",
	t437: "Seleziona il periodo richiesto e premi il pulsante processa",
	t438: "Elaborazione dati",
	t439: "Preparazione dati log, prego attendere",
	t440: "Seleziona il file CSV e salva i dati nel file ...",
	t441: "Punto log non trovato",
	t442: "Errore - Nessun dato log ricevuto",
	t443: "Si è verificato un errore di comunicazione con le unità sulla rete host",
	t444: "Attenzione",
	t445: "Silenzio",
	t446: "Database non salvato, vuoi veramente chiudere la finestra?",
	t447: "Vista tabella",
	t448: "Mostra grafico",
	t449: "Mostra tabella",
	t450: "Sbrinameto man. on",
	t451: "Sbrinameto man. off",
	t452: "Sbrinamento autom.",
	t453: "Imposta controllo sbrinamento",
	t454: "Parzializz. on",
	t455: "Parzializz. off",
	t456: "Liquido",
	t457: "Nessun programma configurato",
	t458: "Vista negozio",
	t459: "Comunicazione con una unità sul network persa!",
	t460: "Setup Rapido",
	t461: "Via",
	t462: "Città",
	t463: "Tel.",
	t464: "Regione",
	t466: "Indice",
	t469: "Rubrica esportata con successo",
	t470: "Cancella elemento",
	t471: "Manometro",
	t472: "Note: maiuscole/minuscole sens.",
	t473: "Online",
	t474: "Scheda RO",
	t475: "SI Scheda",
	t476: "Scheda OI",
	t477: "Calcolo",
	t478: "Imposta ora corrente",
	t479: "armadio dati",
	t480: "Compress. assente",
	t481: "Circuito",
	t482: "Banco",
	t483: "Sconosc.",
	t484: "err. Tipo:",
	t485: "Agg",
	t486: "Copia",
	t487: "Rimuovere",
	t488: "Controllo banco",
	t489: "Tipo banco",
	t490: "LON",
	t491: "Ins. Ammontare da copiare",
	t492: "nessun mome",
	t493: "Configurando layout refrigeraz. ...",
	t494: "Errore configurazione:",
	t495: "Prego verificare configurazione.",
	t496: "La configurazione del layout di refrigerazione è stata completata correttamente. Premere il pulsante ok per uscire da questa procedura guidata.",
	t497: "Configuration Status",
	t498: "Layout di refrigerazione finale",
	t500: "Cod. #",
	t501: "Layout",
	t502: "Impostaz. Sistema",
	t503: "Conc.licenza",
	t504: "Utenti",
	t505: "Non Autorizzato",
	t506: "Prego verificare…",
	t507: "Sei sicuro di abband. Questo schermo?Le tue impost. Saranno perse.",
	t508: "Scansione network controllori",
	t509: "Scansione",
	t510: "Verifica",
	t511: "AK-I/O",
	t512: "Rescan",
	t513: "Includi programmazione offline",
	t514: "Bus apparato",
	t515: "Dispositivi online",
	t516: "Programmazione offline",
	t517: "Indirizzo duplicato",
	t518: "Controlli Compressore",
	t519: "Gruppi di aspirazione",
	t520: "N/D",
	t521: "banchi dispon.",
	t522: "Permetti creazione circuiti multi-banco",
	t523: "Inserire dispositivi",
	t524: "Inserisci circuito",
	t525: "Circuito multi-caso.",
	t526: "Caricamento tabelle in corso...",
	t527: "Richiesta dati allarmi per",
	t528: "Files gruppi log",
	t529: "Files dati storici",
	t530: "Tutti i files",
	t531: "Mostra simboli",
	t532: "Mostra curve",
	t533: "Non è stata raccolta una storia sufficiente.",
	t534: "Recuperare i dati della cronologia più recenti.",
	t535: "Stato/Impostazioni",
	t536: "Ora scaricando",
	t537: "Campioni log…",
	t538: "Recupero",
	t539: "Punti dati...",
	t540: "Intervallo",
	t541: "Minimo",
	t542: "Massimo",
	t543: "Media",
	t544: "Vai alla finestra princ. Allarmi.",
	t545: "Interrompere l'accesso automatico",
	t546: "Connessione a:",
	t547: "Cancella auto-logon",
	t548: "Prego inserire password log-off",
	t549: "Web 1",
	t550: "Web 2",
	t551: "Accedi automaticamente",
	t552: "Disconnettersi dopo il periodo di inattività",
	t553: "Schermo home",
	t554: "Mostra menu di configurazione",
	t555: "Mostra elementi misc nel dashboard",
	t556: "Utilizzare lo schermo intero",
	t557: "Abilita all. sonoro",
	t558: "Permetti progr. Chiudi/minim.",
	t559: "Errore accedendo unità #",
	t560: "Connesso alla rete",
	t561: "Cancallaz. Auto-logon",
	t562: "Riprova connessione a",
	t563: "Disconnesso da",
	t564: "Connesso",
	t565: "Form. orario",
	t566: "Formato data",
	t567: "Press.",
	t568: "Livello luci",
	t569: "Sottoraf.",
	t570: "Subcooler",
	t571: "Giorn.",
	t572: "Settimanale",
	t573: "HST File",
	t574: "CSV File",
	t575: "Domenica",
	t576: "Lunedì",
	t577: "Martedì",
	t578: "Mercoledì",
	t579: "Giovedì",
	t580: "Venerdì",
	t581: "Sabato",
	t582: "Raccolta log cronologia",
	t583: "Abilitare la raccolta automatica",
	t584: "Quando raccogliere",
	t585: "Percorso di salvataggio del file",
	t586: "Selezionare il formato di file",
	t587: "Selezionare la cartella in cui archiviare i file di cronologia...",
	t588: "Abilitare la raccolta automatica della cronologia per recuperare e salvare i registri della cronologia giornalieri o settimanali dei punti della cronologia configurati dell'archivio.",
	t589: "Per visualizzare i file della cronologia HST, passare alla schermata principale della cronologia e selezionare cronologia di importazione. Per visualizzare i file CSV utilizzare qualsiasi applicazione foglio di calcolo.",
	t590: "Se l'indirizzo IP della connessione corrente viene trovato nella rubrica, verranno salvati i punti della cronologia selezionati.",
	t591: "Selezionare i punti dati",
	t592: "Tutti selezionati",
	t593: "Punti selezionati",
	t594: "Cerca",
	t595: "Connessione automatica a",
	t596: "Si prega di verificare",
	t597: "La raccolta automatica della cronologia è abilitata. Sei sicuro di voler chiudere questo programma?",
	t598: "Sei sicuro di voler chiudere questo programma?",
	t599: "Azione",
	t600: "filtro",
	t601: "Tutte le azioni",
	t602: "Ulteriori informazioni...",
	t603: "Modificabile",
	t604: "Funzionamento",
	t605: "Operazione annullata",
	t606: "Download database finito",
	t607: "Database delle unità aggiornato",
	t608: "File trasferiti all'unità con successo",
	t609: "Download terminato, il file è in fase di elaborazione nell'unità",
	t610: "Errore durante l'uploading del file nel PC, prego controllare se il file è scrivibile ed accessibile",
	t611: "Uploading file",
	t612: "Downloading file",
	t613: "Uploading file selezionato(i)",
	t614: "Downloading file selezionato(i)",
	t615: "Cancellazione file",
	t616: "Cancellazione file selezionato(i)",
	t617: "Errore connessione FTP",
	t618: "Download file selezionati.",
	t619: "Errore in connessione FTP. Prego controllare connessione FTP e username/password",
	t620: "FTP connesso",
	t621: "Bytes",
	t622: "I file nell'unità verrebbero sovrascritti da questa operazione, Continuare?",
	t623: "I file sul PC verrebbero sovrascritti da questa operazione, Vuoi continuare?",
	t624: "I file sono stati scaricati nell'unità.",
	t625: "SOLO Refrigerazione",
	t626: "Negozio prossimità",
	t627: "Funzionalità completa",
	t628: "Solo HVAC",
	t629: "Nome file non valido; Assicurarsi che il nome del file abbia meno di 8 caratteri.",
	t630: "Sovrascrivo il codice dell'applicazione ora?",
	t631: "più tardi",
	t632: "Caricato",
	t633: "Puoi scegliere SI per sovrascrivere l'applicazione ora oppure sciegliere Più tardi",
	t634: "Prego selezionare la directory principale del simulatore",
	t635: "Prego selezionare la directory di Windows",
	t636: "Prego selezionare la directory di destinazione",
	t637: "Seleziona la directory Database",
	t638: "FTP disconnesso",
	t639: "Timeout connessione, FTP disconnesso",
	t640: "Upload file selezionato(i) nel PC",
	t641: "Cancella file selezionato(i)",
	t642: "Port server FTP",
	t643: "Porta server web",
	t644: "Percorso locale ",
	t645: "Up",
	t646: "Dim.",
	t647: "Data",
	t648: "Connesso",
	t649: "Disconnesso",
	t650: "Percorso remoto",
	t651: "Spazio libero disponibile:",
	t652: "File(s) selezionati",
	t653: "Files",
	t654: "Dimensione totale:",
	t655: "Clicca per cambiare folder...",
	t656: "Cambia ...",
	t657: "Vuoi cancellare i file selezionato(i)?",
	t658: "Connessione chiusa, uscire",
	t659: "Il(I) nome(i) file selezionato(i)  è(sono) illegale(i) e il download è ignorato.",
	t660: "Alcuni nomi dei file selezionati sono illegali e ignorati.",
	t661: "File trasferiti sul PC con successo",
	t662: "Non c'è più spazio libero disponibile sull'unità.",
	t663: "Trasferimento file(s)",
	t664: "Modificato",
	t665: "Copia file da Flash al disco RAM",
	t666: "Prego selezionare un'Operazione Copia File :",
	t667: "Copia tutti i file EDF configurati dal disco Flash",
	t668: "Copia tutti i file EDF dal disco Flah",
	t669: "I file di EDF/ED3 vengono memorizzati sul disco flash della vostra unità, ma non caricati nella schermata di gestione dei file. Utilizzare le opzioni di seguito per copiare i file EDF memorizzati nell'unità alla schermata File Management",
	t670: "Questa operazione copia tutti i file del EDF configurati nell'unità da disco flash a disco RAM",
	t671: "Questa operazione copia tutti i file EDF dell'unità dal disco flash al disco RAM",
	t672: "Nessun file EDF valido esistente",
	t673: "Copia file EDF completata",
	t674: "file copiati",
	t675: "Conferma download database",
	t676: "Indir. unità",
	t677: "Indirizzo IP/Nome host",
	t678: "Nome file del database",
	t679: "Selez.",
	t680: "Update completato, reset and upgrade unità. Potrà durare alcuni minuti.",
	t681: "Benvenuti nell'Upgrade manager - 1 di 2",
	t682: "Benvenuti nell'Upgrade manager - 2 di 2",
	t683: "Procedere",
	t684: "Controllo pre-upgrade",
	t685: "Firmware locale",
	t686: "Backup Database?",
	t687: "Backup Database",
	t688: "Trasferimento sw",
	t689: "Sovrascrivendo Software",
	t690: "Selez. sw da upgradare",
	t691: "Sw sicuro dalla fabbrica non trovato.",
	t692: "Tu puoi continuare, ma ogni errore che si verificherà durante il processo potrà rendere l'unità inutilizzabile",
	t693: "E' fortemente sconsigliato procedere.",
	t694: "Vuoi continuare?",
	t695: "Aggiornamento remoto del sistema non comportano un rischio di fallimento, che può provocare errori di sistema.",
	t696: "Disclaimer",
	t697: "Upload database terminato",
	t698: "backup database finito",
	t699: "Downloading appl. Finito",
	t700: "Sovrascr. Applicaz.finita",
	t701: "Aggiornamento SW riuscito",
	t702: "Vuoi resettare l'unità ai parametri di fabbrica?",
	t703: "Verifica hardware",
	t704: "Verifica stato apparato",
	t705: "Sicurezza di fabbrica",
	t706: "Attuale vers. FW",
	t707: "Inizio upgrade",
	t708: "Selez. un file database esistente",
	t709: "Selez. un file firmware esistente",
	t710: "Indietro",
	t711: "Firmware locale",
	t712: "Seleziona file .csi",
	t713: "Seleziona file .mai",
	t714: "Download remoto non supportato da questa scheda CPU.",
	t715: "Fallito Dowloading applicaz.",
	t716: "Upload database fallito, riprovare",
	t717: "Sovrascr. Applicaz.finita",
	t718: "Sovrascr. Applicaz. Fallita. Prego ritentare",
	t719: "Sovrascr. Applicaz. Fallita.",
	t720: "Sw sicuro dalla fabbrica non trovato.",
	t721: "Fatto",
	t722: "L'applicazione selezionata può avere un diverso tipo di versione di firmware dell'unità corrente, continuare l'operazione può comportare un rischio di fallimento e di errore di sistema.",
	t723: "Il(I) nome(i) file selezionato(i)  è(sono) illegale(i) e il download è ignorato.",
	t724: "Alcuni nomi dei file selezionati sono illegali e ignorati.",
	t725: "Pin berg unità mancante.",
	t726: "Batteria unità bassa.",
	t727: "Rete host unità fallita.",
	t728: "L'unità è in stato di capannone di carico",
	t729: "Selez. file destinazione",
	t730: "Selez. directory di destinazione",
	t731: "Upload database terminato",
	t732: "Stato Ok",
	t733: "Stato Errore",
	t734: "Stato sconosciuto",
	t735: "Questa operazione può sovrascrivere il file selezionato nel simulatore di destinazione, vuoi continuare?",
	t736: "Download database terminato, occorre resettare il sistema",
	t737: "Download database fallito, occorre riprovare più tardi",
	t738: "L'unità sarà resettata dopo questa operazione. Vuoi continuare?",
	t739: "Seleziona database",
	t740: "Download file Database?",
	t741: "Upload file Database?",
	t742: "Caricamento database nell'unità, prego attendere",
	t743: "Database caricato con successo, ora resettare l'unità",
	t744: "Aggiornamento database terminato, unità resettata con successo",
	t745: "Aggiornamento database fallito! Prego riprovare.",
	t746: "Errore in aggiornamento database",
	t747: "Verifica pre-download",
	t748: "Individuare Database",
	t749: "Download del database",
	t750: "Database Inform. Correnti",
	t751: "Selez. Database per download",
	t752: "Salva database nel PC (Upload)",
	t753: "Carica database nell'unità (Download)",
	t754: "Necessaria autorizz. Supervisore",
	t755: "Inizia upload",
	t756: "Prego selez. operaz. sul database",
	t757: "Inizio download",
	t758: "Attuale vers. FW",
	t760: "Download database effett. Corr.",
	t761: "Benvenuti nel database manager - 1 di 2",
	t762: "Benvenuti nel database manager - 2 di 2",
	t763: "Recupero lista utenti fallita, prego controllare indirizzo IP/URL",
	t764: "L'aggiornamento remoto del sistema non provoca rischio di fallimento, che può rendere inutilizzabile il sistema",
	t765: "Scaricamento database terminato",
	t766: "Scansione Ethernet",
	t767: "Inserisci il primo indirizzo IP, quindi premi start",
	t768: "Scansione",
	t769: "Scansione",
	t770: "Raccolta log cronologia",
	t771: "Colori del grafico analogico",
	t772: "Colori del grafico digitale",
	t773: "Pianificazioni di arresto",
	t774: "Riepilogo defrost",
	t775: "Programma modo notturno",
	t776: "Orari di illuminazione dei casi",
	t777: "Stato caricamento",
	t778: "Allarme raccolta log",
	t779: "Selezionare la cartella in cui memorizzare i file di allarme...",
	t780: "Mensile",
	t781: "Abilitare la raccolta automatica degli allarmi per recuperare e salvare le informazioni sugli allarmi in un file su base periodica.",
	t782: "Separatore CSV",
	t783: "Nessun allarme",
	t784: "Recupero dei conteggi degli allarmi per l'unità:",
	t785: "Recupero dei dettagli dell'allarme dall'unità:",
	t786: "Raccolta automatica allarmi abilitata. Sei sicuro di voler chiudere il programma?",
	t787: "Oraria",
	t788: "Questo aggiornerà il database dell'unità e imposta la posizione al centro della vista corrente. Procedere?",
	t789: "campioni trattati",
	t790: "Configurare un gruppo di aspirazione.",
	t791: "Per ri-utilizzare il Layout guidato più di una volta è necessario reimpostare il layout del database di refrigerazione.",
	t792: "Do this via the Configuration-&gt;Control page and set the number of Racks/Packs to zero.",
	t793: "Nota: eventuali abachi, calcoli o importazioni basati sulla configurazione di refrigerazione precedente dovranno essere riconfigurati.",
	t794: "Processo di copia completato.",
	t795: "Rapporto sulla refrigerazione",
	t796: "Layout HVAC finale",
	t797: "L'impostazione del layout HVAC è stata completata correttamente. Premere il pulsante ok per uscire da questa procedura guidata.",
	t798: "Configurazione del layout HVAC in corso...",
	t799: "Per ri-utilizzare il Layout guidato HVAC più di una volta è necessario reimpostare il layout del database HVAC.",
	t800: "Do this via the Configuration-&gt;Control page and set the number of HVAC units to zero.",
	t801: "Nota: tutte le pianificazioni, i calcoli o le importazioni basate sulla configurazione HVAC precedente dovranno essere riconfigurate.",
	t802: "Schema attiv.",
	t803: "Passi",
	t804: "Livel",
	t805: "Mostra suggerimento dati multipli",
	t806: "Num. Di gradini",
	t807: "Impossibile salvare il file.",
	t808: "Modello non corretto.",
	t809: "Caricamento riuscito.",
	t810: "Aperto",
	t811: "Questa funzione è disponibile solo nella versione desktop di StoreView.",
	t812: "Continua",
	t813: "Uscire dalla procedura guidata",
	t814: "Operazione riuscita. Si desidera continuare o si desidera uscire dalla procedura guidata.",
	t815: "Errore durante il recupero dei dati del logger di allarme.",
	t817: "Device Name : ",
	t819: "In Progress...",
	t820: "I dati di allarme non sono presenti.",
	t821: "Contatore allarmi",
	t822: "Opzioni di visualizzazione mimica",
	t823: "Visualizzare solo il testo",
	t824: "Icona Solo visualizzazione",
	t825: "Testo e icona di visualizzazione",
	t826: "1 Settimana",
	t827: "2 settimane",
	t828: "3 settimane",
	t829: "4 settimane",
	t830: "Impostazioni grafiche",
	t831: "Impossibile visualizzare la diagnostica di allarme, controllare la raccolta di allarmi automatici in base alle preferenze",
	t832: "Il riordino non è consentito con i rack IO o i circuiti inclusi nella configurazione.",
	t833: "Questo indirizzo IP host / URL è già presente nella rubrica. Vuoi aggiornare questa voce?",
	t834: "Sfogliare",
	t835: "Seleziona Cartella Rubrica",
	t836: "Locazione",
	t837: "La localizzazione selezionata non dispone di alcun contenuto della rubrica. Vuoi esportare il contenuto della rubrica esistente nella posizione selezionata?",
	t838: "Codici azione allarme",
	t839: "Il file di rubrica non si trova nel percorso selezionato. La rubrica è caricata dal locale ...",
	t840: "Seleziona la cartella per salvare i logs.",
	t841: "Seleziona una cartella valida per salvare i logs.",
	t842: "Raccolta log allarmi in avanzamento…",
	t843: "Raccolta log history in avanzamento…",
	t844: "Gira in background",
	t845: "Progresso log allarmi",
	t846: "IP/URL host non deve essere bianco",
	t847: "Campo indirizzo non deve essere bianco",
	t849: "Non hai la versione più recente del file, vuoi caricare dati dalla versione più recente?",
	t850: "Conflitto versione",
	t851: "Avanzamento Log History",
	t852: "I campi Nome utente e Password non devono essere vuoti.",
	t853: "Il campo Indirizzo IP non deve essere vuoto.",
	t854: "Il campo Indirizzo IP/URL host non deve essere vuoto.",
	t855: "Il nome utente non deve essere vuoto.",
	t856: "La password non deve essere vuota.",
	t857: "L'SM800 a cui ci si è connessi",
	t858: "non è stata completata la procedura guidata di avvio iniziale. Completare questa attività e riconnettersi all'SM800",
	t859: "Impostare su ora",
	t860: "Input non valido",
	t861: "Il testo è troppo lungo",
	t862: "compensare",
	t863: "Impossibile comunicare con le unità sulla rete host",
	t864: "Verificare che gli indirizzi siano diversi. La configurazione viene aggiornata quando si preme il pulsante Salva.",
	t865: "Selezionare un file di cronologia valido (.hst)",
	t866: "Selezionare un file del gruppo di cronologia valido (.grp)",
	t867: "Colore",
	t868: "Percentuale",
	t869: "Tensione",
	t870: "Corrente",
	t871: "Potere",
	t872: "Frequenza",
	t873: "Concentrazione",
	t874: "Flusso",
	t875: "Velocità",
	t876: "Acidità",
	t877: "Selezionare i grafici nel cassetto inferiore",
	t878: "Salva PNG",
	t879: "Disegnare",
	t880: "Stato sistema...",
	t881: "Stato del sistema e dell'unità",
	t882: "Gataway",
	t883: "Caratteristiche licenze",
	t884: "Versione software",
	t885: "Versione modulo browser",
	t886: "Versione di Danux",
	t887: "Indirizzo Mac",
	t888: "Supporto & Aggiornamenti",
	t889: "Nome unità",
	t890: "Ricollegare",
	t891: "Connessione persa",
	t892: "Nessuna apparecchiatura {{type}} trovata.",
	t893: "La versione software attualmente utilizzata non è supportata",
	t894: "Collegamenti rapidi",
	t895: "attrezzatura",
	t896: "Grafica",
	t897: "Utilità",
	t898: "Rollback software",
	t899: "Diagnostica",
	t900: "Si è verificato un errore",
	t901: "Nessun {{alarms}} dati trovati",
	t902: "Impostazioni",
	t903: "Nome dell'applicazione",
	t904: "Versione dell'applicazione",
	t905: "Messaggio",
	t906: "Tipo di evento",
	t907: "Timestamp",
	t908: "Versione di rollback",
	t909: "Versione attuale",
	t910: "Software",
	t911: "Rollback...",
	t912: "Seleziona l'attrezzatura dall'elenco",
	t913: "Visualizzazione Calendario",
	t914: "Si è verificato un errore durante il caricamento dei dispositivi, riprovare.",
	t915: "Si è verificato un errore durante il caricamento delle pianificazioni, riprovare.",
	t916: "Nessuna attrezzatura trovata",
	t917: "Esportazione pianificazioni",
	t918: "Scegliere CSV o PDF export type",
	t919: "Esporta come CSV",
	t920: "Preparazione del PDF",
	t921: "Esporta in formato PDF",
	t922: "Fornire un IP in questo formato xx.xx.xx.xx[:xxxx]",
	t923: "Nome utente",
	t924: "Login",
	t925: "Rollback",
	t926: "Software DI rollback SM800A",
	t927: "Questa azione rotolerà tutto il software nelle versioni elencate.",
	t928: "Editor dispositivi parte",
	t929: "Testo che spiega la funzione",
	t930: "Aggiorna CDF",
	t931: "Dispositivo mappa",
	t932: "File dispositivo",
	t933: "Numero di registro basato su 1",
	t934: "Tipo di archiviazione",
	t935: "Minimo",
	t936: "Massimo",
	t937: "Sola lettura",
	t938: "Registro",
	t939: "Tipo di messaggio",
	t940: "Parola chiave conversione",
	t941: "Parametri",
	t942: "Addr.",
	t943: "Priorità",
	t944: "pezzo",
	t945: "Negato",
	t946: "Nuovo file",
	t947: "Apri file",
	t948: "Genera file",
	t949: "Gruppo",
	t950: "Versione del prodotto",
	t951: "Errore",
	t952: "Non trovato",
	t953: "Questo dispositivo non è stato trovato",
	t954: "Temperatura esterna.",
	t955: "{{equipment}} non trovato",
	t956: "Casi",
	t957: "Allarme disattivato/agisci correttamente",
	t958: "Disattivazione/azione non riuscita per questo allarme",
	t959: "Si è verificato un errore durante il caricamento delle unità, riprovare.",
	t960: "Nessuna attrezzatura",
	t961: "Nessuna apparecchiatura {{type}} trovata",
	t962: "Configurare il gestore di sistema",
	t963: "Dispositivo",
	t964: "Annulla",
	t965: "È stato modificato il valore del campo, si desidera salvare le modifiche?",
	t966: "Cancella i punti della cronologia selezionati",
	t967: "Shr {{number}}",
	t968: "Velocità della pompa dell'ora",
	t969: "Sd",
	t970: "V3 ore",
	t971: "Riferimento Hr",
	t972: "Stw {{number}}",
	t973: "Tw riferimento",
	t974: "Doppia velocità della pompa",
	t975: "V3tw",
	t976: "Trec",
	t977: "prec",
	t978: "Vrec OD",
	t979: "Riferimento prec",
	t980: "Riferimento Trec",
	t981: "Pc",
	t982: "ss",
	t983: "Vhp OD",
	t984: "Riferimento Pgc",
	t985: "Espulsore DE",
	t986: "pag",
	t987: "Shp",
	t988: "Sgc",
	t989: "Sc3",
	t990: "Sgc ctrl.",
	t991: "cond. capacità",
	t992: "Po",
	t993: "ss",
	t994: "Sd",
	t995: "pz",
	t996: "Capacità di corsa",
	t997: "Certificato del browser",
	t998: "Disconnettersi",
	t1000: "Ordinamento solo su {{count}}",
	t1001: "Caricato {{count}} di {{total}} allarmi",
	t1002: "Riconoscimento riuscito per tutti gli allarmi",
	t1003: "Riconoscimento fallito per tutti gli allarmi",
	t1004: "Riconosciuti solo {{count}} di {{total}} allarmi",
	t1005: "Esportazione non riuscita per tutti gli allarmi",
	t1006: "Esportazione riuscita",
	t1007: "Esportazione non riuscita, è richiesto almeno un tipo di allarme",
	t2000: "-",
	t2001: "La risposta è troppo grande per il buffer di trasmissione XML che è di 32 KB",
	t2002: "Nessun attributo associato all'elemento o comando cmd non ha alcun elemento \"cmd\" o non è stato in grado di scrivere su una variabile o non è stato in grado di leggere una variabile o non è stato possibile leggere un punto o non è stato possibile scrivere on/off modalità di funzionamento dell'ingresso o dell'uscita relè",
	t2003: "Input di comando errato",
	t2004: "Errore nei parametri del post CGI",
	t2005: "Errore durante la conversione della risposta XML dalla sua rappresentazione interna alla stringa XML di risposta",
	t2006: "Errore durante la decodifica della stringa URL",
	t2007: "Overflow di memoria",
	t2008: "Compressione dei dati di risposta non riuscita",
	t2009: "Errore durante l'analisi della richiesta",
	t2010: "Errore di accesso ai dati: una serie di cause che non vengono ulteriormente risolte",
	t2011: "Valore dell'attributo dell'azione di comando non definito Il valore dell'attributo non è valido",
	t2012: "Errore nel numero di riferimento dell'allarme o allarme non configurato",
	t2013: "La stringa del parametro cgi di input è troppo lunga.",
	t2014: "L'indirizzo del dispositivo non è stato trovato",
	t2015: "Autorizzazione a scrivere non riuscita",
	t2016: "Nessuna cronologia configurata",
	t2017: "Nessun dato di cronologia restituito, può essere un errore di tempo",
	t2018: "Crea errori di file",
	t2019: "Leggi gli errori di file",
	t2020: "Dimensione massima dell'elenco dei dispositivi superata",
	t2021: "non usato",
	t2022: "Indice di cronologia non valido",
	t2023: "Impossibile trovare l'indice dei punti della scheda da nodetype, node, mod e point.",
	t2024: "Impossibile trovare l'indice della cronologia",
	t2025: "La query sulla cronologia non è stata inizializzata prima di tentare di avviare una query sulla cronologia.",
	t2026: "È stata tentata una hq_start_query mentre era attiva una query della cronologia",
	t2027: "Nessun attributo di comando trovato quando alcuni sono previsti",
	t2028: "È stato previsto un inizio di epoca senza fine o viceversa",
	t2029: "Impossibile convertire dal formato ora e data ai secondi epoch",
	t2030: "Il comando richiede un query_id ma nessuno fornito",
	t2031: "Errore durante la creazione della risposta XML",
	t2032: "Il query_id fornito non corrisponde a una query attiva",
	t2033: "È stato tentato un comando hq_get_data ma i dati non sono ancora disponibili",
	t2034: "È stata fornita una frequenza di campionamento che non rientra tra quelle consentite",
	t2035: "L'intervallo di media è inferiore o uguale alla frequenza di campionamento alla quale sono stati registrati i dati",
	t2036: "Il tempo dell'epoca di arresto è inferiore all'ora dell'epoca di inizio o il tempo dell'epoca di arresto meno il tempo dell'epoca di inizio è inferiore all'intervallo di media",
	t2037: "Il device_id fornito non corrisponde a nessuno attualmente noto all'ak255. Si noti che solo i dispositivi che sono stati configurati sono noti all'ak255",
	t2038: "Nessun attributo ID",
	t2039: "Attributi ID non validi",
	t2040: "Nessun numero di programma",
	t2041: "Numero di programma non valido",
	t2042: "Nessun dettaglio del programma",
	t2043: "Manca il_tempo",
	t2044: "Ora mancante",
	t2045: "Ora non valida",
	t2046: "Minuto mancante",
	t2047: "Minuto non valido",
	t2048: "Tempo libero mancante",
	t2049: "Mancano i giorni feriali",
	t2050: "Giorni feriali non validi",
	t2051: "Vacanze mancanti",
	t2052: "Festività non valide",
	t2053: "Holiday_start mancante o non valido",
	t2054: "Holiday_end mancante o non valido",
	t2055: "Holiday_open mancante o non valido",
	t2056: "holiday_close mancante o non valido",
	t2057: "Vacanza non configurata",
	t2058: "Descrizione mancante",
	t2059: "Impossibile cancellare l'allarme",
	t2060: "store_open mancante o non valido",
	t2061: "Store_closed mancante o non valido",
	t2062: "Allarme non configurato",
	t2063: "Nodo offline",
	t2064: "Indice mancante",
	t2065: "Indice non valido",
	t2066: "Il relè non può essere attivato",
	t2067: "Il nome da modificare ha troppi caratteri. Se si modifica store_name, o unit_name, deve essere < = 16 caratteri, se si modifica storeId1 o storeId2, deve essere < = 8 caratteri.",
	t2068: "Elementi mancanti associati all'elemento di comando.",
	t2069: "Tag_ID non definito",
	t2070: "Nodo foglia mancante per i dati",
	t2071: "Nessun cambiamento nei dati",
	t2072: "Impossibile aggiornare il database",
	t2073: "Non scrivibile",
	t2074: "Errore contatore capannone",
	t2075: "Nessun metro",
	t2076: "Livello capannone non valido",
	t2077: "Tipo non valido",
	t2078: "Sistema occupato",
	t2079: "Asset non configurati per la riduzione del carico",
	t2080: "Potenza nominale per asset non valida",
	t2081: "Ritardo di avvio non valido per l'asset",
	t2082: "Configurazione occupata",
	t2083: "Scansione I/O in corso",
	t2084: "Troppi sbrinamenti in corso",
	t2085: "Tentativo di caricare una versione errata del database nell'unità",
	t2086: "Caricamento del database non riuscito",
	t2087: "Nessuna memoria web disponibile disponibile",
	t2088: "Risposta alla domanda non configurata (solo il sistema AK355)",
	t2089: "Dimensione file mancante o errata.",
	t2090: "File codificato mancante (elementi b64)",
	t2091: "Impossibile salvare il database sul disco RAM.",
	t2092: "Dimensione del file troppo grande.",
	t2093: "Nome file mancante.",
	t2094: "ID file mancante.",
	t2095: "Indice di caricamento del file mancante (Nota: questo è l'indice di sequenza per load_file load_database).",
	t2096: "L'ID del file non corrisponde o manca.",
	t2097: "Operazione di caricamento file già in corso.",
	t2098: "Il file decodificato non corrisponde alle dimensioni del file originale.",
	t2099: "L'operazione di caricamento del file è stata annullata o non è stata avviata.",
	t2100: "Richiesta utente non valida (il richiedente originale non corrisponde all'indirizzo IP)",
	t2101: "Sequenza di caricamento file non valida.",
	t2102: "Cartella non valida",
	t2103: "Estensione non valida",
	t2104: "Offset non valido",
	t2105: "Errore di codifica del file",
	t2106: "Errore di decodifica del file",
	t2107: "Impossibile aprire il file per la lettura/scrittura",
	t2108: "Impossibile scrivere su file",
	t2109: "Il file è protetto da scrittura nel file system Flash",
	t2110: "Impossibile eliminare il file.",
	t2111: "Impossibile eliminare la cartella.",
	t2112: "Dispositivi mancanti",
	t2113: "Non sono stati trovati tutti i dispositivi dall'URL",
	t2114: "Parametri della cronologia mancanti",
	t2115: "Non sono stati trovati tutti i parametri della cronologia dall'URL",
	t2200: "La versione del software attualmente utilizzata ( {{currentVersion}} ) non è consigliata",
	t2201: "La versione del software attualmente utilizzata ( {{currentVersion}} ) non è consigliata ( > {{recommendedVersion}} consigliata)",
	t2202: "Errore di versione del software",
	t2203: "Editore grafico",
	t2204: "Modifica",
	t2205: "Disegnare",
	t2206: "Nuova vista",
	t2207: "Creato",
	t2208: "Far cadere",
	t2209: "Nuova grafica",
	t2210: "Crea una nuova vista grafica",
	t2211: "Nome",
	t2212: "Rinominare",
	t2214: "Esportare",
	t2213: "Duplicare",
	t2215: "Elimina",
	t2216: "Sei sicuro di voler eliminare la vista \" {{name}} \"?",
	t2217: "Esporta tutte le viste",
	t2218: "Di più...",
	t2219: "Regola",
	t2220: "regole",
	t2221: "Nome della regola",
	t2222: "Pannello Modifica",
	t2223: "Condizioni",
	t2224: "Pendenza",
	t2225: "Inverti le condizioni",
	t2226: "Mappature",
	t2227: "Mappa forme di",
	t2228: "ID forma",
	t2229: "stati",
	t2230: "Aggiungi mappatura",
	t2231: "Aggiungi regola",
	t2232: "Operatore",
	t2233: "Aggiungi condizione",
	t2234: "Inizia aggiungendo regole",
	t2235: "Sei sicuro di voler eliminare la regola \" {{name}} \"?",
	t2236: "Viene eseguita una copia della regola",
	t2237: "Viste del sito",
	t2238: "Devi creare visualizzazioni del sito per visualizzarle",
	t2239: "Vai all'editor grafico",
	t2240: "Vai ai dettagli",
	t2241: "Seleziona parametro",
	t2242: "Nessun parametro trovato",
	t2243: "Opzioni",
	t2244: "Ingresso On/Off",
	t2245: "Uscita Relè",
	t2246: "Ingresso sensore",
	t2247: "Uscita variabile",
	t2248: "Dispositivo generico",
	t2249: "Nodo vuoto",
	t2250: "La registrazione della cronologia non è supportata...",
	t2251: "Riprova",
	t2252: "Impossibile recuperare la cronologia",
	t2253: "Il caricamento della cronologia può richiedere del tempo e non supporta più query contemporaneamente. Riprova questa richiesta.",
	t2254: "Nome parametro",
	t2255: "Nome del dispositivo",
	t2256: "Tipo di Dispositivo",
	t2257: "Non è stato possibile caricare tutti i parametri della cronologia ( {{n}} non riuscito)",
	t2258: "Nome utente o password errati",
	t2259: "Nessuna comunicazione con il controller",
	t2260: "Aggiornamento software",
	t2261: "Sistema",
	t2262: "Rollback software",
	t2263: "Installare",
	t2264: "Il rollback del software sostituirà una versione più recente",
	t2265: "Ripristina il software alla versione di backup {{version}}",
	t2266: "Banca dati",
	t2267: "È disponibile un aggiornamento",
	t2268: "Esporta banca dati",
	t2269: "Scaricamento",
	t2270: "Download del database",
	t2271: "Impossibile esportare il database",
	t2272: "File di database",
	t2999: "Errore di comunicazione",
	t2998: "Errore sconosciuto",
	t3000: "Dettagli",
	t3001: "Codice XML passato qui",
	t3002: "Assegnato a",
	t3003: "Stato",
	t3004: "Gestione del software",
	t3005: "File memorizzato locale",
	t3006: "In linea via Internet",
	t3007: "Assicurarsi che l'alimentazione non venga interrotta durante il processo di aggiornamento. Si consiglia di eseguire un backup del database di sistema prima di aggiornare il sistema. Dopo aver completato gli aggiornamenti software, si consiglia di ispezionare lo stato operativo del sistema per confermare il controllo e il funzionamento corretti.",
	t3008: "Nessun file selezionato",
	t3009: "Seleziona il file del software",
	t3010: "Caricamento di file",
	t3011: "Controllo di sicurezza",
	t3012: "Estrazione file",
	t3013: "File di controllo",
	t3014: "Caricamento file fallito!",
	t3015: "Controllo di sicurezza fallito!",
	t3016: "Estrazione fallita!",
	t3017: "Controllo file fallito!",
	t3018: "Download del file: {{fileName}}",
	t3019: "File scaricato con successo!",
	t3020: "Scaricamento fallito!",
	t3021: "Caricare files",
	t3022: "Scaricare files",
	t3023: "Aggiornamento del sistema operativo",
	t3024: "Aggiornamento Edf",
	t3025: "Scaricare files",
	t3026: "Il valore del parametro selezionato purtroppo non era disponibile",
	t3027: "Importare",
	t3028: "È richiesto almeno un file",
	t3029: "Il file non è valido",
	t3030: "Gestisci le viste grafiche",
	t3031: "Caricamento",
	t3032: "Migrare",
	t3033: "Lettura dei file grafici, attendere...",
	t3034: "Importazione file grafico, attendere...",
	t3035: "Scegli grafica",
	t3036: "Rapporti",
	t3037: "Importa database",
	t3038: "Seleziona il file di database",
	t3039: "Assicurarsi che l'alimentazione non venga interrotta durante il processo di rollback. Si consiglia di eseguire un backup del database di sistema prima del rollback del sistema. Dopo aver completato il rollback del software, si consiglia di ispezionare lo stato operativo del sistema per confermare il controllo e il funzionamento corretti.",
	t3040: "Il Gestore di sistema si riavvierà dopo l'aggiornamento",
	t3041: "Espandere",
	t3042: "Mostra messaggi di debug",
	t3043: "Massimo caratteri consentiti -",
	t3044: "Si prega di caricare il file di aggiornamento",
	t3045: "Versione SW attuale",
	t3046: "Versione corrente di Danux",
	t3047: "Ultima versione SW",
	t3048: "Ultima versione di Danux",
	t3049: "Eseguire il backup, attendere",
	t3050: "File di installazione attendere",
	t3051: "Installazione aggiornamento",
	t3052: "Il dispositivo si sta riavviando",
	t3053: "Riavvio del gestore di sistema",
	t3054: "Il browser si aggiornerà ora",
	t3055: "Software installato correttamente",
	t3056: "Aggiornamento non riuscito, si è verificato il seguente errore:",
	t3057: "Eseguire il backup della versione software corrente",
	t3058: "Eseguire il backup dei file EDF correnti",
	t3059: "La visualizzazione grafica è stata salvata correttamente",
	t3060: "Applicare l'immagine di sfondo",
	t3061: "Chiaro",
	t3062: "Rifare",
	t3063: "Negozio",
	t3064: "Battute d'arresto notturne del circuito",
	t3065: "Battute d'arresto della notte di aspirazione",
	t3066: "Versione scaricata {{fileName}}",
	t3067: "Per aggiornare le unità selezionate, premere 'Installa'",
	t3068: "Rollback della versione {{updateType}}",
	t3069: "Installazione",
	t3070: "Installa la versione di ripristino",
	t3071: "Nessuna delle unità disponibili contiene alcuna versione di rollback",
	t3072: "Nuova versione disponibile online",
	t3073: "Carica il file per le unità selezionate",
	t3074: "Carica il file per l'unità selezionata",
	t3075: "Nessuna versione di rollback disponibile per l'unità",
	t3076: "Download terminato",
	t3077: "Versione corrente {{updateType}}",
	t3078: "Ultima versione {{updateType}}",
	t3079: "L'aggiornamento è in corso, sei sicuro di voler chiudere la finestra?",
	t3080: "Danux non è aggiornato! Aggiorna Danux, quindi aggiorna nuovamente il software",
	t3081: "Non è possibile eseguire il downgrade delle applicazioni a questa versione",
	t3082: "Informazioni sull'applicazione",
	t3083: "Note di rilascio",
	t3084: "Termini e Privacy",
	t3085: "Licenze",
	t3086: "Vai alla configurazione per impostare le pianificazioni",
	t3087: "Purtroppo si è verificato un errore durante la preparazione della migrazione. Assicurarsi che tutte le unità siano raggiungibili sulla rete e configurate correttamente. \n\n Si è verificato il seguente errore:\n {{error}}",
	t3088: "Importa o migra un elemento grafico esistente",
	t3089: "Schermo",
	t3090: "Nome risorsa",
	t3091: "Crollo",
	t3092: "Importazione completata",
	t3093: "Sicurezza di scarico",
	t3094: "Sicurezza di aspirazione",
	t3095: "Scarico Cutin",
	t3096: "Ritaglio di scarico",
	t3097: "Ritaglio di aspirazione",
	t3098: "Aspirazione Cutin",
	t3099: "Capacit�",
	t3100: "Impossibile trovare i file di migrazione (flp, dpj o json).",
	t3101: "File di analisi del timeout",
	t3102: "File e indirizzi di dispositivi di terze parti",
	t3103: "Griglia",
	t3104: "Serratura",
	t3105: "Xml",
	t3106: "Seleziona il parametro da visualizzare",
	t3107: "Parametro del dispositivo",
	t3108: "Nessuna unità",
	t3109: "Nessuna unità trovata o caricata",
	t3110: "HACCP",
	t3111: "RISORSA",
	t3112: "Nessun dato haccp disponibile",
	t3113: "Informazioni sull'unità",
	t3114: "Aggiornamenti disponibili",
	t3115: "Vai a Software",
	t3116: "Non connesso",
	t3117: "L'attuale ambito di accesso per questa applicazione è ' {{targetLevel}} ', l'utente fornito è al di sopra di questo ambito in termini di diritti di accesso",
	t3118: "Esportazione PDF",
	t3119: "Esportazione PDF non riuscita",
	t3120: "Su set di dati di grandi dimensioni, l'interfaccia potrebbe non rispondere e richiedere del tempo per il completamento",
	t3121: "Si prega di selezionare gli orari dall'elenco",
	t3122: "Vai alla configurazione per impostare una pianificazione",
	t3123: "Successo",
	t3124: "Attenzione",
	t3125: "Informazioni sul sito",
	t3126: "Selezionare ora e punti dati dall'elenco",
	t3127: "Collegamento",
	t3128: "Non è stato possibile stabilire la connessione a questo sito, contattare l'assistenza",
	t3129: "Nessun dato HACCP era disponibile alla data prescelta. Assicurati che la cronologia e l'ora siano configurate correttamente nel menu di configurazione",
	t3130: "Rapporto diagnostico",
	t3131: "Selezionare le unità per l'esportazione del rapporto diagnostico",
	t3132: "Non disponibile a questo livello di accesso utente",
	t3133: "Registro diagnostico",
	t3134: "Impossibile scaricare il rapporto",
	t3135: "Font",
	t3136: "dimensione del font",
	t3137: "Nessuna vista da migrare",
	t3138: "Scarica la versione desktop",
	t3139: "A causa delle restrizioni di sicurezza nei browser moderni (",
	t3140: "RFC1918",
	t3141: ") purtroppo non è possibile connettersi a dispositivi all'interno di uno spazio di rete privato utilizzando HTTP non crittografato. Configura il tuo gestore di sistema per utilizzare HTTPS o utilizzare la versione desktop",
	t3142: "Nessun dato disponibile per il periodo selezionato",
	t3143: "Configura il tuo gestore di sistema per utilizzare HTTPS o utilizzare la versione desktop.",
	t3144: "Non siamo riusciti a comunicare con il Gestore di sistema. Ciò significa che il tuo dispositivo non può connettersi all'IP o al nome host del Gestore di sistema o che potresti dover considerare attendibile manualmente il certificato SSL utilizzato sul dispositivo.",
	t3145: "Fare clic sul collegamento seguente per aprire una nuova scheda in modo da considerare attendibile il certificato e quindi tornare a questa schermata.",
	t3146: "Firma",
	t3147: "DI - ingressi digitali",
	t3148: "AI - ingressi analogici",
	t3149: "Vai alla configurazione HACCP",
	t3150: "Nessun dato",
	t3151: "Il backup viene salvato in System Manager per la funzione Rollback",
	t3152: "Seleziona cosa visualizzare nelle viste del sito",
	t3153: "Unità patrimoniale",
	t3154: "File grafici",
	t3155: "Sfortunatamente si è verificato un errore durante il recupero dei file e delle versioni edf dal gestore di sistema...",
	t3156: "Per selezionare quali file installare, premi il pulsante \"Scegli file\".",
	t3157: "Scegliere i file",
	t3158: "Rimozione backup",
	t3159: "File di staging",
	t3160: "Il backup viene salvato in System Manager per la funzione Rollback",
	t3161: "Dimensioni installate",
	t3162: "Nuova taglia",
	t3163: "Data di installazione",
	t3164: "Nuova data",
	t3165: "Sensori",
	t3166: "Relè",
	t3167: "Ingressi",
	t3168: "Out",
	t3169: "Metri",
	t3170: "Generico",
	t3171: "Password scaduta, inserisci una nuova password",
	t3172: "Nuova password",
	t3173: "Conferma password",
	t3174: "Cambia la password",
	t3175: "La password deve contenere minimo 8 caratteri 1 maiuscolo, 1 minuscolo, 1 numero, 1 carattere speciale)",
	t3176: "le passwords non corrispondono",
	t3177: "La password non può essere uguale alla vecchia password",
	t3178: "Modifica password riuscita",
	t3179: "La password non può essere modificata. Errore dal dispositivo",
	t3180: "Reimpostazione della password",
	t3181: "Generato",
	t3182: "Nome del sito",
	t3183: "ID evento",
	t3184: "Tempo",
	t3185: "Ruolo",
	t3186: "Informazioni sull'evento",
	t3187: "Riconosci gli allarmi selezionati",
	t3188: "Riconosci tutti gli allarmi selezionati",
	t3189: "Importa database in",
	t3190: "Importazione database",
	t3191: "Il tipo di file dovrebbe essere .zip",
	t3192: "Seleziona il file",
	t3193: "Orari di apertura",
	t3194: "Vacanze",
	t3195: "Orari di accensione/spegnimento",
	t3196: "Bene",
	t3197: "Hai modifiche non salvate. Sei sicuro di voler partire?",
	t3198: "Purtroppo la migrazione non è riuscita. Il sistema è attualmente occupato. Per favore riprova più tardi",
	t3199: "Carica l'immagine adattandola alle dimensioni attuali",
	t3200: "Sostituisci immagine di sfondo",
	t3201: "Sostituisci le immagini di sfondo con la grafica vettoriale scalabile.",
	t3202: "Vengono sostituiti solo i file SVG con lo stesso nome dei file esistenti.",
	t3203: "Seleziona i file da sostituire",
	t3204: "File di immagine",
	t3205: "non può essere elaborato. Il file ha un formato non valido.",
	t3206: "{{amount}} file sostituiti correttamente",
	t3207: "Impossibile sostituire {{amount}} file",
	t3208: "La vista non ha sfondo",
	t3209: "Puoi caricarne una nuova o sostituire un'immagine di sfondo esistente",
	t3210: "Se si sostituisce un'immagine di sfondo, le posizioni dei parametri possono essere mantenute se:",
	t3211: "Un file png o jpg ha la stessa risoluzione dello sfondo corrente",
	t3212: "Un file svg ha le stesse proporzioni (ma non necessariamente la stessa risoluzione)",
	t3213: "La funzione è disabilitata",
	t3214: "Attualmente mostra l'ultimo valore ricevuto, aggiornando...",
	t3215: "Carica un file grafico creato in",
	t3216: "(opzionale)",
	t3217: "Immagine di sfondo",
	t3218: "Navigare",
	t3219: "Di più...",
	t3220: "Aggiungi un'immagine di sfondo (opzionale)",
	t3221: "File grafico",
	t3222: "Timeout in comunicazione",
	t3223: "Nessun programma di refrigerazione configurato",
	t3224: "Indice",
	t3225: "Tipo",
	t3226: "Vacanze",
	t3227: "Giorni",
	t3228: "SU",
	t3229: "Spento",
	t3230: "ID",
	t3231: "Aprire",
	t3232: "Inizio",
	t3233: "Fine",
	t3234: "Descrizione",
	t3235: "Giorno",
	t3236: "Chiudere",
	t3237: "File Edf caricati correttamente. Riavvio del gestore di sistema",
	t3238: "Il caricamento dei file Edf è terminato, il gestore di sistema è stato ripristinato correttamente",
	t3239: "Errore durante il recupero dei file edf dal gestore di sistema",
	t3240: "Caricamento file Edf non riuscito. Prova dopo un po'",
	t3241: "Nessun programma di refrigerazione configurato",
	t3242: "Carica il file sul gestore di sistema",
	t3243: "Specificare il tipo di dispositivo: influisce sui tag dei parametri richiesti per utilizzarlo",
	t3244: "Specificare come si accede ai valori del dispositivo e quale funzione svolgono",
	t3245: "Crea un allarme basato su un bit di un parametro",
	t3246: "Etichette",
	t3247: "Creare un elenco di etichette che possono essere assegnate a un parametro",
	t3248: "Gruppi",
	t3249: "Specificare i gruppi di parametri",
	t3250: "Numero di registro",
	t3251: "Tipo di dati",
	t3252: " Elenco delle ubicazioni disponibili",
	t3253: "Minimo",
	t3254: "predefinito",
	t3255: "Massimo",
	t3256: "Tipo di registro",
	t3257: "Il tipo di registro viene convertito nei codici funzione corrispondenti per la lettura e la scrittura",
	t3258: "Modalità IP",
	t3259: "Per un valore con byte ABCD inviati al dispositivo, questa opzione scambia byte (BADC), parole (CDAB) o entrambi (DCBA).",
	t3260: "Sola lettura",
	t3261: "Registrazione",
	t3262: "Abilita la registrazione della cronologia per questo parametro",
	t3263: "Allarme temperatura preso in carico",
	t3264: "Posizione del flag di allarme contando da loro il bit meno significativo del parametro",
	t3265: "Attivare l'allarme su valore basso",
	t3266: "Etichetta",
	t3267: "Nascondere",
	t3268: "{{field}} è un campo obbligatorio.",
	t3269: "La temperatura minima non può essere più alta di quella massima.",
	t3270: "Il valore predefinito deve essere in [Minimo, Massimo]",
	t3271: "Il tipo di dati deve essere {{reg}} per il tipo di registro selezionato {{mt}}",
	t3272: "Deve contenere {0} cifre",
	t3273: "Il nome non deve superare i 20 caratteri",
	t3274: "L'elenco delle etichette non deve essere vuoto",
	t3275: "I tag {{taglist}} devono essere assegnati esattamente una volta.",
	t3276: "I gruppi {{grouplist}} contengono più del massimo di 50 parametri.",
	t3277: "Il modulo contiene errori, controlla gli errori e riprova.",
	t3278: "Invia",
	t3279: "Per attivare l'EDF caricato è necessario resettare l'unità. Vuoi ripristinare l'unità ora?",
	t3280: "Ripristina ora",
	t3281: "Reimposta più tardi",
	t3282: "{{file}} esiste già. Vuoi sovrascriverlo?",
	t3283: "Carica {{what}}",
	t3284: "Le etichette e i valori devono essere univoci.",
	t3285: "Multi",
	t3286: "Il dispositivo gestisce più casse o pacchi",
	t3287: "Tag",
	t3288: "Internet Explorer è fuori uso e non è supportato da StoreviewWeb",
	t3289: "Passa a un browser supportato, Edge, Chrome o Firefox",
	t3290: "Maggiori informazioni",
	t3291: "Chiudere",
	t3292: "Impossibile leggere i dati, riprovare",
	t3293: "Backup EDF in corso",
	t3294: "Non ci sono viste da migrare",
	t3295: "Il sistema è configurato con un IP pubblico nel campo IP privato",
	t3296: "Il sistema è configurato con un IP privato nel campo IP pubblico, il sistema potrebbe non essere accessibile da remoto o alcune funzioni potrebbero non funzionare correttamente",
	t3297: "Sei connesso tramite un IP o un nome host che non è noto al Gestore di sistema, probabilmente tramite un servizio proxy, alcune funzioni potrebbero non funzionare correttamente",
	t3298: "Il dispositivo è offline e mostra l'ultimo valore noto",
	t3299: "Integrazione del dispositivo",
	t3300: "I tag {{taglist}} sono duplicati.",
	t3301: "pagina non trovata",
	t3302: "Siamo spiacenti, la pagina richiesta non è stata trovata",
	t3303: "Codice di errore: 404",
	t3304: "Vai a CRUSCOTTO",
	t3305: "Visualizzare solamente",
	t3306: "Indirizzo IP interno",
	t3307: "Indirizzo IP esterno",
	t3308: "Nome del dominio",
	t3309: "Nessun avviso",
	t3310: "Avvisi multipli",
	t3311: "Sembra che il sistema sia configurato con più unità ma non siamo riusciti a contattare una o più unità slave, assicurati che le impostazioni siano corrette. Tieni presente che una configurazione a più unità non supporta più nomi di dominio e che ogni unità deve essere configurata con una porta distinta quando si utilizza lo stesso dominio",
	t3312: "Visualizzare solamente",
	t3313: "Questo elemento non ha alcuna associazione con nessun parametro e può essere rimosso in modo sicuro",
	t3314: "Rimuovi parametro",
	t3315: "Programma",
	t3316: "Ora verrai disconnesso e dovrai accedere con la nuova password",
	t759: "Database scaricato, ripristino e aggiornamento dell'unità. Questo può richiedere diversi minuti.",
	t816: "Tipo di allarme:",
	t818: "Contare :",
	t848: "Il file DB non viene creato. Hai un accesso limitato alla directory installata. Si prega di contattare l'amministratore di sistema.",
	t3317: "Nessuna pianificazione da esportare",
	t3318: "In attesa di risposta dal sistema",
	t3319: "Nessuna attività di rete",
	t3320: "Nome del negozio",
	t3321: "Nuova versione {{latestVersion}} disponibile online",
	t3322: "Porto esterno",
	t3323: "Porto Interno",
	t3324: "Impossibile comunicare con il gestore di sistema attivo",
	t3325: "forse il certificato deve essere attendibile?",
	t3326: "Il file di questo formato non può essere rimosso",
	t3327: "Utilizzo",
	t3328: "Puntuale",
	t3329: "Tempo libero",
	t3330: "Giorni della settimana",
	t3331: "Fase di raffreddamento",
	t3332: "Recupero del calore",
	t3333: "Raffreddamento",
	t3334: "Riscaldamento",
	t3335: "Riscaldamento ausiliario",
	t3336: "Deumidificazione",
	t3337: "Internet",
	t3338: "Messaggi",
	t3339: "Contatti",
	t3340: "comm",
	t3341: "Nessun articolo trovato",
	t3342: "Digita per cercare",
	t3408: "Abilita tutto",
	t3409: "Disabilita tutto",
	t3410: "Normale",
	t3411: "Acuto",
	t3412: "Critico",
	t3413: "Abilita tutti gli allarmi",
	t3414: "Cerca allarmi",
	t3415: "Abilitato",
	t3417: "Errore durante il recupero degli allarmi",
	t3418: "Errore durante il recupero dei dispositivi da copiare",
	t3419: "Errore durante il controllo dello stato della copia",
	t3420: "Il polling automatico riprenderà dopo un timeout massimo di 2 ore o se la procedura guidata viene annullata o terminata",
	t3421: "Copia sul/sui controller",
	t3422: "Copia sui dispositivi selezionati",
	t3423: "La funzione di copia copierà prima le impostazioni nel database System Manager, quindi scaricherà le impostazioni nei dispositivi controller. Potrebbe volerci del tempo per completare l'azione.",
	t3424: "Nessun dispositivo selezionato da copiare",
	t3425: "Fare doppio clic per copiare",
	t3426: "Errore durante il recupero dei dispositivi",
	t3427: "Dispositivi per la copia",
	t3428: "Seleziona il/i dispositivo/i da copiare",
	t3429: "Qui verranno mostrati solo i controller dello stesso tipo",
	t3430: "Il processo di copia è riuscito",
	t3431: "File non valido. Modello di file previsto:",
	t3432: "Errore durante il caricamento del file di impostazioni",
	t3435: "Vuoi davvero spostare il dispositivo selezionato in questo gruppo {{toPackGroup}} ?",
	t3343: "Scoperta",
	t3344: "Aggiorna tabella",
	t3345: "Scansione delle reti, attendere prego...",
	t3346: "La tabella di rete viene aggiornata",
	t3347: "Errore durante l'aggiornamento delle reti",
	t3348: "Errore durante la nuova scansione delle reti",
	t3349: "Errore durante il recupero delle reti",
	t3350: "Scansione di rete completata",
	t3351: "Nessun dispositivo disponibile",
	t3352: "Scansione di rete",
	t3353: "Non config.",
	t3354: "Qualsiasi riorganizzazione dei dispositivi nella procedura guidata potrebbe influire sui servizi di monitoraggio Alsense.",
	t3355: "Rete",
	t3356: "Seleziona il tipo di bus di campo",
	t3357: "Se Modbus/RS485 è abilitato, puoi selezionare SLV per il compressore a velocità variabile Danfoss. Quando selezioni SNMP come bus locale, ti verrà richiesto di immettere un intervallo di indirizzi di subnet valido dai controlli SNMP Danfoss",
	t3358: "Compressori/Pacchetti",
	t3359: "Gestire i compressori/controller dei gruppi",
	t3360: "Qui possono essere gestiti solo i dispositivi Pack, non il sistema Rack I/O. I controller Pack Multi-Suction compatibili che sono stati scansionati appariranno inizialmente come 0 gruppi di aspirazione. Per configurare il controller Pack è necessario AK-ST 500 (Service Tool).",
	t3361: "Importo da copiare",
	t3362: "La riduzione del gruppo di aspirazione configurato in precedenza rimuoverà anche tutti i controlli di evaporazione associati a quel gruppo di aspirazione.",
	t3363: "Il numero del gruppo di aspirazione non può essere zero, il numero minimo del gruppo di aspirazione deve essere almeno uno.",
	t3364: "Errore durante il recupero dei compressori/pacchetti",
	t3365: "Nessun compressore/pacchetto disponibile",
	t3366: "Dispositivi in linea",
	t3367: "Dispositivi offline",
	t3369: "Aggiungi pacchetto",
	t3370: "Selezionando Modbus/RS485 verranno visualizzate opzioni aggiuntive per SLV/ECFan, utilizzate per la refrigerazione e che possono essere impostate su No.",
	t3371: "Aggiungi circuito",
	t3372: "Aggiungi, copia o rimuovi dispositivi. Nota che non tutti i tipi di dispositivi consentono la modifica di tutti i campi e gli indirizzi dei punti I/O sono impostati nella configurazione.",
	t3373: "Gestire i controllori dei casi",
	t3374: "Aggiungi un dispositivo",
	t3375: "Gestire i controllori del compressore/pacchetto",
	t3376: "Errore nell'accesso ai dati",
	t3377: "Il layout HVAC è stato applicato correttamente",
	t3378: "Disposizione HVAC",
	t3379: "Sei sicuro di voler abbandonare questa pagina?",
	t3380: "Le tue impostazioni potrebbero andare perse",
	t3381: "Si prega di configurare un dispositivo",
	t3382: "Esci dalla configurazione rapida",
	t3383: "Vuoi davvero uscire dalla Configurazione rapida?",
	t3384: "Gateway (AK-PI200)",
	t3385: "Seleziona i gateway trovati",
	t3386: "Indirizzo IP",
	t3387: "Controllore sorgente",
	t3388: "Impostazioni sorgente",
	t3389: "Allarmi sorgente",
	t3390: "Grafico sorgente",
	t3392: "Grafico",
	t3393: "Dispositivi",
	t3394: "Il polling automatico è sospeso durante la fase di copia. L'interruttore principale del dispositivo sorgente e di destinazione verrà spento e poi riportato allo stato originale, una volta completata la copia. Assicurarsi di controllare l'interruttore principale dopo il completamento della procedura guidata di copia. Se uno qualsiasi dei dispositivi selezionati è in allarme attivo, qualsiasi modifica all'azione di allarme per quel particolare punto non verrà modificata. L'impostazione originale rimarrà.",
	t3395: "Seleziona un dispositivo sorgente da cui copiare le impostazioni",
	t3396: "In tempo",
	t3397: "La funzione di copia copierà i punti disponibili del grafico fino al raggiungimento della capacità dell'unità. Vai a Configurazione per vedere il numero di punti definiti",
	t3398: "Grafica del dispositivo sorgente",
	t3399: "Configurare i punti del grafico per il controller sorgente",
	t3400: "Abilitare HACCP per registrare i punti in un report di temperatura HACCP",
	t3401: "Cerca punti",
	t3402: "Dati non disponibili",
	t3403: "Errore durante il recupero dei punti del grafico",
	t3404: "Disabilito",
	t3405: "Allarmi del dispositivo sorgente",
	t3406: "Configurare i dettagli dell'allarme nel controller sorgente",
	t3407: "Nome allarme",
	t3436: "Il dispositivo verrà rimosso dal gruppo assegnato [ {{fromPackGroup}} ]",
	t3437: "Disposizione Refrigerazione",
	t3438: "Il layout di refrigerazione è stato applicato con successo",
	t3439: "Riassegnare",
	t3440: "Assegnare i controlli dei casi ai controlli dei pacchetti",
	t3441: "Assegnare i controlli dei casi (dai casi disponibili) ai controlli del compressore/pacchetto. formando gruppi di aspirazione",
	t3442: "Parametri del dispositivo",
	t3443: "Seleziona i parametri per la copia",
	t3444: "Quando si lavora su un dispositivo live (online), potrebbe verificarsi un ritardo nelle impostazioni, dovuto alla comunicazione con il dispositivo.",
	t3445: "Seleziona gli allarmi da copiare",
	t3446: "Grafico del dispositivo",
	t3447: "Seleziona i punti del grafico per la copia",
	t3448: "Salva le impostazioni correnti del dispositivo in un file",
	t3449: "È possibile salvare le impostazioni correnti del dispositivo in un file che può essere utilizzato per configurare i dispositivi in un secondo momento. Se si desidera uscire dalla procedura guidata dopo il salvataggio del file, utilizzare l'icona di uscita. Se si lavora con dispositivi connessi, l'uscita garantirà che il polling di rete e l'interruttore principale del dispositivo torneranno allo stato corretto.",
	t3450: "I file di impostazione sono stati salvati correttamente",
	t3451: "Vuoi continuare o uscire dalla Configurazione rapida?",
	t3452: "Salvataggio dei file",
	t3453: "Errore durante il recupero delle impostazioni",
	t3454: "Impostazioni del dispositivo sorgente",
	t3455: "Configurare i parametri del dispositivo nel controller sorgente",
	t3456: "Nessuno dei campi password deve essere vuoto",
	t3457: "Recenti",
	t3458: "Rubrica",
	t3459: "Ultimo accesso",
	t3460: "Si prega di eseguire regolarmente un backup della Rubrica.",
	t3461: "La rubrica è stata importata correttamente",
	t3462: "Importare rubrica",
	t3463: "Esportare rubrica",
	t3464: "Aggiungi sito",
	t3465: "Modifica sito",
	t3466: "Elimina ubicazione",
	t3467: "È necessario creare una password per la Rubrica prima di iniziare a utilizzare la Rubrica",
	t3468: "Il sito è stato aggiunto correttamente alla tua rubrica",
	t3469: "Già nella Rubrica",
	t3470: "Aggiungi alla rubrica",
	t3471: "Accedi al sito",
	t3472: "Le informazioni del sito sono state aggiornate correttamente",
	t3474: "Notifica",
	t3475: "Il sito è stato rimosso con successo dalla tua rubrica",
	t3476: "Il sito è stato aggiornato con successo",
	t3477: "Formato della rubrica non valido",
	t3478: "Azioni",
	t3479: "Non disponibile offline",
	t3480: "Impossibile caricare il database S55",
	t3481: "Caricamento del database S55",
	t3482: "Protocollo",
	t3483: "L'utente non ha l'autorizzazione per visualizzare le informazioni sul software",
	t3484: "La password deve essere solo alfanumerica o numerica",
	t3485: "È necessario creare una password per la Rubrica per accedere alla Rubrica",
	t3486: "La password della rubrica non è corretta",
	t3487: "Il sito è già presente nella Rubrica",
	t3488: "Crea una password per la rubrica per usare la rubrica. La password deve essere solo alfanumerica o numerica",
	t3489: "Inserisci la password della tua Rubrica",
	t3490: "Nessun dispositivo online disponibile per la copia",
	t3491: "Crea password per la rubrica",
	t3492: "Aggiorna la password della rubrica",
	t3493: "La password del sito non può essere vuota",
	t3494: "Digitare una password per la rubrica che si sta importando",
	t3495: "La password della rubrica è stata creata correttamente",
	t3496: "La password della rubrica è stata aggiornata correttamente",
	t3497: "La password della rubrica importata non è corretta",
	t3498: "Inserisci la password del sito",
	t3499: "Cancella rubrica",
	t3500: "Cerca siti",
	t3501: "Seleziona il file della rubrica da importare",
	t3502: "Seleziona il file chiave per decifrare la Rubrica",
	t3503: "Premere Esporta per scaricare la rubrica crittografata",
	t3505: "Con criptato",
	t3506: "password non crittografate",
	t3507: "Chiave segreta non valida",
	t3508: "Errore di importazione. Assicurati di aver importato la chiave corretta",
	t3509: "Nessuna versione disponibile",
	t3510: "Apri i servizi Alsense (richiede l'abbonamento)",
	t3511: "Si prega di leggere attentamente i termini dell'EULA. Se non si possono accettare i termini, non è consentito utilizzare l'applicazione.",
	t3512: "La Rubrica e la password della Rubrica sono state cancellate correttamente",
	t3513: "(necessario)",
	t3515: "La rubrica è vuota",
	t3516: "La password della rubrica non può essere vuota",
	t3519: "Cancellando la Rubrica verrà rimossa anche la password della Rubrica.",
	t3520: "Non nell'intervallo. Dovrebbe essere compreso tra 0 e {{maxAddr}}",
	t3521: "Premere 'Esporta' per scaricare tutte le viste grafiche memorizzate nel System Manager",
	t3522: "Premere 'Download' per scaricare un report di sistema",
	t3523: "Solo l'unità master è disponibile su una connessione WiFi diretta",
	t3524: "Non ci sono viste grafiche locali. Aggiungi nuove viste o importa quelle esistenti",
	t3525: "Non ci sono viste grafiche remote. Prova a cercare con un nome diverso",
	t3526: "Non ci sono viste grafiche remote. Aggiungi nuove viste, importa o migra quelle esistenti",
	t3527: "Visualizzazioni locali ( {{totalFiles}} di 5)",
	t3528: "{{totalFiles}} grafica locale su un massimo di 5",
	t3529: "Viste remote",
	t3530: "Il file non è un bitmap.",
	t3531: "Lunghezza bitmap errata.",
	t3532: "La dimensione dell'intestazione bitmap è errata.",
	t3533: "La dimensione della bitmap è errata.",
	t3534: "Errore nella dimensione della tavolozza.",
	t3535: "File .bmp incompatibile",
	t3536: "Errore durante l'eliminazione del file grafico locale",
	t3537: "Errore durante la duplicazione del file grafico locale",
	t3538: "Errore durante l'esportazione del file grafico locale",
	t3539: "Elimina grafica",
	t3540: "Elimina un file grafico locale",
	t3541: "È in corso l'eliminazione del file grafico locale",
	t3542: "File grafico locale eliminato correttamente. Riavvio di System Manager",
	t3543: "Eliminazione file grafico locale completata. System Manager riavviato correttamente",
	t3544: "Grafica duplicata",
	t3545: "Duplicare un file grafico locale",
	t3546: "È in corso la duplicazione del file grafico locale",
	t3547: "File grafico locale duplicato correttamente. Riavvio di System Manager",
	t3548: "Duplicazione del file grafico locale completata. System Manager riavviato correttamente",
	t3549: "Esportazione grafica",
	t3550: "Esportare un file grafico locale",
	t3551: "L'esportazione del file grafico locale è in corso",
	t3552: "File grafico locale esportato correttamente",
	t3553: "Il gestore di sistema verrà riavviato dopo questa azione",
	t3554: "Sei sicuro di voler eliminare",
	t3555: "Sei sicuro di voler duplicare?",
	t3556: "Premere \"Esporta\" per scaricare tutte le viste grafiche locali memorizzate nel System Manager",
	t3557: "L'esportazione dei file grafici locali è in corso",
	t3558: "File grafici locali esportati correttamente",
	t3559: "Codice prodotto",
	t3560: "Ottieni maggiori informazioni su questo prodotto",
	t3561: "Ventilatore ecologico",
	t3562: "Apertura della valvola",
	t3563: "surriscaldamento",
	t3564: "Pressione dell'evaporatore",
	t3565: "Temperatura Evaporatore",
	t3566: "Ritagliare",
	t3567: "Taglio",
	t3568: "Porta della cassa",
	t3569: "Temperatura di aspirazione",
	t3570: "Calore ferroviario",
	t3571: "Limite massimo consentito di file raggiunto. Deseleziona i file da importare",
	t3572: "Si prega di eliminare i file esistenti nel gestore di sistema per importare i file",
	t3573: "File grafico locale importato correttamente. Riavvio di System Manager",
	t3574: "Importazione file grafici locali completata. System Manager riavviato correttamente",
	t3575: "È stato raggiunto il limite massimo consentito per i file. Elimina i file da importare",
	t3576: "Il numero massimo di file grafici consentiti è 5",
	t3577: "File grafico locale caricato correttamente. Riavvio di System Manager",
	t3578: "Caricamento del file grafico locale completato. System Manager riavviato correttamente",
	t3579: "Formato file non valido. Carica il file {{supportedFileFormats}}",
	t3580: "La dimensione del file deve essere {{maxWidthAllowed}} x {{maxHeightAllowed}} pixel per gli schermi locali",
	t3581: "Visualizzazione remota",
	t3582: "Vista locale",
	t3583: "Pagina iniziale Web Storeview",
	t3584: "Termini dell'EULA",
	t3585: "Sicurezza",
	t3586: "Livelli di applicazione della sicurezza",
	t3587: "Compatibile con le versioni precedenti (livello di sicurezza più basso, supponendo che l'autenticazione dell'intestazione sia disabilitata)",
	t3588: "Le richieste SvW/XML contengono nome utente e password.",
	t3589: "Nessuna sanificazione delle richieste, stessa sicurezza delle versioni precedenti",
	t3590: "Nessuna modifica necessaria su SvW o sulle connessioni XML esistenti",
	t3591: "Permissivo (può essere utilizzato come transizione al livello rigoroso)",
	t3592: "Le richieste XML possono contenere username e password, e/o un token di sessione. Il token di sessione viene ricevuto nella risposta della richiesta di login.",
	t3593: "Se il token di sessione viene fornito nell'intestazione AKSM-auth, i nomi utente e le password vengono ignorati",
	t3594: "La richiesta XML viene sanificata, viene restituito un avviso nella risposta della richiesta se vengono rilevati problemi",
	t3595: "Prendi nota di questi avvisi in preparazione al passaggio alla modalità rigorosa",
	t3596: "Se il token di sessione non viene utilizzato, la scadenza della sessione non può essere applicata",
	t3597: "Già supportato nell'interfaccia utente remota (SvW)",
	t3598: "Possibili/richieste modifiche dalla prospettiva XML di terze parti",
	t3599: "Nessun utilizzo di token di sessione: nessuna modifica necessaria",
	t3600: "Utilizzo del token di sessione: rimuovere nome utente/password dal corpo e osservare gli avvisi in preparazione per Strict",
	t3601: "Rigoroso (livello di sicurezza più elevato)",
	t3602: "Le richieste XML non possono contenere nomi utente e password, ma devono fornire il token di sessione nell'intestazione AKSM-auth. Se viene scoperto un problema nella richiesta, questa viene rifiutata",
	t3603: "Già supportato in (SvW)",
	t3604: "Modifiche richieste dalla prospettiva di terze parti",
	t3605: "nome utente e password devono essere rimossi dal payload della richiesta XML e il token di sessione deve essere inserito in un'intestazione CORS denominata AKSM-auth",
	t3606: "Back-off per errore di accesso",
	t3607: "Quanto tempo (secondi) un utente remoto attende per poter riprovare ad accedere dopo un tentativo fallito. Il tempo di attesa aumenta cumulativamente con ogni tentativo fallito. Dopo 10 tentativi, l'IP da cui l'utente ha tentato di accedere viene bloccato per 24 ore. Se impostato su 0, il back-off per errore di accesso è disabilitato",
	t3608: "Predefinito 30s (max 600s / 0 = disabilitato)",
	t3609: "Tentativi gratuiti",
	t3610: "Quanti tentativi di accesso sono consentiti prima che venga applicato il back-off",
	t3611: "Predefinito 2 (max 5)",
	t3612: "Bloccare",
	t3613: "Se abilitato, l'utente + IP vengono bloccati durante il back-off, altrimenti l'utente viene bloccato",
	t3614: "'Utente' predefinito (Utente + IP / Utente)",
	t3615: "Sessioni per utente",
	t3616: "Una sessione è il momento in cui un utente ha effettuato l'accesso con successo all'unità e si trova all'interno dell'interfaccia remota (SvW)",
	t3617: "Numero di Sessioni attive che un utente può avere. Se impostato su 0, un utente può avere la quantità massima di Sessioni (30)",
	t3618: "Predefinito 0",
	t3619: "Scadenza della sessione",
	t3620: "Quanti secondi dopo la scadenza di una sessione inattiva. Se una sessione scade, è necessario un nuovo login",
	t3621: "Predefinito 900 (max 3.600)",
	t3622: "Autenticazione dell'intestazione",
	t3623: "Quando si abilita HTTPS e l'autenticazione dell'intestazione, tutte le richieste XML di terze parti devono passare le credenziali nome utente/password nell'intestazione (schema di autenticazione di base)",
	t3624: "L'abilitazione di \"Usa autenticazione intestazione\" richiede la reinizializzazione del sistema. Utilizzare il pulsante \"Premi per inizializzare\" per eseguire questa azione",
	t3625: "Piccolo",
	t3626: "Grande",
	t3627: "Salva grafica",
	t3628: "Salvare un file grafico locale",
	t3629: "File grafico locale eliminato con successo. Caricamento del file grafico locale in corso.",
	t3630: "Il file grafico locale è stato caricato correttamente. Riavvio di System Manager",
	t3631: "Salvataggio del file grafico locale completato. System Manager riavviato correttamente",
	t3632: "Sei sicuro di voler salvare?",
	t3633: "Analisi non riuscita",
	t3634: "Impossibile analizzare il file viz",
	t3635: "Impossibile analizzare il file vz2",
	t3636: "Apertura del file Viz non riuscita:",
	t3637: "Non è stato possibile trovare i file di migrazione (vz2, dpj o json)",
	t3638: "Non è stato possibile trovare i file di migrazione (viz)",
	t3639: "Il System Manager verrà riavviato con questa azione. Il System Manager è in modalità permissiva o rigorosa, quindi il token di sessione scadrà al riavvio e verrai disconnesso dal sistema",
	t3643: "Pressione di scarico",
	t3644: "kW totali",
	t3645: "Totale KVAR",
	t3646: "Totale Kvarh+",
	t3647: "Richiesta",
	t3648: "Fattore di potenza",
	t3649: "Colore del carattere",
	t3650: "Colore di sfondo",
	t3651: "La rubrica è stata esportata correttamente",
	t3652: "Modalità di controllo",
	t3653: "Sc3 Temperatura esterna",
	t3654: "Temperatura di scarico",
	t3655: "Capacità di funzionamento del condensatore",
	t3656: "Nessun dato da esportare",
	t3657: "La password dell'utente su {{unit}} è scaduta",
	t3658: "Impossibile accedere a {{unit}} con le credenziali fornite.",
	t3659: "Aggiorna la versione del firmware o modifica il livello di sicurezza per {{unit}} per ottenere il supporto del token di sessione.",
	t3661: "V3gc",
	t3662: "Sezione B",
	t3663: "Sezione C",
	t3664: "Controllo adattivo del surriscaldamento",
	t3665: "Motivo dello stato di controllo",
	t3666: "L'utente non può effettuare l'accesso. L'indirizzo dell'unità non è impostato nella configurazione del proxy",
	t3667: "Il protocollo di comunicazione non è specificato nella configurazione del proxy. Potrebbe causare errori",
	t3668: "Informazioni sulla sicurezza informatica",
	t3669: "In conformità con gli standard di sicurezza, System Manager ora utilizza per impostazione predefinita il controllo rigoroso della sessione, il che significa che qualsiasi connessione remota/servizio remoto basato su XML non funzionerà più a meno che non vengano applicati token di sessione.",
	t3670: "Danfoss consiglia di continuare in modalità Rigorosa, ma la modalità di compatibilità con le versioni precedenti è ancora disponibile.",
	t3671: "Nota: questa impostazione può essere modificata nel menu Configurazione sicurezza, senza alcun impatto sul controllo locale di System Manager e sul funzionamento del sito.",
	t3672: "Vedi maggiori informazioni sul controllo della sessione",
	t3673: "Modalità all'indietro",
	t3674: "Modalità rigorosa",
	t3675: "Facendo clic sulla modalità compatibile con le versioni precedenti, tutti i System Manager applicabili con firmware aggiornato verranno impostati sulla modalità compatibile con le versioni precedenti, riavviati e l'applicazione verrà disconnessa.",
	t3676: "Il numero massimo di gruppi di aspirazione consentiti è 12",
	t3677: "Dopo aver aggiornato correttamente le unità selezionate dalla versione 3.3.0 alla versione 4.X o superiore, l'utente verrà disconnesso automaticamente facendo clic sul pulsante Fine nel passaggio finale.",
	t3678: "I multi-case per i controller AK2 (Pack/Case) non sono supportati",
	t3679: "In conformità con gli standard di sicurezza introdotti con la release 4.0, System Manager passerà di default al controllo di sessione Strict, il che significa che qualsiasi connessione remota/servizio remoto basato su XML non funzionerà più a meno che non vengano applicati token di sessione. Oltre a ciò, la comunicazione verrà automaticamente regolata su HTTPS tramite l'aggiornamento alla rel. 4.0. Danfoss consiglia di continuare in modalità Strict, ma è disponibile la modalità di compatibilità con le versioni precedenti, che ripristinerà il sistema alle impostazioni applicate fino a quel momento. Si prega di notare che se si esegue questo aggiornamento in remoto su un'unità HTTP, assicurarsi che la configurazione IT sia preparata per HTTPS (porta 443) o di avere personale disponibile in loco.",
	t3680: "Ulteriori informazioni sulla nuova implementazione della sicurezza sono disponibili qui",
	t3681: "L'accesso alla versione http di questo sito potrebbe essere bloccato a causa delle restrizioni del tuo browser. Visita la nostra pagina FAQ per ottenere istruzioni su come risolvere questo problema o scarica la versione desktop di StoreView Web.",
	t3682: "Domande frequenti del gestore di sistema",
	t3683: "u17 Aria termica",
	t3684: "u91 Temperatura di interruzione.",
	t3685: "u00 Stato di controllo",
	t3686: "u12 S3 temperatura dell'aria",
	t3687: "u16 S4 temperatura dell'aria",
	t3688: "u23 EEV OD %",
	t3689: "u21 Surriscaldamento",
	t3690: "u22 SurriscaldamentoRif",
	t3691: "u26 Temperatura di evaporazione",
	t3692: "S2 Temperatura di uscita del gas.",
	t3693: "Sw8",
	t3694: "Sd-MT",
	t3695: "Stato Tw",
	t3696: "Abilitazione Tw",
	t3697: "Due pompe in funzione",
	t3698: "Sw3",
	t3699: "Stw4",
	t3700: "Stw2",
	t3701: "Stato di controllo Rcv.",
	t3702: "Offset di aspirazione",
	t3703: "Modalità di controllo Vrec",
	t3704: "Stato Vrec",
	t3705: "Motivo dello stato di Ctrl",
	t3706: "Stato del controllo Hp",
	t3707: "Pgc massimo.",
	t3708: "Pgc limite massimo P-ban",
	t3709: "Pgc minimo.",
	t3710: "Capacità di espulsione del vapore",
	t3711: "Capacità di espulsione del liquido",
	t3712: "Temperatura dell'aria di riferimento.",
	t3713: "Temperatura dell'aria di mandata.",
	t3714: "Aria di ritorno",
	t3715: "Punto di rugiada della zona",
	t3716: "Umidità della zona",
	t3717: "Temperatura esterna",
	t3718: "Livello di CO2 della zona",
	t3719: "Temperatura della zona.",
	t3720: "Riferimento cond.",
	t3721: "Stato di controllo delle condizioni",
	t3722: "Stato di controllo delle condizioni",
	t3723: "Controllo condizioni.",
	t3724: "Cond. richiesta cap.",
	t3725: "Stato V3gc",
	t3726: "V3gc OD",
	t3727: "Temp. cond.",
	t3728: "Stato delle risorse umane",
	t3729: "Abilitazione risorse umane",
	t3730: "Richiesta del consumatore HR",
	t3731: "Shr3",
	t3732: "Shr4",
	t3733: "Shr2",
	t3734: "Shr8",
	t3735: "Riferimento aspirazione To-MT",
	t3736: "Stato di controllo MT",
	t3737: "Zona attuale MT",
	t3738: "Per optare per offset MT",
	t3739: "Capacità di corsa MT",
	t3740: "Cap. richiesto MT",
	t3741: "Tempo per il passo successivo MT",
	t3742: "Po-MT",
	t3744: "Temperatura di aspirazione To-MT",
	t3745: "Energia attiva totale",
	t3746: "Domanda di picco",
	t3747: "Fattore di potenza totale",
	t3748: "Volt di fase A",
	t3749: "Volt fase B",
	t3750: "Volt di fase C",
	t3751: "Amplificatori di fase A",
	t3752: "Amplificatori di fase B",
	t3753: "Amplificatori di fase C",
	t3754: "Potenza attiva totale",
	t3755: "Temp. di aspirazione To-LT",
	t3756: "Temperatura di aspirazione To-IT",
	t3757: "Riferimento aspirazione LT",
	t3758: "Stato di controllo LT",
	t3759: "Zona attuale LT",
	t3760: "Per optare per offset LT",
	t3761: "Capacità di corsa LT",
	t3762: "Cap. richiesto LT",
	t3763: "È il momento del passo successivo LT",
	t3764: "Po-LT",
	t3765: "Sd-LT",
	t3766: "Riferimento aspirazione IT",
	t3767: "Stato di controllo IT",
	t3768: "Zona attuale IT",
	t3769: "Capacità di esecuzione IT",
	t3770: "Capacità richiesta IT",
	t3771: "È il momento del passaggio successivo IT",
	t3772: "Tn Po-IT",
	t2773: "Sd-IT",
	t2774: "Inizio attività IT Comp.",
	t3774: "Non nell'intervallo. Dovrebbe essere compreso tra {{minAddr}} e {{maxAddr}}",
	t3775: "Operazione disabilitata per questo dispositivo",
	t3776: "Dopo questa azione l'applicazione si disconnetterà. Questa operazione potrebbe richiedere alcuni minuti.",
	t3777: "Impossibile trovare i file di migrazione (flp o json)",
	t3778: "Sondaggi",
	t3779: "Al momento le votazioni sono sospese.",
	t3780: "Desideri riprendere le votazioni?",
	t3781: "Contatore di energia",
	t3782: "Sono stati selezionati più nodi",
	t3783: "Zoom sul nodo",
	t3784: "Unità di misura",
	t3785: "File esportati con successo",
	t3786: "L'indirizzo non può essere negativo",
	t3787: "Ripristina zoom",
	t3788: "Errore durante il recupero dei passaggi del modello di staging",
	t3789: "Errore durante il salvataggio dei passaggi del modello di staging",
	t3790: "I passaggi del modello di staging sono stati salvati correttamente",
	t3791: "Modello di messa in scena",
	t3792: "Premere le icone di scarico per abilitare/disabilitare gli scaricatori associati ai compressori",
	t3793: "Dati mancanti",
	t3794: "I seguenti parametri non hanno restituito alcun punto dati",
	t3795: "Trust HTTPS/SSL riuscito.",
	t3796: "Ora puoi accedere al browser Web StoreView.",
	t3797: "Reindirizza mento al browser Web StoreView in {{timeLeft}} secondi...",
	t3798: "Le votazioni sono attualmente sospese!",
	t3799: "Allarmi cancellati (registri di sistema completi)",
	t3800: "Il numero massimo consentito di impianti HVAC è 45.",
	t3801: "Impossibile ottenere il registro delle modifiche",
	t3802: "Connessione allo storage...",
	t3803: "Timeout della connessione di archiviazione SCADA",
	t3804: "Connessione al sistema di archiviazione SCADA non riuscita",
	t3805: "Data non valida",
	t3806: "Seleziona unità per scaricare il report"
};
var it = {
	lang: lang$5
};

var lang$6 = {
	t0: "Aktywne Alarmy",
	t1: "Wykasowane alarmy",
	t2: "Host",
	t3: "ID",
	t4: "Nazwa urządzenia",
	t5: "Edytowanie notatek",
	t6: "Nie autoryzowany do potwierdzenia alarmu",
	t7: "Typ. Alarmu",
	t8: "Wystąpił",
	t9: "Potwierdzenie",
	t10: "Aktywne Alarmy",
	t11: "Wykasowane",
	t12: "Kalkulacja",
	t13: "Wykasowane alarmy",
	t14: "Wyciszenie/Ack",
	t15: "Potw. wszystkich niepotwierdzonych alarmów",
	t16: "Błąd autoryzacji!",
	t17: "Error",
	t18: "Manual Control",
	t19: "Visual",
	t20: "Dane podsumowujące",
	t21: "Widok techniczny",
	t22: "Wykres (S4)",
	t23: "Node",
	t24: "Mod",
	t25: "Graph (Po &amp; Pc)",
	t26: "(brak alarmów)",
	t27: "Alarmy urządzeń",
	t28: "Wyświetlanie poprzedniego urządzenia",
	t29: "Zobacz następne urządzenie",
	t30: "Błąd podczas odczytywania alarmów.",
	t31: "Aktualne alarmy",
	t32: "Bieżący alarm",
	t33: "Nie wykryto żadnych alarmów.",
	t34: "Detail",
	t35: "Point",
	t36: "Dziennik historii",
	t37: "Harmonogramy",
	t38: "Godzina rozpoczęcia",
	t39: "Czas zatrzymania",
	t40: "Dni",
	t41: "Wakacje",
	t42: "Status",
	t43: "Wybieranie punktów danych",
	t44: "OK",
	t45: "Anuluj",
	t46: "Start",
	t47: "End",
	t48: "Start date",
	t49: "Częstotliwość próbkowania",
	t50: "Nie wybrano punktów danych",
	t51: "Nwo",
	t52: "S",
	t53: "min",
	t54: "hr",
	t55: "Dzien",
	t56: "Typ Adresu",
	t57: "Adres",
	t58: "Lokalizacji:",
	t59: "Wprowadź nową wartość",
	t60: "Odświeżania",
	t61: "Wybieranie punktów danych",
	t62: "Wybierz nową wartość",
	t63: "Wył.",
	t64: "On",
	t65: "Trwa pobieranie punktów danych...",
	t66: "Błąd podczas pobierania danych...",
	t67: "Nie skonfigurowano historii...",
	t68: "Błąd podczas pobierania punktów danych...",
	t69: "Pobieranie próbek historii dla",
	t70: "Błąd podczas pobierania próbek historii...",
	t71: "User Name",
	t72: "Password",
	t73: "Host IP/URL Address:",
	t74: "OK",
	t75: "Anuluj",
	t76: "Name",
	t77: "Wartość",
	t78: "Błąd podczas pobierania listy użytkowników",
	t79: "Wprowadź nowy adres",
	t80: "Nie powiod się listy użytkowników, sprawdź adres IP/ADRES URL hosta, sprawdź adres IP/URL hosta",
	t81: "Kod autoryzacji",
	t82: "Kod acct",
	t83: "Wybierz nową godzinę i datę",
	t84: "Wybierz nowy czas trwania",
	t85: "Operacja nie powiodła się",
	t86: "Wprowadź informacje o autoryzacji",
	t87: "HVAC",
	t88: "Go!",
	t89: "Funkcja odtajania",
	t90: "Uruchamianie ręcznego rozmrażania",
	t91: "Urz.Ster.Oświetleniem",
	t92: "Ręczne światła obudowy włączone",
	t93: "Ręczne światła obudowy wyłączone",
	t94: "Urz.Sterowanie mycia",
	t95: "Ręczne czyszczenie obudowy włączone",
	t96: "Ręczne czyszczenie obudowy wyłączone",
	t97: "Przełącznik główny",
	t98: "Ręczny włącznik główny",
	t99: "Ręczne wyłączanie główne",
	t100: "Kontrola zamykania systemu",
	t101: "Zamknij urządzenie",
	t102: "Włączanie urządzenia",
	t103: "Kontrola nocnych niepowodzeń",
	t104: "Noc Niepowodzenia Na",
	t105: "Noc Niepowodzenia Off",
	t106: "Wiadomość wysłana...",
	t107: "Manual Control",
	t108: "Zamknij",
	t109: "Oświetlenie",
	t110: "Moc/różne",
	t111: "Przegląd",
	t112: "Wybierz jednostkę AK255 do wyświetlenia.",
	t113: "Ładowania...",
	t114: "of",
	t115: "Wszystkie AK255s",
	t116: "Poprzednich",
	t117: "Pages",
	t118: "Next",
	t119: "Parameter",
	t120: "Pomiary",
	t121: "Trwa pobieranie danych...",
	t122: "Błąd w sekcji czytania",
	t123: "Temperatury",
	t124: "Opóźnienie po rozmrażaniu",
	t125: "Alarm jeśli poniżej",
	t126: "Alarm jeśli powyżej",
	t127: "Monitorowania",
	t128: "Offline",
	t129: "Aktywny",
	t130: "Host",
	t131: "Adres",
	t132: "Description",
	t133: "Bieżąca wartość",
	t134: "Bieżący stan",
	t135: "Kontrolery",
	t136: "Unit",
	t137: "Trwa Aktywny Alarmy",
	t138: "Trwają Aktywne Alarmy!",
	t139: "Wejścia włączania i wyłączania",
	t140: "Przekaźnikowe",
	t141: "Wejścia czujników",
	t142: "Zmienne wyjścia",
	t143: "Kontrolery ogólne",
	t144: "Innych",
	t145: "Punkt wizualizacji",
	t146: "Przegląd sklepu",
	t147: "Wyloguj się z systemu",
	t148: "Log On",
	t149: "Wyloguj się",
	t150: "Chłodzenia",
	t151: "Omówienie urządzenia",
	t152: "Alarmy",
	t153: "Historia",
	t154: "Przegląd CS",
	t155: "Przegląd sklepu",
	t156: "Konfiguracja",
	t157: "Site View",
	t158: "Widok roślin",
	t159: "Home",
	t160: "Alarm",
	t161: "Nastawa",
	t162: "Szczegóły urządzenia",
	t163: "Ustawienie",
	t164: "Wybierz datę",
	t165: "Information",
	t166: "Audytu",
	t167: "Kalkulacja",
	t168: "Zarządzanie plikami",
	t169: "Help Manual",
	t170: "Info",
	t171: "Translator",
	t172: "Zapisz na serwerze",
	t173: "Czy zaktualizować pliki językowe?",
	t174: "Ta operacja spowoduje zaktualizowanie plików językowych na urządzeniu. Kontynuować?",
	t175: "Historia eksportu",
	t176: "Wybierz nową wartość",
	t177: "minimum",
	t178: "maximum",
	t179: "No Active Alarm!",
	t180: "Nie można się zmienić!",
	t181: "Czekaj...",
	t182: "Komedna wysłana!",
	t183: "Operacja nie powiodła się, spróbuj ponownie później!",
	t184: "Operacja się powiodła!",
	t185: "Nieautoryzowane działanie!",
	t186: "Kontynuować operację?",
	t187: "EVAP",
	t188: "PACK",
	t189: "Monitorowanie danych wejściowych",
	t190: "MISC",
	t191: "Potwierdzone Alarmy",
	t192: "Wykasuj potwierdzone alarmy",
	t193: "Wykasuj wszystkie potw. Alarmy",
	t194: "Użytkownik nie jest upoważniony do potwierdzania alarmów",
	t195: "Nieautoryzowane",
	t196: "Czy chcesz potwierdzić ten alarm?",
	t197: "Potwierdź, aby potwierdzić alarm",
	t198: "Zaloguj się najpierw",
	t199: "Wybierz alarm, aby wyczyścić",
	t200: "Potwierdź, aby wyczyścić alarmy",
	t201: "Skasować ten alarm?",
	t202: "Nie autoryzowany do wyczyszczenie alarmu",
	t203: "Skasować wszystkie alarmy?",
	t204: "Nie można wyczyścić wybranych alarmów",
	t205: "Alarm ustawienia",
	t206: "Musi być upoważniony jako inspektor do wykonania tej operacji",
	t207: "Brak danych zwróconych z jednostki",
	t208: "Wybierz jednostkę",
	t209: "Wybierz jednostkę z listy",
	t210: "Liczba alarmów",
	t211: "Status Alarmu",
	t212: "Różne",
	t213: "Wyasuj wybrane alarmy",
	t214: "Proszę potwierdzić",
	t215: "Połączenie z urządzeniem nie powiodło się, sprawdź połączenie sieciowe!",
	t216: "Alarm odszraniania",
	t217: "Alarm cyfrowy",
	t218: "Stan wejścia cyfrowego",
	t219: "Dane wejściowe odszraniania",
	t220: "Czyszczenie wejście",
	t221: "Wejście cyfrowe",
	t222: "Zapisywanie plików językowych",
	t223: "Jednostek",
	t224: "Brak urządzenia skonfigurowane w systemie, strona internetowa wyłączona.",
	t225: "Ostrzeżenie:",
	t226: "Wybierz godzinę",
	t227: "Komunikacja",
	t228: "Download Report File?",
	t229: "Poczekaj podczas generowania pliku raportu...",
	t230: "Download Report",
	t231: "Alarm Status/Service",
	t232: "Poczekaj podczas pobierania danych...",
	t233: "Zalogowany",
	t234: "Użytkownik wylogowany",
	t235: "Zalogowany użytkownik",
	t236: "Zapraszamy!",
	t237: "Nieprawidłowa licencja",
	t238: "Sterowniki liczników energii",
	t239: "Panele oświetleniowe",
	t240: "Falownik VLT",
	t241: "Moduł sprężarki",
	t242: "Punkty kontrolera urządzeń",
	t243: "Obl. Punkty",
	t244: "Sztućce-Hammer Panele",
	t245: "Przewoźników",
	t246: "Widok Zaawansowany",
	t247: "Kliknij dwukrotnie tutaj, aby powiększyć / pomniejszyć",
	t248: "Naciśnij aby powiększyć/zmniejszyć",
	t249: "Potwierdzone alarmy",
	t250: "Simulator",
	t251: "Zadanie refrig",
	t252: "Zadanie HVAC",
	t253: "Zadanie oświetlenia",
	t254: "Zadanie MISC",
	t255: "Połączenie z siecią",
	t256: "Dysku",
	t257: "Odwołanie",
	t258: "Graph (Drive Mtr Hz &amp; Amp)",
	t259: "Stop Date",
	t260: "Stop",
	t261: "Czas zatrzymania jest włączony lub przed godziną rozpoczęcia, należy ponownie wybrać datę/godzinę",
	t262: "Czas tworzenia historii punktów danych",
	t263: "są poźniejesze niż ustawiony czas startu.",
	t264: "Zwrócona liczba próbek z każdego punktu historii osiągnęła 100 000, zajęłoby dużo czasu, aby uzyskać odpowiedź. Możesz zmniejszyć tę liczbę, zmniejszając częstotliwość próbkowania LUB zmienić datę/godzinę.",
	t265: "Zadanie kwerendy historii jednostki jest zajęty, poczekaj około 2 minut i spróbuj ponownie ...",
	t266: "Zapisz",
	t267: "Panel sterowania historią",
	t268: "Pierwszym",
	t269: "Ostatnio",
	t270: "Drukowania",
	t271: "Pomniejszenie",
	t272: "Zoom In",
	t273: "Pokaż/Ukryj legendę",
	t274: "Eksportowanie danych historii do pliku",
	t275: "Pokaż/Ukryj siatkę",
	t276: "Widok pojedynczej strony",
	t277: "Widok wielostronicowy",
	t278: "Załaduj grupę historii z pliku",
	t279: "Historia ładowania z pliku",
	t280: "Zapisz grupę historii w pliku",
	t281: "Liczba wyświetleń wielu stron",
	t282: "Page",
	t283: "Wybieranie strony do wyświetlenia",
	t284: "Plik historii musi być w formacie .hst!",
	t285: "Brak zwróconych danych, sprawdź wybór punktu historii oraz wybraną datę/godzinę",
	t286: "Migawka historii",
	t287: "Pełny widok historii",
	t288: "Widok pliku historii",
	t289: "Wybierz...",
	t290: "Ta operacja wyeksportuje bieżący widok historii do pliku .hst/.csv.",
	t291: "Wybierz plik docelowy.",
	t292: "File",
	t293: "Załaduj grupę historii...",
	t294: "Historia ładowania...",
	t295: "Zapisz grupę historii...",
	t296: "View",
	t297: "Zoom",
	t298: "Tak",
	t299: "No",
	t300: "Czy pobrać konfigurację z kontrolera?",
	t301: "Nie pytaj ponownie",
	t302: "Pobieranie konfiguracji kontrolera",
	t303: "Pobrane dane",
	t304: "Poziom alarmu",
	t305: "ALARM Akcja",
	t306: "Potwierdzone",
	t307: "Zapisywanie bazy danych na dysku",
	t308: "File Name",
	t309: "Database Description",
	t310: "Ścieżka bazy danych",
	t311: "Version",
	t312: "Godzina",
	t313: "Alarmy eksportu",
	t314: "Baza danych zapisana w",
	t315: "Zapisywanie bazy danych nie powiodło się, sprawdź, czy katalog i nazwa pliku są prawidłowe!",
	t316: "Nazwa pliku nie jest poprawna, sprawdź nazwę pliku bazy danych",
	t317: "Eksport alarmów poprawny. Wybierz plik do zapisania wyeksportowanych alarmów.",
	t318: "Wybierz alarmy do wyeksportowania",
	t319: "Pobieranie informacji o alarmie z jednostek",
	t320: "File Description",
	t321: "Konwertowanie pliku hst na format pliku csv",
	t322: "Krok 1: Wybierz plik .hst",
	t323: "Krok 2: Wybierz punkty danych i zakres czasu",
	t324: "Krok 3: Wybierz docelowy plik csv",
	t325: "Zapisz jako...",
	t326: "W tej operacji nie więcej niż 6000 punktów danych jest dozwolone",
	t327: "Wybrane punkty danych",
	t328: "Nie wolno eksportować więcej niż 65 536 próbek na punkt danych, zmień częstotliwość próbkowania lub zakres czasu.",
	t329: "Zapisuj",
	t330: "Zapisu historii z urządzenia",
	t331: "Wybieranie zebranych punktów danych",
	t332: "Brak wartości",
	t333: "Brakuje",
	t334: "Zawieszone",
	t335: "Dokonaj wyboru",
	t336: "Wybieranie punktów danych do rysowania",
	t337: "Zapisz w pliku .hst",
	t338: "Pobieranie danych zakończone, wybierz jedną z następujących opcji:",
	t339: "Wybierz co najwyżej 8 punktów do narysowania",
	t340: "Zapisywanie zebranych danych historii w pliku hst",
	t341: "Drukowanie przeglądowe",
	t342: "Wybierz pozycję Zapisz lub wydrukuj raport przeglądowy",
	t343: "Wybierz format pliku",
	t344: "Generowanie pliku przeglądu, proszę czekać...",
	t345: "Istnieje wybrany plik bazy danych, czy chcesz go zastąpić?",
	t346: "Dashboard",
	t347: "Graphic View",
	t348: "Help",
	t349: "System View",
	t350: "Mapę",
	t351: "Narzędzie do tłumaczenia",
	t352: "Manual",
	t353: "Prośba o alarmy...",
	t354: "Aktualizacja",
	t355: "Model",
	t356: "Control",
	t357: "Oleju",
	t358: "Najwyższa temp",
	t359: "Zmaksymalizować",
	t360: "Przywrócić",
	t361: "Przejdź wstecz...",
	t362: "Comm nie powiodło się!",
	t363: "Kom.Status OK",
	t364: "Wiad. Wysłana",
	t365: "Ładowanie komponentu home",
	t366: "Składnik konfiguracji ładowania",
	t367: "Ctrl Temp",
	t368: "To ustawienie będzie obowiązywać tylko wtedy, gdy historia zostanie ponownie narysowana, czy chcesz kontynuować?",
	t369: "Ładowanie, proszę czekać",
	t370: "punkty danych",
	t371: "No unit name",
	t372: "Preferencje",
	t373: "Wersja symulatora",
	t374: "Energii",
	t375: "Przejdź do okna przeglądu",
	t376: "Znaleziono alarm systemowy lub nie znaleziono powiązanego urządzenia",
	t377: "Harmonogramy współbieżne",
	t378: "Brak pliku języka.",
	t379: "Wersja nie jest obsługiwana",
	t380: "Wprowadź tutaj adres IP jednostki (np. 192.168.1.1)",
	t381: "Podaj adres IP lub adres URL jednostki.",
	t382: "Ukryj szczegóły",
	t383: "Pokaż szczegóły",
	t384: "Poza temp",
	t385: "Return",
	t386: "Dostaw",
	t387: "Wilgotność",
	t388: "Punkt rosy",
	t389: "Wentylator",
	t390: "Zone",
	t391: "Zamknięcia",
	t392: "Split",
	t393: "Temperatura ssania",
	t394: "Aktualna pojemność",
	t395: "Ciśnienie ssania",
	t396: "Absolutorium",
	t397: "Sprężarki",
	t398: "Skraplacza",
	t399: "Cel skraplacza",
	t400: "Czynnika chłodniczego",
	t401: "Cel ssania",
	t402: "Ssania",
	t403: "Odszraniania",
	t404: "Format historii danfoss",
	t405: "Text Format",
	t406: "Ta operacja wymaga resetowania jednostki, czy chcesz teraz zresetować urządzenie?",
	t407: "Resetowanie jednostki powiodło się, przeładuj ponownie stronę internetową.",
	t408: "Tryb fabrycznej aplikacji",
	t409: "Kalkulacja",
	t410: "minutes",
	t411: "Nocna porażka",
	t412: "Urz.Światła",
	t413: "Planowanie współbieżności",
	t414: "(rozdzielczość 10 minut)",
	t415: "View Text",
	t416: "Eksport jako CSV",
	t417: "Zapisz plik png",
	t418: "Wyświetl wykres",
	t419: "View Text",
	t420: "Harmonogram",
	t421: "Długość",
	t422: "Termin Temp",
	t423: "Brak",
	t424: "Temp kontroli",
	t425: "Bieganie z",
	t426: "Baza danych załadowana z",
	t427: "Statystyki",
	t428: "Wybierz plik",
	t429: "Wybierz plik historii do kowersji do formatu CSV",
	t430: "Zakończ",
	t431: "Krótki opis",
	t432: "Pliki danych historii (*.hst)",
	t433: "Kreator konwersji historii",
	t434: "Tworzenie pliku zakończone.",
	t435: "Wyiberz punkty danych do konwersji do formatu CSV",
	t436: "Wybierz zakres czasu",
	t437: "Wybierz zakres czasu i naciśnij przycisk procesu...",
	t438: "Process Data",
	t439: "Przygotowanie danych historycznych, proszę czekać",
	t440: "Wybierz nazwę pliku CSV i zapisz dane do pliku...",
	t441: "Nie można odnaleźć historii punktu",
	t442: "Błąd — brak zwróconych danych historii",
	t443: "Wystąpił błąd podczas komunikacji z jednostkami za pośrednictwem sieci hosta. Znalezione",
	t444: "Uwaga",
	t445: "Ciszy",
	t446: "Baza danych nie została zapisana, Czy naprawdę chcesz zamknąć okno?",
	t447: "Table View",
	t448: "Pokaż grafikę",
	t449: "Pokaż tabelę",
	t450: "Ręczne rozmrażanie włączone",
	t451: "Ręczne rozmrażanie wyłączone",
	t452: "Auto odtajanie",
	t453: "Ustawianie kontroli odszraniania",
	t454: "Rozładunek na",
	t455: "Wyłącznik wyładowywany",
	t456: "Cieczy",
	t457: "Nie skonfigurowano żadnych harmonogramów.",
	t458: "StoreView",
	t459: "Utrata komunikacji z jednostką w sieci hosta!",
	t460: "Szybka konfiguracja",
	t461: "Street",
	t462: "Miasto",
	t463: "Telefon",
	t464: "Region",
	t466: "Index",
	t469: "Książka Adresowa wyeksportowana pomyślnie.",
	t470: "Delete item",
	t471: "Miernik",
	t472: "Uwaga: w input jest rozróżniana wielkość liter",
	t473: "Online",
	t474: "Zarząd RO",
	t475: "Zarząd SI",
	t476: "Zarząd OI",
	t477: "Kalkulacja",
	t478: "Ustaw czas bieżący",
	t479: "Rack",
	t480: "Brak sprężarki",
	t481: "Obwód",
	t482: "Urządzenie",
	t483: "Nieznany",
	t484: "typu err:",
	t485: "Dodaj",
	t486: "Kopii",
	t487: "Usunąć",
	t488: "Urz.Sterowanie",
	t489: "Typ.Urz.",
	t490: "LON",
	t491: "Wprowadź kwotę do skopiowania",
	t492: "no name",
	t493: "Konfigurowanie układu chłodzenia...",
	t494: "Configuration error:",
	t495: "Sprawdź konfigurację.",
	t496: "Konfiguracja układu chłodniczego została pomyślnie zakończona. Naciśnij przycisk OK, aby zamknąć ten kreator.",
	t497: "Configuration Status",
	t498: "Ostateczny układ chłodzenia",
	t500: "Code #",
	t501: "Layout",
	t502: "System Setup",
	t503: "Licencjonowania",
	t504: "Użytkowników",
	t505: "Nieautoryzowane",
	t506: "Sprawdź...",
	t507: "Czy na pewno chcesz opuścić ten ekran? Twoje ustawienia mogą zostać utracone.",
	t508: "Skanowanie sieci kontrolera...",
	t509: "Skanowanie",
	t510: "Weryfikowanie",
	t511: "AK-I/O",
	t512: "Przeskanuj",
	t513: "Uwzględnij programowanie w trybie offline",
	t514: "Magistrala urządzeń",
	t515: "Urządzenia online",
	t516: "Programowanie w trybie offline",
	t517: "Zduplikowany adres",
	t518: "Sterowanie sprężarką",
	t519: "Grupy ssące",
	t520: "N/A",
	t521: "Dostępne przypadki",
	t522: "Zezwalaj na tworzenie obwodów wielu przypadków",
	t523: "Wstawianie urządzeń",
	t524: "Obwód wstawiania",
	t525: "Obwód wielowymiernikowy.",
	t526: "Trwa ładowanie tabel...",
	t527: "Żądanie danych alarmowych dla",
	t528: "Pliki grup historii",
	t529: "Pliki danych historii",
	t530: "Wszystkie pliki",
	t531: "Pokaż symbole",
	t532: "Pokaż krzywe",
	t533: "Za mało historii zostały zebrane.",
	t534: "Pobierz najnowsze dane historii.",
	t535: "Stan/Ustawienia",
	t536: "Teraz pobieranie",
	t537: "próbki historii...",
	t538: "Pobieranie",
	t539: "Punkty danych...",
	t540: "Interwał",
	t541: "Min",
	t542: "Max",
	t543: "Średnio",
	t544: "Przejdź do głównego okna alarmowego.",
	t545: "Zatrzymywać automatyczne logowanie",
	t546: "Łączenie się z",
	t547: "Anuluj auto-logowanie",
	t548: "Wprowadź hasło wylogowywki",
	t549: "Web 1",
	t550: "Web 2",
	t551: "Logowanie się automatycznie",
	t552: "Wyloguj się po okresie braku aktywności",
	t553: "Home screen",
	t554: "Pokaż menu konfiguracji",
	t555: "Pokazywale elementów pomocniczych na pulpicie nawigacyjnym",
	t556: "Korzystanie z pełnego ekranu",
	t557: "Włączanie dźwięku alarmu",
	t558: "Zezwól na zamknięcie/minimalizację programu",
	t559: "Dostęp do jednostki z błędami #",
	t560: "Podłączony do sieci.",
	t561: "Auto logowanie anulowane",
	t562: "Ponów próbę połączenia",
	t563: "Wyloguj się z",
	t564: "Podłączony",
	t565: "Format czasu",
	t566: "Date Format",
	t567: "Ciśnienie",
	t568: "Poziom światła",
	t569: "Podchwładny",
	t570: "Podkooler",
	t571: "Codziennie",
	t572: "Tygodniowy",
	t573: "Plik HST",
	t574: "CSV File",
	t575: "Niedziela",
	t576: "Poniedziałek",
	t577: "Wtorek",
	t578: "Środa",
	t579: "Czwartek",
	t580: "Piątek",
	t581: "Sobota",
	t582: "Kolekcja dzienników historii",
	t583: "Włączanie automatycznego zbierania",
	t584: "Kiedy zbierać",
	t585: "Lokalizacja zapisywania plików",
	t586: "Wybierz format pliku",
	t587: "Wybierz folder do przechowywania plików historii...",
	t588: "Włącz automatyczne zbieranie historii, aby pobierać i zapisywać dzienniki historii dziennej lub tygodniowej skonfigurowanych punktów historii sklepu.",
	t589: "Aby wyświetlić pliki historii HST przejdź do głównego ekranu historii i wybierz historię importu. Aby wyświetlić pliki CSV, użyj dowolnej aplikacji arkusza kalkulacyjnego.",
	t590: "Jeśli adres IP bieżącego połączenia zostanie znaleziony w książce adresowej, zostaną zapisane wybrane punkty historii.",
	t591: "Wybieranie punktów danych",
	t592: "Wszystko wybrane",
	t593: "Wybrane punkty",
	t594: "Szukaj",
	t595: "Automatyczne podłączanie do",
	t596: "Proszę sprawdzić,",
	t597: "Automatyczne zapisywanie historii jest aktywne. Czy chcesz zamknać porgram?",
	t598: "Czy na pewno chcesz zamknąć ten program?",
	t599: "Akcja",
	t600: "Filter",
	t601: "Wszystkie Akcje",
	t602: "Więcej informacji...",
	t603: "Edytowalne",
	t604: "Operacji",
	t605: "Operacja anulowana",
	t606: "Pobieranie bazy danych zakończone",
	t607: "Zaktualizowano bazę danych jednostek",
	t608: "Pliki przeniesione do jednostki pomyślnie",
	t609: "Pobieranie zakończone, plik jest w trakcie przetwarzania w urządzeniu",
	t610: "Wystąpił błąd podczas przesyłania pliku do komputera, sprawdź, czy plik jest zapisywalny i dostępny",
	t611: "Przesyłanie pliku",
	t612: "Pobieranie pliku",
	t613: "Przekazywanie wybranych plików",
	t614: "Pobieranie wybranych plików",
	t615: "Usuwanie pliku",
	t616: "Usuwanie wybranych plików",
	t617: "Błąd połączenia FTP",
	t618: "Pobieranie wybranych plików do jednostki",
	t619: "Wystąpił błąd w połączeniu FTP. Sprawdź połączenie FTP i hasło/nazwę użytkownika.",
	t620: "Ftp podłączony",
	t621: "Bajtów",
	t622: "Pliki na urządzeniu zostaną zastąpione przez tę operację, Czy chcesz kontynuować?",
	t623: "Pliki na komputerze zostaną zastąpione przez tę operację, Czy chcesz kontynuować?",
	t624: "Pliki zostały pobrane do urządzenia.",
	t625: "Tylko chłodzenie",
	t626: "Sklep spożywczy",
	t627: "Pełna funkcjonalność",
	t628: "HVAC ONLY",
	t629: "Nazwa pliku jest niezgodna z prawem; Upewnij się, że nazwa pliku ma mniej niż 8 znaków.",
	t630: "Czy zastąpić kod aplikacji teraz?",
	t631: "Później",
	t632: "Przesłane",
	t633: "możesz wybrać TAK, aby zastąpić aplikację teraz lub wybrać Później",
	t634: "Wybierz katalog główny symulatora",
	t635: "Wybierz katalog systemu Windows",
	t636: "Wybierz katalog docelowy",
	t637: "Wybierz katalog bazy danych",
	t638: "Ftp odłączony",
	t639: "Limit czasu połączenia, rozłączony ftp",
	t640: "Przekazywanie wybranych plików do komputera",
	t641: "Usuń zaznaczone pliki",
	t642: "Port serwera FTP",
	t643: "Web Server Port",
	t644: "Ścieżka lokalna",
	t645: "Up",
	t646: "Rozmiar",
	t647: "Date",
	t648: "Połączyć",
	t649: "Odłącz",
	t650: "Ścieżka zdalna",
	t651: "Wolne miejsce dostępne:",
	t652: "Wybrane pliki",
	t653: "Files",
	t654: "Całkowity rozmiar:",
	t655: "Naciśnij aby zmienić folder",
	t656: "Zmiana...",
	t657: "Czy chcesz usunąć wybrane pliki?",
	t658: "Połączenie zamknięte, wyjście",
	t659: "Wybrana nazwa(nazwy) plików(nazw) jest(są) nielegalna, a pobieranie jest ignorowane.",
	t660: "Niektóre nazwy wybranych plików są niezgodne z prawem i ignorowane.",
	t661: "Pomyślnie przeniesione pliki do komputera",
	t662: "Za mało wolnego miejsca na dysku urządzenia.",
	t663: "Transfer file(s)",
	t664: "Zmodyfikowano",
	t665: "Kopiowanie plików z dysku flash na dysk RAM",
	t666: "Wybierz operację kopiowania plików:",
	t667: "Skopiuj wszystkie skonfigurowane pliki EDF z dysku flash",
	t668: "Skopiuj wszystkie pliki EDF z dysku flash",
	t669: "Pliki edf/ed3 są przechowywane na dysku flash urządzenia, ale nie są ładowane do ekranu Zarządzanie plikami.  Skorzystaj z poniższych opcji, aby skopiować zapisane pliki EDF w urządzeniu na ekran Zarządzania plikami",
	t670: "Ta operacja kopiuje wszystkie pliki EDF skonfigurowane w urządzeniu z dysku flash na dysk RAM",
	t671: "Ta operacja Kopiuje wszystkie pliki EDF w urządzeniu z dysku flash na dysk RAM",
	t672: "Nie istnieje prawidłowy plik EDF",
	t673: "Zakończono kopiowanie plików EDF",
	t674: "skopiowane pliki",
	t675: "Potwierdź pobieranie bazy danych",
	t676: "Unit Address",
	t677: "Adres IP/Nazwa hosta",
	t678: "Database File Name",
	t679: "Wybierz",
	t680: "Aktualizacja ukończona, zresetowanie i uaktualnienie jednostki. Może to potrwać kilka minut.",
	t681: "Witamy w Menedżerze uaktualnień - Krok 1 z 2",
	t682: "Witamy w Menedżerze uaktualnień - Krok 2 z 2",
	t683: "Przejść",
	t684: "Kontrola przed uaktualnieniem",
	t685: "Zlokalizuj oprogramowanie układowe",
	t686: "Kopiować bazę danych?",
	t687: "Kopia bazy danych",
	t688: "Przesyłanie oprogramowania",
	t689: "Oprogramowanie do zastępowania",
	t690: "Wybierz oprogramowanie do uaktualnienia",
	t691: "Nie wykryto bezpiecznej aplikacji fabrycznej.",
	t692: "Możesz kontynuować, ale wszelkie błędy podczas procesu aktualizacji mogą uniemożliwić działanie systemu.",
	t693: "Zdecydowanie zaleca się, aby nie postępować.",
	t694: "Czy chcesz kontynuować?",
	t695: "Zdalna modernizacja systemu niesie ze sobą ryzyko awarii, co może spowodować błąd systemu.",
	t696: "Zastrzeżenie",
	t697: "Przekazywanie bazy danych zakończone",
	t698: "Kopiowanie bazy danych zakończone",
	t699: "Alplikacja załadowana",
	t700: "Zakońone nadpisywanie aplikacji",
	t701: "Aktualizacja oprogramowania powiodła się",
	t702: "Czy chcesz przywrócić urządzenie do jego fabrycznej aplikacji?",
	t703: "Sprawdzanie sprzętu",
	t704: "Sprawdzanie statusu urządzenia",
	t705: "Fabryczne bezpieczeństwo awarii",
	t706: "Aktualna wersja oprogramowania układowego",
	t707: "Rozpocznij uaktualnienie",
	t708: "Wybieranie istniejącego pliku bazy danych",
	t709: "Wybieranie istniejącego pliku oprogramowania układowego",
	t710: "Cofnij",
	t711: "Zlokalizuj oprogramowanie układowe",
	t712: "Wybierz plik csi",
	t713: "Wybierz plik .mai",
	t714: "Pobieranie zdalne nie jest obsługiwane przez tę płytę procesora.",
	t715: "Błąd ładowania aplikacji.",
	t716: "Przekazywanie bazy danych nie powiodło się, spróbuj ponownie",
	t717: "Zakońone nadpisywanie aplikacji",
	t718: "Błąd nadpisywania aplikacji spróbuj ponownie.",
	t719: "Błąd nadpisywania aplikacj",
	t720: "Nie wykryto bezpiecznej aplikacji fabrycznej.",
	t721: "Zrobić",
	t722: "Wybrana aplikacja może mieć inny typ wersji niż oprogramowanie układowe bieżącego urządzenia, kontynuowanie operacji może nieść ze sobą ryzyko awarii i błędu systemu.",
	t723: "Wybrana nazwa(nazwy) plików(nazw) jest(są) nielegalna, a pobieranie jest ignorowane.",
	t724: "Niektóre nazwy wybranych plików są niezgodne z prawem i ignorowane.",
	t725: "Brakuje pinu berg.",
	t726: "Unit's battery is low.",
	t727: "Sieć hosta jednostki nie powiodła się.",
	t728: "Jednostka jest w stanie załadunku",
	t729: "Wybieranie pliku docelowego",
	t730: "Wybieranie katalogu docelowego",
	t731: "Przekazywanie bazy danych zakończone",
	t732: "Status OK",
	t733: "Status Error",
	t734: "Stan nieznany",
	t735: "Ta operacja może zastąpić wybrane pliki w symulatorze docelowym, czy chcesz kontynuować?",
	t736: "Pobieranie bazy danych zakończone, trzeba zresetować system",
	t737: "Pobieranie bazy danych nie powiodło się, musisz spróbować ponownie później",
	t738: "Urządzenie zostanie zresetowane automatycznie po tej operacji, Czy chcesz kontynuować?",
	t739: "Wybierz bazę danych",
	t740: "Download Database File?",
	t741: "Upload Database File?",
	t742: "Pobieranie bazy danych do urządzenia, proszę czekać",
	t743: "Baza danych pobrana pomyślnie, teraz resetowanie jednostki",
	t744: "Zakończono aktualizację bazy danych, pomyślnie zresetowano jednostkę",
	t745: "Aktualizacja bazy danych nie powiodła się! Spróbuj ponownie.",
	t746: "Błąd podczas aktualizowania bazy danych",
	t747: "Czek przed pobraniem",
	t748: "Zlokalizuj bazę danych",
	t749: "Pobieranie bazy danych",
	t750: "Informacje o bieżącej bazie danych",
	t751: "Wybierz bazę danych do pobrania",
	t752: "Zapisz bazę danych na komputerze (Przekaż)",
	t753: "Załaduj bazę danych do jednostki (pobierz)",
	t754: "Wymaga autoryzacji inspektora",
	t755: "Start Upload",
	t756: "Wybierz operację bazy danych:",
	t757: "Start Download",
	t758: "Aktualna wersja oprogramowania układowego",
	t760: "Pobieranie bazy danych powiodło się!",
	t761: "Witamy w Menedżerze baz danych - Krok 1 z 2",
	t762: "Witamy w Menedżerze baz danych - Krok 2 z 2",
	t763: "Połączenie z jednostką nie powiodło się, sprawdź adres IP/ADRES URL hosta",
	t764: "Zdalna aktualizacja bazy danych systemu niesie ze sobą ryzyko awarii, co może spowodować błąd systemu.",
	t765: "Pobieranie bazy danych zakończone",
	t766: "Skanowanie Sieci Ethernet",
	t767: "Wprowadź pierwszy adres IP, a następnie naciśnij przycisk start",
	t768: "Skanowania",
	t769: "Skanowanie",
	t770: "Kolekcja dzienników historii",
	t771: "Analog. kolory wykresu",
	t772: "Kolory wykresów cyfrowych",
	t773: "Harmonogramy zamykania",
	t774: "Podsumowanie rozmrażania",
	t775: "Nocne harmonogramy niepowodzeń",
	t776: "Urz.Harmonogr.Oświetl.",
	t777: "Stan załadunku",
	t778: "Zbiór logów alarmu",
	t779: "Wybierz folder do przechowywania plików alarmowych...",
	t780: "Miesięczne",
	t781: "Włącz automatyczne zbieranie alarmów, aby okresowo pobierać i zapisywać informacje alarmowe w pliku.",
	t782: "CSV Separator",
	t783: "Brak alarmów",
	t784: "Pobieranie liczby alarmów dla jednostki:",
	t785: "Pobieranie szczegółów alarmu z jednostki:",
	t786: "Automatyczne zapisywanie alarmów jest aktywne. Czy chcesz zmknąć program?",
	t787: "Godzinowe",
	t788: "Spowoduje to zaktualizowanie bazy danych jednostki i ustawienie lokalizacji na środku bieżącego widoku. Przejść?",
	t789: "przetworzone próbki",
	t790: "Skonfiguruj grupę ssącą.",
	t791: "Aby ponownie użyć Kreatora układu więcej niż raz należy zresetować układ bazy danych chłodniczych.",
	t792: "Do this via the Configuration-&gt;Control page and set the number of Racks/Packs to zero.",
	t793: "Uwaga: wszelkie harmonogramy, obliczenia lub importy oparte na wcześniejszej konfiguracji chłodnicy będą musiały zostać ponownie skonfigurowane.",
	t794: "Proces kopiowania zakończony.",
	t795: "Raport chłodniczy",
	t796: "Ostateczny układ HVAC",
	t797: "Konfiguracja układu HVAC została pomyślnie ukończona. Naciśnij przycisk OK, aby zamknąć ten kreator.",
	t798: "Konfigurowanie układu HVAC...",
	t799: "Aby ponownie użyć Kreatora układu HVAC więcej niż raz należy zresetować układ bazy danych HVAC.",
	t800: "Do this via the Configuration-&gt;Control page and set the number of HVAC units to zero.",
	t801: "Uwaga: wszelkie harmonogramy, obliczenia lub importy oparte na wcześniejszej konfiguracji HVAC będą musiały zostać ponownie skonfigurowane.",
	t802: "Staging pattern",
	t803: "Kroki",
	t804: "Level",
	t805: "Pokaż wiele etykietek dotyczących danych",
	t806: "Liczba kroków",
	t807: "Nie można zapisać pliku.",
	t808: "Niepoprawny model.",
	t809: "Ładowanie powiodło się.",
	t810: "Open",
	t811: "Ta funkcja jest dostępna tylko w klasycznej wersji StoreView.",
	t812: "Kontynuować",
	t813: "Kreator opuszczania",
	t814: "Operacja zakończyła się sukcesem. Czy chcesz kontynuować lub chcesz opuścić kreatora.",
	t815: "Błąd podczas pobierania danych rejestratora alarmów.",
	t817: "Nazwa urządzenia :",
	t819: "In Progress...",
	t820: "Dane alarmowe nie są obecne.",
	t821: "Licznik alarmów",
	t822: "Opcje wyświetlania mimic",
	t823: "Wyświetlanie tylko tekstu",
	t824: "Wyświetl tylko ikonę",
	t825: "Wyświetlanie tekstu i ikony",
	t826: "1 Tydzień",
	t827: "2 Tygodnie",
	t828: "3 Tygodnie",
	t829: "4 Tygodnie",
	t830: "Ustawienia grafiki",
	t831: "Nie można wyświetlić alarmów, sprawdź auto zapis w menu ustawienia",
	t832: "Zmiana kolejności niemożliwa z IO lub układów zawartych w konfiguracji.",
	t833: "Ten Host/IP jest już zapisany w książce, czy chcesz zaktualizować?",
	t834: "Przeglądaj",
	t835: "Wybierz folder dla Książki adresowej",
	t836: "Lokalizacja",
	t837: "Wybrana lokalizacja nie zawiera danych książki adresowej, czy chcesz kontynuować zapis w wybranej lokalizacji?",
	t838: "Nr akcji alarmu",
	t839: "Plik książki adresowej nie istnieje w podanej lokalizacji. Książka adresowa jest załadowana z lokalnego…",
	t840: "Wybierz miejsce aby zapisać log.",
	t841: "Wybierz miejsce aby zapisać.",
	t842: "Trwa pobieranie listy alarmów",
	t843: "Zapis historii w trakcie",
	t844: "Działanie w tle",
	t845: "Tworzenie zapisu listy alarmów",
	t846: "Adres hosta IP/URL nie może być pusty",
	t847: "Pole adresu nie może być puste",
	t849: "Nie masz ostatniej wersji pliku, chcesz załadować dane z nowszej wersji?",
	t850: "Błąd wersji",
	t851: "Zapis historii w trakcie",
	t852: "Pola Nazwa użytkownika i Hasło nie powinny być puste.",
	t853: "Pole Adres IP nie powinno być puste.",
	t854: "Pole Adres IP/ADRES URL hosta nie powinno być puste.",
	t855: "Nazwa użytkownika nie powinna być pusta.",
	t856: "Hasło nie powinno być puste.",
	t857: "SM800, z którego masz połączenie z",
	t858: "nie został ukończony Kreator uruchamiania początkowego. Wykonaj to zadanie i ponownie połącz się z sm800",
	t859: "Ustaw na teraz",
	t860: "Nieprawidłowe dane wejściowe",
	t861: "Tekst jest za długi",
	t862: "Przesunięcie",
	t863: "Nie można komunikować się z jednostkami w sieci hosta",
	t864: "Sprawdź, czy adresy są różne. Konfiguracja jest aktualizowana po naciśnięciu przycisku zapisz.",
	t865: "Wybierz prawidłowy plik historii (.hst)",
	t866: "Wybierz prawidłowy plik grupy historii (grp)",
	t867: "Kolor",
	t868: "Procent",
	t869: "Napięcie",
	t870: "Bieżącego",
	t871: "Zasilania",
	t872: "Częstotliwości",
	t873: "Stężenie",
	t874: "Przepływu",
	t875: "Prędkość",
	t876: "Kwasowości",
	t877: "Wybierz wykresy na dole szuflady",
	t878: "Zapisz PNG",
	t879: "Rysowania",
	t880: "Stan systemu...",
	t881: "Stan systemu i jednostki",
	t882: "Gataway ( Gataway )",
	t883: "Licencje funkcji",
	t884: "Wersja oprogramowania",
	t885: "Wersja modułu przeglądarki",
	t886: "Wersja Danux",
	t887: "Adres Mac",
	t888: "Pomoc techniczna i aktualizacje",
	t889: "Nazwa jednostki",
	t890: "Ponownie",
	t891: "Utracone połączenie",
	t892: "Nie znaleziono sprzętu {{type}}.",
	t893: "Aktualnie używana wersja oprogramowania nie jest obsługiwana",
	t894: "Szybkie linki",
	t895: "Sprzęt",
	t896: "Wykresów",
	t897: "Narzędzia",
	t898: "Wycofywanie oprogramowania",
	t899: "Diagnostyka",
	t900: "Błąd",
	t901: "Nie znaleziono danych {{alarmów}}",
	t902: "Ustawienia",
	t903: "Nazwa aplikacji",
	t904: "Wersja aplikacji",
	t905: "Komunikat",
	t906: "Typ zdarzenia",
	t907: "Sygnatury czasowej",
	t908: "Wycofywanie wersji",
	t909: "Aktualna wersja",
	t910: "Oprogramowania",
	t911: "Wycofywania...",
	t912: "Wybierz sprzęt z listy",
	t913: "Widok kalendarza",
	t914: "Wystąpił błąd podczas ładowania urządzeń, spróbuj ponownie.",
	t915: "Wystąpił błąd podczas ładowania harmonogramów, spróbuj ponownie.",
	t916: "Nie znaleziono sprzętu",
	t917: "Harmonogramy eksportu",
	t918: "Wybierz typ eksportu CSV lub PDF",
	t919: "Eksport jako CSV",
	t920: "Przygotowywanie pliku PDF",
	t921: "Eksportowanie w formacie PDF",
	t922: "Proszę podać adres IP w tym formacie xx.xx.xx.xx[:xxxx]",
	t923: "Nazwę użytkownika",
	t924: "Logowania",
	t925: "Wycofywania",
	t926: "Oprogramowanie Rollback SM800A",
	t927: "Ta akcja spowoduje wycofanie całego oprogramowania do wymienionych wersji.",
	t928: "Edytor urządzeń imprezowych",
	t929: "tekst, który wyjaśnia funkcję",
	t930: "Aktualizacja cdf",
	t931: "Urządzenie mapy",
	t932: "Plik urządzenia",
	t933: "1-bazowy numer rejestru",
	t934: "Typ magazynu",
	t935: "Min",
	t936: "Max",
	t937: "Tylko do odczytu",
	t938: "Dziennika",
	t939: "Typ wiadomości",
	t940: "Słowo kluczowe konwersji",
	t941: "Parametry",
	t942: "Addr.",
	t943: "Priorytet",
	t944: "Bitowych",
	t945: "Zanegowane",
	t946: "Nowy plik",
	t947: "Otwórz plik",
	t948: "Generowanie pliku",
	t949: "Grupa",
	t950: "Wersja produktu",
	t951: "Błąd",
	t952: "Nie znaleziono",
	t953: "Nie znaleziono tego urządzenia",
	t954: "Poza temp.",
	t955: "Nie znaleziono {{equipment}}",
	t956: "Przypadkach",
	t957: "Alarm pomyślnie wyciszyć/działać",
	t958: "Wyciszenie/działanie nie powiodło się dla tego alarmu",
	t959: "Wystąpił błąd podczas ładowania jednostek, spróbuj ponownie.",
	t960: "Brak wyposażenia",
	t961: "Nie znaleziono sprzętu {{type}}",
	t962: "Konfigurowanie menedżera systemu",
	t963: "Urządzenia",
	t964: "Cofnij",
	t965: "Zmieniono wartość pola, czy chcesz zapisać zmiany?",
	t966: "Wyczyść wybrane punkty historii",
	t967: "Shr {{number}}",
	t968: "Prędkość pompy w godz.",
	t969: "Sd",
	t970: "V3h",
	t971: "Odniesienie do godz.",
	t972: "Św {{number}}",
	t973: "Tw odniesienia",
	t974: "Prędkość pompy Tw",
	t975: "V3tw",
	t976: "Trec",
	t977: "Prec",
	t978: "Vrec OD",
	t979: "Prec referencja",
	t980: "Trec odniesienie",
	t981: "Pc",
	t982: "SS",
	t983: "Vhp OD",
	t984: "Odniesienie do Pgc",
	t985: "Wyrzutnik OD",
	t986: "Pgc",
	t987: "Shp",
	t988: "Sgc",
	t989: "Sc3",
	t990: "Sgc ster.",
	t991: "Dyr. Pojemność",
	t992: "Po",
	t993: "SS",
	t994: "Sd",
	t995: "PC",
	t996: "Zdolność do pracy",
	t997: "Certyfikat przeglądarki",
	t998: "Wyloguj",
	t1000: "Sortowanie tylko na {{count}}",
	t1001: "Załadowano {{count}} z {{total}} alarmów",
	t1002: "Potwierdzenie powiodło się dla wszystkich alarmów",
	t1003: "Potwierdzenie nie powiodło się dla wszystkich alarmów",
	t1004: "Potwierdzono tylko {{count}} z {{total}} alarmów",
	t1005: "Eksport nie powiódł się dla wszystkich alarmów",
	t1006: "Eksport się powiódł",
	t1007: "Eksport nie powiódł się, wymagany jest co najmniej jeden typ alarmu",
	t2000: "-",
	t2001: "Odpowiedź jest zbyt duża dla bufora transmisji XML, który ma 32 KB bajtów",
	t2002: "Żaden atrybut skojarzony z elementem lub poleceniem cmd nie ma elementu „cmd” lub nie może pisać do zmiennej lub nie może odczytać zmiennej lub nie może odczytać punktu lub nie może zapisywać trybu włączania/wyłączania wejścia lub wyjścia przekaźnikowego",
	t2003: "Złe wprowadzanie polecenia",
	t2004: "Błąd w parametrach postu CGI",
	t2005: "Błąd podczas konwersji odpowiedzi XML z jej wewnętrznej reprezentacji na łańcuch odpowiedzi XML",
	t2006: "Błąd dekodowania ciągu adresu URL",
	t2007: "Przepełnienie pamięci",
	t2008: "Kompresja danych odpowiedzi nie powiodła się",
	t2009: "Błąd przetwarzania żądania",
	t2010: "Błąd dostępu do danych — szereg przyczyn, które nie są dalej usuwane",
	t2011: "Niezdefiniowana wartość atrybutu działania polecenia Wartość atrybutu jest nieprawidłowa",
	t2012: "Błąd w numerze referencyjnym alarmu lub alarm nieskonfigurowany",
	t2013: "Wejściowy ciąg parametru cgi jest za długi.",
	t2014: "Nie znaleziono adresu urządzenia",
	t2015: "Nie udało się uzyskać autoryzacji do zapisu",
	t2016: "Nie skonfigurowano historii",
	t2017: "Nie zwrócono żadnych danych historycznych, może być błędem czasu",
	t2018: "Twórz błędy plików",
	t2019: "Błędy odczytu plików",
	t2020: "Przekroczono maksymalny rozmiar listy urządzeń",
	t2021: "nieużywany",
	t2022: "Nieprawidłowy indeks historii",
	t2023: "Nie można znaleźć indeksu punktu tablicy z typu węzła, węzła, modu i punktu.",
	t2024: "Nie można znaleźć indeksu historii",
	t2025: "Zapytanie o historię nie zostało zainicjowane przed próbą uruchomienia zapytania o historię.",
	t2026: "Próbowano wykonać hq_start_query, gdy zapytanie historii jest aktywne",
	t2027: "Nie znaleziono atrybutów poleceń, gdy niektóre są oczekiwane",
	t2028: "Podano początek epoki bez końca i vice versa",
	t2029: "Nie można przekonwertować z formatu czasu i daty na sekundy epoki",
	t2030: "Polecenie wymaga identyfikatora zapytania, ale żadnego nie podano",
	t2031: "Błąd podczas budowania odpowiedzi XML",
	t2032: "Podany identyfikator zapytania nie pasuje do aktywnego zapytania",
	t2033: "Próbowano wykonać polecenie hq_get_data, ale dane nie są jeszcze dostępne",
	t2034: "Podano częstotliwość próbkowania, która nie jest jedną z dozwolonych",
	t2035: "Interwał uśredniania jest mniejszy lub równy częstotliwości próbkowania, z jaką dane zostały zarejestrowane",
	t2036: "Czas zatrzymania jest krótszy niż czas początku lub czas zatrzymania minus czas początku jest krótszy niż przedział uśredniania",
	t2037: "Podany identyfikator urządzenia nie pasuje do żadnego obecnie znanego ak255. Pamiętaj, że tylko skonfigurowane urządzenia są znane ak255",
	t2038: "Brak atrybutów identyfikatora",
	t2039: "Nieprawidłowe atrybuty identyfikatora",
	t2040: "Brak numeru harmonogramu",
	t2041: "Nieprawidłowy numer harmonogramu",
	t2042: "Brak szczegółów harmonogramu",
	t2043: "Brakuje on_time",
	t2044: "Brakująca godzina",
	t2045: "Nieprawidłowa godzina",
	t2046: "Brakująca minuta",
	t2047: "Nieprawidłowa minuta",
	t2048: "Brak czasu wyłączenia",
	t2049: "Brakujące dni tygodnia",
	t2050: "Nieprawidłowe dni tygodnia",
	t2051: "Brakujące święta",
	t2052: "Nieprawidłowe święta",
	t2053: "Brakujące lub nieprawidłowe holiday_start",
	t2054: "Brakujące lub nieprawidłowe holiday_end",
	t2055: "Brakujące lub nieprawidłowe holiday_open",
	t2056: "Brakujące lub nieprawidłowe holiday_close",
	t2057: "Święto nieskonfigurowane",
	t2058: "Brak opisu",
	t2059: "Nie można skasować alarmu",
	t2060: "Brakujący lub nieprawidłowy sklep_open",
	t2061: "Brakujący lub nieprawidłowy sklep_zamknięty",
	t2062: "Alarm nieskonfigurowany",
	t2063: "Węzeł offline",
	t2064: "Brakujący indeks",
	t2065: "Nieprawidłowy indeks",
	t2066: "Przekaźnik nie może być włączony w czasie",
	t2067: "Zmieniana nazwa zawiera za dużo znaków. W przypadku zmiany nazwy sklepu lub nazwy jednostki musi wynosić < = 16 znaków, w przypadku zmiany identyfikatora sklepu1 lub identyfikatora sklepu2 musi wynosić < = 8 znaków.",
	t2068: "Brakujące elementy skojarzone z elementem polecenia.",
	t2069: "Nie zdefiniowano identyfikatora tagu",
	t2070: "Brakujący węzeł liścia dla danych",
	t2071: "Brak zmian w danych",
	t2072: "Nie udało się zaktualizować bazy danych",
	t2073: "Nie można zapisywać",
	t2074: "Błąd licznika zrzutu",
	t2075: "Brak metrów",
	t2076: "Nieprawidłowy poziom szopy",
	t2077: "Nieprawidłowy typ",
	t2078: "System zajęty",
	t2079: "Zasoby nieskonfigurowane do zrzucania obciążenia",
	t2080: "Nieprawidłowa ocena mocy dla zasobu",
	t2081: "Nieprawidłowe opóźnienie uruchomienia dla zasobu",
	t2082: "Konfiguracja zajęta",
	t2083: "Skanowanie we/wy w toku",
	t2084: "Zbyt wiele trwa odszranianie",
	t2085: "Próba załadowania złej wersji bazy danych do jednostki",
	t2086: "Ładowanie bazy danych nie powiodło się",
	t2087: "Brak dostępnej pamięci internetowej",
	t2088: "Odpowiedź na żądanie nieskonfigurowana (tylko system AK355)",
	t2089: "Brakujący lub nieprawidłowy rozmiar pliku.",
	t2090: "Brak zakodowanego pliku (elementy b64)",
	t2091: "Nie udało się zapisać bazy danych na dysku RAM.",
	t2092: "Zbyt duży rozmiar pliku.",
	t2093: "Brak nazwy pliku.",
	t2094: "Brak identyfikatora pliku.",
	t2095: "Brak indeksu ładowania pliku (Uwaga: jest to indeks sekwencji dla load_file load_database).",
	t2096: "Identyfikator pliku nie pasuje lub brakuje go.",
	t2097: "Operacja ładowania pliku już w toku.",
	t2098: "Zdekodowany plik nie odpowiada oryginalnemu rozmiarowi pliku.",
	t2099: "Operacja ładowania pliku została anulowana lub nie została rozpoczęta.",
	t2100: "Nieprawidłowe żądanie użytkownika (pierwotny żądający nie pasuje do adresu IP)",
	t2101: "Nieprawidłowa sekwencja ładowania pliku.",
	t2102: "Nieprawidłowy katalog",
	t2103: "Nieprawidłowe rozszerzenie",
	t2104: "Nieprawidłowe przesunięcie",
	t2105: "Błąd podczas kodowania pliku",
	t2106: "Błąd dekodowania pliku",
	t2107: "Nie udało się otworzyć pliku do odczytu/zapisu",
	t2108: "Nie udało się zapisać do pliku",
	t2109: "Plik jest chroniony przed zapisem w systemie plików Flash",
	t2110: "Nie udało się usunąć pliku.",
	t2111: "Nie można usunąć folderu.",
	t2112: "Brakujące urządzenia",
	t2113: "Nie znaleziono wszystkich urządzeń z adresu URL",
	t2114: "Brakujące parametry historii",
	t2115: "Nie znaleziono wszystkich parametrów historii z adresu URL",
	t2200: "Aktualnie używana wersja oprogramowania ( {{currentVersion}} ) nie jest zalecana",
	t2201: "Aktualnie używana wersja oprogramowania ( {{currentVersion}} ) nie jest zalecana (zalecane > {{recommendedVersion}} )",
	t2202: "Błąd wersji oprogramowania",
	t2203: "Edytor graficzny",
	t2204: "Edytuj",
	t2205: "Rysowania",
	t2206: "Nowy widok",
	t2207: "Utworzony",
	t2208: "Upuść",
	t2209: "Nowa grafika",
	t2210: "Utwórz nowy widok graficzny",
	t2211: "Name",
	t2212: "Przemianować",
	t2214: "Eksport",
	t2213: "Duplikować",
	t2215: "Usunąć",
	t2216: "Czy na pewno chcesz usunąć widok „ {{name}} ”?",
	t2217: "Eksportuj wszystkie widoki",
	t2218: "Więcej...",
	t2219: "Reguła",
	t2220: "zasady",
	t2221: "Nazwa reguły",
	t2222: "Panel edycji",
	t2223: "Warunki",
	t2224: "Gradient",
	t2225: "Odwróć warunki",
	t2226: "Mapowania",
	t2227: "Mapuj kształty według",
	t2228: "Identyfikator kształtu",
	t2229: "Stany",
	t2230: "Dodaj mapowanie",
	t2231: "Dodaj regułę",
	t2232: "Operator",
	t2233: "Dodaj warunek",
	t2234: "Zacznij od dodania reguł",
	t2235: "Czy na pewno chcesz usunąć regułę „ {{name}} ”?",
	t2236: "Sporządzono kopię reguły",
	t2237: "Widoki witryny",
	t2238: "Aby je wyświetlić, musisz utworzyć widoki witryny",
	t2239: "Przejdź do edytora graficznego",
	t2240: "Przejdź do szczegółów",
	t2241: "Wybierz parametr",
	t2242: "Nie znaleziono parametrów",
	t2243: "Opcje",
	t2244: "Wejście wł./wył.",
	t2245: "Wyjscie przekaznikowe",
	t2246: "Wejście czujnika",
	t2247: "Zmienne wyjście",
	t2248: "Podstawowe Urządzenie",
	t2249: "Pusty węzeł",
	t2250: "Rejestrowanie historii nie jest obsługiwane...",
	t2251: "Spróbować ponownie",
	t2252: "Nie udało się pobrać historii",
	t2253: "Wczytywanie historii może trochę potrwać i nie obsługuje wielu zapytań jednocześnie. Spróbuj ponownie.",
	t2254: "Nazwa parametru",
	t2255: "Nazwa urządzenia",
	t2256: "Typ urządzenia",
	t2257: "Nie udało się wczytać wszystkich parametrów historii ( {{n}} nie powiodło się)",
	t2258: "Zła nazwa użytkownika lub hasło",
	t2259: "Brak komunikacji z kontrolerem",
	t2260: "Aktualizacja oprogramowania",
	t2261: "System",
	t2262: "Wycofywanie oprogramowania",
	t2263: "zainstalować",
	t2264: "Przywrócenie oprogramowania zastąpi nowszą wersję",
	t2265: "Przywróć oprogramowanie do wersji kopii zapasowej {{version}}",
	t2266: "Baza danych",
	t2267: "Aktualizacja jest dostępna",
	t2268: "Eksportuj bazę danych",
	t2269: "Pobierać",
	t2270: "Pobieranie bazy danych",
	t2271: "Nie można wyeksportować bazy danych",
	t2272: "Plik bazy danych",
	t2999: "Błąd komunikacji",
	t2998: "Nieznany błąd",
	t3000: "Szczegóły",
	t3001: "Tutaj przeszły kod XML",
	t3002: "Przypisać do",
	t3003: "Stan",
	t3004: "Zarządzanie oprogramowaniem",
	t3005: "Lokalnie zapisany plik",
	t3006: "Online przez internet",
	t3007: "Upewnij się, że zasilanie nie jest przerywane podczas procesu aktualizacji. Zaleca się wykonanie kopii zapasowej bazy danych systemu przed aktualizacją systemu. Po zakończeniu aktualizacji oprogramowania zaleca się sprawdzenie stanu pracy systemu w celu potwierdzenia poprawności sterowania i działania.",
	t3008: "Nie wybrano plików",
	t3009: "Wybierz plik oprogramowania",
	t3010: "Przesyłanie plików",
	t3011: "Kontrola bezpieczeństwa",
	t3012: "Rozpakowywanie pliku",
	t3013: "Sprawdzam plik",
	t3014: "Przesyłanie pliku nie powiodło się!",
	t3015: "Kontrola bezpieczeństwa nie powiodła się!",
	t3016: "Wyodrębnianie nie powiodło się!",
	t3017: "Sprawdzanie pliku nie powiodło się!",
	t3018: "Pobieranie pliku: {{fileName}}",
	t3019: "Plik został pomyślnie pobrany!",
	t3020: "Pobieranie nie udane!",
	t3021: "Prześlij pliki",
	t3022: "Pobierz pliki",
	t3023: "Aktualizacja systemu operacyjnego",
	t3024: "Aktualizacja EDF",
	t3025: "Pobierz pliki",
	t3026: "Wartość wybranego parametru była niestety niedostępna",
	t3027: "Import",
	t3028: "Wymagany jest co najmniej jeden plik",
	t3029: "Plik jest nieprawidłowy",
	t3030: "Zarządzaj widokami graficznymi",
	t3031: "Wgrywać",
	t3032: "Migrować",
	t3033: "Odczytywanie plików graficznych, proszę czekać...",
	t3034: "Importowanie pliku graficznego, proszę czekać...",
	t3035: "Wybierz grafikę",
	t3036: "Raporty",
	t3037: "Importuj bazę danych",
	t3038: "Wybierz plik bazy danych",
	t3039: "Upewnij się, że zasilanie nie jest przerywane podczas procesu przywracania. Zaleca się wykonanie kopii zapasowej bazy danych systemu przed wycofaniem systemu. Po zakończeniu przywracania oprogramowania zaleca się sprawdzenie stanu działania systemu w celu potwierdzenia prawidłowego sterowania i działania.",
	t3040: "Menedżer systemu uruchomi się ponownie po aktualizacji",
	t3041: "Zwiększać",
	t3042: "Pokaż komunikaty debugowania",
	t3043: "Maks. dozwolone znaki -",
	t3044: "Proszę przesłać plik aktualizacji",
	t3045: "Aktualna wersja oprogramowania",
	t3046: "Aktualna wersja Danux",
	t3047: "Najnowsza wersja oprogramowania",
	t3048: "Najnowsza wersja Danux",
	t3049: "Tworzenie kopii zapasowej, proszę czekać",
	t3050: "Instalowanie pliku proszę czekać",
	t3051: "Instalacja aktualizacji",
	t3052: "Urządzenie uruchamia się ponownie",
	t3053: "Ponowne uruchamianie menedżera systemu",
	t3054: "Przeglądarka odświeży się teraz",
	t3055: "Oprogramowanie zostało pomyślnie zainstalowane",
	t3056: "Aktualizacja nie powiodła się, wystąpił następujący błąd:",
	t3057: "Wykonaj kopię zapasową aktualnej wersji oprogramowania",
	t3058: "Wykonaj kopię zapasową bieżących plików EDF",
	t3059: "Widok graficzny został pomyślnie zapisany",
	t3060: "Zastosuj obraz tła",
	t3061: "Jasny",
	t3062: "Przerobić",
	t3063: "Sklep",
	t3064: "Komplikacje nocne w obwodzie",
	t3065: "Ssanie nocne niepowodzenia",
	t3066: "Pobrana wersja {{fileName}}",
	t3067: "Aby zaktualizować wybrane jednostki, naciśnij „Zainstaluj”",
	t3068: "Wycofaj wersję {{updateType}}",
	t3069: "Instalowanie",
	t3070: "Zainstaluj wersję wycofaną",
	t3071: "Żadna z dostępnych jednostek nie zawiera wersji cofania",
	t3072: "Nowa wersja dostępna online",
	t3073: "Prześlij plik dla wybranych jednostek",
	t3074: "Prześlij plik dla wybranej jednostki",
	t3075: "Brak dostępnych wersji cofania dla jednostki",
	t3076: "Pobieranie zakończone",
	t3077: "Aktualna wersja {{updateType}}",
	t3078: "Najnowsza wersja {{updateType}}",
	t3079: "Trwa aktualizacja, czy na pewno chcesz zamknąć okno?",
	t3080: "Danux nie jest aktualny! Zaktualizuj Danux, a następnie ponownie zaktualizuj oprogramowanie",
	t3081: "Aplikacji nie można obniżyć do tej wersji",
	t3082: "Informacje o aplikacji",
	t3083: "Informacje o wydaniu",
	t3084: "Warunki i prywatność",
	t3085: "Licencje",
	t3086: "Przejdź do konfiguracji, aby ustawić harmonogramy",
	t3087: "Niestety wystąpił błąd podczas przygotowywania migracji. Upewnij się, że wszystkie jednostki są dostępne w sieci i prawidłowo skonfigurowane. \n\n Wystąpił następujący błąd:\n {{error}}",
	t3088: "Importuj lub migruj istniejącą grafikę",
	t3089: "Wyświetlacz",
	t3090: "Nazwa zasobu",
	t3091: "Zawalić się",
	t3092: "Importowanie zakończone",
	t3093: "Bezpieczeństwo rozładowania",
	t3094: "Bezpieczeństwo ssania",
	t3095: "Kutyna rozładowania",
	t3096: "Wyłącznik rozładowania",
	t3097: "Odcięcie ssania",
	t3098: "Ssanie Cutin",
	t3099: "Przepustowość",
	t3100: "Nie można znaleźć plików migracji (flp, dpj lub json)",
	t3101: "Przekroczono limit czasu parsowania pliku",
	t3102: "Pliki i adresy urządzeń innych firm",
	t3103: "Krata",
	t3104: "Zablokować",
	t3105: "Xml",
	t3106: "Wybierz parametr do wyświetlenia",
	t3107: "Parametr urządzenia",
	t3108: "Brak jednostek",
	t3109: "Nie znaleziono ani nie załadowano żadnych jednostek",
	t3110: "HACCP",
	t3111: "ZALETA",
	t3112: "Brak dostępnych danych hackp",
	t3113: "Informacje o jednostce",
	t3114: "Dostępne aktualizacje",
	t3115: "Przejdź do oprogramowania",
	t3116: "Nie połączony",
	t3117: "Obecny zakres dostępu dla tej aplikacji to ' {{targetLevel}} ', podany użytkownik znajduje się powyżej tego zakresu pod względem praw dostępu",
	t3118: "Eksportowanie PDF",
	t3119: "Eksport PDF nie powiódł się",
	t3120: "W przypadku dużych zbiorów danych interfejs może nie odpowiadać i jego ukończenie może zająć trochę czasu",
	t3121: "Proszę wybrać harmonogramy z listy",
	t3122: "Przejdź do konfiguracji, aby ustawić harmonogram",
	t3123: "Sukces",
	t3124: "Ostrzeżenie",
	t3125: "Informacje o stronie",
	t3126: "Proszę wybrać czas i punkty danych z listy",
	t3127: "Złączony",
	t3128: "Nie można nawiązać połączenia z tą witryną, skontaktuj się z pomocą techniczną",
	t3129: "W wybranym dniu nie były dostępne żadne dane HACCP. Upewnij się, że historia i czas są poprawnie skonfigurowane w menu konfiguracji",
	t3130: "Raport diagnostyczny",
	t3131: "Wybierz jednostki do eksportu raportu diagnostycznego",
	t3132: "Niedostępne na tym poziomie dostępu użytkownika",
	t3133: "Dziennik diagnostyczny",
	t3134: "nie udało się pobrać raportu",
	t3135: "Czcionka",
	t3136: "rozmiar czcionki",
	t3137: "Brak widoków do migracji",
	t3138: "Pobierz wersję komputerową",
	t3139: "Ze względu na ograniczenia bezpieczeństwa w nowoczesnych przeglądarkach (",
	t3140: "RFC1918",
	t3141: ") niestety nie jest możliwe łączenie się z urządzeniami w przestrzeni sieci prywatnej przy użyciu nieszyfrowanego protokołu HTTP. Skonfiguruj swojego menedżera systemu do korzystania z protokołu HTTPS lub użyj wersji na komputery",
	t3142: "Brak danych dla wybranego okresu",
	t3143: "Skonfiguruj swojego menedżera systemu do korzystania z protokołu HTTPS lub użyj wersji na komputer.",
	t3144: "Nie mogliśmy skomunikować się z Menedżerem Systemu. Oznacza to, że albo urządzenie nie może połączyć się z adresem IP lub nazwą hosta Menedżera systemu, albo że konieczne może być ręczne zaufanie certyfikatowi SSL używanemu na urządzeniu.",
	t3145: "Kliknij poniższy link, aby otworzyć nową kartę i zaufać certyfikatowi, a następnie wróć do tego ekranu.",
	t3146: "Podpis",
	t3147: "DI - wejścia cyfrowe",
	t3148: "AI - wejścia analogowe",
	t3149: "Przejdź do konfiguracji HACCP",
	t3150: "Brak danych",
	t3151: "Kopia zapasowa jest zapisywana w Menedżerze systemu dla funkcji wycofywania",
	t3152: "Wybierz, co ma być wyświetlane w widokach witryny",
	t3153: "Jednostka aktywów",
	t3154: "Pliki graficzne",
	t3155: "Niestety wystąpił błąd podczas pobierania plików edf i wersji z menedżera systemu...",
	t3156: "Aby wybrać pliki do zainstalowania, naciśnij przycisk „Wybierz pliki”",
	t3157: "Wybierz pliki",
	t3158: "Usuwanie kopii zapasowej",
	t3159: "Pliki pomostowe",
	t3160: "Kopia zapasowa jest zapisywana w Menedżerze systemu dla funkcji wycofywania",
	t3161: "Zainstalowany rozmiar",
	t3162: "Nowy rozmiar",
	t3163: "Data instalacji",
	t3164: "Nowa data",
	t3165: "Czujniki",
	t3166: "Przekaźniki",
	t3167: "Wejścia",
	t3168: "na zewnątrz",
	t3169: "Metry",
	t3170: "Rodzajowy",
	t3171: "Hasło wygasło, wprowadź nowe hasło",
	t3172: "Nowe hasło",
	t3173: "Potwierdź hasło",
	t3174: "Zmień hasło",
	t3175: "Hasło musi zawierać min 8 znaków 1 wielka litera, 1 mała litera, 1 cyfra, 1 znak specjalny)",
	t3176: "Hasła nie pasują do siebie",
	t3177: "Hasło nie może być takie samo jak stare hasło",
	t3178: "Zmiana hasła powiodła się",
	t3179: "Nie można zmienić hasła. Błąd urządzenia",
	t3180: "Resetowania hasła",
	t3181: "Wygenerowane",
	t3182: "Nazwa strony",
	t3183: "Identyfikator zdarzenia",
	t3184: "Czas",
	t3185: "Rola",
	t3186: "Informacje o wydarzeniu",
	t3187: "Potwierdź wybrane alarmy",
	t3188: "Potwierdź wszystkie wybrane alarmy",
	t3189: "Importuj bazę danych do",
	t3190: "Importowanie bazy danych",
	t3191: "Typ pliku powinien być .zip",
	t3192: "Wybierz plik",
	t3193: "Godziny otwarcia",
	t3194: "Wakacje",
	t3195: "Harmonogramy włączania/wyłączania",
	t3196: "Zaleta",
	t3197: "Masz niezapisane zmiany. Jesteś pewny, że chcesz wyjść?",
	t3198: "Niestety migracja nie powiodła się. System jest obecnie zajęty. Spróbuj ponownie później",
	t3199: "Prześlij obraz dopasowując się do aktualnych wymiarów",
	t3200: "Zamień obraz tła",
	t3201: "Zastąp obrazy tła skalowalną grafiką wektorową.",
	t3202: "Zastępowane są tylko pliki SVG o tej samej nazwie, co istniejące pliki.",
	t3203: "Wybierz plik(i) do zastąpienia",
	t3204: "Pliki obrazów",
	t3205: "nie mogą być przetwarzane. Plik ma nieprawidłowy format.",
	t3206: "{{amount}} plik(i) zastąpiony(e) pomyślnie",
	t3207: "{{amount}} plik(i) nie zostały zastąpione",
	t3208: "Widok nie ma tła",
	t3209: "Możesz przesłać nowy lub zastąpić istniejący obraz tła",
	t3210: "Jeśli zastępujesz obraz tła, pozycje parametrów mogą zostać zachowane, jeśli:",
	t3211: "Plik png lub jpg ma taką samą rozdzielczość jak bieżące tło",
	t3212: "Plik SVG ma ten sam współczynnik proporcji (ale niekoniecznie tę samą rozdzielczość)",
	t3213: "Funkcja jest wyłączona",
	t3214: "Obecnie wyświetlam ostatnią otrzymaną wartość, aktualizuję...",
	t3215: "Prześlij plik graficzny utworzony w",
	t3216: "(opcjonalny)",
	t3217: "Zdjęcie w tle",
	t3218: "Przeglądaj",
	t3219: "Więcej...",
	t3220: "Dodaj obraz tła (opcjonalnie)",
	t3221: "Plik graficzny",
	t3222: "Limit czasu w komunikacji",
	t3223: "Brak skonfigurowanych harmonogramów chłodzenia",
	t3224: "Indeks",
	t3225: "Rodzaj",
	t3226: "Wakacje",
	t3227: "Dni",
	t3228: "Na",
	t3229: "Wyłączony",
	t3230: "ID",
	t3231: "otwarty",
	t3232: "Początek",
	t3233: "Koniec",
	t3234: "Opis",
	t3235: "Dzień",
	t3236: "Blisko",
	t3237: "Plik(i) Edf został pomyślnie przesłany. Ponowne uruchamianie menedżera systemu",
	t3238: "Zakończono przesyłanie plików Edf, pomyślnie zresetowano menedżera systemu",
	t3239: "Błąd podczas pobierania plików edf z menedżera systemu",
	t3240: "Przesyłanie plików Edf nie powiodło się. Spróbuj za jakiś czas",
	t3241: "Brak skonfigurowanych harmonogramów chłodzenia",
	t3242: "Prześlij plik do menedżera systemu",
	t3243: "Określ typ urządzenia - wpływa na wymagane tagi parametrów do jego obsługi",
	t3244: "Określ sposób dostępu do wartości urządzenia i jaką funkcję pełnią",
	t3245: "Utwórz alarm na podstawie bitu parametru",
	t3246: "Etykiety",
	t3247: "Utwórz listę etykiet, które można przypisać do parametru",
	t3248: "Grupy",
	t3249: "Określ grupy parametrów",
	t3250: "Numer rejestracyjny",
	t3251: "Typ danych",
	t3252: "Lista etykiet",
	t3253: "Minimum",
	t3254: "Domyślna",
	t3255: "Maksymalny",
	t3256: "Typ rejestru",
	t3257: "Typ rejestru jest tłumaczony na odpowiednie kody funkcji do odczytu i zapisu",
	t3258: "Tryb zamiany",
	t3259: "W przypadku wartości z bajtami ABCD wysłanymi do urządzenia ta opcja zamienia bajty (BADC), słowa (CDAB) lub oba (DCBA).",
	t3260: "Tylko czytać",
	t3261: "Logowanie",
	t3262: "Włącza rejestrowanie historii dla tego parametru",
	t3263: "Przejmij tekst z parametru do alarmu",
	t3264: "Pozycja flagi alarmu licząc od nich najmniej znaczący bit parametru",
	t3265: "Uruchom alarm przy niskiej wartości",
	t3266: "Etykieta",
	t3267: "Ukrywać",
	t3268: "{{field}} jest polem wymaganym.",
	t3269: "Maksimum musi być większe lub równe minimum",
	t3270: "Wartość domyślna musi być w [minimum, maksimum]",
	t3271: "Typ danych musi wynosić {{reg}} dla wybranego typu rejestru {{mt}}",
	t3272: "Nazwa nie może zawierać znaków rozdzielających",
	t3273: "Nazwa nie może przekraczać 20 znaków",
	t3274: "Lista etykiet nie może być pusta",
	t3275: "Tag(y) {{taglist}} należy przypisać dokładnie raz.",
	t3276: "Grupa(y) {{grouplist}} zawiera więcej niż maksymalnie 50 parametrów.",
	t3277: "Formularz zawiera błędy, sprawdź błędy i spróbuj ponownie.",
	t3278: "Składać",
	t3279: "Aby aktywować załadowany EDF, musisz zresetować urządzenie. Czy chcesz teraz zresetować urządzenie?",
	t3280: "Zresetuj teraz",
	t3281: "Zresetuj później",
	t3282: "{{file}} już istnieje. Czy chcesz to nadpisać?",
	t3283: "Prześlij {{what}}",
	t3284: "Etykiety i wartości muszą być niepowtarzalne.",
	t3285: "Wielo",
	t3286: "Urządzenie obsługuje wiele przypadków lub paczek",
	t3287: "Tagi",
	t3288: "Internet Explorer jest wycofany z eksploatacji i nie jest obsługiwany przez StoreviewWeb",
	t3289: "Przełącz się na obsługiwaną przeglądarkę: Edge, Chrome lub Firefox",
	t3290: "Więcej informacji",
	t3291: "Blisko",
	t3292: "Błąd odczytu danych, spróbuj ponownie",
	t3293: "Trwa tworzenie kopii zapasowych EDF",
	t3294: "Brak widoków do migracji",
	t3295: "System jest skonfigurowany z publicznym adresem IP w polu prywatnego IP",
	t3296: "System jest skonfigurowany z prywatnym adresem IP w polu IP publicznego, system może nie być dostępny zdalnie lub niektóre funkcje mogą nie działać poprawnie",
	t3297: "Jesteś połączony przez adres IP lub nazwę hosta, która nie jest znana Menedżerowi systemu, prawdopodobnie przez usługę proxy, niektóre funkcje mogą nie działać poprawnie",
	t3298: "Urządzenie jest offline, pokazuje ostatnią znaną wartość",
	t3299: "Integracja urządzenia",
	t3300: "{{taglist}} są zduplikowane.",
	t3301: "Strona nie znaleziona",
	t3302: "Przepraszamy, żądana strona nie została znaleziona",
	t3303: "Kod błędu: 404",
	t3304: "Przejdź do panelu",
	t3305: "Tylko podgląd",
	t3306: "Wewnętrzny adres IP",
	t3307: "Zewnętrzny adres IP",
	t3308: "Nazwa domeny",
	t3309: "Brak ostrzeżeń",
	t3310: "Wiele ostrzeżeń",
	t3311: "Wygląda na to, że system jest skonfigurowany z wieloma jednostkami, ale nie byliśmy w stanie skontaktować się z jedną lub kilkoma jednostkami podrzędnymi, upewnij się, że ustawienia są prawidłowe. Należy pamiętać, że konfiguracja z wieloma jednostkami nie obsługuje wielu nazw domen i że każda jednostka musi być skonfigurowana z osobnym portem w przypadku korzystania z tej samej domeny",
	t3312: "Tylko podgląd",
	t3313: "Ten element nie jest powiązany z żadnym parametrem i można go bezpiecznie usunąć",
	t3314: "Usuń parametr",
	t3315: "Harmonogram",
	t3316: "Zostaniesz wylogowany i będziesz musiał zalogować się przy użyciu nowego hasła",
	t759: "Baza danych pobrana, resetowanie i aktualizacja jednostki. Może to potrwać kilka minut.",
	t816: "Typ alarmu:",
	t818: "Liczyć :",
	t848: "Plik DB nie jest tworzony. Masz ograniczony dostęp do zainstalowanego katalogu. Skontaktuj się z administratorem systemu.",
	t3317: "Brak harmonogramów do wyeksportowania",
	t3318: "Oczekiwanie na odpowiedź z systemu",
	t3319: "Brak aktywności w sieci",
	t3320: "Nazwa sklepu",
	t3321: "Nowa wersja {{latestVersion}} dostępna online",
	t3322: "Port zewnętrzny",
	t3323: "Port wewnętrzny",
	t3324: "Nie możemy komunikować się z menedżerem systemu",
	t3325: "może certyfikatowi trzeba zaufać?",
	t3326: "Nie można usunąć pliku w tym formacie",
	t3327: "Stosowanie",
	t3328: "Na czas",
	t3329: "Po czasie",
	t3330: "Dni powszednie",
	t3331: "Etap chłodzenia",
	t3332: "Odzysk ciepła",
	t3333: "Chłodzenie",
	t3334: "Ogrzewanie",
	t3335: "Ogrzewanie pomocnicze",
	t3336: "Osuszanie",
	t3337: "Internet",
	t3338: "Wiadomości",
	t3339: "Łączność",
	t3340: "Kom",
	t3341: "Nie znaleziono elementów",
	t3342: "Wpisz aby wyszukać",
	t3408: "Włącz wszystko",
	t3409: "Wyłącz wszystko",
	t3410: "Normalna",
	t3411: "Ciężki : silny",
	t3412: "Krytyczny",
	t3413: "Włącz wszystkie alarmy",
	t3414: "Wyszukaj alarmy",
	t3415: "Włączony",
	t3417: "Błąd podczas pobierania alarmów",
	t3418: "Wystąpił błąd podczas pobierania urządzeń do skopiowania",
	t3419: "Wystąpił błąd podczas sprawdzania statusu kopiowania",
	t3420: "Automatyczne sondowanie zostanie wznowione po upływie maksymalnie 2 godzin lub po anulowaniu lub zakończeniu działania kreatora",
	t3421: "Kopiuj do kontrolera(ów)",
	t3422: "Kopiowanie do wybranego urządzenia(ów)",
	t3423: "Funkcja kopiowania najpierw skopiuje ustawienia do bazy danych System Manager, a następnie pobierze ustawienia do urządzeń kontrolera. Wykonanie tej czynności może zająć trochę czasu.",
	t3424: "Nie wybrano urządzeń do skopiowania",
	t3425: "Kliknij dwukrotnie, aby skopiować",
	t3426: "Błąd podczas pobierania urządzeń",
	t3427: "Urządzenia do kopiowania",
	t3428: "Wybierz urządzenie(a) do skopiowania",
	t3429: "Tutaj będą wyświetlane tylko kontrolery tego samego typu",
	t3430: "Proces kopiowania zakończony powodzeniem",
	t3431: "Nieprawidłowy plik. Oczekiwany model pliku:",
	t3432: "Wystąpił błąd podczas ładowania pliku ustawień",
	t3435: "Czy na pewno chcesz przenieść wybrane urządzenie do tej grupy {{toPackGroup}} ?",
	t3343: "Odkrycie",
	t3344: "Odśwież tabelę",
	t3345: "Skanowanie sieci, proszę czekać...",
	t3346: "Tabela sieciowa jest odświeżana",
	t3347: "Błąd podczas odświeżania sieci",
	t3348: "Błąd podczas ponownego skanowania sieci",
	t3349: "Błąd podczas pobierania sieci",
	t3350: "Zakończono skanowanie sieci",
	t3351: "Brak dostępnych urządzeń",
	t3352: "Przeskanuj sieć",
	t3353: "Nieutworzone",
	t3354: "Wszelkie zmiany w układzie urządzeń w kreatorze mogą mieć wpływ na usługi monitorujące Alsense.",
	t3355: "Sieć",
	t3356: "Wybierz typ magistrali polowej",
	t3357: "Jeśli włączony jest Modbus/RS485, możesz wybrać SLV dla sprężarki Danfoss o zmiennej prędkości. Wybierając SNMP jako magistralę lokalną, będziesz musiał wprowadzić prawidłowy zakres adresów podsieci przez sterowanie SNMP Danfoss",
	t3358: "Kompresory/Zestawy",
	t3359: "Zarządzanie kontrolerami sprężarek/pakietów",
	t3360: "Tylko urządzenia Pack mogą być zarządzane tutaj - nie system Rack I/O. Kompatybilne kontrolery Multi-Suction Pack, które zostały zeskanowane, początkowo pojawią się jako 0 grup ssących. Do skonfigurowania kontrolera Pack wymagany jest AK-ST 500 (Service Tool).",
	t3361: "Kwota do skopiowania",
	t3362: "Zmniejszenie poprzednio skonfigurowanej grupy ssącej spowoduje również usunięcie wszystkich powiązanych elementów sterujących parowaniem dla tej grupy ssącej.",
	t3363: "Liczba grup ssących nie może być równa zero, minimalna liczba grup ssących powinna wynosić co najmniej jeden.",
	t3364: "Błąd podczas pobierania kompresorów/pakietów",
	t3365: "Brak dostępnych kompresorów/zestawów",
	t3366: "Urządzenia online",
	t3367: "Urządzenia offline",
	t3369: "Dodaj pakiet",
	t3370: "W przypadku wybrania opcji Modbus/RS485 wyświetlone zostaną dodatkowe opcje dla SLV/ECFan. Są one wykorzystywane w chłodnictwie i można im nadać wartość Nie.",
	t3371: "Dodaj obwód",
	t3372: "Dodaj, skopiuj lub usuń urządzenia. Należy pamiętać, że nie wszystkie typy urządzeń pozwalają na edycję wszystkich pól, a adresy punktów I/O są ustawiane w konfiguracji.",
	t3373: "Zarządzaj kontrolerami spraw",
	t3374: "Dodaj urządzenie",
	t3375: "Zarządzanie kontrolerami sprężarek/zestawów",
	t3376: "Błąd w dostępie do danych",
	t3377: "Układ HVAC został pomyślnie zastosowany",
	t3378: "Układ HVAC",
	t3379: "Czy na pewno chcesz opuścić tę stronę?",
	t3380: "Twoje ustawienia mogą zostać utracone",
	t3381: "Proszę skonfigurować urządzenie",
	t3382: "Wyjdź z Szybkiej konfiguracji",
	t3383: "Czy na pewno chcesz opuścić Szybką konfigurację?",
	t3384: "Bramy (AK-PI200)",
	t3385: "Wybierz znalezione bramki",
	t3386: "IP Address",
	t3387: "Kontroler źródła",
	t3388: "Ustawienia źródła",
	t3389: "Alarmy źródłowe",
	t3390: "Wykres źródłowy",
	t3392: "Wykres",
	t3393: "Urządzenia",
	t3394: "Automatyczne sondowanie jest zawieszone podczas fazy kopiowania. Główny przełącznik urządzenia źródłowego i docelowego zostanie wyłączony, a następnie przywrócony do stanu pierwotnego po zakończeniu kopiowania. Upewnij się, że sprawdziłeś główny przełącznik po zakończeniu działania kreatora kopiowania. Jeśli którekolwiek z wybranych urządzeń jest w aktywnym alarmie, żadna modyfikacja działania alarmowego dla tego konkretnego punktu nie zostanie zmodyfikowana. Oryginalne ustawienie pozostanie.",
	t3395: "Wybierz urządzenie źródłowe, z którego chcesz skopiować ustawienia",
	t3396: "O czasie",
	t3397: "Funkcja kopiowania skopiuje dostępne punkty wykresu, aż do osiągnięcia pojemności jednostki. Przejdź do Konfiguracji, aby zobaczyć liczbę zdefiniowanych punktów",
	t3398: "Wykresowanie urządzenia źródłowego",
	t3399: "Skonfiguruj punkty wykresu dla kontrolera źródłowego",
	t3400: "Włącz HACCP, aby rejestrować punkty w raporcie temperatury HACCP",
	t3401: "Wyszukaj punkty",
	t3402: "Brak dostępnych danych",
	t3403: "Błąd podczas pobierania punktów wykresu",
	t3404: "Wyłączone",
	t3405: "Alarmy urządzeń źródłowych",
	t3406: "Skonfiguruj szczegóły alarmu w kontrolerze źródłowym",
	t3407: "Nazwa alarmu",
	t3436: "Urządzenie zostanie usunięte z przypisanej grupy [ {{fromPackGroup}} ]",
	t3437: "Układ Chłodnictwa",
	t3438: "Układ chłodniczy został pomyślnie zastosowany",
	t3439: "Przypisz ponownie",
	t3440: "Przypisz kontrole przypadków do kontroli pakietów",
	t3441: "Przypisz elementy sterujące obudowy (z dostępnych przypadków) do elementów sterujących sprężarki/zestawu, tworząc grupy ssące",
	t3442: "Parametry urządzenia",
	t3443: "Wybierz parametry do kopiowania",
	t3444: "Podczas pracy na urządzeniu podłączonym do sieci (online) może wystąpić opóźnienie w ustawieniach ze względu na komunikację z urządzeniem.",
	t3445: "Wybierz alarmy do skopiowania",
	t3446: "Wykres urządzeń",
	t3447: "Wybierz punkty wykresu do skopiowania",
	t3448: "Zapisz bieżące ustawienia urządzenia do pliku",
	t3449: "Możesz zapisać bieżące ustawienia urządzenia do pliku, który może być użyty do skonfigurowania urządzeń w późniejszym terminie. Jeśli chcesz wyjść z kreatora po zapisaniu pliku, użyj ikony wyjścia. Jeśli pracujesz z podłączonymi urządzeniami, wyjście zapewni, że sondowanie sieciowe i główny przełącznik urządzenia powrócą do prawidłowego stanu.",
	t3450: "Ustawienia pliku(ów) zostały pomyślnie zapisane",
	t3451: "Czy chcesz kontynuować czy opuścić Szybką konfigurację?",
	t3452: "Zapisywanie pliku(ów)",
	t3453: "Wystąpił błąd podczas pobierania ustawień",
	t3454: "Ustawienia urządzenia źródłowego",
	t3455: "Skonfiguruj parametry urządzenia w kontrolerze źródłowym",
	t3456: "Żadne z pól hasła nie powinno być puste",
	t3457: "Ostatnie",
	t3458: "Książka Adresowa",
	t3459: "Ostatni dostęp",
	t3460: "Wykonuj regularnie kopię zapasową Książki adresowej.",
	t3461: "Książka adresowa została pomyślnie zaimportowana",
	t3462: "Import Address Book",
	t3463: "Export Address Book",
	t3464: "Dodaj witrynę",
	t3465: "Edytuj witrynę",
	t3466: "Usuń lokalizację",
	t3467: "Przed rozpoczęciem korzystania z Książki adresowej należy utworzyć hasło do Książki adresowej",
	t3468: "Strona została pomyślnie dodana do Twojej Książki adresowej",
	t3469: "Już w Książce adresowej",
	t3470: "Dodaj do książki adresowej",
	t3471: "Zaloguj się do serwisu",
	t3472: "Informacje o witrynie zostały pomyślnie zaktualizowane",
	t3474: "Powiadomienie",
	t3475: "Witryna została pomyślnie usunięta z Twojej Książki adresowej",
	t3476: "Strona została pomyślnie zaktualizowana",
	t3477: "Nieprawidłowy format książki adresowej",
	t3478: "Działania",
	t3479: "Niedostępne w trybie offline",
	t3480: "Nie udało się załadować bazy danych S55",
	t3481: "Ładowanie bazy danych S55",
	t3482: "Protokół",
	t3483: "Użytkownik nie ma uprawnień do przeglądania informacji o oprogramowaniu",
	t3484: "Hasło musi być wyłącznie alfanumeryczne lub numeryczne",
	t3485: "Aby uzyskać dostęp do Książki adresowej, należy utworzyć hasło do Książki adresowej",
	t3486: "Hasło do Książki adresowej jest nieprawidłowe",
	t3487: "Witryna jest już obecna w Książce adresowej",
	t3488: "Utwórz hasło do Książki adresowej, aby korzystać z Książki adresowej. Hasło musi być wyłącznie alfanumeryczne lub numeryczne",
	t3489: "Wprowadź hasło do Książki adresowej",
	t3490: "Brak dostępnych urządzeń online do kopiowania",
	t3491: "Utwórz hasło do książki adresowej",
	t3492: "Odśwież hasło do książki adresowej",
	t3493: "Hasło witryny nie może być puste",
	t3494: "Wpisz hasło do książki adresowej, którą chcesz zaimportować",
	t3495: "Hasło do Książki adresowej zostało pomyślnie utworzone",
	t3496: "Hasło do Książki adresowej zostało pomyślnie zaktualizowane",
	t3497: "Hasło importowanej Książki adresowej jest nieprawidłowe",
	t3498: "Proszę wpisać hasło do witryny",
	t3499: "Wyczyść książkę adresową",
	t3500: "Przeszukaj witryny",
	t3501: "Wybierz plik książki adresowej do zaimportowania",
	t3502: "Wybierz plik klucza, aby odszyfrować książkę adresową",
	t3503: "Naciśnij przycisk eksportuj, aby pobrać zaszyfrowaną książkę adresową",
	t3505: "Z szyfrowaniem",
	t3506: "niezaszyfrowane hasła",
	t3507: "Nieprawidłowy klucz tajny",
	t3508: "Błąd importu. Upewnij się, że zaimportowałeś właściwy klucz",
	t3509: "Brak dostępnych wersji",
	t3510: "Otwórz usługi Alsense (wymaga subskrypcji)",
	t3511: "Proszę dokładnie zapoznać się z warunkami EULA. Jeśli nie możesz zaakceptować warunków, nie masz prawa korzystać z aplikacji.",
	t3512: "Książka adresowa i hasło do niej zostały pomyślnie wyczyszczone",
	t3513: "(wymagany)",
	t3515: "Książka adresowa jest pusta",
	t3516: "Hasło do Książki adresowej nie może być puste",
	t3519: "Wyczyszczenie Książki adresowej spowoduje również usunięcie hasła do Książki adresowej.",
	t3520: "Poza zakresem. Powinno być pomiędzy 0 a {{maxAddr}}",
	t3521: "Naciśnij „Eksportuj”, aby pobrać wszystkie widoki graficzne zapisane w Menedżerze systemu",
	t3522: "Naciśnij „Pobierz”, aby pobrać raport systemowy",
	t3523: "Tylko jednostka główna jest dostępna w bezpośrednim połączeniu WiFi",
	t3524: "Brak lokalnych widoków graficznych. Dodaj nowe lub zaimportuj istniejące widoki",
	t3525: "Brak zdalnych widoków graficznych. Spróbuj wyszukać z inną nazwą",
	t3526: "Brak zdalnych widoków graficznych. Dodaj nowe, importuj lub migruj istniejące widoki",
	t3527: "Widoki lokalne ( {{totalFiles}} z 5)",
	t3528: "{{totalFiles}} grafiki lokalnej z maksymalnie 5",
	t3529: "Widoki zdalne",
	t3530: "Plik nie jest mapą bitową.",
	t3531: "Nieprawidłowa długość mapy bitowej.",
	t3532: "Nieprawidłowy rozmiar nagłówka mapy bitowej.",
	t3533: "Nieprawidłowy rozmiar mapy bitowej.",
	t3534: "Błąd w rozmiarze palety.",
	t3535: "Niezgodny plik .bmp",
	t3536: "Błąd podczas usuwania lokalnego pliku graficznego",
	t3537: "Błąd podczas duplikowania lokalnego pliku graficznego",
	t3538: "Błąd podczas eksportowania lokalnego pliku graficznego",
	t3539: "Usuń grafikę",
	t3540: "Usuń lokalny plik graficzny",
	t3541: "Trwa usuwanie lokalnego pliku graficznego",
	t3542: "Lokalny plik graficzny został pomyślnie usunięty. Ponowne uruchomienie Menedżera systemu",
	t3543: "Zakończono usuwanie lokalnego pliku graficznego. System Manager został pomyślnie ponownie uruchomiony",
	t3544: "Duplikat grafiki",
	t3545: "Duplikuj lokalny plik graficzny",
	t3546: "Trwa duplikowanie lokalnego pliku graficznego",
	t3547: "Lokalny plik graficzny został pomyślnie zduplikowany. Ponowne uruchomienie Menedżera systemu",
	t3548: "Zakończono duplikowanie lokalnego pliku graficznego. System Manager został pomyślnie ponownie uruchomiony",
	t3549: "Eksportuj grafikę",
	t3550: "Eksportuj lokalny plik graficzny",
	t3551: "Trwa eksportowanie lokalnego pliku graficznego",
	t3552: "Lokalny plik graficzny został pomyślnie wyeksportowany",
	t3553: "Po wykonaniu tej czynności nastąpi ponowne uruchomienie Menedżera systemu",
	t3554: "Czy na pewno chcesz usunąć?",
	t3555: "Czy na pewno chcesz zduplikować?",
	t3556: "Naciśnij „Eksportuj”, aby pobrać wszystkie lokalne widoki graficzne zapisane w Menedżerze systemu",
	t3557: "Trwa eksportowanie lokalnych plików graficznych",
	t3558: "Lokalne pliki graficzne zostały pomyślnie wyeksportowane",
	t3559: "Kod produktu",
	t3560: "Dowiedz się więcej o tym produkcie",
	t3561: "Wentylator eko",
	t3562: "Otwarcie zaworu",
	t3563: "Przegrzać",
	t3564: "Ciśnienie parownika",
	t3565: "Temperatura parownika",
	t3566: "Wyłącznik",
	t3567: "Kutyna",
	t3568: "Drzwi obudowy",
	t3569: "Temperatura ssania",
	t3570: "Ciepło kolejowe",
	t3571: "Osiągnięto maksymalny dozwolony limit plików. Odznacz plik(i) do zaimportowania",
	t3572: "Aby zaimportować pliki, usuń istniejące pliki w menedżerze systemu",
	t3573: "Lokalny plik graficzny został pomyślnie zaimportowany. Ponowne uruchomienie Menedżera systemu",
	t3574: "Zakończono importowanie lokalnych plików graficznych. System Manager został pomyślnie ponownie uruchomiony",
	t3575: "Osiągnięto maksymalny dozwolony limit plików. Usuń plik(i) do zaimportowania",
	t3576: "Maksymalna liczba dozwolonych plików graficznych to 5",
	t3577: "Lokalny plik graficzny przesłany pomyślnie. Ponowne uruchomienie Menedżera systemu",
	t3578: "Zakończono przesyłanie lokalnego pliku graficznego. System Manager został pomyślnie ponownie uruchomiony",
	t3579: "Nieprawidłowy format pliku. Prześlij plik {{supportedFileFormats}}",
	t3580: "Rozmiar pliku musi wynosić {{maxWidthAllowed}} x {{maxHeightAllowed}} pikseli dla ekranów lokalnych",
	t3581: "Zdalny widok",
	t3582: "Widok lokalny",
	t3583: "Strona główna witryny Storeview",
	t3584: "Warunki umowy EULA",
	t3585: "Bezpieczeństwo",
	t3586: "Poziomy egzekwowania bezpieczeństwa",
	t3587: "Wsteczna zgodność (najniższy poziom bezpieczeństwa – przy założeniu, że uwierzytelnianie za pomocą nagłówka jest wyłączone)",
	t3588: "Żądania SvW/XML zawierają nazwę użytkownika i hasło.",
	t3589: "Brak dezynfekcji żądań, takie samo bezpieczeństwo jak w przypadku poprzednich wydań",
	t3590: "Nie są wymagane żadne zmiany w połączeniach SvW ani istniejących połączeniach XML",
	t3591: "Tolerancyjny (może być używany jako przejście do poziomu ścisłego)",
	t3592: "Żądania XML mogą zawierać nazwę użytkownika i hasło lub token sesji. Token sesji jest otrzymywany w odpowiedzi na żądanie logowania.",
	t3593: "Jeśli token sesji jest podany w nagłówku AKSM-auth, nazwy użytkowników i hasła są ignorowane",
	t3594: "Żądanie XML zostaje oczyszczone, a w odpowiedzi na żądanie zwracane jest ostrzeżenie, jeśli zostaną wykryte jakiekolwiek problemy",
	t3595: "Należy zwrócić uwagę na te ostrzeżenia, przygotowując się do przejścia w tryb ścisły",
	t3596: "Jeżeli token sesji nie jest używany, nie można zastosować wygaśnięcia sesji",
	t3597: "Już obsługiwane w zdalnym interfejsie użytkownika (SvW)",
	t3598: "Możliwe/wymagane zmiany z perspektywy XML strony trzeciej",
	t3599: "Brak użycia tokena sesji — nie są wymagane żadne zmiany",
	t3600: "Użycie tokena sesji — usuń nazwę użytkownika i hasło z treści i obserwuj ostrzeżenia w przygotowaniu do trybu ścisłego",
	t3601: "Ścisły (najwyższy poziom bezpieczeństwa)",
	t3602: "Żądania XML nie mogą zawierać nazw użytkowników i haseł, ale muszą zawierać token sesji w nagłówku AKSM-auth. Jeśli w żądaniu zostanie wykryty problem, zostanie ono odrzucone",
	t3603: "Już obsługiwane w (SvW)",
	t3604: "Wymagane zmiany z perspektywy strony trzeciej",
	t3605: "nazwa użytkownika i hasło muszą zostać usunięte z ładunku żądania XML, a token sesji musi zostać umieszczony w nagłówku CORS o nazwie AKSM-auth",
	t3606: "Nieudane logowanie",
	t3607: "Jak długo (w sekundach) użytkownik zdalny czeka, aby móc spróbować ponownie zalogować się po nieudanej próbie. Czas oczekiwania zwiększa się kumulatywnie z każdą nieudaną próbą. Po 10 próbach adres IP, z którego użytkownik próbował się zalogować, jest blokowany na 24 godziny. Jeśli ustawiono na 0, wycofywanie nieudanego logowania jest wyłączone",
	t3608: "Domyślnie 30 s (maks. 600 s / 0 = wyłączone)",
	t3609: "Bezpłatne próby",
	t3610: "Ile prób logowania jest dozwolonych przed zastosowaniem wycofywania",
	t3611: "Domyślnie 2 (maks. 5)",
	t3612: "Blok",
	t3613: "Jeśli włączone, użytkownik + adres IP są blokowane podczas wycofywania, w przeciwnym razie użytkownik jest blokowany",
	t3614: "Domyślny „Użytkownik” (Użytkownik + IP / Użytkownik)",
	t3615: "Sesje na użytkownika",
	t3616: "Sesja to moment, w którym użytkownik pomyślnie zalogował się do jednostki i znajduje się w interfejsie zdalnym (SvW).",
	t3617: "Liczba aktywnych sesji, które może mieć użytkownik. Jeśli ustawiono na 0, użytkownik może mieć maksymalną liczbę sesji (30)",
	t3618: "Domyślnie 0",
	t3619: "Wygaśnięcie sesji",
	t3620: "Ile sekund po wygaśnięciu bezczynnej sesji. Jeśli sesja wygaśnie, wymagane jest nowe logowanie",
	t3621: "Domyślnie 900s (maks. 3600)",
	t3622: "Uwierzytelnianie nagłówka",
	t3623: "W przypadku włączenia protokołu HTTPS i uwierzytelniania nagłówka wszystkie żądania XML stron trzecich muszą przekazywać dane uwierzytelniające w postaci nazwy użytkownika i hasła w nagłówku (podstawowy schemat uwierzytelniania)",
	t3624: "Włączenie opcji „Użyj uwierzytelniania nagłówka” wymaga ponownej inicjalizacji systemu. Użyj przycisku „Naciśnij, aby zainicjować”, aby wykonać tę czynność",
	t3625: "Mały",
	t3626: "Duży",
	t3627: "Zapisz grafikę",
	t3628: "Zapisz lokalny plik graficzny",
	t3629: "Lokalny plik graficzny został pomyślnie usunięty. Trwa ładowanie lokalnego pliku graficznego",
	t3630: "Lokalny plik graficzny został pomyślnie załadowany. Ponowne uruchomienie Menedżera systemu",
	t3631: "Zakończono zapisywanie lokalnego pliku graficznego. System Manager został pomyślnie ponownie uruchomiony",
	t3632: "Czy na pewno chcesz zapisać?",
	t3633: "Analiza nie powiodła się",
	t3634: "Nie można przeanalizować pliku wizualizacyjnego",
	t3635: "Nie można przeanalizować pliku vz2",
	t3636: "Otwarcie pliku Viz nie powiodło się:",
	t3637: "Nie można znaleźć plików migracji (vz2, dpj lub json)",
	t3638: "Nie można znaleźć plików migracji (viz)",
	t3639: "System Manager zostanie uruchomiony ponownie po tej akcji. System Manager jest w trybie permissive lub strict, więc token sesji wygaśnie po ponownym uruchomieniu i zostaniesz wylogowany z systemu",
	t3643: "Ciśnienie wylotowe",
	t3644: "Całkowita moc kW",
	t3645: "Całkowity KVAR",
	t3646: "Łącznie Kvarh+",
	t3647: "Popyt",
	t3648: "Współczynnik mocy",
	t3649: "Kolor czcionki",
	t3650: "Kolor tła",
	t3651: "Książka adresowa została pomyślnie wyeksportowana",
	t3652: "Tryb sterowania",
	t3653: "Sc3 Temperatura zewnętrzna",
	t3654: "Temperatura rozładowania",
	t3655: "Wydajność robocza skraplacza",
	t3656: "Brak danych do eksportu",
	t3657: "Hasło użytkownika w {{unit}} wygasło",
	t3658: "Nie można zalogować się do {{unit}} przy użyciu podanych danych logowania.",
	t3659: "Zaktualizuj wersję oprogramowania sprzętowego lub zmień poziom wymuszania zabezpieczeń dla {{unit}} aby uzyskać obsługę tokena sesji.",
	t3661: "V3GC",
	t3662: "Sekcja B",
	t3663: "Sekcja C",
	t3664: "Adaptacyjna kontrola przegrzania",
	t3665: "Powód statusu kontroli",
	t3666: "Użytkownik nie może się zalogować. Adres jednostki nie jest ustawiony w konfiguracji serwera proxy",
	t3667: "Protokół komunikacyjny nie jest określony w konfiguracji proxy. Może to prowadzić do błędów",
	t3668: "Informacje o bezpieczeństwie IT",
	t3669: "Zgodnie ze standardami bezpieczeństwa System Manager domyślnie przełącza się na ścisłą kontrolę sesji, co oznacza, że żadne połączenie zdalne / usługa zdalna oparta na XML nie będzie już działać, dopóki nie zostaną zastosowane tokeny sesji.",
	t3670: "Firma Danfoss zaleca kontynuowanie pracy w trybie ścisłym, jednak tryb zgodności wstecznej jest nadal dostępny.",
	t3671: "Należy pamiętać, że tę opcję można zmienić w menu Konfiguracja zabezpieczeń. Nie ma to wpływu na lokalne sterowanie Menedżerem systemu ani na działanie witryny.",
	t3672: "Zobacz więcej informacji na temat kontroli sesji",
	t3673: "Tryb wsteczny",
	t3674: "Tryb ścisły",
	t3675: "Po kliknięciu trybu wstecznej zgodności wszystkie stosowne menedżery systemów z zaktualizowanym oprogramowaniem sprzętowym zostaną ustawione w tryb wstecznej zgodności, ponownie uruchomione, a aplikacja zostanie wylogowana.",
	t3676: "Maksymalna liczba grup ssących wynosi 12",
	t3677: "Po pomyślnej aktualizacji wybranych jednostek z wersji 3.3.0 do wersji 4.X lub nowszej użytkownik zostanie automatycznie wylogowany po kliknięciu przycisku Zakończ w ostatnim kroku.",
	t3678: "Nie są obsługiwane obudowy wielokrotne dla kontrolerów AK2 (Pack/Case)",
	t3679: "Zgodnie ze standardami bezpieczeństwa wprowadzonymi w wersji 4.0, System Manager domyślnie będzie korzystał ze ścisłej kontroli sesji, co oznacza, że żadne zdalne połączenie / zdalna usługa oparta na XML nie będzie już działać, dopóki nie zostaną zastosowane tokeny sesji. Poza tym komunikacja zostanie automatycznie dostosowana do HTTPS poprzez uaktualnienie do rel. 4.0. Danfoss zaleca kontynuowanie w trybie ścisłym, ale dostępny jest tryb wstecznej kompatybilności, który przywróci system do ustawień stosowanych do tej pory. Należy pamiętać, że jeśli dokonujesz tej aktualizacji zdalnie na jednostce HTTP, upewnij się, że Twoja konfiguracja IT jest przygotowana na HTTPS (port 443) lub masz personel dostępny na miejscu.",
	t3680: "Więcej informacji na temat nowego wdrożenia zabezpieczeń znajdziesz tutaj",
	t3681: "Dostęp do wersji http tej witryny może być zablokowany z powodu ograniczeń przeglądarki. Odwiedź naszą stronę FAQ, aby uzyskać instrukcje dotyczące rozwiązania tego problemu lub pobierz wersję StoreView Web na komputer.",
	t3682: "Często zadawane pytania dotyczące menedżera systemu",
	t3683: "u17 powietrze",
	t3684: "u91 Temperatura wycięcia.",
	t3685: "u00 Ctrl. stan",
	t3686: "u12 S3 temperatura powietrza.",
	t3687: "u16 S4 temperatura powietrza.",
	t3688: "u23 EEV OD %",
	t3689: "u21 Przegrzanie",
	t3690: "u22 PrzegrzanieRef",
	t3691: "u26 Temperatura parowania Te",
	t3692: "S2 Temperatura wylotu gazu.",
	t3693: "Stw8",
	t3694: "Sd-MT",
	t3695: "Status tw",
	t3696: "Włącz dwa",
	t3697: "Dwie pompy pracują",
	t3698: "Stw3",
	t3699: "Stw4",
	t3700: "Stw2",
	t3701: "Status kontroli Rcv",
	t3702: "Przesunięcie ssania",
	t3703: "Tryb sterowania Vrec",
	t3704: "Status Vrec",
	t3705: "Powód statusu Ctrl",
	t3706: "Status sterowania HP",
	t3707: "Maksymalne stężenie PGC.",
	t3708: "Pgc maks. limit P-ban",
	t3709: "Pgc min.",
	t3710: "Pojemność wyrzutnika pary",
	t3711: "Pojemność wyrzutnika cieczy",
	t3712: "Temperatura powietrza odniesienia.",
	t3713: "Temperatura powietrza nawiewanego.",
	t3714: "Powietrze powrotne",
	t3715: "Strefowy punkt rosy",
	t3716: "Wilgotność strefy",
	t3717: "Temperatura na zewnątrz.",
	t3718: "Poziom CO2 w strefie",
	t3719: "Temp. strefy.",
	t3720: "Stan odniesienia",
	t3721: "Stan kontroli stanu",
	t3722: "Stan kontroli stanu",
	t3723: "Sterowanie stanem.",
	t3724: "War. żądana nasadka.",
	t3725: "Stan V3GC",
	t3726: "V3gc OD",
	t3727: "Temperatura otoczenia",
	t3728: "Status HR",
	t3729: "Włącz HR",
	t3730: "Żądanie klienta HR",
	t3731: "Sz3",
	t3732: "Szr4",
	t3733: "Shr2",
	t3734: "Sz8",
	t3735: "Odniesienie ssania To-MT",
	t3736: "Status kontroli MT",
	t3737: "Rzeczywista strefa MT",
	t3738: "Aby wybrać opcję offsetu MT",
	t3739: "Pojemność robocza MT",
	t3740: "Żądany limit MT",
	t3741: "Czas na następny krok MT",
	t3742: "Po-MT",
	t3744: "Temperatura ssania To-MT",
	t3745: "Całkowita energia czynna",
	t3746: "Szczytowe zapotrzebowanie",
	t3747: "Całkowity współczynnik mocy",
	t3748: "Napięcie fazy A",
	t3749: "Napięcie fazy B",
	t3750: "Napięcie fazy C",
	t3751: "Wzmacniacze fazy A",
	t3752: "Wzmacniacze fazy B",
	t3753: "Wzmacniacze fazy C",
	t3754: "Całkowita moc czynna",
	t3755: "Temperatura ssania To-LT",
	t3756: "Temperatura ssania To-IT",
	t3757: "Odniesienie ssania LT",
	t3758: "Status kontroli LT",
	t3759: "Rzeczywista strefa LT",
	t3760: "Aby wybrać opcję offsetu LT",
	t3761: "Pojemność robocza LT",
	t3762: "Żądany limit LT",
	t3763: "Czas na następny krok LT",
	t3764: "Po-LT",
	t3765: "Sd-LT",
	t3766: "Odniesienie ssania IT",
	t3767: "Status kontroli IT",
	t3768: "Aktualna strefa IT",
	t3769: "Pojemność robocza IT",
	t3770: "Żądana pojemność IT",
	t3771: "Czas na następny krok IT",
	t3772: "Tn Po-IT",
	t2773: "SD-IT",
	t2774: "IT Comp. start",
	t3774: "Poza zakresem. Powinno być pomiędzy {{minAddr}} a {{maxAddr}}",
	t3775: "Operacja wyłączona dla tego urządzenia",
	t3776: "Po wykonaniu tej czynności aplikacja zostanie wylogowana, co może potrwać chwilę.",
	t3777: "Nie można znaleźć plików migracji (flp lub json)",
	t3778: "Ankieta",
	t3779: "Głosowanie jest obecnie zawieszone.",
	t3780: "Czy chcesz wznowić głosowanie?",
	t3781: "Licznik energii",
	t3782: "Wybrano wiele węzłów",
	t3783: "Powiększ węzeł",
	t3784: "Jednostki miary",
	t3785: "Pliki zostały pomyślnie wyeksportowane",
	t3786: "Adres nie może być ujemny",
	t3787: "Zresetuj powiększenie",
	t3788: "Wystąpił błąd podczas pobierania kroków wzorca przygotowawczego",
	t3789: "Wystąpił błąd podczas zapisywania kroków wzorca przygotowania",
	t3790: "Kroki wzorca przygotowania zostały pomyślnie zapisane",
	t3791: "Wzór inscenizacji",
	t3792: "Naciśnij ikony rozładowania, aby włączyć/wyłączyć rozładowni ki powiązane ze sprężarkami",
	t3793: "Brak danych",
	t3794: "Następujące parametry nie zwróciły żadnych punktów danych",
	t3795: "Nawiązanie zaufania HTTPS/SSL powiodło się.",
	t3796: "Możesz teraz zalogować się do przeglądarki internetowej StoreView.",
	t3797: "Przekierowanie do przeglądarki internetowej StoreView za {{timeLeft}} sekund...",
	t3798: "Głosowanie jest zawieszone!",
	t3799: "Wyczyszczone alarmy (pełne logi systemowe)",
	t3800: "Maksymalna liczba urządzeń HVAC wynosi 45.",
	t3801: "Nie udało się pobrać dziennika zmian",
	t3802: "Łączenie z pamięcią masową...",
	t3803: "Przekroczono limit czasu połączenia z magazynem SCADA",
	t3804: "Nie udało się połączyć z magazynem SCADA",
	t3805: "Nieprawidłowa data",
	t3806: "Wybierz jednostkę(y) do pobrania raportu"
};
var pl = {
	lang: lang$6
};

var lang$7 = {
	t0: "Alarmes ativos",
	t1: "Alarmes apagados",
	t2: "Hospedeiro",
	t3: "EU IRIA",
	t4: "Nome do dispositivo",
	t5: "Editar notas",
	t6: "Não autorizado a reconhecer o alarme",
	t7: "Tipo de alarme",
	t8: "Ocorreu",
	t9: "Reconhecer",
	t10: "Alarme(s) Ativo(s)",
	t11: "Limpo",
	t12: "Cálculos",
	t13: "Alarme(s) Apagado(s)",
	t14: "Silenciar/Reconhecer",
	t15: "Reconhecer todos os alarmes não reconhecidos",
	t16: "Falha na autorização!",
	t17: "Erro",
	t18: "Controle manual",
	t19: "Visual",
	t20: "Dados resumidos",
	t21: "Visão técnica",
	t22: "Gráfico (S4)",
	t23: "Nó",
	t24: "Mod",
	t25: "Gráfico (Po e Pc)",
	t26: "(nenhum alarme detectado)",
	t27: "Alarmes do dispositivo",
	t28: "Ver dispositivo anterior",
	t29: "Ver o próximo dispositivo",
	t30: "Erro na leitura dos alarmes.",
	t31: "Alarmes atuais",
	t32: "Alarme atual",
	t33: "Nenhum alarme detectado.",
	t34: "Detalhe",
	t35: "Apontar",
	t36: "Histórico de registro",
	t37: "Horários",
	t38: "Hora de início",
	t39: "Pare o tempo",
	t40: "Dias",
	t41: "Feriados",
	t42: "Status",
	t43: "Selecionar pontos de dados",
	t44: "OK",
	t45: "Cancelar",
	t46: "Começar",
	t47: "Fim",
	t48: "Data de início",
	t49: "Taxa de amostragem",
	t50: "Nenhum ponto de dados selecionado",
	t51: "Agora",
	t52: "segundo",
	t53: "min",
	t54: "hora",
	t55: "Dia",
	t56: "tipo de endereço",
	t57: "Endereço",
	t58: "Localização:",
	t59: "Insira um novo valor",
	t60: "Atualizar",
	t61: "Selecionar pontos de dados",
	t62: "Selecione o novo valor",
	t63: "Fora",
	t64: "Em",
	t65: "Recuperando pontos de dados...",
	t66: "Erro ao recuperar dados...",
	t67: "Nenhum histórico configurado...",
	t68: "Erro ao recuperar pontos de dados...",
	t69: "Recuperando amostras de histórico para",
	t70: "Erro ao recuperar amostras de histórico...",
	t71: "Nome do usuário",
	t72: "Senha",
	t73: "Endereço IP/URL do host:",
	t74: "OK",
	t75: "Cancelar",
	t76: "Nome",
	t77: "Valor",
	t78: "Erro ao recuperar a lista de usuários",
	t79: "Insira o novo endereço",
	t80: "Falha ao obter lista de usuários, verifique o endereço IP/URL do host, por favor",
	t81: "Código de autenticação",
	t82: "Código da conta",
	t83: "Selecione Nova Hora e Data",
	t84: "Selecionar nova duração",
	t85: "Operação falhou",
	t86: "Insira as informações de autorização",
	t87: "HVAC",
	t88: "Vai!",
	t89: "Controle de descongelamento",
	t90: "Iniciar descongelamento manual",
	t91: "Controle de Iluminação da Caixa",
	t92: "Luzes da caixa manual acesas",
	t93: "Luzes do estojo manual apagadas",
	t94: "Controle de limpeza da caixa",
	t95: "Limpeza manual da caixa ativada",
	t96: "Limpeza manual da caixa",
	t97: "Interruptor Principal",
	t98: "Ligação principal manual",
	t99: "Desligamento Manual Principal",
	t100: "Controle de desligamento",
	t101: "Desligar dispositivo",
	t102: "Ativar dispositivo",
	t103: "Controle de recuo noturno",
	t104: "Retardo noturno ativado",
	t105: "Desativação da Noite",
	t106: "Mensagem enviada...",
	t107: "Controle manual",
	t108: "Perto",
	t109: "Iluminação",
	t110: "Potência/Diversos",
	t111: "Visão geral",
	t112: "Selecione a unidade AK255 para exibir.",
	t113: "Carregando...",
	t114: "de",
	t115: "Todos os AK255",
	t116: "Anterior",
	t117: "Páginas",
	t118: "Próximo",
	t119: "Parâmetro",
	t120: "Medidas",
	t121: "Recuperando dados...",
	t122: "Erro ao ler a seção",
	t123: "Temperatura",
	t124: "Atraso após o degelo",
	t125: "Alarme se abaixo",
	t126: "Alarme se acima",
	t127: "Monitoramento",
	t128: "desligada",
	t129: "Ativo",
	t130: "Hospedeiro",
	t131: "Endereço",
	t132: "Descrição",
	t133: "Valor atual",
	t134: "Status atual",
	t135: "Controladores",
	t136: "Unidade",
	t137: "Existe um alarme ativo",
	t138: "Existem alarmes ativos!",
	t139: "Entradas liga-desliga",
	t140: "Saídas de relé",
	t141: "Entradas do Sensor",
	t142: "Saídas Variáveis",
	t143: "Controladores genéricos",
	t144: "Outros",
	t145: "Ponto de visualização",
	t146: "Visão geral da loja",
	t147: "Sair do sistema",
	t148: "Entrar",
	t149: "Sair",
	t150: "Refrigeração",
	t151: "Visão geral do dispositivo",
	t152: "Alarmes",
	t153: "História",
	t154: "Visão geral do CS",
	t155: "Visão geral da loja",
	t156: "Configuração",
	t157: "Visualização do site",
	t158: "Vista da planta",
	t159: "Casa",
	t160: "Alarme",
	t161: "Ponto de ajuste",
	t162: "Detalhe do dispositivo",
	t163: "Contexto",
	t164: "Selecione a data",
	t165: "Em formação",
	t166: "Trilha de auditoria",
	t167: "Cálculos",
	t168: "Gerenciamento de arquivos",
	t169: "Manual de ajuda",
	t170: "Informações",
	t171: "Tradutor",
	t172: "Salvar no servidor",
	t173: "Atualizar arquivos de idioma?",
	t174: "Esta operação atualizará os arquivos de idioma na unidade. Continuar?",
	t175: "Exportar gráfico",
	t176: "Selecionar novo valor",
	t177: "mínimo",
	t178: "máximo",
	t179: "Nenhum alarme ativo!",
	t180: "Não Mutável!",
	t181: "Por favor, aguarde...",
	t182: "Comando enviado!",
	t183: "Falha na operação, tente novamente mais tarde!",
	t184: "Operação bem sucedida!",
	t185: "Operação Não Autorizada!",
	t186: "Tem certeza de continuar esta operação?",
	t187: "EVAP",
	t188: "PACOTE",
	t189: "Entradas de Monitoramento",
	t190: "DIVERSOS",
	t191: "Alarmes reconhecidos",
	t192: "Limpar alarmes reconhecidos",
	t193: "Limpar todos os alarmes reconhecidos",
	t194: "Você não está autorizado a reconhecer alarmes",
	t195: "Não autorizado",
	t196: "Deseja reconhecer este alarme?",
	t197: "Confirme para reconhecer o alarme",
	t198: "Por favor, faça o login primeiro",
	t199: "Selecione um alarme para limpar",
	t200: "Confirme para limpar os alarmes",
	t201: "Tem certeza de que desmarca este alarme?",
	t202: "Não autorizado a limpar o alarme",
	t203: "Tem certeza de limpar todos os alarmes?",
	t204: "Os alarmes selecionados não podem ser apagados",
	t205: "Configuração de alarme",
	t206: "Deve ser autorizado como supervisor para realizar esta operação",
	t207: "Nenhum dado retornado da unidade",
	t208: "Selecione uma unidade",
	t209: "Selecione uma unidade da lista",
	t210: "Número de Alarmes",
	t211: "Status do alarme",
	t212: "Diversos",
	t213: "Limpar alarme selecionado",
	t214: "Por favor confirme",
	t215: "Falha na conexão com a unidade, verifique a conexão de rede!",
	t216: "Alarme de descongelamento",
	t217: "Alarme Digital",
	t218: "Status da entrada digital",
	t219: "Entrada de descongelamento",
	t220: "Entrada de limpeza",
	t221: "Entrada digital",
	t222: "Salvando arquivos de idioma",
	t223: "Unidades",
	t224: "Nenhum dispositivo configurado no sistema, site desativado.",
	t225: "Aviso:",
	t226: "Selecionar hora",
	t227: "Comunicações",
	t228: "Baixar arquivo de relatório?",
	t229: "Aguarde enquanto gera o arquivo de relatório...",
	t230: "Baixar relatório",
	t231: "Status/Serviço de Alarme",
	t232: "Aguarde enquanto recupera os dados...",
	t233: "Logado",
	t234: "Usuário desconectado",
	t235: "Usuário conectado",
	t236: "Receber!",
	t237: "Licença inválida",
	t238: "Controladores de medidores de energia",
	t239: "Painéis de Iluminação",
	t240: "Inversor VLT",
	t241: "Módulo Compressor",
	t242: "Pontos do controlador do dispositivo",
	t243: "Pontos Calculados",
	t244: "Painéis de cutelo-martelo",
	t245: "Operadoras",
	t246: "Visualização Avançada",
	t247: "Clique duas vezes aqui para aumentar/diminuir o zoom",
	t248: "Clique aqui para ampliar/diminuir",
	t249: "Alarme(s) Reconhecido(s)",
	t250: "Simulador",
	t251: "Tarefa de Refrigeração",
	t252: "Tarefa HVAC",
	t253: "Tarefa de Iluminação",
	t254: "Tarefa MISC",
	t255: "Rede conectada",
	t256: "Dirigir",
	t257: "Referência",
	t258: "Gráfico (Drive Mtr Hz & Amp)",
	t259: "Data de parada",
	t260: "Pare",
	t261: "A hora de parada está ligada ou antes da hora de início, selecione novamente a data/hora",
	t262: "A hora de criação do histórico dos pontos de dados",
	t263: "são posteriores à hora de início especificada",
	t264: "O número retornado de amostras de cada ponto do histórico atingiu 100.000, levaria muito tempo para obter a resposta. Você pode reduzir esse número diminuindo a taxa de amostragem OU alterando a data/hora.",
	t265: "A tarefa de consulta do histórico da unidade está ocupada, aguarde cerca de 2 minutos e tente novamente...",
	t266: "Salve ",
	t267: "Painel de controle do histórico",
	t268: "Primeiro",
	t269: "Último",
	t270: "Impressão",
	t271: "Reduzir o zoom",
	t272: "Mais Zoom",
	t273: "Mostrar/ocultar legenda",
	t274: "Exportar arquivo de dados do gráfico",
	t275: "Mostrar/ocultar grade",
	t276: "Visualização de página única",
	t277: "Visualização de várias páginas",
	t278: "Carregar grupo de histórico do arquivo",
	t279: "Carregar histórico do arquivo",
	t280: "Salvar grupo de histórico em arquivo",
	t281: "Contagem de visualizações de várias páginas",
	t282: "Página",
	t283: "Selecione uma página para exibir",
	t284: "O arquivo de histórico deve estar no formato .hst!",
	t285: "Nenhum dado retornado, verifique a seleção do ponto do histórico e a data/hora selecionada",
	t286: "Instantâneo do histórico",
	t287: "Visualização completa do histórico",
	t288: "Visualização do arquivo de histórico",
	t289: "Escolher...",
	t290: "Esta operação exportará a visualização do histórico atual para um arquivo .hst/.csv.",
	t291: "Por favor, escolha um arquivo de destino.",
	t292: "Arquivo",
	t293: "Carregar grupo de histórico...",
	t294: "Carregar histórico...",
	t295: "Salvar grupo de histórico...",
	t296: "Visualizar",
	t297: "Ampliação",
	t298: "Sim",
	t299: "Não",
	t300: "Recuperar configuração do controlador?",
	t301: "Não pergunte novamente",
	t302: "Recuperar configuração do controlador",
	t303: "Dados recuperados",
	t304: "Nível de alarme",
	t305: "Ação de alarme",
	t306: "Reconhecido",
	t307: "Salvar banco de dados em disco",
	t308: "Nome do arquivo",
	t309: "Descrição do banco de dados",
	t310: "Caminho do banco de dados",
	t311: "Versão",
	t312: "Tempo",
	t313: "Exportar alarmes",
	t314: "Banco de dados salvo em",
	t315: "Falha ao salvar o banco de dados, verifique se o diretório e o nome do arquivo são válidos!",
	t316: "O nome do arquivo não está correto, verifique o nome do arquivo do banco de dados",
	t317: "A exportação de alarmes foi bem-sucedida. Escolha um arquivo para salvar os alarmes exportados",
	t318: "Por favor, selecione alarmes para exportar",
	t319: "Recuperando Informações de Alarme da(s) Unidade(s)",
	t320: "Descrição do arquivo",
	t321: "Converter arquivo .hst para formato de arquivo .csv",
	t322: "Etapa 1: selecione o arquivo .hst",
	t323: "Etapa 2: selecione os pontos de dados e o intervalo de tempo",
	t324: "Etapa 3: selecione o arquivo .csv de destino",
	t325: "Salvar como...",
	t326: "Não mais de 6.000 pontos de dados permitidos nesta operação",
	t327: "Pontos de dados selecionados",
	t328: "Não é permitido exportar mais de 65.536 amostras por ponto de dados, altere a taxa de amostragem ou intervalo de tempo.",
	t329: "Coletar",
	t330: "Coletar dados históricos da unidade",
	t331: "Selecione os pontos de dados coletados",
	t332: "Sem valor",
	t333: "Ausente",
	t334: "Suspenso",
	t335: "Por favor, faça uma seleção",
	t336: "Selecione os pontos de dados para desenhar",
	t337: "Salvar em arquivo .hst",
	t338: "Download de dados concluído, selecione uma das seguintes opções:",
	t339: "Selecione no máximo 8 pontos para desenhar",
	t340: "Salve os dados do histórico coletados em um arquivo .hst",
	t341: "Impressão da visão geral",
	t342: "Selecione Salvar ou Imprimir Relatório de Visão Geral",
	t343: "Escolha um formato de arquivo",
	t344: "Gerando arquivo de visão geral, aguarde...",
	t345: "O arquivo de banco de dados selecionado existe, você deseja sobrescrevê-lo?",
	t346: "Painel",
	t347: "Visualização Gráfica",
	t348: "Ajuda",
	t349: "Visualização do sistema",
	t350: "Mapa",
	t351: "Ferramenta de tradução",
	t352: "Manual",
	t353: "Solicitar alarmes...",
	t354: "Atualizada",
	t355: "Modelo",
	t356: "Ao controle",
	t357: "Óleo",
	t358: "Temp",
	t359: "Maximizar",
	t360: "Restaurar",
	t361: "Volte...",
	t362: "Falha na comunicação!",
	t363: "Status de comunicação ok.",
	t364: "Mensagem de comunicação enviada.",
	t365: "Carregando o componente inicial",
	t366: "Carregando Componente de Configuração",
	t367: "Ctrl Temp",
	t368: "Esta configuração só terá efeito quando o histórico for redesenhado, deseja continuar?",
	t369: "Carregando, por favor espere",
	t370: "Os pontos de dados",
	t371: "Sem nome de unidade",
	t372: "Preferências",
	t373: "Versão do simulador",
	t374: "Energia",
	t375: "Ir para a janela de visão geral",
	t376: "Alarme do sistema ou nenhum dispositivo relacionado encontrado",
	t377: "Programações simultâneas",
	t378: "Arquivo de idioma ausente.",
	t379: "Versão não suportada",
	t380: "Insira o endereço IP de uma unidade aqui (por exemplo, 192.168.1.1)",
	t381: "Insira o endereço IP ou URL de uma unidade.",
	t382: "Ocultar detalhes",
	t383: "Mostrar detalhes",
	t384: "Fora temporariamente",
	t385: "Retornar",
	t386: "Fornecer",
	t387: "Umidade",
	t388: "Ponto de condensação da água",
	t389: "Fã",
	t390: "Zona",
	t391: "Desligar",
	t392: "Dividir",
	t393: "Temperatura de sucção",
	t394: "Capacidade atual",
	t395: "Pressão de sucção",
	t396: "Descarga",
	t397: "Compressores",
	t398: "Condensador",
	t399: "Alvo do Condensador",
	t400: "Refrigerante",
	t401: "Alvo de sucção",
	t402: "Sucção",
	t403: "Descongelar",
	t404: "Formato do histórico da Danfoss",
	t405: "Formato de texto",
	t406: "Esta operação precisa de uma reinicialização da unidade, você deseja reinicializar a unidade agora?",
	t407: "A reinicialização da unidade foi bem-sucedida, recarregue o site.",
	t408: "Modo de Aplicação de Fábrica",
	t409: "Calculando...",
	t410: "minutos",
	t411: "Contratempo noturno",
	t412: "Luzes da Caixa",
	t413: "Agendar simultaneidade",
	t414: "(resolução de 10 minutos)",
	t415: "Ver texto",
	t416: "Exportar como CSV",
	t417: "Exportar como PNG",
	t418: "Ver gráfico",
	t419: "Ver texto",
	t420: "Cronograma",
	t421: "Duração",
	t422: "Termo Temp",
	t423: "NENHUM",
	t424: "Controle de Temperatura",
	t425: "Correndo de",
	t426: "Banco de dados carregado de",
	t427: "Estatisticas",
	t428: "Selecione o arquivo",
	t429: "Escolha o arquivo de histórico que você deseja converter para o formato CSV...",
	t430: "Terminar",
	t431: "Resumo",
	t432: "Arquivos de dados históricos (*.hst)",
	t433: "Assistente de conversão de histórico",
	t434: "Criação do arquivo concluída.",
	t435: "Escolha os pontos de dados que deseja converter para o formato CSV...",
	t436: "Selecionar intervalo de tempo",
	t437: "Selecione o intervalo de tempo e pressione o botão de processo...",
	t438: "Dados do Processo",
	t439: "Preparando dados do histórico, aguarde",
	t440: "Selecione o nome do arquivo CSV e salve os dados no arquivo...",
	t441: "Não é possível encontrar o ponto de dados do histórico",
	t442: "Erro - Nenhum dado do histórico retornado",
	t443: "Ocorreu um erro de comunicação com as unidades na rede do host. Encontrado",
	t444: "Atenção",
	t445: "Silêncio",
	t446: "Banco de dados não salvo. Deseja realmente fechar a janela?",
	t447: "Vista de mesa",
	t448: "Mostrar gráfico",
	t449: "Mostrar tabela",
	t450: "Descongelamento Manual Ligado",
	t451: "Descongelamento Manual Desligado",
	t452: "Auto degelo",
	t453: "Definir controle de descongelamento",
	t454: "Descarregador ativado",
	t455: "Descarregador desligado",
	t456: "Líquido",
	t457: "Nenhuma programação configurada.",
	t458: "StoreView",
	t459: "Perda de comunicação com uma unidade na rede do host!",
	t460: "Configuração rápida",
	t461: "Rua",
	t462: "Cidade",
	t463: "Telefone",
	t464: "Região",
	t466: "Índice",
	t469: "Catálogo de endereços exportado com sucesso",
	t470: "Excluir item",
	t471: "Medidor",
	t472: "Nota: a entrada diferencia maiúsculas de minúsculas",
	t473: "Conectados",
	t474: "Conselho de RO",
	t475: "Placa SI",
	t476: "Conselho de OI",
	t477: "Cálculo",
	t478: "Definir como hora atual",
	t479: "Prateleira",
	t480: "Sem compressor",
	t481: "O circuito",
	t482: "Caso",
	t483: "Desconhecido",
	t484: "tipo de erro:",
	t485: "Adicionar",
	t486: "cópia de",
	t487: "Remover",
	t488: "Controle de caso",
	t489: "Caso-tipo",
	t490: "LON",
	t491: "Insira o valor a ser copiado",
	t492: "sem nome",
	t493: "Configurando o layout de refrigeração...",
	t494: "Erro de configuração:",
	t495: "Por favor, verifique a configuração.",
	t496: "A configuração do layout de refrigeração foi concluída com sucesso. Pressione o botão ok para sair deste assistente.",
	t497: "Status de configuração",
	t498: "Layout Final de Refrigeração",
	t500: "Código #",
	t501: "Esquema",
	t502: "Configuração do sistema",
	t503: "Licenciamento",
	t504: "Comercial",
	t505: "Não autorizado",
	t506: "Por favor verifique...",
	t507: "Tem certeza de que deseja sair desta tela? Suas configurações podem ser perdidas.",
	t508: "Digitalizando a rede do controlador...",
	t509: "Digitalização",
	t510: "Verificando",
	t511: "AK-I/O",
	t512: "Redigitalização",
	t513: "Incluir programação offline",
	t514: "Barramento do dispositivo",
	t515: "Dispositivos on-line",
	t516: "Programação off-line",
	t517: "Endereço duplicado",
	t518: "Controles do compressor",
	t519: "Grupos de sucção",
	t520: "N / D",
	t521: "Estojos Disponíveis",
	t522: "Permitir a criação de circuitos multi-case",
	t523: "Inserir dispositivos",
	t524: "Inserir circuito",
	t525: "Circuito multi-caso.",
	t526: "Carregando tabelas...",
	t527: "Solicitando dados de alarme para",
	t528: "Arquivos de grupo de histórico",
	t529: "Arquivos de dados históricos",
	t530: "Todos os arquivos",
	t531: "Mostrar símbolos",
	t532: "Mostrar curvas",
	t533: "Não foi coletado histórico suficiente.",
	t534: "Recupere os dados históricos mais recentes.",
	t535: "Status/Configurações",
	t536: "Agora baixando",
	t537: "exemplos de história...",
	t538: "Recuperando",
	t539: "Os pontos de dados...",
	t540: "Intervalo",
	t541: "Mín.",
	t542: "Máx.",
	t543: "Média",
	t544: "Vá para a janela de alarme principal.",
	t545: "Parar o login automático",
	t546: "Conectando à",
	t547: "Cancelar login automático",
	t548: "Por favor, digite a senha de logoff",
	t549: "Web 1",
	t550: "Web 2",
	t551: "Faça logon automaticamente",
	t552: "Faça logoff após o período de inatividade",
	t553: "Tela inicial",
	t554: "Mostrar menu de configuração",
	t555: "Mostrar itens diversos no painel",
	t556: "Usar tela cheia",
	t557: "Ativar som de alarme",
	t558: "Permitir o fechamento/minimização do programa",
	t559: "Erro ao acessar a unidade #",
	t560: "Conectado à rede.",
	t561: "Login automático cancelado",
	t562: "Tente conectar novamente",
	t563: "Desconectado de",
	t564: "Conectado",
	t565: "Formato de hora",
	t566: "Formato de data",
	t567: "Pressão",
	t568: "Nível de luz",
	t569: "Sublegal",
	t570: "Sub-resfriador",
	t571: "Diário",
	t572: "Semanalmente",
	t573: "Arquivo HST",
	t574: "Arquivo CSV",
	t575: "Domingo",
	t576: "Segunda-feira",
	t577: "Terça-feira",
	t578: "Quarta-feira",
	t579: "Quinta-feira",
	t580: "Sexta-feira",
	t581: "Sábado",
	t582: "Coleta de registro de histórico",
	t583: "Ativar coleta automática",
	t584: "Quando coletar",
	t585: "Local de salvamento do arquivo",
	t586: "Selecione o formato do arquivo",
	t587: "Selecione a pasta para armazenar os arquivos de histórico...",
	t588: "Habilite a coleta automática de histórico para recuperar e salvar logs de histórico diários ou semanais dos pontos de histórico configurados da loja.",
	t589: "Para visualizar os arquivos de histórico HST, vá para a tela principal do histórico e selecione o histórico de importação. Para visualizar arquivos CSV, use qualquer aplicativo de planilha.",
	t590: "Se o endereço IP da conexão atual for encontrado no livro de endereços, os pontos de histórico selecionados serão salvos.",
	t591: "Selecionar pontos de dados",
	t592: "Todos selecionados",
	t593: "Pontos selecionados",
	t594: "Procurar",
	t595: "Conectando-se automaticamente a",
	t596: "Por favor verifique",
	t597: "A coleta automática de histórico está habilitada. Tem certeza de que deseja fechar este programa?",
	t598: "Tem certeza de que deseja fechar este programa?",
	t599: "Açao",
	t600: "Filtro",
	t601: "Todas as ações",
	t602: "Mais Informações...",
	t603: "Editável",
	t604: "Operação",
	t605: "Operação cancelada",
	t606: "Download do banco de dados concluído",
	t607: "Banco de dados de unidades atualizado",
	t608: "Arquivo(s) transferido(s) para a unidade com sucesso",
	t609: "Download concluído, o arquivo está em processamento na unidade",
	t610: "Ocorreu um erro ao carregar o arquivo para o PC, verifique se o arquivo é gravável e acessível",
	t611: "Carregando arquivo",
	t612: "Baixando arquivo",
	t613: "Fazendo upload dos arquivos selecionados",
	t614: "Baixando os arquivos selecionados",
	t615: "Excluindo arquivo",
	t616: "Excluindo os arquivos selecionados",
	t617: "Erro de conexão FTP",
	t618: "Baixar Arquivo(s) Selecionado(s) para a Unidade",
	t619: "Ocorreu um erro na conexão FTP. Verifique a conexão FTP e a senha/nome de usuário.",
	t620: "FTP conectado",
	t621: "Bytes",
	t622: "Os arquivos na unidade seriam substituídos por esta operação. Deseja continuar?",
	t623: "Os arquivos no PC seriam substituídos por esta operação. Deseja continuar?",
	t624: "Os arquivos foram baixados para a unidade.",
	t625: "SOMENTE refrigeração",
	t626: "Loja de conveniência",
	t627: "Funcionalidade completa",
	t628: "SOMENTE HVAC",
	t629: "O nome do arquivo é ilegal; Certifique-se de que o nome do arquivo tenha menos de 8 caracteres.",
	t630: "Substituir o código do aplicativo agora?",
	t631: "Mais tarde",
	t632: "carregado",
	t633: "você pode escolher SIM para substituir o aplicativo agora ou escolher Mais tarde",
	t634: "Selecione o diretório raiz do simulador",
	t635: "Por favor, selecione o diretório do Windows",
	t636: "Selecione o diretório de destino",
	t637: "Selecione o diretório do banco de dados",
	t638: "FTP desconectado",
	t639: "Tempo limite de conexão, FTP desconectado",
	t640: "Carregar Arquivo(s) Selecionado(s) para o PC",
	t641: "Excluir arquivo(s) selecionado(s)",
	t642: "Porta do servidor FTP",
	t643: "Porta do servidor web",
	t644: "Caminho local",
	t645: "Acima",
	t646: "Tamanho",
	t647: "Encontro",
	t648: "Conectar",
	t649: "desconectar",
	t650: "Caminho Remoto",
	t651: "Espaço Livre Disponível:",
	t652: "Arquivo(s) Selecionado(s)",
	t653: "arquivos",
	t654: "Tamanho total:",
	t655: "Clique para mudar de pasta...",
	t656: "Mudar...",
	t657: "Deseja excluir o(s) arquivo(s) selecionado(s)?",
	t658: "Conexão Fechada, Sair",
	t659: "O(s) nome(s) do(s) arquivo(s) selecionado(s) é(são) ilegal(is) e o download é ignorado.",
	t660: "Alguns dos nomes dos arquivos selecionados são ilegais e ignorados.",
	t661: "Arquivo(s) transferido(s) para o PC com sucesso",
	t662: "Espaço livre em disco insuficiente na unidade.",
	t663: "Transferir arquivo(s)",
	t664: "Modificado",
	t665: "Copiar arquivos do disco flash para o disco RAM",
	t666: "Selecione uma operação de cópia de arquivo:",
	t667: "Copie todos os arquivos EDF configurados do disco flash",
	t668: "Copie todos os arquivos EDF do disco flash",
	t669: "Os arquivos edf/ed3 são armazenados no disco flash de sua unidade, mas não são carregados na tela de gerenciamento de arquivos. Use as opções abaixo para copiar os arquivos EDF armazenados em sua unidade para a tela Gerenciamento de arquivos",
	t670: "Esta Operação Copia todos os arquivos EDF que foram configurados na unidade do disco flash para o disco RAM",
	t671: "Esta operação Copia todos os arquivos EDF na unidade do disco flash para o disco RAM",
	t672: "Não existe nenhum arquivo EDF válido",
	t673: "Cópia do arquivo EDF concluída",
	t674: "arquivos copiados",
	t675: "Confirme o download do banco de dados",
	t676: "Endereço da unidade",
	t677: "Endereço IP/Nome do host",
	t678: "Nome do arquivo de banco de dados",
	t679: "Selecionar",
	t680: "Atualização completa, redefinindo e atualizando a unidade. Isso pode levar vários minutos.",
	t681: "Bem-vindo ao Upgrade Manager - Etapa 1 de 2",
	t682: "Bem-vindo ao Upgrade Manager - Etapa 2 de 2",
	t683: "Continuar",
	t684: "Verificação de pré-atualização",
	t685: "Localizar firmware",
	t686: "Banco de dados de backup?",
	t687: "Backup de banco de dados",
	t688: "Transferindo Software",
	t689: "Software de substituição",
	t690: "Selecione o software para atualizar",
	t691: "Nenhum aplicativo à prova de falhas de fábrica detectado.",
	t692: "Você pode continuar, mas quaisquer erros durante o processo de atualização podem tornar o sistema inoperante.",
	t693: "É altamente recomendável não prosseguir.",
	t694: "Você quer continuar?",
	t695: "A atualização remota de um sistema acarreta um risco de falha, o que pode resultar em erro do sistema.",
	t696: "Isenção de responsabilidade",
	t697: "Upload do banco de dados concluído",
	t698: "Backup do banco de dados concluído",
	t699: "Download do aplicativo concluído",
	t700: "Sobregravação de aplicativo concluída",
	t701: "Atualização de software bem-sucedida",
	t702: "Deseja redefinir a unidade para sua aplicação de fábrica?",
	t703: "Verificando Hardware",
	t704: "Verificando o status do dispositivo",
	t705: "Fábrica à prova de falhas",
	t706: "Versão atual do firmware",
	t707: "Iniciar atualização",
	t708: "Selecione um arquivo de banco de dados existente",
	t709: "Selecione um arquivo de firmware existente",
	t710: "Costas",
	t711: "Localizar firmware",
	t712: "Selecione o arquivo .csi",
	t713: "Selecione o arquivo .mai",
	t714: "O download remoto não é suportado por esta placa de CPU.",
	t715: "Falha no download do aplicativo",
	t716: "Falha no upload do banco de dados, tente novamente",
	t717: "Sobregravação de aplicativo concluída",
	t718: "Falha na substituição do aplicativo! Por favor, tente novamente.",
	t719: "Falha na substituição do aplicativo",
	t720: "Nenhum aplicativo à prova de falhas de fábrica detectado.",
	t721: "Feito",
	t722: "O aplicativo selecionado pode ter um tipo de versão diferente do firmware da unidade atual, continuar a operação pode acarretar risco de falha e erro do sistema.",
	t723: "O(s) nome(s) do(s) arquivo(s) selecionado(s) é(são) ilegal(is) e o download é ignorado.",
	t724: "Alguns dos nomes dos arquivos selecionados são ilegais e ignorados.",
	t725: "O pino do berg da unidade está ausente.",
	t726: "A bateria da unidade está fraca.",
	t727: "A rede do host da unidade falhou.",
	t728: "A unidade está no status de corte de carga",
	t729: "Selecione o arquivo de destino",
	t730: "Selecione o diretório de destino",
	t731: "Upload do banco de dados concluído",
	t732: "Estado OK",
	t733: "Erro de status",
	t734: "Status desconhecido",
	t735: "Esta operação pode substituir os arquivos selecionados no simulador de destino, deseja continuar?",
	t736: "O download do banco de dados foi concluído, você precisa redefinir o sistema",
	t737: "Falha no download do banco de dados, você precisa tentar novamente mais tarde",
	t738: "A unidade será reinicializada automaticamente após esta operação. Deseja continuar?",
	t739: "Selecionar banco de dados",
	t740: "Baixar arquivo de banco de dados?",
	t741: "Carregar arquivo de banco de dados?",
	t742: "Baixando o banco de dados para a unidade, aguarde",
	t743: "Banco de dados baixado com sucesso, agora redefinindo a unidade",
	t744: "Atualização do banco de dados concluída, unidade redefinida com sucesso",
	t745: "Falha na atualização do banco de dados! Por favor, tente novamente.",
	t746: "Erro na atualização do banco de dados",
	t747: "Verificação de pré-download",
	t748: "Localizar banco de dados",
	t749: "Baixando o banco de dados",
	t750: "Informações atuais do banco de dados",
	t751: "Selecione o banco de dados para download",
	t752: "Salvar banco de dados no PC (upload)",
	t753: "Carregar Banco de Dados para a Unidade (Download)",
	t754: "Precisa de autorização do supervisor",
	t755: "Iniciar envio",
	t756: "Selecione uma operação de banco de dados:",
	t757: "Iniciar download",
	t758: "Versão atual do firmware",
	t760: "Download do banco de dados bem-sucedido!",
	t761: "Bem-vindo ao Database Manager - Etapa 1 de 2",
	t762: "Bem-vindo ao Database Manager - Etapa 2 de 2",
	t763: "Falha na conexão com a unidade, verifique o endereço IP/URL do host",
	t764: "A atualização remota do banco de dados de um sistema apresenta risco de falha, o que pode resultar em erro do sistema.",
	t765: "Download do banco de dados concluído",
	t766: "Varredura Ethernet",
	t767: "Digite o primeiro endereço IP e pressione iniciar",
	t768: "Varredura",
	t769: "Digitalização",
	t770: "Coleta de registro de histórico",
	t771: "Cores do gráfico analógico",
	t772: "Cores do gráfico digital",
	t773: "Programações de desligamento",
	t774: "Resumo do descongelamento",
	t775: "Horários de contratempos noturnos",
	t776: "Cronogramas de Iluminação de Caixa",
	t777: "Status de carregamento",
	t778: "Coleta de registro de alarme",
	t779: "Selecione a pasta para armazenar os arquivos de alarme...",
	t780: "Por mês",
	t781: "Ative a coleta automática de alarmes para recuperar e salvar informações de alarme em um arquivo periodicamente.",
	t782: "Separador CSV",
	t783: "Sem alarmes",
	t784: "Recuperando contagens de alarme para a unidade:",
	t785: "Recuperando detalhes do alarme da unidade:",
	t786: "A coleta automática de alarmes está habilitada. Tem certeza de que deseja fechar este programa?",
	t787: "De hora em hora",
	t788: "Isso atualizará o banco de dados da unidade e definirá o local para o centro da visualização atual. Continuar?",
	t789: "amostras processadas",
	t790: "Por favor, configure um grupo de sucção.",
	t791: "Para reutilizar o Layout Wizard mais de uma vez, o layout do banco de dados de refrigeração precisa ser redefinido.",
	t792: "Faça isso através da página Configuração- > Controle e defina o número de Racks/Packs para zero.",
	t793: "Observação: quaisquer programações, cálculos ou importações com base na configuração de refrigeração anterior precisarão ser reconfigurados.",
	t794: "Processo de cópia concluído.",
	t795: "Relatório de Refrigeração",
	t796: "Layout final de HVAC",
	t797: "A configuração do layout HVAC foi concluída com sucesso. Pressione o botão ok para sair deste assistente.",
	t798: "Configurando o layout HVAC...",
	t799: "Para reutilizar o Assistente de layout HVAC mais de uma vez, o layout do banco de dados HVAC precisa ser redefinido.",
	t800: "Faça isso através da página Configuração- > Controle e defina o número de unidades HVAC para zero.",
	t801: "Observação: quaisquer agendamentos, cálculos ou importações com base na configuração anterior de HVAC precisarão ser reconfigurados.",
	t802: "Padrão de estágio (requer redefinição da unidade para ativar)",
	t803: "Passos",
	t804: "Nível",
	t805: "Mostrar vários dadosTip",
	t806: "Número de etapas",
	t807: "Não foi possível salvar o arquivo.",
	t808: "Modelo incorreto.",
	t809: "Carregamento bem-sucedido.",
	t810: "Aberto",
	t811: "Esse recurso está disponível apenas na versão desktop do StoreView.",
	t812: "Continuar",
	t813: "Sair do assistente",
	t814: "Operação bem sucedida. Deseja continuar ou deseja sair do assistente.",
	t815: "Erro ao buscar dados do registrador de alarmes.",
	t817: "Nome do dispositivo :",
	t819: "Em andamento...",
	t820: "Os dados de alarme não estão presentes.",
	t821: "Contagem de alarmes",
	t822: "Opções de exibição de mímica",
	t823: "Exibir apenas texto",
	t824: "Ícone de exibição somente",
	t825: "Exibir texto e ícone",
	t826: "1 semana",
	t827: "2 semanas",
	t828: "3 semanas",
	t829: "4 semanas",
	t830: "Configurações gráficas",
	t831: "Não é possível exibir o diagnóstico de alarme, verifique a coleta automática de alarmes nas preferências",
	t832: "Não é permitido reordenar com racks ou circuitos de E/S incluídos na configuração.",
	t833: "Este endereço IP/URL do host já está presente no catálogo de endereços. Você gostaria de atualizar essa entrada?",
	t834: "Navegar",
	t835: "Selecione a pasta do catálogo de endereços",
	t836: "Localização",
	t837: "O local selecionado não tem nenhum conteúdo do catálogo de endereços. Deseja exportar o conteúdo do catálogo de endereços existente para o local selecionado?",
	t838: "Códigos de ação de alarme",
	t839: "O arquivo do catálogo de endereços não foi encontrado no caminho selecionado. O catálogo de endereços é carregado do local...",
	t840: "Selecione o local para salvar os logs.",
	t841: "Selecione um local válido para salvar os registros.",
	t842: "A coleta do registro de alarmes está em andamento...",
	t843: "A coleta do registro do histórico está em andamento...",
	t844: "Executado em segundo plano",
	t845: "Progresso do registro de alarme",
	t846: "O endereço IP/URL do host não deve ficar em branco",
	t847: "O campo de endereço não deve ficar em branco",
	t849: "Você não tem a versão mais recente do arquivo, gostaria de carregar dados da versão mais recente?",
	t850: "Conflito de versão",
	t851: "Progresso do registro do histórico",
	t852: "Os campos Nome de usuário e Senha não devem ficar em branco.",
	t853: "O campo Endereço IP não deve ficar em branco.",
	t854: "O campo Endereço IP/URL do host não deve ficar em branco.",
	t855: "O nome de usuário não deve ficar em branco.",
	t856: "A senha não deve ficar em branco.",
	t857: "O SM800 com o qual você conectou",
	t858: "não teve o Assistente de Inicialização Inicial concluído. Conclua esta tarefa e reconecte-se ao SM800",
	t859: "Definir para agora",
	t860: "Entrada inválida",
	t861: "O texto é muito longo",
	t862: "Desvio",
	t863: "Não é possível se comunicar com a(s) unidade(s) na rede do host",
	t864: "Verifique se os endereços são diferentes. A configuração é atualizada quando o botão salvar é pressionado.",
	t865: "Selecione um arquivo de histórico válido (.hst)",
	t866: "Selecione um arquivo de grupo de histórico válido (.grp)",
	t867: "Cor",
	t868: "Percentagem",
	t869: "Tensão",
	t870: "Atual",
	t871: "Poder",
	t872: "Frequência",
	t873: "Concentração",
	t874: "Fluxo",
	t875: "Velocidade",
	t876: "Acidez",
	t877: "Por favor, selecione gráficos na gaveta inferior",
	t878: "Salvar PNG",
	t879: "Desenhar",
	t880: "Status do sistema...",
	t881: "Status do sistema e da unidade",
	t882: "Portal",
	t883: "Licenças de recursos",
	t884: "Versão do software",
	t885: "Versão do módulo do navegador",
	t886: "Versão Danux",
	t887: "Endereço MAC",
	t888: "Suporte e atualizações",
	t889: "Nome da unidade",
	t890: "Reconectar",
	t891: "Inalcançavel. Verifique a configuração de IP.",
	t892: "Nenhum equipamento {{type}} encontrado.",
	t893: "A versão do software atualmente usada não é suportada",
	t894: "Links Rápidos",
	t895: "Equipamento",
	t896: "Representação gráfica",
	t897: "Serviços de utilidade pública",
	t898: "Reversão de software",
	t899: "Diagnóstico",
	t900: "Um erro ocorreu",
	t901: "Nenhum dado de {{alarms}} encontrado",
	t902: "Definições",
	t903: "Nome da Aplicação",
	t904: "Versão do aplicativo",
	t905: "Mensagem",
	t906: "Tipo de evento",
	t907: "Carimbo de data e hora",
	t908: "Versão de reversão",
	t909: "Versão Atual",
	t910: "Programas",
	t911: "Reverter...",
	t912: "Selecione o equipamento da lista",
	t913: "Visualização de calendário",
	t914: "Ocorreu um erro ao carregar os dispositivos. Tente novamente.",
	t915: "Ocorreu um erro ao carregar os agendamentos, tente novamente.",
	t916: "Nenhum equipamento encontrado",
	t917: "Exportação de agendamentos",
	t918: "Escolha o tipo de exportação CSV ou PDF",
	t919: "Exportar como CSV",
	t920: "Preparando PDF",
	t921: "Exportar como PDF",
	t922: "Forneça um IP neste formato xx.xx.xx.xx[:xxxx]",
	t923: "Nome do usuário",
	t924: "Conecte-se",
	t925: "Reverter",
	t926: "Software de reversão SM800A",
	t927: "Esta ação reverterá todos os softwares para as versões listadas.",
	t928: "Editor de dispositivo de festa",
	t929: "algum texto que explica o recurso",
	t930: "Atualizar CDF",
	t931: "Dispositivo de mapa",
	t932: "Arquivo do dispositivo",
	t933: "número de registro baseado em 1",
	t934: "Tipo de armazenamento",
	t935: "Mín.",
	t936: "Máx.",
	t937: "Somente leitura",
	t938: "Registro",
	t939: "Tipo de mensagem",
	t940: "Palavra-chave de conversão",
	t941: "Parâmetros",
	t942: "Endereço",
	t943: "Prioridade",
	t944: "Pedaço",
	t945: "Negado",
	t946: "Novo arquivo",
	t947: "Abrir arquivo",
	t948: "Gerar arquivo",
	t949: "Grupo",
	t950: "Versão do produto",
	t951: "Ocorreu um erro",
	t952: "Não encontrado",
	t953: "Este dispositivo não foi encontrado",
	t954: "Fora temporariamente.",
	t955: "Nenhum {{equipment}} encontrado",
	t956: "Casos",
	t957: "Alarme mudo/agir com sucesso",
	t958: "Silenciar/agir falhou para este alarme",
	t959: "Ocorreu um erro ao carregar a(s) unidade(s), tente novamente.",
	t960: "Nenhum equipamento",
	t961: "Nenhum equipamento {{type}} encontrado",
	t962: "Configurar o gerenciador do sistema",
	t963: "Dispositivo",
	t964: "Desfazer",
	t965: "Você alterou o valor do campo, deseja salvar as alterações?",
	t966: "Limpar pontos de histórico selecionados",
	t967: "Shr {{number}}",
	t968: "Velocidade da bomba Hr",
	t969: "Sd",
	t970: "V3hr",
	t971: "Referência de RH",
	t972: "Stw {{number}}",
	t973: "Duas referências",
	t974: "Duas velocidades da bomba",
	t975: "V3tw",
	t976: "Trec",
	t977: "Prec",
	t978: "Vrec OD",
	t979: "Referência Prec",
	t980: "Referência Trec",
	t981: "Pc",
	t982: "S",
	t983: "Vhp OD",
	t984: "Referência Pgc",
	t985: "Ejetor OD",
	t986: "Pgc",
	t987: "Shp",
	t988: "Sgc",
	t989: "Sc3",
	t990: "Sgc ctrl.",
	t991: "Cond. capacidade",
	t992: "Po",
	t993: "S",
	t994: "Sd",
	t995: "computador",
	t996: "Capacidade de funcionamento",
	t997: "Certificado do navegador",
	t998: "Sair",
	t1000: "Classificando apenas em {{count}}",
	t1001: "Carregado {{count}} de {{total}} alarmes",
	t1002: "Reconhecimento bem-sucedido para todos os alarmes",
	t1003: "Falha de reconhecimento para todos os alarmes",
	t1004: "Apenas {{count}} de {{total}} alarmes reconhecidos",
	t1005: "A exportação falhou para todos os alarmes",
	t1006: "Exportação bem-sucedida",
	t1007: "Falha na exportação, pelo menos um tipo de alarme é necessário",
	t2000: "-",
	t2001: "A resposta é muito grande para o buffer de transmissão XML que é de 32K bytes",
	t2002: "Nenhum atributo associado ao elemento ou comando cmd não possui elemento \"cmd\" ou não pôde escrever em uma variável ou não pôde ler uma variável ou não pôde ler um ponto ou não pôde escrever no modo de operação de entrada ou saída de relé ligado/desligado",
	t2003: "Entrada de comando incorreta",
	t2004: "Erro nos parâmetros de postagem CGI",
	t2005: "Erro ao converter a resposta XML de sua representação interna para a string XML de resposta",
	t2006: "Erro ao decodificar a string do URL",
	t2007: "Estouro de memória",
	t2008: "Falha na compactação de dados de resposta",
	t2009: "Erro ao analisar a solicitação",
	t2010: "Erro de acesso a dados - várias causas que não são mais resolvidas",
	t2011: "Valor do atributo de ação de comando indefinido O valor do atributo é inválido",
	t2012: "Erro no número de referência do alarme ou alarme não configurado",
	t2013: "A string do parâmetro cgi de entrada é muito longa.",
	t2014: "O endereço do dispositivo não foi encontrado",
	t2015: "Falha na autorização para gravar",
	t2016: "Nenhum histórico configurado",
	t2017: "Nenhum dado do histórico retornado, pode ser erro de hora",
	t2018: "Criar erros de arquivo",
	t2019: "Ler erros de arquivo",
	t2020: "Excedeu o tamanho máximo da lista de dispositivos",
	t2021: "não usado",
	t2022: "Índice de histórico inválido",
	t2023: "Não foi possível encontrar o índice de pontos de placa do tipo de nó, nó, mod e ponto.",
	t2024: "Não foi possível encontrar o índice do histórico",
	t2025: "A consulta de histórico não foi inicializada antes de tentar iniciar uma consulta de histórico.",
	t2026: "Uma hq_start_query foi tentada enquanto uma consulta de histórico está ativa",
	t2027: "Nenhum atributo de comando encontrado quando alguns são esperados",
	t2028: "Um começo de época foi fornecido sem fim ou vice-versa",
	t2029: "Não foi possível converter do formato de data e hora para segundos de época",
	t2030: "O comando requer um query_id, mas nenhum fornecido",
	t2031: "Erro ao criar resposta XML",
	t2032: "O query_id fornecido não corresponde a uma consulta ativa",
	t2033: "Um comando hq_get_data foi tentado, mas os dados ainda não estão disponíveis",
	t2034: "Foi fornecida uma taxa de amostragem que não é uma das permitidas",
	t2035: "O intervalo médio é menor ou igual à taxa de amostragem na qual os dados foram registrados",
	t2036: "O tempo da época de parada é menor que o tempo da época inicial ou o tempo da época de parada menos o tempo da época inicial é menor que o intervalo médio",
	t2037: "O device_id fornecido não corresponde a nenhum atualmente conhecido pelo ak255. Observe que apenas os dispositivos que foram configurados são conhecidos pelo ak255",
	t2038: "Nenhum atributo de ID",
	t2039: "Atributos de ID inválidos",
	t2040: "Sem número de agendamento",
	t2041: "Número de agendamento inválido",
	t2042: "Sem detalhes de programação",
	t2043: "Faltando on_time",
	t2044: "Faltando hora",
	t2045: "Hora inválida",
	t2046: "Minuto faltando",
	t2047: "Minuto inválido",
	t2048: "Faltando tempo_fora",
	t2049: "Dias da semana ausentes",
	t2050: "Dias da semana inválidos",
	t2051: "Feriados ausentes",
	t2052: "Feriados inválidos",
	t2053: "Holiday_start ausente ou inválido",
	t2054: "Holiday_end ausente ou inválido",
	t2055: "Holiday_open ausente ou inválido",
	t2056: "Holiday_close ausente ou inválido",
	t2057: "Feriado não configurado",
	t2058: "Descrição ausente",
	t2059: "Não é possível limpar o alarme",
	t2060: "store_open ausente ou inválido",
	t2061: "store_closed ausente ou inválido",
	t2062: "Alarme não configurado",
	t2063: "Nó offline",
	t2064: "Índice ausente",
	t2065: "Índice inválido",
	t2066: "O relé não pode ser cronometrado",
	t2067: "O nome que está sendo alterado tem muitos caracteres. Se alterar store_name ou unit_name, deve ter < = 16 caracteres, se alterar storeId1 ou storeId2, deve ter < = 8 caracteres.",
	t2068: "Elementos ausentes associados ao elemento de comando.",
	t2069: "Tag_ID não definido",
	t2070: "Nó folha ausente para dados",
	t2071: "Nenhuma alteração nos dados",
	t2072: "Falha ao atualizar o banco de dados",
	t2073: "Não gravável",
	t2074: "Erro do medidor de derramamento",
	t2075: "Sem metros",
	t2076: "Nível de galpão inválido",
	t2077: "Tipo inválido",
	t2078: "Sistema ocupado",
	t2079: "Ativos não configurados para redução de carga",
	t2080: "Classificação de potência inválida para o ativo",
	t2081: "Atraso de inicialização inválido para o ativo",
	t2082: "Configuração ocupada",
	t2083: "Varredura de E/S em andamento",
	t2084: "Muitos degelos em andamento",
	t2085: "Tentando carregar uma versão ruim do banco de dados na unidade",
	t2086: "Falha no carregamento do banco de dados",
	t2087: "Não há memória da web disponível",
	t2088: "Resposta de demanda não configurada (somente o sistema AK355)",
	t2089: "Tamanho de arquivo ausente ou incorreto.",
	t2090: "Arquivo codificado ausente (elementos b64)",
	t2091: "Falha ao salvar o banco de dados no disco RAM.",
	t2092: "Tamanho do arquivo muito grande.",
	t2093: "Falta o nome do arquivo.",
	t2094: "ID do arquivo ausente.",
	t2095: "Índice de carregamento de arquivo ausente (Observação: este é o índice de sequência para load_file load_database).",
	t2096: "O ID do arquivo não corresponde ou está ausente.",
	t2097: "Operação de carregamento de arquivo já em andamento.",
	t2098: "O arquivo decodificado não corresponde ao tamanho do arquivo original.",
	t2099: "A operação de carregamento do arquivo foi cancelada ou não foi iniciada.",
	t2100: "Solicitação de usuário inválida (o solicitante original não corresponde ao endereço IP)",
	t2101: "Sequência de carregamento de arquivo inválida.",
	t2102: "Diretório inválido",
	t2103: "Extensão inválida",
	t2104: "Deslocamento inválido",
	t2105: "Erro ao codificar arquivo",
	t2106: "Erro ao decodificar arquivo",
	t2107: "Falha ao abrir arquivo para leitura/gravação",
	t2108: "Falha ao gravar no arquivo",
	t2109: "O arquivo está protegido contra gravação no sistema de arquivos Flash",
	t2110: "Falha ao excluir arquivo.",
	t2111: "Não é possível excluir a pasta.",
	t2112: "Dispositivos ausentes",
	t2113: "Nem todos os dispositivos do URL foram encontrados",
	t2114: "Parâmetros de histórico ausentes",
	t2115: "Nem todos os parâmetros de histórico do URL foram encontrados",
	t2200: "A versão do software atualmente usada ( {{currentVersion}} ) não é recomendada",
	t2201: "A versão do software usada atualmente ( {{currentVersion}} ) não é recomendada ( > {{recommendedVersion}} recomendado)",
	t2202: "Erro de versão do software",
	t2203: "Editor gráfico",
	t2204: "Editar",
	t2205: "Desenhar",
	t2206: "Nova visualização",
	t2207: "Criado",
	t2208: "Derrubar",
	t2209: "Novo gráfico",
	t2210: "Criar uma nova visualização gráfica",
	t2211: "Nome",
	t2212: "Renomear",
	t2214: "Exportar",
	t2213: "Duplicado",
	t2215: "Excluir",
	t2216: "Tem certeza de que deseja excluir a visualização \" {{name}} \"?",
	t2217: "Exportar todas as visualizações",
	t2218: "Mais...",
	t2219: "Regra",
	t2220: "as regras",
	t2221: "Nome da regra",
	t2222: "Editar painel",
	t2223: "Condições",
	t2224: "Gradiente",
	t2225: "Inverter condições",
	t2226: "Mapeamentos",
	t2227: "Mapear formas por",
	t2228: "ID da forma",
	t2229: "Estados",
	t2230: "Adicionar mapeamento",
	t2231: "Adicionar regra",
	t2232: "Operador",
	t2233: "Adicionar condição",
	t2234: "Comece adicionando regras",
	t2235: "Tem certeza de que deseja excluir a regra \" {{name}} \"?",
	t2236: "Uma cópia da regra é feita",
	t2237: "Visualizações do site",
	t2238: "Você precisa criar visualizações de site para visualizá-las",
	t2239: "Ir para o editor gráfico",
	t2240: "Ir para detalhes",
	t2241: "Selecionar parâmetro",
	t2242: "Nenhum parâmetro encontrado",
	t2243: "Opções",
	t2244: "Entrada Ligar/Desligar",
	t2245: "Saída de relé",
	t2246: "Entrada do Sensor",
	t2247: "Saída variável",
	t2248: "Dispositivo genérico",
	t2249: "Nó vazio",
	t2250: "O registro de histórico não é suportado...",
	t2251: "Repetir",
	t2252: "Não foi possível buscar o histórico",
	t2253: "O histórico de carregamento pode demorar um pouco e não é compatível com várias consultas ao mesmo tempo. Tente novamente esta solicitação.",
	t2254: "Nome do parâmetro",
	t2255: "Nome do dispositivo",
	t2256: "Tipo de dispositivo",
	t2257: "Nem todos os parâmetros do histórico puderam ser carregados ( {{n}} falhou)",
	t2258: "Nome de usuário ou senha incorretos",
	t2259: "Sem comunicação com o controlador",
	t2260: "Atualização de software",
	t2261: "Sistema",
	t2262: "Reversão de software",
	t2263: "Instalar",
	t2264: "A reversão do software substituirá uma versão mais recente",
	t2265: "Reverter software para a versão de backup {{version}}",
	t2266: "Base de dados",
	t2267: "Uma atualização está disponível",
	t2268: "Exportar banco de dados",
	t2269: "Download",
	t2270: "Baixando banco de dados",
	t2271: "Não é possível exportar o banco de dados",
	t2272: "Arquivo de banco de dados",
	t2999: "Erro de comunicação",
	t2998: "Erro desconhecido",
	t3000: "Detalhes",
	t3001: "Código XML passado aqui",
	t3002: "Atribuir a",
	t3003: "Estado",
	t3004: "Gerenciamento de software",
	t3005: "Arquivo armazenado local",
	t3006: "On-line pela internet",
	t3007: "Certifique-se de que a energia não seja interrompida durante o processo de atualização. Recomenda-se fazer um backup do banco de dados do sistema antes de atualizar seu sistema. Após concluir a(s) atualização(ões) de software, é recomendável inspecionar o status operacional do sistema para confirmar o controle e a operação corretos.",
	t3008: "Nenhum arquivo selecionado",
	t3009: "Selecionar arquivo de software",
	t3010: "Fazendo upload de arquivos",
	t3011: "Verificação de segurança",
	t3012: "Extraindo arquivo",
	t3013: "Verificando arquivo",
	t3014: "Falha no upload do arquivo!",
	t3015: "Falha na verificação de segurança!",
	t3016: "Falha na extração!",
	t3017: "Falha na verificação do arquivo!",
	t3018: "Baixando arquivo: {{fileName}}",
	t3019: "Arquivo baixado com sucesso!",
	t3020: "Falha no Download!",
	t3021: "Fazer upload de arquivos",
	t3022: "Baixar arquivos",
	t3023: "Atualização do SO",
	t3024: "Atualização Edf",
	t3025: "Baixar arquivos",
	t3026: "Infelizmente, o valor do parâmetro selecionado não estava disponível",
	t3027: "Importar",
	t3028: "É necessário no mínimo um arquivo",
	t3029: "O arquivo não é válido",
	t3030: "Gerenciar visualizações gráficas",
	t3031: "Carregar",
	t3032: "Migrar",
	t3033: "Lendo o(s) arquivo(s) gráfico(s), aguarde...",
	t3034: "Importando arquivo gráfico, aguarde...",
	t3035: "Escolha o gráfico",
	t3036: "Relatórios",
	t3037: "Importar banco de dados",
	t3038: "Selecione o arquivo de banco de dados",
	t3039: "Certifique-se de que a energia não seja interrompida durante o processo de reversão. Recomenda-se fazer um backup do banco de dados do sistema antes da reversão do sistema. Após concluir a reversão do software, é recomendável inspecionar o status operacional do sistema para confirmar o controle e a operação corretos.",
	t3040: "O System Manager será reiniciado após a atualização",
	t3041: "Expandir",
	t3042: "Mostrar mensagens de depuração",
	t3043: "Máx. caracteres permitidos -",
	t3044: "Faça o upload do arquivo de atualização",
	t3045: "Versão atual do SW",
	t3046: "Versão atual do Danux",
	t3047: "Versão mais recente do SW",
	t3048: "Última versão do Danux",
	t3049: "Fazendo backup, aguarde",
	t3050: "Instalando o arquivo por favor aguarde",
	t3051: "Instalando atualização",
	t3052: "O dispositivo está reiniciando",
	t3053: "Reiniciando o gerenciador do sistema",
	t3054: "O navegador será atualizado agora",
	t3055: "Software instalado com sucesso",
	t3056: "Falha na atualização, ocorreu o seguinte erro:",
	t3057: "Faça backup da versão atual do software",
	t3058: "Faça backup dos arquivos EDF atuais",
	t3059: "A visualização gráfica foi salva com sucesso",
	t3060: "Aplicar imagem de fundo",
	t3061: "Claro",
	t3062: "Refazer",
	t3063: "Armazenar",
	t3064: "Contratempos da noite do circuito",
	t3065: "Contratempos da noite de sucção",
	t3066: "Versão {{fileName}}",
	t3067: "Para atualizar as unidades selecionadas, pressione 'Instalar'",
	t3068: "Reverter a versão {{updateType}}",
	t3069: "Instalando",
	t3070: "Instale a versão de reversão",
	t3071: "Nenhuma das unidades disponíveis contém qualquer versão de rollback",
	t3072: "Nova versão disponível online",
	t3073: "Carregar arquivo para unidades selecionadas",
	t3074: "Carregar arquivo para a unidade selecionada",
	t3075: "Nenhuma versão de reversão disponível para a unidade",
	t3076: "Download concluído",
	t3077: "Versão atual {{updateType}}",
	t3078: "Versão mais recente do {{updateType}}",
	t3079: "A atualização está em andamento. Tem certeza de que deseja fechar a janela?",
	t3080: "Danux não está atualizado! Atualize o Danux e atualize o software novamente",
	t3081: "Os aplicativos não podem ser rebaixados para esta versão",
	t3082: "Informações do aplicativo",
	t3083: "Notas de lançamento",
	t3084: "Termos e privacidade",
	t3085: "Licenças",
	t3086: "Por favor, vá para configuração para definir horários",
	t3087: "Infelizmente ocorreu um erro ao preparar a migração. Certifique-se de que todas as unidades estejam acessíveis na rede e configuradas corretamente. \n\n O seguinte erro ocorreu:\n {{error}}",
	t3088: "Importar ou migrar um gráfico existente",
	t3089: "Mostrar",
	t3090: "Nome do recurso",
	t3091: "Colapso",
	t3092: "Importação concluída",
	t3093: "Segurança de descarga",
	t3094: "Segurança de sucção",
	t3095: "Descarga Cutina",
	t3096: "Corte de descarga",
	t3097: "Recorte de sucção",
	t3098: "Cutina de Sucção",
	t3099: "Capacidade",
	t3100: "Os arquivos de migração (flp, dpj ou json) não foram encontrados",
	t3101: "Arquivo de análise de tempo limite",
	t3102: "Arquivos e endereços de dispositivos de terceiros",
	t3103: "Rede",
	t3104: "Trancar",
	t3105: "XML",
	t3106: "Selecione o parâmetro a ser exibido",
	t3107: "Parâmetro do dispositivo",
	t3108: "Nenhuma unidade",
	t3109: "Não há unidade(s) encontrada(s) ou carregada(s)",
	t3110: "HACCP",
	t3111: "DE ATIVOS",
	t3112: "Não há dados haccp disponíveis",
	t3113: "Informações da unidade",
	t3114: "Atualizações disponíveis",
	t3115: "Ir para Software",
	t3116: "Não conectado",
	t3117: "O escopo de acesso atual para este aplicativo é ' {{targetLevel}} ', o usuário fornecido está acima desse escopo em termos de direitos de acesso",
	t3118: "Exportando PDF",
	t3119: "Falha na exportação de PDF",
	t3120: "Em grandes conjuntos de dados, a interface pode não responder e demorar um pouco para ser concluída",
	t3121: "Por favor, selecione os horários da lista",
	t3122: "Por favor, vá para a configuração para definir uma programação",
	t3123: "Sucesso",
	t3124: "Aviso",
	t3125: "Informação do Site",
	t3126: "Selecione o tempo e os pontos de dados da lista",
	t3127: "Conectando",
	t3128: "Não foi possível estabelecer conexão com este site, entre em contato com o suporte",
	t3129: "Nenhum dado HACCP estava disponível na data escolhida. Certifique-se de que o histórico e a hora estejam configurados corretamente no menu de configuração",
	t3130: "Relatório de diagnóstico",
	t3131: "Selecione a(s) unidade(s) para exportação do relatório de diagnóstico",
	t3132: "Não disponível neste nível de acesso do usuário",
	t3133: "Registro de diagnóstico",
	t3134: "falha ao baixar o relatório",
	t3135: "Fonte",
	t3136: "tamanho da fonte",
	t3137: "Não há visualizações para migrar",
	t3138: "Baixe a versão Desktop",
	t3139: "Devido a restrições de segurança em navegadores modernos (",
	t3140: "RFC1918",
	t3141: "), infelizmente, não é possível conectar-se a dispositivos em um espaço de rede privada usando HTTP não criptografado. Configure seu gerenciador de sistema para usar HTTPS ou use a versão para desktop",
	t3142: "Não há dados disponíveis para o período selecionado",
	t3143: "Configure seu gerenciador de sistema para usar HTTPS ou use a versão desktop.",
	t3144: "Não foi possível comunicar com o System Manager. Isso significa que seu dispositivo não pode se conectar ao IP ou nome de host do System Manager ou que você pode precisar confiar manualmente no certificado SSL usado no dispositivo.",
	t3145: "Clique no link a seguir para abrir uma nova guia para confiar no certificado e, em seguida, retornar a esta tela.",
	t3146: "Assinatura",
	t3147: "DI - entradas digitais",
	t3148: "AI - entradas analógicas",
	t3149: "Vá para a configuração HACCP",
	t3150: "Sem dados",
	t3151: "O backup é salvo no System Manager para o recurso Rollback",
	t3152: "Selecione o que exibir nas visualizações do site",
	t3153: "Unidade de recursos",
	t3154: "Arquivos gráficos",
	t3155: "Infelizmente aconteceu um erro ao buscar os arquivos edf e as versões do gerenciador do sistema...",
	t3156: "Para selecionar quais arquivos instalar, pressione o botão 'Escolher arquivos'",
	t3157: "Escolher os arquivos",
	t3158: "Removendo backup",
	t3159: "Arquivos de teste",
	t3160: "O backup é salvo no System Manager para o recurso Rollback",
	t3161: "Tamanho instalado",
	t3162: "Novo tamanho",
	t3163: "Data de instalação",
	t3164: "Nova data",
	t3165: "Sensores",
	t3166: "Relés",
	t3167: "Entradas",
	t3168: "Saídas",
	t3169: "Metros",
	t3170: "Genérico",
	t3171: "A senha expirou, digite uma nova senha",
	t3172: "Nova Senha",
	t3173: "Confirme sua senha",
	t3174: "Mudar senha",
	t3175: "A senha deve conter no mínimo 8 caracteres 1 maiúscula, 1 minúscula, 1 número, 1 caractere especial)",
	t3176: "As senhas não coincidem",
	t3177: "A senha não pode ser igual à senha antiga",
	t3178: "Alteração de senha bem-sucedida",
	t3179: "A senha não pode ser alterada. Erro do dispositivo",
	t3180: "Redefinição de senha",
	t3181: "Gerado",
	t3182: "Nome do site",
	t3183: "ID do evento",
	t3184: "Tempo",
	t3185: "Função",
	t3186: "Informações do evento",
	t3187: "Reconhecer alarmes selecionados",
	t3188: "Reconhecer todos os alarmes selecionados",
	t3189: "Importar banco de dados para",
	t3190: "Importando banco de dados",
	t3191: "O tipo de arquivo deve ser .zip",
	t3192: "Selecione o arquivo",
	t3193: "Horário de funcionamento",
	t3194: "Feriados",
	t3195: "Horários de ligar/desligar",
	t3196: "De ativos",
	t3197: "Você tem alterações não salvas. Você tem certeza de que quer sair?",
	t3198: "Infelizmente a migração falhou. O sistema está atualmente ocupado. Por favor, tente novamente mais tarde",
	t3199: "Carregar imagem ajustando-se às dimensões atuais",
	t3200: "Substituir imagem de fundo",
	t3201: "Substitua imagens de fundo por gráficos vetoriais escaláveis.",
	t3202: "Apenas os arquivos SVG com o mesmo nome dos arquivos existentes são substituídos.",
	t3203: "Selecione o(s) arquivo(s) para substituir",
	t3204: "Arquivos de imagem",
	t3205: "não pode ser processado. O arquivo tem formato inválido.",
	t3206: "{{amount}} arquivo(s) substituído(s) com sucesso",
	t3207: "{{amount}} arquivo(s) não foram substituídos",
	t3208: "A vista não tem fundo",
	t3209: "Você pode fazer upload de uma nova ou substituir uma imagem de plano de fundo existente",
	t3210: "Se você estiver substituindo uma imagem de fundo, as posições dos parâmetros podem ser mantidas se:",
	t3211: "Um arquivo png ou jpg tem a mesma resolução que o plano de fundo atual",
	t3212: "Um arquivo svg tem a mesma proporção (mas não necessariamente a mesma resolução)",
	t3213: "O recurso está desativado",
	t3214: "Atualmente mostrando o último valor recebido, atualizando...",
	t3215: "Carregar um arquivo gráfico criado em",
	t3216: "(opcional)",
	t3217: "Imagem de fundo",
	t3218: "Navegar",
	t3219: "Mais...",
	t3220: "Adicione uma imagem de fundo (opcional)",
	t3221: "Arquivo gráfico",
	t3222: "Tempo limite na comunicação",
	t3223: "Nenhuma programação de refrigeração configurada",
	t3224: "Índice",
	t3225: "Modelo",
	t3226: "Feriados",
	t3227: "Dias",
	t3228: "Sobre",
	t3229: "Desligado",
	t3230: "EU IRIA",
	t3231: "Abrir",
	t3232: "Começar",
	t3233: "Fim",
	t3234: "Descrição",
	t3235: "Dia",
	t3236: "Perto",
	t3237: "Arquivo(s) Edf carregado(s) com sucesso. Reiniciando o gerenciador do sistema",
	t3238: "O upload do(s) arquivo(s) Edf foi concluído, o gerenciador do sistema foi redefinido com sucesso",
	t3239: "Erro ao buscar arquivo(s) edf do gerenciador de sistema",
	t3240: "Falha no upload do(s) arquivo(s) Edf. Tente depois de algum tempo",
	t3241: "Nenhuma programação de refrigeração configurada",
	t3242: "Carregar arquivo para o gerenciador de sistema",
	t3243: "Especifique o tipo de dispositivo - afeta os tags de parâmetro necessários para operá-lo",
	t3244: "Especifique como os valores do dispositivo são acessados e qual função eles cumprem",
	t3245: "Crie um alarme com base em um bit de um parâmetro",
	t3246: "Rótulos",
	t3247: "Crie uma lista de rótulos que podem ser atribuídos a um parâmetro",
	t3248: "Grupos",
	t3249: "Especificar grupos de parâmetros",
	t3250: "Número de registro",
	t3251: "Tipo de dados",
	t3252: "Lista de marcadores",
	t3253: "Mínimo",
	t3254: "Predefinição",
	t3255: "Máximo",
	t3256: "Tipo de registro",
	t3257: "O tipo de registro é traduzido para códigos de função correspondentes para leitura e escrita",
	t3258: "Modo de troca",
	t3259: "Para um valor com bytes ABCD enviado ao dispositivo, esta opção troca bytes (BADC), palavras (CDAB) ou ambos (DCBA).",
	t3260: "Somente leitura",
	t3261: "Exploração madeireira",
	t3262: "Habilita o registro de histórico para este parâmetro",
	t3263: "Assuma o texto do parâmetro para o alarme",
	t3264: "Posição do sinalizador de alarme contando a partir deles o bit menos significativo do parâmetro",
	t3265: "Disparar alarme em valor baixo",
	t3266: "Etiqueta",
	t3267: "Esconder",
	t3268: "{{field}} é um campo obrigatório.",
	t3269: "O máximo deve ser maior ou igual ao mínimo",
	t3270: "O valor padrão deve estar em [Mínimo, Máximo]",
	t3271: "O tipo de dados deve ser {{reg}} para o tipo de registro selecionado {{mt}}",
	t3272: "O nome não deve conter sinais delimitadores",
	t3273: "O nome não deve exceder 20 caracteres",
	t3274: "A lista de marcadores não deve estar vazia",
	t3275: "A(s) tag(s) {{taglist}} deve(m) ser atribuída(s) exatamente uma vez.",
	t3276: "O(s) grupo(s) {{grouplist}} contém mais do que o máximo de 50 parâmetros.",
	t3277: "O formulário contém erros. Revise os erros e tente novamente.",
	t3278: "Enviar",
	t3279: "Para ativar o EDF carregado, você deve reinicializar a unidade. Deseja redefinir a unidade agora?",
	t3280: "Redefinir agora",
	t3281: "Redefinir mais tarde",
	t3282: "{{file}} já existe. Você deseja sobrescrever isso?",
	t3283: "Carregar {{what}}",
	t3284: "Rótulos e valores devem ser exclusivos.",
	t3285: "Multi",
	t3286: "O dispositivo lida com vários casos ou pacotes",
	t3287: "Tag",
	t3288: "O Internet Explorer está em fim de vida e não é suportado pelo StoreviewWeb",
	t3289: "Mude para um navegador compatível, Edge, Chrome ou Firefox",
	t3290: "Mais Informações",
	t3291: "Perto",
	t3292: "Falha ao ler os dados, tente novamente",
	t3293: "EDFs de backup em andamento",
	t3294: "Não há visualizações para migrar",
	t3295: "O sistema está configurado com um IP público no campo IP privado",
	t3296: "O sistema está configurado com um IP privado no campo IP público, o sistema pode não estar acessível remotamente ou algumas funções podem não funcionar corretamente",
	t3297: "Você está conectado através de um IP ou nome de host que não é conhecido pelo System Manager, provavelmente através de um serviço proxy, algumas funções podem não funcionar corretamente",
	t3298: "O dispositivo está offline, mostrando o último valor conhecido",
	t3299: "Integração de dispositivos",
	t3300: "As tags {{taglist}} estão duplicadas.",
	t3301: "página não encontrada",
	t3302: "Lamentamos, mas a página solicitada não foi encontrada",
	t3303: "Código de erro: 404",
	t3304: "Ir para a dashboard",
	t3305: "Somente visualização",
	t3306: "Endereço IP interno",
	t3307: "Endereço IP externo",
	t3308: "Nome do domínio",
	t3309: "Sem avisos",
	t3310: "Vários avisos",
	t3311: "Parece que o sistema está configurado com várias unidades, mas não foi possível entrar em contato com uma ou mais unidades escravas, verifique se as configurações estão corretas. Observe que uma configuração de várias unidades não suporta vários nomes de domínio e que cada unidade precisa ser configurada com uma porta distinta ao usar o mesmo domínio",
	t3312: "Somente visualização",
	t3313: "Este elemento não tem associação com nenhum parâmetro e pode ser removido com segurança",
	t3314: "Remover parâmetro",
	t3315: "Cronograma",
	t3316: "Você será desconectado e precisará fazer login com a nova senha",
	t759: "Banco de dados baixado, redefinindo e atualizando a unidade. Isso pode levar vários minutos.",
	t816: "Tipo de Alarme:",
	t818: "Contar :",
	t848: "O arquivo DB não foi criado. Você tem acesso limitado ao diretório instalado. Entre em contato com o administrador do sistema.",
	t3317: "Sem agendamentos para exportar",
	t3318: "Aguardando resposta do sistema",
	t3319: "Nenhuma atividade de rede",
	t3320: "Nome da loja",
	t3321: "Nova versão {{latestVersion}} disponível online",
	t3322: "Porta Externa",
	t3323: "Porta Interna",
	t3324: "Não podemos nos comunicar com o gerente do sistema em",
	t3325: "talvez o certificado precise ser confiável?",
	t3326: "Arquivo deste formato não pode ser removido",
	t3327: "Uso",
	t3328: "Na hora",
	t3329: "Tempo de folga",
	t3330: "Dias da semana",
	t3331: "Estágio de Resfriamento",
	t3332: "Recuperação de calor",
	t3333: "Resfriamento",
	t3334: "Aquecimento",
	t3335: "Aquecimento auxiliar",
	t3336: "Desumidificação",
	t3337: "Internet",
	t3338: "Mensagens",
	t3339: "Contatos",
	t3340: "Comm",
	t3341: "Nenhum item encontrado",
	t3342: "Escreva para pesquisar",
	t3408: "Habilitar tudo",
	t3409: "Desativar tudo",
	t3410: "Normal",
	t3411: "Forte",
	t3412: "Crítico",
	t3413: "Habilitar todos os alarmes",
	t3414: "Pesquisar por alarmes",
	t3415: "Habilitado",
	t3417: "Erro ao buscar alarmes",
	t3418: "Erro ao buscar dispositivos para copiar",
	t3419: "Erro ao verificar o status da cópia",
	t3420: "A pesquisa automática será retomada após um tempo limite máximo de 2 horas ou se o assistente for cancelado ou concluído",
	t3421: "Copiar para o(s) controlador(es)",
	t3422: "Copiando para o(s) dispositivo(s) selecionado(s)",
	t3423: "A função de cópia primeiro copiará as configurações para o banco de dados do System Manager e, em seguida, baixará as configurações para os dispositivos do controlador. Pode levar algum tempo para concluir a ação.",
	t3424: "Nenhum dispositivo selecionado para copiar",
	t3425: "Clique duas vezes para copiar",
	t3426: "Erro ao buscar dispositivos",
	t3427: "Dispositivos para cópia",
	t3428: "Selecione o(s) dispositivo(s) para cópia",
	t3429: "Somente controladores do mesmo tipo serão mostrados aqui",
	t3430: "Processo de cópia bem-sucedido",
	t3431: "Arquivo inválido. Modelo de arquivo esperado:",
	t3432: "Erro ao carregar arquivo de configurações",
	t3435: "Tem certeza de que deseja mover o dispositivo selecionado para este grupo {{toPackGroup}} ?",
	t3343: "Descoberta",
	t3344: "Atualizar tabela",
	t3345: "Escaneando redes, aguarde...",
	t3346: "A tabela de rede é atualizada",
	t3347: "Erro ao atualizar redes",
	t3348: "Erro ao escanear novamente as redes",
	t3349: "Erro ao buscar redes",
	t3350: "Verificação de rede concluída",
	t3351: "Nenhum dispositivo disponível",
	t3352: "Varredura da rede",
	t3353: "Não cfg",
	t3354: "Qualquer reorganização de dispositivos no assistente pode afetar os serviços de monitoramento do Alsense.",
	t3355: "Rede",
	t3356: "Selecione o tipo de barramento de campo",
	t3357: "Se o Modbus/RS485 estiver habilitado, você pode selecionar SLV para o compressor de velocidade variável Danfoss. Ao selecionar SNMP como um barramento local, você será solicitado a inserir um intervalo de endereço de sub-rede válido pelos controles SNMP da Danfoss",
	t3358: "Compressores/Pacotes",
	t3359: "Gerenciar controladores de compressores/pacotes",
	t3360: "Somente dispositivos Pack podem ser gerenciados aqui - não o sistema Rack I/O. Controladores de Pack Multi-Suction compatíveis que foram escaneados aparecerão inicialmente como 0 grupos de sucção. O AK-ST 500 (Service Tool) é necessário para configurar o controlador Pack.",
	t3361: "Quantidade a copiar",
	t3362: "Reduzir o grupo de sucção configurado anteriormente também removerá quaisquer controles de evaporação associados a esse grupo de sucção.",
	t3363: "O número de grupos de sucção não pode ser zero, o número mínimo de grupos de sucção deve ser pelo menos um.",
	t3364: "Erro ao buscar compressores/pacotes",
	t3365: "Nenhum compressor/pacote disponível",
	t3366: "Dispositivos on-line",
	t3367: "Dispositivos Off-line",
	t3369: "Adicionar pacote",
	t3370: "Se selecionar Modbus/RS485, você verá opções adicionais para SLV/ECFan, elas são usadas para refrigeração e podem ser selecionadas como Não.",
	t3371: "Adicionar circuito",
	t3372: "Adicione, copie ou remova dispositivos. Observe que nem todos os tipos de dispositivos permitem edições de todos os campos e os endereços dos pontos de E/S são definidos na configuração.",
	t3373: "Gerenciar controladores de caso",
	t3374: "Adicionar um dispositivo",
	t3375: "Gerenciar controladores de compressor/pacote",
	t3376: "Erro no acesso aos dados",
	t3377: "O layout HVAC foi aplicado com sucesso",
	t3378: "Layout HVAC",
	t3379: "Tem certeza de que deseja sair desta página?",
	t3380: "Suas configurações podem ser perdidas",
	t3381: "Por favor configure um dispositivo",
	t3382: "Sair da configuração rápida",
	t3383: "Tem certeza de que deseja sair da configuração rápida?",
	t3384: "Portais (AK-PI200)",
	t3385: "Selecione os gateways encontrados",
	t3386: "Endereço IP",
	t3387: "Controlador de fonte",
	t3388: "Configurações de origem",
	t3389: "Alarmes de origem",
	t3390: "Gráfico de origem",
	t3392: "Gráfico",
	t3393: "Dispositivos",
	t3394: "A sondagem automática é suspensa durante a fase de cópia. O interruptor principal do(s) dispositivo(s) de origem e destino será desligado e retornará ao estado original, assim que a cópia for concluída. Certifique-se de verificar o interruptor principal após o assistente de cópia ser concluído. Se algum dos dispositivos selecionados estiver em alarme ativo, qualquer modificação na ação de alarme para esse ponto específico não será modificada. A configuração original permanecerá.",
	t3395: "Selecione um dispositivo de origem para copiar as configurações",
	t3396: "Na hora certa",
	t3397: "A função de cópia copiará os pontos de gráfico disponíveis até que a capacidade da unidade seja atingida. Vá para Configuração para ver o número de pontos definidos",
	t3398: "Gráficos de dispositivos de origem",
	t3399: "Configurar pontos de gráfico para o controlador de origem",
	t3400: "Habilitar HACCP para registrar os pontos em um relatório de temperatura HACCP",
	t3401: "Pesquisar por pontos",
	t3402: "Não há dados disponíveis",
	t3403: "Erro ao buscar pontos do gráfico",
	t3404: "Desabilitado",
	t3405: "Alarmes de dispositivos de origem",
	t3406: "Configurar detalhes do alarme no controlador de origem",
	t3407: "Nome do alarme",
	t3436: "O dispositivo será removido do grupo atribuído [ {{fromPackGroup}} ]",
	t3437: "Layout Refrigeração",
	t3438: "O layout de refrigeração foi aplicado com sucesso",
	t3439: "Reatribuir",
	t3440: "Atribuir controles de caso aos controles de pacote",
	t3441: "Atribuir controles de caso (de casos disponíveis) aos controles de compressor/pacote, formando grupos de sucção",
	t3442: "Parâmetros do dispositivo",
	t3443: "Selecione parâmetros para cópia",
	t3444: "Ao trabalhar em um dispositivo ativo (on-line), pode haver um atraso nas configurações devido à comunicação com o dispositivo.",
	t3445: "Selecione alarmes para cópia",
	t3446: "Gráfico de dispositivo",
	t3447: "Selecione pontos do gráfico para cópia",
	t3448: "Salvar as configurações atuais do dispositivo em um arquivo",
	t3449: "Você pode salvar as configurações atuais do dispositivo em um arquivo que pode ser usado para configurar dispositivos posteriormente. Se desejar sair do assistente após salvar o arquivo, use o ícone de saída. Se estiver trabalhando com dispositivos conectados, sair garantirá a pesquisa de rede e o interruptor principal do dispositivo retornará ao status correto.",
	t3450: "Arquivo(s) de configuração salvo(s) com sucesso",
	t3451: "Deseja continuar ou sair da Configuração rápida?",
	t3452: "Salvando arquivo(s)",
	t3453: "Erro ao buscar configurações",
	t3454: "Configurações do dispositivo de origem",
	t3455: "Configurar parâmetros do dispositivo no controlador de origem",
	t3456: "Nenhum dos campos de senha deve estar vazio",
	t3457: "Recentes",
	t3458: "Livro de endereços",
	t3459: "Último acesso",
	t3460: "Faça backup do Catálogo de Endereços regularmente.",
	t3461: "O catálogo de endereços foi importado com sucesso",
	t3462: "Importar catálogo de endereços",
	t3463: "Exportar catálogo de endereços",
	t3464: "Adicionar local",
	t3465: "Editar site",
	t3466: "Excluir site",
	t3467: "Você precisa criar uma senha para o Catálogo de Endereços antes de começar a usar o Catálogo de Endereços",
	t3468: "O site foi adicionado com sucesso ao seu catálogo de endereços",
	t3469: "Já no catálogo de endereços",
	t3470: "Adicionar ao catálogo de endereços",
	t3471: "Entrar no site",
	t3472: "As informações do site foram atualizadas com sucesso",
	t3474: "Notificação",
	t3475: "O site foi removido com sucesso do seu catálogo de endereços",
	t3476: "O site foi atualizado com sucesso",
	t3477: "Formato inválido do catálogo de endereços",
	t3478: "Ações",
	t3479: "Não disponível quando offline",
	t3480: "Falha ao carregar o banco de dados S55",
	t3481: "Carregando banco de dados S55",
	t3482: "Protocolo",
	t3483: "O usuário não tem permissão para ver informações do software",
	t3484: "A senha deve ser alfanumérica ou apenas numérica",
	t3485: "Você precisa criar uma senha do Catálogo de Endereços para acessar o Catálogo de Endereços",
	t3486: "A senha do catálogo de endereços não está correta",
	t3487: "O site já está presente no catálogo de endereços",
	t3488: "Crie uma senha do Catálogo de Endereços para usar o Catálogo de Endereços. A senha deve ser alfanumérica ou numérica somente",
	t3489: "Digite sua senha do catálogo de endereços",
	t3490: "Nenhum dispositivo online disponível para cópia",
	t3491: "Criar senha do catálogo de endereços",
	t3492: "Atualizar senha do catálogo de endereços",
	t3493: "A senha do site não pode estar vazia",
	t3494: "Digite uma senha do catálogo de endereços que está sendo importado",
	t3495: "A senha do catálogo de endereços foi criada com sucesso",
	t3496: "A senha do catálogo de endereços foi atualizada com sucesso",
	t3497: "A senha do catálogo de endereços que está sendo importado não está correta",
	t3498: "Por favor, insira a senha do site",
	t3499: "Limpar catálogo de endereços",
	t3500: "Pesquisar sites",
	t3501: "Selecione o arquivo do catálogo de endereços para importar",
	t3502: "Selecione o arquivo de chave para descriptografar o catálogo de endereços",
	t3503: "Pressione exportar para baixar o catálogo de endereços criptografado",
	t3505: "Com criptografado",
	t3506: "senhas não criptografadas",
	t3507: "Chave secreta inválida",
	t3508: "Falha na importação. Certifique-se de ter importado a chave adequada",
	t3509: "Nenhuma versão disponível",
	t3510: "Serviços Open Alsense (requer assinatura)",
	t3511: "Por favor, revise os termos do EULA cuidadosamente. Se você não puder aceitar os termos, você não tem permissão para usar o aplicativo.",
	t3512: "O catálogo de endereços e a senha do catálogo de endereços foram apagados com sucesso",
	t3513: "(obrigatório)",
	t3515: "O catálogo de endereços está vazio",
	t3516: "A senha do catálogo de endereços não pode estar vazia",
	t3519: "Ao limpar o Catálogo de Endereços, você também removerá sua senha.",
	t3520: "Fora do intervalo. Deve estar entre 0 e {{maxAddr}}",
	t3521: "Pressione 'Exportar' para baixar todas as visualizações gráficas armazenadas no Gerenciador do Sistema",
	t3522: "Pressione 'Download' para baixar um relatório do sistema",
	t3523: "Somente a unidade mestre está disponível em uma conexão WiFi direta",
	t3524: "Não há visualizações gráficas locais. Adicione novas visualizações ou importe visualizações existentes",
	t3525: "Não há visualizações gráficas remotas. Tente pesquisar com nome diferente",
	t3526: "Não há visualizações gráficas remotas. Adicione novas, importe ou migre visualizações existentes",
	t3527: "Visualizações locais ( {{totalFiles}} de 5)",
	t3528: "{{totalFiles}} gráficos locais de um máximo de 5",
	t3529: "Visualizações Remotas",
	t3530: "O arquivo não é um bitmap.",
	t3531: "Comprimento do bitmap incorreto.",
	t3532: "Tamanho do cabeçalho do bitmap incorreto.",
	t3533: "Tamanho do bitmap incorreto.",
	t3534: "Erro no tamanho da paleta.",
	t3535: "Arquivo .bmp incompatível",
	t3536: "Erro ao excluir arquivo gráfico local",
	t3537: "Erro ao duplicar arquivo gráfico local",
	t3538: "Erro ao exportar arquivo gráfico local",
	t3539: "Excluir gráfico",
	t3540: "Excluir um arquivo gráfico local",
	t3541: "A exclusão do arquivo gráfico local está em andamento",
	t3542: "Arquivo gráfico local excluído com sucesso. Reiniciando o System Manager",
	t3543: "Exclusão do arquivo gráfico local concluída. O System Manager foi reiniciado com sucesso",
	t3544: "Gráfico duplicado",
	t3545: "Duplicar um arquivo gráfico local",
	t3546: "A duplicação do arquivo gráfico local está em andamento",
	t3547: "Arquivo gráfico local duplicado com sucesso. Reiniciando o System Manager",
	t3548: "Duplicação do arquivo gráfico local concluída. O System Manager foi reiniciado com sucesso",
	t3549: "Exportar gráfico",
	t3550: "Exportar um arquivo gráfico local",
	t3551: "A exportação do arquivo gráfico local está em andamento",
	t3552: "Arquivo gráfico local exportado com sucesso",
	t3553: "O Gerenciador do Sistema será reiniciado nesta ação",
	t3554: "Tem certeza de que deseja excluir",
	t3555: "Tem certeza de que deseja duplicar",
	t3556: "Pressione 'Exportar' para baixar todas as visualizações gráficas locais armazenadas no Gerenciador do Sistema",
	t3557: "A exportação de arquivos gráficos locais está em andamento",
	t3558: "Arquivos gráficos locais exportados com sucesso",
	t3559: "Código do produto",
	t3560: "Obtenha mais informações sobre este produto",
	t3561: "Fã ecológico",
	t3562: "Abertura de válvula",
	t3563: "Superaquecimento",
	t3564: "Pressão do evaporador",
	t3565: "Temperatura do evaporador",
	t3566: "Recortar",
	t3567: "Corte",
	t3568: "Porta do gabinete",
	t3569: "Temperatura de sucção",
	t3570: "Calor ferroviário",
	t3571: "Limite máximo de arquivos permitidos atingido. Desmarque os arquivos para importar",
	t3572: "Por favor, exclua os arquivos existentes no gerenciador do sistema para importar os arquivos",
	t3573: "Arquivo gráfico local importado com sucesso. Reiniciando o System Manager",
	t3574: "A importação de arquivo(s) gráfico(s) local(ais) foi concluída. O System Manager foi reiniciado com sucesso",
	t3575: "Limite máximo de arquivos permitidos atingido. Excluir arquivo(s) para importar",
	t3576: "O máximo de arquivos gráficos permitidos é 5",
	t3577: "Arquivo gráfico local carregado com sucesso. Reiniciando o System Manager",
	t3578: "Upload do arquivo gráfico local concluído. O System Manager foi reiniciado com sucesso",
	t3579: "Formato de arquivo inválido. Carregar arquivo {{supportedFileFormats}}",
	t3580: "A dimensão do arquivo deve ser {{maxWidthAllowed}} x {{maxHeightAllowed}} pixels para telas locais",
	t3581: "Visualização remota",
	t3582: "Visão local",
	t3583: "Página inicial da Storeview Web",
	t3584: "Termos do EULA",
	t3585: "Segurança",
	t3586: "Níveis de aplicação de segurança",
	t3587: "Compatível com versões anteriores (nível de segurança mais baixo - assumindo que o uso de autenticação de cabeçalho esteja desabilitado)",
	t3588: "As solicitações SvW/XML contêm nome de usuário e senha.",
	t3589: "Nenhuma higienização de solicitações, mesma segurança das versões anteriores",
	t3590: "Nenhuma alteração necessária em conexões SvW ou XML existentes",
	t3591: "Permissivo (pode ser usado como uma transição para o nível estrito)",
	t3592: "As solicitações XML podem conter nome de usuário e senha, e/ou um token de sessão. O token de sessão é recebido na resposta da solicitação de login.",
	t3593: "Se o token de sessão for fornecido no cabeçalho AKSM-auth, nomes de usuário e senhas serão ignorados",
	t3594: "A solicitação XML é higienizada, um aviso é retornado na resposta da solicitação se algum problema for descoberto",
	t3595: "Tome nota destes avisos em preparação para mudar para o modo estrito",
	t3596: "Se o token de sessão não for usado, a expiração da sessão não poderá ser aplicada",
	t3597: "Já suportado na IU remota (SvW)",
	t3598: "Alterações possíveis/necessárias da perspectiva XML de terceiros",
	t3599: "Nenhum uso de token de sessão - nenhuma alteração necessária",
	t3600: "Uso do token de sessão - remova o nome de usuário/senha do corpo e observe os avisos em preparação para o Strict",
	t3601: "Rigoroso (nível de segurança mais alto)",
	t3602: "As solicitações XML não podem conter nomes de usuários e senhas, mas devem fornecer o token de sessão no cabeçalho AKSM-auth. Se um problema for descoberto na solicitação, ela será rejeitada",
	t3603: "Já suportado em (SvW)",
	t3604: "Mudanças necessárias da perspectiva de terceiros",
	t3605: "nome de usuário e senha devem ser removidos da carga útil da solicitação XML e o token de sessão deve ser colocado em um cabeçalho CORS chamado AKSM-auth",
	t3606: "Falha de login Back-off",
	t3607: "Quanto tempo (segundos) um usuário remoto espera para tentar fazer login novamente após uma tentativa com falha. O tempo de espera aumenta cumulativamente com cada tentativa com falha. Após 10 tentativas, o IP do qual o usuário tentou fazer login é bloqueado por 24 horas. Se definido como 0, o back-off de falha de login é desabilitado",
	t3608: "Padrão 30s (máx. 600s / 0 = desabilitado)",
	t3609: "Tentativas gratuitas",
	t3610: "Quantas tentativas de login são permitidas antes que o recuo seja aplicado",
	t3611: "Padrão 2 (máx. 5)",
	t3612: "Bloquear",
	t3613: "Se habilitado, o Usuário + IP são bloqueados durante o back-off, caso contrário, o Usuário é bloqueado",
	t3614: "'Usuário' padrão (Usuário + IP / Usuário)",
	t3615: "Sessões por usuário",
	t3616: "Uma sessão é quando um usuário efetua login com sucesso na unidade e está dentro da interface remota (SvW)",
	t3617: "Número de Sessões ativas que um usuário pode ter. Se definido como 0, um usuário pode ter a quantidade máxima de Sessões (30)",
	t3618: "Padrão 0",
	t3619: "Expiração da sessão",
	t3620: "Quantos segundos após uma sessão ociosa expirar. Se uma sessão expirar, um novo login será necessário",
	t3621: "Padrão 900s (máx. 3.600)",
	t3622: "Autenticação de Cabeçalho",
	t3623: "Ao habilitar HTTPS e autenticação de cabeçalho, todas as solicitações XML de terceiros devem passar credenciais de nome de usuário/senha no cabeçalho (esquema de autenticação básico)",
	t3624: "Habilitar 'Use Header Authentication' requer que o sistema seja reinicializado. Use o botão 'Press to Initialize' para executar esta ação",
	t3625: "Pequeno",
	t3626: "Grande",
	t3627: "Salvar gráfico",
	t3628: "Salvar um arquivo gráfico local",
	t3629: "Arquivo gráfico local excluído com sucesso. O carregamento do arquivo gráfico local está em andamento",
	t3630: "O arquivo gráfico local foi carregado com sucesso. Reiniciando o System Manager",
	t3631: "O salvamento do arquivo gráfico local foi concluído. O System Manager foi reiniciado com sucesso",
	t3632: "Tem certeza de que deseja salvar?",
	t3633: "Falha na análise",
	t3634: "Não foi possível analisar o arquivo viz",
	t3635: "Não foi possível analisar o arquivo vz2",
	t3636: "Falha na abertura do arquivo Viz:",
	t3637: "Os arquivos de migração (vz2, dpj ou json) não puderam ser encontrados",
	t3638: "Os arquivos de migração (ou seja) não puderam ser encontrados",
	t3639: "O System Manager reiniciará com esta ação. O System Manager está no modo permissivo ou estrito, então o token de sessão expirará na reinicialização e você será desconectado do sistema",
	t3643: "Pressão de descarga",
	t3644: "Potência total kW",
	t3645: "KVAR total",
	t3646: "Total Kvarh+",
	t3647: "Demanda",
	t3648: "Fator de potência",
	t3649: "Cor da fonte",
	t3650: "Cor de fundo",
	t3651: "O catálogo de endereços foi exportado com sucesso",
	t3652: "Modo de controle",
	t3653: "Sc3 Temperatura externa",
	t3654: "Temperatura de descarga",
	t3655: "Capacidade de funcionamento do condensador",
	t3656: "Nenhum dado a ser exportado",
	t3657: "A senha do usuário em {{unit}} expirou",
	t3658: "Não é possível efetuar login em {{unit}} com as credenciais fornecidas.",
	t3659: "Atualize a versão do firmware ou altere o nível de aplicação de segurança para {{unit}} para obter suporte ao token de sessão.",
	t3661: "V3gc",
	t3662: "Seção B",
	t3663: "Seção C",
	t3664: "Controle de superaquecimento adaptativo",
	t3665: "Razão do status de controle",
	t3666: "O usuário não pode efetuar login. O endereço da unidade não está definido na configuração do proxy",
	t3667: "O protocolo de comunicação não está especificado na configuração do proxy. Pode levar a erros",
	t3668: "Informações de segurança de TI",
	t3669: "Em conformidade com os padrões de segurança, o Gerenciador do Sistema agora usa como padrão o Controle de sessão estrito, o que significa que qualquer conexão remota/serviço remoto baseado em XML não funcionará mais, a menos que tokens de sessão sejam aplicados.",
	t3670: "A Danfoss recomenda continuar no modo Strict, mas o modo de compatibilidade com versões anteriores ainda está disponível.",
	t3671: "Observe que isso pode ser alterado no menu Segurança da configuração e não tem impacto no controle local do System Manager nem na operação do site.",
	t3672: "Veja mais informações sobre o controle de sessão",
	t3673: "Modo reverso",
	t3674: "Modo estrito",
	t3675: "Ao clicar no modo de compatibilidade com versões anteriores, todos os Gerenciadores de Sistema aplicáveis com firmware atualizado serão definidos para o modo de compatibilidade com versões anteriores, reiniciados e o aplicativo será desconectado.",
	t3676: "O número máximo de grupos de sucção permitidos é 12",
	t3677: "Ao atualizar com sucesso as unidades selecionadas de 3.3.0 para 4.X ou superior, o usuário será desconectado automaticamente ao clicar no botão Concluir na etapa final.",
	t3678: "Multi case para controladores AK2 (Pack/Case) não são suportados",
	t3679: "Em conformidade com os padrões de segurança introduzidos com a versão 4.0, o System Manager assumirá como padrão o controle de sessão Strict, o que significa que qualquer conexão remota/serviço remoto baseado em XML não funcionará mais a menos que tokens de sessão sejam aplicados. Além disso, a comunicação será ajustada automaticamente para HTTPS ao atualizar para a versão 4.0. A Danfoss recomenda continuar no modo Strict, mas o modo de compatibilidade com versões anteriores está disponível, o que definirá o sistema de volta para as configurações aplicadas até agora. Observe que se você estiver fazendo esta atualização remotamente em uma unidade HTTP, certifique-se de que sua configuração de TI esteja preparada para HTTPS (porta 443) ou tenha pessoal disponível no local.",
	t3680: "Encontre mais informações sobre a nova implementação de segurança aqui",
	t3681: "O acesso à versão http deste site pode estar bloqueado devido às restrições do seu navegador. Visite nossa página de FAQ para obter instruções sobre como resolver esse problema ou baixe a versão desktop do StoreView Web.",
	t3682: "Perguntas frequentes do gerente do sistema",
	t3683: "u17 Ther. ar",
	t3684: "u91 Temperatura de corte.",
	t3685: "u00 Ctrl. estado",
	t3686: "u12 S3 temperatura do ar.",
	t3687: "u16 S4 temperatura do ar.",
	t3688: "u23 EEV OD %",
	t3689: "Superaquecimento sub-21",
	t3690: "u22 SuperaquecimentoRef",
	t3691: "u26 EvapTemp Te",
	t3692: "S2 Temperatura de saída do gás.",
	t3693: "Stw8",
	t3694: "Sd-MT",
	t3695: "Status do Tw",
	t3696: "Ativar Tw",
	t3697: "Bomba tw funcionando",
	t3698: "Stw3",
	t3699: "Stw4",
	t3700: "Stw2",
	t3701: "Status do controle de Rcv.",
	t3702: "Deslocamento de sucção",
	t3703: "Modo de controle Vrec",
	t3704: "Status do Vrec",
	t3705: "Razão do status Ctrl",
	t3706: "Status do controle HP",
	t3707: "Pgc máx.",
	t3708: "Limite máximo de PGC P-ban",
	t3709: "Pgc mín.",
	t3710: "Capacidade do ejetor de vapor",
	t3711: "Capacitor ejetor de líquido",
	t3712: "Temperatura do ar de referência.",
	t3713: "Temperatura do ar de suprimento.",
	t3714: "Retorno de ar",
	t3715: "Ponto de orvalho da zona",
	t3716: "Umidade da zona",
	t3717: "Temperatura externa.",
	t3718: "Nível de CO2 da zona",
	t3719: "Temperatura da zona.",
	t3720: "Referência de condição",
	t3721: "Status de controle de cond.",
	t3722: "Estado de controle de cond.",
	t3723: "Controle de comando.",
	t3724: "Cond. tampa solicitada.",
	t3725: "Estado V3gc",
	t3726: "V3gc OD",
	t3727: "Temperatura cond.",
	t3728: "Status de RH",
	t3729: "Habilitação de RH",
	t3730: "Solicitação do consumidor de RH",
	t3731: "Shr3",
	t3732: "Shr4",
	t3733: "Shr2",
	t3734: "Shr8",
	t3735: "Ref. de sucção To-MT",
	t3736: "Status de controle MT",
	t3737: "Zona atual MT",
	t3738: "Para optar por compensar MT",
	t3739: "Capacidade de execução MT",
	t3740: "Cap. solicitado. MT",
	t3741: "Hora do próximo passo MT",
	t3742: "Po-MT",
	t3744: "Temperatura de sucção To-MT",
	t3745: "Energia ativa total",
	t3746: "Pico de demanda",
	t3747: "Fator de potência total",
	t3748: "Volts da fase A",
	t3749: "Voltagem da fase B",
	t3750: "Fase C volts",
	t3751: "Amplificadores de fase A",
	t3752: "Amplificadores de fase B",
	t3753: "Amplificadores de fase C",
	t3754: "Potência ativa total",
	t3755: "Temperatura de sucção To-LT",
	t3756: "Temperatura de sucção para TI",
	t3757: "Referência de sucção LT",
	t3758: "Status de controle LT",
	t3759: "Zona atual LT",
	t3760: "Para optar por compensar LT",
	t3761: "Capacidade de execução LT",
	t3762: "Cap. solicitado. LT",
	t3763: "Hora do próximo passo LT",
	t3764: "Po-LT",
	t3765: "SD-LT",
	t3766: "Referência de sucção TI",
	t3767: "Status de controle de TI",
	t3768: "Zona atual de TI",
	t3769: "Capacidade de execução de TI",
	t3770: "Capacidade solicitada de TI",
	t3771: "Hora do próximo passo TI",
	t3772: "Tn Po-TI",
	t2773: "Sd-TI",
	t2774: "Início da Comp. de TI",
	t3774: "Fora do intervalo. Deve estar entre {{minAddr}} e {{maxAddr}}",
	t3775: "Operação desabilitada para este dispositivo",
	t3776: "Após esta ação, o aplicativo será desconectado, o que pode levar alguns instantes.",
	t3777: "Os arquivos de migração (flp ou json) não puderam ser encontrados",
	t3778: "Sondagem",
	t3779: "A votação está suspensa no momento.",
	t3780: "Você deseja retomar a votação?",
	t3781: "Medidor de energia",
	t3782: "Vários nós selecionados",
	t3783: "Ampliar para nó",
	t3784: "Unidades de medida",
	t3785: "Arquivo(s) exportado(s) com sucesso",
	t3786: "O endereço não pode ser negativo",
	t3787: "Redefinir zoom",
	t3788: "Erro ao buscar etapas do padrão de preparação",
	t3789: "Erro ao salvar etapas do padrão de preparação.",
	t3790: "Etapas do padrão de preparação salvas com sucesso.",
	t3791: "Padrão de preparação",
	t3792: "Pressione os ícones descarregados para habilitar/desabilitar os descarregadores associados aos compressores.",
	t3793: "Dados ausentes",
	t3794: "Os seguintes parâmetros não retornaram nenhum ponto de dados.",
	t3795: "A confiança HTTPS/SSL foi bem-sucedida.",
	t3796: "Agora você pode efetuar login no StoreView Web Browser.",
	t3797: "Redirecionando para o navegador StoreView em {{timeLeft}} segundos...",
	t3798: "Votação suspensa no momento!",
	t3799: "Alarmes limpos (registros completos do sistema)",
	t3800: "O número máximo de HVAC permitido é 45.",
	t3801: "Falha ao obter o log de alterações",
	t3802: "Conectando ao armazenamento...",
	t3803: "Tempo limite de conexão de armazenamento SCADA",
	t3804: "Falha ao conectar ao armazenamento SCADA",
	t3805: "Data de término",
	t3806: "Selecione a(s) unidade(s) para download do relatório"
};
var pr = {
	lang: lang$7
};

var lang$8 = {
	t0: "Alarmes Ativos",
	t1: "Alarmes Apagados",
	t2: "Host",
	t3: "ID",
	t4: "Nome Controlador",
	t5: "Editor de Notas",
	t6: "Não esta autorizado a reconhecer o alarme",
	t7: "Tipo Alarme",
	t8: "Ocorrido",
	t9: "Reconhecido",
	t10: "Alarme(s) Ativo(s)",
	t11: "Apagados",
	t12: "Cálculos",
	t13: "Alarme(s) Resolvido(s)",
	t14: "Silenciar/Reconhecer",
	t15: "Reconhecer todos os alarmes desconhecidos",
	t16: "Falha na Autorização",
	t17: "erro",
	t18: "Controle Manual",
	t19: "Visualizar",
	t20: "Resumo de Dados",
	t21: "Vista Técnica",
	t22: "Gráficos",
	t23: "Nodo",
	t24: "Mod",
	t25: "Gráfico (Po &amp; Pc)",
	t26: "(não existem alarmes)",
	t27: "Alarmes Contr.",
	t28: "Ver controlador anterior",
	t29: "Ver próximo controlador",
	t30: "Erro na leictura alarmes",
	t31: "Alarmes Activos",
	t32: "Alarme activo",
	t33: "Não existem alarmes",
	t34: "Detalhes",
	t35: "Ponto",
	t36: "Histórico de Gráficos",
	t37: "Programas",
	t38: "Hora do início",
	t39: "Hora de finalização",
	t40: "dias",
	t41: "Feriados",
	t42: "Estado",
	t43: "Seleccionar pontos",
	t44: "OK",
	t45: "Cancelar",
	t46: "Iniciar",
	t47: "Fim",
	t48: "Start date",
	t49: "Taxa amostral",
	t50: "Pontos não selecionados",
	t51: "Sinc",
	t52: "seg",
	t53: "min",
	t54: "hora",
	t55: "dia",
	t56: "Tipo de Endereço",
	t57: "Ender.",
	t58: "Localização:",
	t59: "Introduza Novo Valor",
	t60: "Atualizar",
	t61: "Seleccionar pontos",
	t62: "Seleccionar Novo Valor",
	t63: "Fora",
	t64: "on",
	t65: "Actualizando Pontos...",
	t66: "Erro ao actualizar dados...",
	t67: "Histórico não configurado...",
	t68: "Erro ao actualizar dados dos pontos...",
	t69: "Actualizando histórico para",
	t70: "Erro ao actualizar histórico",
	t71: "Nome Usuário",
	t72: "Password",
	t73: "Endereço IP/URL:",
	t74: "OK",
	t75: "Cancelar",
	t76: "Nome",
	t77: "Valor",
	t78: "Erro ao actualizar lista de Usuário",
	t79: "Introduza um Novo Endereço",
	t80: "Falha ao ler lista de Usuário, por favor verifique o endereço IP/URL",
	t81: "Cód. Autor ",
	t82: "Código da Conta",
	t83: "Seleccione a Nova Data e Hora",
	t84: "Seleccione o Novo Periodo",
	t85: "Operação Falhada",
	t86: "Introduza a Informação de Autorização",
	t87: "HVAC",
	t88: "Ir!",
	t89: "Controle Degelo",
	t90: "Iniciar DegeloManual",
	t91: "Controle de Iluminação",
	t92: "Controle de Iluminação Manual On",
	t93: "Controle de Iluminação Manual Off",
	t94: "Controle de Limpeza de Evaporador",
	t95: "Controle de Limpeza de Evaporador  Manual On",
	t96: "Controle de Limpeza de Evaporador  Manual Off",
	t97: "Int. Ppal",
	t98: "Interruptor Principal Manual On",
	t99: "Interruptor Principal Manual Off",
	t100: "Controle de Desligamento",
	t101: "Desligar Controlador",
	t102: "Ligar Controlador",
	t103: "Controle de Ajuste Noturno",
	t104: "Ajuste Noturno ligado",
	t105: "Ajuste Noturno desligado",
	t106: "Mensagem Enviada...",
	t107: "Controle Manual",
	t108: "Fechar",
	t109: "Iluminação",
	t110: "Energia/Miscelan",
	t111: "Visão geral",
	t112: "Selecione a unidade AK255 para visualizar.",
	t113: "Carregando...",
	t114: "de",
	t115: "Todos os AK255s",
	t116: "Anterior",
	t117: "Páginas",
	t118: "Próximo",
	t119: "Parâmetro",
	t120: "Medidas",
	t121: "Atualizando dados...",
	t122: "Erro ao ler a seção",
	t123: "Temperatura",
	t124: "Atraso após Degelo",
	t125: "Alarme se abaixo",
	t126: "Alarme se acima",
	t127: "Monitoriz.",
	t128: "Deslig.",
	t129: "Ativo",
	t130: "Host",
	t131: "Ender.",
	t132: "Descrição",
	t133: "Valor Atual",
	t134: "Estado Atual",
	t135: "Controladores",
	t136: "Unidade",
	t137: "Existe um Alarme Ativo",
	t138: "Existem Alarmes Ativos!",
	t139: "Entradas Digitais",
	t140: "Relés saída",
	t141: "Ents Sensores",
	t142: "Saídas Variáveis",
	t143: "Controladores gerais",
	t144: "Outros",
	t145: "Ponto de Visualização ",
	t146: "Vista da Instalação",
	t147: "Sair do Sistema",
	t148: "Iniciar Sessão",
	t149: "Terminar Sessão",
	t150: "Refrigeração",
	t151: "Vista de Controladores",
	t152: "Alarmes",
	t153: "Histórico",
	t154: "Visão geral do CS",
	t155: "Vista da Instalação",
	t156: "Configuração",
	t157: "Vista Site",
	t158: "Layout da Instalação",
	t159: "Início",
	t160: "Alarme",
	t161: "Setpoint",
	t162: "DetalhesCont.",
	t163: "Ajuste",
	t164: "Seleccionar Data",
	t165: "Informação",
	t166: "Reg. Alt.",
	t167: "Cálculos",
	t168: "Gestão Ficheiros",
	t169: "Manual e Ajuda",
	t170: "Informação",
	t171: "Traductor",
	t172: "Guardar no Servidor",
	t173: "Actualizar Ficheiros de Idioma",
	t174: "Esta operação irá actualizar os ficheiros de idioma na unidade. Deseja continuar ?",
	t175: "Exportar Histórico",
	t176: "Selecionar Novo Valor",
	t177: "mínimo",
	t178: "máximo",
	t179: "Não Existem Alarmes Activos!",
	t180: "Não Pode Ser Alterado!",
	t181: "Por Favor Aguarde...",
	t182: "Comando Enviado!",
	t183: "A operação falhou, tente novamente!",
	t184: "Operação bem sucedida!",
	t185: "Operação não autorizada!",
	t186: "Tem certeza de que deseja continuar esta operação?",
	t187: "EVAP.",
	t188: "CENTRAL",
	t189: "Monotorização de Entradas",
	t190: "MISCELA.",
	t191: "Alarmes reconhecidos",
	t192: "Apagar os Alarmes Reconhecidos",
	t193: "Apagar Todos os Alarmes Reconhecidos",
	t194: "Não esta autorizado a reconhecer alarmes",
	t195: "Não Autorizado",
	t196: "Deseja reconhecer este alarme?",
	t197: "Confirme para reconhecer o alarme",
	t198: "Por favor inicie a sessão",
	t199: "Por favor seleccione um alarme para apagar",
	t200: "Confirme para apagar alarmes",
	t201: "Tem certeza de que deseja apagar este alarme?",
	t202: "Não esta autorizado a apagar alarmes",
	t203: "Tem certeza de que deseja apagar todos os alarmes?",
	t204: "Os alrames seleccionados não podem ser apagados",
	t205: "Ajuste de Alarme",
	t206: "Deve estar autorizado como Supervisor para realizar esta operação",
	t207: "Não foram recebidos dados da unidade",
	t208: "Seleccione uma unidade",
	t209: "Por favor seleccione uma unidade da lista",
	t210: "Número de Alarmes",
	t211: "Estado Alarme",
	t212: "Miscelaneos",
	t213: "Apagar Alarme Seleccionado",
	t214: "Por Favor Confirme",
	t215: "Falha na comunicação com a unidade, por favor verifique a ligação de rede.",
	t216: "Alarme de Degelo",
	t217: "Alarme Digital",
	t218: "Estado Entrada Digital",
	t219: "Entrada Degelo",
	t220: "Entrada Limpeza Evaporador",
	t221: "Entrada Digital",
	t222: "Guardando ficheiros de idioma",
	t223: "Unids",
	t224: "Não existem controladores configurados no sistema, Website Inactivo.",
	t225: "Aviso!",
	t226: "Seleccione Hora",
	t227: "Comunicações",
	t228: "Descarregar Ficheiro de Ajustes",
	t229: "Por favor aguarde enquanto é criado o ficheiro de ajustes...",
	t230: "Descarregar Relatório",
	t231: "Estado Alarmes/Serviço",
	t232: "Por favor aguarde enquanto actaliza dados...",
	t233: "Sessão Iniciada",
	t234: "Usuário Não Registado",
	t235: "Usuário Registado",
	t236: "Bem Vindo!",
	t237: "Licença inválida",
	t238: "Controladores de Registo Energia",
	t239: "Paineis de Iluminação",
	t240: "Variador AKD",
	t241: "Módulo de Compressor",
	t242: "Pontos de Controle dispositivo",
	t243: "Pontos Cálculados",
	t244: "Paineis de Contactores",
	t245: "Portadores",
	t246: "Vista Avançada",
	t247: "Duplo click aqui para zoom In/Out",
	t248: "Click aqui para zoom In/Out",
	t249: "Alarme(s) Reconhecido(s)",
	t250: "Simulador",
	t251: "Tarefa Refrgeração",
	t252: "Tarefa HVAC",
	t253: "Tarefa Iluminação",
	t254: "Tarefa Miscelan",
	t255: "Rede comunicando",
	t256: "Varid.",
	t257: "Refer.",
	t258: "Gráfico (Variador Mtr Hz &amp; Amp)",
	t259: "Data finalização",
	t260: "Parar",
	t261: "A hora de finalização é inferior ou igual à hora de inicio, por favor seleccione novamente a data/hora",
	t262: "Hora de inicio histórico do(s) ponto(s)",
	t263: "é anterior à hora de inicio especificada",
	t264: "A amostragem  por cada ponto de histórico atingiu os 100,000. O tempo de resposta se muito elevado. Deve reduzir este tempo diminuindo o periodo de amostragem.",
	t265: "O processo da query de histórico esta ocupado a processar outros pedidos, por favor aguarde cerca de 2 minutos e tente novamente...",
	t266: "Guardar",
	t267: "Painel de Controle",
	t268: "Primeiro",
	t269: "Último",
	t270: "Impr.",
	t271: "Ampliar",
	t272: "Diminuir",
	t273: "Mostrar/Ocultar legenda",
	t274: "Exportar histórico para ficheiro",
	t275: "Mostrar/Ocultar grelha",
	t276: "Vista uma página",
	t277: "Vista multi-página",
	t278: "Ler histórico de grupo do ficheiro",
	t279: "Ler histórico do ficheiro",
	t280: "Guardar histórico de grupo para ficheiro",
	t281: "Contagem vista multi-página",
	t282: "Página",
	t283: "Seleccionar uma página para mostrar",
	t284: "O ficheiro de histórico deve ser em formato Excel CSV!",
	t285: "Não existem dados, por favor verifique os pontos de histórico, data/hora seleccionados ",
	t286: "Histórico vista reduzida",
	t287: "Histórico vista completa",
	t288: "Vista ficheiro histórico",
	t289: "Seleccionar...",
	t290: "Esta operação vai exportar o histórico da vista actual para um ficheiro .csv.",
	t291: "Por favor seleccione o ficheiro de destino",
	t292: "Ficheiro",
	t293: "Ler histórico de grupo...",
	t294: "Ler histórico...",
	t295: "Guardar histórico de grupo...",
	t296: "Ver",
	t297: "Ampliar",
	t298: "Sim",
	t299: "Não",
	t300: "Actualizando configuração controlador",
	t301: "Perguntar novamente",
	t302: "Actualizar Configuração do Controlador",
	t303: "Dados Actualizados",
	t304: "Nível de Alarme",
	t305: "Ação Alarme",
	t306: "Reconhecido",
	t307: "Guardar Base Dados para Disco",
	t308: "Nome Ficheiro",
	t309: "Descrição Base Dados",
	t310: "Caminho Base Dados",
	t311: "Versão",
	t312: "Tempo",
	t313: "Exportar Alarmes",
	t314: "Base Dados guardada em",
	t315: "Falha ao guardar base dados, por favor verifique se a pasta e o nome do ficheiro é válido!",
	t316: "Nome do ficheiro incorrecto, por favor verifique o nome do ficheiro da base de dados.",
	t317: "Alarmes exportados. Por favor selecione o arquivo para gravar os alarmes exportados.",
	t318: "Por favor seleccione os alarmes para exportar",
	t319: "Actualizando informação de alarmes desde a unidade(s)",
	t320: "Descrição do Ficheiro",
	t321: "Converter ficheiro .hst para formato .csv",
	t322: "Passo 1: Seleccionar o ficheiro .hst",
	t323: "Passo 2:  Seleccionar as variáveis e o intervalo de tempo",
	t324: "Passo 3: Seleccionar o ficheiro de destino .csv",
	t325: "Guardar como...",
	t326: "Não são permitidos mais de 250 pontos nesta operação",
	t327: "Pontos seleccionados",
	t328: "Não é permitido exportar mais de 65,536 amostras por ponto, por favor altere a taxa de amostragem",
	t329: "Armazenar",
	t330: "Armazenar histórico desde a unidade",
	t331: "Seleccionar os pontos armazenados",
	t332: "Sem Valor",
	t333: "Desconhecido",
	t334: "Suspenso",
	t335: "Por favor seleccione",
	t336: "Seleccionar pontos para visualizar",
	t337: "Guardar para um ficheiro .hst",
	t338: "Descarga de dados terminada, por favor seleccione uma das seguintes opções:",
	t339: "Seleccione até 8 pontos para visualizar",
	t340: "Guardar o histórico armazenado para um ficheiro .hst",
	t341: "Imprimir Vista Geral",
	t342: "Por favor seleccione guardar ou imprimir Relatório da Vista Geral",
	t343: "Seleccione um formato de ficheiro",
	t344: "Criando ficheiro de estado, por favor aguarde...",
	t345: "O ficheiro seleccionado já existe, deseja substituir o ficheiro actual?",
	t346: "Painel Controle",
	t347: "Vista Gráfica",
	t348: "Ajuda",
	t349: "Vista Sist.",
	t350: "Mapa",
	t351: "Ferramenta Tradução",
	t352: "Manual",
	t353: "Atualizar alarmes...",
	t354: "Actualizado",
	t355: "Modelo",
	t356: "Controle",
	t357: "Óleo",
	t358: "Temp",
	t359: "Máximizar",
	t360: "Restaurar",
	t361: "Voltar...",
	t362: "Comando falhou!",
	t363: "Estado comun.  ok.",
	t364: "Mensagem comun. enviada.",
	t365: "Actualizando Componente Principal",
	t366: "Actualiando Componente da Configuração",
	t367: "Temp. Ctrl",
	t368: "Este ajuste será efectivo apenas quando a curva de histórico apresentada for redesenhada, deseja continuar?",
	t369: "Actualizando, por favor aguarde",
	t370: "Pontos",
	t371: "Nome da unidade",
	t372: "Preferências",
	t373: "Versão Simulador",
	t374: "Energia",
	t375: "Ir para vista geral",
	t376: "Sistema de alarme ou dispositivo relacionado não encontrado",
	t377: "Programas actuais",
	t378: "Ficheiro de idioma não encontrado",
	t379: "Verão não suportada",
	t380: "Introduza o IP da unidade aqui (ex. 192.168.1.1)",
	t381: "Por favor introduza o IP da unidade ou URL",
	t382: "Ocultar Detalhes",
	t383: "Mostrar Detalhes",
	t384: "Temp. Ext.",
	t385: "Voltar",
	t386: "Impul.",
	t387: "Umidade",
	t388: "Pto orvl",
	t389: "Vent.",
	t390: "Zona",
	t391: "Parar Inj.",
	t392: "Divisão",
	t393: "Temp. Asp.",
	t394: "Capacidade Actual",
	t395: "Pressão Sucção",
	t396: "Descarga",
	t397: "Compressores",
	t398: "Condensador",
	t399: "Referência Condensador",
	t400: "Refrigerante",
	t401: "Referência Sucção",
	t402: "Sucção",
	t403: "Degelo",
	t404: "Formato Histórico Danfoss",
	t405: "Formato texto",
	t406: "Esta operação requere o reinicio da unidade, deseja reiniciar a unidade agora?",
	t407: "Unidade reiniciada com sucesso, por favor volte a carregar a página web.",
	t408: "Modo Aplicação Fábrica",
	t409: "Calculando...",
	t410: "minutos",
	t411: "Ajuste Noite",
	t412: "Ilum. Exp.",
	t413: "Concorrencia de Programas",
	t414: "(resolução em 10 minutos)",
	t415: "Ver texto",
	t416: "Exportação como CSV",
	t417: "Guardar PNG",
	t418: "Ver gráfico",
	t419: "Ver texto",
	t420: "Programa",
	t421: "Duração",
	t422: "Temp. Term.",
	t423: "Nada",
	t424: "Temp. Controle",
	t425: "Iniciando desde",
	t426: "Base de dados atualizada desde",
	t427: "Estatisticas",
	t428: "Selecione Ficheiro",
	t429: "Selecione o ficheiro de histórico que deseja converter para o formato CSV...",
	t430: "Finalizar",
	t431: "Resumo",
	t432: "Ficheiro de Histórico (*.hst)",
	t433: "Assistente Conversão Histórico",
	t434: "Criação ficheiro completa",
	t435: "Selecione os pontos que deseja converter para o formato CSV...",
	t436: "Selecione o Intervalo Tempo",
	t437: "Selecione o intervalo de tempo e pressione o botão processar",
	t438: "Processar dados",
	t439: "Preparando dados históricos, por favor aguarde",
	t440: "Selecione o ficheiro CSV e guarde para o ficheiro",
	t441: "Não é possivel encontrar o histórico do ponto",
	t442: "Erro - Dados históricos não atualizados",
	t443: "Ocorreu um erro de comunicação das unidades com a rede principal",
	t444: "Atenção",
	t445: "Silenciar",
	t446: "Base de dados por guardar, deseja fechar a janela?",
	t447: "Vista Tabela",
	t448: "Mostrar Gráfico",
	t449: "Mostrar Tabela",
	t450: "Degelo Manual On",
	t451: "Degelo Manual Off",
	t452: "Degelo auto",
	t453: "Configurar Controle Degelo",
	t454: "Etapa on",
	t455: "Etapa off",
	t456: "Liquido",
	t457: "Não existem programas configurados",
	t458: "Vista Loja",
	t459: "Comunicação da unidade perdida com a rede principal!",
	t460: "Ajuste Rápido",
	t461: "Rua",
	t462: "City",
	t463: "Telf.",
	t464: "Região",
	t466: "Indíce",
	t469: "Lista de Endereços Exportada com Sucesso",
	t470: "Apagar item",
	t471: "Aferir",
	t472: "Nota: entrada case sensitive",
	t473: "Ligado",
	t474: "Módulo RO",
	t475: "Módulo SI",
	t476: "Modulo OI",
	t477: "Cálculo",
	t478: "Ajustar para Hora Actual",
	t479: "Rack",
	t480: "Sem Compressor",
	t481: "Circuito",
	t482: "Evap.",
	t483: "Desconhecido",
	t484: "tipo de err:",
	t485: "adic.",
	t486: "Copiar",
	t487: "Remover",
	t488: "Controle de casos",
	t489: "Tipo Exp.",
	t490: "LON",
	t491: "Digite valor para copiar",
	t492: "no name",
	t493: "Configurando o layout de refrigeração...",
	t494: "Configuration error:",
	t495: "Verifique a configuração.",
	t496: "A configuração do layout de refrigeração foi concluída com sucesso. Pressione o botão ok para sair deste assistente.",
	t497: "Configuration Status",
	t498: "Layout final de refrigeração",
	t500: "Code #",
	t501: "Layout",
	t502: "System Setup",
	t503: "Licença",
	t504: "Utlizadores",
	t505: "Não Autorizado",
	t506: "Por favor, verifique...",
	t507: "Tem certeza de que deseja sair desta tela? Suas configurações atuais podem ser perdidas.",
	t508: "Rede controladora de varredura...",
	t509: "Actualizando",
	t510: "Verificando",
	t511: "AK-I/O",
	t512: "Rescan",
	t513: "Inclua programação offline",
	t514: "Ônibus de dispositivos",
	t515: "Dispositivos online",
	t516: "Programação Offline",
	t517: "Endereço duplicado",
	t518: "Controles do compressor",
	t519: "Grupos Aspir.",
	t520: "N/A",
	t521: "Dispositivos disponíveis",
	t522: "Permitir criação de circuito múltiplo",
	t523: "Inserir dispositivos",
	t524: "Inserir circuito",
	t525: "Circuito multi-caso.",
	t526: "Carregando mesas...",
	t527: "Solicitando dados de alarme para",
	t528: "Arquivos de grupo de história",
	t529: "Arquivos de dados de histórico",
	t530: "Todos os arquivos",
	t531: "Mostrar Símbolos",
	t532: "Mostrar curvas",
	t533: "Não foi coletada história suficiente.",
	t534: "Recupere os últimos dados do histórico.",
	t535: "Status/Configurações",
	t536: "Agora baixando",
	t537: "amostras de história...",
	t538: "Recuperar",
	t539: "Pontos de dados...",
	t540: "Interv.",
	t541: "Min",
	t542: "Max",
	t543: "Média",
	t544: "Vá para a janela principal do alarme.",
	t545: "Pare o logotipo automático",
	t546: "Connecting to ",
	t547: "Cancelar o logotipo automático",
	t548: "Digite a senha de log-off",
	t549: "Web 1",
	t550: "Web 2",
	t551: "Faça logon automaticamente",
	t552: "Logoff após o período de inatividade",
	t553: "Home screen",
	t554: "Mostrar menu de configuração",
	t555: "Mostrar itens misc na tela princ.",
	t556: "Use tela cheia",
	t557: "Habilite o som do alarme",
	t558: "Permitir fechamento/minimização do programa",
	t559: "Unidade de acesso a erros #",
	t560: "Conectado à rede.",
	t561: "Logon automático cancelado",
	t562: "Retry connect a",
	t563: "Conectado a partir de",
	t564: "Comun.",
	t565: "Formato Hora",
	t566: "Formato Data",
	t567: "Pressão",
	t568: "Nível Ilum.",
	t569: "Subarrf",
	t570: "Subarref.",
	t571: "Diário",
	t572: "Semanal",
	t573: "Arquivo HST",
	t574: "CSV File",
	t575: "Domingo",
	t576: "Segunda",
	t577: "Terça",
	t578: "Quarta",
	t579: "Quinta",
	t580: "Sexta",
	t581: "Sábado",
	t582: "Coleção de registros de história",
	t583: "Habilite a coleta automática",
	t584: "Quando coletar",
	t585: "Localização de salvamento de arquivos",
	t586: "Selecione o formato do arquivo",
	t587: "Selecione pasta para armazenar arquivos de histórico...",
	t588: "Habilite a coleta automática de histórico para recuperar e salvar registros diários ou semanais dos pontos de histórico configurados da loja.",
	t589: "Para exibir arquivos de histórico do HST, vá para a tela de histórico principal e selecione o histórico de importação. Para exibir arquivos CSV use qualquer aplicativo de planilha.",
	t590: "Se o endereço IP da conexão atual for encontrado na agenda de endereços, os pontos de histórico selecionados serão salvos.",
	t591: "Selecione pontos de dados",
	t592: "Todos os selecionados",
	t593: "Pontos selecionados",
	t594: "Buscar",
	t595: "auto- conectando a",
	t596: "Por favor, verifique",
	t597: "Histórico automático ativo. Tem certeza de que deseja encerrar este programa?",
	t598: "Tem certeza de que deseja fechar este programa?",
	t599: "Ação ",
	t600: "Filtro",
	t601: "Todas as ações",
	t602: "Mais informações...",
	t603: "Editável",
	t604: "Operação",
	t605: "Operação Cancelada",
	t606: "Download base de dados terminado",
	t607: "Base de dados das unidades actualizada",
	t608: "Ficheiro(s) transferidos com sucesso para a unidade",
	t609: "Download terminado, o ficheiro encontra-se em processomento na unidade",
	t610: "Ocorreu um erro ao actualizar o ficheiro para o PC, por favor verifique se o ficheiro permite escrita e acesso",
	t611: "Actualizando ficheiro",
	t612: "Descarregando ficheiro",
	t613: "Actualizando o(s) ficheiro(s) Seleccionado(s)",
	t614: "Descarregando o(s) ficheiro(s) Seleccionado(s)",
	t615: "Apagando ficheiro",
	t616: "Apagando o(s) ficheiro(s) Seleccionado(s)",
	t617: "Erro de ligação FTP",
	t618: "Descarregar os Ficheiro(s) Seleccionado(s) para AK-CS",
	t619: "Ocorreu um erro na ligação FTP. Por favor verifique a autorização FTP e o endereço IP.",
	t620: "FTP ligado",
	t621: "Bytes",
	t622: "Os ficheiros existentes na unidade serão sobrescritos nesta operação, deseja continuar?",
	t623: "Os ficheiros existentes no PC serão sobrescritos nesta operação, deseja continuar?",
	t624: "Os ficheiros foram descarregados para a unidade.",
	t625: "Apenas REFRIGERAÇÃO",
	t626: "Loja Conveniencia",
	t627: "Funcionalidade COMPLETA",
	t628: "APENAS HVAC",
	t629: "Nome ilegal do ficheiro; por favor verifique que o nome tem menos de 8 caracteres.",
	t630: "Substituir código da aplicação?",
	t631: "Depois",
	t632: "Actualizado",
	t633: "terá de seleccionar SIM para sobreescrever a aplicação agora, ou seleccione mais tarde                                               ",
	t634: "Por favor seleccione a directoria raiz do simulador",
	t635: "Por favor selccione a directoria do windows",
	t636: "Por favor seleccione a directoria de destino",
	t637: "Seleccione a directoria da base de dados",
	t638: "FTP desligado",
	t639: "Tempo de ligação FTP esgotado, FTP desligado",
	t640: "Actualizar os Ficheiros(s) Seleccionado(s) para o PC",
	t641: "Apagar os Ficheiro(s) Seleccionado(s)",
	t642: "Porta Serv. FTP",
	t643: "Porta Serv Web",
	t644: "Destino Local",
	t645: "Retroceder",
	t646: "Tamanho",
	t647: "Data",
	t648: "Comunicar",
	t649: "Desligar",
	t650: "Destino Remoto",
	t651: "Libertar Espaço Disponível:",
	t652: "Ficheiro(s) Selecionado(s)",
	t653: "Ficheiros",
	t654: "Total Espaço",
	t655: "Clique para alterar a pasta...",
	t656: "Alterar...",
	t657: "Deseja apagar o(s) ficheiro(s) seleccionado(s)?",
	t658: "Ligação Terminada, Sair",
	t659: "O nome(nomes) do ficheiro(ficheiros) seleccionado é(são) ilegais e o download é ignorado.",
	t660: "Alguns dos nomes dos ficheiros seleccionados são elegais e ignorados.",
	t661: "Ficheiro(s) transferidos com sucesso para o PC",
	t662: "Não existe espaço disco suficiente na unidade",
	t663: "Transfer file(s)",
	t664: "Modificado",
	t665: "Copiar ficheiros do disco flash para memória RAM",
	t666: "Por favor selecione a operação cópia ficheiro:",
	t667: "Copiar todos os arquivos EDF configurados do Flash Disk",
	t668: "Copiar todos os ficheiros EDF da memória flash",
	t669: "Os arquivos edf/ed3 são armazenados no disco flash da unidade, mas não carregados na tela gerenciamento de arquivos.  Use as opções abaixo para copiar os arquivos EDF armazenados em sua unidade para a tela gerenciamento de arquivos",
	t670: "Esta operação copia todos os ficheiros EDF que foram configurados na unidade a partir da memória flash para a memória RAM",
	t671: "Esta operação copia todos os ficheiros EDF na unidade a partir da memória flash para a memória RAM",
	t672: "Não existe nenhum arquivo EDF válido",
	t673: "Cópia de arquivo do EDF concluída",
	t674: "arquivos copiados",
	t675: "Confirma download base dados",
	t676: "End. Unidade",
	t677: "Endereço IP/Nome do host",
	t678: "Nome Ficheiro Base de dados",
	t679: "Selec.",
	t680: "Actualização terminada, reiniciando e actualizando unidade. Poderá levar alguns minutos.",
	t681: "Bem vindo ao gestor de actualizações - Passo 1 de 2",
	t682: "Bem vindo ao gestor de actualizações - Passo 2 de 2",
	t683: "Proceder",
	t684: "Pré-actualização verificada",
	t685: "Localizar Firmware",
	t686: "Backup base dados?",
	t687: "Guardando base dados",
	t688: "Transferindo software",
	t689: "Sobrescrevendo software",
	t690: "Selecione software para actualizar",
	t691: "Não foi detectada nenhuma aplicação fábrica gestão falhas",
	t692: "Pode continuar se deseja, mas qualquer erro durante a actualização pode levar o sistema a ficar inoperativo",
	t693: "Recomenda-se fortemente não continuar com o processo.",
	t694: "Deseja continuar?",
	t695: "A actualização do sistema de forma remota presume algum risco de falha, que pode resultar num erro do sistema.",
	t696: "Desaprovar",
	t697: "Actualização base dados terminada",
	t698: "Backup base dados terminado",
	t699: "Download da aplicação terminado",
	t700: "Sobrescrição da aplicação terminada",
	t701: "Software actualizado com sucesso",
	t702: "Deseja reiniciar a unidade à sua aplicação fábrica?",
	t703: "Verificando hardware",
	t704: "Verificando estado controlador",
	t705: "Teste falha fábrica",
	t706: "Versão actual firmware",
	t707: "Iniciar actualização",
	t708: "Selecione um ficheiro exixtente da base de dados",
	t709: "Selecione um ficheiro exixtente do firmware",
	t710: "Voltar",
	t711: "Localizar Firmware",
	t712: "Seleccione o ficheiro .csi",
	t713: "Selecione ficheiro .mai",
	t714: "O download remoto não é suportado por esta placa de CPU.",
	t715: "Falha no download da aplicação",
	t716: "Actualização da base de dados falhada, Por favor tente novamente. ",
	t717: "Sobrescrição da aplicação terminada",
	t718: "Falha na sobrescrição da aplicação! Tente novamente.",
	t719: "Falha na sobrescrição da aplicação",
	t720: "Não foi detectada nenhuma aplicação fábrica gestão falhas",
	t721: "Terminado",
	t722: "A plicação selecionada pode ter uma versão de firmware diferente da actual na unidade, continuar com o procedimento pode provocar um risco de falha e um erro do sistema. ",
	t723: "O nome(nomes) do ficheiro(ficheiros) seleccionado é(são) ilegais e o download é ignorado.",
	t724: "Alguns dos nomes dos ficheiros seleccionados são elegais e ignorados.",
	t725: "Falta o berg pin na unidade.",
	t726: "Bateria da unidade baixa.",
	t727: "Flha na rede host da unidade.",
	t728: "A unidade esta estado deslastrado",
	t729: "Selecione o ficheiro de destino",
	t730: "Selecione a directoria de destino",
	t731: "Actualização base dados terminada",
	t732: "Estado OK",
	t733: "Estado Erro",
	t734: "Estado Desconhecido",
	t735: "Este operação poderá sobreescrever os ficheiros seleccionados no simulador de destino, deseja continuar?",
	t736: "Descarga da base de dados terminada, necessita de reiniciar o sistema",
	t737: "Descarga da base de dados falhada, necessita de tentar novamente ",
	t738: "A unidade será reiniciada automáticamente após esta operação, deseja continuar?",
	t739: "Seleccionar Base Dados",
	t740: "Descarregar Ficheiro da base de dados?",
	t741: "Actualizar Ficheiro da Base Dados?",
	t742: "Carregando base de dados para AK-CS, Por favor aguarde",
	t743: "Base de dados carregada com sucesso, Agora reiniciando unidade",
	t744: "Actualização da base de dados terminada, Unidade reiniciada com sucesso",
	t745: "Actulização da base de dados falhada! Por favor tente novamente.",
	t746: "Erro ao actualizar base de dados",
	t747: "Pré-download verificado",
	t748: "Localizar base dados",
	t749: "Descarregando base dados",
	t750: "Informação actual base dados",
	t751: "Selecione a base de dados para download",
	t752: "Guardar Base Dados para o PC (Actualizar)",
	t753: "Ler Base Dados para o AK-CS (Descarregar)",
	t754: "Necessita de autorização Supervisor",
	t755: "Iniciar actualização",
	t756: "Por favor selecione a operação da base dados:",
	t757: "Iniciar download",
	t758: "Versão actual firmware",
	t760: "Base de dados descarregada com sucesso!",
	t761: "Bem vindo ao gestor da base dados - Passo 1 de 2",
	t762: "Bem vindo ao gestor da base dados - Passo 2 de 2",
	t763: "Falha ao ler lista de Usuários, Por favor verifique o IP Local/Endereço URL",
	t764: "A actualização da base de dados de forma remota presume algum risco de falha, que pode resultar num erro do sistema.",
	t765: "Download de banco de dados concluído",
	t766: "Ethernet Scan",
	t767: "Digite primeiro endereço IP e pressione iniciar",
	t768: "Varredura",
	t769: "Actualizando",
	t770: "Coleção de registros de história",
	t771: "Gráfico de cores analógico",
	t772: "Cores de gráficos digitais",
	t773: "Horários de desligamento",
	t774: "Resumo de Descongelado",
	t775: "Programa ajuste noite",
	t776: "Cronogramas de iluminação de casos",
	t777: "Status de carregamento",
	t778: "Relação de registros de alarme",
	t779: "Selecione pasta para armazenar arquivos de alarme...",
	t780: "Mensal",
	t781: "Habilite a coleta automática de alarmes para recuperar e salvar informações de alarme em um arquivo periodicamente.",
	t782: "Separador de CSV",
	t783: "Sem Alarmes",
	t784: "Recuperar contagem de alarmes para unidade:",
	t785: "Recuperando detalhes do alarme da unidade:",
	t786: "Coleção de alarmes automáticos ativa. Tem certeza de que deseja fechar este programa?",
	t787: "Por Hora",
	t788: "Isso atualizará o banco de dados da unidade e definirá o local para o centro da visão atual. Prosseguir?",
	t789: "amostras processadas",
	t790: "Por favor, configure um grupo de sucção.",
	t791: "Para reutilizar o Assistente de layout mais de uma vez que o layout do banco de dados de refrigeração precisa ser redefinido.",
	t792: "Do this via the Configuration-&gt;Control page and set the number of Racks/Packs to zero.",
	t793: "Nota: quaisquer horários, cálculos ou importações com base na configuração de refrigeração prévia precisarão ser reconfigurados.",
	t794: "Processo de cópia concluído.",
	t795: "Relatório de Refrigeração",
	t796: "Final HVAC Layout",
	t797: "A configuração do layout do HVAC foi concluída com sucesso. Pressione o botão ok para sair deste assistente.",
	t798: "Configurando o layout HVAC...",
	t799: "Para reutilizar o Assistente de Layout HVAC mais de uma vez que o layout do banco de dados HVAC precisa ser reiniciado.",
	t800: "Do this via the Configuration-&gt;Control page and set the number of HVAC units to zero.",
	t801: "Nota: quaisquer cronogramas, cálculos ou importações com base na configuração anterior do HVAC precisarão ser reconfigurados.",
	t802: "Padrão estágio",
	t803: "Passos",
	t804: "Nível",
	t805: "Mostrar várias dicas de dados",
	t806: "Num. Etapas",
	t807: "Não pude salvar o arquivo.",
	t808: "Modelo incorreto.",
	t809: "Carregue com sucesso.",
	t810: "Aberto",
	t811: "Este recurso só está disponível na versão desktop do StoreView.",
	t812: "Continuar",
	t813: "Deixe o Mago",
	t814: "A operação teve sucesso. Você gostaria de continuar ou você quer deixar o mago.",
	t815: "Erro ao buscar dados de alarme.",
	t817: "Device Name : ",
	t819: "In Progress...",
	t820: "Os dados do alarme não estão presentes.",
	t821: "Contagem de alarmes",
	t822: "Opções de exibição de imitação",
	t823: "Exibir apenas texto",
	t824: "Exibir apenas ícone",
	t825: "Exibir texto e ícone",
	t826: "1 Semana",
	t827: "2 semanas",
	t828: "3 semanas",
	t829: "4 semanas",
	t830: "Configurações gráficas",
	t831: "Incapaz de exibir diagnósticos de alarme, verifique a coleta de alarme automático sob preferências",
	t832: "Reordenação não é permitido com racks ou circuitos IO incluídos na configuração.",
	t833: "Este endereço IP/URL do Host já está presente no catálogo de endereços. Você gosta de atualizar essa entrada?",
	t834: "Navegar",
	t835: "Selecione pasta de livro de endereços",
	t836: "Localização",
	t837: "O local selecionado não tem nenhum conteúdo de catálogo de endereços. Deseja exportar conteúdo do catálogo de endereços existente para o local selecionado?",
	t838: "Alarm Action Codes",
	t839: "O arquivo do catálogo de endereços não está encontrado no caminho selecionado. A agenda de endereços é carregada de locais...",
	t840: "Selecione o local para salvar logs.",
	t841: "Selecione um local válido para salvar logs.",
	t842: "A coleta de registro de alarme está em andamento...",
	t843: "A coleção de registros históricos está em andamento...",
	t844: "Executar em segundo plano",
	t845: "Alarm log progress",
	t846: "O endereço IP/URL do host não deve estar em branco",
	t847: "O campo de endereços não deve estar em branco",
	t849: "Você não tem a versão mais recente do arquivo, gostaria de carregar dados da versão mais recente?",
	t850: "Conflito de versões",
	t851: "Progresso do registro de história",
	t852: "Os campos de Nome de Usuário e Senha não devem estar em branco.",
	t853: "O campo de endereços IP não deve estar em branco.",
	t854: "O campo de endereço IP/URL do host não deve estar em branco.",
	t855: "O nome do usuário não deve estar em branco.",
	t856: "A senha não deve estar em branco.",
	t857: "O SM800 ao que você se conectou",
	t858: "não teve o Assistente inicial de inicialização concluído. Por favor, complete esta tarefa e reconecte-se ao SM800",
	t859: "Definido para agora",
	t860: "Entrada inválida",
	t861: "O texto é muito longo.",
	t862: "Deslocamento",
	t863: "Não é possível se comunicar com unidades na rede host",
	t864: "Verifique se os endereços são diferentes. A configuração é atualizada quando o botão de salvamento é pressionado.",
	t865: "Selecione um arquivo de histórico válido (.hst)",
	t866: "Selecione um arquivo de grupo de histórico válido (.grp)",
	t867: "Cor",
	t868: "Porcentagem",
	t869: "Tensão",
	t870: "Atual",
	t871: "Poder",
	t872: "Freqüência",
	t873: "Concentração",
	t874: "Fluxo",
	t875: "Velocidade",
	t876: "Acidez",
	t877: "Selecione gráficos na gaveta inferior",
	t878: "Salvar PNG",
	t879: "Desenhar",
	t880: "Status do sistema...",
	t881: "Status do sistema e da unidade",
	t882: "Gataway",
	t883: "Licenças de recursos",
	t884: "Versão de software",
	t885: "Versão do módulo do navegador",
	t886: "Versão danux",
	t887: "Endereço mac",
	t888: "Suporte e atualizações",
	t889: "Nome da unidade",
	t890: "Reconectar",
	t891: "Conexão Perdida",
	t892: "Nenhum equipamento {{tipo}} encontrado.",
	t893: "A versão de software usada atualmente não é suportada",
	t894: "Links rápidos",
	t895: "Equipamento",
	t896: "Gráficos",
	t897: "Utilitários",
	t898: "Reversão de software",
	t899: "Diagnostics",
	t900: "Ocorreu um erro",
	t901: "Nenhum dado {{alarms}} encontrado",
	t902: "Configurações",
	t903: "Nome do aplicativo",
	t904: "Versão do aplicativo",
	t905: "Mensagem",
	t906: "Tipo de evento",
	t907: "Timestamp",
	t908: "Versão de reversão",
	t909: "Versão atual",
	t910: "Software",
	t911: "Reversão...",
	t912: "Selecione o equipamento da lista",
	t913: "Exibição do calendário",
	t914: "Ocorreu um erro ao carregar dispositivos(s), por favor, tente novamente.",
	t915: "Ocorreu um erro durante o carregamento dos horários, por favor tente novamente.",
	t916: "Nenhum equipamento encontrado",
	t917: "Horários de Exportação",
	t918: "Por favor, escolha o tipo de exportação CSV ou PDF",
	t919: "Exportação como CSV",
	t920: "Preparando PDF",
	t921: "Exportar como PDF",
	t922: "Por favor, forneça um IP neste formato xx.xx.xx.xx[:xxxx]",
	t923: "Username",
	t924: "Login",
	t925: "Reversão",
	t926: "Software Rollback SM800A",
	t927: "Esta ação irá reverter todos os softwares para as versões listadas.",
	t928: "Editor de dispositivos de festa",
	t929: "algum texto que explica o recurso",
	t930: "Atualização CDF",
	t931: "Dispositivo de mapa",
	t932: "Arquivo do dispositivo",
	t933: "Número de registro baseado em 1",
	t934: "Tipo de armazenamento",
	t935: "Min",
	t936: "Max",
	t937: "Leia apenas",
	t938: "Log",
	t939: "Tipo de mensagem",
	t940: "Palavra-chave de conversão",
	t941: "Parâmetros",
	t942: "Addr.",
	t943: "Prioridade",
	t944: "Pouco",
	t945: "Negado",
	t946: "Novo arquivo",
	t947: "Arquivo aberto",
	t948: "Gerar arquivo",
	t949: "Grupo",
	t950: "Versão do produto",
	t951: "Ocorreu erro",
	t952: "Não encontrado",
	t953: "Este dispositivo não foi encontrado",
	t954: "Fora temporário.",
	t955: "Nenhum {{equipamento}} encontrado",
	t956: "Casos",
	t957: "Alarme mudo/ato com sucesso",
	t958: "Mudo/ato falhou para este alarme",
	t959: "Ocorreu um erro durante o carregamento das unidades, por favor, tente novamente.",
	t960: "Sem equipamento",
	t961: "Nenhum equipamento {{type}} encontrado",
	t962: "Configure o gerenciador de sistemas",
	t963: "Dispositivo",
	t964: "Desfazer",
	t965: "Você mudou o valor do campo, quer salvar as mudanças?",
	t966: "Limpar pontos de histórico selecionados",
	t967: "Shr {{number}}",
	t968: "Velocidade da bomba Hr",
	t969: "Sd",
	t970: "V3hr",
	t971: "Referência de RH",
	t972: "Stw {{number}}",
	t973: "Duas referências",
	t974: "Duas velocidades da bomba",
	t975: "V3tw",
	t976: "Trec",
	t977: "Prec",
	t978: "Vrec OD",
	t979: "Referência Prec",
	t980: "Referência Trec",
	t981: "Pc",
	t982: "S",
	t983: "Vhp OD",
	t984: "Referência Pgc",
	t985: "Ejetor OD",
	t986: "Pgc",
	t987: "Shp",
	t988: "Sgc",
	t989: "Sc3",
	t990: "Sgc ctrl.",
	t991: "Cond. capacidade",
	t992: "Po",
	t993: "S",
	t994: "Sd",
	t995: "computador",
	t996: "Capacidade de funcionamento",
	t997: "Certificado do navegador",
	t998: "Sair",
	t1000: "Classificando apenas em {{count}}",
	t1001: "{{count}} de {{total}} alarmes carregados",
	t1002: "Reconhecimento bem-sucedido para todos os alarmes",
	t1003: "Falha de reconhecimento para todos os alarmes",
	t1004: "Apenas {{count}} de {{total}} alarmes reconhecidos",
	t1005: "A exportação falhou para todos os alarmes",
	t1006: "Exportação bem-sucedida",
	t1007: "Falha na exportação, pelo menos um tipo de alarme é necessário",
	t2000: "-",
	t2001: "A resposta é muito grande para o buffer de transmissão XML que é de 32K bytes",
	t2002: "Nenhum atributo associado ao elemento ou comando cmd não possui elemento \"cmd\" ou não pôde escrever em uma variável ou não pôde ler uma variável ou não pôde ler um ponto ou não pôde escrever no modo de operação de entrada ou saída de relé ligado/desligado",
	t2003: "Entrada de comando incorreta",
	t2004: "Erro nos parâmetros de postagem CGI",
	t2005: "Erro ao converter a resposta XML de sua representação interna para a string XML de resposta",
	t2006: "Erro ao decodificar a string do URL",
	t2007: "Estouro de memória",
	t2008: "Falha na compactação de dados de resposta",
	t2009: "Erro ao analisar a solicitação",
	t2010: "Erro de acesso a dados - várias causas que não são mais resolvidas",
	t2011: "Valor do atributo de ação de comando indefinido O valor do atributo é inválido",
	t2012: "Erro no número de referência do alarme ou alarme não configurado",
	t2013: "A string do parâmetro cgi de entrada é muito longa.",
	t2014: "O endereço do dispositivo não foi encontrado",
	t2015: "Falha na autorização para gravar",
	t2016: "Nenhum histórico configurado",
	t2017: "Nenhum dado do histórico retornado, pode ser erro de hora",
	t2018: "Criar erros de arquivo",
	t2019: "Ler erros de arquivo",
	t2020: "Excedeu o tamanho máximo da lista de dispositivos",
	t2021: "não usado",
	t2022: "Índice de histórico inválido",
	t2023: "Não foi possível encontrar o índice de pontos de placa do tipo de nó, nó, mod e ponto.",
	t2024: "Não foi possível encontrar o índice do histórico",
	t2025: "A consulta de histórico não foi inicializada antes de tentar iniciar uma consulta de histórico.",
	t2026: "Uma hq_start_query foi tentada enquanto uma consulta de histórico está ativa",
	t2027: "Nenhum atributo de comando encontrado quando alguns são esperados",
	t2028: "Um começo de época foi fornecido sem fim ou vice-versa",
	t2029: "Não foi possível converter do formato de data e hora para segundos de época",
	t2030: "O comando requer um query_id, mas nenhum fornecido",
	t2031: "Erro ao criar resposta XML",
	t2032: "O query_id fornecido não corresponde a uma consulta ativa",
	t2033: "Um comando hq_get_data foi tentado, mas os dados ainda não estão disponíveis",
	t2034: "Foi fornecida uma taxa de amostragem que não é uma das permitidas",
	t2035: "O intervalo médio é menor ou igual à taxa de amostragem na qual os dados foram registrados",
	t2036: "O tempo da época de parada é menor que o tempo da época inicial ou o tempo da época de parada menos o tempo da época inicial é menor que o intervalo médio",
	t2037: "O device_id fornecido não corresponde a nenhum atualmente conhecido pelo ak255. Observe que apenas os dispositivos que foram configurados são conhecidos pelo ak255",
	t2038: "Nenhum atributo de ID",
	t2039: "Atributos de ID inválidos",
	t2040: "Sem número de agendamento",
	t2041: "Número de agendamento inválido",
	t2042: "Sem detalhes de programação",
	t2043: "Faltando on_time",
	t2044: "Faltando hora",
	t2045: "Hora inválida",
	t2046: "Minuto faltando",
	t2047: "Minuto inválido",
	t2048: "Faltando tempo_fora",
	t2049: "Dias da semana ausentes",
	t2050: "Dias da semana inválidos",
	t2051: "Feriados ausentes",
	t2052: "Feriados inválidos",
	t2053: "Holiday_start ausente ou inválido",
	t2054: "Holiday_end ausente ou inválido",
	t2055: "Holiday_open ausente ou inválido",
	t2056: "Holiday_close ausente ou inválido",
	t2057: "Feriado não configurado",
	t2058: "Descrição ausente",
	t2059: "Não é possível limpar o alarme",
	t2060: "store_open ausente ou inválido",
	t2061: "store_closed ausente ou inválido",
	t2062: "Alarme não configurado",
	t2063: "Nó offline",
	t2064: "Índice ausente",
	t2065: "Índice inválido",
	t2066: "O relé não pode ser cronometrado",
	t2067: "O nome que está sendo alterado tem muitos caracteres. Se alterar store_name ou unit_name, deve ter < = 16 caracteres, se alterar storeId1 ou storeId2, deve ter < = 8 caracteres.",
	t2068: "Elementos ausentes associados ao elemento de comando.",
	t2069: "Tag_ID não definido",
	t2070: "Nó folha ausente para dados",
	t2071: "Nenhuma alteração nos dados",
	t2072: "Falha ao atualizar o banco de dados",
	t2073: "Não gravável",
	t2074: "Erro do medidor de derramamento",
	t2075: "Sem metros",
	t2076: "Nível de galpão inválido",
	t2077: "Tipo inválido",
	t2078: "Sistema ocupado",
	t2079: "Ativos não configurados para redução de carga",
	t2080: "Classificação de potência inválida para o ativo",
	t2081: "Atraso de inicialização inválido para o ativo",
	t2082: "Configuração ocupada",
	t2083: "Varredura de E/S em andamento",
	t2084: "Muitos degelos em andamento",
	t2085: "Tentando carregar uma versão ruim do banco de dados na unidade",
	t2086: "Falha no carregamento do banco de dados",
	t2087: "Não há memória da web disponível",
	t2088: "Resposta de demanda não configurada (somente o sistema AK355)",
	t2089: "Tamanho de arquivo ausente ou incorreto.",
	t2090: "Arquivo codificado ausente (elementos b64)",
	t2091: "Falha ao salvar o banco de dados no disco RAM.",
	t2092: "Tamanho do arquivo muito grande.",
	t2093: "Falta o nome do arquivo.",
	t2094: "ID do arquivo ausente.",
	t2095: "Índice de carregamento de arquivo ausente (Observação: este é o índice de sequência para load_file load_database).",
	t2096: "O ID do arquivo não corresponde ou está ausente.",
	t2097: "Operação de carregamento de arquivo já em andamento.",
	t2098: "O arquivo decodificado não corresponde ao tamanho do arquivo original.",
	t2099: "A operação de carregamento do arquivo foi cancelada ou não foi iniciada.",
	t2100: "Solicitação de usuário inválida (o solicitante original não corresponde ao endereço IP)",
	t2101: "Sequência de carregamento de arquivo inválida.",
	t2102: "Diretório inválido",
	t2103: "Extensão inválida",
	t2104: "Deslocamento inválido",
	t2105: "Erro ao codificar arquivo",
	t2106: "Erro ao decodificar arquivo",
	t2107: "Falha ao abrir arquivo para leitura/gravação",
	t2108: "Falha ao gravar no arquivo",
	t2109: "O arquivo está protegido contra gravação no sistema de arquivos Flash",
	t2110: "Falha ao excluir arquivo.",
	t2111: "Não é possível excluir a pasta.",
	t2112: "Dispositivos ausentes",
	t2113: "Nem todos os dispositivos do URL foram encontrados",
	t2114: "Parâmetros de histórico ausentes",
	t2115: "Nem todos os parâmetros de histórico do URL foram encontrados",
	t2200: "A versão do software atualmente usada ( {{currentVersion}} ) não é recomendada",
	t2201: "A versão do software usada atualmente ( {{currentVersion}} ) não é recomendada ( > {{recommendedVersion}} recomendado)",
	t2202: "Erro de versão do software",
	t2203: "Editor gráfico",
	t2204: "Editar",
	t2205: "Desenhar",
	t2206: "Nova visualização",
	t2207: "Criado",
	t2208: "Derrubar",
	t2209: "Novo gráfico",
	t2210: "Criar uma nova visualização gráfica",
	t2211: "Nome",
	t2212: "Renomear",
	t2214: "Exportar",
	t2213: "Duplicado",
	t2215: "Excluir",
	t2216: "Tem certeza de que deseja excluir a visualização \" {{name}} \"?",
	t2217: "Exportar todas as visualizações",
	t2218: "Mais...",
	t2219: "Regra",
	t2220: "as regras",
	t2221: "Nome da regra",
	t2222: "Editar painel",
	t2223: "Condições",
	t2224: "Gradiente",
	t2225: "Inverter condições",
	t2226: "Mapeamentos",
	t2227: "Mapear formas por",
	t2228: "ID da forma",
	t2229: "Estados",
	t2230: "Adicionar mapeamento",
	t2231: "Adicionar regra",
	t2232: "Operador",
	t2233: "Adicionar condição",
	t2234: "Comece adicionando regras",
	t2235: "Tem certeza de que deseja excluir a regra \" {{name}} \"?",
	t2236: "Uma cópia da regra é feita",
	t2237: "Visualizações do site",
	t2238: "Você precisa criar visualizações de site para visualizá-las",
	t2239: "Ir para o editor gráfico",
	t2240: "Ir para detalhes",
	t2241: "Selecionar parâmetro",
	t2242: "Nenhum parâmetro encontrado",
	t2243: "Opções",
	t2244: "Entrada Ligar/Desligar",
	t2245: "Saída de Relé",
	t2246: "Entrada do Sensor",
	t2247: "Saída variável",
	t2248: "Dispositivo genérico",
	t2249: "Nó vazio",
	t2250: "O registro de histórico não é suportado...",
	t2251: "Tentar novamente",
	t2252: "Não foi possível buscar o histórico",
	t2253: "O histórico de carregamento pode demorar um pouco e não é compatível com várias consultas ao mesmo tempo. Tente novamente esta solicitação.",
	t2254: "Nome do parâmetro",
	t2255: "Nome do dispositivo",
	t2256: "Tipo de dispositivo",
	t2257: "Nem todos os parâmetros do histórico puderam ser carregados ( {{n}} falhou)",
	t2258: "Nome de usuário ou senha incorretos",
	t2259: "Sem comunicação com o controlador",
	t2260: "Atualização de software",
	t2261: "Sistema",
	t2262: "Reversão de software",
	t2263: "Instalar",
	t2264: "A reversão do software substituirá uma versão mais recente",
	t2265: "Reverter software para a versão de backup {{version}}",
	t2266: "Base de dados",
	t2267: "Uma atualização está disponível",
	t2268: "Exportar banco de dados",
	t2269: "Download",
	t2270: "Baixando banco de dados",
	t2271: "Não é possível exportar o banco de dados",
	t2272: "Arquivo de banco de dados",
	t2999: "Erro de comunicação",
	t2998: "Erro desconhecido",
	t3000: "Detalhes",
	t3001: "Código XML passado aqui",
	t3002: "Atribuir a",
	t3003: "Estado",
	t3004: "Gerenciamento de software",
	t3005: "Arquivo armazenado local",
	t3006: "On-line pela internet",
	t3007: "Certifique-se de que a energia não seja interrompida durante o processo de atualização. Recomenda-se fazer um backup do banco de dados do sistema antes de atualizar seu sistema. Após concluir a(s) atualização(ões) de software, é recomendável inspecionar o status operacional do sistema para confirmar o controle e a operação corretos.",
	t3008: "Nenhum arquivo selecionado",
	t3009: "Selecionar arquivo de software",
	t3010: "Fazendo upload de arquivos",
	t3011: "Verificação de segurança",
	t3012: "Extraindo arquivo",
	t3013: "Verificando arquivo",
	t3014: "Falha no upload do arquivo!",
	t3015: "Falha na verificação de segurança!",
	t3016: "Falha na extração!",
	t3017: "Falha na verificação do arquivo!",
	t3018: "Baixando arquivo: {{fileName}}",
	t3019: "Arquivo baixado com sucesso!",
	t3020: "Falha no Download!",
	t3021: "Fazer upload de arquivos",
	t3022: "Download de arquivos",
	t3023: "Atualização do SO",
	t3024: "Atualização Edf",
	t3025: "Download de arquivos",
	t3026: "Infelizmente, o valor do parâmetro selecionado não estava disponível",
	t3027: "Importar",
	t3028: "É necessário no mínimo um arquivo",
	t3029: "O arquivo não é válido",
	t3030: "Gerenciar visualizações gráficas",
	t3031: "Carregar",
	t3032: "Migrar",
	t3033: "Lendo o(s) arquivo(s) gráfico(s), aguarde...",
	t3034: "Importando arquivo gráfico, aguarde...",
	t3035: "Escolha o gráfico",
	t3036: "Relatórios",
	t3037: "Importar banco de dados",
	t3038: "Selecione o arquivo de banco de dados",
	t3039: "Certifique-se de que a energia não seja interrompida durante o processo de reversão. Recomenda-se fazer um backup do banco de dados do sistema antes da reversão do sistema. Após concluir a reversão do software, é recomendável inspecionar o status operacional do sistema para confirmar o controle e a operação corretos.",
	t3040: "O System Manager será reiniciado após a atualização",
	t3041: "Expandir",
	t3042: "Mostrar mensagens de depuração",
	t3043: "Máx. caracteres permitidos -",
	t3044: "Faça o upload do arquivo de atualização",
	t3045: "Versão atual do SW",
	t3046: "Versão atual do Danux",
	t3047: "Versão mais recente do SW",
	t3048: "Última versão do Danux",
	t3049: "Fazendo backup, aguarde",
	t3050: "Instalando o arquivo por favor aguarde",
	t3051: "Instalando atualização",
	t3052: "O dispositivo está reiniciando",
	t3053: "Reiniciando o gerenciador do sistema",
	t3054: "O navegador será atualizado agora",
	t3055: "Software instalado com sucesso",
	t3056: "Falha na atualização, ocorreu o seguinte erro:",
	t3057: "Faça backup da versão atual do software",
	t3058: "Faça backup dos arquivos EDF atuais",
	t3059: "A visualização gráfica foi salva com sucesso",
	t3060: "Aplicar imagem de fundo",
	t3061: "Claro",
	t3062: "Refazer",
	t3063: "Loja",
	t3064: "Contratempos da noite do circuito",
	t3065: "Contratempos da noite de sucção",
	t3066: "Versão {{fileName}}",
	t3067: "Para atualizar as unidades selecionadas, pressione 'Instalar'",
	t3068: "Reverter a versão {{updateType}}",
	t3069: "Instalando",
	t3070: "Instale a versão de reversão",
	t3071: "Nenhuma das unidades disponíveis contém qualquer versão de rollback",
	t3072: "Nova versão disponível online",
	t3073: "Carregar arquivo para unidades selecionadas",
	t3074: "Carregar arquivo para a unidade selecionada",
	t3075: "Nenhuma versão de reversão disponível para a unidade",
	t3076: "Download concluído",
	t3077: "Versão atual {{updateType}}",
	t3078: "Versão mais recente do {{updateType}}",
	t3079: "A atualização está em andamento. Tem certeza de que deseja fechar a janela?",
	t3080: "Danux não está atualizado! Atualize o Danux e atualize o software novamente",
	t3081: "Os aplicativos não podem ser rebaixados para esta versão",
	t3082: "Informações do aplicativo",
	t3083: "Notas de lançamento",
	t3084: "Termos e privacidade",
	t3085: "Licenças",
	t3086: "Por favor, vá para configuração para definir horários",
	t3087: "Infelizmente ocorreu um erro ao preparar a migração. Certifique-se de que todas as unidades estejam acessíveis na rede e configuradas corretamente. \n\n O seguinte erro ocorreu:\n {{error}}",
	t3088: "Importar ou migrar um gráfico existente",
	t3089: "Tela",
	t3090: "Nome do Ativo",
	t3091: "Colapso",
	t3092: "Importação concluída",
	t3093: "Segurança de descarga",
	t3094: "Segurança de sucção",
	t3095: "Descarga Cutina",
	t3096: "Corte de descarga",
	t3097: "Recorte de sucção",
	t3098: "Cutina de Sucção",
	t3099: "Capacidade",
	t3100: "Os arquivos de migração (flp, dpj ou json) não foram encontrados",
	t3101: "Arquivo de análise de tempo limite",
	t3102: "Arquivos e endereços de dispositivos de terceiros",
	t3103: "Rede",
	t3104: "Trancar",
	t3105: "XML",
	t3106: "Selecione o parâmetro a ser exibido",
	t3107: "Parâmetro do dispositivo",
	t3108: "Nenhuma unidade",
	t3109: "Não há unidade(s) encontrada(s) ou carregada(s)",
	t3110: "HACCP",
	t3111: "ATIVO",
	t3112: "Não há dados haccp disponíveis",
	t3113: "Informações da unidade",
	t3114: "Atualizações disponíveis",
	t3115: "Ir para Software",
	t3116: "Não conectado",
	t3117: "O escopo de acesso atual para este aplicativo é ' {{targetLevel}} ', o usuário fornecido está acima desse escopo em termos de direitos de acesso",
	t3118: "Exportando PDF",
	t3119: "Falha na exportação de PDF",
	t3120: "Em grandes conjuntos de dados, a interface pode não responder e demorar um pouco para ser concluída",
	t3121: "Por favor, selecione os horários da lista",
	t3122: "Por favor, vá para a configuração para definir uma programação",
	t3123: "Sucesso",
	t3124: "Atenção",
	t3125: "Informação do Site",
	t3126: "Selecione o tempo e os pontos de dados da lista",
	t3127: "Conectando",
	t3128: "Não foi possível estabelecer conexão com este site, entre em contato com o suporte",
	t3129: "Nenhum dado HACCP estava disponível na data escolhida. Certifique-se de que o histórico e a hora estejam configurados corretamente no menu de configuração",
	t3130: "Relatório de diagnóstico",
	t3131: "Selecione a(s) unidade(s) para exportação do relatório de diagnóstico",
	t3132: "Não disponível neste nível de acesso do usuário",
	t3133: "Registro de diagnóstico",
	t3134: "falha ao baixar o relatório",
	t3135: "Fonte",
	t3136: "tamanho da fonte",
	t3137: "Não há visualizações para migrar",
	t3138: "Baixe a versão Desktop",
	t3139: "Devido a restrições de segurança em navegadores modernos (",
	t3140: "RFC1918",
	t3141: "), infelizmente, não é possível conectar-se a dispositivos em um espaço de rede privada usando HTTP não criptografado. Configure seu gerenciador de sistema para usar HTTPS ou use a versão para desktop",
	t3142: "Não há dados disponíveis para o período selecionado",
	t3143: "Configure seu gerenciador de sistema para usar HTTPS ou use a versão desktop.",
	t3144: "Não foi possível comunicar com o System Manager. Isso significa que seu dispositivo não pode se conectar ao IP ou nome de host do System Manager ou que você pode precisar confiar manualmente no certificado SSL usado no dispositivo.",
	t3145: "Clique no link a seguir para abrir uma nova guia para confiar no certificado e, em seguida, retornar a esta tela.",
	t3146: "Assinatura",
	t3147: "DI - entradas digitais",
	t3148: "AI - entradas analógicas",
	t3149: "Vá para a configuração HACCP",
	t3150: "Sem dados",
	t3151: "O backup é salvo no System Manager para o recurso Rollback",
	t3152: "Selecione o que exibir nas visualizações do site",
	t3153: "Unidade de recursos",
	t3154: "Arquivos gráficos",
	t3155: "Infelizmente aconteceu um erro ao buscar os arquivos edf e as versões do gerenciador do sistema...",
	t3156: "Para selecionar quais arquivos instalar, pressione o botão 'Escolher arquivos'",
	t3157: "Escolher os arquivos",
	t3158: "Removendo backup",
	t3159: "Arquivos de teste",
	t3160: "O backup é salvo no System Manager para o recurso Rollback",
	t3161: "Tamanho instalado",
	t3162: "Novo tamanho",
	t3163: "Data de instalação",
	t3164: "Nova data",
	t3165: "Sensores",
	t3166: "Relés",
	t3167: "Entradas",
	t3168: "Saídas",
	t3169: "Metros",
	t3170: "Genérico",
	t3171: "A senha expirou, digite uma nova senha",
	t3172: "Nova Senha",
	t3173: "Confirme sua senha",
	t3174: "Mudar senha",
	t3175: "A senha deve conter no mínimo 8 caracteres 1 maiúscula, 1 minúscula, 1 número, 1 caractere especial)",
	t3176: "As senhas não coincidem",
	t3177: "A senha não pode ser igual à senha antiga",
	t3178: "Alteração de senha bem-sucedida",
	t3179: "A senha não pode ser alterada. Erro do dispositivo",
	t3180: "Redefinição de senha",
	t3181: "Gerado",
	t3182: "Nome do site",
	t3183: "ID do evento",
	t3184: "Tempo",
	t3185: "Função",
	t3186: "Informações do evento",
	t3187: "Reconhecer alarmes selecionados",
	t3188: "Reconhecer todos os alarmes selecionados",
	t3189: "Importar banco de dados para",
	t3190: "Importando banco de dados",
	t3191: "O tipo de arquivo deve ser .zip",
	t3192: "Selecione o arquivo",
	t3193: "Horário de funcionamento",
	t3194: "Feriados",
	t3195: "Horários de ligar/desligar",
	t3196: "De ativos",
	t3197: "Você tem alterações não salvas. Você tem certeza de que quer sair?",
	t3198: "Infelizmente a migração falhou. O sistema está atualmente ocupado. Por favor, tente novamente mais tarde",
	t3199: "Carregar imagem ajustando-se às dimensões atuais",
	t3200: "Substituir imagem de fundo",
	t3201: "Substitua imagens de fundo por gráficos vetoriais escaláveis.",
	t3202: "Apenas os arquivos SVG com o mesmo nome dos arquivos existentes são substituídos.",
	t3203: "Selecione o(s) arquivo(s) para substituir",
	t3204: "Arquivos de imagem",
	t3205: "não pode ser processado. O arquivo tem formato inválido.",
	t3206: "{{amount}} arquivo(s) substituído(s) com sucesso",
	t3207: "{{amount}} arquivo(s) não foram substituídos",
	t3208: "A vista não tem fundo",
	t3209: "Você pode fazer upload de uma nova ou substituir uma imagem de plano de fundo existente",
	t3210: "Se você estiver substituindo uma imagem de fundo, as posições dos parâmetros podem ser mantidas se:",
	t3211: "Um arquivo png ou jpg tem a mesma resolução que o plano de fundo atual",
	t3212: "Um arquivo svg tem a mesma proporção (mas não necessariamente a mesma resolução)",
	t3213: "O recurso está desativado",
	t3214: "Atualmente mostrando o último valor recebido, atualizando...",
	t3215: "Carregar um arquivo gráfico criado em",
	t3216: "(opcional)",
	t3217: "Imagem de fundo",
	t3218: "Navegar",
	t3219: "Mais...",
	t3220: "Adicione uma imagem de fundo (opcional)",
	t3221: "Arquivo gráfico",
	t3222: "Tempo limite na comunicação",
	t3223: "Nenhuma programação de refrigeração configurada",
	t3224: "Índice",
	t3225: "Modelo",
	t3226: "Feriados",
	t3227: "Dias",
	t3228: "Sobre",
	t3229: "Desligado",
	t3230: "EU IRIA",
	t3231: "Abrir",
	t3232: "Começar",
	t3233: "Fim",
	t3234: "Descrição",
	t3235: "Dia",
	t3236: "Perto",
	t3237: "Arquivo(s) Edf carregado(s) com sucesso. Reiniciando o gerenciador do sistema",
	t3238: "O upload do(s) arquivo(s) Edf foi concluído, o gerenciador do sistema foi redefinido com sucesso",
	t3239: "Erro ao buscar arquivo(s) edf do gerenciador de sistema",
	t3240: "Falha no upload do(s) arquivo(s) Edf. Tente depois de algum tempo",
	t3241: "Nenhuma programação de refrigeração configurada",
	t3242: "Carregar arquivo para o gerenciador de sistema",
	t3243: "Especifique o tipo de dispositivo - afeta os tags de parâmetro necessários para operá-lo",
	t3244: "Especifique como os valores do dispositivo são acessados e qual função eles cumprem",
	t3245: "Crie um alarme com base em um bit de um parâmetro",
	t3246: "Rótulos",
	t3247: "Crie uma lista de rótulos que podem ser atribuídos a um parâmetro",
	t3248: "Grupos",
	t3249: "Especificar grupos de parâmetros",
	t3250: "Número de registro",
	t3251: "Tipo de dados",
	t3252: "Lista de marcadores",
	t3253: "Mínimo",
	t3254: "Predefinição",
	t3255: "Máximo",
	t3256: "Tipo de registro",
	t3257: "O tipo de registro é traduzido para códigos de função correspondentes para leitura e escrita",
	t3258: "Modo de troca",
	t3259: "Para um valor com bytes ABCD enviado ao dispositivo, esta opção troca bytes (BADC), palavras (CDAB) ou ambos (DCBA).",
	t3260: "Somente leitura",
	t3261: "Exploração madeireira",
	t3262: "Habilita o registro de histórico para este parâmetro",
	t3263: "Assuma o texto do parâmetro para o alarme",
	t3264: "Posição do sinalizador de alarme contando a partir deles o bit menos significativo do parâmetro",
	t3265: "Disparar alarme em valor baixo",
	t3266: "Etiqueta",
	t3267: "Esconder",
	t3268: "{{field}} é um campo obrigatório.",
	t3269: "O máximo deve ser maior ou igual ao mínimo",
	t3270: "O valor padrão deve estar em [Mínimo, Máximo]",
	t3271: "O tipo de dados deve ser {{reg}} para o tipo de registro selecionado {{mt}}",
	t3272: "O nome não deve conter sinais delimitadores",
	t3273: "O nome não deve exceder 20 caracteres",
	t3274: "A lista de marcadores não deve estar vazia",
	t3275: "A(s) tag(s) {{taglist}} deve(m) ser atribuída(s) exatamente uma vez.",
	t3276: "O(s) grupo(s) {{grouplist}} contém mais do que o máximo de 50 parâmetros.",
	t3277: "O formulário contém erros. Revise os erros e tente novamente.",
	t3278: "Enviar",
	t3279: "Para ativar o EDF carregado, você deve reinicializar a unidade. Deseja redefinir a unidade agora?",
	t3280: "Redefinir agora",
	t3281: "Redefinir mais tarde",
	t3282: "{{file}} já existe. Você deseja sobrescrever isso?",
	t3283: "Carregar {{what}}",
	t3284: "Rótulos e valores devem ser exclusivos.",
	t3285: "Multi",
	t3286: "O dispositivo lida com vários casos ou pacotes",
	t3287: "Tag",
	t3288: "O Internet Explorer está em fim de vida e não é suportado pelo StoreviewWeb",
	t3289: "Mude para um navegador compatível, Edge, Chrome ou Firefox",
	t3290: "Mais Informações",
	t3291: "Perto",
	t3292: "Falha ao ler os dados, tente novamente",
	t3293: "EDFs de backup em andamento",
	t3294: "Não há visualizações para migrar",
	t3295: "O sistema está configurado com um IP público no campo IP privado",
	t3296: "O sistema está configurado com um IP privado no campo IP público, o sistema pode não estar acessível remotamente ou algumas funções podem não funcionar corretamente",
	t3297: "Você está conectado através de um IP ou nome de host que não é conhecido pelo System Manager, provavelmente através de um serviço proxy, algumas funções podem não funcionar corretamente",
	t3298: "O dispositivo está offline, mostrando o último valor conhecido",
	t3299: "Integração de dispositivos",
	t3300: "As tags {{taglist}} estão duplicadas.",
	t3301: "página não encontrada",
	t3302: "Lamentamos, mas a página solicitada não foi encontrada",
	t3303: "Código de erro: 404",
	t3304: "Ir para a dashboard",
	t3305: "Somente visualização",
	t3306: "Endereço IP interno",
	t3307: "Endereço IP externo",
	t3308: "Nome do domínio",
	t3309: "Sem avisos",
	t3310: "Vários avisos",
	t3311: "Parece que o sistema está configurado com várias unidades, mas não foi possível entrar em contato com uma ou mais unidades escravas, verifique se as configurações estão corretas. Observe que uma configuração de várias unidades não suporta vários nomes de domínio e que cada unidade precisa ser configurada com uma porta distinta ao usar o mesmo domínio",
	t3312: "Somente visualização",
	t3313: "Este elemento não tem associação com nenhum parâmetro e pode ser removido com segurança",
	t3314: "Remover parâmetro",
	t3315: "Cronograma",
	t3316: "Você será desconectado e precisará fazer login com a nova senha",
	t759: "Banco de dados baixado, redefinindo e atualizando a unidade. Isso pode levar vários minutos.",
	t816: "Tipo de Alarme:",
	t818: "Contar :",
	t848: "O arquivo DB não foi criado. Você tem acesso limitado ao diretório instalado. Entre em contato com o administrador do sistema.",
	t3317: "Sem agendamentos para exportar",
	t3318: "Aguardando resposta do sistema",
	t3319: "Nenhuma atividade de rede",
	t3320: "Nome da loja",
	t3321: "Nova versão {{latestVersion}} disponível online",
	t3322: "Porta Externa",
	t3323: "Porta Interna",
	t3324: "Não podemos nos comunicar com o gerente do sistema em",
	t3325: "talvez o certificado precise ser confiável?",
	t3326: "Arquivo deste formato não pode ser removido",
	t3327: "Uso",
	t3328: "Na hora",
	t3329: "Tempo de folga",
	t3330: "Dias da semana",
	t3331: "Estágio de Resfriamento",
	t3332: "Recuperação de calor",
	t3333: "Resfriamento",
	t3334: "Aquecimento",
	t3335: "Aquecimento auxiliar",
	t3336: "Desumidificação",
	t3337: "Internet",
	t3338: "Mensagens",
	t3339: "Contatos",
	t3340: "Comm",
	t3341: "Nenhum item encontrado",
	t3342: "Escreva para pesquisar",
	t3408: "Habilitar tudo",
	t3409: "Desativar tudo",
	t3410: "Normal",
	t3411: "Forte",
	t3412: "Crítico",
	t3413: "Habilitar todos os alarmes",
	t3414: "Pesquisar por alarmes",
	t3415: "Habilitado",
	t3417: "Erro ao buscar alarmes",
	t3418: "Erro ao buscar dispositivos para copiar",
	t3419: "Erro ao verificar o status da cópia",
	t3420: "A pesquisa automática será retomada após um tempo limite máximo de 2 horas ou se o assistente for cancelado ou concluído",
	t3421: "Copiar para o(s) controlador(es)",
	t3422: "Copiando para o(s) dispositivo(s) selecionado(s)",
	t3423: "A função de cópia primeiro copiará as configurações para o banco de dados do System Manager e, em seguida, baixará as configurações para os dispositivos do controlador. Pode levar algum tempo para concluir a ação.",
	t3424: "Nenhum dispositivo selecionado para copiar",
	t3425: "Clique duas vezes para copiar",
	t3426: "Erro ao buscar dispositivos",
	t3427: "Dispositivos para cópia",
	t3428: "Selecione o(s) dispositivo(s) para cópia",
	t3429: "Somente controladores do mesmo tipo serão mostrados aqui",
	t3430: "Processo de cópia bem-sucedido",
	t3431: "Arquivo inválido. Modelo de arquivo esperado:",
	t3432: "Erro ao carregar arquivo de configurações",
	t3435: "Tem certeza de que deseja mover o dispositivo selecionado para este grupo {{toPackGroup}} ?",
	t3343: "Descoberta",
	t3344: "Atualizar tabela",
	t3345: "Escaneando redes, aguarde...",
	t3346: "A tabela de rede é atualizada",
	t3347: "Erro ao atualizar redes",
	t3348: "Erro ao escanear novamente as redes",
	t3349: "Erro ao buscar redes",
	t3350: "Verificação de rede concluída",
	t3351: "Nenhum dispositivo disponível",
	t3352: "Verificar rede",
	t3353: "Não cfg",
	t3354: "Qualquer reorganização de dispositivos no assistente pode afetar os serviços de monitoramento do Alsense.",
	t3355: "Rede",
	t3356: "Selecione o tipo de barramento de campo",
	t3357: "Se o Modbus/RS485 estiver habilitado, você pode selecionar SLV para o compressor de velocidade variável Danfoss. Ao selecionar SNMP como um barramento local, você será solicitado a inserir um intervalo de endereço de sub-rede válido pelos controles SNMP da Danfoss",
	t3358: "Compressores/Pacotes",
	t3359: "Gerenciar controladores de compressores/pacotes",
	t3360: "Somente dispositivos Pack podem ser gerenciados aqui - não o sistema Rack I/O. Controladores de Pack Multi-Suction compatíveis que foram escaneados aparecerão inicialmente como 0 grupos de sucção. O AK-ST 500 (Service Tool) é necessário para configurar o controlador Pack.",
	t3361: "Quantidade a copiar",
	t3362: "Reduzir o grupo de sucção configurado anteriormente também removerá quaisquer controles de evaporação associados a esse grupo de sucção.",
	t3363: "O número de grupos de sucção não pode ser zero, o número mínimo de grupos de sucção deve ser pelo menos um.",
	t3364: "Erro ao buscar compressores/pacotes",
	t3365: "Nenhum compressor/pacote disponível",
	t3366: "Dispositivos on-line",
	t3367: "Dispositivos Off-line",
	t3369: "Adicionar pacote",
	t3370: "Se selecionar Modbus/RS485, você verá opções adicionais para SLV/ECFan, elas são usadas para refrigeração e podem ser selecionadas como Não.",
	t3371: "Adicionar circuito",
	t3372: "Adicione, copie ou remova dispositivos. Observe que nem todos os tipos de dispositivos permitem edições de todos os campos e os endereços dos pontos de E/S são definidos na configuração.",
	t3373: "Gerenciar controladores de caso",
	t3374: "Adicionar um dispositivo",
	t3375: "Gerenciar controladores de compressor/pacote",
	t3376: "Erro no acesso aos dados",
	t3377: "O layout HVAC foi aplicado com sucesso",
	t3378: "Layout HVAC",
	t3379: "Tem certeza de que deseja sair desta página?",
	t3380: "Suas configurações podem ser perdidas",
	t3381: "Por favor configure um dispositivo",
	t3382: "Sair da configuração rápida",
	t3383: "Tem certeza de que deseja sair da configuração rápida?",
	t3384: "Portais (AK-PI200)",
	t3385: "Selecione os gateways encontrados",
	t3386: "Endereço IP",
	t3387: "Controlador de fonte",
	t3388: "Configurações de origem",
	t3389: "Alarmes de origem",
	t3390: "Gráfico de origem",
	t3392: "Gráfico",
	t3393: "Devices",
	t3394: "A sondagem automática é suspensa durante a fase de cópia. O interruptor principal do(s) dispositivo(s) de origem e destino será desligado e retornará ao estado original, assim que a cópia for concluída. Certifique-se de verificar o interruptor principal após o assistente de cópia ser concluído. Se algum dos dispositivos selecionados estiver em alarme ativo, qualquer modificação na ação de alarme para esse ponto específico não será modificada. A configuração original permanecerá.",
	t3395: "Selecione um dispositivo de origem para copiar as configurações",
	t3396: "Na hora certa",
	t3397: "A função de cópia copiará os pontos de gráfico disponíveis até que a capacidade da unidade seja atingida. Vá para Configuração para ver o número de pontos definidos",
	t3398: "Gráficos de dispositivos de origem",
	t3399: "Configurar pontos de gráfico para o controlador de origem",
	t3400: "Habilitar HACCP para registrar os pontos em um relatório de temperatura HACCP",
	t3401: "Pesquisar por pontos",
	t3402: "Não há dados disponíveis",
	t3403: "Erro ao buscar pontos do gráfico",
	t3404: "Desativado",
	t3405: "Alarmes de dispositivos de origem",
	t3406: "Configurar detalhes do alarme no controlador de origem",
	t3407: "Nome do alarme",
	t3436: "O dispositivo será removido do grupo atribuído [ {{fromPackGroup}} ]",
	t3437: "Layout Refrigeração",
	t3438: "O layout de refrigeração foi aplicado com sucesso",
	t3439: "Reatribuir",
	t3440: "Atribuir controles de caso aos controles de pacote",
	t3441: "Atribuir controles de caso (de casos disponíveis) aos controles de compressor/pacote, formando grupos de sucção",
	t3442: "Parâmetros do dispositivo",
	t3443: "Selecione parâmetros para cópia",
	t3444: "Ao trabalhar em um dispositivo ativo (on-line), pode haver um atraso nas configurações devido à comunicação com o dispositivo.",
	t3445: "Selecione alarmes para cópia",
	t3446: "Gráfico de dispositivo",
	t3447: "Selecione pontos do gráfico para cópia",
	t3448: "Salvar as configurações atuais do dispositivo em um arquivo",
	t3449: "Você pode salvar as configurações atuais do dispositivo em um arquivo que pode ser usado para configurar dispositivos posteriormente. Se desejar sair do assistente após salvar o arquivo, use o ícone de saída. Se estiver trabalhando com dispositivos conectados, sair garantirá a pesquisa de rede e o interruptor principal do dispositivo retornará ao status correto.",
	t3450: "Arquivo(s) de configuração salvo(s) com sucesso",
	t3451: "Deseja continuar ou sair da Configuração rápida?",
	t3452: "Salvando arquivo(s)",
	t3453: "Erro ao buscar configurações",
	t3454: "Configurações do dispositivo de origem",
	t3455: "Configurar parâmetros do dispositivo no controlador de origem",
	t3456: "Nenhum dos campos de senha deve estar vazio",
	t3457: "Recentes",
	t3458: "Lista de Endereços",
	t3459: "Último acesso",
	t3460: "Faça backup do Catálogo de Endereços regularmente.",
	t3461: "O catálogo de endereços foi importado com sucesso",
	t3462: "Importar Lista Endereços",
	t3463: "Exportar Lista Endereços",
	t3464: "Adicionar site",
	t3465: "Editar site",
	t3466: "Excluir site",
	t3467: "Você precisa criar uma senha para o Catálogo de Endereços antes de começar a usar o Catálogo de Endereços",
	t3468: "O site foi adicionado com sucesso ao seu catálogo de endereços",
	t3469: "Já no catálogo de endereços",
	t3470: "Adicionar ao catálogo de endereços",
	t3471: "Entrar no site",
	t3472: "As informações do site foram atualizadas com sucesso",
	t3474: "Notificação",
	t3475: "O site foi removido com sucesso do seu catálogo de endereços",
	t3476: "O site foi atualizado com sucesso",
	t3477: "Formato inválido do catálogo de endereços",
	t3478: "Ações",
	t3479: "Não disponível quando offline",
	t3480: "Falha ao carregar o banco de dados S55",
	t3481: "Carregando banco de dados S55",
	t3482: "Protocolo",
	t3483: "O usuário não tem permissão para ver informações do software",
	t3484: "A senha deve ser alfanumérica ou apenas numérica",
	t3485: "Você precisa criar uma senha do Catálogo de Endereços para acessar o Catálogo de Endereços",
	t3486: "A senha do catálogo de endereços não está correta",
	t3487: "O site já está presente no catálogo de endereços",
	t3488: "Crie uma senha do Catálogo de Endereços para usar o Catálogo de Endereços. A senha deve ser alfanumérica ou numérica somente",
	t3489: "Digite sua senha do catálogo de endereços",
	t3490: "Nenhum dispositivo online disponível para cópia",
	t3491: "Criar senha do catálogo de endereços",
	t3492: "Atualizar senha do catálogo de endereços",
	t3493: "A senha do site não pode estar vazia",
	t3494: "Digite uma senha do catálogo de endereços que está sendo importado",
	t3495: "A senha do catálogo de endereços foi criada com sucesso",
	t3496: "A senha do catálogo de endereços foi atualizada com sucesso",
	t3497: "A senha do catálogo de endereços que está sendo importado não está correta",
	t3498: "Por favor, insira a senha do site",
	t3499: "Limpar catálogo de endereços",
	t3500: "Pesquisar sites",
	t3501: "Selecione o arquivo do catálogo de endereços para importar",
	t3502: "Selecione o arquivo de chave para descriptografar o catálogo de endereços",
	t3503: "Pressione exportar para baixar o catálogo de endereços criptografado",
	t3505: "Com criptografado",
	t3506: "senhas não criptografadas",
	t3507: "Chave secreta inválida",
	t3508: "Falha na importação. Certifique-se de ter importado a chave adequada",
	t3509: "Nenhuma versão disponível",
	t3510: "Serviços Open Alsense (requer assinatura)",
	t3511: "Por favor, revise os termos do EULA cuidadosamente. Se você não puder aceitar os termos, você não tem permissão para usar o aplicativo.",
	t3512: "O catálogo de endereços e a senha do catálogo de endereços foram apagados com sucesso",
	t3513: "(obrigatório)",
	t3515: "O catálogo de endereços está vazio",
	t3516: "A senha do catálogo de endereços não pode estar vazia",
	t3519: "Ao limpar o Catálogo de Endereços, você também removerá sua senha.",
	t3520: "Fora do intervalo. Deve estar entre 0 e {{maxAddr}}",
	t3521: "Pressione 'Exportar' para baixar todas as visualizações gráficas armazenadas no Gerenciador do Sistema",
	t3522: "Pressione 'Download' para baixar um relatório do sistema",
	t3523: "Somente a unidade mestre está disponível em uma conexão WiFi direta",
	t3524: "Não há visualizações gráficas locais. Adicione novas visualizações ou importe visualizações existentes",
	t3525: "Não há visualizações gráficas remotas. Tente pesquisar com nome diferente",
	t3526: "Não há visualizações gráficas remotas. Adicione novas, importe ou migre visualizações existentes",
	t3527: "Visualizações locais ( {{totalFiles}} de 5)",
	t3528: "{{totalFiles}} gráficos locais de um máximo de 5",
	t3529: "Visualizações Remotas",
	t3530: "O arquivo não é um bitmap.",
	t3531: "Comprimento do bitmap incorreto.",
	t3532: "Tamanho do cabeçalho do bitmap incorreto.",
	t3533: "Tamanho do bitmap incorreto.",
	t3534: "Erro no tamanho da paleta.",
	t3535: "Arquivo .bmp incompatível",
	t3536: "Erro ao excluir arquivo gráfico local",
	t3537: "Erro ao duplicar arquivo gráfico local",
	t3538: "Erro ao exportar arquivo gráfico local",
	t3539: "Excluir gráfico",
	t3540: "Excluir um arquivo gráfico local",
	t3541: "A exclusão do arquivo gráfico local está em andamento",
	t3542: "Arquivo gráfico local excluído com sucesso. Reiniciando o System Manager",
	t3543: "Exclusão do arquivo gráfico local concluída. O System Manager foi reiniciado com sucesso",
	t3544: "Gráfico duplicado",
	t3545: "Duplicar um arquivo gráfico local",
	t3546: "A duplicação do arquivo gráfico local está em andamento",
	t3547: "Arquivo gráfico local duplicado com sucesso. Reiniciando o System Manager",
	t3548: "Duplicação do arquivo gráfico local concluída. O System Manager foi reiniciado com sucesso",
	t3549: "Exportar gráfico",
	t3550: "Exportar um arquivo gráfico local",
	t3551: "A exportação do arquivo gráfico local está em andamento",
	t3552: "Arquivo gráfico local exportado com sucesso",
	t3553: "O Gerenciador do Sistema será reiniciado nesta ação",
	t3554: "Tem certeza de que deseja excluir",
	t3555: "Tem certeza de que deseja duplicar",
	t3556: "Pressione 'Exportar' para baixar todas as visualizações gráficas locais armazenadas no Gerenciador do Sistema",
	t3557: "A exportação de arquivos gráficos locais está em andamento",
	t3558: "Arquivos gráficos locais exportados com sucesso",
	t3559: "Código do produto",
	t3560: "Obtenha mais informações sobre este produto",
	t3561: "Fã ecológico",
	t3562: "Abertura de válvula",
	t3563: "Superaquecimento",
	t3564: "Pressão do evaporador",
	t3565: "Temperatura do evaporador",
	t3566: "Recortar",
	t3567: "Corte",
	t3568: "Porta do gabinete",
	t3569: "Temperatura de sucção",
	t3570: "Calor ferroviário",
	t3571: "Limite máximo de arquivos permitidos atingido. Desmarque os arquivos para importar",
	t3572: "Por favor, exclua os arquivos existentes no gerenciador do sistema para importar os arquivos",
	t3573: "Arquivo gráfico local importado com sucesso. Reiniciando o System Manager",
	t3574: "A importação de arquivo(s) gráfico(s) local(ais) foi concluída. O System Manager foi reiniciado com sucesso",
	t3575: "Limite máximo de arquivos permitidos atingido. Excluir arquivo(s) para importar",
	t3576: "O máximo de arquivos gráficos permitidos é 5",
	t3577: "Arquivo gráfico local carregado com sucesso. Reiniciando o System Manager",
	t3578: "Upload do arquivo gráfico local concluído. O System Manager foi reiniciado com sucesso",
	t3579: "Formato de arquivo inválido. Carregar arquivo {{supportedFileFormats}}",
	t3580: "A dimensão do arquivo deve ser {{maxWidthAllowed}} x {{maxHeightAllowed}} pixels para telas locais",
	t3581: "Visualização remota",
	t3582: "Visão local",
	t3583: "Página inicial da Storeview Web",
	t3584: "Termos do EULA",
	t3585: "Segurança",
	t3586: "Níveis de aplicação de segurança",
	t3587: "Compatível com versões anteriores (nível de segurança mais baixo - assumindo que o uso de autenticação de cabeçalho esteja desabilitado)",
	t3588: "As solicitações SvW/XML contêm nome de usuário e senha.",
	t3589: "Nenhuma higienização de solicitações, mesma segurança das versões anteriores",
	t3590: "Nenhuma alteração necessária em conexões SvW ou XML existentes",
	t3591: "Permissivo (pode ser usado como uma transição para o nível estrito)",
	t3592: "As solicitações XML podem conter nome de usuário e senha, e/ou um token de sessão. O token de sessão é recebido na resposta da solicitação de login.",
	t3593: "Se o token de sessão for fornecido no cabeçalho AKSM-auth, nomes de usuário e senhas serão ignorados",
	t3594: "A solicitação XML é higienizada, um aviso é retornado na resposta da solicitação se algum problema for descoberto",
	t3595: "Tome nota destes avisos em preparação para mudar para o modo estrito",
	t3596: "Se o token de sessão não for usado, a expiração da sessão não poderá ser aplicada",
	t3597: "Já suportado na IU remota (SvW)",
	t3598: "Alterações possíveis/necessárias da perspectiva XML de terceiros",
	t3599: "Nenhum uso de token de sessão - nenhuma alteração necessária",
	t3600: "Uso do token de sessão - remova o nome de usuário/senha do corpo e observe os avisos em preparação para o Strict",
	t3601: "Rigoroso (nível de segurança mais alto)",
	t3602: "As solicitações XML não podem conter nomes de usuários e senhas, mas devem fornecer o token de sessão no cabeçalho AKSM-auth. Se um problema for descoberto na solicitação, ela será rejeitada",
	t3603: "Já suportado em (SvW)",
	t3604: "Mudanças necessárias da perspectiva de terceiros",
	t3605: "nome de usuário e senha devem ser removidos da carga útil da solicitação XML e o token de sessão deve ser colocado em um cabeçalho CORS chamado AKSM-auth",
	t3606: "Falha de login Back-off",
	t3607: "Quanto tempo (segundos) um usuário remoto espera para tentar fazer login novamente após uma tentativa com falha. O tempo de espera aumenta cumulativamente com cada tentativa com falha. Após 10 tentativas, o IP do qual o usuário tentou fazer login é bloqueado por 24 horas. Se definido como 0, o back-off de falha de login é desabilitado",
	t3608: "Padrão 30s (máx. 600s / 0 = desabilitado)",
	t3609: "Tentativas gratuitas",
	t3610: "Quantas tentativas de login são permitidas antes que o recuo seja aplicado",
	t3611: "Padrão 2 (máx. 5)",
	t3612: "Bloquear",
	t3613: "Se habilitado, o Usuário + IP são bloqueados durante o back-off, caso contrário, o Usuário é bloqueado",
	t3614: "'Usuário' padrão (Usuário + IP / Usuário)",
	t3615: "Sessões por usuário",
	t3616: "Uma sessão é quando um usuário efetua login com sucesso na unidade e está dentro da interface remota (SvW)",
	t3617: "Número de Sessões ativas que um usuário pode ter. Se definido como 0, um usuário pode ter a quantidade máxima de Sessões (30)",
	t3618: "Padrão 0",
	t3619: "Expiração da sessão",
	t3620: "Quantos segundos após uma sessão ociosa expirar. Se uma sessão expirar, um novo login será necessário",
	t3621: "Padrão 900s (máx. 3.600)",
	t3622: "Autenticação de Cabeçalho",
	t3623: "Ao habilitar HTTPS e autenticação de cabeçalho, todas as solicitações XML de terceiros devem passar credenciais de nome de usuário/senha no cabeçalho (esquema de autenticação básico)",
	t3624: "Habilitar 'Use Header Authentication' requer que o sistema seja reinicializado. Use o botão 'Press to Initialize' para executar esta ação",
	t3625: "Pequeno",
	t3626: "Grande",
	t3627: "Salvar gráfico",
	t3628: "Salvar um arquivo gráfico local",
	t3629: "Arquivo gráfico local excluído com sucesso. O carregamento do arquivo gráfico local está em andamento",
	t3630: "O arquivo gráfico local foi carregado com sucesso. Reiniciando o System Manager",
	t3631: "O salvamento do arquivo gráfico local foi concluído. O System Manager foi reiniciado com sucesso",
	t3632: "Tem certeza de que deseja salvar?",
	t3633: "Falha na análise",
	t3634: "Não foi possível analisar o arquivo viz",
	t3635: "Não foi possível analisar o arquivo vz2",
	t3636: "Falha na abertura do arquivo Viz:",
	t3637: "Os arquivos de migração (vz2, dpj ou json) não puderam ser encontrados",
	t3638: "Os arquivos de migração (ou seja) não puderam ser encontrados",
	t3639: "O System Manager reiniciará com esta ação. O System Manager está no modo permissivo ou estrito, então o token de sessão expirará na reinicialização e você será desconectado do sistema",
	t3643: "Pressão de descarga",
	t3644: "Potência total kW",
	t3645: "KVAR total",
	t3646: "Total Kvarh+",
	t3647: "Demanda",
	t3648: "Fator de potência",
	t3649: "Cor da fonte",
	t3650: "Cor de fundo",
	t3651: "O catálogo de endereços foi exportado com sucesso",
	t3652: "Modo de controle",
	t3653: "Sc3 Temperatura externa",
	t3654: "Temperatura de descarga",
	t3655: "Capacidade de funcionamento do condensador",
	t3656: "Nenhum dado a ser exportado",
	t3657: "A senha do usuário em {{unit}} expirou",
	t3658: "Não é possível efetuar login em {{unit}} com as credenciais fornecidas.",
	t3659: "Atualize a versão do firmware ou altere o nível de aplicação de segurança para {{unit}} para obter suporte ao token de sessão.",
	t3661: "V3gc",
	t3662: "Seção B",
	t3663: "Seção C",
	t3664: "Controle de superaquecimento adaptativo",
	t3665: "Razão do status de controle",
	t3666: "O usuário não pode efetuar login. O endereço da unidade não está definido na configuração do proxy",
	t3667: "O protocolo de comunicação não está especificado na configuração do proxy. Pode levar a erros",
	t3668: "Informações de segurança de TI",
	t3669: "Em conformidade com os padrões de segurança, o Gerenciador do Sistema agora usa como padrão o Controle de sessão estrito, o que significa que qualquer conexão remota/serviço remoto baseado em XML não funcionará mais, a menos que tokens de sessão sejam aplicados.",
	t3670: "A Danfoss recomenda continuar no modo Strict, mas o modo de compatibilidade com versões anteriores ainda está disponível.",
	t3671: "Observe que isso pode ser alterado no menu Segurança da configuração e não tem impacto no controle local do System Manager nem na operação do site.",
	t3672: "Veja mais informações sobre o controle de sessão",
	t3673: "Modo reverso",
	t3674: "Modo estrito",
	t3675: "Ao clicar no modo de compatibilidade com versões anteriores, todos os Gerenciadores de Sistema aplicáveis com firmware atualizado serão definidos para o modo de compatibilidade com versões anteriores, reiniciados e o aplicativo será desconectado.",
	t3676: "O número máximo de grupos de sucção permitidos é 12",
	t3677: "Ao atualizar com sucesso as unidades selecionadas de 3.3.0 para 4.X ou superior, o usuário será desconectado automaticamente ao clicar no botão Concluir na etapa final.",
	t3678: "Multi case para controladores AK2 (Pack/Case) não são suportados",
	t3679: "Em conformidade com os padrões de segurança introduzidos com a versão 4.0, o System Manager assumirá como padrão o controle de sessão Strict, o que significa que qualquer conexão remota/serviço remoto baseado em XML não funcionará mais, a menos que tokens de sessão sejam aplicados. Além disso, a comunicação será ajustada automaticamente para HTTPS ao atualizar para a versão 4.0. A Danfoss recomenda continuar no modo Strict, mas o modo de compatibilidade com versões anteriores está disponível, o que definirá o sistema de volta para as configurações aplicadas até agora. Observe que se você estiver fazendo esta atualização remotamente em uma unidade HTTP, certifique-se de que sua configuração de TI esteja preparada para HTTPS (porta 443) ou tenha pessoal disponível no local.",
	t3680: "Encontre mais informações sobre a nova implementação de segurança aqui",
	t3681: "O acesso à versão http deste site pode estar bloqueado devido às restrições do seu navegador. Visite nossa página de FAQ para obter instruções sobre como resolver esse problema ou baixe a versão desktop do StoreView Web.",
	t3682: "Perguntas frequentes do gerente do sistema",
	t3683: "u17 Ther. ar",
	t3684: "u91 Temperatura de corte.",
	t3685: "u00 Ctrl. estado",
	t3686: "u12 S3 temperatura do ar.",
	t3687: "u16 S4 temperatura do ar.",
	t3688: "u23 EEV OD %",
	t3689: "Superaquecimento sub-21",
	t3690: "u22 SuperaquecimentoRef",
	t3691: "u26 EvapTemp Te",
	t3692: "S2 Temperatura de saída do gás.",
	t3693: "Stw8",
	t3694: "Sd-MT",
	t3695: "Status do Tw",
	t3696: "Ativar Tw",
	t3697: "Bomba tw funcionando",
	t3698: "Stw3",
	t3699: "Stw4",
	t3700: "Stw2",
	t3701: "Status do controle de Rcv.",
	t3702: "Deslocamento de sucção",
	t3703: "Modo de controle Vrec",
	t3704: "Status do Vrec",
	t3705: "Razão do status Ctrl",
	t3706: "Status do controle HP",
	t3707: "Pgc máx.",
	t3708: "Limite máximo de PGC P-ban",
	t3709: "Pgc mín.",
	t3710: "Capacidade do ejetor de vapor",
	t3711: "Capacitor ejetor de líquido",
	t3712: "Temperatura do ar de referência.",
	t3713: "Temperatura do ar de suprimento.",
	t3714: "Retorno de ar",
	t3715: "Ponto de orvalho da zona",
	t3716: "Umidade da zona",
	t3717: "Temperatura externa.",
	t3718: "Nível de CO2 da zona",
	t3719: "Temperatura da zona.",
	t3720: "Referência de condição",
	t3721: "Status de controle de cond.",
	t3722: "Estado de controle de cond.",
	t3723: "Controle de comando.",
	t3724: "Cond. tampa solicitada.",
	t3725: "Estado V3gc",
	t3726: "V3gc OD",
	t3727: "Temperatura cond.",
	t3728: "Status de RH",
	t3729: "Habilitação de RH",
	t3730: "Solicitação do consumidor de RH",
	t3731: "Shr3",
	t3732: "Shr4",
	t3733: "Shr2",
	t3734: "Shr8",
	t3735: "Ref. de sucção To-MT",
	t3736: "Status de controle MT",
	t3737: "Zona atual MT",
	t3738: "Para optar por compensar MT",
	t3739: "Capacidade de execução MT",
	t3740: "Cap. solicitado. MT",
	t3741: "Hora do próximo passo MT",
	t3742: "Po-MT",
	t3744: "Temperatura de sucção To-MT",
	t3745: "Energia ativa total",
	t3746: "Pico de demanda",
	t3747: "Fator de potência total",
	t3748: "Volts da fase A",
	t3749: "Voltagem da fase B",
	t3750: "Fase C volts",
	t3751: "Amplificadores de fase A",
	t3752: "Amplificadores de fase B",
	t3753: "Amplificadores de fase C",
	t3754: "Potência ativa total",
	t3755: "Temperatura de sucção To-LT",
	t3756: "Temperatura de sucção para TI",
	t3757: "Referência de sucção LT",
	t3758: "Status de controle LT",
	t3759: "Zona atual LT",
	t3760: "Para optar por compensar LT",
	t3761: "Capacidade de execução LT",
	t3762: "Cap. solicitado. LT",
	t3763: "Hora do próximo passo LT",
	t3764: "Po-LT",
	t3765: "SD-LT",
	t3766: "Referência de sucção TI",
	t3767: "Status de controle de TI",
	t3768: "Zona atual de TI",
	t3769: "Capacidade de execução de TI",
	t3770: "Capacidade solicitada de TI",
	t3771: "Hora do próximo passo TI",
	t3772: "Tn Po-TI",
	t2773: "Sd-TI",
	t2774: "Início da Comp. de TI",
	t3774: "Fora do intervalo. Deve estar entre {{minAddr}} e {{maxAddr}}",
	t3775: "Operação desabilitada para este dispositivo",
	t3776: "Após esta ação, o aplicativo será desconectado, o que pode levar alguns instantes.",
	t3777: "Os arquivos de migração (flp ou json) não puderam ser encontrados",
	t3778: "Sondagem",
	t3779: "A votação está suspensa no momento.",
	t3780: "Deseja retomar a votação?",
	t3781: "Medidor de energia",
	t3782: "Vários nós selecionados",
	t3783: "Ampliar para nó",
	t3784: "Unidades de medida",
	t3785: "Arquivo(s) exportado(s) com sucesso",
	t3786: "O endereço não pode ser negativo",
	t3787: "Redefinir zoom",
	t3788: "Erro ao buscar etapas do padrão de preparação",
	t3789: "Erro ao salvar etapas do padrão de preparação.",
	t3790: "Etapas do padrão de preparação salvas com sucesso.",
	t3791: "Padrão de preparação",
	t3792: "Pressione os ícones descarregados para habilitar/desabilitar os descarregadores associados aos compressores.",
	t3793: "Dados ausentes",
	t3794: "Os seguintes parâmetros não retornaram nenhum ponto de dados.",
	t3795: "A confiança HTTPS/SSL foi bem-sucedida.",
	t3796: "Agora você pode efetuar login no StoreView Web Browser.",
	t3797: "Redirecionando para o navegador StoreView em {{timeLeft}} segundos...",
	t3798: "Votação suspensa no momento!",
	t3799: "Alarmes limpos (registros completos do sistema)",
	t3800: "O número máximo de HVAC permitido é 45.",
	t3801: "Falha ao obter o log de alterações",
	t3802: "Conectando ao armazenamento...",
	t3803: "Tempo limite de conexão de armazenamento SCADA",
	t3804: "Falha ao conectar ao armazenamento SCADA",
	t3805: "Dados inválidos",
	t3806: "Selecione a(s) unidade(s) para download do relatório"
};
var pt = {
	lang: lang$8
};

var lang$9 = {
	t0: "Активн. аварии",
	t1: "Сброшенные аварии:",
	t2: "Основной",
	t3: "ИД",
	t4: "Имя устройства",
	t5: "Редактировать записки",
	t6: "НЕ авторизованы для потверждения аварии",
	t7: "Тип аварии",
	t8: "Произошло",
	t9: "Подтвердить",
	t10: "Активные аварии",
	t11: "Сброшено",
	t12: "Расчеты",
	t13: "Сброшенные аварии",
	t14: "Тишина/потвержд",
	t15: "Потвердить все непотвержденные аварии",
	t16: "Неудачная авторизация",
	t17: "Ошибка",
	t18: "Ручное управление",
	t19: "Визуальный",
	t20: "Сводные данные",
	t21: "Технический вид",
	t22: "График (S4)",
	t23: "Узел",
	t24: "Мод",
	t25: "График (Ро и  Рс)",
	t26: "(Нет аварий)",
	t27: "Авария устройства",
	t28: "Смотреть предыдущее устройство",
	t29: "Смотреть следущее устройство",
	t30: "Ошибка чтения аварий",
	t31: "Текущие аварии",
	t32: "Текущие Авария",
	t33: "Не найдены аварии",
	t34: "Тип",
	t35: "Точка",
	t36: "Лог Записей",
	t37: "Расписания",
	t38: "Время запуска",
	t39: "Время остановки",
	t40: "Дни",
	t41: "Праздн",
	t42: "Статус",
	t43: "Выбирите точки",
	t44: "ОК",
	t45: "Отмена",
	t46: "Старт",
	t47: "Конец",
	t48: "Дата начало",
	t49: "Частота дикретизации",
	t50: "Нет выбранных точек",
	t51: "Сейч",
	t52: "сек",
	t53: "мин",
	t54: "ч",
	t55: "день",
	t56: "Тип Адреса",
	t57: "Адрес",
	t58: "Размещение",
	t59: "Введение нового значения",
	t60: "Обнов",
	t61: "Выбирите точки",
	t62: "Выбирите новое значение",
	t63: "Отк",
	t64: "Вкл",
	t65: "Извлекаю точки...",
	t66: "Ошибка извлечения данных....",
	t67: "Нет сконфигурированных записей..",
	t68: "Ошибка извлечения точек данных....",
	t69: "Извлечение записей для",
	t70: "Ошибка извлечения зписей....",
	t71: "Имя пользователя",
	t72: "Пароль",
	t73: "IP/URL адерес Основного Блока",
	t74: "ОК",
	t75: "Отмена",
	t76: "Имя",
	t77: "Знач.",
	t78: "Ошибка извлечения списка записей...",
	t79: "Введение нового адреса",
	t80: "Не получен список пользователей. Проверьте IP/URL адерес узла",
	t81: "Код дост",
	t82: "Акт код",
	t83: "Выбирите новое время и дату",
	t84: "Выбирите новую Длительность",
	t85: "Ошибка операции: ",
	t86: "Введите Авторизационую информацию",
	t87: "HVAC",
	t88: "Иди!",
	t89: "Управление оттайкой",
	t90: "Запустить оттайку в ручную",
	t91: "Управление освещением",
	t92: "Выкл освещение в ручную",
	t93: "Вкл освещение в ручную",
	t94: "Управление уборкой",
	t95: "Ручная уборка Вкл",
	t96: "Ручная уборка Откл",
	t97: "Осн Выкл-ль",
	t98: "Ручной Главн Выкл ВКЛ",
	t99: "Ручной Главн Выкл ОТКЛ",
	t100: "выключть управление",
	t101: "Выключить устройство",
	t102: "Включите устройство",
	t103: "Управление Смещением уставки",
	t104: "Смещение уставки ВКЛ",
	t105: "Смещение уставки ОТКЛ",
	t106: "Сообщение послано",
	t107: "Ручное управление",
	t108: "Закрыть",
	t109: "Освещ-е",
	t110: "Энер/Разн",
	t111: "Обзор",
	t112: "Выбирите AK255 единицу для дисплея",
	t113: "Читаю.....",
	t114: "из",
	t115: "Все AK255",
	t116: "Предыдущ",
	t117: "Страницы",
	t118: "След",
	t119: "Параметр",
	t120: "Управление",
	t121: "Извлечение данных",
	t122: "Ошибка чтения секции",
	t123: "Температура",
	t124: "Задержка после оттайки",
	t125: "Авар есл мен.",
	t126: "Авария превыш.",
	t127: "Мониторинг",
	t128: "Offline",
	t129: "Активн",
	t130: "Основной",
	t131: "Адрес",
	t132: "Описание",
	t133: "Текущ Знач-е",
	t134: "Текщий статус",
	t135: "Контроллеры",
	t136: "Единица",
	t137: "Существуют Активные аварии!",
	t138: "Существуют активные аварии!",
	t139: "Цифровые входы",
	t140: "Релейн выходы",
	t141: "Входы датчика",
	t142: "Аналог выходы",
	t143: "Контроллеры",
	t144: "Другие",
	t145: "Визуализация точек",
	t146: "Обзор объекта",
	t147: "Выйти из системы",
	t148: "Войти в систему",
	t149: "Выйти из системы",
	t150: "Холод ",
	t151: "Просмотр устройства",
	t152: "Аварии",
	t153: "Записи",
	t154: "Просмотр CS",
	t155: "Обзор объекта",
	t156: "Конфигурация",
	t157: "Прсм сайт",
	t158: "Вид объекта",
	t159: "Обр",
	t160: "Авария",
	t161: "Уставка",
	t162: "Особ Устр-ва",
	t163: "Настройки",
	t164: "Выбирите Дату",
	t165: "Информация",
	t166: "Отслеживание",
	t167: "Расчеты",
	t168: "Управление файлами",
	t169: "Помощь ручн",
	t170: "Инфо",
	t171: "Переводчик",
	t172: "Сохранить на сервер",
	t173: "Обновить языковой файл",
	t174: "Эта процедура обновит языковой файл на блоке, Продолжать? ",
	t175: "Экспорт записей",
	t176: "Выбирите новое значение",
	t177: "минимальная",
	t178: "максимум",
	t179: "Нет активных аварий!",
	t180: "НЕ изменяемый!!",
	t181: "Пожалуйста ждите",
	t182: "Команда послана",
	t183: "Операция не выполнена, попробуйте позже!",
	t184: "Операция выполнена!",
	t185: "НЕ авторизованая операция",
	t186: "Вы хотите продолжать эту операцию?",
	t187: "ИСПАР",
	t188: "Централь",
	t189: "Мониторинговый вход",
	t190: "Дополнительно",
	t191: "Подтвержд. аварии",
	t192: "Очистить Потвержденные аварии",
	t193: "Очистить все Потвержденные аварии",
	t194: "Вы не авторизированы для потвеждения аварий",
	t195: "НЕ авторизованый",
	t196: "Вы хотите потвердить эту аварию",
	t197: "Потвердите потверждение аварий",
	t198: "Сначала зарегистрируйтесь",
	t199: "Пожалуйста, выбирите аварии для очиски",
	t200: "Потвердите сброс аварий",
	t201: "Вы хотите сбросить эту аварию?",
	t202: "НЕ авторизованы для очиски аварии",
	t203: "Вы хотите очистить все аварии?",
	t204: "Выбранные аварии не могут быть сброшены",
	t205: "Настройка аварии",
	t206: "Вы должны авторизироваться как Supervisir для данной операции",
	t207: "Нет данных полученных из этой единицы",
	t208: "Выбирите единицу",
	t209: "Пожалуйста, выбирите единицу из списка.",
	t210: "Номер аварий",
	t211: "Статус Авар",
	t212: "Разное",
	t213: "Очистить выбранные аварии",
	t214: "Пожалуйста, потвердите",
	t215: "Подключиться к единице не удалось, Пожалуйста проверьте сетевые подключения ",
	t216: "Авария оттайки",
	t217: "Цифровой сигнал тревоги",
	t218: "Статус цифрового входа",
	t219: "Выход оттайки",
	t220: "Очищенный вход",
	t221: "цифровой вход",
	t222: "Сохранить языковой файл",
	t223: "Единц",
	t224: "В системе  нет сконфигурироавныз устройств. Сайт оключен",
	t225: "Предупреждение",
	t226: "Выбирите время",
	t227: "Связь",
	t228: "Загрузить файл отчета",
	t229: "Пожалуйста, Ждите пока создается файл отчета...",
	t230: "Загрузить отчет",
	t231: "Статус/Сервис аварии",
	t232: "Пожалуйста, Ждите, получаю данные",
	t233: "Вошедший",
	t234: "Пользователь вышел",
	t235: "Пользователь зарегистрирован",
	t236: "Приветствую!",
	t237: "Недействит. лицензия",
	t238: "Счетчик энергии",
	t239: "Панель освещения",
	t240: "ЧП привод",
	t241: "Модулькомпрессора",
	t242: "Точки Устройсива",
	t243: "Вычисляемые точки",
	t244: "Панель Cutler-Рammer",
	t245: "Carriers",
	t246: "Дополнительный вид",
	t247: "Двойной щелчек для увеличения/ уменьшения",
	t248: "Кликните это для увеличение/уменьшения",
	t249: "Потвержденные аварии",
	t250: "Имитация",
	t251: "Задачи охлождения",
	t252: "Вентиляция задача",
	t253: "Задача Освещения",
	t254: "Задачи для дополнительного",
	t255: "Сетевое подключение",
	t256: "Чстотн",
	t257: "Ссылка",
	t258: "График (Част./ток))",
	t259: "Дата остановки",
	t260: "Стоп",
	t261: "Время остановки раньше времени начало. Измените временные параметры.",
	t262: "Время создания точек записей",
	t263: "позже, чем указно как начало диапазона.",
	t264: "Вернувшийся количество замеров точек записи больше 100000 вы взяли слишком большой времянной интервал. Вам необходимо изменить или времянное разрешение или время/дату",
	t265: "Записи блока заняты другой задачей, ждите 2 минуты прежде чем пробывать снова... ",
	t266: "Сохранить",
	t267: "Панель управления записями",
	t268: "Первый",
	t269: "Последн",
	t270: "Печать",
	t271: "Уменьшить",
	t272: "Увеличить",
	t273: "Показать/скрыть легенду",
	t274: "Экспорт записей в файл",
	t275: "Показать/скрыть сетку",
	t276: "Одностраничный вид",
	t277: "Многостраничный вид",
	t278: "Чтение групп записей из файла",
	t279: "Чтение записей из файла",
	t280: "Сохранить группу записей в файл",
	t281: "подсчет Многостраничного вида",
	t282: "Страница",
	t283: "Выбирите страницу для дисплея",
	t284: "Файл записей должен быть в формате .hst",
	t285: "Нет вернувшихся данных проверьте точки записей и выбранные  даты/время",
	t286: "Снимок записей",
	t287: "Просмотр Всех записей",
	t288: "Просмотр фала записей",
	t289: "Выбирать...",
	t290: "Эта операция будет экспортировать текущие записи в .hst/csv файл",
	t291: "Выбирите целевой файл.",
	t292: "Файл",
	t293: "Чтение групп записей...",
	t294: "Чтение записей...",
	t295: "Сохранить группу записй",
	t296: "Вид",
	t297: "Zoom",
	t298: "Да",
	t299: "Нет",
	t300: "Получить конфигурацию контроллера?",
	t301: "Не спраш-ть более",
	t302: "Извлекаю Конфигурацию контролера",
	t303: "Обновление данных",
	t304: "Уровень аварии",
	t305: "Авар.Действ.",
	t306: "Подтвержд.",
	t307: "Сохранить базу данных на диск",
	t308: "Имя файла",
	t309: "Описание базы данных",
	t310: "Путь к базе данных",
	t311: "Версия",
	t312: "Время",
	t313: "Экспорт аварий",
	t314: "Сохранить база данных в",
	t315: "База данных не сохранилась. Проверьте папку и имя файла.",
	t316: "Имя не корректно, измените имя файла базы данных",
	t317: "Экспорт аварий завершился. Пожалуйста  выбирите файл для сохранния экспортируемых аварий",
	t318: "Пожалуйста, Выбирите аварии для экспорта",
	t319: "Получаю Информацию об аварии из единицы",
	t320: "Описания файла",
	t321: "Преобразовать .hst файл в .csv",
	t322: "Шаг 1:  Выбирите .HST файл",
	t323: "Шаг 2:  Выбирите точки и временной диапазон.",
	t324: "Шаг 1:  Выбирите .csv файл",
	t325: "Сохранить как...",
	t326: "Не более 6000 точек для использования в этой операции",
	t327: "Выбранные точки ",
	t328: "Нелзя экспортировать более 65536 замеров на одну точку измените временное разрешение. ",
	t329: "Собирать",
	t330: "Собираю данные из единицы",
	t331: "Выбирите собираемые точки",
	t332: "Нет значения",
	t333: "Уменьшить",
	t334: "Останов-ый",
	t335: "Пожалуйста, Выбирите",
	t336: "Постоить графики",
	t337: "Сохранить в файл .hst",
	t338: "Данные получены, пожалуйста, выбирите то, что хотите делать дальше ",
	t339: "Выбирите до 8 точек для прорисовки",
	t340: "Сохранить собранные записи в файл .hst",
	t341: "Обзор печати",
	t342: "Пожалуйста, Выбирите записать или распечатать отчет",
	t343: "Выбирите формат файла",
	t344: "Создаю файл обзора, ЖДИТЕ ",
	t345: "Выбранный файл базы данных существует, заменить его? ",
	t346: "Приборная панель",
	t347: "План объекта",
	t348: "Help",
	t349: "Обзор Системы",
	t350: "Карта",
	t351: "Инструмент перевода",
	t352: "Ручн",
	t353: "Запрос Аварии...",
	t354: "Обновлен",
	t355: "Модель",
	t356: "Управление",
	t357: "Масло",
	t358: "Темп",
	t359: "Развернуть",
	t360: "Востановить",
	t361: "Вернутся назад",
	t362: "Ошибка Связи!!!",
	t363: "Статус связи OK",
	t364: "Сообщения связи послано",
	t365: "Чтение домашних компонентов ",
	t366: "Чтение компонентов конфигурации",
	t367: "Кнтрл Темп",
	t368: "Эти настойки будут эффективны только, когда записи в измененной редакции вы хотите продолжать?",
	t369: "Читаю ждите",
	t370: "Точки",
	t371: "Нет имени единицы",
	t372: "Предпочтения",
	t373: "Имитационная Версия",
	t374: "Энергия",
	t375: "Вернутся к Окну обзора",
	t376: "Системная авария или не найдено устройство",
	t377: "Совпадающие расписания",
	t378: "Отсутствует языковой файл",
	t379: "Версия не поддерживается",
	t380: "Введение IP адреса единицы ",
	t381: "Пожалуйста, введите IP адрес или URL единици",
	t382: "Детали Помощи",
	t383: "Показать детали",
	t384: "Внеш темп",
	t385: "Вернуть",
	t386: "Подав",
	t387: "Влажн-ть",
	t388: "Тчк росы",
	t389: "Вентил",
	t390: "Зона",
	t391: "Отключение",
	t392: "Сплит",
	t393: "Темп Всас-ия",
	t394: "Текущая производительность",
	t395: "Давления всасывания",
	t396: "Нагн-е",
	t397: "Компрессоры",
	t398: "Конденсатор",
	t399: "Задача конденсатора",
	t400: "Хладагент",
	t401: "Всасывание задание",
	t402: "Всас",
	t403: "Оттайка",
	t404: "Данфосский формат записей",
	t405: "Текстовый формат",
	t406: "Для этой процедуры надо перегрузится сделать это сейчас?",
	t407: "Блок перегружен,  обновите WEB сайт",
	t408: "Приложение заводского вида ",
	t409: "Вычисляю",
	t410: "минуты",
	t411: "Ночн Смещ-е",
	t412: "Осв Витр",
	t413: "Совпадение расписаний",
	t414: "(10 минутная резолюция )",
	t415: "Просмотр текста",
	t416: "Экспорт как CSV",
	t417: "Сохранить PNG",
	t418: "Просмот Графиков",
	t419: "Просмотр текста",
	t420: "Расписание ",
	t421: "Продолж",
	t422: "Темп Терм",
	t423: "НЕТ",
	t424: "Управление темп",
	t425: "Запустить из",
	t426: "База данных читается из",
	t427: "Статистика",
	t428: "Выбирите файл",
	t429: "Выбирите файлы записей для преобразования в CSV формат",
	t430: "Конец",
	t431: "Кратко",
	t432: "Файл записей (*.hst)",
	t433: "Волшебник Преобразования записей",
	t434: "Файл создан",
	t435: "Выбирите точки для преобразования в CSV формат",
	t436: "Выбирите диапазон времени",
	t437: "Выбирите временной интервал и нажмите кнопку для продолжения",
	t438: "Прием данных",
	t439: "Ждите, готовлю данные записей",
	t440: "Выбирите CSV имя файла для сохрания данных в файл...",
	t441: "Не могу найти точки записей",
	t442: "Ошибка данные записей не получены",
	t443: "В этом месте была ошибка связи с единицами сети главных блоков",
	t444: "Внимание",
	t445: "Тишина",
	t446: "База данных не сохранена. Вы действительно хотите закрыть это окно? ",
	t447: "Табличный вид",
	t448: "Показать План",
	t449: "Показать таблицу",
	t450: "Ручная оттайка Вкл",
	t451: "Ручная оттайка Откл",
	t452: "Автоматическая Оттайка",
	t453: "Установите управление оттайкой",
	t454: "Разгрузчик ВКЛ",
	t455: "Разгрузчик Откл",
	t456: "Жидкость",
	t457: "Нет сконфигурированных расписаний",
	t458: "Вид объекта",
	t459: "Оборвана связь с единицей в ",
	t460: "Быстрая настройка",
	t461: "Улица",
	t462: "Город",
	t463: "Телефон",
	t464: "Регион ",
	t466: "указатель",
	t469: "Адресная книга Экспортирована удачно",
	t470: "Удалить пункт",
	t471: "калибровать",
	t472: "Примечание: ввод с учетом регистра",
	t473: "В сети",
	t474: "RO Плата",
	t475: "SI модуль",
	t476: "OI Board",
	t477: "Расчет",
	t478: "Установите Текущее время",
	t479: "Централь",
	t480: "Кол-во компр",
	t481: "Контур",
	t482: "Витрина",
	t483: "Неизв",
	t484: "Тип ошибки:",
	t485: "Добавить",
	t486: "Копировать",
	t487: "Удалить",
	t488: "Управление Витриной",
	t489: "Тип Витр",
	t490: "LON",
	t491: "Введите сумму для копирования",
	t492: "Без названия",
	t493: "Настройка расположения холодильного ...",
	t494: "Ошибка конфигурации:",
	t495: "Please verify configuration.",
	t496: "Настройка холодильного оборудования успешно завершена. Нажмите на кнопку ОК, чтобы выйти из этого мастера.",
	t497: "Состояние конфигурации",
	t498: "Заключительное расположение охлаждения",
	t500: "Код #",
	t501: "Создание",
	t502: "Настройка системы",
	t503: "Разреш-е",
	t504: "Пользователи",
	t505: "НЕ авторизованый",
	t506: "Уточните пожалуйста ...",
	t507: "Вы уверены, что хотите покинуть этот экран? Ваши настройки могут быть потеряны.",
	t508: "Сканирование сети контроллеров ...",
	t509: "Сканирую",
	t510: "Проверяю",
	t511: "AK-I/O",
	t512: "Сканирование",
	t513: "Вкл автономное программирование",
	t514: "Шина устройства",
	t515: "Устройство В сети ",
	t516: "Автоном Программирование ",
	t517: "дублирования адресов",
	t518: "Compressor Controls",
	t519: "Всас группы",
	t520: "Н/П",
	t521: "Доступные потребители",
	t522: "Разрешить создание многоиспарит потреб ",
	t523: "Вставьте устройство",
	t524: "Вставьте контур",
	t525: "Конт с нескол потреб",
	t526: "Чтение таблиц...",
	t527: "Запрос данных для сигнализации",
	t528: "Файл груп записей",
	t529: "Файлы Записей",
	t530: "Все файлы",
	t531: "Показывать символы",
	t532: "Показать кривые",
	t533: "Не достаточно собранных записей",
	t534: "Получить самые последние данные Записей.",
	t535: "Состояние / Настройки",
	t536: "Сейчас загружаю",
	t537: "Образцы записей ...",
	t538: "Извлечение",
	t539: "Точек данных ...",
	t540: "Интервал",
	t541: "Мин",
	t542: "Макс",
	t543: "Средн",
	t544: "Перейти в главное окно Аварий",
	t545: "Стоп автоматический вход",
	t546: "Подключение к",
	t547: "Отмена Авто входа",
	t548: "Please enter log-off password",
	t549: "Web1",
	t550: "Web 2",
	t551: "Автоматический вход в",
	t552: "Завершение работы после периода бездействия",
	t553: "Главный экран",
	t554: "Показать меню конфигурации",
	t555: "Показать Элементы разного на приборной панели",
	t556: "Используйте весь экран",
	t557: "Включить звуковой сигнал",
	t558: "Разрешить выполнение программы закрыть / минимизировать",
	t559: "Ошибка доступа Блок #",
	t560: "Подключение к сети.",
	t561: "Автоподключение отключено",
	t562: "Повторить подключения к",
	t563: "Записан из",
	t564: "Подключено",
	t565: "Формат времени",
	t566: "Формат Даты",
	t567: "Давление",
	t568: "Уров Освещ",
	t569: "Преохлож",
	t570: "Переохлад",
	t571: "Ежед",
	t572: "Недельный",
	t573: "HST File",
	t574: "CSV Файл",
	t575: "Воскресенье",
	t576: "Понедельник",
	t577: "Вторник",
	t578: "Среда",
	t579: "Четверг",
	t580: "Пятница",
	t581: "Суббота",
	t582: "History Log Collection",
	t583: "Enable auto-collect",
	t584: "Когда собирать",
	t585: "File save location",
	t586: "Выберите формат файла",
	t587: "Выберите папку для хранения файлов записей ...",
	t588: "Enable automatic history collection to retrieve and save daily or weekly history logs of the store's configured history points.",
	t589: "Для просмотра файлов записей HST идти на главное окно записей и выберите импорт записей. Для просмотра CSV файлов используйте любые электронные таблицы.",
	t590: "If the current connection's IP address is found in the address book the selected history points will be saved.",
	t591: "Выберите точки",
	t592: "Все выбранные",
	t593: "Выбот точек",
	t594: "Поиск",
	t595: "Авто-подключения к",
	t596: "Please verify",
	t597: "Автоматический сбор записей включен. Вы уверены, что хотите закрыть эту программу?",
	t598: "Вы уверены, что хотите закрыть эту программу?",
	t599: "Действие",
	t600: "Фильтр",
	t601: "Все действия",
	t602: "More information...",
	t603: "Editable",
	t604: "Операция",
	t605: "Операция отменена",
	t606: "Загрузка базы данных завершена",
	t607: "Базы данных блоков обновлены",
	t608: "Файлы переданы на блок удачно",
	t609: "Загрузка завершена, Теперь работаю в блоке",
	t610: "Ошибка при выгрузки файла на ПК, проверьте файл на доступность и читаемость.",
	t611: "Выгружаю файл",
	t612: "Загружаю файл",
	t613: "Выгружаю выделенные файлы",
	t614: "Загружаю выделенные файлы",
	t615: "Удаляю файл",
	t616: "Удаляю выделенные файлы",
	t617: "ошибка FTP соединения",
	t618: "Загрузить выделенные файлы в блок",
	t619: "Не удачная попытка FTP соединения, проверьте FTP соединение и имя/пароль пользователя",
	t620: "FTP подключено",
	t621: "байт",
	t622: "Файлы на блоке будут перезаписаны, Вы хотите продолжать?",
	t623: "Файлы на ПК будут перезаписаны! продолжать?",
	t624: "Файлы были загружены в блок",
	t625: "Только холод",
	t626: "Магаз У Дома",
	t627: "Полная функциональность",
	t628: "Только HVAC",
	t629: "Неверное имя файла, проверьте имя файла оно не должно иметь более 8 символов",
	t630: "Перезаписать Программный код сейчас?",
	t631: "После",
	t632: "загруженный",
	t633: "Вы можете выбрать ДА для перезаписи программы сейчас или выбрать Потом",
	t634: "Выберите корневую папку симуляторов",
	t635: "Пожалуйста выберите папку Windows",
	t636: "Пожалуйста, выберите папку назначения",
	t637: "Выберите папку базы данных",
	t638: "FTP  отключено",
	t639: "Время соединения вышло FTP разорвалось",
	t640: "Выгрузить выделенные файлы на ПК",
	t641: "Удалить выделенные файлы",
	t642: "Порт Серв FTP",
	t643: "Порт Web Сервер",
	t644: "Локальный путь",
	t645: "Вверх",
	t646: "Размер",
	t647: "Дата",
	t648: "Соединение",
	t649: "Разрыв",
	t650: "Удаленный путь",
	t651: "Доступное свободное пространство:",
	t652: "Выберите файл(ы)",
	t653: "Файлы",
	t654: "Всего",
	t655: "Нажмите для смены папки",
	t656: "Смена...",
	t657: "Вы хотите удалить выбранные файлы?",
	t658: "Соединение закрыто, выход",
	t659: "Имена выбранных файлов не корректны и их загрузка отменяется",
	t660: "Имена нескольких из выбранных файлов не корректны и игнорируются",
	t661: "Файлы переданы на ПК удачно",
	t662: "Нет свободного пространства на диске блока",
	t663: "Передача файлов",
	t664: "Modified",
	t665: "Копировать файлы из флеш диска на RAM диск",
	t666: "Пожалуйста, выберите операцию копирования файла:",
	t667: "Копировать все сконфигуренные EDF файлы с флэш-диска",
	t668: "Копировать все EDF файлы с Флеш диска",
	t669: "Файлы edf/ed3 хранятся на флэш-диске Вашего устройства, но не загружены на экран управления файлами. Используйте параметры ниже, чтобы скопировать сохраненные EDF файлы в вашего устройства на экран управления файлами",
	t670: "Эта операция скопирует все сконфигурированные  EDF файлы блока с флеш диска на RAM диск.",
	t671: "Эта операция скопирует все EDF файлы блока с флеш диска на RAM диск.",
	t672: "No Valid EDF File Exists",
	t673: "EDF File Copy Finished",
	t674: "files copied",
	t675: "Подтвердите скачать базу данных",
	t676: "Адрес блока",
	t677: "IP-адрес/имя хоста",
	t678: "Имя файла базы данных",
	t679: "Выбор",
	t680: "Обновление клиентов",
	t681: "Привет Вам в Управление обновлениями - шаг 1 из 2",
	t682: "Привет Вам в Управление обновлениями - шаг 2 из 2",
	t683: "Делаю",
	t684: "Проверка перед обновлением",
	t685: "Расположение прошивки",
	t686: "Сохранить Базу данных",
	t687: "Сохраняю базу данных",
	t688: "Передача программного обеспечения",
	t689: "Перезапись программного обеспечения",
	t690: "Выберите программное обеспечение для обновления",
	t691: "Обнаружено не заводское отказоустойчивое приложение",
	t692: "Вы можете продолжать это делать, но любые ошибки в процессе обновления непонятно как скажутся на работе системы.",
	t693: "Настоятельно рекомендуется не продолжать.",
	t694: "Вы хотите продолжать?",
	t695: "Удаленное обновление системы может привести к сбою, что может быть результатом большой неприятности.",
	t696: "Отказ",
	t697: "Обновление Базы данных завершено",
	t698: "Сохранение Базы данных завершено",
	t699: "Загрузка приложения завершена",
	t700: "Перезапись приложения завершена",
	t701: "Удачное обновление программного обеспечения ",
	t702: "Вы хотите перезагрузить блок до заводской программы",
	t703: "Проверка Железа",
	t704: "Проверяю состояние устройства",
	t705: "Заводская отказоустойчивость",
	t706: "Версия текущей прошивки",
	t707: "Начать обновление",
	t708: "Выберите существующий файл базы данных",
	t709: "Выберите существующий файл базы прошивки",
	t710: "Назад ",
	t711: "Расположение прошивки",
	t712: "Выберите .csi файл",
	t713: "Выберите .mai файл",
	t714: "Remote downloading is not supported by this CPU board.",
	t715: "Ошибка при загрузки приложения",
	t716: "База данных не правильно выгрузилась, попробуйте еще. ",
	t717: "Перезапись приложения завершена",
	t718: "Ошибка при перезаписи приложения, Попробуйте еще раз, потом. ",
	t719: "Ошибка при перезаписи приложения",
	t720: "Обнаружено не заводское отказоустойчивое приложение",
	t721: "Готово",
	t722: "Выбранное приложение может иметь другую версию чем то что на блоке, при продолжении операции есть риск аварии и системной ошибки.",
	t723: "Имена выбранных файлов не корректны и их загрузка отменяется",
	t724: "Имена нескольких из выбранных файлов не корректны и игнорируются",
	t725: "Разъем разомкнут",
	t726: "Разрядилась батарейка",
	t727: "Нет связи между блоками",
	t728: "Блок загружается",
	t729: "Выберите файл назначения",
	t730: "Выберите папку назначения",
	t731: "Обновление Базы данных завершено",
	t732: "Статус Хорошо",
	t733: "Статус Ошибка",
	t734: "Статус Не знаю",
	t735: "Эта операция обновит выбранные файлы в симуляторе, Вы уверины что хотите продолжать?",
	t736: "Загрузка базы данных закончена. Вам необходимо перегрузить блок. ",
	t737: "Загрузить базу данных не получилась. Вам нужно повторить позже. Вдруг  получится?",
	t738: "Блок будет автоматически перезагружен после операции, будете продолжать?",
	t739: "Выбрать базу данных",
	t740: "Загрузить базу данных?",
	t741: "Выгрузить базу данных?",
	t742: "Загружаю базу данных в блок! Ждите.",
	t743: "База данных удачно загружена. Сейчас перегрузим блок.",
	t744: "Обновление завершено, Блок перезагрузился удачно. ",
	t745: "Обновление Базы данных неудачно, пробуйте еще. ",
	t746: "Ошибка в обновлении базы данных",
	t747: "Проверка перед загрузкой",
	t748: "Расположение базы данных",
	t749: "Загружаю базу данных",
	t750: "Информация о текущей базе данных",
	t751: "Выберите базу данных для загрузки",
	t752: "Сохранить базу данных на ПК (Выгрузить)",
	t753: "Считать базу данных с ПК (Загрузить)",
	t754: "Нужны права супервайзера!!!",
	t755: "Старт выгрузки",
	t756: "Выберите Операцию для Базы данных:",
	t757: "Старт загрузки",
	t758: "Версия текущей прошивки",
	t760: "База данных успешно загружена",
	t761: "Привет Вам в Месте Управления Базой данных - шаг 1 из 2",
	t762: "Привет Вам в Месте Управления Базой данных - шаг 2 из 2",
	t763: "Не подключились к блоку, проверьте  IP/URL адрес",
	t764: "Удаленное обновление системной базы данных, это риск, любые ошибки в процессе обновления непонятно как скажутся на работе системы, то есть если что сами виноваты.",
	t765: "Загрузка базы данных завершена",
	t766: "Ethernet Scan",
	t767: "Enter first IP address then press start",
	t768: "Scan",
	t769: "Сканирую",
	t770: "History Log Collection",
	t771: "Анал цвет график",
	t772: "Digital graph colors",
	t773: "ЗАкрыть расписания",
	t774: "Defrost Summary",
	t775: "Расписание ночн смещ",
	t776: "Расписание освещения потреб",
	t777: "Loading Status",
	t778: "Alarm Log Collection",
	t779: "Select folder to store alarm files...",
	t780: "Ежемес",
	t781: "Enable automatic alarm collection to retrieve and save alarm information to a file on a periodic basis.",
	t782: "CSV Separator",
	t783: "Нет аварий",
	t784: "Retrieving alarm counts for unit:",
	t785: "Retrieving alarm details from unit:",
	t786: "Automatic alarm collection is enabled. Are you sure you want to close this program?",
	t787: "Часов",
	t788: "This will update the unit's database and set the location to the center of the current view. Proceed?",
	t789: "samples processed",
	t790: "Please configure a suction group.",
	t791: "To re-use the Layout Wizard more than once the refrigeration database layout needs to be reset.",
	t792: "Do this via the Configuration-&gt;Control page and set the number of Racks/Packs to zero.",
	t793: "Note: any schedules, calculations or imports based on prior refrigeration configuration will need to be reconfigured.",
	t794: "Copy process finished.",
	t795: "Refrigeration Report",
	t796: "Final HVAC Layout",
	t797: "The HVAC layout setup has completed successfully. Press the ok button to exit this wizard.",
	t798: "Configuring HVAC layout...",
	t799: "To re-use the HVAC Layout Wizard more than once the HVAC database layout needs to be reset.",
	t800: "Do this via the Configuration-&gt;Control page and set the number of HVAC units to zero.",
	t801: "Note: any schedules, calculations or imports based on prior HVAC configuration will need to be reconfigured.",
	t802: "предст модели",
	t803: "Шаги",
	t804: "Уров",
	t805: "Show Multiple DataTip",
	t806: "Кол-во шагов",
	t807: "Could not save file.",
	t808: "Incorrect model.",
	t809: "Load successful.",
	t810: "Откр",
	t811: "This feature is only available in the desktop version of StoreView.",
	t812: "Продолж",
	t813: "Leave Wizard",
	t814: "Operation succeeded. Would you like to continue or you want to leave the wizard.",
	t815: "Error while fetching alarm logger data.",
	t817: "Device Name : ",
	t819: "In Progress...",
	t820: "Alarm data is not present.",
	t821: "Количество аварий",
	t822: "Mimic Display Options",
	t823: "Display only text",
	t824: "Display only icon",
	t825: "Display text and icon",
	t826: "1 неделя",
	t827: "2 недели",
	t828: "3 недели",
	t829: "4 недели",
	t830: "Настройки графики",
	t831: "Невозможно отобразить диагностику аварии, проверьте авто сбор аварий в настройках",
	t832: "Упорядочивание не поддерживается IO централями или контурами включенными в конфигурацию.",
	t833: "Этот IP/URL адрес хоста уже присутствует в адресной книге. Хотите обновить запись?",
	t834: "Пролистать",
	t835: "Выерите папку адресной книги",
	t836: "Размещение",
	t837: "В выбранном месте нет содержимого адресной книги. Хотите экспортировать содержимое существующей адресной книги в выбранное место?",
	t838: "Коды действия аварии",
	t839: "Этот файл адресной книги не найден по указанному пути. Адресная книга загружается из локального…",
	t840: "Выберите место для сохранения логов.",
	t841: "Выберите правильное место для сохраненения логов.",
	t842: "Идет сбор журнала аварий…",
	t843: "Журнал логов записей выполняется…",
	t844: "Работать в фоновом режиме",
	t845: "Сбор журнала аварий",
	t846: "IP/URL адрес основного блока не должен быть пустым",
	t847: "Поле адреса не должно быть пустым",
	t849: "У Вас нет последней версии файла, хотите загрузить данные из последней версии?",
	t850: "Конфликт версии",
	t851: "Выполнение лога записей",
	t852: "User Name and Password fields should not be blank.",
	t853: "IP Address field should not be blank.",
	t854: "Host IP/URL Address field should not be blank.",
	t855: "User name should not be blank.",
	t856: "Password should not be blank.",
	t857: "The SM800 you have connected to with",
	t858: "has not had the Initial Startup Wizard completed. Please complete this task and reconnect to the SM800",
	t859: "Set to now",
	t860: "Invalid input",
	t861: "Text is too long",
	t862: "Offset",
	t863: "Unable to communicate with unit(s) on host network",
	t864: "Please verify addresses are different. Configuration is updated when the save button is pressed.",
	t865: "Please select a valid History File (.hst)",
	t866: "Please select a valid History Group File (.grp)",
	t867: "Цвет",
	t868: "Процент",
	t869: "Напряжение",
	t870: "Текущего",
	t871: "Мощность",
	t872: "Частота",
	t873: "Концентрации",
	t874: "Потока",
	t875: "Скорость",
	t876: "Кислотности",
	t877: "Пожалуйста, выберите диаграммы в нижней части ящика",
	t878: "Сохранить PNG",
	t879: "Нарисовать",
	t880: "Статус системы...",
	t881: "Статус системы и единицы",
	t882: "Гатауэй",
	t883: "Лицензии на функции",
	t884: "Версия программного обеспечения",
	t885: "Версия модуля браузера",
	t886: "Версия Danux",
	t887: "Адрес Mac",
	t888: "Поддержка и обновления",
	t889: "Название подразделения",
	t890: "Подключите",
	t891: "Потерянное соединение",
	t892: "Оборудование типа не найдено.",
	t893: "Используемая в настоящее время версия программного обеспечения не поддерживается",
	t894: "Быстрые ссылки",
	t895: "Оборудование",
	t896: "Графический",
	t897: "Утилиты",
	t898: "Откат программного обеспечения",
	t899: "Диагностики",
	t900: "Ошибка",
	t901: "Данные о «тревогах» не найдены",
	t902: "Параметры",
	t903: "Название приложения",
	t904: "Версия приложения",
	t905: "Сообщение",
	t906: "Тип события",
	t907: "Timestamp",
	t908: "Версия отката",
	t909: "Текущая версия",
	t910: "Программного обеспечения",
	t911: "Отката...",
	t912: "Пожалуйста, выберите оборудование из списка",
	t913: "Представление календаря",
	t914: "Ошибка произошла во время загрузки устройств (ы), пожалуйста, повторите попытку.",
	t915: "Ошибка произошла во время загрузки графиков, пожалуйста, повторите попытку.",
	t916: "Оборудование не найдено",
	t917: "Расписание экспорта",
	t918: "Пожалуйста, выберите тип экспорта CSV или PDF",
	t919: "Экспорт как CSV",
	t920: "Подготовка PDF",
	t921: "Экспорт как PDF",
	t922: "Пожалуйста, предоставьте IP в этом формате xx.xx.xx.xx:xxxx",
	t923: "Пользователя",
	t924: "Входа",
	t925: "Отката",
	t926: "Откат программного обеспечения SM800A",
	t927: "Это действие откатит все программное обеспечение на перечисленные версии.",
	t928: "Редактор партийных устройств",
	t929: "некоторый текст, который объясняет функцию",
	t930: "Обновление CDF",
	t931: "Картография устройства",
	t932: "Файл устройства",
	t933: "1-основанный номер регистра",
	t934: "Тип хранилища",
	t935: "Мин",
	t936: "Макс",
	t937: "Читать только",
	t938: "Журнала",
	t939: "Тип сообщения",
	t940: "Ключевое слово преобразования",
	t941: "Параметры",
	t942: "Addr.",
	t943: "Приоритет",
	t944: "Разрядной",
	t945: "Отрицается",
	t946: "Новый файл",
	t947: "Открытый файл",
	t948: "Создание файла",
	t949: "Группы",
	t950: "Версия продукта",
	t951: "Произошла ошибка",
	t952: "Не найдено",
	t953: "Это устройство не было найдено",
	t954: "Вне темпа.",
	t955: "Не найдено ни одной «оборудования»",
	t956: "Случаях",
	t957: "Тревога успешно отключить / действовать",
	t958: "Mute/act не удалось для этой тревоги",
	t959: "Ошибка произошла во время загрузки единицы (ы), пожалуйста, повторите попытку.",
	t960: "Нет оборудования",
	t961: "Оборудование типа не найдено",
	t962: "Настройка системного менеджера",
	t963: "Устройства",
	t964: "Отменить",
	t965: "Вы изменили значение поля, хотите сохранить изменения?",
	t966: "Очистить выбранные точки истории",
	t967: "Шр {{number}}",
	t968: "час скорость насоса",
	t969: "Sd",
	t970: "V3ч",
	t971: "Справочник по часам",
	t972: "Ств {{number}}",
	t973: "двойная ссылка",
	t974: "Двойная скорость насоса",
	t975: "V3tw",
	t976: "Трек",
	t977: "Prec",
	t978: "Врек ОД",
	t979: "Предварительная ссылка",
	t980: "Трек ссылка",
	t981: "Pc",
	t982: "SS",
	t983: "Вхп ОД",
	t984: "Ссылка на PGC",
	t985: "Эжектор НД",
	t986: "ПГС",
	t987: "Шп",
	t988: "сержант",
	t989: "СЦ3",
	t990: "Контр. гл.",
	t991: "конд. емкость",
	t992: "По",
	t993: "SS",
	t994: "Sd",
	t995: "ПК",
	t996: "Пропускная способность",
	t997: "Сертификат браузера",
	t998: "Выйти",
	t1000: "Сортировка только по {{count}}",
	t1001: "Загружено {{count}} из {{total}} будильников",
	t1002: "Подтверждение успешно для всех сигналов тревоги",
	t1003: "Не удалось подтвердить все тревоги",
	t1004: "Подтверждено только {{count}} из {{total}} тревог",
	t1005: "Не удалось экспортировать все сигналы тревоги",
	t1006: "Экспорт выполнен",
	t1007: "Не удалось экспортировать, требуется хотя бы один тип тревоги.",
	t2000: "-",
	t2001: "Ответ слишком велик для буфера передачи XML, который составляет 32 КБ.",
	t2002: "Нет атрибутов, связанных с элементом cmd или команда не имеет элемента «cmd», или не может записать в переменную, или не может прочитать переменную, или не может прочитать точку, или не может записать вкл/выкл режим работы входа или релейного выхода",
	t2003: "Неправильный ввод команды",
	t2004: "Ошибка в параметрах поста CGI",
	t2005: "Ошибка преобразования XML-ответа из его внутреннего представления в XML-строку ответа.",
	t2006: "Ошибка декодирования строки URL",
	t2007: "Переполнение памяти",
	t2008: "Сжатие данных ответа не удалось",
	t2009: "Ошибка парсинга запроса",
	t2010: "Ошибка доступа к данным - ряд причин, которые в дальнейшем не устраняются",
	t2011: "Неопределенное значение атрибута действия команды Недопустимое значение атрибута",
	t2012: "Ошибка в контрольном номере аварийного сигнала или аварийный сигнал не настроен",
	t2013: "Строка входного параметра cgi слишком длинная.",
	t2014: "Адрес устройства не найден",
	t2015: "Не удалось авторизоваться для записи",
	t2016: "История не настроена",
	t2017: "Данные истории не возвращаются, может быть ошибка времени",
	t2018: "Создать файл с ошибками",
	t2019: "Ошибки чтения файлов",
	t2020: "Превышен максимальный размер списка устройств",
	t2021: "не используется",
	t2022: "Неверный индекс истории",
	t2023: "Не удалось найти индекс точки платы по типу узла, узлу, модулю и точке.",
	t2024: "Не удалось найти индекс истории",
	t2025: "Запрос истории не был инициализирован перед попыткой запустить запрос истории.",
	t2026: "Была предпринята попытка hq_start_query, пока активен запрос истории",
	t2027: "Атрибуты команды не найдены, хотя некоторые из них ожидаются",
	t2028: "Начало эпохи дано без конца и наоборот",
	t2029: "Не удалось преобразовать формат времени и даты в секунды эпохи",
	t2030: "Команде требуется query_id, но он не указан",
	t2031: "Ошибка создания XML-ответа",
	t2032: "Предоставленный query_id не соответствует активному запросу",
	t2033: "Была предпринята попытка выполнить команду hq_get_data, но данные еще не доступны",
	t2034: "Была предоставлена частота дискретизации, которая не является одной из разрешенных",
	t2035: "Интервал усреднения меньше или равен частоте дискретизации, с которой были записаны данные.",
	t2036: "Время конечной эпохи меньше, чем время начальной эпохи, или время конечной эпохи минус время начальной эпохи меньше, чем интервал усреднения",
	t2037: "Предоставленный device_id не соответствует ни одному известному в настоящее время ak255. Обратите внимание, что только настроенные устройства известны ak255.",
	t2038: "Нет атрибутов идентификатора",
	t2039: "Недопустимые атрибуты идентификатора",
	t2040: "Нет номера расписания",
	t2041: "Неверный номер расписания",
	t2042: "Нет сведений о расписании",
	t2043: "Отсутствует on_time",
	t2044: "Пропущенный час",
	t2045: "Неверный час",
	t2046: "Пропущенная минута",
	t2047: "Недействительная минута",
	t2048: "Отсутствует off_time",
	t2049: "Отсутствующие дни недели",
	t2050: "Недопустимые дни недели",
	t2051: "Отсутствующие праздники",
	t2052: "Недействительные праздники",
	t2053: "Отсутствует или недействителен праздничный_старт",
	t2054: "Отсутствует или недействителен holiday_end",
	t2055: "Отсутствует или недействителен holiday_open",
	t2056: "Отсутствует или недействителен holiday_close",
	t2057: "Праздник не настроен",
	t2058: "Отсутствует описание",
	t2059: "Не могу сбросить тревогу",
	t2060: "Отсутствует или недействителен store_open",
	t2061: "Отсутствует или недействителен store_closed",
	t2062: "Тревога не настроена",
	t2063: "Узел в автономном режиме",
	t2064: "Отсутствует индекс",
	t2065: "Неверный индекс",
	t2066: "Реле не может быть включено по времени",
	t2067: "Изменяемое имя содержит слишком много символов. При изменении store_name или unit_name должно быть < = 16 символов, при изменении storeId1 или storeId2 должно быть < = 8 символов.",
	t2068: "Отсутствуют элементы, связанные с элементом команды.",
	t2069: "Tag_ID не определен",
	t2070: "Отсутствует конечный узел для данных",
	t2071: "Нет изменений в данных",
	t2072: "Не удалось обновить базу",
	t2073: "Недоступно для записи",
	t2074: "Ошибка расходомера",
	t2075: "Нет счетчиков",
	t2076: "Неверный уровень сарая",
	t2077: "Недопустимый тип",
	t2078: "Система занята",
	t2079: "Активы, не настроенные для сброса нагрузки",
	t2080: "Недопустимая номинальная мощность для актива",
	t2081: "Недопустимая задержка запуска для актива",
	t2082: "Конфигурация занята",
	t2083: "Выполняется сканирование ввода/вывода",
	t2084: "Выполняется слишком много разморозок",
	t2085: "Попытка загрузить неверную версию базы данных в модуль",
	t2086: "Загрузка базы данных не удалась",
	t2087: "Нет доступной веб-памяти",
	t2088: "Ответ на запрос не настроен (только система AK355)",
	t2089: "Отсутствует или неверный размер файла.",
	t2090: "Отсутствует закодированный файл (элементы b64)",
	t2091: "Не удалось сохранить базу данных на RAM-диск.",
	t2092: "Слишком большой размер файла.",
	t2093: "Отсутствует имя файла.",
	t2094: "Отсутствует идентификатор файла.",
	t2095: "Отсутствует индекс загрузки файла (Примечание: это индекс последовательности для load_file load_database).",
	t2096: "Идентификатор файла не совпадает или отсутствует.",
	t2097: "Операция загрузки файла уже выполняется.",
	t2098: "Декодированный файл не соответствует размеру исходного файла.",
	t2099: "Операция загрузки файла была отменена или не запущена.",
	t2100: "Неверный запрос пользователя (исходный запросчик не соответствует IP-адресу)",
	t2101: "Неверная последовательность загрузки файла.",
	t2102: "Недействительным каталог",
	t2103: "Недопустимое расширение",
	t2104: "Недопустимое смещение",
	t2105: "Ошибка кодирования файла",
	t2106: "Ошибка декодирования файла",
	t2107: "Не удалось открыть файл для чтения/записи",
	t2108: "Не удалось записать в файл",
	t2109: "Файл защищен от записи в файловой системе Flash",
	t2110: "Не удалось удалить файл.",
	t2111: "Не могу удалить папку.",
	t2112: "Отсутствующие устройства",
	t2113: "Не все устройства по URL были найдены",
	t2114: "Отсутствуют параметры истории",
	t2115: "Не все параметры истории из URL были найдены",
	t2200: "Текущая используемая версия программного обеспечения ( {{currentVersion}} ) не рекомендуется.",
	t2201: "Текущая используемая версия программного обеспечения ( {{currentVersion}} ) не рекомендуется ( > {{recommendedVersion}} рекомендуется)",
	t2202: "Ошибка версии ПО",
	t2203: "Графический редактор",
	t2204: "Редактировать",
	t2205: "Нарисовать",
	t2206: "Новый вид",
	t2207: "Созданный",
	t2208: "Уронить",
	t2209: "Новая графика",
	t2210: "Создать новый графический вид",
	t2211: "Имя",
	t2212: "Переименовать",
	t2214: "Экспорт",
	t2213: "Дублировать",
	t2215: "Удалить",
	t2216: "Вы уверены, что хотите удалить представление \" {{name}} \"?",
	t2217: "Экспортировать все представления",
	t2218: "Более...",
	t2219: "Правило",
	t2220: "правила",
	t2221: "Название правила",
	t2222: "Панель редактирования",
	t2223: "Условия",
	t2224: "Градиент",
	t2225: "Инвертировать условия",
	t2226: "Сопоставления",
	t2227: "Формы карты по",
	t2228: "Идентификатор формы",
	t2229: "состояния",
	t2230: "Добавить сопоставление",
	t2231: "Добавить правило",
	t2232: "Оператор",
	t2233: "Добавить условие",
	t2234: "Начните с добавления правил",
	t2235: "Вы уверены, что хотите удалить правило \" {{name}} \"?",
	t2236: "Копия правила сделана",
	t2237: "Просмотры сайта",
	t2238: "Вы должны создать представления сайта, чтобы просмотреть их",
	t2239: "Перейти в графический редактор",
	t2240: "Перейти к деталям",
	t2241: "Выберите параметр",
	t2242: "Параметры не найдены",
	t2243: "Опции",
	t2244: "Вкл./выкл. вход",
	t2245: "Релейный выход",
	t2246: "Вход датчика",
	t2247: "Переменный выход",
	t2248: "Универсальное устройство",
	t2249: "Пустой узел",
	t2250: "Ведение журнала не поддерживается...",
	t2251: "Повторить",
	t2252: "Не удалось получить историю",
	t2253: "Загрузка истории может занять некоторое время и не поддерживает несколько запросов одновременно, повторите этот запрос.",
	t2254: "Имя параметра",
	t2255: "Имя устройства",
	t2256: "Тип устройства",
	t2257: "Не удалось загрузить все параметры истории (не удалось загрузить {{n}} )",
	t2258: "Неверное имя пользователя или пароль",
	t2259: "Нет связи с контроллером",
	t2260: "Обновление программного обеспечения",
	t2261: "Система",
	t2262: "Откат программного обеспечения",
	t2263: "Установить",
	t2264: "Откат программного обеспечения заменит более новую версию",
	t2265: "Откат ПО до резервной версии {{version}}",
	t2266: "База данных",
	t2267: "Доступно обновление",
	t2268: "Экспорт базы данных",
	t2269: "Скачать",
	t2270: "Загрузка базы данных",
	t2271: "Не могу экспортировать базу",
	t2272: "Файл базы данных",
	t2999: "Ошибка связи",
	t2998: "Неизвестная ошибка",
	t3000: "Сведения",
	t3001: "Прошлый код XML здесь",
	t3002: "Назначить на",
	t3003: "Состояние",
	t3004: "Управление программным обеспечением",
	t3005: "Локальный сохраненный файл",
	t3006: "Онлайн через интернет",
	t3007: "Убедитесь, что питание не прерывается во время процесса обновления. Перед обновлением системы рекомендуется сделать резервную копию системной базы данных. После завершения обновления программного обеспечения рекомендуется проверить рабочее состояние системы, чтобы убедиться в правильности управления и работы.",
	t3008: "Файлы не выбраны",
	t3009: "Выберите файл программного обеспечения",
	t3010: "Загрузка файлов",
	t3011: "Проверка безопасности",
	t3012: "Извлечение файла",
	t3013: "Проверка файла",
	t3014: "Загрузка файла не удалась!",
	t3015: "Проверка безопасности не удалась!",
	t3016: "Извлечение не удалось!",
	t3017: "Ошибка проверки файла!",
	t3018: "Скачивание файла: {{fileName}}",
	t3019: "Файл успешно скачан!",
	t3020: "Ошибка загрузки!",
	t3021: "Загрузить файлы",
	t3022: "Скачать файлы",
	t3023: "Обновление ОС",
	t3024: "Обновление Edf",
	t3025: "Скачать файлы",
	t3026: "К сожалению, значение выбранного параметра недоступно",
	t3027: "Импорт",
	t3028: "Требуется минимум один файл",
	t3029: "Файл недействителен",
	t3030: "Управление графическими представлениями",
	t3031: "Загрузить",
	t3032: "Мигрировать",
	t3033: "Чтение графических файлов, пожалуйста, подождите...",
	t3034: "Импорт графического файла, пожалуйста, подождите...",
	t3035: "Выбрать графику",
	t3036: "Отчеты",
	t3037: "Импорт базы данных",
	t3038: "Выберите файл базы данных",
	t3039: "Убедитесь, что питание не прерывается во время процесса отката. Перед откатом системы рекомендуется сделать резервную копию системной базы данных. После завершения отката программного обеспечения рекомендуется проверить рабочее состояние системы, чтобы убедиться в правильности управления и работы.",
	t3040: "Диспетчер системы перезапустится после обновления",
	t3041: "Расширять",
	t3042: "Показать отладочные сообщения",
	t3043: "Максимум. разрешенные символы -",
	t3044: "Пожалуйста, загрузите файл обновления",
	t3045: "Текущая версия ПО",
	t3046: "Текущая версия Danux",
	t3047: "Последняя версия программного обеспечения",
	t3048: "Последняя версия Danux",
	t3049: "Резервное копирование, пожалуйста, подождите",
	t3050: "Установка файла подождите",
	t3051: "Устанавливается обновление",
	t3052: "Устройство перезагружается",
	t3053: "Перезапуск системного менеджера",
	t3054: "Браузер обновится сейчас",
	t3055: "Программное обеспечение успешно установлено",
	t3056: "Не удалось обновить, произошла следующая ошибка:",
	t3057: "Резервное копирование текущей версии программного обеспечения",
	t3058: "Резервное копирование текущих файлов EDF",
	t3059: "Графический вид успешно сохранен",
	t3060: "Применить фоновое изображение",
	t3061: "Очистить",
	t3062: "Повторить",
	t3063: "Магазин",
	t3064: "Цепные ночные неудачи",
	t3065: "Ночные неудачи",
	t3066: "Загруженная версия {{fileName}}",
	t3067: "Чтобы обновить выбранные единицы, нажмите «Установить».",
	t3068: "Откат {{updateType}} версии",
	t3069: "Установка",
	t3070: "Установить откатную версию",
	t3071: "Ни один из доступных модулей не содержит откатной версии.",
	t3072: "Новая версия доступна онлайн",
	t3073: "Загрузить файл для выбранных объектов",
	t3074: "Загрузить файл для выбранного объекта",
	t3075: "Нет доступных версий отката для объекта",
	t3076: "Загрузка завершена",
	t3077: "Текущая версия {{updateType}}",
	t3078: "Последняя версия {{updateType}}",
	t3079: "Выполняется обновление. Вы уверены, что хотите закрыть окно?",
	t3080: "Danux не обновляется! Обновите Danux, а затем снова обновите программное обеспечение.",
	t3081: "Приложения не могут быть понижены до этой версии",
	t3082: "Информация о приложении",
	t3083: "Примечания к выпуску",
	t3084: "Условия и конфиденциальность",
	t3085: "Лицензии",
	t3086: "Пожалуйста, перейдите к конфигурации, чтобы настроить расписания",
	t3087: "К сожалению, при подготовке миграции произошла ошибка. Убедитесь, что все устройства доступны в сети и правильно настроены. \n\n Произошла следующая ошибка:\n {{error}}",
	t3088: "Импорт или перенос существующей графики",
	t3089: "Отображать",
	t3090: "Название актива",
	t3091: "Крах",
	t3092: "Импорт завершен",
	t3093: "Безопасность при разрядке",
	t3094: "Безопасность всасывания",
	t3095: "Разряд Кутин",
	t3096: "Вырез разрядки",
	t3097: "Вырез всасывания",
	t3098: "Всасывание Кутин",
	t3099: "Емкость",
	t3100: "Файлы миграции (flp, dpj или json) не найдены",
	t3101: "Файл разбора тайм-аута",
	t3102: "Файлы и адреса сторонних устройств",
	t3103: "Сеть электроснабжения",
	t3104: "Замок",
	t3105: "XML",
	t3106: "Выберите параметр для отображения",
	t3107: "Параметр устройства",
	t3108: "Нет единиц",
	t3109: "Единицы не найдены или не загружены",
	t3110: "НАССР",
	t3111: "АКТИВ",
	t3112: "Нет доступных данных HACCP",
	t3113: "Информация об устройстве",
	t3114: "Доступны обновления",
	t3115: "Перейти к программному обеспечению",
	t3116: "Нет соединения",
	t3117: "Текущая область доступа для этого приложения: « {{targetLevel}} », предоставленный пользователь выше этой области с точки зрения прав доступа.",
	t3118: "Экспорт PDF",
	t3119: "Не удалось экспортировать PDF",
	t3120: "На больших наборах данных интерфейс может не отвечать, и для завершения потребуется некоторое время.",
	t3121: "Пожалуйста, выберите расписание из списка",
	t3122: "Пожалуйста, перейдите к конфигурации, чтобы настроить расписание",
	t3123: "Успешно",
	t3124: "Предупреждение",
	t3125: "Информация о сайте",
	t3126: "Пожалуйста, выберите время и точки данных из списка",
	t3127: "Подключение",
	t3128: "Не удалось установить соединение с этим сайтом, обратитесь в службу поддержки.",
	t3129: "На выбранную дату данные HACCP отсутствовали. Убедитесь, что история и время правильно настроены в меню конфигурации.",
	t3130: "Диагностический отчет",
	t3131: "Выберите устройство(а) для экспорта диагностического отчета",
	t3132: "Недоступно на этом уровне доступа пользователя",
	t3133: "Журнал диагностики",
	t3134: "не удалось загрузить отчет",
	t3135: "Шрифт",
	t3136: "размер шрифта",
	t3137: "Нет представлений для переноса",
	t3138: "Скачать десктопную версию",
	t3139: "Из-за ограничений безопасности в современных браузерах (",
	t3140: "RFC1918",
	t3141: ") к сожалению, невозможно подключиться к устройствам в частном сетевом пространстве с использованием незашифрованного HTTP. Настройте системного администратора на использование HTTPS или использование настольной версии.",
	t3142: "Нет данных за выбранный период",
	t3143: "Настройте системного администратора на использование HTTPS или настольной версии.",
	t3144: "Не удалось связаться с системным администратором. Это либо означает, что ваше устройство не может подключиться к IP-адресу или имени хоста администратора системы, либо вам может потребоваться вручную установить доверие к SSL-сертификату, используемому на устройстве.",
	t3145: "Щелкните следующую ссылку, чтобы открыть новую вкладку, чтобы доверять сертификату, а затем вернуться к этому экрану.",
	t3146: "Подпись",
	t3147: "DI - цифровые входы",
	t3148: "AI - аналоговые входы",
	t3149: "Перейти к конфигурации ХАССП",
	t3150: "Нет данных",
	t3151: "Резервная копия сохраняется в диспетчере системы для функции отката.",
	t3152: "Выберите, что отображать в представлениях сайта",
	t3153: "Единица актива",
	t3154: "Графические файлы",
	t3155: "К сожалению, при получении файлов и версий edf из системного менеджера произошла ошибка...",
	t3156: "Чтобы выбрать файлы для установки, нажмите кнопку «Выбрать файлы».",
	t3157: "Выбрать файлы",
	t3158: "Удаление резервной копии",
	t3159: "Промежуточные файлы",
	t3160: "Резервная копия сохраняется в диспетчере системы для функции отката.",
	t3161: "Установленный размер",
	t3162: "Новый размер",
	t3163: "Дата установки",
	t3164: "Новая дата",
	t3165: "Датчики",
	t3166: "Реле",
	t3167: "Входы",
	t3168: "Выходы",
	t3169: "Метры",
	t3170: "Общий",
	t3171: "Срок действия пароля истек, введите новый пароль",
	t3172: "Новый пароль",
	t3173: "Подтвердить Пароль",
	t3174: "Сменить пароль",
	t3175: "Пароль должен содержать не менее 8 символов (1 верхний регистр, 1 нижний регистр, 1 цифра, 1 специальный символ)",
	t3176: "Пароли не совпадают",
	t3177: "Пароль не может совпадать со старым паролем",
	t3178: "Смена пароля прошла успешно",
	t3179: "Пароль не может быть изменен. Ошибка с устройства",
	t3180: "Сброс пароля",
	t3181: "Сгенерировано",
	t3182: "Название сайта",
	t3183: "Идентификатор события",
	t3184: "Время",
	t3185: "Роль",
	t3186: "Информация о событиях",
	t3187: "Подтвердить выбранные тревоги",
	t3188: "Подтвердить все выбранные тревоги",
	t3189: "Импорт базы данных в",
	t3190: "Импорт базы данных",
	t3191: "Тип файла должен быть .zip",
	t3192: "Выбрать файл",
	t3193: "Часы работы",
	t3194: "каникулы",
	t3195: "График включения/выключения",
	t3196: "Актив",
	t3197: "У вас есть несохраненные изменения. Вы уверены, что хотите оставить?",
	t3198: "К сожалению, миграция не удалась. В настоящее время система занята. Пожалуйста, попробуйте позже",
	t3199: "Загрузить изображение с текущими размерами",
	t3200: "Заменить фоновое изображение",
	t3201: "Замените фоновые изображения масштабируемой векторной графикой.",
	t3202: "Заменяются только файлы SVG с тем же именем, что и у существующих файлов.",
	t3203: "Выберите файл(ы) для замены",
	t3204: "Файлы изображений",
	t3205: "не может быть обработан. Файл имеет недопустимый формат.",
	t3206: "{{amount}} файлов успешно заменено",
	t3207: "{{amount}} файлов не удалось заменить",
	t3208: "Вид не имеет фона",
	t3209: "Вы можете загрузить новое или заменить существующее фоновое изображение",
	t3210: "Если вы заменяете фоновое изображение, позиции параметров можно сохранить, если:",
	t3211: "Файл png или jpg имеет то же разрешение, что и текущий фон.",
	t3212: "Файл svg имеет такое же соотношение сторон (но не обязательно такое же разрешение)",
	t3213: "Функция отключена",
	t3214: "В настоящее время показывает последнее полученное значение, обновляется...",
	t3215: "Загрузите графический файл, созданный в",
	t3216: "(по желанию)",
	t3217: "Фоновая картинка",
	t3218: "Просматривать",
	t3219: "Более...",
	t3220: "Добавьте фоновое изображение (необязательно)",
	t3221: "Графический файл",
	t3222: "Тайм-аут в общении",
	t3223: "Графики охлаждения не настроены",
	t3224: "Индекс",
	t3225: "Тип",
	t3226: "каникулы",
	t3227: "Дней",
	t3228: "На",
	t3229: "Выключенный",
	t3230: "Я БЫ",
	t3231: "Открытым",
	t3232: "Начинать",
	t3233: "Конец",
	t3234: "Описание",
	t3235: "День",
	t3236: "Закрывать",
	t3237: "Файлы Edf успешно загружены. Перезапуск системного менеджера",
	t3238: "Загрузка файлов Edf завершена, системный менеджер успешно сброшен",
	t3239: "Ошибка при извлечении файлов edf из системного администратора",
	t3240: "Не удалось загрузить файл(ы) Edf. Попробуйте через некоторое время",
	t3241: "Графики охлаждения не настроены",
	t3242: "Загрузить файл в системный менеджер",
	t3243: "Укажите тип устройства — влияет на необходимые теги параметров для его работы.",
	t3244: "Укажите, как осуществляется доступ к значениям устройства и какую функцию они выполняют",
	t3245: "Создайте сигнал тревоги на основе небольшого параметра",
	t3246: "Этикетки",
	t3247: "Создайте список меток, которые можно присвоить параметру",
	t3248: "Группы",
	t3249: "Укажите группы параметров",
	t3250: "Регистрационный номер",
	t3251: "Тип данных",
	t3252: "Список ярлыков",
	t3253: "Минимум",
	t3254: "По умолчанию",
	t3255: "Максимум",
	t3256: "Тип регистрации",
	t3257: "Тип регистра транслируется в соответствующие функциональные коды для чтения и записи.",
	t3258: "Поменять режим",
	t3259: "Для значения с байтами ABCD, отправляемого на устройство, эта опция меняет местами байты (BADC), слова (CDAB) или и то, и другое (DCBA).",
	t3260: "Только чтение",
	t3261: "логирование",
	t3262: "Включает ведение журнала истории для этого параметра",
	t3263: "Перенести текст из параметра в сигнал тревоги",
	t3264: "Позиция тревожного флага, считая от них младший значащий бит параметра",
	t3265: "Инициировать сигнал тревоги при низком значении",
	t3266: "Этикетка",
	t3267: "Скрывать",
	t3268: "{{field}} является обязательным полем.",
	t3269: "Максимум должен быть больше или равен минимуму",
	t3270: "Значение по умолчанию должно быть в [Минимум, Максимум]",
	t3271: "Тип данных должен быть {{reg}} для выбранного типа регистра {{mt}}",
	t3272: "Имя не должно содержать знаков-разделителей",
	t3273: "Имя не должно превышать 20 символов",
	t3274: "Список ярлыков не должен быть пустым",
	t3275: "Тег(и) {{taglist}} должен быть назначен ровно один раз.",
	t3276: "Группа(ы) {{grouplist}} содержит более 50 параметров.",
	t3277: "Форма содержит ошибки, проверьте ошибки и повторите попытку.",
	t3278: "Представлять на рассмотрение",
	t3279: "Чтобы активировать загруженный EDF, вам необходимо перезагрузить устройство. Вы хотите перезагрузить устройство сейчас?",
	t3280: "Сбросить сейчас",
	t3281: "Сбросить позже",
	t3282: "{{file}} уже существует. Вы хотите перезаписать его?",
	t3283: "Загрузить {{what}}",
	t3284: "Метки и значения должны быть уникальными.",
	t3285: "Мульти",
	t3286: "Устройство обрабатывает несколько ящиков или упаковок",
	t3287: "Теги",
	t3288: "Internet Explorer устарел и не поддерживается StoreviewWeb.",
	t3289: "Пожалуйста, переключитесь на поддерживаемый браузер: Edge, Chrome или Firefox.",
	t3290: "Дополнительная информация",
	t3291: "Закрывать",
	t3292: "Не удалось прочитать данные. Повторите попытку.",
	t3293: "Выполняется резервное копирование файлов EDF",
	t3294: "Нет представлений для переноса",
	t3295: "Система настроена с общедоступным IP-адресом в поле частного IP-адреса.",
	t3296: "В системе настроен частный IP-адрес в поле общедоступного IP-адреса, система может быть недоступна удаленно или некоторые функции могут работать неправильно.",
	t3297: "Вы подключены через IP или имя хоста, которые не известны Администратору системы, вероятно, через прокси-сервис, некоторые функции могут работать некорректно",
	t3298: "Устройство не в сети, отображается последнее известное значение",
	t3299: "Интеграция устройств",
	t3300: "Теги {{taglist}} дублируются.",
	t3301: "Страница не найдена",
	t3302: "К сожалению, запрошенная вами страница не найдена",
	t3303: "Код ошибки: 404",
	t3304: "Перейти к приборной панели",
	t3305: "Подключенный IP",
	t3306: "Внутренний IP-адрес",
	t3307: "Внешний IP-адрес",
	t3308: "Доменное имя",
	t3309: "Нет предупреждений",
	t3310: "Несколько предупреждений",
	t3311: "Похоже, что система настроена на несколько устройств, но нам не удалось связаться с одним или несколькими подчиненными устройствами. Убедитесь, что настройки верны. Обратите внимание, что установка нескольких устройств не поддерживает несколько доменных имен и что для каждого устройства необходимо настроить отдельный порт при использовании одного и того же домена.",
	t3312: "Только просмотр",
	t3313: "Этот элемент не связан ни с одним параметром и может быть безопасно удален.",
	t3314: "Удалить параметр",
	t3315: "Расписание",
	t3316: "Теперь вы выйдете из системы и вам нужно будет войти в систему с новым паролем.",
	t759: "Загрузка базы данных, сброс и обновление устройства. Это может занять несколько минут.",
	t816: "Тип будильника:",
	t818: "Считать :",
	t848: "Файл БД не создается. У вас ограниченный доступ к установленному каталогу. Пожалуйста, свяжитесь с системным администратором.",
	t3317: "Нет расписаний для экспорта",
	t3318: "Ожидается ответ от системы",
	t3319: "Нет сетевой активности",
	t3320: "Название магазина",
	t3321: "Новая версия {{latestVersion}} доступна онлайн",
	t3322: "Внешний порт",
	t3323: "Внутренний порт",
	t3324: "Мы не можем связаться с системным администратором на",
	t3325: "может сертификату нужно доверять?",
	t3326: "Файл этого формата не может быть удален",
	t3327: "Применение",
	t3328: "Вовремя",
	t3329: "Время отключения",
	t3330: "Будни",
	t3331: "Стадия охлаждения",
	t3332: "Рекуперация тепла",
	t3333: "Охлаждение",
	t3334: "Отопление",
	t3335: "Вспомогательное отопление",
	t3336: "Осушение",
	t3337: "Интернет",
	t3338: "Сообщения",
	t3339: "Контакты",
	t3340: "Связь",
	t3341: "ничего не найдено",
	t3342: "Введите для поиска",
	t3408: "Включить все",
	t3409: "Отключить все",
	t3410: "Нормальный",
	t3411: "Серьезный",
	t3412: "Критический",
	t3413: "Включить все будильники",
	t3414: "Поиск будильников",
	t3415: "Включено",
	t3417: "Ошибка при получении будильников",
	t3418: "Ошибка при извлечении устройств для копирования",
	t3419: "Ошибка при проверке статуса копии",
	t3420: "Автоматический опрос возобновится по истечении максимального времени ожидания в 2 часа или в случае отмены или завершения работы мастера.",
	t3421: "Копировать на контроллер(ы)",
	t3422: "Копирование на выбранное устройство(а)",
	t3423: "Функция копирования сначала скопирует настройки в базу данных System Manager, а затем загрузит настройки в устройства контроллера. Выполнение действия может занять некоторое время.",
	t3424: "Устройства для копирования не выбраны.",
	t3425: "Дважды щелкните, чтобы скопировать",
	t3426: "Ошибка при загрузке устройств",
	t3427: "Устройства для копирования",
	t3428: "Выберите устройство(а) для копирования",
	t3429: "Здесь будут показаны только контроллеры одного типа.",
	t3430: "Процесс копирования успешен",
	t3431: "Неверный файл. Ожидаемая модель файла:",
	t3432: "Ошибка при загрузке файла настроек",
	t3435: "Вы уверены, что хотите переместить выбранное устройство в эту группу {{toPackGroup}} ?",
	t3343: "Открытие",
	t3344: "Обновить таблицу",
	t3345: "Сканирование сетей, пожалуйста, подождите...",
	t3346: "Сетевая таблица обновлена",
	t3347: "Ошибка при обновлении сетей",
	t3348: "Ошибка при повторном сканировании сетей",
	t3349: "Ошибка при загрузке сетей",
	t3350: "Сканирование сети завершено",
	t3351: "Нет доступных устройств",
	t3352: "Сканировать сеть",
	t3353: "Uncfg",
	t3354: "Любая перестановка устройств в мастере может повлиять на работу служб мониторинга Alsense.",
	t3355: "Топология сети",
	t3356: "Выберите тип полевой шины",
	t3357: "Если Modbus/RS485 включен, вы можете выбрать SLV для компрессора переменной скорости Danfoss. При выборе SNMP в качестве локальной шины вам потребуется ввести допустимый диапазон адресов подсети с помощью элементов управления SNMP Danfoss",
	t3358: "Компрессоры/Пакеты",
	t3359: "Управление контроллерами компрессоров/пакетов",
	t3360: "Здесь можно управлять только устройствами Pack, а не системой Rack I/O. Совместимые контроллеры Multi-Suction Pack, которые были просканированы, изначально будут отображаться как 0 групп всасывания. Для настройки контроллера Pack требуется AK-ST 500 (Service Tool).",
	t3361: "Сумма для копирования",
	t3362: "Уменьшение ранее настроенной группы всасывания также приведет к удалению всех связанных с ней элементов управления испарением для этой группы всасывания.",
	t3363: "Количество групп всасывания не может быть равно нулю, минимальное количество групп всасывания должно быть не менее одной.",
	t3364: "Ошибка при загрузке компрессоров/пакетов",
	t3365: "Нет доступных компрессоров/наборов",
	t3366: "Онлайн-устройства",
	t3367: "Оффлайн устройства",
	t3369: "Добавить пакет",
	t3370: "При выборе Modbus/RS485 вы увидите дополнительные параметры для SLV/ECFan, они используются для охлаждения и могут быть выбраны как Нет.",
	t3371: "Добавить цепь",
	t3372: "Добавить, скопировать или удалить устройства. Обратите внимание, что не все типы устройств позволяют редактировать все поля, а адреса точек ввода/вывода задаются в конфигурации.",
	t3373: "Управление контроллерами дел",
	t3374: "Добавить устройство",
	t3375: "Управление контроллерами компрессоров/пакетов",
	t3376: "Ошибка доступа к данным",
	t3377: "Планировка HVAC была успешно применена",
	t3378: "Планировка ОВиК",
	t3379: "Вы уверены, что хотите покинуть эту страницу?",
	t3380: "Ваши настройки могут быть утеряны",
	t3381: "Пожалуйста, настройте устройство",
	t3382: "Выйти из быстрой настройки",
	t3383: "Вы уверены, что хотите выйти из режима быстрой настройки?",
	t3384: "Шлюзы (AK-PI200)",
	t3385: "Выберите найденные шлюзы",
	t3386: "IP Адрес",
	t3387: "Контроллер источника",
	t3388: "Настройки источника",
	t3389: "Источники тревог",
	t3390: "Исходный график",
	t3392: "График",
	t3393: "Устройства",
	t3394: "Автоматический опрос приостановлен на этапе копирования. Главный выключатель исходного и целевого устройства(-ий) будет выключен, а затем возвращен в исходное состояние после завершения копирования. Обязательно проверьте главный выключатель после завершения работы мастера копирования. Если какое-либо из выбранных устройств находится в состоянии активной тревоги, любые изменения в действии тревоги для этой конкретной точки не будут изменены. Исходная настройка останется.",
	t3395: "Выберите исходное устройство для копирования настроек.",
	t3396: "Вовремя",
	t3397: "Функция копирования копирует доступные точки графика до тех пор, пока не будет достигнута емкость блока. Перейдите в Конфигурацию, чтобы увидеть количество определенных точек",
	t3398: "Графическое представление исходного устройства",
	t3399: "Настройте точки графика для исходного контроллера",
	t3400: "Включите HACCP для регистрации точек в температурном отчете HACCP",
	t3401: "Поиск точек",
	t3402: "Нет доступных данных",
	t3403: "Ошибка при извлечении точек графика",
	t3404: "Выключено",
	t3405: "Сигналы тревоги исходного устройства",
	t3406: "Настройте детали тревоги в исходном контроллере",
	t3407: "Имя сигнала тревоги",
	t3436: "Устройство будет удалено из назначенной группы [ {{fromPackGroup}} ]",
	t3437: "Компоновка Холодильное оборудование",
	t3438: "Схема охлаждения была успешно применена",
	t3439: "Переназначить",
	t3440: "Назначить элементы управления случаями элементам управления упаковкой",
	t3441: "Назначьте элементы управления корпусами (из доступных корпусов) элементам управления компрессором/пакетом, формируя группы всасывания",
	t3442: "Параметры устройства",
	t3443: "Выберите параметры для копирования",
	t3444: "При работе на подключенном (онлайн) устройстве возможна задержка в настройках из-за связи с устройством.",
	t3445: "Выберите сигналы тревоги для копирования",
	t3446: "Граф устройства",
	t3447: "Выберите точки графика для копирования",
	t3448: "Сохранить текущие настройки устройства в файл",
	t3449: "Вы можете сохранить текущие настройки устройства в файл, который можно использовать для настройки устройств в дальнейшем. Если вы хотите выйти из мастера после сохранения файла, используйте значок выхода. При работе с подключенными устройствами выход обеспечит возврат сетевого опроса и главного переключателя устройства в правильное состояние.",
	t3450: "Файл(ы) настроек успешно сохранены",
	t3451: "Хотите продолжить или выйти из быстрой настройки?",
	t3452: "Сохранение файла(ов)",
	t3453: "Ошибка при загрузке настроек",
	t3454: "Настройки исходного устройства",
	t3455: "Настройте параметры устройства в исходном контроллере",
	t3456: "Ни одно из полей пароля не должно быть пустым.",
	t3457: "Недавние",
	t3458: "Адресная книга",
	t3459: "Последний доступ",
	t3460: "Пожалуйста, регулярно создавайте резервную копию адресной книги.",
	t3461: "Адресная книга успешно импортирована",
	t3462: "Импорт Адресной книги",
	t3463: "Экспорт Адресной книги",
	t3464: "Добавить объект",
	t3465: "Редактировать сайт",
	t3466: "Удалить местоположение",
	t3467: "Прежде чем начать пользоваться адресной книгой, вам необходимо создать пароль для нее.",
	t3468: "Сайт успешно добавлен в вашу адресную книгу",
	t3469: "Уже в адресной книге",
	t3470: "Добавить в адресную книгу",
	t3471: "Войти на сайт",
	t3472: "Информация о сайте успешно обновлена",
	t3474: "Уведомление",
	t3475: "Сайт был успешно удален из вашей адресной книги",
	t3476: "Сайт успешно обновлен",
	t3477: "Неверный формат адресной книги",
	t3478: "Действия",
	t3479: "Недоступно в автономном режиме",
	t3480: "Не удалось загрузить базу данных S55",
	t3481: "Загрузка базы данных S55",
	t3482: "Протокол",
	t3483: "У пользователя нет разрешения на просмотр информации о программном обеспечении",
	t3484: "Пароль должен состоять только из букв, цифр или цифр.",
	t3485: "Для доступа к адресной книге вам необходимо создать пароль адресной книги.",
	t3486: "Неверный пароль адресной книги",
	t3487: "Сайт уже присутствует в адресной книге",
	t3488: "Создайте пароль адресной книги для использования адресной книги. Пароль должен состоять только из букв и цифр",
	t3489: "Введите пароль адресной книги",
	t3490: "Нет доступных онлайн-устройств для копирования",
	t3491: "Создать пароль адресной книги",
	t3492: "Обновить пароль адресной книги",
	t3493: "Пароль сайта не может быть пустым",
	t3494: "Введите пароль импортируемой адресной книги.",
	t3495: "Пароль адресной книги был успешно создан",
	t3496: "Пароль адресной книги успешно обновлен",
	t3497: "Неверный пароль импортируемой адресной книги.",
	t3498: "Введите пароль сайта",
	t3499: "Очистить адресную книгу",
	t3500: "Поиск сайтов",
	t3501: "Выберите файл адресной книги для импорта",
	t3502: "Выберите файл ключа для расшифровки адресной книги",
	t3503: "Нажмите «Экспорт», чтобы загрузить зашифрованную адресную книгу.",
	t3505: "С зашифрованным",
	t3506: "незашифрованные пароли",
	t3507: "Неверный секретный ключ",
	t3508: "Ошибка импорта. Убедитесь, что вы импортировали правильный ключ",
	t3509: "Нет доступных версий",
	t3510: "Открытые службы Alsense (требуется подписка)",
	t3511: "Пожалуйста, внимательно ознакомьтесь с условиями EULA. Если вы не можете принять условия, вам не разрешается использовать приложение.",
	t3512: "Адресная книга и ваш пароль адресной книги были успешно очищены",
	t3513: "(необходимый)",
	t3515: "Адресная книга пуста",
	t3516: "Пароль адресной книги не может быть пустым",
	t3519: "Очистив адресную книгу, вы также удалите свой пароль к адресной книге.",
	t3520: "Не в диапазоне. Должно быть от 0 до {{maxAddr}}",
	t3521: "Нажмите «Экспорт», чтобы загрузить все графические представления, сохраненные в System Manager.",
	t3522: "Нажмите «Загрузить», чтобы загрузить системный отчет.",
	t3523: "Только главный блок доступен при прямом подключении по WiFi.",
	t3524: "Нет локальных графических представлений. Добавьте новые или импортируйте существующие представления",
	t3525: "Нет удаленных графических представлений. Попробуйте поискать по другому имени",
	t3526: "Нет удаленных графических представлений. Добавьте новые, импортируйте или перенесите существующие представления",
	t3527: "Локальные просмотры ( {{totalFiles}} из 5)",
	t3528: "{{totalFiles}} локальных графических файлов из максимум 5",
	t3529: "Удаленные виды",
	t3530: "Файл не является растровым изображением.",
	t3531: "Неправильная длина битового изображения.",
	t3532: "Неправильный размер заголовка битовой карты.",
	t3533: "Неправильный размер растрового изображения.",
	t3534: "Ошибка в размере палитры.",
	t3535: "Несовместимый файл .bmp",
	t3536: "Ошибка при удалении локального графического файла",
	t3537: "Ошибка при копировании локального графического файла",
	t3538: "Ошибка при экспорте локального графического файла",
	t3539: "Удалить графику",
	t3540: "Удалить локальный графический файл",
	t3541: "Выполняется удаление локального графического файла",
	t3542: "Локальный графический файл успешно удален. Перезапуск System Manager",
	t3543: "Удаление локального графического файла завершено. Системный менеджер успешно перезапущен",
	t3544: "Дублировать графику",
	t3545: "Дублировать локальный графический файл",
	t3546: "Выполняется дублирование локального графического файла",
	t3547: "Локальный графический файл успешно продублирован. Перезапуск System Manager",
	t3548: "Локальный графический файл дублирования завершен. Системный менеджер перезапущен успешно",
	t3549: "Экспортировать графику",
	t3550: "Экспорт локального графического файла",
	t3551: "Выполняется экспорт локального графического файла",
	t3552: "Локальный графический файл успешно экспортирован",
	t3553: "Системный менеджер перезапустится при этом действии.",
	t3554: "Вы уверены, что хотите удалить?",
	t3555: "Вы уверены, что хотите дублировать?",
	t3556: "Нажмите «Экспорт», чтобы загрузить все локальные графические представления, хранящиеся в System Manager.",
	t3557: "Выполняется экспорт локальных графических файлов",
	t3558: "Локальные графические файлы успешно экспортированы",
	t3559: "Код продукта",
	t3560: "Получить больше информации об этом продукте",
	t3561: "Фан-эко",
	t3562: "Открытие клапана",
	t3563: "Перегрев",
	t3564: "Давление испарителя",
	t3565: "Температура испарителя",
	t3566: "Вырезать",
	t3567: "Кутин",
	t3568: "Дверь корпуса",
	t3569: "Температура всасывания",
	t3570: "Рельсовое тепло",
	t3571: "Достигнут максимальный допустимый лимит файла. Отменить выбор файла(ов) для импорта",
	t3572: "Пожалуйста, удалите существующие файлы в системном менеджере, чтобы импортировать файлы.",
	t3573: "Локальный графический файл успешно импортирован. Перезапуск System Manager",
	t3574: "Импорт локального графического файла(ов) завершен. Системный менеджер успешно перезапущен",
	t3575: "Достигнут максимальный допустимый лимит файла. Удалить файл(ы) для импорта",
	t3576: "Максимально допустимое количество графических файлов — 5.",
	t3577: "Локальный графический файл успешно загружен. Перезапуск System Manager",
	t3578: "Локальная загрузка графического файла завершена. Системный менеджер успешно перезапущен",
	t3579: "Неверный формат файла. Загрузите файл {{supportedFileFormats}}",
	t3580: "Размер файла должен быть {{maxWidthAllowed}} x {{maxHeightAllowed}} пикселей для локальных экранов.",
	t3581: "Удаленный просмотр",
	t3582: "Локальный вид",
	t3583: "Домашняя страница Storeview Web",
	t3584: "Условия лицензионного соглашения с конечным пользователем",
	t3585: "Безопасность",
	t3586: "Уровни обеспечения безопасности",
	t3587: "Обратная совместимость (самый низкий уровень безопасности — при условии, что отключена аутентификация по заголовку)",
	t3588: "Запросы SvW/XML содержат имя пользователя и пароль.",
	t3589: "Никакой очистки запросов, та же безопасность, что и в предыдущих версиях",
	t3590: "Не требуется вносить изменения в SvW или существующие XML-соединения.",
	t3591: "Разрешительный (может использоваться как переход к строгому уровню)",
	t3592: "Запросы XML могут содержать имя пользователя и пароль, а также токен сеанса. Токен сеанса принимается в ответе на запрос входа.",
	t3593: "Если токен сеанса указан в заголовке AKSM-auth, имена пользователей и пароли игнорируются.",
	t3594: "Запрос XML очищается, в ответе на запрос возвращается предупреждение, если обнаружены какие-либо проблемы",
	t3595: "Примите во внимание эти предупреждения при подготовке к переходу на строгий режим.",
	t3596: "Если токен сеанса не используется, срок действия сеанса не может быть применен.",
	t3597: "Уже поддерживается в удаленном пользовательском интерфейсе (SvW)",
	t3598: "Возможные/требуемые изменения с точки зрения стороннего XML",
	t3599: "Не используется токен сеанса — не требуется никаких изменений",
	t3600: "Использование токена сеанса — удалите имя пользователя и пароль из тела и соблюдайте предупреждения при подготовке к Strict",
	t3601: "Строгий (высший уровень безопасности)",
	t3602: "Запросы XML не могут содержать имена пользователей и пароли, но должны предоставлять токен сеанса в заголовке AKSM-auth. Если в запросе обнаружена проблема, он отклоняется",
	t3603: "Уже поддерживается в (SvW)",
	t3604: "Требуемые изменения с точки зрения третьей стороны",
	t3605: "Имя пользователя и пароль должны быть удалены из полезной нагрузки XML-запроса, а токен сеанса должен быть помещен в заголовок CORS с именем AKSM-auth.",
	t3606: "Откат из-за ошибки входа",
	t3607: "Сколько времени (в секундах) удаленный пользователь ждет, чтобы снова попытаться войти в систему после неудачной попытки. Время ожидания увеличивается кумулятивно с каждой неудачной попыткой. После 10 попыток IP, с которого пользователь пытался войти, блокируется на 24 часа. Если установлено значение 0, откат при неудачном входе отключен",
	t3608: "По умолчанию 30 с (макс. 600 с / 0 = отключено)",
	t3609: "Бесплатные попытки",
	t3610: "Сколько попыток входа разрешено, прежде чем будет применена отсрочка?",
	t3611: "По умолчанию 2 (макс. 5)",
	t3612: "Блокировать",
	t3613: "Если включено, пользователь + IP блокируются во время отката, в противном случае пользователь блокируется",
	t3614: "«Пользователь» по умолчанию (Пользователь + IP / Пользователь)",
	t3615: "Сеансов на пользователя",
	t3616: "Сеанс — это когда пользователь успешно вошел в устройство и находится в пределах удаленного интерфейса (SvW).",
	t3617: "Количество активных сессий, которые может иметь пользователь. Если установлено значение 0, пользователь может иметь максимальное количество сессий (30)",
	t3618: "По умолчанию 0",
	t3619: "Истечение срока сеанса",
	t3620: "Сколько секунд после истечения сеанса бездействия. Если сеанс истекает, требуется новый вход",
	t3621: "По умолчанию 900 (макс. 3600)",
	t3622: "Аутентификация заголовка",
	t3623: "При включении HTTPS и аутентификации заголовков все сторонние XML-запросы должны передавать учетные данные имени пользователя и пароля в заголовке (базовая схема аутентификации)",
	t3624: "Включение «Использовать аутентификацию заголовка» требует повторной инициализации системы. Используйте кнопку «Нажать для инициализации», чтобы выполнить это действие",
	t3625: "Маленький",
	t3626: "Большой",
	t3627: "Сохранить графику",
	t3628: "Сохранить локальный графический файл",
	t3629: "Локальный графический файл успешно удален. Загрузка локального графического файла в процессе",
	t3630: "Локальный графический файл успешно загружен. Перезапуск System Manager",
	t3631: "Сохранение локального графического файла завершено. Системный менеджер успешно перезапущен",
	t3632: "Вы уверены, что хотите сохранить?",
	t3633: "Синтаксический анализ не удался",
	t3634: "Не удалось проанализировать viz-файл",
	t3635: "Не удалось проанализировать файл vz2",
	t3636: "Не удалось открыть файл Viz:",
	t3637: "Файлы миграции (vz2, dpj или json) не найдены",
	t3638: "Файлы миграции (viz) не найдены",
	t3639: "System Manager перезапустится при этом действии. System Manager находится либо в разрешающем, либо в строгом режиме, поэтому токен сеанса истечет при перезапуске, и вы выйдете из системы",
	t3643: "Давление нагнетания",
	t3644: "Всего кВт",
	t3645: "Всего кВАР",
	t3646: "Всего Кварч+",
	t3647: "Требовать",
	t3648: "Коэффициент мощности",
	t3649: "Цвет шрифта",
	t3650: "Цвет фона",
	t3651: "Адресная книга успешно экспортирована",
	t3652: "Режим управления",
	t3653: "Sc3 Наружная температура",
	t3654: "Температура нагнетания",
	t3655: "Мощность конденсатора",
	t3656: "Нет данных для экспорта",
	t3657: "Срок действия пароля пользователя на {{unit}} истек",
	t3658: "Невозможно войти в {{unit}} с предоставленными учетными данными.",
	t3659: "Обновите версию прошивки или измените уровень безопасности для {{unit}} чтобы получить поддержку токена сеанса.",
	t3661: "V3gc",
	t3662: "Раздел Б",
	t3663: "Раздел С",
	t3664: "Адаптивное управление перегревом",
	t3665: "Причина статуса контроля",
	t3666: "Пользователь не может войти в систему. Адрес устройства не установлен в конфигурации прокси-сервера.",
	t3667: "Протокол связи не указан в конфигурации прокси. Это может привести к ошибкам",
	t3668: "Информация о безопасности ИТ",
	t3669: "В соответствии со стандартами безопасности System Manager теперь по умолчанию использует строгий контроль сеансов, что означает, что любое удаленное соединение или удаленная служба на основе XML больше не будут функционировать, если не будут применены токены сеансов.",
	t3670: "Danfoss рекомендует продолжать работу в строгом режиме, но режим обратной совместимости по-прежнему доступен.",
	t3671: "Обратите внимание, что это можно изменить в меню «Конфигурация безопасности», и это не повлияет на локальное управление System Manager и работу сайта.",
	t3672: "Подробнее об управлении сеансом см.",
	t3673: "Обратный режим",
	t3674: "Строгий режим",
	t3675: "При выборе режима обратной совместимости все соответствующие системные менеджеры с обновленной прошивкой будут переведены в режим обратной совместимости, перезапущены, а приложение будет закрыто.",
	t3676: "Максимально допустимое количество групп всасывания — 12.",
	t3677: "После успешного обновления выбранных модулей с версии 3.3.0 до 4.X или выше пользователь автоматически выйдет из системы при нажатии кнопки «Готово» на последнем этапе.",
	t3678: "Мультикейсы для контроллеров AK2 (Pack/Case) не поддерживаются.",
	t3679: "В соответствии со стандартами безопасности, введенными в версии 4.0, System Manager по умолчанию будет использовать строгий контроль сеанса, что означает, что любое удаленное соединение / удаленная служба на основе XML больше не будет работать, если не будут применены токены сеанса. Кроме того, связь будет автоматически настроена на HTTPS путем обновления до версии 4.0. Danfoss рекомендует продолжать работу в строгом режиме, но доступен режим обратной совместимости, который вернет систему к настройкам, примененным до сих пор. Обратите внимание, что если вы выполняете это обновление удаленно на HTTP-устройстве, убедитесь, что ваша ИТ-настройка подготовлена для HTTPS (порт 443) или на месте есть персонал.",
	t3680: "Более подробную информацию о новой реализации безопасности можно найти здесь.",
	t3681: "Доступ к http-версии этого сайта может быть заблокирован из-за ограничений вашего браузера. Посетите нашу страницу FAQ, чтобы получить инструкции по решению этой проблемы или загрузите настольную версию StoreView Web.",
	t3682: "Часто задаваемые вопросы о системном менеджере",
	t3683: "u17 Термический воздух",
	t3684: "u91 Температура отключения.",
	t3685: "u00 Состояние управления",
	t3686: "u12 S3 температура воздуха.",
	t3687: "u16 S4 температура воздуха.",
	t3688: "u23 EEV OD %",
	t3689: "u21 Перегрев",
	t3690: "u22 ПерегревRef",
	t3691: "u26 Температура испарения Te",
	t3692: "S2 Температура газа на выходе.",
	t3693: "Stw8",
	t3694: "Сд-МТ",
	t3695: "статус Tw",
	t3696: "Tw включить",
	t3697: "Два насоса работают",
	t3698: "Stw3",
	t3699: "Stw4",
	t3700: "Stw2",
	t3701: "Статус контроля приема",
	t3702: "Смещение всасывания",
	t3703: "Режим управления Vrec",
	t3704: "Статус Vrec",
	t3705: "Причина статуса Ctrl",
	t3706: "Статус управления Hp",
	t3707: "Pgc макс.",
	t3708: "Pgc макс. лимит P-бан",
	t3709: "Пгк мин.",
	t3710: "Мощность парового эжектора",
	t3711: "Емкость жидкостного эжектора",
	t3712: "Эталонная температура воздуха.",
	t3713: "Температура приточного воздуха.",
	t3714: "Возврат воздуха",
	t3715: "Зона точки росы",
	t3716: "Зональная влажность",
	t3717: "Температура наружного воздуха.",
	t3718: "Уровень CO2 в зоне",
	t3719: "Зональная температура.",
	t3720: "Условие ссылки",
	t3721: "Состояние контроля состояния",
	t3722: "Состояние контроля состояния",
	t3723: "Услов. упр.",
	t3724: "Условие запрошенной капитализации.",
	t3725: "Состояние V3gc",
	t3726: "V3gc ОД",
	t3727: "Конд. темп.",
	t3728: "статус кадрового резерва",
	t3729: "Hr включить",
	t3730: "Запрос потребителя HR",
	t3731: "Шр3",
	t3732: "Шр4",
	t3733: "Шр2",
	t3734: "Шр8",
	t3735: "Всасывание, ссылка на К-МТ",
	t3736: "Статус управления МТ",
	t3737: "Фактическая зона МТ",
	t3738: "Для выбора смещения MT",
	t3739: "Грузоподъемность MT",
	t3740: "Запрошенный кап. MT",
	t3741: "Время для следующего шага MT",
	t3742: "По-МТ",
	t3744: "Температура всасывания. До-MT",
	t3745: "Общая активная энергия",
	t3746: "Пик спроса",
	t3747: "Общий коэффициент мощности",
	t3748: "Фаза А вольт",
	t3749: "Фаза B вольт",
	t3750: "Фаза C вольт",
	t3751: "Фаза А усилители",
	t3752: "Усилители фазы B",
	t3753: "Фаза C усилители",
	t3754: "Общая активная мощность",
	t3755: "Температура всасывания To-LT",
	t3756: "Температура всасывания. To-IT",
	t3757: "Всасывание эталонное LT",
	t3758: "Статус управления LT",
	t3759: "Фактическая зона LT",
	t3760: "Для выбора смещения LT",
	t3761: "Пропускная способность LT",
	t3762: "Запрошенный капитал LT",
	t3763: "Время для следующего шага LT",
	t3764: "По-LT",
	t3765: "Сд-ЛТ",
	t3766: "Всасывание ссылка IT",
	t3767: "Статус управления ИТ",
	t3768: "Фактическая зона ИТ",
	t3769: "Мощность ИТ",
	t3770: "Запрашиваемая мощность ИТ",
	t3771: "Время для следующего шага ИТ",
	t3772: "Tn Po-IT",
	t2773: "Сд-ИТ",
	t2774: "Начало ИТ-комп.",
	t3774: "Не в диапазоне. Должно быть между {{minAddr}} и {{maxAddr}}",
	t3775: "Операция отключена для этого устройства",
	t3776: "После этого действия приложение выйдет из системы, что может занять несколько минут.",
	t3777: "Файлы миграции (flp или json) не найдены",
	t3778: "Опрос",
	t3779: "В настоящее время голосование приостановлено.",
	t3780: "Хотите ли вы возобновить голосование?",
	t3781: "Счетчик энергии",
	t3782: "Выбрано несколько узлов",
	t3783: "Увеличить до узла",
	t3784: "Единицы измерения",
	t3785: "Файл(ы) успешно экспортированы",
	t3786: "Адрес не может быть отрицательным.",
	t3787: "Сбросить масштаб",
	t3788: "Ошибка при получении шагов шаблона подготовки",
	t3789: "Ошибка при сохранении шагов шаблона промежуточного этапа",
	t3790: "Шаги шаблона постановки успешно сохранены",
	t3791: "Постановочный шаблон",
	t3792: "Нажмите на значки разгрузки, чтобы включить/отключить разгрузчики, связанные с компрессорами.",
	t3793: "Отсутствующие данные",
	t3794: "Следующие параметры не вернули никаких точек данных",
	t3795: "Доверие HTTPS/SSL установлено успешно.",
	t3796: "Теперь вы можете войти в веб-браузер StoreView.",
	t3797: "Перенаправление в веб-браузер StoreView через {{timeLeft}} секунд...",
	t3798: "В настоящее время голосование приостановлено!",
	t3799: "Очищенные сигналы тревоги (полные системные журналы)",
	t3800: "Максимально допустимое количество систем отопления, вентиляции и кондиционирования воздуха — 45.",
	t3801: "Не удалось получить журнал изменений",
	t3802: "Подключение к хранилищу...",
	t3803: "Тайм-аут соединения с хранилищем SCADA",
	t3804: "Не удалось подключиться к хранилищу SCADA",
	t3805: "Недействительная дата",
	t3806: "Выберите единицу(ы) для загрузки отчета"
};
var ru = {
	lang: lang$9
};

var lang$a = {
	t0: "Alarmas Activas",
	t1: "Alarmas Solucionadas",
	t2: "Servidor",
	t3: "Ident.",
	t4: "Nombre Dispositivo",
	t5: "Editar Notas",
	t6: "No autorizado a reconocer alarmas",
	t7: "Tipo de Alarma",
	t8: "Ocurrió",
	t9: "Reconocido",
	t10: "Alarma/s activa/s",
	t11: "Solucionado",
	t12: "Cálculos",
	t13: "Alarmas Solucionadas",
	t14: "Silenciar/Reconocer",
	t15: "Reconocer todas las alarmas",
	t16: "Fallo en Autorización",
	t17: "Error",
	t18: "Control Manual",
	t19: "Visual",
	t20: "Resumen Datos",
	t21: "Vista Técnica",
	t22: "Grafico (S4)",
	t23: "Nodo",
	t24: "Mod.",
	t25: "Gráfico (Po & Pc)",
	t26: "(no hay alarmas)",
	t27: "Alarmas Controladores",
	t28: "Ver controlador anterior",
	t29: "Ver controlador siguiente",
	t30: "Error al leer alarmas",
	t31: "Alarmas Activas",
	t32: "Alarma Activa",
	t33: "No hay alarmas",
	t34: "Escribe",
	t35: "Punto",
	t36: "Registro Histórico",
	t37: "Horarios",
	t38: "Hora de inicio",
	t39: "Hora Parada",
	t40: "Días",
	t41: "Festivos",
	t42: "Estado",
	t43: "Seleccionar puntos",
	t44: "OK",
	t45: "Cancelar",
	t46: "Auto",
	t47: "Final",
	t48: "Fecha Inicio",
	t49: "Frecuencia de muestreo",
	t50: "No hay datos seleccionados",
	t51: "Ahora",
	t52: "seg",
	t53: "min",
	t54: "horas",
	t55: "Dia",
	t56: "Tipo Dirección",
	t57: "Direccion",
	t58: "Localización:",
	t59: "Introducir Valor Nuevo",
	t60: "Actualizar",
	t61: "Seleccionar puntos",
	t62: "Seleccione el Nuevo Valor",
	t63: "OFF",
	t64: "ON",
	t65: "Recogiendo datos...",
	t66: "Error al recoger datos...",
	t67: "No hay historicos configurados...",
	t68: "Error al recoger datos...",
	t69: "Recogiendo datos historicos para",
	t70: "Error al recoger datos historicos...",
	t71: "Usuario",
	t72: "Contraseña",
	t73: "Direccion Host IP/URL",
	t74: "OK",
	t75: "Cancelar",
	t76: "Nombre",
	t77: "Valor",
	t78: "Error al buscar lista de usuarios",
	t79: "Meta Nueva Dirección",
	t80: "Fallo al coger lista usuarios. Verifique direccion IP/URL del Host",
	t81: "Cod.Autoriz.",
	t82: "Codigo Acct",
	t83: "Seleccione nueva Hora y Fecha",
	t84: "Seleccione nueva duracion",
	t85: "Operación Fallida",
	t86: "Introduzca Informacion Autorización",
	t87: "Clima",
	t88: "¡Vamos!",
	t89: "Control Desescarche",
	t90: "Iniciar Desesc. Manual",
	t91: "Control Luces Muebles",
	t92: "Luces Vitrina Manual ON",
	t93: "Luces Vitrina Manual OFF",
	t94: "Control Limpieza Muebles",
	t95: "Limpieza Manual ON",
	t96: "Limpieza Manual OFF",
	t97: "Interr. Principal",
	t98: "Interruptor Ppal. Manual ON",
	t99: "Interruptor Ppal. Manual OFF",
	t100: "Apagar Controlador",
	t101: "Apagar Controlador",
	t102: "Encender Controlador",
	t103: "Control Ajuste Nocturno",
	t104: "Ajuste Nocturno ON",
	t105: "Ajuste Nocturno OFF",
	t106: "Mensaje enviado...",
	t107: "Control Manual",
	t108: "Cerrar",
	t109: "Iluminación",
	t110: "Energia/Varios",
	t111: "Vista General",
	t112: "Seleccione unidad AK255 a mostrar",
	t113: "Cargando...",
	t114: "de",
	t115: "Todos los AK-SC",
	t116: "Previo",
	t117: "Páginas",
	t118: "Siguiente",
	t119: "Parametro",
	t120: "Medidas",
	t121: "Recogiendo datos...",
	t122: "Error al leer seccion",
	t123: "Temperatura",
	t124: "Retr. despues desescarche",
	t125: "Alrm si esta abajo",
	t126: "Alrm si esta arriba",
	t127: "Monitorizar",
	t128: "Desconectado",
	t129: "Activo",
	t130: "Servidor",
	t131: "Dirección",
	t132: "Descripción",
	t133: "Valor Actual",
	t134: "Estado Actual",
	t135: "Controladores",
	t136: "Unidad",
	t137: "Hay Alarma Activa",
	t138: "¡ Hay Alarma Activa !",
	t139: "Entradas Digitales",
	t140: "Salidas Relé",
	t141: "Entdas Sensor",
	t142: "Salidas Variable",
	t143: "Controladores Genericos",
	t144: "Otros",
	t145: "Ver Puntos",
	t146: "Vista General Tienda",
	t147: "Salir del Sistema",
	t148: "Entrar Usuario",
	t149: "Salida Usuario",
	t150: "Refrigeración",
	t151: "Vista General Dispositivo",
	t152: "Alarmas",
	t153: "Historia",
	t154: "Vista Gral. CS",
	t155: "Vista Gral. Tienda",
	t156: "Configuración",
	t157: "Vista Tienda",
	t158: "Vista Planta",
	t159: "Inicio",
	t160: "Alarma",
	t161: "Ajuste",
	t162: "Detalle Controlad.",
	t163: "Ajustes",
	t164: "Seleccionar Fecha",
	t165: "Información",
	t166: "Reg. Audito",
	t167: "Cálculos",
	t168: "Admon. Archivo",
	t169: "Manual de Ayuda",
	t170: "Info",
	t171: "Traductor",
	t172: "Salvar a Servidor",
	t173: "¿Actualizar ficheros de lenguaje?",
	t174: "Esta operacion actualizará el fichero de idioma en la unidad, Continuar?",
	t175: "Exportar Históricos",
	t176: "Seleccionar Nuevo Valor",
	t177: "mínimo",
	t178: "máximo",
	t179: "¡Ninguna alarma activa!",
	t180: "No admite cambios!",
	t181: "Por favor espere...",
	t182: "Comando Enviado!",
	t183: "Fallo en la operación, intentelo mas tarde",
	t184: "Operacion con éxito",
	t185: "¡Operación no autorizada!",
	t186: "¿Esta seguro que quiere continuar?",
	t187: "Evaporadores",
	t188: "Central",
	t189: "Entradas para monitoriz.",
	t190: "Varios",
	t191: "Alarmas Reconocidas",
	t192: "Borrar alarmas reconocidas",
	t193: "Borrar todas las alarmas reconocidas",
	t194: "No esta autorizado a borrar alarmas",
	t195: "No autorizado",
	t196: "¿Quiere reconocer esta alarma?",
	t197: "Confirmar para reconocer alarma",
	t198: "Por favor registrese antes",
	t199: "Seleccione una alarma para borrar",
	t200: "Confirmar para borrar alarmas",
	t201: "Esta seguro que quiere borrar esta alarma?",
	t202: "No autorizado para borrar alarmas",
	t203: "Esta seguro que quiere borrar TODAS las alarmas?",
	t204: "Alarmas seleccionadas no se pueden borrar",
	t205: "Ajustes de Alarma",
	t206: "Esta operacion la debe realizar un Supervisor autorizado",
	t207: "No se reciben datos desde la unidad",
	t208: "Seleccione una unidad",
	t209: "Seleccione una unidad de la lista",
	t210: "Numero de alarmas",
	t211: "Estado Alarma",
	t212: "Varios",
	t213: "Borrar Alarma Seleccionada",
	t214: "Por favor confirme",
	t215: "Fallo en conexion a unidad. Verifique la conexion de red.",
	t216: "Alarma desescarche",
	t217: "Alarma digital",
	t218: "Estado Entrada Digital",
	t219: "Entrada Desescarche",
	t220: "Entrada Limpieza",
	t221: "Entrada Digital",
	t222: "Salvando ficheros de idioma",
	t223: "Unidades",
	t224: "No hay dispositivo configurado en el Sistema. Sitio web deshabilitado",
	t225: "Advertencia:",
	t226: "Seleccione la Hora",
	t227: "Comunicaciones",
	t228: "¿Volcar fichero de informes?",
	t229: "Por favor espere mientras se genera el fich de informes...",
	t230: "Volcar Informe",
	t231: "Estado Alarma/Servicio",
	t232: "Por espere, recogiendo datos",
	t233: "Registrado",
	t234: "Usuario no registrado",
	t235: "Usuario registrado",
	t236: "¡Bienvenido!",
	t237: "Licencia no válida",
	t238: "Controladores Energia",
	t239: "Paneles Iluminacion",
	t240: "Variador AKD/VLT",
	t241: "Modulo Compresor",
	t242: "Puntos Controlador",
	t243: "Puntos Calculados",
	t244: "Paneles Cutler-Hammer",
	t245: "Portadores",
	t246: "Vista Avanzada",
	t247: "Haga doble click  para Zoom In/Out",
	t248: "Haga click para Zoom In(Out",
	t249: "Alarma(s) Reconocidas",
	t250: "Simulador",
	t251: "Area Refrig.",
	t252: "Area Clima",
	t253: "Area Luces",
	t254: "Area Varios",
	t255: "Conectado a Red",
	t256: "Variad",
	t257: "Referencia",
	t258: "Grafica (Drive Mtr Hz & Amp)",
	t259: "Fecha Finaliz.",
	t260: "Parado",
	t261: "La hora de finalización es igual o anterior a la hora de inicio, vuelva a seleccionar la fecha/hora",
	t262: "La hora de creación del historial de los puntos de datos",
	t263: "despues de tiempo de inicio seleccionado.",
	t264: "Los puntos de muestreo superan los 100.000, va a llevar tiempo recoger las variables.",
	t265: "El procesador de Historicos esta ocupado, espere por favor 2 minutos e intentelo de nuevo",
	t266: "Guardar",
	t267: "Panel de Control de Historicos",
	t268: "Primero",
	t269: "Último",
	t270: "Imprimir",
	t271: "Disminuir Zoom",
	t272: "Aumentar Zoom",
	t273: "Mostr/Ocultar Leyenda",
	t274: "Exportar Datos Historicos a Fich",
	t275: "Mostrar/Ocultar malla",
	t276: "Vista Una Página",
	t277: "Vista Multi Pagina",
	t278: "Cargar Historicos (grp) de fichero",
	t279: "Cargar Historicos desde fichero",
	t280: "Salvar Historicos a fichero",
	t281: "Contador Multi Paginas",
	t282: "Paginas",
	t283: "Seleccione una pagina para mostrar",
	t284: "Historicos deben estar en formato .hst!",
	t285: "No hay datos, por favor verifique los historicos y la fecha/tiempo seleccionado",
	t286: "Historicos, Pantallazo",
	t287: "Historicos, Vista Completa",
	t288: "Vista Ficheros Históricos",
	t289: "Elegir...",
	t290: "Esta operacion exportará los Historicos actuales a fichero .hst ó .csv",
	t291: "Seleccione un fichero.",
	t292: "Archivo",
	t293: "Carga Historicos (grp)…",
	t294: "Cargar Históricos…",
	t295: "Salvar Historicos (grp)…",
	t296: "Ver",
	t297: "Zoom",
	t298: "Si",
	t299: "No",
	t300: "¿Recuperar la configuración del controlador?",
	t301: "No preguntar más",
	t302: "Recibir Configuracion Controlador",
	t303: "Datos recibidos",
	t304: "Nivel Alarma",
	t305: "Accion Alarma",
	t306: "Reconocido",
	t307: "Salvar datos al Disco",
	t308: "Nombre Fichero",
	t309: "Descripción BaseDatos",
	t310: "Directorio BaseDatos",
	t311: "Versión",
	t312: "Hora",
	t313: "Exportar Alarmas",
	t314: "Base Datos guardada en",
	t315: "Fallo al salvar BD, verifique si el directorio y nombre fich son validos",
	t316: "Nombre Fichero no es correcto, verifique nombre fichero de BaseDatos",
	t317: "Alarmas exportadas con exito, elija un fichero para salvar las alarmas exportadas",
	t318: "Seleccione las alarmas a exportar",
	t319: "Recogiendo informacion de la Unidad(es)",
	t320: "Descripcion Fich.",
	t321: "Convertir fich .hst a formato .csv",
	t322: "Paso 1: Seleccione el fichero .hst",
	t323: "Paso 2: Seleccione los datos y el rango de tiempo.",
	t324: "Paso 3: Seleccione el fichero .csv",
	t325: "Guardar como...",
	t326: "No se permiten más de 6000 puntos de datos en esta operación",
	t327: "Puntos de Datos seleccionados",
	t328: "No se permite exportar mas de 65536 muestras por punto, por favor cambie el intervalo de muestro o el rango de tiempo.",
	t329: "Colectar",
	t330: "Recoger historicos desde la unidad",
	t331: "Seleccionar puntos recogidos",
	t332: "No hay valor",
	t333: "Perdido",
	t334: "Suspendido",
	t335: "Por favor haga una seleccion",
	t336: "Seleccione los puntos a dibujar",
	t337: "Guardar a fichero .hst",
	t338: "Volcado de datos finalizado, seleccione one de las opciones siguientes:",
	t339: "Seleccione como maximo 8 puntos para dibujar",
	t340: "Salvar datos historicos a fichero .hst",
	t341: "Imprimir Vista Gral.",
	t342: "Por favor selecciones Guardar o Imprimir el Informe",
	t343: "Elija un formato de fich.",
	t344: "Generando fichero de VistaGral, por favor espere...",
	t345: "La BaseDatos seleccionada existe, ¿quiere sobreescribirla?",
	t346: "Panel de Control",
	t347: "Vista Gráfica",
	t348: "Ayuda",
	t349: "Vista de Sistema",
	t350: "Mapa",
	t351: "Herramienta de Idiomas",
	t352: "Manual",
	t353: "Pidiendo Alarmas...",
	t354: "Actualizado",
	t355: "Modelo",
	t356: "Control",
	t357: "Aceite",
	t358: "Temp.",
	t359: "Máximizar",
	t360: "Restaurar",
	t361: "Ir atras...",
	t362: "Fallo comunicacion!",
	t363: "Estado comunicacion OK",
	t364: "Mensaje comunic. enviado",
	t365: "Cargando Componentes",
	t366: "Cargando Configuracion de Componentes",
	t367: "Temp Ctrl",
	t368: "Este ajuste sera efectivo cuando los historicos se redibujen, quiere continuar?",
	t369: "Cargando, por favor espere...",
	t370: "puntos de datos",
	t371: "Unidad sin nombre",
	t372: "Preferencias",
	t373: "Version Simulador",
	t374: "Energía",
	t375: "Ir a Vista General",
	t376: "Alarma de Sistema o dispositivo no encontrado",
	t377: "Horarios coincidentes",
	t378: "Fichero de lenguaje perdido",
	t379: "Version no soportada",
	t380: "Meta la direccion IP de la Ud aqui (ej: 192.168.1.1)",
	t381: "Por favor meta la direccion IP o URL",
	t382: "Ocultar Detalles",
	t383: "Mostrar Detalles",
	t384: "Temp. Exterior",
	t385: "Retorno",
	t386: "Impulsion",
	t387: "Humedad",
	t388: "PtoRocio",
	t389: "Vent",
	t390: "Zona",
	t391: "Apagado",
	t392: "Separar",
	t393: "TempAspirac.",
	t394: "Capacidad Actual",
	t395: "Presión Aspiración",
	t396: "Descarga",
	t397: "Compresores",
	t398: "Condensador",
	t399: "Consigna Condensación",
	t400: "Refrigerante",
	t401: "Consigna Compresores",
	t402: "Aspirac",
	t403: "Desesc.",
	t404: "Formato Historicos Danfoss",
	t405: "Formato Texto",
	t406: "Esta operación necesita resetear la unidad, quiere resetear la unidad ahora?",
	t407: "Reseteo de la unidad con éxito, por favor cargue la página de nuevo.",
	t408: "Modo de Aplicacion de Fábrica",
	t409: "Calculando...",
	t410: "minutos",
	t411: "Ajuste Nocturno",
	t412: "Luces Servicios",
	t413: "Coincidencia de Horarios",
	t414: "(Resolución de 10 min)",
	t415: "Vista Texto",
	t416: "Exportar como CSV",
	t417: "Exportar como PNG",
	t418: "Ver Gráfico",
	t419: "Ver Texto",
	t420: "Horario",
	t421: "Duración",
	t422: "Temp. Term.",
	t423: "NINGUNO",
	t424: "Temp. Control",
	t425: "Funcionando desde",
	t426: "Base de Datos cargada desde",
	t427: "Estadisticas",
	t428: "Seleccione Fichero",
	t429: "Elija el fichero histórico que desea convertir a formato CSV",
	t430: "Finalizar",
	t431: "Resumén",
	t432: "Ficheros de Datos Historicos (.hst)",
	t433: "Asistente de conversión de ficheros",
	t434: "Fichero creado.",
	t435: "Elija los puntos que quiere convertir a formato CSV",
	t436: "Seleccione el rango de tiempo",
	t437: "Seleccione el rango de tiempo y pulse el boton de Procesar",
	t438: "Procesar Datos",
	t439: "Preparando datos históricos, espere.",
	t440: "Seleccione nombre del fich CSV y salvar datos a fichero...",
	t441: "No se pueden encontrar datos históricos",
	t442: "Error - No hay datos históricos",
	t443: "Ha habido un error de comunicación con las unidades en la red. Encuentre",
	t444: "Atención",
	t445: "Silencio",
	t446: "La base de datos no esta guardada, Quiere cerrar esta ventana?",
	t447: "Vista de Tabla",
	t448: "Mostrar Gráfico",
	t449: "Mostrar Tabla",
	t450: "Desesc. Manual On",
	t451: "Desesc. Manual Off",
	t452: "Desesc. Auto",
	t453: "Ajustar control Desesc.",
	t454: "Etapa ON",
	t455: "Etapa OFF",
	t456: "Líquido",
	t457: "No hay horarios definidos",
	t458: "Vista Historicos",
	t459: "Comunicación perdida con una unidad de la red !!",
	t460: "Ajuste Rápido",
	t461: "Calle",
	t462: "Ciudad",
	t463: "Tel.",
	t464: "Región",
	t466: "Indice",
	t469: "Libreta Direcc. exportada con éxtio",
	t470: "Borrar elemento",
	t471: "Medir",
	t472: "Nota: Se distingue mayus/minus",
	t473: "En línea",
	t474: "Salidas Relé",
	t475: "Entr. Analógicas",
	t476: "Entr. Digitales",
	t477: "Cálculo",
	t478: "Ajustar a hora actual",
	t479: "Rack",
	t480: "Sin Compresores",
	t481: "Circuito",
	t482: "Evaporador",
	t483: "Desconoc",
	t484: "tipo error:",
	t485: "Añadir",
	t486: "Copia",
	t487: "Quitar",
	t488: "Control Evaporador",
	t489: "Tipo Evaporador",
	t490: "LON",
	t491: "Meta cantidad a copiar",
	t492: "sin nombre",
	t493: "Configurando la vista de Refrigeracion",
	t494: "Error Config.:",
	t495: "Por favor verifique la configuración",
	t496: "El ajuste de la Planta de Refrigeración se ha completado con éxito. Presione OK para salir de este Asistente",
	t497: "Estado Configuración",
	t498: "Vista Final Planta Refrig.",
	t500: "Código #",
	t501: "Planta",
	t502: "Ajuste Sistema",
	t503: "Licencias",
	t504: "Usuarios",
	t505: "No autorizado",
	t506: "Por favor verifique",
	t507: "Esta seguro que quiere salir de esta pantalla? Los ajustes se perderán",
	t508: "Escaneando red de controles",
	t509: "Escaneando",
	t510: "Verificando",
	t511: "AK-I/O (E/S)",
	t512: "Re-escanear",
	t513: "Incluir program. Offline",
	t514: "Bus Dispositivos",
	t515: "Disposit. Conectados",
	t516: "Programacion Offline",
	t517: "Direcc. duplicada",
	t518: "Controles Compresor",
	t519: "Grupo Asp.",
	t520: "N/A",
	t521: "Servicios Disponibles",
	t522: "Permitir crear servicios multi-evap.",
	t523: "Meta Dispositv.",
	t524: "Meta un Servicio",
	t525: "Servicio Multi-Evap",
	t526: "Cargando Tablas",
	t527: "Pidiendo Alarmas para",
	t528: "Ficheros de Grupo de Historicos",
	t529: "Fich. datos históricos",
	t530: "Todos los fich.",
	t531: "Mostrar Símbolos",
	t532: "Mostrar Curvas",
	t533: "No se han recogido sufic. datos hist.",
	t534: "Recibir los últimos datos históricos",
	t535: "Medidas/Ajustes",
	t536: "Volcando ahora",
	t537: "muestras históricas",
	t538: "Recibiendo",
	t539: "Puntos...",
	t540: "Intervalo",
	t541: "Min",
	t542: "máximo",
	t543: "Media",
	t544: "Ir a la ventana alarmas ppal.",
	t545: "Parar Entr.Usuario Auto",
	t546: "Conectado a",
	t547: "Cancelar Entr. auutomatica",
	t548: "Por favor meta el passw de cierre de sesión",
	t549: "Web 1",
	t550: "Web 2",
	t551: "Iniciar sesión automáticamente",
	t552: "Salir desp. de tiempo inactivo",
	t553: "Pantalla Inicio",
	t554: "Mostrar Menu Configuración",
	t555: "Mostrar puntos Varios en Panel Control",
	t556: "Usar Pantalla Completa",
	t557: "Habilitar sonido alarmas",
	t558: "Permitir cerrar/minimizar",
	t559: "Error accediendo a unidad#",
	t560: "Conectado a la red",
	t561: "Entrada automatica cancelada",
	t562: "Intentando conectar a",
	t563: "Salir desde",
	t564: "Conectado",
	t565: "Formato Hora",
	t566: "Form. Fecha",
	t567: "Presión",
	t568: "Nivel Ilumin",
	t569: "SubEnfria",
	t570: "SubEnfri",
	t571: "Diario",
	t572: "Semanal",
	t573: "Archivo HST",
	t574: "CSV File",
	t575: "Domingo",
	t576: "Lunes",
	t577: "Martes",
	t578: "Miercoles",
	t579: "Jueves",
	t580: "Viernes",
	t581: "Sábado",
	t582: "Colección de registros de historial",
	t583: "Habilitar la recopilación automática",
	t584: "Cuándo recoger",
	t585: "Ubicación de guardado de archivos",
	t586: "Seleccione el formato de archivo",
	t587: "Seleccione la carpeta para almacenar los archivos de historial...",
	t588: "Habilite la recopilación automática de historial para recuperar y guardar registros de historial diarios o semanales de los puntos de historial configurados de la tienda.",
	t589: "Para ver los archivos de historial de HST, vaya a la pantalla principal del historial y seleccione el historial de importación. Para ver archivos CSV, utilice cualquier aplicación de hoja de cálculo.",
	t590: "Si la dirección IP de la conexión actual se encuentra en la libreta de direcciones, se guardarán los puntos de historial seleccionados.",
	t591: "Seleccionar puntos de datos",
	t592: "Todos los seleccionados",
	t593: "Puntos seleccionados",
	t594: "Búsqueda",
	t595: "Conexión automática a",
	t596: "Por favor, verifique",
	t597: "La recopilación automática de historial está habilitada. ¿Está seguro de que desea cerrar este programa?",
	t598: "¿Está seguro de que desea cerrar este programa?",
	t599: "Acción",
	t600: "Filtrar",
	t601: "Todas las acciones",
	t602: "Más información...",
	t603: "Editable",
	t604: "Operación",
	t605: "Operacion Cancelada",
	t606: "Volcado de la Base de Datos finalizado",
	t607: "Base de Datos actualizada",
	t608: "Fichero(s) transferidos a AK-SM con éxito.",
	t609: "Volcado finalizado, el fichero se esta procesando en la unidad.",
	t610: "Ha ocurrido un error al cargar fich al PC, revise si el fichero es accesible y se puede escribir",
	t611: "Cargando Fichero",
	t612: "Volcando Fichero",
	t613: "Cargando fichero(s) seleccionado(s)",
	t614: "Volcando fichero(s) seleccionado(s)",
	t615: "Borrando fichero",
	t616: "Borrando fichero seleccionado",
	t617: "Error conexion FTP",
	t618: "Volcar ficheros seleccionado(s) a AK-SM",
	t619: "Ha ocurrido un error en la conexion FTP, revise autorizacion FTP y direccion IP.",
	t620: "FTP conectado",
	t621: "Bytes",
	t622: "Los ficheros en la Unidad seran sobreescritos, ¿Quiere continuar?",
	t623: "Los ficheros en el PC seran sobreescritos, ¿Quiere continuar?",
	t624: "Los ficheros se han volcado a la unidad.",
	t625: "Sólo REFRIGERACION",
	t626: "Tienda Conveniencia",
	t627: "TODAS las Funciones",
	t628: "Solo CLIMA",
	t629: "El nombre del fichero no es válido, por favor asegúrese de que el nombre del fichero tiene menos de 8 caracteres.",
	t630: "Sobreescribir ahora la Aplicacion?",
	t631: "Despues",
	t632: "cargado",
	t633: "tiene que elegir SI para sobreescribir la aplicarion o elegir Despues",
	t634: "Por favor seleccione el Directorio raiz del simulador",
	t635: "Por favor seleccione el Directorio Windows",
	t636: "Por favor seleccione el Directorio Destino",
	t637: "Seleccione el directorio de Bases de Datos",
	t638: "FTP desconectado",
	t639: "Tiempo conexion FTP agotado, FTP desconectado",
	t640: "Cargar fich seleccionado al PC",
	t641: "Borrar Fich seleccionado",
	t642: "Puerto FTP",
	t643: "Puerto Serv. WEB",
	t644: "Directorio Local (PC)",
	t645: "Arriba",
	t646: "Tamaño",
	t647: "Fecha",
	t648: "Conectar",
	t649: "Desconectar",
	t650: "Dir. Remoto (CS)",
	t651: "Espacio libre disponible:",
	t652: "Fich. Seleccionados",
	t653: "Archivos",
	t654: "Tamaño Total:",
	t655: "Haga clic para cambiar la carpeta...",
	t656: "Cambiar...",
	t657: "Quiere borrar el fichero(s) seleccionado(s) ?",
	t658: "Conexion cerrada, Salir",
	t659: "Los nombre(s) de fichero(s) seleccionados son ilegales y el volcado será ignorado.",
	t660: "Algunos de los nombres de fich son ilegales e ignorados.",
	t661: "Fichero(s) transferidos al PC con éxito.",
	t662: "No hay suficiente espacio libre en la Unidad",
	t663: "Transfer file(s)",
	t664: "Modificado",
	t665: "Copiar ficheros desde el disco Flash a memoria RAM",
	t666: "Por favor seleccione una operación de Copia de Ficheros",
	t667: "Copiar todos los archivos EDF configurados desde el disco Flash",
	t668: "Copiar TODOS los ficheros EDF desde el disco Flash",
	t669: "Los archivos edf/ed3 se almacenan en el disco flash de la unidad, pero no se cargan en la pantalla Administración de archivos.  Utilice las siguientes opciones para copiar los archivos EDF almacenados en su unidad en la pantalla Administración de archivos",
	t670: "Esta operacion copia todos los ficheros EDF que se han configurado en la unidad desde el disco Flash al disco RAM",
	t671: "Esta operacion copia todos los ficheros EDF en la unidad desde el disco Flash al disco RAM",
	t672: "No existe ningún archivo EDF válido",
	t673: "Copia de archivo EDF terminada",
	t674: "archivos copiados",
	t675: "Confirmar la descarga de la base de datos",
	t676: "Direcc.Unidad",
	t677: "Dirección IP",
	t678: "Nombre fich Base Datos",
	t679: "Seleccione",
	t680: "Actualizacion completada, reseteando y actualizando la unidad. Esto puede llevar unos minutos.",
	t681: "Bienvenido al gestor de Actualizaciones, paso 1 de 2",
	t682: "Bienvenido al gestor de Actualizaciones, paso 2 de 2",
	t683: "Proceder",
	t684: "Comprobación Previa",
	t685: "Localizar Firmware",
	t686: "¿Copiar Base de Datos?",
	t687: "Copiando Base Datos",
	t688: "Transfiriendo Software",
	t689: "Sobreescribiendo Software",
	t690: "Seleccione SW para actualizar",
	t691: "No se ha detectado aplicacion anti-fallos de fábrica",
	t692: "Puede continuar, pero cualquier fallo durante el proceso de actualización puede hacer es sistema no operativo.",
	t693: "Se recomienda no continuar.",
	t694: "¿Quiere continuar?",
	t695: "Actualizar de forma remota conlleva un riesgo de fallo, que puede ocasionar un error en la unidad.",
	t696: "Advertencia",
	t697: "Actualizacion de Base Datos terminada.",
	t698: "Copia Seg. de Base Datos terminada.",
	t699: "Volcado de aplicación terminada.",
	t700: "Sobre escritura de aplicacion terminada.",
	t701: "Actualizacion de software con éxito.",
	t702: "¿Quiere resetear esta unidad a ajustes de fábrica?",
	t703: "Revisando Hardware",
	t704: "Revisando estado de la Unidad",
	t705: "Seguridad anti-fallos",
	t706: "Version de Firmware actual",
	t707: "Iniciar actualización",
	t708: "Seleccione un fichero de Base Datos",
	t709: "Seleccione un fichero de Firmware",
	t710: "Atrás",
	t711: "Localizar Firmware",
	t712: "Seleccione fichero .csi",
	t713: "Seleccione fichero .mai",
	t714: "Esta placa de CPU no admite la descarga remota.",
	t715: "Fallo en la apliacación de volcado!",
	t716: "Fallo al cargar Base Datos, Intentelo de nuevo",
	t717: "Sobre escritura de aplicacion terminada.",
	t718: "Fallo al sobre-escribir, por favor intentelo de nuevo!",
	t719: "Fallo al sobre-escribir !",
	t720: "No se ha detectado aplicacion anti-fallos de fábrica",
	t721: "Hecho !",
	t722: "La aplicación seleccionada puede tener una version diferente que el firmware de la Unidad; continuar con esta operacion conlleva un riesgo de fallo y error de Sistema.",
	t723: "Los nombre(s) de fichero(s) seleccionados son ilegales y el volcado será ignorado.",
	t724: "Algunos de los nombres de fich son ilegales e ignorados.",
	t725: "Falta el pin berg en la Unidad",
	t726: "La bateria de la unidad esta baja.",
	t727: "Fallo en la red host de la Unidad",
	t728: "La Unidad esta en modo de Deslastrado",
	t729: "Seleccione fichero destino",
	t730: "Seleccione directorio de destino.",
	t731: "Actualizacion de Base Datos terminada.",
	t732: "Estado OK",
	t733: "Estado de Error",
	t734: "Estado Desconocido",
	t735: "Esta operación sobreescribirá los ficheros seleccionados en el simulador Destino, quiere continuar?",
	t736: "Actualizacion Base Datos finalizada, necesita resetear el sistema",
	t737: "Falolo al actualizar Base Datos, intentelo mas tarde.",
	t738: "La unidad se reseteará automaticamente despues de esta operacion, ¿quiere continuar?",
	t739: "Seleccione Base Datos",
	t740: "Volcar fichero de Base Datos?",
	t741: "Cargar fichero de Base Datos?",
	t742: "Cargando Base Datos al AK-SM, espere",
	t743: "Base Datos cargada con éxito, reseteando la Unidad",
	t744: "Base Datos actualizada, Unidad reseteada con exito.",
	t745: "Fallo actualizando Base Datos! Intentelo de nuevo",
	t746: "Error al actualizar la Base Datos",
	t747: "Comprobacion pre-volcado",
	t748: "Localizar Base Datos",
	t749: "Volcando Base Datos",
	t750: "Información Base Datos actual",
	t751: "Seleccione Base Datos para Volcar",
	t752: "Guardar Base datos al PC",
	t753: "Volcar Base Datos a AK-SM",
	t754: "Necesita autorizacion de Supervisor",
	t755: "Comenzar Carga",
	t756: "Por favor seleccione una Operacion de Base Datos",
	t757: "Comenzar Volcado",
	t758: "Version de Firmware actual",
	t760: "Base Datos volcada con éxito.",
	t761: "Bienvenido al Gestor de Bases de Datos, paso 1 de 2",
	t762: "Bienvenido al Gestor de Bases de Datos, paso 2 de 2",
	t763: "Fallo al obtener Lista Usuarios, revise IP/URL del Servidor",
	t764: "Actualizar de forma remota una Base de Datos conlleva un riesgo de fallo, que puede derivar en un fallo del sistema.",
	t765: "Descarga de bases de datos terminada",
	t766: "Escaneo Ethernet",
	t767: "Introduzca la primera dirección IP y, a continuación, pulse Start",
	t768: "Escanear",
	t769: "Escaneando",
	t770: "Colección de registros de historial",
	t771: "Colores de gráficos analógicos",
	t772: "Colores de gráficos digitales",
	t773: "Horarios de cierre",
	t774: "Resumen de descongelación",
	t775: "Horarios Ajuste Nocturn",
	t776: "Horarios de iluminación de casos",
	t777: "Estado de carga",
	t778: "Colección de registros de alarmas",
	t779: "Seleccione la carpeta para almacenar los archivos de alarma...",
	t780: "Mensual",
	t781: "Habilite la recopilación automática de alarmas para recuperar y guardar la información de alarma en un archivo de forma periódica.",
	t782: "Separador CSV",
	t783: "Sin alarmas",
	t784: "Recuperación de alarmas para la unidad:",
	t785: "Recuperar los detalles de alarma de la unidad:",
	t786: "La recopilación automática de alarmas está activada. ¿Está seguro de que desea cerrar este programa?",
	t787: "CadaHora",
	t788: "Esto actualizará la base de datos de la unidad y establecerá la ubicación en el centro de la vista actual. ¿Proceder?",
	t789: "muestras procesadas",
	t790: "Configure un grupo de succión.",
	t791: "Para volver a utilizar el Asistente para diseño más de una vez, es necesario restablecer el diseño de la base de datos de refrigeración.",
	t792: "Do this via the Configuration-&gt;Control page and set the number of Racks/Packs to zero.",
	t793: "Nota: cualquier programación, cálculo o importación basada en la configuración de refrigeración previa tendrá que ser reconfigurada.",
	t794: "Copiar proceso terminado.",
	t795: "Informe de refrigeración",
	t796: "Diseño final de HVAC",
	t797: "La configuración del diseño de HVAC se ha completado correctamente. Pulse el botón ok para salir de este asistente.",
	t798: "Configuración del diseño de HVAC...",
	t799: "Para volver a utilizar el Asistente de diseño de HVAC más de una vez, es necesario restablecer el diseño de la base de datos hvac.",
	t800: "Do this via the Configuration-&gt;Control page and set the number of HVAC units to zero.",
	t801: "Nota: cualquier programación, cálculo o importación basada en la configuración anterior de HVAC tendrá que ser reconfigurada.",
	t802: "Patrón de etapas (requiere reinicio de la unidad para activar)",
	t803: "Etapa",
	t804: "Nivel",
	t805: "Mostrar información sobre datos múltiples",
	t806: "Número de pasos",
	t807: "No se pudo guardar el archivo.",
	t808: "Modelo incorrecto.",
	t809: "Carga correctamente.",
	t810: "Abierto",
	t811: "Esta característica solo está disponible en la versión de escritorio de StoreView.",
	t812: "Continuar",
	t813: "Abandonar Asistente",
	t814: "La operación tuvo éxito. ¿Desea continuar o desea dejar el asistente.",
	t815: "Error al capturar datos del registrador de alarmas.",
	t817: "Nombre Dispositivo",
	t819: "En curso...",
	t820: "Los datos de alarma no están presentes.",
	t821: "Contador de Alarmas",
	t822: "Opciones de visualización de Mimic",
	t823: "Mostrar solo texto",
	t824: "Icono de solo visualización",
	t825: "Mostrar texto e icono",
	t826: "1 Semana",
	t827: "2 semanas",
	t828: "3 semanas",
	t829: "4 semanas",
	t830: "Configuración gráfica",
	t831: "No se puede mostrar el diagnóstico de alarma, compruebe la recopilación automática de alarmas en las preferencias",
	t832: "No se permite el reordenamiento con racks de E/S o circuitos incluidos en la configuración.",
	t833: "Esta dirección IP/URL del host ya está presente en la libreta de direcciones. ¿Te gusta actualizar esa entrada?",
	t834: "Navega",
	t835: "Seleccione Carpeta de libreta de direcciones",
	t836: "Ubicación",
	t837: "La ubicación seleccionada no tiene ningún contenido de la libreta de direcciones. ¿Desea exportar el contenido de la libreta de direcciones existente a la ubicación seleccionada?",
	t838: "Alarm Action Codes",
	t839: "El archivo de la libreta de direcciones no se encuentra en la ruta seleccionada. La libreta de direcciones se carga desde la zona...",
	t840: "Seleccione la ubicación para guardar los registros.",
	t841: "Seleccione una ubicación válida para guardar los registros.",
	t842: "La recopilación de registros de alarma está en curso...",
	t843: "La recopilación de registros de historial está en curso...",
	t844: "Ejecutar en segundo plano",
	t845: "Progreso del registro de alarmas",
	t846: "La dirección IP/URL del host no debe estar en blanco",
	t847: "El campo De dirección no debe estar en blanco",
	t849: "No tiene la última versión del archivo, ¿le gustaría cargar datos de la última versión?",
	t850: "Conflicto de versión",
	t851: "Progreso del registro de historial",
	t852: "Los campos Nombre de usuario y Contraseña no deben estar en blanco.",
	t853: "El campo Dirección IP no debe estar en blanco.",
	t854: "El campo Dirección IP/URL del host no debe estar en blanco.",
	t855: "El nombre de usuario no debe estar en blanco.",
	t856: "La contraseña no debe estar en blanco.",
	t857: "El SM800 al que se ha conectado",
	t858: "no se ha completado el Asistente de inicio inicial. Complete esta tarea y vuelva a conectarse al SM800",
	t859: "Establecido en ahora",
	t860: "Entrada no válida",
	t861: "El texto es demasiado largo",
	t862: "Compensar",
	t863: "No se puede comunicar con las unidades de la red host",
	t864: "Por favor, verifique que las direcciones son diferentes. La configuración se actualiza cuando se pulsa el botón Guardar.",
	t865: "Seleccione un archivo de historial válido (.hst)",
	t866: "Seleccione un archivo de grupo de historial válido (.grp)",
	t867: "Color",
	t868: "Porcentaje",
	t869: "Voltaje",
	t870: "Actual",
	t871: "Energía",
	t872: "Frecuencia",
	t873: "Concentración",
	t874: "Flujo",
	t875: "Velocidad",
	t876: "Acidez",
	t877: "Por favor, seleccione los gráficos en el cajón inferior",
	t878: "Guardar PNG",
	t879: "Dibujar",
	t880: "Estado del sistema...",
	t881: "Estado del sistema y de la unidad",
	t882: "Gataway",
	t883: "Características licencias",
	t884: "Versión de software",
	t885: "Versión del módulo del navegador",
	t886: "Versión Danux",
	t887: "Dirección Mac",
	t888: "Soporte y actualizaciones",
	t889: "Nombre de la unidad",
	t890: "Reconectar",
	t891: "Conexión perdida",
	t892: "No se ha encontrado ningún equipo de tipo.",
	t893: "La versión de software utilizada actualmente no es compatible",
	t894: "Enlaces rápidos",
	t895: "Equipo",
	t896: "Gráficas",
	t897: "Utilidades",
	t898: "Reversión de software",
	t899: "Diagnóstico",
	t900: "Error",
	t901: "No se han encontrado datos de alarmas",
	t902: "Configuración",
	t903: "Nombre de la aplicación",
	t904: "Versión de la aplicación",
	t905: "Mensaje",
	t906: "Tipo de evento",
	t907: "Marca de tiempo",
	t908: "Revertir la version",
	t909: "Versión actual",
	t910: "Software",
	t911: "Revertir…",
	t912: "Seleccione el equipo de la lista",
	t913: "Vista de calendario",
	t914: "Se ha producido un error al cargar dispositivos, inténtelo de nuevo.",
	t915: "Se ha producido un error al cargar las programaciones, inténtelo de nuevo.",
	t916: "No se ha encontrado ningún equipo",
	t917: "Horarios Exportar",
	t918: "Elija el tipo de exportación CSV o PDF",
	t919: "Exportar como CSV",
	t920: "Preparación de PDF",
	t921: "Exportar en formato PDF",
	t922: "Proporcione una IP en este formato xx.xx.xx.xx[:xxxx]",
	t923: "Nombre de usuario",
	t924: "Iniciar sesión",
	t925: "Revertir",
	t926: "Revertir software SM800A",
	t927: "Esta acción revertirá todo el software a las versiones enumeradas.",
	t928: "Editor de dispositivos 3rd Party",
	t929: "algún texto que explica la característica",
	t930: "Actualización CDF",
	t931: "Dispositivo de mapa",
	t932: "Archivo de dispositivo",
	t933: "Número de registro basado en 1",
	t934: "Tipo de almacenamiento",
	t935: "Min",
	t936: "máximo",
	t937: "Sólo lectura",
	t938: "Registro",
	t939: "Tipo de mensaje",
	t940: "Palabra clave de conversión",
	t941: "Parámetros",
	t942: "Addr.",
	t943: "Prioridad",
	t944: "Bit",
	t945: "Negado",
	t946: "Nuevo archivo",
	t947: "Abrir archivo",
	t948: "Generar archivo",
	t949: "Grupo",
	t950: "Versión del producto",
	t951: "Error ocurrido",
	t952: "No encontrado",
	t953: "Este dispositivo no se encontró",
	t954: "Temperatura exterior.",
	t955: "No se ha encontrado ningún equipo",
	t956: "Cases",
	t957: "Alarma silenciada/rec con éxito",
	t958: "Fallo al silenciar/rec esta alarma",
	t959: "Se ha producido un error al cargar la(s) unidad(es), inténtelo de nuevo.",
	t960: "Sin equipo",
	t961: "No se ha encontrado ningún equipo de tipo",
	t962: "Configurar el administrador del sistema",
	t963: "Dispositivo",
	t964: "Deshacer",
	t965: "Ha cambiado el valor de campo, ¿quiere guardar los cambios?",
	t966: "Borrar los puntos de historial seleccionados",
	t967: "Shr {{number}}",
	t968: "Veloc Bomba Hr",
	t969: "Sd",
	t970: "V3hr",
	t971: "Referencia Hr",
	t972: "Stw {{number}}",
	t973: "Referencia Tw",
	t974: "Veloc Bomba Tw",
	t975: "V3tw",
	t976: "Trec",
	t977: "Prec",
	t978: "%Apert Vrec",
	t979: "Referencia Prec",
	t980: "Referencia Trec",
	t981: "Pc",
	t982: "Ss",
	t983: "%Apert Vhp",
	t984: "Referencia Pgc",
	t985: "%Apert Eyector",
	t986: "Pgc",
	t987: "Shp",
	t988: "Sgc",
	t989: "Sc3 (ext.)",
	t990: "Sgc Control",
	t991: "Capacidad Cond.",
	t992: "Po",
	t993: "Ss",
	t994: "Sd",
	t995: "Pc",
	t996: "Capacidad en funcionamiento",
	t997: "certificado del navegador",
	t998: "Cerrar sesión",
	t1000: "Ordenando solo en {{count}}",
	t1001: "Cargado {{count}} de {{total}} alarmas",
	t1002: "Reconocimiento exitoso para todas las alarmas",
	t1003: "Reconocimiento fallido para todas las alarmas",
	t1004: "Reconocido solo {{count}} de {{total}} alarmas",
	t1005: "Exportación fallida para todas las alarmas",
	t1006: "Exportación exitosa",
	t1007: "Exportación fallida, se requiere al menos un tipo de alarma",
	t2000: "-",
	t2001: "La respuesta es demasiado grande para el búfer de transmisión XML que es de 32 K bytes",
	t2002: "No hay atributos asociados con el elemento cmd o el comando no tiene el elemento \"cmd\" o no pudo escribir en una variable o no pudo leer una variable o no pudo leer un punto o no pudo escribir el modo de operación de salida de relé o entrada on/off",
	t2003: "Entrada de comando incorrecta",
	t2004: "Error en los parámetros de publicación de CGI",
	t2005: "Error al convertir la respuesta XML de su representación interna a la cadena XML de respuesta",
	t2006: "Error al decodificar la cadena de URL",
	t2007: "Desbordamiento de memoria",
	t2008: "La compresión de datos de respuesta falló",
	t2009: "Solicitud de análisis de error",
	t2010: "Error de acceso a datos: una serie de causas que no se resuelven más",
	t2011: "Valor de atributo de acción de comando no definido El valor de atributo no es válido",
	t2012: "Error en Número de Referencia de Alarma o Alarma no configurada",
	t2013: "La cadena del parámetro cgi de entrada es demasiado larga.",
	t2014: "No se encuentra la dirección del dispositivo",
	t2015: "Error en la autorización para escribir",
	t2016: "Sin historial configurado",
	t2017: "No se devolvieron datos de historial, puede ser un error de tiempo",
	t2018: "Crear archivos de errores",
	t2019: "Leer errores de archivo",
	t2020: "Se ha superado el tamaño máximo de la lista de dispositivos",
	t2021: "no utilizado",
	t2022: "Índice de historial no válido",
	t2023: "No se pudo encontrar el índice de puntos del tablero de tipo de nodo, nodo, mod y punto.",
	t2024: "No se pudo encontrar el índice de historial",
	t2025: "La consulta de historial no se ha inicializado antes de intentar iniciar una consulta de historial.",
	t2026: "Se ha intentado un hq_start_query mientras una consulta de historial está activa",
	t2027: "No se encontraron atributos de comando cuando se esperaban algunos",
	t2028: "Se ha proporcionado un comienzo de época sin fin o viceversa.",
	t2029: "No se pudo convertir del formato de fecha y hora a segundos de época",
	t2030: "El comando requiere un query_id pero no se proporciona ninguno",
	t2031: "Error al generar la respuesta XML",
	t2032: "El query_id proporcionado no coincide con una consulta activa",
	t2033: "Se ha intentado un comando hq_get_data pero los datos aún no están disponibles",
	t2034: "Se ha proporcionado una frecuencia de muestreo que no es una de las permitidas",
	t2035: "El intervalo de promedio es menor o igual que la frecuencia de muestreo a la que se registraron los datos",
	t2036: "El tiempo de la época de parada es menor que el tiempo de la época de inicio o el tiempo de la época de parada menos el tiempo de la época de inicio es menor que el intervalo promedio",
	t2037: "El device_id proporcionado no coincide con ninguno actualmente conocido por el ak255. Tenga en cuenta que solo los dispositivos que se han configurado son conocidos por el ak255",
	t2038: "Sin atributos de identificación",
	t2039: "Atributos de identificación no válidos",
	t2040: "Sin número de horario",
	t2041: "Número de horario no válido",
	t2042: "Sin detalles de horario",
	t2043: "Falta on_time",
	t2044: "Hora que falta",
	t2045: "Hora inválida",
	t2046: "Minuto que falta",
	t2047: "Minuto inválido",
	t2048: "Falta tiempo_apagado",
	t2049: "Días de la semana que faltan",
	t2050: "Días de la semana no válidos",
	t2051: "vacaciones faltantes",
	t2052: "Días festivos no válidos",
	t2053: "Holiday_start faltante o no válido",
	t2054: "Holiday_end faltante o no válido",
	t2055: "Holiday_open faltante o no válido",
	t2056: "Holiday_close faltante o no válido",
	t2057: "Vacaciones no configuradas",
	t2058: "Falta la descripción",
	t2059: "No se puede borrar la alarma",
	t2060: "Store_open faltante o no válido",
	t2061: "Tienda faltante o no válida_cerrada",
	t2062: "Alarma no configurada",
	t2063: "Nodo fuera de línea",
	t2064: "Índice faltante",
	t2065: "Índice no válido",
	t2066: "El relé no se puede sincronizar",
	t2067: "El nombre que se está cambiando tiene demasiados caracteres. Si el cambio de store_name o unit_name debe tener < = 16 caracteres, si se cambia storeId1 o storeId2, debe tener < = 8 caracteres.",
	t2068: "Faltan elementos asociados con el elemento de comando.",
	t2069: "Tag_ID no definido",
	t2070: "Nodo hoja faltante para datos",
	t2071: "Sin cambios en los datos",
	t2072: "No se pudo actualizar la base de datos",
	t2073: "no se puede escribir",
	t2074: "Error de medidor de cobertizo",
	t2075: "sin metros",
	t2076: "Nivel de cobertizo no válido",
	t2077: "tipo inválido",
	t2078: "Sistema ocupado",
	t2079: "Activos no configurados para deslastre de carga",
	t2080: "Clasificación de potencia no válida para el activo",
	t2081: "Retraso de inicio no válido para activo",
	t2082: "Configuración ocupada",
	t2083: "Exploración de E/S en curso",
	t2084: "Demasiados descongelamientos en curso",
	t2085: "Intentando cargar una versión de base de datos incorrecta en la unidad",
	t2086: "La carga de la base de datos falló",
	t2087: "No hay memoria web disponible disponible",
	t2088: "Respuesta a la demanda no configurada (Solo el sistema AK355)",
	t2089: "Tamaño de archivo faltante o incorrecto.",
	t2090: "Archivo codificado faltante (elementos b64)",
	t2091: "No se pudo guardar la base de datos en el disco RAM.",
	t2092: "Tamaño de archivo demasiado grande.",
	t2093: "Falta el nombre del archivo.",
	t2094: "Falta la identificación del archivo.",
	t2095: "Falta el índice de carga del archivo (Nota: este es el índice de secuencia para load_file load_database).",
	t2096: "El ID del archivo no coincide o falta.",
	t2097: "La operación de carga de archivos ya está en curso.",
	t2098: "El archivo decodificado no coincide con el tamaño del archivo original.",
	t2099: "La operación de carga de archivos se canceló o no se inició.",
	t2100: "Solicitud de usuario no válida (el solicitante original no coincide con la dirección IP)",
	t2101: "Secuencia de carga de archivos no válida.",
	t2102: "Directorio invalido",
	t2103: "Extensión no válida",
	t2104: "Compensación no válida",
	t2105: "Archivo de codificación de error",
	t2106: "Error al decodificar el archivo",
	t2107: "No se pudo abrir el archivo para lectura/escritura",
	t2108: "Error al escribir en el archivo",
	t2109: "El archivo está protegido contra escritura en el sistema de archivos Flash",
	t2110: "No se pudo eliminar el archivo.",
	t2111: "No se puede eliminar la carpeta.",
	t2112: "Dispositivos faltantes",
	t2113: "No se encontraron todos los dispositivos de la URL",
	t2114: "Faltan parámetros de historial",
	t2115: "No se encontraron todos los parámetros de historial de la URL",
	t2200: "No se recomienda la versión de software utilizada actualmente ( {{currentVersion}} )",
	t2201: "No se recomienda la versión de software utilizada actualmente ( {{currentVersion}} ) ( > {{recommendedVersion}} recomendado)",
	t2202: "Error de versión de software",
	t2203: "Editor Gráficos",
	t2204: "Editar",
	t2205: "Dibujar",
	t2206: "Nueva vista",
	t2207: "Creado",
	t2208: "Soltar",
	t2209: "Nuevo Gráfico",
	t2210: "Crear una nueva vista gráfica",
	t2211: "Nombre",
	t2212: "Renombrar",
	t2214: "Exportar",
	t2213: "Duplicar",
	t2215: "Borrar",
	t2216: "¿Está seguro de que desea eliminar la vista \" {{name}} \"?",
	t2217: "Exportar todas las vistas",
	t2218: "Más...",
	t2219: "Regla",
	t2220: "normas",
	t2221: "Nombre de la regla",
	t2222: "Panel de edición",
	t2223: "Condiciones",
	t2224: "Degradado",
	t2225: "Invertir condiciones",
	t2226: "Asignaciones",
	t2227: "Mapear formas por",
	t2228: "ID de forma",
	t2229: "estados",
	t2230: "Agregar mapeo",
	t2231: "Añadir regla",
	t2232: "Operador",
	t2233: "Agregar condición",
	t2234: "Comience agregando reglas",
	t2235: "¿Está seguro de que desea eliminar la regla \" {{name}} \"?",
	t2236: "Se hace una copia de la regla.",
	t2237: "Vista Gráfica",
	t2238: "Tienes que crear vistas del sitio para verlas.",
	t2239: "Ir al editor gráfico",
	t2240: "ir a detalles",
	t2241: "Seleccionar parámetro",
	t2242: "No se encontraron parámetros",
	t2243: "Opciones",
	t2244: "Entrada de encendido/apagado",
	t2245: "Salida de relé",
	t2246: "Entrada del sensor",
	t2247: "Salida variable",
	t2248: "Dispositivo genérico",
	t2249: "Nodo vacío",
	t2250: "No se admite el registro de historial...",
	t2251: "Re-intentar",
	t2252: "No se pudo recuperar el historial",
	t2253: "El historial de carga puede demorar un tiempo y no admite varias consultas al mismo tiempo, vuelva a intentar esta solicitud.",
	t2254: "Nombre del parámetro",
	t2255: "Nombre del dispositivo",
	t2256: "Tipo de dispositivo",
	t2257: "No se pudieron cargar todos los parámetros del historial (falló {{n}} )",
	t2258: "Nombre de usuario o contraseña incorrectos",
	t2259: "Sin comunicación con el controlador",
	t2260: "Actualización de software",
	t2261: "Sistema",
	t2262: "Reversión de software",
	t2263: "Instalar en pc",
	t2264: "La reversión del software reemplazará una versión más nueva",
	t2265: "Revertir el software a la versión de copia de seguridad {{version}}",
	t2266: "Base de datos",
	t2267: "Hay una actualización disponible",
	t2268: "Exportar base de datos",
	t2269: "Descargar",
	t2270: "Descargando base de datos",
	t2271: "No se puede exportar la base de datos",
	t2272: "archivo de base de datos",
	t2999: "Error de comunicación",
	t2998: "Error desconocido",
	t3000: "Detalles",
	t3001: "Código XML pasado aquí",
	t3002: "Asignar a",
	t3003: "Estado",
	t3004: "Gestión de software",
	t3005: "Archivo almacenado local",
	t3006: "En línea a través de Internet",
	t3007: "Asegúrese de que la energía no se interrumpa durante el proceso de actualización. Se recomienda hacer una copia de seguridad de la base de datos del sistema antes de actualizar su sistema. Después de completar las actualizaciones de software, se recomienda inspeccionar el estado operativo del sistema para confirmar el control y la operación correctos.",
	t3008: "Ningún archivo seleccionado",
	t3009: "Seleccionar archivo de programa",
	t3010: "Subiendo archivos",
	t3011: "Comprobación de seguridad",
	t3012: "Extrayendo archivo",
	t3013: "Comprobando archivo",
	t3014: "¡Falló la carga del archivo!",
	t3015: "¡El control de seguridad falló!",
	t3016: "¡Extracción fallida!",
	t3017: "¡Error al verificar el archivo!",
	t3018: "Descargando archivo: {{fileName}}",
	t3019: "¡Archivo descargado con éxito!",
	t3020: "¡Descarga fracasó!",
	t3021: "Subir archivos",
	t3022: "Descargar archivos",
	t3023: "Actualización del sistema operativo",
	t3024: "actualización de Edf",
	t3025: "Descargar archivos",
	t3026: "Lamentablemente, el valor del parámetro seleccionado no estaba disponible.",
	t3027: "Importar",
	t3028: "Se requiere un mínimo de un archivo",
	t3029: "El archivo no es válido",
	t3030: "Administrar vistas gráficas",
	t3031: "Subir",
	t3032: "Migrar",
	t3033: "Leyendo archivo(s) gráfico(s), por favor espere...",
	t3034: "Importando archivo gráfico, por favor espere...",
	t3035: "Elige gráfico",
	t3036: "Informes",
	t3037: "Importar base de datos",
	t3038: "Seleccionar archivo de base de datos",
	t3039: "Asegúrese de que la energía no se interrumpa durante el proceso de reversión. Se recomienda hacer una copia de seguridad de la base de datos del sistema antes de revertir su sistema. Después de completar la reversión del software, se recomienda inspeccionar el estado operativo del sistema para confirmar el control y la operación correctos.",
	t3040: "El administrador del sistema se reiniciará después de la actualización.",
	t3041: "Expandir",
	t3042: "Mostrar mensajes de depuración",
	t3043: "máx. caracteres permitidos -",
	t3044: "Cargue el archivo de actualización",
	t3045: "Versión actual del software",
	t3046: "Versión actual de Danux",
	t3047: "Última versión SW",
	t3048: "Última versión de Danux",
	t3049: "Realizando copia de seguridad por favor espere",
	t3050: "Instalando archivo por favor espere",
	t3051: "Instalando actualización",
	t3052: "El dispositivo se está reiniciando",
	t3053: "Reinicio del administrador del sistema",
	t3054: "El navegador se actualizará ahora",
	t3055: "Software instalado correctamente",
	t3056: "La actualización falló, ocurrió el siguiente error:",
	t3057: "Copia de seguridad de la versión actual del software",
	t3058: "Copia de seguridad de los archivos EDF actuales",
	t3059: "La vista gráfica se guarda correctamente",
	t3060: "Aplicar imagen de fondo",
	t3061: "Borrar",
	t3062: "Rehacer",
	t3063: "Tienda",
	t3064: "Ajuste Noche Evaporadores",
	t3065: "Ajuste Noche Aspiracion",
	t3066: "Versión descargada {{fileName}}",
	t3067: "Para actualizar las unidades seleccionadas, presione 'Instalar'",
	t3068: "Revertir la versión {{updateType}}",
	t3069: "Instalando",
	t3070: "Instalar la versión de reversión",
	t3071: "Ninguna de las unidades disponibles contiene ninguna versión de reversión",
	t3072: "Nueva versión disponible en línea",
	t3073: "Subir archivo para unidades seleccionadas",
	t3074: "Subir archivo para la unidad seleccionada",
	t3075: "No hay versiones de reversión disponibles para la unidad",
	t3076: "Descarga finalizada",
	t3077: "Versión actual de {{updateType}}",
	t3078: "Última versión {{updateType}}",
	t3079: "La actualización está en curso, ¿está seguro de que desea cerrar la ventana?",
	t3080: "¡Danux no está actualizado! Actualice Danux y luego vuelva a actualizar el software",
	t3081: "Las aplicaciones no se pueden degradar a esta versión",
	t3082: "Información de la aplicación",
	t3083: "Notas de lanzamiento",
	t3084: "Términos y Privacidad",
	t3085: "Licencias",
	t3086: "Vaya a configuración para establecer horarios",
	t3087: "Desafortunadamente, ocurrió un error al preparar la migración. Asegúrese de que todas las unidades estén accesibles en la red y configuradas correctamente. \n\n El siguiente error ha ocurrido:\n {{error}}",
	t3088: "Importar o migrar un gráfico existente",
	t3089: "Mostrar",
	t3090: "Nombre del activo",
	t3091: "Colapso",
	t3092: "Importación completa",
	t3093: "Seguridad de descarga",
	t3094: "Seguridad de aspiración",
	t3095: "Enganche Descarga",
	t3096: "Corte Descarga",
	t3097: "Corte de aspiración",
	t3098: "Enganche Aspiracion",
	t3099: "Capacidad",
	t3100: "No se pudieron encontrar los archivos de migración (flp, dpj o json)",
	t3101: "Archivo de análisis de tiempo de espera",
	t3102: "Archivos y direcciones de dispositivos de terceros",
	t3103: "Cuadrícula",
	t3104: "Bloquear",
	t3105: "XML",
	t3106: "Seleccione el parámetro para mostrar",
	t3107: "Parámetro del dispositivo",
	t3108: "Sin unidades",
	t3109: "No hay unidad(es) encontrada(s) o cargada(s)",
	t3110: "HACCP",
	t3111: "Controlador",
	t3112: "No hay datos de HACCP disponibles",
	t3113: "Información de la unidad",
	t3114: "Actualizaciones disponibles",
	t3115: "Ir a Software",
	t3116: "No conectado",
	t3117: "El alcance de acceso actual para esta aplicación es ' {{targetLevel}} ', el usuario proporcionado está por encima de este alcance en términos de derechos de acceso",
	t3118: "Exportación de PDF",
	t3119: "Exportación de PDF fallida",
	t3120: "En conjuntos de datos grandes, es posible que la interfaz no responda y tarde un poco en completarse",
	t3121: "Por favor seleccione horarios de la lista",
	t3122: "Vaya a configuración para establecer un horario",
	t3123: "Éxito",
	t3124: "Atención",
	t3125: "Información de la Tienda",
	t3126: "Seleccione puntos de tiempo y datos de la lista",
	t3127: "Conectando",
	t3128: "No se pudo establecer la conexión con esta tienda, comuníquese con soporte",
	t3129: "No había datos HACCP disponibles en la fecha elegida. Asegúrese de que el historial y la hora estén configurados correctamente en el menú de configuración",
	t3130: "Informe de diagnóstico",
	t3131: "Seleccione la(s) unidad(es) para exportar el informe de diagnóstico",
	t3132: "No disponible en este nivel de acceso de usuario",
	t3133: "Registro de diagnóstico",
	t3134: "no se pudo descargar el informe",
	t3135: "Fuente",
	t3136: "Tamaño de fuente",
	t3137: "No hay vistas para migrar",
	t3138: "Descargar versión de escritorio",
	t3139: "Debido a restricciones de seguridad en los navegadores modernos (",
	t3140: "RFC1918",
	t3141: ") lamentablemente no es posible conectarse a dispositivos dentro de un espacio de red privada utilizando HTTP sin cifrar. Configure su administrador de sistema para usar HTTPS o use la versión de escritorio",
	t3142: "No hay datos disponibles para el período seleccionado",
	t3143: "Configure su administrador de sistema para usar HTTPS o use la versión de escritorio.",
	t3144: "No pudimos comunicarnos con el administrador del sistema. Esto significa que su dispositivo no puede conectarse a la IP o al nombre de host del administrador del sistema, o que puede necesitar confiar manualmente en el certificado SSL utilizado en el dispositivo.",
	t3145: "Haga clic en el siguiente enlace para abrir una nueva pestaña para confiar en el certificado y luego regresar a esta pantalla.",
	t3146: "Firma",
	t3147: "DI - entradas digitales",
	t3148: "AI - entradas analógicas",
	t3149: "Ir a configuración HACCP",
	t3150: "Sin datos",
	t3151: "La copia de seguridad se guarda en el Administrador del sistema para la función de reversión",
	t3152: "Seleccionar qué mostrar en la(s) Vista(s) Gráfica",
	t3153: "unidad de activo",
	t3154: "Archivos gráficos",
	t3155: "Desafortunadamente, ocurrió un error al obtener los archivos edf y las versiones del administrador del sistema...",
	t3156: "Para seleccionar qué archivos instalar, presione el botón 'Elegir archivos'",
	t3157: "Seleccionar archivos",
	t3158: "Eliminando copia de seguridad",
	t3159: "Archivos de ensayo",
	t3160: "La copia de seguridad se guarda en el Administrador del sistema para la función de reversión",
	t3161: "Tamaño instalado",
	t3162: "nuevo tamaño",
	t3163: "Fecha de instalación",
	t3164: "nueva fecha",
	t3165: "Sensores",
	t3166: "Relés",
	t3167: "Entradas",
	t3168: "Salidas",
	t3169: "Energia",
	t3170: "Genérico",
	t3171: "Contraseña caducada, por favor introduzca nueva contraseña",
	t3172: "Nueva contraseña",
	t3173: "Confirmar Contraseña",
	t3174: "Cambia la contraseña",
	t3175: "La contraseña debe contener un mínimo de 8 caracteres (1 mayúscula, 1 minúscula, 1 número, 1 carácter especial)",
	t3176: "Las contraseñas no coinciden",
	t3177: "La contraseña no puede ser la misma que la contraseña anterior",
	t3178: "Cambio de contraseña exitoso",
	t3179: "La contraseña no se puede cambiar. Error del dispositivo",
	t3180: "Restablecimiento de contraseña",
	t3181: "Generado",
	t3182: "Nombre de la Tienda",
	t3183: "Id. de evento",
	t3184: "Tiempo",
	t3185: "Role",
	t3186: "Información del Evento",
	t3187: "Confirmar alarmas seleccionadas",
	t3188: "Reconocer todas las alarmas seleccionadas",
	t3189: "Importar base de datos a",
	t3190: "Importando base de datos",
	t3191: "El tipo de archivo debe ser .zip",
	t3192: "Seleccione Archivo",
	t3193: "Horario de apertura",
	t3194: "Días festivos",
	t3195: "Horarios de encendido/apagado",
	t3196: "Activo",
	t3197: "Usted tiene cambios no guardados. Estás seguro que quieres irte?",
	t3198: "Desafortunadamente, la migración falló. El sistema está actualmente ocupado. Por favor, inténtelo de nuevo más tarde",
	t3199: "Subir imagen ajustándose a las dimensiones actuales",
	t3200: "Reemplazar imagen de fondo",
	t3201: "Reemplace las imágenes de fondo con gráficos vectoriales escalables.",
	t3202: "El sw SvW intentará sustituir las imágenes de fondo para todos los gráficos basados en el fichero cargado. Solo se reemplazan los archivos SVG con el mismo nombre que los archivos existentes.",
	t3203: "Seleccionar archivo(s) para reemplazar",
	t3204: "Archivos de imagen",
	t3205: "no se puede procesar. El archivo tiene un formato no válido.",
	t3206: "{{amount}} archivo(s) reemplazado(s) con éxito",
	t3207: "No se pudieron reemplazar {{amount}} archivo(s)",
	t3208: "La vista no tiene fondo.",
	t3209: "Puede cargar una imagen de fondo nueva o reemplazar una existente",
	t3210: "Si está reemplazando una imagen de fondo, las posiciones de los parámetros se pueden mantener si:",
	t3211: "Un archivo png o jpg tiene la misma resolución que el fondo actual",
	t3212: "Un archivo svg tiene la misma relación de aspecto (pero no necesariamente la misma resolución)",
	t3213: "La función está deshabilitada",
	t3214: "Actualmente mostrando el último valor recibido, actualizando...",
	t3215: "Cargue un archivo gráfico creado en",
	t3216: "(opcional)",
	t3217: "Imagen de fondo",
	t3218: "Navegar",
	t3219: "Más...",
	t3220: "Añade una imagen de fondo (opcional)",
	t3221: "Archivo gráfico",
	t3222: "Tiempo de espera en la comunicación",
	t3223: "No hay horarios de refrigeración configurados",
	t3224: "Índice",
	t3225: "Tipo",
	t3226: "Días festivos",
	t3227: "Días",
	t3228: "En",
	t3229: "Apagado",
	t3230: "IDENTIFICACIÓN",
	t3231: "Abierto",
	t3232: "Inicio",
	t3233: "Final",
	t3234: "Descripción",
	t3235: "Día",
	t3236: "Cerrar",
	t3237: "Los archivos EDF se cargaron correctamente. Reinicio del administrador del sistema",
	t3238: "La carga de los archivos Edf finalizó, el administrador del sistema se restableció correctamente",
	t3239: "Error al obtener archivos edf del administrador del sistema",
	t3240: "La carga de los archivos Edf falló. Intente después de un tiempo",
	t3241: "No hay horarios de refrigeración configurados",
	t3242: "Subir archivo al administrador del sistema",
	t3243: "Especifique el tipo de dispositivo: afecta las etiquetas de parámetros requeridas para operarlo",
	t3244: "Especificar cómo se accede a los valores del dispositivo y qué función cumplen",
	t3245: "Crear una alarma basada en un bit de un parámetro",
	t3246: "Etiquetas",
	t3247: "Crear una lista de etiquetas que se pueden asignar a un parámetro",
	t3248: "Grupos",
	t3249: "Especificar grupos de parámetros",
	t3250: "Número de registro",
	t3251: "Tipo de datos",
	t3252: "Lista de etiquetas",
	t3253: "Mínimo",
	t3254: "Defecto",
	t3255: "Máximo",
	t3256: "Tipo de registro",
	t3257: "El tipo de registro se traduce a los códigos de función correspondientes para lectura y escritura.",
	t3258: "Modo de intercambio",
	t3259: "Para un valor con bytes ABCD enviados al dispositivo, esta opción intercambia bytes (BADC), palabras (CDAB) o ambos (DCBA).",
	t3260: "Solo lectura",
	t3261: "Inicio sesión",
	t3262: "Habilita el registro de historial para este parámetro",
	t3263: "Transferir texto de parámetro a alarma",
	t3264: "Posición de la bandera de alarma contando desde ellos el bit menos significativo del parámetro",
	t3265: "Activar alarma en valor bajo",
	t3266: "Etiqueta",
	t3267: "Ocultar",
	t3268: "{{field}} es un campo obligatorio.",
	t3269: "El máximo debe ser mayor o igual al mínimo",
	t3270: "El valor predeterminado debe estar en [Mínimo, Máximo]",
	t3271: "El tipo de datos debe ser {{reg}} para el tipo de registro seleccionado {{mt}}",
	t3272: "El nombre no debe contener signos delimitadores",
	t3273: "El nombre no debe exceder los 20 caracteres",
	t3274: "La lista de etiquetas no debe estar vacía",
	t3275: "La(s) etiqueta(s) {{taglist}} deben asignarse exactamente una vez.",
	t3276: "Los grupos {{grouplist}} contienen más del máximo de 50 parámetros.",
	t3277: "El formulario contiene errores, revise los errores y vuelva a intentarlo.",
	t3278: "Enviar",
	t3279: "Para activar el EDF cargado, debe reiniciar la unidad. ¿Desea reiniciar la unidad ahora?",
	t3280: "restablecer ahora",
	t3281: "restablecer más tarde",
	t3282: "{{file}} ya existe. ¿Quieres sobreescribirlo?",
	t3283: "Subir {{what}}",
	t3284: "Las etiquetas y los valores deben ser únicos.",
	t3285: "Multi",
	t3286: "El dispositivo maneja múltiples cajas o paquetes",
	t3287: "Etiquetas",
	t3288: "Internet Explorer está al final de su ciclo de vida y no es compatible con StoreviewWeb",
	t3289: "Cambie a un navegador compatible, ya sea Edge, Chrome o Firefox",
	t3290: "Más información",
	t3291: "Cerca",
	t3292: "No se han podido leer los datos, inténtalo de nuevo.",
	t3293: "Copia de seguridad de EDF en curso",
	t3294: "No hay vistas para migrar",
	t3295: "El sistema está configurado con una IP pública en el campo de IP privada",
	t3296: "El sistema está configurado con una IP privada en el campo de IP pública, es posible que no se pueda acceder al sistema de forma remota o que algunas funciones no funcionen correctamente",
	t3297: "Está conectado a través de una IP o nombre de host que el administrador del sistema no conoce, probablemente a través de un servicio de proxy, es posible que algunas funciones no funcionen correctamente",
	t3298: "El dispositivo está fuera de línea y muestra el último valor conocido",
	t3299: "Integración de dispositivos",
	t3300: "Las etiquetas {{taglist}} están duplicadas.",
	t3301: "Página no encontrada",
	t3302: "Lo sentimos, no se pudo encontrar la página solicitada",
	t3303: "Código de error: 404",
	t3304: "Ir al panel de control",
	t3305: "Sólo vista",
	t3306: "Dirección IP interna",
	t3307: "Dirección IP externa",
	t3308: "Nombre de dominio",
	t3309: "Sin advertencias",
	t3310: "Múltiples advertencias",
	t3311: "Parece que el sistema está configurado con unidades múltiples, pero no pudimos contactar con una o más de las unidades esclavas, asegúrese de que la configuración sea correcta. Tenga en cuenta que una configuración de unidades múltiples no admite varios nombres de dominio y que cada unidad debe configurarse con un puerto distinto cuando se usa el mismo dominio",
	t3312: "Sólo vista",
	t3313: "Este elemento no tiene asociación con ningún parámetro y se puede eliminar de forma segura",
	t3314: "Eliminar parámetro",
	t3315: "Calendario",
	t3316: "Ahora se cerrará la sesión y deberá iniciar sesión con la nueva contraseña",
	t759: "Unidad de descarga, reinicio y actualización de la base de datos. Esto puede tardar varios minutos.",
	t816: "Tipo de alarma:",
	t818: "Contar :",
	t848: "El archivo DB no se crea. Tiene acceso limitado al directorio instalado. Póngase en contacto con el administrador del sistema.",
	t3317: "No hay horarios para exportar",
	t3318: "Pendiente de respuesta del sistema",
	t3319: "Sin actividad en la red",
	t3320: "Nombre de la tienda",
	t3321: "Nueva versión {{latestVersion}} disponible en línea",
	t3322: "Puerto externo",
	t3323: "Puerto interno",
	t3324: "No podemos comunicarnos con el administrador del sistema en",
	t3325: "tal vez el certificado necesita ser de confianza?",
	t3326: "El archivo de este formato no se puede eliminar",
	t3327: "Uso",
	t3328: "A tiempo",
	t3329: "Fuera de tiempo",
	t3330: "Días de semana",
	t3331: "Etapa de enfriamiento",
	t3332: "Recuperación de calor",
	t3333: "Enfriamiento",
	t3334: "Calefacción",
	t3335: "Calor auxiliar",
	t3336: "Deshumidificación",
	t3337: "Internet",
	t3338: "Mensajes",
	t3339: "Contactos",
	t3340: "comunicación",
	t3341: "No se encontraron artículos",
	t3342: "Escribe para buscar",
	t3408: "Habilitar todo",
	t3409: "Desactivar todo",
	t3410: "Normal",
	t3411: "Severo",
	t3412: "Crítico",
	t3413: "Habilitar todas las alarmas",
	t3414: "Buscar alarmas",
	t3415: "Activado",
	t3417: "Error al obtener alarmas",
	t3418: "Error al obtener los dispositivos para copiar",
	t3419: "Error al comprobar el estado de la copia",
	t3420: "El sondeo automático se reanudará después de un tiempo de espera máximo de 2 horas, o si se cancela o finaliza el asistente.",
	t3421: "Copiar a los controladores",
	t3422: "Copiar a los dispositivos seleccionados",
	t3423: "La función de copia primero copiará las configuraciones a la base de datos de System Manager y luego descargará las configuraciones a los dispositivos del controlador. Puede llevar algún tiempo completar la acción.",
	t3424: "No hay dispositivos seleccionados para copiar",
	t3425: "Haga doble clic para copiar",
	t3426: "Error al buscar dispositivos",
	t3427: "Dispositivos para copiar",
	t3428: "Seleccione el(los) dispositivo(s) para copiar",
	t3429: "Aquí solo se mostrarán los controladores del mismo tipo",
	t3430: "El proceso de copia se realizó correctamente",
	t3431: "Archivo no válido. Modelo de archivo esperado:",
	t3432: "Error al cargar el archivo de configuración",
	t3435: "¿Está seguro de mover el dispositivo seleccionado a este grupo {{toPackGroup}} ?",
	t3343: "Descubrimiento",
	t3344: "Actualizar tabla",
	t3345: "Escaneando redes, por favor espere...",
	t3346: "La tabla de red se actualiza",
	t3347: "Error al actualizar redes",
	t3348: "Error al volver a escanear redes",
	t3349: "Error al obtener redes",
	t3350: "Escaneo de red completado",
	t3351: "No hay dispositivos disponibles",
	t3352: "Escanear red",
	t3353: "Deshabilitar",
	t3354: "Cualquier reorganización de los dispositivos en el asistente puede afectar los servicios de monitoreo de Alsense.",
	t3355: "Red",
	t3356: "Seleccionar el tipo de bus de campo",
	t3357: "Si está habilitado el Modbus/RS485, puede seleccionar SLV para el compresor de velocidad variable de Danfoss. Al seleccionar SNMP como bus local, los controles SNMP de Danfoss le solicitarán que ingrese un rango de direcciones de subred válido.",
	t3358: "Compresores/Paquetes",
	t3359: "Administrar controladores de compresores/paquetes",
	t3360: "Aquí solo se pueden administrar los dispositivos Pack, no el sistema de E/S de bastidor. Los controladores Pack de succión múltiple compatibles que se hayan escaneado aparecerán inicialmente como 0 grupos de succión. Se requiere la herramienta de servicio AK-ST 500 para configurar el controlador Pack.",
	t3361: "Cantidad a copiar",
	t3362: "Al reducir el grupo de succión configurado previamente también se eliminarán todos los controles de evaporación asociados para ese grupo de succión.",
	t3363: "El número de grupos de succión no puede ser cero, el número mínimo de grupos de succión debe ser al menos uno.",
	t3364: "Error al obtener compresores/paquetes",
	t3365: "No hay compresores/paquetes disponibles",
	t3366: "Dispositivos en línea",
	t3367: "Dispositivos fuera de línea",
	t3369: "Agregar paquete",
	t3370: "Si selecciona Modbus/RS485, verá opciones adicionales para SLV/ECFan, estas se utilizan para refrigeración y se pueden seleccionar como No.",
	t3371: "Agregar circuito",
	t3372: "Agregar, copiar o eliminar dispositivos. Tenga en cuenta que no todos los tipos de dispositivos permiten editar todos los campos y que las direcciones de los puntos de E/S se establecen en la configuración.",
	t3373: "Gestionar controladores de casos",
	t3374: "Agregar un dispositivo",
	t3375: "Administrar controladores de compresores/paquetes",
	t3376: "Error en el acceso a los datos",
	t3377: "El diseño de HVAC se ha aplicado correctamente",
	t3378: "Disposición del sistema HVAC",
	t3379: "¿Estás seguro que deseas abandonar esta página?",
	t3380: "Es posible que se pierda su configuración",
	t3381: "Por favor configure un dispositivo",
	t3382: "Salir de la configuración rápida",
	t3383: "¿Está seguro de que desea salir de la configuración rápida?",
	t3384: "Pasarelas (AK-PI200)",
	t3385: "Seleccione las puertas de enlace encontradas",
	t3386: "Dirección IP",
	t3387: "Controlador de fuente",
	t3388: "Configuración de origen",
	t3389: "Alarmas de origen",
	t3390: "Gráfico fuente",
	t3392: "Gráfico",
	t3393: "Dispositivos",
	t3394: "El sondeo automático se suspende durante la fase de copia. El interruptor principal de los dispositivos de origen y destino se apagará y luego volverá al estado original una vez que se complete la copia. Asegúrese de verificar el interruptor principal una vez que se complete el asistente de copia. Si alguno de los dispositivos seleccionados está en alarma activa, no se modificará ninguna modificación a la acción de alarma para ese punto en particular. La configuración original permanecerá.",
	t3395: "Seleccione un dispositivo de origen desde el cual copiar la configuración",
	t3396: "A tiempo",
	t3397: "La función de copia copiará los puntos del gráfico disponibles hasta que se alcance la capacidad de la unidad. Vaya a Configuración para ver la cantidad de puntos definidos",
	t3398: "Gráficos del dispositivo fuente",
	t3399: "Configurar puntos de gráficos para el controlador de origen",
	t3400: "Habilite HACCP para registrar los puntos en un informe de temperatura de HACCP",
	t3401: "Búsqueda de puntos",
	t3402: "Ningún dato disponible",
	t3403: "Error al obtener los puntos del gráfico",
	t3404: "No habilitado",
	t3405: "Alarmas del dispositivo fuente",
	t3406: "Configurar los detalles de la alarma en el controlador de origen",
	t3407: "Nombre de la alarma",
	t3436: "El dispositivo será eliminado del grupo asignado [ {{fromPackGroup}} ]",
	t3437: "Disposición de refrigeración",
	t3438: "El diseño de refrigeración se ha aplicado con éxito.",
	t3439: "Reasignar",
	t3440: "Asignar controles de caso a controles de paquete",
	t3441: "Asignar controles de caja (de las cajas disponibles) a controles de compresor/paquete, formando grupos de succión",
	t3442: "Parámetros del dispositivo",
	t3443: "Seleccionar parámetros para copiar",
	t3444: "Al trabajar en un dispositivo en vivo (en línea), puede haber un retraso en la configuración debido a la comunicación con el dispositivo.",
	t3445: "Seleccionar alarmas para copiar",
	t3446: "Gráfico del dispositivo",
	t3447: "Seleccionar puntos del gráfico para copiar",
	t3448: "Guardar la configuración actual del dispositivo en un archivo",
	t3449: "Puede guardar la configuración actual del dispositivo en un archivo que se puede utilizar para configurar dispositivos en una fecha posterior. Si desea salir del asistente después de guardar el archivo, utilice el icono de salida. Si trabaja con dispositivos conectados, al salir se asegurará de que el sondeo de la red y el interruptor principal del dispositivo vuelvan al estado correcto.",
	t3450: "Los archivos de configuración se guardaron correctamente",
	t3451: "¿Desea continuar o salir de la configuración rápida?",
	t3452: "Guardar archivo(s)",
	t3453: "Error al obtener la configuración",
	t3454: "Configuración del dispositivo de origen",
	t3455: "Configurar los parámetros del dispositivo en el controlador de origen",
	t3456: "Ninguno de los campos de contraseña debe estar vacío",
	t3457: "Recientes",
	t3458: "Directorio",
	t3459: "Último acceso",
	t3460: "Realice una copia de seguridad de la libreta de direcciones periódicamente.",
	t3461: "La libreta de direcciones se importó correctamente",
	t3462: "Importar libreta Direcc.",
	t3463: "Exportar libreta Direcc.",
	t3464: "Agregar sitio",
	t3465: "Editar sitio",
	t3466: "Eliminar ubicación",
	t3467: "Debe crear una contraseña para la libreta de direcciones antes de comenzar a utilizarla.",
	t3468: "El sitio se ha añadido correctamente a su libreta de direcciones",
	t3469: "Ya está en la libreta de direcciones",
	t3470: "Agregar a la libreta de direcciones",
	t3471: "Iniciar sesión en el sitio",
	t3472: "La información del sitio se ha actualizado correctamente",
	t3474: "Notificación",
	t3475: "El sitio ha sido eliminado exitosamente de su libreta de direcciones",
	t3476: "El sitio se ha actualizado correctamente",
	t3477: "Formato de libreta de direcciones no válido",
	t3478: "Comportamiento",
	t3479: "No disponible sin conexión",
	t3480: "No se pudo cargar la base de datos S55",
	t3481: "Cargando la base de datos S55",
	t3482: "Protocolo",
	t3483: "El usuario no tiene permiso para ver la información del software",
	t3484: "La contraseña debe ser alfanumérica o solo numérica",
	t3485: "Necesita crear una contraseña de libreta de direcciones para acceder a la libreta de direcciones",
	t3486: "La contraseña de la libreta de direcciones no es correcta",
	t3487: "El sitio ya está presente en la libreta de direcciones",
	t3488: "Cree una contraseña para utilizar la libreta de direcciones. La contraseña debe ser alfanumérica o numérica únicamente.",
	t3489: "Introduzca la contraseña de su libreta de direcciones",
	t3490: "No hay dispositivos en línea disponibles para copiar",
	t3491: "Crear contraseña para la libreta de direcciones",
	t3492: "Actualizar contraseña de la libreta de direcciones",
	t3493: "La contraseña del sitio no puede estar vacía",
	t3494: "Escriba una contraseña para la libreta de direcciones que se va a importar",
	t3495: "La contraseña de la libreta de direcciones se creó correctamente",
	t3496: "La contraseña de la libreta de direcciones se actualizó correctamente",
	t3497: "La contraseña de la libreta de direcciones que se está importando no es correcta",
	t3498: "Por favor, introduzca la contraseña del sitio",
	t3499: "Limpiar la libreta de direcciones",
	t3500: "Buscar sitios",
	t3501: "Seleccione el archivo de la libreta de direcciones que desea importar",
	t3502: "Seleccione el archivo de clave para descifrar la libreta de direcciones",
	t3503: "Pulse exportar para descargar la libreta de direcciones cifrada",
	t3505: "Con cifrado",
	t3506: "contraseñas sin cifrar",
	t3507: "Clave secreta no válida",
	t3508: "Error en la importación. Asegúrese de haber importado la clave correcta",
	t3509: "No hay versiones disponibles",
	t3510: "Servicios abiertos de Alsense (requiere suscripción)",
	t3511: "Lea atentamente los términos del EULA. Si no los acepta, no podrá utilizar la aplicación.",
	t3512: "La libreta de direcciones y su contraseña de la libreta de direcciones se borraron correctamente",
	t3513: "(requerido)",
	t3515: "La libreta de direcciones está vacía",
	t3516: "La contraseña de la libreta de direcciones no puede estar vacía",
	t3519: "Al borrar la libreta de direcciones también eliminará su contraseña.",
	t3520: "Fuera de rango. Debe estar entre 0 y {{maxAddr}}",
	t3521: "Pulse 'Exportar' para descargar todas las vistas gráficas almacenadas en el Administrador del sistema",
	t3522: "Pulse 'Descargar' para descargar un informe del sistema",
	t3523: "Solo la unidad principal está disponible en una conexión WiFi directa",
	t3524: "No hay vistas gráficas locales. Agregue vistas nuevas o importe las existentes",
	t3525: "No hay vistas gráficas remotas. Intente buscar con un nombre diferente",
	t3526: "No hay vistas gráficas remotas. Agregue nuevas vistas, importe o migre vistas existentes",
	t3527: "Vistas locales ( {{totalFiles}} de 5)",
	t3528: "{{totalFiles}} gráficos locales de un máximo de 5",
	t3529: "Vistas remotas",
	t3530: "El archivo no es un mapa de bits.",
	t3531: "Longitud del mapa de bits incorrecta.",
	t3532: "El tamaño del encabezado de mapa de bits es incorrecto.",
	t3533: "Tamaño de mapa de bits incorrecto.",
	t3534: "Error en el tamaño de la paleta.",
	t3535: "Archivo .bmp incompatible",
	t3536: "Error al eliminar archivo gráfico local",
	t3537: "Error al duplicar archivo gráfico local",
	t3538: "Error al exportar archivo gráfico local",
	t3539: "Eliminar gráfico",
	t3540: "Eliminar un archivo gráfico local",
	t3541: "La eliminación del archivo gráfico local está en proceso",
	t3542: "Archivo gráfico local eliminado correctamente. Reiniciando el Administrador del sistema",
	t3543: "Se ha finalizado la eliminación del archivo gráfico local. El administrador del sistema se ha reiniciado correctamente",
	t3544: "Gráfico duplicado",
	t3545: "Duplicar un archivo gráfico local",
	t3546: "Se está realizando una duplicación de archivo gráfico local",
	t3547: "El archivo gráfico local se duplicó correctamente. Reiniciando el Administrador del sistema",
	t3548: "Se completó la duplicación del archivo gráfico local. El Administrador del sistema se reinició correctamente",
	t3549: "Exportar gráfico",
	t3550: "Exportar un archivo gráfico local",
	t3551: "La exportación del archivo gráfico local está en curso",
	t3552: "Archivo gráfico local exportado con éxito",
	t3553: "El Administrador del sistema se reiniciará con esta acción",
	t3554: "¿Estás seguro de que deseas eliminar?",
	t3555: "¿Estás seguro de que deseas duplicar?",
	t3556: "Pulse 'Exportar' para descargar todas las vistas gráficas locales almacenadas en el Administrador del sistema",
	t3557: "La exportación de archivos gráficos locales está en curso",
	t3558: "Archivos gráficos locales exportados exitosamente",
	t3559: "Código del producto",
	t3560: "Obtenga más información sobre este producto",
	t3561: "Ventilador ecológico",
	t3562: "Apertura de válvula",
	t3563: "Supercalentar",
	t3564: "Presión del evaporador",
	t3565: "Temperatura del evaporador",
	t3566: "Separar",
	t3567: "Cutina",
	t3568: "Puerta de caja",
	t3569: "Temperatura de succión",
	t3570: "Calor del riel",
	t3571: "Se alcanzó el límite máximo de archivos permitido. Anule la selección de los archivos que desea importar",
	t3572: "Elimine los archivos existentes en el administrador del sistema para importar archivos",
	t3573: "El archivo gráfico local se importó correctamente. Reiniciando el Administrador del sistema",
	t3574: "Se completó la importación de los archivos gráficos locales. El Administrador del sistema se reinició correctamente",
	t3575: "Se alcanzó el límite máximo de archivos permitido. Eliminar archivo(s) para importar",
	t3576: "El máximo de archivos gráficos permitidos es 5",
	t3577: "El archivo gráfico local se cargó correctamente. Reiniciando el Administrador del sistema",
	t3578: "Se ha finalizado la carga del archivo gráfico local. El administrador del sistema se ha reiniciado correctamente",
	t3579: "Formato de archivo no válido. Cargue el archivo {{supportedFileFormats}}",
	t3580: "La dimensión del archivo debe ser {{maxWidthAllowed}} x {{maxHeightAllowed}} píxeles para pantallas locales",
	t3581: "Vista remota",
	t3582: "Vista local",
	t3583: "Página de inicio de Storeview Web",
	t3584: "Términos del EULA",
	t3585: "Seguridad",
	t3586: "Niveles de aplicación de la seguridad",
	t3587: "Compatible con versiones anteriores (nivel de seguridad más bajo, suponiendo que el uso de autenticación de encabezado está deshabilitado)",
	t3588: "Las solicitudes SvW/XML contienen nombre de usuario y contraseña.",
	t3589: "Sin desinfección de solicitudes, misma seguridad que en versiones anteriores",
	t3590: "No es necesario realizar cambios en SvW ni en las conexiones XML existentes",
	t3591: "Permisivo (se puede utilizar como transición al nivel estricto)",
	t3592: "Las solicitudes XML pueden contener un nombre de usuario y una contraseña, o un token de sesión. El token de sesión se recibe en la respuesta a la solicitud de inicio de sesión.",
	t3593: "Si el token de sesión se proporciona en el encabezado AKSM-auth, se ignoran los nombres de usuario y las contraseñas",
	t3594: "La solicitud XML se desinfecta y se devuelve una advertencia en la respuesta de la solicitud si se descubre algún problema.",
	t3595: "Tenga en cuenta estas advertencias antes de cambiar al modo estricto",
	t3596: "Si no se utiliza el token de sesión, no se puede aplicar la expiración de la sesión",
	t3597: "Ya compatible con la interfaz de usuario remota (SvW)",
	t3598: "Cambios posibles/obligatorios desde la perspectiva XML de terceros",
	t3599: "Sin uso de token de sesión: no se necesitan cambios",
	t3600: "Uso del token de sesión: elimine el nombre de usuario y la contraseña del cuerpo y observe las advertencias en preparación para Strict",
	t3601: "Estricto (nivel de seguridad más alto)",
	t3602: "Las solicitudes XML no pueden contener nombres de usuario ni contraseñas, pero deben proporcionar el token de sesión en el encabezado AKSM-auth. Si se descubre un problema en la solicitud, se rechaza.",
	t3603: "Ya soportado en (SvW)",
	t3604: "Cambios necesarios desde la perspectiva de terceros",
	t3605: "El nombre de usuario y la contraseña deben eliminarse de la carga útil de la solicitud XML y el token de sesión debe colocarse en un encabezado CORS llamado AKSM-auth",
	t3606: "Error de inicio de sesión. Retroceso",
	t3607: "Cuánto tiempo (en segundos) espera un usuario remoto para poder volver a intentar iniciar sesión después de un intento fallido. El tiempo de espera aumenta de forma acumulativa con cada intento fallido. Después de 10 intentos, la IP desde la que el usuario intentó iniciar sesión se bloquea durante 24 horas. Si se establece en 0, la interrupción por error de inicio de sesión se desactiva",
	t3608: "Predeterminado: 30 s (máximo: 600 s / 0 = deshabilitado)",
	t3609: "Intentos libres",
	t3610: "¿Cuántos intentos de inicio de sesión se permiten antes de que se aplique la suspensión?",
	t3611: "Predeterminado 2 (máximo 5)",
	t3612: "Bloquear",
	t3613: "Si está habilitado, el Usuario + IP se bloquean durante la retirada, de lo contrario, el Usuario se bloquea",
	t3614: "'Usuario' predeterminado (Usuario + IP / Usuario)",
	t3615: "Sesiones por usuario",
	t3616: "Una sesión es cuando un usuario ha iniciado sesión con éxito en la unidad y se encuentra dentro de la interfaz remota (SvW)",
	t3617: "Número de sesiones activas que puede tener un usuario. Si se establece en 0, un usuario puede tener la cantidad máxima de sesiones (30)",
	t3618: "Predeterminado 0",
	t3619: "Expiración de la sesión",
	t3620: "¿Cuántos segundos transcurren después de que expira una sesión inactiva? Si una sesión expira, se necesita iniciar sesión nuevamente.",
	t3621: "900 predeterminados (máximo 3600)",
	t3622: "Autenticación de encabezado",
	t3623: "Al habilitar HTTPS y la autenticación de encabezado, todas las solicitudes XML de terceros deben pasar credenciales de nombre de usuario y contraseña en el encabezado (esquema de autenticación básico)",
	t3624: "Para habilitar la opción \"Usar autenticación de encabezado\", es necesario reiniciar el sistema. Utilice el botón \"Presionar para inicializar\" para realizar esta acción.",
	t3625: "Pequeño",
	t3626: "Grande",
	t3627: "Guardar gráfico",
	t3628: "Guardar un archivo gráfico local",
	t3629: "El archivo gráfico local se eliminó correctamente. La carga del archivo gráfico local está en curso",
	t3630: "El archivo gráfico local se cargó correctamente. Reiniciando el Administrador del sistema",
	t3631: "Se ha finalizado el guardado del archivo gráfico local. El Administrador del sistema se ha reiniciado correctamente",
	t3632: "¿Estás seguro que deseas guardar?",
	t3633: "Error de análisis",
	t3634: "No se pudo analizar el archivo viz",
	t3635: "No se pudo analizar el archivo vz2",
	t3636: "Error al abrir el archivo Viz:",
	t3637: "No se pudieron encontrar los archivos de migración (vz2, dpj o json)",
	t3638: "No se pudieron encontrar los archivos de migración (viz)",
	t3639: "El administrador del sistema se reiniciará con esta acción. El administrador del sistema está en modo permisivo o estricto, por lo que el token de sesión caducará al reiniciarse y se cerrará la sesión del sistema.",
	t3643: "Presión de descarga",
	t3644: "kW totales",
	t3645: "KVAR total",
	t3646: "Kvarh totales+",
	t3647: "Demanda",
	t3648: "Factor de potencia",
	t3649: "Color de fuente",
	t3650: "Color de fondo",
	t3651: "La libreta de direcciones se exportó correctamente",
	t3652: "Modo de control",
	t3653: "Sc3 Temperatura exterior",
	t3654: "Temperatura de descarga",
	t3655: "Capacidad de funcionamiento del condensador",
	t3656: "No hay datos para exportar",
	t3657: "La contraseña del usuario en {{unit}} ha expirado",
	t3658: "No se puede iniciar sesión en {{unit}} con las credenciales proporcionadas.",
	t3659: "Actualice la versión del firmware o cambie el nivel de seguridad de {{unit}} para obtener soporte de token de sesión.",
	t3661: "V3gc",
	t3662: "Sección B",
	t3663: "Sección C",
	t3664: "Control adaptativo del sobrecalentamiento",
	t3665: "Razón del estado de control",
	t3666: "El usuario no puede iniciar sesión. La dirección de la unidad no está configurada en la configuración del proxy",
	t3667: "El protocolo de comunicación no está especificado en la configuración del proxy. Esto puede provocar errores.",
	t3668: "Información de seguridad informática",
	t3669: "En cumplimiento con los estándares de seguridad, el Administrador del sistema ahora tiene como valor predeterminado el control de sesión estricto, lo que significa que cualquier conexión remota o servicio remoto basado en XML ya no funcionará a menos que se apliquen tokens de sesión.",
	t3670: "Danfoss recomienda continuar en modo estricto, pero el modo de compatibilidad con versiones anteriores aún está disponible.",
	t3671: "Tenga en cuenta que esto se puede cambiar en el menú de Configuración de seguridad y no tiene impacto en el control local del Administrador del sistema ni en la operación del sitio.",
	t3672: "Ver más información sobre el control de sesiones",
	t3673: "Modo inverso",
	t3674: "Modo estricto",
	t3675: "Al hacer clic en el modo de compatibilidad con versiones anteriores, todos los administradores de sistema aplicables con firmware actualizado se configurarán en el modo de compatibilidad con versiones anteriores, se reiniciarán y se cerrará la sesión de la aplicación.",
	t3676: "El número máximo de grupos de succión permitidos es 12",
	t3677: "Al actualizar exitosamente las unidades seleccionadas de 3.3.0 a 4.X o superior, el usuario cerrará la sesión automáticamente al hacer clic en el botón Finalizar en el paso final.",
	t3678: "No se admiten estuches múltiples para controladores AK2 (paquete/estuche)",
	t3679: "De conformidad con los estándares de seguridad introducidos con la versión 4.0, el administrador del sistema utilizará de manera predeterminada el control de sesión estricto, lo que significa que cualquier conexión remota o servicio remoto basado en XML dejará de funcionar a menos que se apliquen tokens de sesión. Además de eso, la comunicación se ajustará automáticamente a HTTPS al actualizar a la versión 4.0. Danfoss recomienda continuar en modo estricto, pero está disponible el modo de compatibilidad con versiones anteriores, que restablecerá el sistema a la configuración aplicada hasta ahora. Tenga en cuenta que si está realizando esta actualización de forma remota en una unidad HTTP, asegúrese de que su configuración de TI esté preparada para HTTPS (puerto 443) o tenga personal disponible en el sitio.",
	t3680: "Encuentre más información sobre la nueva implementación de seguridad aquí",
	t3681: "El acceso a la versión http de este sitio puede estar bloqueado debido a las restricciones de su navegador. Visite nuestra página de preguntas frecuentes para obtener instrucciones sobre cómo resolver este problema o descargue la versión de escritorio de StoreView Web.",
	t3682: "Preguntas frecuentes sobre el administrador del sistema",
	t3683: "u17 Aire termal",
	t3684: "u91 Temperatura de corte.",
	t3685: "u00 Ctrl. estado",
	t3686: "u12 S3 temperatura del aire.",
	t3687: "u16 S4 temperatura del aire.",
	t3688: "% de sobredosis de EEV u23",
	t3689: "Supercaliente sub-21",
	t3690: "u22 SupercalorRef",
	t3691: "Prueba de temperatura de evaporación u26",
	t3692: "S2 Temperatura de salida de gas.",
	t3693: "Stw8",
	t3694: "SD-MT",
	t3695: "Estado de Tw",
	t3696: "Habilitar Tw",
	t3697: "Bomba Tw funcionando",
	t3698: "Stw3",
	t3699: "Stw4",
	t3700: "Stw2",
	t3701: "Estado del control de rcv.",
	t3702: "Desplazamiento de succión",
	t3703: "Modo de control Vrec",
	t3704: "Estado de Vrec",
	t3705: "Razón del estado de control",
	t3706: "Estado de control de HP",
	t3707: "Pgc máx.",
	t3708: "Pgc límite máximo P-ban",
	t3709: "Pgc mín.",
	t3710: "Capacidad del eyector de vapor",
	t3711: "Capacidad del eyector de líquido",
	t3712: "Temperatura del aire de referencia.",
	t3713: "Temperatura del aire de suministro.",
	t3714: "Aire de retorno",
	t3715: "Punto de rocío zonal",
	t3716: "Humedad de la zona",
	t3717: "Temperatura exterior",
	t3718: "Nivel de CO2 en la zona",
	t3719: "Temperatura de la zona",
	t3720: "Referencia condicional",
	t3721: "Estado de control del cond.",
	t3722: "Estado de control del cond.",
	t3723: "Control de cond.",
	t3724: "Cond. solicitó cap.",
	t3725: "Estado V3gc",
	t3726: "V3gc sobredosis",
	t3727: "Temperatura ambiente",
	t3728: "Estado de RRHH",
	t3729: "Habilitación de recursos humanos",
	t3730: "Solicitud de consumidor de RRHH",
	t3731: "Shr3",
	t3732: "Shr4",
	t3733: "Shr2",
	t3734: "Shr8",
	t3735: "Ref. de succión To-MT",
	t3736: "Estado de control MT",
	t3737: "Zona actual MT",
	t3738: "Para optar por compensar MT",
	t3739: "Capacidad de funcionamiento MT",
	t3740: "Cap. solicitado MT",
	t3741: "Es hora del siguiente paso MT",
	t3742: "Po-MT",
	t3744: "Temperatura de succión hasta MT",
	t3745: "Energía activa total",
	t3746: "Demanda máxima",
	t3747: "Factor de potencia total",
	t3748: "Voltios de fase A",
	t3749: "Voltios de fase B",
	t3750: "Voltios de fase C",
	t3751: "Amplificadores de fase A",
	t3752: "Amplificadores de fase B",
	t3753: "Amplificadores de fase C",
	t3754: "Potencia activa total",
	t3755: "Temperatura de succión hasta LT",
	t3756: "Temperatura de succión To-IT",
	t3757: "Referencia de succión LT",
	t3758: "Estado de control LT",
	t3759: "Zona actual LT",
	t3760: "Para optar por compensar LT",
	t3761: "Capacidad de funcionamiento LT",
	t3762: "Cap. solicitado LT",
	t3763: "Es hora del siguiente paso LT",
	t3764: "Po-LT",
	t3765: "SD-LT",
	t3766: "Referencia de succión IT",
	t3767: "Estado de control de TI",
	t3768: "Zona actual IT",
	t3769: "Capacidad de ejecución de TI",
	t3770: "Capacidad solicitada TI",
	t3771: "Es hora del siguiente paso en TI",
	t3772: "Tn Po-TI",
	t2773: "SD-IT",
	t2774: "Inicio de Comp. IT",
	t3774: "Fuera de rango. Debe estar entre {{minAddr}} y {{maxAddr}}",
	t3775: "Operación deshabilitada para este dispositivo",
	t3776: "Tras esta acción, la aplicación cerrará la sesión, lo que puede tardar unos instantes.",
	t3777: "No se pudieron encontrar los archivos de migración (flp o json)",
	t3778: "Votación",
	t3779: "La votación está suspendida actualmente.",
	t3780: "¿Desea reanudar la votación?",
	t3781: "Medidor de energía",
	t3782: "Varios nodos seleccionados",
	t3783: "Zoom al nodo",
	t3784: "Unidades de medida",
	t3785: "Archivo(s) exportado(s) exitosamente",
	t3786: "La dirección no puede ser negativa",
	t3787: "Restablecer zoom",
	t3788: "Error al obtener los pasos del patrón de ensayo",
	t3789: "Error al guardar los pasos del patrón de preparación",
	t3790: "Los pasos del patrón de puesta en escena se guardaron correctamente",
	t3791: "Patrón de puesta en escena",
	t3792: "Pulse los iconos descargados para habilitar/deshabilitar los descargadores asociados a los compresores",
	t3793: "Datos faltantes",
	t3794: "Los siguientes parámetros no devolvieron ningún punto de datos",
	t3795: "La confianza HTTPS/SSL fue exitosa.",
	t3796: "Ahora puede iniciar sesión en el navegador web StoreView.",
	t3797: "Redireccionando al navegador web StoreView en {{timeLeft}} segundos...",
	t3798: "¡La votación está suspendida actualmente!",
	t3799: "Alarmas borradas (registros completos del sistema)",
	t3800: "El número máximo de HVAC permitido es 45.",
	t3801: "No se pudo obtener el registro de cambios",
	t3802: "Conectando al almacenamiento...",
	t3803: "Tiempo de espera de conexión de almacenamiento SCADA",
	t3804: "Error al conectar con el almacenamiento SCADA",
	t3805: "datos no válidos",
	t3806: "Seleccione la(s) unidad(es) para descargar el informe"
};
var es = {
	lang: lang$a
};

var lang$b = {
	t0: "Alarmas Activas",
	t1: "Alarmas Solucionadas",
	t2: "Servidor",
	t3: "Ident.",
	t4: "Nombre Dispositivo",
	t5: "Editar Notas",
	t6: "No autorizado a reconocer alarmas",
	t7: "Tipo de Alarma",
	t8: "Ocurrió",
	t9: "Reconocido",
	t10: "Alarma/s activa/s",
	t11: "Solucionado",
	t12: "Cálculos",
	t13: "Alarmas Solucionadas",
	t14: "Silenciar/Reconocer",
	t15: "Reconocer todas las alarmas",
	t16: "Fallo en Autorización",
	t17: "Error",
	t18: "Control Manual",
	t19: "Visual",
	t20: "Resumen Datos",
	t21: "Vista Técnica",
	t22: "Grafico (S4)",
	t23: "Nodo",
	t24: "Mod.",
	t25: "Gráfico (Po & Pc)",
	t26: "(no hay alarmas)",
	t27: "Alarmas Controladores",
	t28: "Ver controlador anterior",
	t29: "Ver controlador siguiente",
	t30: "Error al leer alarmas",
	t31: "Alarmas Activas",
	t32: "Alarma Activa",
	t33: "No hay alarmas",
	t34: "Escribe",
	t35: "Punto",
	t36: "Registro Histórico",
	t37: "Horarios",
	t38: "Hora de inicio",
	t39: "Hora Parada",
	t40: "Días",
	t41: "Festivos",
	t42: "Estado",
	t43: "Seleccionar puntos",
	t44: "OK",
	t45: "Cancelar",
	t46: "Auto",
	t47: "Final",
	t48: "Fecha Inicio",
	t49: "Frecuencia de muestreo",
	t50: "No hay datos seleccionados",
	t51: "Ahora",
	t52: "seg",
	t53: "min",
	t54: "horas",
	t55: "Dia",
	t56: "Tipo Dirección",
	t57: "Direccion",
	t58: "Localización:",
	t59: "Introducir Valor Nuevo",
	t60: "Actualizar",
	t61: "Seleccionar puntos",
	t62: "Seleccione el Nuevo Valor",
	t63: "OFF",
	t64: "ON",
	t65: "Recogiendo datos...",
	t66: "Error al recoger datos...",
	t67: "No hay historicos configurados...",
	t68: "Error al recoger datos...",
	t69: "Recogiendo datos historicos para",
	t70: "Error al recoger datos historicos...",
	t71: "Usuario",
	t72: "Contraseña",
	t73: "Direccion Host IP/URL",
	t74: "OK",
	t75: "Cancelar",
	t76: "Nombre",
	t77: "Valor",
	t78: "Error al buscar lista de usuarios",
	t79: "Meta Nueva Dirección",
	t80: "Fallo al coger lista usuarios. Verifique direccion IP/URL del Host",
	t81: "Cod.Autoriz.",
	t82: "Codigo Acct",
	t83: "Seleccione nueva Hora y Fecha",
	t84: "Seleccione nueva duracion",
	t85: "Operación Fallida",
	t86: "Introduzca Informacion Autorización",
	t87: "Clima",
	t88: "¡Vamos!",
	t89: "Control Desescarche",
	t90: "Iniciar Desesc. Manual",
	t91: "Control Luces Muebles",
	t92: "Luces Vitrina Manual ON",
	t93: "Luces Vitrina Manual OFF",
	t94: "Control Limpieza Muebles",
	t95: "Limpieza Manual ON",
	t96: "Limpieza Manual OFF",
	t97: "Interr. Principal",
	t98: "Interruptor Ppal. Manual ON",
	t99: "Interruptor Ppal. Manual OFF",
	t100: "Apagar Controlador",
	t101: "Apagar Controlador",
	t102: "Encender Controlador",
	t103: "Control Ajuste Nocturno",
	t104: "Ajuste Nocturno ON",
	t105: "Ajuste Nocturno OFF",
	t106: "Mensaje enviado...",
	t107: "Control Manual",
	t108: "Cerrar",
	t109: "Iluminación",
	t110: "Energia/Varios",
	t111: "Vista General",
	t112: "Seleccione unidad AK255 a mostrar",
	t113: "Cargando...",
	t114: "de",
	t115: "Todos los AK-SC",
	t116: "Previo",
	t117: "Páginas",
	t118: "Siguiente",
	t119: "Parametro",
	t120: "Medidas",
	t121: "Recogiendo datos...",
	t122: "Error al leer seccion",
	t123: "Temperatura",
	t124: "Retr. despues desescarche",
	t125: "Alrm si esta abajo",
	t126: "Alrm si esta arriba",
	t127: "Monitorizar",
	t128: "Desconectado",
	t129: "Activo",
	t130: "Servidor",
	t131: "Dirección",
	t132: "Descripción",
	t133: "Valor Actual",
	t134: "Estado Actual",
	t135: "Controladores",
	t136: "Unidad",
	t137: "Hay Alarma Activa",
	t138: "¡ Hay Alarma Activa !",
	t139: "Entradas Digitales",
	t140: "Salidas Relé",
	t141: "Entdas Sensor",
	t142: "Salidas Variable",
	t143: "Controladores Genericos",
	t144: "Otros",
	t145: "Ver Puntos",
	t146: "Vista General Tienda",
	t147: "Salir del Sistema",
	t148: "Entrar Usuario",
	t149: "Salida Usuario",
	t150: "Refrigeración",
	t151: "Vista General Dispositivo",
	t152: "Alarmas",
	t153: "Historia",
	t154: "Vista Gral. CS",
	t155: "Vista Gral. Tienda",
	t156: "Configuración",
	t157: "Vista Tienda",
	t158: "Vista Planta",
	t159: "Inicio",
	t160: "Alarma",
	t161: "Ajuste",
	t162: "Detalle Controlad.",
	t163: "Ajustes",
	t164: "Seleccionar Fecha",
	t165: "Información",
	t166: "Reg. Audito",
	t167: "Cálculos",
	t168: "Admon. Archivo",
	t169: "Manual de Ayuda",
	t170: "Info",
	t171: "Traductor",
	t172: "Salvar a Servidor",
	t173: "¿Actualizar ficheros de lenguaje?",
	t174: "Esta operacion actualizará el fichero de idioma en la unidad, Continuar?",
	t175: "Exportar Históricos",
	t176: "Seleccionar Nuevo Valor",
	t177: "mínimo",
	t178: "máximo",
	t179: "¡Ninguna alarma activa!",
	t180: "No admite cambios!",
	t181: "Por favor espere...",
	t182: "Comando Enviado!",
	t183: "Fallo en la operación, intentelo mas tarde",
	t184: "Operacion con éxito",
	t185: "¡Operación no autorizada!",
	t186: "¿Esta seguro que quiere continuar?",
	t187: "Evaporadores",
	t188: "Central",
	t189: "Entradas para monitoriz.",
	t190: "Varios",
	t191: "Alarmas Reconocidas",
	t192: "Borrar alarmas reconocidas",
	t193: "Borrar todas las alarmas reconocidas",
	t194: "No esta autorizado a borrar alarmas",
	t195: "No autorizado",
	t196: "¿Quiere reconocer esta alarma?",
	t197: "Confirmar para reconocer alarma",
	t198: "Por favor registrese antes",
	t199: "Seleccione una alarma para borrar",
	t200: "Confirmar para borrar alarmas",
	t201: "Esta seguro que quiere borrar esta alarma?",
	t202: "No autorizado para borrar alarmas",
	t203: "Esta seguro que quiere borrar TODAS las alarmas?",
	t204: "Alarmas seleccionadas no se pueden borrar",
	t205: "Ajustes de Alarma",
	t206: "Esta operacion la debe realizar un Supervisor autorizado",
	t207: "No se reciben datos desde la unidad",
	t208: "Seleccione una unidad",
	t209: "Seleccione una unidad de la lista",
	t210: "Numero de alarmas",
	t211: "Estado Alarma",
	t212: "Varios",
	t213: "Borrar Alarma Seleccionada",
	t214: "Por favor confirme",
	t215: "Fallo en conexion a unidad. Verifique la conexion de red.",
	t216: "Alarma desescarche",
	t217: "Alarma digital",
	t218: "Estado Entrada Digital",
	t219: "Entrada Desescarche",
	t220: "Entrada Limpieza",
	t221: "Entrada Digital",
	t222: "Salvando ficheros de idioma",
	t223: "Unidades",
	t224: "No hay dispositivo configurado en el Sistema. Sitio web deshabilitado",
	t225: "Advertencia:",
	t226: "Seleccione la Hora",
	t227: "Comunicaciones",
	t228: "¿Volcar fichero de informes?",
	t229: "Por favor espere mientras se genera el fich de informes...",
	t230: "Volcar Informe",
	t231: "Estado Alarma/Servicio",
	t232: "Por espere, recogiendo datos",
	t233: "Registrado",
	t234: "Usuario no registrado",
	t235: "Usuario registrado",
	t236: "¡Bienvenido!",
	t237: "Licencia no válida",
	t238: "Controladores Energia",
	t239: "Paneles Iluminacion",
	t240: "Variador AKD/VLT",
	t241: "Modulo Compresor",
	t242: "Puntos Controlador",
	t243: "Puntos Calculados",
	t244: "Paneles Cutler-Hammer",
	t245: "Portadores",
	t246: "Vista Avanzada",
	t247: "Haga doble click  para Zoom In/Out",
	t248: "Haga click para Zoom In(Out",
	t249: "Alarma(s) Reconocidas",
	t250: "Simulador",
	t251: "Area Refrig.",
	t252: "Area Clima",
	t253: "Area Luces",
	t254: "Area Varios",
	t255: "Conectado a Red",
	t256: "Variad",
	t257: "Referencia",
	t258: "Grafica (Drive Mtr Hz & Amp)",
	t259: "Fecha Finaliz.",
	t260: "Parado",
	t261: "La hora de finalización es igual o anterior a la hora de inicio, vuelva a seleccionar la fecha/hora",
	t262: "La hora de creación del historial de los puntos de datos",
	t263: "despues de tiempo de inicio seleccionado.",
	t264: "Los puntos de muestreo superan los 100.000, va a llevar tiempo recoger las variables.",
	t265: "El procesador de Historicos esta ocupado, espere por favor 2 minutos e intentelo de nuevo",
	t266: "Guardar",
	t267: "Panel de Control de Historicos",
	t268: "Primero",
	t269: "Último",
	t270: "Imprimir",
	t271: "Disminuir Zoom",
	t272: "Aumentar Zoom",
	t273: "Mostr/Ocultar Leyenda",
	t274: "Exportar Datos Historicos a Fich",
	t275: "Mostrar/Ocultar malla",
	t276: "Vista Una Página",
	t277: "Vista Multi Pagina",
	t278: "Cargar Historicos (grp) de fichero",
	t279: "Cargar Historicos desde fichero",
	t280: "Salvar Historicos a fichero",
	t281: "Contador Multi Paginas",
	t282: "Paginas",
	t283: "Seleccione una pagina para mostrar",
	t284: "Historicos deben estar en formato .hst!",
	t285: "No hay datos, por favor verifique los historicos y la fecha/tiempo seleccionado",
	t286: "Historicos, Pantallazo",
	t287: "Historicos, Vista Completa",
	t288: "Vista Ficheros Históricos",
	t289: "Elegir...",
	t290: "Esta operacion exportará los Historicos actuales a fichero .hst ó .csv",
	t291: "Seleccione un fichero.",
	t292: "Archivo",
	t293: "Carga Historicos (grp)…",
	t294: "Cargar Históricos…",
	t295: "Salvar Historicos (grp)…",
	t296: "Ver",
	t297: "Zoom",
	t298: "Si",
	t299: "No",
	t300: "¿Recuperar la configuración del controlador?",
	t301: "No preguntar más",
	t302: "Recibir Configuracion Controlador",
	t303: "Datos recibidos",
	t304: "Nivel Alarma",
	t305: "Accion Alarma",
	t306: "Reconocido",
	t307: "Salvar datos al Disco",
	t308: "Nombre Fichero",
	t309: "Descripción BaseDatos",
	t310: "Directorio BaseDatos",
	t311: "Versión",
	t312: "Hora",
	t313: "Exportar Alarmas",
	t314: "Base Datos guardada en",
	t315: "Fallo al salvar BD, verifique si el directorio y nombre fich son validos",
	t316: "Nombre Fichero no es correcto, verifique nombre fichero de BaseDatos",
	t317: "Alarmas exportadas con exito, elija un fichero para salvar las alarmas exportadas",
	t318: "Seleccione las alarmas a exportar",
	t319: "Recogiendo informacion de la Unidad(es)",
	t320: "Descripcion Fich.",
	t321: "Convertir fich .hst a formato .csv",
	t322: "Paso 1: Seleccione el fichero .hst",
	t323: "Paso 2: Seleccione los datos y el rango de tiempo.",
	t324: "Paso 3: Seleccione el fichero .csv",
	t325: "Guardar como...",
	t326: "No se permiten más de 6000 puntos de datos en esta operación",
	t327: "Puntos de Datos seleccionados",
	t328: "No se permite exportar mas de 65536 muestras por punto, por favor cambie el intervalo de muestro o el rango de tiempo.",
	t329: "Colectar",
	t330: "Recoger historicos desde la unidad",
	t331: "Seleccionar puntos recogidos",
	t332: "No hay valor",
	t333: "Perdido",
	t334: "Suspendido",
	t335: "Por favor haga una seleccion",
	t336: "Seleccione los puntos a dibujar",
	t337: "Guardar a fichero .hst",
	t338: "Volcado de datos finalizado, seleccione one de las opciones siguientes:",
	t339: "Seleccione como maximo 8 puntos para dibujar",
	t340: "Salvar datos historicos a fichero .hst",
	t341: "Imprimir Vista Gral.",
	t342: "Por favor selecciones Guardar o Imprimir el Informe",
	t343: "Elija un formato de fich.",
	t344: "Generando fichero de VistaGral, por favor espere...",
	t345: "La BaseDatos seleccionada existe, ¿quiere sobreescribirla?",
	t346: "Panel de Control",
	t347: "Vista Gráfica",
	t348: "Ayuda",
	t349: "Vista de Sistema",
	t350: "Mapa",
	t351: "Herramienta de Idiomas",
	t352: "Manual",
	t353: "Pidiendo Alarmas...",
	t354: "Actualizado",
	t355: "Modelo",
	t356: "Control",
	t357: "Aceite",
	t358: "Temp.",
	t359: "Máximizar",
	t360: "Restaurar",
	t361: "Ir atras...",
	t362: "Fallo comunicacion!",
	t363: "Estado comunicacion OK",
	t364: "Mensaje comunic. enviado",
	t365: "Cargando Componentes",
	t366: "Cargando Configuracion de Componentes",
	t367: "Temp Ctrl",
	t368: "Este ajuste sera efectivo cuando los historicos se redibujen, quiere continuar?",
	t369: "Cargando, por favor espere...",
	t370: "puntos de datos",
	t371: "Unidad sin nombre",
	t372: "Preferencias",
	t373: "Version Simulador",
	t374: "Energía",
	t375: "Ir a Vista General",
	t376: "Alarma de Sistema o dispositivo no encontrado",
	t377: "Horarios coincidentes",
	t378: "Fichero de lenguaje perdido",
	t379: "Version no soportada",
	t380: "Meta la direccion IP de la Ud aqui (ej: 192.168.1.1)",
	t381: "Por favor meta la direccion IP o URL",
	t382: "Ocultar Detalles",
	t383: "Mostrar Detalles",
	t384: "Temp. Exterior",
	t385: "Retorno",
	t386: "Impulsion",
	t387: "Humedad",
	t388: "PtoRocio",
	t389: "Vent",
	t390: "Zona",
	t391: "Apagado",
	t392: "Separar",
	t393: "TempAspirac.",
	t394: "Capacidad Actual",
	t395: "Presión Aspiración",
	t396: "Descarga",
	t397: "Compresores",
	t398: "Condensador",
	t399: "Consigna Condensación",
	t400: "Refrigerante",
	t401: "Consigna Compresores",
	t402: "Aspirac",
	t403: "Desesc.",
	t404: "Formato Historicos Danfoss",
	t405: "Formato Texto",
	t406: "Esta operación necesita resetear la unidad, quiere resetear la unidad ahora?",
	t407: "Reseteo de la unidad con éxito, por favor cargue la página de nuevo.",
	t408: "Modo de Aplicacion de Fábrica",
	t409: "Calculando...",
	t410: "minutos",
	t411: "Ajuste Nocturno",
	t412: "Luces Servicios",
	t413: "Coincidencia de Horarios",
	t414: "(Resolución de 10 min)",
	t415: "Vista Texto",
	t416: "Exportar como CSV",
	t417: "Exportar como PNG",
	t418: "Ver Gráfico",
	t419: "Ver Texto",
	t420: "Horario",
	t421: "Duración",
	t422: "Temp. Term.",
	t423: "NINGUNO",
	t424: "Temp. Control",
	t425: "Funcionando desde",
	t426: "Base de Datos cargada desde",
	t427: "Estadisticas",
	t428: "Seleccione Fichero",
	t429: "Elija el fichero histórico que desea convertir a formato CSV",
	t430: "Finalizar",
	t431: "Resumén",
	t432: "Ficheros de Datos Historicos (.hst)",
	t433: "Asistente de conversión de ficheros",
	t434: "Fichero creado.",
	t435: "Elija los puntos que quiere convertir a formato CSV",
	t436: "Seleccione el rango de tiempo",
	t437: "Seleccione el rango de tiempo y pulse el boton de Procesar",
	t438: "Procesar Datos",
	t439: "Preparando datos históricos, espere.",
	t440: "Seleccione nombre del fich CSV y salvar datos a fichero...",
	t441: "No se pueden encontrar datos históricos",
	t442: "Error - No hay datos históricos",
	t443: "Ha habido un error de comunicación con las unidades en la red. Encuentre",
	t444: "Atención",
	t445: "Silencio",
	t446: "La base de datos no esta guardada, Quiere cerrar esta ventana?",
	t447: "Vista de Tabla",
	t448: "Mostrar Gráfico",
	t449: "Mostrar Tabla",
	t450: "Desesc. Manual On",
	t451: "Desesc. Manual Off",
	t452: "Desesc. Auto",
	t453: "Ajustar control Desesc.",
	t454: "Etapa ON",
	t455: "Etapa OFF",
	t456: "Líquido",
	t457: "No hay horarios definidos",
	t458: "Vista Historicos",
	t459: "Comunicación perdida con una unidad de la red !!",
	t460: "Ajuste Rápido",
	t461: "Calle",
	t462: "Ciudad",
	t463: "Tel.",
	t464: "Región",
	t466: "Indice",
	t469: "Libreta Direcc. exportada con éxtio",
	t470: "Borrar elemento",
	t471: "Medir",
	t472: "Nota: Se distingue mayus/minus",
	t473: "En línea",
	t474: "Salidas Relé",
	t475: "Entr. Analógicas",
	t476: "Entr. Digitales",
	t477: "Cálculo",
	t478: "Ajustar a hora actual",
	t479: "Rack",
	t480: "Sin Compresores",
	t481: "Circuito",
	t482: "Evaporador",
	t483: "Desconoc",
	t484: "tipo error:",
	t485: "Añadir",
	t486: "Copia",
	t487: "Quitar",
	t488: "Control Evaporador",
	t489: "Tipo Evaporador",
	t490: "LON",
	t491: "Meta cantidad a copiar",
	t492: "sin nombre",
	t493: "Configurando la vista de Refrigeracion",
	t494: "Error Config.:",
	t495: "Por favor verifique la configuración",
	t496: "El ajuste de la Planta de Refrigeración se ha completado con éxito. Presione OK para salir de este Asistente",
	t497: "Estado Configuración",
	t498: "Vista Final Planta Refrig.",
	t500: "Código #",
	t501: "Planta",
	t502: "Ajuste Sistema",
	t503: "Licencias",
	t504: "Usuarios",
	t505: "No autorizado",
	t506: "Por favor verifique",
	t507: "Esta seguro que quiere salir de esta pantalla? Los ajustes se perderán",
	t508: "Escaneando red de controles",
	t509: "Escaneando",
	t510: "Verificando",
	t511: "AK-I/O (E/S)",
	t512: "Re-escanear",
	t513: "Incluir program. Offline",
	t514: "Bus Dispositivos",
	t515: "Disposit. Conectados",
	t516: "Programacion Offline",
	t517: "Direcc. duplicada",
	t518: "Controles Compresor",
	t519: "Grupo Asp.",
	t520: "N/A",
	t521: "Servicios Disponibles",
	t522: "Permitir crear servicios multi-evap.",
	t523: "Meta Dispositv.",
	t524: "Meta un Servicio",
	t525: "Servicio Multi-Evap",
	t526: "Cargando Tablas",
	t527: "Pidiendo Alarmas para",
	t528: "Ficheros de Grupo de Historicos",
	t529: "Fich. datos históricos",
	t530: "Todos los fich.",
	t531: "Mostrar Símbolos",
	t532: "Mostrar Curvas",
	t533: "No se han recogido sufic. datos hist.",
	t534: "Recibir los últimos datos históricos",
	t535: "Medidas/Ajustes",
	t536: "Volcando ahora",
	t537: "muestras históricas",
	t538: "Recibiendo",
	t539: "Puntos...",
	t540: "Intervalo",
	t541: "Min",
	t542: "máximo",
	t543: "Media",
	t544: "Ir a la ventana alarmas ppal.",
	t545: "Parar Entr.Usuario Auto",
	t546: "Conectado a",
	t547: "Cancelar Entr. auutomatica",
	t548: "Por favor meta el passw de cierre de sesión",
	t549: "Web 1",
	t550: "Web 2",
	t551: "Iniciar sesión automáticamente",
	t552: "Salir desp. de tiempo inactivo",
	t553: "Pantalla Inicio",
	t554: "Mostrar Menu Configuración",
	t555: "Mostrar puntos Varios en Panel Control",
	t556: "Usar Pantalla Completa",
	t557: "Habilitar sonido alarmas",
	t558: "Permitir cerrar/minimizar",
	t559: "Error accediendo a unidad#",
	t560: "Conectado a la red",
	t561: "Entrada automatica cancelada",
	t562: "Intentando conectar a",
	t563: "Salir desde",
	t564: "Conectado",
	t565: "Formato Hora",
	t566: "Form. Fecha",
	t567: "Presión",
	t568: "Nivel Ilumin",
	t569: "SubEnfria",
	t570: "SubEnfri",
	t571: "Diario",
	t572: "Semanal",
	t573: "Archivo HST",
	t574: "CSV File",
	t575: "Domingo",
	t576: "Lunes",
	t577: "Martes",
	t578: "Miercoles",
	t579: "Jueves",
	t580: "Viernes",
	t581: "Sábado",
	t582: "Colección de registros de historial",
	t583: "Habilitar la recopilación automática",
	t584: "Cuándo recoger",
	t585: "Ubicación de guardado de archivos",
	t586: "Seleccione el formato de archivo",
	t587: "Seleccione la carpeta para almacenar los archivos de historial...",
	t588: "Habilite la recopilación automática de historial para recuperar y guardar registros de historial diarios o semanales de los puntos de historial configurados de la tienda.",
	t589: "Para ver los archivos de historial de HST, vaya a la pantalla principal del historial y seleccione el historial de importación. Para ver archivos CSV, utilice cualquier aplicación de hoja de cálculo.",
	t590: "Si la dirección IP de la conexión actual se encuentra en la libreta de direcciones, se guardarán los puntos de historial seleccionados.",
	t591: "Seleccionar puntos de datos",
	t592: "Todos los seleccionados",
	t593: "Puntos seleccionados",
	t594: "Búsqueda",
	t595: "Conexión automática a",
	t596: "Por favor, verifique",
	t597: "La recopilación automática de historial está habilitada. ¿Está seguro de que desea cerrar este programa?",
	t598: "¿Está seguro de que desea cerrar este programa?",
	t599: "Acción",
	t600: "Filtrar",
	t601: "Todas las acciones",
	t602: "Más información...",
	t603: "Editable",
	t604: "Operación",
	t605: "Operacion Cancelada",
	t606: "Volcado de la Base de Datos finalizado",
	t607: "Base de Datos actualizada",
	t608: "Fichero(s) transferidos a AK-SM con éxito.",
	t609: "Volcado finalizado, el fichero se esta procesando en la unidad.",
	t610: "Ha ocurrido un error al cargar fich al PC, revise si el fichero es accesible y se puede escribir",
	t611: "Cargando Fichero",
	t612: "Volcando Fichero",
	t613: "Cargando fichero(s) seleccionado(s)",
	t614: "Volcando fichero(s) seleccionado(s)",
	t615: "Borrando fichero",
	t616: "Borrando fichero seleccionado",
	t617: "Error conexion FTP",
	t618: "Volcar ficheros seleccionado(s) a AK-SM",
	t619: "Ha ocurrido un error en la conexion FTP, revise autorizacion FTP y direccion IP.",
	t620: "FTP conectado",
	t621: "Bytes",
	t622: "Los ficheros en la Unidad seran sobreescritos, ¿Quiere continuar?",
	t623: "Los ficheros en el PC seran sobreescritos, ¿Quiere continuar?",
	t624: "Los ficheros se han volcado a la unidad.",
	t625: "Sólo REFRIGERACION",
	t626: "Tienda Conveniencia",
	t627: "TODAS las Funciones",
	t628: "Solo CLIMA",
	t629: "El nombre del fichero no es válido, por favor asegúrese de que el nombre del fichero tiene menos de 8 caracteres.",
	t630: "Sobreescribir ahora la Aplicacion?",
	t631: "Despues",
	t632: "cargado",
	t633: "tiene que elegir SI para sobreescribir la aplicarion o elegir Despues",
	t634: "Por favor seleccione el Directorio raiz del simulador",
	t635: "Por favor seleccione el Directorio Windows",
	t636: "Por favor seleccione el Directorio Destino",
	t637: "Seleccione el directorio de Bases de Datos",
	t638: "FTP desconectado",
	t639: "Tiempo conexion FTP agotado, FTP desconectado",
	t640: "Cargar fich seleccionado al PC",
	t641: "Borrar Fich seleccionado",
	t642: "Puerto FTP",
	t643: "Puerto Serv. WEB",
	t644: "Directorio Local (PC)",
	t645: "Arriba",
	t646: "Tamaño",
	t647: "Fecha",
	t648: "Conectar",
	t649: "Desconectar",
	t650: "Dir. Remoto (CS)",
	t651: "Espacio libre disponible:",
	t652: "Fich. Seleccionados",
	t653: "Archivos",
	t654: "Tamaño Total:",
	t655: "Haga clic para cambiar la carpeta...",
	t656: "Cambiar...",
	t657: "Quiere borrar el fichero(s) seleccionado(s) ?",
	t658: "Conexion cerrada, Salir",
	t659: "Los nombre(s) de fichero(s) seleccionados son ilegales y el volcado será ignorado.",
	t660: "Algunos de los nombres de fich son ilegales e ignorados.",
	t661: "Fichero(s) transferidos al PC con éxito.",
	t662: "No hay suficiente espacio libre en la Unidad",
	t663: "Transfer file(s)",
	t664: "Modificado",
	t665: "Copiar ficheros desde el disco Flash a memoria RAM",
	t666: "Por favor seleccione una operación de Copia de Ficheros",
	t667: "Copiar todos los archivos EDF configurados desde el disco Flash",
	t668: "Copiar TODOS los ficheros EDF desde el disco Flash",
	t669: "Los archivos edf/ed3 se almacenan en el disco flash de la unidad, pero no se cargan en la pantalla Administración de archivos.  Utilice las siguientes opciones para copiar los archivos EDF almacenados en su unidad en la pantalla Administración de archivos",
	t670: "Esta operacion copia todos los ficheros EDF que se han configurado en la unidad desde el disco Flash al disco RAM",
	t671: "Esta operacion copia todos los ficheros EDF en la unidad desde el disco Flash al disco RAM",
	t672: "No existe ningún archivo EDF válido",
	t673: "Copia de archivo EDF terminada",
	t674: "archivos copiados",
	t675: "Confirmar la descarga de la base de datos",
	t676: "Direcc.Unidad",
	t677: "Dirección IP",
	t678: "Nombre fich Base Datos",
	t679: "Seleccione",
	t680: "Actualizacion completada, reseteando y actualizando la unidad. Esto puede llevar unos minutos.",
	t681: "Bienvenido al gestor de Actualizaciones, paso 1 de 2",
	t682: "Bienvenido al gestor de Actualizaciones, paso 2 de 2",
	t683: "Proceder",
	t684: "Comprobación Previa",
	t685: "Localizar Firmware",
	t686: "¿Copiar Base de Datos?",
	t687: "Copiando Base Datos",
	t688: "Transfiriendo Software",
	t689: "Sobreescribiendo Software",
	t690: "Seleccione SW para actualizar",
	t691: "No se ha detectado aplicacion anti-fallos de fábrica",
	t692: "Puede continuar, pero cualquier fallo durante el proceso de actualización puede hacer es sistema no operativo.",
	t693: "Se recomienda no continuar.",
	t694: "¿Quiere continuar?",
	t695: "Actualizar de forma remota conlleva un riesgo de fallo, que puede ocasionar un error en la unidad.",
	t696: "Advertencia",
	t697: "Actualizacion de Base Datos terminada.",
	t698: "Copia Seg. de Base Datos terminada.",
	t699: "Volcado de aplicación terminada.",
	t700: "Sobre escritura de aplicacion terminada.",
	t701: "Actualizacion de software con éxito.",
	t702: "¿Quiere resetear esta unidad a ajustes de fábrica?",
	t703: "Revisando Hardware",
	t704: "Revisando estado de la Unidad",
	t705: "Seguridad anti-fallos",
	t706: "Version de Firmware actual",
	t707: "Iniciar actualización",
	t708: "Seleccione un fichero de Base Datos",
	t709: "Seleccione un fichero de Firmware",
	t710: "Atrás",
	t711: "Localizar Firmware",
	t712: "Seleccione fichero .csi",
	t713: "Seleccione fichero .mai",
	t714: "Esta placa de CPU no admite la descarga remota.",
	t715: "Fallo en la apliacación de volcado!",
	t716: "Fallo al cargar Base Datos, Intentelo de nuevo",
	t717: "Sobre escritura de aplicacion terminada.",
	t718: "Fallo al sobre-escribir, por favor intentelo de nuevo!",
	t719: "Fallo al sobre-escribir !",
	t720: "No se ha detectado aplicacion anti-fallos de fábrica",
	t721: "Hecho !",
	t722: "La aplicación seleccionada puede tener una version diferente que el firmware de la Unidad; continuar con esta operacion conlleva un riesgo de fallo y error de Sistema.",
	t723: "Los nombre(s) de fichero(s) seleccionados son ilegales y el volcado será ignorado.",
	t724: "Algunos de los nombres de fich son ilegales e ignorados.",
	t725: "Falta el pin berg en la Unidad",
	t726: "La bateria de la unidad esta baja.",
	t727: "Fallo en la red host de la Unidad",
	t728: "La Unidad esta en modo de Deslastrado",
	t729: "Seleccione fichero destino",
	t730: "Seleccione directorio de destino.",
	t731: "Actualizacion de Base Datos terminada.",
	t732: "Estado OK",
	t733: "Estado de Error",
	t734: "Estado Desconocido",
	t735: "Esta operación sobreescribirá los ficheros seleccionados en el simulador Destino, quiere continuar?",
	t736: "Actualizacion Base Datos finalizada, necesita resetear el sistema",
	t737: "Falolo al actualizar Base Datos, intentelo mas tarde.",
	t738: "La unidad se reseteará automaticamente despues de esta operacion, ¿quiere continuar?",
	t739: "Seleccione Base Datos",
	t740: "Volcar fichero de Base Datos?",
	t741: "Cargar fichero de Base Datos?",
	t742: "Cargando Base Datos al AK-SM, espere",
	t743: "Base Datos cargada con éxito, reseteando la Unidad",
	t744: "Base Datos actualizada, Unidad reseteada con exito.",
	t745: "Fallo actualizando Base Datos! Intentelo de nuevo",
	t746: "Error al actualizar la Base Datos",
	t747: "Comprobacion pre-volcado",
	t748: "Localizar Base Datos",
	t749: "Volcando Base Datos",
	t750: "Información Base Datos actual",
	t751: "Seleccione Base Datos para Volcar",
	t752: "Guardar Base datos al PC",
	t753: "Volcar Base Datos a AK-SM",
	t754: "Necesita autorizacion de Supervisor",
	t755: "Comenzar Carga",
	t756: "Por favor seleccione una Operacion de Base Datos",
	t757: "Comenzar Volcado",
	t758: "Version de Firmware actual",
	t760: "Base Datos volcada con éxito.",
	t761: "Bienvenido al Gestor de Bases de Datos, paso 1 de 2",
	t762: "Bienvenido al Gestor de Bases de Datos, paso 2 de 2",
	t763: "Fallo al obtener Lista Usuarios, revise IP/URL del Servidor",
	t764: "Actualizar de forma remota una Base de Datos conlleva un riesgo de fallo, que puede derivar en un fallo del sistema.",
	t765: "Descarga de bases de datos terminada",
	t766: "Escaneo Ethernet",
	t767: "Introduzca la primera dirección IP y, a continuación, pulse Start",
	t768: "Escanear",
	t769: "Escaneando",
	t770: "Colección de registros de historial",
	t771: "Colores de gráficos analógicos",
	t772: "Colores de gráficos digitales",
	t773: "Horarios de cierre",
	t774: "Resumen de descongelación",
	t775: "Horarios Ajuste Nocturn",
	t776: "Horarios de iluminación de casos",
	t777: "Estado de carga",
	t778: "Colección de registros de alarmas",
	t779: "Seleccione la carpeta para almacenar los archivos de alarma...",
	t780: "Mensual",
	t781: "Habilite la recopilación automática de alarmas para recuperar y guardar la información de alarma en un archivo de forma periódica.",
	t782: "Separador CSV",
	t783: "Sin alarmas",
	t784: "Recuperación de alarmas para la unidad:",
	t785: "Recuperar los detalles de alarma de la unidad:",
	t786: "La recopilación automática de alarmas está activada. ¿Está seguro de que desea cerrar este programa?",
	t787: "CadaHora",
	t788: "Esto actualizará la base de datos de la unidad y establecerá la ubicación en el centro de la vista actual. ¿Proceder?",
	t789: "muestras procesadas",
	t790: "Configure un grupo de succión.",
	t791: "Para volver a utilizar el Asistente para diseño más de una vez, es necesario restablecer el diseño de la base de datos de refrigeración.",
	t792: "Do this via the Configuration-&gt;Control page and set the number of Racks/Packs to zero.",
	t793: "Nota: cualquier programación, cálculo o importación basada en la configuración de refrigeración previa tendrá que ser reconfigurada.",
	t794: "Copiar proceso terminado.",
	t795: "Informe de refrigeración",
	t796: "Diseño final de HVAC",
	t797: "La configuración del diseño de HVAC se ha completado correctamente. Pulse el botón ok para salir de este asistente.",
	t798: "Configuración del diseño de HVAC...",
	t799: "Para volver a utilizar el Asistente de diseño de HVAC más de una vez, es necesario restablecer el diseño de la base de datos hvac.",
	t800: "Do this via the Configuration-&gt;Control page and set the number of HVAC units to zero.",
	t801: "Nota: cualquier programación, cálculo o importación basada en la configuración anterior de HVAC tendrá que ser reconfigurada.",
	t802: "Patrón de etapas (requiere reinicio de la unidad para activar)",
	t803: "Etapa",
	t804: "Nivel",
	t805: "Mostrar información sobre datos múltiples",
	t806: "Número de pasos",
	t807: "No se pudo guardar el archivo.",
	t808: "Modelo incorrecto.",
	t809: "Carga correctamente.",
	t810: "Abierto",
	t811: "Esta característica solo está disponible en la versión de escritorio de StoreView.",
	t812: "Continuar",
	t813: "Abandonar Asistente",
	t814: "La operación tuvo éxito. ¿Desea continuar o desea dejar el asistente.",
	t815: "Error al capturar datos del registrador de alarmas.",
	t817: "Nombre Dispositivo",
	t819: "En curso...",
	t820: "Los datos de alarma no están presentes.",
	t821: "Contador de Alarmas",
	t822: "Opciones de visualización de Mimic",
	t823: "Mostrar solo texto",
	t824: "Icono de solo visualización",
	t825: "Mostrar texto e icono",
	t826: "1 Semana",
	t827: "2 semanas",
	t828: "3 semanas",
	t829: "4 semanas",
	t830: "Configuración gráfica",
	t831: "No se puede mostrar el diagnóstico de alarma, compruebe la recopilación automática de alarmas en las preferencias",
	t832: "No se permite el reordenamiento con racks de E/S o circuitos incluidos en la configuración.",
	t833: "Esta dirección IP/URL del host ya está presente en la libreta de direcciones. ¿Te gusta actualizar esa entrada?",
	t834: "Navega",
	t835: "Seleccione Carpeta de libreta de direcciones",
	t836: "Ubicación",
	t837: "La ubicación seleccionada no tiene ningún contenido de la libreta de direcciones. ¿Desea exportar el contenido de la libreta de direcciones existente a la ubicación seleccionada?",
	t838: "Alarm Action Codes",
	t839: "El archivo de la libreta de direcciones no se encuentra en la ruta seleccionada. La libreta de direcciones se carga desde la zona...",
	t840: "Seleccione la ubicación para guardar los registros.",
	t841: "Seleccione una ubicación válida para guardar los registros.",
	t842: "La recopilación de registros de alarma está en curso...",
	t843: "La recopilación de registros de historial está en curso...",
	t844: "Ejecutar en segundo plano",
	t845: "Progreso del registro de alarmas",
	t846: "La dirección IP/URL del host no debe estar en blanco",
	t847: "El campo De dirección no debe estar en blanco",
	t849: "No tiene la última versión del archivo, ¿le gustaría cargar datos de la última versión?",
	t850: "Conflicto de versión",
	t851: "Progreso del registro de historial",
	t852: "Los campos Nombre de usuario y Contraseña no deben estar en blanco.",
	t853: "El campo Dirección IP no debe estar en blanco.",
	t854: "El campo Dirección IP/URL del host no debe estar en blanco.",
	t855: "El nombre de usuario no debe estar en blanco.",
	t856: "La contraseña no debe estar en blanco.",
	t857: "El SM800 al que se ha conectado",
	t858: "no se ha completado el Asistente de inicio inicial. Complete esta tarea y vuelva a conectarse al SM800",
	t859: "Establecido en ahora",
	t860: "Entrada no válida",
	t861: "El texto es demasiado largo",
	t862: "Compensar",
	t863: "No se puede comunicar con las unidades de la red host",
	t864: "Por favor, verifique que las direcciones son diferentes. La configuración se actualiza cuando se pulsa el botón Guardar.",
	t865: "Seleccione un archivo de historial válido (.hst)",
	t866: "Seleccione un archivo de grupo de historial válido (.grp)",
	t867: "Color",
	t868: "Porcentaje",
	t869: "Voltaje",
	t870: "Actual",
	t871: "Energía",
	t872: "Frecuencia",
	t873: "Concentración",
	t874: "Flujo",
	t875: "Velocidad",
	t876: "Acidez",
	t877: "Por favor, seleccione los gráficos en el cajón inferior",
	t878: "Guardar PNG",
	t879: "Dibujar",
	t880: "Estado del sistema...",
	t881: "Estado del sistema y de la unidad",
	t882: "Gataway",
	t883: "Características licencias",
	t884: "Versión de software",
	t885: "Versión del módulo del navegador",
	t886: "Versión Danux",
	t887: "Dirección Mac",
	t888: "Soporte y actualizaciones",
	t889: "Nombre de la unidad",
	t890: "Reconectar",
	t891: "Conexión perdida",
	t892: "No se ha encontrado ningún equipo de tipo.",
	t893: "La versión de software utilizada actualmente no es compatible",
	t894: "Enlaces rápidos",
	t895: "Equipo",
	t896: "Gráficas",
	t897: "Utilidades",
	t898: "Reversión de software",
	t899: "Diagnóstico",
	t900: "Error",
	t901: "No se han encontrado datos de alarmas",
	t902: "Configuración",
	t903: "Nombre de la aplicación",
	t904: "Versión de la aplicación",
	t905: "Mensaje",
	t906: "Tipo de evento",
	t907: "Marca de tiempo",
	t908: "Revertir la version",
	t909: "Versión actual",
	t910: "Software",
	t911: "Revertir…",
	t912: "Seleccione el equipo de la lista",
	t913: "Vista de calendario",
	t914: "Se ha producido un error al cargar dispositivos, inténtelo de nuevo.",
	t915: "Se ha producido un error al cargar las programaciones, inténtelo de nuevo.",
	t916: "No se ha encontrado ningún equipo",
	t917: "Horarios Exportar",
	t918: "Elija el tipo de exportación CSV o PDF",
	t919: "Exportar como CSV",
	t920: "Preparación de PDF",
	t921: "Exportar en formato PDF",
	t922: "Proporcione una IP en este formato xx.xx.xx.xx[:xxxx]",
	t923: "Nombre de usuario",
	t924: "Iniciar sesión",
	t925: "Revertir",
	t926: "Revertir software SM800A",
	t927: "Esta acción revertirá todo el software a las versiones enumeradas.",
	t928: "Editor de dispositivos 3rd Party",
	t929: "algún texto que explica la característica",
	t930: "Actualización CDF",
	t931: "Dispositivo de mapa",
	t932: "Archivo de dispositivo",
	t933: "Número de registro basado en 1",
	t934: "Tipo de almacenamiento",
	t935: "Min",
	t936: "máximo",
	t937: "Sólo lectura",
	t938: "Registro",
	t939: "Tipo de mensaje",
	t940: "Palabra clave de conversión",
	t941: "Parámetros",
	t942: "Addr.",
	t943: "Prioridad",
	t944: "Bit",
	t945: "Negado",
	t946: "Nuevo archivo",
	t947: "Abrir archivo",
	t948: "Generar archivo",
	t949: "Grupo",
	t950: "Versión del producto",
	t951: "Error ocurrido",
	t952: "No encontrado",
	t953: "Este dispositivo no se encontró",
	t954: "Temperatura exterior.",
	t955: "No se ha encontrado ningún equipo",
	t956: "Cases",
	t957: "Alarma silenciada/rec con éxito",
	t958: "Fallo al silenciar/rec esta alarma",
	t959: "Se ha producido un error al cargar la(s) unidad(es), inténtelo de nuevo.",
	t960: "Sin equipo",
	t961: "No se ha encontrado ningún equipo de tipo",
	t962: "Configurar el administrador del sistema",
	t963: "Dispositivo",
	t964: "Deshacer",
	t965: "Ha cambiado el valor de campo, ¿quiere guardar los cambios?",
	t966: "Borrar los puntos de historial seleccionados",
	t967: "Shr {{number}}",
	t968: "Veloc Bomba Hr",
	t969: "Sd",
	t970: "V3hr",
	t971: "Referencia Hr",
	t972: "Stw {{number}}",
	t973: "Referencia Tw",
	t974: "Veloc Bomba Tw",
	t975: "V3tw",
	t976: "Trec",
	t977: "Prec",
	t978: "%Apert Vrec",
	t979: "Referencia Prec",
	t980: "Referencia Trec",
	t981: "Pc",
	t982: "Ss",
	t983: "%Apert Vhp",
	t984: "Referencia Pgc",
	t985: "%Apert Eyector",
	t986: "Pgc",
	t987: "Shp",
	t988: "Sgc",
	t989: "Sc3 (ext.)",
	t990: "Sgc Control",
	t991: "Capacidad Cond.",
	t992: "Po",
	t993: "Ss",
	t994: "Sd",
	t995: "Pc",
	t996: "Capacidad en funcionamiento",
	t997: "certificado del navegador",
	t998: "Cerrar sesión",
	t1000: "Ordenando solo en {{count}}",
	t1001: "Cargado {{count}} de {{total}} alarmas",
	t1002: "Reconocimiento exitoso para todas las alarmas",
	t1003: "Reconocimiento fallido para todas las alarmas",
	t1004: "Reconocido solo {{count}} de {{total}} alarmas",
	t1005: "Exportación fallida para todas las alarmas",
	t1006: "Exportación exitosa",
	t1007: "Exportación fallida, se requiere al menos un tipo de alarma",
	t2000: "-",
	t2001: "La respuesta es demasiado grande para el búfer de transmisión XML que es de 32 K bytes",
	t2002: "No hay atributos asociados con el elemento cmd o el comando no tiene el elemento \"cmd\" o no pudo escribir en una variable o no pudo leer una variable o no pudo leer un punto o no pudo escribir el modo de operación de salida de relé o entrada on/off",
	t2003: "Entrada de comando incorrecta",
	t2004: "Error en los parámetros de publicación de CGI",
	t2005: "Error al convertir la respuesta XML de su representación interna a la cadena XML de respuesta",
	t2006: "Error al decodificar la cadena de URL",
	t2007: "Desbordamiento de memoria",
	t2008: "La compresión de datos de respuesta falló",
	t2009: "Solicitud de análisis de error",
	t2010: "Error de acceso a datos: una serie de causas que no se resuelven más",
	t2011: "Valor de atributo de acción de comando no definido El valor de atributo no es válido",
	t2012: "Error en Número de Referencia de Alarma o Alarma no configurada",
	t2013: "La cadena del parámetro cgi de entrada es demasiado larga.",
	t2014: "No se encuentra la dirección del dispositivo",
	t2015: "Error en la autorización para escribir",
	t2016: "Sin historial configurado",
	t2017: "No se devolvieron datos de historial, puede ser un error de tiempo",
	t2018: "Crear archivos de errores",
	t2019: "Leer errores de archivo",
	t2020: "Se ha superado el tamaño máximo de la lista de dispositivos",
	t2021: "no utilizado",
	t2022: "Índice de historial no válido",
	t2023: "No se pudo encontrar el índice de puntos del tablero de tipo de nodo, nodo, mod y punto.",
	t2024: "No se pudo encontrar el índice de historial",
	t2025: "La consulta de historial no se ha inicializado antes de intentar iniciar una consulta de historial.",
	t2026: "Se ha intentado un hq_start_query mientras una consulta de historial está activa",
	t2027: "No se encontraron atributos de comando cuando se esperaban algunos",
	t2028: "Se ha proporcionado un comienzo de época sin fin o viceversa.",
	t2029: "No se pudo convertir del formato de fecha y hora a segundos de época",
	t2030: "El comando requiere un query_id pero no se proporciona ninguno",
	t2031: "Error al generar la respuesta XML",
	t2032: "El query_id proporcionado no coincide con una consulta activa",
	t2033: "Se ha intentado un comando hq_get_data pero los datos aún no están disponibles",
	t2034: "Se ha proporcionado una frecuencia de muestreo que no es una de las permitidas",
	t2035: "El intervalo de promedio es menor o igual que la frecuencia de muestreo a la que se registraron los datos",
	t2036: "El tiempo de la época de parada es menor que el tiempo de la época de inicio o el tiempo de la época de parada menos el tiempo de la época de inicio es menor que el intervalo promedio",
	t2037: "El device_id proporcionado no coincide con ninguno actualmente conocido por el ak255. Tenga en cuenta que solo los dispositivos que se han configurado son conocidos por el ak255",
	t2038: "Sin atributos de identificación",
	t2039: "Atributos de identificación no válidos",
	t2040: "Sin número de horario",
	t2041: "Número de horario no válido",
	t2042: "Sin detalles de horario",
	t2043: "Falta on_time",
	t2044: "Hora que falta",
	t2045: "Hora inválida",
	t2046: "Minuto que falta",
	t2047: "Minuto inválido",
	t2048: "Falta tiempo_apagado",
	t2049: "Días de la semana que faltan",
	t2050: "Días de la semana no válidos",
	t2051: "vacaciones faltantes",
	t2052: "Días festivos no válidos",
	t2053: "Holiday_start faltante o no válido",
	t2054: "Holiday_end faltante o no válido",
	t2055: "Holiday_open faltante o no válido",
	t2056: "Holiday_close faltante o no válido",
	t2057: "Vacaciones no configuradas",
	t2058: "Falta la descripción",
	t2059: "No se puede borrar la alarma",
	t2060: "Store_open faltante o no válido",
	t2061: "Tienda faltante o no válida_cerrada",
	t2062: "Alarma no configurada",
	t2063: "Nodo fuera de línea",
	t2064: "Índice faltante",
	t2065: "Índice no válido",
	t2066: "El relé no se puede sincronizar",
	t2067: "El nombre que se está cambiando tiene demasiados caracteres. Si el cambio de store_name o unit_name debe tener < = 16 caracteres, si se cambia storeId1 o storeId2, debe tener < = 8 caracteres.",
	t2068: "Faltan elementos asociados con el elemento de comando.",
	t2069: "Tag_ID no definido",
	t2070: "Nodo hoja faltante para datos",
	t2071: "Sin cambios en los datos",
	t2072: "No se pudo actualizar la base de datos",
	t2073: "no se puede escribir",
	t2074: "Error de medidor de cobertizo",
	t2075: "sin metros",
	t2076: "Nivel de cobertizo no válido",
	t2077: "tipo inválido",
	t2078: "Sistema ocupado",
	t2079: "Activos no configurados para deslastre de carga",
	t2080: "Clasificación de potencia no válida para el activo",
	t2081: "Retraso de inicio no válido para activo",
	t2082: "Configuración ocupada",
	t2083: "Exploración de E/S en curso",
	t2084: "Demasiados descongelamientos en curso",
	t2085: "Intentando cargar una versión de base de datos incorrecta en la unidad",
	t2086: "La carga de la base de datos falló",
	t2087: "No hay memoria web disponible disponible",
	t2088: "Respuesta a la demanda no configurada (Solo el sistema AK355)",
	t2089: "Tamaño de archivo faltante o incorrecto.",
	t2090: "Archivo codificado faltante (elementos b64)",
	t2091: "No se pudo guardar la base de datos en el disco RAM.",
	t2092: "Tamaño de archivo demasiado grande.",
	t2093: "Falta el nombre del archivo.",
	t2094: "Falta la identificación del archivo.",
	t2095: "Falta el índice de carga del archivo (Nota: este es el índice de secuencia para load_file load_database).",
	t2096: "El ID del archivo no coincide o falta.",
	t2097: "La operación de carga de archivos ya está en curso.",
	t2098: "El archivo decodificado no coincide con el tamaño del archivo original.",
	t2099: "La operación de carga de archivos se canceló o no se inició.",
	t2100: "Solicitud de usuario no válida (el solicitante original no coincide con la dirección IP)",
	t2101: "Secuencia de carga de archivos no válida.",
	t2102: "Directorio invalido",
	t2103: "Extensión no válida",
	t2104: "Compensación no válida",
	t2105: "Archivo de codificación de error",
	t2106: "Error al decodificar el archivo",
	t2107: "No se pudo abrir el archivo para lectura/escritura",
	t2108: "Error al escribir en el archivo",
	t2109: "El archivo está protegido contra escritura en el sistema de archivos Flash",
	t2110: "No se pudo eliminar el archivo.",
	t2111: "No se puede eliminar la carpeta.",
	t2112: "Dispositivos faltantes",
	t2113: "No se encontraron todos los dispositivos de la URL",
	t2114: "Faltan parámetros de historial",
	t2115: "No se encontraron todos los parámetros de historial de la URL",
	t2200: "No se recomienda la versión de software utilizada actualmente ( {{currentVersion}} )",
	t2201: "No se recomienda la versión de software utilizada actualmente ( {{currentVersion}} ) ( > {{recommendedVersion}} recomendado)",
	t2202: "Error de versión de software",
	t2203: "Editor Gráficos",
	t2204: "Editar",
	t2205: "Dibujar",
	t2206: "Nueva vista",
	t2207: "Creado",
	t2208: "Soltar",
	t2209: "Nuevo Gráfico",
	t2210: "Crear una nueva vista gráfica",
	t2211: "Nombre",
	t2212: "Renombrar",
	t2214: "Exportar",
	t2213: "Duplicar",
	t2215: "Borrar",
	t2216: "¿Está seguro de que desea eliminar la vista \" {{name}} \"?",
	t2217: "Exportar todas las vistas",
	t2218: "Más...",
	t2219: "Regla",
	t2220: "normas",
	t2221: "Nombre de la regla",
	t2222: "Panel de edición",
	t2223: "Condiciones",
	t2224: "Degradado",
	t2225: "Invertir condiciones",
	t2226: "Asignaciones",
	t2227: "Mapear formas por",
	t2228: "ID de forma",
	t2229: "estados",
	t2230: "Agregar mapeo",
	t2231: "Añadir regla",
	t2232: "Operador",
	t2233: "Agregar condición",
	t2234: "Comience agregando reglas",
	t2235: "¿Está seguro de que desea eliminar la regla \" {{name}} \"?",
	t2236: "Se hace una copia de la regla.",
	t2237: "Vista Gráfica",
	t2238: "Tienes que crear vistas del sitio para verlas.",
	t2239: "Ir al editor gráfico",
	t2240: "ir a detalles",
	t2241: "Seleccionar parámetro",
	t2242: "No se encontraron parámetros",
	t2243: "Opciones",
	t2244: "Entrada de encendido/apagado",
	t2245: "Salida de relé",
	t2246: "Entrada del sensor",
	t2247: "Salida variable",
	t2248: "Dispositivo genérico",
	t2249: "Nodo vacío",
	t2250: "No se admite el registro de historial...",
	t2251: "Re-intentar",
	t2252: "No se pudo recuperar el historial",
	t2253: "El historial de carga puede demorar un tiempo y no admite varias consultas al mismo tiempo, vuelva a intentar esta solicitud.",
	t2254: "Nombre del parámetro",
	t2255: "Nombre del dispositivo",
	t2256: "Tipo de dispositivo",
	t2257: "No se pudieron cargar todos los parámetros del historial (falló {{n}} )",
	t2258: "Nombre de usuario o contraseña incorrectos",
	t2259: "Sin comunicación con el controlador",
	t2260: "Actualización de software",
	t2261: "Sistema",
	t2262: "Reversión de software",
	t2263: "Instalar en pc",
	t2264: "La reversión del software reemplazará una versión más nueva",
	t2265: "Revertir el software a la versión de copia de seguridad {{version}}",
	t2266: "Base de datos",
	t2267: "Hay una actualización disponible",
	t2268: "Exportar base de datos",
	t2269: "Descargar",
	t2270: "Descargando base de datos",
	t2271: "No se puede exportar la base de datos",
	t2272: "archivo de base de datos",
	t2999: "Error de comunicación",
	t2998: "Error desconocido",
	t3000: "Detalles",
	t3001: "Código XML pasado aquí",
	t3002: "Asignar a",
	t3003: "Estado",
	t3004: "Gestión de software",
	t3005: "Archivo almacenado local",
	t3006: "En línea a través de Internet",
	t3007: "Asegúrese de que la energía no se interrumpa durante el proceso de actualización. Se recomienda hacer una copia de seguridad de la base de datos del sistema antes de actualizar su sistema. Después de completar las actualizaciones de software, se recomienda inspeccionar el estado operativo del sistema para confirmar el control y la operación correctos.",
	t3008: "Ningún archivo seleccionado",
	t3009: "Seleccionar archivo de programa",
	t3010: "Subiendo archivos",
	t3011: "Comprobación de seguridad",
	t3012: "Extrayendo archivo",
	t3013: "Comprobando archivo",
	t3014: "¡Falló la carga del archivo!",
	t3015: "¡El control de seguridad falló!",
	t3016: "¡Extracción fallida!",
	t3017: "¡Error al verificar el archivo!",
	t3018: "Descargando archivo: {{fileName}}",
	t3019: "¡Archivo descargado con éxito!",
	t3020: "¡Descarga fracasó!",
	t3021: "Subir archivos",
	t3022: "Descargar archivos",
	t3023: "Actualización del sistema operativo",
	t3024: "actualización de Edf",
	t3025: "Descargar archivos",
	t3026: "Lamentablemente, el valor del parámetro seleccionado no estaba disponible.",
	t3027: "Importar",
	t3028: "Se requiere un mínimo de un archivo",
	t3029: "El archivo no es válido",
	t3030: "Administrar vistas gráficas",
	t3031: "Subir",
	t3032: "Migrar",
	t3033: "Leyendo archivo(s) gráfico(s), por favor espere...",
	t3034: "Importando archivo gráfico, por favor espere...",
	t3035: "Elige gráfico",
	t3036: "Informes",
	t3037: "Importar base de datos",
	t3038: "Seleccionar archivo de base de datos",
	t3039: "Asegúrese de que la energía no se interrumpa durante el proceso de reversión. Se recomienda hacer una copia de seguridad de la base de datos del sistema antes de revertir su sistema. Después de completar la reversión del software, se recomienda inspeccionar el estado operativo del sistema para confirmar el control y la operación correctos.",
	t3040: "El administrador del sistema se reiniciará después de la actualización.",
	t3041: "Expandir",
	t3042: "Mostrar mensajes de depuración",
	t3043: "máx. caracteres permitidos -",
	t3044: "Cargue el archivo de actualización",
	t3045: "Versión actual del software",
	t3046: "Versión actual de Danux",
	t3047: "Última versión SW",
	t3048: "Última versión de Danux",
	t3049: "Realizando copia de seguridad por favor espere",
	t3050: "Instalando archivo por favor espere",
	t3051: "Instalando actualización",
	t3052: "El dispositivo se está reiniciando",
	t3053: "Reinicio del administrador del sistema",
	t3054: "El navegador se actualizará ahora",
	t3055: "Software instalado correctamente",
	t3056: "La actualización falló, ocurrió el siguiente error:",
	t3057: "Copia de seguridad de la versión actual del software",
	t3058: "Copia de seguridad de los archivos EDF actuales",
	t3059: "La vista gráfica se guarda correctamente",
	t3060: "Aplicar imagen de fondo",
	t3061: "Borrar",
	t3062: "Rehacer",
	t3063: "Tienda",
	t3064: "Ajuste Noche Evaporadores",
	t3065: "Ajuste Noche Aspiracion",
	t3066: "Versión descargada {{fileName}}",
	t3067: "Para actualizar las unidades seleccionadas, presione 'Instalar'",
	t3068: "Revertir la versión {{updateType}}",
	t3069: "Instalando",
	t3070: "Instalar la versión de reversión",
	t3071: "Ninguna de las unidades disponibles contiene ninguna versión de reversión",
	t3072: "Nueva versión disponible en línea",
	t3073: "Subir archivo para unidades seleccionadas",
	t3074: "Subir archivo para la unidad seleccionada",
	t3075: "No hay versiones de reversión disponibles para la unidad",
	t3076: "Descarga finalizada",
	t3077: "Versión actual de {{updateType}}",
	t3078: "Última versión {{updateType}}",
	t3079: "La actualización está en curso, ¿está seguro de que desea cerrar la ventana?",
	t3080: "¡Danux no está actualizado! Actualice Danux y luego vuelva a actualizar el software",
	t3081: "Las aplicaciones no se pueden degradar a esta versión",
	t3082: "Información de la aplicación",
	t3083: "Notas de lanzamiento",
	t3084: "Términos y Privacidad",
	t3085: "Licencias",
	t3086: "Vaya a configuración para establecer horarios",
	t3087: "Desafortunadamente, ocurrió un error al preparar la migración. Asegúrese de que todas las unidades estén accesibles en la red y configuradas correctamente. \n\n El siguiente error ha ocurrido:\n {{error}}",
	t3088: "Importar o migrar un gráfico existente",
	t3089: "Mostrar",
	t3090: "Nombre del activo",
	t3091: "Colapso",
	t3092: "Importación completa",
	t3093: "Seguridad de descarga",
	t3094: "Seguridad de aspiración",
	t3095: "Enganche Descarga",
	t3096: "Corte Descarga",
	t3097: "Corte de aspiración",
	t3098: "Enganche Aspiracion",
	t3099: "Capacidad",
	t3100: "No se pudieron encontrar los archivos de migración (flp, dpj o json)",
	t3101: "Archivo de análisis de tiempo de espera",
	t3102: "Archivos y direcciones de dispositivos de terceros",
	t3103: "Cuadrícula",
	t3104: "Bloquear",
	t3105: "XML",
	t3106: "Seleccione el parámetro para mostrar",
	t3107: "Parámetro del dispositivo",
	t3108: "Sin unidades",
	t3109: "No hay unidad(es) encontrada(s) o cargada(s)",
	t3110: "HACCP",
	t3111: "Controlador",
	t3112: "No hay datos de HACCP disponibles",
	t3113: "Información de la unidad",
	t3114: "Actualizaciones disponibles",
	t3115: "Ir a Software",
	t3116: "No conectado",
	t3117: "El alcance de acceso actual para esta aplicación es ' {{targetLevel}} ', el usuario proporcionado está por encima de este alcance en términos de derechos de acceso",
	t3118: "Exportación de PDF",
	t3119: "Exportación de PDF fallida",
	t3120: "En conjuntos de datos grandes, es posible que la interfaz no responda y tarde un poco en completarse",
	t3121: "Por favor seleccione horarios de la lista",
	t3122: "Vaya a configuración para establecer un horario",
	t3123: "Éxito",
	t3124: "Atención",
	t3125: "Información de la Tienda",
	t3126: "Seleccione puntos de tiempo y datos de la lista",
	t3127: "Conectando",
	t3128: "No se pudo establecer la conexión con esta tienda, comuníquese con soporte",
	t3129: "No había datos HACCP disponibles en la fecha elegida. Asegúrese de que el historial y la hora estén configurados correctamente en el menú de configuración",
	t3130: "Informe de diagnóstico",
	t3131: "Seleccione la(s) unidad(es) para exportar el informe de diagnóstico",
	t3132: "No disponible en este nivel de acceso de usuario",
	t3133: "Registro de diagnóstico",
	t3134: "no se pudo descargar el informe",
	t3135: "Fuente",
	t3136: "Tamaño de fuente",
	t3137: "No hay vistas para migrar",
	t3138: "Descargar versión de escritorio",
	t3139: "Debido a restricciones de seguridad en los navegadores modernos (",
	t3140: "RFC1918",
	t3141: ") lamentablemente no es posible conectarse a dispositivos dentro de un espacio de red privada utilizando HTTP sin cifrar. Configure su administrador de sistema para usar HTTPS o use la versión de escritorio",
	t3142: "No hay datos disponibles para el período seleccionado",
	t3143: "Configure su administrador de sistema para usar HTTPS o use la versión de escritorio.",
	t3144: "No pudimos comunicarnos con el administrador del sistema. Esto significa que su dispositivo no puede conectarse a la IP o al nombre de host del administrador del sistema, o que puede necesitar confiar manualmente en el certificado SSL utilizado en el dispositivo.",
	t3145: "Haga clic en el siguiente enlace para abrir una nueva pestaña para confiar en el certificado y luego regresar a esta pantalla.",
	t3146: "Firma",
	t3147: "DI - entradas digitales",
	t3148: "AI - entradas analógicas",
	t3149: "Ir a configuración HACCP",
	t3150: "Sin datos",
	t3151: "La copia de seguridad se guarda en el Administrador del sistema para la función de reversión",
	t3152: "Seleccionar qué mostrar en la(s) Vista(s) Gráfica",
	t3153: "unidad de activo",
	t3154: "Archivos gráficos",
	t3155: "Desafortunadamente, ocurrió un error al obtener los archivos edf y las versiones del administrador del sistema...",
	t3156: "Para seleccionar qué archivos instalar, presione el botón 'Elegir archivos'",
	t3157: "Seleccionar archivos",
	t3158: "Eliminando copia de seguridad",
	t3159: "Archivos de ensayo",
	t3160: "La copia de seguridad se guarda en el Administrador del sistema para la función de reversión",
	t3161: "Tamaño instalado",
	t3162: "nuevo tamaño",
	t3163: "Fecha de instalación",
	t3164: "nueva fecha",
	t3165: "Sensores",
	t3166: "Relés",
	t3167: "Entradas",
	t3168: "Salidas",
	t3169: "Energia",
	t3170: "Genérico",
	t3171: "Contraseña caducada, por favor introduzca nueva contraseña",
	t3172: "Nueva contraseña",
	t3173: "Confirmar Contraseña",
	t3174: "Cambia la contraseña",
	t3175: "La contraseña debe contener un mínimo de 8 caracteres (1 mayúscula, 1 minúscula, 1 número, 1 carácter especial)",
	t3176: "Las contraseñas no coinciden",
	t3177: "La contraseña no puede ser la misma que la contraseña anterior",
	t3178: "Cambio de contraseña exitoso",
	t3179: "La contraseña no se puede cambiar. Error del dispositivo",
	t3180: "Restablecimiento de contraseña",
	t3181: "Generado",
	t3182: "Nombre de la Tienda",
	t3183: "Id. de evento",
	t3184: "Tiempo",
	t3185: "Role",
	t3186: "Información del Evento",
	t3187: "Confirmar alarmas seleccionadas",
	t3188: "Reconocer todas las alarmas seleccionadas",
	t3189: "Importar base de datos a",
	t3190: "Importando base de datos",
	t3191: "El tipo de archivo debe ser .zip",
	t3192: "Seleccione Archivo",
	t3193: "Horario de apertura",
	t3194: "Días festivos",
	t3195: "Horarios de encendido/apagado",
	t3196: "Activo",
	t3197: "Usted tiene cambios no guardados. Estás seguro que quieres irte?",
	t3198: "Desafortunadamente, la migración falló. El sistema está actualmente ocupado. Por favor, inténtelo de nuevo más tarde",
	t3199: "Subir imagen ajustándose a las dimensiones actuales",
	t3200: "Reemplazar imagen de fondo",
	t3201: "Reemplace las imágenes de fondo con gráficos vectoriales escalables.",
	t3202: "El sw SvW intentará sustituir las imágenes de fondo para todos los gráficos basados en el fichero cargado. Solo se reemplazan los archivos SVG con el mismo nombre que los archivos existentes.",
	t3203: "Seleccionar archivo(s) para reemplazar",
	t3204: "Archivos de imagen",
	t3205: "no se puede procesar. El archivo tiene un formato no válido.",
	t3206: "{{amount}} archivo(s) reemplazado(s) con éxito",
	t3207: "No se pudieron reemplazar {{amount}} archivo(s)",
	t3208: "La vista no tiene fondo.",
	t3209: "Puede cargar una imagen de fondo nueva o reemplazar una existente",
	t3210: "Si está reemplazando una imagen de fondo, las posiciones de los parámetros se pueden mantener si:",
	t3211: "Un archivo png o jpg tiene la misma resolución que el fondo actual",
	t3212: "Un archivo svg tiene la misma relación de aspecto (pero no necesariamente la misma resolución)",
	t3213: "La función está deshabilitada",
	t3214: "Actualmente mostrando el último valor recibido, actualizando...",
	t3215: "Cargue un archivo gráfico creado en",
	t3216: "(opcional)",
	t3217: "Imagen de fondo",
	t3218: "Navegar",
	t3219: "Más...",
	t3220: "Añade una imagen de fondo (opcional)",
	t3221: "Archivo gráfico",
	t3222: "Tiempo de espera en la comunicación",
	t3223: "No hay horarios de refrigeración configurados",
	t3224: "Índice",
	t3225: "Tipo",
	t3226: "Días festivos",
	t3227: "Días",
	t3228: "En",
	t3229: "Apagado",
	t3230: "IDENTIFICACIÓN",
	t3231: "Abierto",
	t3232: "Inicio",
	t3233: "Final",
	t3234: "Descripción",
	t3235: "Día",
	t3236: "Cerrar",
	t3237: "Los archivos EDF se cargaron correctamente. Reinicio del administrador del sistema",
	t3238: "La carga de los archivos Edf finalizó, el administrador del sistema se restableció correctamente",
	t3239: "Error al obtener archivos edf del administrador del sistema",
	t3240: "La carga de los archivos Edf falló. Intente después de un tiempo",
	t3241: "No hay horarios de refrigeración configurados",
	t3242: "Subir archivo al administrador del sistema",
	t3243: "Especifique el tipo de dispositivo: afecta las etiquetas de parámetros requeridas para operarlo",
	t3244: "Especificar cómo se accede a los valores del dispositivo y qué función cumplen",
	t3245: "Crear una alarma basada en un bit de un parámetro",
	t3246: "Etiquetas",
	t3247: "Crear una lista de etiquetas que se pueden asignar a un parámetro",
	t3248: "Grupos",
	t3249: "Especificar grupos de parámetros",
	t3250: "Número de registro",
	t3251: "Tipo de datos",
	t3252: "Lista de etiquetas",
	t3253: "Mínimo",
	t3254: "Defecto",
	t3255: "Máximo",
	t3256: "Tipo de registro",
	t3257: "El tipo de registro se traduce a los códigos de función correspondientes para lectura y escritura.",
	t3258: "Modo de intercambio",
	t3259: "Para un valor con bytes ABCD enviados al dispositivo, esta opción intercambia bytes (BADC), palabras (CDAB) o ambos (DCBA).",
	t3260: "Solo lectura",
	t3261: "Inicio sesión",
	t3262: "Habilita el registro de historial para este parámetro",
	t3263: "Transferir texto de parámetro a alarma",
	t3264: "Posición de la bandera de alarma contando desde ellos el bit menos significativo del parámetro",
	t3265: "Activar alarma en valor bajo",
	t3266: "Etiqueta",
	t3267: "Ocultar",
	t3268: "{{field}} es un campo obligatorio.",
	t3269: "El máximo debe ser mayor o igual al mínimo",
	t3270: "El valor predeterminado debe estar en [Mínimo, Máximo]",
	t3271: "El tipo de datos debe ser {{reg}} para el tipo de registro seleccionado {{mt}}",
	t3272: "El nombre no debe contener signos delimitadores",
	t3273: "El nombre no debe exceder los 20 caracteres",
	t3274: "La lista de etiquetas no debe estar vacía",
	t3275: "La(s) etiqueta(s) {{taglist}} deben asignarse exactamente una vez.",
	t3276: "Los grupos {{grouplist}} contienen más del máximo de 50 parámetros.",
	t3277: "El formulario contiene errores, revise los errores y vuelva a intentarlo.",
	t3278: "Enviar",
	t3279: "Para activar el EDF cargado, debe reiniciar la unidad. ¿Desea reiniciar la unidad ahora?",
	t3280: "restablecer ahora",
	t3281: "restablecer más tarde",
	t3282: "{{file}} ya existe. ¿Quieres sobreescribirlo?",
	t3283: "Subir {{what}}",
	t3284: "Las etiquetas y los valores deben ser únicos.",
	t3285: "Multi",
	t3286: "El dispositivo maneja múltiples cajas o paquetes",
	t3287: "Etiquetas",
	t3288: "Internet Explorer está al final de su ciclo de vida y no es compatible con StoreviewWeb",
	t3289: "Cambie a un navegador compatible, ya sea Edge, Chrome o Firefox",
	t3290: "Más información",
	t3291: "Cerca",
	t3292: "No se han podido leer los datos, inténtalo de nuevo.",
	t3293: "Copia de seguridad de EDF en curso",
	t3294: "No hay vistas para migrar",
	t3295: "El sistema está configurado con una IP pública en el campo de IP privada",
	t3296: "El sistema está configurado con una IP privada en el campo de IP pública, es posible que no se pueda acceder al sistema de forma remota o que algunas funciones no funcionen correctamente",
	t3297: "Está conectado a través de una IP o nombre de host que el administrador del sistema no conoce, probablemente a través de un servicio de proxy, es posible que algunas funciones no funcionen correctamente",
	t3298: "El dispositivo está fuera de línea y muestra el último valor conocido",
	t3299: "Integración de dispositivos",
	t3300: "Las etiquetas {{taglist}} están duplicadas.",
	t3301: "Página no encontrada",
	t3302: "Lo sentimos, no se pudo encontrar la página solicitada",
	t3303: "Código de error: 404",
	t3304: "Ir al panel de control",
	t3305: "Sólo vista",
	t3306: "Dirección IP interna",
	t3307: "Dirección IP externa",
	t3308: "Nombre de dominio",
	t3309: "Sin advertencias",
	t3310: "Múltiples advertencias",
	t3311: "Parece que el sistema está configurado con unidades múltiples, pero no pudimos contactar con una o más de las unidades esclavas, asegúrese de que la configuración sea correcta. Tenga en cuenta que una configuración de unidades múltiples no admite varios nombres de dominio y que cada unidad debe configurarse con un puerto distinto cuando se usa el mismo dominio",
	t3312: "Sólo vista",
	t3313: "Este elemento no tiene asociación con ningún parámetro y se puede eliminar de forma segura",
	t3314: "Eliminar parámetro",
	t3315: "Calendario",
	t3316: "Ahora se cerrará la sesión y deberá iniciar sesión con la nueva contraseña",
	t759: "Unidad de descarga, reinicio y actualización de la base de datos. Esto puede tardar varios minutos.",
	t816: "Tipo de alarma:",
	t818: "Contar :",
	t848: "El archivo DB no se crea. Tiene acceso limitado al directorio instalado. Póngase en contacto con el administrador del sistema.",
	t3317: "No hay horarios para exportar",
	t3318: "Pendiente de respuesta del sistema",
	t3319: "Sin actividad en la red",
	t3320: "Nombre de la tienda",
	t3321: "Nueva versión {{latestVersion}} disponible en línea",
	t3322: "Puerto externo",
	t3323: "Puerto interno",
	t3324: "No podemos comunicarnos con el administrador del sistema en",
	t3325: "tal vez el certificado necesita ser de confianza?",
	t3326: "El archivo de este formato no se puede eliminar",
	t3327: "Uso",
	t3328: "A tiempo",
	t3329: "Fuera de tiempo",
	t3330: "Días de semana",
	t3331: "Etapa de enfriamiento",
	t3332: "Recuperación de calor",
	t3333: "Enfriamiento",
	t3334: "Calefacción",
	t3335: "Calor auxiliar",
	t3336: "Deshumidificación",
	t3337: "Internet",
	t3338: "Mensajes",
	t3339: "Contactos",
	t3340: "comunicación",
	t3341: "No se encontraron artículos",
	t3342: "Escribe para buscar",
	t3408: "Habilitar todo",
	t3409: "Desactivar todo",
	t3410: "Normal",
	t3411: "Severo",
	t3412: "Crítico",
	t3413: "Habilitar todas las alarmas",
	t3414: "Buscar alarmas",
	t3415: "Activado",
	t3417: "Error al obtener alarmas",
	t3418: "Error al obtener los dispositivos para copiar",
	t3419: "Error al comprobar el estado de la copia",
	t3420: "El sondeo automático se reanudará después de un tiempo de espera máximo de 2 horas, o si se cancela o finaliza el asistente.",
	t3421: "Copiar a los controladores",
	t3422: "Copiar a los dispositivos seleccionados",
	t3423: "La función de copia primero copiará las configuraciones a la base de datos de System Manager y luego descargará las configuraciones a los dispositivos del controlador. Puede llevar algún tiempo completar la acción.",
	t3424: "No hay dispositivos seleccionados para copiar",
	t3425: "Haga doble clic para copiar",
	t3426: "Error al buscar dispositivos",
	t3427: "Dispositivos para copiar",
	t3428: "Seleccione el(los) dispositivo(s) para copiar",
	t3429: "Aquí solo se mostrarán los controladores del mismo tipo",
	t3430: "El proceso de copia se realizó correctamente",
	t3431: "Archivo no válido. Modelo de archivo esperado:",
	t3432: "Error al cargar el archivo de configuración",
	t3435: "¿Está seguro de mover el dispositivo seleccionado a este grupo {{toPackGroup}} ?",
	t3343: "Descubrimiento",
	t3344: "Actualizar tabla",
	t3345: "Escaneando redes, por favor espere...",
	t3346: "La tabla de red se actualiza",
	t3347: "Error al actualizar redes",
	t3348: "Error al volver a escanear redes",
	t3349: "Error al obtener redes",
	t3350: "Escaneo de red completado",
	t3351: "No hay dispositivos disponibles",
	t3352: "Escanear red",
	t3353: "Deshabilitar",
	t3354: "Cualquier reorganización de los dispositivos en el asistente puede afectar los servicios de monitoreo de Alsense.",
	t3355: "Red",
	t3356: "Seleccionar el tipo de bus de campo",
	t3357: "Si está habilitado el Modbus/RS485, puede seleccionar SLV para el compresor de velocidad variable de Danfoss. Al seleccionar SNMP como bus local, los controles SNMP de Danfoss le solicitarán que ingrese un rango de direcciones de subred válido.",
	t3358: "Compresores/Paquetes",
	t3359: "Administrar controladores de compresores/paquetes",
	t3360: "Aquí solo se pueden administrar los dispositivos Pack, no el sistema de E/S de bastidor. Los controladores Pack de succión múltiple compatibles que se hayan escaneado aparecerán inicialmente como 0 grupos de succión. Se requiere la herramienta de servicio AK-ST 500 para configurar el controlador Pack.",
	t3361: "Cantidad a copiar",
	t3362: "Al reducir el grupo de succión configurado previamente también se eliminarán todos los controles de evaporación asociados para ese grupo de succión.",
	t3363: "El número de grupos de succión no puede ser cero, el número mínimo de grupos de succión debe ser al menos uno.",
	t3364: "Error al obtener compresores/paquetes",
	t3365: "No hay compresores/paquetes disponibles",
	t3366: "Dispositivos en línea",
	t3367: "Dispositivos fuera de línea",
	t3369: "Agregar paquete",
	t3370: "Si selecciona Modbus/RS485, verá opciones adicionales para SLV/ECFan, estas se utilizan para refrigeración y se pueden seleccionar como No.",
	t3371: "Agregar circuito",
	t3372: "Agregar, copiar o eliminar dispositivos. Tenga en cuenta que no todos los tipos de dispositivos permiten editar todos los campos y que las direcciones de los puntos de E/S se establecen en la configuración.",
	t3373: "Gestionar controladores de casos",
	t3374: "Agregar un dispositivo",
	t3375: "Administrar controladores de compresores/paquetes",
	t3376: "Error en el acceso a los datos",
	t3377: "El diseño de HVAC se ha aplicado correctamente",
	t3378: "Disposición del sistema HVAC",
	t3379: "¿Estás seguro que deseas abandonar esta página?",
	t3380: "Es posible que se pierda su configuración",
	t3381: "Por favor configure un dispositivo",
	t3382: "Salir de la configuración rápida",
	t3383: "¿Está seguro de que desea salir de la configuración rápida?",
	t3384: "Pasarelas (AK-PI200)",
	t3385: "Seleccione las puertas de enlace encontradas",
	t3386: "Dirección IP",
	t3387: "Controlador de fuente",
	t3388: "Configuración de origen",
	t3389: "Alarmas de origen",
	t3390: "Gráfico fuente",
	t3392: "Gráfico",
	t3393: "Dispositivos",
	t3394: "El sondeo automático se suspende durante la fase de copia. El interruptor principal de los dispositivos de origen y destino se apagará y luego volverá al estado original una vez que se complete la copia. Asegúrese de verificar el interruptor principal una vez que se complete el asistente de copia. Si alguno de los dispositivos seleccionados está en alarma activa, no se modificará ninguna modificación a la acción de alarma para ese punto en particular. La configuración original permanecerá.",
	t3395: "Seleccione un dispositivo de origen desde el cual copiar la configuración",
	t3396: "A tiempo",
	t3397: "La función de copia copiará los puntos del gráfico disponibles hasta que se alcance la capacidad de la unidad. Vaya a Configuración para ver la cantidad de puntos definidos",
	t3398: "Gráficos del dispositivo fuente",
	t3399: "Configurar puntos de gráficos para el controlador de origen",
	t3400: "Habilite HACCP para registrar los puntos en un informe de temperatura de HACCP",
	t3401: "Búsqueda de puntos",
	t3402: "No hay datos disponibles",
	t3403: "Error al obtener los puntos del gráfico",
	t3404: "Desactivado",
	t3405: "Alarmas del dispositivo fuente",
	t3406: "Configurar los detalles de la alarma en el controlador de origen",
	t3407: "Nombre de la alarma",
	t3436: "El dispositivo será eliminado del grupo asignado [ {{fromPackGroup}} ]",
	t3437: "Disposición de refrigeración",
	t3438: "El diseño de refrigeración se ha aplicado con éxito.",
	t3439: "Reasignar",
	t3440: "Asignar controles de caso a controles de paquete",
	t3441: "Asignar controles de caja (de las cajas disponibles) a controles de compresor/paquete, formando grupos de succión",
	t3442: "Parámetros del dispositivo",
	t3443: "Seleccionar parámetros para copiar",
	t3444: "Al trabajar en un dispositivo en vivo (en línea), puede haber un retraso en la configuración debido a la comunicación con el dispositivo.",
	t3445: "Seleccionar alarmas para copiar",
	t3446: "Gráfico del dispositivo",
	t3447: "Seleccionar puntos del gráfico para copiar",
	t3448: "Guardar la configuración actual del dispositivo en un archivo",
	t3449: "Puede guardar la configuración actual del dispositivo en un archivo que se puede utilizar para configurar dispositivos en una fecha posterior. Si desea salir del asistente después de guardar el archivo, utilice el icono de salida. Si trabaja con dispositivos conectados, al salir se asegurará de que el sondeo de la red y el interruptor principal del dispositivo vuelvan al estado correcto.",
	t3450: "Los archivos de configuración se guardaron correctamente",
	t3451: "¿Desea continuar o salir de la configuración rápida?",
	t3452: "Guardar archivo(s)",
	t3453: "Error al obtener la configuración",
	t3454: "Configuración del dispositivo de origen",
	t3455: "Configurar los parámetros del dispositivo en el controlador de origen",
	t3456: "Ninguno de los campos de contraseña debe estar vacío",
	t3457: "Recientes",
	t3458: "Libreta de direcciones",
	t3459: "Último acceso",
	t3460: "Realice una copia de seguridad de la libreta de direcciones periódicamente.",
	t3461: "La libreta de direcciones se importó correctamente",
	t3462: "Importar libreta Direcc.",
	t3463: "Exportar libreta Direcc.",
	t3464: "Agregar sitio",
	t3465: "Editar sitio",
	t3466: "Eliminar sitio",
	t3467: "Debe crear una contraseña para la libreta de direcciones antes de comenzar a utilizarla.",
	t3468: "El sitio se ha añadido correctamente a su libreta de direcciones",
	t3469: "Ya está en la libreta de direcciones",
	t3470: "Agregar a la libreta de direcciones",
	t3471: "Iniciar sesión en el sitio",
	t3472: "La información del sitio se ha actualizado correctamente",
	t3474: "Notificación",
	t3475: "El sitio ha sido eliminado exitosamente de su libreta de direcciones",
	t3476: "El sitio se ha actualizado correctamente",
	t3477: "Formato de libreta de direcciones no válido",
	t3478: "Comportamiento",
	t3479: "No disponible sin conexión",
	t3480: "No se pudo cargar la base de datos S55",
	t3481: "Cargando la base de datos S55",
	t3482: "Protocolo",
	t3483: "El usuario no tiene permiso para ver la información del software",
	t3484: "La contraseña debe ser alfanumérica o solo numérica",
	t3485: "Necesita crear una contraseña de libreta de direcciones para acceder a la libreta de direcciones",
	t3486: "La contraseña de la libreta de direcciones no es correcta",
	t3487: "El sitio ya está presente en la libreta de direcciones",
	t3488: "Cree una contraseña para utilizar la libreta de direcciones. La contraseña debe ser alfanumérica o numérica únicamente.",
	t3489: "Introduzca la contraseña de su libreta de direcciones",
	t3490: "No hay dispositivos en línea disponibles para copiar",
	t3491: "Crear contraseña para la libreta de direcciones",
	t3492: "Actualizar contraseña de la libreta de direcciones",
	t3493: "La contraseña del sitio no puede estar vacía",
	t3494: "Escriba una contraseña para la libreta de direcciones que se va a importar",
	t3495: "La contraseña de la libreta de direcciones se creó correctamente",
	t3496: "La contraseña de la libreta de direcciones se actualizó correctamente",
	t3497: "La contraseña de la libreta de direcciones que se está importando no es correcta",
	t3498: "Por favor, introduzca la contraseña del sitio",
	t3499: "Limpiar la libreta de direcciones",
	t3500: "Buscar sitios",
	t3501: "Seleccione el archivo de la libreta de direcciones que desea importar",
	t3502: "Seleccione el archivo de clave para descifrar la libreta de direcciones",
	t3503: "Pulse exportar para descargar la libreta de direcciones cifrada",
	t3505: "Con cifrado",
	t3506: "contraseñas sin cifrar",
	t3507: "Clave secreta no válida",
	t3508: "Error en la importación. Asegúrese de haber importado la clave correcta",
	t3509: "No hay versiones disponibles",
	t3510: "Servicios abiertos de Alsense (requiere suscripción)",
	t3511: "Lea atentamente los términos del EULA. Si no los acepta, no podrá utilizar la aplicación.",
	t3512: "La libreta de direcciones y su contraseña de la libreta de direcciones se borraron correctamente",
	t3513: "(requerido)",
	t3515: "La libreta de direcciones está vacía",
	t3516: "La contraseña de la libreta de direcciones no puede estar vacía",
	t3519: "Al borrar la libreta de direcciones también eliminará su contraseña.",
	t3520: "Fuera de rango. Debe estar entre 0 y {{maxAddr}}",
	t3521: "Pulse 'Exportar' para descargar todas las vistas gráficas almacenadas en el Administrador del sistema",
	t3522: "Pulse 'Descargar' para descargar un informe del sistema",
	t3523: "Solo la unidad principal está disponible en una conexión WiFi directa",
	t3524: "No hay vistas gráficas locales. Agregue vistas nuevas o importe las existentes",
	t3525: "No hay vistas gráficas remotas. Intente buscar con un nombre diferente",
	t3526: "No hay vistas gráficas remotas. Agregue nuevas vistas, importe o migre vistas existentes",
	t3527: "Vistas locales ( {{totalFiles}} de 5)",
	t3528: "{{totalFiles}} gráficos locales de un máximo de 5",
	t3529: "Vistas remotas",
	t3530: "El archivo no es un mapa de bits.",
	t3531: "Longitud del mapa de bits incorrecta.",
	t3532: "El tamaño del encabezado de mapa de bits es incorrecto.",
	t3533: "Tamaño de mapa de bits incorrecto.",
	t3534: "Error en el tamaño de la paleta.",
	t3535: "Archivo .bmp incompatible",
	t3536: "Error al eliminar archivo gráfico local",
	t3537: "Error al duplicar archivo gráfico local",
	t3538: "Error al exportar archivo gráfico local",
	t3539: "Eliminar gráfico",
	t3540: "Eliminar un archivo gráfico local",
	t3541: "La eliminación del archivo gráfico local está en proceso",
	t3542: "Archivo gráfico local eliminado correctamente. Reiniciando el Administrador del sistema",
	t3543: "Se ha finalizado la eliminación del archivo gráfico local. El administrador del sistema se ha reiniciado correctamente",
	t3544: "Gráfico duplicado",
	t3545: "Duplicar un archivo gráfico local",
	t3546: "Se está realizando una duplicación de archivo gráfico local",
	t3547: "El archivo gráfico local se duplicó correctamente. Reiniciando el Administrador del sistema",
	t3548: "Se completó la duplicación del archivo gráfico local. El Administrador del sistema se reinició correctamente",
	t3549: "Exportar gráfico",
	t3550: "Exportar un archivo gráfico local",
	t3551: "La exportación del archivo gráfico local está en curso",
	t3552: "Archivo gráfico local exportado con éxito",
	t3553: "El Administrador del sistema se reiniciará con esta acción",
	t3554: "¿Estás seguro de que deseas eliminar?",
	t3555: "¿Estás seguro de que deseas duplicar?",
	t3556: "Pulse 'Exportar' para descargar todas las vistas gráficas locales almacenadas en el Administrador del sistema",
	t3557: "La exportación de archivos gráficos locales está en curso",
	t3558: "Archivos gráficos locales exportados exitosamente",
	t3559: "Código del producto",
	t3560: "Obtenga más información sobre este producto",
	t3561: "Ventilador ecológico",
	t3562: "Apertura de válvula",
	t3563: "Supercalentar",
	t3564: "Presión del evaporador",
	t3565: "Temperatura del evaporador",
	t3566: "Separar",
	t3567: "Cutina",
	t3568: "Puerta de caja",
	t3569: "Temperatura de succión",
	t3570: "Calor del riel",
	t3571: "Se alcanzó el límite máximo de archivos permitido. Anule la selección de los archivos que desea importar",
	t3572: "Elimine los archivos existentes en el administrador del sistema para importar archivos",
	t3573: "El archivo gráfico local se importó correctamente. Reiniciando el Administrador del sistema",
	t3574: "Se completó la importación de los archivos gráficos locales. El Administrador del sistema se reinició correctamente",
	t3575: "Se alcanzó el límite máximo de archivos permitido. Eliminar archivo(s) para importar",
	t3576: "El máximo de archivos gráficos permitidos es 5",
	t3577: "El archivo gráfico local se cargó correctamente. Reiniciando el Administrador del sistema",
	t3578: "Se ha finalizado la carga del archivo gráfico local. El administrador del sistema se ha reiniciado correctamente",
	t3579: "Formato de archivo no válido. Cargue el archivo {{supportedFileFormats}}",
	t3580: "La dimensión del archivo debe ser {{maxWidthAllowed}} x {{maxHeightAllowed}} píxeles para pantallas locales",
	t3581: "Vista remota",
	t3582: "Vista local",
	t3583: "Página de inicio de Storeview Web",
	t3584: "Términos del EULA",
	t3585: "Seguridad",
	t3586: "Niveles de aplicación de la seguridad",
	t3587: "Compatible con versiones anteriores (nivel de seguridad más bajo, suponiendo que el uso de autenticación de encabezado está deshabilitado)",
	t3588: "Las solicitudes SvW/XML contienen nombre de usuario y contraseña.",
	t3589: "Sin desinfección de solicitudes, misma seguridad que en versiones anteriores",
	t3590: "No es necesario realizar cambios en SvW ni en las conexiones XML existentes",
	t3591: "Permisivo (se puede utilizar como transición al nivel estricto)",
	t3592: "Las solicitudes XML pueden contener un nombre de usuario y una contraseña, o un token de sesión. El token de sesión se recibe en la respuesta a la solicitud de inicio de sesión.",
	t3593: "Si el token de sesión se proporciona en el encabezado AKSM-auth, se ignoran los nombres de usuario y las contraseñas",
	t3594: "La solicitud XML se desinfecta y se devuelve una advertencia en la respuesta de la solicitud si se descubre algún problema.",
	t3595: "Tenga en cuenta estas advertencias antes de cambiar al modo estricto",
	t3596: "Si no se utiliza el token de sesión, no se puede aplicar la expiración de la sesión",
	t3597: "Ya compatible con la interfaz de usuario remota (SvW)",
	t3598: "Cambios posibles/obligatorios desde la perspectiva XML de terceros",
	t3599: "Sin uso de token de sesión: no se necesitan cambios",
	t3600: "Uso del token de sesión: elimine el nombre de usuario y la contraseña del cuerpo y observe las advertencias en preparación para Strict",
	t3601: "Estricto (nivel de seguridad más alto)",
	t3602: "Las solicitudes XML no pueden contener nombres de usuario ni contraseñas, pero deben proporcionar el token de sesión en el encabezado AKSM-auth. Si se descubre un problema en la solicitud, se rechaza.",
	t3603: "Ya soportado en (SvW)",
	t3604: "Cambios necesarios desde la perspectiva de terceros",
	t3605: "El nombre de usuario y la contraseña deben eliminarse de la carga útil de la solicitud XML y el token de sesión debe colocarse en un encabezado CORS llamado AKSM-auth",
	t3606: "Error de inicio de sesión. Retroceso",
	t3607: "Cuánto tiempo (en segundos) espera un usuario remoto para poder volver a intentar iniciar sesión después de un intento fallido. El tiempo de espera aumenta de forma acumulativa con cada intento fallido. Después de 10 intentos, la IP desde la que el usuario intentó iniciar sesión se bloquea durante 24 horas. Si se establece en 0, la interrupción por error de inicio de sesión se desactiva",
	t3608: "Predeterminado: 30 s (máximo: 600 s / 0 = deshabilitado)",
	t3609: "Intentos libres",
	t3610: "¿Cuántos intentos de inicio de sesión se permiten antes de que se aplique la suspensión?",
	t3611: "Predeterminado 2 (máximo 5)",
	t3612: "Bloquear",
	t3613: "Si está habilitado, el Usuario + IP se bloquean durante la retirada, de lo contrario, el Usuario se bloquea",
	t3614: "'Usuario' predeterminado (Usuario + IP / Usuario)",
	t3615: "Sesiones por usuario",
	t3616: "Una sesión es cuando un usuario ha iniciado sesión con éxito en la unidad y se encuentra dentro de la interfaz remota (SvW)",
	t3617: "Número de sesiones activas que puede tener un usuario. Si se establece en 0, un usuario puede tener la cantidad máxima de sesiones (30)",
	t3618: "Predeterminado 0",
	t3619: "Expiración de la sesión",
	t3620: "¿Cuántos segundos transcurren después de que expira una sesión inactiva? Si una sesión expira, se necesita iniciar sesión nuevamente.",
	t3621: "900 predeterminados (máximo 3600)",
	t3622: "Autenticación de encabezado",
	t3623: "Al habilitar HTTPS y la autenticación de encabezado, todas las solicitudes XML de terceros deben pasar credenciales de nombre de usuario y contraseña en el encabezado (esquema de autenticación básico)",
	t3624: "Para habilitar la opción \"Usar autenticación de encabezado\", es necesario reiniciar el sistema. Utilice el botón \"Presionar para inicializar\" para realizar esta acción.",
	t3625: "Pequeño",
	t3626: "Grande",
	t3627: "Guardar gráfico",
	t3628: "Guardar un archivo gráfico local",
	t3629: "El archivo gráfico local se eliminó correctamente. La carga del archivo gráfico local está en curso",
	t3630: "El archivo gráfico local se cargó correctamente. Reiniciando el Administrador del sistema",
	t3631: "Se ha finalizado el guardado del archivo gráfico local. El Administrador del sistema se ha reiniciado correctamente",
	t3632: "¿Estás seguro que deseas guardar?",
	t3633: "Error de análisis",
	t3634: "No se pudo analizar el archivo viz",
	t3635: "No se pudo analizar el archivo vz2",
	t3636: "Error al abrir el archivo Viz:",
	t3637: "No se pudieron encontrar los archivos de migración (vz2, dpj o json)",
	t3638: "No se pudieron encontrar los archivos de migración (viz)",
	t3639: "El administrador del sistema se reiniciará con esta acción. El administrador del sistema está en modo permisivo o estricto, por lo que el token de sesión caducará al reiniciarse y se cerrará la sesión del sistema.",
	t3643: "Presión de descarga",
	t3644: "kW totales",
	t3645: "KVAR total",
	t3646: "Kvarh totales+",
	t3647: "Demanda",
	t3648: "Factor de potencia",
	t3649: "Color de fuente",
	t3650: "Color de fondo",
	t3651: "La libreta de direcciones se exportó correctamente",
	t3652: "Modo de control",
	t3653: "Sc3 Temperatura exterior",
	t3654: "Temperatura de descarga",
	t3655: "Capacidad de funcionamiento del condensador",
	t3656: "No hay datos para exportar",
	t3657: "La contraseña del usuario en {{unit}} ha expirado",
	t3658: "No se puede iniciar sesión en {{unit}} con las credenciales proporcionadas.",
	t3659: "Actualice la versión del firmware o cambie el nivel de seguridad de {{unit}} para obtener soporte de token de sesión.",
	t3661: "V3gc",
	t3662: "Sección B",
	t3663: "Sección C",
	t3664: "Control adaptativo del sobrecalentamiento",
	t3665: "Razón del estado de control",
	t3666: "El usuario no puede iniciar sesión. La dirección de la unidad no está configurada en la configuración del proxy",
	t3667: "El protocolo de comunicación no está especificado en la configuración del proxy. Esto puede provocar errores.",
	t3668: "Información de seguridad informática",
	t3669: "En cumplimiento con los estándares de seguridad, el Administrador del sistema ahora tiene como valor predeterminado el control de sesión estricto, lo que significa que cualquier conexión remota o servicio remoto basado en XML ya no funcionará a menos que se apliquen tokens de sesión.",
	t3670: "Danfoss recomienda continuar en modo estricto, pero el modo de compatibilidad con versiones anteriores aún está disponible.",
	t3671: "Tenga en cuenta que esto se puede cambiar en el menú de Configuración de seguridad y no tiene impacto en el control local del Administrador del sistema ni en la operación del sitio.",
	t3672: "Ver más información sobre el control de sesiones",
	t3673: "Modo inverso",
	t3674: "Modo estricto",
	t3675: "Al hacer clic en el modo de compatibilidad con versiones anteriores, todos los administradores de sistema aplicables con firmware actualizado se configurarán en el modo de compatibilidad con versiones anteriores, se reiniciarán y se cerrará la sesión de la aplicación.",
	t3676: "El número máximo de grupos de succión permitidos es 12",
	t3677: "Al actualizar exitosamente las unidades seleccionadas de 3.3.0 a 4.X o superior, el usuario cerrará la sesión automáticamente al hacer clic en el botón Finalizar en el paso final.",
	t3678: "No se admiten estuches múltiples para controladores AK2 (paquete/estuche)",
	t3679: "De conformidad con los estándares de seguridad introducidos con la versión 4.0, el administrador del sistema utilizará de manera predeterminada el control de sesión estricto, lo que significa que cualquier conexión remota o servicio remoto basado en XML dejará de funcionar a menos que se apliquen tokens de sesión. Además de eso, la comunicación se ajustará automáticamente a HTTPS al actualizar a la versión 4.0. Danfoss recomienda continuar en modo estricto, pero está disponible el modo de compatibilidad con versiones anteriores, que restablecerá el sistema a la configuración aplicada hasta ahora. Tenga en cuenta que si está realizando esta actualización de forma remota en una unidad HTTP, asegúrese de que su configuración de TI esté preparada para HTTPS (puerto 443) o tenga personal disponible en el sitio.",
	t3680: "Encuentre más información sobre la nueva implementación de seguridad aquí",
	t3681: "El acceso a la versión http de este sitio puede estar bloqueado debido a las restricciones de su navegador. Visite nuestra página de preguntas frecuentes para obtener instrucciones sobre cómo resolver este problema o descargue la versión de escritorio de StoreView Web.",
	t3682: "Preguntas frecuentes sobre el administrador del sistema",
	t3683: "u17 Aire termal",
	t3684: "u91 Temperatura de corte.",
	t3685: "u00 Ctrl. estado",
	t3686: "u12 S3 temperatura del aire.",
	t3687: "u16 S4 temperatura del aire.",
	t3688: "% de sobredosis de EEV u23",
	t3689: "Supercaliente sub-21",
	t3690: "u22 SupercalorRef",
	t3691: "Prueba de temperatura de evaporación u26",
	t3692: "S2 Temperatura de salida de gas.",
	t3693: "Stw8",
	t3694: "SD-MT",
	t3695: "Estado de Tw",
	t3696: "Habilitar Tw",
	t3697: "Bomba Tw funcionando",
	t3698: "Stw3",
	t3699: "Stw4",
	t3700: "Stw2",
	t3701: "Estado del control de rcv.",
	t3702: "Desplazamiento de succión",
	t3703: "Modo de control Vrec",
	t3704: "Estado de Vrec",
	t3705: "Razón del estado de control",
	t3706: "Estado del control de HP",
	t3707: "Pgc máx.",
	t3708: "Pgc límite máximo P-ban",
	t3709: "Pgc mín.",
	t3710: "Capacidad del eyector de vapor",
	t3711: "Capacidad del eyector de líquido",
	t3712: "Temperatura del aire de referencia.",
	t3713: "Temperatura del aire de suministro.",
	t3714: "Aire de retorno",
	t3715: "Punto de rocío zonal",
	t3716: "Humedad de la zona",
	t3717: "Temperatura exterior",
	t3718: "Nivel de CO2 en la zona",
	t3719: "Temperatura de la zona",
	t3720: "Referencia condicional",
	t3721: "Estado de control del cond.",
	t3722: "Estado de control del cond.",
	t3723: "Control de cond.",
	t3724: "Cond. solicitó cap.",
	t3725: "Estado V3gc",
	t3726: "V3gc sobredosis",
	t3727: "Temperatura ambiente",
	t3728: "Estado de RRHH",
	t3729: "Habilitación de recursos humanos",
	t3730: "Solicitud de consumidor de RRHH",
	t3731: "Shr3",
	t3732: "Shr4",
	t3733: "Shr2",
	t3734: "Shr8",
	t3735: "Ref. de succión To-MT",
	t3736: "Estado de control MT",
	t3737: "Zona actual MT",
	t3738: "Para optar por compensar MT",
	t3739: "Capacidad de funcionamiento MT",
	t3740: "Cap. solicitado MT",
	t3741: "Es hora del siguiente paso MT",
	t3742: "Po-MT",
	t3744: "Temperatura de succión hasta MT",
	t3745: "Energía activa total",
	t3746: "Demanda máxima",
	t3747: "Factor de potencia total",
	t3748: "Voltios de fase A",
	t3749: "Voltios de fase B",
	t3750: "Voltios de fase C",
	t3751: "Amplificadores de fase A",
	t3752: "Amplificadores de fase B",
	t3753: "Amplificadores de fase C",
	t3754: "Potencia activa total",
	t3755: "Temperatura de succión hasta LT",
	t3756: "Temperatura de succión To-IT",
	t3757: "Referencia de succión LT",
	t3758: "Estado de control LT",
	t3759: "Zona actual LT",
	t3760: "Para optar por compensar LT",
	t3761: "Capacidad de funcionamiento LT",
	t3762: "Cap. solicitado LT",
	t3763: "Es hora del siguiente paso LT",
	t3764: "Po-LT",
	t3765: "SD-LT",
	t3766: "Referencia de succión IT",
	t3767: "Estado de control de TI",
	t3768: "Zona actual IT",
	t3769: "Capacidad de ejecución de TI",
	t3770: "Capacidad solicitada TI",
	t3771: "Es hora del siguiente paso en TI",
	t3772: "Tn Po-TI",
	t2773: "SD-IT",
	t2774: "Inicio de Comp. de TI",
	t3774: "Fuera de rango. Debe estar entre {{minAddr}} y {{maxAddr}}",
	t3775: "Operación deshabilitada para este dispositivo",
	t3776: "Tras esta acción, la aplicación cerrará la sesión, lo que puede tardar unos instantes.",
	t3777: "No se pudieron encontrar los archivos de migración (flp o json)",
	t3778: "Votación",
	t3779: "La votación está suspendida actualmente.",
	t3780: "¿Desea reanudar la votación?",
	t3781: "Medidor de energía",
	t3782: "Varios nodos seleccionados",
	t3783: "Zoom al nodo",
	t3784: "Unidades de medida",
	t3785: "Archivo(s) exportado(s) exitosamente",
	t3786: "La dirección no puede ser negativa",
	t3787: "Restablecer zoom",
	t3788: "Error al obtener los pasos del patrón de ensayo",
	t3789: "Error al guardar los pasos del patrón de preparación",
	t3790: "Los pasos del patrón de puesta en escena se guardaron correctamente",
	t3791: "Patrón de puesta en escena",
	t3792: "Pulse los iconos descargados para habilitar/deshabilitar los descargadores asociados a los compresores",
	t3793: "Datos faltantes",
	t3794: "Los siguientes parámetros no devolvieron ningún punto de datos",
	t3795: "La confianza HTTPS/SSL fue exitosa.",
	t3796: "Ahora puede iniciar sesión en el navegador web StoreView.",
	t3797: "Redireccionando al navegador web StoreView en {{timeLeft}} segundos...",
	t3798: "¡La votación está suspendida actualmente!",
	t3799: "Alarmas borradas (registros completos del sistema)",
	t3800: "El número máximo de HVAC permitido es 45.",
	t3801: "No se pudo obtener el registro de cambios",
	t3802: "Conectando al almacenamiento...",
	t3803: "Tiempo de espera de conexión de almacenamiento SCADA",
	t3804: "Error al conectar con el almacenamiento SCADA",
	t3805: "Entrada no válida",
	t3806: "Seleccione la(s) unidad(es) para descargar el informe"
};
var sp = {
	lang: lang$b
};

var lang$c = {
	t0: "Aktiva larm",
	t1: "Borttagna larm",
	t2: "Host",
	t3: "ID",
	t4: "Enhetens namn",
	t5: "Redigera",
	t6: "Ej auktoriserad att kvittera larm",
	t7: "Larmtyp",
	t8: "Inträffade",
	t9: "Kvittera",
	t10: "Aktiva larm",
	t11: "Borttaget",
	t12: "Kalkyleringar",
	t13: "Borttagna larm",
	t14: "Undertryck/Kvittera",
	t15: "Kvittera alla okvitterade larm",
	t16: "Behörighet misslyckades!",
	t17: "Error",
	t18: "Manuell styrning",
	t19: "Visning",
	t20: "Summerade data",
	t21: "Teknisk vy",
	t22: "Graf (S4)",
	t23: "Nod",
	t24: "Mod",
	t25: "Graf (Po &amp; Pc)",
	t26: "(inga larm detekterade)",
	t27: "Larm från enhet",
	t28: "Visa föreg. enhet",
	t29: "Visa nästa enhet",
	t30: "Fel i avläsning larm",
	t31: "Aktuella larm",
	t32: "Aktuellt larm",
	t33: "Inga larm detekterade.",
	t34: "Typ",
	t35: "Punkt",
	t36: "Historiska loggar",
	t37: "Scheman",
	t38: "Start time",
	t39: "Stopptid",
	t40: "Dagar",
	t41: "Semester",
	t42: "Status",
	t43: "Välj datapunkter",
	t44: "OK",
	t45: "Ta bort",
	t46: "Start",
	t47: "Slut",
	t48: "Start date",
	t49: "Sample rate",
	t50: "Inga datapunkter valda",
	t51: "Nu",
	t52: "sek",
	t53: "min",
	t54: "tim",
	t55: "dag",
	t56: "Adresstyp",
	t57: "Adress",
	t58: "Plats:",
	t59: "Lägg in nytt värde",
	t60: "Uppdatera",
	t61: "Välj datapunkter",
	t62: "Välj det nya värdet",
	t63: "Av",
	t64: "On",
	t65: "Hämtar datapunkter...",
	t66: "Fel i datahämtning",
	t67: "Ingen logg konfigurerad...",
	t68: "Fel i hämtning av datapunkter...",
	t69: "Hämtar loggar för",
	t70: "Fel i logghämtning...",
	t71: "Användarnamn",
	t72: "Lösenord",
	t73: "Host IP/URL adress:",
	t74: "OK",
	t75: "Ta bort",
	t76: "Namn",
	t77: "Värde",
	t78: "Fel i hämtning av användarlista",
	t79: "Lägg in ny adress",
	t80: "Hämta Användarlista misslyckades, kontr. Host IP/URL Adress",
	t81: "Beh.kod",
	t82: "Acct Kod",
	t83: "Välj ny tid och datum",
	t84: "Välj ny tidsrymd",
	t85: "Åtgärd misslyckades",
	t86: "Ange Behörighetsinfo",
	t87: "HVAC",
	t88: "Kör!",
	t89: "Avfrostn.styrning",
	t90: "Starta manuell avfrostning",
	t91: "Styrning av kylmöbelbelysning",
	t92: "Manuell belysn. På",
	t93: "Manuell belysn. Av",
	t94: "Styrning av kylmöbelrengöring",
	t95: "Manuell rengöring På",
	t96: "Manuell rengöring Av",
	t97: "Huvudbrytare",
	t98: "Manuell huvudbryt. På",
	t99: "Manuell huvudbryt. Av",
	t100: "Avstängn.styrning",
	t101: "Stäng av enhet",
	t102: "Slå på enhet",
	t103: "Natthöjningsstyrning",
	t104: "Natthöjning På",
	t105: "Natthöjning Av",
	t106: "Meddelande sänt...",
	t107: "Manuell styrning",
	t108: "Close",
	t109: "Lighting",
	t110: "Effekt/Övrigt",
	t111: "Översikt",
	t112: "Välj AK255 enhet att visa.",
	t113: "Laddar...",
	t114: "av",
	t115: "Alla AK255",
	t116: "Föregående",
	t117: "Sidor",
	t118: "Nästa",
	t119: "Parameter",
	t120: "Avläsningar",
	t121: "Hämtar data...",
	t122: "Fel i lässektion",
	t123: "Temperatur",
	t124: "Fördröjning efter avfrostning",
	t125: "Larm om nedan",
	t126: "Larm om ovan.",
	t127: "Övervakning",
	t128: "Offline",
	t129: "Aktiv",
	t130: "Host",
	t131: "Adress",
	t132: "Beskrivning",
	t133: "Aktuellt värde",
	t134: "Aktuell status",
	t135: "Regulatorer",
	t136: "Enhet",
	t137: "Aktiva larm finns",
	t138: "Aktiva larm finns",
	t139: "Digitala ingångar",
	t140: "Reläutgångar",
	t141: "Givaringångar",
	t142: "Spänningsutgång",
	t143: "Generic Regulatorer",
	t144: "Andra",
	t145: "Visningspunkt",
	t146: "Butiksöversikt",
	t147: "Logga ut från systemet",
	t148: "Logga på",
	t149: "Logga av",
	t150: "Kyla",
	t151: "Enhet översikt",
	t152: "Larm",
	t153: "Loggar",
	t154: "CS-Översikt",
	t155: "Butiksöversikt",
	t156: "Konfiguration",
	t157: "Anläggnings-vy",
	t158: "Anläggn.översikt",
	t159: "Hem",
	t160: "Larm",
	t161: "Setpoint",
	t162: "Enhetsdetalj",
	t163: "Inställning",
	t164: "Välj datum",
	t165: "Information",
	t166: "Audit Trail",
	t167: "Kalkyleringar",
	t168: "Filhantering",
	t169: "Hjälpmanual",
	t170: "Info",
	t171: "Översättare",
	t172: "Spara till server",
	t173: "Uppdatera språkfiler?",
	t174: "Denna åtgärd uppdaterar språkfilerna på enheten. Fortsätta?",
	t175: "Exportera loggar",
	t176: "Välj nytt värde",
	t177: "minimum",
	t178: "maximum",
	t179: "Inget aktivt larm",
	t180: "Ej ändringsbart",
	t181: "V.v. vänta...",
	t182: "Kommando sänt!",
	t183: "Åtgärd misslyckades, v.v. försök senare!",
	t184: "Åtgärd lyckades!",
	t185: "Ej auktoriserad åtgärd!",
	t186: "Är du säker på att fortsätta?",
	t187: "FÖRÅNGARE",
	t188: "PACK",
	t189: "Övervakning ingångar",
	t190: "ÖVRIGT",
	t191: "Kvitterade larm",
	t192: "Ta bort Kvitterade larm",
	t193: "Ta bort alla kvitterade larm",
	t194: "Du är ej auktoriserad att kvittera larm",
	t195: "Ej auktoriserad",
	t196: "Vill du kvittera detta larm?",
	t197: "Bekräfta Larmkvittering",
	t198: "V.v. logga på först",
	t199: "V.v. välj ett larm att ta bort",
	t200: "Bekräfta Larmborttag",
	t201: "Är du säker på att ta bort detta larm?",
	t202: "Ej auktoriserad att ta bort larm",
	t203: "Är du säker på att ta bort alla larm?",
	t204: "Valda larmer kan ej tas bort",
	t205: "Larminställning",
	t206: "Måste vara Supervisor för att utföra detta",
	t207: "Inga data i retur från enhet",
	t208: "Välj en enhet",
	t209: "V.v. välj en enhet från listan",
	t210: "Antal larm",
	t211: "Larmstatus",
	t212: "Övrigt",
	t213: "Ta bort valt larm",
	t214: "V.v. bekräfta",
	t215: "Ansl. till enhet misslyckades, kontrollera nätverksanslutning!",
	t216: "Avfrostn.larm",
	t217: "Digitalt larm",
	t218: "Digital ingång status",
	t219: "Avfrostn.ingång",
	t220: "Möbelrengöring Ingång",
	t221: "Digital ingång",
	t222: "Sparar språkfiler",
	t223: "Enheter",
	t224: "Ingen enhet konfigurerad i systemet, Website avaktiverad",
	t225: "Varning:",
	t226: "Välj tid",
	t227: "Kommunikationer",
	t228: "Ladda ned Rapportfil?",
	t229: "V.v. vänta medan rapportfil genereras...",
	t230: "Ladda ned Rapport",
	t231: "Larm Status/Service",
	t232: "V.v. vänta medan data hämtas...",
	t233: "Påloggad",
	t234: "Användare utloggad",
	t235: "Användare inloggad",
	t236: "Välkommen",
	t237: "Invalid license",
	t238: "Energimätarmoduler",
	t239: "Belysningstavlor",
	t240: "Frekvensomformare",
	t241: "Kompressormodul",
	t242: "Regulatorpunkter",
	t243: "Kalkylerade punkter",
	t244: "Cutler-Hammer Panels",
	t245: "Carriers",
	t246: "Avancerad vy",
	t247: "Dubbelklicka här för att zooma In/Ut",
	t248: "Klicka här för att zooma In/Ut",
	t249: "Kvitterade larm",
	t250: "Simulator",
	t251: "Kylfråga",
	t252: "HVAC-fråga",
	t253: "Belysningsfråga",
	t254: "ÖVRIGT-fråga",
	t255: "Nätverk anslutet",
	t256: "Frekv.omf.",
	t257: "Referens",
	t258: "Graf (Drive Mtr Hz &amp; Amp)",
	t259: "Stoppdatum",
	t260: "Stopp",
	t261: "Stopptiden är före starttid, v.v. välj nytt datum/tid",
	t262: "Tid för skapande av loggpunkter",
	t263: "senare än specad starttid",
	t264: "Antal samples från varje datapunkt har nått 100000. Du kan reducera antalet genom att öka samplingsintervallet eller ändra datum/tid.",
	t265: "Enhetens logginsamling är aktiv, v.v. vänta ca 2 min. och försök igen...",
	t266: "Spara",
	t267: "Logginställningar",
	t268: "Först",
	t269: "Senaste",
	t270: "Skriv ut",
	t271: "Zooma ut",
	t272: "Zooma in",
	t273: "Visa/Göm Legend",
	t274: "Exportera loggar till fil",
	t275: "Visa/Göm grid",
	t276: "Ensides-vy",
	t277: "Flersides-vy",
	t278: "Ladda loggrupp från fil",
	t279: "Ladda loggar från fil",
	t280: "Spara loggrupp till fil",
	t281: "Flersides-vy",
	t282: "Sida",
	t283: "Välj en sida att visa",
	t284: "Loggfil måste vara i .hst-format",
	t285: "Inga data i retur, v.v. kontrollera loggpunkter och valt datum/tid",
	t286: "Logg snapshot",
	t287: "Logg hel vy",
	t288: "Loggfil-vy",
	t289: "Välj...",
	t290: "Denna åtgärd exporterar aktuell logg-vy till en .hst/.csv-fil-",
	t291: "V.v. välj en målfil",
	t292: "File",
	t293: "Ladda loggrupp...",
	t294: "Ladda loggar...",
	t295: "Spara loggrupp...",
	t296: "Visa",
	t297: "Zooma",
	t298: "Ja",
	t299: "Nej",
	t300: "Hämta konfiguration från regulator?",
	t301: "Fråga inte igen",
	t302: "Hämta regulatorkonfiguration",
	t303: "Data hämtade",
	t304: "Larmnivå",
	t305: "Larmaktion",
	t306: "Kvitterad",
	t307: "Spara databas till disk",
	t308: "Filnamn",
	t309: "Databasbeskrivning",
	t310: "Databas-väg",
	t311: "Version",
	t312: "Tid",
	t313: "Exportera larm",
	t314: "Databas sparad till",
	t315: "Sparande av databas misslyckades, v.v. kontrollera om mapp och filnamn är giltiga!",
	t316: "Filnamn är ej korrekt, v.v. kontrollera databasens filnamn",
	t317: "Larmexport lyckad. V.v. välj en fil för att spara larm",
	t318: "V.v. välj larmer att exportera",
	t319: "Hämtar larminformation från enheter",
	t320: "Filbeskrivning",
	t321: "Konvertera .hst-fil till .csv-filformat",
	t322: "Steg 1: Välj .hst-fil",
	t323: "Steg 2: Välj datapunkter och tidsområde",
	t324: "Steg 3: Välj målfil .csv",
	t325: "Spara som...",
	t326: "Max 6000 datapunkter tillåtet i denna operation",
	t327: "Datapunkter valda",
	t328: "Det är ej tillåtet att exportera mer än 65536 data per punkt, v.v. ändra samplingsintervall eller tid.",
	t329: "Hämta",
	t330: "Hämta loggdata från enhet",
	t331: "Välj insamlade datapunkter",
	t332: "Inget värde",
	t333: "Saknas",
	t334: "Avvisad",
	t335: "V.v. gör ett val",
	t336: "Välj datapunkter att rita",
	t337: "Spara till .hst-fil",
	t338: "Datanedladdning avslutat, v.v. välj ett av följande alternativ:",
	t339: "Välj högst 8 punkter att rita",
	t340: "Spara hämtade loggae till .hst-fil",
	t341: "Översikt utskrift",
	t342: "V.v. välj Spara eller Skriv ut översiktsrapport",
	t343: "Välj ett filformat",
	t344: "Skapar Översiktsfil, v.v. vänta...",
	t345: "Vald databasfil existerar, vill du skriva över den?",
	t346: "Översikt",
	t347: "Graf-vy",
	t348: "Hjälp",
	t349: "System-vy",
	t350: "Karta",
	t351: "Översättn.verktyg",
	t352: "Manuell",
	t353: "Frågar efter larm...",
	t354: "Uppdaterad",
	t355: "Modell",
	t356: "Reglering",
	t357: "Olja",
	t358: "Temp",
	t359: "Maximera",
	t360: "Återställ",
	t361: "Gå tillbaka...",
	t362: "Komm. misslyckades!",
	t363: "Komm.status ok.",
	t364: "Komm.meddelande sänt",
	t365: "Laddar hemkomponent",
	t366: "Laddar Konfigureringskomponent",
	t367: "Reglertemp.",
	t368: "Denna inställning blir aktiv först när loggarna tas bort, vill du fortsätta?",
	t369: "Laddar, v.v. vänta",
	t370: "datapunkter",
	t371: "Inget namn på enhet",
	t372: "Preferenser",
	t373: "Simulatorversion",
	t374: "Energi",
	t375: "Gå till översiktsfönster",
	t376: "Systemlarm eller ingen relateradenhet funnen",
	t377: "Samtidiga scheman",
	t378: "Saknar språkfil",
	t379: "Version ej supportad",
	t380: "Lägg in en enhets IP-adress här (t.ex. 192.168.1.1)",
	t381: "V.v. ange en enhets IP-adress eller URL.",
	t382: "Göm detalj",
	t383: "Visa detalj",
	t384: "Utetemp.",
	t385: "Retur",
	t386: "Matning",
	t387: "Fukt",
	t388: "Daggpunkt",
	t389: "Fläkt",
	t390: "Zon",
	t391: "Stäng av",
	t392: "Dela",
	t393: "Sugtemp.",
	t394: "Aktuell kapacitet",
	t395: "Sugtryck",
	t396: "Hetgas",
	t397: "Kompressorer",
	t398: "Kondensor",
	t399: "Kondensor mål",
	t400: "Köldmedium",
	t401: "Sugtryck mål",
	t402: "Sug",
	t403: "Defrost",
	t404: "Danfoss loggformat",
	t405: "Textformat",
	t406: "Denna åtgärd kräver en reset av enheten, vill du göra reset nu?",
	t407: "Enhetsreset lyckades, v.v. ladda om web-sidan.",
	t408: "Fabriksapplikationsläge",
	t409: "Beräknar...",
	t410: "minuter",
	t411: "Night Setback",
	t412: "Kylmöbelbelysning",
	t413: "Samtidiga scheman",
	t414: "(10 min. upplösning)",
	t415: "Visa text",
	t416: "Exportera som CSV",
	t417: "Spara PNG",
	t418: "Visa graf",
	t419: "Visa text",
	t420: "Schema",
	t421: "Tidsrymd",
	t422: "Term.temp",
	t423: "INGEN",
	t424: "Reglertemp.",
	t425: "Kör från",
	t426: "Databas laddad från",
	t427: "Statistik",
	t428: "Välj fil",
	t429: "Välj den loggfil du vill konvertera till CSV...",
	t430: "Avsluta",
	t431: "Summering",
	t432: "Loggdatafiler (*.hst)",
	t433: "Loggomvandling - Wizard",
	t434: "Fil komplett",
	t435: "Välj de datapunkter du vill konvertera till CSV...",
	t436: "Välj tidsområde",
	t437: "Välj tidsområdet och tryck på fortsätt...",
	t438: "Processdata",
	t439: "Förbereder loggdata, v.v. vänta",
	t440: "Välj CSV filnamn och spara data till disk",
	t441: "Kan ej finna loggpunkt",
	t442: "Fel - inga loggar hämtade",
	t443: "Fel vid kommunikation med enheter över host-nätverket funnet",
	t444: "Obs",
	t445: "Tyst",
	t446: "Databas ej sparad, vill du verkligen stänga fönstret?",
	t447: "Tabell-vy",
	t448: "Visa grafik",
	t449: "Visa tabell",
	t450: "Manuell avfrostn. På",
	t451: "Manuell avfrostn. Av",
	t452: "Autoavfrostning",
	t453: "Ställ in avfrostn.styrning",
	t454: "Avlastare på",
	t455: "Avlastare av",
	t456: "Vätska",
	t457: "Inga scheman konfigurerade.",
	t458: "Butiks-vy",
	t459: "Tappat kommunikation med en enhet på host-nätverket!",
	t460: "Snabbinställning",
	t461: "Gata",
	t462: "Stad",
	t463: "Telefon",
	t464: "Region",
	t466: "Index",
	t469: "Adressbok exporterad",
	t470: "Ta bort punkt",
	t471: "Gauge",
	t472: "Obs Versaler och Gemener",
	t473: "Online",
	t474: "RO-kort",
	t475: "SI-kort",
	t476: "OI-kort",
	t477: "Kalkylering",
	t478: "Ställ aktuell tid",
	t479: "Rack",
	t480: "No Compressor",
	t481: "Circuit",
	t482: "Case",
	t483: "Unknown",
	t484: "err type:",
	t485: "Add",
	t486: "Copy",
	t487: "Remove",
	t488: "Case Control",
	t489: "Case Type",
	t490: "LON",
	t491: "Enter amount to copy",
	t492: "no name",
	t493: "Configuring refrigeration layout...",
	t494: "Configuration error:",
	t495: "Please verify configuration.",
	t496: "The refrigeration layout setup has completed successfully. Press the ok button to exit this wizard.",
	t497: "Configuration Status",
	t498: "Final Refrigeration Layout",
	t500: "Code #",
	t501: "Layout",
	t502: "System Setup",
	t503: "Licensing",
	t504: "Users",
	t505: "Ej auktoriserad",
	t506: "Please verify...",
	t507: "Are you sure you want to leave this screen? Your settings may be lost.",
	t508: "Scanning controller network...",
	t509: "Scanning",
	t510: "Verifying",
	t511: "AK-I/O",
	t512: "Rescan",
	t513: "Include offline programming",
	t514: "Device Bus",
	t515: "Online devices",
	t516: "Offline-programmering",
	t517: "Duplicate address",
	t518: "Compressor Controls",
	t519: "Suction Groups",
	t520: "N/A",
	t521: "Available Cases",
	t522: "Allow multi-case circuit creation",
	t523: "Insert devices",
	t524: "Insert circuit",
	t525: "Multi-case circuit.",
	t526: "Loading tables...",
	t527: "Requesting alarm data for",
	t528: "History Group Files",
	t529: "History Data Files",
	t530: "All Files",
	t531: "Show Symbols",
	t532: "Show Curves",
	t533: "Not enough history has been collected.",
	t534: "Retrieve the latest history data.",
	t535: "Status/Settings",
	t536: "Now downloading",
	t537: "history samples...",
	t538: "Retrieving",
	t539: "Datapoints...",
	t540: "Interval",
	t541: "Min",
	t542: "Max",
	t543: "Average",
	t544: "Go to main alarm window.",
	t545: "Stop auto-logon",
	t546: "Connecting to ",
	t547: "Cancel auto-logon",
	t548: "Please enter log-off password",
	t549: "Web 1",
	t550: "Web 2",
	t551: "Log on automatically",
	t552: "Log off after inactivity period",
	t553: "Home screen",
	t554: "Show configuration menu",
	t555: "Show misc items on Dashboard",
	t556: "Use full screen",
	t557: "Enable alarm sound",
	t558: "Allow program close/minimize",
	t559: "Error accessing unit #",
	t560: "Connected to network.",
	t561: "Auto-logon cancelled",
	t562: "Retry connect to",
	t563: "Logged out from",
	t564: "Connected",
	t565: "Time Format",
	t566: "Date Format",
	t567: "Pressure",
	t568: "Light Level",
	t569: "Subcool",
	t570: "Subcooler",
	t571: "Daily",
	t572: "Weekly",
	t573: "HST File",
	t574: "CSV File",
	t575: "Söndag",
	t576: "Måndag",
	t577: "Tisdag",
	t578: "Onsdag",
	t579: "Torsdag",
	t580: "Fredag",
	t581: "Lördag",
	t582: "History Log Collection",
	t583: "Enable auto-collect",
	t584: "When to collect",
	t585: "File save location",
	t586: "Select file format",
	t587: "Select folder to store history files...",
	t588: "Enable automatic history collection to retrieve and save daily or weekly history logs of the store's configured history points.",
	t589: "To view HST history files go to the main history screen and select import history. To view CSV files use any spreadsheet application.",
	t590: "If the current connection's IP address is found in the address book the selected history points will be saved.",
	t591: "Select data-points",
	t592: "All selected",
	t593: "Points selected",
	t594: "Sök",
	t595: "Auto-connecting to",
	t596: "Please verify",
	t597: "Automatic history collection is enabled. Are you sure you want to close this program?",
	t598: "Are you sure you want to close this program?",
	t599: "Action",
	t600: "Filter",
	t601: "All actions",
	t602: "More information...",
	t603: "Editable",
	t604: "Operation",
	t605: "Operation Cancelled",
	t606: "Databas-laddning avslutad",
	t607: "Enhetens databas uppdaterad",
	t608: "Fil(er) överförd till enhet OK",
	t609: "Laddning avslutad, fil processas i enhet",
	t610: "Fel uppstod vid laddning av fil till PC, v.v. kontrollera om filen är skrivbar och tillgänglig",
	t611: "Laddar fil",
	t612: "Laddar fil",
	t613: "Laddar valda filer",
	t614: "Laddar valda filer",
	t615: "Tar bort fil",
	t616: "Tar bort valda filer",
	t617: "FTP anslutningsfel",
	t618: "Ladda valda filer till enhet",
	t619: "Fel vid FTP-anslutning. V.v. kontrollera FTP-anslutning och beh.kod/anv.namn",
	t620: "FTP ansluten",
	t621: "Bytes",
	t622: "Filer i enheten skrivs över av denna åtgärd. Vill du fortsätta?",
	t623: "Filer i PC:n skrivs över av denna åtgärd. Vill du fortsätta?",
	t624: "Filer har laddats till enhet.",
	t625: "Enbart Kyla",
	t626: "Snabbköpsbutik",
	t627: "Full funktionalitet",
	t628: "Bara HVAC",
	t629: "Filnamn ej giltigt; V.v. se till att filnamnet har mindre än 8 tecken.",
	t630: "Skriva över Applikationskoden nu?",
	t631: "Senare",
	t632: "laddad",
	t633: "du kan välja JA för att skriva över applikationen nu eller välja Senare",
	t634: "V.v. välj Simulator huvudmapp",
	t635: "V.v. välj Windowsmappen",
	t636: "V.v. välj målmappen",
	t637: "Välj Databas-mapp",
	t638: "FTP-ansl. bruten",
	t639: "Anslutning Timeout, FTP-ansl. bruten",
	t640: "Ladda valda filer till PC",
	t641: "Ta bort valda filer",
	t642: "FTP Server Port",
	t643: "Web Server Port",
	t644: "Lokal stig",
	t645: "Upp",
	t646: "Storlek",
	t647: "Datum",
	t648: "Anslut",
	t649: "Koppla ned",
	t650: "Ta bort väg",
	t651: "Fritt utrymme tillgängligt",
	t652: "Valda filer",
	t653: "Filer",
	t654: "Total storlek:",
	t655: "Click to change folder...",
	t656: "Byt...",
	t657: "Önskar du ta bort de valda filerna?",
	t658: "Anslutning stängd, Gå ur",
	t659: "De valda filnamnen är ogiltiga och laddningen ignoreras.",
	t660: "Några av de valda filnamnen är ogiltiga och ignoreras.",
	t661: "Fil(er) överförd till PC OK",
	t662: "Ej tillräckligt fritt utrymme på enheten",
	t663: "Transfer file(s)",
	t664: "Modified",
	t665: "Kopiera filer från Flash Disk till RAM Disk",
	t666: "V.v. välj en Filkopieringsåtgärd",
	t667: "Copy All the Configured EDF Files from Flash Disk",
	t668: "Kopiera alla EDF-filer från Flash Disk",
	t669: "The edf/ed3 files are stored on the flash disk of your unit but not loaded into the File Management screen.  Use the options below to copy the stored EDF files in your unit to the File Management screen",
	t670: "Denna åtgärd kopierar alla EDF-filer som har konfigurerats i enheten från flash-disk till RAM-disk",
	t671: "Denna åtgärd kopierar alla EDF-filer i enheten från flash-disk till RAM-disk",
	t672: "No Valid EDF File Exists",
	t673: "EDF File Copy Finished",
	t674: "files copied",
	t675: "Confirm database download",
	t676: "Enhets-adress",
	t677: "IP-adress/värdnamn",
	t678: "Databas filnamn",
	t679: "Välj",
	t680: "Komplett Uppdatering med återställning och uppgradering av enhet. Detta kan ta ett antal minuter.",
	t681: "Välkommen till Upgrade Manager - Steg 1 av 2",
	t682: "Välkommen till Upgrade Manager - Steg 2 av 2",
	t683: "Fortsätt",
	t684: "Kontroll före uppgradering",
	t685: "Lokalisera Firmware",
	t686: "Backup databas?",
	t687: "Backup databas",
	t688: "Flyttar mjukvara",
	t689: "Skriver över mjukvara",
	t690: "Välj mjukvara att uppgradera",
	t691: "Ingen fabriks-fail safe application hittad.",
	t692: "Du kan fortsätta processen, men eventuella fel under uppdateringsprocessen kan göra systemet instabilt.",
	t693: "Det rekommenderas starkt att inte fortsätta.",
	t694: "Önskar du fortsätta?",
	t695: "Att uppgradera ett system över nätet kan medföra felrisker, som kan orsaka systemfel.",
	t696: "Dementi",
	t697: "Databas-laddning avslutad",
	t698: "Databas-backup avslutad",
	t699: "Laddning av Applikation avslutad",
	t700: "Överskrivning av Applikation avslutad",
	t701: "Mjukvaru-uppgradering genomförd",
	t702: "Önskar du återställa enheten till fabriks-applikation?",
	t703: "Kontrollerar hårdvara",
	t704: "Kontrollerar Enhetens status",
	t705: "Fabriks-Fail Safe",
	t706: "Aktuell Firmware-version",
	t707: "Starta Uppgradering",
	t708: "Välj en existerande databasfil",
	t709: "Välj en existerande firmware-fil",
	t710: "Tillbaka",
	t711: "Lokalisera Firmware",
	t712: "Välj .csi-fil",
	t713: "Välj .mai-fil",
	t714: "Remote downloading is not supported by this CPU board.",
	t715: "Applikations-laddning misslyckades",
	t716: "Databasladdning fel, v.v. försök igen",
	t717: "Överskrivning av Applikation avslutad",
	t718: "Applikations-överskrivning misslyckades! V.v. försök igen.",
	t719: "Applikations-överskrivning misslyckades",
	t720: "Ingen fabriks-fail safe application hittad.",
	t721: "Klart",
	t722: "Den valda applikationen kan ha en annan versionstyp än den aktuella enhetens firmware. Att fortsätta med denna åtgärd kan medföra risk för fel och systemfel.",
	t723: "De valda filnamnen är ogiltiga och laddningen ignoreras.",
	t724: "Några av de valda filnamnen är ogiltiga och ignoreras.",
	t725: "Enhetens berg-pin saknas",
	t726: "Enhetens batteri har låg nivå.",
	t727: "Enhetens host-nätverk falerade.",
	t728: "Enheten är i belastningsbegr. status",
	t729: "Välj målfil",
	t730: "Välj målmapp",
	t731: "Databas-laddning avslutad",
	t732: "Status OK",
	t733: "Status Fel",
	t734: "Status okänd",
	t735: "Denna åtgärd kan skriva över de valda filerna i målsimulatorn, vill du fortsätta?",
	t736: "Laddning av databas avslutad, v.v. återställ systemet.",
	t737: "Laddning av databas misslyckades, v.v. försök senare",
	t738: "Enheten blir återställd automatiskt efter denna operation. Vill du fortsätta?",
	t739: "Välj databas",
	t740: "Ladda databasfil?",
	t741: "Ladda databasfil",
	t742: "Laddar databas till enhet, v.v.vänta",
	t743: "Databasladdning OK, nu återställs enhet",
	t744: "Databasuppdatering avslutad, återställning OK",
	t745: "Databasuppdatering misslyckades, v.v. försök igen",
	t746: "Fel vid databasuppdatering",
	t747: "Före laddning-kontroll",
	t748: "Lokalisera Databas",
	t749: "Laddar databas",
	t750: "Aktuell databas-information",
	t751: "Välj databas att ladda",
	t752: "Spara databas till PC ",
	t753: "Ladda databas till enhet",
	t754: "Kräver Supervisor Behörighet",
	t755: "Starta laddning",
	t756: "V.v. välj en Databas-åtgärd",
	t757: "Starta laddning",
	t758: "Aktuell Firmware-version",
	t760: "Databas-laddning utförd!",
	t761: "Välkommen till Databas Manager - Steg 1 av 2",
	t762: "Välkommen till Databas Manager - Steg 2 av 2",
	t763: "Fel vid anslutning till enhet, v.v. kontrollera Host IP/URL adress",
	t764: "Att uppgradera ett systems databas över nätet kan medföra felrisker, som kan orsaka systemfel.",
	t765: "Database downloading finished",
	t766: "Ethernet Scan",
	t767: "Enter first IP address then press start",
	t768: "Scan",
	t769: "Scanning",
	t770: "History Log Collection",
	t771: "Analog graph colors",
	t772: "Digital graph colors",
	t773: "Shutdown Schedules",
	t774: "Defrost Summary",
	t775: "Night setback schedules",
	t776: "Case Lighting Schedules",
	t777: "Loading Status",
	t778: "Alarm Log Collection",
	t779: "Select folder to store alarm files...",
	t780: "Monthly",
	t781: "Enable automatic alarm collection to retrieve and save alarm information to a file on a periodic basis.",
	t782: "CSV Separator",
	t783: "Inga larm",
	t784: "Retrieving alarm counts for unit:",
	t785: "Retrieving alarm details from unit:",
	t786: "Automatic alarm collection is enabled. Are you sure you want to close this program?",
	t787: "Hourly",
	t788: "This will update the unit's database and set the location to the center of the current view. Proceed?",
	t789: "samples processed",
	t790: "Please configure a suction group.",
	t791: "To re-use the Layout Wizard more than once the refrigeration database layout needs to be reset.",
	t792: "Do this via the Configuration-&gt;Control page and set the number of Racks/Packs to zero.",
	t793: "Note: any schedules, calculations or imports based on prior refrigeration configuration will need to be reconfigured.",
	t794: "Copy process finished.",
	t795: "Refrigeration Report",
	t796: "Final HVAC Layout",
	t797: "The HVAC layout setup has completed successfully. Press the ok button to exit this wizard.",
	t798: "Configuring HVAC layout...",
	t799: "To re-use the HVAC Layout Wizard more than once the HVAC database layout needs to be reset.",
	t800: "Do this via the Configuration-&gt;Control page and set the number of HVAC units to zero.",
	t801: "Note: any schedules, calculations or imports based on prior HVAC configuration will need to be reconfigured.",
	t802: "Staging pattern",
	t803: "Steps",
	t804: "Nivå",
	t805: "Show Multiple DataTip",
	t806: "Antal Steg",
	t807: "Could not save file.",
	t808: "Incorrect model.",
	t809: "Load successful.",
	t810: "Open",
	t811: "This feature is only available in the desktop version of StoreView.",
	t812: "Continue",
	t813: "Leave Wizard",
	t814: "Operation succeeded. Would you like to continue or you want to leave the wizard.",
	t815: "Error while fetching alarm logger data.",
	t817: "Device Name : ",
	t819: "In Progress...",
	t820: "Alarm data is not present.",
	t821: "Larmräkning",
	t822: "Mimic Display Options",
	t823: "Display only text",
	t824: "Display only icon",
	t825: "Display text and icon",
	t826: "1 Vecka",
	t827: "2 Weeks",
	t828: "3 Weeks",
	t829: "4 Weeks",
	t830: "Graphic Settings",
	t831: "Unable to display alarm diagnostics, please check auto alarm collection under preferences",
	t832: "Reordering not allowed with IO racks or circuits included in configuration.",
	t833: "This Host IP/URL Address is already present in address book. Do you like to update that entry?",
	t834: "Sök",
	t835: "Select Address Book Folder",
	t836: "Plats",
	t837: "Selected location is not having any address book contents.Would you like to export contents of the existing address book to the selected location?",
	t838: "Alarm Action Codes",
	t839: "Address book file is not found in the selected path. Address book is loaded from local...",
	t840: "Please select location to save logs.",
	t841: "Please select a valid location to save logs.",
	t842: "Alarm log collection is in progress...",
	t843: "History log collection is in progress...",
	t844: "Kör i bakgrunden",
	t845: "Alarm log progress",
	t846: "Host IP/URL Address should not be blank",
	t847: "Address field should not be blank",
	t849: "You don't have latest version of the file, would you like to load data from the latest version?",
	t850: "Version conflict",
	t851: "History Log Progress",
	t852: "User Name and Password fields should not be blank.",
	t853: "IP Address field should not be blank.",
	t854: "Host IP/URL Address field should not be blank.",
	t855: "User name should not be blank.",
	t856: "Password should not be blank.",
	t857: "The SM800 you have connected to with",
	t858: "has not had the Initial Startup Wizard completed. Please complete this task and reconnect to the SM800",
	t859: "Set to now",
	t860: "Invalid input",
	t861: "Text is too long",
	t862: "Offset",
	t863: "Unable to communicate with unit(s) on host network",
	t864: "Please verify addresses are different. Configuration is updated when the save button is pressed.",
	t865: "Please select a valid History File (.hst)",
	t866: "Please select a valid History Group File (.grp)",
	t867: "Färg",
	t868: "Procent",
	t869: "Spänning",
	t870: "Nuvarande",
	t871: "Makt",
	t872: "Frekvens",
	t873: "Koncentration",
	t874: "Flöde",
	t875: "Hastighet",
	t876: "Surhetsgrad",
	t877: "Vänligen välj diagram längst ned Dragare",
	t878: "Spara PNG",
	t879: "Rita",
	t880: "Systemstatus...",
	t881: "Status för system och enhet",
	t882: "Gataway",
	t883: "Funktioner licenser",
	t884: "Programversion",
	t885: "Version av webbläsarmodul",
	t886: "Danux-version",
	t887: "Mac-adress",
	t888: "Support & Uppdateringar",
	t889: "Enhetsnamn",
	t890: "Återansluta",
	t891: "Förlorad anslutning",
	t892: "Ingen {{type}}-utrustning hittades.",
	t893: "Den för tillfället använda programvaruversionen stöds inte",
	t894: "Snabblänkar",
	t895: "Utrustning",
	t896: "Diagram",
	t897: "Verktyg",
	t898: "Återställning av programvara",
	t899: "Diagnostik",
	t900: "Ett fel uppstod",
	t901: "Inga {{alarms}} data hittades",
	t902: "Inställningar",
	t903: "Programnamn",
	t904: "Programversion",
	t905: "Meddelande",
	t906: "Händelsetyp",
	t907: "Tidsstämpel",
	t908: "Rollback-version",
	t909: "Aktuell version",
	t910: "Programvara",
	t911: "Rollback...",
	t912: "Vänligen välj utrustning från listan",
	t913: "Kalendervy",
	t914: "Ett fel uppstod vid inläsning av enheter(ar), försök igen.",
	t915: "Ett fel uppstod vid inläsning av scheman, försök igen.",
	t916: "Ingen utrustning hittades",
	t917: "Scheman Exportera",
	t918: "Vänligen välj CSV eller PDF-exporttyp",
	t919: "Exportera som CSV",
	t920: "Förbereda PDF",
	t921: "Exportera som PDF",
	t922: "Vänligen lämna en IP i detta format xx.xx.xx.xx[:xxxx]",
	t923: "Användarnamn",
	t924: "Logga in",
	t925: "Rollback",
	t926: "Rollback SM800A programvara",
	t927: "Denna åtgärd kommer att återställa all programvara till de listade versionerna.",
	t928: "Redigerare för partyenhet",
	t929: "viss text vilket förklarar funktionen",
	t930: "Uppdatera CDF",
	t931: "Karta Enhet",
	t932: "Enhetsfil",
	t933: "1-baserat registernummer",
	t934: "Lagringstyp",
	t935: "Min",
	t936: "Max",
	t937: "Endast läsning",
	t938: "Logga in",
	t939: "Meddelandetyp",
	t940: "Nyckelord för konvertering",
	t941: "Parametrar",
	t942: "Addr.",
	t943: "Prioritet",
	t944: "Bitars",
	t945: "Förnekas",
	t946: "Ny fil",
	t947: "Öppna fil",
	t948: "Generera fil",
	t949: "Grupp",
	t950: "Produktversion",
	t951: "Fel uppstod",
	t952: "Hittade inte",
	t953: "Den här enheten hittades inte",
	t954: "Utanför temp.",
	t955: "Ingen {{utrustning}} hittades",
	t956: "Fall",
	t957: "Larmet stänger av ljudet/agerar med framgång",
	t958: "Ljud av/agera misslyckades för detta larm",
	t959: "Ett fel uppstod vid inläsning av enhet(er), försök igen.",
	t960: "Ingen utrustning",
	t961: "Ingen {{type}} utrustning hittades",
	t962: "Konfigurera systemhanteraren",
	t963: "Enhet",
	t964: "Ångra",
	t965: "Du ändrade värdet på fältet, vill du spara ändringarna?",
	t966: "Rensa markerade historikpunkter",
	t967: "Shr {{number}}",
	t968: "Hr pumphastighet",
	t969: "Sd",
	t970: "V3hr",
	t971: "Hr referens",
	t972: "Stw {{number}}",
	t973: "Två referens",
	t974: "Två pumphastighet",
	t975: "V3tw",
	t976: "Trec",
	t977: "Prec",
	t978: "Vrec OD",
	t979: "Prec referens",
	t980: "Trec referens",
	t981: "Pc",
	t982: "Ss",
	t983: "Vhp OD",
	t984: "Pgc referens",
	t985: "Ejektor OD",
	t986: "Pgc",
	t987: "Shp",
	t988: "Sgc",
	t989: "Sc3",
	t990: "Sgc ctrl.",
	t991: "Cond. kapacitet",
	t992: "Po",
	t993: "Ss",
	t994: "Sd",
	t995: "Pc",
	t996: "Löpkapacitet",
	t997: "Webbläsarcertifikat",
	t998: "Logga av",
	t1000: "Sorterar endast på {{count}}",
	t1001: "Laddade {{count}} av {{total}} larm",
	t1002: "Bekräfta lyckades för alla larm",
	t1003: "Bekräftelsen misslyckades för alla larm",
	t1004: "Endast {{count}} av {{total}} larm kvitterades",
	t1005: "Exporten misslyckades för alla larm",
	t1006: "Exporten lyckades",
	t1007: "Exporten misslyckades, minst en larmtyp krävs",
	t2000: "-",
	t2001: "Svaret är för stort för XML-överföringsbufferten som är 32K byte",
	t2002: "Inga attribut associerade med cmd-element eller kommando har inget \"cmd\"-element eller kunde inte skriva till en variabel eller kunde inte läsa en variabel eller kunde inte läsa en punkt eller kunde inte skriva på/av ingångs- eller reläutgångsdriftläge",
	t2003: "Dålig kommandoinmatning",
	t2004: "Fel i CGI-postparametrar",
	t2005: "Fel när XML-svaret konverterades från dess interna representation till XML-svarssträngen",
	t2006: "Fel vid avkodning av URL-sträng",
	t2007: "Minnesspill",
	t2008: "Komprimering av svarsdata misslyckades",
	t2009: "Fel vid analys av begäran",
	t2010: "Dataåtkomstfel - ett antal orsaker som inte åtgärdas ytterligare",
	t2011: "Odefinierat kommandoåtgärdsattributvärde Attributvärdet är ogiltigt",
	t2012: "Fel i larmreferensnummer eller larm inte konfigurerat",
	t2013: "Den ingående cgi-parametersträngen är för lång.",
	t2014: "Enhetens adress hittades inte",
	t2015: "Det gick inte att skriva tillstånd",
	t2016: "Ingen historik har konfigurerats",
	t2017: "Ingen historikdata returneras, kan vara tidsfel",
	t2018: "Skapa filfel",
	t2019: "Läs filfel",
	t2020: "Den maximala storleken på enhetslistan har överskridits",
	t2021: "inte använd",
	t2022: "Ogiltigt historikindex",
	t2023: "Kunde inte hitta board point index från nodetype, node, mod och point.",
	t2024: "Kunde inte hitta History Index",
	t2025: "Historikfrågan har inte initierats innan du försökte starta en historikfråga.",
	t2026: "En hq_start_query har försökts medan en historikfråga är aktiv",
	t2027: "Inga kommandoattribut hittades när några förväntas",
	t2028: "En början av epoken har tillhandahållits utan slut eller vice versa",
	t2029: "Kunde inte konvertera från tids- och datumformat till epoksekunder",
	t2030: "Kommandot kräver ett query_id men inget tillhandahålls",
	t2031: "Det gick inte att skapa XML-svar",
	t2032: "Det angivna query_id matchar inte en aktiv fråga",
	t2033: "Ett hq_get_data-kommando har försökts men data är ännu inte tillgänglig",
	t2034: "En samplingsfrekvens har tillhandahållits som inte är en av de tillåtna",
	t2035: "Medelvärdesintervallet är mindre än eller lika med samplingshastigheten med vilken data har registrerats",
	t2036: "Stoppepokens tid är mindre än startepokens tid eller stopptiden minus startepokens tid är mindre än medelvärdesintervallet",
	t2037: "Det angivna device_id matchar inte något som för närvarande är känt för ak255. Observera att endast enheter som har konfigurerats är kända för ak255",
	t2038: "Inga ID-attribut",
	t2039: "Ogiltiga id-attribut",
	t2040: "Inget schemanummer",
	t2041: "Ogiltigt schemanummer",
	t2042: "Inga schemadetaljer",
	t2043: "Saknar on_time",
	t2044: "Saknade timme",
	t2045: "Ogiltig timme",
	t2046: "Minut saknas",
	t2047: "Ogiltig minut",
	t2048: "Saknar off_time",
	t2049: "Saknar vardagar",
	t2050: "Ogiltiga vardagar",
	t2051: "Saknar helgdagar",
	t2052: "Ogiltiga helgdagar",
	t2053: "Saknas eller ogiltig holiday_start",
	t2054: "Saknas eller ogiltig holiday_end",
	t2055: "Saknas eller ogiltig holiday_open",
	t2056: "Saknas eller ogiltig holiday_close",
	t2057: "Semester inte konfigurerad",
	t2058: "Saknar beskrivning",
	t2059: "Kan inte rensa larmet",
	t2060: "Saknas eller är ogiltig store_open",
	t2061: "Saknas eller är ogiltig store_closed",
	t2062: "Larm inte konfigurerat",
	t2063: "Nod offline",
	t2064: "Saknat index",
	t2065: "Ogiltigt index",
	t2066: "Reläet kan inte sättas på",
	t2067: "Namnet som ändras har för många tecken. Om ändring av butiksnamn, eller enhetsnamn, måste vara < = 16 tecken, om ändring av storeId1 eller storeId2 måste vara < = 8 tecken.",
	t2068: "Saknade element associerade med kommandoelementet.",
	t2069: "Tag_ID inte definierat",
	t2070: "Saknade bladnod för data",
	t2071: "Ingen förändring i data",
	t2072: "Det gick inte att uppdatera databasen",
	t2073: "Ej skrivbar",
	t2074: "Shed meter fel",
	t2075: "Inga meter",
	t2076: "Ogiltig bodnivå",
	t2077: "Ogiltig typ",
	t2078: "Systemet upptaget",
	t2079: "Tillgångar är inte konfigurerade för belastningsskydd",
	t2080: "Ogiltig effektklassning för tillgång",
	t2081: "Ogiltig startfördröjning för tillgång",
	t2082: "Konfiguration Upptagen",
	t2083: "I/O-skanning pågår",
	t2084: "För många upptinningar pågår",
	t2085: "Försöker ladda en dålig databasversion till enheten",
	t2086: "Databasladdning misslyckades",
	t2087: "Inget tillgängligt webbminne tillgängligt",
	t2088: "Begäransvar inte konfigurerat (endast AK355-systemet)",
	t2089: "Filstorlek saknas eller är felaktig.",
	t2090: "Saknas kodad fil (b64-element)",
	t2091: "Det gick inte att spara databasen på RAM-disken.",
	t2092: "Filstorleken är för stor.",
	t2093: "Filnamn saknas.",
	t2094: "Fil-id saknas.",
	t2095: "Filladdningsindex saknas (Obs: detta är sekvensindexet för load_file load_database).",
	t2096: "Fil-id matchar inte eller saknas.",
	t2097: "Filladdning pågår redan.",
	t2098: "Avkodad fil matchar inte den ursprungliga filstorleken.",
	t2099: "Filladdningen avbröts eller startades inte.",
	t2100: "Ogiltig användarbegäran (den ursprungliga begäranden matchar inte IP-adressen)",
	t2101: "Ogiltig filladdningssekvens.",
	t2102: "Ogiltig katalog",
	t2103: "Ogiltig förlängning",
	t2104: "Ogiltig offset",
	t2105: "Fel vid kodning av filen",
	t2106: "Fel vid avkodning av fil",
	t2107: "Det gick inte att öppna filen för att läsa/skriva",
	t2108: "Det gick inte att skriva till filen",
	t2109: "Filen är skrivskyddad i Flash-filsystemet",
	t2110: "Det gick inte att ta bort filen.",
	t2111: "Kan inte ta bort mappen.",
	t2112: "Saknade enheter",
	t2113: "Inte alla enheter från URL hittades",
	t2114: "Saknade historikparametrar",
	t2115: "Inte alla historikparametrar från webbadressen hittades",
	t2200: "Den för närvarande använda programvaruversionen ( {{currentVersion}} ) rekommenderas inte",
	t2201: "Den för närvarande använda programvaruversionen ( {{currentVersion}} ) rekommenderas inte ( > {{recommendedVersion}} rekommenderas)",
	t2202: "Programvaruversionsfel",
	t2203: "Grafisk redaktör",
	t2204: "Redigera",
	t2205: "Rita",
	t2206: "Ny vy",
	t2207: "Skapad",
	t2208: "Släppa",
	t2209: "Ny grafik",
	t2210: "Skapa en ny grafisk vy",
	t2211: "Namn",
	t2212: "Döp om",
	t2214: "Exportera",
	t2213: "Duplicera",
	t2215: "Radera",
	t2216: "Är du säker på att du vill ta bort vyn \" {{name}} \"?",
	t2217: "Exportera alla vyer",
	t2218: "Mer...",
	t2219: "Regel",
	t2220: "regler",
	t2221: "Regelnamn",
	t2222: "Redigera panel",
	t2223: "Betingelser",
	t2224: "Lutning",
	t2225: "Invertera förhållanden",
	t2226: "Kartläggningar",
	t2227: "Karta former av",
	t2228: "Form ID",
	t2229: "stater",
	t2230: "Lägg till mappning",
	t2231: "Lägg till regel",
	t2232: "Operatör",
	t2233: "Lägg till villkor",
	t2234: "Börja med att lägga till regler",
	t2235: "Är du säker på att du vill ta bort regeln \" {{name}} \"?",
	t2236: "En kopia av regeln görs",
	t2237: "Webbplatsvyer",
	t2238: "Du måste skapa webbplatsvyer för att se dem",
	t2239: "Gå till grafisk editor",
	t2240: "Gå till detaljer",
	t2241: "Välj parameter",
	t2242: "Inga parametrar hittades",
	t2243: "alternativ",
	t2244: "På/av-ingång",
	t2245: "Reläutgång",
	t2246: "Sensoringång",
	t2247: "Variabel utgång",
	t2248: "Generisk enhet",
	t2249: "Tom nod",
	t2250: "Historikloggning stöds inte...",
	t2251: "Försök igen",
	t2252: "Det gick inte att hämta historiken",
	t2253: "Laddningshistoriken kan ta ett tag och stöder inte flera frågor samtidigt. Försök igen med denna begäran.",
	t2254: "Parameternamn",
	t2255: "Enhetens namn",
	t2256: "Enhetstyp",
	t2257: "Alla historikparametrar kunde inte laddas ( {{n}} misslyckades)",
	t2258: "Fel användarnamn eller lösenord",
	t2259: "Ingen kommunikation till styrenheten",
	t2260: "Programuppdatering",
	t2261: "Systemet",
	t2262: "Återställning av programvara",
	t2263: "Installera",
	t2264: "Återställningen av mjukvaran kommer att ersätta en nyare version",
	t2265: "Återställ programvara till säkerhetskopieringsversionen {{version}}",
	t2266: "Databas",
	t2267: "En uppdatering är tillgänglig",
	t2268: "Exportera databas",
	t2269: "Ladda ner",
	t2270: "Laddar ner databas",
	t2271: "Det går inte att exportera databasen",
	t2272: "Databasfil",
	t2999: "Kommunikationsfel",
	t2998: "Okänt fel",
	t3000: "Detaljer",
	t3001: "Tidigare XML-kod här",
	t3002: "Tilldela till",
	t3003: "stat",
	t3004: "Programvaruhantering",
	t3005: "Lokalt lagrad fil",
	t3006: "Online via internet",
	t3007: "Se till att strömmen inte bryts under uppdateringsprocessen. Det rekommenderas att du gör en säkerhetskopia av systemdatabasen innan du uppdaterar ditt system. Efter att ha slutfört mjukvaruuppdatering(ar) rekommenderas att inspektera systemets funktionsstatus för att bekräfta korrekt kontroll och funktion.",
	t3008: "Inga filer har valts",
	t3009: "Välj mjukvarufil",
	t3010: "Laddar upp filer",
	t3011: "Säkerhetskontroll",
	t3012: "Extraherar fil",
	t3013: "Kontrollerar fil",
	t3014: "Filuppladdning misslyckades!",
	t3015: "Säkerhetskontrollen misslyckades!",
	t3016: "Det gick inte att extrahera!",
	t3017: "Det gick inte att kontrollera filen!",
	t3018: "Laddar ned fil: {{fileName}}",
	t3019: "Filen har laddats ned!",
	t3020: "Nedladdning misslyckad!",
	t3021: "Ladda upp filer",
	t3022: "Ladda ner filer",
	t3023: "OS-uppdatering",
	t3024: "Edf uppdatering",
	t3025: "Ladda ner filer",
	t3026: "Värdet på den valda parametern var tyvärr inte tillgängligt",
	t3027: "Importera",
	t3028: "Minst en fil krävs",
	t3029: "Filen är inte giltig",
	t3030: "Hantera grafiska vyer",
	t3031: "Ladda upp",
	t3032: "Migrera",
	t3033: "Läser grafikfil(er), vänta...",
	t3034: "Importerar grafikfil, vänta...",
	t3035: "Välj grafik",
	t3036: "Rapporter",
	t3037: "Importera databas",
	t3038: "Välj databasfil",
	t3039: "Se till att strömmen inte bryts under återställningsprocessen. Det rekommenderas att göra en säkerhetskopia av systemdatabasen innan återställning av ditt system. Efter avslutad återställning av mjukvaran rekommenderas det att inspektera systemets funktionsstatus för att bekräfta korrekt kontroll och funktion.",
	t3040: "Systemhanteraren kommer att starta om efter uppdateringen",
	t3041: "Bygga ut",
	t3042: "Visa felsökningsmeddelanden",
	t3043: "Max. tillåtna tecken -",
	t3044: "Ladda upp uppdateringsfilen",
	t3045: "Aktuell SW-version",
	t3046: "Aktuell Danux-version",
	t3047: "Senaste SW-versionen",
	t3048: "Senaste Danux-versionen",
	t3049: "Säkerhetskopiera vänligen vänta",
	t3050: "Installerar filen vänligen vänta",
	t3051: "Installerar uppdatering",
	t3052: "Enheten startar om",
	t3053: "Startar om systemhanteraren",
	t3054: "Webbläsaren kommer att uppdateras nu",
	t3055: "Programvaran har installerats",
	t3056: "Uppdateringen misslyckades, följande fel inträffade:",
	t3057: "Säkerhetskopiera den aktuella mjukvaruversionen",
	t3058: "Säkerhetskopiera de aktuella EDF-filerna",
	t3059: "Grafisk vy har sparats",
	t3060: "Använd bakgrundsbild",
	t3061: "Rensa",
	t3062: "Göra om",
	t3063: "Lagra",
	t3064: "Motgångar i kretsnatten",
	t3065: "Sug nattbakslag",
	t3066: "Nedladdat version {{fileName}}",
	t3067: "För att uppdatera de valda enheterna, tryck på 'Installera'",
	t3068: "Återställ versionen {{updateType}}",
	t3069: "Installerar",
	t3070: "Installera återställningsversion",
	t3071: "Ingen av de tillgängliga enheterna innehåller någon återställningsversion",
	t3072: "Ny version tillgänglig online",
	t3073: "Ladda upp fil för valda enheter",
	t3074: "Ladda upp fil för vald enhet",
	t3075: "Inga återställningsversioner tillgängliga för enheten",
	t3076: "Nedladdningen är klar",
	t3077: "Aktuell version {{updateType}}",
	t3078: "Senaste versionen {{updateType}}",
	t3079: "Uppdatering pågår, är du säker på att du vill stänga fönstret?",
	t3080: "Danux är inte uppdaterad! Uppdatera Danux och uppdatera sedan programvaran igen",
	t3081: "Applikationer kan inte nedgraderas till denna version",
	t3082: "Ansökningsinformation",
	t3083: "Release Notes",
	t3084: "Villkor & Sekretess",
	t3085: "Licenser",
	t3086: "Vänligen gå till konfiguration för att ställa in scheman",
	t3087: "Tyvärr inträffade ett fel när migreringen förbereddes. Se till att alla enheter är tillgängliga i nätverket och korrekt konfigurerade. \n\n Följande fel inträffade:\n {{error}}",
	t3088: "Importera eller migrera en befintlig grafik",
	t3089: "Visa",
	t3090: "Tillgångens namn",
	t3091: "Kollaps",
	t3092: "Importen slutförd",
	t3093: "Utsläppssäkerhet",
	t3094: "Sugsäkerhet",
	t3095: "Urladdning Cutin",
	t3096: "Urladdningsurskärning",
	t3097: "Sugutskärning",
	t3098: "Suction Cutin",
	t3099: "Kapacitet",
	t3100: "Migreringsfilerna (flp, dpj eller json) kunde inte hittas",
	t3101: "Timeout-analysfil",
	t3102: "Tredje parts enhetsfiler och adresser",
	t3103: "Nät",
	t3104: "Låsa",
	t3105: "Xml",
	t3106: "Välj parameter som ska visas",
	t3107: "Enhetsparameter",
	t3108: "Inga enheter",
	t3109: "Det finns ingen enhet(er) hittades eller laddade",
	t3110: "HACCP",
	t3111: "TILLGÅNG",
	t3112: "Inga haccp-data tillgängliga",
	t3113: "Enhetsinformation",
	t3114: "Uppdateringar tillgängliga",
	t3115: "Gå till Programvara",
	t3116: "Ej ansluten",
	t3117: "Det aktuella åtkomstomfånget för denna applikation är ' {{targetLevel}} ', den angivna användaren är över detta omfång vad gäller åtkomsträttigheter",
	t3118: "Exporterar PDF",
	t3119: "PDF-export misslyckades",
	t3120: "På stora datamängder kan gränssnittet inte svara och ta ett tag att slutföra",
	t3121: "Välj scheman från listan",
	t3122: "Vänligen gå till konfiguration för att ställa in ett schema",
	t3123: "Utfört",
	t3124: "Varning",
	t3125: "Webbplatsinformation",
	t3126: "Vänligen välj tid och datapunkter från listan",
	t3127: "Ansluter",
	t3128: "Det gick inte att upprätta anslutning till denna webbplats, kontakta supporten",
	t3129: "Inga HACCP-data fanns tillgängliga på det valda datumet. Se till att historik och tid är korrekt konfigurerade i konfigurationsmenyn",
	t3130: "Diagnostisk rapport",
	t3131: "Välj enhet(er) för export av diagnostikrapporter",
	t3132: "Ej tillgängligt på denna användaråtkomstnivå",
	t3133: "Diagnostiklogg",
	t3134: "Det gick inte att ladda ned rapporten",
	t3135: "Font",
	t3136: "textstorlek",
	t3137: "Inga vyer att migrera",
	t3138: "Ladda ner skrivbordsversionen",
	t3139: "På grund av säkerhetsrestriktioner i moderna webbläsare (",
	t3140: "RFC1918",
	t3141: ") är det tyvärr inte möjligt att ansluta till enheter inom ett privat nätverksutrymme med okrypterad HTTP.",
	t3142: "Ingen data tillgänglig för den valda perioden",
	t3143: "Konfigurera din systemhanterare för att använda HTTPS eller använd skrivbordsversionen.",
	t3144: "Vi kunde inte kommunicera med systemansvarig. Detta betyder antingen att din enhet inte kan ansluta till IP- eller värdnamnet för systemhanteraren, eller att du kan behöva lita på SSL-certifikatet som används på enheten manuellt.",
	t3145: "Klicka på följande länk för att öppna en ny flik för att lita på certifikatet och återgå sedan till den här skärmen.",
	t3146: "Signatur",
	t3147: "DI - digitala ingångar",
	t3148: "AI - Analog ingång",
	t3149: "Gå till HACCP-konfiguration",
	t3150: "Inga data",
	t3151: "Säkerhetskopieringen sparas i systemhanteraren för återställningsfunktionen",
	t3152: "Välj vad som ska visas i webbplatsvy(er)",
	t3153: "Tillgångsenhet",
	t3154: "Grafiska filer",
	t3155: "Tyvärr inträffade ett fel när edf-filerna och versionerna hämtades från systemhanteraren...",
	t3156: "För att välja vilka filer som ska installeras, tryck på knappen \"Välj filer\".",
	t3157: "Välj filer",
	t3158: "Tar bort säkerhetskopian",
	t3159: "Staging filer",
	t3160: "Säkerhetskopieringen sparas i systemhanteraren för återställningsfunktionen",
	t3161: "Installerad storlek",
	t3162: "Ny storlek",
	t3163: "Installationsdatum",
	t3164: "Nytt datum",
	t3165: "Sensorer",
	t3166: "Reläer",
	t3167: "Ingångar",
	t3168: "Outs",
	t3169: "Meter",
	t3170: "Generisk",
	t3171: "Lösenordet har upphört att gälla, ange ett nytt lösenord",
	t3172: "Nytt lösenord",
	t3173: "Bekräfta lösenord",
	t3174: "ändra lösenord",
	t3175: "Lösenordet måste innehålla minst 8 tecken, 1 versal, 1 gemen, 1 siffra, 1 specialtecken)",
	t3176: "Lösenorden matchar inte",
	t3177: "Lösenordet kan inte vara detsamma som det gamla lösenordet",
	t3178: "Lösenordsändringen lyckades",
	t3179: "Lösenordet kan inte ändras. Fel från enheten",
	t3180: "Återställning av lösenord",
	t3181: "Genererad",
	t3182: "Sidnamn",
	t3183: "Händelse-ID",
	t3184: "Tid",
	t3185: "Roll",
	t3186: "Event-information",
	t3187: "Kvittera valda larm",
	t3188: "Kvittera alla valda larm",
	t3189: "Importera databas till",
	t3190: "Importerar databas",
	t3191: "Filtypen ska vara .zip",
	t3192: "Välj fil",
	t3193: "Öppettider",
	t3194: "Högtider",
	t3195: "På/av-scheman",
	t3196: "Tillgång",
	t3197: "Du har osparade ändringar. Är du säker på att du vill lämna?",
	t3198: "Tyvärr misslyckades migreringen. Systemet är för närvarande upptaget. Vänligen försök igen senare",
	t3199: "Ladda upp bild som justerar till nuvarande mått",
	t3200: "Byt ut bakgrundsbilden",
	t3201: "Ersätt bakgrundsbilder med skalbar vektorgrafik.",
	t3202: "Endast SVG-filer med samma namn som de befintliga filerna ersätts.",
	t3203: "Välj fil(er) som ska ersättas",
	t3204: "Bildfiler",
	t3205: "kan inte behandlas. Filen har ogiltigt format.",
	t3206: "{{amount}} fil(er) har ersatts",
	t3207: "{{amount}} fil(er) kunde inte ersättas",
	t3208: "Vyn har ingen bakgrund",
	t3209: "Du kan ladda upp en ny eller ersätta en befintlig bakgrundsbild",
	t3210: "Om du byter ut en bakgrundsbild kan parameterpositionerna behållas om:",
	t3211: "En png- eller jpg-fil har samma upplösning som den aktuella bakgrunden",
	t3212: "En svg-fil har samma bildförhållande (men inte nödvändigtvis samma upplösning)",
	t3213: "Funktionen är inaktiverad",
	t3214: "Visar för närvarande senast mottagna värde, uppdaterar...",
	t3215: "Ladda upp en grafikfil skapad i",
	t3216: "(frivillig)",
	t3217: "Bakgrundsbild",
	t3218: "Bläddra",
	t3219: "Mer...",
	t3220: "Lägg till en bakgrundsbild (valfritt)",
	t3221: "Grafisk fil",
	t3222: "Timeout i kommunikation",
	t3223: "Inga kylscheman har konfigurerats",
	t3224: "Index",
	t3225: "Typ",
	t3226: "Högtider",
	t3227: "dagar",
	t3228: "På",
	t3229: "Av",
	t3230: "ID",
	t3231: "Öppna",
	t3232: "Start",
	t3233: "Slutet",
	t3234: "Beskrivning",
	t3235: "Dag",
	t3236: "Stänga",
	t3237: "Edf-fil(er) har laddats upp. Startar om systemhanteraren",
	t3238: "Uppladdning av Edf-fil(er) slutförd, systemhanteraren har återställts",
	t3239: "Fel vid hämtning av edf-fil(er) från systemhanteraren",
	t3240: "Uppladdning av Edf-fil(er) misslyckades. Försök efter ett tag",
	t3241: "Inga kylscheman har konfigurerats",
	t3242: "Ladda upp filen till systemhanteraren",
	t3243: "Ange enhetstyp - påverkar de parametertaggar som krävs för att använda den",
	t3244: "Ange hur enhetens värden nås och vilken funktion de fyller",
	t3245: "Skapa ett larm baserat på lite av en parameter",
	t3246: "Etiketter",
	t3247: "Skapa en lista med etiketter som kan tilldelas en parameter",
	t3248: "Grupper",
	t3249: "Ange parametergrupper",
	t3250: "Registreringsnummer",
	t3251: "Data typ",
	t3252: "Lista över etiketter",
	t3253: "Minimum",
	t3254: "Standard",
	t3255: "Maximal",
	t3256: "Registreringstyp",
	t3257: "Registertypen översätts till motsvarande funktionskoder för läsning och skrivning",
	t3258: "Bytsläge",
	t3259: "För ett värde med bytes ABCD som skickas till enheten byter detta alternativ byte (BADC), ord (CDAB) eller båda (DCBA).",
	t3260: "Endast läs",
	t3261: "Skogsavverkning",
	t3262: "Aktiverar historikloggning för denna parameter",
	t3263: "Ta över text från parameter till larm",
	t3264: "Placering av larmflaggan räknar från dem minst signifikanta biten av parametern",
	t3265: "Utlösa larm vid lågt värde",
	t3266: "Märka",
	t3267: "Dölj",
	t3268: "{{field}} är ett obligatoriskt fält.",
	t3269: "Maximum måste vara större eller lika med minimum",
	t3270: "Standardvärdet måste vara i [Minimum, Maximum]",
	t3271: "Datatypen måste vara {{reg}} för den valda registertypen {{mt}}",
	t3272: "Namnet får inte innehålla avgränsningstecken",
	t3273: "Namnet får inte överstiga 20 tecken",
	t3274: "Listan över etiketter får inte vara tom",
	t3275: "Taggen/taggarna {{taglist}} måste tilldelas exakt en gång.",
	t3276: "Gruppen/ {{grouplist}} innehåller fler än de maximala 50 parametrarna.",
	t3277: "Formuläret innehåller fel, vänligen granska felen och försök igen.",
	t3278: "Skicka in",
	t3279: "För att aktivera den uppladdade EDF:n måste du återställa enheten. Vill du återställa enheten nu?",
	t3280: "Återställ nu",
	t3281: "Återställ senare",
	t3282: "{{file}} finns redan. Vill du skriva över det?",
	t3283: "Ladda upp {{what}}",
	t3284: "Etiketter och värden måste vara unika.",
	t3285: "Mång",
	t3286: "Enheten hanterar flera fodral eller förpackningar",
	t3287: "Taggar",
	t3288: "Internet Explorer är uttjänt och stöds inte av StoreviewWeb",
	t3289: "Byt till en webbläsare som stöds, antingen Edge, Chrome eller Firefox",
	t3290: "Mer information",
	t3291: "Stänga",
	t3292: "Det gick inte att läsa data, försök igen",
	t3293: "Säkerhetskopiering av EDF pågår",
	t3294: "Det finns inga vyer att migrera",
	t3295: "Systemet är konfigurerat med en publik IP i det privata IP-fältet",
	t3296: "Systemet är konfigurerat med en privat IP i det offentliga IP-fältet, systemet kanske inte är tillgängligt på distans eller vissa funktioner kanske inte fungerar korrekt",
	t3297: "Du är ansluten via en IP eller värdnamn som inte är känt för systemhanteraren, troligen via en proxytjänst, vissa funktioner kanske inte fungerar korrekt",
	t3298: "Enheten är offline, visar det senaste kända värdet",
	t3299: "Enhetsintegration",
	t3300: "Taggen/taggarna {{taglist}} är duplicerade.",
	t3301: "sidan hittas inte",
	t3302: "Vi är ledsna, sidan du begärde kunde inte hittas",
	t3303: "Felkod: 404",
	t3304: "Gå till instrumentpanelen",
	t3305: "Titta enbart",
	t3306: "Intern IP-adress",
	t3307: "Extern IP-adress",
	t3308: "Domän namn",
	t3309: "Inga varningar",
	t3310: "Flera varningar",
	t3311: "Det ser ut som att systemet är konfigurerat med flera enheter men vi kunde inte kontakta en eller flera av slavenheterna, se till att inställningarna är korrekta. Observera att en multi-unit setup inte stöder flera domännamn och att varje enhet måste konfigureras med en distinkt port när du använder samma domän",
	t3312: "Titta enbart",
	t3313: "Detta element har ingen koppling till någon parameter och kan säkert tas bort",
	t3314: "Ta bort parameter",
	t3315: "Schema",
	t3316: "Du kommer nu att loggas ut och måste logga in med det nya lösenordet",
	t759: "Databas nedladdad, återställning och uppgraderingsenhet. Detta kan ta flera minuter.",
	t816: "Larmtyp:",
	t818: "Räkna:",
	t848: "DB-filen skapas inte. Du har begränsad tillgång till den installerade katalogen. Kontakta systemadministratören.",
	t3317: "Inga scheman att exportera",
	t3318: "Väntar på svar från systemet",
	t3319: "Ingen nätverksaktivitet",
	t3320: "Affärsnamn",
	t3321: "Ny version {{latestVersion}} tillgänglig online",
	t3322: "Extern port",
	t3323: "Intern port",
	t3324: "Vi kan inte kommunicera med systemansvarig på",
	t3325: "kanske certifikatet måste litas på?",
	t3326: "Fil med detta format kan inte tas bort",
	t3327: "Användande",
	t3328: "I tid",
	t3329: "Ledig tid",
	t3330: "Vardagar",
	t3331: "Kylningsstadiet",
	t3332: "Värmeåtervinning",
	t3333: "Kyl",
	t3334: "Värme",
	t3335: "Extra värme",
	t3336: "Avfuktning",
	t3337: "Internet",
	t3338: "Meddelanden",
	t3339: "Kontakter",
	t3340: "Comm",
	t3341: "Inga föremål hittades",
	t3342: "Skriv för att söka",
	t3408: "Aktivera alla",
	t3409: "Inaktivera alla",
	t3410: "Normal",
	t3411: "Svår",
	t3412: "Kritisk",
	t3413: "Aktivera alla larm",
	t3414: "Sök efter larm",
	t3415: "Aktiverad",
	t3417: "Fel vid hämtning av larm",
	t3418: "Fel vid hämtning av enheter att kopiera",
	t3419: "Fel vid kontroll av kopieringsstatus",
	t3420: "Auto polling kommer att återupptas efter max 2 timmars timeout, eller om guiden avbryts eller avslutas",
	t3421: "Kopiera till kontrollanter",
	t3422: "Kopierar till den eller de valda enheterna",
	t3423: "Kopieringsfunktionen kopierar först inställningarna till System Manager-databasen och laddar sedan ner inställningarna till styrenheterna. Det kan ta lite tid att slutföra åtgärden.",
	t3424: "Inga enheter har valts att kopiera",
	t3425: "Dubbelklicka för att kopiera",
	t3426: "Fel vid hämtning av enheter",
	t3427: "Enheter för kopiering",
	t3428: "Välj enheten/enheterna för kopiering",
	t3429: "Endast styrenheter av samma typ kommer att visas här",
	t3430: "Kopieringsprocessen lyckades",
	t3431: "Ogiltig fil. Förväntad filmodell:",
	t3432: "Fel vid laddning av inställningsfil",
	t3435: "Är du säker på att flytta den valda enheten till den här gruppen {{toPackGroup}} ?",
	t3343: "Upptäckt",
	t3344: "Uppdatera tabell",
	t3345: "Skannar nätverk, vänta...",
	t3346: "Nätverkstabellen uppdateras",
	t3347: "Fel vid uppdatering av nätverk",
	t3348: "Fel vid omsökning av nätverk",
	t3349: "Fel vid hämtning av nätverk",
	t3350: "Nätverksskanning slutförd",
	t3351: "Inga tillgängliga enheter",
	t3352: "Skanna nätverket",
	t3353: "Uncfg",
	t3354: "Varje omarrangering av enheter i guiden kan påverka Alsense-övervakningstjänsterna.",
	t3355: "Nätverk",
	t3356: "Välj fältbusstyp",
	t3357: "Om Modbus/RS485 är aktiverat kan du välja SLV för Danfoss kompressor med variabel hastighet. När du väljer SNMP som en lokal buss måste du ange ett giltigt subnätadressintervall av Danfoss SNMP-kontroller",
	t3358: "Kompressorer/paket",
	t3359: "Hantera kompressorer/paketstyrenheter",
	t3360: "Endast Pack-enheter kan hanteras här - inte Rack I/O-system. Kompatibla Multi-Suction Pack-kontroller som har skannats kommer initialt att visas som 0 suggrupper. AK-ST 500 (Service Tool) krävs för att konfigurera Pack-styrenheten.",
	t3361: "Belopp att kopiera",
	t3362: "Om du minskar tidigare konfigurerad suggrupp kommer även alla associerade avdunstningskontroller att tas bort för den suggruppen.",
	t3363: "Antal suggrupp kan inte vara noll, minimum antal. av suggruppen bör vara minst en.",
	t3364: "Fel vid hämtning av kompressorer/paket",
	t3365: "Inga kompressorer/paket tillgängliga",
	t3366: "On-line enheter",
	t3367: "Off-line-enheter",
	t3369: "Lägg till paket",
	t3370: "Om du väljer Modbus/RS485 kommer du att se ytterligare alternativ för SLV/ECFan, dessa används för Refrigeration och kan väljas till Nej.",
	t3371: "Lägg till krets",
	t3372: "Lägg till, kopiera eller ta bort enheter. Observera att inte alla enhetstyper tillåter redigering av alla fält och adresserna för I/O-punkter ställs in i konfigurationen.",
	t3373: "Hantera ärendekontrollanter",
	t3374: "Lägg till en enhet",
	t3375: "Hantera kompressor-/packkontroller",
	t3376: "Fel i dataåtkomst",
	t3377: "VVS-layout har tillämpats",
	t3378: "Layout VVS",
	t3379: "Är du säker på att du vill lämna den här sidan?",
	t3380: "Dina inställningar kan gå förlorade",
	t3381: "Vänligen konfigurera en enhet",
	t3382: "Avsluta snabbinställning",
	t3383: "Är du säker på att du vill avsluta snabbinställningen?",
	t3384: "Gateways (AK-PI200)",
	t3385: "Välj de hittade gateways",
	t3386: "IP-adress",
	t3387: "Källkontroller",
	t3388: "Källinställningar",
	t3389: "Källlarm",
	t3390: "Källdiagram",
	t3392: "Diagram",
	t3393: "Enheter",
	t3394: "Auto polling avbryts under kopieringsfasen. Huvudströmbrytaren för käll- och målenhet(er) kommer att stängas AV och återgå till det ursprungliga tillståndet när kopieringen är klar. Se till att kontrollera huvudströmbrytaren efter att kopieringsguiden är klar. Om någon av de valda enheterna är i aktivt larm, kommer alla ändringar av larmåtgärden för den specifika punkten inte att ändras. Den ursprungliga inställningen kommer att finnas kvar.",
	t3395: "Välj en källenhet att kopiera inställningar från",
	t3396: "I tid",
	t3397: "Kopieringsfunktionen kopierar tillgängliga grafpunkter tills enhetens kapacitet uppnås. Gå till Konfiguration för att se antalet definierade punkter",
	t3398: "Källenhetsgrafik",
	t3399: "Konfigurera grafpunkter för källstyrenheten",
	t3400: "Aktivera HACCP för att logga punkterna i en HACCP-temperaturrapport",
	t3401: "Sök efter poäng",
	t3402: "Inga data tillgängliga",
	t3403: "Fel vid hämtning av grafpunkter",
	t3404: "Inaktiverad",
	t3405: "Källenhetslarm",
	t3406: "Konfigurera larmdetaljer i källstyrenheten",
	t3407: "Larmnamn",
	t3436: "Enheten kommer att tas bort från den tilldelade gruppen [ {{fromPackGroup}} ]",
	t3437: "Layout Kylning",
	t3438: "Kyllayouten har tillämpats framgångsrikt",
	t3439: "Tilldela om",
	t3440: "Tilldela fallkontroller för att packa kontroller",
	t3441: "Tilldela Case-kontroller (från tillgängliga fall) till Kompressor/Pack-kontroller. bildar suggrupper",
	t3442: "Enhetsparametrar",
	t3443: "Välj parametrar för kopiering",
	t3444: "När du arbetar på en live (online) enhet kan det bli en fördröjning i inställningarna, på grund av kommunikation till enheten.",
	t3445: "Välj larm för kopiering",
	t3446: "Enhetsdiagram",
	t3447: "Välj grafpunkter för kopiering",
	t3448: "Spara aktuella enhetsinställningar till en fil",
	t3449: "Du kan spara de aktuella enhetsinställningarna i en fil som kan användas för att konfigurera enheter vid ett senare tillfälle. Om du vill avsluta guiden efter att filen har sparats, använd exit-ikonen. Om du arbetar med anslutna enheter kommer att avsluta att säkerställa att nätverkshämtning och enhetens huvudbrytare återgår till korrekt status.",
	t3450: "Inställningsfil(er) har sparats",
	t3451: "Vill du fortsätta eller avsluta snabbinställningen?",
	t3452: "Sparar fil(er)",
	t3453: "Fel vid hämtning av inställningar",
	t3454: "Källenhetsinställningar",
	t3455: "Konfigurera enhetsparametrar i källstyrenheten",
	t3456: "Inget av lösenordsfälten ska vara tomt",
	t3457: "Senaste",
	t3458: "Adressbok",
	t3459: "Senast åtkomst",
	t3460: "Ta en säkerhetskopia av adressboken regelbundet.",
	t3461: "Adressboken har importerats",
	t3462: "Importera adressbok",
	t3463: "Exportera Adressbok",
	t3464: "Lägg till anläggning",
	t3465: "Redigera webbplats",
	t3466: "Ta bort webbplats",
	t3467: "Du måste skapa ett lösenord för adressboken innan du börjar använda adressboken",
	t3468: "Webbplatsen har lagts till i din adressbok",
	t3469: "Redan i adressboken",
	t3470: "Lägg till i adressboken",
	t3471: "Logga in på webbplatsen",
	t3472: "Webbplatsinformationen har uppdaterats",
	t3474: "Underrättelse",
	t3475: "Webbplatsen har tagits bort från din adressbok",
	t3476: "Webbplatsen har uppdaterats",
	t3477: "Ogiltigt adressboksformat",
	t3478: "Åtgärder",
	t3479: "Inte tillgängligt när du är offline",
	t3480: "Det gick inte att ladda S55-databasen",
	t3481: "Laddar S55-databas",
	t3482: "Protokoll",
	t3483: "Användaren har inte behörighet att se programvaruinformation",
	t3484: "Lösenordet måste endast vara alfanumeriskt eller numeriskt",
	t3485: "Du måste skapa ett lösenord för adressboken för att komma åt adressboken",
	t3486: "Adressbokens lösenord är inte korrekt",
	t3487: "Webbplatsen finns redan i adressboken",
	t3488: "Skapa ett lösenord för adressboken för att använda adressboken. Lösenordet måste endast vara alfanumeriskt eller numeriskt",
	t3489: "Ange ditt lösenord till adressboken",
	t3490: "Inga onlineenheter tillgängliga att kopiera",
	t3491: "Skapa lösenord för adressboken",
	t3492: "Uppdatera lösenordet för adressboken",
	t3493: "Webbplatslösenordet får inte vara tomt",
	t3494: "Skriv in lösenordet för adressboken som importeras",
	t3495: "Lösenordet för adressboken har skapats",
	t3496: "Lösenordet för adressboken har uppdaterats",
	t3497: "Lösenordet för adressboken som importeras är inte korrekt",
	t3498: "Vänligen ange webbplatsens lösenord",
	t3499: "Rensa adressboken",
	t3500: "Sök på webbplatser",
	t3501: "Välj adressboksfil för att importera",
	t3502: "Välj nyckelfil för att dekryptera adressboken",
	t3503: "Tryck på exportera för att ladda ner den krypterade adressboken",
	t3505: "Med krypterad",
	t3506: "okrypterade lösenord",
	t3507: "Ogiltig hemlig nyckel",
	t3508: "Import misslyckades. Se till att du har importerat rätt nyckel",
	t3509: "Inga tillgängliga versioner",
	t3510: "Öppna Alsense Services (kräver prenumeration)",
	t3511: "Läs igenom EULA-villkoren noggrant. Om du inte kan acceptera villkoren får du inte använda applikationen.",
	t3512: "Adressboken och ditt lösenord för adressboken har rensats",
	t3513: "(nödvändig)",
	t3515: "Adressboken är tom",
	t3516: "Lösenordet för adressboken får inte vara tomt",
	t3519: "När du rensar adressboken tar du också bort ditt lösenord för adressboken.",
	t3520: "Inte inom räckhåll. Bör vara mellan 0 och {{maxAddr}}",
	t3521: "Tryck på \"Exportera\" för att ladda ner alla grafiska vyer som är lagrade i Systemhanteraren",
	t3522: "Tryck på \"Ladda ner\" för att ladda ner en systemrapport",
	t3523: "Endast masterenheten är tillgänglig på en direkt WiFi-anslutning",
	t3524: "Det finns inga lokala grafiska vyer. Lägg till nya eller importera befintliga vyer",
	t3525: "Det finns inga avlägsna grafiska vyer. Försök att söka med ett annat namn",
	t3526: "Det finns inga avlägsna grafiska vyer. Lägg till nya, importera eller migrera befintliga vyer",
	t3527: "Lokala vyer ( {{totalFiles}} av 5)",
	t3528: "{{totalFiles}} lokal grafik av maximalt 5",
	t3529: "Fjärrvyer",
	t3530: "Filen är inte en bitmapp.",
	t3531: "Felaktig bitmappslängd.",
	t3532: "Felaktig bitmappshuvudstorlek.",
	t3533: "Bitmappsstorleken är felaktig.",
	t3534: "Fel i palettstorlek.",
	t3535: "Inkompatibel .bmp-fil",
	t3536: "Fel vid borttagning av lokal grafikfil",
	t3537: "Fel vid duplicering av lokal grafikfil",
	t3538: "Fel vid export av lokal grafikfil",
	t3539: "Ta bort grafik",
	t3540: "Ta bort en lokal grafikfil",
	t3541: "Radering av lokal grafikfil pågår",
	t3542: "Lokal grafikfil har raderats. Startar om System Manager",
	t3543: "Radering av lokal grafikfil slutförd. Systemhanteraren har startats om",
	t3544: "Duplicera grafik",
	t3545: "Duplicera en lokal grafikfil",
	t3546: "Lokal grafikfil duplikat pågår",
	t3547: "Lokal grafikfil har duplicerats. Startar om System Manager",
	t3548: "Duplicering av lokal grafikfil är klar. Systemhanteraren har startats om",
	t3549: "Exportera grafik",
	t3550: "Exportera en lokal grafikfil",
	t3551: "Export av lokal grafikfil pågår",
	t3552: "Lokal grafikfil har exporterats",
	t3553: "Systemhanteraren kommer att starta om den här åtgärden",
	t3554: "Är du säker på att du vill ta bort",
	t3555: "Är du säker på att du vill duplicera",
	t3556: "Tryck på \"Exportera\" för att ladda ner alla lokala grafiska vyer lagrade i systemhanteraren",
	t3557: "Export av lokala grafikfiler pågår",
	t3558: "Lokala grafikfiler har exporterats",
	t3559: "Produktkod",
	t3560: "Få mer information om denna produkt",
	t3561: "Fläkt eko",
	t3562: "Ventilöppning",
	t3563: "Överhettning",
	t3564: "Förångarens tryck",
	t3565: "Förångarens temperatur",
	t3566: "Utklippsfigur",
	t3567: "Cutin",
	t3568: "Falldörr",
	t3569: "Sugtemperatur",
	t3570: "Rälsvärme",
	t3571: "Den högsta tillåtna filgränsen har nåtts. Avmarkera fil(er) att importera",
	t3572: "Ta bort befintlig(a) fil(er) i systemhanteraren för att importera filer",
	t3573: "Lokal grafikfil har importerats. Startar om System Manager",
	t3574: "Import av lokala grafikfiler har slutförts. Systemhanteraren har startats om",
	t3575: "Den högsta tillåtna filgränsen har nåtts. Ta bort fil(er) att importera",
	t3576: "Maximalt tillåtna grafikfiler är 5",
	t3577: "Lokal grafikfil har laddats upp. Startar om System Manager",
	t3578: "Uppladdningen av lokal grafikfil slutförd. Systemhanteraren har startats om",
	t3579: "Ogiltigt filformat. ladda upp filen {{supportedFileFormats}}",
	t3580: "Fildimensionen måste vara {{maxWidthAllowed}} x {{maxHeightAllowed}} pixlar för lokala skärmar",
	t3581: "Fjärrvy",
	t3582: "Lokal vy",
	t3583: "Storeview webbsida",
	t3584: "EULA-villkor",
	t3585: "Säkerhet",
	t3586: "Säkerhetsupprätthållande nivåer",
	t3587: "Bakåtkompatibel (lägsta säkerhetsnivå - förutsatt att användningshuvudautentisering är inaktiverad)",
	t3588: "SvW / XML-förfrågningar innehåller användarnamn och lösenord.",
	t3589: "Ingen sanering av förfrågningar, samma säkerhet som tidigare releaser",
	t3590: "Inga ändringar behövs på SvW eller befintliga XML-anslutningar",
	t3591: "Tillåtande (kan användas som en övergång till strikt nivå)",
	t3592: "XML-förfrågningar kan innehålla användarnamn och lösenord och eller en sessionstoken. Sessionstoken tas emot som svar på inloggningsförfrågan.",
	t3593: "Om sessionstoken tillhandahålls i AKSM-auth-huvudet ignoreras användarnamn och lösenord",
	t3594: "XML-förfrågan saneras, en varning returneras i svaret på begäran om några problem upptäcks",
	t3595: "Notera dessa varningar som förberedelse för att byta till strikt läge",
	t3596: "Om sessionstoken inte används kan sessionsutgång inte tillämpas",
	t3597: "Stöds redan i fjärrgränssnittet (SvW)",
	t3598: "Möjliga/Obligatoriska ändringar från tredje parts XML-perspektiv",
	t3599: "Ingen användning av sessionstoken - inga ändringar behövs",
	t3600: "Användning av sessionstoken - ta bort användarnamn/lösenord från kroppen och observera varningar inför Strict",
	t3601: "Strikt (högsta säkerhetsnivå)",
	t3602: "XML-förfrågningar kan inte innehålla användarnamn och lösenord, men måste tillhandahålla sessionstoken i AKSM-auth-huvudet. Om ett problem upptäcks i begäran avvisas det",
	t3603: "Stöds redan i (SvW)",
	t3604: "Krävda ändringar ur tredje parts perspektiv",
	t3605: "användarnamn och lösenord måste tas bort från XML-begärans nyttolast och sessionstoken måste placeras i en CORS-header som heter AKSM-auth",
	t3606: "Inloggningsfel Back-off",
	t3607: "Hur länge (sekunder) en fjärranvändare väntar på att kunna försöka logga in igen efter ett misslyckat försök. Väntetiden ökar kumulativt för varje misslyckat försök. Efter 10 försök blockeras IP-adressen från vilken användaren försökte logga in i 24 timmar. Om inställt på 0 Inloggningsfel är back-off inaktiverat",
	t3608: "Standard 30s (max 600s / 0 = inaktiverad)",
	t3609: "Gratis försök",
	t3610: "Hur många inloggningsförsök som tillåts innan back-off tillämpas",
	t3611: "Standard 2 (max 5)",
	t3612: "Blockera",
	t3613: "Om aktiverat blockeras Användare + IP under back-off, annars blockeras Användaren",
	t3614: "Standard \"Användare\" (Användare + IP / Användare)",
	t3615: "Sessioner per användare",
	t3616: "En session är där en användare framgångsrikt har loggat in på enheten och befinner sig inom fjärrgränssnittet (SvW)",
	t3617: "Antal aktiva sessioner en användare kan ha. Om den är inställd på 0 kan en användare ha det maximala antalet sessioner (30)",
	t3618: "Standard 0",
	t3619: "Session utgång",
	t3620: "Hur många sekunder efter att en inaktiv session löper ut. Om en session löper ut krävs en ny inloggning",
	t3621: "Standard 900-tal (max 3 600)",
	t3622: "Rubrikautentisering",
	t3623: "När du aktiverar HTTPS och Header Authentication måste alla XML-förfrågningar från tredje part skicka användarnamn/lösenordsuppgifter i rubriken (grundläggande autentiseringsschema)",
	t3624: "Aktivering av 'Använd headerautentisering' kräver att systemet återinitieras. Använd knappen 'Tryck för att initiera' för att utföra denna åtgärd",
	t3625: "Små",
	t3626: "Stor",
	t3627: "Spara grafik",
	t3628: "Spara en lokal grafikfil",
	t3629: "Lokal grafikfil har raderats. Lokal grafikfil laddas in",
	t3630: "Den lokala grafikfilen har laddats. Startar om System Manager",
	t3631: "Sparandet av den lokala grafikfilen är klar. Systemhanteraren har startats om",
	t3632: "Är du säker på att du vill spara?",
	t3633: "Parsningen misslyckades",
	t3634: "Kunde inte analysera viz-fil",
	t3635: "Kunde inte analysera vz2-filen",
	t3636: "Öppnande av Viz-fil misslyckades:",
	t3637: "Migreringsfilerna (vz2, dpj eller json) kunde inte hittas",
	t3638: "Migreringsfilerna (dvs.) kunde inte hittas",
	t3639: "Systemhanteraren kommer att starta om på denna åtgärd. Systemhanteraren är i antingen tillåtande eller strikt läge så sessionstoken kommer att upphöra vid omstart och du kommer att loggas ut från systemet",
	t3643: "Utloppstryck",
	t3644: "Totalt kW",
	t3645: "Totalt KVAR",
	t3646: "Totalt Kvarh+",
	t3647: "Efterfrågan",
	t3648: "Effektfaktor",
	t3649: "Teckensnittsfärg",
	t3650: "Bakgrundsfärg",
	t3651: "Adressboken har exporterats",
	t3652: "Kontrollläge",
	t3653: "Sc3 Utetemperatur",
	t3654: "Utloppstemperatur",
	t3655: "Kondensorns körkapacitet",
	t3656: "Inga data ska exporteras",
	t3657: "Användarens lösenord på {{unit}} har löpt ut",
	t3658: "Det går inte att logga in på {{unit}} med angivna referenser.",
	t3659: "Uppdatera firmwareversion eller ändra säkerhetstillämpningsnivå för {{unit}} för att få support för sessionstoken.",
	t3661: "V3gc",
	t3662: "Avsnitt B",
	t3663: "Avsnitt C",
	t3664: "Adaptiv överhettningskontroll",
	t3665: "Orsak till kontrollstatus",
	t3666: "Användaren kan inte loggas in. Enhetsadressen är inte inställd i proxykonfigurationen",
	t3667: "Kommunikationsprotokollet är inte specificerat i proxykonfigurationen. Det kan leda till fel",
	t3668: "IT-säkerhetsinformation",
	t3669: "I överensstämmelse med säkerhetsstandarder har systemhanteraren nu som standard Strikt sessionskontroll, vilket innebär att alla fjärranslutningar/XML-baserade fjärrtjänster inte längre fungerar om inte sessionstokens används.",
	t3670: "Danfoss rekommenderar att du fortsätter i strikt läge, men bakåtkompatibilitetsläget är fortfarande tillgängligt.",
	t3671: "Observera att detta kan ändras i menyn Configuration Security och har ingen inverkan på systemhanterarens lokala kontroll och platsdrift.",
	t3672: "Se mer information om sessionskontroll",
	t3673: "Bakåtläge",
	t3674: "Strikt läge",
	t3675: "När du klickar på bakåtkompatibelt läge kommer alla tillämpliga systemhanterare med uppdaterad firmware att ställas in på bakåtkompatibelt läge, startas om och applikationen loggas ut",
	t3676: "Max antal tillåtna suggrupper är 12",
	t3677: "Efter framgångsrik uppdatering av de valda enheterna från 3.3.0 till 4.X eller högre, kommer användaren att loggas ut automatiskt genom att klicka på knappen Slutför i det sista steget.",
	t3678: "Multifodral för AK2-kontroller (Pack/Case) stöds inte",
	t3679: "I överensstämmelse med säkerhetsstandarder som introducerades med version 4.0, kommer systemhanteraren som standard att använda Strikt sessionskontroll, vilket innebär att alla fjärranslutningar/XML-baserade fjärrtjänster inte längre kommer att fungera om inte sessionstokens används. Utöver det kommer kommunikationen automatiskt att justeras till HTTPS genom att uppgradera till rel. 4.0. Danfoss rekommenderar att du fortsätter i strikt läge, men bakåtkompatibilitetsläge är tillgängligt, vilket kommer att återställa systemet till de inställningar som har tillämpats hittills. Observera att om du gör den här uppdateringen på distans på en HTTP-enhet, se till att din IT-installation är förberedd för HTTPS (port 443) eller ha personal tillgänglig på plats.",
	t3680: "Hitta mer information om den nya säkerhetsimplementeringen här",
	t3681: "Åtkomst till http-versionen av denna webbplats kan blockeras på grund av din webbläsarbegränsningar. Besök vår FAQ-sida för att få instruktioner om hur du löser det här problemet eller ladda ner skrivbordsversionen av StoreView Web.",
	t3682: "System Manager FAQ",
	t3683: "u17 Ther. luft",
	t3684: "u91 Urkopplingstemp.",
	t3685: "u00 Ctrl. ange",
	t3686: "u12 S3 lufttemp.",
	t3687: "u16 S4 lufttemp.",
	t3688: "u23 EEV OD %",
	t3689: "u21 överhettning",
	t3690: "u22 SuperheatRef",
	t3691: "u26 EvapTemp Te",
	t3692: "S2 Gasuttagstemp.",
	t3693: "Stw8",
	t3694: "Sd-MT",
	t3695: "Tw status",
	t3696: "Tw aktivera",
	t3697: "Två pump igång",
	t3698: "Stw3",
	t3699: "Stw4",
	t3700: "Stw2",
	t3701: "Rcv. kontrollstatus",
	t3702: "Sugförskjutning",
	t3703: "Vrec kontrollläge",
	t3704: "Vrec status",
	t3705: "Ctrl-statusorsak",
	t3706: "Hp kontrollstatus",
	t3707: "Pgc max.",
	t3708: "Pgc max. begränsa P-förbud",
	t3709: "Pgc min.",
	t3710: "Ångejektorkapaci",
	t3711: "Kapac för vätskeejektor",
	t3712: "Referenslufttemp.",
	t3713: "Tilluftstemp.",
	t3714: "Retur luft",
	t3715: "Zondaggpunkt",
	t3716: "Zonens fuktighet",
	t3717: "Utetemp.",
	t3718: "Zonens CO2-nivå",
	t3719: "Zon temp.",
	t3720: "Cond. hänvisning",
	t3721: "Cond. kontrollstatus",
	t3722: "Cond. kontrolltillstånd",
	t3723: "Cond. ctrl.",
	t3724: "Cond. begärt lock.",
	t3725: "V3gc-tillstånd",
	t3726: "V3gc OD",
	t3727: "Cond. temp.",
	t3728: "Hr status",
	t3729: "Hr aktivera",
	t3730: "HR Konsumentförfrågan",
	t3731: "Shr3",
	t3732: "Shr4",
	t3733: "Shr2",
	t3734: "Shr8",
	t3735: "Sug ref. Till-MT",
	t3736: "Styrstatus MT",
	t3737: "Faktisk zon MT",
	t3738: "Att välja. offset MT",
	t3739: "Körkapacitet MT",
	t3740: "Begärt lock. MT",
	t3741: "Dags till nästa steg MT",
	t3742: "Po-MT",
	t3744: "Sugtemp. Till-MT",
	t3745: "Total aktiv energi",
	t3746: "Topp efterfrågan",
	t3747: "Total effektfaktor",
	t3748: "Fas A volt",
	t3749: "Fas B volt",
	t3750: "Fas C volt",
	t3751: "Fas A-förstärkare",
	t3752: "Fas B-förstärkare",
	t3753: "Fas C förstärkare",
	t3754: "Total aktiv effekt",
	t3755: "Sugtemp. Till-LT",
	t3756: "Sugtemp. Till-IT",
	t3757: "Sugreferens LT",
	t3758: "Styrstatus LT",
	t3759: "Faktisk zon LT",
	t3760: "Att välja. offset LT",
	t3761: "Körkapacitet LT",
	t3762: "Begärt lock. LT",
	t3763: "Dags till nästa steg LT",
	t3764: "Po-LT",
	t3765: "Sd-LT",
	t3766: "Sugreferens IT",
	t3767: "Kontrollstatus IT",
	t3768: "Faktisk zon IT",
	t3769: "Driftkapacitet IT",
	t3770: "Begärd kapacitet IT",
	t3771: "Dags till nästa steg IT",
	t3772: "Tn Po-IT",
	t2773: "SD-IT",
	t2774: "IT Comp. start",
	t3774: "Inte inom räckhåll. Bör vara mellan {{minAddr}} till {{maxAddr}}",
	t3775: "Funktionen inaktiverad för den här enheten",
	t3776: "Efter denna åtgärd kommer programmet att logga ut, vilket kan ta en stund.",
	t3777: "Migreringsfilerna (flp eller json) kunde inte hittas",
	t3778: "Omröstning",
	t3779: "Omröstningen är för närvarande inställd.",
	t3780: "Vill du återuppta omröstningen?",
	t3781: "Energimätare",
	t3782: "Flera noder valda",
	t3783: "Zooma till nod",
	t3784: "Måttenheter",
	t3785: "Fil(er) exporterades framgångsrikt",
	t3786: "Adress kan inte vara negativ",
	t3787: "Återställ zoom",
	t3788: "Fel vid hämtning av steg för iscensättningsmönster",
	t3789: "Det gick inte att spara steg för iscensättningsmönster",
	t3790: "Steg för iscensättningsmönster har sparats",
	t3791: "Stansning mönster",
	t3792: "Tryck på de avlastade ikonerna för att aktivera/avaktivera de avlastare som är kopplade till kompressorerna",
	t3793: "Saknade data",
	t3794: "Följande parametrar gav inga datapunkter",
	t3795: "HTTPS/SSL-förtroende lyckades.",
	t3796: "Du kan nu logga in på StoreView webbläsare.",
	t3797: "Omdirigerar till StoreView-webbläsaren om {{timeLeft}} sekunder...",
	t3798: "Omröstningen avstängd för närvarande!",
	t3799: "Rensade larm (fullständiga systemloggar)",
	t3800: "Max antal tillåtna HVAC är 45.",
	t3801: "Det gick inte att hämta ändringsloggen",
	t3802: "Ansluter till lagring...",
	t3803: "Timeout för SCADA-lagringsanslutningen",
	t3804: "Det gick inte att ansluta till SCADA-lagring",
	t3805: "Ogiltigt datum",
	t3806: "Välj enhet(er) för rapportnedladdning"
};
var sv = {
	lang: lang$c
};

var lang$d = {
	t0: "Aktif Alarmlar",
	t1: "Temizlenmiş Alarmlar",
	t2: "Host",
	t3: "ID",
	t4: "Cihaz Adı",
	t5: "Notları Edit",
	t6: "Alarm Kabul Etme Yetkisi Ne Kadar Yüksek",
	t7: "Alarm Type",
	t8: "Oluştu",
	t9: "Kabul",
	t10: "Active Alarm(s)",
	t11: "Temizlenmiş",
	t12: "Hesaplama",
	t13: "Temizlenmiş Alarm(lar)",
	t14: "Sessiz/Ack",
	t15: "Tüm onaylanmamış alarmları kabul edin",
	t16: "Yetkilendirme başarısız oldu!",
	t17: "Error",
	t18: "Manual Control",
	t19: "Visual",
	t20: "Özet Veriler",
	t21: "Teknik Görünüm",
	t22: "Grafik (S4)",
	t23: "Node",
	t24: "Mod",
	t25: "Graph (Po &amp; Pc)",
	t26: "(alarm algılanmadı)",
	t27: "Cihaz Alarmları",
	t28: "Önceki cihazı görüntüleme",
	t29: "Sonraki cihazı görüntüleme",
	t30: "Okuma alarmlarında hata.",
	t31: "Güncel Alarmlar",
	t32: "Akım Alarmı",
	t33: "Alarm algılanmadı.",
	t34: "Tip",
	t35: "Point",
	t36: "Geçmiş Günlüğü",
	t37: "Zamanlama",
	t38: "Başlangıç saati",
	t39: "Durma Süresi",
	t40: "Gün",
	t41: "Tatil",
	t42: "Status",
	t43: "Veri Noktalarını Seçin",
	t44: "OK",
	t45: "İptal",
	t46: "Start",
	t47: "End",
	t48: "Start date",
	t49: "Örnek oranı",
	t50: "Veri noktası seçilmedi",
	t51: "Nwo",
	t52: "Sn",
	t53: "min",
	t54: "hr",
	t55: "Day",
	t56: "Address Type",
	t57: "Address",
	t58: "Konum:",
	t59: "Giriş Yeni Değer",
	t60: "Yenileme",
	t61: "Veri Noktalarını Seçin",
	t62: "Yeni Değeri Seçin",
	t63: "Kapalı",
	t64: "On",
	t65: "Veri noktaları alma...",
	t66: "Veri alma hatası...",
	t67: "Hiçbir geçmiş yapılandırıldı ...",
	t68: "Veri noktalarını alma hatası...",
	t69: "Tarih örneklerini alma",
	t70: "Geçmiş örneklerini alma hatası...",
	t71: "User Name",
	t72: "Password",
	t73: "Host IP/URL Address:",
	t74: "OK",
	t75: "İptal",
	t76: "Name",
	t77: "Değer",
	t78: "Kullanıcı listesini alma hatası",
	t79: "Giriş Yeni Adres",
	t80: "Kullanıcı Listesini Başarısız Alın, Host IP/URL Adresini Kontrol Edin Lütfen",
	t81: "Auth Code",
	t82: "Acct Kodu",
	t83: "Yeni Saat ve Tarih Seçin",
	t84: "Yeni Süre Seçin",
	t85: "İşlem Başarısız Oldu",
	t86: "Yetkilendirme Bilgilerini Girin",
	t87: "HVAC",
	t88: "Go!",
	t89: "Defrost Kontrolü",
	t90: "Manuel Defrost'u Başlat",
	t91: "Kasa Aydınlatma Kontrolü",
	t92: "Manuel Kasa Işıkları Yanıyor",
	t93: "Manuel Kasa Işıkları Kapalı",
	t94: "Kasa Temizleme Kontrolü",
	t95: "Manuel Kasa Temizleme",
	t96: "Manuel Kasa Temizleme Kapalı",
	t97: "Ana Anahtar",
	t98: "Manuel Ana Anahtar Açık",
	t99: "Manuel Ana Kapatma",
	t100: "Kapatma Denetimi",
	t101: "Cihazı Kapat",
	t102: "Cihazı Aç",
	t103: "Gece Gerileme Kontrolü",
	t104: "Gece Gerilemesi On",
	t105: "Gece Gerileme Kapalı",
	t106: "İleti gönderildi...",
	t107: "Manual Control",
	t108: "Yakın",
	t109: "Aydınlatma",
	t110: "Güç /Misc",
	t111: "Genel bakış",
	t112: "Görüntülemek için AK255 birimini seçin.",
	t113: "Yükleme...",
	t114: "of",
	t115: "Tüm AK255'ler",
	t116: "Önceki",
	t117: "Pages",
	t118: "Next",
	t119: "Parameter",
	t120: "Ölçüm",
	t121: "Veri alma...",
	t122: "Okuma bölümünde hata",
	t123: "Sıcaklık",
	t124: "Defrost sonrası gecikme",
	t125: "Aşağıda ise alarm",
	t126: "Yukarıdaki alarm",
	t127: "Izleme",
	t128: "Offline",
	t129: "Active",
	t130: "Host",
	t131: "Address",
	t132: "Description",
	t133: "Geçerli Değer",
	t134: "Geçerli Durum",
	t135: "Denetleyici",
	t136: "Unit",
	t137: "Etkin Alarm Var",
	t138: "Aktif Alarmlar Var!",
	t139: "On-Off Girişleri",
	t140: "Röle Çıkışları",
	t141: "Sensör Girişleri",
	t142: "Değişken Çıktılar",
	t143: "Genel Denetleyiciler",
	t144: "Diğer",
	t145: "Görselleştirme Noktası",
	t146: "Mağazaya Genel Bakış",
	t147: "Sistemden Çıkış",
	t148: "Log On",
	t149: "Oturumu Kapat",
	t150: "Soğutma",
	t151: "Cihaza Genel Bakış",
	t152: "Alarm",
	t153: "Geçmiş",
	t154: "CS-Genel Bakış",
	t155: "Mağazaya Genel Bakış",
	t156: "Yapılandırma",
	t157: "Site View",
	t158: "Bitki Görünümü",
	t159: "Home",
	t160: "Alarm",
	t161: "Setpoint",
	t162: "Cihaz Detayı",
	t163: "Ayarı",
	t164: "Tarih Seçin",
	t165: "Information",
	t166: "Denetim İzi",
	t167: "Hesaplama",
	t168: "Dosya Yönetimi",
	t169: "Help Manual",
	t170: "Info",
	t171: "Translator",
	t172: "Sunucuya Kaydet",
	t173: "Dil Dosyalarını Güncelleştirin?",
	t174: "Bu işlem, birimdeki dil dosyalarını güncelleştirecektir. Devam?",
	t175: "İhracat Geçmişi",
	t176: "Yeni Değer Seçin",
	t177: "minimum",
	t178: "maximum",
	t179: "No Active Alarm!",
	t180: "Değiştirilebilir değil!",
	t181: "Lütfen bekleyin...",
	t182: "Komut gönderildi!",
	t183: "Operasyon başarısız oldu, lütfen daha sonra tekrar deneyin!",
	t184: "Operasyon başarılı oldu!",
	t185: "Yetkili Değil Operasyon!",
	t186: "Bu operasyona devam edeceğinden emin misin?",
	t187: "EVAP",
	t188: "PACK",
	t189: "Girişleri İzleme",
	t190: "MISC",
	t191: "Kabul Edilen Alarmlar",
	t192: "Kabul Edilen Alarmları Temizle",
	t193: "Tüm Kabul Edilen Alarmları Temizle",
	t194: "Alarmları onaylama yetkiniz yok",
	t195: "Yetkili Değil",
	t196: "Bu alarmı kabul etmek ister misin?",
	t197: "Alarmı Onayla",
	t198: "Lütfen Önce Giriş Yapın",
	t199: "Temizlemek için lütfen bir alarm seçin",
	t200: "Alarmları Temizle'ye Doğrula",
	t201: "Bu alarmı temizleyeceğinden emin misin?",
	t202: "Alarmı Temizleme Yetkisi Ne",
	t203: "Tüm alarmları temizleyeceğinden emin misin?",
	t204: "Seçili alarmlar temizlenemez",
	t205: "Alarm Ayarı",
	t206: "Bu İşlemi Gerçekleştirmek için Denetçi Olarak YetkiveRilmelidir",
	t207: "Birimden döndürülen veri yok",
	t208: "Birim Seçin",
	t209: "Lütfen listeden bir birim seçin",
	t210: "Alarm Sayısı",
	t211: "Alarm Status",
	t212: "Çeşitli",
	t213: "Seçili Alarmı Temizle",
	t214: "Lütfen Onaylayın",
	t215: "Birim Bağlantısı Arızalı, Lütfen Ağ Bağlantısını Kontrol Edin!",
	t216: "Defrost Alarmı",
	t217: "Dijital Alarm",
	t218: "Dijital Giriş Durumu",
	t219: "Defrost Girişi",
	t220: "Temizleme Girişi",
	t221: "Dijital Giriş",
	t222: "Dil Dosyalarını Kaydetme",
	t223: "Birim",
	t224: "Sistemde Yapılandırılan Aygıt Yok, Web Sitesi Devre Dışı.",
	t225: "Uyarı:",
	t226: "Saat Seçin",
	t227: "Iletişim",
	t228: "Download Report File?",
	t229: "Lütfen rapor dosyası oluştururken bekleyin...",
	t230: "Download Report",
	t231: "Alarm Status/Service",
	t232: "Lütfen Veri Alırken Bekleyiniz...",
	t233: "Oturum Açma",
	t234: "Kullanıcı Oturumu Kapatıldı",
	t235: "Kullanıcı Oturum Açtı",
	t236: "Hoş geldi -niz!",
	t237: "Geçersiz lisans",
	t238: "Enerji Ölçer Kontrolörleri",
	t239: "Aydınlatma Panelleri",
	t240: "VLT Çevirici",
	t241: "Kompresör Modülü",
	t242: "Cihaz Denetleyici Noktaları",
	t243: "Hesaplanan Puanlar",
	t244: "Çatal-Çekiç Paneller",
	t245: "Taşıyıcı",
	t246: "Gelişmiş Görünüm",
	t247: "Yakınlaştırmak/Uzaklaştırmak İçin Buraya Çift Tıklayın",
	t248: "Yakınlaştırmak/Uzaklaştırmak için Buraya Tıklayın",
	t249: "Kabul Edilen Alarm(lar)",
	t250: "Simülatörü",
	t251: "Refrig Görevi",
	t252: "HVAC Görevi",
	t253: "Aydınlatma Görevi",
	t254: "MISC Görevi",
	t255: "Ağa Bağlı",
	t256: "Sürücü",
	t257: "Başvuru",
	t258: "Graph (Drive Mtr Hz &amp; Amp)",
	t259: "Stop Date",
	t260: "Stop",
	t261: "Durdurma süresi açık veya başlangıç saatinden önce, lütfen tarihi/saati yeniden seçin",
	t262: "Datapoint(ler)'in geçmiş oluşturma zamanı",
	t263: "belirtilen başlangıç saatinden daha geç",
	t264: "Her tarih noktasından gelen örnek sayısı 100.000'e ulaştı, yanıtı almak uzun zaman alacak. Örnek oranını düşürerek bu sayıyı azaltabilir veya tarih/saati değiştirebilirsiniz.",
	t265: "Birim geçmişi sorgu görevi meşgul, lütfen yaklaşık 2 dakika bekleyin ve yeniden deneyin...",
	t266: "Kaydetmek",
	t267: "Tarih Kontrol Paneli",
	t268: "Ilk",
	t269: "Son",
	t270: "Yazdırma",
	t271: "Uzaklaştırma",
	t272: "Zoom In",
	t273: "Göstergeyi Göster/Gizle",
	t274: "Geçmiş Verilerini Dosyaya İhraç Etme",
	t275: "Izgarayı Göster/Gizle",
	t276: "Tek Sayfa Görünümü",
	t277: "Çok Sayfa Görüntüleme",
	t278: "Dosyadan Yük Geçmişi Grubu",
	t279: "Dosyadan Yük Geçmişi",
	t280: "Geçmiş Grubunu Dosyaya Kaydet",
	t281: "Çok Sayfa Görüntüleme Sayısı",
	t282: "Page",
	t283: "Görüntülemek için bir sayfa seçin",
	t284: "Geçmiş dosyası .hst formatında olmalıdır!",
	t285: "Döndürülen veri yok, lütfen geçmiş noktası seçimini ve seçilen tarih/saati kontrol edin",
	t286: "Geçmiş Anlık Görüntü",
	t287: "Tarih Tam Görünüm",
	t288: "Geçmiş Dosya Görünümü",
	t289: "Seçin...",
	t290: "Bu işlem geçerli geçmiş görünümünü bir .hst/.csv dosyasına dışa aktaracaktır.",
	t291: "Lütfen bir hedef dosya seçin.",
	t292: "File",
	t293: "Yük Geçmişi Grubu...",
	t294: "Yük Geçmişi...",
	t295: "Geçmiş Grubunu Kaydet...",
	t296: "View",
	t297: "Zoom",
	t298: "Evet",
	t299: "No",
	t300: "Denetleyiciden yapılandırma almak?",
	t301: "Bir daha sorma",
	t302: "Denetleyici Yapılandırması Alın",
	t303: "Alınan Veriler",
	t304: "Alarm Level",
	t305: "Alarm Action",
	t306: "Kabul",
	t307: "Veritabanını Diske Kaydetme",
	t308: "File Name",
	t309: "Database Description",
	t310: "Veritabanı Yolu",
	t311: "Version",
	t312: "Saat",
	t313: "İhracat Alarmları",
	t314: "Veritabanı kaydedilen",
	t315: "Veritabanı kaydetme başarısız oldu, dizin ve dosya adı geçerli olup olmadığını kontrol edin!",
	t316: "Dosya adı doğru değil, lütfen veritabanı dosya adını kontrol edin",
	t317: "Alarmlar ihracat başarılı oldu. Lütfen dışa aktarılan alarmları kaydetmek için bir dosya seçin",
	t318: "Lütfen İhracat için Alarmları Seçiniz",
	t319: "Birimden Alarm Bilgileri Alma",
	t320: "File Description",
	t321: ".hst dosyasını .csv dosya biçimine dönüştürün",
	t322: "Adım 1: .hst Dosyasını seçin",
	t323: "Adım 2: Veri Noktalarını ve Zaman Aralığını Seçin",
	t324: "Adım 3: Hedef .csv Dosyasını Seçin",
	t325: "Kaydet...",
	t326: "Bu işlemde en fazla 6000 veri puanına izin verilmeyen",
	t327: "Veri Noktaları Seçili",
	t328: "Veri noktası başına 65.536'dan fazla numune ihraç etmek yasaktır, lütfen örnek oranını veya zaman aralığını değiştirin.",
	t329: "Toplamak",
	t330: "Birimden geçmiş verileri toplama",
	t331: "Toplanan veri noktalarını seçme",
	t332: "Değer Yok",
	t333: "Eksik",
	t334: "Askıya",
	t335: "Lütfen Bir Seçim Yapın",
	t336: "Çizilecek Veri Noktaları'nı seçin",
	t337: ".hst Dosyasına Kaydet",
	t338: "Veri indirme tamamlandı, lütfen aşağıdaki seçeneklerden birini seçin:",
	t339: "Çizmek için en fazla 8 puan seçin",
	t340: "Toplanan geçmiş verilerini .hst dosyasına kaydetme",
	t341: "Genel Bakış Yazdırma",
	t342: "Lütfen Kaydet veya Genel Değer Raporu Yazdır'ı seçin",
	t343: "Dosya Biçimi Seçin",
	t344: "Genel Bakış Dosyası Oluşturma, Lütfen Bekleyin...",
	t345: "Seçili veritabanı dosyası var, üzerine yazmak istiyor musunuz?",
	t346: "Dashboard",
	t347: "Graphic View",
	t348: "Help",
	t349: "System View",
	t350: "Harita",
	t351: "Çeviri Aracı",
	t352: "Manual",
	t353: "Alarm isteyin...",
	t354: "Güncel -leştirilmiş",
	t355: "Model",
	t356: "Control",
	t357: "Yağ",
	t358: "Temp",
	t359: "En üst düzeye çıkarmak",
	t360: "Geri yükleme",
	t361: "Geri Git...",
	t362: "Comm başarısız oldu!",
	t363: "Comm durumu tamam.",
	t364: "İletişim iletisi gönderildi.",
	t365: "Ev BileşeniNin Yüklenmesi",
	t366: "Yükleme Yapılandırma Bileşeni",
	t367: "Ctrl Temp",
	t368: "Bu ayar yalnızca geçmiş yeniden çizildiğinde etkili olacak, devam etmek istiyor musunuz?",
	t369: "Yükleniyor, lütfen bekleyin",
	t370: "veri noktaları",
	t371: "No unit name",
	t372: "Tercihler",
	t373: "Simülatör Versiyonu",
	t374: "Enerji",
	t375: "Genel bakış penceresine git",
	t376: "Sistem alarmı veya ilgili cihaz bulunamadı",
	t377: "Eşzamanlı zamanlamalar",
	t378: "Eksik dil dosyası.",
	t379: "Sürüm desteklenmiyor",
	t380: "Bir birimin IP adresini buraya girin (örn. 192.168.1.1)",
	t381: "Lütfen bir birimin IP adresini veya URL'sini girin.",
	t382: "Ayrıntıyı Gizle",
	t383: "Ayrıntıları Göster",
	t384: "Temp Dışında",
	t385: "Return",
	t386: "Kaynağı",
	t387: "Nem",
	t388: "Dewpoint",
	t389: "Fan",
	t390: "Zone",
	t391: "Kapatma",
	t392: "Split",
	t393: "Emme Sıcaklığı",
	t394: "Akım Kapasitesi",
	t395: "Emme Basıncı",
	t396: "Deşarj",
	t397: "Kompresör",
	t398: "Kondansatör",
	t399: "Kondansatör Hedefi",
	t400: "Soğutucu",
	t401: "Emme Hedefi",
	t402: "Emme",
	t403: "Defrost",
	t404: "Danfoss Tarih Formatı",
	t405: "Text Format",
	t406: "Bu işlemin bir ünitenin sıfırlanması gerekiyor, birimi şimdi sıfırlamak istiyor musunuz?",
	t407: "Birim sıfırlama başarılı, web sitesini yeniden lütfen.",
	t408: "Fabrika Uygulama Modu",
	t409: "Hesaplama...",
	t410: "minutes",
	t411: "Gece Gerilemesi",
	t412: "Kasa Işıkları",
	t413: "Zamanlama Eşzamanlılık",
	t414: "(10 dakikalık çözünürlük)",
	t415: "View Text",
	t416: "CSV olarak dışa aktarma",
	t417: "PNG Kaydet",
	t418: "Grafiği Görüntüle",
	t419: "View Text",
	t420: "Zamanlama",
	t421: "Süre",
	t422: "Dönem Temp",
	t423: "NONE",
	t424: "Kontrol Sıcaklığı",
	t425: "Çalışan",
	t426: "Yüklenen veritabanı",
	t427: "Istatistik",
	t428: "Dosyayı Seçin",
	t429: "CSV formatına dönüştürmek istediğiniz geçmiş dosyasını seçin...",
	t430: "Bitirmek",
	t431: "Özet",
	t432: "Geçmiş Veri Dosyaları (*.hst)",
	t433: "Geçmiş Dönüşüm Sihirbazı",
	t434: "Dosya oluşturma tamamlandı.",
	t435: "CSV formatına dönüştürmek istediğiniz veri noktalarını seçin...",
	t436: "Zaman Aralığını Seçin",
	t437: "Zaman aralığını seçin ve işlem düğmesine basın...",
	t438: "Process Data",
	t439: "Geçmiş verilerini hazırlama, lütfen bekleyin",
	t440: "CSV dosya adını seçin ve verileri dosyaya kaydedin...",
	t441: "Geçmiş veri noktasını bulamıyor",
	t442: "Hata - Döndürülen geçmiş verileri yok",
	t443: "Ana bilgisayar ağı üzerinden birimler ile iletişim kuran bir hata oluştu. Bulundu",
	t444: "Dikkat",
	t445: "Sessiz -lik",
	t446: "Veritabanı kaydedilmedi, gerçekten pencereyi kapatmak istiyor musunuz?",
	t447: "Table View",
	t448: "Grafiği Göster",
	t449: "Tabloyu Göster",
	t450: "Manuel Defrost On",
	t451: "Manuel Defrost Off",
	t452: "Otomatik Defrost",
	t453: "Ayar Defrost Kontrolü",
	t454: "Boşaltma makinesi üzerinde",
	t455: "Boşaltıcıyı boşaltın",
	t456: "Sıvı",
	t457: "Yapılandırılan zamanlama yok.",
	t458: "StoreView",
	t459: "Ana ağdaki bir birimle iletişimi kaybettik!",
	t460: "Hızlı Kurulum",
	t461: "Street",
	t462: "City",
	t463: "Telefon",
	t464: "Region",
	t466: "Index",
	t469: "Adres Defteri Başarıyla Dışa Aktarıla",
	t470: "Delete item",
	t471: "Ölçer",
	t472: "Not: giriş büyük/küçük harf duyarlıdır",
	t473: "Online",
	t474: "RO Kurulu",
	t475: "SI Kurulu",
	t476: "OI Kurulu",
	t477: "Hesaplama",
	t478: "Geçerli Saate Ayarla",
	t479: "Raf",
	t480: "Kompresör Yok",
	t481: "Devre",
	t482: "Durumda",
	t483: "Bilinmeyen",
	t484: "err türü:",
	t485: "Add",
	t486: "Kopya",
	t487: "Kaldırmak",
	t488: "Büyük/Küçük Harf Kontrolü",
	t489: "Büyük/Küçük Harf Türü",
	t490: "LON",
	t491: "Kopyalamak için tutarı girin",
	t492: "no name",
	t493: "Soğutma düzenini yapılandırma...",
	t494: "Configuration error:",
	t495: "Lütfen yapılandırmayı doğrulayın.",
	t496: "Soğutma düzeni kurulumu başarıyla tamamlandı. Bu sihirbazdan çıkmak için tamam düğmesine basın.",
	t497: "Configuration Status",
	t498: "Son Soğutma Düzeni",
	t500: "Code #",
	t501: "Layout",
	t502: "System Setup",
	t503: "Lisans",
	t504: "Kullanıcı",
	t505: "Yetkili Değil",
	t506: "Lütfen doğrulayın...",
	t507: "Bu ekrandan ayrılmak istediğinden emin misin? Ayarlarınız kaybolabilir.",
	t508: "Tarama denetleyici ağı...",
	t509: "Tarama",
	t510: "Doğrulama",
	t511: "AK-I/O",
	t512: "Rescan",
	t513: "Çevrimdışı programlama ekleme",
	t514: "Cihaz Veri Otobüsü",
	t515: "Çevrimiçi cihazlar",
	t516: "Çevrimdışı programlama",
	t517: "Yinelenen adres",
	t518: "Kompresör Kontrolleri",
	t519: "Emme Grupları",
	t520: "N/A",
	t521: "Kullanılabilir Servis Talepleri",
	t522: "Çok harfli devre oluşturma ya izin ver",
	t523: "Aygıtları ekleme",
	t524: "Devreyi ekleme",
	t525: "Çok kasa devresi.",
	t526: "Masaları yükleme...",
	t527: "Alarm verileri istemek için",
	t528: "Geçmiş Grup Dosyaları",
	t529: "Geçmiş Veri Dosyaları",
	t530: "Tüm Dosyalar",
	t531: "Sembolleri Göster",
	t532: "Eğrileri Göster",
	t533: "Yeterince tarih toplanmadı.",
	t534: "En son geçmiş verilerini alın.",
	t535: "Durum/Ayarlar",
	t536: "Şimdi indiriyor",
	t537: "tarih örnekleri...",
	t538: "Alma",
	t539: "Veri noktaları...",
	t540: "Aralığı",
	t541: "Dk",
	t542: "Max",
	t543: "Ortalama",
	t544: "Ana alarm penceresine git.",
	t545: "Otomatik oturumu durdurma",
	t546: "Bağlanma",
	t547: "Otomatik oturumu iptal etme",
	t548: "Lütfen giriş şifreni girin",
	t549: "Web 1",
	t550: "Web 2",
	t551: "Oturum otomatik olarak oturum açın",
	t552: "Hareketsizlik döneminden sonra oturumu kapat",
	t553: "Home screen",
	t554: "Yapılandırma menüsünü göster",
	t555: "Pano'da misc öğelerini göster",
	t556: "Tam ekran kullanın",
	t557: "Alarm sesini etkinleştirme",
	t558: "Programı kapatmaya/en aza indirmeye izin ver",
	t559: "Hata erişim birimi #",
	t560: "Ağa bağlı.",
	t561: "Otomatik oturum iptal edildi",
	t562: "Yeniden bağlanmaya yeniden bağlanma",
	t563: "Oturum dan çıkış",
	t564: "Bağlı",
	t565: "Zaman Biçimi",
	t566: "Date Format",
	t567: "Basınç",
	t568: "Işık Seviyesi",
	t569: "Subcool",
	t570: "Alt soğutucu",
	t571: "Günlük",
	t572: "Hafta -lık",
	t573: "HST Dosyası",
	t574: "CSV File",
	t575: "Pazar",
	t576: "Pazartesi",
	t577: "Salı",
	t578: "Çarşamba",
	t579: "Perşembe",
	t580: "Cuma",
	t581: "Cumartesi",
	t582: "Geçmiş Günlük Koleksiyonu",
	t583: "Otomatik toplamayı etkinleştirme",
	t584: "Ne zaman toplanacak",
	t585: "Dosya kaydetme konumu",
	t586: "Dosya biçimini seçin",
	t587: "Geçmiş dosyalarını depolamak için klasörü seçin...",
	t588: "Mağazanın yapılandırılmış geçmiş noktalarının günlük veya haftalık geçmiş günlüklerini almak ve kaydetmek için otomatik geçmiş koleksiyonunu etkinleştirin.",
	t589: "HST geçmiş dosyalarını görüntülemek için ana geçmiş ekranına gidin ve alma geçmişini seçin. CSV dosyalarını görüntülemek için herhangi bir elektronik tablo uygulaması kullanın.",
	t590: "Geçerli bağlantının IP adresi adres defterinde bulunursa, seçili geçmiş noktaları kaydedilir.",
	t591: "Veri noktalarını seçin",
	t592: "Tüm seçili",
	t593: "Seçili puanlar",
	t594: "Arama",
	t595: "Otomatik bağlanma",
	t596: "Lütfen doğrulayın",
	t597: "Otomatik geçmiş koleksiyonu etkinleştirilir. Bu programı kapatmak istediğinizden emin misiniz?",
	t598: "Bu programı kapatmak istediğinizden emin misiniz?",
	t599: "Action",
	t600: "Filter",
	t601: "Tüm eylemler",
	t602: "Daha fazla bilgi...",
	t603: "Düzenlen -ebilir",
	t604: "Işlem",
	t605: "İşlem İptal Edildi",
	t606: "Veritabanı İndirme Tamamlandı",
	t607: "Birimlerin Veritabanı Güncellendi",
	t608: "Dosya(lar) Üniteye Başarıyla Aktarıldı",
	t609: "İndirme tamamlandı, dosya birimde işleme altında",
	t610: "Hata PC'ye dosya yükleme oldu, dosya yazılabilir ve erişilebilir olup olmadığını kontrol edin",
	t611: "Dosya Yükleme",
	t612: "DosyaYı İndirme",
	t613: "Seçili dosya(lar) yükleme",
	t614: "Seçili dosya(lar) indirme",
	t615: "Dosyasilme",
	t616: "Seçili dosya(lar) silme",
	t617: "FTP Bağlantı Hatası",
	t618: "Seçili Dosya(lar)ı Üniteye İndir",
	t619: "FTP bağlantısında hata oluştu. Lütfen FTP bağlantısını ve şifre/kullanıcı adını kontrol edin.",
	t620: "FTP'ye bağlı",
	t621: "Bytes",
	t622: "Birimdeki dosyalar bu işlemle üzerine yazılır, devam etmek ister misiniz?",
	t623: "PC'deki dosyalar bu işlemle üzerine yazılır, devam etmek ister misiniz?",
	t624: "Dosyalar üniteye indirildi.",
	t625: "SOĞUTMA SADECE",
	t626: "Market",
	t627: "Tam İşlevsellik",
	t628: "HVAC ONLY",
	t629: "Dosya Adı geçersizdir; Lütfen dosya adının 8 karakterden az olduğundan emin olun.",
	t630: "Uygulama Kodunun Üzerine Şimdi Mi Yazın?",
	t631: "Sonra",
	t632: "Yüklenen",
	t633: "şimdi uygulama üzerine yazmak için EVET'i seçebilir veya Daha Sonra",
	t634: "Lütfen Simülatör Kök Dizini Seçiniz",
	t635: "Lütfen Windows Dizinini Seçiniz",
	t636: "Lütfen Hedef Dizini Seçiniz",
	t637: "Veritabanı Dizini'ni seçin",
	t638: "FTP bağlantısı kesildi",
	t639: "Bağlantı Zaman, FTP Bağlantısı Kesildi",
	t640: "Seçili Dosya(lar)ı PC'ye yükleme",
	t641: "Seçili Dosya(lar)'ı sil",
	t642: "FTP Sunucu Bağlantı Noktası",
	t643: "Web Server Port",
	t644: "Yerel Yol",
	t645: "Up",
	t646: "Boyutu",
	t647: "Date",
	t648: "Bağlamak",
	t649: "Bağlantı -sını kesmek",
	t650: "Uzak Yol",
	t651: "Boş Alan Kullanılabilir:",
	t652: "Seçili Dosya(lar)",
	t653: "Files",
	t654: "Toplam Boyut:",
	t655: "Klasörü değiştirmek için tıklayın...",
	t656: "Değiştirmek...",
	t657: "Seçili dosya(lar)'ı silmek istiyor musunuz?",
	t658: "Bağlantı Kapalı, Çıkış",
	t659: "Seçili dosya(dosyalar) adı(adları) geçersizdir ve karşıdan yükleme göz ardı edilir.",
	t660: "Seçili dosyaların adlarından bazıları geçersizdir ve yoksayılır.",
	t661: "Dosya(lar) PC'ye Başarıyla Aktarıldı",
	t662: "Birimde Yeterli Boş Disk Alanı Yok.",
	t663: "Transfer file(s)",
	t664: "Değiştirilmiş",
	t665: "Dosyaları Flash Disk'ten RAM Diske Kopyalama",
	t666: "Lütfen bir dosya kopyalama işlemi seçin:",
	t667: "Flash Diskten Yapılandırılan Tüm EDF Dosyalarını Kopyala",
	t668: "Flash Diskteki Tüm EDF Dosyalarını Kopyala",
	t669: "edf/ed3 dosyaları ünitenizin flash diskinde saklanır, ancak Dosya Yönetimi ekranına yüklenmez.  Biriminizde depolanan EDF dosyalarını Dosya Yönetimi ekranına kopyalamak için aşağıdaki seçenekleri kullanın",
	t670: "Bu İşlem, birimde yapılandırılan tüm EDF dosyalarını flash diskten RAM diske kopyalar",
	t671: "Bu İşlem, flash diskten RAM diskine birimdeki tüm EDF dosyalarını kopyalar",
	t672: "Geçerli EDF Dosyası Yok",
	t673: "EDF Dosya Kopyası Tamamlandı",
	t674: "kopyalanan dosyalar",
	t675: "Veritabanı karşıdan yüklemeyi onaylama",
	t676: "Unit Address",
	t677: "IP Adresi/Ana Bilgisayar Adı",
	t678: "Database File Name",
	t679: "Seçin",
	t680: "Üniteyi tamamla, sıfırlama ve yükseltme ünitesini güncelleştirin. Bu işlem birkaç dakika sürebilir.",
	t681: "Yükseltme Yöneticisine Hoş Geldiniz - Adım 1 / 2",
	t682: "Upgrade Manager hoşgeldiniz - Adım 2 2",
	t683: "Devam",
	t684: "Ön Yükseltme Denetimi",
	t685: "Firmware'i Bul",
	t686: "Backup Database?",
	t687: "Veritabanını Yedekleme",
	t688: "Yazılımı Aktarma",
	t689: "Yazılım Üzerine Yazma",
	t690: "Yükseltilecek Yazılımları Seçin",
	t691: "Hiçbir fabrika başarısız güvenli uygulama algılandı.",
	t692: "Devam etmeye devam edebilirsiniz, ancak güncelleştirme işlemi sırasında ki hatalar sistemi çalışamaz hale getirebilir.",
	t693: "Devam etmemeleri şiddetle tavsiye edilir.",
	t694: "Devam etmek istiyor musun?",
	t695: "Bir sistemi uzaktan yükseltmek, sistem hatasına neden olabilecek bir hata riski taşır.",
	t696: "Reddi",
	t697: "Veritabanı Yükleme Tamamlandı",
	t698: "Veritabanı Nı Yedekleme Tamamlandı",
	t699: "Uygulama İndirme Tamamlandı",
	t700: "Uygulama Overwriting Tamamlandı",
	t701: "Yazılım Yükseltme Başarılı",
	t702: "Üniteyi fabrika uygulamasına sıfırlamak istiyor musunuz?",
	t703: "Donanımı Denetleme",
	t704: "Cihaz Durumunu Denetleme",
	t705: "Fabrika Fail Safe",
	t706: "Geçerli Firmware Sürümü",
	t707: "Yükseltmeyi Başlat",
	t708: "Varolan bir veritabanı dosyanı seçme",
	t709: "Varolan bir firmware dosyası seçin",
	t710: "Geri",
	t711: "Firmware'i Bul",
	t712: ".csi dosyayı seçin",
	t713: ".mai Dosya'yı seçin",
	t714: "Uzaktan indirme bu CPU panosu tarafından desteklenmez.",
	t715: "Uygulama İndirme Başarısız Oldu",
	t716: "Veritabanı Yükleme Başarısız, Lütfen Tekrar Deneyin",
	t717: "Uygulama Overwriting Tamamlandı",
	t718: "Uygulama Overwriting Başarısız Oldu! Lütfen tekrar deneyin.",
	t719: "Uygulama Overwriting Başarısız",
	t720: "Hiçbir fabrika başarısız güvenli uygulama algılandı.",
	t721: "Yapılır",
	t722: "Seçili uygulama, geçerli birimin firmware farklı bir sürüm türü olabilir, işlem devam başarısızlık ve sistem hatası riski taşıyabilir.",
	t723: "Seçili dosya(dosyalar) adı(adları) geçersizdir ve karşıdan yükleme göz ardı edilir.",
	t724: "Seçili dosyaların adlarından bazıları geçersizdir ve yoksayılır.",
	t725: "Birimin berg pini kayıp.",
	t726: "Unit's battery is low.",
	t727: "Birimin ana bilgisayar ağı başarısız oldu.",
	t728: "Birim yük shed durumunda",
	t729: "Hedef dosyayı seçin",
	t730: "Hedef dizinini seçin",
	t731: "Veritabanı Yükleme Tamamlandı",
	t732: "Status OK",
	t733: "Status Error",
	t734: "Durum Bilinmiyor",
	t735: "Bu işlem hedef simülatörde seçili dosyaların üzerine yazabilir, devam etmek istiyor musunuz?",
	t736: "Veritabanı nı indirme tamamlandı, sistemi sıfırlamanız gerekir",
	t737: "Veritabanını karşıdan yükleme başarısız oldu, daha sonra yeniden denemeniz gerekir",
	t738: "Ünite bu işlemden sonra otomatik olarak sıfırlanacak, devam etmek istiyor musunuz?",
	t739: "Veritabanı'nı seçin",
	t740: "Download Database File?",
	t741: "Upload Database File?",
	t742: "Veritabanını üniteye indirme, lütfen bekleyin",
	t743: "Veritabanı Başarıyla İndirildi, Şimdi Üniteyi Sıfırlama",
	t744: "Veritabanı Güncelleme Tamamlandı, Birim Sıfırlama Başarıyla",
	t745: "Veritabanı Güncelleme Başarısız Oldu! Lütfen tekrar deneyin.",
	t746: "Veritabanını güncelleştirme de hata",
	t747: "Ön İndirme Denetimi",
	t748: "Veritabanını Bul",
	t749: "VeritabanıNı İndirme",
	t750: "Güncel Veritabanı Bilgileri",
	t751: "İndirilecek Veritabanını Seçin",
	t752: "Veritabanını PC'ye Kaydet (Upload)",
	t753: "Veritabanını Birime Yükleyin (İndir)",
	t754: "İhtiyaç Lar Gözetmeni",
	t755: "Start Upload",
	t756: "Lütfen bir veritabanı işlemi seçin:",
	t757: "Start Download",
	t758: "Geçerli Firmware Sürümü",
	t760: "Veritabanı indirme başarılı oldu!",
	t761: "Veritabanı Yöneticisine Hoş Geldiniz - Adım 1 / 2",
	t762: "Veritabanı Yöneticisine Hoş Geldiniz - Adım 2 / 2",
	t763: "Birim Bağlantısı Arızalı, Lütfen Host IP/URL Adresini Kontrol Edin",
	t764: "Bir sistemin veritabanını uzaktan yükseltmek, sistem hatasına neden olabilecek bir hata riski taşır.",
	t765: "Veritabanı indirme tamamlandı",
	t766: "Ethernet Scan",
	t767: "Önce IP adresini girin sonra başlat'a basın",
	t768: "Tarama",
	t769: "Tarama",
	t770: "Geçmiş Günlük Koleksiyonu",
	t771: "Analog grafik renkleri",
	t772: "Dijital grafik renkleri",
	t773: "Kapatma Zamanlamaları",
	t774: "Defrost Özeti",
	t775: "Gece gerileme programları",
	t776: "Case Aydınlatma Programları",
	t777: "Yükleme Durumu",
	t778: "Alarm Günlüğü Koleksiyonu",
	t779: "Alarm dosyalarını depolamak için klasörü seçin...",
	t780: "Aylık",
	t781: "Alarm bilgilerini periyodik olarak bir dosyaya almak ve kaydetmek için otomatik alarm toplamayı etkinleştirin.",
	t782: "CSV Ayırıcı",
	t783: "Alarm Yok",
	t784: "Birim için alarm sayımları alma:",
	t785: "Birimden alarm ayrıntılarını alma:",
	t786: "Otomatik alarm toplama etkinleştirilir. Bu programı kapatmak istediğinizden emin misiniz?",
	t787: "Saatlik",
	t788: "Bu, birimin veritabanını günceller ve konumu geçerli görünümün merkezine ayarlar. Devam?",
	t789: "işlenen numuneler",
	t790: "Lütfen bir emme grubu yapılandırın.",
	t791: "Düzen Sihirbazı'nı birden fazla kez yeniden kullanmak için soğutma veritabanı düzeninin sıfırlanması gerekir.",
	t792: "Do this via the Configuration-&gt;Control page and set the number of Racks/Packs to zero.",
	t793: "Not: Önceki soğutma yapılandırmasına dayalı tüm zamanlamaların, hesaplamaların veya içe aktarımların yeniden yapılandırılması gerekir.",
	t794: "Kopyalama işlemi tamamlandı.",
	t795: "Soğutma Raporu",
	t796: "Son HVAC Düzeni",
	t797: "HVAC düzeni kurulumu başarıyla tamamlandı. Bu sihirbazdan çıkmak için tamam düğmesine basın.",
	t798: "HVAC düzenini yapılandırma...",
	t799: "HVAC Düzen Sihirbazı'nı birden fazla kez yeniden kullanmak için HVAC veritabanı düzeninin sıfırlanması gerekir.",
	t800: "Do this via the Configuration-&gt;Control page and set the number of HVAC units to zero.",
	t801: "Not: Önceki HVAC yapılandırmasına dayalı tüm zamanlamaların, hesaplamaların veya içeri aktarımların yeniden yapılandırılması gerekir.",
	t802: "Staging pattern",
	t803: "Adım -ları",
	t804: "Level",
	t805: "Birden Çok Veri İpucu Göster",
	t806: "Adım Sayısı",
	t807: "Dosyakaydedilemedi.",
	t808: "Yanlış model.",
	t809: "Yük başarılı.",
	t810: "Open",
	t811: "Bu özellik yalnızca StoreView'Un masaüstü sürümünde kullanılabilir.",
	t812: "Devam",
	t813: "Sihirbazı Bırak",
	t814: "Operasyon başarılı oldu. Devam etmek ister misin yoksa büyücüden ayrılmak mı?",
	t815: "Alarm logger verilerini alırken hata.",
	t817: "Cihaz Adı :",
	t819: "In Progress...",
	t820: "Alarm verileri yok.",
	t821: "Alarm Sayısı",
	t822: "Taklit Ekran Seçenekleri",
	t823: "Yalnızca metni görüntüleme",
	t824: "Yalnızca simgeyi görüntüleme",
	t825: "Metni ve simgeyi görüntüleme",
	t826: "1 Hafta",
	t827: "2 Hafta",
	t828: "3 Hafta",
	t829: "4 Hafta",
	t830: "Grafik Ayarları",
	t831: "Alarm tanılamagörüntülemiyor, tercihleri altında otomatik alarm toplama kontrol edin",
	t832: "Yapılandırmaya dahil olan IO raflar veya devrelerle yeniden sipariş edilmiyor.",
	t833: "Bu Ana Bilgisayar IP/URL Adresi adres defterinde zaten mevcut. Bu girişi güncellemek ister misiniz?",
	t834: "Gözat",
	t835: "Adres Defteri Klasörünü Seçin",
	t836: "Konum",
	t837: "Seçili konumda adres defteri içeriği yoktur. Varolan adres defterinin içeriğini seçili konuma aktarmak ister misiniz?",
	t838: "Alarm Action Codes",
	t839: "Adres defteri dosyası seçili yolda bulunmaz. Adres defteri yerel den yüklenir ...",
	t840: "Günlükleri kaydetmek için lütfen konumu seçin.",
	t841: "Günlükleri kaydetmek için lütfen geçerli bir konum seçin.",
	t842: "Alarm günlüğü koleksiyonu devam ediyor...",
	t843: "Geçmiş günlük koleksiyonu devam ediyor...",
	t844: "Arka Planda Çalıştır",
	t845: "Alarm log progress",
	t846: "Ana bilgisayar IP/URL Adresi boş olmamalıdır",
	t847: "Adres alanı boş olmamalıdır",
	t849: "Dosyanın en son sürümü yok, en son sürümden veri yüklemek ister misiniz?",
	t850: "Sürüm çakışması",
	t851: "Tarih Günlüğü İlerleme",
	t852: "Kullanıcı Adı ve Parola alanları boş olmamalıdır.",
	t853: "IP Adresi alanı boş olmamalıdır.",
	t854: "Ana bilgisayar IP/URL Adresi alanı boş olmamalıdır.",
	t855: "Kullanıcı adı boş olmamalıdır.",
	t856: "Parola boş olmamalıdır.",
	t857: "Bağlı olduğunuz SM800",
	t858: "İlk Başlangıç Sihirbazı'nı tamamlamadı. Lütfen bu görevi tamamlayın ve SM800'e yeniden bağlanın",
	t859: "Şimdi ayarlayın",
	t860: "Geçersiz giriş",
	t861: "Metin çok uzun",
	t862: "Uzaklık",
	t863: "Ana ağda birim(ler) ile iletişim kuramıyor",
	t864: "Lütfen adreslerin farklı olduğunu doğrulayın. Kaydet düğmesine basıldığında yapılandırma güncelleştirilir.",
	t865: "Lütfen geçerli bir Geçmiş Dosyası (.hst) seçin",
	t866: "Lütfen geçerli bir Geçmiş Grubu Dosyası (.grp) seçiniz",
	t867: "Renk",
	t868: "Yüzde",
	t869: "Gerilim",
	t870: "Şu anki",
	t871: "Güç",
	t872: "Frekans",
	t873: "Konsantrasyon",
	t874: "Akışı",
	t875: "Hız",
	t876: "Asit",
	t877: "Lütfen altçekmece'deki grafikleri seçin",
	t878: "PNG kaydet",
	t879: "Çiz -mek",
	t880: "Sistem Durumu...",
	t881: "Sistem ve birim durumu",
	t882: "Gataway",
	t883: "Özellikler lisansları",
	t884: "Yazılım sürümü",
	t885: "Tarayıcı modülü sürümü",
	t886: "Danux versiyonu",
	t887: "Mac Adresi",
	t888: "Destek ve Güncellemeler",
	t889: "Birim adı",
	t890: "Yeniden bağlanma",
	t891: "Kayıp Bağlantı",
	t892: "{{type}} donanımı bulunamadı.",
	t893: "Şu anda kullanılan yazılım sürümü desteklenmiyor",
	t894: "Hızlı bağlantılar",
	t895: "Ekipman",
	t896: "Grafik",
	t897: "Yardımcı program",
	t898: "Yazılım geri alma",
	t899: "Tanılama",
	t900: "Bir hata oluştu",
	t901: "{{alarms}} veri bulunamadı",
	t902: "Ayarlar",
	t903: "Başvuru adı",
	t904: "Uygulama sürümü",
	t905: "İleti",
	t906: "Olay türü",
	t907: "Zaman damgası",
	t908: "Geri alma sürümü",
	t909: "Geçerli sürüm",
	t910: "Yazılım",
	t911: "Geri alma...",
	t912: "Lütfen listeden ekipman seçin",
	t913: "Takvim Görünümü",
	t914: "Aygıtları yüklerken bir hata oluştu, lütfen yeniden deneyin.",
	t915: "Zamanlamaları yüklerken bir hata oluştu, lütfen yeniden deneyin.",
	t916: "Ekipman bulunamadı",
	t917: "Zamanlamalar Dışa Aktarma",
	t918: "Lütfen CSV veya PDF dışa aktarma türünü seçin",
	t919: "CSV olarak dışa aktarma",
	t920: "PDF Hazırlama",
	t921: "PDF olarak dışa aktarma",
	t922: "Lütfen bu formatta bir IP sağlayın xx.xx.xx.xx[:xxxx]",
	t923: "Username",
	t924: "Oturum açma",
	t925: "Geri alma",
	t926: "Rollback SM800A yazılımı",
	t927: "Bu eylem, tüm yazılımları listelenen sürümlere geri alacaktır.",
	t928: "Parti Cihazı Editörü",
	t929: "özelliği açıklayan bazı metin",
	t930: "CDF'yi güncelleştir",
	t931: "Harita Cihazı",
	t932: "Aygıt dosyası",
	t933: "1 tabanlı kayıt numarası",
	t934: "Depolama türü",
	t935: "Dk",
	t936: "Max",
	t937: "Yalnızca okuyun",
	t938: "Günlük",
	t939: "İleti Türü",
	t940: "Dönüşüm Anahtar Kelimesi",
	t941: "Parametre",
	t942: "Adresi.",
	t943: "Öncelik",
	t944: "Bit",
	t945: "Ters",
	t946: "Yeni dosya",
	t947: "Dosyayı aç",
	t948: "Dosya oluşturma",
	t949: "Grup",
	t950: "Ürün sürümü",
	t951: "Hata oluştu",
	t952: "Bulunamadı",
	t953: "Bu cihaz bulunamadı",
	t954: "Temp'in dışında.",
	t955: "{{equipment}} bulunamadı",
	t956: "Durumda",
	t957: "Alarm başarıyla sessize/hareket",
	t958: "Bu alarm için sessiz/hareket başarısız oldu",
	t959: "Ünite(ler) yüklenirken bir hata oluştu, lütfen yeniden deneyin.",
	t960: "Ekipman yok",
	t961: "{{type}} ekipmanı bulunamadı",
	t962: "Sistem yöneticisini yapılandırma",
	t963: "Aygıt",
	t964: "Geri alma",
	t965: "Alanın değerini değiştirdiniz, değişiklikleri kaydetmek istiyor musunuz?",
	t966: "Seçili geçmiş nokta(lar)ını temizle",
	t967: "Shr {{number}}",
	t968: "Saat pompa hızı",
	t969: "Sd",
	t970: "V3 saat",
	t971: "Saat referansı",
	t972: "Standart {{number}}",
	t973: "iki referans",
	t974: "iki pompa hızı",
	t975: "v3tw",
	t976: "trek",
	t977: "Prec",
	t978: "Vrek OD",
	t979: "Prec referansı",
	t980: "Trek referansı",
	t981: "Pc",
	t982: "Ss",
	t983: "Vhp OD'si",
	t984: "Pgc referansı",
	t985: "ejektör OD",
	t986: "Pgc",
	t987: "Shp",
	t988: "Sgc",
	t989: "sc3",
	t990: "Sgc ctrl.",
	t991: "koşul. kapasite",
	t992: "po",
	t993: "s",
	t994: "Sd",
	t995: "bilgisayar",
	t996: "çalışma kapasitesi",
	t997: "tarayıcı sertifikası",
	t998: "Çıkış Yap",
	t1000: "Yalnızca {{count}} üzerinde sıralama",
	t1001: "{{total}} alarmdan {{count}} tanesi yüklendi",
	t1002: "Tüm alarmlar için başarılı onay",
	t1003: "Tüm alarmlar için onay başarısız oldu",
	t1004: "{{total}} alarmdan yalnızca {{count}} tanesi onaylandı",
	t1005: "Tüm alarmlar için dışa aktarma başarısız oldu",
	t1006: "Dışa aktarma başarılı",
	t1007: "Dışa aktarma başarısız, en az bir alarm türü gerekli",
	t2000: "-",
	t2001: "32K bayt olan XML iletim arabelleği için yanıt çok büyük",
	t2002: "cmd öğesi veya komutuyla ilişkili hiçbir öznitelik \"cmd\" öğesi içermiyor veya bir değişkene yazamıyor veya bir değişkeni okuyamıyor veya bir noktayı okuyamıyor veya açık/kapalı giriş veya röle çıkışı çalışma modunu yazamıyor",
	t2003: "Kötü komut girişi",
	t2004: "CGI gönderi parametrelerinde hata",
	t2005: "XML yanıtını dahili temsilinden yanıt XML dizesine dönüştürme hatası",
	t2006: "URL dizesinin kodu çözülürken hata oluştu",
	t2007: "Bellek Taşması",
	t2008: "Yanıt verisi sıkıştırma başarısız oldu",
	t2009: "İstek ayrıştırılırken hata oluştu",
	t2010: "Veri erişim hatası - daha fazla çözülmemiş bir dizi neden",
	t2011: "Tanımsız komut eylemi öznitelik değeri Öznitelik değeri geçersiz",
	t2012: "Alarm Referans Numarasında Hata veya Alarm yapılandırılmamış",
	t2013: "Giriş cgi parametresi dizesi çok uzun.",
	t2014: "Cihaz adresi bulunamadı",
	t2015: "Yazma yetkisi başarısız oldu",
	t2016: "Yapılandırılmış geçmiş yok",
	t2017: "Geçmiş verisi döndürülmedi, zaman hatası olabilir",
	t2018: "Dosya hataları oluştur",
	t2019: "Dosya hatalarını oku",
	t2020: "Cihaz listesinin maksimum boyutu aşıldı",
	t2021: "kullanılmamış",
	t2022: "Geçersiz Geçmiş Dizini",
	t2023: "Düğüm türü, düğüm, mod ve noktadan pano noktası dizini bulunamadı.",
	t2024: "Geçmiş Dizini bulunamadı",
	t2025: "Bir geçmiş sorgusu başlatmaya çalışmadan önce geçmiş sorgusu başlatılmamış.",
	t2026: "Bir geçmiş sorgusu etkinken bir hq_start_query denendi",
	t2027: "Bazıları beklendiğinde hiçbir komut özelliği bulunamadı",
	t2028: "Sonsuz bir çağın başlangıcı sağlandı veya tam tersi",
	t2029: "Saat ve tarih biçiminden dönem saniyesine dönüştürülemedi",
	t2030: "Komut, bir query_id gerektiriyor ancak hiçbiri sağlanmadı",
	t2031: "XML yanıtı oluşturulurken hata oluştu",
	t2032: "Sağlanan sorgu_kimliği etkin bir sorguyla eşleşmiyor",
	t2033: "Bir hq_get_data komutu denendi ancak veriler henüz mevcut değil",
	t2034: "İzin verilenlerden biri olmayan bir örnekleme oranı sağlandı",
	t2035: "Ortalama alma aralığı, verilerin kaydedildiği örnekleme hızından küçük veya ona eşit",
	t2036: "Durma dönemi süresi, başlangıç dönemi zamanından daha az veya durma dönemi süresi eksi başlangıç dönemi süresi, ortalama alma aralığından daha az",
	t2037: "Sağlanan device_id, ak255 tarafından şu anda bilinen herhangi biriyle eşleşmiyor. Yalnızca yapılandırılan cihazların ak255 tarafından bilindiğini unutmayın.",
	t2038: "Kimlik özelliği yok",
	t2039: "Geçersiz Kimlik özellikleri",
	t2040: "Program numarası yok",
	t2041: "Geçersiz program numarası",
	t2042: "Program ayrıntıları yok",
	t2043: "on_time eksik",
	t2044: "eksik saat",
	t2045: "Geçersiz saat",
	t2046: "eksik dakika",
	t2047: "Geçersiz dakika",
	t2048: "Off_time eksik",
	t2049: "hafta içi eksik",
	t2050: "Geçersiz hafta içi günler",
	t2051: "Eksik tatiller",
	t2052: "Geçersiz tatiller",
	t2053: "Eksik veya geçersiz tatil_başlangıcı",
	t2054: "Eksik veya geçersiz tatil_sonu",
	t2055: "Eksik veya geçersiz tatil_open",
	t2056: "Eksik veya geçersiz tatil_kapat",
	t2057: "Tatil yapılandırılmamış",
	t2058: "Eksik açıklama",
	t2059: "Alarm temizlenemiyor",
	t2060: "Eksik veya geçersiz store_open",
	t2061: "Eksik veya geçersiz store_closed",
	t2062: "Alarm Yapılandırılmadı",
	t2063: "Düğüm Çevrimdışı",
	t2064: "Eksik Dizin",
	t2065: "Geçersiz Dizin",
	t2066: "Röle Zamanlanamaz",
	t2067: "Değiştirilen ad çok fazla karakter içeriyor. mağaza_adı veya birim_adı değiştiriliyorsa < = 16 karakter, mağaza kimliği1 veya mağaza kimliği2 değiştirilirse < = 8 karakter olmalıdır.",
	t2068: "Komut öğesiyle ilişkili eksik öğeler.",
	t2069: "Tag_ID tanımlanmadı",
	t2070: "Veri için Eksik Yaprak Düğümü",
	t2071: "Verilerde Değişiklik Yok",
	t2072: "Veritabanı güncellenemedi",
	t2073: "yazılabilir değil",
	t2074: "Shed metre hatası",
	t2075: "metre yok",
	t2076: "Geçersiz kuluçka seviyesi",
	t2077: "geçersiz tür",
	t2078: "sistem meşgul",
	t2079: "Yük atma için yapılandırılmamış varlıklar",
	t2080: "Varlık için Geçersiz Güç Derecesi",
	t2081: "Varlık için Geçersiz Başlatma Gecikmesi",
	t2082: "Yapılandırma Meşgul",
	t2083: "G/Ç Taraması Sürüyor",
	t2084: "Çok Fazla Defrost Sürüyor",
	t2085: "Birime kötü bir veritabanı sürümü yüklemeye çalışmak",
	t2086: "Veritabanı yüklemesi başarısız oldu",
	t2087: "Kullanılabilir web belleği yok",
	t2088: "Talep yanıtı yapılandırılmadı (Yalnızca AK355 sistemi)",
	t2089: "Eksik veya yanlış dosya boyutu.",
	t2090: "Eksik kodlanmış dosya (b64 öğeleri)",
	t2091: "Veritabanı RAM diskine kaydedilemedi.",
	t2092: "Dosya boyutu çok büyük.",
	t2093: "Dosya adı eksik.",
	t2094: "Dosya kimliği eksik.",
	t2095: "Eksik dosya yükleme indeksi (Not: bu, load_file load_database için sıra indeksidir).",
	t2096: "Dosya kimliği eşleşmiyor veya eksik.",
	t2097: "Dosya yükleme işlemi zaten devam ediyor.",
	t2098: "Kodu çözülen dosya, orijinal dosya boyutuyla eşleşmiyor.",
	t2099: "Dosya yükleme işlemi iptal edildi veya başlatılmadı.",
	t2100: "Geçersiz kullanıcı isteği (Orijinal istekte bulunan kişi IP adresiyle eşleşmiyor)",
	t2101: "Geçersiz dosya yükleme sırası.",
	t2102: "Geçersiz dizin",
	t2103: "Geçersiz uzantı",
	t2104: "Geçersiz ofset",
	t2105: "Dosya kodlama hatası",
	t2106: "Dosyanın kodunu çözme hatası",
	t2107: "Dosya okuma/yazma için açılamadı",
	t2108: "dosyaya yazılamadı",
	t2109: "Dosya, Flash dosya sisteminde yazmaya karşı korumalıdır",
	t2110: "Dosya silinemedi.",
	t2111: "Klasör silinemiyor.",
	t2112: "Eksik cihazlar",
	t2113: "URL'deki tüm cihazlar bulunamadı",
	t2114: "Eksik geçmiş parametreleri",
	t2115: "URL'deki tüm geçmiş paragrafları bulunamadı",
	t2200: "Şu anda kullanılan yazılım sürümü ( {{currentVersion}} ) önerilmez",
	t2201: "Şu anda kullanılan yazılım sürümü ( {{currentVersion}} ) önerilmez ( > {{recommendedVersion}} önerilir)",
	t2202: "Yazılım sürümü hatası",
	t2203: "Grafik Düzenleyici",
	t2204: "Düzenle",
	t2205: "Çiz -mek",
	t2206: "Yeni görüntülenme",
	t2207: "oluşturuldu",
	t2208: "Düşürmek",
	t2209: "Yeni Grafik",
	t2210: "Yeni bir grafik görünümü oluşturun",
	t2211: "Name",
	t2212: "Yeniden isimlendirmek",
	t2214: "İhracat",
	t2213: "Kopyalamak",
	t2215: "Silmek",
	t2216: "\" {{name}} \" görünümünü silmek istediğinizden emin misiniz?",
	t2217: "Tüm görünümleri dışa aktar",
	t2218: "Daha...",
	t2219: "Kural",
	t2220: "tüzük",
	t2221: "Kural adı",
	t2222: "Paneli düzenle",
	t2223: "Koşullar",
	t2224: "Gradyan",
	t2225: "koşulları tersine çevir",
	t2226: "Eşlemeler",
	t2227: "Harita şekilleri",
	t2228: "şekil kimliği",
	t2229: "eyaletler",
	t2230: "Eşleme ekle",
	t2231: "Kural ekle",
	t2232: "Şebeke",
	t2233: "koşul ekle",
	t2234: "Kurallar ekleyerek başlayın",
	t2235: "\" {{name}} \" kuralını silmek istediğinizden emin misiniz?",
	t2236: "Kuralın bir kopyası yapılır",
	t2237: "Site görünümleri",
	t2238: "Bunları görüntülemek için site görünümleri oluşturmanız gerekir.",
	t2239: "Grafik düzenleyiciye git",
	t2240: "Ayrıntılara git",
	t2241: "Parametre seç",
	t2242: "Parametre bulunamadı",
	t2243: "Seçenekler",
	t2244: "Açık/Kapalı Giriş",
	t2245: "Röle Çıkışı",
	t2246: "Sensör Girişi",
	t2247: "Değişken Çıkış",
	t2248: "Jenerik Cihaz",
	t2249: "Boş Düğüm",
	t2250: "Geçmiş kaydı desteklenmiyor...",
	t2251: "yeniden dene",
	t2252: "Geçmiş getirilemedi",
	t2253: "Yükleme geçmişi biraz zaman alabilir ve aynı anda birden fazla sorguyu desteklemez, lütfen bu isteği yeniden deneyin.",
	t2254: "Parametre adı",
	t2255: "Cihaz adı",
	t2256: "Cihaz tipi",
	t2257: "Tüm geçmiş parametreleri yüklenemedi ( {{n}} başarısız oldu)",
	t2258: "Kullanıcı adı ya da parola yanlış",
	t2259: "Kontrolörle iletişim yok",
	t2260: "Yazılım güncellemesi",
	t2261: "sistem",
	t2262: "Yazılım geri alma",
	t2263: "Düzenlemek",
	t2264: "Yazılım geri dönüşü daha yeni bir sürümün yerini alacak",
	t2265: "Yazılımı yedek sürüm {{version}} a geri alma",
	t2266: "Veri tabanı",
	t2267: "Bir güncelleme mevcut",
	t2268: "Veritabanını dışa aktar",
	t2269: "İndirmek",
	t2270: "veritabanı indiriliyor",
	t2271: "Veritabanı dışa aktarılamıyor",
	t2272: "Veritabanı dosyası",
	t2999: "İletişim hatası",
	t2998: "Bilinmeyen hata",
	t3000: "Detaylar",
	t3001: "Burada geçmiş XML kodu",
	t3002: "Atamak",
	t3003: "Durum",
	t3004: "Yazılım yönetimi",
	t3005: "Yerel saklanan dosya",
	t3006: "İnternet üzerinden çevrimiçi",
	t3007: "Güncelleme işlemi sırasında gücün kesilmediğinden emin olun. Sisteminizi güncellemeden önce sistem veritabanının yedeğini almanız önerilir. Yazılım güncellemelerini tamamladıktan sonra, doğru kontrol ve çalışmayı onaylamak için sistemin çalışma durumunu incelemeniz önerilir.",
	t3008: "Dosya seçilmedi",
	t3009: "Yazılım dosyasını seçin",
	t3010: "Dosya yükleme",
	t3011: "Güvenlik kontrolü",
	t3012: "Dosya ayıklanıyor",
	t3013: "Dosya kontrol ediliyor",
	t3014: "Dosya yüklenemedi!",
	t3015: "Güvenlik kontrolü başarısız oldu!",
	t3016: "Çıkarma başarısız oldu!",
	t3017: "Dosya kontrol edilemedi!",
	t3018: "Dosya indiriliyor: {{fileName}}",
	t3019: "Dosya başarıyla indirildi!",
	t3020: "Yükleme başarısız!",
	t3021: "Dosyaları yükle",
	t3022: "Dosyaları indir",
	t3023: "İşletim Sistemi Güncellemesi",
	t3024: "Edf güncellemesi",
	t3025: "Dosyaları indir",
	t3026: "Seçilen parametrenin değeri maalesef mevcut değildi",
	t3027: "İçe aktarmak",
	t3028: "En az bir dosya gerekli",
	t3029: "Dosya geçerli değil",
	t3030: "Grafik görünümlerini yönet",
	t3031: "Yüklemek",
	t3032: "Göç",
	t3033: "Grafik dosyası/dosyaları okunuyor, lütfen bekleyin...",
	t3034: "Grafik dosyası içe aktarılıyor, lütfen bekleyin...",
	t3035: "Grafik seç",
	t3036: "Raporlar",
	t3037: "Veritabanını içe aktar",
	t3038: "Veritabanı dosyasını seçin",
	t3039: "Geri alma işlemi sırasında gücün kesilmediğinden emin olun. Sisteminizi geri almadan önce sistem veritabanının yedeğini almanız önerilir. Yazılım geri alma işlemini tamamladıktan sonra, doğru kontrol ve çalışmayı onaylamak için sistemin çalışma durumunu incelemeniz önerilir.",
	t3040: "Güncellemeden sonra Sistem Yöneticisi yeniden başlatılacak",
	t3041: "Genişletmek",
	t3042: "Hata ayıklama mesajlarını göster",
	t3043: "Maks. izin verilen karakterler -",
	t3044: "Lütfen güncelleme dosyasını yükleyin",
	t3045: "Mevcut yazılım sürümü",
	t3046: "Mevcut Danux sürümü",
	t3047: "En son yazılım sürümü",
	t3048: "En son Danux sürümü",
	t3049: "Yedekleniyor lütfen bekleyin",
	t3050: "Dosya yükleniyor lütfen bekleyin",
	t3051: "İndirme güncellemesi",
	t3052: "Cihaz yeniden başlatılıyor",
	t3053: "Sistem yöneticisini yeniden başlatma",
	t3054: "Tarayıcı şimdi yenilenecek",
	t3055: "Yazılım başarıyla yüklendi",
	t3056: "Güncelleme başarısız oldu, aşağıdaki hata oluştu:",
	t3057: "Mevcut yazılım sürümünü yedekleyin",
	t3058: "Mevcut EDF dosyalarını yedekleyin",
	t3059: "Grafik görünümü başarıyla kaydedildi",
	t3060: "Arka plan resmini uygula",
	t3061: "Temizlemek",
	t3062: "yinele",
	t3063: "Mağaza",
	t3064: "Devre gecesi aksaklıkları",
	t3065: "Emme gecesi aksaklıkları",
	t3066: "{{fileName}} sürümü indirildi",
	t3067: "Seçilen birimleri güncellemek için 'Yükle'ye basın",
	t3068: "{{updateType}} sürümünü geri al",
	t3069: "yükleme",
	t3070: "Geri alma sürümünü yükleyin",
	t3071: "Kullanılabilir birimlerin hiçbiri herhangi bir geri alma sürümü içermiyor",
	t3072: "Yeni sürüm çevrimiçi olarak kullanılabilir",
	t3073: "Seçili birimler için dosya yükle",
	t3074: "Seçilen birim için dosya yükle",
	t3075: "Birim için geri alma sürümü yok",
	t3076: "İndirme tamamlandı",
	t3077: "Mevcut {{updateType}} sürümü",
	t3078: "En son {{updateType}} sürümü",
	t3079: "Güncelleme devam ediyor, pencereyi kapatmak istediğinizden emin misiniz?",
	t3080: "Danux güncel değil! Lütfen Danux'u güncelleyin ve ardından yazılımı tekrar güncelleyin",
	t3081: "Uygulamalar bu sürüme düşürülemez",
	t3082: "Başvuru bilgisi",
	t3083: "Sürüm notları",
	t3084: "Şartlar ve Gizlilik",
	t3085: "lisanslar",
	t3086: "Programları ayarlamak için lütfen yapılandırmaya gidin",
	t3087: "Maalesef taşıma hazırlanırken bir hata oluştu. Lütfen tüm birimlerin ağ üzerinde erişilebilir olduğundan ve uygun şekilde yapılandırıldığından emin olun. \n\n Aşağıdaki hata oluştu:\n {{error}}",
	t3088: "Mevcut bir grafiği içe aktarın veya taşıyın",
	t3089: "Görüntülemek",
	t3090: "Varlık Adı",
	t3091: "Çöküş",
	t3092: "İçe aktarma tamamlandı",
	t3093: "Deşarj güvenliği",
	t3094: "emme güvenliği",
	t3095: "Deşarj Kesimi",
	t3096: "Deşarj Kesimi",
	t3097: "Emme Kesiği",
	t3098: "Emme Kesimi",
	t3099: "Kapasite",
	t3100: "Taşıma dosyaları (flp, dpj veya json) bulunamadı",
	t3101: "Zaman aşımı ayrıştırma dosyası",
	t3102: "Üçüncü taraf cihaz dosyaları ve adresleri",
	t3103: "Kafes",
	t3104: "Kilit",
	t3105: "xml",
	t3106: "Görüntülenecek parametreyi seçin",
	t3107: "Cihaz parametresi",
	t3108: "Birim yok",
	t3109: "Bulunan veya yüklenen birim(ler) yok",
	t3110: "HACCP",
	t3111: "VARLIK",
	t3112: "Kullanılabilir haccp verisi yok",
	t3113: "Ünite Bilgileri",
	t3114: "Güncellemeler mevcut",
	t3115: "Yazılıma Git",
	t3116: "Bağlı değil",
	t3117: "Bu uygulama için mevcut erişim kapsamı ' {{targetLevel}} ', sağlanan kullanıcı erişim hakları açısından bu kapsamın üzerinde",
	t3118: "PDF'yi dışa aktarma",
	t3119: "PDF dışa aktarma başarısız oldu",
	t3120: "Büyük veri kümelerinde arayüz yanıt vermeyebilir ve tamamlanması biraz zaman alabilir",
	t3121: "Lütfen listeden programları seçin",
	t3122: "Bir program ayarlamak için lütfen yapılandırmaya gidin",
	t3123: "Başarı",
	t3124: "Uyarı",
	t3125: "Site Bilgileri",
	t3126: "Lütfen listeden zaman ve veri noktalarını seçin",
	t3127: "Bağlanıyor",
	t3128: "Bu siteye bağlantı kurulamadı, lütfen destek ile iletişime geçin.",
	t3129: "Seçilen tarihte HACCP verisi mevcut değildi. Lütfen yapılandırma menüsünde geçmişin ve zamanın doğru yapılandırıldığından emin olun",
	t3130: "teşhis raporu",
	t3131: "Tanılama raporunu dışa aktarmak için birim(ler)i seçin",
	t3132: "Bu kullanıcı erişim düzeyinde mevcut değil",
	t3133: "Teşhis Günlüğü",
	t3134: "rapor indirilemedi",
	t3135: "Yazı tipi",
	t3136: "yazı Boyutu",
	t3137: "Taşınacak görünüm yok",
	t3138: "Masaüstü sürümünü indirin",
	t3139: "Modern tarayıcılardaki güvenlik kısıtlamaları nedeniyle (",
	t3140: "RFC1918",
	t3141: ") şifrelenmemiş HTTP kullanarak özel ağ alanı içindeki cihazlara bağlanmak maalesef mümkün değildir. Sistem yöneticinizi HTTPS kullanacak veya masaüstü sürümünü kullanacak şekilde yapılandırın",
	t3142: "Seçilen dönem için veri yok",
	t3143: "Sistem yöneticinizi HTTPS veya masaüstü sürümünü kullanacak şekilde yapılandırın.",
	t3144: "Sistem Yöneticisi ile iletişim kuramadık. Bu, cihazınızın Sistem Yöneticisinin IP'sine veya Ana Bilgisayar Adına bağlanamayacağı veya cihazda kullanılan SSL sertifikasına manuel olarak güvenmeniz gerekebileceği anlamına gelir.",
	t3145: "Sertifikaya güvenmek için yeni bir sekme açmak için aşağıdaki bağlantıya tıklayın ve ardından bu ekrana dönün.",
	t3146: "İmza",
	t3147: "DI - dijital girişler",
	t3148: "AI - analog girişler",
	t3149: "HACCP yapılandırmasına gidin",
	t3150: "Veri yok",
	t3151: "Yedekleme, Geri Alma özelliği için Sistem Yöneticisine kaydedilir",
	t3152: "Site görünümlerinde nelerin görüntüleneceğini seçin",
	t3153: "Varlık birimi",
	t3154: "Grafik Dosyaları",
	t3155: "Maalesef sistem yöneticisinden edf dosyaları ve sürümleri alınırken bir hata oluştu...",
	t3156: "Hangi dosyaların yükleneceğini seçmek için 'Dosya seç' düğmesine basın",
	t3157: "Dosyaları seçin",
	t3158: "Yedeklemeyi kaldırma",
	t3159: "Dosyaları hazırlama",
	t3160: "Yedekleme, Geri Alma özelliği için Sistem Yöneticisine kaydedilir",
	t3161: "yüklü boyut",
	t3162: "Yeni boyut",
	t3163: "Kurulum tarihi",
	t3164: "Yeni tarih",
	t3165: "Sensörler",
	t3166: "Röleler",
	t3167: "girişler",
	t3168: "çıkışlar",
	t3169: "metre",
	t3170: "Genel",
	t3171: "Şifrenin süresi doldu, lütfen yeni şifre girin",
	t3172: "Yeni Şifre",
	t3173: "Şifreyi Onayla",
	t3174: "Şifre değiştir",
	t3175: "Şifre en az 8 karakter içermelidir 1 büyük harf, 1 küçük harf, 1 rakam, 1 özel karakter)",
	t3176: "Parolalar uyuşmuyor",
	t3177: "Şifre eski şifre ile aynı olamaz",
	t3178: "Şifre değişikliği başarılı",
	t3179: "Şifre değiştirilemez. Cihazdan hata",
	t3180: "Parola sıfırlama",
	t3181: "oluşturulan",
	t3182: "Site adı",
	t3183: "Olay Kimliği",
	t3184: "Zaman",
	t3185: "rol",
	t3186: "Etkinlik bilgisi",
	t3187: "Seçilen alarmları onayla",
	t3188: "Seçilen tüm alarmları onayla",
	t3189: "Veritabanını şuraya aktar:",
	t3190: "Veritabanı içe aktarılıyor",
	t3191: "Dosya türü .zip olmalıdır",
	t3192: "Dosya Seç",
	t3193: "Açılış saatleri",
	t3194: "Bayram",
	t3195: "Açık/Kapalı Programları",
	t3196: "Varlık",
	t3197: "Kaydedilmemiş değişiklikleriniz mevcut. Ayrılmak istediğinden emin misin?",
	t3198: "Maalesef geçiş başarısız oldu. Sistem şu anda meşgul. Lütfen daha sonra tekrar deneyiniz",
	t3199: "Mevcut boyutlara göre ayarlanan resim yükleyin",
	t3200: "Arka plan resmini değiştir",
	t3201: "Arka plan resimlerini Ölçeklenebilir Vektör Grafikleri ile değiştirin.",
	t3202: "Yalnızca mevcut dosyalarla aynı ada sahip SVG dosyaları değiştirilir.",
	t3203: "Değiştirilecek dosyaları seçin",
	t3204: "Görüntü dosyaları",
	t3205: "işlenemez. Dosyanın biçimi geçersiz.",
	t3206: "{{amount}} dosya başarıyla değiştirildi",
	t3207: "{{amount}} dosya(lar) değiştirilemedi",
	t3208: "Görünümün arka planı yok",
	t3209: "Yeni bir arka plan resmi yükleyebilir veya mevcut bir arka plan resmini değiştirebilirsiniz",
	t3210: "Bir arka plan görüntüsünü değiştiriyorsanız, aşağıdaki durumlarda parametre konumları korunabilir:",
	t3211: "Bir png veya jpg dosyası, geçerli arka planla aynı çözünürlüğe sahip",
	t3212: "Bir svg dosyası aynı en-boy oranına sahiptir (ancak aynı çözünürlüğe sahip olması gerekmez)",
	t3213: "Özellik devre dışı",
	t3214: "Şu anda son alınan değer gösteriliyor, güncelleniyor...",
	t3215: "İçinde oluşturulan bir grafik dosyasını yükleyin",
	t3216: "(isteğe bağlı)",
	t3217: "Arka plan görüntüsü",
	t3218: "Araştır",
	t3219: "Daha...",
	t3220: "Arka plan resmi ekleyin (isteğe bağlı)",
	t3221: "Grafik dosyası",
	t3222: "İletişimde zaman aşımı",
	t3223: "Yapılandırılmış soğutma programı yok",
	t3224: "dizin",
	t3225: "Tip",
	t3226: "Bayram",
	t3227: "Günler",
	t3228: "Açık",
	t3229: "Kapalı",
	t3230: "İD",
	t3231: "Açık",
	t3232: "Başlama",
	t3233: "Son",
	t3234: "Tanım",
	t3235: "Gün",
	t3236: "Kapat",
	t3237: "Edf dosya(lar)ı başarıyla yüklendi. Sistem yöneticisini yeniden başlatma",
	t3238: "Edf dosyalarının yüklenmesi tamamlandı, sistem yöneticisi başarıyla sıfırlandı",
	t3239: "Sistem yöneticisinden edf dosyaları alınırken hata oluştu",
	t3240: "Edf dosya(lar)ı yüklenemedi. bir süre sonra dene",
	t3241: "Yapılandırılmış soğutma programı yok",
	t3242: "Dosyayı sistem yöneticisine yükle",
	t3243: "Cihaz türünü belirtin - onu çalıştırmak için gerekli parametre etiketlerini etkiler",
	t3244: "Cihazın değerlerine nasıl erişildiğini ve hangi işlevi yerine getirdiğini belirtin",
	t3245: "Biraz parametreye dayalı bir alarm oluşturun",
	t3246: "Etiketler",
	t3247: "Bir parametreye atanabilecek bir etiket listesi oluşturun",
	t3248: "Gruplar",
	t3249: "Parametre gruplarını belirtin",
	t3250: "Kayıt Numarası",
	t3251: "Veri tipi",
	t3252: "Etiketlerin Listesi",
	t3253: "Asgari",
	t3254: "Varsayılan",
	t3255: "Maksimum",
	t3256: "Kayıt Türü",
	t3257: "Kayıt tipi, okuma ve yazma için ilgili fonksiyon kodlarına çevrilir",
	t3258: "Takas Modu",
	t3259: "Aygıta gönderilen ABCD baytlı bir değer için, bu seçenek baytları (BADC), sözcükleri (CDAB) veya her ikisini (DCBA) değiştirir.",
	t3260: "Sadece oku",
	t3261: "Kerestecilik",
	t3262: "Bu parametre için geçmiş günlüğünü etkinleştirir",
	t3263: "Parametreden alarma kadar metni devral",
	t3264: "Onlardan parametrenin en az anlamlı bitini sayan alarm bayrağının konumu",
	t3265: "Düşük değerde alarmı tetikle",
	t3266: "Etiket",
	t3267: "Saklamak",
	t3268: "{{field}} zorunlu bir alandır.",
	t3269: "Maksimum, daha büyük veya minimuma eşit olmalıdır",
	t3270: "Varsayılan değer [Minimum, Maximum] içinde olmalıdır",
	t3271: "Seçilen Kayıt Türü {{mt}} {{reg}} olmalıdır",
	t3272: "Ad, sınırlayıcı işaretler içermemelidir",
	t3273: "Ad 20 karakteri geçmemelidir",
	t3274: "Etiketler Listesi boş bırakılamaz",
	t3275: "{{taglist}} etiketleri tam olarak bir kez atanmalıdır.",
	t3276: "{{grouplist}} maksimum 50 parametreden fazlasını içeriyor.",
	t3277: "Formda hatalar var, lütfen hataları inceleyin ve tekrar deneyin.",
	t3278: "Göndermek",
	t3279: "Yüklenen EDF'yi etkinleştirmek için üniteyi sıfırlamanız gerekir. Üniteyi şimdi sıfırlamak istiyor musunuz?",
	t3280: "Şimdi sıfırla",
	t3281: "Daha sonra sıfırla",
	t3282: "{{file}} zaten var. Üzerine yazmak istiyor musunuz?",
	t3283: "{{what}} yükle",
	t3284: "Etiketler ve değerler benzersiz olmalıdır.",
	t3285: "Çok",
	t3286: "Cihaz birden çok vakayı veya paketi işler",
	t3287: "Etiketler",
	t3288: "Internet Explorer'ın kullanım ömrü sona erdi ve StoreviewWeb tarafından desteklenmiyor",
	t3289: "Lütfen Edge, Chrome veya Firefox gibi desteklenen bir tarayıcıya geçin",
	t3290: "Daha fazla bilgi",
	t3291: "Kapat",
	t3292: "Veriler okunamadı, lütfen tekrar deneyin",
	t3293: "Yedekleme EDF'leri devam ediyor",
	t3294: "Taşınacak görünüm yok",
	t3295: "Sistem, özel IP alanında genel bir IP ile yapılandırılmıştır.",
	t3296: "Sistem, genel IP alanında özel bir IP ile yapılandırılmış, sisteme uzaktan erişilemeyebilir veya bazı işlevler düzgün çalışmayabilir.",
	t3297: "Sistem Yöneticisi tarafından bilinmeyen bir IP veya ana bilgisayar adı üzerinden bağlısınız, muhtemelen bir proxy hizmeti aracılığıyla, bazı işlevler düzgün çalışmayabilir",
	t3298: "Cihaz çevrimdışı, bilinen son değeri gösteriyor",
	t3299: "Cihaz Entegrasyonu",
	t3300: "{{taglist}} etiket(ler)i kopyalanmış.",
	t3301: "sayfa bulunamadı",
	t3302: "Üzgünüz, istediğiniz sayfa bulunamadı",
	t3303: "Hata kodu: 404",
	t3304: "Kontrol paneline git",
	t3305: "Sadece Görüntüle",
	t3306: "Dahili IP Adresi",
	t3307: "Harici IP Adresi",
	t3308: "Alan adı",
	t3309: "Uyarı Yok",
	t3310: "Çoklu uyarılar",
	t3311: "Sistem birden fazla birim ile yapılandırılmış gibi görünüyor, ancak bir veya daha fazla bağımlı birim ile iletişim kuramadık, lütfen ayarların doğru olduğundan emin olun. Lütfen çok birimli bir kurulumun birden çok alan adını desteklemediğini ve aynı etki alanını kullanırken her birimin ayrı bir bağlantı noktasıyla yapılandırılması gerektiğini unutmayın.",
	t3312: "Sadece Görüntüle",
	t3313: "Bu öğenin herhangi bir parametreyle ilişkisi yoktur ve güvenle kaldırılabilir",
	t3314: "Parametreyi kaldır",
	t3315: "Takvim",
	t3316: "Şimdi çıkış yapacaksınız ve yeni şifre ile giriş yapmanız gerekecek",
	t759: "Veritabanı indirildi, sıfırlama ve yükseltme birimi. Bu birkaç dakika sürebilir.",
	t816: "Alarm Tipi :",
	t818: "Saymak :",
	t848: "DB dosyası oluşturulmadı. Kurulan dizine sınırlı erişiminiz var. Lütfen sistem yöneticisi ile iletişime geçin.",
	t3317: "Dışa aktarılacak program yok",
	t3318: "Sistemden yanıt bekleniyor",
	t3319: "Ağ etkinliği yok",
	t3320: "Dükkan adı",
	t3321: "Yeni sürüm {{latestVersion}} çevrimiçi olarak kullanıma sunuldu",
	t3322: "Harici Bağlantı Noktası",
	t3323: "Dahili Bağlantı Noktası",
	t3324: "Sistem yöneticisi ile şu adreste iletişim kuramıyoruz:",
	t3325: "belki de sertifikaya güvenilmesi gerekiyor?",
	t3326: "Bu formattaki dosya kaldırılamaz",
	t3327: "kullanım",
	t3328: "Zamanında",
	t3329: "Kapalı Zaman",
	t3330: "Hafta içi",
	t3331: "Soğutma Aşaması",
	t3332: "Isı Geri Kazanımı",
	t3333: "Soğutma",
	t3334: "Isıtma",
	t3335: "Yardımcı Isı",
	t3336: "Nem alma",
	t3337: "İnternet",
	t3338: "Mesajlar",
	t3339: "Kişiler",
	t3340: "İletişim",
	t3341: "hiç bir öğe bulunamadı",
	t3342: "Aramak için yazın",
	t3408: "Tümünü Etkinleştir",
	t3409: "Tümünü devre dışı bırak",
	t3410: "Normal",
	t3411: "Haşin",
	t3412: "Kritik",
	t3413: "Tüm alarmları etkinleştir",
	t3414: "Alarmları arayın",
	t3415: "Etkinleştirilmiş",
	t3417: "Alarmlar alınırken hata oluştu",
	t3418: "Kopyalanacak cihazlar getirilirken hata oluştu",
	t3419: "Kopyalama durumu kontrol edilirken hata oluştu",
	t3420: "Otomatik yoklama, en fazla 2 saatlik zaman aşımından sonra veya sihirbaz iptal edildiğinde veya tamamlandığında devam edecektir",
	t3421: "Denetleyiciye kopyala",
	t3422: "Seçili cihaza(lara) kopyalanıyor",
	t3423: "Kopyalama işlevi önce ayarları Sistem Yöneticisi veritabanına kopyalayacak, ardından ayarları denetleyici aygıtlara indirecektir. İşlemin tamamlanması biraz zaman alabilir.",
	t3424: "Kopyalanacak cihaz seçilmedi",
	t3425: "Kopyalamak için çift tıklayın",
	t3426: "Cihazlar getirilirken hata oluştu",
	t3427: "Kopyalama cihazları",
	t3428: "Kopyalanacak cihazı/cihazları seçin",
	t3429: "Burada yalnızca aynı tip denetleyiciler gösterilecektir",
	t3430: "Kopyalama işlemi başarılı oldu",
	t3431: "Geçersiz dosya. Beklenen dosya modeli:",
	t3432: "Ayarlar dosyası yüklenirken hata oluştu",
	t3435: "Seçili cihazı bu gruba {{toPackGroup}} taşımak istediğinizden emin misiniz?",
	t3343: "Keşif",
	t3344: "Tabloyu yenile",
	t3345: "Ağlar taranıyor, lütfen bekleyin...",
	t3346: "Ağ tablosu yenilendi",
	t3347: "Ağlar yenilenirken hata oluştu",
	t3348: "Ağlar yeniden taranırken hata oluştu",
	t3349: "Ağlar getirilirken hata oluştu",
	t3350: "Ağ taraması tamamlandı",
	t3351: "Hiçbir cihaz mevcut değil",
	t3352: "Ağ taraması",
	t3353: "Uncfg",
	t3354: "Sihirbazdaki cihazlarda yapılacak herhangi bir düzenleme Alsense izleme hizmetlerini etkileyebilir.",
	t3355: "Ağ",
	t3356: "Fieldbus tipini seçin",
	t3357: "Modbus/RS485 etkinleştirilmişse Danfoss değişken hızlı kompresör için SLV'yi seçebilirsiniz. Yerel veri yolu olarak SNMP'yi seçerken, Danfoss SNMP kontrolleri tarafından geçerli bir alt ağ adres aralığı girmeniz gerekecektir",
	t3358: "Kompresörler/Paketler",
	t3359: "Kompresörleri/paket kontrol cihazlarını yönetin",
	t3360: "Burada yalnızca Paket aygıtları yönetilebilir - Raf G/Ç sistemi değil. Taranan Uyumlu Çoklu Emiş Paketi Denetleyicileri başlangıçta 0 emiş grubu olarak görünecektir. Paket denetleyicisini yapılandırmak için AK-ST 500 (Servis Aracı) gereklidir.",
	t3361: "Kopyalanacak miktar",
	t3362: "Daha önce yapılandırılmış emiş grubunun küçültülmesi, o emiş grubuyla ilişkili tüm buharlaşma kontrollerini de kaldıracaktır.",
	t3363: "Emiş grubu sayısı sıfır olamaz, minimum emiş grubu sayısı en az bir olmalıdır.",
	t3364: "Sıkıştırıcılar/paketleri getirirken hata oluştu",
	t3365: "Kompresör/paket mevcut değil",
	t3366: "Çevrimiçi Cihazlar",
	t3367: "Çevrimdışı Cihazlar",
	t3369: "Paket Ekle",
	t3370: "Modbus/RS485'i seçerseniz SLV/ECFan için ek seçenekler göreceksiniz, bunlar Soğutma için kullanılır ve Hayır olarak seçilebilir.",
	t3371: "Devre Ekle",
	t3372: "Aygıtları ekleyin, kopyalayın veya kaldırın. Tüm aygıt türlerinin tüm alanların düzenlenmesine izin vermediğini ve G/Ç noktalarının adreslerinin yapılandırmada ayarlandığını unutmayın.",
	t3373: "Dava denetleyicilerini yönetin",
	t3374: "Bir cihaz ekle",
	t3375: "Kompresör/paket kontrol cihazlarını yönetin",
	t3376: "Veri Erişiminde Hata",
	t3377: "HVAC Düzeni başarıyla uygulandı",
	t3378: "Düzen HVAC",
	t3379: "Bu sayfadan ayrılmak istediğinize emin misiniz?",
	t3380: "Ayarlarınız kaybolabilir",
	t3381: "Lütfen bir cihaz yapılandırın",
	t3382: "Hızlı Kurulumdan Çık",
	t3383: "Hızlı kurulumdan çıkmak istediğinize emin misiniz?",
	t3384: "Ağ Geçitleri (AK-PI200)",
	t3385: "Bulunan ağ geçitlerini seçin",
	t3386: "IP Address",
	t3387: "Kaynak denetleyicisi",
	t3388: "Kaynak ayarları",
	t3389: "Kaynak alarmları",
	t3390: "Kaynak grafik",
	t3392: "Grafik",
	t3393: "Cihazlar",
	t3394: "Kopyalama aşaması sırasında otomatik yoklama askıya alınır. Kaynak ve hedef cihaz(lar)ın ana anahtarı KAPATILIR ve kopyalama tamamlandıktan sonra orijinal durumuna geri döner. Kopyalama sihirbazı tamamlandıktan sonra ana anahtarı kontrol ettiğinizden emin olun. Seçilen cihazlardan herhangi biri aktif alarmdaysa, o belirli nokta için alarm eyleminde yapılan herhangi bir değişiklik değiştirilmeyecektir. Orijinal ayar kalacaktır.",
	t3395: "Ayarları kopyalamak için bir kaynak cihaz seçin",
	t3396: "Zamanında",
	t3397: "Kopyalama işlevi, birim kapasitesine ulaşılana kadar mevcut grafik noktalarını kopyalayacaktır. Tanımlanan nokta sayısını görmek için Yapılandırma'ya gidin",
	t3398: "Kaynak cihaz grafiği",
	t3399: "Kaynak denetleyicisi için grafik noktalarını yapılandırın",
	t3400: "HACCP sıcaklık raporundaki noktaları kaydetmek için HACCP'yi etkinleştirin",
	t3401: "Puan ara",
	t3402: "Veri yok",
	t3403: "Grafik noktaları alınırken hata oluştu",
	t3404: "Devre dışı",
	t3405: "Kaynak cihaz alarmları",
	t3406: "Kaynak denetleyicide alarm ayrıntılarını yapılandırın",
	t3407: "Alarm Adı",
	t3436: "Aygıt, atanan gruptan kaldırılacak [ {{fromPackGroup}} ]",
	t3437: "Düzen Soğutma",
	t3438: "Soğutma düzeni başarıyla uygulandı",
	t3439: "Yeniden atama",
	t3440: "Paket denetimlerine vaka denetimleri atayın",
	t3441: "Mevcut kasalardan Kasa kontrollerini Kompresör/Paket kontrollerine atayın. Emiş grupları oluşturun",
	t3442: "Cihaz parametreleri",
	t3443: "Kopyalama için parametreleri seçin",
	t3444: "Canlı (çevrimiçi) bir cihazda çalışırken, cihazla iletişimden dolayı ayarlarda gecikme yaşanabilir.",
	t3445: "Kopyalanacak alarmları seçin",
	t3446: "Cihaz grafiği",
	t3447: "Kopyalanacak grafik noktalarını seçin",
	t3448: "Mevcut cihaz ayarlarını bir dosyaya kaydet",
	t3449: "Mevcut cihaz ayarlarını daha sonra cihazları yapılandırmak için kullanılabilecek bir dosyaya kaydedebilirsiniz. Dosyayı kaydettikten sonra sihirbazdan çıkmak isterseniz çıkış simgesini kullanın. Bağlı cihazlarla çalışıyorsanız, çıkmak ağ yoklamasını ve cihaz ana anahtarının doğru duruma dönmesini sağlayacaktır.",
	t3450: "Ayar dosyası(ları) başarıyla kaydedildi",
	t3451: "Hızlı Kurulum'a devam etmek mi yoksa çıkmak mı istiyorsunuz?",
	t3452: "Dosya(lar) kaydediliyor",
	t3453: "Ayarlar alınırken hata oluştu",
	t3454: "Kaynak aygıt ayarları",
	t3455: "Kaynak denetleyicide aygıt parametrelerini yapılandırın",
	t3456: "Şifre alanlarının hiçbiri boş olmamalıdır",
	t3457: "Son Haberler",
	t3458: "Address Book",
	t3459: "Son erişim",
	t3460: "Lütfen Adres Defterinizin yedeğini düzenli olarak alın.",
	t3461: "Adres Defteri başarıyla içe aktarıldı",
	t3462: "Import Address Book",
	t3463: "Export Address Book",
	t3464: "Site ekle",
	t3465: "Siteyi düzenle",
	t3466: "Siteyi sil",
	t3467: "Adres Defterini kullanmaya başlamadan önce bir Adres Defteri parolası oluşturmanız gerekir",
	t3468: "Site Adres Defterinize başarıyla eklendi",
	t3469: "Zaten Adres Defterinde",
	t3470: "Adres Defterine Ekle",
	t3471: "Siteye giriş yapın",
	t3472: "Site bilgisi başarıyla güncellendi",
	t3474: "Bildiri",
	t3475: "Site Adres Defterinizden başarıyla kaldırıldı",
	t3476: "Site başarıyla güncellendi",
	t3477: "Geçersiz Adres Defteri biçimi",
	t3478: "İşlemler",
	t3479: "Çevrimdışıyken kullanılamaz",
	t3480: "S55 veritabanı yüklenemedi",
	t3481: "S55 veritabanı yükleniyor",
	t3482: "Protokol",
	t3483: "Kullanıcının yazılım bilgilerini görme izni yok",
	t3484: "Şifre yalnızca alfanümerik veya sayısal olmalıdır",
	t3485: "Adres Defterine erişmek için bir Adres Defteri parolası oluşturmanız gerekir",
	t3486: "Adres Defteri şifresi doğru değil",
	t3487: "Site Adres Defterinde zaten mevcut",
	t3488: "Adres Defterini kullanmak için bir Adres Defteri parolası oluşturun. Parola yalnızca alfanümerik veya sayısal olmalıdır",
	t3489: "Adres Defteri şifrenizi girin",
	t3490: "Kopyalamak için çevrimiçi cihaz yok",
	t3491: "Adres Defteri şifresi oluştur",
	t3492: "Adres Defteri şifresini yenile",
	t3493: "Site şifresi boş olamaz",
	t3494: "İçeri aktarılacak Adres Defterinin parolasını girin",
	t3495: "Adres Defteri şifresi başarıyla oluşturuldu",
	t3496: "Adres Defteri şifresi başarıyla güncellendi",
	t3497: "İçe aktarılan Adres Defterinin şifresi doğru değil",
	t3498: "Lütfen site şifresini giriniz",
	t3499: "Adres Defterini Temizle",
	t3500: "Siteleri ara",
	t3501: "İçe aktarılacak Adres Defteri dosyasını seçin",
	t3502: "Adres Defterini şifresini çözmek için anahtar dosyasını seçin",
	t3503: "Şifrelenmiş adres defterini indirmek için dışa aktar'a basın",
	t3505: "Şifrelenmiş olarak",
	t3506: "şifrelenmemiş şifreler",
	t3507: "Geçersiz gizli anahtar",
	t3508: "İçe aktarma başarısız. Doğru anahtarı içe aktardığınızdan emin olun",
	t3509: "Mevcut sürüm yok",
	t3510: "Alsense Hizmetlerini Açın (abonelik gerektirir)",
	t3511: "Lütfen EULA şartlarını dikkatlice inceleyin. Şartları kabul edemiyorsanız, uygulamayı kullanmanıza izin verilmez.",
	t3512: "Adres Defteri ve Adres Defteri parolanız başarıyla temizlendi",
	t3513: "(gerekli)",
	t3515: "Adres Defteri boş",
	t3516: "Adres Defteri şifresi boş olamaz",
	t3519: "Adres Defterinizi temizlediğinizde Adres Defteri şifrenizi de kaldırmış olursunuz.",
	t3520: "Aralıkta değil. 0 ile {{maxAddr}} arasında olmalıdır",
	t3521: "Sistem Yöneticisi'nde saklanan tüm grafik görünümlerini indirmek için 'Dışa Aktar'a basın",
	t3522: "Bir Sistem raporu indirmek için 'İndir'e basın",
	t3523: "Doğrudan WiFi bağlantısında yalnızca ana ünite kullanılabilir",
	t3524: "Yerel grafik görünümleri yok. Yeni görünümler ekleyin veya mevcut görünümleri içe aktarın",
	t3525: "Uzaktan grafik görünümleri yok. Farklı bir adla aramayı deneyin",
	t3526: "Uzaktan grafik görünümleri yoktur. Yeni görünümler ekleyin, mevcut görünümleri içe aktarın veya taşıyın",
	t3527: "Yerel Görünümler ( {{totalFiles}} / 5)",
	t3528: "{{totalFiles}} maksimum 5 yerel grafikten",
	t3529: "Uzaktan Görünümler",
	t3530: "Dosya bir bitmap değil.",
	t3531: "Bitmap uzunluğu yanlış.",
	t3532: "Bitmap başlık boyutu yanlış.",
	t3533: "Bitmap boyutu yanlış.",
	t3534: "Palet boyutunda hata.",
	t3535: "Uyumsuz .bmp dosyası",
	t3536: "Yerel grafik dosyası silinirken hata oluştu",
	t3537: "Yerel grafik dosyasını çoğaltırken hata oluştu",
	t3538: "Yerel grafik dosyası dışa aktarılırken hata oluştu",
	t3539: "Grafik Sil",
	t3540: "Yerel bir grafik dosyasını silin",
	t3541: "Yerel grafik dosyasının silinmesi devam ediyor",
	t3542: "Yerel grafik dosyası başarıyla silindi. Sistem Yöneticisi yeniden başlatılıyor",
	t3543: "Yerel grafik dosyası silme işlemi tamamlandı. Sistem Yöneticisi başarıyla yeniden başlatıldı",
	t3544: "Kopyalanmış Grafik",
	t3545: "Yerel bir grafik dosyasını çoğaltın",
	t3546: "Yerel grafik dosyası çoğaltma işlemi devam ediyor",
	t3547: "Yerel grafik dosyası başarıyla çoğaltıldı. Sistem Yöneticisi yeniden başlatılıyor",
	t3548: "Yerel grafik dosyası çoğaltma işlemi tamamlandı. Sistem Yöneticisi başarıyla yeniden başlatıldı",
	t3549: "Grafik İhracatı",
	t3550: "Yerel bir grafik dosyasını dışa aktar",
	t3551: "Yerel grafik dosyası dışa aktarma işlemi devam ediyor",
	t3552: "Yerel grafik dosyası başarıyla dışa aktarıldı",
	t3553: "Sistem Yöneticisi bu eylemde yeniden başlatılacak",
	t3554: "Silmek istediğinizden emin misiniz?",
	t3555: "Kopyalamak istediğinizden emin misiniz?",
	t3556: "Sistem Yöneticisi'nde depolanan tüm yerel grafik görünümlerini indirmek için 'Dışa Aktar'a basın",
	t3557: "Yerel grafik dosyalarının dışa aktarımı devam ediyor",
	t3558: "Yerel grafik dosyaları başarıyla dışa aktarıldı",
	t3559: "Ürün kodu",
	t3560: "Bu ürün hakkında daha fazla bilgi edinin",
	t3561: "Fan eko",
	t3562: "Vana açılışı",
	t3563: "Aşırı ısınma",
	t3564: "Buharlaştırıcı basıncı",
	t3565: "Buharlaştırıcı sıcaklığı",
	t3566: "Kesmek",
	t3567: "Kesim",
	t3568: "Kasa kapısı",
	t3569: "Emiş sıcaklığı",
	t3570: "Demiryolu ısısı",
	t3571: "İzin verilen maksimum dosya sınırına ulaşıldı. İçe aktarılacak dosya(lar)ın seçimini kaldırın",
	t3572: "Dosyaları içe aktarmak için lütfen sistem yöneticisindeki mevcut dosyayı/dosyaları silin",
	t3573: "Yerel grafik dosyası başarıyla içe aktarıldı. Sistem Yöneticisi yeniden başlatılıyor",
	t3574: "Yerel grafik dosyası(ları) içe aktarımı tamamlandı. Sistem Yöneticisi başarıyla yeniden başlatıldı",
	t3575: "İzin verilen maksimum dosya sınırına ulaşıldı. İçe aktarılacak dosyayı/dosyaları silin",
	t3576: "İzin verilen maksimum grafik dosyası sayısı 5'tir",
	t3577: "Yerel grafik dosyası başarıyla yüklendi. Sistem Yöneticisi yeniden başlatılıyor",
	t3578: "Yerel grafik dosyası yüklemesi tamamlandı. Sistem Yöneticisi başarıyla yeniden başlatıldı",
	t3579: "Geçersiz dosya biçimi. {{supportedFileFormats}} dosyasını yükleyin",
	t3580: "Yerel ekranlar için dosya boyutu {{maxWidthAllowed}} x {{maxHeightAllowed}} piksel olmalıdır",
	t3581: "Uzaktan Görünüm",
	t3582: "Yerel Görünüm",
	t3583: "Storeview Web ana sayfası",
	t3584: "EULA şartları",
	t3585: "Güvenlik",
	t3586: "Güvenlik Uygulama Seviyeleri",
	t3587: "Geriye Dönük Uyumlu (en düşük güvenlik seviyesi - başlık kimlik doğrulamasının devre dışı bırakıldığını varsayarak)",
	t3588: "SvW/XML istekleri kullanıcı adı ve şifre içerir.",
	t3589: "İsteklerin temizlenmesi yok, önceki sürümlerle aynı güvenlik",
	t3590: "SvW veya mevcut XML bağlantılarında herhangi bir değişikliğe gerek yok",
	t3591: "İzin verici (sıkı düzeye geçiş olarak kullanılabilir)",
	t3592: "XML istekleri kullanıcı adı ve parola ve/veya bir oturum belirteci içerebilir. Oturum belirteci oturum açma isteğinin yanıtında alınır.",
	t3593: "Oturum belirteci AKSM-auth başlığında sağlanırsa, kullanıcı adları ve parolalar yok sayılır",
	t3594: "XML isteği temizlenir, herhangi bir sorun keşfedilirse istek yanıtında bir uyarı döndürülür",
	t3595: "Sıkı moda geçmeye hazırlık olarak bu uyarıları dikkate alın",
	t3596: "Oturum belirteci kullanılmazsa, oturum sonlandırma uygulanamaz",
	t3597: "Uzaktan kullanıcı arayüzünde (SvW) zaten destekleniyor",
	t3598: "3. taraf XML perspektifinden olası/gerekli değişiklikler",
	t3599: "Oturum belirteci kullanımı yok - herhangi bir değişikliğe gerek yok",
	t3600: "Oturum belirteci kullanımı - Kullanıcı adını / parolayı gövdeden kaldırın ve Strict'e hazırlık olarak uyarıları inceleyin",
	t3601: "Sıkı (en yüksek güvenlik seviyesi)",
	t3602: "XML istekleri kullanıcı adları ve parolalar içeremez, ancak AKSM-auth başlığında oturum belirtecini sağlamalıdır. İstekte bir sorun keşfedilirse, reddedilir",
	t3603: "Zaten (SvW)'de destekleniyor",
	t3604: "Üçüncü taraf bakış açısından gerekli değişiklikler",
	t3605: "kullanıcı adı ve parola XML istek yükünden kaldırılmalı ve oturum belirteci AKSM-auth adlı bir CORS başlığına yerleştirilmelidir",
	t3606: "Giriş Başarısız Geri Çekilme",
	t3607: "Uzak bir kullanıcının başarısız bir girişimden sonra tekrar oturum açmayı deneyebilmek için beklediği süre (saniye). Bekleme süresi her başarısız girişimle birlikte kümülatif olarak artar. 10 denemeden sonra kullanıcının oturum açmaya çalıştığı IP 24 saat boyunca engellenir. 0 olarak ayarlanırsa Oturum açma başarısızlığı geri çekmesi devre dışı bırakılır",
	t3608: "Varsayılan 30sn (maksimum 600sn / 0 = devre dışı)",
	t3609: "Ücretsiz Denemeler",
	t3610: "Geri çekilme uygulanmadan önce kaç oturum açma girişimine izin verilir",
	t3611: "Varsayılan 2 (maksimum 5)",
	t3612: "Engellemek",
	t3613: "Etkinleştirilirse Kullanıcı + IP geri çekilme sırasında engellenir, aksi takdirde Kullanıcı engellenir",
	t3614: "Varsayılan 'Kullanıcı' (Kullanıcı + IP / Kullanıcı)",
	t3615: "Kullanıcı başına oturum sayısı",
	t3616: "Bir oturum, bir kullanıcının üniteye başarıyla giriş yaptığı ve uzak arayüzde (SvW) bulunduğu yerdir",
	t3617: "Bir kullanıcının sahip olabileceği etkin Oturum sayısı. 0 olarak ayarlanırsa, bir kullanıcı maksimum Oturum miktarına (30) sahip olabilir",
	t3618: "Varsayılan 0",
	t3619: "Oturum Sonu",
	t3620: "Boşta kalan bir Oturum sona erdikten sonra kaç saniye. Bir Oturum sona ererse yeni bir oturum açmaya ihtiyaç vardır",
	t3621: "Varsayılan 900'ler (maksimum 3.600)",
	t3622: "Başlık Kimlik Doğrulaması",
	t3623: "HTTPS ve Başlık Kimlik Doğrulaması etkinleştirildiğinde, tüm 3. taraf XML istekleri başlıkta kullanıcı adı / parola kimlik bilgilerini geçirmelidir (temel kimlik doğrulama şeması)",
	t3624: "'Başlık Kimlik Doğrulamasını Kullan'ı etkinleştirmek sistemin yeniden başlatılmasını gerektirir. Bu işlemi gerçekleştirmek için 'Başlatmak İçin Bas' düğmesini kullanın",
	t3625: "Küçük",
	t3626: "Büyük",
	t3627: "Grafik Kaydet",
	t3628: "Yerel bir grafik dosyasını kaydedin",
	t3629: "Yerel grafik dosyası başarıyla silindi. Yerel grafik dosyası yükleme işlemi devam ediyor",
	t3630: "Yerel grafik dosyası başarıyla yüklendi. Sistem Yöneticisi yeniden başlatılıyor",
	t3631: "Yerel grafik dosyası kaydetme işlemi tamamlandı. Sistem Yöneticisi başarıyla yeniden başlatıldı",
	t3632: "Kaydetmek istediğinize emin misiniz?",
	t3633: "Ayrıştırma Başarısız Oldu",
	t3634: "Viz dosyası ayrıştırılamadı",
	t3635: "vz2 dosyası ayrıştırılamadı",
	t3636: "Viz Dosyasının Açılması Başarısız Oldu:",
	t3637: "Göç dosyaları (vz2, dpj veya json) bulunamadı",
	t3638: "Göç dosyaları (yani) bulunamadı",
	t3639: "Sistem Yöneticisi bu eylemde yeniden başlatılacak. Sistem Yöneticisi izin verici veya katı moddadır, bu nedenle oturum belirteci yeniden başlatıldığında sona erecek ve sistemden çıkış yapacaksınız",
	t3643: "Deşarj basıncı",
	t3644: "Toplam kW",
	t3645: "Toplam KVAR",
	t3646: "Toplam Kvarh+",
	t3647: "Talep etmek",
	t3648: "Güç faktörü",
	t3649: "Yazı rengi",
	t3650: "Arkaplan rengi",
	t3651: "Adres Defteri başarıyla dışa aktarıldı",
	t3652: "Kontrol modu",
	t3653: "Sc3 Dış sıcaklık",
	t3654: "Deşarj sıcaklığı",
	t3655: "Kondansatör çalışma kapasitesi",
	t3656: "Dışa aktarılacak veri yok",
	t3657: "Kullanıcının {{unit}} üzerindeki şifresi süresi doldu",
	t3658: "Verilen kimlik bilgileriyle {{unit}} e giriş yapılamıyor.",
	t3659: "Oturum belirteci desteğini almak için {{unit}} için aygıt yazılımı sürümünü güncelleyin veya güvenlik uygulama düzeyini değiştirin.",
	t3661: "V3gc",
	t3662: "Bölüm B",
	t3663: "Bölüm C",
	t3664: "Uyarlanabilir aşırı ısıtma kontrolü",
	t3665: "Kontrol durumu nedeni",
	t3666: "Kullanıcı oturum açamıyor. Birim adresi proxy yapılandırmasında ayarlanmamış",
	t3667: "Proxy yapılandırmasında iletişim protokolü belirtilmemiştir. Hatalara yol açabilir",
	t3668: "BT Güvenlik Bilgileri",
	t3669: "Güvenlik standartlarına uygun olarak Sistem Yöneticisi artık varsayılan olarak Sıkı oturum denetimine geçiyor; bu, oturum belirteçleri uygulanmadığı sürece herhangi bir uzak bağlantının/XML tabanlı uzak hizmetin artık çalışmayacağı anlamına geliyor.",
	t3670: "Danfoss, Sıkı modda devam etmeyi öneriyor ancak geriye dönük uyumluluk modu hala mevcut.",
	t3671: "Bunun Yapılandırma Güvenliği menüsünden değiştirilebileceğini ve Sistem Yöneticisi yerel denetimi ve site işletimi üzerinde hiçbir etkisinin olmayacağını unutmayın.",
	t3672: "Oturum denetimi hakkında daha fazla bilgi edinin",
	t3673: "Geri mod",
	t3674: "Sıkı mod",
	t3675: "Geriye dönük uyumluluk moduna tıklandığında, güncellenmiş aygıt yazılımına sahip tüm geçerli Sistem Yöneticisi(leri) geriye dönük uyumluluk moduna ayarlanacak, yeniden başlatılacak ve uygulama oturumu kapatılacaktır.",
	t3676: "İzin verilen maksimum Emiş grubu sayısı 12'dir",
	t3677: "Seçilen birimlerin 3.3.0'dan 4.X veya üstüne başarıyla güncellenmesi durumunda, son adımda Bitir düğmesine tıklandığında kullanıcı otomatik olarak çıkış yapacaktır.",
	t3678: "AK2 kontrolörleri için çoklu kasa (Paket/Kasa) desteklenmez",
	t3679: "4.0 sürümüyle tanıtılan güvenlik standartlarına uygun olarak, Sistem Yöneticisi varsayılan olarak Sıkı oturum denetimine geçecektir, yani oturum belirteçleri uygulanmadığı sürece herhangi bir uzak bağlantı/XML tabanlı uzak hizmet artık çalışmayacaktır. Bunun dışında, iletişim rel. 4.0'a yükseltilerek otomatik olarak HTTPS'ye ayarlanacaktır. Danfoss Sıkı modda devam etmeyi önerir, ancak geriye dönük uyumluluk modu mevcuttur ve bu da sistemi şimdiye kadar uygulanan ayarlara geri döndürecektir. Bu güncellemeyi bir HTTP ünitesinde uzaktan yapıyorsanız, lütfen BT kurulumunuzun HTTPS (443 numaralı bağlantı noktası) için hazır olduğundan veya sahada personel bulundurduğunuzdan emin olun.",
	t3680: "Yeni güvenlik uygulaması hakkında daha fazla bilgiyi burada bulabilirsiniz",
	t3681: "Bu sitenin http sürümüne erişim, tarayıcınızın kısıtlamaları nedeniyle engellenmiş olabilir. Bu sorunu çözme talimatlarını almak veya StoreView Web'in masaüstü sürümünü indirmek için SSS sayfamızı ziyaret edin.",
	t3682: "Sistem Yöneticisi SSS",
	t3683: "u17 Termal hava",
	t3684: "u91 Kesme sıcaklığı.",
	t3685: "u00 Ctrl. durumu",
	t3686: "u12 S3 hava sıcaklığı.",
	t3687: "u16 S4 hava sıcaklığı.",
	t3688: "u23 EEV OD %",
	t3689: "u21 Süper ısıtma",
	t3690: "u22 SüperısıtmaRef",
	t3691: "u26 EvapTemp Te",
	t3692: "S2 Gaz çıkış sıcaklığı.",
	t3693: "Stw8",
	t3694: "Sd-MT",
	t3695: "İki durum",
	t3696: "Tw etkinleştir",
	t3697: "İki pompa çalışıyor",
	t3698: "Stw3",
	t3699: "Stw4",
	t3700: "Stw2",
	t3701: "Rcv. kontrol durumu",
	t3702: "Emiş ofseti",
	t3703: "Vrec kontrol modu",
	t3704: "Vrec durumu",
	t3705: "Ctrl durum nedeni",
	t3706: "Hp kontrol durumu",
	t3707: "Pgc maksimum.",
	t3708: "Pgc max. limit P-ban",
	t3709: "Pgc en az.",
	t3710: "Buhar ejektör kapasitesi",
	t3711: "Sıvı ejektör kapasitesi",
	t3712: "Referans hava sıcaklığı.",
	t3713: "Besleme havası sıcaklığı.",
	t3714: "Geri dönüş havası",
	t3715: "Bölge çiğ noktası",
	t3716: "Bölge nemi",
	t3717: "Dış ortam sıcaklığı",
	t3718: "Bölge CO2 seviyesi",
	t3719: "Bölge sıcaklığı.",
	t3720: "Koşullu referans",
	t3721: "Koşul kontrol durumu",
	t3722: "Koşul kontrol durumu",
	t3723: "Koşul ctrl.",
	t3724: "Koşul talep edilen sınır.",
	t3725: "V3gc durumu",
	t3726: "V3gc OD",
	t3727: "Koşul. sıcaklığı.",
	t3728: "İnsan kaynakları durumu",
	t3729: "İnsan kaynakları etkinleştirme",
	t3730: "İK Tüketici talebi",
	t3731: "Shr3",
	t3732: "Shr4",
	t3733: "Shr2",
	t3734: "Shr8",
	t3735: "Emiş ref. To-MT",
	t3736: "Kontrol durumu MT",
	t3737: "Gerçek bölge MT",
	t3738: "Seçmek için. MT'yi ofsetleyin",
	t3739: "Koşu kapasitesi MT",
	t3740: "Talep edilen kap. MT",
	t3741: "Bir sonraki adıma geçme zamanı MT",
	t3742: "Po-MT",
	t3744: "Emiş sıcaklığı To-MT",
	t3745: "Toplam aktif enerji",
	t3746: "Tepe talep",
	t3747: "Toplam güç faktörü",
	t3748: "A fazı volt",
	t3749: "B fazı voltları",
	t3750: "Faz C voltları",
	t3751: "Faz A amfileri",
	t3752: "B fazı amfileri",
	t3753: "Faz C amplifikatörleri",
	t3754: "Toplam aktif güç",
	t3755: "Emiş sıcaklığı To-LT",
	t3756: "Emiş sıcaklığı To-IT",
	t3757: "Emiş referansı LT",
	t3758: "Kontrol durumu LT",
	t3759: "Gerçek bölge LT",
	t3760: "Seçmek için. ofset LT",
	t3761: "Çalışma kapasitesi LT",
	t3762: "Talep edilen kap.LT",
	t3763: "Bir sonraki adıma geçme zamanı LT",
	t3764: "Po-LT",
	t3765: "Sd-LT",
	t3766: "Emiş referansı BT",
	t3767: "Kontrol durumu BT",
	t3768: "Gerçek bölge BT",
	t3769: "Çalıştırma kapasitesi BT",
	t3770: "İstenen kapasite BT",
	t3771: "Bir sonraki adıma geçme zamanı BT",
	t3772: "Tennessee Po-IT",
	t2773: "Sd-IT",
	t2774: "BT Comp. başlangıcı",
	t3774: "Aralıkta değil. {{minAddr}} ile {{maxAddr}} arasında olmalı",
	t3775: "Bu cihaz için işlem devre dışı bırakıldı",
	t3776: "Bu işlemden sonra uygulamadan çıkış yapılacaktır, bu işlem birkaç dakika sürebilir.",
	t3777: "Göç dosyaları (flp veya json) bulunamadı",
	t3778: "Anket",
	t3779: "Oylama şu anda askıya alınmış durumda.",
	t3780: "Oy kullanmaya devam etmek istiyor musunuz?",
	t3781: "Enerji Ölçer",
	t3782: "Birden fazla düğüm seçildi",
	t3783: "Düğüme yakınlaştır",
	t3784: "Ölçüm birimleri",
	t3785: "Dosya(lar) başarıyla dışa aktarıldı",
	t3786: "Adres olumsuz olamaz",
	t3787: "Yakınlaştırmayı sıfırla",
	t3788: "Aşamalı desen adımları getirilirken hata oluştu",
	t3789: "Aşamalı desen adımları kaydedilirken hata oluştu",
	t3790: "Aşamalı desen adımları başarıyla kaydedildi",
	t3791: "Sahneleme Deseni",
	t3792: "Kompresörlerle ilişkili boşaltıcıları etkinleştirmek/devre dışı bırakmak için boşaltılmış simgelere basın",
	t3793: "Eksik veri",
	t3794: "Aşağıdaki parametreler herhangi bir veri noktası döndürmedi",
	t3795: "HTTPS/SSL güveni başarılı oldu.",
	t3796: "Artık StoreView Web Tarayıcısına giriş yapabilirsiniz.",
	t3797: "{{timeLeft}} saniye içinde StoreView Web Tarayıcısına yönlendiriliyorsunuz...",
	t3798: "Oylama şu anda askıya alındı!",
	t3799: "Temizlenmiş Alarmlar (tam sistem günlükleri)",
	t3800: "İzin verilen maksimum HVAC sayısı 45'tir.",
	t3801: "Değişiklik günlüğü alınamadı",
	t3802: "Depolamaya bağlanılıyor...",
	t3803: "SCADA depolama bağlantısı zaman aşımı",
	t3804: "SCADA depolamasına bağlanılamadı",
	t3805: "Geçersiz tarih",
	t3806: "Rapor indirme için birim(ler)i seçin"
};
var tr = {
	lang: lang$d
};

var lang$e = {
	t0: "活动警报",
	t1: "已清除的警报",
	t2: "主机",
	t3: "Id",
	t4: "设备名称",
	t5: "编辑备注",
	t6: "未授权确认报警",
	t7: "报警类型",
	t8: "发生",
	t9: "承认",
	t10: "活动报警",
	t11: "清除",
	t12: "计算",
	t13: "已清除报警",
	t14: "静音/已静音",
	t15: "确认所有未确认的警报",
	t16: "授权失败！",
	t17: "错误",
	t18: "手动控制",
	t19: "视觉",
	t20: "摘要数据",
	t21: "技术视图",
	t22: "图 （S4）",
	t23: "节点",
	t24: "国防部",
	t25: "图形 （Po & Pc）",
	t26: "（未检测到警报）",
	t27: "设备警报",
	t28: "查看上一个设备",
	t29: "查看下一个设备",
	t30: "读取警报时出错。",
	t31: "当前警报",
	t32: "当前报警",
	t33: "未检测到警报。",
	t34: "類型",
	t35: "点",
	t36: "历史记录日志",
	t37: "附表",
	t38: "开始时间",
	t39: "停止时间",
	t40: "日",
	t41: "假期",
	t42: "地位",
	t43: "选择数据点",
	t44: "还行",
	t45: "取消",
	t46: "开始",
	t47: "结束",
	t48: "开始日期",
	t49: "采样率",
	t50: "未选择数据点",
	t51: "现在",
	t52: "秒",
	t53: "分钟",
	t54: "人力资源",
	t55: "一天",
	t56: "地址类型",
	t57: "地址",
	t58: "位置：",
	t59: "输入新值",
	t60: "刷新",
	t61: "选择数据点",
	t62: "选择新值",
	t63: "关闭",
	t64: "上",
	t65: "正在检索数据点...",
	t66: "检索数据时出错...",
	t67: "未配置历史记录...",
	t68: "检索数据点时出错...",
	t69: "检索的历史记录示例",
	t70: "检索历史记录示例时出错...",
	t71: "用户名",
	t72: "密码",
	t73: "主机 IP/URL 地址：",
	t74: "还行",
	t75: "取消",
	t76: "名字",
	t77: "价值",
	t78: "检索用户列表时出错",
	t79: "输入新地址",
	t80: "获取用户列表失败，请检查主机 IP/URL 地址请",
	t81: "身份验证代码",
	t82: "Acct 代码",
	t83: "选择新的时间和日期",
	t84: "选择\"新持续时间\"",
	t85: "操作失败",
	t86: "输入授权信息",
	t87: "暖 通 空调",
	t88: "去！",
	t89: "除爆控制",
	t90: "启动手动除气",
	t91: "外壳照明控制",
	t92: "手动外壳指示灯亮起",
	t93: "手动外壳指示灯熄灭",
	t94: "箱用清洁控制",
	t95: "手动外壳清洁打开",
	t96: "手动外壳清洁关闭",
	t97: "主开关",
	t98: "手动主开关打开",
	t99: "手动主开关关闭",
	t100: "关机控制",
	t101: "关闭设备",
	t102: "打开设备",
	t103: "夜间挫折控制",
	t104: "夜间挫折打开",
	t105: "夜间挫折关闭",
	t106: "发送的消息...",
	t107: "手动控制",
	t108: "关闭",
	t109: "照明",
	t110: "电源/杂点",
	t111: "概述",
	t112: "选择要显示的 AK255 单元。",
	t113: "加载。。。",
	t114: "的",
	t115: "所有 AK255s",
	t116: "以前",
	t117: "页面",
	t118: "下一个",
	t119: "参数",
	t120: "测量",
	t121: "正在检索数据...",
	t122: "阅读部分错误",
	t123: "温度",
	t124: "解冻后延迟",
	t125: "报警（如果如下）",
	t126: "报警（如果以上）",
	t127: "监测",
	t128: "离线",
	t129: "积极",
	t130: "主机",
	t131: "地址",
	t132: "描述",
	t133: "当前值",
	t134: "现状",
	t135: "控制器",
	t136: "单位",
	t137: "活动报警存在",
	t138: "活动警报存在！",
	t139: "开/关输入",
	t140: "继电器输出",
	t141: "传感器输入",
	t142: "可变输出",
	t143: "通用控制器",
	t144: "别人",
	t145: "可视化点",
	t146: "商店概述",
	t147: "从系统注销",
	t148: "登录",
	t149: "注销",
	t150: "制冷",
	t151: "设备概述",
	t152: "警报",
	t153: "历史",
	t154: "CS-总览",
	t155: "商店概述",
	t156: "配置",
	t157: "站点视图",
	t158: "工厂视图",
	t159: "家",
	t160: "报警",
	t161: "设定点",
	t162: "设备详细信息",
	t163: "设置",
	t164: "选择日期",
	t165: "信息",
	t166: "审计跟踪",
	t167: "计算",
	t168: "文件管理",
	t169: "帮助手册",
	t170: "信息",
	t171: "在线翻译",
	t172: "保存到服务器",
	t173: "更新语言文件？",
	t174: "此操作将更新单元上的语言文件。继续？",
	t175: "导出历史记录",
	t176: "选择新值",
	t177: "最低",
	t178: "最大",
	t179: "无活动报警！",
	t180: "不可更改！",
	t181: "请稍候。。。",
	t182: "命令已发送！",
	t183: "操作失败，请稍后再试！",
	t184: "操作成功！",
	t185: "未授权操作！",
	t186: "是否确实要继续此操作？",
	t187: "EVAP",
	t188: "包",
	t189: "监控输入",
	t190: "杂项",
	t191: "已确认的警报",
	t192: "清除已确认的警报",
	t193: "清除所有已确认的警报",
	t194: "您无权确认警报",
	t195: "未授权",
	t196: "是否要确认此警报？",
	t197: "确认确认报警",
	t198: "请先登录",
	t199: "请选择要清除的警报",
	t200: "确认清除警报",
	t201: "您确定要清除此警报吗？",
	t202: "未授权清除警报",
	t203: "您确定要清除所有警报吗？",
	t204: "无法清除选定的警报",
	t205: "报警设置",
	t206: "必须授权其作为主管执行此操作",
	t207: "没有从单位返回的数据",
	t208: "选择单位",
	t209: "请从列表中选择一个单位",
	t210: "报警数",
	t211: "报警状态",
	t212: "杂项",
	t213: "清除所选警报",
	t214: "请确认",
	t215: "连接到设备失败，请检查网络连接！",
	t216: "除爆报警",
	t217: "数字报警",
	t218: "数字输入状态",
	t219: "除爆输入",
	t220: "清洁输入",
	t221: "数字输入",
	t222: "保存语言文件",
	t223: "单位",
	t224: "系统中未配置设备，网站已禁用。",
	t225: "警告：",
	t226: "选择时间",
	t227: "通信",
	t228: "下载报告文件？",
	t229: "请在生成报告文件时等待...",
	t230: "下载报告",
	t231: "报警状态/服务",
	t232: "请在检索数据时等待...",
	t233: "登录",
	t234: "用户注销",
	t235: "用户登录",
	t236: "欢迎！",
	t237: "无效许可证",
	t238: "能量计控制器",
	t239: "照明面板",
	t240: "VLT 逆变器",
	t241: "压缩机模块",
	t242: "设备控制器点",
	t243: "计算点",
	t244: "切割机锤面板",
	t245: "载体",
	t246: "高级视图",
	t247: "双击此处可放大/缩小",
	t248: "单击此处放大/缩小",
	t249: "已确认报警",
	t250: "模拟",
	t251: "参考任务",
	t252: "HVAC 任务",
	t253: "照明任务",
	t254: "MISC 任务",
	t255: "网络连接",
	t256: "驱动",
	t257: "参考",
	t258: "图形（驱动器 Mtr Hz 和安培）",
	t259: "停止日期",
	t260: "停止",
	t261: "停止时间处于开始时间或开始时间之前，请重新选择日期/时间",
	t262: "数据点的历史记录创建时间",
	t263: "晚于指定的开始时间",
	t264: "每个历史点返回的样本数已达到 100，000 个，需要很长时间才能得到响应。可以通过降低采样率或更改日期/时间来减少此数字。",
	t265: "单位历史记录查询任务繁忙，请等待约 2 分钟，然后重试...",
	t266: "救",
	t267: "历史记录控制面板",
	t268: "第一",
	t269: "最后",
	t270: "打印",
	t271: "缩小",
	t272: "放大缩小字体功能",
	t273: "显示/隐藏图例",
	t274: "将历史记录数据导出到文件",
	t275: "显示/隐藏网格",
	t276: "单页视图",
	t277: "多页视图",
	t278: "从文件加载历史记录组",
	t279: "从文件加载历史记录",
	t280: "将历史记录组保存到文件",
	t281: "多页视图计数",
	t282: "网页",
	t283: "选择要显示的页面",
	t284: "历史记录文件必须采用 .hst 格式！",
	t285: "未返回数据，请检查所选的历史记录点选择和日期/时间",
	t286: "历史记录快照",
	t287: "历史记录完整视图",
	t288: "历史记录文件视图",
	t289: "选择。。。",
	t290: "此操作会将当前历史记录视图导出到 .hst/.csv 文件。",
	t291: "请选择目标文件。",
	t292: "文件",
	t293: "加载历史记录组...",
	t294: "加载历史记录...",
	t295: "保存历史记录组...",
	t296: "视图",
	t297: "缩放",
	t298: "是的",
	t299: "不",
	t300: "是否从控制器检索配置？",
	t301: "别问了",
	t302: "检索控制器配置",
	t303: "检索到的数据",
	t304: "报警级别",
	t305: "报警操作",
	t306: "承认",
	t307: "将数据库保存到磁盘",
	t308: "文件名",
	t309: "数据库描述",
	t310: "数据库路径",
	t311: "版本",
	t312: "时间",
	t313: "导出警报",
	t314: "数据库保存到",
	t315: "数据库保存失败，请检查目录和文件名是否有效！",
	t316: "文件名不正确，请检查数据库文件名",
	t317: "警报导出成功。请选择一个文件来保存导出的警报",
	t318: "请选择要导出的警报",
	t319: "从单元中检索报警信息",
	t320: "文件描述",
	t321: "将 .hst 文件转换为 .csv 文件格式",
	t322: "第 1 步：选择 .hst 文件",
	t323: "第 2 步：选择数据点和时间范围",
	t324: "第 3 步：选择目标 .csv 文件",
	t325: "保存为...",
	t326: "此操作中允许的数据点不超过 6000 个",
	t327: "所选数据点",
	t328: "每个数据点不允许导出超过 65，536 个样本，请更改采样率或时间范围。",
	t329: "收集",
	t330: "从单位收集历史数据",
	t331: "选择收集的数据点",
	t332: "无值",
	t333: "失踪",
	t334: "暂停",
	t335: "请进行选择",
	t336: "选择要绘制的数据点",
	t337: "保存到 .hst 文件",
	t338: "数据下载完成，请选择以下选项之一：",
	t339: "最多选择 8 点进行绘制",
	t340: "将收集的历史记录数据保存到 .hst 文件",
	t341: "概述打印",
	t342: "请选择\"保存\"或\"打印概览报告\"",
	t343: "选择文件格式",
	t344: "正在生成概述文件，请稍候...",
	t345: "所选数据库文件存在，是否要覆盖它？",
	t346: "仪表 板",
	t347: "图形视图",
	t348: "帮助",
	t349: "系统视图",
	t350: "地图",
	t351: "翻译工具",
	t352: "手动",
	t353: "请求警报...",
	t354: "更新",
	t355: "模型",
	t356: "控制",
	t357: "油",
	t358: "临时",
	t359: "最大化",
	t360: "恢复",
	t361: "回去。。。",
	t362: "通信失败！",
	t363: "通信状态正常。",
	t364: "已发送通信消息。",
	t365: "加载家庭组件",
	t366: "加载配置组件",
	t367: "Ctrl 温度",
	t368: "此设置仅在重绘历史记录时才有效，是否要继续？",
	t369: "正在加载，请稍候",
	t370: "数据点",
	t371: "无单位名称",
	t372: "偏好",
	t373: "模拟器版本",
	t374: "能源",
	t375: "转到概览窗口",
	t376: "系统警报或未找到相关设备",
	t377: "并发计划",
	t378: "缺少语言文件。",
	t379: "不支持版本",
	t380: "输入单位的 IP 地址（例如 192.168.1.1）",
	t381: "请输入单位的 IP 地址或 URL。",
	t382: "隐藏细节",
	t383: "显示详细信息",
	t384: "温度外",
	t385: "返回",
	t386: "供应",
	t387: "湿度",
	t388: "露点",
	t389: "风扇",
	t390: "区",
	t391: "关闭",
	t392: "分裂",
	t393: "吸水温度",
	t394: "当前容量",
	t395: "吸气压力",
	t396: "放电",
	t397: "压缩机",
	t398: "冷凝 器",
	t399: "冷凝器目标",
	t400: "制冷 剂",
	t401: "吸气目标",
	t402: "吸",
	t403: "除 霜",
	t404: "丹佛斯历史格式",
	t405: "文本格式",
	t406: "此操作需要单元重置，是否现在重置设备？",
	t407: "单位重置成功，请重新加载网站。",
	t408: "工厂应用模式",
	t409: "计算。。。",
	t410: "分钟",
	t411: "夜挫折",
	t412: "箱灯",
	t413: "计划并发",
	t414: "（10 分钟分辨率）",
	t415: "查看文本",
	t416: "导出为 CSV",
	t417: "保存 PNG",
	t418: "查看图形",
	t419: "查看文本",
	t420: "附表",
	t421: "时间",
	t422: "术语温度",
	t423: "没有",
	t424: "控制温度",
	t425: "从",
	t426: "从",
	t427: "统计",
	t428: "选择文件",
	t429: "选择要转换为 CSV 格式的历史记录文件...",
	t430: "完成",
	t431: "总结",
	t432: "历史数据文件 （*.hst）",
	t433: "历史记录转换向导",
	t434: "文件创建完成。",
	t435: "选择要转换为 CSV 格式的数据点...",
	t436: "选择时间范围",
	t437: "选择时间范围并按程按钮 . . .",
	t438: "处理数据",
	t439: "准备历史记录数据，请稍候",
	t440: "选择 CSV 文件名并将数据保存到文件...",
	t441: "找不到历史记录数据点",
	t442: "错误 - 未返回历史记录数据",
	t443: "通过主机网络与设备通信时出错。发现",
	t444: "注意",
	t445: "沉默",
	t446: "数据库未保存，是否真的要关闭窗口？",
	t447: "表视图",
	t448: "显示图形",
	t449: "显示表",
	t450: "手动解冻打开",
	t451: "手动除气关闭",
	t452: "自动除气",
	t453: "设置除气控制",
	t454: "卸载器打开",
	t455: "卸载器关闭",
	t456: "液",
	t457: "未配置计划。",
	t458: "商店视图",
	t459: "与主机网络上的设备失去通信！",
	t460: "快速设置",
	t461: "街",
	t462: "城市",
	t463: "电话",
	t464: "地区",
	t466: "指数",
	t469: "已成功导出通讯簿",
	t470: "删除项目",
	t471: "表",
	t472: "注意：输入是大小写敏感",
	t473: "在线",
	t474: "RO 板",
	t475: "SI 板",
	t476: "OI 板",
	t477: "计算",
	t478: "设置为当前时间",
	t479: "架",
	t480: "无压缩机",
	t481: "电路",
	t482: "情况 下",
	t483: "未知",
	t484: "错误类型：",
	t485: "添加",
	t486: "复制",
	t487: "删除",
	t488: "案例控制",
	t489: "案例类型",
	t490: "离子",
	t491: "输入要复制的金额",
	t492: "无名称",
	t493: "配置制冷布局...",
	t494: "配置错误：",
	t495: "请验证配置。",
	t496: "制冷布局设置已成功完成。按\"确定\"按钮退出此向导。",
	t497: "配置状态",
	t498: "最终制冷布局",
	t500: "代码#",
	t501: "布局",
	t502: "系统设置",
	t503: "发 牌",
	t504: "用户",
	t505: "未授权",
	t506: "请验证...",
	t507: "是否确实要离开此屏幕？您的设置可能失去。",
	t508: "正在扫描控制器网络...",
	t509: "扫描",
	t510: "验证",
	t511: "AK-I/O",
	t512: "重新扫描",
	t513: "包括离线编程",
	t514: "设备总线",
	t515: "在线设备",
	t516: "离线编程",
	t517: "重复地址",
	t518: "压缩机控制",
	t519: "吸气组",
	t520: "不和",
	t521: "可用案例",
	t522: "允许创建多例电路",
	t523: "插入设备",
	t524: "插入电路",
	t525: "多外壳电路。",
	t526: "正在加载表...",
	t527: "请求报警数据",
	t528: "历史组文件",
	t529: "历史数据文件",
	t530: "所有文件",
	t531: "显示符号",
	t532: "显示曲线",
	t533: "没有收集足够的历史记录。",
	t534: "检索最新的历史记录数据。",
	t535: "状态/设置",
	t536: "正在下载",
	t537: "历史样本...",
	t538: "检索",
	t539: "数据点...",
	t540: "区间",
	t541: "最小",
	t542: "麦克斯",
	t543: "平均",
	t544: "转到主报警窗口。",
	t545: "停止自动登录",
	t546: "连接到",
	t547: "取消自动登录",
	t548: "请输入注销密码",
	t549: "网页 1",
	t550: "网页 2",
	t551: "自动登录",
	t552: "非活动期后注销",
	t553: "主屏幕",
	t554: "显示配置菜单",
	t555: "在仪表板上显示杂项",
	t556: "使用全屏",
	t557: "启用报警声音",
	t558: "允许程序关闭/最小化",
	t559: "访问设备时出错#",
	t560: "连接到网络。",
	t561: "自动登录已取消",
	t562: "重试连接到",
	t563: "从",
	t564: "连接",
	t565: "时间格式",
	t566: "日期格式",
	t567: "压力",
	t568: "光级",
	t569: "子冷却器",
	t570: "子冷却器",
	t571: "日常",
	t572: "每周",
	t573: "HST 文件",
	t574: "CSV 文件",
	t575: "星期日",
	t576: "星期一",
	t577: "星期二",
	t578: "星期三",
	t579: "星期四",
	t580: "星期五",
	t581: "星期六",
	t582: "历史记录日志集合",
	t583: "启用自动收集",
	t584: "何时收集",
	t585: "文件保存位置",
	t586: "选择文件格式",
	t587: "选择要存储历史记录文件的文件夹...",
	t588: "启用自动历史记录收集，以检索和保存商店配置的历史记录点的每日或每周历史记录日志。",
	t589: "要查看 HST 历史记录文件，请转到主历史记录屏幕并选择导入历史记录。要查看 CSV 文件，请使用任何电子表格应用程序。",
	t590: "如果在通讯簿中找到当前连接的 IP 地址，则所选的历史记录点将被保存。",
	t591: "选择数据点",
	t592: "全部选定",
	t593: "所选点",
	t594: "搜索",
	t595: "自动连接到",
	t596: "请验证",
	t597: "自动历史记录收集已启用。是否确实要关闭此程序？",
	t598: "是否确实要关闭此程序？",
	t599: "行动",
	t600: "滤波器",
	t601: "所有操作",
	t602: "更多信息...",
	t603: "编辑",
	t604: "操作",
	t605: "操作已取消",
	t606: "数据库下载完成",
	t607: "单位数据库已更新",
	t608: "文件成功传输到单元",
	t609: "下载完成，文件正在处理中单位",
	t610: "将文件上传到 PC 时发生错误，请检查该文件是否可写且可访问",
	t611: "上传文件",
	t612: "下载文件",
	t613: "上传所选文件",
	t614: "下载选定的文件",
	t615: "删除文件",
	t616: "删除选定的文件",
	t617: "FTP 连接错误",
	t618: "将所选文件下载到设备",
	t619: "FTP 连接中发生错误。请检查 FTP 连接和密码/用户名。",
	t620: "已连接 FTP",
	t621: "字节",
	t622: "该装置上的文件将被此操作覆盖，是否要继续？",
	t623: "电脑上的文件将被此操作覆盖，是否要继续？",
	t624: "文件已下载到设备。",
	t625: "仅制冷",
	t626: "便利店",
	t627: "完整功能",
	t628: "仅 HVAC",
	t629: "文件名是非法的;请确保文件名少于 8 个字符。",
	t630: "是否正在覆盖应用程序代码？",
	t631: "后",
	t632: "上传",
	t633: "您可以选择\"是\"现在覆盖应用程序或选择\"以后\"",
	t634: "请选择模拟器根目录",
	t635: "请选择 Windows 目录",
	t636: "请选择目标目录",
	t637: "选择数据库目录",
	t638: "FTP 断开连接",
	t639: "连接超时，FTP 断开连接",
	t640: "将所选文件上传到 PC",
	t641: "删除所选文件",
	t642: "FTP 服务器端口",
	t643: "Web 服务器端口",
	t644: "本地路径",
	t645: "向上",
	t646: "大小",
	t647: "日期",
	t648: "连接",
	t649: "断开",
	t650: "远程路径",
	t651: "可用空间：",
	t652: "所选文件",
	t653: "文件",
	t654: "总大小：",
	t655: "单击以更改文件夹...",
	t656: "改变。。。",
	t657: "是否要删除所选文件？",
	t658: "连接关闭，退出",
	t659: "所选文件（文件）名称（名称）是非法的，下载将被忽略。",
	t660: "某些所选文件的名称是非法的并忽略。",
	t661: "文件已成功传输到 PC",
	t662: "装置上的可用磁盘空间不足。",
	t663: "传输文件",
	t664: "改 性",
	t665: "将文件从闪存磁盘复制到 RAM 磁盘",
	t666: "请选择文件复制操作 ：",
	t667: "从闪存盘复制所有配置的 EDF 文件",
	t668: "从闪存盘复制所有 EDF 文件",
	t669: "edf/ed3 文件存储在设备闪存盘上，但未加载到文件管理屏幕中。 使用以下选项将单元中存储的 EDF 文件复制到\"文件管理\"屏幕",
	t670: "此操作 将设备中配置的所有 EDF 文件从闪存磁盘复制到 RAM 磁盘",
	t671: "此操作将单元中的所有 EDF 文件从闪存磁盘复制到 RAM 磁盘",
	t672: "不存在有效的 EDF 文件",
	t673: "EDF 文件复制已完成",
	t674: "复制的文件",
	t675: "确认数据库下载",
	t676: "单位地址",
	t677: "IP 地址",
	t678: "数据库文件名",
	t679: "选择",
	t680: "更新完成、重置和升级单元。这可能需要几分钟时间。",
	t681: "欢迎来到升级管理器 - 步骤 1 的 2",
	t682: "欢迎访问升级管理器 - 步骤 2 的 2",
	t683: "进行",
	t684: "升级前检查",
	t685: "查找固件",
	t686: "备份数据库？",
	t687: "备份数据库",
	t688: "传输软件",
	t689: "覆盖软件",
	t690: "选择要升级的软件",
	t691: "未检测到工厂故障安全应用。",
	t692: "您可以继续，但更新过程中的任何错误都可能导致系统无法运行。",
	t693: "强烈建议不要继续。",
	t694: "是否要继续？",
	t695: "远程升级系统确实存在故障风险，这可能会导致系统错误。",
	t696: "免责 声明",
	t697: "数据库上传完成",
	t698: "备份数据库已完成",
	t699: "应用程序下载完成",
	t700: "应用程序覆盖已完成",
	t701: "软件升级成功",
	t702: "是否要将设备重置为出厂应用程序？",
	t703: "检查硬件",
	t704: "检查设备状态",
	t705: "工厂故障安全",
	t706: "当前固件版本",
	t707: "开始升级",
	t708: "选择现有数据库文件",
	t709: "选择现有固件文件",
	t710: "返回",
	t711: "查找固件",
	t712: "选择 .csi 文件",
	t713: "选择.mai 文件",
	t714: "此 CPU 板不支持远程下载。",
	t715: "应用程序下载失败",
	t716: "数据库上传失败，请重试",
	t717: "应用程序覆盖已完成",
	t718: "应用程序覆盖失败！请重试。",
	t719: "应用程序覆盖失败",
	t720: "未检测到工厂故障安全应用。",
	t721: "做",
	t722: "所选应用程序可能具有与当前设备固件不同的版本类型，继续操作可能会带来故障和系统错误的风险。",
	t723: "所选文件（文件）名称（名称）是非法的，下载将被忽略。",
	t724: "某些所选文件的名称是非法的并忽略。",
	t725: "单元的 berg 引脚丢失。",
	t726: "设备电池电量不足。",
	t727: "单元的主机网络失败。",
	t728: "设备状态为负载棚状态",
	t729: "选择目标文件",
	t730: "选择目标目录",
	t731: "数据库上传完成",
	t732: "状态正常",
	t733: "状态错误",
	t734: "状态未知",
	t735: "此操作可能会覆盖目标模拟器中的选定文件，是否要继续？",
	t736: "下载数据库完成后，您需要重置系统",
	t737: "下载数据库失败，以后需要重试",
	t738: "此操作后，设备将自动重置，是否要继续？",
	t739: "选择数据库",
	t740: "下载数据库文件？",
	t741: "上传数据库文件？",
	t742: "将数据库下载到设备，请稍候",
	t743: "已成功下载数据库，现在重置设备",
	t744: "数据库更新完成，单元重置成功",
	t745: "更新数据库失败！请重试。",
	t746: "更新数据库时出错",
	t747: "下载前检查",
	t748: "查找数据库",
	t749: "下载数据库",
	t750: "当前数据库信息",
	t751: "选择要下载的数据库",
	t752: "将数据库保存到 PC（上传）",
	t753: "将数据库加载到设备（下载）",
	t754: "需要主管授权",
	t755: "开始上传",
	t756: "请选择数据库操作：",
	t757: "开始下载",
	t758: "当前固件版本",
	t760: "数据库下载成功！",
	t761: "欢迎来到数据库管理器 - 步骤 1 的 2",
	t762: "欢迎来到数据库管理器 - 步骤 2 的 2",
	t763: "连接到设备失败，请检查主机 IP/URL 地址",
	t764: "远程升级系统数据库确实存在故障风险，这可能会导致系统错误。",
	t765: "数据库下载完成",
	t766: "以太网扫描",
	t767: "输入第一个 IP 地址，然后按\"开始\"",
	t768: "扫描",
	t769: "扫描",
	t770: "历史记录日志集合",
	t771: "模拟图形颜色",
	t772: "数字图形颜色",
	t773: "关机计划",
	t774: "解冻摘要",
	t775: "夜间挫折时间表",
	t776: "案例照明时间表",
	t777: "加载状态",
	t778: "报警日志集合",
	t779: "选择要存储报警文件的文件夹...",
	t780: "每月",
	t781: "启用自动报警收集，以便定期检索报警信息并保存到文件中。",
	t782: "CSV 分离器",
	t783: "无报警",
	t784: "检索单位警报计数：",
	t785: "从设备检索报警详细信息：",
	t786: "自动报警收集已启用。是否确实要关闭此程序？",
	t787: "小时",
	t788: "这将更新设备数据库，并将位置设置为当前视图的中心。进行？",
	t789: "处理的样品",
	t790: "请配置吸气组。",
	t791: "要重新使用布局向导，需要重置制冷数据库布局。",
	t792: "通过配置控制页面>，将机架/包数设置为零。",
	t793: "注意：任何基于先前制冷配置的计划、计算或导入都需要重新配置。",
	t794: "复制过程已完成。",
	t795: "制冷报告",
	t796: "最终 HVAC 布局",
	t797: "HVAC 布局设置已成功完成。按\"确定\"按钮退出此向导。",
	t798: "正在配置 HVAC 布局...",
	t799: "要重新使用 HVAC 布局向导，需要重置 HVAC 数据库布局。",
	t800: "通过配置控制页面>，将 HVAC 单元数设置为零。",
	t801: "注意：需要重新配置基于先前 HVAC 配置的任何计划、计算或导入。",
	t802: "暂存模式（需要单元重置才能激活）",
	t803: "步骤",
	t804: "水平",
	t805: "显示多个数据提示",
	t806: "步骤数",
	t807: "无法保存文件。",
	t808: "模型不正确。",
	t809: "加载成功。",
	t810: "打开",
	t811: "此功能仅在应用商店视图的桌面版本中可用。",
	t812: "继续",
	t813: "离开向导",
	t814: "操作成功。是否要继续还是要离开向导。",
	t815: "获取报警记录器数据时出错。",
	t817: "设备名称 ：",
	t819: "正在进行中...",
	t820: "没有报警数据。",
	t821: "报警计数",
	t822: "模拟显示选项",
	t823: "仅显示文本",
	t824: "仅显示图标",
	t825: "显示文本和图标",
	t826: "1 周",
	t827: "2 周",
	t828: "3 周",
	t829: "4 周",
	t830: "图形设置",
	t831: "无法显示报警诊断，请检查首选项下的自动报警收集",
	t832: "配置中包含的 IO 机架或电路不允许重新排序。",
	t833: "此主机 IP/URL 地址已存在于通讯簿中。是否要更新该条目？",
	t834: "浏览",
	t835: "选择通讯簿文件夹",
	t836: "位置",
	t837: "所选位置没有任何通讯簿内容。要将现有通讯簿的内容导出到所选位置吗？",
	t838: "报警操作代码",
	t839: "在所选路径中找不到通讯簿文件。通讯簿从本地加载...",
	t840: "请选择保存日志的位置。",
	t841: "请选择保存日志的有效位置。",
	t842: "报警日志收集正在进行中...",
	t843: "历史记录日志收集正在进行中...",
	t844: "在后台运行",
	t845: "报警日志进度",
	t846: "主机 IP/URL 地址不应为空",
	t847: "地址字段不应为空",
	t849: "您没有最新版本的文件，要加载最新版本中的数据吗？",
	t850: "版本冲突",
	t851: "历史记录日志进度",
	t852: "用户名和密码字段不应为空。",
	t853: "IP 地址字段不应为空。",
	t854: "主机 IP/URL 地址字段不应为空。",
	t855: "用户名不应为空。",
	t856: "密码不应为空。",
	t857: "您连接到的 SM800",
	t858: "尚未完成初始启动向导。请完成此任务并重新连接到 SM800",
	t859: "设置为现在",
	t860: "无效输入",
	t861: "文本太长",
	t862: "抵消",
	t863: "无法与主机网络上的单元通信",
	t864: "请验证地址不同。按下保存按钮时，配置将更新。",
	t865: "请选择有效的历史记录文件 （.hst）",
	t866: "请选择有效的历史记录组文件 （.grp）",
	t867: "颜色",
	t868: "百分比",
	t869: "电压",
	t870: "当前",
	t871: "权力",
	t872: "频率",
	t873: "浓度",
	t874: "流",
	t875: "速度",
	t876: "酸度",
	t877: "请选择底部抽屉的图表",
	t878: "保存 PNG",
	t879: "画",
	t880: "系统状态...",
	t881: "系统和单位状态",
	t882: "加塔韦",
	t883: "功能许可证",
	t884: "软件版本",
	t885: "浏览器模块版本",
	t886: "达诺版本",
	t887: "Mac 地址",
	t888: "支持和更新",
	t889: "单位名称",
	t890: "重新",
	t891: "连接丢失",
	t892: "未找到 [类型] 设备。",
	t893: "不支持当前使用的软件版本",
	t894: "快速链接",
	t895: "设备",
	t896: "图形",
	t897: "公用事业",
	t898: "软件回滚",
	t899: "诊断",
	t900: "发生错误",
	t901: "未找到 [警报] 数据",
	t902: "设置",
	t903: "应用程序名称",
	t904: "应用程序版本",
	t905: "消息",
	t906: "事件类型",
	t907: "时间 戳",
	t908: "回滚版本",
	t909: "当前版本",
	t910: "软件",
	t911: "回 滚。。。",
	t912: "请从列表中选择设备",
	t913: "日历视图",
	t914: "加载设备时发生错误，请重试。",
	t915: "加载计划时发生错误，请重试。",
	t916: "未找到设备",
	t917: "计划导出",
	t918: "请选择 CSV 或 PDF 导出类型",
	t919: "导出为 CSV",
	t920: "准备 PDF",
	t921: "导出为 PDF",
	t922: "请提供此格式的 IP xx.xx.xx=：xxxx",
	t923: "用户",
	t924: "登录",
	t925: "回 滚",
	t926: "回滚 SM800A 软件",
	t927: "此操作将回滚所有软件到列出的版本。",
	t928: "派对设备编辑器",
	t929: "一些文本， 解释功能",
	t930: "更新 CDF",
	t931: "地图设备",
	t932: "设备文件",
	t933: "1 个基于寄存器编号",
	t934: "存储类型",
	t935: "最小",
	t936: "麦克斯",
	t937: "仅读",
	t938: "日志",
	t939: "消息类型",
	t940: "转换关键字",
	t941: "参数",
	t942: "加接器。",
	t943: "优先",
	t944: "位",
	t945: "否定",
	t946: "新文件",
	t947: "打开文件",
	t948: "生成文件",
	t949: "组",
	t950: "产品版本",
	t951: "发生错误",
	t952: "未找到",
	t953: "找不到此设备",
	t954: "外部温度。",
	t955: "未找到 [设备]",
	t956: "例",
	t957: "报警成功静音/行动",
	t958: "此警报的静音/行动失败",
	t959: "加载装置时出错，请重试。",
	t960: "无设备",
	t961: "未找到 [类型] 设备",
	t962: "配置系统管理器",
	t963: "装置",
	t964: "撤消",
	t965: "您更改了字段的值，是否要保存更改？",
	t966: "清除選定的歷史記錄點",
	t967: "Shr {{number}}",
	t968: "小時泵速",
	t969: "Sd",
	t970: "V3小時",
	t971: "人力資源參考",
	t972: "流量{{number}}",
	t973: "Tw 參考",
	t974: "雙泵轉速",
	t975: "V3tw",
	t976: "特雷克",
	t977: "預",
	t978: "外徑",
	t979: "精確參考",
	t980: "Trec 參考",
	t981: "Pc",
	t982: "不銹鋼",
	t983: "馬力外徑",
	t984: "Pgc 參考",
	t985: "噴射器外徑",
	t986: "PGC",
	t987: "水產",
	t988: "SGC",
	t989: "Sc3",
	t990: "sgc ctrl。",
	t991: "條件。容量",
	t992: "寶",
	t993: "不銹鋼",
	t994: "Sd",
	t995: "個人電腦",
	t996: "運行能力",
	t997: "瀏覽器證書",
	t998: "登出",
	t1000: "僅對{{count}}排序",
	t1001: "已加載{{count}} } 個警報， {{total}}警報",
	t1002: "已成功確認所有警報",
	t1003: "所有警報的確認失敗",
	t1004: "僅確認{{count}}個{{total}}警報",
	t1005: "所有警報的導出失敗",
	t1006: "導出成功",
	t1007: "導出失敗，至少需要一種報警類型",
	t2000: "-",
	t2001: "對於 32K 字節的 XML 傳輸緩衝區，響應過大",
	t2002: "沒有與 cmd 元素或命令關聯的屬性 沒有“cmd”元素或無法寫入變量或無法讀取變量或無法讀取點或無法寫入開/關輸入或繼電器輸出操作模式",
	t2003: "錯誤的命令輸入",
	t2004: "CGI 後期參數錯誤",
	t2005: "將 XML 響應從其內部表示形式轉換為響應 XML 字符串時出錯",
	t2006: "解碼 URL 字符串時出錯",
	t2007: "內存溢出",
	t2008: "響應數據壓縮失敗",
	t2009: "解析請求時出錯",
	t2010: "數據訪問錯誤 - 一些未進一步解決的原因",
	t2011: "未定義的命令動作屬性值 屬性值無效",
	t2012: "警報參考編號錯誤或未配置警報",
	t2013: "輸入的 cgi 參數字符串太長。",
	t2014: "未找到設備地址",
	t2015: "授權寫入失敗",
	t2016: "未配置歷史記錄",
	t2017: "沒有返回曆史數據，可能是時間錯誤",
	t2018: "創建文件錯誤",
	t2019: "讀取文件錯誤",
	t2020: "超出設備列表的最大大小",
	t2021: "未使用",
	t2022: "無效的歷史索引",
	t2023: "無法從 nodetype、node、mod 和 point 中找到棋盤點索引。",
	t2024: "找不到歷史索引",
	t2025: "在嘗試啟動歷史查詢之前，歷史查詢尚未初始化。",
	t2026: "在歷史查詢處於活動狀態時嘗試了 hq_start_query",
	t2027: "預期某些命令屬性時未找到命令屬性",
	t2028: "一個紀元的開始沒有結束，反之亦然",
	t2029: "無法從時間和日期格式轉換為紀元秒",
	t2030: "命令需要一個 query_id 但沒有提供",
	t2031: "構建 XML 響應時出錯",
	t2032: "提供的 query_id 與活動查詢不匹配",
	t2033: "已嘗試 hq_get_data 命令，但數據尚不可用",
	t2034: "提供的採樣率不是允許的採樣率之一",
	t2035: "平均間隔小於或等於記錄數據的採樣率",
	t2036: "停止曆元時間小於開始曆元時間或停止曆元時間減去開始曆元時間小於平均間隔",
	t2037: "提供的 device_id 與 ak255 當前已知的任何不匹配。請注意，ak255 只知道已配置的設備",
	t2038: "無 ID 屬性",
	t2039: "無效的 ID 屬性",
	t2040: "沒有時間表編號",
	t2041: "計劃編號無效",
	t2042: "沒有時間表詳情",
	t2043: "缺少準時",
	t2044: "錯過一小時",
	t2045: "無效小時",
	t2046: "錯過一分鐘",
	t2047: "分鐘無效",
	t2048: "缺少 off_time",
	t2049: "缺少工作日",
	t2050: "無效的工作日",
	t2051: "缺少假期",
	t2052: "無效假期",
	t2053: "缺少或無效的holiday_start",
	t2054: "缺少或無效的holiday_end",
	t2055: "缺少或無效的 holiday_open",
	t2056: "缺少或無效的holiday_close",
	t2057: "假期未配置",
	t2058: "缺少描述",
	t2059: "無法清除警報",
	t2060: "缺少或無效的 store_open",
	t2061: "丟失或無效的 store_closed",
	t2062: "未配置警報",
	t2063: "節點離線",
	t2064: "缺失索引",
	t2065: "無效索引",
	t2066: "繼電器無法定時",
	t2067: "正在更改的名稱包含太多字符。如果更改 store_name 或 unit_name，則必須為< = 16 個字符，如果更改 storeId1 或 storeId2，則必須為< = 8 個字符。",
	t2068: "缺少與命令元素關聯的元素。",
	t2069: "Tag_ID 未定義",
	t2070: "缺少數據的葉節點",
	t2071: "數據沒有變化",
	t2072: "更新數據庫失敗",
	t2073: "不可寫",
	t2074: "棚米誤差",
	t2075: "沒有米",
	t2076: "無效的棚層",
	t2077: "無效類型",
	t2078: "系統忙",
	t2079: "資產未配置為卸載",
	t2080: "資產的額定功率無效",
	t2081: "資產啟動延遲無效",
	t2082: "配置忙",
	t2083: "正在進行 I/O 掃描",
	t2084: "進行中的除霜次數過多",
	t2085: "試圖將錯誤的數據庫版本加載到單元中",
	t2086: "數據庫加載失敗",
	t2087: "沒有可用的網絡內存",
	t2088: "未配置需求響應（僅限 AK355 系統）",
	t2089: "文件大小丟失或不正確。",
	t2090: "缺少編碼文件（b64 元素）",
	t2091: "將數據庫保存到 RAM 磁盤失敗。",
	t2092: "文件大小太大。",
	t2093: "缺少文件名。",
	t2094: "缺少文件 ID。",
	t2095: "缺少文件加載索引（注意：這是 load_file load_database 的序列索引）。",
	t2096: "文件 ID 不匹配或丟失。",
	t2097: "文件加載操作已在進行中。",
	t2098: "解碼文件與原始文件大小不匹配。",
	t2099: "文件加載操作已取消或未啟動。",
	t2100: "無效的用戶請求（原始請求者與 IP 地址不匹配）",
	t2101: "文件加載順序無效。",
	t2102: "無效的目錄",
	t2103: "無效的擴展",
	t2104: "無效的偏移量",
	t2105: "錯誤編碼文件",
	t2106: "解碼文件錯誤",
	t2107: "無法打開文件進行讀/寫",
	t2108: "無法寫入文件",
	t2109: "文件在 Flash 文件系統中被寫保護",
	t2110: "刪除文件失敗。",
	t2111: "無法刪除文件夾。",
	t2112: "缺少設備",
	t2113: "未找到來自 URL 的所有設備",
	t2114: "缺少歷史參數",
	t2115: "未找到 URL 中的所有歷史參數",
	t2200: "不推薦當前使用的軟件版本（ {{currentVersion}} ）",
	t2201: "當前使用的軟件版本（ {{currentVersion}} ）不推薦（ > {{recommendedVersion}}推薦）",
	t2202: "軟件版本錯誤",
	t2203: "圖形編輯器",
	t2204: "編輯",
	t2205: "画",
	t2206: "新觀點",
	t2207: "已創建",
	t2208: "降低",
	t2209: "新圖形",
	t2210: "創建一個新的圖形視圖",
	t2211: "名字",
	t2212: "改名",
	t2214: "出口",
	t2213: "複製",
	t2215: "刪除",
	t2216: "您確定要刪除“ {{name}} ”視圖嗎？",
	t2217: "導出所有視圖",
	t2218: "更多的...",
	t2219: "規則",
	t2220: "規則",
	t2221: "規則名稱",
	t2222: "編輯面板",
	t2223: "狀況",
	t2224: "坡度",
	t2225: "反轉條件",
	t2226: "映射",
	t2227: "映射形狀",
	t2228: "形狀 ID",
	t2229: "狀態",
	t2230: "添加映射",
	t2231: "添加規則",
	t2232: "操作員",
	t2233: "添加條件",
	t2234: "從添加規則開始",
	t2235: "您確定要刪除“ {{name}} ”規則嗎？",
	t2236: "製作了規則的副本",
	t2237: "站點視圖",
	t2238: "您必須創建站點視圖才能查看它們",
	t2239: "轉到圖形編輯器",
	t2240: "轉到詳細信息",
	t2241: "選擇參數",
	t2242: "未找到參數",
	t2243: "選項",
	t2244: "開/關輸入",
	t2245: "繼電器輸出",
	t2246: "傳感器輸入",
	t2247: "可變輸出",
	t2248: "通用設備",
	t2249: "空節點",
	t2250: "不支持歷史記錄...",
	t2251: "重試",
	t2252: "無法獲取歷史記錄",
	t2253: "加載歷史記錄可能需要一段時間，並且不支持同時進行多個查詢，請重試此請求。",
	t2254: "參數名稱",
	t2255: "設備名稱",
	t2256: "設備類型",
	t2257: "並非所有歷史參數都可以加載（ {{n}}失敗）",
	t2258: "錯誤的用戶名或密碼",
	t2259: "無法與控制器通信",
	t2260: "軟件更新",
	t2261: "系統",
	t2262: "软件回滚",
	t2263: "安裝",
	t2264: "軟件回滾將替換更新版本",
	t2265: "將軟件回滾到備份版本{{version}}",
	t2266: "數據庫",
	t2267: "有可用的更新",
	t2268: "導出數據庫",
	t2269: "下載",
	t2270: "下載數據庫",
	t2271: "無法導出數據庫",
	t2272: "數據庫文件",
	t2999: "通信故障",
	t2998: "未知錯誤",
	t3000: "細節",
	t3001: "過去的 XML 代碼在這裡",
	t3002: "分配給",
	t3003: "狀態",
	t3004: "軟件管理",
	t3005: "本地存儲文件",
	t3006: "通過互聯網在線",
	t3007: "確保更新過程中電源不中斷。建議在更新系統之前備份系統數據庫。完成軟件更新後，建議檢查系統運行狀態以確認正確的控制和操作。",
	t3008: "未選擇任何文件",
	t3009: "選擇軟件文件",
	t3010: "上傳文件",
	t3011: "安全檢查",
	t3012: "提取文件",
	t3013: "檢查文件",
	t3014: "文件上傳失敗！",
	t3015: "安全檢查失敗！",
	t3016: "提取失敗！",
	t3017: "檢查文件失敗！",
	t3018: "下載文件： {{fileName}}",
	t3019: "文件下載成功！",
	t3020: "下載失敗！",
	t3021: "上傳文件",
	t3022: "下載文件",
	t3023: "操作系統更新",
	t3024: "edf 更新",
	t3025: "下載文件",
	t3026: "不幸的是，所選參數的值不可用",
	t3027: "進口",
	t3028: "至少需要一個文件",
	t3029: "文件無效",
	t3030: "管理圖形視圖",
	t3031: "上傳",
	t3032: "遷移",
	t3033: "正在讀取圖形文件，請稍候...",
	t3034: "正在導入圖形文件，請稍候...",
	t3035: "選擇圖形",
	t3036: "報告",
	t3037: "導入數據庫",
	t3038: "選擇數據庫文件",
	t3039: "確保回滾過程中電源不中斷。建議在回滾系統之前備份系統數據庫。完成軟件回滾後，建議檢查系統運行狀態以確認正確控制和操作。",
	t3040: "系統管理器將在更新後重新啟動",
	t3041: "擴張",
	t3042: "顯示調試消息",
	t3043: "最大限度。允許的字符 -",
	t3044: "請上傳更新文件",
	t3045: "當前軟件版本",
	t3046: "當前的 Danux 版本",
	t3047: "最新軟件版本",
	t3048: "最新的 Danux 版本",
	t3049: "正在備份，請稍候",
	t3050: "安裝文件請稍候",
	t3051: "安裝更新",
	t3052: "設備正在重啟",
	t3053: "重啟系統管理器",
	t3054: "瀏覽器現在將刷新",
	t3055: "軟件安裝成功",
	t3056: "更新失敗，出現以下錯誤：",
	t3057: "備份當前軟件版本",
	t3058: "備份當前的 EDF 文件",
	t3059: "圖形視圖保存成功",
	t3060: "應用背景圖片",
	t3061: "清除",
	t3062: "重做",
	t3063: "店鋪",
	t3064: "巡迴賽之夜挫折",
	t3065: "吸夜挫折",
	t3066: "下載版本{{fileName}}",
	t3067: "要更新選定的單位，請按“安裝”",
	t3068: "回滾{{updateType}}版本",
	t3069: "安裝",
	t3070: "安裝回滾版本",
	t3071: "沒有可用單元包含任何回滾版本",
	t3072: "新版本上線",
	t3073: "上傳選定單位的文件",
	t3074: "上傳所選單位的文件",
	t3075: "沒有可用於單元的回滾版本",
	t3076: "下載完成",
	t3077: "當前{{updateType}}版本",
	t3078: "最新{{updateType}}版本",
	t3079: "更新正在進行中，您確定要關閉窗口嗎？",
	t3080: "Danux 不是最新的！請更新 Danux 然後再次更新軟件",
	t3081: "應用程序無法降級到此版本",
	t3082: "應用信息",
	t3083: "發行說明",
	t3084: "條款和隱私",
	t3085: "許可證",
	t3086: "請轉到配置以設置時間表",
	t3087: "不幸的是，準備遷移時發生了錯誤。請確保所有設備均可通過網絡訪問並正確配置。 \n\n發生以下錯誤：\n {{error}}",
	t3088: "導入或遷移現有圖形",
	t3089: "展示",
	t3090: "資產名稱",
	t3091: "坍塌",
	t3092: "導入完成",
	t3093: "放電安全",
	t3094: "吸入安全",
	t3095: "排出角質",
	t3096: "放電切斷",
	t3097: "吸入切口",
	t3098: "吸角質",
	t3099: "容量",
	t3100: "找不到遷移文件（flp、dpj 或 json）",
	t3101: "超時解析文件",
	t3102: "第三方設備文件和地址",
	t3103: "網格",
	t3104: "鎖",
	t3105: "xml",
	t3106: "選擇要顯示的參數",
	t3107: "設備參數",
	t3108: "沒有單位",
	t3109: "沒有找到或加載單元",
	t3110: "危害分析臨界控制點",
	t3111: "資產",
	t3112: "沒有可用的 haccp 數據",
	t3113: "單位信息",
	t3114: "可用更新",
	t3115: "轉到軟件",
	t3116: "未連接",
	t3117: "此應用程序的當前訪問範圍是“ {{targetLevel}} ”，提供的用戶在訪問權限方面高於此範圍",
	t3118: "導出 PDF",
	t3119: "PDF 導出失敗",
	t3120: "在大型數據集上，界面可能沒有響應並且需要一段時間才能完成",
	t3121: "請從列表中選擇時間表",
	t3122: "請轉到配置以設置時間表",
	t3123: "成功",
	t3124: "警告",
	t3125: "網站信息",
	t3126: "請從列表中選擇時間和數據點",
	t3127: "連接",
	t3128: "無法與此站點建立連接，請聯繫支持人員",
	t3129: "在所選日期沒有可用的 HACCP 數據。請確保在配置菜單中正確配置歷史和時間",
	t3130: "診斷報告",
	t3131: "選擇用於診斷報告導出的單元",
	t3132: "在此用戶訪問級別不可用",
	t3133: "診斷日誌",
	t3134: "下載報告失敗",
	t3135: "字體",
	t3136: "字體大小",
	t3137: "沒有要遷移的視圖",
	t3138: "下載桌面版",
	t3139: "由於現代瀏覽器的安全限制（",
	t3140: "RFC1918",
	t3141: ") 很遺憾，無法使用未加密的 HTTP 連接到專用網絡空間內的設備。將系統管理器配置為使用 HTTPS 或使用桌面版本",
	t3142: "所選期間沒有可用數據",
	t3143: "將您的系統管理器配置為使用 HTTPS 或使用桌面版本。",
	t3144: "我們無法與系統管理員溝通。這意味著您的設備無法連接到系統管理器的 IP 或主機名，或者您可能需要手動信任設備上使用的 SSL 證書。",
	t3145: "單擊以下鏈接打開一個新選項卡以信任證書，然後返回此屏幕。",
	t3146: "簽名",
	t3147: "DI - 數字輸入",
	t3148: "AI - 模擬輸入",
	t3149: "進入 HACCP 配置",
	t3150: "沒有數據",
	t3151: "備份保存在系統管理器中用於回滾功能",
	t3152: "選擇要在站點視圖中顯示的內容",
	t3153: "資產單位",
	t3154: "圖形文件",
	t3155: "不幸的是，從系統管理器獲取 edf 文件和版本時發生錯誤...",
	t3156: "要選擇要安裝的文件，請按“選擇文件”按鈕",
	t3157: "選擇文件",
	t3158: "刪除備份",
	t3159: "暫存文件",
	t3160: "備份保存在系統管理器中用於回滾功能",
	t3161: "安裝尺寸",
	t3162: "新尺寸",
	t3163: "安裝日期",
	t3164: "新日期",
	t3165: "傳感器",
	t3166: "繼電器",
	t3167: "輸入",
	t3168: "出局",
	t3169: "米",
	t3170: "通用的",
	t3171: "密碼過期，請輸入新密碼",
	t3172: "新密碼",
	t3173: "確認密碼",
	t3174: "更改密碼",
	t3175: "密碼必須包含最少 8 個字符 1 個大寫字母、1 個小寫字母、1 個數字、1 個特殊字符）",
	t3176: "密碼不匹配",
	t3177: "密碼不能與舊密碼相同",
	t3178: "密碼修改成功",
	t3179: "密碼不能更改。來自設備的錯誤",
	t3180: "重設密碼",
	t3181: "生成",
	t3182: "網站名稱",
	t3183: "事件 ID",
	t3184: "時間",
	t3185: "角色",
	t3186: "事件信息",
	t3187: "確認選定的警報",
	t3188: "確認所有選定的警報",
	t3189: "導入數據庫到",
	t3190: "導入數據庫",
	t3191: "文件類型應為 .zip",
	t3192: "選擇文件",
	t3193: "營業時間",
	t3194: "節假日",
	t3195: "開/關時間表",
	t3196: "資產",
	t3197: "您有未保存的更改。你確定要離開嗎？",
	t3198: "不幸的是，遷移失敗了。系統當前正忙。請稍後再試",
	t3199: "上傳圖片調整到當前尺寸",
	t3200: "替換背景圖片",
	t3201: "用可縮放矢量圖形替換背景圖像。",
	t3202: "僅替換與現有文件同名的 SVG 文件。",
	t3203: "選擇要替換的文件",
	t3204: "圖像文件",
	t3205: "無法處理。文件格式無效。",
	t3206: "{{amount}}文件已成功替換",
	t3207: "{{amount}}文件替換失敗",
	t3208: "視圖沒有背景",
	t3209: "您可以上傳新的或替換現有的背景圖片",
	t3210: "如果要替換背景圖像，則可以在以下情況下保留參數位置：",
	t3211: "png 或 jpg 文件與當前背景具有相同的分辨率",
	t3212: "svg 文件具有相同的縱橫比（但不一定具有相同的分辨率）",
	t3213: "功能被禁用",
	t3214: "當前顯示最後收到的值，更新中...",
	t3215: "上傳在中創建的圖形文件",
	t3216: "（可選的）",
	t3217: "背景圖片",
	t3218: "瀏覽",
	t3219: "更多的...",
	t3220: "添加背景圖片（可選）",
	t3221: "圖形文件",
	t3222: "通訊超時",
	t3223: "未配置製冷計劃",
	t3224: "指數",
	t3225: "類型",
	t3226: "節假日",
	t3227: "天",
	t3228: "上",
	t3229: "離開",
	t3230: "ID",
	t3231: "打開",
	t3232: "開始",
	t3233: "結尾",
	t3234: "描述",
	t3235: "天",
	t3236: "關",
	t3237: "Edf 文件已成功上傳。重啟系統管理器",
	t3238: "Edf 文件上傳完成，系統管理器重置成功",
	t3239: "從系統管理器獲取 edf 文件時出錯",
	t3240: "Edf 文件上傳失敗。過一段時間試試",
	t3241: "未配置製冷計劃",
	t3242: "上傳文件到系統管理器",
	t3243: "指定設備類型 - 影響操作它所需的參數標籤",
	t3244: "指定如何訪問設備的值以及它們實現的功能",
	t3245: "根據一些參數創建警報",
	t3246: "標籤",
	t3247: "創建可分配給參數的標籤列表",
	t3248: "團體",
	t3249: "指定參數組",
	t3250: "註冊號碼",
	t3251: "數據類型",
	t3252: "標籤列表",
	t3253: "最低限度",
	t3254: "默認",
	t3255: "最大",
	t3256: "註冊類型",
	t3257: "寄存器類型翻譯成對應的讀寫功能碼",
	t3258: "交換模式",
	t3259: "對於發送到設備的字節 ABCD 的值，此選項交換字節 (BADC)、字 (CDAB) 或兩者 (DCBA)。",
	t3260: "只讀",
	t3261: "日誌記錄",
	t3262: "啟用此參數的歷史記錄",
	t3263: "將文本從參數接收到警報",
	t3264: "報警標誌的位置，從參數的最低有效位開始計數",
	t3265: "低值觸發警報",
	t3266: "標籤",
	t3267: "隱藏",
	t3268: "{{field}}是必填字段。",
	t3269: "最大值必須大於或等於最小值",
	t3270: "默認值必須在 [Minimum, Maximum]",
	t3271: "所選寄存器類型{{mt}}的數據類型必須為{{reg}} }",
	t3272: "名稱不得包含分隔符",
	t3273: "名稱不得超過 20 個字符",
	t3274: "標籤列表不能為空",
	t3275: "標籤{{taglist}}必須只分配一次。",
	t3276: "組{{grouplist}}包含超過 50 個參數的最大值。",
	t3277: "表單包含錯誤，請檢查錯誤並重試。",
	t3278: "提交",
	t3279: "要激活上傳的 EDF，您必須重置設備。您現在要重置設備嗎？",
	t3280: "立即重置",
	t3281: "稍後重置",
	t3282: "{{file}}已存在。你想覆蓋它嗎？",
	t3283: "上傳{{what}}",
	t3284: "標籤和值必須是唯一的。",
	t3285: "多",
	t3286: "設備可處理多個箱子或包裝",
	t3287: "標籤",
	t3288: "Internet Explorer 已停產，StoreviewWeb 不支持",
	t3289: "請切換到支持的瀏覽器，Edge、Chrome 或 Firefox",
	t3290: "更多信息",
	t3291: "關",
	t3292: "讀取數據失敗，請重試",
	t3293: "正在備份 EDF",
	t3294: "沒有要遷移的視圖",
	t3295: "系統在私有 IP 字段中配置了公共 IP",
	t3296: "系統在公網IP字段中配置了私網IP，系統可能無法遠程訪問或部分功能無法正常工作",
	t3297: "您通過系統管理員不知道的 IP 或主機名連接，可能是通過代理服務，某些功能可能無法正常工作",
	t3298: "設備離線，顯示最後一個已知值",
	t3299: "設備集成",
	t3300: "標籤{{taglist}}重複。",
	t3301: "網頁未找到",
	t3302: "很抱歉，找不到您請求的頁面",
	t3303: "錯誤代碼：404",
	t3304: "轉到儀表板",
	t3305: "只讀",
	t3306: "內部 IP 地址",
	t3307: "外部 IP 地址",
	t3308: "域名",
	t3309: "沒有警告",
	t3310: "多個警告",
	t3311: "系統似乎配置了多台設備，但我們無法聯繫到一台或多台從機，請確保設置正確。請注意，多單元設置不支持多個域名，並且在使用同一域時，每個單元都需要配置不同的端口",
	t3312: "只讀",
	t3313: "該元素與任何參數都沒有關聯，可以安全地刪除",
	t3314: "移除參數",
	t3315: "日程",
	t3316: "您現在將被註銷，需要使用新密碼登錄",
	t759: "數據庫下載、重置和升級單元。這可能需要幾分鐘時間。",
	t816: "報警類型：",
	t818: "數數 ：",
	t848: "未創建 DB 文件。您對已安裝目錄的訪問權限有限。請聯繫系統管理員。",
	t3317: "沒有要導出的時間表",
	t3318: "等待系統響應",
	t3319: "無網絡活動",
	t3320: "店鋪名稱",
	t3321: "在線提供新版本{{latestVersion}}",
	t3322: "外部端口",
	t3323: "內部端口",
	t3324: "我們無法與系統管理器通信",
	t3325: "也許證書需要被信任？",
	t3326: "無法刪除此格式的文件",
	t3327: "用法",
	t3328: "準時",
	t3329: "關閉時間",
	t3330: "平日",
	t3331: "冷卻階段",
	t3332: "熱回收",
	t3333: "冷卻",
	t3334: "加熱",
	t3335: "輔助熱量",
	t3336: "除濕",
	t3337: "網際網路",
	t3338: "留言",
	t3339: "聯繫人",
	t3340: "通信",
	t3341: "未找到任何項目",
	t3342: "鍵入搜索",
	t3408: "全部啟用",
	t3409: "全部禁用",
	t3410: "普通的",
	t3411: "劇烈",
	t3412: "批判的",
	t3413: "啟用所有警報",
	t3414: "搜尋警報",
	t3415: "啟用",
	t3417: "取得警報時出錯",
	t3418: "取得要複製的裝置時出錯",
	t3419: "檢查副本狀態時發生錯誤",
	t3420: "自動輪詢將在最多 2 小時逾時後或嚮導被取消或完成時恢復",
	t3421: "複製到控制器",
	t3422: "複製到選定的設備",
	t3423: "複製功能將首先將設定複製到系統管理器資料庫，然後將設定下載到控制器設備。完成該操作可能需要一些時間。",
	t3424: "未選擇要複製的設備",
	t3425: "按兩下複製",
	t3426: "取得設備時出錯",
	t3427: "影印設備",
	t3428: "選擇要複製的設備",
	t3429: "此處僅顯示相同類型的控制器",
	t3430: "複製過程成功",
	t3431: "文件無效。預期文件模型：",
	t3432: "載入設定檔時出錯",
	t3435: "您確定將所選設備移至此組{{toPackGroup}}嗎？",
	t3343: "發現",
	t3344: "重新整理表",
	t3345: "正在掃描網絡，請稍候...",
	t3346: "網路表已刷新",
	t3347: "刷新網路時發生錯誤",
	t3348: "重新掃描網路時發生錯誤",
	t3349: "取得網路時出錯",
	t3350: "網路掃描完成",
	t3351: "沒有可用的設備",
	t3352: "掃描網路",
	t3353: "未配置",
	t3354: "嚮導中設備的任何重新排列都可能影響 Alsense 監控服務。",
	t3355: "網路",
	t3356: "選擇現場匯流排類型",
	t3357: "如果啟用了 Modbus/RS485，您可以為 Danfoss 變速壓縮機選擇 SLV。選擇 SNMP 作為本地匯流排時，丹佛斯 SNMP 控制項會要求您輸入有效的子網路位址範圍",
	t3358: "壓縮機/機組",
	t3359: "管理壓縮機/機組控制器",
	t3360: "此處只能管理 Pack 設備 - 無法管理機架 I/O 系統。已掃描的相容多吸盤控制器最初將顯示為 0 個吸盤組。設定 Pack 控制器需要 AK-ST 500（服務工具）。",
	t3361: "複製金額",
	t3362: "減少先前配置的吸力組也將刪除該吸力組的任何相關蒸發控制。",
	t3363: "吸力組數不能為零，最小吸力組數吸力組數應至少為 1 個。",
	t3364: "取得壓縮機/包時出錯",
	t3365: "沒有可用的壓縮機/包",
	t3366: "線上設備",
	t3367: "離線設備",
	t3369: "添加包",
	t3370: "如果選擇 Modbus/RS485，您將看到 SLV/ECFan 的附加選項，這些選項用於冷凍，可以選擇為否。",
	t3371: "新增電路",
	t3372: "新增、複製或刪除設備。請注意，並非所有設備類型都允許編輯所有字段，且 I/O 點的位址是在配置中設定的。",
	t3373: "管理案件控制員",
	t3374: "新增設備",
	t3375: "管理壓縮機/機組控制器",
	t3376: "資料存取錯誤",
	t3377: "HVAC佈局已成功應用",
	t3378: "暖通空調佈局",
	t3379: "您確定要離開此頁面嗎？",
	t3380: "您的設定可能會遺失",
	t3381: "請配置設備",
	t3382: "退出快速設定",
	t3383: "您確定要退出快速設定嗎？",
	t3384: "網關 (AK-PI200)",
	t3385: "選擇找到的網關",
	t3386: "IP 地址",
	t3387: "來源控制器",
	t3388: "信號源設定",
	t3389: "警報來源",
	t3390: "原始圖",
	t3392: "圖形",
	t3393: "裝置",
	t3394: "自動輪詢在複製階段暫停。複製完成後，來源裝置和目標裝置的主開關將關閉，然後返回原始狀態。複製精靈完成後，請務必檢查主開關。如果任何選定的設備處於活動警報狀態，則對該特定點的警報操作的任何修改都不會被修改。",
	t3395: "選擇要從中複製設定的來源設備",
	t3396: "準時",
	t3397: "複製功能將複製可用的圖形點，直到達到單位容量。前往配置以查看定義的點數",
	t3398: "來源設備繪圖",
	t3399: "為來源控制器配置圖形點",
	t3400: "啟用 HACCP 以在 HACCP 溫度報告中記錄點",
	t3401: "搜尋點",
	t3402: "無可用數據",
	t3403: "取得圖表點時出錯",
	t3404: "殘障人士",
	t3405: "源設備警報",
	t3406: "在來源控制器中配置警報詳細信息",
	t3407: "警報名稱",
	t3436: "該設備將從分配的群組 [ {{fromPackGroup}} ] 中刪除",
	t3437: "佈局製冷",
	t3438: "冷凍佈局已成功應用",
	t3439: "重新分配",
	t3440: "將案例控件指派給包控件",
	t3441: "將案例控制（從可用案例中）指派給壓縮機/包控制。形成吸力組",
	t3442: "設備參數",
	t3443: "選擇複製參數",
	t3444: "在即時（線上）設備上工作時，由於與設備的通信，設定可能會出現延遲。",
	t3445: "選擇要複製的警報",
	t3446: "設備圖",
	t3447: "選擇要複製的圖點",
	t3448: "將目前設備設定儲存到檔案中",
	t3449: "您可以將目前設備設定儲存到一個檔案中，以便日後配置設備。如果您希望在文件儲存後退出嚮導，請使用退出圖示。如果與連接的裝置一起工作，退出將確保網路輪詢並且裝置主開關將返回正確的狀態。",
	t3450: "設定檔已成功儲存",
	t3451: "您想繼續還是退出快速設定？",
	t3452: "儲存檔案",
	t3453: "取得設定時出錯",
	t3454: "源設備設定",
	t3455: "在來源控制器中配置設備參數",
	t3456: "所有密碼欄位都不能為空",
	t3457: "最近的內容",
	t3458: "通讯簿",
	t3459: "上次訪問時間",
	t3460: "請定期備份地址簿。",
	t3461: "地址簿已成功匯入",
	t3462: "导入通讯簿",
	t3463: "导出通讯簿",
	t3464: "新增站點",
	t3465: "編輯站點",
	t3466: "刪除站點",
	t3467: "在開始使用地址簿之前，您需要建立地址簿密碼",
	t3468: "網站已成功新增至您的通訊錄",
	t3469: "已在通訊錄中",
	t3470: "新增至地址簿",
	t3471: "登入網站",
	t3472: "網站資訊已成功更新",
	t3474: "通知",
	t3475: "網站已成功從您的通訊錄中刪除",
	t3476: "網站已成功更新",
	t3477: "地址簿格式無效",
	t3478: "行動",
	t3479: "離線時不可用",
	t3480: "載入S55資料庫失敗",
	t3481: "載入S55資料庫",
	t3482: "協定",
	t3483: "使用者無權查看軟體訊息",
	t3484: "密碼只能是字母數字或數字",
	t3485: "您需要建立地址簿密碼才能存取地址簿",
	t3486: "地址簿密碼不正確",
	t3487: "站點已存在於通訊錄中",
	t3488: "建立地址簿密碼以使用地址簿。密碼只能是字母數字或數字",
	t3489: "輸入您的通訊錄密碼",
	t3490: "沒有可複製的線上設備",
	t3491: "建立地址簿密碼",
	t3492: "刷新通訊錄密碼",
	t3493: "網站密碼不能為空",
	t3494: "輸入正在匯入的地址簿的密碼",
	t3495: "地址簿密碼建立成功",
	t3496: "地址簿密碼已成功更新",
	t3497: "導入的通訊錄密碼不正確",
	t3498: "請輸入網站密碼",
	t3499: "清除地址簿",
	t3500: "搜尋網站",
	t3501: "選擇要匯入的地址簿文件",
	t3502: "選擇密鑰檔案來解密通訊錄",
	t3503: "按匯出下載加密通訊錄",
	t3505: "帶加密",
	t3506: "未加密的密碼",
	t3507: "密鑰無效",
	t3508: "導入失敗。確保您已匯入正確的金鑰",
	t3509: "無可用版本",
	t3510: "開放 Alsense 服務（需訂閱）",
	t3511: "請仔細閱讀 EULA 條款。如果您不能接受這些條款，您將無法使用該應用程式。",
	t3512: "地址簿和您的地址簿密碼已成功清除",
	t3513: "（必需的）",
	t3515: "地址簿為空",
	t3516: "通訊錄密碼不能為空",
	t3519: "清除地址簿您也將刪除您的通訊錄密碼。",
	t3520: "不在範圍內。應介於 0 到{{maxAddr}}之間",
	t3521: "按「匯出」下載系統管理員中儲存的所有圖形視圖",
	t3522: "按“下載”下載系統報告",
	t3523: "僅主設備可透過直接 WiFi 連線使用",
	t3524: "沒有本地圖形視圖。新增視圖或匯入現有視圖",
	t3525: "沒有遠端圖形視圖。嘗試使用不同的名稱進行搜尋",
	t3526: "沒有遠端圖形視圖。新增視圖、匯入或遷移現有視圖",
	t3527: "本地視圖（ {{totalFiles}}共 5 個）",
	t3528: "{{totalFiles}}本地圖形，最多 5 個",
	t3529: "遠端查看",
	t3530: "文件不是點陣圖。",
	t3531: "點陣圖長度錯誤。",
	t3532: "點陣圖標題大小錯誤。",
	t3533: "點陣圖尺寸錯誤。",
	t3534: "調色板尺寸錯誤。",
	t3535: "不相容的 .bmp 文件",
	t3536: "刪除本機圖形檔案時出錯",
	t3537: "複製本機圖形檔案時發生錯誤",
	t3538: "匯出本機圖形檔案時出錯",
	t3539: "刪除圖形",
	t3540: "刪除本機圖形文件",
	t3541: "本地圖形檔案刪除正在進行中",
	t3542: "本地圖形檔案刪除成功。重新啟動系統管理員",
	t3543: "本機圖形檔案刪除完成。系統管理員重新啟動成功",
	t3544: "重複圖形",
	t3545: "複製本地圖形文件",
	t3546: "本地圖形檔案複製正在進行中",
	t3547: "本地圖形檔案複製成功。重新啟動系統管理員",
	t3548: "本機圖形檔案複製完成。系統管理員重新啟動成功",
	t3549: "導出圖形",
	t3550: "匯出本機圖形文件",
	t3551: "本地圖形文件匯出正在進行中",
	t3552: "本機圖形檔案匯出成功",
	t3553: "系統管理員將在此操作後重新啟動",
	t3554: "您確定要刪除嗎",
	t3555: "您確定要複製嗎",
	t3556: "按「匯出」下載系統管理員中儲存的所有本機圖形視圖",
	t3557: "本地圖形文件匯出正在進行中",
	t3558: "本機圖形檔案匯出成功",
	t3559: "產品代碼",
	t3560: "獲取有關該產品的更多信息",
	t3561: "風扇生態",
	t3562: "閥門開度",
	t3563: "過熱",
	t3564: "蒸發器壓力",
	t3565: "蒸發器溫度",
	t3566: "剪下",
	t3567: "角質",
	t3568: "箱門",
	t3569: "吸氣溫度",
	t3570: "軌道熱",
	t3571: "已達到允許的最大檔案限制。取消選擇要匯入的文件",
	t3572: "請刪除系統管理員中的現有文件以匯入文件",
	t3573: "本地圖形檔案導入成功。重新啟動系統管理員",
	t3574: "本地圖形檔案匯入完成。系統管理員重新啟動成功",
	t3575: "已達到允許的最大檔案限制。刪除要匯入的文件",
	t3576: "允許的最大圖形檔為 5",
	t3577: "本機圖形檔案上傳成功。重新啟動系統管理員",
	t3578: "本機圖形檔案上傳完成。系統管理員重新啟動成功",
	t3579: "文件格式無效。上傳{{supportedFileFormats}}文件",
	t3580: "對於本機螢幕，檔案尺寸必須為{{maxWidthAllowed}} x {{maxHeightAllowed}}像素",
	t3581: "遠端查看",
	t3582: "本地視圖",
	t3583: "商店瀏覽網頁首頁",
	t3584: "最終使用者授權協議條款",
	t3585: "安全",
	t3586: "安全執行級別",
	t3587: "向後相容（最低安全等級 - 假設停用使用標頭驗證）",
	t3588: "SvW / XML 請求包含使用者名稱和密碼。",
	t3589: "無需清理請求，與先前版本相同的安全性",
	t3590: "無需對 SvW 或現有 XML 連接進行任何更改",
	t3591: "寬鬆（可用作嚴格的過渡）",
	t3592: "XML 請求可以包含使用者名稱和密碼，和/或會話令牌。會話令牌是在登入請求的回應中接收的。",
	t3593: "如果 AKSM-auth 標頭中提供了會話令牌，則使用者名稱和密碼將被忽略",
	t3594: "XML 請求已清理，如果發現任何問題，則會在請求回應中傳回警告",
	t3595: "請注意這些警告，為切換到嚴格模式做好準備",
	t3596: "如果不使用會話令牌，則無法套用會話過期",
	t3597: "遠端 UI (SvW) 已支援",
	t3598: "從第三方 XML 角度來看可能/需要的更改",
	t3599: "不使用會話令牌 - 無需更改",
	t3600: "會話令牌使用 - 從正文中刪除使用者名稱/密碼並觀察警告，為嚴格做好準備",
	t3601: "嚴格（最高安全等級）",
	t3602: "XML 請求不能包含使用者名稱和密碼，但必須在 AKSM-auth 標頭中提供會話令牌。如果請求中發現問題，則會被拒絕",
	t3603: "(SvW) 已支持",
	t3604: "從第三方角度來看所需的更改",
	t3605: "必須從 XML 請求負載中刪除使用者名稱和密碼，並且必須將會話令牌放置在名為 AKSM-auth 的 CORS 標頭中",
	t3606: "登入失敗後退",
	t3607: "遠端使用者在嘗試登入失敗後等待多長時間（秒）才能再次嘗試登入。等待時間隨著每次失敗的嘗試而累積增加。 10 次嘗試後，用戶嘗試登入的 IP 將被封鎖 24 小時。如果設定為 0 則停用登入失敗退避",
	t3608: "預設 30 秒（最長 600 秒/0 = 停用）",
	t3609: "自由嘗試",
	t3610: "在應用回退之前允許嘗試多少次登入",
	t3611: "預設 2（最多 5）",
	t3612: "堵塞",
	t3613: "如果啟用的用戶+IP在退避期間被阻止，否則用戶被阻止",
	t3614: "預設“用戶”（用戶+IP/用戶）",
	t3615: "每個使用者的會話數",
	t3616: "會話是使用者成功登入裝置並位於遠端介面 (SvW) 內的情況",
	t3617: "使用者可以擁有的活動會話數。如果設定為 0，則使用者可以擁有的最大會話數 (30)",
	t3618: "預設 0",
	t3619: "會話到期",
	t3620: "空閒會話過期後多少秒。如果會話過期，則需要重新登入",
	t3621: "預設 900 秒（最多 3,600）",
	t3622: "標頭認證",
	t3623: "啟用 HTTPS 和標頭驗證時，所有第 3 方 XML 請求必須在標頭中傳遞使用者名稱/密碼憑證（基本驗證方案）",
	t3624: "啟用“使用標頭身份驗證”需要重新初始化系統。使用“按初始化”按鈕執行此操作",
	t3625: "小的",
	t3626: "大的",
	t3627: "儲存圖形",
	t3628: "儲存本機圖形文件",
	t3629: "本地圖形檔案刪除成功。本地圖形檔案載入正在進行中",
	t3630: "本地圖形檔案載入成功。重新啟動系統管理員",
	t3631: "本機圖形檔案儲存完成。系統管理員重新啟動成功",
	t3632: "您確定要儲存嗎？",
	t3633: "解析失敗",
	t3634: "無法解析視覺化文件",
	t3635: "無法解析 vz2 文件",
	t3636: "開啟 Viz 檔案失敗：",
	t3637: "找不到遷移檔案（vz2、dpj 或 json）",
	t3638: "找不到遷移檔案（即）",
	t3639: "系統管理員將在此操作後重新啟動。系統管理員處於寬鬆或嚴格模式，因此會話令牌將在重新啟動時過期，並且您將從系統中登出",
	t3643: "排出壓力",
	t3644: "總功率",
	t3645: "總無功功率",
	t3646: "總無功電量+",
	t3647: "要求",
	t3648: "功率因數",
	t3649: "字體顏色",
	t3650: "背景顏色",
	t3651: "地址簿已成功匯出",
	t3652: "控制方式",
	t3653: "Sc3 室外溫度",
	t3654: "排氣溫度",
	t3655: "凝汽器運作能力",
	t3656: "沒有要匯出的數據",
	t3657: "{{unit}}上的使用者密碼已過期",
	t3658: "無法使用提供的憑證登入{{unit}} 。",
	t3659: "更新韌體版本或更改{{unit}}的安全強製等級以獲得會話令牌支援。",
	t3661: "V3gc",
	t3662: "B部分",
	t3663: "C節",
	t3664: "自適應過熱度控制",
	t3665: "控制狀態原因",
	t3666: "使用者無法登入。",
	t3667: "代理配置中未指定通訊協定。可能會導致錯誤",
	t3668: "IT安全資訊",
	t3669: "為了符合安全標準，系統管理員現在預設為嚴格會話控制，這意味著除非套用會話令牌，否則任何遠端連線/基於 XML 的遠端服務將不再運作。",
	t3670: "丹佛斯建議繼續使用嚴格模式，但向後相容模式仍然可用。",
	t3671: "請注意，這可以在「配置安全性」功能表中更改，並且對系統管理員本機控制和網站操作沒有影響。",
	t3672: "查看有關會話控制的更多信息",
	t3673: "後退模式",
	t3674: "嚴格模式",
	t3675: "在點擊向後相容模式後，所有具有更新韌體的適用系統管理器將被設定為向後相容模式，重新啟動並且應用程式將被註銷",
	t3676: "允許的吸力組最大數量為 12",
	t3677: "成功將所選裝置從 3.3.0 更新到 4.X 或更高版本後，在最後一步中按一下「完成」按鈕後，使用者將自動登出。",
	t3678: "不支援 AK2 控制器的多盒（包/盒）",
	t3679: "根據 4.0 版引入的安全標準，系統管理器將預設為嚴格會話控制，這意味著除非套用會話令牌，否則任何遠端連線/基於 XML 的遠端服務將不再運作。除此之外，升級到rel後，通訊將自動調整為HTTPS。 4.0。丹佛斯建議繼續使用嚴格模式，但向後相容模式可用，這會將系統設定回到目前為止應用的設定。請注意，如果您在 HTTP 裝置上遠端進行此更新，請確保您的 IT 設定已準備好支援 HTTPS（連接埠 443）或現場有人員在場。",
	t3680: "在此處查找有關新安全實施的更多信息",
	t3681: "由於您的瀏覽器限制，造訪本網站的 http 版本可能會被封鎖。請造訪我們的常見問題頁面以取得解決此問題的說明或下載 StoreView Web 的桌面版本。",
	t3682: "系統管理員常見問題解答",
	t3683: "u17 治療師。空氣",
	t3684: "u91 切斷溫度",
	t3685: "u00 控制。狀態",
	t3686: "u12 S3 空氣溫度",
	t3687: "u16 S4 空氣溫度",
	t3688: "u23 EEV OD %",
	t3689: "u21 過熱度",
	t3690: "u22 過熱參考",
	t3691: "u26 蒸發溫度",
	t3692: "S2 氣體出口溫度",
	t3693: "斯威8",
	t3694: "Sd-MT",
	t3695: "TW 狀態",
	t3696: "TW 啟用",
	t3697: "TW 泵浦運行",
	t3698: "斯威3",
	t3699: "斯沃4",
	t3700: "斯威2",
	t3701: "接收值。控制狀態",
	t3702: "吸力偏移",
	t3703: "Vrec控制模式",
	t3704: "Vrec狀態",
	t3705: "Ctrl狀態原因",
	t3706: "HP控制狀態",
	t3707: "最大PGC",
	t3708: "最大PGC限制P-禁令",
	t3709: "PGC 分鐘。",
	t3710: "蒸汽噴射器容量",
	t3711: "液體噴射器容量",
	t3712: "參考空氣溫度",
	t3713: "供氣溫度",
	t3714: "回風",
	t3715: "區域露點",
	t3716: "區域濕度",
	t3717: "室外溫度",
	t3718: "區域二氧化碳濃度",
	t3719: "區域溫度",
	t3720: "條件。參考",
	t3721: "條件。控制狀態",
	t3722: "條件。控制狀態",
	t3723: "條件。 Ctrl。",
	t3724: "條件。要求的上限。",
	t3725: "V3gc狀態",
	t3726: "V3gc OD",
	t3727: "條件。溫度。",
	t3728: "人力資源狀況",
	t3729: "人力資源啟用",
	t3730: "人力資源消費者要求",
	t3731: "什爾3",
	t3732: "什爾4",
	t3733: "什爾2",
	t3734: "什爾8",
	t3735: "吸力參考值到MT",
	t3736: "控制狀態 MT",
	t3737: "實際區域 MT",
	t3738: "去選擇。偏移量MT",
	t3739: "運作能力MT",
	t3740: "要求的上限。公噸",
	t3741: "下一步時間 MT",
	t3742: "多聚MT",
	t3744: "吸氣溫度到MT",
	t3745: "總有功能量",
	t3746: "高峰需求",
	t3747: "總功率因數",
	t3748: "A相電壓",
	t3749: "B相電壓",
	t3750: "C相電壓",
	t3751: "A相放大器",
	t3752: "B相放大器",
	t3753: "相位放大器",
	t3754: "總有功功率",
	t3755: "吸氣溫度至LT",
	t3756: "吸氣溫度至IT",
	t3757: "吸力參考 LT",
	t3758: "控制狀態 LT",
	t3759: "實際區域 LT",
	t3760: "去選擇。偏移LT",
	t3761: "運行能力LT",
	t3762: "要求的上限。 LT",
	t3763: "下一步時間 LT",
	t3764: "Po-LT",
	t3765: "硫代LT",
	t3766: "吸力參考IT",
	t3767: "控制狀態IT",
	t3768: "實際區域IT",
	t3769: "運作能力 IT",
	t3770: "請求的容量 IT",
	t3771: "下一步 IT 的時間",
	t3772: "肌鈣蛋白-IT",
	t2773: "硫代IT",
	t2774: "IT 比較開始",
	t3774: "不在範圍內。應介於{{minAddr}}到{{maxAddr}}之間",
	t3775: "該設備已禁用操作",
	t3776: "執行此操作後，應用程式將註銷，這可能需要一些時間。",
	t3777: "找不到遷移檔案（flp 或 json）",
	t3778: "輪詢",
	t3779: "目前投票已暫停。",
	t3780: "您想恢復投票嗎？",
	t3781: "電能表",
	t3782: "選擇多個節點",
	t3783: "縮放至節點",
	t3784: "測量單位",
	t3785: "文件匯出成功",
	t3786: "地址不能為負數",
	t3787: "重設變焦",
	t3788: "取得暫存模式步驟時出錯",
	t3789: "儲存暫存模式步驟時發生錯誤",
	t3790: "暫存模式步驟已成功儲存",
	t3791: "分期模式",
	t3792: "按卸載圖示可啟用/停用與壓縮機關聯的卸載器",
	t3793: "缺失資料",
	t3794: "以下參數未傳回任何數據點",
	t3795: "HTTPS/SSL 信任成功。",
	t3796: "現在您可以登入 StoreView Web 瀏覽器。",
	t3797: "{{timeLeft}}秒後重定向到 StoreView Web 瀏覽器...",
	t3798: "目前投票已暫停！",
	t3799: "已清除的警報（完整的系統日誌）",
	t3800: "允許的 HVAC 最大數量為 45。",
	t3801: "取得變更日誌失敗",
	t3802: "正在連接到儲存...",
	t3803: "SCADA儲存連接逾時",
	t3804: "無法連接到SCADA存儲",
	t3805: "无效输入",
	t3806: "選擇報告下載的單位"
};
var zh = {
	lang: lang$e
};

var lang$f = {
	t0: "Aktive Alarmer",
	t1: "Ryddede Alarmer",
	t2: "Vært",
	t3: "ID",
	t4: "Enhedsnavn",
	t5: "Rediger Noter",
	t6: "Ikke Godkendt til at Kvittere Alarmen",
	t7: "Alarmtype",
	t8: "Opstået",
	t9: "Kvitter",
	t10: "Aktiv(e) Alarm(er)",
	t11: "Ryddet",
	t12: "Beregninger",
	t13: "Ryddede Alarm(er)",
	t14: "Lydløs/Godk",
	t15: "Kvitter alle alarmer",
	t16: "Godkendelsen mislykkedes!",
	t17: "Fejl",
	t18: "Manuel styring",
	t19: "Visuel",
	t20: "Oversigt",
	t21: "Teknisk Visning",
	t22: "Graf (S4)",
	t23: "Node",
	t24: "Mod",
	t25: "Graf (Po og Pc)",
	t26: "(ingen alarmer fundet)",
	t27: "Enhedsalarmer",
	t28: "Vis tidligere enhed",
	t29: "Vis næste enhed",
	t30: "Fejl under læsning af alarmer.",
	t31: "Nuværende alarmer",
	t32: "Nuværende alarm",
	t33: "Ingen alarmer fundet.",
	t34: "Type",
	t35: "Punkt",
	t36: "Historik Log",
	t37: "Tidsplaner",
	t38: "Starttid",
	t39: "Stoptid",
	t40: "Dage",
	t41: "Ferier",
	t42: "Status",
	t43: "Vælg Datapunkter",
	t44: "OK",
	t45: "Cancel",
	t46: "Start",
	t47: "Slut",
	t48: "Startdato",
	t49: "Log interval",
	t50: "Ingen datapunkter valgt",
	t51: "Nu",
	t52: "sek",
	t53: "min.",
	t54: "tim",
	t55: "Dag",
	t56: "Adressetype",
	t57: "Adresse",
	t58: "Placering:",
	t59: "Indtast Ny Værdi",
	t60: "Opdater",
	t61: "Vælg Datapunkter",
	t62: "Vælg den Nye Værdi",
	t63: "Fra",
	t64: "Til",
	t65: "Hent datapunkter...",
	t66: "Fejl under hentning af data...",
	t67: "Ingen historik konfigureret...",
	t68: "Fejl under hentning af datapunkter...",
	t69: "Henter historik for",
	t70: "Fejl under hentning af historik...",
	t71: "Brugernavn",
	t72: "Password",
	t73: "Værts IP/URL-adresse:",
	t74: "OK",
	t75: "Annuller",
	t76: "Navn",
	t77: "Værdi",
	t78: "Fejl under hentning af brugere",
	t79: "Indtast Ny Adresse",
	t80: "Hentning af Brugerlisten Mislykkedes. Kontroller Værts IP/URL-adresse",
	t81: "Godk Kode",
	t82: "Acct Kode",
	t83: "Vælg Ny Tid og Dato",
	t84: "Vælg Ny Varighed",
	t85: "Opgave mislykkedes",
	t86: "Indtast godkendelsesinformation",
	t87: "HVAC",
	t88: "Start!",
	t89: "Afrimningsfunktion",
	t90: "Start Manual Afrimning",
	t91: "Styring af kølemøbelbelysning",
	t92: "Manuel Kølemøbelbelysning Til",
	t93: "Manuel Kølemøbelbelysning Fra",
	t94: "Styring af kølemøbelrengøring",
	t95: "Manuel Rengøring af Kølemøbel Til",
	t96: "Manuel Rengøring af Kølemøbel Fra",
	t97: "Hovedkontakt",
	t98: "Manuel Hovedkontakt Til",
	t99: "Manuel Hovedkontakt Fra",
	t100: "Nedlukningsstyring",
	t101: "Luk enheden ned",
	t102: "Tænd for enheden",
	t103: "Natindstilling Regulering",
	t104: "Natindstilling Til",
	t105: "Natindstilling Fra",
	t106: "Besked sendt...",
	t107: "Manuel styring",
	t108: "Luk",
	t109: "Lys",
	t110: "Effekt/Div",
	t111: "Oversigt",
	t112: "Vælg den AK255-enhed, der skal vises.",
	t113: "Indlæser...",
	t114: "af",
	t115: "Alle AK255'ere",
	t116: "Forrige",
	t117: "Sider",
	t118: "Næste",
	t119: "Parameter",
	t120: "Målinger",
	t121: "Henter data....",
	t122: "Fejl under læsning af sektion",
	t123: "Temperatur",
	t124: "Forsinkelse efter afrimning",
	t125: "Alarm, hvis under",
	t126: "Alarm, hvis over",
	t127: "Overvågning",
	t128: "Offline",
	t129: "Aktiv",
	t130: "Vært",
	t131: "Adresse",
	t132: "Beskrivelse",
	t133: "Aktuel Værdi",
	t134: "Aktuel Status",
	t135: "Regulatorer",
	t136: "Enhed",
	t137: "Aktiv Alarm Eksisterer",
	t138: "Aktive Alarmer Eksisterer!",
	t139: "On-Off Input",
	t140: "Relæudgange",
	t141: "Føler Input",
	t142: "Variable Output",
	t143: "Generiske Regulatorer",
	t144: "Andet",
	t145: "Visualiseringspunkt",
	t146: "Butiksoversigt",
	t147: "Log Ud fra Systemet",
	t148: "Log På",
	t149: "Log af",
	t150: "Køling",
	t151: "Enhed Oversigt",
	t152: "Alarmer",
	t153: "Historik",
	t154: "CS-Oversigt",
	t155: "Butiksoversigt",
	t156: "Konfiguration",
	t157: "Sidevisning",
	t158: "Anlægsoversigt",
	t159: "Startside",
	t160: "Alarm",
	t161: "Sætpunkt",
	t162: "Enhedsoplysninger",
	t163: "Indstilling",
	t164: "Vælg dato",
	t165: "Information",
	t166: "Audit Trail",
	t167: "Beregninger",
	t168: "Filstyring",
	t169: "Hjælpemanual",
	t170: "Info",
	t171: "Oversætter",
	t172: "Gem på server",
	t173: "Opdater sprogfiler?",
	t174: "Denne handling opdaterer sprogfilerne på enheden. Vil du fortsætte?",
	t175: "Eksporter Historik",
	t176: "Vælg Ny Værdi",
	t177: "minimum",
	t178: "maksimum",
	t179: "Ingen Aktiv Alarm!",
	t180: "Kan ikke ændres!",
	t181: "Vent et øjeblik....",
	t182: "Kommando sendt!",
	t183: "Handlingen mislykkedes. Prøv igen senere!",
	t184: "Handlingen Lykkedes!",
	t185: "Ikke Godkendt Handling!",
	t186: "Er du sikker på, at fortsætte denne handling?",
	t187: "FORDAMPER",
	t188: "PACK",
	t189: "Overvågning Input",
	t190: "Diverse",
	t191: "Kvitterede Alarmer",
	t192: "Ryd Kvitterede Alarmer",
	t193: "Ryd Alle Kvitterede Alarmer",
	t194: "Du er ikke autoriseret til at kvittere alarmer",
	t195: "Ikke Godkendt",
	t196: "Ønsker du at kvittere denne alarm?",
	t197: "Bekræft for at Kvittere Alarm",
	t198: "Log venligst ind først",
	t199: "Vælg en alarm at rydde",
	t200: "Bekræft for at Rydde Alarmer",
	t201: "Er du sikker på, du vil rydde denne alarm?",
	t202: "Ikke Godkendt til at Rydde Alarmen",
	t203: "Er du sikker på, du vil rydde alle alarmer?",
	t204: "De valgte alarmer kan ikke ryddes",
	t205: "Alarm indst.",
	t206: "Skal Godkendes som Supervisor for at udføre denne handling",
	t207: "Ingen data returneres fra enheden",
	t208: "Vælg en Enhed",
	t209: "Vælg en enhed fra listen",
	t210: "Antal Alarmer",
	t211: "Alarm Status",
	t212: "Diverse",
	t213: "Ryd Valgt Alarm",
	t214: "Bekræft venligst",
	t215: "Forbindelse til enheden mislykkedes. Kontroller netværksforbindelsen!",
	t216: "Afrimning Alarm",
	t217: "Digital Alarm",
	t218: "Digital Input Status",
	t219: "Afrimningsindgang",
	t220: "Rengøringsinput",
	t221: "Digitalt Input",
	t222: "Gemmer Sprogfiler",
	t223: "Enheder",
	t224: "Ingen Enhed er Konfigureret i systemet. Hjemmeside deaktiveret.",
	t225: "Advarsel:",
	t226: "Vælg Tid",
	t227: "Kommunikationer",
	t228: "Download Rapportfilen?",
	t229: "Vent et øjeblik, mens rapportfilen oprettes ...",
	t230: "Download Rapport",
	t231: "Alarm Status/Service",
	t232: "Vent et øjeblik, mens dataene hentes...",
	t233: "Logget På",
	t234: "Brugeren er Logget Af",
	t235: "Brugeren er Logget På",
	t236: "Velkommen!",
	t237: "Ugyldig licens",
	t238: "Energimålerregulatorer",
	t239: "Belysningspaneler",
	t240: "VLT inverter",
	t241: "Kompressor modul",
	t242: "Enhed Regulator Punkter",
	t243: "Logik punkter",
	t244: "Antal Cutler-Hammer Paneler",
	t245: "Transportører",
	t246: "Avanceret Visning",
	t247: "Dobbeltklik Her for at Zoome Ind/Ud",
	t248: "Klik Her for at Zoome Ind/Ud",
	t249: "Kvitterede Alarm(er)",
	t250: "Simulator",
	t251: "Køleopgave",
	t252: "HVAC-opgave",
	t253: "Belysningsopgave",
	t254: "DIV Opgaver",
	t255: "Netværket er Tilsluttet",
	t256: "Omformer",
	t257: "Reference",
	t258: "Graf (Omformer Mtr Hz og Amp)",
	t259: "Stopdato",
	t260: "Stop",
	t261: "Stoptidspunktet ligger på eller før starttidspunktet. Vælg dato/tid igen.",
	t262: "Historikoprettelsestid for datapunkt(er)",
	t263: "er senere end den angivne starttid",
	t264: "Det returnerede antal punkter fra hvert historikpunkt har rundet de 100.000. Det vil tage meget lang tid at få svar. Du kan reducere dette antal ved at mindske intervallet ELLER ændre datoen/tidspunktet.",
	t265: "Enhedens historikforespørgselsopgave er optaget. Vent i ca. 2 minutter, og prøv igen...",
	t266: "Gem",
	t267: "Historik Betjeningspanel",
	t268: "Først",
	t269: "Sidste",
	t270: "Udskriv",
	t271: "Zoom Out",
	t272: "Zoom Ind",
	t273: "Vis/Skjul Forklaring",
	t274: "Eksporter Historikdata til Fil",
	t275: "Vis/Skjul Gitter",
	t276: "Visning af Enkelt side",
	t277: "Flersidet Visning",
	t278: "Indlæs Historikgruppe fra Fil",
	t279: "Indlæs Historik fra Fil",
	t280: "Gem Historikgruppe til Fil",
	t281: "Flersidet Visningstæller",
	t282: "Side",
	t283: "Vælg en side til visning",
	t284: "Historik-filen skal være i .hst-format!",
	t285: "Ingen data returneres. Kontroller valg af historikpunkt og den/det valgte dato/tidspunkt",
	t286: "Historik Snapshot",
	t287: "Historik Fuld Visning",
	t288: "Historikfil-visning",
	t289: "Vælg...",
	t290: "Denne handling vil eksportere den nuværende historikvisning til en .hst-/.csv-fil.",
	t291: "Vælg en målfil.",
	t292: "Fil",
	t293: "Indlæs Historikgruppe...",
	t294: "Indlæs Historik...",
	t295: "Gem Historikgruppe...",
	t296: "Vis",
	t297: "Zoom",
	t298: "Ja",
	t299: "Nej",
	t300: "Hent konfiguration fra regulator?",
	t301: "Spørg ikke igen",
	t302: "Hent Regulatorkonfiguration",
	t303: "Data Hentet",
	t304: "Alarmniveau",
	t305: "Alarmhandling",
	t306: "Kvitteret",
	t307: "Gem Database til Disk",
	t308: "Filnavn",
	t309: "Databasebeskrivelse",
	t310: "Databasesti",
	t311: "Version",
	t312: "Tid",
	t313: "Eksport Alarmer",
	t314: "Database gemt til",
	t315: "Det lykkedes ikke at gemme databasen. Kontroller, om mappen og filnavnet er gyldige!",
	t316: "Filnavnet er ikke korrekt. Kontroller databasefilnavnet",
	t317: "Eksport af alarmer lykkedes. Vælg en fil, hvor de eksporterede alarmer gemmes",
	t318: "Vælg Alarmer til Eksport",
	t319: "Henter Alarmoplysninger fra Enhed(er)",
	t320: "Filbeskrivelse",
	t321: "Konverter .hst-fil til .csv-format",
	t322: "Trin 1: Vælg .hst-filen",
	t323: "Trin 2: Vælg Datapunkterne og Tidsintervallet",
	t324: "Trin 3: Vælg .csv-destinationsfilen",
	t325: "Gem som...",
	t326: "Højst 6000 datapunkter er tilladt i denne funktion",
	t327: "Valgte datapunkter",
	t328: "Det er ikke tilladt at eksportere mere end 65.536 punkter pr. datapunkt. Ændr intervallet eller tidsrummet.",
	t329: "Indsaml",
	t330: "Indsaml historik fra enheden",
	t331: "Vælg indsamlede datapunkter",
	t332: "Ingen Værdi",
	t333: "Mangler",
	t334: "Afbrudt",
	t335: "Foretag et Valg",
	t336: "Vælg Datapunkter, der skal tegnes",
	t337: "Gem til .hst-fil",
	t338: "Dataoverførslen er fuldført. Vælg en af følgende muligheder:",
	t339: "Vælg højest 8 punkter, der skal tegnes",
	t340: "Gem indsamlet historikdata til .hst-fil",
	t341: "Udskrift af oversigt",
	t342: "Vælg Gem eller Udskriv Oversigtsrapport",
	t343: "Vælg et filformat",
	t344: "Opretter Oversigtsfilen, vent et øjeblik...",
	t345: "Den valgte database eksisterer. Vil du overskrive den?",
	t346: "Dashboard",
	t347: "Grafisk visning",
	t348: "Hjælp",
	t349: "System Visning",
	t350: "Oversigt",
	t351: "Oversættelsesværktøj",
	t352: "Manuel",
	t353: "Anmod om alarmer...",
	t354: "Opdateret",
	t355: "Model",
	t356: "Regulering",
	t357: "Olie",
	t358: "Temp.",
	t359: "Maksimere",
	t360: "Gendan",
	t361: "Gå tilbage...",
	t362: "Komm mislykkedes!",
	t363: "Komm status ok.",
	t364: "Komm besked sendt.",
	t365: "Indlæser Hjemkomponent",
	t366: "Indlæser Konfigurationskomponent",
	t367: "Ktrl Temp",
	t368: "Denne indstilling vil kun have virkning, når historikken gentegnes. Ønsker du at fortsætte?",
	t369: "Indlæser, vent et øjeblik",
	t370: "datapunkter",
	t371: "Intet enhedsnavn",
	t372: "Præferencer",
	t373: "Simulatorversion",
	t374: "Energi",
	t375: "Gå til oversigtsvinduet",
	t376: "Systemalarm eller ingen relateret enhed blev fundet",
	t377: "Sideløbende tidsplaner",
	t378: "Mangler sprogfil.",
	t379: "Versionen understøttes ikke",
	t380: "Indtast en enheds IP-adresse her (fx 192.168.1.1)",
	t381: "Indtast en enheds IP-adresse eller URL.",
	t382: "Skjul Detaljer",
	t383: "Vis Detaljer",
	t384: "Ude Temp",
	t385: "Retur",
	t386: "Forsyning",
	t387: "Fugtighed",
	t388: "Dugpunkt",
	t389: "Fan",
	t390: "Zone",
	t391: "Nedlukning",
	t392: "Split",
	t393: "Sugetemp",
	t394: "Aktuel Kapacitet",
	t395: "Sugetryk",
	t396: "Afgang",
	t397: "Kompressorer",
	t398: "Kondensator",
	t399: "Kondensator Mål",
	t400: "Kølemiddel",
	t401: "Sugesetpunkt",
	t402: "Sug",
	t403: "Afrimning",
	t404: "Danfoss Historikformat",
	t405: "Tekstformat",
	t406: "Denne handling har brug for at genstarte enheden; vil du genstarte enheden nu?",
	t407: "Genstart af enhed lykkedes. Genindlæs hjemmesiden.",
	t408: "Factory Application Mode",
	t409: "Beregner...",
	t410: "minutter",
	t411: "Natindstilling",
	t412: "Kølemøbellys",
	t413: "Planlæg Samtidighed",
	t414: "(10 minutters opløsning)",
	t415: "Vis tekst",
	t416: "Eksporter som CSV",
	t417: "Gem PNG",
	t418: "Vis Graf",
	t419: "Vis tekst",
	t420: "Planlæg",
	t421: "Varighed",
	t422: "Stop Temp",
	t423: "Ingen",
	t424: "Reguler Temp",
	t425: "Kører fra",
	t426: "Databasen indlæst fra",
	t427: "Statistik",
	t428: "Vælg fil",
	t429: "Vælg den historikfil, du ønsker at konvertere til CSV-format...",
	t430: "Afslut",
	t431: "Sammendrag",
	t432: "Historik-datafiler (*.hst)",
	t433: "Historik Konverteringsguide",
	t434: "Filoprettelse fuldført",
	t435: "Vælg de datapunkter, du ønsker at konvertere til CSV-format...",
	t436: "Vælg Tidsinterval",
	t437: "Vælg tidsintervallet, og tryk på procesknappen...",
	t438: "Procesdata",
	t439: "Forbereder historikdata, vent et øjeblik",
	t440: "Vælg CSV-filnavnet og gem data til fil…",
	t441: "Kan ikke finde historikdatapunkt",
	t442: "Fejl - Ingen historikdata er returneret",
	t443: "Der opstod en fejl under kommunikationen med enheder over værtsnetværket. Fundet",
	t444: "Bemærk",
	t445: "Lydløs",
	t446: "Database ikke gemt. Ønsker du virkelig at lukke vinduet?",
	t447: "Tabelvisning",
	t448: "Vis Grafik",
	t449: "Vis Tabel",
	t450: "Manuel Afrimning Til",
	t451: "Manuel Afrimning Fra",
	t452: "Auto Afrimning",
	t453: "Indstil Afrimningsfunktionen",
	t454: "Unloader on",
	t455: "Unloader off",
	t456: "Væske",
	t457: "Ingen tidsplaner konfigureret.",
	t458: "StoreView",
	t459: "Mistet kommunikation med en enhed på værtsnetværket!",
	t460: "Hurtig Opsætning",
	t461: "Gade",
	t462: "By",
	t463: "Telefon",
	t464: "Region",
	t466: "Indeks",
	t469: "Eksport af Adressebog er Fuldført",
	t470: "Slet emne",
	t471: "Manometer",
	t472: "Bemærk: I inputtet er der forskel på store og små bogstaver",
	t473: "Online",
	t474: "RO Board",
	t475: "SI Board",
	t476: "Ol Board",
	t477: "Beregning",
	t478: "Indstil til Aktuel Tid",
	t479: "Rack",
	t480: "Ingen Kompressor",
	t481: "Kredsløb",
	t482: "Kølemøbel",
	t483: "Ukendt",
	t484: "fejltype:",
	t485: "Tilføj",
	t486: "Kopi",
	t487: "Fjern",
	t488: "Kølemøbelstyring",
	t489: "Kølemøbeltype",
	t490: "LON",
	t491: "Indtast antal til kopi",
	t492: "intet navn",
	t493: "Konfigurerer køle opsætning...",
	t494: "Konfigurationsfejl:",
	t495: "Kontroller konfigurationen.",
	t496: "Kølingsopsætningen er fuldført. Tryk på ok-knappen for at afslutte denne guide.",
	t497: "Konfigurationsstatus",
	t498: "Færdig Kølingsopsætning",
	t500: "Kode #",
	t501: "Layout",
	t502: "Systemopsætning",
	t503: "Licensering",
	t504: "Brugere",
	t505: "Ikke Godkendt",
	t506: "Kontroller...",
	t507: "Er du sikker på, at du vil forlade denne skærm? Din indstillinger kan gå tabt.",
	t508: "Scanner regulatornetværket...",
	t509: "Scanning",
	t510: "Bekræfter",
	t511: "AK-I/O",
	t512: "Søg igen",
	t513: "Inkluder offline-programmering",
	t514: "Enhed Bus",
	t515: "Online-enheder",
	t516: "Offline-programmering",
	t517: "Dupliker adresse",
	t518: "Kompressorstyringer",
	t519: "Sugegrupper",
	t520: "IKKE OPLYST",
	t521: "Tilgængelige Kølemøbler",
	t522: "Tillad oprettelse af flerkølemøbel-kredsløb",
	t523: "Indsæt enheder",
	t524: "Indsæt kredsløb",
	t525: "Multi-kølemøbel kredsløb.",
	t526: "Indlæser tabeller ...",
	t527: "Anmoder om alarmdata for",
	t528: "Historik Gruppe Filer",
	t529: "Historik-datafiler",
	t530: "Alle Filer",
	t531: "Vis Symboler",
	t532: "Vis Kurver",
	t533: "Ikke nok historik er blevet indsamlet.",
	t534: "Hent de seneste historikdata.",
	t535: "Status/Indstillinger",
	t536: "Downloader...",
	t537: "historikprøver...",
	t538: "Henter",
	t539: "Datapunkter...",
	t540: "Interval",
	t541: "Min",
	t542: "Maks",
	t543: "Gennemsnit",
	t544: "Gå til hovedalarmvinduet.",
	t545: "Stop auto-logon",
	t546: "Tilslutter til ",
	t547: "Annuller auto-logon",
	t548: "Indtast log-af password",
	t549: "Web 1",
	t550: "Web 2",
	t551: "Log på automatisk",
	t552: "Log ud efter inaktivitetsperiode",
	t553: "Startskærm",
	t554: "Vis konfigurationsmenu",
	t555: "Vis div. punkter på Dashboardet",
	t556: "Brug fuld skærm",
	t557: "Aktiver alarmlyd",
	t558: "Tillad programmet at lukke/minimere",
	t559: "Fejl under adgang til enhed #",
	t560: "Tilsluttet til netværk.",
	t561: "Auto-login annulleret",
	t562: "Prøv igen at tilslutte til",
	t563: "Logget ud fra",
	t564: "Tilsluttet",
	t565: "Tidsformat",
	t566: "Datoformat",
	t567: "Tryk",
	t568: "Lysniveau",
	t569: "Underkøling",
	t570: "Underkøler",
	t571: "Daglig",
	t572: "Ugentlig",
	t573: "HST-fil",
	t574: "CSV Fil",
	t575: "Søndag",
	t576: "Mandag",
	t577: "Tirsdag",
	t578: "Onsdag",
	t579: "Torsdag",
	t580: "Fredag",
	t581: "Lørdag",
	t582: "Historik Log Indsamling",
	t583: "Aktivér auto-opsamling",
	t584: "Hvornår der skal indsamles",
	t585: "Placering af gemt fil",
	t586: "Vælg filformat",
	t587: "Vælg mappe til opbevaring af historikfiler...",
	t588: "Aktivér automatisk historikopsamling for at hente og gemme daglige eller ugentlige historiklogger af butikkens konfigurerede historikpunkter.",
	t589: "Gå til hovedhistorikskærmen og vælg Importer historik for at se HST-historikfiler. Se CSV-filer i hvilket som helst regnearksprogram.",
	t590: "Hvis den nuværende forbindelses IP-adresse ikke findes i adressebogen, vil de valgte historikpunkter blive gemt.",
	t591: "Vælg datapunkter",
	t592: "Alle valgte",
	t593: "Valgte punkter",
	t594: "Søg",
	t595: "Auto-tilslutning til",
	t596: "Kontroller",
	t597: "Automatisk historikindsamling er aktiveret. Er du sikker på, at du vil lukke dette program?",
	t598: "Er du sikker på, at du vil lukke dette program?",
	t599: "Handling",
	t600: "Filter",
	t601: "Alle handlinger",
	t602: "Mere information...",
	t603: "Redigerbar",
	t604: "Drift",
	t605: "Opgave annulleret",
	t606: "Download af Databasen er færdigt",
	t607: "Enhedernes Database Opdateret",
	t608: "Filen/filerne blev overført til enheden",
	t609: "Downloadet er færdigt. Filen er under behandling i enheden",
	t610: "Der opstod en fejl under upload af fil til PC. Kontroller, om filen er skrivbar og tilgængelig",
	t611: "Overfører filen...",
	t612: "Downloader Filen",
	t613: "Overfører valgt(e) fil(er)",
	t614: "Downloader valgte fil(er)",
	t615: "Sletter Fil",
	t616: "Sletter valgte fil(er)",
	t617: "FTP-forbindelsesfejl",
	t618: "Download valgte fil(er) til Enheden",
	t619: "Der opstod en fejl i FTP-forbindelsen. Kontrollér FTP-forbindelse og password/brugernavn.",
	t620: "FTP tilsluttet ",
	t621: "Bytes",
	t622: "Filer på enheden bliver overskrevet ved denne handling. Ønsker du at fortsætte?",
	t623: "Filer på PC'en bliver overskrevet ved denne handling. Ønsker du at fortsætte?",
	t624: "Filerne er blevet downloadet til enheden.",
	t625: "KUN Køling",
	t626: "Døgnbutik",
	t627: "Fuld Funktionalitet",
	t628: "KUN HVAC",
	t629: "Filnavnet er ulovligt. Sørg for, at filnavnet har mindre end 8 tegn.",
	t630: "Overskriv applikationskoden nu?",
	t631: "Senere",
	t632: "overført",
	t633: "du kan vælge JA for at overskrive applikationen nu eller vælge Senere",
	t634: "Vælg Simulator Rodmappe",
	t635: "Vælg Windowsmappen",
	t636: "Vælg den Ønskede Mappe",
	t637: "Vælg Databasemappe",
	t638: "FTP frakoblet",
	t639: "Forbindelse Timeout, FTP Frakoblet",
	t640: "Overfør valgt(e) fil(er) til PC",
	t641: "Slet Valgte Fil(er)",
	t642: "FTP-serverport",
	t643: "Web Server Port",
	t644: "Lokal Sti",
	t645: "Op",
	t646: "Størrelse",
	t647: "Dato",
	t648: "Tilslut",
	t649: "Frakobl",
	t650: "Fjernsti",
	t651: "Ledig Plads:",
	t652: "Valgt(e) Fil(er)",
	t653: "Filer",
	t654: "Samlet størrelse:",
	t655: "Klik for at ændre mappe...",
	t656: "Ændring...",
	t657: "Ønsker du at slette de(n) valgte fil(er)?",
	t658: "Tilslutning Lukket, Afslut",
	t659: "De(t) valgte filnavn(e) er ulovligt/ulovlige, og download ignoreres.",
	t660: "Nogle af de valgte filers navne er ulovlige og ignoreres.",
	t661: "Filen/filerne er nu overført til PC",
	t662: "Ikke tilstrækkelig ledig diskplads på enheden.",
	t663: "Overfør fil(er)",
	t664: "Ændret",
	t665: "Kopier filer fra Flashdisk til RAM-disk",
	t666: "Vælg en Filkopieringshandling:",
	t667: "Kopier alle de konfigurerede EDF-filer fra Flashdisken",
	t668: "Kopier alle EDF-filerne fra Flashdisken",
	t669: "Edf/ed3-filerne gemmes på flash-disken i din enhed men indlæses ikke på Filstyringsskærmen.  Brug mulighederne nedenfor til at kopiere de lagrede EDF-filer i din enhed til Filstyringsskærmen.",
	t670: "Denne handling kopierer alle EDF-filer, som er blevet konfigureret i enheden fra flash-disken til RAM-disken",
	t671: "Denne Handling kopierer alle EDF-filer i enheden fra flash-disken til RAM-disken",
	t672: "Ingen gyldig EDF-fil findes",
	t673: "EDF-filkopiering færdig",
	t674: "filer kopieret",
	t675: "Bekræft download af database",
	t676: "Enhedsadresse",
	t677: "IP-adresse/værtsnavn",
	t678: "Database-filnavn",
	t679: "Vælg",
	t680: "Opdateringen er fuldført. Enheden genstarter og opgraderer. Dette kan tage flere minutter.",
	t681: "Velkommen til Opgraderingsstyringen - Trin 1 af 2",
	t682: "Velkommen til Opgraderingsstyringen - Trin 2 af 2",
	t683: "Gå videre",
	t684: "Kontrol inden Opgradering",
	t685: "Find Firmware",
	t686: "Sikkerhedskopiere Databasen?",
	t687: "Sikkerhedskopierer Databasen",
	t688: "Overførsel af Software",
	t689: "Overskrivning af Software",
	t690: "Vælg Software til Opgradering",
	t691: "Ingen fail-safe fabriksapplikation fundet.",
	t692: "Du kan fortsætte, men enhver fejl under opdateringen kan medføre, at systemet ikke længere fungerer.",
	t693: "Det anbefales på det kraftigste ikke at fortsætte.",
	t694: "Ønsker du at fortsætte?",
	t695: "Fjernbetjent opgradering af et system udgør en risiko for fejl, der kan medføre systemfejl.",
	t696: "Ansvarsfraskrivelse",
	t697: "Overførsel af databasen er færdig",
	t698: "Sikkerhedskopiering af Databasen er Færdig",
	t699: "Download af Applikation er Færdigt",
	t700: "Overskrivning af Applikation er Færdig!",
	t701: "Opgradering af Software lykkedes",
	t702: "Ønsker du at nulstille enheden til sin fabriksapplikation?",
	t703: "Tjekker hardware",
	t704: "Tjekker enhedsstatus",
	t705: "Factory Fail safe",
	t706: "Aktuel Firmware-version",
	t707: "Start Opgradering",
	t708: "Vælg en eksisterende databasefil",
	t709: "Vælg en eksisterende firmware-fil",
	t710: "Tilbage",
	t711: "Find Firmware",
	t712: "Vælg .csi-fil",
	t713: "Vælg .mai-fil",
	t714: "Fjernbetjent download understøttes ikke af denne CPU Board.",
	t715: "Download af Applikation Mislykkedes",
	t716: "Upload af databasen mislykkedes. Prøv igen",
	t717: "Overskrivning af Applikation er Færdig!",
	t718: "Overskrivning af Applikation Mislykkedes! Prøv igen.",
	t719: "Overskrivning af Applikation Mislykkedes",
	t720: "Ingen fail-safe fabriksapplikation fundet.",
	t721: "Udført",
	t722: "Den valgte applikation kan have en anden versionstype end den nuværende enheds firmware. At fortsætte handlingen kan udgøre en risiko for fejl og systemfejl.",
	t723: "De(t) valgte filnavn(e) er ulovligt/ulovlige, og download ignoreres.",
	t724: "Nogle af de valgte filers navne er ulovlige og ignoreres.",
	t725: "Unit's berg pin is missing.",
	t726: "Enhedens batteri er lavt.",
	t727: "Enhedens værtsnetværk mislykkedes.",
	t728: "Enheden er i ”load shed”-status",
	t729: "Vælg destinationsfil",
	t730: "Vælg destinationsmappe",
	t731: "Overførsel af databasen er færdig",
	t732: "Status OK",
	t733: "Status Fejl",
	t734: "Status Ukendt",
	t735: "Denne handling kan overskrive de markerede filer i destinationssimulatoren. Vil du fortsætte?",
	t736: "Download af database er færdigt. Du skal resette systemet",
	t737: "Download af database mislykkedes. Du må forsøge igen senere",
	t738: "Enheden vil automatisk blive nulstillet efter denne handling. Ønsker du at fortsætte?",
	t739: "Vælg Database",
	t740: "Download Databasefil?",
	t741: "Overfør databasefil?",
	t742: "Downloader databasen til enheden, vent venligst",
	t743: "Database er downloadet. Genstarter enheden",
	t744: "Opdatering af databasen er afsluttet. Enheden er nu resettet.",
	t745: "Opdatering af databasen mislykkedes! Prøv igen.",
	t746: "Fejl under opdatering af databasen",
	t747: "Kontrol inden Download",
	t748: "Find Database",
	t749: "Downloader databasen",
	t750: "Aktuel Databaseinformation",
	t751: "Vælg Database til download",
	t752: "Gem Database til PC (Upload)",
	t753: "Indlæs Databasen til Enheden (Download)",
	t754: "Kræver Supervisorens Godkendelse",
	t755: "Start Upload",
	t756: "Vælg en Databasehandling:",
	t757: "Start Download",
	t758: "Aktuel Firmware-version",
	t760: "Download af database lykkedes!",
	t761: "Velkommen til Databasestyringen - Trin 1 af 2",
	t762: "Velkommen til Databasestyringen - Trin 2 af 2",
	t763: "Forbindelse til enheden mislykkedes. Kontroller værts IP/URL-adresse",
	t764: "Fjernbetjent opgradering af et systems database udgør en risiko for fejl, der kan medføre systemfejl.",
	t765: "Download af databasen er færdigt",
	t766: "Ethernet Scan",
	t767: "Angiv første IP-adresse. Tryk derefter på start",
	t768: "Scan",
	t769: "Scanning",
	t770: "Historik Log Indsamling",
	t771: "Analoge diagramfarver",
	t772: "Digitale diagramfarver",
	t773: "Nedlukningstidsplaner",
	t774: "Afrimning Samlet",
	t775: "Natindstillingstidsplaner",
	t776: "Kølemøbler lys tidsplaner",
	t777: "Indlæsningsstatus",
	t778: "Alarm Log Indsamling",
	t779: "Vælg mappe til opbevaring af alarmfiler...",
	t780: "Månedlig",
	t781: "Aktivér automatisk alarmopsamling for at hente og gemme alarminformationen til en fil med regelmæssige intervaller.",
	t782: "CSV Separator",
	t783: "Ingen Alarmer",
	t784: "Henter alarmtællinger for enhed:",
	t785: "Henter alarmoplysninger fra enhed:",
	t786: "Automatisk alarmopsamling er aktiveret. Er du sikker på, at du vil lukke dette program?",
	t787: "Per time",
	t788: "Dette vil opdatere enhedens database og indstille placeringen i midten af den aktuelle visning. Vil du fortsætte?",
	t789: "logs behandlet",
	t790: "Konfigurer en sugegruppe.",
	t791: "Skal Opsætningsguiden genbruges mere end én gang, skal køledatabaseopsætningen nulstilles.",
	t792: "Gør dette via siden Konfiguration-&gt;Kontrol, og indstil antallet af Racks/Packs til nul.",
	t793: "Bemærk: Alle tidsplaner, beregninger eller importer baseret på tidligere kølingskonfiguration skal omkonfigureres.",
	t794: "Kopieringen er afsluttet.",
	t795: "Kølingsrapport",
	t796: "Færdig HVAC-opsætning",
	t797: "HVAC-opsætningen er fuldført. Tryk på ok-knappen for at afslutte denne guide.",
	t798: "Konfigurerer HVAC-opsætning...",
	t799: "Skal HVAC-opsætningsguiden genbruges mere end én gang, skal HVAC-databaseopsætningen nulstilles.",
	t800: "Gør dette via siden Konfiguration-&gt;Kontrol, og indstil antallet af HVAC-enheder til nul.",
	t801: "Bemærk: Alle tidsplaner, beregninger eller importer baseret på tidligere HVAC-konfiguration skal omkonfigureres.",
	t802: "Staging pattern",
	t803: "Trin",
	t804: "Niveau",
	t805: "Vis flere DataTip",
	t806: "Antal Trin",
	t807: "Filen kunne ikke gemmes.",
	t808: "Forkert model.",
	t809: "Indlæsningen lykkedes.",
	t810: "Åben",
	t811: "Denne funktion er kun tilgængelig i desktopversionen af StoreView.",
	t812: "Fortsæt",
	t813: "Forlad Guiden",
	t814: "Handlingen Lykkedes. Vil du gerne fortsætte, eller ønsker du at forlade guiden.",
	t815: "Fejl under hentning af alarmloggerdata.",
	t817: "Enhed Navn: ",
	t819: "I gang...",
	t820: "Alarmdata er ikke til stede.",
	t821: "Alarm antal",
	t822: "Efterlign Displaymuligheder",
	t823: "Vis kun tekst",
	t824: "Vis kun ikon",
	t825: "Vis tekst og ikon",
	t826: "1 Uge",
	t827: "2 Uger",
	t828: "3 Uger",
	t829: "4 Uger",
	t830: "Grafikindstillinger",
	t831: "Kan ikke vise alarmdiagnostik. Kontroller auto-alarmopsamling under præferencer",
	t832: "Omarrangering er ikke tilladt med de IO-racks eller kredsløb, der indgår i konfigurationen.",
	t833: "Denne Værts IP/URL-adresse findes allerede i adressebogen. Ønsker du at opdatere denne post?",
	t834: "Gennemse",
	t835: "Vælg Adressebogsmappe",
	t836: "Placering",
	t837: "Den valgte placering har ikke noget adressebogsindhold. Ønsker du at eksportere indholdet fra den eksisterende adressebog til den valgte placering?",
	t838: "Alarmhandlingskoder",
	t839: "Adressebogfilen blev ikke fundet i den valgte sti. Adressebog indlæses fra lokal...",
	t840: "Vælg en placering, hvor loggerne kan gemmes.",
	t841: "Vælg en gyldig placering, hvor loggerne kan gemmes.",
	t842: "Indsamling af alarmlog er i gang...",
	t843: "Indsamlingen af historiklog er i gang...",
	t844: "Kør i baggrunden",
	t845: "Alarmlogs status",
	t846: "Værts IP/URL-adresse må ikke være tom",
	t847: "Adressefeltet må ikke være tomt",
	t849: "Du har ikke seneste version af filen. Vil du indlæse data fra den seneste version?",
	t850: "Konflikt imellem versioner",
	t851: "Historikloggens Fremskridt",
	t852: "User Name and Password fields should not be blank.",
	t853: "IP Address field should not be blank.",
	t854: "Host IP/URL Address field should not be blank.",
	t855: "User name should not be blank.",
	t856: "Password should not be blank.",
	t857: "The SM800 you have connected to with",
	t858: "has not had the Initial Startup Wizard completed. Please complete this task and reconnect to the SM800",
	t859: "Set to now",
	t860: "Invalid input",
	t861: "Text is too long",
	t862: "Offset",
	t863: "Unable to communicate with unit(s) on host network",
	t864: "Please verify addresses are different. Configuration is updated when the save button is pressed.",
	t865: "Please select a valid History File (.hst)",
	t866: "Please select a valid History Group File (.grp)",
	t867: "Farve",
	t868: "Procentdel",
	t869: "Spænding",
	t870: "Nuværende",
	t871: "Magt",
	t872: "Frekvens",
	t873: "Koncentration",
	t874: "Flow",
	t875: "Hastighed",
	t876: "Surhedsgrad",
	t877: "Vælg diagrammer nederst i skuffen",
	t878: "Gem PNG",
	t879: "Tegne",
	t880: "Systemstatus...",
	t881: "Status for system og enhed",
	t882: "Gataway",
	t883: "Licenser til funktioner",
	t884: "Softwareversion",
	t885: "Version af browsermodul",
	t886: "Danux-version",
	t887: "Mac-adresse",
	t888: "Support & opdateringer",
	t889: "Navn på enhed",
	t890: "Tilslut",
	t891: "Mistet forbindelse",
	t892: "Der blev ikke fundet noget {{type}}-udstyr.",
	t893: "Den softwareversion, der aktuelt bruges, understøttes ikke",
	t894: "Hurtige links",
	t895: "Udstyr",
	t896: "Graftegning",
	t897: "Utilities",
	t898: "Tilbageførsel af software",
	t899: "Diagnostik",
	t900: "Der opstod en fejl",
	t901: "Der blev ikke fundet nogen {{alarms}} data",
	t902: "Indstillinger",
	t903: "Programnavn",
	t904: "Programversion",
	t905: "Besked",
	t906: "Hændelsestype",
	t907: "Tidsstempel",
	t908: "Tilbagerulningsversion",
	t909: "Aktuel version",
	t910: "Software",
	t911: "Rollback...",
	t912: "Vælg udstyr på listen",
	t913: "Kalendervisning",
	t914: "Der opstod en fejl under indlæsning af enheder, prøv igen.",
	t915: "Der opstod en fejl under indlæsning af tidsplaner.",
	t916: "Der blev ikke fundet noget udstyr",
	t917: "Planlægger eksport",
	t918: "Vælg CSV- eller PDF-eksporttype",
	t919: "Eksporter som CSV",
	t920: "Forberedelse af PDF",
	t921: "Eksporter som PDF",
	t922: "Angiv en IP i dette format xx.xx.xx.xx[:xxxx]",
	t923: "Brugernavn",
	t924: "Login",
	t925: "Rollback",
	t926: "Rollback SM800A software",
	t927: "Denne handling vil rulle al software tilbage til de angivne versioner.",
	t928: "Redaktør af enhed til fest",
	t929: "noget tekst, der forklarer funktionen",
	t930: "Opdater CDF",
	t931: "Kortenhed",
	t932: "Enhedsfil",
	t933: "1-baseret registreringsnummer",
	t934: "Lagertype",
	t935: "Min",
	t936: "Maks",
	t937: "Skrivebeskyttet",
	t938: "Log",
	t939: "Meddelelsestype",
	t940: "Nøgleord for konvertering",
	t941: "Parametre",
	t942: "Addr.",
	t943: "Prioritet",
	t944: "Bit",
	t945: "Negeret",
	t946: "Ny fil",
	t947: "Åbn fil",
	t948: "Generér fil",
	t949: "Gruppe",
	t950: "Produktversion",
	t951: "Der opstod en fejl",
	t952: "Blev ikke fundet",
	t953: "Denne enhed blev ikke fundet",
	t954: "Udenfor temp.",
	t955: "Der blev ikke fundet nogen {{equipment}}",
	t956: "Tilfælde",
	t957: "Alarmen slog lyden fra/handlingen",
	t958: "Mute/act mislykkedes for denne alarm",
	t959: "Der opstod en fejl under indlæsning af enhed(er), prøv igen.",
	t960: "Intet udstyr",
	t961: "Der blev ikke fundet noget {{type}}-udstyr",
	t962: "Konfigurer systemstyring",
	t963: "Enhed",
	t964: "Fortryde",
	t965: "Du har ændret værdien af feltet, vil du gemme ændringerne?",
	t966: "Ryd valgte historikpunkt(er)",
	t967: "Shr {{number}}",
	t968: "Ht pumpehastighed",
	t969: "Sd",
	t970: "V3hr",
	t971: "Hr reference",
	t972: "Stw {{number}}",
	t973: "Tw reference",
	t974: "Tw pumpehastighed",
	t975: "V3tw",
	t976: "Trec",
	t977: "Prec",
	t978: "Vrec OD",
	t979: "Præc reference",
	t980: "Trec reference",
	t981: "Pc",
	t982: "Ss",
	t983: "Vhp OD",
	t984: "Pgc reference",
	t985: "Ejektor OD",
	t986: "Pgc",
	t987: "Shp",
	t988: "Sgc",
	t989: "Sc3",
	t990: "Sgc ctrl.",
	t991: "Cond. kapacitet",
	t992: "Po",
	t993: "Ss",
	t994: "Sd",
	t995: "Pc",
	t996: "Løbekapacitet",
	t997: "Browser certifikat",
	t998: "Log ud",
	t1000: "Sorterer kun på {{count}}",
	t1001: "Indlæst {{count}} af {{total}} alarmer",
	t1002: "Anerkend lykkedes for alle alarmer",
	t1003: "Bekræftelse mislykkedes for alle alarmer",
	t1004: "Anerkendte kun {{count}} af {{total}} alarmer",
	t1005: "Eksporten mislykkedes for alle alarmer",
	t1006: "Eksporten lykkedes",
	t1007: "Eksporten mislykkedes. Der kræves mindst én alarmtype",
	t2000: "-",
	t2001: "Svaret er for stort til XML-transmissionsbufferen, som er på 32K bytes",
	t2002: "Ingen attributter forbundet med cmd element eller kommando har intet \"cmd\" element eller kunne ikke skrive til en variabel eller kunne ikke læse en variabel eller kunne ikke læse et punkt eller kunne ikke skrive on/off input eller relæ output driftstilstand",
	t2003: "Dårlig kommandoinput",
	t2004: "Fejl i CGI post parametre",
	t2005: "Fejl under konvertering af XML-svaret fra dets interne repræsentation til svar-XML-strengen",
	t2006: "Fejl ved afkodning af URL-streng",
	t2007: "Hukommelse overløb",
	t2008: "Komprimering af svardata mislykkedes",
	t2009: "Fejl ved parsing af anmodning",
	t2010: "Dataadgangsfejl - en række årsager, der ikke løses yderligere",
	t2011: "Udefineret kommandohandlingsattributværdi Attributværdien er ugyldig",
	t2012: "Fejl i alarmreferencenummer eller alarm ikke konfigureret",
	t2013: "Indgangscgi-parameterstrengen er for lang.",
	t2014: "Enhedsadresse blev ikke fundet",
	t2015: "Kunne ikke skrive tilladelse",
	t2016: "Ingen historik er konfigureret",
	t2017: "Ingen historikdata returneres, kan være tidsfejl",
	t2018: "Opret filfejl",
	t2019: "Læs filfejl",
	t2020: "Overskredet den maksimale størrelse på enhedslisten",
	t2021: "anvendes ikke",
	t2022: "Ugyldigt historieindeks",
	t2023: "Kunne ikke finde board point indeks fra nodetype, node, mod og point.",
	t2024: "Kunne ikke finde historieindeks",
	t2025: "Historieforespørgsel er ikke blevet initialiseret før forsøg på at starte en historieforespørgsel.",
	t2026: "En hq_start_query er blevet forsøgt, mens en historieforespørgsel er aktiv",
	t2027: "Ingen kommandoattributter fundet, når nogle forventes",
	t2028: "En begyndelse af epoken er blevet tilvejebragt uden en ende eller omvendt",
	t2029: "Kunne ikke konvertere fra tids- og datoformat til epokesekunder",
	t2030: "Kommandoen kræver et query_id, men ingen angivet",
	t2031: "Fejl ved bygning af XML-svar",
	t2032: "Det angivne query_id matcher ikke en aktiv forespørgsel",
	t2033: "En hq_get_data-kommando er blevet forsøgt, men data er endnu ikke tilgængelige",
	t2034: "Der er angivet en prøvefrekvens, som ikke er en af de tilladte",
	t2035: "Gennemsnitsintervallet er mindre end eller lig med den samplingshastighed, hvormed data er blevet registreret",
	t2036: "Stop-epoketiden er mindre end startepoketiden eller stop-epoketiden minus startepoketiden er mindre end gennemsnitsintervallet",
	t2037: "Det angivne device_id matcher ikke nogen aktuelt kendt af ak255. Bemærk, at kun enheder, der er blevet konfigureret, er kendt af ak255",
	t2038: "Ingen id-attributter",
	t2039: "Ugyldige id-attributter",
	t2040: "Intet skemanummer",
	t2041: "Ugyldigt skemanummer",
	t2042: "Ingen tidsplan detaljer",
	t2043: "Mangler on_time",
	t2044: "Manglende time",
	t2045: "Ugyldig time",
	t2046: "Manglende minut",
	t2047: "Ugyldigt minut",
	t2048: "Mangler off_time",
	t2049: "Mangler hverdage",
	t2050: "Ugyldige hverdage",
	t2051: "Mangler helligdage",
	t2052: "Ugyldige helligdage",
	t2053: "Manglende eller ugyldig feriestart",
	t2054: "Manglende eller ugyldig ferieafslutning",
	t2055: "Manglende eller ugyldig holiday_open",
	t2056: "Manglende eller ugyldig holiday_close",
	t2057: "Ferie ikke konfigureret",
	t2058: "Manglende beskrivelse",
	t2059: "Alarmen kan ikke slettes",
	t2060: "Manglende eller ugyldig store_open",
	t2061: "Manglende eller ugyldig store_closed",
	t2062: "Alarm ikke konfigureret",
	t2063: "Node offline",
	t2064: "Manglende indeks",
	t2065: "Ugyldigt indeks",
	t2066: "Relæ kan ikke tidsindstilles",
	t2067: "Navnet, der ændres, har for mange tegn. Hvis ændring af butiksnavn eller enhedsnavn skal være < = 16 tegn, hvis ændring af butiksnavn1 eller butiksnavn2 skal være < = 8 tegn.",
	t2068: "Manglende elementer knyttet til kommandoelementet.",
	t2069: "Tag_ID ikke defineret",
	t2070: "Manglende bladknude til data",
	t2071: "Ingen ændring i data",
	t2072: "Kunne ikke opdatere databasen",
	t2073: "Ikke skrivbar",
	t2074: "Skurmåler fejl",
	t2075: "Ingen meter",
	t2076: "Ugyldigt skurniveau",
	t2077: "Ugyldig type",
	t2078: "System optaget",
	t2079: "Aktiver er ikke konfigureret til load shed",
	t2080: "Ugyldig effektvurdering for aktiv",
	t2081: "Ugyldig opstartsforsinkelse for aktiv",
	t2082: "Konfig Optaget",
	t2083: "I/O-scanning i gang",
	t2084: "For mange afrimninger i gang",
	t2085: "Forsøger at indlæse en dårlig databaseversion i enheden",
	t2086: "Databaseindlæsning mislykkedes",
	t2087: "Ingen tilgængelig webhukommelse tilgængelig",
	t2088: "Efterspørgselssvar ikke konfigureret (kun AK355-systemet)",
	t2089: "Manglende eller forkert filstørrelse.",
	t2090: "Den anmodede fil kunne ikke findes på enheden",
	t2091: "Kunne ikke gemme database på RAM-disk.",
	t2092: "Filstørrelsen er for stor.",
	t2093: "Manglende filnavn.",
	t2094: "Manglende fil-id.",
	t2095: "Manglende filindlæsningsindeks (Bemærk: dette er sekvensindekset for load_file load_database).",
	t2096: "Fil-id'et stemmer ikke overens eller mangler.",
	t2097: "Filindlæsning er allerede i gang.",
	t2098: "Afkodet fil matcher ikke den originale filstørrelse.",
	t2099: "Filindlæsningshandlingen blev annulleret eller ikke startet.",
	t2100: "Ugyldig brugeranmodning (Original andmodning matcher ikke IP-adresse)",
	t2101: "Ugyldig filindlæsningssekvens.",
	t2102: "Ugyldig mappe",
	t2103: "Ugyldig forlængelse",
	t2104: "Ugyldig offset",
	t2105: "Fejl ved indkodning af fil",
	t2106: "Fejl ved afkodning af fil",
	t2107: "Kunne ikke åbne filen til læsning/skrivning",
	t2108: "Kunne ikke skrive til filen",
	t2109: "Filen er skrivebeskyttet i Flash-filsystemet",
	t2110: "Filen kunne ikke slettes.",
	t2111: "Kan ikke slette mappe.",
	t2112: "Manglende enheder",
	t2113: "Ikke alle enheder fra URL blev fundet",
	t2114: "Manglende historieparametre",
	t2115: "Ikke alle historieparametre fra URL'en blev fundet",
	t2200: "Den aktuelt brugte softwareversion ( {{currentVersion}} ) anbefales ikke",
	t2201: "Den aktuelt brugte softwareversion ( {{currentVersion}} ) anbefales ikke ( > {{recommendedVersion}} anbefales)",
	t2202: "Softwareversionsfejl",
	t2203: "Grafisk redaktør",
	t2204: "Rediger",
	t2205: "Tegne",
	t2206: "Ny udsigt",
	t2207: "Oprettet",
	t2208: "Dråbe",
	t2209: "Ny grafik",
	t2210: "Opret en ny grafisk visning",
	t2211: "Navn",
	t2212: "Omdøb",
	t2214: "Eksport",
	t2213: "Dupliker",
	t2215: "Slet",
	t2216: "Er du sikker på, at du vil slette \" {{name}} \"-visningen?",
	t2217: "Eksporter alle visninger",
	t2218: "Mere...",
	t2219: "Herske",
	t2220: "regler",
	t2221: "Regelnavn",
	t2222: "Rediger",
	t2223: "Betingelser",
	t2224: "Gradient",
	t2225: "Inverter betingelser",
	t2226: "Kortlægninger",
	t2227: "Kort former efter",
	t2228: "Form ID",
	t2229: "stater",
	t2230: "Tilføj kortlægning",
	t2231: "Tilføj regel",
	t2232: "Operatør",
	t2233: "Tilføj betingelse",
	t2234: "Start med at tilføje regler",
	t2235: "Er du sikker på, at du vil slette reglen \" {{name}} \"?",
	t2236: "Der laves en kopi af reglen",
	t2237: "Grafisk Visning",
	t2238: "Du skal oprette webstedsvisninger for at se dem",
	t2239: "Gå til grafisk editor",
	t2240: "Gå til detaljer",
	t2241: "Vælg parameter",
	t2242: "Ingen parametre fundet",
	t2243: "Muligheder",
	t2244: "On/Off-indgang",
	t2245: "Relæudgang",
	t2246: "Sensorindgang",
	t2247: "Variabel udgang",
	t2248: "Generisk enhed",
	t2249: "Tom node",
	t2250: "Logning af historik er ikke understøttet...",
	t2251: "Prøv igen",
	t2252: "Historien kunne ikke hentes",
	t2253: "Indlæsningshistorikken kan tage et stykke tid og understøtter ikke flere forespørgsler på samme tid. Prøv venligst denne anmodning igen.",
	t2254: "Parameternavn",
	t2255: "Enhedens navn",
	t2256: "Enhedstype",
	t2257: "Ikke alle historikparametre kunne indlæses ( {{n}} mislykkedes)",
	t2258: "Forkert brugernavn eller adgangskode",
	t2259: "Ingen kommunikation til system-manageren",
	t2260: "Software opdatering",
	t2261: "System",
	t2262: "Tilbageførsel af software",
	t2263: "Installere",
	t2264: "Softwaretilbageføringen vil erstatte en nyere version",
	t2265: "Rul software til backupversionen {{version}}",
	t2266: "Database",
	t2267: "En opdatering er tilgængelig",
	t2268: "Eksporter database",
	t2269: "Hent",
	t2270: "Downloader database",
	t2271: "Kan ikke eksportere databasen",
	t2272: "Database fil",
	t2999: "Kommunikationsfejl",
	t2998: "Ukendt fejl",
	t3000: "Detaljer",
	t3001: "Tidligere XML-kode her",
	t3002: "Tildel til",
	t3003: "Stat",
	t3004: "Software Administration",
	t3005: "Lokal gemt fil",
	t3006: "Online via internettet",
	t3007: "Sørg for, at strømmen ikke afbrydes under opdateringsprocessen. Det anbefales at lave en sikkerhedskopi af systemdatabasen, før du opdaterer dit system. Efter fuldførelse af softwareopdatering(er) anbefales det at inspicere systemets driftsstatus for at bekræfte korrekt kontrol og drift.",
	t3008: "Ingen filer er valgt",
	t3009: "Vælg softwarefil",
	t3010: "Uploader filer",
	t3011: "Sikkerhedskontrol",
	t3012: "Udpakker fil",
	t3013: "Kontrollerer fil",
	t3014: "Filupload mislykkedes!",
	t3015: "Sikkerhedstjek mislykkedes!",
	t3016: "Udpakningen mislykkedes!",
	t3017: "Kontrol af fil mislykkedes!",
	t3018: "Downloader fil: {{fileName}}",
	t3019: "Filen blev downloadet!",
	t3020: "Download fejlede!",
	t3021: "Upload filer",
	t3022: "Download filer",
	t3023: "OS-opdatering",
	t3024: "Edf opdatering",
	t3025: "Download filer",
	t3026: "Værdien af den valgte parameter var desværre ikke tilgængelig",
	t3027: "Importer",
	t3028: "Der kræves mindst én fil",
	t3029: "Filen er ikke gyldig",
	t3030: "Administrer grafiske visninger",
	t3031: "Upload",
	t3032: "Migrer",
	t3033: "Læser grafikfil(er), vent venligst ...",
	t3034: "Importerer grafikfil, vent venligst ...",
	t3035: "Vælg grafik",
	t3036: "Rapporter",
	t3037: "Importer database",
	t3038: "Vælg databasefil",
	t3039: "Sørg for, at strømmen ikke afbrydes under tilbagerulningsprocessen. Det anbefales at lave en sikkerhedskopi af systemdatabasen før tilbagerulning af dit system. Efter fuldførelse af softwaretilbageføring anbefales det at inspicere systemets driftsstatus for at bekræfte korrekt kontrol og drift.",
	t3040: "Systemadministratoren genstarter efter opdateringen",
	t3041: "Udvide",
	t3042: "Vis fejlretningsmeddelelser",
	t3043: "Maks. tilladte tegn -",
	t3044: "Upload venligst opdateringsfil",
	t3045: "Nuværende SW-version",
	t3046: "Nuværende Danux-version",
	t3047: "Seneste SW-version",
	t3048: "Seneste Danux-version",
	t3049: "Sikkerhedskopierer vent venligst",
	t3050: "Installerer fil, vent venligst",
	t3051: "Installerer opdatering",
	t3052: "Enheden genstarter",
	t3053: "Genstarter systemadministrator",
	t3054: "Browseren opdateres nu",
	t3055: "Software installeret",
	t3056: "Opdatering mislykkedes, følgende fejl opstod:",
	t3057: "Sikkerhedskopier den aktuelle softwareversion",
	t3058: "Sikkerhedskopier de aktuelle EDF-filer",
	t3059: "Den grafiske visning er gemt",
	t3060: "Anvend baggrundsbillede",
	t3061: "Ryd",
	t3062: "Gentag",
	t3063: "Butik",
	t3064: "Circuit nat tilbageslag",
	t3065: "Suge nat tilbageslag",
	t3066: "Downloadet version {{fileName}}",
	t3067: "For at opdatere de valgte enheder, tryk på 'Installer'",
	t3068: "{{updateType}} version",
	t3069: "Installerer",
	t3070: "Installer rollback-version",
	t3071: "Ingen af de tilgængelige enheder indeholder nogen rollback-version",
	t3072: "Ny version tilgængelig online",
	t3073: "Upload fil for udvalgte enheder",
	t3074: "Upload fil for valgt enhed",
	t3075: "Ingen rollback-versioner tilgængelige for enheden",
	t3076: "Download afsluttet",
	t3077: "Nuværende version {{updateType}}",
	t3078: "Seneste version {{updateType}}",
	t3079: "Opdatering er i gang, er du sikker på, at du vil lukke vinduet?",
	t3080: "Danux er ikke opdateret! Opdater Danux og opdater derefter softwaren igen",
	t3081: "Applikationer kan ikke nedgraderes til denne version",
	t3082: "Software Information",
	t3083: "Udgivelses noter",
	t3084: "Vilkår og privatliv",
	t3085: "Licenser",
	t3086: "Gå til konfiguration for at opsætte tidsplaner",
	t3087: "Desværre skete der en fejl under forberedelse af migreringen. Sørg for, at alle enheder er tilgængelige på netværket og korrekt konfigureret. \n\n Følgende fejl opstod:\n {{error}}",
	t3088: "Importer eller migrér en eksisterende grafik",
	t3089: "Skærm",
	t3090: "Aktivets navn",
	t3091: "Falde sammen",
	t3092: "Import færdig",
	t3093: "Udledningssikkerhed",
	t3094: "Sugesikkerhed",
	t3095: "Udledning Cutin",
	t3096: "Udledningsudskæring",
	t3097: "Sugeudskæring",
	t3098: "Suge Cutin",
	t3099: "Kapacitet",
	t3100: "Migreringsfilerne (flp, dpj eller json) kunne ikke findes",
	t3101: "Timeout-parsing-fil",
	t3102: "Tredjeparts enhedsfiler og adresser",
	t3103: "Net",
	t3104: "Låse",
	t3105: "Xml",
	t3106: "Vælg parameter, der skal vises",
	t3107: "Enhedsparameter",
	t3108: "Ingen enheder",
	t3109: "Der er ingen enhed(er) fundet eller indlæst",
	t3110: "HACCP",
	t3111: "AKTIVER",
	t3112: "Ingen haccp-data tilgængelige",
	t3113: "Enhedsoplysninger",
	t3114: "Opdateringer tilgængelige",
	t3115: "Gå til Software",
	t3116: "Ikke forbundet",
	t3117: "Det aktuelle adgangsområde for denne applikation er ' {{targetLevel}} ', den angivne bruger er over dette omfang med hensyn til adgangsrettigheder",
	t3118: "Desværre opstod der en fejl under generering af PDF'en. Prøv venligst igen eller kontakt produktsupport, hvis problemet fortsætter.",
	t3119: "PDF-eksport mislykkedes",
	t3120: "På store datasæt kan grænsefladen ikke reagere og tage et stykke tid at færdiggøre",
	t3121: "Vælg venligst tidsplaner fra listen",
	t3122: "Gå til konfiguration for at opsætte en tidsplan",
	t3123: "Udført",
	t3124: "Advarsel",
	t3125: "General Information",
	t3126: "Vælg venligst tidspunkt og datapunkter fra listen",
	t3127: "Tilslutning",
	t3128: "Forbindelse kunne ikke etableres til dette websted, kontakt venligst support",
	t3129: "Ingen HACCP-data var tilgængelige på den valgte dato. Sørg for, at historik og tid er konfigureret korrekt i konfigurationsmenuen",
	t3130: "Diagnostisk rapport",
	t3131: "Vælg enhed(er) til eksport af diagnosticeringsrapporter",
	t3132: "Ikke tilgængelig på dette brugeradgangsniveau",
	t3133: "Diagnostiklog",
	t3134: "kunne ikke downloade rapporten",
	t3135: "Skrifttype",
	t3136: "Skriftstørrelse",
	t3137: "Ingen visninger at migrere",
	t3138: "Download desktop version",
	t3139: "På grund af sikkerhedsrestriktioner i moderne browsere (",
	t3140: "RFC1918",
	t3141: ") er det desværre ikke muligt at oprette forbindelse til enheder inden for et privat netværksområde ved hjælp af ukrypteret HTTP.",
	t3142: "Der er ingen tilgængelige data for den valgte periode",
	t3143: "Konfigurer din systemadministrator til at bruge HTTPS eller brug desktopversionen.",
	t3144: "Vi kunne ikke kommunikere med systemadministratoren. Dette betyder enten, at din enhed ikke kan oprette forbindelse til IP eller værtsnavnet på systemadministratoren, eller at du muligvis manuelt skal have tillid til det SSL-certifikat, der bruges på enheden.",
	t3145: "Klik på følgende link for at åbne en ny fane for at stole på certifikatet og vende tilbage til denne skærm.",
	t3146: "Underskrift",
	t3147: "DI - digitale indgange",
	t3148: "AI - Analog Indgang",
	t3149: "Gå til HACCP-konfiguration",
	t3150: "Ingen data",
	t3151: "Sikkerhedskopien gemmes i systemadministratoren for funktionen Rollback",
	t3152: "Vælg, hvad der skal vises i webstedsvisning(er)",
	t3153: "Måleenhed",
	t3154: "Grafiske filer",
	t3155: "Desværre skete der en fejl under hentning af edf-filerne og versionerne fra systemadministratoren...",
	t3156: "For at vælge, hvilke filer der skal installeres, skal du trykke på knappen 'Vælg filer'",
	t3157: "Vælg filer",
	t3158: "Fjerner backup",
	t3159: "Iscenesættelse af filer",
	t3160: "Sikkerhedskopien gemmes i systemadministratoren for funktionen Rollback",
	t3161: "Installeret størrelse",
	t3162: "Ny størrelse",
	t3163: "Installationsdato",
	t3164: "Ny dato",
	t3165: "Sensorer",
	t3166: "Relæer",
	t3167: "Indgange",
	t3168: "Outs",
	t3169: "Meter",
	t3170: "Generisk",
	t3171: "Adgangskoden er udløbet. Indtast venligst ny adgangskode",
	t3172: "Nyt kodeord",
	t3173: "Bekræft kodeord",
	t3174: "Skift kodeord",
	t3175: "Adgangskoden skal indeholde mindst 8 tegn 1 stort bogstav, 1 lille bogstav, 1 tal, 1 specialtegn)",
	t3176: "Kodeordene er ikke ens",
	t3177: "Adgangskoden kan ikke være den samme som den gamle adgangskode",
	t3178: "Adgangskodeændring lykkedes",
	t3179: "Adgangskoden kan ikke ændres. Fejl fra enhed",
	t3180: "Nulstil kodeord",
	t3181: "Genereret",
	t3182: "Side navn",
	t3183: "Begivenheds-id",
	t3184: "Tid",
	t3185: "Rolle",
	t3186: "Event-information",
	t3187: "Godkend valgte alarmer",
	t3188: "Kvitter alle valgte alarmer",
	t3189: "Importer database til",
	t3190: "Importerer database",
	t3191: "Filtypen skal være .zip",
	t3192: "Vælg fil",
	t3193: "Åbningstider",
	t3194: "Helligdage",
	t3195: "Til/fra tidsplaner",
	t3196: "Aktiv",
	t3197: "Du har ikke-gemte ændringer. Er du sikker på, at du vil tage afsted?",
	t3198: "Desværre mislykkedes migreringen. Systemet er i øjeblikket optaget. Prøv igen senere",
	t3199: "Upload billede tilpasning til aktuelle dimensioner",
	t3200: "Udskift baggrundsbillede",
	t3201: "Erstat baggrundsbilleder med skalerbar vektorgrafik.",
	t3202: "Kun SVG-filer med samme navn som de eksisterende filer erstattes.",
	t3203: "Vælg fil(er), der skal erstattes",
	t3204: "Billedfiler",
	t3205: "kan ikke behandles. Filen har et ugyldigt format.",
	t3206: "{{amount}} fil(er) blev erstattet",
	t3207: "{{amount}} fil(er) kunne ikke erstattes",
	t3208: "Udsigten har ingen baggrund",
	t3209: "Du kan uploade et nyt eller erstatte et eksisterende baggrundsbillede",
	t3210: "Hvis du udskifter et baggrundsbillede, kan parameterpositionerne bevares, hvis:",
	t3211: "En png- eller jpg-fil har samme opløsning som den aktuelle baggrund",
	t3212: "En svg-fil har samme billedformat (men ikke nødvendigvis den samme opløsning)",
	t3213: "Funktionen er deaktiveret",
	t3214: "Viser i øjeblikket sidst modtagne værdi, opdaterer...",
	t3215: "Upload en grafisk fil, der er oprettet i",
	t3216: "(valgfrit)",
	t3217: "Baggrundsbillede",
	t3218: "Gennemse",
	t3219: "Mere...",
	t3220: "Tilføj et baggrundsbillede (Option)",
	t3221: "Grafisk fil",
	t3222: "Timeout i kommunikation",
	t3223: "Ingen køleplaner er konfigureret",
	t3224: "Indeks",
	t3225: "Type",
	t3226: "Helligdage",
	t3227: "Dage",
	t3228: "På",
	t3229: "Fra",
	t3230: "ID",
	t3231: "Åben",
	t3232: "Start",
	t3233: "Slut",
	t3234: "Beskrivelse",
	t3235: "Dag",
	t3236: "Luk",
	t3237: "Edf filer blev uploadet. Enheden genstartes nu",
	t3238: "Edf fil(er) upload færdig, enheden er nu genstartet",
	t3239: "Fejl under hentning af edf-fil(er) fra enheden",
	t3240: "Upload af Edf-fil(er) mislykkedes. Vent et øjeblik og prøv igen",
	t3241: "Ingen tidsplaner konfigureret",
	t3242: "Upload fil til enheden",
	t3243: "Angiv enhedstypen - påvirker de nødvendige parameteretiketter for at betjene den",
	t3244: "Angiv, hvordan enhedens værdier tilgås, og hvilken funktion de opfylder",
	t3245: "Opret en alarm baseret på en bit af en parameter",
	t3246: "Etiketter",
	t3247: "Opret en liste over etiketter, der kan tildeles en parameter",
	t3248: "Grupper",
	t3249: "Angiv parametergrupper",
	t3250: "Registernummer",
	t3251: "Datatype",
	t3252: "Liste over etiketter",
	t3253: "Minimum",
	t3254: "Standard",
	t3255: "Maksimum",
	t3256: "Registertype",
	t3257: "Registertypen er oversat til tilsvarende funktionskoder for læsning og skrivning",
	t3258: "Skift tilstand",
	t3259: "For en værdi med bytes ABCD sendt til enheden bytter denne indstilling bytes (BADC), ord (CDAB) eller begge dele (DCBA).",
	t3260: "Skrivebeskyttet",
	t3261: "Logning",
	t3262: "Aktiverer historiklogning for denne parameter",
	t3263: "Overtag tekst fra parameter til alarm",
	t3264: "Position af alarmflaget tæller fra den mindst signifikante bit af parameteren",
	t3265: "Udløs alarm ved lav værdi",
	t3266: "Etiket",
	t3267: "Skjul",
	t3268: "{{field}} er et obligatorisk felt.",
	t3269: "Maksimum skal være større eller lig med minimum",
	t3270: "Standardværdien skal være indenfor [Minimum, Maksimum]",
	t3271: "Datatypen skal være {{reg}} for den valgte registertype {{mt}}",
	t3272: "Navn må ikke indeholde afgrænsningstegn",
	t3273: "Navnet må ikke overstige 20 tegn",
	t3274: "Listen over etiketter må ikke være tom",
	t3275: "Tagget/taggene {{taglist}} skal tildeles præcis én gang.",
	t3276: "Gruppen/grupperne {{grouplist}} indeholder mere end de maksimale 50 parametre.",
	t3277: "Formularen indeholder fejl. Gennemgå venligst fejlene og prøv igen.",
	t3278: "Send",
	t3279: "For at aktivere den uploadede EDF skal du nulstille enheden. Vil du nulstille enheden nu?",
	t3280: "Genstart nu",
	t3281: "Reset senere",
	t3282: "{{file}} eksisterer allerede. Vil du overskrive den?",
	t3283: "Upload {{what}}",
	t3284: "Etiketter og værdier skal være unikke.",
	t3285: "Multi",
	t3286: "Enheden håndterer flere kølemøbler eller pakker",
	t3287: "Tags",
	t3288: "Internet Explorer er ikke længere supporteret og kan ikke bruges med StoreviewWeb",
	t3289: "Skift venligst til en understøttet browser, enten Edge, Chrome eller Firefox",
	t3290: "Mere information",
	t3291: "Luk",
	t3292: "Kunne ikke læse data, prøv igen",
	t3293: "EDF Backup er igang",
	t3294: "Der er ingen visninger dat migrere",
	t3295: "Systemet er konfigureret med en offentlig IP i det private IP-felt",
	t3296: "Enheden er konfigureret med en privat IP i feltet til ekstern internet, systemet er muligvis ikke tilgængeligt eksternt og nogle funktioner virker måske ikke korrekt",
	t3297: "Du er forbundet gennem en IP eller et domæne som ikke er kendt af enheden, formentlig gennem en proxy-service, nogle funktioner virker måske ikke korrekt",
	t3298: "Enheden er offline, viser sidst modtagne værdi",
	t3299: "Integration af enheder",
	t3300: "Tagget/taggene {{taglist}} er duplikeret.",
	t3301: "Side ikke fundet",
	t3302: "Desværre! Den side, du leder efter, findes ikke",
	t3303: "Fejlkode: 404",
	t3304: "Gå til dashboard",
	t3305: "Forbundet IP/Domæne",
	t3306: "Intern IP-adresse",
	t3307: "Ekstern IP-adresse",
	t3308: "Domænenavn",
	t3309: "Ingen advarsler",
	t3310: "Flere advarsler",
	t3311: "Det ser ud til, at systemet er konfigureret med flere enheder, men vi var ikke i stand til at kontakte en eller flere af slave-enhederne. Sørg venligst for at indstillingerne er korrekte. Bemærk venligst, at en multi-unit opsætning ikke understøtter flere domænenavne, og at hver enhed skal konfigureres med en særskilt port, når du bruger det samme domæne",
	t3312: "Kun visning",
	t3313: "Dette element har ingen tilknytning til nogen parameter og kan sikkert fjernes",
	t3314: "Fjern parameter",
	t3315: "Tidsplan",
	t3316: "Du bliver nu logget ud og skal logge på med den nye adgangskode",
	t759: "Database downloadet, nulstillet og opgraderet enhed. Dette kan tage flere minutter.",
	t816: "Alarmtype:",
	t818: "Antal:",
	t848: "DB-fil er ikke oprettet. Du har begrænset adgang til den installerede mappe. Kontakt venligst systemadministratoren.",
	t3317: "Ingen tidsplaner at eksportere",
	t3318: "Afventer svar fra systemet",
	t3319: "Ingen aktivitet",
	t3320: "Butiksnavn",
	t3321: "Ny version {{latestVersion}} tilgængelig online",
	t3322: "Ekstern Port",
	t3323: "Intern port",
	t3324: "Vi kan ikke kommunikere med systemadministratoren på",
	t3325: "måske certifikatet skal have tillid til?",
	t3326: "Fil i dette format kan ikke fjernes",
	t3327: "Brug",
	t3328: "Tænd tidspunkt",
	t3329: "Off Time",
	t3330: "Hverdage",
	t3331: "Afkølingsstadie",
	t3332: "Varmegenvinding",
	t3333: "Køling",
	t3334: "Varme",
	t3335: "Aux varme",
	t3336: "Affugtning",
	t3337: "Internet",
	t3338: "Beskeder",
	t3339: "Kontaktpersoner",
	t3340: "Comm",
	t3341: "Ingen varer fundet",
	t3342: "Skriv for at søge",
	t3408: "Aktiver alle",
	t3409: "Deaktiver alle",
	t3410: "Normal",
	t3411: "Alvorlig",
	t3412: "Kritisk",
	t3413: "Aktiver alle alarmer",
	t3414: "Søg efter alarmer",
	t3415: "Aktiveret",
	t3417: "Fejl under hentning af alarmer",
	t3418: "Fejl under hentning af enheder til kopiering",
	t3419: "Fejl under kontrol af kopistatus",
	t3420: "Automatisk polling genoptages efter maks. 2 timers timeout, eller hvis guiden annulleres eller afsluttes",
	t3421: "Kopiér til controller(e)",
	t3422: "Kopierer til den(e) valgte enhed(er)",
	t3423: "Kopieringsfunktionen kopierer først indstillingerne til System Manager-databasen og downloader derefter indstillingerne til controllerenhederne. Det kan tage noget tid at fuldføre handlingen.",
	t3424: "Der er ikke valgt nogen enheder til at kopiere",
	t3425: "Dobbeltklik for at kopiere",
	t3426: "Fejl under hentning af enheder",
	t3427: "Enheder til kopiering",
	t3428: "Vælg enhed(erne) til kopiering",
	t3429: "Kun kontrolenheder af samme type vil blive vist her",
	t3430: "Kopieringsprocessen lykkedes",
	t3431: "Ugyldig fil. Forventet filmodel:",
	t3432: "Fejl under indlæsning af indstillingsfil",
	t3435: "Er du sikker på at du vil flytte den valgte enhed til denne gruppe {{toPackGroup}} ?",
	t3343: "Opdagelse",
	t3344: "Opdater tabel",
	t3345: "Scanner netværk, vent venligst...",
	t3346: "Netværkstabellen er opdateret",
	t3347: "Fejl under opdatering af netværk",
	t3348: "Fejl under genscanning af netværk",
	t3349: "Fejl under hentning af netværk",
	t3350: "Netværksscanning afsluttet",
	t3351: "Ingen tilgængelige enheder",
	t3352: "Skan netværk",
	t3353: "Uncfg",
	t3354: "Enhver omarrangering af enheder i guiden kan påvirke Alsense-overvågningstjenesterne.",
	t3355: "Netværk",
	t3356: "Vælg feltbustype",
	t3357: "Hvis Modbus/RS485 er aktiveret, kan du vælge SLV for Danfoss kompressor med variabel hastighed. Når du vælger SNMP som en lokal bus, bliver du bedt om at indtaste et gyldigt subnet-adresseområde af Danfoss SNMP-kontroller",
	t3358: "Kompressorer/pakker",
	t3359: "Administrer kompressorer/pakker controllere",
	t3360: "Kun Pack-enheder kan administreres her - ikke Rack I/O-system. Kompatible Multi-Suction Pack-controllere, der er blevet scannet, vil til at begynde med vises som 0 sugegrupper. AK-ST 500 (serviceværktøj) er påkrævet for at konfigurere pakkecontroller.",
	t3361: "Beløb, der skal kopieres",
	t3362: "Reduktion af tidligere konfigureret sugegruppe vil også fjerne eventuelle tilknyttede fordampningskontroller for den pågældende sugegruppe.",
	t3363: "Antal sugegruppe kan ikke være nul, minimum antal. af sugegruppen skal være mindst én.",
	t3364: "Fejl under hentning af kompressorer/pakker",
	t3365: "Ingen kompressorer/pakker tilgængelige",
	t3366: "On-line enheder",
	t3367: "Off-line enheder",
	t3369: "Tilføj pakke",
	t3370: "Hvis du vælger Modbus/RS485, vil du se yderligere muligheder for SLV/ECFan, disse bruges til Køling og kan vælges til Nej.",
	t3371: "Tilføj kredsløb",
	t3372: "Tilføj, kopier eller fjern enheder. Bemærk, at ikke alle enhedstyper tillader redigeringer af alle felter, og adresserne på I/O-punkter er indstillet i konfigurationen.",
	t3373: "Administrer sagscontrollere",
	t3374: "Tilføj en enhed",
	t3375: "Administrer kompressor/pakke-controllere",
	t3376: "Fejl i dataadgang",
	t3377: "HVAC Layout er blevet anvendt",
	t3378: "Indretning VVS",
	t3379: "Er du sikker på, at du vil forlade denne side?",
	t3380: "Dine indstillinger kan gå tabt",
	t3381: "Konfigurer venligst en enhed",
	t3382: "Afslut Quick Setup",
	t3383: "Er du sikker på, at du vil afslutte Hurtig opsætning?",
	t3384: "Gateways (AK-PI200)",
	t3385: "Vælg de fundne gateways",
	t3386: "IP-adresse",
	t3387: "Kilde controller",
	t3388: "Kildeindstillinger",
	t3389: "Kilde alarmer",
	t3390: "Kilde graf",
	t3392: "Grafer",
	t3393: "Devices",
	t3394: "Auto polling afbrydes under kopieringsfasen. Hovedafbryderen på kilden og målenhed(erne) vil blive slukket og derefter returneret til den oprindelige tilstand, når kopieringen er færdig. Sørg for at kontrollere hovedafbryderen, når kopieringsguiden er færdig. Hvis nogen af de valgte enheder er i aktiv alarm, vil enhver ændring af alarmhandlingen for det pågældende punkt ikke blive ændret. Den oprindelige indstilling forbliver.",
	t3395: "Vælg en kildeenhed at kopiere indstillinger fra",
	t3396: "Til tiden",
	t3397: "Kopieringsfunktionen kopierer tilgængelige grafpunkter, indtil enhedens kapacitet er nået. Gå til Konfiguration for at se antallet af definerede punkter",
	t3398: "Tegning af kildeenheder",
	t3399: "Konfigurer grafpunkter for kildecontrolleren",
	t3400: "Aktiver HACCP for at logge punkterne i en HACCP-temperaturrapport",
	t3401: "Søg efter point",
	t3402: "Ingen tilgængelige data",
	t3403: "Fejl under hentning af grafpunkter",
	t3404: "Deaktiveret",
	t3405: "Kildeenhedsalarmer",
	t3406: "Konfigurer alarmdetaljer i kildecontrolleren",
	t3407: "Alarm navn",
	t3436: "Enheden vil blive fjernet fra den tildelte gruppe [ {{fromPackGroup}} ]",
	t3437: "Layout Køling",
	t3438: "Kølelayoutet er blevet anvendt",
	t3439: "Gentildel",
	t3440: "Tildel sagskontroller til at pakke kontrolelementer",
	t3441: "Tildel kassestyringer (fra tilgængelige kasser) til kompressor-/pakkestyringer. danner sugegrupper",
	t3442: "Enhedsparametre",
	t3443: "Vælg parametre til kopiering",
	t3444: "Når du arbejder på en live (online) enhed, kan der være en forsinkelse i indstillingerne på grund af kommunikation til enheden.",
	t3445: "Vælg alarmer til kopiering",
	t3446: "Enhedsgraf",
	t3447: "Vælg grafpunkter til kopi",
	t3448: "Gem aktuelle enhedsindstillinger i en fil",
	t3449: "Du kan gemme de aktuelle enhedsindstillinger i en fil, der kan bruges til at konfigurere enheder på et senere tidspunkt. Hvis du ønsker at afslutte guiden efter filen er gemt, skal du bruge exit-ikonet. Hvis du arbejder med tilsluttede enheder, vil afslutning sikre netværkspoling, og enhedens hovedafbryder vender tilbage til korrekt status.",
	t3450: "Indstillingsfil(er) blev gemt",
	t3451: "Vil du fortsætte eller afslutte Quick Setup?",
	t3452: "Gemmer fil(er)",
	t3453: "Fejl under hentning af indstillinger",
	t3454: "Indstillinger for kildeenheden",
	t3455: "Konfigurer enhedsparametre i kildecontrolleren",
	t3456: "Ingen af adgangskodefelterne må være tomme",
	t3457: "Seneste",
	t3458: "Adressebog",
	t3459: "Sidst tilgået",
	t3460: "Tag en sikkerhedskopi af adressebogen med jævne mellemrum.",
	t3461: "Adressebog blev importeret",
	t3462: "Importer Adressebog",
	t3463: "Eksporter Adressebog",
	t3464: "Tilføj site",
	t3465: "Rediger websted",
	t3466: "Slet websted",
	t3467: "Du skal oprette en adgangskode til adressebogen, før du begynder at bruge adressebogen",
	t3468: "Webstedet er blevet tilføjet til din adressebog",
	t3469: "Allerede i adressebogen",
	t3470: "Føj til adressebog",
	t3471: "Log ind på siden",
	t3472: "Siteinfo er blevet opdateret",
	t3474: "Notifikation",
	t3475: "Webstedet er blevet fjernet fra din adressebog",
	t3476: "Siden er blevet opdateret",
	t3477: "Ugyldigt adressebogsformat",
	t3478: "Handlinger",
	t3479: "Ikke tilgængelig, når du er offline",
	t3480: "Kunne ikke indlæse S55-databasen",
	t3481: "Indlæser S55 database",
	t3482: "Protokol",
	t3483: "Brugeren har ikke tilladelse til at se softwareoplysninger",
	t3484: "Adgangskoden skal kun være alfanumerisk eller numerisk",
	t3485: "Du skal oprette en adgangskode til adressebogen for at få adgang til adressebogen",
	t3486: "Adgangskoden til adressebogen er ikke korrekt",
	t3487: "Webstedet er allerede til stede i adressebogen",
	t3488: "Opret en Address Book-adgangskode for at bruge Address Book. Adgangskoden skal kun være alfanumerisk eller numerisk",
	t3489: "Indtast adgangskoden til din adressebog",
	t3490: "Der er ingen online-enheder, der kan kopieres",
	t3491: "Opret adgangskode til adressebog",
	t3492: "Opdater adgangskoden til adressebogen",
	t3493: "Webstedets adgangskode må ikke være tomt",
	t3494: "Indtast en adgangskode til den adressebog, der importeres",
	t3495: "Adgangskoden til adressebogen blev oprettet",
	t3496: "Adgangskoden til adressebogen blev opdateret",
	t3497: "Adgangskoden til den adressebog, der importeres, er ikke korrekt",
	t3498: "Indtast venligst webstedets adgangskode",
	t3499: "Ryd adressebog",
	t3500: "Søg på websteder",
	t3501: "Vælg adressebogsfil for at importere",
	t3502: "Vælg nøglefil for at dekryptere adressebogen",
	t3503: "Tryk på eksport for at downloade den krypterede adressebog",
	t3505: "Med krypteret",
	t3506: "ukrypterede adgangskoder",
	t3507: "Ugyldig hemmelig nøgle",
	t3508: "Importfejl. Sørg for, at du har importeret den rigtige nøgle",
	t3509: "Ingen tilgængelige versioner",
	t3510: "Åbn Alsense Services (kræver abonnement)",
	t3511: "Gennemgå venligst EULA-vilkårene omhyggeligt. Hvis du ikke kan acceptere vilkårene, må du ikke bruge applikationen.",
	t3512: "Adressebog og adgangskode til din adressebog blev ryddet",
	t3513: "(påkrævet)",
	t3515: "Adressebogen er tom",
	t3516: "Adgangskoden til adressebogen må ikke være tom",
	t3519: "Når du rydder adressebogen, vil du også fjerne adgangskoden til din adressebog.",
	t3520: "Ikke inden for rækkevidde. Skal være mellem 0 og {{maxAddr}}",
	t3521: "Tryk på 'Eksporter' for at downloade alle grafiske visninger gemt i System Manager",
	t3522: "Tryk på 'Download' for at downloade en systemrapport",
	t3523: "Kun masterenheden er tilgængelig på en direkte WiFi-forbindelse",
	t3524: "Der er ingen lokale grafiske visninger. Tilføj nye eller importer eksisterende visninger",
	t3525: "Der er ingen eksterne grafiske visninger. Prøv at søge med et andet navn",
	t3526: "Der er ingen eksterne grafiske visninger. Tilføj nye, importer eller migrer eksisterende visninger",
	t3527: "Lokale visninger ( {{totalFiles}} af 5)",
	t3528: "{{totalFiles}} lokal grafik ud af maksimalt 5",
	t3529: "Fjernvisninger",
	t3530: "Filen er ikke en bitmap.",
	t3531: "Bitmaplængden er forkert.",
	t3532: "Bitmap-headerstørrelsen er forkert.",
	t3533: "Bitmapstørrelsen er forkert.",
	t3534: "Fejl i paletstørrelse.",
	t3535: "Inkompatibel .bmp-fil",
	t3536: "Fejl under sletning af lokal grafikfil",
	t3537: "Fejl under duplikering af lokal grafikfil",
	t3538: "Fejl under eksport af lokal grafikfil",
	t3539: "Slet grafik",
	t3540: "Slet en lokal grafikfil",
	t3541: "Sletning af lokal grafikfil er i gang",
	t3542: "Lokal grafikfil blev slettet. Genstarter System Manager",
	t3543: "Sletning af lokal grafikfil afsluttet. Systemadministrator blev genstartet",
	t3544: "Dublet grafik",
	t3545: "Dupliker en lokal grafikfil",
	t3546: "Lokal grafikfil duplikat er i gang",
	t3547: "Lokal grafikfil blev duplikeret. Genstarter System Manager",
	t3548: "Lokal grafikfil duplikat færdig. Systemadministrator blev genstartet",
	t3549: "Eksporter grafik",
	t3550: "Eksporter en lokal grafikfil",
	t3551: "Eksport af lokal grafikfil er i gang",
	t3552: "Lokal grafikfil blev eksporteret",
	t3553: "Systemadministratoren genstarter ved denne handling",
	t3554: "Er du sikker på, at du vil slette",
	t3555: "Er du sikker på, at du vil duplikere",
	t3556: "Tryk på 'Eksporter' for at downloade alle lokale grafiske visninger gemt i System Manager",
	t3557: "Eksport af lokale grafikfiler er i gang",
	t3558: "Lokale grafikfiler blev eksporteret",
	t3559: "Varenummer",
	t3560: "Få mere information om dette produkt",
	t3561: "Fan øko",
	t3562: "Ventilåbning",
	t3563: "Overhedning",
	t3564: "Fordampertryk",
	t3565: "Fordampertemperatur",
	t3566: "Udskæring",
	t3567: "Cutin",
	t3568: "Sagsdør",
	t3569: "Sugetemperatur",
	t3570: "Skinnevarme",
	t3571: "Den maksimale tilladte filgrænse er nået. Fravælg fil(er) til import",
	t3572: "Slet venligst eksisterende fil(er) i systemadministrator for at importere filer",
	t3573: "Lokal grafikfil blev importeret. Genstarter System Manager",
	t3574: "Import af lokale grafikfiler er fuldført. Systemadministrator blev genstartet",
	t3575: "Den maksimale tilladte filgrænse er nået. Slet fil(er) for at importere",
	t3576: "Maksimalt tilladte grafikfiler er 5",
	t3577: "Lokal grafikfil blev uploadet. Genstarter System Manager",
	t3578: "Upload af lokal grafikfil afsluttet. Systemadministrator blev genstartet",
	t3579: "Ugyldigt filformat. upload {{supportedFileFormats}} fil",
	t3580: "Fildimension skal være {{maxWidthAllowed}} x {{maxHeightAllowed}} pixels for lokale skærme",
	t3581: "Fjernvisning",
	t3582: "Lokal udsigt",
	t3583: "Storeview-hjemmeside",
	t3584: "EULA vilkår",
	t3585: "Sikkerhed",
	t3586: "Sikkerhedshåndhævende niveauer",
	t3587: "Bagudkompatibel (laveste sikkerhedsniveau - forudsat at brug af header-godkendelse er deaktiveret)",
	t3588: "SvW / XML-anmodninger indeholder brugernavn og adgangskode.",
	t3589: "Ingen desinficering af anmodninger, samme sikkerhed som tidligere udgivelser",
	t3590: "Ingen ændringer nødvendige på SvW eller eksisterende XML-forbindelser",
	t3591: "Tilladende (kan bruges som overgang til strengt niveau)",
	t3592: "XML-anmodninger kan indeholde brugernavn og adgangskode og eller et sessionstoken. Sessionstokenet modtages som svar på login-anmodningen.",
	t3593: "Hvis sessionstokenet er angivet i AKSM-auth-headeren, ignoreres brugernavne og adgangskoder",
	t3594: "XML-anmodning bliver renset, en advarsel returneres i svaret på anmodningen, hvis der opdages problemer",
	t3595: "Bemærk disse advarsler som forberedelse til at skifte til streng tilstand",
	t3596: "Hvis sessionstokenet ikke bruges, kan sessionsudløb ikke anvendes",
	t3597: "Allerede understøttet i remote UI (SvW)",
	t3598: "Mulige/påkrævede ændringer fra 3. parts XML-perspektiv",
	t3599: "Ingen brug af sessionstoken - ingen ændringer nødvendige",
	t3600: "Brug af sessionstoken - fjern brugernavn/adgangskode fra brødteksten og observer advarsler som forberedelse til Strict",
	t3601: "Strenge (højeste sikkerhedsniveau)",
	t3602: "XML-anmodninger kan ikke indeholde brugernavne og adgangskoder, men skal angive sessionstokenet i AKSM-auth-headeren. Hvis et problem opdages i anmodningen, bliver det afvist",
	t3603: "Allerede understøttet i (SvW)",
	t3604: "Nødvendige ændringer fra 3. parts perspektiv",
	t3605: "brugernavn og adgangskode skal fjernes fra XML-anmodningsnyttelasten, og sessionstokenet skal placeres i en CORS-header kaldet AKSM-auth",
	t3606: "Login Fejl Back-off",
	t3607: "Hvor længe (sekunder) en fjernbruger venter på at kunne prøve igen at logge ind efter et mislykket forsøg. Ventetiden stiger kumulativt for hvert mislykket forsøg. Efter 10 forsøg er den IP, som brugeren forsøgte at logge ind fra, blokeret i 24 timer. Hvis indstillet til 0 Login-fejl, er back-off deaktiveret",
	t3608: "Standard 30s (maks. 600s / 0 = deaktiveret)",
	t3609: "Gratis forsøg",
	t3610: "Hvor mange loginforsøg er tilladt, før back-off anvendes",
	t3611: "Standard 2 (maks. 5)",
	t3612: "Blok",
	t3613: "Hvis aktiveret, blokeres bruger + IP under back-off, ellers er brugeren blokeret",
	t3614: "Standard 'Bruger' (Bruger + IP / Bruger)",
	t3615: "Sessioner pr. bruger",
	t3616: "En session er, hvor en bruger er logget ind på enheden og er inden for fjerngrænsefladen (SvW)",
	t3617: "Antal aktive sessioner en bruger kan have. Hvis indstillet til 0, kan en bruger have det maksimale antal sessioner (30)",
	t3618: "Standard 0",
	t3619: "Session udløb",
	t3620: "Hvor mange sekunder efter en inaktiv session udløber. Hvis en session udløber, er et nyt login nødvendigt",
	t3621: "Standard 900s (maks. 3.600)",
	t3622: "Overskriftsgodkendelse",
	t3623: "Når du aktiverer HTTPS og Header Authentication, skal alle tredjeparts XML-anmodninger sende brugernavn/adgangskode-legitimationsoplysninger i headeren (grundlæggende godkendelsesskema)",
	t3624: "Aktivering af 'Brug header-godkendelse' kræver, at systemet geninitialiseres. Brug knappen 'Tryk for at initialisere' for at udføre denne handling",
	t3625: "Lille",
	t3626: "Stor",
	t3627: "Gem grafik",
	t3628: "Gem en lokal grafikfil",
	t3629: "Lokal grafikfil blev slettet. Indlæsning af lokal grafikfil er i gang",
	t3630: "Den lokale grafikfil blev indlæst. Genstarter System Manager",
	t3631: "Gem af lokal grafikfil afsluttet. Systemadministrator blev genstartet",
	t3632: "Er du sikker på, at du vil gemme?",
	t3633: "Parsing mislykkedes",
	t3634: "Kunne ikke parse viz-fil",
	t3635: "Kunne ikke parse vz2-fil",
	t3636: "Åbning af Viz-fil mislykkedes:",
	t3637: "Migreringsfilerne (vz2, dpj eller json) kunne ikke findes",
	t3638: "Migreringsfilerne (dvs.) kunne ikke findes",
	t3639: "Systemadministratoren genstarter ved denne handling. Systemadministratoren er i enten tilladelig eller streng tilstand, så sessionstokenet udløber ved genstart, og du vil blive logget ud af systemet",
	t3643: "Udledningstryk",
	t3644: "Total kW",
	t3645: "Samlet KVAR",
	t3646: "Total Kvarh+",
	t3647: "Efterspørgsel",
	t3648: "Effektfaktor",
	t3649: "Skriftfarve",
	t3650: "Baggrundsfarve",
	t3651: "Adressebog blev eksporteret",
	t3652: "Kontroltilstand",
	t3653: "Sc3 Udetemperatur",
	t3654: "Afgangstemperatur",
	t3655: "Kondensatordriftskapacitet",
	t3656: "Ingen data skal eksporteres",
	t3657: "Brugerens adgangskode på {{unit}} er udløbet",
	t3658: "Kan ikke logge ind på {{unit}} med de angivne legitimationsoplysninger.",
	t3659: "Opdater firmwareversion, eller skift sikkerhedshåndhævelsesniveau for {{unit}} for at få support for sessionstoken.",
	t3661: "V3gc",
	t3662: "Afsnit B",
	t3663: "Afsnit C",
	t3664: "Adaptiv overhedningskontrol",
	t3665: "Årsag til kontrolstatus",
	t3666: "Brugeren kan ikke logges på. Enhedsadressen er ikke angivet i proxy-konfigurationen",
	t3667: "Kommunikationsprotokol er ikke angivet i proxy-konfigurationen. Det kan føre til fejl",
	t3668: "IT-sikkerhedsoplysninger",
	t3669: "I overensstemmelse med sikkerhedsstandarder indstiller System Manager nu som standard Strikt sessionskontrol, hvilket betyder, at enhver fjernforbindelse/XML-baseret fjerntjeneste ikke længere fungerer, medmindre sessionstokens anvendes.",
	t3670: "Danfoss anbefaler at fortsætte i streng tilstand, men bagudkompatibilitetstilstand er stadig tilgængelig.",
	t3671: "Bemærk, dette kan ændres i menuen Configuration Security og har ingen indflydelse på systemadministratorens lokale kontrol og drift af webstedet.",
	t3672: "Se flere oplysninger om sessionskontrol",
	t3673: "Baglæns tilstand",
	t3674: "Strenge tilstand",
	t3675: "Når du klikker på bagudkompatibel tilstand, vil alle relevante systemadministratorer med opdateret firmware blive indstillet til bagudkompatibel tilstand, genstartet, og applikationen vil blive logget ud",
	t3676: "Max antal tilladte sugegrupper er 12",
	t3677: "Efter vellykket opdatering af de valgte enheder fra 3.3.0 til 4.X eller nyere, vil brugeren automatisk blive logget ud ved klik på knappen Udfør i det sidste trin.",
	t3678: "Multi-etui til AK2-controllere (Pack/Case) understøttes ikke",
	t3679: "I overensstemmelse med sikkerhedsstandarder introduceret med release 4.0, vil System Manager som standard bruge Strict session control, hvilket betyder, at enhver fjernforbindelse/XML-baseret fjerntjeneste ikke længere fungerer, medmindre sessionstokens anvendes. Udover det vil kommunikationen automatisk blive justeret til HTTPS ved at opgradere til rel. 4.0. Danfoss anbefaler at fortsætte i Strict-tilstand, men bagudkompatibilitetstilstand er tilgængelig, som vil sætte systemet tilbage til de indstillinger, der er anvendt indtil nu. Bemærk venligst, at hvis du laver denne opdatering eksternt på en HTTP-enhed, skal du sørge for, at din it-opsætning er forberedt til HTTPS (port 443) eller have personale til rådighed på stedet.",
	t3680: "Find mere information om den nye sikkerhedsimplementering her",
	t3681: "Adgang til http-versionen af dette websted kan blive blokeret på grund af dine browserbegrænsninger. Besøg vores FAQ-side for at få instruktioner om at løse dette problem, eller download desktopversionen af StoreView Web.",
	t3682: "System Manager FAQ",
	t3683: "u17 Ther. luft",
	t3684: "u91 Udkoblingstemp.",
	t3685: "u00 Ctrl. tilstand",
	t3686: "u12 S3 lufttemp.",
	t3687: "u16 S4 lufttemp.",
	t3688: "u23 EEV OD %",
	t3689: "u21 Overhedning",
	t3690: "u22 SuperheatRef",
	t3691: "u26 EvapTemp Te",
	t3692: "S2 Gasudgangstemp.",
	t3693: "Stw8",
	t3694: "Sd-MT",
	t3695: "Tw status",
	t3696: "Tw aktivere",
	t3697: "Tw pumpe kører",
	t3698: "Stw3",
	t3699: "Stw4",
	t3700: "Stw2",
	t3701: "Rcv. kontrolstatus",
	t3702: "Suge offset",
	t3703: "Vrec kontroltilstand",
	t3704: "Vrec status",
	t3705: "Ctrl-statusårsag",
	t3706: "Hp kontrolstatus",
	t3707: "Pgc max.",
	t3708: "Pgc max. begrænse P-forbud",
	t3709: "Pgc min.",
	t3710: "Dampudsugningskapacitet",
	t3711: "Væskeudkaster kapac",
	t3712: "Referencelufttemp.",
	t3713: "Indblæsnings temp.",
	t3714: "Returluft",
	t3715: "Zone dugpunkt",
	t3716: "Zonens fugtighed",
	t3717: "Udendørs temp.",
	t3718: "Zone CO2 niveau",
	t3719: "Zone temp.",
	t3720: "Cond. reference",
	t3721: "Cond. kontrolstatus",
	t3722: "Cond. kontroltilstand",
	t3723: "Cond. ctrl.",
	t3724: "Cond. anmodede kasket.",
	t3725: "V3gc tilstand",
	t3726: "V3gc OD",
	t3727: "Cond. temp.",
	t3728: "Hr status",
	t3729: "Hr aktivere",
	t3730: "HR Forbruger anmodning",
	t3731: "Shr3",
	t3732: "Shr4",
	t3733: "Shr2",
	t3734: "Shr8",
	t3735: "Suge ref. Til-MT",
	t3736: "Kontrolstatus MT",
	t3737: "Faktisk zone MT",
	t3738: "At vælge. offset MT",
	t3739: "Løbekapacitet MT",
	t3740: "Ønsket cap. MT",
	t3741: "Tid til næste trin MT",
	t3742: "Po-MT",
	t3744: "Suge temp. Til-MT",
	t3745: "Total aktiv energi",
	t3746: "Top efterspørgsel",
	t3747: "Samlet effektfaktor",
	t3748: "Fase A volt",
	t3749: "Fase B volt",
	t3750: "Fase C volt",
	t3751: "Fase A forstærkere",
	t3752: "Fase B forstærkere",
	t3753: "Fase C forstærkere",
	t3754: "Samlet aktiv effekt",
	t3755: "Suge temp. Til-LT",
	t3756: "Suge temp. Til-IT",
	t3757: "Suge reference LT",
	t3758: "Kontrolstatus LT",
	t3759: "Faktisk zone LT",
	t3760: "At vælge. offset LT",
	t3761: "Løbekapacitet LT",
	t3762: "Ønsket cap. LT",
	t3763: "Tid til næste trin LT",
	t3764: "Po-LT",
	t3765: "Sd-LT",
	t3766: "Suge reference IT",
	t3767: "Kontrolstatus IT",
	t3768: "Faktisk zone IT",
	t3769: "Driftskapacitet IT",
	t3770: "Ønsket kapacitet IT",
	t3771: "Tid til næste trin IT",
	t3772: "Tn Po-IT",
	t2773: "Sd-IT",
	t2774: "IT Comp. starte",
	t3774: "Ikke inden for rækkevidde. Skal være mellem {{minAddr}} til {{maxAddr}}",
	t3775: "Betjening deaktiveret for denne enhed",
	t3776: "Efter denne handling vil applikationen logge ud, hvilket kan tage et par øjeblikke.",
	t3777: "Migreringsfilerne (flp eller json) kunne ikke findes",
	t3778: "Afstemning",
	t3779: "Afstemningen er i øjeblikket suspenderet.",
	t3780: "Ønsker du at genoptage afstemningen?",
	t3781: "Energimåler",
	t3782: "Flere noder er valgt",
	t3783: "Zoom til node",
	t3784: "Måleenheder",
	t3785: "Fil(er) blev eksporteret",
	t3786: "Adressen må ikke være negativ",
	t3787: "Nulstil zoom",
	t3788: "Fejl under hentning af iscenesættelsesmønstertrin",
	t3789: "Fejl under lagring af iscenesættelsesmønstertrin",
	t3790: "Iscenesættelsesmønstertrin blev gemt",
	t3791: "Iscenesættelsesmønster",
	t3792: "Tryk på de aflæssede ikoner for at aktivere/deaktivere de aflæssere, der er knyttet til kompressorerne",
	t3793: "Manglende data",
	t3794: "Følgende parametre returnerede ingen datapunkter",
	t3795: "HTTPS/SSL-tillid var vellykket.",
	t3796: "Du kan nu logge på StoreView Web Browser.",
	t3797: "Omdirigerer til StoreView-webbrowser om {{timeLeft}} sekunder...",
	t3798: "Afstemningen er i øjeblikket suspenderet!",
	t3799: "Ryddede alarmer (fulde systemlogfiler)",
	t3800: "Max antal tilladte HVAC er 45.",
	t3801: "Kunne ikke hente ændringslog",
	t3802: "Opretter forbindelse til lager...",
	t3803: "Timeout for SCADA-lagringsforbindelse",
	t3804: "Kunne ikke oprette forbindelse til SCADA-lager",
	t3805: "Ugyldig dato",
	t3806: "Vælg enhed(er) til rapportdownload"
};
var da = {
	lang: lang$f
};

var lang$g = {
	t0: "Aktivní alarmy",
	t1: "Vymazány alarmy",
	t2: "Host",
	t3: "ID",
	t4: "Název zařízení",
	t5: "Úpravy poznámek",
	t6: "Není oprávněno potvrzovat poplach",
	t7: "Alarm Type",
	t8: "Došlo",
	t9: "Potvrdit",
	t10: "Active Alarm(s)",
	t11: "Vymazány",
	t12: "Výpočty",
	t13: "Vymazány Alarm(y)",
	t14: "Ztlumit/Ack",
	t15: "Potvrďte všechny nekvitované alarmy",
	t16: "Autorizace se nezdařila!",
	t17: "Error",
	t18: "Manual Control",
	t19: "Visual",
	t20: "Souhrnná data",
	t21: "Technický pohled",
	t22: "Graf (S4)",
	t23: "Node",
	t24: "Mod",
	t25: "Graph (Po &amp; Pc)",
	t26: "(nebyly zjištěny žádné alarmy)",
	t27: "Alarmy zařízení",
	t28: "Zobrazit předchozí zařízení",
	t29: "Zobrazit další zařízení",
	t30: "Chyba při čtení alarmů.",
	t31: "Aktuální alarmy",
	t32: "Aktuální alarm",
	t33: "Nebyly zjištěny žádné alarmy.",
	t34: "Typ",
	t35: "Point",
	t36: "Protokol historie",
	t37: "Plány",
	t38: "Čas zahájení",
	t39: "Zastavit čas",
	t40: "Dny",
	t41: "Dovolená",
	t42: "Status",
	t43: "Vybrat datové body",
	t44: "OK",
	t45: "Zrušit",
	t46: "Start",
	t47: "End",
	t48: "Start date",
	t49: "Vzorkovací frekvence",
	t50: "Nebyly vybrány žádné datové body.",
	t51: "Nwo",
	t52: "Sec",
	t53: "min",
	t54: "hr",
	t55: "Den",
	t56: "Address Type",
	t57: "Adresa",
	t58: "Umístění:",
	t59: "Vstupní nová hodnota",
	t60: "Aktualizovat",
	t61: "Vybrat datové body",
	t62: "Vyberte novou hodnotu",
	t63: "Vypnuto",
	t64: "On",
	t65: "Načítání datových bodů...",
	t66: "Při načítání dat došlo k chybě...",
	t67: "Není nakonfigurována žádná historie...",
	t68: "Při načítání datových bodů došlo k chybě...",
	t69: "Načítání vzorků historie pro",
	t70: "Chyba při načítání vzorků historie...",
	t71: "User Name",
	t72: "Password",
	t73: "Host IP/URL Address:",
	t74: "OK",
	t75: "Zrušit",
	t76: "Name",
	t77: "Hodnotu",
	t78: "Při načítání seznamu uživatelů došlo k chybě.",
	t79: "Zadání nové adresy",
	t80: "Získat seznam uživatelů se nezdařilo, zkontrolujte adresu IP/URL hostitele, prosím.",
	t81: "Auth Code",
	t82: "Kód acct",
	t83: "Vybrat nový čas a datum",
	t84: "Vybrat novou dobu trvání",
	t85: "Operace se nezdařila.",
	t86: "Zadat informace o autorizaci",
	t87: "HVAC",
	t88: "Go!",
	t89: "Rízení odtávání",
	t90: "Spustit ruční odmrazování",
	t91: "Řízení osvětlení pouzdra",
	t92: "Ruční osvětlení pouzdra svítí",
	t93: "Ruční osvětlení pouzdra zhasne",
	t94: "Řízení čištění pouzdra",
	t95: "Ruční čištění pouzdra zapnuto",
	t96: "Ruční čištění pouzdra vypnuto",
	t97: "Hlavní přepínač",
	t98: "Ruční hlavní zapnutí",
	t99: "Ruční hlavní vypnutí",
	t100: "Řízení vypnutí",
	t101: "Vypnout zařízení vypnuto",
	t102: "Zapnutí zařízení",
	t103: "Noční setback Control",
	t104: "Noční nezdar na",
	t105: "Noční nezdar Off",
	t106: "Zpráva odeslána...",
	t107: "Manual Control",
	t108: "Zavřete",
	t109: "Osvětlení",
	t110: "Napájení/Různé",
	t111: "Přehled",
	t112: "Vyberte jednotku AK255, která se má zobrazit.",
	t113: "Načítání...",
	t114: "of",
	t115: "Všechny AK255s",
	t116: "Předchozí",
	t117: "Pages",
	t118: "Next",
	t119: "Parameter",
	t120: "Měření",
	t121: "Načítání dat...",
	t122: "Chyba v sekci čtení",
	t123: "Teplota",
	t124: "Zpoždění po rozmrazení",
	t125: "Alarm, pokud je uveden níže",
	t126: "Alarm, pokud je výše",
	t127: "Sledování",
	t128: "Offline",
	t129: "Aktivn",
	t130: "Host",
	t131: "Adresa",
	t132: "Description",
	t133: "Aktuální hodnota",
	t134: "Aktuální stav",
	t135: "Řadiče",
	t136: "Unit",
	t137: "Aktivní alarm existuje",
	t138: "Aktivní alarmy existují!",
	t139: "Vstupy zapnuto-vypnuté",
	t140: "Relé výstupy",
	t141: "Vstupy senzorů",
	t142: "Variabilní výstupy",
	t143: "Obecné řadiče",
	t144: "Ostatní",
	t145: "Vizualizační bod",
	t146: "Přehled obchodu",
	t147: "Odhlásit ze systému",
	t148: "Log On",
	t149: "Odhlásit se",
	t150: "Chladicí",
	t151: "Přehled zařízení",
	t152: "Alarmy",
	t153: "Historie",
	t154: "Přehled CS",
	t155: "Přehled obchodu",
	t156: "Konfigurace",
	t157: "Site View",
	t158: "Pohled na závod",
	t159: "Home",
	t160: "Alarm",
	t161: "Setpoint",
	t162: "Detail zařízení",
	t163: "Nastavení",
	t164: "Vybrat datum",
	t165: "Information",
	t166: "Auditu",
	t167: "Výpočty",
	t168: "Správa souborů",
	t169: "Help Manual",
	t170: "Info",
	t171: "Překladatel",
	t172: "Uložit na server",
	t173: "Aktualizovat jazykové soubory?",
	t174: "Tato operace aktualizuje jazykové soubory na jednotce. Pokračovat?",
	t175: "Historie exportu",
	t176: "Vybrat novou hodnotu",
	t177: "minimum",
	t178: "maximum",
	t179: "No Active Alarm!",
	t180: "Nelze vyměnit!",
	t181: "Počkejte prosím...",
	t182: "Příkaz odeslán!",
	t183: "Operace se nezdařila, opakujte akci později!",
	t184: "Operace byla úspěšná!",
	t185: "Není autorizovaný provoz!",
	t186: "Opravdu budete pokračovat v této operaci?",
	t187: "EVAP",
	t188: "PACK",
	t189: "Monitorovací vstupy",
	t190: "MISC",
	t191: "Potvrzené alarmy",
	t192: "Vymazat potvrzené alarmy",
	t193: "Vymazat všechny potvrzené alarmy",
	t194: "Nejste oprávněni potvrzovat poplachy",
	t195: "Není povoleno",
	t196: "Chcete potvrdit tento alarm?",
	t197: "Potvrdit potvrzení poplachu",
	t198: "Nejprve se přihlaste",
	t199: "Vyberte budík, který chcete vymazat",
	t200: "Potvrdit vymazání alarmů",
	t201: "Opravdu ten alarm vyčistíte?",
	t202: "Není povoleno vymazat alarm",
	t203: "Opravdu vyčistíte všechny alarmy?",
	t204: "Vybrané alarmy nelze vymazat.",
	t205: "Nastavení budíku",
	t206: "K provedení této operace musí být jako supervizor oprávněn.",
	t207: "Z jednotky nebyla vrácena žádná data.",
	t208: "Výběr jednotky",
	t209: "Vyberte jednotku ze seznamu",
	t210: "Počet alarmů",
	t211: "Stav alarmů",
	t212: "Různé",
	t213: "Vymazat vybraný alarm",
	t214: "Potvrďte, prosím,",
	t215: "Připojení k jednotce se nezdařilo, zkontrolujte připojení k síti!",
	t216: "Alarm rozmrazování",
	t217: "Digitální alarm",
	t218: "Stav digitálního vstupu",
	t219: "Vstup odmrazování",
	t220: "Čištění vstup",
	t221: "Digitální vstup",
	t222: "Ukládání jazykových souborů",
	t223: "Jednotky",
	t224: "Žádné zařízení nakonfigurováno v systému, web zakázán.",
	t225: "Upozornění:",
	t226: "Vybrat čas",
	t227: "Komunikace",
	t228: "Download Report File?",
	t229: "Počkejte prosím při generování souboru sestavy...",
	t230: "Download Report",
	t231: "Alarm Status/Service",
	t232: "Počkejte prosím při načítání dat...",
	t233: "Přihlášeni",
	t234: "Odhlášený uživatel",
	t235: "Přihlášený uživatel",
	t236: "Vítejte!",
	t237: "Neplatná licence",
	t238: "Regulátory měřičů energie",
	t239: "Osvětlovací panely",
	t240: "Střídač VLT",
	t241: "Modul kompresoru",
	t242: "Body řadiče zařízení",
	t243: "Počítané body",
	t244: "Cutler-Hammer panely",
	t245: "Dopravci",
	t246: "Rozšířené zobrazení",
	t247: "Poklepáním sem zobrazení přiblížíte/oddálíte",
	t248: "Chcete-li přiblížit/oddálit, klepněte sem.",
	t249: "Potvrzovací alarm (alarmy)",
	t250: "Simulátor",
	t251: "Úkol refrig",
	t252: "Úloha vzduchotechniky",
	t253: "Úloha osvětlení",
	t254: "Úkol MISC",
	t255: "Připojená síť",
	t256: "Jednotky",
	t257: "Odkaz",
	t258: "Graph (Drive Mtr Hz &amp; Amp)",
	t259: "Stop Date",
	t260: "Stop",
	t261: "Čas zastavení je zapnutý nebo před časem zahájení, vyberte datum a čas",
	t262: "Čas vytvoření historie datových bodů",
	t263: "jsou pozdější než zadaný čas zahájení",
	t264: "Vrácený počet vzorků z každého bodu historie dosáhl 100 000, získání odpovědi by trvalo dlouho. Toto číslo můžete snížit snížením vzorkovací frekvence NEBO změnit datum a čas.",
	t265: "Úloha dotazu na historii jednotky je zaneprázdněna, počkejte prosím asi 2 minuty a akci opakujte...",
	t266: "Uložit",
	t267: "Ovládací panel Historie",
	t268: "První",
	t269: "Poslední",
	t270: "Tisk",
	t271: "Oddálit",
	t272: "Zoom In",
	t273: "Zobrazit/skrýt legendu",
	t274: "Exportovat data historie do souboru",
	t275: "Zobrazit/skrýt mřížku",
	t276: "Zobrazení jedné stránky",
	t277: "Zobrazení více stránek",
	t278: "Skupina historie načtení ze souboru",
	t279: "Historie načítání ze souboru",
	t280: "Uložit skupinu historie do souboru",
	t281: "Počet zobrazení více stránek",
	t282: "Page",
	t283: "Výběr stránky, kterou chcete zobrazit",
	t284: "Soubor historie musí být ve formátu HST!",
	t285: "Nejsou vrácena žádná data, zkontrolujte výběr bodů historie a vybrané datum a čas",
	t286: "Snímek historie",
	t287: "Úplné zobrazení historie",
	t288: "Zobrazení souboru historie",
	t289: "Zvolte...",
	t290: "Tato operace exportuje aktuální zobrazení historie do souboru .hst/.csv.",
	t291: "Zvolte cílový soubor.",
	t292: "File",
	t293: "Skupina historie načtení...",
	t294: "Historie načítání...",
	t295: "Uložit skupinu historie...",
	t296: "View",
	t297: "Zoom",
	t298: "Ano",
	t299: "No",
	t300: "Načíst konfiguraci z řadiče?",
	t301: "Neptejte se znovu",
	t302: "Konfigurace načíst řadič",
	t303: "Načtená data",
	t304: "Alarm Level",
	t305: "Alarm. akce",
	t306: "Potvrzeno",
	t307: "Uložit databázi na disk",
	t308: "File Name",
	t309: "Database Description",
	t310: "Cesta k databázi",
	t311: "Version",
	t312: "Čas",
	t313: "Export alarmů",
	t314: "Databáze uložená do",
	t315: "Ukládání do databáze se nezdařilo, zkontrolujte, zda jsou adresář a název souboru platné!",
	t316: "Název souboru není správný, zkontrolujte název souboru databáze",
	t317: "Export alarmů byl úspěšný. Zvolte soubor pro uložení exportovaných alarmů.",
	t318: "Vyberte budíky k exportu.",
	t319: "Načítání informací o alarmu z jednotky (jednotek)",
	t320: "File Description",
	t321: "Převést soubor HST na formát souboru .csv",
	t322: "Krok 1: Výběr souboru HST",
	t323: "Krok 2: Výběr datových bodů a časového rozsahu",
	t324: "Krok 3: Výběr cílového souboru CSV",
	t325: "Uložit jako...",
	t326: "V této operaci není povoleno více než 6000 datových bodů.",
	t327: "Vybrané datové body",
	t328: "Není dovoleno exportovat více než 65 536 vzorků na datový bod, změňte vzorkovací frekvenci nebo časový rozsah.",
	t329: "Shromažďovat",
	t330: "Shromažďování údajů o historii z jednotky",
	t331: "Výběr shromážděných datových bodů",
	t332: "Žádná hodnota",
	t333: "Chybějící",
	t334: "Pozastavena",
	t335: "Proveďte výběr",
	t336: "Vybrat datové body k losování",
	t337: "Uložit do souboru HST",
	t338: "Stahování dat dokončeno, vyberte jednu z následujících možností:",
	t339: "Vyberte maximálně 8 bodů k losování",
	t340: "Uložení shromážděných dat historie do souboru HST",
	t341: "Přehled Tisk",
	t342: "Vyberte sestavu přehledu uložit nebo vytisknout.",
	t343: "Volba formátu souboru",
	t344: "Generování souboru s přehledem, počkejte prosím...",
	t345: "Vybraný databázový soubor existuje, chcete jej přepsat?",
	t346: "Dashboard",
	t347: "Graphic View",
	t348: "Help",
	t349: "System View",
	t350: "Mapu",
	t351: "Nástroj překlad",
	t352: "Manual",
	t353: "Vyžádejte si alarmy...",
	t354: "Aktualizovány",
	t355: "Model",
	t356: "Control",
	t357: "Olej",
	t358: "Temp",
	t359: "Maximalizovat",
	t360: "Obnovení",
	t361: "Vrať se...",
	t362: "Komuniké selhalo!",
	t363: "Stav comm je v pořádku.",
	t364: "Zpráva z komunika odeslána.",
	t365: "Načítání domácí součásti",
	t366: "Načítání konfigurační součásti",
	t367: "Ctrl Temp",
	t368: "Toto nastavení bude účinné pouze v případě, že je historie překreslena, chcete pokračovat?",
	t369: "Načítání, počkejte prosím",
	t370: "datové body",
	t371: "No unit name",
	t372: "Předvolby",
	t373: "Verze simulátoru",
	t374: "Energie",
	t375: "Přejít do okna přehledu",
	t376: "Systémový alarm nebo žádné související zařízení nalezeno",
	t377: "Souběžné plány",
	t378: "Chybí soubor jazyka.",
	t379: "Verze není podporována.",
	t380: "Zde zadejte IP adresu jednotky (např. 192.168.1.1)",
	t381: "Zadejte IP adresu nebo adresu URL jednotky.",
	t382: "Skrýt detaily",
	t383: "Zobrazit podrobnosti",
	t384: "Mimo Temp",
	t385: "Return",
	t386: "Dodávky",
	t387: "Vlhkost",
	t388: "Rosné bod",
	t389: "Ventilátor",
	t390: "Zone",
	t391: "Vypnutí",
	t392: "Rozdělit",
	t393: "Teplota sání",
	t394: "Aktuální kapacita",
	t395: "Sací tlak",
	t396: "Vypouštění",
	t397: "Kompresory",
	t398: "Kondenzátor",
	t399: "Cíl kondenzátoru",
	t400: "Chladivo",
	t401: "Sací cíl",
	t402: "Sací",
	t403: "Odmrazit",
	t404: "Formát historie Danfoss",
	t405: "Text Format",
	t406: "Tato operace potřebuje reset jednotky, chcete nyní resetovat jednotku?",
	t407: "Reset jednotky proběhl úspěšně, načtěte web znovu.",
	t408: "Tovární aplikační režim",
	t409: "Výpočet...",
	t410: "minutes",
	t411: "Noční nezdar",
	t412: "Case Světla",
	t413: "Naplánovat souběžnost",
	t414: "(10minutové rozlišení)",
	t415: "View Text",
	t416: "Exportovat jako CSV",
	t417: "Uložit PNG",
	t418: "Zobrazit graf",
	t419: "View Text",
	t420: "Plán",
	t421: "Doba trvání",
	t422: "Termín Temp",
	t423: "Zádný",
	t424: "Kontrola temp",
	t425: "Spuštění z",
	t426: "Databáze načtená z",
	t427: "Statistiky",
	t428: "Vybrat soubor",
	t429: "Vyberte soubor historie, který chcete převést do formátu CSV...",
	t430: "Dokončit",
	t431: "Shrnutí",
	t432: "Datové soubory historie (*.hst)",
	t433: "Průvodce převodem historie",
	t434: "Vytvoření souboru bylo dokončeno.",
	t435: "Vyberte datové body, které chcete převést do formátu CSV...",
	t436: "Vybrat časový rozsah",
	t437: "Vyberte časový rozsah a stiskněte tlačítko procesu...",
	t438: "Process Data",
	t439: "Příprava dat historie, počkejte prosím",
	t440: "Vyberte název souboru CSV a uložte data do souboru...",
	t441: "Nelze najít datový bod historie.",
	t442: "Chyba – nebyla vrácena žádná data historie.",
	t443: "Při komunikaci s jednotkami v hostitelské síti došlo k chybě. Nalezen",
	t444: "Pozornost",
	t445: "Ticho",
	t446: "Databáze není uložena, Opravdu chcete zavřít okno?",
	t447: "Table View",
	t448: "Zobrazit grafiku",
	t449: "Zobrazit tabulku",
	t450: "Ruční odmrazování zapnuto",
	t451: "Ruční odmrazování",
	t452: "Automatické odmrazování",
	t453: "Nastavit řízení odmrazování",
	t454: "Vykladač zapnut",
	t455: "Vykladač vypnut",
	t456: "Kapaliny",
	t457: "Nejsou nakonfigurovány žádné plány.",
	t458: "StoreView",
	t459: "Ztracená komunikace s jednotkou v hostitelské síti!",
	t460: "Rychlé nastavení",
	t461: "Street",
	t462: "City",
	t463: "Telefon",
	t464: "Region",
	t466: "Index",
	t469: "Úspěšně exportován adresář",
	t470: "Delete item",
	t471: "Měřidla",
	t472: "Poznámka: vstup rozlnáší malá a velká písmena",
	t473: "Online",
	t474: "RO deska",
	t475: "Deska SI",
	t476: "OI deska",
	t477: "Výpočet",
	t478: "Nastaveno na aktuální čas",
	t479: "Rack",
	t480: "Bez kompresoru",
	t481: "Okruh",
	t482: "Případě",
	t483: "Neznámý",
	t484: "typ chyby:",
	t485: "Přid",
	t486: "Kopírovat",
	t487: "Odebrat",
	t488: "Řízení případu",
	t489: "Typ případu",
	t490: "LON",
	t491: "Zadejte částku ke kopírování.",
	t492: "no name",
	t493: "Konfigurace rozložení chlazení...",
	t494: "Configuration error:",
	t495: "Ověřte konfiguraci.",
	t496: "Nastavení rozložení chlazení bylo úspěšně dokončeno. Stisknutím tlačítka ok ukončíte tohoto průvodce.",
	t497: "Configuration Status",
	t498: "Konečné rozložení chlazení",
	t500: "Code #",
	t501: "Layout",
	t502: "System Setup",
	t503: "Licencování",
	t504: "Uživatelé",
	t505: "Není povoleno",
	t506: "Ověřte prosím...",
	t507: "Opravdu chcete opustit tuto obrazovku? Nastavení může být ztraceno.",
	t508: "Síť řadičů skenování...",
	t509: "Skenování",
	t510: "Ověření",
	t511: "AK-I/O",
	t512: "Prohledat",
	t513: "Zahrnout offline programování",
	t514: "Sběrnice zařízení",
	t515: "Online zařízení",
	t516: "Offline programování",
	t517: "Duplicitní adresa",
	t518: "Ovládání kompresoru",
	t519: "Sací skupiny",
	t520: "N/A",
	t521: "Dostupné případy",
	t522: "Povolit vytvoření vícedílného obvodu",
	t523: "Vložit zařízení",
	t524: "Vložte obvod",
	t525: "Vícedílný obvod.",
	t526: "Načítání tabulek...",
	t527: "Vyžádání údajů o poplachu pro",
	t528: "Soubory skupiny historie",
	t529: "Datové soubory historie",
	t530: "Všechny soubory",
	t531: "Zobrazit symboly",
	t532: "Zobrazit křivky",
	t533: "Nebylo shromážděno málo historie.",
	t534: "Načtěte nejnovější data historie.",
	t535: "Stav/nastavení",
	t536: "Právě se stahuje",
	t537: "vzorky historie...",
	t538: "Načítání",
	t539: "Datové body...",
	t540: "Interval",
	t541: "Min",
	t542: "Max",
	t543: "Průměrná",
	t544: "Přejděte do hlavního okna alarmu.",
	t545: "Zastavení automatického přihlášení",
	t546: "Připojení k",
	t547: "Zrušení automatického přihlášení",
	t548: "Zadejte heslo k odhlášení.",
	t549: "Web 1",
	t550: "Web 2",
	t551: "Automatické přihlášení",
	t552: "Odhlášení po uplynutí doby nečinnosti",
	t553: "Home screen",
	t554: "Zobrazit konfigurační nabídku",
	t555: "Zobrazit různé položky na řídicím panelu",
	t556: "Použití celé obrazovky",
	t557: "Povolení zvuku budíku",
	t558: "Povolit ukončení nebo minimalizaci programu",
	t559: "Chyba přistupování k jednotce #",
	t560: "Připojeno k síti.",
	t561: "Automatické přihlášení bylo zrušeno.",
	t562: "Opakujte připojení",
	t563: "Odhlášeni z",
	t564: "Připojen",
	t565: "Formát času",
	t566: "Date Format",
	t567: "Tlak",
	t568: "Úroveň světla",
	t569: "Podchlazení",
	t570: "Podchodce",
	t571: "Denní",
	t572: "Týdenní",
	t573: "Soubor HST",
	t574: "CSV File",
	t575: "Neděle",
	t576: "Pondělí",
	t577: "Úterý",
	t578: "Středa",
	t579: "Čtvrtek",
	t580: "Pátek",
	t581: "Sobota",
	t582: "Kolekce protokolu historie",
	t583: "Povolit automatické shromažďování",
	t584: "Kdy sbírat",
	t585: "Umístění pro uložení souboru",
	t586: "Vybrat formát souboru",
	t587: "Vyberte složku pro ukládání souborů historie...",
	t588: "Povolte automatické shromažďování historie pro načtení a uložení denních nebo týdenních protokolů historie nakonfigurovaných bodů historie obchodu.",
	t589: "Chcete-li zobrazit soubory historie HST, přejděte na hlavní obrazovku historie a vyberte historii importu. Chcete-li zobrazit soubory CSV, použijte libovolnou tabulkovou aplikaci.",
	t590: "Pokud je v adresáři nalezena ip adresa aktuálního připojení, vybrané body historie budou uloženy.",
	t591: "Výběr datových bodů",
	t592: "Všechny vybrané",
	t593: "Vybrané body",
	t594: "Hledat",
	t595: "Automatické připojení k",
	t596: "Ověřte prosím",
	t597: "Automatická kolekce historie je povolena. Opravdu chcete tento program zavřít?",
	t598: "Opravdu chcete tento program zavřít?",
	t599: "Akce",
	t600: "filtr",
	t601: "Všechny akce",
	t602: "Více informací...",
	t603: "Upravitelné",
	t604: "Operace",
	t605: "Operace byla zrušena.",
	t606: "Stahování databáze bylo dokončeno.",
	t607: "Databáze jednotek byla aktualizována.",
	t608: "Soubor (soubory) Byl úspěšně přenesen do jednotky",
	t609: "Stahování dokončeno, soubor je v jednotce zpracovávána",
	t610: "Při nahrávání souboru do počítače došlo k chybě, zkontrolujte, zda je soubor zapisovatelný a přístupný",
	t611: "Nahrávání souboru",
	t612: "Stahování souboru",
	t613: "Nahrání vybraných souborů",
	t614: "Stahování vybraných souborů",
	t615: "Odstranění souboru",
	t616: "Odstranění vybraných souborů",
	t617: "Chyba připojení FTP",
	t618: "Stáhnout vybrané soubory do jednotky",
	t619: "Při připojení FTP došlo k chybě. Zkontrolujte připojení FTP a heslo/uživatelské jméno.",
	t620: "FTP připojen",
	t621: "Bytes",
	t622: "Soubory na jednotce by byly přepsány touto operací, chcete pokračovat?",
	t623: "Soubory na PC by být přepsány touto operací, Chcete pokračovat?",
	t624: "Soubory byly staženy do jednotky.",
	t625: "POUZE pro chlazení",
	t626: "Samoobsluze",
	t627: "Plná funkčnost",
	t628: "HVAC ONLY",
	t629: "Název souboru je nezákonný. Přesvědčte se, zda má název souboru méně než 8 znaků.",
	t630: "Přepsat kód aplikace nyní?",
	t631: "Později",
	t632: "Nahrané",
	t633: "můžete zvolit ANO přepsat aplikaci nyní nebo zvolte Později",
	t634: "Vyberte kořenový adresář simulátoru.",
	t635: "Vyberte adresář systému Windows.",
	t636: "Vyberte cílový adresář.",
	t637: "Vybrat adresář databáze",
	t638: "FtP odpojen",
	t639: "Časový limit připojení, odpojený protokol FTP",
	t640: "Nahrát vybrané soubory do počítače",
	t641: "Odstranit vybrané soubory",
	t642: "Port serveru FTP",
	t643: "Web Server Port",
	t644: "Místní cesta",
	t645: "Up",
	t646: "Velikost",
	t647: "Date",
	t648: "Připojit",
	t649: "Odpojit",
	t650: "Vzdálená cesta",
	t651: "Volné místo k dispozici:",
	t652: "Vybrané soubory",
	t653: "Files",
	t654: "Celková velikost:",
	t655: "Klepnutím změníte složku...",
	t656: "Změnit...",
	t657: "Chcete odstranit vybrané soubory?",
	t658: "Připojení uzavřeno, ukončit",
	t659: "Název (názvy) vybraného souboru(souborů) je (jsou) neplatné a stahování je ignorováno.",
	t660: "Některé názvy vybraných souborů jsou neplatné a ignorují se.",
	t661: "Soubor (y) Byl úspěšně přenesen do počítače",
	t662: "Na jednotce není dostatek volného místa na disku.",
	t663: "Transfer file(s)",
	t664: "Upravené",
	t665: "Kopírování souborů z disku Flash na disk RAM",
	t666: "Vyberte operaci kopírování souboru :",
	t667: "Kopírování všech nakonfigurovaných souborů EDF z disku Flash",
	t668: "Kopírování všech souborů ERF z disku Flash",
	t669: "Soubory EDF/ed3 jsou uloženy na flash disku jednotky, ale nejsou načteny do obrazovky Správa souborů.  Pomocí níže uvedených možností zkopírujte uložené soubory EDF ve vaší jednotce na obrazovku Správa souborů.",
	t670: "Tato operace zkopíruje všechny soubory EDF, které byly v jednotce nakonfigurovány, z flash disku na disk RAM.",
	t671: "Tato operace zkopíruje všechny soubory EDF v jednotce z flash disku na disk RAM",
	t672: "Neexistuje žádný platný soubor EDF",
	t673: "Kopírování souboru EDF dokončeno",
	t674: "zkopírované soubory",
	t675: "Potvrdit stažení databáze",
	t676: "Unit Address",
	t677: "IP adresa/název hostitele",
	t678: "Database File Name",
	t679: "Vyberte",
	t680: "Aktualizace dokončena, resetování a upgrade jednotky. To může trvat několik minut.",
	t681: "Vítá vás Správce upgradu – krok 1 ze 2",
	t682: "Vítá vás Správce upgradu – krok 2 ze 2",
	t683: "Pokračovat",
	t684: "Kontrola před upgradem",
	t685: "Vyhledat firmware",
	t686: "Backup Database?",
	t687: "Zálohování databáze",
	t688: "Přenos softwaru",
	t689: "Software pro přepisování",
	t690: "Vyberte software, který chcete inovovat.",
	t691: "Nebyla zjištěna žádná aplikace pro obnovení selhání ve výrobě.",
	t692: "Můžete pokračovat, ale všechny chyby během procesu aktualizace může způsobit, že systém nefunkční.",
	t693: "Důrazně se doporučuje nepokračovat.",
	t694: "Chcete pokračovat?",
	t695: "Vzdálená inovace systému nese riziko selhání, což může mít za následek systémovou chybu.",
	t696: "Prohlášení",
	t697: "Nahrávání databáze bylo dokončeno.",
	t698: "Zálohování dokončené databáze",
	t699: "Stahování aplikací dokončeno",
	t700: "Aplikace Přepsání Dokončeno",
	t701: "Upgrade softwaru byl úspěšný.",
	t702: "Chcete jednotku resetovat do tovární aplikace?",
	t703: "Kontrola hardwaru",
	t704: "Kontrola stavu zařízení",
	t705: "Tovární selhání bezpečné",
	t706: "Aktuální verze firmwaru",
	t707: "Spustit upgrade",
	t708: "Výběr existujícího databázový soubor",
	t709: "Výběr existujícího souboru firmwaru",
	t710: "Zpět",
	t711: "Vyhledat firmware",
	t712: "Vybrat soubor CSI",
	t713: "Vyberte soubor .mai",
	t714: "Vzdálené stahování není touto deskou CPU podporováno.",
	t715: "Stahování aplikací se nezdařilo.",
	t716: "Nahrávání databáze se nezdařilo, opakujte akci",
	t717: "Aplikace Přepsání Dokončeno",
	t718: "Přepsání aplikace se nezdařilo! Opakujte akci.",
	t719: "Přepsání aplikace se nezdařilo.",
	t720: "Nebyla zjištěna žádná aplikace pro obnovení selhání ve výrobě.",
	t721: "Udělal",
	t722: "Vybraná aplikace může mít jiný typ verze než firmware aktuální jednotky, pokračování operace může nést riziko selhání a systémové chyby.",
	t723: "Název (názvy) vybraného souboru(souborů) je (jsou) neplatné a stahování je ignorováno.",
	t724: "Některé názvy vybraných souborů jsou neplatné a ignorují se.",
	t725: "Chybí kolík jednotky.",
	t726: "Unit's battery is low.",
	t727: "Hostitelská síť jednotky se nezdařila.",
	t728: "Jednotka je ve stavu nákladového prostoru",
	t729: "Vybrat cílový soubor",
	t730: "Vybrat cílový adresář",
	t731: "Nahrávání databáze bylo dokončeno.",
	t732: "Status OK",
	t733: "Status Error",
	t734: "Stav neznámý",
	t735: "Tato operace může přepsat vybrané soubory v simulátoru cíle, chcete pokračovat?",
	t736: "Stahování databáze dokončeno, je třeba obnovit systém",
	t737: "Stahování databáze se nezdařilo, je třeba to zkusit později",
	t738: "Jednotka bude resetován automaticky po této operaci, Chcete pokračovat?",
	t739: "Vybrat databázi",
	t740: "Download Database File?",
	t741: "Upload Database File?",
	t742: "Stahování databáze do jednotky, čekejte prosím",
	t743: "Databáze byla úspěšně stažena, nyní resetování jednotky",
	t744: "Aktualizace databáze byla dokončena, jednotka byla úspěšně resetována",
	t745: "Aktualizace databáze se nezdařila! Opakujte akci.",
	t746: "Chyba při aktualizaci databáze",
	t747: "Kontrola před stažením",
	t748: "Vyhledat databázi",
	t749: "Stahování databáze",
	t750: "Aktuální informace o databázi",
	t751: "Vyberte databázi ke stažení.",
	t752: "Uložit databázi do PC (upload)",
	t753: "Načíst databázi do jednotky (ke stažení)",
	t754: "Potřebuje autorizaci supervizora",
	t755: "Start Upload",
	t756: "Vyberte databázovou operaci:",
	t757: "Start Download",
	t758: "Aktuální verze firmwaru",
	t760: "Stahování databáze proběhlo úspěšně!",
	t761: "Vítejte ve Správci databází - krok 1 ze 2",
	t762: "Vítejte ve Správci databází - krok 2 ze 2",
	t763: "Připojení k jednotce se nezdařilo, zkontrolujte adresu IP/URL hostitele",
	t764: "Vzdálená inovace databáze systému nese riziko selhání, což může mít za následek systémovou chybu.",
	t765: "Stahování databáze dokončeno",
	t766: "Skenování sítě Ethernet",
	t767: "Zadejte první IP adresu a stiskněte tlačítko start.",
	t768: "Skenování",
	t769: "Skenování",
	t770: "Kolekce protokolu historie",
	t771: "Analogové barvy grafu",
	t772: "Barvy digitálního grafu",
	t773: "Plány vypnutí",
	t774: "Souhrn rozmrazování",
	t775: "Noční setback plány",
	t776: "Plány osvětlení případů",
	t777: "Stav načítání",
	t778: "Kolekce protokolu budíku",
	t779: "Vyberte složku pro ukládání souborů alarmů...",
	t780: "Měsíční",
	t781: "Povolte automatické shromažďování alarmů pro pravidelné načítání a ukládání informací o alarmu do souboru.",
	t782: "Oddělovač CSV",
	t783: "Žádné alarmy",
	t784: "Načítání alarmů se počítá pro jednotku:",
	t785: "Získávání podrobností o poplachu z jednotky:",
	t786: "Automatické shromažďování alarmů je povoleno. Opravdu chcete tento program zavřít?",
	t787: "Hodinové",
	t788: "Tím se aktualizuje databáze jednotky a umístění na střed aktuálního zobrazení. Pokračovat?",
	t789: "zpracované vzorky",
	t790: "Nakonfigurujte sací skupinu.",
	t791: "Chcete-li průvodce rozložením znovu použít více než jednou, je třeba obnovit rozložení chladicí databáze.",
	t792: "Do this via the Configuration-&gt;Control page and set the number of Racks/Packs to zero.",
	t793: "Poznámka: Veškeré plány, výpočty nebo dovozy založené na předchozí konfiguraci chlazení budou muset být překonfigurovány.",
	t794: "Proces kopírování dokončen.",
	t795: "Zpráva o chlazení",
	t796: "Konečné uspořádání vzduchotechniky",
	t797: "Nastavení rozložení HVAC bylo úspěšně dokončeno. Stisknutím tlačítka ok ukončíte tohoto průvodce.",
	t798: "Konfigurace rozložení vzduchotechniky...",
	t799: "Chcete-li znovu použít Průvodce rozložením vzduchotechniky více než jednou, je třeba obnovit rozložení databáze HVAC.",
	t800: "Do this via the Configuration-&gt;Control page and set the number of HVAC units to zero.",
	t801: "Poznámka: Veškeré plány, výpočty nebo dovozy založené na předchozí konfiguraci vzduchotechniky budou muset být překonfigurovány.",
	t802: "Staging pattern",
	t803: "Kroky",
	t804: "Level",
	t805: "Zobrazit více datových popisů",
	t806: "Počet kroků",
	t807: "Soubor nelze uložit.",
	t808: "Nesprávný model.",
	t809: "Načtení bylo úspěšné.",
	t810: "Open",
	t811: "Tato funkce je k dispozici pouze v desktopové verzi aplikace StoreView.",
	t812: "Pokračovat",
	t813: "Průvodce opuštěním",
	t814: "Operace byla úspěšná. Chcete pokračovat nebo chcete průvodce opustit.",
	t815: "Při načítání dat záznamníku alarmu došlo k chybě.",
	t817: "Název zařízení :",
	t819: "In Progress...",
	t820: "Data alarmu nejsou k dispozici.",
	t821: "Počet alarmů",
	t822: "Napodobovat možnosti zobrazení",
	t823: "Zobrazit pouze text",
	t824: "Ikona Pouze zobrazit",
	t825: "Zobrazení textu a ikony",
	t826: "1 týden",
	t827: "2 týdny",
	t828: "3 týdny",
	t829: "4 týdny",
	t830: "Grafické nastavení",
	t831: "Nelze zobrazit diagnostiku alarmu, zkontrolujte automatické shromažďování alarmů pod předvolbami",
	t832: "Přiobjednání není povoleno u nosičů vstupně-inů nebo obvodů zahrnutých v konfiguraci.",
	t833: "Tato adresa IP/URL hostitele je již v adresáři k dispozici. Chcete tuto položku aktualizovat?",
	t834: "Procházet",
	t835: "Vybrat složku adresáře",
	t836: "Umístění",
	t837: "Vybrané umístění nemá žádný obsah adresáře. Chcete exportovat obsah existujícího adresáře do vybraného umístění?",
	t838: "Alarm Action Codes",
	t839: "Soubor adresáře nebyl ve vybrané cestě nalezen. Adresář je načten z místního...",
	t840: "Chcete-li protokoly uložit, vyberte umístění.",
	t841: "Chcete-li protokoly uložit, vyberte platné umístění.",
	t842: "Probíhá sběr protokolu alarmů...",
	t843: "Probíhá shromažďování protokolů historie...",
	t844: "Spustit na pozadí",
	t845: "Alarm log progress",
	t846: "Adresa IP/URL hostitele by neměla být prázdná.",
	t847: "Pole adresa by nemělo být prázdné.",
	t849: "Nemáte nejnovější verzi souboru, chcete načíst data z nejnovější verze?",
	t850: "Konflikt verzí",
	t851: "Průběh protokolu historie",
	t852: "Pole Uživatelské jméno a heslo by neměla být prázdná.",
	t853: "Pole ADRESA IP by nemělo být prázdné.",
	t854: "Pole Adresa IP/URL hostitele by nemělo být prázdné.",
	t855: "Uživatelské jméno by nemělo být prázdné.",
	t856: "Heslo by nemělo být prázdné.",
	t857: "Zařízení SM800, ke kterým jste se připojili",
	t858: "Nebyl dokončen Průvodce počátečním spuštěním. Dokončete tento úkol a znovu se připojte k SM800",
	t859: "Nastaveno na nyní",
	t860: "Neplatný vstup",
	t861: "Text je příliš dlouhý.",
	t862: "Posun",
	t863: "Nelze komunikovat s jednotkami v hostitelské síti.",
	t864: "Ověřte, zda se adresy liší. Konfigurace se aktualizuje po stisknutí tlačítka uložit.",
	t865: "Vyberte platný soubor historie (.hst)",
	t866: "Vyberte platný soubor skupiny historie (.grp)",
	t867: "Barev",
	t868: "Procento",
	t869: "Napětí",
	t870: "Aktuální",
	t871: "Sílu",
	t872: "Frekvence",
	t873: "Koncentrace",
	t874: "Tok",
	t875: "Rychlost",
	t876: "Kyselost",
	t877: "Vyberte grafy v dolní zásuvce",
	t878: "Uložit PNG",
	t879: "Kreslit",
	t880: "Stav systému...",
	t881: "Stav systému a jednotky",
	t882: "Gataway (Gataway)",
	t883: "Licence funkcí",
	t884: "Verze softwaru",
	t885: "Verze modulu prohlížeče",
	t886: "Verze Danux",
	t887: "Adresa Mac",
	t888: "Podpora a aktualizace",
	t889: "Název jednotky",
	t890: "Znovu",
	t891: "Ztracené připojení",
	t892: "Nebylo nalezeno žádné zařízení {{type}}.",
	t893: "Aktuálně používaná verze softwaru není podporována.",
	t894: "Rychlé odkazy",
	t895: "Zařízení",
	t896: "Grafy",
	t897: "Nástroje",
	t898: "Vrácení softwaru zpět",
	t899: "Diagnostika",
	t900: "Došlo k chybě.",
	t901: "Nebyla nalezena žádná {{alarmy}}",
	t902: "Nastavení",
	t903: "Název aplikace",
	t904: "Verze aplikace",
	t905: "Zprávu",
	t906: "Typ události",
	t907: "Časové razítko",
	t908: "Vrácení zpět verze",
	t909: "Aktuální verze",
	t910: "Software",
	t911: "Vrácení zpět...",
	t912: "Vyberte prosím zařízení ze seznamu",
	t913: "Zobrazení kalendáře",
	t914: "Při načítání zařízení došlo k chybě, zkuste to prosím znovu.",
	t915: "Při načítání plánů došlo k chybě, akci opakujte.",
	t916: "Nebylo nalezeno žádné vybavení.",
	t917: "Plány exportu",
	t918: "Zvolte typ exportu CSV nebo PDF.",
	t919: "Exportovat jako CSV",
	t920: "Příprava PDF",
	t921: "Exportovat ve formátu PDF",
	t922: "Uveďte prosím IP adresu v tomto formátu xx.xx.xx.xx[:xxxx]",
	t923: "Username",
	t924: "Přihlášení",
	t925: "Vrácení zpět",
	t926: "Vrácení softwaru SM800A",
	t927: "Tato akce vrátí zpět veškerý software na uvedené verze.",
	t928: "Editor zařízení strany",
	t929: "nějaký text, který vysvětluje tuto funkci",
	t930: "Aktualizovat cdf",
	t931: "Mapovací zařízení",
	t932: "Soubor zařízení",
	t933: "1-založené registrační číslo",
	t934: "Typ úložiště",
	t935: "Min",
	t936: "Max",
	t937: "Jen pro čtení",
	t938: "Protokolu",
	t939: "Typ zprávy",
	t940: "Klíčové slovo převodu",
	t941: "Parametry",
	t942: "Addr.",
	t943: "Prioritou",
	t944: "Bit",
	t945: "Negované",
	t946: "Nový soubor",
	t947: "Otevřít soubor",
	t948: "Generovat soubor",
	t949: "Skupiny",
	t950: "Verze produktu",
	t951: "Došlo k chybě",
	t952: "Nebyl nalezen",
	t953: "Toto zařízení nebylo nalezeno.",
	t954: "Venkovní teplota.",
	t955: "Nebyla nalezena žádná {{equipment}}",
	t956: "Případech",
	t957: "Alarm úspěšně ztlumit/působit",
	t958: "Ztlumení/akt se nezdařilo pro tento alarm",
	t959: "Při nakládací jednotce došlo k chybě, zkuste to prosím znovu.",
	t960: "Žádné vybavení",
	t961: "Nebylo nalezeno žádné zařízení {{type}}.",
	t962: "Konfigurace správce systému",
	t963: "Zařízení",
	t964: "Zpět",
	t965: "Změnili jste hodnotu pole, chcete uložit změny?",
	t966: "Vymazat vybrané body historie",
	t967: "Shr {{number}}",
	t968: "Hodinová rychlost čerpadla",
	t969: "Sd",
	t970: "V3hod",
	t971: "Odkaz na HR",
	t972: "Stw {{number}}",
	t973: "Tw reference",
	t974: "Tw rychlost čerpadla",
	t975: "V3tw",
	t976: "Trec",
	t977: "Prec",
	t978: "Vrec OD",
	t979: "Předběžný odkaz",
	t980: "Odkaz na Trec",
	t981: "Pc",
	t982: "Ss",
	t983: "Vhp OD",
	t984: "Reference Pgc",
	t985: "Vnější průměr vyhazovače",
	t986: "Pgc",
	t987: "Shp",
	t988: "Sgc",
	t989: "Sc3",
	t990: "Sgc ctrl.",
	t991: "Cond. kapacita",
	t992: "Po",
	t993: "Ss",
	t994: "Sd",
	t995: "PC",
	t996: "Provozní kapacita",
	t997: "Certifikát prohlížeče",
	t998: "Odhlásit se",
	t1000: "Řazení pouze na {{count}}",
	t1001: "Načteno {{count}} z {{total}} budíků",
	t1002: "Potvrzení bylo úspěšné u všech alarmů",
	t1003: "Potvrzení se nezdařilo pro všechny alarmy",
	t1004: "Potvrzeno pouze {{count}} z {{total}} alarmů",
	t1005: "Export se nezdařil pro všechny alarmy",
	t1006: "Export se zdařil",
	t1007: "Export se nezdařil, je vyžadován alespoň jeden typ alarmu",
	t2000: "-",
	t2001: "Odezva je příliš velká pro vyrovnávací paměť přenosu XML, která má 32 kB",
	t2002: "Žádné atributy spojené s prvkem cmd nebo příkazem nemají žádný prvek „cmd“ nebo nemohly zapisovat do proměnné nebo nemohly číst proměnnou nebo nemohly přečíst bod nebo nemohly zapisovat do provozního režimu vstupu nebo reléového výstupu",
	t2003: "Špatný vstup příkazu",
	t2004: "Chyba v parametrech příspěvku CGI",
	t2005: "Chyba při převodu odpovědi XML z její interní reprezentace na řetězec XML odpovědi",
	t2006: "Chyba při dekódování řetězce adresy URL",
	t2007: "Přetečení paměti",
	t2008: "Komprese dat odpovědi se nezdařila",
	t2009: "Chyba při analýze požadavku",
	t2010: "Chyba přístupu k datům – řada příčin, které se dále neřeší",
	t2011: "Nedefinovaná hodnota atributu akce příkazu Hodnota atributu je neplatná",
	t2012: "Chyba v referenčním čísle alarmu nebo Alarm není nakonfigurován",
	t2013: "Vstupní řetězec parametru cgi je příliš dlouhý.",
	t2014: "Adresa zařízení nebyla nalezena",
	t2015: "Autorizace zápisu se nezdařila",
	t2016: "Není nakonfigurována žádná historie",
	t2017: "Nebyla vrácena žádná data historie, může to být chyba času",
	t2018: "Vytvořit chyby souboru",
	t2019: "Chyby čtení souboru",
	t2020: "Překročena maximální velikost seznamu zařízení",
	t2021: "nepoužívá",
	t2022: "Neplatný index historie",
	t2023: "Nelze najít index bodu desky z nodetype, node, mod a point.",
	t2024: "Index historie nelze najít",
	t2025: "Dotaz na historii nebyl inicializován před pokusem o spuštění dotazu na historii.",
	t2026: "Došlo k pokusu o hq_start_query, když je aktivní dotaz na historii",
	t2027: "Nebyly nalezeny žádné atributy příkazu, když jsou očekávány",
	t2028: "Začátek epochy byl poskytnut bez konce nebo naopak",
	t2029: "Nelze převést z formátu času a data na epochové sekundy",
	t2030: "Příkaz vyžaduje query_id, ale žádný není poskytnut",
	t2031: "Chyba při vytváření odpovědi XML",
	t2032: "Zadané query_id neodpovídá aktivnímu dotazu",
	t2033: "Byl proveden pokus o příkaz hq_get_data, ale data ještě nejsou k dispozici",
	t2034: "Byla poskytnuta vzorkovací frekvence, která není jednou z povolených",
	t2035: "Interval průměrování je menší nebo roven vzorkovací frekvenci, při které byla data zaznamenána",
	t2036: "Čas konce epochy je kratší než čas počáteční epochy nebo čas konce epochy mínus čas počáteční epochy je menší než průměrný interval",
	t2037: "Poskytnuté device_id neodpovídá žádnému aktuálně známému ak255. Všimněte si, že ak255 zná pouze zařízení, která byla nakonfigurována",
	t2038: "Žádné atributy ID",
	t2039: "Neplatné atributy ID",
	t2040: "Žádné číslo plánu",
	t2041: "Neplatné číslo plánu",
	t2042: "Žádné podrobnosti o rozvrhu",
	t2043: "Chybí on_time",
	t2044: "Chybějící hodina",
	t2045: "Neplatná hodina",
	t2046: "Chybějící minuta",
	t2047: "Neplatná minuta",
	t2048: "Chybí off_time",
	t2049: "Chybějící všední dny",
	t2050: "Neplatné pracovní dny",
	t2051: "Chybějící prázdniny",
	t2052: "Neplatné svátky",
	t2053: "Chybějící nebo neplatný holiday_start",
	t2054: "Chybějící nebo neplatný holiday_end",
	t2055: "Chybějící nebo neplatný holiday_open",
	t2056: "Chybějící nebo neplatný holiday_close",
	t2057: "Dovolená není nakonfigurována",
	t2058: "Chybí popis",
	t2059: "Alarm nelze vymazat",
	t2060: "Chybí nebo je neplatný store_open",
	t2061: "Chybí nebo je neplatný store_closed",
	t2062: "Alarm není nakonfigurován",
	t2063: "Uzel offline",
	t2064: "Chybí index",
	t2065: "Neplatný index",
	t2066: "Relé nelze načasovat",
	t2067: "Název, který se mění, má příliš mnoho znaků. Pokud měníte název_obchodu nebo název_jednotky, musí mít < = 16 znaků, při změně identifikátoru_úložiště1 nebo Id2 musí mít < = 8 znaků.",
	t2068: "Chybějící prvky spojené s prvkem příkazu.",
	t2069: "Tag_ID není definováno",
	t2070: "Chybí listový uzel pro data",
	t2071: "Žádná změna v datech",
	t2072: "Aktualizace databáze se nezdařila",
	t2073: "Nelze zapisovat",
	t2074: "Chyba měřiče přístřešku",
	t2075: "Žádné metry",
	t2076: "Neplatná úroveň kůlny",
	t2077: "Neplatný typ",
	t2078: "Systém je zaneprázdněn",
	t2079: "Aktiva nejsou nakonfigurována pro snížení zátěže",
	t2080: "Neplatný jmenovitý výkon pro aktivum",
	t2081: "Neplatné zpoždění spuštění pro aktivum",
	t2082: "Konfigurace zaneprázdněna",
	t2083: "Probíhá skenování I/O",
	t2084: "Probíhá příliš mnoho rozmrazování",
	t2085: "Pokus o načtení špatné verze databáze do jednotky",
	t2086: "Načtení databáze se nezdařilo",
	t2087: "Není k dispozici žádná dostupná webová paměť",
	t2088: "Odezva poptávky není nakonfigurována (pouze systém AK355)",
	t2089: "Chybějící nebo nesprávná velikost souboru.",
	t2090: "Chybí zakódovaný soubor (prvky b64)",
	t2091: "Nepodařilo se uložit databázi na disk RAM.",
	t2092: "Velikost souboru je příliš velká.",
	t2093: "Chybí název souboru.",
	t2094: "Chybí ID souboru.",
	t2095: "Chybějící index načtení souboru (Poznámka: toto je sekvenční index pro load_file load_database).",
	t2096: "ID souboru neodpovídá nebo chybí.",
	t2097: "Operace načítání souboru již probíhá.",
	t2098: "Dekódovaný soubor neodpovídá původní velikosti souboru.",
	t2099: "Operace načítání souboru byla zrušena nebo nebyla spuštěna.",
	t2100: "Neplatný požadavek uživatele (původní žadatel neodpovídá IP adrese)",
	t2101: "Neplatná sekvence načítání souboru.",
	t2102: "Neplatný adresář",
	t2103: "Neplatné rozšíření",
	t2104: "Neplatný posun",
	t2105: "Chyba při kódování souboru",
	t2106: "Chyba při dekódování souboru",
	t2107: "Nepodařilo se otevřít soubor pro čtení/zápis",
	t2108: "Zápis do souboru se nezdařil",
	t2109: "Soubor je chráněn proti zápisu v systému souborů Flash",
	t2110: "Smazání souboru se nezdařilo.",
	t2111: "Nelze smazat složku.",
	t2112: "Chybějící zařízení",
	t2113: "Ne všechna zařízení z adresy URL byla nalezena",
	t2114: "Chybí parametry historie",
	t2115: "Nebyly nalezeny všechny parametry historie z adresy URL",
	t2200: "Aktuálně používaná verze softwaru ( {{currentVersion}} ) se nedoporučuje",
	t2201: "Aktuálně používaná verze softwaru ( {{currentVersion}} ) se nedoporučuje (doporučeno > {{recommendedVersion}} )",
	t2202: "Chyba verze softwaru",
	t2203: "Grafický editor",
	t2204: "Uprav",
	t2205: "Kreslit",
	t2206: "Nový pohled",
	t2207: "Vytvořeno",
	t2208: "Upustit",
	t2209: "Nová grafika",
	t2210: "Vytvořte nové grafické zobrazení",
	t2211: "Name",
	t2212: "Přejmenovat",
	t2214: "Vývozní",
	t2213: "Duplikát",
	t2215: "Vymazat",
	t2216: "Opravdu chcete smazat zobrazení „ {{name}} “?",
	t2217: "Exportovat všechna zobrazení",
	t2218: "Více...",
	t2219: "Pravidlo",
	t2220: "pravidla",
	t2221: "Název pravidla",
	t2222: "Panel úprav",
	t2223: "Podmínky",
	t2224: "Spád",
	t2225: "Invertovat podmínky",
	t2226: "Mapování",
	t2227: "Mapa tvarů podle",
	t2228: "ID tvaru",
	t2229: "států",
	t2230: "Přidat mapování",
	t2231: "Přidat pravidlo",
	t2232: "Operátor",
	t2233: "Přidat podmínku",
	t2234: "Začněte přidáním pravidel",
	t2235: "Opravdu chcete smazat pravidlo \" {{name}} \"?",
	t2236: "Vytvoří se kopie pravidla",
	t2237: "Zobrazení stránek",
	t2238: "Chcete-li je zobrazit, musíte vytvořit zobrazení webu",
	t2239: "Přejděte do grafického editoru",
	t2240: "Přejděte na podrobnosti",
	t2241: "Vyberte parametr",
	t2242: "Nebyly nalezeny žádné parametry",
	t2243: "Možnosti",
	t2244: "Vstup zapnuto/vypnuto",
	t2245: "Relé Výstup",
	t2246: "Vstup senzoru",
	t2247: "Variabilní výstup",
	t2248: "Generic Device",
	t2249: "Prázdný uzel",
	t2250: "Protokolování historie není podporováno...",
	t2251: "Zkuste to znovu",
	t2252: "Historii se nepodařilo načíst",
	t2253: "Načítání historie může chvíli trvat a nepodporuje více dotazů současně. Zkuste prosím tento požadavek znovu.",
	t2254: "Název parametru",
	t2255: "Název zařízení",
	t2256: "Typ zařízení",
	t2257: "Nelze načíst všechny parametry historie ( {{n}} se nezdařilo)",
	t2258: "Chybné uživatelské jméno nebo heslo",
	t2259: "Žádná komunikace s ovladačem",
	t2260: "Aktualizace softwaru",
	t2261: "Systém",
	t2262: "Vrácení softwaru zpět",
	t2263: "Nainstalujte",
	t2264: "Vrácení softwaru nahradí novější verzi",
	t2265: "Vrácení softwaru na záložní verzi {{version}}",
	t2266: "Databáze",
	t2267: "K dispozici je aktualizace",
	t2268: "Export databáze",
	t2269: "Stažení",
	t2270: "Stahování databáze",
	t2271: "Nelze exportovat databázi",
	t2272: "Soubor databáze",
	t2999: "Chyba v komunikaci",
	t2998: "Neznámá chyba",
	t3000: "Podrobnosti",
	t3001: "Minulý kód XML zde",
	t3002: "Přiřadit",
	t3003: "Stav",
	t3004: "Správa softwaru",
	t3005: "Místní uložený soubor",
	t3006: "Online přes internet",
	t3007: "Ujistěte se, že během procesu aktualizace nedošlo k přerušení napájení. Před aktualizací systému se doporučuje provést zálohu databáze systému. Po dokončení aktualizace (aktualizací) softwaru se doporučuje zkontrolovat provozní stav systému pro potvrzení správného ovládání a provozu.",
	t3008: "Nejsou vybrány žádné soubory",
	t3009: "Vyberte soubor softwaru",
	t3010: "Nahrávání souborů",
	t3011: "Bezpečnostní kontrola",
	t3012: "Extrahování souboru",
	t3013: "Kontrola souboru",
	t3014: "Nahrání souboru se nezdařilo!",
	t3015: "Bezpečnostní kontrola se nezdařila!",
	t3016: "Extrakce se nezdařila!",
	t3017: "Kontrola souboru se nezdařila!",
	t3018: "Stahování souboru: {{fileName}}",
	t3019: "Soubor byl úspěšně stažen!",
	t3020: "Stažení se nezdařilo!",
	t3021: "Nahrát soubory",
	t3022: "Stáhnout soubory",
	t3023: "Aktualizace OS",
	t3024: "Edf aktualizace",
	t3025: "Stáhnout soubory",
	t3026: "Hodnota zvoleného parametru bohužel nebyla k dispozici",
	t3027: "Import",
	t3028: "Je vyžadován minimálně jeden soubor",
	t3029: "Soubor není platný",
	t3030: "Správa grafických zobrazení",
	t3031: "nahrát",
	t3032: "Migrovat",
	t3033: "Čtení grafických souborů, čekejte prosím...",
	t3034: "Probíhá import grafického souboru, čekejte prosím...",
	t3035: "Vyberte grafiku",
	t3036: "Zprávy",
	t3037: "Importovat databázi",
	t3038: "Vyberte soubor databáze",
	t3039: "Ujistěte se, že během procesu vrácení nedošlo k přerušení napájení. Před vrácením vašeho systému se doporučuje provést zálohu systémové databáze. Po dokončení vrácení softwaru se doporučuje zkontrolovat provozní stav systému a potvrdit správné ovládání a provoz.",
	t3040: "Správce systému se po aktualizaci restartuje",
	t3041: "Rozšířit",
	t3042: "Zobrazit ladicí zprávy",
	t3043: "Max. povolené znaky -",
	t3044: "Nahrajte soubor aktualizace",
	t3045: "Aktuální verze SW",
	t3046: "Aktuální verze Danux",
	t3047: "Nejnovější verze SW",
	t3048: "Nejnovější verze Danux",
	t3049: "Zálohování prosím čekejte",
	t3050: "Instalace souboru prosím čekejte",
	t3051: "Instalování aktualizace",
	t3052: "Zařízení se restartuje",
	t3053: "Restartování správce systému",
	t3054: "Prohlížeč se nyní obnoví",
	t3055: "Software byl úspěšně nainstalován",
	t3056: "Aktualizace se nezdařila, došlo k následující chybě:",
	t3057: "Zálohujte aktuální verzi softwaru",
	t3058: "Zálohujte aktuální soubory EDF",
	t3059: "Grafický pohled byl úspěšně uložen",
	t3060: "Použít obrázek na pozadí",
	t3061: "Průhledná",
	t3062: "Předělat",
	t3063: "Obchod",
	t3064: "Noční výpadky okruhu",
	t3065: "Noční výpadky sání",
	t3066: "Stažená verze {{fileName}}",
	t3067: "Chcete-li aktualizovat vybrané jednotky, stiskněte 'Instalovat'",
	t3068: "Vrátit verzi {{updateType}}",
	t3069: "Instalace",
	t3070: "Nainstalujte verzi pro vrácení zpět",
	t3071: "Žádná z dostupných jednotek neobsahuje žádnou rollback verzi",
	t3072: "Nová verze dostupná online",
	t3073: "Nahrajte soubor pro vybrané jednotky",
	t3074: "Nahrajte soubor pro vybranou jednotku",
	t3075: "Pro jednotku nejsou k dispozici žádné verze vrácení zpět",
	t3076: "Stahování dokončeno",
	t3077: "Aktuální verze {{updateType}}",
	t3078: "Nejnovější verze {{updateType}}",
	t3079: "Probíhá aktualizace, opravdu chcete zavřít okno?",
	t3080: "Danux není aktuální! Aktualizujte prosím Danux a poté znovu aktualizujte software",
	t3081: "Aplikace nelze downgradovat na tuto verzi",
	t3082: "Informace o aplikaci",
	t3083: "Poznámky k vydání",
	t3084: "Podmínky a soukromí",
	t3085: "licence",
	t3086: "Přejděte do konfigurace a nastavte plány",
	t3087: "Bohužel došlo k chybě při přípravě migrace. Ujistěte se, že jsou všechny jednotky dostupné v síti a jsou správně nakonfigurovány. \n\n Došlo k následující chybě:\n {{error}}",
	t3088: "Importujte nebo migrujte existující grafiku",
	t3089: "Zobrazit",
	t3090: "Název aktiva",
	t3091: "Kolaps",
	t3092: "Import dokončen",
	t3093: "Bezpečnost výboje",
	t3094: "Bezpečnost sání",
	t3095: "Vypustit Cutin",
	t3096: "Vypouštěcí výřez",
	t3097: "Výřez sání",
	t3098: "Cutin sání",
	t3099: "Kapacity",
	t3100: "Soubory migrace (flp, dpj nebo json) nebyly nalezeny",
	t3101: "Časový limit pro analýzu souboru",
	t3102: "Soubory a adresy zařízení třetích stran",
	t3103: "Mřížka",
	t3104: "Zámek",
	t3105: "Xml",
	t3106: "Vyberte parametr, který chcete zobrazit",
	t3107: "Parametr zařízení",
	t3108: "Žádné jednotky",
	t3109: "Nebyly nalezeny ani načteny žádné jednotky",
	t3110: "HACCP",
	t3111: "AKTIVUM",
	t3112: "Nejsou k dispozici žádná data haccp",
	t3113: "Informace o jednotce",
	t3114: "Dostupné aktualizace",
	t3115: "Přejděte na Software",
	t3116: "Nepřipojený",
	t3117: "Aktuální rozsah přístupu pro tuto aplikaci je ' {{targetLevel}} ', poskytnutý uživatel je nad tímto rozsahem, pokud jde o přístupová práva",
	t3118: "Export PDF",
	t3119: "Export PDF se nezdařil",
	t3120: "U velkých datových sad může rozhraní přestat reagovat a dokončení může chvíli trvat",
	t3121: "Vyberte rozvrhy ze seznamu",
	t3122: "Přejděte do konfigurace a nastavte plán",
	t3123: "Úspěch",
	t3124: "Varování",
	t3125: "Informace o webu",
	t3126: "Vyberte prosím časové a datové body ze seznamu",
	t3127: "Spojovací",
	t3128: "Připojení k tomuto webu se nepodařilo navázat, kontaktujte prosím podporu",
	t3129: "Ke zvolenému datu nebyly k dispozici žádné údaje HACCP. Ujistěte se, že historie a čas jsou správně nakonfigurovány v konfigurační nabídce",
	t3130: "Diagnostická zpráva",
	t3131: "Vyberte jednotku(y) pro export diagnostické zprávy",
	t3132: "Není k dispozici na této úrovni uživatelského přístupu",
	t3133: "Protokol diagnostiky",
	t3134: "nepodařilo se stáhnout zprávu",
	t3135: "Písmo",
	t3136: "velikost písma",
	t3137: "Žádná zobrazení k migraci",
	t3138: "Stáhněte si verzi pro stolní počítače",
	t3139: "Kvůli bezpečnostním omezením v moderních prohlížečích (",
	t3140: "RFC1918",
	t3141: ") bohužel není možné se připojit k zařízením v soukromém síťovém prostoru pomocí nešifrovaného HTTP. Nakonfigurujte správce systému tak, aby používal HTTPS nebo verzi pro stolní počítače",
	t3142: "Pro zvolené období nejsou k dispozici žádná data",
	t3143: "Nakonfigurujte správce systému tak, aby používal HTTPS nebo verzi pro stolní počítače.",
	t3144: "Nemohli jsme komunikovat se správcem systému. To buď znamená, že se vaše zařízení nemůže připojit k IP nebo názvu hostitele správce systému, nebo že možná budete muset ručně důvěřovat certifikátu SSL používanému v zařízení.",
	t3145: "Kliknutím na následující odkaz otevřete novou kartu, abyste certifikátu důvěřovali, a poté se vraťte na tuto obrazovku.",
	t3146: "Podpis",
	t3147: "DI - digitální vstupy",
	t3148: "AI - analogové vstupy",
	t3149: "Přejděte na konfiguraci HACCP",
	t3150: "Žádná data",
	t3151: "Záloha je uložena ve správci systému pro funkci vrácení zpět",
	t3152: "Vyberte, co se má zobrazit v zobrazení webu",
	t3153: "Jednotka aktiv",
	t3154: "Grafické soubory",
	t3155: "Při načítání souborů a verzí edf ze správce systému bohužel došlo k chybě...",
	t3156: "Chcete-li vybrat soubory k instalaci, stiskněte tlačítko „Vybrat soubory“.",
	t3157: "Vyberte soubory",
	t3158: "Odebírání zálohy",
	t3159: "Pracovní soubory",
	t3160: "Záloha je uložena ve správci systému pro funkci vrácení zpět",
	t3161: "Instalovaná velikost",
	t3162: "Nová velikost",
	t3163: "Datum instalace",
	t3164: "Nové datum",
	t3165: "Senzory",
	t3166: "Relé",
	t3167: "Vstupy",
	t3168: "Outs",
	t3169: "metrů",
	t3170: "Obecný",
	t3171: "Platnost hesla vypršela, zadejte prosím nové heslo",
	t3172: "Nové heslo",
	t3173: "Potvrďte heslo",
	t3174: "Změnit heslo",
	t3175: "Heslo musí obsahovat minimálně 8 znaků 1 velké písmeno, 1 malé písmeno, 1 číslo, 1 speciální znak)",
	t3176: "Hesla se neshodují",
	t3177: "Heslo nemůže být stejné jako staré heslo",
	t3178: "Změna hesla úspěšná",
	t3179: "Heslo nelze změnit. Chyba ze zařízení",
	t3180: "Resetovat heslo",
	t3181: "Vygenerováno",
	t3182: "Jméno stránky",
	t3183: "ID události",
	t3184: "Čas",
	t3185: "Role",
	t3186: "Informace o události",
	t3187: "Potvrďte vybrané alarmy",
	t3188: "Potvrďte všechny vybrané alarmy",
	t3189: "Importovat databázi do",
	t3190: "Import databáze",
	t3191: "Typ souboru by měl být .zip",
	t3192: "Zvolte soubor",
	t3193: "Otevírací doba",
	t3194: "Dovolená",
	t3195: "Plány zapnutí/vypnutí",
	t3196: "Aktivum",
	t3197: "Máte neuložené změny. Opravdu chcete odejít?",
	t3198: "Bohužel migrace se nezdařila. Systém je momentálně zaneprázdněn. Prosím zkuste to znovu později",
	t3199: "Nahrajte obrázek upravující aktuální rozměry",
	t3200: "Nahradit obrázek na pozadí",
	t3201: "Nahraďte obrázky na pozadí škálovatelnou vektorovou grafikou.",
	t3202: "Nahrazují se pouze soubory SVG se stejným názvem jako existující soubory.",
	t3203: "Vyberte soubory, které chcete nahradit",
	t3204: "Soubory obrázků",
	t3205: "nelze zpracovat. Soubor má neplatný formát.",
	t3206: "Soubory ( {{amount}} ) byly úspěšně nahrazeny",
	t3207: "Soubory ( {{amount}} ) se nepodařilo nahradit",
	t3208: "Pohled nemá pozadí",
	t3209: "Můžete nahrát nový nebo nahradit stávající obrázek na pozadí",
	t3210: "Pokud nahrazujete obrázek na pozadí, mohou být pozice parametrů zachovány, pokud:",
	t3211: "Soubor png nebo jpg má stejné rozlišení jako aktuální pozadí",
	t3212: "Soubor svg má stejný poměr stran (ale nemusí nutně stejné rozlišení)",
	t3213: "Funkce je zakázána",
	t3214: "Aktuálně se zobrazuje poslední přijatá hodnota, aktualizuje se...",
	t3215: "Nahrajte grafický soubor vytvořený v",
	t3216: "(volitelný)",
	t3217: "Obrázek na pozadí",
	t3218: "Procházet",
	t3219: "Více...",
	t3220: "Přidat obrázek na pozadí (volitelné)",
	t3221: "Grafický soubor",
	t3222: "Časový limit v komunikaci",
	t3223: "Nejsou nakonfigurovány žádné plány chlazení",
	t3224: "Index",
	t3225: "Typ",
	t3226: "Dovolená",
	t3227: "Dny",
	t3228: "Na",
	t3229: "Vypnuto",
	t3230: "ID",
	t3231: "OTEVŘENO",
	t3232: "Start",
	t3233: "Konec",
	t3234: "Popis",
	t3235: "Den",
	t3236: "Zavřít",
	t3237: "Soubory edf byly úspěšně nahrány. Restartování správce systému",
	t3238: "Nahrávání souborů edf dokončeno, správce systému byl úspěšně resetován",
	t3239: "Chyba při načítání souborů edf ze správce systému",
	t3240: "Nahrání souboru(ů) Edf se nezdařilo. Zkuste po nějaké době",
	t3241: "Nejsou nakonfigurovány žádné plány chlazení",
	t3242: "Nahrajte soubor do správce systému",
	t3243: "Specifikujte typ zařízení - ovlivní požadované proměnné parametrů pro jeho provoz",
	t3244: "Určete, jak se přistupuje k hodnotám zařízení a jakou funkci plní",
	t3245: "Vytvořte alarm na základě určitého parametru",
	t3246: "Štítky",
	t3247: "Vytvořte seznam štítků, které lze přiřadit k parametru",
	t3248: "Skupiny",
	t3249: "Zadejte skupiny parametrů",
	t3250: "Registrační číslo",
	t3251: "Datový typ",
	t3252: "Seznam štítků",
	t3253: "Minimální",
	t3254: "Výchozí",
	t3255: "Maximum",
	t3256: "Typ registrace",
	t3257: "Typ Register je přeložen na odpovídající funkční kódy pro čtení a zápis",
	t3258: "Režim výměny",
	t3259: "Pro hodnotu s byty ABCD odeslanou do zařízení tato volba zamění bajty (BADC), slova (CDAB) nebo obojí (DCBA).",
	t3260: "Pouze ke čtení",
	t3261: "Protokolování",
	t3262: "Povolí protokolování historie pro tento parametr",
	t3263: "Převzít text z parametru do alarmu",
	t3264: "Pozice alarmového příznaku počítajícího z nich nejméně významný bit parametru",
	t3265: "Spustit alarm při nízké hodnotě",
	t3266: "Označení",
	t3267: "Skrýt",
	t3268: "{{field}} je povinné pole.",
	t3269: "Maximum musí být větší nebo rovné minimu",
	t3270: "Výchozí hodnota musí být v [Minimum, Maximum]",
	t3271: "Typ dat musí být {{reg}} pro vybraný typ registru {{mt}}",
	t3272: "Název nesmí obsahovat oddělovací znaky",
	t3273: "Název nesmí přesáhnout 20 znaků",
	t3274: "Seznam štítků nesmí být prázdný",
	t3275: "Tag(y) {{taglist}} musí být přiřazeny přesně jednou.",
	t3276: "Skupina(y) {{grouplist}} obsahuje více než maximálně 50 parametrů.",
	t3277: "Formulář obsahuje chyby, zkontrolujte chyby a zkuste to znovu.",
	t3278: "Předložit",
	t3279: "Chcete-li aktivovat nahrané EDF, musíte resetovat jednotku. Chcete nyní resetovat jednotku?",
	t3280: "Resetovat nyní",
	t3281: "Obnovit později",
	t3282: "{{file}} již existuje. Chcete jej přepsat?",
	t3283: "Nahrát {{what}}",
	t3284: "Štítky a hodnoty musí být jedinečné.",
	t3285: "Multi",
	t3286: "Zařízení zvládne více pouzder nebo balení",
	t3287: "Tagy",
	t3288: "Internet Explorer je na konci životnosti a StoreviewWeb jej nepodporuje",
	t3289: "Přepněte na podporovaný prohlížeč, buď Edge, Chrome nebo Firefox",
	t3290: "Více informací",
	t3291: "Zavřít",
	t3292: "Načítání dat se nezdařilo, zkuste to prosím znovu",
	t3293: "Probíhá zálohování EDF",
	t3294: "Nejsou k dispozici žádné pohledy k migraci",
	t3295: "Systém je nakonfigurován s veřejnou IP v poli privátní IP",
	t3296: "Systém je nakonfigurován se soukromou IP v poli veřejné IP, systém nemusí být vzdáleně přístupný nebo některé funkce nemusí fungovat správně",
	t3297: "Jste připojeni přes IP nebo název hostitele, který správce systému nezná, pravděpodobně prostřednictvím služby proxy, některé funkce nemusí fungovat správně",
	t3298: "Zařízení je offline, zobrazuje poslední známou hodnotu",
	t3299: "Integrace zařízení",
	t3300: "{{taglist}} jsou duplicitní.",
	t3301: "Stránka nenalezena",
	t3302: "Je nám líto, požadovaná stránka nebyla nalezena",
	t3303: "Kód chyby: 404",
	t3304: "Přejděte na řídicí panel",
	t3305: "Pouze prohlížet",
	t3306: "Interní IP adresa",
	t3307: "Externí IP adresa",
	t3308: "Doménové jméno",
	t3309: "Žádná varování",
	t3310: "Vícenásobná varování",
	t3311: "Zdá se, že systém je nakonfigurován s více jednotkami, ale nepodařilo se nám kontaktovat jednu nebo více podřízených jednotek. Ujistěte se prosím, že jsou nastavení správná. Vezměte prosím na vědomí, že nastavení pro více jednotek nepodporuje více názvů domén a že každá jednotka musí být nakonfigurována s odlišným portem, pokud používáte stejnou doménu.",
	t3312: "Pouze prohlížet",
	t3313: "Tento prvek nemá žádnou asociaci s žádným parametrem a lze jej bezpečně odebrat",
	t3314: "Odebrat parametr",
	t3315: "Plán",
	t3316: "Nyní budete odhlášeni a budete se muset přihlásit pomocí nového hesla",
	t759: "Databáze stažena, resetování a aktualizace jednotky. To může trvat několik minut.",
	t816: "Typ alarmu:",
	t818: "Počet :",
	t848: "DB soubor není vytvořen. Máte omezený přístup k nainstalovanému adresáři. Kontaktujte správce systému.",
	t3317: "Žádné plány k exportu",
	t3318: "Čeká se na odpověď systému",
	t3319: "Žádná síťová aktivita",
	t3320: "Jméno obchodu",
	t3321: "Nová verze {{latestVersion}} dostupná online",
	t3322: "Externí port",
	t3323: "Interní port",
	t3324: "Nemůžeme komunikovat se správcem systému zapnutým",
	t3325: "možná musí být certifikát důvěryhodný?",
	t3326: "Soubor tohoto formátu nelze odstranit",
	t3327: "Používání",
	t3328: "Včas",
	t3329: "Čas vypnutí",
	t3330: "všední dny",
	t3331: "Stupeň chlazení",
	t3332: "Regenerace tepla",
	t3333: "Chlazení",
	t3334: "Topení",
	t3335: "Aux Heat",
	t3336: "Odvlhčování",
	t3337: "Internet",
	t3338: "Zprávy",
	t3339: "Kontakty",
	t3340: "Comm",
	t3341: "žádné předměty nenalezeny",
	t3342: "Zadejte a vyhledejte",
	t3408: "Povolit vše",
	t3409: "Zakázat vše",
	t3410: "Normální",
	t3411: "Těžké",
	t3412: "Kritické",
	t3413: "Povolit všechny budíky",
	t3414: "Hledejte budíky",
	t3415: "Povoleno",
	t3417: "Chyba při načítání budíků",
	t3418: "Při načítání zařízení ke kopírování došlo k chybě",
	t3419: "Chyba při kontrole stavu kopírování",
	t3420: "Automatické dotazování se obnoví maximálně po 2 hodinách, nebo pokud je průvodce zrušen nebo dokončen",
	t3421: "Zkopírovat do ovladače",
	t3422: "Kopírování do vybraných zařízení",
	t3423: "Funkce kopírování nejprve zkopíruje nastavení do databáze správce systému a poté stáhne nastavení do řídicích zařízení. Dokončení akce může nějakou dobu trvat.",
	t3424: "Nebyla vybrána žádná zařízení ke kopírování",
	t3425: "Dvojitým kliknutím zkopírujete",
	t3426: "Chyba při načítání zařízení",
	t3427: "Zařízení pro kopírování",
	t3428: "Vyberte zařízení pro kopírování",
	t3429: "Zde budou zobrazeny pouze ovladače stejného typu",
	t3430: "Proces kopírování byl úspěšný",
	t3431: "Neplatný soubor. Očekávaný model souboru:",
	t3432: "Chyba při načítání souboru nastavení",
	t3435: "Jste si jisti, že přesunete vybrané zařízení do této skupiny {{toPackGroup}} ?",
	t3343: "Objev",
	t3344: "Obnovit tabulku",
	t3345: "Prohledávám sítě, čekejte prosím...",
	t3346: "Tabulka sítě je obnovena",
	t3347: "Chyba při obnovování sítí",
	t3348: "Chyba při opětovném prohledávání sítí",
	t3349: "Chyba při načítání sítí",
	t3350: "Síťové skenování dokončeno",
	t3351: "Nejsou k dispozici žádná zařízení",
	t3352: "Skenování sítě",
	t3353: "Uncfg",
	t3354: "Jakékoli přeuspořádání zařízení v průvodci může ovlivnit monitorovací služby Alsense.",
	t3355: "Síť",
	t3356: "Vyberte typ sběrnice",
	t3357: "Pokud je povoleno Modbus/RS485, můžete pro kompresor Danfoss s proměnnou rychlostí vybrat SLV. Při výběru SNMP jako místní sběrnice budete požádáni o zadání platného rozsahu adres podsítě pomocí ovládacích prvků SNMP společnosti Danfoss",
	t3358: "Kompresory/packy",
	t3359: "Správa ovladačů kompresorů/balíčků",
	t3360: "Zde lze spravovat pouze zařízení Pack - nikoli systém Rack I/O. Kompatibilní ovladače multi-suction Pack, které byly naskenovány, se zpočátku zobrazí jako 0 sacích skupin. Ke konfiguraci řadiče Pack je vyžadován AK-ST 500 (servisní nástroj).",
	t3361: "Částka ke kopírování",
	t3362: "Snížením dříve nakonfigurované sací skupiny také odstraníte veškeré související ovládací prvky výparu pro danou sací skupinu.",
	t3363: "Počet sací skupiny nemůže být nulový, minimální počet. sací skupiny by měla být alespoň jedna.",
	t3364: "Chyba při načítání kompresorů/balíčků",
	t3365: "Nejsou k dispozici žádné kompresory/soupravy",
	t3366: "On-line zařízení",
	t3367: "Off-line zařízení",
	t3369: "Přidat balíček",
	t3370: "Pokud vyberete Modbus/RS485, zobrazí se další možnosti pro SLV/ECFan, které se používají pro chlazení a lze je vybrat na Ne.",
	t3371: "Přidat okruh",
	t3372: "Přidejte, zkopírujte nebo odeberte zařízení. Upozorňujeme, že ne všechny typy zařízení umožňují úpravy všech polí a adresy I/O bodů jsou nastaveny v konfiguraci.",
	t3373: "Správa případových kontrolérů",
	t3374: "Přidejte zařízení",
	t3375: "Správa ovladačů kompresoru/balení",
	t3376: "Chyba v přístupu k datům",
	t3377: "Rozvržení HVAC bylo úspěšně použito",
	t3378: "Rozložení HVAC",
	t3379: "Opravdu chcete opustit tuto stránku?",
	t3380: "Vaše nastavení může být ztraceno",
	t3381: "Nakonfigurujte zařízení",
	t3382: "Ukončete rychlé nastavení",
	t3383: "Opravdu chcete ukončit Rychlé nastavení?",
	t3384: "Brány (AK-PI200)",
	t3385: "Vyberte nalezené brány",
	t3386: "IP Address",
	t3387: "Ovladač zdroje",
	t3388: "Nastavení zdroje",
	t3389: "Zdroj alarmů",
	t3390: "Zdrojový graf",
	t3392: "Graf",
	t3393: "Zařízení",
	t3394: "Auto polling je během fáze kopírování pozastaven. Po dokončení kopírování se hlavní vypínač zdrojového a cílového zařízení (zařízení) vypne a vrátí se do původního stavu. Po dokončení průvodce kopírováním nezapomeňte zkontrolovat hlavní vypínač. Pokud je některé z vybraných zařízení v aktivním poplachu, žádná modifikace poplachové akce pro daný bod se nezmění. Původní nastavení zůstane zachováno.",
	t3395: "Vyberte zdrojové zařízení, ze kterého chcete kopírovat nastavení",
	t3396: "Včas",
	t3397: "Funkce kopírování zkopíruje dostupné body grafu, dokud nebude dosažena kapacita jednotky. Přejděte na Konfigurace a uvidíte počet definovaných bodů",
	t3398: "Grafy zdrojového zařízení",
	t3399: "Nakonfigurujte body grafu pro zdrojový řadič",
	t3400: "Povolte HACCP pro protokolování bodů do zprávy o teplotě HACCP",
	t3401: "Hledejte body",
	t3402: "Nejsou k dispozici žádná data",
	t3403: "Chyba při načítání bodů grafu",
	t3404: "Deaktivováno",
	t3405: "Alarmy zdrojového zařízení",
	t3406: "Nakonfigurujte podrobnosti alarmu ve zdrojovém ovladači",
	t3407: "Název alarmu",
	t3436: "Zařízení bude odebráno z přiřazené skupiny [ {{fromPackGroup}} ]",
	t3437: "Rozložení Chlazení",
	t3438: "Uspořádání chlazení bylo úspěšně použito",
	t3439: "Přeřadit",
	t3440: "Přiřaďte ovládací prvky pouzdra ovládacím prvkům balení",
	t3441: "Přiřaďte ovládací prvky Case (z dostupných případů) k ovládacím prvkům Compressor/Pack. tvořící sací skupiny",
	t3442: "Parametry zařízení",
	t3443: "Vyberte parametry pro kopírování",
	t3444: "Při práci na živém (online) zařízení může docházet ke zpoždění v nastavení z důvodu komunikace se zařízením.",
	t3445: "Vyberte budíky pro kopírování",
	t3446: "Graf zařízení",
	t3447: "Vyberte body grafu pro kopírování",
	t3448: "Uložte aktuální nastavení zařízení do souboru",
	t3449: "Aktuální nastavení zařízení můžete uložit do souboru, který lze později použít ke konfiguraci zařízení. Pokud chcete po uložení souboru ukončit průvodce, použijte ikonu ukončení. Pokud pracujete s připojenými zařízeními, ukončením zajistíte síťové dotazování a hlavní vypínač zařízení se vrátí do správného stavu.",
	t3450: "Nastavení souboru (souborů) bylo úspěšně uloženo",
	t3451: "Chcete pokračovat nebo ukončit Rychlé nastavení?",
	t3452: "Ukládání souborů",
	t3453: "Chyba při načítání nastavení",
	t3454: "Nastavení zdrojového zařízení",
	t3455: "Nakonfigurujte parametry zařízení ve zdrojovém řadiči",
	t3456: "Žádné z polí pro heslo nesmí být prázdné",
	t3457: "Nedávné",
	t3458: "Address Book",
	t3459: "Poslední přístup",
	t3460: "Pravidelně si prosím zálohujte adresář.",
	t3461: "Adresář byl úspěšně importován",
	t3462: "Import Address Book",
	t3463: "Export Address Book",
	t3464: "Přidat web",
	t3465: "Upravit web",
	t3466: "Smazat web",
	t3467: "Než začnete Adresář používat, musíte si vytvořit heslo adresáře",
	t3468: "Stránka byla úspěšně přidána do vašeho adresáře",
	t3469: "Již v adresáři",
	t3470: "Přidat do adresáře",
	t3471: "Přihlaste se na stránky",
	t3472: "Informace o webu byly úspěšně aktualizovány",
	t3474: "Oznámení",
	t3475: "Stránka byla úspěšně odstraněna z vašeho adresáře",
	t3476: "Stránka byla úspěšně aktualizována",
	t3477: "Neplatný formát adresáře",
	t3478: "Akce",
	t3479: "Není k dispozici v režimu offline",
	t3480: "Nepodařilo se načíst databázi S55",
	t3481: "Načítání databáze S55",
	t3482: "Protokol",
	t3483: "Uživatel nemá oprávnění k zobrazení informací o softwaru",
	t3484: "Heslo musí být pouze alfanumerické nebo číselné",
	t3485: "Pro přístup do adresáře musíte vytvořit heslo adresáře",
	t3486: "Heslo adresáře není správné",
	t3487: "Stránka se již nachází v adresáři",
	t3488: "Chcete-li používat adresář, vytvořte heslo adresáře. Heslo musí být pouze alfanumerické nebo číselné",
	t3489: "Zadejte heslo svého adresáře",
	t3490: "Nejsou k dispozici žádná online zařízení ke kopírování",
	t3491: "Vytvořte heslo adresáře",
	t3492: "Obnovit heslo adresáře",
	t3493: "Heslo webu nesmí být prázdné",
	t3494: "Zadejte heslo importovaného adresáře",
	t3495: "Heslo adresáře bylo úspěšně vytvořeno",
	t3496: "Heslo adresáře bylo úspěšně aktualizováno",
	t3497: "Heslo importovaného adresáře není správné",
	t3498: "Zadejte heslo webu",
	t3499: "Vymazat adresář",
	t3500: "Prohledejte stránky",
	t3501: "Vyberte soubor adresáře, který chcete importovat",
	t3502: "Vyberte soubor klíče pro dešifrování adresáře",
	t3503: "Stisknutím exportovat stáhnete šifrovaný adresář",
	t3505: "Se zašifrovaným",
	t3506: "nešifrovaná hesla",
	t3507: "Neplatný tajný klíč",
	t3508: "Import se nezdařil. Ujistěte se, že jste importovali správný klíč",
	t3509: "Žádné dostupné verze",
	t3510: "Otevřít služby Alsense (vyžaduje předplatné)",
	t3511: "Přečtěte si prosím pozorně podmínky EULA. Pokud nemůžete přijmout podmínky, nemůžete aplikaci používat.",
	t3512: "Adresář a heslo adresáře byly úspěšně vymazány",
	t3513: "(požadovaný)",
	t3515: "Adresář je prázdný",
	t3516: "Heslo adresáře nemůže být prázdné",
	t3519: "Vymazáním adresáře odstraníte také heslo adresáře.",
	t3520: "Není v dosahu. Mělo by být mezi 0 a {{maxAddr}}",
	t3521: "Stisknutím tlačítka „Exportovat“ stáhnete všechny grafické pohledy uložené ve správci systému",
	t3522: "Stisknutím tlačítka Stáhnout stáhněte systémový přehled",
	t3523: "Na přímém WiFi připojení je k dispozici pouze hlavní jednotka",
	t3524: "Neexistují žádné místní grafické pohledy. Přidejte nové nebo importujte existující výběry dat",
	t3525: "Neexistují žádné vzdálené grafické pohledy. Zkuste hledat pod jiným jménem",
	t3526: "Neexistují žádné vzdálené grafické pohledy. Přidejte nové, importujte nebo migrujte existující pohledy",
	t3527: "Místní zobrazení ( {{totalFiles}} z 5)",
	t3528: "{{totalFiles}} místní grafiky z maximálního počtu 5",
	t3529: "Vzdálené pohledy",
	t3530: "Soubor není bitmapa.",
	t3531: "Špatná délka bitmapy.",
	t3532: "Špatná velikost záhlaví bitmapy.",
	t3533: "Špatná velikost bitmapy.",
	t3534: "Chyba ve velikosti palety.",
	t3535: "Nekompatibilní soubor .bmp",
	t3536: "Chyba při mazání místního grafického souboru",
	t3537: "Chyba při duplikování místního grafického souboru",
	t3538: "Chyba při exportu místního grafického souboru",
	t3539: "Smazat grafiku",
	t3540: "Odstraňte místní grafický soubor",
	t3541: "Probíhá odstraňování místního grafického souboru",
	t3542: "Místní grafický soubor byl úspěšně odstraněn. Restartování správce systému",
	t3543: "Mazání místního grafického souboru bylo dokončeno. Správce systému byl úspěšně restartován",
	t3544: "Duplicitní grafika",
	t3545: "Duplikujte místní grafický soubor",
	t3546: "Probíhá duplikát místního grafického souboru",
	t3547: "Místní grafický soubor byl úspěšně duplikován. Restartování správce systému",
	t3548: "Duplikát místního grafického souboru byl dokončen. Správce systému byl úspěšně restartován",
	t3549: "Export grafiky",
	t3550: "Exportujte místní grafický soubor",
	t3551: "Probíhá export místního grafického souboru",
	t3552: "Místní grafický soubor byl úspěšně exportován",
	t3553: "Správce systému se po této akci restartuje",
	t3554: "Opravdu chcete smazat?",
	t3555: "Jste si jisti, že chcete duplikovat?",
	t3556: "Stisknutím tlačítka „Exportovat“ stáhnete všechny místní grafické pohledy uložené ve správci systému",
	t3557: "Probíhá export místních grafických souborů",
	t3558: "Místní grafické soubory byly úspěšně exportovány",
	t3559: "Kód produktu",
	t3560: "Získejte více informací o tomto produktu",
	t3561: "Ventilátor eko",
	t3562: "Otevření ventilu",
	t3563: "Přehřátí",
	t3564: "Tlak výparníku",
	t3565: "Teplota výparníku",
	t3566: "Výřez",
	t3567: "Cutin",
	t3568: "Dveře pouzdra",
	t3569: "Teplota sání",
	t3570: "Železniční teplo",
	t3571: "Bylo dosaženo maximálního povoleného limitu souborů. Zrušte výběr souborů k importu",
	t3572: "Chcete-li importovat soubory, odstraňte existující soubory ve správci systému",
	t3573: "Místní grafický soubor byl úspěšně importován. Restartování správce systému",
	t3574: "Import místních grafických souborů byl dokončen. Správce systému byl úspěšně restartován",
	t3575: "Bylo dosaženo maximálního povoleného limitu souborů. Smažte soubor(y), které chcete importovat",
	t3576: "Maximální povolený počet grafických souborů je 5",
	t3577: "Místní grafický soubor byl úspěšně nahrán. Restartování správce systému",
	t3578: "Nahrávání místního grafického souboru bylo dokončeno. Správce systému byl úspěšně restartován",
	t3579: "Neplatný formát souboru. nahrajte soubor {{supportedFileFormats}}",
	t3580: "Rozměry souboru musí být {{maxWidthAllowed}} x {{maxHeightAllowed}} pixelů pro místní obrazovky",
	t3581: "Vzdálený pohled",
	t3582: "Místní zobrazení",
	t3583: "Domovská stránka webu Storeview",
	t3584: "Podmínky EULA",
	t3585: "Zabezpečení",
	t3586: "Úrovně prosazování zabezpečení",
	t3587: "Zpětně kompatibilní (nejnižší úroveň zabezpečení – za předpokladu, že je zakázáno ověřování záhlaví)",
	t3588: "Požadavky SvW / XML obsahují uživatelské jméno a heslo.",
	t3589: "Žádná dezinfekce požadavků, stejné zabezpečení jako předchozí verze",
	t3590: "Nejsou potřeba žádné změny na SvW nebo existujících XML připojeních",
	t3591: "Permisivní (lze použít jako přechod na přísnou úroveň)",
	t3592: "Požadavky XML mohou obsahovat uživatelské jméno a heslo nebo token relace. Token relace je přijat v odpovědi na žádost o přihlášení.",
	t3593: "Pokud je token relace uveden v záhlaví AKSM-auth, uživatelská jména a hesla jsou ignorována",
	t3594: "Požadavek XML je vyčištěn, v odpovědi na požadavek se vrátí varování, pokud byly zjištěny nějaké problémy",
	t3595: "Při přípravě na přechod do přísného režimu vezměte na vědomí tato varování",
	t3596: "Pokud není použit token relace, nelze použít vypršení platnosti relace",
	t3597: "Již podporováno ve vzdáleném uživatelském rozhraní (SvW)",
	t3598: "Možné / požadované změny z pohledu XML třetích stran",
	t3599: "Žádné použití tokenu relace – nejsou potřeba žádné změny",
	t3600: "Využití tokenu relace – odeberte uživatelské jméno / heslo z těla a dodržujte varování při přípravě na Strict",
	t3601: "Přísné (nejvyšší úroveň zabezpečení)",
	t3602: "Požadavky XML nemohou obsahovat uživatelská jména a hesla, ale musí poskytnout token relace v hlavičce AKSM-auth. Pokud je v požadavku zjištěn problém, bude zamítnut",
	t3603: "Již podporováno v (SvW)",
	t3604: "Požadované změny z pohledu třetí strany",
	t3605: "uživatelské jméno a heslo musí být odstraněno z datové části požadavku XML a token relace musí být umístěn v hlavičce CORS s názvem AKSM-auth",
	t3606: "Selhání přihlášení Back-off",
	t3607: "Jak dlouho (v sekundách) čeká vzdálený uživatel, než se bude moci znovu pokusit o přihlášení po neúspěšném pokusu. Čekací doba se zvyšuje kumulativně s každým neúspěšným pokusem. Po 10 pokusech je IP adresa, ze které se uživatel pokusil přihlásit, na 24 hodin zablokována. Je-li nastaveno na 0, je zpětné vypnutí při selhání přihlášení zakázáno",
	t3608: "Výchozí 30 s (max. 600 s / 0 = zakázáno)",
	t3609: "Bezplatné pokusy",
	t3610: "Kolik pokusů o přihlášení je povoleno, než se použije back-off",
	t3611: "Výchozí 2 (max. 5)",
	t3612: "Blok",
	t3613: "Pokud je povoleno User + IP jsou blokovány během back-off, v opačném případě je uživatel blokován",
	t3614: "Výchozí „uživatel“ (uživatel + IP / uživatel)",
	t3615: "Počet relací na uživatele",
	t3616: "Relace je místo, kde se uživatel úspěšně přihlásil do jednotky a nachází se ve vzdáleném rozhraní (SvW)",
	t3617: "Počet aktivních relací, které uživatel může mít. Pokud je nastaveno na 0, uživatel může mít maximální počet relací (30)",
	t3618: "Výchozí 0",
	t3619: "Vypršení platnosti relace",
	t3620: "Kolik sekund po vypršení nečinné relace. Pokud relace vyprší, je potřeba nové přihlášení",
	t3621: "Výchozí 900 s (max. 3 600)",
	t3622: "Ověření záhlaví",
	t3623: "Při povolení HTTPS a ověřování záhlaví musí všechny požadavky XML třetích stran předat přihlašovací údaje uživatelského jména / hesla v záhlaví (základní schéma ověřování)",
	t3624: "Povolení „Use Header Authentication“ vyžaduje reinicializaci systému. K provedení této akce použijte tlačítko 'Press to Initialize'",
	t3625: "Malý",
	t3626: "Velký",
	t3627: "Uložit grafiku",
	t3628: "Uložte místní grafický soubor",
	t3629: "Místní grafický soubor byl úspěšně odstraněn. Probíhá načítání místního grafického souboru",
	t3630: "Místní grafický soubor byl úspěšně načten. Restartování správce systému",
	t3631: "Ukládání místního grafického souboru bylo dokončeno. Správce systému byl úspěšně restartován",
	t3632: "Opravdu chcete uložit?",
	t3633: "Analýza se nezdařila",
	t3634: "Soubor viz nelze analyzovat",
	t3635: "Nelze analyzovat soubor vz2",
	t3636: "Otevření souboru Viz se nezdařilo:",
	t3637: "Soubory migrace (vz2, dpj nebo json) nebyly nalezeny",
	t3638: "Soubory migrace (viz) nebyly nalezeny",
	t3639: "Správce systému se po této akci restartuje. Správce systému je buď v permisivním nebo přísném režimu, takže token relace vyprší po restartu a budete odhlášeni ze systému",
	t3643: "Vypouštěcí tlak",
	t3644: "Celkový kW",
	t3645: "Celkový KVAR",
	t3646: "Celkem Kvarh+",
	t3647: "Požadovat",
	t3648: "Účiník",
	t3649: "Barva písma",
	t3650: "Barva pozadí",
	t3651: "Adresář byl úspěšně exportován",
	t3652: "Režim ovládání",
	t3653: "Sc3 Venkovní teplota",
	t3654: "Teplota výtlaku",
	t3655: "Provozní kapacita kondenzátoru",
	t3656: "Žádná data k exportu",
	t3657: "Platnost hesla uživatele na {{unit}} vypršela",
	t3658: "Nelze se přihlásit k {{unit}} pomocí zadaných přihlašovacích údajů.",
	t3659: "Aktualizujte verzi firmwaru nebo změňte úroveň vynucení zabezpečení pro {{unit}} abyste získali podporu tokenu relace.",
	t3661: "V3gc",
	t3662: "Oddíl B",
	t3663: "Sekce C",
	t3664: "Adaptivní regulace přehřátí",
	t3665: "Důvod stavu kontroly",
	t3666: "Uživatel nemůže být přihlášen. Adresa jednotky není nastavena v konfiguraci proxy",
	t3667: "V konfiguraci proxy není uveden komunikační protokol. Může to vést k chybám",
	t3668: "Informace o bezpečnosti IT",
	t3669: "V souladu s bezpečnostními standardy je nyní System Manager nastaven na přísné řízení relace, což znamená, že jakékoli vzdálené připojení / vzdálená služba založená na XML již nebude fungovat, pokud nebudou použity tokeny relace.",
	t3670: "Společnost Danfoss doporučuje pokračovat v přísném režimu, ale režim zpětné kompatibility je stále k dispozici.",
	t3671: "Všimněte si, že to lze změnit v nabídce Zabezpečení konfigurace a nemá to žádný vliv na místní ovládání správce systému a provoz na místě.",
	t3672: "Další informace o ovládání relace",
	t3673: "Zpětný režim",
	t3674: "Přísný režim",
	t3675: "Po kliknutí na režim zpětně kompatibilní se všechny příslušné správce systému s aktualizovaným firmwarem nastaví do režimu zpětně kompatibilní, restartují se a aplikace bude odhlášena.",
	t3676: "Maximální povolený počet sacích skupin je 12",
	t3677: "Po úspěšné aktualizaci vybraných jednotek z 3.3.0 na 4.X nebo vyšší bude uživatel automaticky odhlášen kliknutím na tlačítko Dokončit v posledním kroku.",
	t3678: "Multicase pro ovladače AK2 (Pack/Case) nejsou podporovány",
	t3679: "V souladu s bezpečnostními standardy zavedenými ve verzi 4.0 bude System Manager standardně nastaven na Strict session control, což znamená, že jakékoli vzdálené připojení / vzdálená služba založená na XML již nebude fungovat, pokud nebudou použity tokeny relace. Kromě toho bude komunikace automaticky upravena na HTTPS upgradem na rel. 4,0. Společnost Danfoss doporučuje pokračovat v přísném režimu, ale je k dispozici režim zpětné kompatibility, který nastaví systém zpět na dosud používaná nastavení. Upozorňujeme, že pokud tuto aktualizaci provádíte vzdáleně na jednotce HTTP, ujistěte se, že je vaše nastavení IT připraveno na HTTPS (port 443) nebo je na místě k dispozici personál.",
	t3680: "Více informací o nové implementaci zabezpečení naleznete zde",
	t3681: "Přístup k http verzi tohoto webu může být zablokován z důvodu omezení vašeho prohlížeče. Navštivte naši stránku s častými dotazy, kde najdete pokyny k vyřešení tohoto problému, nebo si stáhněte desktopovou verzi StoreView Web.",
	t3682: "Nejčastější dotazy správce systému",
	t3683: "u17 Ther. vzduch",
	t3684: "u91 Vypínací tepl.",
	t3685: "u00 Ctrl. stát",
	t3686: "u12 S3 tepl.",
	t3687: "u16 S4 tepl.",
	t3688: "u23 EEV OD %",
	t3689: "u21 Přehřátí",
	t3690: "u22 PřehřátíRef",
	t3691: "u26 EvapTemp Te",
	t3692: "S2 Výstupní tepl.",
	t3693: "Stw8",
	t3694: "Sd-MT",
	t3695: "Stav Tw",
	t3696: "Tw povolit",
	t3697: "Tw čerpadlo běží",
	t3698: "Stw3",
	t3699: "Stw4",
	t3700: "Stw2",
	t3701: "Rcv. stav kontroly",
	t3702: "Offset sání",
	t3703: "Režim ovládání Vrec",
	t3704: "Stav Vrec",
	t3705: "Důvod stavu Ctrl",
	t3706: "Stav ovládání Hp",
	t3707: "Pgc max.",
	t3708: "Pgc max. limit P-ban",
	t3709: "Pgc min.",
	t3710: "Kapacita parního ejektoru",
	t3711: "Kapalina ejektoru",
	t3712: "Referenční teplota vzduchu",
	t3713: "Teplota přiváděného vzduchu",
	t3714: "Zpětný vzduch",
	t3715: "Zónový rosný bod",
	t3716: "Zónová vlhkost",
	t3717: "Venkovní teplota",
	t3718: "Úroveň CO2 zóny",
	t3719: "Teplota zóny",
	t3720: "Cond. odkaz",
	t3721: "Cond. stav kontroly",
	t3722: "Cond. kontrolní stav",
	t3723: "Cond. ctrl.",
	t3724: "Cond. požadovaná čepice.",
	t3725: "Stav V3gc",
	t3726: "V3gc OD",
	t3727: "Cond. tepl.",
	t3728: "Hr stav",
	t3729: "Hr aktivovat",
	t3730: "HR Požadavek spotřebitele",
	t3731: "Shr3",
	t3732: "Shr4",
	t3733: "Shr2",
	t3734: "Shr8",
	t3735: "Ref. To-MT",
	t3736: "Stav kontroly MT",
	t3737: "Aktuální zóna MT",
	t3738: "Chcete-li zvolit. offset MT",
	t3739: "Provozní kapacita MT",
	t3740: "Požadovaná čepice. MT",
	t3741: "Čas na další krok MT",
	t3742: "Po-MT",
	t3744: "Teplota sání To-MT",
	t3745: "Celková aktivní energie",
	t3746: "Špičková poptávka",
	t3747: "Celkový účiník",
	t3748: "Volty fáze A",
	t3749: "Volty fáze B",
	t3750: "Volty fáze C",
	t3751: "Zesilovače fáze A",
	t3752: "Zesilovače fáze B",
	t3753: "Zesilovače fáze C",
	t3754: "Celkový činný výkon",
	t3755: "Teplota sání To-LT",
	t3756: "Teplota sání Do-IT",
	t3757: "Referenční sání LT",
	t3758: "Kontrolní stav LT",
	t3759: "Aktuální zóna LT",
	t3760: "Chcete-li zvolit. offset LT",
	t3761: "Provozní kapacita LT",
	t3762: "Požadovaná čepice. LT",
	t3763: "Čas na další krok LT",
	t3764: "Po-LT",
	t3765: "Sd-LT",
	t3766: "Referenční sání IT",
	t3767: "Stav kontroly IT",
	t3768: "Aktuální zóna IT",
	t3769: "Provozní kapacita IT",
	t3770: "Požadovaná kapacita IT",
	t3771: "Čas na další krok IT",
	t3772: "Tn Po-IT",
	t2773: "Sd-IT",
	t2774: "IT Comp. start",
	t3774: "Není v dosahu. Mělo by být mezi {{minAddr}} až {{maxAddr}}",
	t3775: "Provoz pro toto zařízení zakázán",
	t3776: "Po této akci se aplikace odhlásí, což může chvíli trvat.",
	t3777: "Soubory migrace (flp nebo json) nebyly nalezeny",
	t3778: "Průzkum",
	t3779: "Hlasování je momentálně pozastaveno.",
	t3780: "Přejete si obnovit hlasování?",
	t3781: "Měřič energie",
	t3782: "Vybráno více uzlů",
	t3783: "Přiblížit na uzel",
	t3784: "Měrné jednotky",
	t3785: "Soubory byly úspěšně exportovány",
	t3786: "Adresa nesmí být záporná",
	t3787: "Obnovit přiblížení",
	t3788: "Chyba při načítání kroků přípravného vzoru",
	t3789: "Chyba při ukládání kroků vzoru přípravy",
	t3790: "Kroky pracovního vzoru byly úspěšně uloženy",
	t3791: "Inscenační vzor",
	t3792: "Stisknutím ikon vyložení povolíte/zakážete vykladače přidružené ke kompresorům",
	t3793: "Chybějící data",
	t3794: "Následující parametry nevrátily žádné datové body",
	t3795: "Důvěryhodnost HTTPS/SSL byla úspěšná.",
	t3796: "Nyní se můžete přihlásit do webového prohlížeče StoreView.",
	t3797: "Přesměrování do webového prohlížeče StoreView za {{timeLeft}} sekund...",
	t3798: "Hlasování je momentálně pozastaveno!",
	t3799: "Vymazané alarmy (úplné systémové protokoly)",
	t3800: "Maximální povolený počet HVAC je 45.",
	t3801: "Nepodařilo se získat protokol změn",
	t3802: "Připojování k úložišti...",
	t3803: "Vypršel časový limit připojení úložiště SCADA",
	t3804: "Připojení zařízení se nezdařilo",
	t3805: "Neplatné datum",
	t3806: "Vyberte jednotky pro stažení přehledu"
};
var cs = {
	lang: lang$g
};

const isProd = process.env.NODE_ENV === 'production';
const resources = {
  cs,
  da,
  nl,
  en,
  fr,
  de,
  hu,
  it,
  pl,
  pt,
  pr,
  ru,
  es,
  sp,
  sv,
  tr,
  zh
};
const initConfig = {
  debug: !isProd,
  resources,
  fallbackLng: 'en',
  defaultNS: 'lang',
  interpolation: {
    escapeValue: false
  }
};

export { initConfig, resources };

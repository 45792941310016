import { useLocation } from 'react-router-dom';
import { b64UriToObj } from 'utils/b64-object-serialisation';
import { useApp } from '@danfoss/etui-sm/context';
import React from 'react';

export function useLoginParams() {
  const [, dispatch] = useApp();
  const location = useLocation<{ prevPathName: string; prevSearch: string }>();

  /**
   * Login information about user that is kept in login= url query param
   * which is a url encoded base 64 string of JSON {user: ..., IP: ...}
   */
  const loginUrlInfo = React.useRef(location.state?.prevSearch || '');
  /**
   * Login information about proxy that is kept in proxy= url query param
   * which is a url encoded base 64 string of JSON {proxyAddrr: ..., ipAddress: ..., token: ...}
   */
  const proxyAsObject = b64UriToObj(
    new URLSearchParams(loginUrlInfo.current).get('proxy') || '',
  );

  const proxyString = proxyAsObject ? JSON.stringify(proxyAsObject) : '{}';

  React.useEffect(() => {
    dispatch({
      type: 'SET_PROXY',
      payload: proxyString,
    });
  }, [proxyString]);
}

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useTheme } from '@danfoss/etui-system';
import {
  Button,
  Form,
  Icon,
  icons,
  Notification,
  TextInput,
  Tooltip,
  useResponsive,
} from '@danfoss/etui-core';
import { Div, Span } from '@danfoss/etui-system-elements';
import { useAuth } from '@danfoss/etui-sm';
import { EditType } from 'pages/ConfigurationPageOld/Configuration/components/ConfigurationItemContentList/utils';
import { useConfiguration } from '../../context';
import { BaseEditProps } from './ConfigurationListItemEdit';
import { ConfigurationListitemEditInputAddon } from './ConfigurationListItemEditInputAddon';
import {
  checkPasswordUpdatedForCurrentUser,
  FORM_TEST_ID_PREFIX,
  getIsEventInForm,
} from './utils';
import { ConfigurationListItemEditDisplayValue } from './ConfigurationListItemEditDisplayValue';
import { ConfigurationListItemAddress } from './ConfigurationItemContentList';
import { editedItemByAddress, NodeTypes } from './utils/address-utils';
import { useConfigItemEdit } from './hooks/useConfigItemEdit';

const PASSWORD_PNUM = '4902';

const inputTypeByFieldType = {
  [EditType.Text]: 'text',
  [EditType.Password]: 'password',
  [EditType.Address]: 'number',
};

type BaseEditWithAddresses = BaseEditProps & {
  addresses?: ConfigurationListItemAddress[];
};

const ConfigurationListItemEditText = React.memo(
  function ConfigurationListItemEditText({
    index,
    name,
    item,
    onSave,
    onUnsavedChanges,
    isAuthorized,
    addonAnimatonDuration = 2000,
    type,
    addresses = [],
    isConfirmModalOpen,
  }: BaseEditWithAddresses) {
    const node = React.useRef<HTMLDivElement>();
    const theme = useTheme();
    const { t } = useTranslation();
    const [isSucceeded, setIsSucceeded] = React.useState(false);
    const { configurationDataItem } = useConfiguration();
    const { user } = useAuth();

    const valueProperty = 'value';
    const initialValue = item[valueProperty];
    const defaultValues = {
      [name]: initialValue,
    };
    const {
      control,
      setError,
      setValue,
      getValues,
      errors,
      reset,
      formState,
      handleSubmit,
      clearErrors,
    } = useForm();
    const [isUndoingChanges, setIsUndoingChanges] = React.useState(false);

    const { isSubmitting, dirtyFields } = formState;
    const { editMode, enableEditMode, disableEditMode } = useConfigItemEdit();
    const error = errors[name];
    const isFailed = !!error;
    const isDirty = !!dirtyFields[name];
    const maxLen = +item.size > 0 ? +item.size - 1 : +item.size;
    const isAddressField = type === EditType.Address;
    const isPasswordField =
      item.token?.toLowerCase().includes('password') ||
      item.pnum === PASSWORD_PNUM;

    const handleOnUnsavedChanges = () => {
      onUnsavedChanges({
        isOpen: true,
        onSaveChanges: () => {
          handleSubmit(handleOnSubmit)();
        },
        onCancelChanges: () => {
          setIsUndoingChanges(true);
        },
      });
    };

    const handleOnCancelEdit = (event, isOnBlur: boolean = false) => {
      if (isSubmitting || isSucceeded || isFailed) {
        return;
      }

      if (isUndoingChanges) {
        reset(defaultValues);
        disableEditMode();
        setIsUndoingChanges(false);
        return;
      }

      if (!isOnBlur && node.current.contains(event.target)) {
        return;
      }

      const values = getValues();
      const nextValue = values[name];
      const isFieldDirty = dirtyFields[name];

      if (isFieldDirty && initialValue !== nextValue) {
        if (isOnBlur && getIsEventInForm(event)) {
          handleOnSubmitEditText();
        } else {
          event.preventDefault();
          if (event.currentTarget instanceof HTMLInputElement) {
            event.currentTarget.focus();
            handleOnUnsavedChanges();
          }
          if (event.currentTarget instanceof Document) {
            const { activeElement } = event.currentTarget;
            activeElement.focus();
            handleOnUnsavedChanges();
          }
        }
      } else {
        reset(defaultValues);
        disableEditMode();
      }
    };

    const handleOnSubmitEditText = () => {
      const isPasswordUpdatedForCurrentUser =
        checkPasswordUpdatedForCurrentUser(
          item,
          configurationDataItem,
          item[valueProperty],
          user,
        );
      if (isPasswordUpdatedForCurrentUser) {
        handleOnUnsavedChanges();
      } else {
        handleSubmit(handleOnSubmit)();
      }
    };

    const handleOnSubmit = async (data: { [key: string]: string }) => {
      const newValue = data[name];

      if (!initialValue || (initialValue && newValue !== initialValue)) {
        try {
          if (isAddressField) {
            if (+newValue < 0) throw new Error(t('t3786'));
            const { addressLine } = editedItemByAddress(addresses, item);
            const options = {
              iotype: NodeTypes.NODE_TYPE_NA,
              node: newValue,
              mod: '0',
              point: '0',
            };
            await onSave({ ...addressLine, ...options }, { ival: '0' });
          } else {
            await onSave(item, {
              ival: newValue,
              value: initialValue,
              valueProperty,
              tmpValue: newValue,
            });
          }
          setIsSucceeded(true);
        } catch (e) {
          setError(name, { type: 'server' });
          !isPasswordField &&
            !isAddressField &&
            Notification.error({
              message: t('t85'),
              description: e.message,
              duration: 3,
              theme,
            });
        }
      }
    };

    React.useEffect(() => {
      reset(defaultValues);
    }, [item[valueProperty]]);

    React.useEffect(() => {
      if (error) {
        setTimeout(() => {
          clearErrors(name);
          reset(defaultValues);
        }, addonAnimatonDuration);
      }

      if (isSucceeded) {
        setTimeout(() => {
          setIsSucceeded(false);
        }, addonAnimatonDuration);
      }

      if (isUndoingChanges) {
        handleOnCancelEdit(null, false);
      }
    }, [error, isSucceeded, isUndoingChanges]);

    const inputType = !isPasswordField
      ? inputTypeByFieldType[type]
      : EditType.Password;
    const { screenIsAtMost } = useResponsive({
      xs: parseInt(theme.breakpoints[0], 10),
      sm: parseInt(theme.breakpoints[1], 10),
      md: parseInt(theme.breakpoints[2], 10),
      lg: parseInt(theme.breakpoints[3], 10),
    });
    const isXs = screenIsAtMost('xs');
    const isSm = screenIsAtMost('sm');
    const isMd = screenIsAtMost('md');
    const isLg = screenIsAtMost('lg');

    const spacing = isXs
      ? theme.spacing.xs - 19
      : isSm
      ? theme.spacing.sm - 43
      : isMd
      ? theme.spacing.md - 53
      : isLg
      ? theme.spacing.lg - 53
      : theme.spacing.spacingUnit - 58;

    return (
      <Div
        testId="configuration-list-item-edit-text"
        ml={item.pnum === PASSWORD_PNUM ? `${spacing}px` : '0px'}
        width={item.pnum === PASSWORD_PNUM ? '264%' : '100%'}
      >
        {isAuthorized ? (
          <Form
            onSubmit={event => {
              event.preventDefault();
              event.currentTarget.focus();
              handleOnSubmitEditText();
            }}
            styles={{ root: { position: 'relative' } }}
            testId={`${FORM_TEST_ID_PREFIX}${name}`}
          >
            <div key={index} ref={node}>
              {editMode ? (
                <Controller
                  control={control}
                  name={name}
                  defaultValue={defaultValues[name]}
                  render={({ onChange, value }) => (
                    <TextInput
                      type={inputType}
                      size="small"
                      autoComplete="off"
                      disabled={isSubmitting || isSucceeded || isFailed}
                      name={name}
                      defaultValue={value}
                      autoFocus={true}
                      value={value}
                      testId={name}
                      onChange={onChange}
                      maxLength={maxLen}
                      onKeyDown={event => {
                        if (event.key === 'Enter' && isConfirmModalOpen) {
                          return event.preventDefault();
                        }
                        if (event.key === 'Escape') {
                          handleOnCancelEdit(event, true);
                        }
                      }}
                      onFocus={enableEditMode}
                      onBlur={event => {
                        if (isDirty) {
                          if (!value) {
                            reset(defaultValues);
                          } else {
                            setValue(name, value);
                            handleOnCancelEdit(event, true);
                          }
                        } else {
                          handleOnCancelEdit(event, true);
                        }
                      }}
                      endInputAddon={
                        isSubmitting || isSucceeded || isFailed ? (
                          <ConfigurationListitemEditInputAddon
                            isSubmitting={isSubmitting}
                            isSucceeded={isSucceeded}
                            isFailed={isFailed}
                            styles={{
                              inputAddon: {
                                root: isSubmitting
                                  ? {
                                      top: '9px',
                                      width: '24px',
                                      height: '24px',
                                      right: `${theme.spacing.xxs}px`,
                                    }
                                  : {
                                      top: '5px',
                                      width: '32px',
                                      height: '32px',
                                      right: `${theme.spacing.xxs}px`,
                                    },
                              },
                            }}
                          />
                        ) : null
                      }
                      styles={{
                        label: error
                          ? { color: theme.palette.error.main }
                          : isSucceeded
                          ? { color: theme.palette.success.main }
                          : {},
                        input: error
                          ? { borderColor: theme.palette.error.main }
                          : isSucceeded
                          ? { borderColor: theme.palette.success.main }
                          : { pr: `${theme.spacing.xs}px` },
                      }}
                    />
                  )}
                />
              ) : (
                <ConfigurationListItemEditDisplayValue
                  onClick={enableEditMode}
                  onFocus={enableEditMode}
                  value={item.value}
                />
              )}
            </div>
            {editMode && isAddressField && maxLen > 0 && (
              <Div position="absolute" top="10px" left="-28px">
                <Tooltip
                  placement="left"
                  message={() => (
                    <Div display="inline-block">
                      {`${t('t3043')} `}
                      <strong>{maxLen}</strong>
                    </Div>
                  )}
                >
                  <Icon glyph={icons.INFO_CIRCLE} />
                </Tooltip>
              </Div>
            )}
            {editMode &&
              isDirty &&
              !isSubmitting &&
              !isSucceeded &&
              !isFailed && (
                <Div
                  display="flex"
                  alignItems="center"
                  position="absolute"
                  top="0"
                  right={['unset', '-62px']}
                  left={['-62px', 'unset']}
                  height="42px"
                >
                  <Button
                    variant="tertiary"
                    testId="configuration-editText-button"
                    onMouseDown={() => setIsUndoingChanges(true)}
                    small={true}
                  >
                    {t('t964')}
                  </Button>
                </Div>
              )}
          </Form>
        ) : (
          <Span>{item.value}</Span>
        )}
      </Div>
    );
  },
);

export { ConfigurationListItemEditText };

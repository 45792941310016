import { fetchXMLData, XML_ACTION, isXmlError } from '@danfoss/etui-sm-xml';
import { checkAuth } from './utils/check-auth.js';

async function loginUser(dispatch, user, password, url, targetAuthLevel) {
  try {
    dispatch({
      type: 'LOGIN_USER_REQUEST'
    });
    const response = await fetchXMLData({
      url,
      attributes: {
        action: XML_ACTION.AUTH,
        user,
        password
      }
    });
    const isAboveTargetAuthLevel = targetAuthLevel ? !checkAuth(response.authtype, targetAuthLevel) : false;
    if (response.authtype === '-1') {
      dispatch({
        type: 'LOGIN_PASSWORD_EXPIRED',
        payload: {
          strongPassword: response.strongpassword,
          passwordExpiredError: 't3171'
        }
      });
    } else if (response.authorization === '0') {
      dispatch({
        type: 'LOGIN_USER_ERROR',
        payload: 't2258'
      });
    } else if (isAboveTargetAuthLevel) {
      dispatch({
        type: 'LOGIN_USER_ERROR',
        payload: 't3117'
      });
    } else {
      dispatch({
        type: 'LOGIN_USER_SUCCESS',
        payload: {
          user,
          password,
          authtype: response.authtype,
          language: response.language,
          strongpassword: response.strongpassword,
          sessionToken: response.session_token
        }
      });
    }
  } catch (error) {
    dispatch({
      type: 'LOGIN_USER_ERROR',
      payload: isXmlError(error) ? error.message : 't2259'
    });
  }
}
async function changePassword(dispatch, user, password, old_password, url) {
  try {
    dispatch({
      type: 'CHANGE_PASSWORD_REQUEST'
    });
    const response = await fetchXMLData({
      url,
      attributes: {
        action: XML_ACTION.UPDATE_PASSWORD,
        user,
        password,
        old_password
      }
    });
    if (response.return_code === '0') {
      dispatch({
        type: 'CHANGE_PASSWORD_RESPONSE',
        payload: 't3178'
      });
    } else {
      dispatch({
        type: 'CHANGE_PASSWORD_RESPONSE',
        payload: 't3179'
      });
    }
  } catch (error) {
    dispatch({
      type: 'CHANGE_PASSWORD_RESPONSE',
      payload: isXmlError(error) ? error.message : 't2259'
    });
  }
}
async function logoutUser(authDispatch, unitDispatch, appDispatch) {
  authDispatch({
    type: 'LOGOUT_USER'
  });
  unitDispatch === null || unitDispatch === void 0 ? void 0 : unitDispatch({
    type: 'CLEAR_UNITS'
  });
  appDispatch === null || appDispatch === void 0 ? void 0 : appDispatch({
    type: 'SET_BE_IP',
    payload: ''
  });
}
async function resetLogoutUser(authDispatch) {
  authDispatch({
    type: 'RESET_LOGOUT_USER'
  });
}
async function clearPreferenceOnLogout(appDispatch) {
  appDispatch({
    type: 'SET_PREFERRED_URL',
    payload: ''
  });
}

export { changePassword, clearPreferenceOnLogout, loginUser, logoutUser, resetLogoutUser };

import * as React from 'react';
import { Unit, UnitNetwork } from '@danfoss/etui-sm-xml';
import { DefaultTheme, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { A, P } from '@danfoss/etui-system-elements';
import { getUnitDetails } from 'pages/InfoPage/utils';
import { getArray } from 'pages/ConfigurationPage';
import { UnitInfoType } from '@danfoss/etui-sm';

export interface OfflineUnitErrorProps {
  network: UnitNetwork;
  isHttp: boolean;
  units: Partial<Unit[]>;
  unitInfo: Partial<UnitInfoType>;
}
export function OfflineUnitError({
  network,
  isHttp,
  units,
  unitInfo,
}: OfflineUnitErrorProps) {
  const theme: DefaultTheme = useTheme();
  const { t } = useTranslation();
  const link: string = getLink(isHttp, network);

  const trustCertificate = link => window.open(link, '_blank');

  const getIpLink = (
    isHttp: boolean,
    externalIp: string,
    externalPort: string,
  ): string => {
    const protocol: string = isHttp ? `http://` : `https://`;
    const ipPort: string = `${externalIp}:${externalPort}`;
    return protocol + ipPort;
  };

  const getExternalIpDetails = () => {
    return getIpLink(
      isHttp,
      getUnitDetails(getArray(unitInfo?.externalUnits), network).ip,
      getUnitDetails(getArray(unitInfo?.externalUnits), network).port,
    );
  };

  return (
    <>
      <P color={theme.palette.secondary.main}>
        {`${t('t128')} - `} {`${t('t3324')} `}
        <A
          fontWeight={700}
          color="black"
          textDecoration="underline"
          cursor="pointer"
          onClick={() =>
            trustCertificate(
              units && units[0]?.external_internet === '0'
                ? link
                : getExternalIpDetails(),
            )
          }
        >
          {units && units[0]?.external_internet === '0'
            ? link
            : getExternalIpDetails()}
        </A>
        {!isHttp && `, ${t('t3325')}`}
      </P>
    </>
  );
}

const getLink = (isHttp: boolean, network: UnitNetwork): string => {
  const protocol: string = isHttp ? `http://` : `https://`;
  const ipPort: string = `${network?.ip}:${network.port}`;
  return protocol + ipPort;
};

/* eslint-disable no-alert */
import { v4 as uuidv4 } from 'uuid';
import { STORAGE_ADDRESS_BOOK_KEY } from '../constants';
import {
  AddressBook,
  AddressBookForm,
  AddressBookItem,
  NOT_AVAILABLE,
} from '../types';
import { deleteCookie, getCookie, setCookie } from './cookie-helpers';
import { getAddressBookPasswordSHA, encrypt, decrypt } from './crypto-helpers';

export const ADDRESS_BOOK_VERSION = '1.1';
export const ADDRESS_BOOK_COOKIE_NAME = 'adrb';
export const CREDS_COOKIE_NAME = 'crd';

const MAX_RECENTS_SIZE = 5;
const CREDS_COOKIE_MAX_AGE_SECS = 100;

const isPromptCanceled = (text: string): boolean =>
  [null, undefined].some(value => value === text);

const areSitesEqual = (siteA: AddressBookItem, siteB: AddressBookItem) => {
  return ['ipAddress', 'user'].every(key => siteA[key] === siteB[key]);
};

const updateLastAccessedTime = (site: AddressBookItem) => {
  const userSites = getUserSites();
  const updatedUserSites = userSites.map(userSite => {
    if (areSitesEqual(userSite, site)) {
      return { ...userSite, lastAccessed: new Date() };
    }

    return userSite;
  });

  setUserSites(updatedUserSites);
};

const isAlreadyInStorage = (site: AddressBookItem) => {
  const sites = getUserSites();
  return sites.some(addressBookItem => areSitesEqual(addressBookItem, site));
};

const createSite = (
  { protocol, name, ipAddress, user, password }: AddressBookForm,
  addressBookPassword: string = '',
): AddressBookItem => {
  const site: AddressBookItem = {
    id: uuidv4(),
    protocol,
    name,
    ipAddress,
    user,
    lastAccessed: addressBookPassword ? NOT_AVAILABLE : new Date(),
    inAddressBook: !!addressBookPassword,
  };

  if (password && addressBookPassword) {
    site.password = encrypt(password, addressBookPassword);
  }

  return site;
};

const sortSitesByLastAccessedTime = (): AddressBookItem[] => {
  const userSites = getUserSites().filter(
    site => site.lastAccessed !== NOT_AVAILABLE,
  );

  return userSites.sort(
    (siteA, siteB) =>
      +new Date(siteB.lastAccessed) - +new Date(siteA.lastAccessed),
  );
};

const removeSite = (siteId: string) => {
  const userSites = getUserSites();
  const updatedUserSites = userSites.filter(site => site.id !== siteId);
  setUserSites(updatedUserSites);
};

const validateAddressBookPassword = (password: string): boolean =>
  /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9@&*]+)$/.test(password) ||
  /^\d+$/.test(password);

const compareAddressBookPassword = (masterPassword: string): boolean =>
  getAddressBookPasswordSHA(masterPassword) === getAddressBookPasswordHash();

const setHashToAddressBook = (hash: string) => {
  const userSitesObject = getUserSitesObject();
  localStorage.setItem(
    STORAGE_ADDRESS_BOOK_KEY,
    JSON.stringify({ ...userSitesObject, hash }),
  );
};

export const verifyAddressBookPassword = (addressBookPassword: string) => {
  const hash = getAddressBookPasswordHash();

  if (isPromptCanceled(addressBookPassword)) return '';

  if (addressBookPassword === '') throw new Error('t3516');

  if (!hash) {
    if (!validateAddressBookPassword(addressBookPassword))
      throw new Error('t3484');
    const passwordHash = getAddressBookPasswordSHA(addressBookPassword);
    setHashToAddressBook(passwordHash);
  } else {
    const isPasswordCorrect = compareAddressBookPassword(addressBookPassword);
    if (!isPasswordCorrect) throw new Error('t3486');
  }

  return addressBookPassword;
};

export const getRecentSites = (): AddressBookItem[] => {
  const sortedSites = sortSitesByLastAccessedTime();
  const nextAfterRecents = sortedSites[MAX_RECENTS_SIZE];

  if (nextAfterRecents && !nextAfterRecents.inAddressBook)
    removeSite(nextAfterRecents.id);

  return sortedSites.slice(0, MAX_RECENTS_SIZE);
};

export const addSiteToRecents = (siteData: AddressBookForm): void => {
  const site = createSite(siteData);
  if (isAlreadyInStorage(site)) {
    updateLastAccessedTime(site);
  } else {
    const userSites = getUserSites();
    delete site.password;
    setUserSites([...userSites, site]);
  }
};

export const getUserSites = (): AddressBookItem[] =>
  getUserSitesObject()?.data || [];

export const getAddressBookPasswordHash = () => getUserSitesObject().hash || '';

export const removeAddressBookPassword = () => {
  const addressBookObj = getUserSitesObject();
  delete addressBookObj.hash;
  localStorage.setItem(
    STORAGE_ADDRESS_BOOK_KEY,
    JSON.stringify(addressBookObj),
  );
};

export const setUserSites = (userSites: AddressBookItem[]) => {
  const hash = getAddressBookPasswordHash();
  localStorage.setItem(
    STORAGE_ADDRESS_BOOK_KEY,
    JSON.stringify({ version: ADDRESS_BOOK_VERSION, data: userSites, hash }),
  );
};

export const getUserSitesObject = (): AddressBook =>
  JSON.parse(localStorage.getItem(STORAGE_ADDRESS_BOOK_KEY)) || {};

export const addRecentSiteToAddressBook = (
  site: AddressBookItem,
  sitePassword: string,
  addressBookPassword: string,
): void => {
  const userSites = getUserSites();
  const enctyptedPassword = encrypt(sitePassword, addressBookPassword);
  const updatedUserSites = userSites.map(userSite => {
    if (userSite.id === site.id) {
      return {
        ...userSite,
        name: site.name || userSite.name,
        inAddressBook: true,
        password: enctyptedPassword,
      };
    }
    return userSite;
  });

  setUserSites(updatedUserSites);
};

export const decryptSitePassword = (
  passwordHash: string,
  key: string,
): string => {
  const password = decrypt(passwordHash, key);
  return password;
};

export const addSiteToAddressBook = (
  siteInfo: AddressBookForm,
  addressBookPassword: string,
) => {
  const site = createSite(siteInfo, addressBookPassword);

  if (isAlreadyInAddressBook(site)) {
    throw new Error('t3487');
  } else if (isAlreadyInStorage(site)) {
    const userSites = getUserSites();
    const siteToBeUpdated = userSites.find(userSite =>
      areSitesEqual(userSite, site),
    );
    addRecentSiteToAddressBook(
      siteToBeUpdated,
      siteInfo.password,
      addressBookPassword,
    );
  } else {
    const userSites = getUserSites();
    setUserSites([...userSites, site]);
  }
};

export const isAlreadyInAddressBook = (site: AddressBookItem) => {
  const addressBook = getAddressBook();
  return addressBook.some(addressBookItem =>
    areSitesEqual(addressBookItem, site),
  );
};

export const getAddressBook = (): AddressBookItem[] => {
  const userSites = getUserSites();
  return userSites.filter(site => site.inAddressBook);
};

export const editSiteInAddressBook = (
  siteId: string,
  { name, user, password, ipAddress, protocol }: AddressBookForm,
  addressBookPassword: string,
): void => {
  const userSites = getUserSites();
  const encryptedPassword = encrypt(password, addressBookPassword);
  const updatedUserSites = userSites.map(userSite => {
    if (userSite.id === siteId) {
      return {
        ...userSite,
        protocol,
        name,
        user,
        ipAddress,
        password: encryptedPassword,
      };
    }

    return userSite;
  });

  setUserSites(updatedUserSites);
};

export const removeSiteFromAddressBook = (siteId: string) => {
  const recentsIds = getRecentSites().map(site => site.id);
  if (recentsIds.includes(siteId)) {
    const userSites = getUserSites();
    const updatedUserSites = userSites.map(userSite => {
      if (userSite.id === siteId) {
        return { ...userSite, inAddressBook: false, password: '' };
      }

      return userSite;
    });

    setUserSites(updatedUserSites);
  } else {
    removeSite(siteId);
  }
};

export const validateAdressBookItems = (addressBook: AddressBookItem[]) =>
  addressBook.every(
    ({ user, password, ipAddress }) => user && password && ipAddress,
  );

export const getAddressBookObject = (
  addressBookPassword: string,
): AddressBook => {
  const hash = getAddressBookPasswordHash();
  const addressBook = getAddressBook();
  const encryptedAddressBook = addressBook.map(item => ({
    ...item,
    user: encrypt(item.user, addressBookPassword),
  }));

  return {
    version: ADDRESS_BOOK_VERSION,
    data: encryptedAddressBook,
    hash,
  };
};

export const mergeAddressBooks = (
  importedBook: AddressBookItem[],
  refreshAccessTimeOfNewItems: boolean = true,
) => {
  const recentSites = getRecentSites().filter(site => !site.inAddressBook);
  const currentAddressBook = getAddressBook();
  const allSites = recentSites.concat(currentAddressBook);
  const updatedSites = allSites.map(site => ({ ...site }));

  importedBook.forEach(importedSite => {
    const importedSiteCopy = { ...importedSite };
    const coincidence = updatedSites.find(site =>
      areSitesEqual(site, importedSite),
    );

    if (!coincidence) {
      importedSiteCopy.lastAccessed = refreshAccessTimeOfNewItems
        ? NOT_AVAILABLE
        : importedSite.lastAccessed;
      return updatedSites.push(importedSiteCopy);
    }

    coincidence.password = importedSite.password;
    if (!coincidence.inAddressBook && importedSite.inAddressBook) {
      coincidence.inAddressBook = true;
    }
  });

  setUserSites(updatedSites);
  deleteCookie(ADDRESS_BOOK_COOKIE_NAME);
};

export const reencryptAddressBook = ({
  sites,
  importedAddressBookPassword,
  addressBookPassword,
  addressBookVersion,
}: {
  sites: AddressBookItem[];
  importedAddressBookPassword: string;
  addressBookPassword: string;
  addressBookVersion: string;
}): AddressBookItem[] =>
  sites.map(site => {
    const sitePassword = importedAddressBookPassword
      ? decrypt(site.password, importedAddressBookPassword)
      : site.password;
    const reencryptedSitePassword = encrypt(sitePassword, addressBookPassword);
    const user =
      +addressBookVersion > 1
        ? decrypt(site.user, importedAddressBookPassword)
        : site.user;

    return {
      ...site,
      user,
      password: reencryptedSitePassword,
    };
  });

export const addSiteName = (
  siteInfo: Partial<AddressBookItem>,
  name: string,
) => {
  const userSites = getUserSites();
  const updatedUserSites = userSites.map(userSite => {
    if (
      areSitesEqual(userSite, siteInfo as AddressBookItem) &&
      !userSite.name
    ) {
      return { ...userSite, name };
    }
    return userSite;
  });

  setUserSites(updatedUserSites);
};

export const addAddressBookToCookie = () => {
  const addressBook = getUserSitesObject();
  setCookie(ADDRESS_BOOK_COOKIE_NAME, JSON.stringify(addressBook));
};

export const addCredsToCookie = ({ ipAddress, user, password }) => {
  setCookie(CREDS_COOKIE_NAME, JSON.stringify({ ipAddress, user, password }), {
    'max-age': CREDS_COOKIE_MAX_AGE_SECS,
  });
};

export const mergeCookieAddressBook = () => {
  const addressBookCookie = getCookie(ADDRESS_BOOK_COOKIE_NAME);
  const addressBookObj = addressBookCookie && JSON.parse(addressBookCookie);
  addressBookObj && mergeAddressBooks(addressBookObj.data || [], false);
};

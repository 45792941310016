import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  CommandBar,
  CommandBarItemProps,
  icons,
  Tabs,
  useResponsive,
} from '@danfoss/etui-core';
import {
  Skeleton,
  useMeasurementUnits,
  getFormattedTime,
} from '@danfoss/etui-sm';
import { useTheme } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';
import { lighten } from '@danfoss/etui-colors';
import { useTranslation } from 'react-i18next';
import { HelpPanel } from 'components/HelpPanel';
import { useConfiguration } from '../../context';
import {
  getMenuIdsByPathname,
  getParentMenuIdByPathname,
  getParentMenuItemsByPathname,
  getMenuItemsByMenuId,
} from '../../utils';
import { ConfigurationOptions } from '../../data/ConfigurationOptions';
import { createHelpTextList } from '../../../../ConfigurationPage/Configuration/components/ConfigurationItemHeader/utils/getHelpText';
import { getActiveMenuItemsByMenuId, FORMATTED_LABEL } from './utils';

type ConfigurationItemHeaderProps = {
  options?: ConfigurationOptions;
};

const SECURITY_TABLE_INDEX = '20091-4';

const ConfigurationItemHeader = React.memo(
  ({
    options: { showHeader, updateTimeByMenuId } = {},
  }: ConfigurationItemHeaderProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const {
      route,
      menuItemsById,
      subMenuItemsById,
      activeMenuItem: activeMenuId,
      menuLevelById,
    } = useConfiguration();
    const { timeFormat } = useMeasurementUnits();
    const { push, location } = useHistory();
    const menuId = React.useMemo(
      () => location.pathname.split('/').pop(),
      [location.pathname],
    );
    const { screenIsAtMost, screenIsAtLeast } = useResponsive({
      sm: parseInt(theme.breakpoints[1], 10),
      lg: parseInt(theme.breakpoints[3], 10),
    });
    const updateTime = updateTimeByMenuId?.[menuId];

    const handleOnTabsChange = React.useCallback(
      (value: string) => {
        const id = value.split('-');
        const tableId = id[0];

        const params = getMenuIdsByPathname(route, location.pathname);
        const newParams = params.filter(param => !param.includes(tableId));
        newParams.push(value);

        push(`${route}/${newParams.join('/')}`);
      },
      [route, location.pathname],
    );

    const isSmView = screenIsAtMost('sm', ['portrait', 'landscape']);
    const isLgView = screenIsAtLeast('lg', ['portrait', 'landscape']);
    const isRootLevel = menuLevelById[activeMenuId] === 0;
    const isFirstLevel = menuLevelById[activeMenuId] === 1;
    const isFirefox = window.navigator.userAgent.indexOf('Firefox') !== -1;

    const getCommandItems = (): CommandBarItemProps[] => {
      const menuLevel = menuLevelById[activeMenuId];

      const hasParentItems =
        getParentMenuItemsByPathname(route, location.pathname, menuItemsById)
          .length !== 0 ||
        getParentMenuItemsByPathname(route, location.pathname, subMenuItemsById)
          .length !== 0;

      const isSingleMenu = isFirstLevel || !menuLevel;

      const hasBackButton =
        (isSmView || menuLevel > 2) && (isSingleMenu || hasParentItems);

      const items = [];

      if (showHeader) {
        if (hasParentItems && (isSmView || !isSingleMenu)) {
          const menuItems = getActiveMenuItemsByMenuId(
            route,
            isRootLevel,
            activeMenuId,
            menuItemsById,
            subMenuItemsById,
            location.pathname,
          );
          const menuIds = menuItems.map(({ id }) => id);
          const menuItemsHasActiveMenuId = menuIds.includes(activeMenuId);

          items.push({
            key: 'deviceTabs',
            onRender: () =>
              menuItems.length ? (
                <Div style={{ position: 'relative' }}>
                  <Tabs
                    key="deviceTabs"
                    onChange={handleOnTabsChange}
                    value={menuItemsHasActiveMenuId ? activeMenuId : menuIds[0]}
                    styles={{
                      root: {
                        borderLeft: hasBackButton
                          ? `1px solid ${theme.palette.divider}`
                          : 'none',
                        overflow: 'hidden',
                        overflowX: isLgView ? 'hidden' : 'auto',
                        width: isFirefox
                          ? '-moz-available'
                          : '-webkit-fill-available',
                        position: 'fixed',
                      },
                    }}
                  >
                    {menuItems.map(item => (
                      <Tabs.Tab
                        key={`${item.label}-${item.id}`}
                        tab={item.label}
                        value={item.id}
                        styles={{ root: { borderBottom: 'none' } }}
                      />
                    ))}
                  </Tabs>
                </Div>
              ) : (
                <Div
                  p={`0 ${theme.spacing.md}px`}
                  display="flex"
                  alignItems="center"
                >
                  <Skeleton height={26} width="300px" />
                </Div>
              ),
          });
        } else if (isSingleMenu) {
          const parentMenuItems = getParentMenuItemsByPathname(
            route,
            location.pathname,
            menuItemsById,
          );
          const activeMenuItem =
            parentMenuItems &&
            parentMenuItems.find(menuItem => menuItem.id === activeMenuId);

          let label = '';
          if (activeMenuItem) {
            label = activeMenuItem.label;
          } else if (activeMenuId) {
            label = t(FORMATTED_LABEL[activeMenuId.replace('-', '')]);
          }

          items.push({
            key: 'tab',
            onRender: () => (
              <Tabs
                key="tab"
                onChange={handleOnTabsChange}
                value={activeMenuId}
                styles={{
                  root: {
                    borderLeft: hasBackButton
                      ? `1px solid ${theme.palette.divider}`
                      : 'none',
                  },
                }}
              >
                <Tabs.Tab
                  tab={label}
                  value={activeMenuId}
                  styles={{ root: { borderBottom: 'none' } }}
                />
              </Tabs>
            ),
          });
        }
      }

      if (hasBackButton) {
        const parentParentsId = getParentMenuIdByPathname(
          route,
          location.pathname,
          3,
        );

        const parentId = getParentMenuIdByPathname(route, location.pathname);

        const parentParentsMenuItems = getMenuItemsByMenuId(
          parentParentsId,
          menuItemsById,
        );

        const parent = parentParentsMenuItems.find(
          ppMenuItem => ppMenuItem.id === parentId,
        );

        const backButton = {
          key: 'back',
          iconProps: { glyph: icons.CHEVRON_DOWN, rotate: 90 },
          onClick: () => {
            const menuIds = getMenuIdsByPathname(route, location.pathname);
            const parentMenuLevel = menuLevelById[parentId];

            const newMenuIds = menuIds.slice(
              0,
              parentMenuLevel === 0 ? 1 : parentMenuLevel,
            );

            if (!parentMenuLevel || parentMenuLevel === 0) {
              push({
                pathname: route,
                state: { usedBackButton: true },
              });
            } else {
              push(`${route}/${newMenuIds.join('/')}`);
            }
          },
          iconOnly: true,
          styles: {
            root: { flexShrink: 0, width: 50 },
          },
        };

        if (parent) {
          const parentItem: CommandBarItemProps = {
            key: parent.id,
            onRender: () => (
              <Div
                display="flex"
                alignItems="center"
                borderLeft={`1px solid ${theme.palette.divider}`}
                bg={lighten(theme.palette.primary.main, 0.9)}
                p={`0 ${theme.spacing.sm}px`}
                whiteSpace="nowrap"
                color={theme.palette.text.disabled}
                {...theme.typography.button}
              >
                {parent.label}
              </Div>
            ),
          };
          return [backButton, parentItem, ...items];
        }

        return [backButton, ...items];
      }

      return [...items];
    };

    const farItems = React.useMemo(() => {
      const items = location.pathname.endsWith(SECURITY_TABLE_INDEX)
        ? [
            {
              key: 'help-panel',
              onRender: () => (
                <HelpPanel
                  title="Security"
                  customTemplate={createHelpTextList(theme, t)}
                />
              ),
            },
          ]
        : [];

      if (updateTime) {
        items.push({
          key: 'updateTime',
          onRender: () => (
            <Div
              display="flex"
              alignItems="center"
              p={`0 ${theme.spacing.md}px`}
              fontSize={theme.typography.fontSizeSmall}
              color={theme.palette.text.secondary}
            >
              {`${t('t354')}: ${getFormattedTime(timeFormat, updateTime)}`}
            </Div>
          ),
        });
      }

      return items;
    }, [location.pathname, updateTime]);

    return (
      <Div>
        <CommandBar items={getCommandItems()} farItems={farItems} />
      </Div>
    );
  },
);

export { ConfigurationItemHeader };

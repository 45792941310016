import * as React from 'react';
import {
  HeaderNavItem,
  HeaderNavItemProps,
  Icon,
  Badge,
  IconGlyph,
} from '@danfoss/etui-core';
import { usePopper } from 'react-popper';
import { useTheme } from '@danfoss/etui-system';
import { useOnClickOutside } from 'hooks';
import { Button, Div, Ul } from '@danfoss/etui-system-elements';
import { AppHeaderNavItemDropdownItem } from './AppHeaderNavItemDropdownItem';

export function AppHeaderNavItemDropdown({
  children,
  badgeText,
  badgeIcon,
  ...props
}: HeaderNavItemProps & { badgeText?: string; badgeIcon?: IconGlyph }) {
  const theme = useTheme();
  const [visible, setVisible] = React.useState(false);
  const referenceRef = React.useRef<HTMLDivElement>(null);
  const popperRef = React.useRef<HTMLDivElement>(null);

  useOnClickOutside(popperRef, event => {
    // TODO: CHECK IF THIS "IF" STATEMENT ISN'T DONE IN THE useOnClickOutside HOOK. I BELIEVE IT IS
    // MEANING THIS IS UNNECESSARY
    const reference = referenceRef.current;

    if (reference?.contains(event.target as Element)) {
      event.preventDefault();
    } else {
      setVisible(false);
    }
  });

  const { styles, attributes } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      placement: 'bottom-start',
      strategy: 'absolute',
      modifiers: [
        {
          name: 'computeStyles',
          options: {
            adaptive: false, // true by default
          },
        },
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [0, 0],
          },
        },
      ],
    },
  );

  function handleDropdownClick(e: any) {
    e.preventDefault();
    setVisible(!visible);
  }

  const containerStyle: React.CSSProperties = {
    ...styles.popper,
    display: visible ? 'flex' : 'none',
    zIndex: 999,
    flexDirection: 'column',
    backgroundColor: theme.palette.black[80],
    borderRadius: 2,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow:
      'rgba(0, 0, 0, 0.15) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.2) 0px 0.6px 1.8px 0px',
    width: 'max-content',
  };

  return (
    <>
      <HeaderNavItem
        {...props}
        props={{
          root: {
            elRef: referenceRef,
            tabIndex: 1,
            testId: 'appDropdown-headerNavItem',
          },
          a: { cursor: 'inherit', border: 'none' },
        }}
        onClick={handleDropdownClick}
        as={Button}
        active={visible}
      />
      {typeof badgeText === 'string' && (
        <Div position="absolute" top="10px" right="10px">
          <Badge text={badgeText} size="sm" isRound={true} />
        </Div>
      )}
      {typeof badgeIcon === 'string' && (
        <Div position="absolute" top="6px" right="6px">
          <Icon
            glyph={badgeIcon}
            styles={{
              root: {
                color: theme.palette.warning.main,
              },
            }}
          />
        </Div>
      )}
      <div ref={popperRef} style={containerStyle} {...attributes.popper}>
        <Div position="relative" overflow="hidden auto">
          <Ul listStyle="none" p={`${theme.spacing.xs}px 0`} m={0} role="menu">
            {children}
          </Ul>
        </Div>
      </div>
    </>
  );
}

AppHeaderNavItemDropdown.Item = AppHeaderNavItemDropdownItem;

import * as React from 'react';
import {
  Button,
  Modal,
  CommandBar,
  CommandBarItemProps,
  Tabs,
  useResponsive,
  icons,
  Row,
  Col,
} from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import { useTheme } from '@danfoss/etui-system';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  getUnitUrl,
  Unit,
  XML_DEVICE_COMBO,
  ConfigurationListItem as ConfigurationListItemProp,
  CONFIGURATION_REFRESH_INTERVAL,
  Device,
} from '@danfoss/etui-sm-xml';
import { useAlarm, useAuth, useXmlResource } from '@danfoss/etui-sm';
import { darken } from '@danfoss/etui-colors';
import { useHistory } from 'react-router-dom';
import {
  useConfigurationMenu,
  useConfigurationAuth,
  useConfigurationModals,
  useConfiguration,
} from '../..';
import {
  ConfigurationTabByUnitResponse,
  fetchConfigurationTabByUnit,
  fetchConfigurationTabsByUnit,
  writeConfigurationListItem,
} from '../../actions';
import {
  ConfigurationItemContentMenuDeviceGroupSelect,
  ConfigurationItemContentMenuDeviceSelect,
  ConfigurationItemContentMenuPageSelect,
} from '..';
import { withErrorHandled } from '../../utils/with-error-handled';
import { ConfigurationListItem } from './ConfigurationListItem';
import { getHtmlId, getAuditNames, getEditType, EditType } from './utils';
import { getAddresses } from './utils/address-utils';
import * as S from './styles';

const calculationPath = '20081-5';

export const debounceScroll = (func, timeout: number = 500) => {
  let timer;

  return scrollEvent => {
    clearTimeout(timer);
    scrollEvent.persist();

    timer = setTimeout(() => {
      func.call(null, scrollEvent);
    }, timeout);
  };
};

function ConfigurationListItemEditInModal({
  item,
  unit,
}: {
  unit: Unit;
  item: ConfigurationListItemProp;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    location: { pathname },
  } = useHistory();
  const { url } = useXmlResource();
  const { user } = useAuth();
  const { setShouldRequest } = useAlarm();
  const [someValue, forceUpdate] = React.useState(0);
  const itemId = item.name + item.li;
  const deleteItemId = `${itemId}-delete`;

  const {
    modalDevices,
    onSetModalDevice,
    modalDeviceGroup,
    onSetModalDeviceGroup,
    modalDeviceSubgroup,
    onSetModalDeviceSubgroup,
    modalPage,
    onSetModalPage,
  } = useConfigurationMenu();

  const {
    activeTab,
    onSetActiveTab,
    openModals,
    toggleModals,
    clearAndSetModals,
    modalScrollSettings,
    handleModalScrollSettings,
    removeModalScrollSettings,
  } = useConfigurationModals();

  const { cachedDevices, configurationDataItem } = useConfiguration();
  const isConfirmOpen = openModals.includes(deleteItemId);
  const isOpen = openModals.includes(itemId) && !isConfirmOpen;
  const currentActiveTab = activeTab[itemId];
  const isCalculationPage = pathname.includes(calculationPath);
  const selectedDevice = modalDevices[itemId] || ({} as Device);
  const [localUseParent, setLocalUseParent] = React.useState<'0' | '1'>('1');

  const { data: tabs = [] } = useSWR(
    () => (isOpen ? [url, unit, '', item.lta, user] : null),
    fetchConfigurationTabsByUnit,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: true,
    },
  );

  const { getItemIsAuthorized } = useConfigurationAuth(currentActiveTab, user);

  React.useEffect(() => {
    if (!isOpen) return () => {};

    setShouldRequest(false);
    return () => {
      setShouldRequest(true);
    };
  }, []);

  useDeepCompareEffect(() => {
    if (tabs.length) {
      onSetActiveTab(itemId, !currentActiveTab ? tabs[0] : currentActiveTab);
    }
  }, [tabs]);

  const handleOpen = () => {
    toggleModals(itemId);
  };

  const handleClose = () => {
    onSetActiveTab(itemId, null);
    onSetModalDevice(itemId, null);
    toggleModals(itemId);
    removeModalScrollSettings(itemId);
  };

  const {
    data = {} as ConfigurationTabByUnitResponse,
    isLoading,
    mutate,
  } = useSWR(
    () =>
      (isOpen && currentActiveTab) || isConfirmOpen
        ? [
            url + selectedDevice.name + currentActiveTab?.id + modalPage, // serves as key for useSWR
            url, // sourceUrl: string,
            unit, // unit: Unit,
            `${item.lta}-${currentActiveTab?.index}`, // tabId: string,
            user, // user
            cachedDevices,
            localUseParent, // useparent

            '0', // isconfigure
            selectedDevice.bpidx ? +selectedDevice.bpidx - 1 : '0', // bpidx
            selectedDevice.stype || '', // stype
            selectedDevice.nodetype || '0', // nodetype
            selectedDevice.node || '0', // node

            modalDeviceGroup || item.group, // item.group, // group
            modalDeviceSubgroup || item.subgroup, // subgroup
            modalPage, // page
            currentActiveTab?.combo, // combo
            currentActiveTab?.configuretype || '0', // configuretype

            selectedDevice.arg1 || item.combo || item.iVal || '0', // arg1
            selectedDevice.arg2 || '0', // arg2
            selectedDevice.arg3 || '0', // arg3
            null,
            currentActiveTab?.configuretype || '0', // old_cfgtype
            item.ltn, // listtabname

            item.pnum, // pnum
            item.pnum, // parentpnum
            item.li, // listindex
            item.li, // parentlistindex
            item.bnum, // bnum

            item.bnum, // parentbnum
            item.group, // parentgroup
            item.cpi, // calptindex
            item.group, // listgoup
            item.combo, // listcombo
          ]
        : null,
    // @ts-ignore
    ([cacheKey, ...args]) => fetchConfigurationTabByUnit(args),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: true,
      refreshInterval: isOpen ? CONFIGURATION_REFRESH_INTERVAL.DEFAULT : 0,
    },
  );

  const resetConfigSwrCache = () => {
    mutate({} as ConfigurationTabByUnitResponse);
  };

  const { list } = data;
  const scrollBlock = React.useRef(null);

  React.useLayoutEffect(() => {
    if (isOpen) {
      if (modalScrollSettings[itemId] && !scrollBlock.current) {
        forceUpdate(prev => prev + 1);
      }

      scrollBlock.current?.scrollTo({
        left: 0,
        top: modalScrollSettings[itemId],
      });
    }
  }, [someValue]);

  const onScroll = event => {
    const { scrollTop } = event.target;

    modalScrollSettings[itemId] !== scrollTop &&
      handleModalScrollSettings({
        modalName: itemId,
        scrollTop,
      });
  };

  const debouncedScroll = debounceScroll(onScroll, 100);

  const { screenIsAtMost } = useResponsive({
    sm: parseInt(theme.breakpoints[1], 10),
  });
  const isSmView = screenIsAtMost('sm');

  const handleSave = React.useCallback(
    async (listItem, { value, ival, fval = null }) => {
      const res = withErrorHandled(t, theme, writeConfigurationListItem)(
        getUnitUrl(url, unit),
        user,
        getAuditNames(list, parseInt(listItem.li, 10), ''),
        `-${item.lta}-${currentActiveTab?.index}`,
        selectedDevice,
        modalDeviceGroup,
        currentActiveTab?.configuretype || '0', // configuretype
        listItem,
        value,
        ival,
        fval,
        item,
        localUseParent,
      );

      return res;
    },
    [isOpen, item, list, unit, url, user],
  );

  const handleOnSetActiveTab = (tabIndex: string | number) => {
    onSetActiveTab(itemId, tabs[tabIndex]);
  };

  const handleModalDeviceChange = (newDevice: Device) => {
    const isSchedOrPointCalc = [
      XML_DEVICE_COMBO.COMBO_CALCS,
      XML_DEVICE_COMBO.COMBO_SCHEDS,
    ].includes(currentActiveTab?.combo);

    const newModalToShow = configurationDataItem
      .filter(listItem => getEditType(listItem) === EditType.ModalSubView)
      .find(listItem =>
        isSchedOrPointCalc
          ? +newDevice.comboindex === +listItem.combo
          : parseInt(listItem.bnum, 10) === parseInt(newDevice.bpidx, 10),
      );

    clearAndSetModals(newModalToShow.name + newModalToShow.li);
    onSetActiveTab(itemId, null);
    onSetModalDevice(itemId, null);
    handleModalDeviceGroupChange('0');
    setLocalUseParent('0');
  };

  const handleModalDeviceGroupChange = (newGroup: string) => {
    onSetModalDeviceGroup(newGroup);
    handleModalDeviceSubgroupChange('0');
  };

  const handleModalDeviceSubgroupChange = (newSubgroup: string) => {
    onSetModalDeviceSubgroup(newSubgroup);
    handleModalPageChange('0');
  };

  const handleModalPageChange = (newPage: string) => {
    onSetModalPage(newPage);
    removeModalScrollSettings(itemId);
  };

  // TODO extract device based dumb component
  function getCommandBarItems() {
    if (!currentActiveTab) {
      return [];
    }

    const { combo, stype } = currentActiveTab;
    const deviceMenuItems: CommandBarItemProps[] = [];

    if (
      combo !== XML_DEVICE_COMBO.COMBO_NONE &&
      combo !== XML_DEVICE_COMBO.COMBO_UNITS
    ) {
      deviceMenuItems.push({
        key: 'deviceSelect',
        onRender: () => (
          <ConfigurationItemContentMenuDeviceSelect
            unit={unit}
            combo={combo}
            stype={stype}
            menuId={item.lta}
            onDeviceChange={handleModalDeviceChange}
            selectedDevice={selectedDevice}
            selectedItem={item}
            isModal={true}
          />
        ),
      });

      if (data.groupnames?.length) {
        deviceMenuItems.push({
          key: 'deviceGroupSelect',
          onRender: deviceGroupItem => (
            <ConfigurationItemContentMenuDeviceGroupSelect
              name={deviceGroupItem.key}
              selectedValue={modalDeviceGroup}
              menugroups={data.groupnames}
              onChange={handleModalDeviceGroupChange}
            />
          ),
        });
      }
    }

    if (data.subgroupnames?.length > 1) {
      deviceMenuItems.push({
        key: 'deviceSubgroupSelect',
        onRender: i => (
          <ConfigurationItemContentMenuDeviceGroupSelect
            name={i.key}
            selectedValue={modalDeviceSubgroup}
            menugroups={data.subgroupnames}
            onChange={handleModalDeviceSubgroupChange}
          />
        ),
      });
    }

    if (Number(data.multipage) > 0) {
      deviceMenuItems.push({
        key: 'pageSelect',
        onRender: () => (
          <ConfigurationItemContentMenuPageSelect
            multipage={data.multipage}
            onPageChange={handleModalPageChange}
            selectedPage={modalPage}
          />
        ),
      });
    }

    return deviceMenuItems;
  }

  const addresses = React.useMemo(() => getAddresses(list), [list]);

  const toggleConfirmModal = () => toggleModals(deleteItemId);

  return (
    <>
      <Modal
        key={itemId}
        onClose={handleClose}
        isOpen={isOpen}
        header={<Modal.Header title={item.name} />}
        style={{
          content: {
            maxWidth: '90%',
            width: '99vw',
            maxHeight: '90%',
            height: '99vh',
            background: darken(theme.palette.common.bg, 0.04),
          },
          overlay: {},
        }}
      >
        <Div height="100%" display="flex" flexDirection="column">
          {tabs?.length > 1 && (
            <Tabs
              onChange={handleOnSetActiveTab}
              value={Number(currentActiveTab?.index)}
            >
              {tabs.map((tab, i) => (
                <Tabs.Tab
                  key={tab?.id}
                  tab={tab?.label}
                  value={i}
                  styles={{ root: { borderBottom: 'none' } }}
                />
              ))}
            </Tabs>
          )}
          <Div>
            <CommandBar
              items={getCommandBarItems()}
              styles={{
                root: { m: null },
                leftSide: { width: '100%' },
              }}
            />
          </Div>
          {!isLoading || Object.keys(selectedDevice).length ? (
            <Div
              flex="1"
              overflowY="auto"
              onScroll={debouncedScroll}
              elRef={scrollBlock}
            >
              {list?.map((l, i) => (
                <ConfigurationListItem
                  key={l.li}
                  item={l}
                  unit={unit}
                  isEditable={true}
                  isAuthorized={getItemIsAuthorized(l)}
                  htmlId={getHtmlId(l, i)}
                  onSave={handleSave}
                  addresses={addresses}
                  prefix="Modal-"
                  resetConfigSwrCache={resetConfigSwrCache}
                />
              ))}
            </Div>
          ) : null}
        </Div>
      </Modal>

      <ConfirmationModal
        isOpen={isConfirmOpen}
        toggleModal={toggleConfirmModal}
        onConfirm={handleSave}
        list={list}
        isLoading={isLoading}
      />

      <Div display="flex" justifyContent="space-between" alignItems="center">
        <Div mr={`${theme.spacing.sm}px`}>
          <S.PreWrapper>
            <pre>{item.value}</pre>
          </S.PreWrapper>
        </Div>
        <S.FloatingModalButton isSmView={isSmView}>
          <Button
            variant="secondary"
            testId="configuration-floatingModal-open-button"
            onClick={handleOpen}
            height="38px"
          >
            {t('t3000')}
          </Button>
        </S.FloatingModalButton>
        {isCalculationPage &&
        (!openModals.length || openModals[0].includes('delete')) ? (
          <Button
            iconOnly={true}
            iconProps={{
              glyph: icons.TRASH,
              size: 24,
            }}
            testId="configuration-floatingModal-delete-button"
            onClick={toggleConfirmModal}
            small={true}
            styles={{
              root: { p: `${theme.spacing.xs}px` },
            }}
          />
        ) : null}
      </Div>
    </>
  );
}

function ConfirmationModal({
  isOpen,
  list,
  onConfirm,
  toggleModal,
  isLoading,
}) {
  const handleClick = () => {
    onConfirm(list[0], {});
    if (!isLoading) {
      toggleModal();
    }
  };
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={toggleModal}
      header={<Modal.Header title={t('t214')} />}
      actionButtons={[
        {
          children: t('t45'),
          variant: 'secondary',
          onClick: toggleModal,
        },
        {
          children: t('t44'),
          variant: 'primary',
          onClick: handleClick,
          disabled: !list?.length,
        },
      ]}
    >
      <Row>
        <Col xs={12}>
          <Div>{t('t186')}</Div>
        </Col>
      </Row>
    </Modal>
  );
}

export { ConfigurationListItemEditInModal };

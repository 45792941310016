import * as React from 'react';
import { Label, TextInput } from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { STEPS } from './types';

export interface ConfigurationListItemEditStagingPatternStepLabelProps {
  stepValue: string;
  handleStepChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ConfigurationListItemEditStagingPatternStepLabel = ({
  stepValue,
  handleStepChange,
}: ConfigurationListItemEditStagingPatternStepLabelProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Div ml="10px">
        <TextInput
          styles={{
            root: { width: '15%', mt: '10px', mb: '10px' },
          }}
          type="number"
          label={t('t806')}
          defaultValue={stepValue}
          testId="staging-pattern-step-text-input"
          key={stepValue}
          onChange={handleStepChange}
          onFocus={e => {
            e.persist();
          }}
          autoFocus={true}
          min={STEPS.MIN_STEP}
          max={STEPS.MAX_STEP}
        />
      </Div>
      <Div testId="staging-pattern-step-label-div" ml="10px">
        <Label testId="staging-pattern-step-label">{t('t3792')}</Label>
      </Div>
    </>
  );
};

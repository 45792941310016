import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Tile,
  CommandBar,
  Icon,
  ColumnProps,
  icons,
  TableProps,
  H6,
  useResponsive,
  IconGlyph,
} from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import { A, Div, P } from '@danfoss/etui-system-elements';
import { ContentSpinner, EmptyState } from '@danfoss/etui-sm/components';
import { TableVirtualized } from 'components/TableVirtualized';
import { getIsEqual } from 'utils';
import { Link } from 'react-router-dom';
import { useQuery } from 'hooks';
import { useMeasurementUnits, getFormattedTime } from '@danfoss/etui-sm';

type BaseTileProps<T> = {
  rowKey: TableProps<T>['rowKey'];
  rowPropsGetter: (record: T) => { to: string };
  height: number;
  title: string;
  icon: IconGlyph;
  data: T[];
  columns: ColumnProps<T>[];
  isValidating: boolean;
  isMaximized: boolean;
  emptyStateText: string;
  rowCount?: number;
  error?: any;
  onMaximize: () => void;
  onMinimize: () => void;
  onRefreshClick: () => void;
  onTableRowClick?: (record: T) => void;
  isWidget?: boolean;
};

const BaseTile = React.memo(function BaseTile<T>({
  rowKey,
  rowPropsGetter = null,
  height,
  title,
  icon,
  columns,
  data,
  error,
  isValidating,
  isMaximized,
  rowCount = 2,
  emptyStateText,
  onMaximize,
  onMinimize,
  onRefreshClick,
  isWidget = false,
}: BaseTileProps<T>) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { timeFormat } = useMeasurementUnits();
  const { screenIsAtMost, screenIsAtLeast } = useResponsive({
    xs: parseInt(theme.breakpoints[0], 10),
    sm: parseInt(theme.breakpoints[1], 10),
    md: parseInt(theme.breakpoints[2], 10),
    lg: parseInt(theme.breakpoints[3], 10),
  });
  const isXs = screenIsAtMost('xs');
  const isSm = screenIsAtMost('sm');
  const isMd = screenIsAtMost('md');
  const isLg = screenIsAtLeast('lg');

  const [lastFetched, setLastFetched] = React.useState('');
  const [isReFetchingData, setIsReFetchingData] = React.useState(false);

  const isLoading = !data && !error;
  const isLoadingFailed = !data && error;
  const isEmpty = data && data.length === 0;
  const query = useQuery();

  const updateLastFetchTime = () => {
    const currentTime = getFormattedTime(timeFormat);
    setLastFetched(currentTime);
  };

  React.useEffect(() => {
    if (!isReFetchingData && data && !error) {
      updateLastFetchTime();
    }
    if (isReFetchingData && !isValidating) {
      updateLastFetchTime();
      setIsReFetchingData(false);
    }
  }, [data, error, isValidating, isReFetchingData]);

  const handleOnRefreshClick = () => {
    setIsReFetchingData(true);
    onRefreshClick();
  };

  const getRawContentHeight = () => {
    const spacingHeight =
      rowCount === 1
        ? theme.spacing.xlg + theme.spacing.xlg
        : theme.spacing.xlg + theme.spacing.xlg + theme.spacing.xlg;
    const totalHeight = height - spacingHeight;
    return Math.floor(totalHeight / rowCount - 50);
  };

  const tableWrappedlink = props => <Link component={A} {...props} />;

  const getTileContentHeight = () => {
    if (isWidget || isMaximized) {
      return height - 50;
    }

    const calculatedHeight = getRawContentHeight();
    return isXs || isSm || isMd ? 300 : isLg ? calculatedHeight : 300;
  };

  const tileContentHeight = getTileContentHeight();

  return (
    <Tile
      testIds={{
        content: `dashboard-tile-${
          (query.get('maximized') || title) ?? ''
        }-tile`,
      }}
      header={
        <CommandBar
          items={[
            {
              key: 'title',
              onRender: () => (
                <Div
                  key="title"
                  display="flex"
                  alignItems="center"
                  pl={theme.spacing.sm}
                >
                  <Icon
                    size={32}
                    glyph={icon}
                    styles={{
                      root: {
                        color: theme.palette.secondary.main,
                        ml: `-${theme.spacing.xxs}px`,
                        mr: `${theme.spacing.xxs}px`,
                      },
                    }}
                  />
                  <Div>
                    <H6 testId="baseTile-header" lineHeight={1.25}>
                      {title}
                    </H6>
                    <P
                      fontSize={theme.typography.pxToRem(10)}
                      color={theme.palette.text.secondary}
                      lineHeight={1.25}
                    >
                      {`${t('t354')}: ${lastFetched}`}
                    </P>
                  </Div>
                </Div>
              ),
            },
          ]}
          farItems={[
            {
              key: 'refresh',
              iconProps: { glyph: icons.REFRESH },
              onClick: handleOnRefreshClick,
              disabled: isLoading || isValidating || isReFetchingData,
              iconOnly: true,
              styles: {
                root: {
                  width: 50,
                },
              },
              text: t('t60'),
              tooltipProps: {
                message: t('t60'),
                placement: 'bottom',
              },
            },
            {
              key: 'maximize',
              iconProps: { glyph: isMaximized ? icons.COLLAPSE : icons.EXPAND },
              text: t('t3041'),
              tooltipProps: {
                message: t(isMaximized ? 't3091' : 't3041'),
                placement: 'left',
              },
              onClick: () => {
                if (isMaximized) {
                  onMinimize();
                } else {
                  onMaximize();
                }
              },
              disabled: isLoading || isValidating || isReFetchingData,
              iconOnly: true,
              styles: {
                root: {
                  width: 50,
                },
              },
            },
          ]}
          styles={{
            root: {
              width: '100%',
              boxShadow: 'none',
              zIndex: 'unset',
            },
          }}
        />
      }
      styles={{
        root: {
          position: isWidget || isMaximized ? 'absolute' : null,
          top: isWidget || isMaximized ? 0 : null,
          left: isWidget || isMaximized ? 0 : null,
          zIndex: isMaximized ? 1 : 'unset',
          mb:
            isWidget || isMaximized
              ? null
              : rowCount === 1
              ? [theme.spacing.xlg, null]
              : theme.spacing.xlg,
          boxShadow: theme.shadows[3],
        },
        header: {
          p: 0,
          height: 50,
        },
        content: {
          p: 0,
          height: tileContentHeight,
        },
      }}
    >
      {isReFetchingData && <ContentSpinner size={0} transparent={true} />}
      {isLoading ? (
        <ContentSpinner size={0} />
      ) : isLoadingFailed ? (
        <EmptyState title={t('t66')} />
      ) : isEmpty ? (
        <EmptyState title={emptyStateText} />
      ) : (
        <Div testId={`dashboard-tile-${query.get('maximized') ?? title}`}>
          <TableVirtualized
            rowKey={rowKey}
            columns={columns}
            dataSource={data || []}
            pagination={false}
            emptyPlaceholder={null}
            scroll={{ y: tileContentHeight }}
            rowAs={tableWrappedlink}
            rowPropsGetter={rowPropsGetter}
          />
        </Div>
      )}
    </Tile>
  );
},
getIsEqual);

export { BaseTile };

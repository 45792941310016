import * as React from 'react';
import { CommandBar, Tabs } from '@danfoss/etui-core';
import { ConfigurationTabItem } from '@danfoss/etui-sm-xml';
import { Div } from '@danfoss/etui-system-elements';

export interface DeviceConfigurationBaseHeaderProps {
  activeTabId: string;
  tabs: ConfigurationTabItem[];
  onTabChange: (tabId: string) => void;
}

function DeviceConfigurationBaseHeader({
  activeTabId,
  tabs = [],
  onTabChange,
}: DeviceConfigurationBaseHeaderProps) {
  return (
    <Div testId="commandBar">
      <CommandBar
        items={[
          {
            key: 'deviceTabs',
            onRender: i => (
              <Tabs
                key={i.key}
                testId={`deviceConfigurationCommandBar-${
                  i.key ?? ''
                }-device-tabs`}
                onChange={onTabChange}
                value={activeTabId}
              >
                {tabs.map((item, index) => (
                  <Tabs.Tab
                    key={item.id}
                    tab={item.label}
                    value={item.id}
                    props={{
                      root: { testId: `tab-${index}` },
                    }}
                  />
                ))}
              </Tabs>
            ),
          },
        ]}
        styles={{
          leftSide: { maxWidth: ['100%'], width: '100%' },
        }}
      />
    </Div>
  );
}

export { DeviceConfigurationBaseHeader };

import {
  ConfigurationListItem,
  XML_ACTION,
  fetchXMLData,
} from '@danfoss/etui-sm-xml';
import { HomeKey } from '@danfoss/etui-sm';
import { TABLE_ADDRESS } from 'pages/ConfigurationPage';
import { routePaths } from 'routePaths';
import useSWR from 'swr';

interface PreferredParameter {
  user: string;
  password: string;
}

interface PreferredHomeScreenResponseItems<ConfigurationListItem> {
  items?: {
    l?: ConfigurationListItem[];
  };
}

const HOME_SCREEN_KEY = 'home screen';

export const preferredHomeScreen = (module: Partial<ConfigurationListItem>) => {
  return module?.name?.trim().toLowerCase() === HOME_SCREEN_KEY;
};

export const fetchPreferredHomeScreen = async (
  url: string,
  parameter: PreferredParameter,
): Promise<Partial<ConfigurationListItem[]>> => {
  const response = (await fetchXMLData<ConfigurationListItem>({
    url,
    attributes: {
      ...parameter,
      lang: 'English',
      action: XML_ACTION.READ_LIST,
      tableaddress: TABLE_ADDRESS.HOME_SCREEN,
      table_name: '',
      storeview_only: '1',
      version: 'C',
      session_update: 'no',
    },
  })) as PreferredHomeScreenResponseItems<ConfigurationListItem>;
  return response.items?.l || [];
};

export const defaultHomeRedirect = (homeKey: HomeKey): string => {
  switch (homeKey) {
    case HomeKey.Home:
    case HomeKey.Custom:
      return routePaths.pages.home;
    case HomeKey.Equipment:
      return `${routePaths.pages.equipment.listPath}?device_type=refrig`;
    case HomeKey.GraphicViewer:
      return routePaths.pages.graphicViewer.home;
    default:
      return routePaths.pages.home;
  }
};

export const usePreferredScreen = (
  url: string,
  parameter: PreferredParameter,
) => {
  const userParam = { user: parameter?.user, password: parameter?.password };
  const { data, error, isLoading, mutate } = useSWR(
    parameter ? ['fetchPreferredHomeScreen', url, userParam] : null,
    ([_key, url, parameter]: [string, string, PreferredParameter]) =>
      fetchPreferredHomeScreen(url, parameter),
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      shouldRetryOnError: true,
    },
  );

  const preferredResultData: Partial<ConfigurationListItem> =
    data?.find(preferredHomeScreen) || {};
  const { iVal = HomeKey.Home } = preferredResultData;

  return {
    iVal,
    error,
    isLoading,
    mutate,
  };
};

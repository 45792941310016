import * as React from 'react';
import {
  RouteComponentProps,
  withRouter,
  useHistory,
  Link,
  useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSWRConfig } from 'swr';
import { Layout as CoreLayout } from '@danfoss/etui-core/Layout';
import {
  Header,
  HeaderBrandLogo,
  HeaderNavItem,
  HeaderNavItemProps,
} from '@danfoss/etui-core/Header';
import { SideMenu, SideMenuGroupProps } from '@danfoss/etui-core/SideMenu';
import { Icon, icons, Badge, useResponsive } from '@danfoss/etui-core';
import { Alert } from '@danfoss/etui-core/Alert';
import {
  useAuth,
  useAlarm,
  useUnit,
  useUnitSoftware,
  logoutUser,
  getIsMatchedSoftwareVersion,
  SOFTWARE_VERSION_PREFIX,
  ErrorBoundary,
  getSoftwareVersionPrefix,
  ContentError,
  softwareRequirements,
  getOlderSoftwareVersion,
  useModal,
  useApp,
  useUnitConfiguration,
  HomeKey,
} from '@danfoss/etui-sm';
import { useTheme } from '@danfoss/etui-system';
import { routePaths } from 'routePaths';
import { SettingsBrowserCertificateModal } from 'components/SettingsBrowserCertificateModal';
import { Div, Button } from '@danfoss/etui-system-elements';
import { useLocalStorageSet } from 'hooks/use-local-storage';
import { LANG_I18NEXT_KEY, SM_PROXY } from 'constants/local-storage-constants';
import { Flag, WithFlag, WithFlagDeep } from 'feature-flags';
import { Unit, resetProxyConfig } from '@danfoss/etui-sm-xml';
import { getIsAllowedScadaStorage } from 'utils/get-is-allowed-scada-storage';
import { getAppNameByUnits } from 'utils/get-app-name-by-units';
import { EquipmentTreeviewSelect } from 'components/EquipmentTreeviewSelect';
import { getSoftwareVersion } from 'utils/get-software-version';
import {
  useFeatureFlag,
  useFeatureHub,
} from 'hooks/featurehub/FeatureHubContext';
import ExperimentalSwitch from 'components/ExperimentalSwitcher/ExperimentalSwitcher';
import { defaultHomeRedirect } from 'components/App/utils/getPreferredRoute';
import { isDevMode, isExperimental } from '../../config';
import { AppHeaderNavItemDropdown } from '../AppHeaderNavItemDropdown';
import { AppLayoutProps } from './AppLayout.types';

const NO_CONFIG_ACCESS_AUTHTYPE = '00000000';

type Props = AppLayoutProps & RouteComponentProps<{}>;

type SoftwareRequirementIndexes = keyof typeof softwareRequirements;

function findOldestVersionForUnits(units: Unit[] = []) {
  return units
    .map(({ software }) => software)
    .reduce(getOlderSoftwareVersion, '');
}

const softwareVersionAlertMessageMap = {
  [SOFTWARE_VERSION_PREFIX.G03]: 't2201',
  [SOFTWARE_VERSION_PREFIX.G08]: 't2201',
  [SOFTWARE_VERSION_PREFIX.G09]: 't2200',
  [SOFTWARE_VERSION_PREFIX.G10]: 't2200',
};

function getAlertMessageKey(version: SOFTWARE_VERSION_PREFIX) {
  return softwareVersionAlertMessageMap[version] || '';
}

const AppLayout = React.memo(function Layout({
  children,
  onGetActiveMenuItem,
  onBackButtonClick,
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const params = useParams<{ equipmentId: string }>();
  const { location, push } = useHistory();
  const { dispatch: authDispatch, user } = useAuth();
  const { units, failedNetworks, unitInfo, dispatch: unitDispatch } = useUnit();
  const [state, appStateDispatch] = useApp();
  const { activeAlarmsRefs, ackedAlarmsRefs } = useAlarm();
  const { incompatibleUnits, nonRecommendedUnits } = useUnitSoftware(units);
  const [isUnsupported, setIsUnsupported] = React.useState(false);
  const activeAlarmsCount = activeAlarmsRefs.length;
  const ackedAlarmsCount = ackedAlarmsRefs?.length;
  const oldestVersion = findOldestVersionForUnits(nonRecommendedUnits);
  const oldestVersionPrefix = getSoftwareVersionPrefix(oldestVersion);
  const { client } = useFeatureHub();
  const unitsInfosEnabled = useFeatureFlag('UnitInfos');
  const scadaEditEnabled = useFeatureFlag('SCADAEdit');
  const browserCertificateEnabled = useFeatureFlag('BrowserCertificate');
  const alarmManagementWriteEnabled = useFeatureFlag('AlarmManagementWrite');
  const sysmtemConfigurationWriteEnabled = useFeatureFlag(
    'SystemConfigurationWrite',
  );
  const deviceConfigurationWriteEnabled = useFeatureFlag(
    'DeviceConfigurationWrite',
  );
  const deviceIntegrationEnabled = useFeatureFlag('DeviceIntegration');

  const userHasAccessToConfigPage = React.useMemo(() => {
    if (!user) return false;

    if (!Number(user.authtype)) return false;

    return !Number(user.authtype)
      .toString(2)
      .endsWith(NO_CONFIG_ACCESS_AUTHTYPE);
  }, [user]);

  const [showCertificateModal] = useModal(SettingsBrowserCertificateModal);
  const {
    privateIpInExternalUnit,
    publicIpInInternalUnit,
    connectedIpNotFound,
    macAddrSameForMasterSlaveUnits,
    multipleWarnings,
    latestOSVersionAvailable,
  } = useUnitConfiguration(units, unitInfo, state.xmlBackendIp, user);
  const { mutate } = useSWRConfig();
  const { preferredUrl } = state || {};
  const initialRoute: string = defaultHomeRedirect(preferredUrl as HomeKey);

  const alertMessageKey = getAlertMessageKey(
    oldestVersionPrefix as SOFTWARE_VERSION_PREFIX,
  );
  const enableSystemMenuForSM800: boolean = true;
  function filterOutFeatures({ flag }: { flag?: Flag }) {
    if (!flag) return true;
    return client.feature(flag).value;
  }

  React.useEffect(() => {
    if (incompatibleUnits.length) {
      setIsUnsupported(true);
      sessionStorage.clear();
    }
  }, [incompatibleUnits]);

  const { remove: removeLangKey } =
    useLocalStorageSet<string>(LANG_I18NEXT_KEY);
  const { remove: removeSmProxyKey } = useLocalStorageSet<string>(SM_PROXY);

  const { screenIsAtMost } = useResponsive({
    sm: parseInt(theme.breakpoints[1], 10),
  });
  const isSmView = screenIsAtMost('sm');

  const getHeaderBottomMenuItems = ():
    | WithFlag<HeaderNavItemProps>[]
    | undefined => {
    return onGetActiveMenuItem?.(location.pathname, params).filter(
      filterOutFeatures,
    );
  };

  const removeSmProxy = () => {
    removeSmProxyKey();
    resetProxyConfig();
  };

  const handleOnLogout = () => {
    logoutUser(authDispatch, unitDispatch, appStateDispatch);
    mutate(() => true, undefined, { revalidate: false });
    removeLangKey();
    removeSmProxy();
    push(routePaths.pages.login);
  };

  const handleOnBackButtonClick = () => {
    if (onBackButtonClick) {
      onBackButtonClick(location.pathname, params);
    }
  };

  const getTopMenuItems = () => {
    const topMenuItems: WithFlag<HeaderNavItemProps>[] = [
      {
        key: 'experimental-switch',
        // TODO: Add beta tester user flag ? (for now only dev mode)
        as: () => (isDevMode || isExperimental) && <ExperimentalSwitch />,
        props: {
          a: { id: 'switcher' },
        },
      },
      {
        key: 'alarms-count',
        flag: 'AlarmManagement',
        as: () => (
          <>
            <HeaderNavItem
              title={t('t10')}
              iconProps={{ glyph: icons.ALARM_URGENT }}
              props={{ root: { testId: 'layout-alarmCount-headerNavItem' } }}
              as={Link}
              to={routePaths.pages.alarms.listActivePath}
            />
            {(activeAlarmsCount > 0 || ackedAlarmsCount > 0) && (
              <Div
                position="absolute"
                top={`${theme.spacing.xs}px`}
                left={
                  !isSmView
                    ? `calc(${theme.spacing.xs * 4}px)`
                    : `${theme.spacing.xlg}px`
                }
                zIndex={10}
                pointerEvents="none"
              >
                <Badge
                  iconOnly={true}
                  isRound={true}
                  text={`${ackedAlarmsCount + activeAlarmsCount}`}
                  styles={{ root: { pointerEvents: 'none' } }}
                />
              </Div>
            )}
          </>
        ),
        props: {
          a: { id: 'activeAlarmsButton' },
        },
      },
      {
        key: 'settings',
        as: () =>
          unitsInfosEnabled && (
            <>
              <AppHeaderNavItemDropdown
                title={t('t165')}
                key="settings"
                iconProps={{ glyph: icons.INFO_CIRCLE }}
                iconOnly={true}
              >
                <AppHeaderNavItemDropdown.Item
                  key="siteInfoNav"
                  onClick={() => push(routePaths.pages.info.site)}
                  title={
                    multipleWarnings
                      ? t('t3310')
                      : publicIpInInternalUnit
                      ? t('t3295')
                      : privateIpInExternalUnit
                      ? t('t3296')
                      : macAddrSameForMasterSlaveUnits
                      ? t('t3311')
                      : connectedIpNotFound
                      ? t('t3297')
                      : ''
                  }
                >
                  {(failedNetworks.length > 0 ||
                    multipleWarnings ||
                    publicIpInInternalUnit ||
                    privateIpInExternalUnit ||
                    connectedIpNotFound ||
                    macAddrSameForMasterSlaveUnits ||
                    latestOSVersionAvailable) && (
                    <Div
                      position="absolute"
                      style={{ transform: 'translateX(-90%)' }}
                    >
                      <Icon
                        glyph={icons.WARNING_FILLED}
                        styles={{
                          root: {
                            color: theme.palette.warning.main,
                            mr: `${theme.spacing.sm}px`,
                            left: `4px`,
                            top: `-3px`,
                          },
                        }}
                      />
                    </Div>
                  )}
                  {t('t3125')}
                </AppHeaderNavItemDropdown.Item>
                {applyBorder()}
                <AppHeaderNavItemDropdown.Item
                  onClick={() => push(routePaths.pages.info.unit)}
                >
                  {t('t3113')}
                </AppHeaderNavItemDropdown.Item>
                {applyBorder()}
                <AppHeaderNavItemDropdown.Item
                  onClick={() => push(routePaths.pages.information.home)}
                >
                  {t('t3082')}
                  <Div
                    testId="app-version-text"
                    fontWeight="lighter"
                    fontSize="x-small"
                  >
                    {t('t311')} {getSoftwareVersion(true)}
                  </Div>
                </AppHeaderNavItemDropdown.Item>
              </AppHeaderNavItemDropdown>
              {(failedNetworks.length > 0 ||
                multipleWarnings ||
                publicIpInInternalUnit ||
                privateIpInExternalUnit ||
                connectedIpNotFound ||
                macAddrSameForMasterSlaveUnits ||
                latestOSVersionAvailable) && (
                <Div
                  position="absolute"
                  top={`${theme.spacing.xs}px`}
                  left={
                    !isSmView
                      ? `calc(${theme.spacing.xs * 4}px)`
                      : `${theme.spacing.xlg}px`
                  }
                  zIndex={10}
                  pointerEvents="none"
                >
                  <Icon
                    glyph={icons.WARNING_FILLED}
                    styles={{
                      root: {
                        color: theme.palette.warning.main,
                        mr: `${theme.spacing.sm}px`,
                        left: `-2px`,
                        top: `-3px`,
                      },
                    }}
                  />
                </Div>
              )}
            </>
          ),
        props: {
          a: { id: 'infoButton' },
        },
      },
      {
        title: t('t998'),
        iconProps: { glyph: icons.DOOR_EXIT },
        iconOnly: true,
        as: Button,
        onClick: handleOnLogout,
        props: {
          a: {
            id: 'logoutButton',
            tabIndex: 0,
            border: 'none',
            cursor: 'pointer',
          },
        },
      },
    ];

    if (getIsMatchedSoftwareVersion(units, [SOFTWARE_VERSION_PREFIX.G09])) {
      topMenuItems.splice(topMenuItems.length - 1, 0, {
        key: 'browser-certificate',
        as: () => (
          <AppHeaderNavItemDropdown
            key="browser-certificate"
            iconProps={{ glyph: icons.COG }}
            iconOnly={true}
            isDisabled={!browserCertificateEnabled}
          >
            <AppHeaderNavItemDropdown.Item onClick={showCertificateModal}>
              {t('t997')}
            </AppHeaderNavItemDropdown.Item>
          </AppHeaderNavItemDropdown>
        ),
      });
    }

    return topMenuItems;
  };

  const applyBorder = () => {
    return (
      <Div
        borderBottom="1px solid"
        style={{ marginLeft: '5%', marginRight: '5%' }}
      />
    );
  };

  const daskBoardTitle = t('t346');
  const equipmentTitle = t('t895');
  const alarmsTitle = t('t152');
  const schedulesTitle = t('t37');
  const historyTitle = t('t153');
  const filesTitle = t('t653');
  const editorTitle = t('t2203');
  const configurationTitle = t('t156');
  const systemTitle = t('t2261');
  const integrationTitle = t('t3299');

  const getIconStyles = () => ({
    root: {
      mt: '0',
    },
  });

  const getSideMenuGroups = () => {
    const sideMenuGroups: WithFlagDeep<SideMenuGroupProps, 'items'>[] = [
      {
        items: [
          {
            title: daskBoardTitle,
            key: daskBoardTitle,
            icon: {
              glyph: icons.HOME,
              styles: getIconStyles(),
            },
            to: routePaths.pages.home,
            as: Link,
            active: location.pathname === routePaths.pages.home,
          },
          {
            title: equipmentTitle,
            key: equipmentTitle,
            icon: {
              glyph: icons.NO_EQUIPMENT,
              styles: getIconStyles(),
            },
            flag: 'Equipment',
            as: Link,
            showBanner: !deviceConfigurationWriteEnabled,
            bannerText: t('t3312'),
            to: routePaths.pages.equipment.listPath,
            active: location.pathname.includes(
              routePaths.pages.equipment.listPath,
            ),
          },
          {
            title: alarmsTitle,
            key: alarmsTitle,
            icon: {
              glyph: icons.ALARM_URGENT,
              styles: getIconStyles(),
            },
            flag: 'AlarmManagement',
            to: routePaths.pages.alarms.listActivePath,
            showBanner: !alarmManagementWriteEnabled,
            bannerText: t('t3312'),
            as: Link,
            active: location.pathname.includes(
              routePaths.pages.alarms.listActivePath,
            ),
          },
          {
            title: schedulesTitle,
            key: schedulesTitle,
            icon: {
              glyph: icons.CALENDAR,
              styles: getIconStyles(),
            },
            flag: 'ScheduleView',
            to: routePaths.pages.schedules.listPath,
            as: Link,
            active: location.pathname.includes(
              routePaths.pages.schedules.listPath,
            ),
          },
          {
            title: historyTitle,
            key: historyTitle,
            icon: {
              glyph: icons.GRAPH,
              styles: getIconStyles(),
            },
            flag: 'History',
            as: Link,
            to: routePaths.pages.history,
            active: location.pathname.includes(routePaths.pages.history),
          },
        ],
      },
    ];

    if (
      getIsMatchedSoftwareVersion(units, [
        SOFTWARE_VERSION_PREFIX.G08,
        SOFTWARE_VERSION_PREFIX.G09,
        SOFTWARE_VERSION_PREFIX.G10,
      ])
    ) {
      sideMenuGroups[0].items.push({
        title: 'Reports',
        key: 'Reports',
        icon: {
          glyph: icons.REPORTS,
          styles: getIconStyles(),
        },
        as: Link,
        flag: 'Reports',
        to: routePaths.pages.reports,
        active: location.pathname.includes(routePaths.pages.reports),
      });
      if (isExperimental) {
        sideMenuGroups[0].items.push({
          title: filesTitle,
          key: filesTitle,
          icon: {
            glyph: icons.LIST_VIEW_BORDERED,
            styles: getIconStyles(),
          },
          as: Link,
          to: routePaths.pages.files.listPath,
          active: location.pathname.includes(routePaths.pages.files.listPath),
        });
      }
    }
    if (getIsAllowedScadaStorage(units)) {
      sideMenuGroups[0].items.push({
        title: editorTitle,
        key: editorTitle,
        icon: {
          glyph: icons.GRAPHICEDIT,
          styles: getIconStyles(),
        },
        as: Link,
        to: routePaths.pages.graphicEditor.listPath,
        flag: 'SCADAViewWright',
        showBanner: !scadaEditEnabled,
        bannerText: t('t3312'),
        active: location.pathname.includes(
          routePaths.pages.graphicEditor.listPath,
        ),
      });
    }

    if (userHasAccessToConfigPage) {
      sideMenuGroups[0].items.push({
        title: configurationTitle,
        key: configurationTitle,
        icon: {
          glyph: icons.SCREWDRIVER,
          styles: getIconStyles(),
        },
        flag: 'SystemConfiguration',
        showBanner: !sysmtemConfigurationWriteEnabled,
        bannerText: t('t3312'),
        to: routePaths.pages.configuration,
        as: Link,
        active: location.pathname.includes(routePaths.pages.configuration),
      });
    }

    if (
      getIsMatchedSoftwareVersion(
        units,
        [
          SOFTWARE_VERSION_PREFIX.G08,
          SOFTWARE_VERSION_PREFIX.G09,
          SOFTWARE_VERSION_PREFIX.G10,
        ],
        enableSystemMenuForSM800,
      )
    ) {
      sideMenuGroups[0].items.push({
        title: systemTitle,
        key: systemTitle,
        icon: {
          glyph: icons.SOFTWARE_UPDATE,
          styles: getIconStyles(),
        },
        as: Link,
        to: routePaths.pages.system.home,
        active: location.pathname.includes(routePaths.pages.system.home),
      });
    }
    sideMenuGroups[0].items.push({
      title: integrationTitle,
      key: integrationTitle,
      icon: {
        glyph: icons.NO_EQUIPMENT,
        styles: getIconStyles(),
      },
      disabled: !deviceIntegrationEnabled,
      toolTipMessage: !deviceIntegrationEnabled && t('t3213'),
      flag: 'DeviceConfiguration',
      as: Link,
      to: routePaths.pages.deviceIntegration,
      active: location.pathname.includes(routePaths.pages.deviceIntegration),
    });

    return sideMenuGroups;
  };

  const displayTreeviewSelect = () => <EquipmentTreeviewSelect />;
  /**
   * When the applicationName is more than 11 it seems to linebreak around 310 px
   */
  const applicationName = getAppNameByUnits(units);

  const shouldShrinkApplicationName = applicationName.length > 11 && isSmView;

  const isMobEquipmentView =
    isSmView && location.pathname.includes('equipment/');
  const topMenuItems = getTopMenuItems()
    .filter(_ => !isMobEquipmentView)
    .filter(filterOutFeatures);

  const sideMenuGroups = getSideMenuGroups().map(group => ({
    ...group,
    items: group.items.filter(filterOutFeatures),
  }));

  return (
    <>
      {alertMessageKey ? (
        <Alert
          type="warning"
          message={t(alertMessageKey, {
            currentVersion: oldestVersion,
            recommendedVersion:
              softwareRequirements[
                oldestVersionPrefix as SoftwareRequirementIndexes
              ]?.recommended || 'n/a',
          })}
          props={{
            icon: { size: 24 } as any,
          }}
          styles={{
            root: {
              mb: 0,
              border: 'none',
              minHeight: 'unset',
              fontSize: theme.typography.fontSizeSmall,
              borderRadius: 0,
              justifyContent: 'flex-end',
            },
          }}
        />
      ) : null}
      {isUnsupported ? (
        <ContentError
          title={t('t2202')}
          message={t('t893')}
          onRetryClick={window.location.reload}
        />
      ) : (
        <CoreLayout
          header={
            <Header
              logo={(!isSmView && <HeaderBrandLogo />) || undefined}
              showBackButton={!!onBackButtonClick}
              onBackButtonClick={handleOnBackButtonClick}
              onRenderTopMenu={() => (
                <>
                  <HeaderNavItem
                    key="home"
                    iconOnly={true}
                    iconProps={{ glyph: icons.HOME }}
                    props={{
                      root: { testId: 'layout-home-headerNavItem' },
                    }}
                    as={Link}
                    to={initialRoute}
                    active={location.pathname === routePaths.pages.home}
                  />
                  {isMobEquipmentView ||
                    (applicationName && (
                      <Div
                        display="flex"
                        alignItems="center"
                        ml={`calc(${theme.spacing.xs * 3}px)`}
                        fontSize={
                          shouldShrinkApplicationName
                            ? theme.typography.fontSizeSmall
                            : theme.typography.fontSizeBase
                        }
                      >
                        <Div>{applicationName}</Div>
                      </Div>
                    ))}
                  {location.pathname.includes('equipment/') &&
                    displayTreeviewSelect()}
                </>
              )}
              topMenuItems={topMenuItems}
              bottomMenuItems={getHeaderBottomMenuItems()}
            />
          }
          sideMenu={<SideMenu testId="side-menu" groups={sideMenuGroups} />}
        >
          <ErrorBoundary>{children}</ErrorBoundary>
        </CoreLayout>
      )}
    </>
  );
});

export default withRouter(AppLayout);

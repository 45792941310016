import * as React from 'react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { darken } from '@danfoss/etui-colors';
import { Div } from '@danfoss/etui-system-elements';
import { ConfigurationTabItem, Device, User, Unit } from '@danfoss/etui-sm-xml';
import { ContentError } from '@danfoss/etui-sm';
import { useHistory } from 'react-router-dom';
import {
  DeviceConfigurationBaseCommandBar,
  DeviceConfigurationBaseHeader,
} from '../../../DeviceConfiguration';
import { fetchDeviceStatusTabData } from '../../actions';
import { ConfigurationItemContentList } from '../ConfigurationItemContentList';
import { ConfigurationCustomActionsProvider } from '../../context';

type WidgetProps = {
  xmlBackendUrl: string;
  device: Device;
  user: User;
  unit: Unit;
  tabs: any[];
  selectedGroup: string;
  handleDeviceGroupChange: (selectedGroup: string) => void;
};

const DeviceConfigurationWidget = React.memo(
  function DeviceConfigurationWidget({
    xmlBackendUrl,
    device,
    user,
    unit,
    tabs = [],
    selectedGroup,
    handleDeviceGroupChange,
  }: WidgetProps) {
    const theme = useTheme();
    const { t } = useTranslation();

    const [selectedTab, setSelectedTab] = React.useState<ConfigurationTabItem>(
      tabs[0],
    );

    React.useEffect(() => {
      setSelectedTab(tabs[0]);
    }, [tabs]);

    const [group, setGroup] = React.useState(selectedGroup);
    const [subgroup, setSubgroup] = React.useState('0');
    const [page, setPage] = React.useState('0');

    const {
      location: { pathname },
    } = useHistory();

    const {
      data,
      error: deviceDataError,
      mutate,
    } = useSWR(
      () =>
        selectedTab && user && device && unit
          ? [xmlBackendUrl, unit, device, user, selectedTab, pathname]
          : null,
      fetchDeviceStatusTabData,
      {
        revalidateOnFocus: false,
        shouldRetryOnError: true,
      },
    );

    const { groupnames = [], subgroupnames = [], multipage = '0' } = data || {};

    const handleOnTabChange = React.useCallback(
      (value: string) => {
        const newSelectedTab = tabs.find(deviceTab => deviceTab.id === value);
        newSelectedTab && setSelectedTab(newSelectedTab);
      },
      [tabs],
    );

    const shouldShowCommandBar = [
      groupnames.length,
      subgroupnames.length,
      +multipage > 0,
    ].some(Boolean);

    React.useEffect(() => {
      if (selectedGroup) {
        setGroup(selectedGroup);
      }
    }, [selectedGroup]);

    return deviceDataError ? (
      <ContentError
        title={t('t17')}
        message={t('t66')}
        onRetryClick={() => mutate()}
      />
    ) : (
      <Div flex="1">
        <DeviceConfigurationBaseHeader
          activeTabId={selectedTab ? selectedTab.id : null}
          tabs={tabs || []}
          onTabChange={handleOnTabChange}
        />
        <Div
          height="calc(100% - 50px)"
          bg={darken(theme.palette.common.bg, 0.01)}
        >
          {shouldShowCommandBar ? (
            <DeviceConfigurationBaseCommandBar
              groupnames={groupnames}
              subgroupnames={subgroupnames}
              multipage={multipage}
              deviceGroup={group}
              deviceSubgroup={subgroup}
              page={page}
              handleDeviceGroupChange={handleDeviceGroupChange}
              onSetDeviceSubgroup={setSubgroup}
              onSetPage={setPage}
            />
          ) : null}
          <ConfigurationCustomActionsProvider>
            <ConfigurationItemContentList
              menuId={selectedTab.id}
              tab={selectedTab}
              unit={unit}
              device={device}
              deviceGroup={group}
              deviceSubgroup={subgroup}
              page={page}
            />
          </ConfigurationCustomActionsProvider>
        </Div>
      </Div>
    );
  },
);

export { DeviceConfigurationWidget };
